import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { useEffect, useMemo } from 'react';

import { findBy } from '../../../helpers/findBy';
import rootStore from '../../../stores/rootStore/rootStore';
import { System } from '../../../ts/enums/enums';
import { getUnlinkedFeatures } from '../helpers/getUnlinkedFeatures';
import { IDataFeatures } from '../Mapper.model';

const useFeatures = (data: N<IDataFeatures[]>) => {
  const { isConstructor } = rootStore.constructorStore;
  const { systemsInfo, isCrossroadBorder } = rootStore.mapDataStore;

  const { setClustersKeyValue, features, unlinkedFeatures } =
    rootStore.clustersStore;

  const { coordTls, isMultipleSelect, isScriptsControl } =
    rootStore.scriptsControlStore;

  useEffect(() => {
    const unlinked = getUnlinkedFeatures(features);

    setClustersKeyValue('unlinkedFeatures', unlinked);
  }, [features, setClustersKeyValue, systemsInfo]);

  useEffect(() => {
    if (!data) return;

    const newFeatures = data.reduce(
      (acc: Feature<Point>[], { features, isFeatures }) => {
        isFeatures && acc.push(...features);

        return acc;
      },
      []
    );

    setClustersKeyValue('features', newFeatures);
  }, [data, setClustersKeyValue]);

  const featuresCoordTls = useMemo(
    () => coordTls.map(({ feature }) => feature),
    [coordTls]
  );

  const featuresTLInfo = findBy(data ?? [], System.Lights, 'system');
  const isTLMode = isMultipleSelect && featuresTLInfo;

  if (isTLMode)
    return isScriptsControl ? featuresCoordTls : featuresTLInfo.features;

  return isCrossroadBorder && !isConstructor ? unlinkedFeatures : features;
};

export default useFeatures;
