import { PROPERTY_NAMES } from '../../../../apiGIS/constants/weather';
import { IWeatherLayerItem } from '../../../Map/IWeatherTimeModel';

export const getLabels = (item: IWeatherLayerItem): string[] => {
  const { propertyName, gradient } = item;

  if (!gradient) {
    return [];
  }

  const colorStops = gradient?.colorStops || [];

  return colorStops.map((item, index) => {
    const { value } = item;

    if (
      index === colorStops.length - 1 &&
      propertyName === PROPERTY_NAMES.WIND_SPEED
    ) {
      return `>${value}`;
    }

    if (
      index === colorStops.length - 1 &&
      propertyName === PROPERTY_NAMES.PRECIPITATION
    ) {
      return `>${value}`;
    }

    return String(value);
  });
};
