import { Feature } from 'ol';
import { Point } from 'ol/geom';

import { MARKERS_DICT } from '../../../constants/constants';
import {
  FeatureDeviceProps,
  FeaturesTypes,
  System,
} from '../../../ts/enums/enums';
import { Markers } from '../../uiStore/uiStore.model';
import { MultipleClusters } from '../clustersStore.model';

const checkMarkerOff = (markers: Markers, clusters: N<MultipleClusters>) => {
  if (!clusters) return true;

  const isNeedCloseCluster = clusters.cluster
    .get(FeaturesTypes.Features)
    ?.some((feature: Feature<Point>) => {
      const system: U<System> = feature.get(FeatureDeviceProps.System);

      return system && !markers[MARKERS_DICT[system]];
    });

  return isNeedCloseCluster;
};

export default checkMarkerOff;
