import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import TLanesDirections from '../TLanesDirections/TLanesDirections';

import useLinkedLanes from './hooks/useLinkedLanes';
import { LinkedLanesProps } from './PointUdsOverlay.model';

const LinkedTLanes: FC<LinkedLanesProps> = ({ pointUds }) => {
  const { linkedLanes, isTrafficLanes } = rootStore.pointsUdsStore;

  const {
    schemaODD,
    point_uds_uid: uid,
    linkedDeviceIds: { detectors: dtIds },
  } = pointUds.basicProfileInfo;

  useLinkedLanes({ dtIds, uid });

  if (!uid || !linkedLanes[uid]) return null;

  return (
    <>
      {linkedLanes[uid].map((lane) => {
        if (!isTrafficLanes) return null;

        return (
          <TLanesDirections
            trafficLane={lane}
            key={`lane_${lane.id}`}
            isTLCrossroad={Boolean(schemaODD)}
          />
        );
      })}
    </>
  );
};

export default observer(LinkedTLanes);
