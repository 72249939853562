import { useState, useEffect, CSSProperties } from 'react';

import { TLTact } from '../../../ts/models/tl.model';
import { ANIMATION_TIME } from '../constants/constants';
import setDisplay from '../helpers/setDisplay';
import { CrossroadData } from '../models/crossroad.model';
import { getAllDirsElements } from '../PhaseCircleOverlay/helpers/getDirsElements';
import setDirsDisplay from '../PhaseCircleOverlay/helpers/setDirsDisplay';

const { CROSSROAD_TRANSITION } = ANIMATION_TIME;

interface useDirsArgs {
  phaseIdx?: number;
  id: number;
  tact: U<TLTact>;
  tactType: U<0 | 1>;
  isBlinkingTransitionTact: boolean;
  data: CrossroadData;
}

const useDirections = ({
  phaseIdx,
  id,
  tact,
  tactType,
  isBlinkingTransitionTact,
  data,
}: useDirsArgs) => {
  const [phaseEls, setPhaseEls] = useState<HTMLElement[]>();

  useEffect(() => {
    let timerId: U<NodeJS.Timeout> = undefined;

    const clean = () => timerId && clearInterval(timerId);

    if (!tact?.direction) return clean;
    const { direction, direction_green_in_phase: greenDirs } = tact;
    const { dirsEls, greenDirsEls } = getAllDirsElements(
      direction,
      greenDirs,
      id
    );

    setDirsDisplay(phaseEls, 'none');

    if (!dirsEls.length) return clean;
    let val: CSSProperties['display'] = 'inline';

    if (isBlinkingTransitionTact && !tactType) {
      timerId = setInterval(() => {
        setDirsDisplay(dirsEls, val);
        val = setDisplay(val === 'none');
      }, CROSSROAD_TRANSITION);
    }

    setDirsDisplay(dirsEls);
    setDirsDisplay(greenDirsEls);
    setPhaseEls(dirsEls.concat(greenDirsEls));

    return clean;
  }, [phaseIdx, data, isBlinkingTransitionTact, tactType]); // eslint-disable-line
};

export default useDirections;
