import { useEffect, useMemo, useState } from 'react';

import { hasIdentifiedLayers } from '../../../../apiGIS/layers/utils/hasIdentificableLayers';
import rootStore from '../../../../stores/rootStore/rootStore';
import { System } from '../../../../ts/enums/enums';
import usePanelLogic from '../../hooks/usePanelLogic';
import { ClusterTabKeys } from '../constants/constants';
import { getClusterLink } from '../helpers/getClusterLink';
import { getTabTooltips } from '../helpers/getTabTooltips';
import { isClusterKey } from '../model/cluster.model';

const useClusterTabs = () => {
  const { markers } = rootStore.uiStore;
  const { clickedCluster: clickedClusterMap } = rootStore.clustersStore;
  const { mapProxy: map } = rootStore.mapDataStore;
  const { layersState: layerDefinitions, layersVisibilityProxy } =
    rootStore.gisDataStore;

  const [activeKey, setActiveKey] = useState(ClusterTabKeys.MainInfo);
  const [isDisabledLayers, setIsDisabledLayers] = useState(
    !hasIdentifiedLayers(map, layerDefinitions)
  );

  const { panelContentContext: clickedCluster } =
    usePanelLogic(clickedClusterMap);

  const link = useMemo(
    () => getClusterLink(clickedCluster?.cluster),
    [clickedCluster?.cluster]
  );

  const isDisabledCrossroads = useMemo(
    () => !markers.isLights || !link?.[System.Lights],
    [markers.isLights, link]
  );

  const { titleCrossroads, gisLayerTooltip } = getTabTooltips(
    link,
    isDisabledCrossroads,
    isDisabledLayers
  );

  const onTabClick = (key: string) => {
    isClusterKey(key) && setActiveKey(key);
  };

  useEffect(() => {
    setIsDisabledLayers(!hasIdentifiedLayers(map, layerDefinitions));
  }, [map, layersVisibilityProxy, layerDefinitions]);

  useEffect(() => {
    const isBlockedCrossroad =
      activeKey === ClusterTabKeys.Crossroads && isDisabledCrossroads;

    const isBlockedLayers =
      activeKey === ClusterTabKeys.Identification && isDisabledLayers;

    (isBlockedCrossroad || isBlockedLayers) &&
      setActiveKey(ClusterTabKeys.MainInfo);
  }, [activeKey, isDisabledCrossroads, isDisabledLayers]);

  return {
    titleCrossroads,
    gisLayerTooltip,
    isDisabledCrossroads,
    isDisabledLayers,
    link,
    activeKey,
    onTabClick,
  };
};

export default useClusterTabs;
