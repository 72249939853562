import { IIdentifyItem } from '../../../../ts/models/gis/identify.model';

export interface IFeatureProperty {
  text: string;
  value: U<string | number | boolean>;
  type?: string;
}

export const getItemProperties = (
  item: U<IIdentifyItem>
): IFeatureProperty[] => {
  if (!item) {
    return [];
  }

  const { feature } = item;

  const properties = feature.getProperties();

  const results: IFeatureProperty[] = [];

  for (const key in properties) {
    const value = properties[key];

    if (typeof value !== 'object') {
      results.push({ text: key, value: String(value) || 'нет данных' });
    }
  }

  return results;
};
