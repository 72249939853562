import { Card, Col, InputNumber, Slider } from 'antd';
import { isNumber } from 'lodash';
import React, { useState } from 'react';

import { ReactComponent as ResizeIco } from '../../../assets/icons/resize.svg';
import { TLPhaseCodes } from '../../../ts/enums/tl';
import translateCode from '../../Constructor/DetailedConstructor/ConstructorPhaseImages/helpers/translateCode';
import ImageGallery from '../../ui-kit/ImageGallery/ImageGallery';
import Img from '../../ui-kit/Img/Img';

import { IPhasesData } from './helpers/getImgPhases';

import styles from './TlPhaseSchemeFilled.module.scss';

const HEIGHT_CORRECTION = 20;

interface TlPhaseSchemeFilledProps {
  id: number;
  phasesData: IPhasesData[];
  cashedIMGs: Record<string, unknown>;
}

const TlPhaseSchemeFilled: React.FC<TlPhaseSchemeFilledProps> = (
  props: TlPhaseSchemeFilledProps
) => {
  const { id, phasesData } = props;
  const [schemeWidth, setSchemeWidth] = useState(15);

  const onChange = (newValue: N<number>) => {
    isNumber(newValue) && setSchemeWidth(newValue);
  };

  if (!phasesData) {
    return (
      <div className={styles.containerNotData}>
        <Card className={styles.mainGraphCardNotData}>
          <h3>Нет данных</h3>
        </Card>
      </div>
    );
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.headinputs}>
          <Col span={3}>
            <ResizeIco className={styles.resizeIco} />
          </Col>
          <Col span={13}>
            <Slider
              min={15}
              max={100}
              onChange={onChange}
              value={schemeWidth}
            />
          </Col>
          <Col span={2}>
            <InputNumber
              min={15}
              max={100}
              style={{
                margin: '0 16px',
              }}
              value={schemeWidth}
              onChange={onChange}
            />
          </Col>
        </div>
        <ImageGallery>
          {phasesData &&
            phasesData.map(({ phaseID, phaseIMG, main, code }, i) => {
              const { label } = translateCode(
                code ?? TLPhaseCodes.Undefined,
                Number(phaseID)
              );

              return (
                main !== 0 && (
                  <div
                    className={styles.phaseScheme}
                    key={`key_${i}`}
                    style={{
                      width: `${schemeWidth}%`,
                      maxWidth: `${schemeWidth}%`,
                      height: `${schemeWidth + HEIGHT_CORRECTION}vh`,
                    }}
                  >
                    <h4 className={styles.phaseTitle}>{label}</h4>
                    <Img
                      src={phaseIMG}
                      alt={`tl_${id}_${label}`}
                      className={styles.card}
                      classNameImg={styles.img}
                      isShowIcons
                    />
                  </div>
                )
              );
            })}
        </ImageGallery>
      </div>
    </>
  );
};

export default TlPhaseSchemeFilled;
