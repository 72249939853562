import { useCallback, useEffect, useMemo, useState } from 'react';

import { TlManagementKeys } from '../../../../../constants/commands';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { getCurrentTLInfo } from '../../helpers/getCurrentTLInfo';
import {
  INITIAL_SELECTED_PLAN,
  INITIAL_SELECTED_TIME,
} from '../../PlanSelect/constants/constants';
import {
  SelectedPlan,
  SelectedTime,
} from '../../PlanSelect/models/plansControl.model';

const useTemporaryPlan = (id: number) => {
  const { tls } = rootStore.mapDataStore;
  const { activeControlKey, setIsNotDetailed } = rootStore.detailedStore;

  const [selectedPlan, setSelectedPlan] = useState<SelectedPlan>(
    INITIAL_SELECTED_PLAN
  );
  const [selectedTime, setSelectedTime] = useState<SelectedTime>(
    INITIAL_SELECTED_TIME
  );

  const tlInfo = useMemo(() => getCurrentTLInfo(tls, id), [id, tls]);

  useEffect(() => {
    if (activeControlKey !== TlManagementKeys.CoordinatedManagement) return;

    const check =
      selectedPlan.planId && selectedTime.date && selectedTime.duration;

    setIsNotDetailed('isDisabledControlCmd', !check);
  }, [
    activeControlKey,
    selectedPlan.planId,
    selectedTime.date,
    selectedTime.duration,
    setIsNotDetailed,
  ]);

  const clearPlan = useCallback(() => {
    setSelectedTime(INITIAL_SELECTED_TIME);
    setSelectedPlan(INITIAL_SELECTED_PLAN);
  }, []);

  return {
    tlInfo,
    clearPlan,
    selectedPlan,
    selectedTime,
    setSelectedPlan,
    setSelectedTime,
  };
};

export default useTemporaryPlan;
