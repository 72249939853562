import { type DescriptionsProps } from 'antd/lib';

import { SECOND } from '../../../../../../../constants/constants';
import { UPDATE_PREDICT_CHART_INTERVAL } from '../../../../../../../constants/queryConstants';
import rootStore from '../../../../../../../stores/rootStore/rootStore';
import { GraphTypes } from '../../../../../../TrafficLightDetailed/TlDetectors/constants/constants';
import { IDateInfo } from '../../../../../../TrafficLightDetailed/TlDetectors/model/graph.model';
import { getIntervalFormatted } from '../../../../helpers/getIntervalFormatted';
import { UpdateInfo } from '../../../../models/chart.model';

import getSelectedPeriod from './getSelectedPeriod';

const getMainItems = (
  graphInfo: IDateInfo,
  timeUpdate: UpdateInfo
): DescriptionsProps['items'] => {
  const { graphType } = rootStore.detailedStore;

  const isCandleChart = graphType === GraphTypes.CandlesChart;

  const dates = getSelectedPeriod({ ...graphInfo, isCandleChart });

  const intervalText = getIntervalFormatted(
    isCandleChart ? UPDATE_PREDICT_CHART_INTERVAL / SECOND : graphInfo.interval
  );

  const correctTimeUpdate = timeUpdate[graphType];

  return [
    {
      key: 'dates',
      label: 'Период',
      children: dates,
      span: 3,
    },
    {
      key: 'interval',
      label: 'Интервал',
      children: intervalText,
    },
    {
      key: 'update',
      label: 'Обновлено',
      children: correctTimeUpdate,
    },
  ].filter(({ children }) => children);
};

export default getMainItems;
