import { CSSProperties } from 'react';

const setDirsDisplay = (
  els: Nullish<HTMLElement[]>,
  display?: CSSProperties['display']
) =>
  els?.forEach((el) => {
    el.style.display = display ?? 'inline';
  });

export default setDirsDisplay;
