import { VectorImage } from 'ol/layer';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { StyleLike } from 'ol/style/Style'; // eslint-disable-line

import { createStyleFunction } from './fromRenderer';
import { DrawingInfoRenderer } from './fromRenderer/renderer.model';

const fromEsri = (
  vector: U<VectorLayer<VectorSource>> | U<VectorImage<VectorSource>>,
  renderer: DrawingInfoRenderer | undefined
) => {
  if (!renderer || !vector) return;

  const styleFunction = createStyleFunction(renderer);

  try {
    if (styleFunction) {
      vector.setStyle(styleFunction as StyleLike);
    }
  } catch (e) {
    console.warn(`Стиль для слоя ${vector.getClassName()} с ошибкой!`);
  }
};

export default { fromEsri };
