import { FC } from 'react';

import { MODES_INFO } from '../../../../../../constants/commands';
import {
  DEVICES_INFO,
  DEVICES_STATE,
  TDeviceStatus,
} from '../../../../../../constants/devicesConstants';
import { findBy } from '../../../../../../helpers/findBy';
import { TObjMap } from '../../../../../../stores/mapDataStore/mapDataStore.model';
import { System } from '../../../../../../ts/enums/enums';
import { TlMode } from '../../../../../../ts/models/tl.model';
import { getDeviceTitle } from '../helpers';

import styles from './PopoverContent.module.scss';

interface IPopoverContent {
  system: System;
  mode?: TlMode;
  currentObject: TObjMap;
  id: number;
  statusDevice: TDeviceStatus;
}

const PopoverContent: FC<IPopoverContent> = ({
  system,
  mode,
  currentObject,
  id,
  statusDevice,
}) => {
  const title = getDeviceTitle({ currentObject, id, notSlice: true });

  const status =
    findBy(DEVICES_STATE, statusDevice, 'com')?.dsc ??
    DEVICES_INFO.STATE.Undefined.dsc;
  const statusTL =
    findBy(Object.values(MODES_INFO), mode ?? 0, 'com')?.dsc ??
    MODES_INFO.Unknown.dsc;

  const isSpecialText = mode !== undefined;

  return (
    <div className={styles.wrapper}>
      <p>{system}</p>
      <p>{title}</p>
      <hr />
      <p>Статус устройства: {status}</p>
      {isSpecialText ? <p>Режим СО: {statusTL}</p> : null}
    </div>
  );
};

export default PopoverContent;
