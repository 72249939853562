import { uniqueId } from 'lodash';
import { FC } from 'react';

import NoData from '../../../../ui-kit/Errors/NoData/NoData';
import { FilesDataType } from '../../../models/documents.model';
import DocumentsItem from '../DocumentsItem/DocumentsItem';

import styles from './FileList.module.scss';

interface IFileList {
  fileList: FilesDataType['children'];
}

const FileList: FC<IFileList> = ({ fileList }) => {
  if (!fileList)
    return <NoData textError="Файлы отсутствуют" isNeedBackground={false} />;

  return (
    <ul className={styles.wrapper}>
      {fileList.map((file) => (
        <DocumentsItem key={uniqueId('#')} file={file} />
      ))}
    </ul>
  );
};

export default FileList;
