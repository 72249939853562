import { type DrawerProps } from 'antd';

import { COLORS } from '../../../constants/colorsConstants';

export const adminDrawerStyles: DrawerProps['styles'] = {
  content: { background: COLORS.BG_TINT_CONTRAST },
};

export const bottomDrawerStyles: DrawerProps['styles'] = {
  ...adminDrawerStyles,
  header: { border: 'none' },
};
