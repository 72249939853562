import { observer } from 'mobx-react-lite';
import { Suspense, lazy, useEffect, FC } from 'react';

import setDisplayStyle from '../../../helpers/setDisplayStyle';
import rootStore from '../../../stores/rootStore/rootStore';
import { useCreateOverlay } from '../hooks';

import styles from './ConstructorOverlays.module.scss';

const ConstructorCircle = lazy(
  () => import('../../Overlays/ConstructorCircle/ConstructorCircle')
);

const ConstructorOverlays: FC = () => {
  const { constructorCircleRef, constructorCircle } = rootStore.mapStore;

  const {
    setConstructorData,
    isCircleConstructor,
    isConstructorLoaded,
    isCenteredByClick,
  } = rootStore.constructorStore;

  const { coordinates } = rootStore.mapDataStore;

  useCreateOverlay('constructorCircle', constructorCircleRef);

  useEffect(() => {
    if (isCircleConstructor) {
      isCenteredByClick &&
        setConstructorData('circleCenter', coordinates as XY);
      setDisplayStyle([constructorCircleRef], 'block');
    }
  }, [coordinates]); // eslint-disable-line

  return (
    <div className={styles.circleContainer} ref={constructorCircleRef}>
      {isConstructorLoaded && isCircleConstructor ? (
        <Suspense>
          <ConstructorCircle constructorCirclePopup={constructorCircle} />
        </Suspense>
      ) : null}
    </div>
  );
};

export default observer(ConstructorOverlays);
