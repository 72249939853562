import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import { TooltipProps } from '../../Mapper/Mapper.model';
import Popover from '../../ui-kit/Popover/Popover';

import styles from './MapTooltipContent.module.scss';

interface IMapTooltipContent
  extends Pick<TooltipProps, 'isTooltip' | 'rerenderData'> {
  content: TooltipProps['children'];
}

const MapTooltipContent: FC<IMapTooltipContent> = ({
  isTooltip,
  content,
  rerenderData,
}) => {
  const { mapTipsDelay, isCursorOnMapChildElement } = rootStore.uiStore;
  const [cacheTooltipData, setCacheTooltipData] = useState<N<JSX.Element>>();

  useEffect(() => {
    setCacheTooltipData(rerenderData);
  }, [rerenderData]);

  const isSameContent = cacheTooltipData === rerenderData;

  if (!content || !isTooltip) return null;

  const tooltipContent = <div className={styles.tooltip}>{content}</div>;

  if (!isCursorOnMapChildElement) return null;

  return (
    <Popover
      open={isSameContent}
      placement="top"
      isClearParent
      destroyTooltipOnHide
      tipsDelay={mapTipsDelay}
      content={tooltipContent}
      fresh
    >
      <div />
    </Popover>
  );
};

export default observer(MapTooltipContent);
