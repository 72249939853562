import { TL_DETAILED } from '../../../../constants/tlDetailedConstants';
import { TLPhaseCodes } from '../../../../ts/enums/tl';

const {
  PHASE_ID: { COMMON },
} = TL_DETAILED;

interface ImageInfo {
  url: string;
  code: N<TLPhaseCodes>;
}

interface Filtered extends ImageInfo {
  phaseID: string;
}

export const filterPHID = (imgInfo: ImageInfo[]) =>
  imgInfo.reduce((acc: Filtered[], { url, code }) => {
    const subName = url.split('/').at(-1) as U<string>;

    if (!subName) {
      acc.push({ phaseID: 'с ошибкой', url, code });

      return acc;
    }

    if (subName.includes('no_phase_image')) return acc;

    if (subName && !subName.includes('_')) {
      acc.push({ phaseID: COMMON, url, code });

      return acc;
    }

    const start = subName?.indexOf('_') + 1;
    const end = subName?.indexOf('.');

    acc.push({ phaseID: subName.slice(start, end), url, code });

    return acc;
  }, []);
