import { Feature } from 'ol';
import { Point } from 'ol/geom';

import { FeaturesTypes } from '../../../ts/enums/enums';
import { IFeaturesArr } from '../../Map/helpers/getFeaturesCluster';

import { getColorStatusOverlay } from './getColorStatusOverlay';

export const getErrorsObj = (features: Feature<Point>[]) => {
  const errorsFeatures = features.reduce(
    (acc: IFeaturesArr[], value: Feature<Point>) => {
      const features: U<Feature<Point>[]> = value.get(FeaturesTypes.Features);
      const colorAlarm = getColorStatusOverlay(features ?? []);

      if (!colorAlarm || !features?.length) return acc;

      const coord = value.getGeometry()?.getCoordinates();

      const isCluster = features.length > 1;

      const feature = isCluster ? value : features[0];

      acc.push({ feature, coord, colorAlarm });

      return acc;
    },
    []
  );

  return errorsFeatures;
};
