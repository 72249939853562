import { DataValue } from '../../models/chart.model';

const getLineKeys = (data: N<DataValue>, valueKey: string) => {
  if (!data) return [];

  const { time, [valueKey]: value, ...obj } = data;

  const keys = Object.keys(obj);

  return keys;
};

export default getLineKeys;
