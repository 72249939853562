import { TObjMap } from '../stores/mapDataStore/mapDataStore.model';
import { System } from '../ts/enums/enums';

const SYSTEMS_NAMES: Record<System, string> = {
  [System.Lights]: 'Светофорный объект',
  [System.Detectors]: 'Детектор транспорта',
  [System.Cameras]: 'Камера видеонаблюдения',
  [System.PublicTransport]: 'Общественный транспорт',
  [System.Meteo]: 'Метеостанция',
  [System.SpecialTransport]: 'Специальная техника',
};

export const getSystemTitle = (system: System, item: TObjMap) => {
  return `${SYSTEMS_NAMES[system]} ${item.id}`;
};
