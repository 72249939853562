import { Popconfirm } from 'antd';
import { useState } from 'react';
import { BsDownload } from 'react-icons/bs';

import { exportFile, exportFileService } from '../../../helpers/export/helpers';
import { sanitizeAndCrop, sanitizeText } from '../../../helpers/sanitize';
import ButtonOnlyIcon from '../ButtonOnlyIcon/ButtonOnlyIcon';
import { TPartialConfirm } from '../ButtonOnlyIcon/model/buttonIcon.model';

import ChangeExportType from './ChangeExportType/ChangeExportType';
import {
  BASE_CONFIRM_PROPS,
  CANCEL_TEXT,
  CONFIRM_PLACEMENT,
  OK_TEXT,
  POPOVER_EXPORT,
} from './constants';
import { FileExtensions, IExport } from './Export.model';
import useCsvExport from './hooks/useCsvExport';

const Export = <T extends Record<K, ValueOf<T>>, K extends keyof T>({
  data,
  headers,
  title,
  tip = POPOVER_EXPORT,
  popconfirmProps,
  popoverProps,
  isDisabled = false,
  children,
}: IExport<T, K>) => {
  const { exportData } = useCsvExport({
    data,
    headers,
  });

  const [type, setType] = useState(FileExtensions.xlsx);

  const handleExport = () => {
    type === FileExtensions.xlsx &&
      exportFile(data, type, sanitizeAndCrop(title), headers);
    type === FileExtensions.xlsxService &&
      exportFileService(data, type, sanitizeText(title), headers);
    type === FileExtensions.csv && exportData();
  };

  const basicProps: TPartialConfirm = {
    ...BASE_CONFIRM_PROPS,
    placement: popconfirmProps?.placement ?? CONFIRM_PLACEMENT,
    title: <ChangeExportType onChange={setType} value={type} />,
  };

  if (!children)
    return (
      <ButtonOnlyIcon
        isDisabled={isDisabled}
        onClick={handleExport}
        tip={popoverProps?.content ?? tip}
        isSmall
        confirmProps={basicProps}
      >
        <BsDownload />
      </ButtonOnlyIcon>
    );

  return (
    <Popconfirm
      {...basicProps}
      placement={CONFIRM_PLACEMENT}
      onConfirm={handleExport}
      okText={OK_TEXT}
      cancelText={CANCEL_TEXT}
      disabled={isDisabled}
      {...(popconfirmProps ?? {})}
      title={<ChangeExportType onChange={setType} value={type} />}
    >
      {children}
    </Popconfirm>
  );
};

export default Export;
