import { FC } from 'react';

import { SECOND } from '../../../../../../../constants/constants';
import { convertDate } from '../../../../../helpers/helpers';
import { UpdateInfo } from '../../../../NJSInfo.model';
import DscItem from '../DscItem/DscItem';
interface GroupItemInfoProps {
  objectType: string;
  updateInfo: N<UpdateInfo>;
}

const GroupItemInfo: FC<GroupItemInfoProps> = ({ updateInfo, objectType }) => {
  if (!updateInfo)
    return (
      <>
        <DscItem label="Тип объектов">{objectType}</DscItem>
        <DscItem label="Обновления">не обнаружены</DscItem>
      </>
    );

  return (
    <>
      <DscItem label="Тип объектов">{objectType}</DscItem>
      <DscItem label="Время обновления">
        {convertDate(updateInfo.timestamp / SECOND)}
      </DscItem>
      <DscItem label="Кол-во объектов">{updateInfo.objCount}</DscItem>
      <DscItem label="Время выполнения">
        {updateInfo.spentTime / SECOND} сек
      </DscItem>
    </>
  );
};

export default GroupItemInfo;
