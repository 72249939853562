import { observer } from 'mobx-react-lite';
import { MapBrowserEvent } from 'ol';
import { boundingExtent } from 'ol/extent';
import { FC, useEffect, useMemo } from 'react';

import { Z_INDEX_CLUSTER_LAYER } from '../../../../../../apiGIS/constants/map';
import { CONFIG } from '../../../../../../constants/configConstants';
import { ZOOM } from '../../../../../../constants/mapConstants';
import { SetMapPropsDetailed } from '../../../../../../stores/detailedStore/detailedStore.model';
import { TMap } from '../../../../../../stores/mapStore/mapStore.model';
import { DISABLED_POPOVER_TEXT } from '../../../../../Panel/MainPanelTabs/AllSystemsTab/constants/constants';
import DefaultChecked from '../../../../../ui-kit/DefaultChecked/DefaultChecked';
import { TileLayer } from '../../../../../ui-kit/MapLayers';
import { osm } from '../../../../../ui-kit/MapSource';
import UiMap, {
  UIMapProps,
  ZoomProps,
} from '../../../../../ui-kit/UiMap/UiMap';

import styles from './MapWrapper.module.scss';

const EXTENT_CORRECTION = {
  X: 0.005,
  Y: 0.001,
} as const;

const ZOOM_PROPERTIES: ZoomProps = {
  initZoom: ZOOM.CROSSROAD_BORDER,
  minZoom: ZOOM.CROSSROAD_BORDER,
};

const { X, Y } = EXTENT_CORRECTION;
const { UnknownCoordinates } = DISABLED_POPOVER_TEXT;

interface MapWrapperProps {
  map: TMap;
  centerCoord?: XY;
  setMap: SetState<TMap>;
  setMapProps: SetMapPropsDetailed;
  isMapLoading: boolean;
  setIsLoading: (value: boolean) => void;
  handleMapClick?: (event: MapBrowserEvent<any>) => void;
  isEmptyData?: boolean;
}

const MapWrapper: FC<MapWrapperProps> = ({
  map,
  centerCoord,
  setMap,
  setMapProps,
  isMapLoading,
  setIsLoading,
  handleMapClick,
  isEmptyData = false,
}) => {
  const handleLoadView = () => setIsLoading(false);
  const handleZoom: UIMapProps['handleZoom'] = (currZoom, resolution) =>
    setMapProps(currZoom, resolution);

  useEffect(() => {
    return () => setIsLoading(true);
  }, [setIsLoading]);

  const zoomProps = useMemo(() => ZOOM_PROPERTIES, []);

  const extent = useMemo(
    () =>
      centerCoord
        ? boundingExtent([
            [centerCoord[0] - X, centerCoord[1] - Y],
            [centerCoord[0] + X, centerCoord[1] + Y],
          ])
        : undefined,
    [centerCoord]
  );

  const uiMapProps: Omit<UIMapProps, 'children'> = {
    map,
    centerCoord,
    zoomProps,
    extent,
    setMap,
    handleLoadView,
    handleZoom,
    handleMapClick,
  };

  return (
    <div className={styles.container}>
      <DefaultChecked
        isLoading={isMapLoading}
        isEmptyData={isEmptyData}
        noDataProps={{ textError: UnknownCoordinates }}
        isBackdropFilter
      >
        <UiMap {...uiMapProps}>
          <TileLayer
            source={osm(CONFIG.OSM_SOURCE)}
            zIndex={Z_INDEX_CLUSTER_LAYER}
            map={map}
          />
        </UiMap>
      </DefaultChecked>
    </div>
  );
};

export default observer(MapWrapper);
