import { FilesDataType } from '../../../models/documents.model';

const sortUpItem = (item: FilesDataType) => {
  if (item.children) {
    item.children.sort((a, b) => {
      const name1 = a.profile.fileAttributes.name;
      const name2 = b.profile.fileAttributes.name;

      return name1.localeCompare(name2);
    });
  }

  return item;
};

const handleSortUp = (data: FilesDataType[]) =>
  data.map((item) => sortUpItem(item));

export default handleSortUp;
