import classNames from 'classnames';
import { FC } from 'react';

import CloseBtn from '../../../Svg/CloseBtn/CloseBtn';

import styles from './DrawerHeader.module.scss';

interface IDrawerHeader {
  onClose: () => void;
  title: U<string>;
  isFullMode?: boolean;
}

const DrawerHeader: FC<IDrawerHeader> = ({
  onClose,
  title,
  isFullMode = true,
}) => {
  const titleStyle = classNames({
    [styles.title]: true,
    [styles.fullForm]: isFullMode,
  });

  return (
    <div className={styles.wrapper}>
      <h2 className={titleStyle}>{title}</h2>
      <CloseBtn onClick={onClose} />
    </div>
  );
};

export default DrawerHeader;
