import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';

import rootStore from '../../../../../../../../../stores/rootStore/rootStore';
import { System } from '../../../../../../../../../ts/enums/enums';
import getDtProfiles from '../../../../../../../../Overlays/PhaseCircleOverlay/LinkedDevices/helpers/getDtProfiles';

import LinkedDt from './LinkedDt/LinkedDt';

interface LinkedDetectorsProps {
  linkedDts: number[];
  rate: number;
}

const LinkedDetectors: FC<LinkedDetectorsProps> = ({ linkedDts, rate }) => {
  const { infoData } = rootStore.uiStore;
  const { detectors, cameras } = rootStore.mapDataStore;

  const lanes = useMemo(
    () => getDtProfiles(linkedDts, detectors, cameras),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [linkedDts, detectors, cameras]
  );

  const isTL = useMemo(
    () => infoData?.system === System.Lights,
    [infoData?.system]
  );

  return (
    <>
      {lanes.map((lane) => {
        lane.isEnabled = true;

        return (
          <LinkedDt
            key={`lane_${lane.id}`}
            lane={lane}
            rate={rate}
            isTL={isTL}
          />
        );
      })}
    </>
  );
};

export default observer(LinkedDetectors);
