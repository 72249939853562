import { FC } from 'react';

import { IDataList } from '../../model/monitoring.model';
import { ShowBtnsDevices } from '../DisplayBtns/constants/constants';

import DevicesList from './DevicesList/DevicesList';
import DevicesTiles from './DevicesTiles/DevicesTiles';

interface IDevicesBlockProps {
  data: IDataList[];
  displayType: ShowBtnsDevices;
  addressLoading: boolean;
}

const DevicesBlock: FC<IDevicesBlockProps> = ({
  data,
  displayType,
  addressLoading,
}) => {
  const basicProps = {
    data,
    addressLoading,
  };

  switch (displayType) {
    case ShowBtnsDevices.List:
      return <DevicesList {...basicProps} />;
    case ShowBtnsDevices.Tiles:
      return <DevicesTiles {...basicProps} />;
    default:
      return <DevicesList {...basicProps} />;
  }
};

export default DevicesBlock;
