import { Card } from 'antd';
import { FC, useMemo, useState } from 'react';

import { findById } from '../../../helpers/findBy';
import { neverTypeCheck } from '../../../helpers/neverTypeCheck';
import rootStore from '../../../stores/rootStore/rootStore';
import { ICamera } from '../../../ts/models/camera.model';
import { DetailedItemProps } from '../../DetailedMain/DetailedWrapper/constants/constants';

import CamerasEqual from './Templates/CamerasEqual';
import CamerasOneToMany from './Templates/CamerasOneToMany';
import CamerasVsSwitcher, {
  CamerasTemplate,
} from './Templates/CamerasVsSwitcher';

import styles from './TlCameras.module.scss';

const switchTemplate = (
  templateVer: CamerasTemplate,
  camerasInfo: ICamera[]
) => {
  switch (templateVer) {
    case CamerasTemplate.ONE_TO_MANY:
      return <CamerasOneToMany cameras={camerasInfo} />;
    case CamerasTemplate.EQUAL:
      return <CamerasEqual cameras={camerasInfo} />;
    default:
      neverTypeCheck(templateVer);
  }
};

const TlCameras: FC<DetailedItemProps> = ({ id }) => {
  const [templateVer, setTemplateVer] = useState<CamerasTemplate>(
    CamerasTemplate.EQUAL
  );
  const { cameras, tls } = rootStore.mapDataStore;

  const currentObject = useMemo(() => findById(tls, id), [id, tls]);

  const camerasInfo = useMemo(
    () =>
      cameras.filter(
        ({ linkedDeviceIds }) =>
          linkedDeviceIds?.trafficLights &&
          linkedDeviceIds?.trafficLights === currentObject?.id
      ),
    [cameras, currentObject?.id]
  );

  if (!camerasInfo.length) {
    return (
      <div className={styles.containerNotCameras}>
        <Card className={styles.mainGraphCardNotCameras}>
          <h3>На данном участке отсутствуют камеры</h3>
        </Card>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <CamerasVsSwitcher
        templateVer={templateVer}
        toggler={(tVersion) => setTemplateVer(tVersion)}
      />
      {switchTemplate(templateVer, camerasInfo)}
    </div>
  );
};

export default TlCameras;
