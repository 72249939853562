import {
  DEFAULT_TACT_TYPE,
  TACT_TYPES_INFO,
  TLTactTypesCode,
} from '../../../../../constants/signalsConstants';
import { findBy } from '../../../../../helpers/findBy';

export const getTypeTactObj = (tactType: N<TLTactTypesCode>) =>
  findBy(TACT_TYPES_INFO, tactType ?? DEFAULT_TACT_TYPE, 'code') ??
  TACT_TYPES_INFO[DEFAULT_TACT_TYPE];
