import { FC } from 'react';
import { IoIosClose } from 'react-icons/io';

import {
  DEVICES_STATE,
  TDeviceStatus,
} from '../../../../../../constants/devicesConstants';
import rootStore from '../../../../../../stores/rootStore/rootStore';
import { getDeviceColor } from '../../../../../MapBtns/MapInfoWrapper/InfoBtn/ContentInfo/helpers/getMapInfo';
import ButtonOnlyIcon from '../../../../../ui-kit/ButtonOnlyIcon/ButtonOnlyIcon';

import styles from './FilterItem.module.scss';

interface FilterItemProps {
  status: TDeviceStatus;
  len: number;
}

const FilterItem: FC<FilterItemProps> = ({ status, len }) => {
  const { setStatusFilteredMonitoring } = rootStore.monitoringPanelStore;

  const name = DEVICES_STATE.find(({ com }) => com === status)?.dsc;

  const onClickClose = () => {
    setStatusFilteredMonitoring(status);
  };

  if (!name) return null;

  return (
    <div className={styles.container}>
      <span
        className={styles.block}
        style={{ backgroundColor: getDeviceColor(status) }}
      >
        {len}
      </span>
      <p>{name}</p>
      <ButtonOnlyIcon onClick={onClickClose} isSmall>
        <IoIosClose />
      </ButtonOnlyIcon>
    </div>
  );
};

export default FilterItem;
