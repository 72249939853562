import { Spin } from 'antd';
import { SpinProps } from 'antd/lib';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';

import { findBy } from '../../../../../helpers/findBy';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { IMG_PATH } from '../../../../../ts/enums/tl';
import { DaiJdt83 } from '../../../../../ts/models/tl.model';
import Img from '../../../../ui-kit/Img/Img';
import { getTypeTactObj } from '../helpers/getTypeTactObj';
import { PhasesInfo } from '../PhaseCardsList';

import PhaseContent from './PhaseContent/PhaseContent';

import styles from './MainPhaseCard.module.scss';

interface MainPhaseCardProps {
  extId: PhasesInfo['extId'];
  images: DaiJdt83['images'];
  isDetailed?: boolean;
}

const MainPhaseCard: FC<MainPhaseCardProps> = ({
  extId,
  images,
  isDetailed = false,
}) => {
  const { secTL, phasesArray } = rootStore.uiStore;
  const [imgUrl, setImgUrl] = useState<N<string>>(null);

  const curPhase = phasesArray.find(
    ({ stop, start }) => secTL <= stop && secTL >= start
  );

  const timeToEnd = curPhase ? curPhase.stop - secTL : null;

  useEffect(() => {
    if (!curPhase) return;

    const imgPhase = findBy(images ?? [], curPhase.phase, 'phase')?.image;

    const url = IMG_PATH + imgPhase;

    setImgUrl(imgPhase ? url : null);
  }, [curPhase, extId, images]); // eslint-disable-line

  const spinProps: SpinProps = {
    size: 'large',
    className: styles.spin,
  };

  if (!curPhase || timeToEnd === null)
    return (
      <div className={styles.container}>
        <Spin {...spinProps} />
      </div>
    );

  const tactTypeObj = getTypeTactObj(curPhase.tactType).dsc;

  const tactTypeText = tactTypeObj ? `(${tactTypeObj})` : '';

  const phaseTitle = `Фаза ${curPhase.phase} ${tactTypeText}`;

  const containerStyle = classNames({
    [styles.container]: true,
    [styles.detailed]: isDetailed,
  });

  return (
    <div className={containerStyle}>
      <div className={styles.mainContainer}>
        <div className={styles.titleRow}>
          <span className={styles.phaseName}>{phaseTitle}</span>
          <div className={styles.secRow}>
            <p>Время до конца фазы: </p>
            <span className={styles.phaseSec}>{timeToEnd}</span>
          </div>
        </div>
        <Img
          src={imgUrl}
          alt="Картинка Фазы"
          className={styles.img}
          isShowIcons
        />
      </div>
      <PhaseContent curPhase={curPhase} />
    </div>
  );
};

export default observer(MainPhaseCard);
