import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';

import rootStore from '../../../stores/rootStore/rootStore';

const ModalLoading = () => {
  const { isModalLoading } = rootStore.uiStore;

  if (!isModalLoading) return null;

  return <Spin size="large" fullscreen />;
};

export default observer(ModalLoading);
