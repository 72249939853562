import DOMPurify from 'dompurify';
import { CSSProperties, FC } from 'react';

import PhaseCircleImgWrapper from '../PhaseCircleImgWrapper/PhaseCircleImgWrapper';

export interface ImgProps {
  data: string;
  style?: CSSProperties;
  isFixedSizeImg?: boolean;
}

const PhaseCircleImg: FC<ImgProps> = ({
  data,
  style,
  isFixedSizeImg = false,
}) => (
  <PhaseCircleImgWrapper style={style} isFixedSizeImg={isFixedSizeImg}>
    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data) }} />
  </PhaseCircleImgWrapper>
);

export default PhaseCircleImg;
