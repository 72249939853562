import React from 'react';
import { AiOutlineCopyrightCircle } from 'react-icons/ai';

import Logo from '../../ui-kit/Logo/Logo';

import styles from './LoginLogo.module.scss';

const generateBgSlices = (sliceAmount: number) => {
  const idxs = [];

  for (let i = sliceAmount; i > 0; i--) {
    const key = `bgSlice${i}`;

    idxs.push(<div className={styles[key]} key={key} />);
  }

  return idxs;
};

function LoginLogo() {
  return (
    <section className={styles.container}>
      {generateBgSlices(5)}
      <Logo className={styles.logo} />
      <div className={styles.title}>
        <h2>Веб-интерфейс</h2>
        <h2>Единая платформа управления транспортной системой</h2>
        <h1>{'"Шелковый путь"'}</h1>
      </div>
      <div className={styles.footer}>
        <div className={styles.copyrightContainer}>
          <AiOutlineCopyrightCircle className={styles.copyrightIco} />
          Права на используемые карты принадлежат OpenStreetMap
        </div>
        <div className={styles.footerText}>
          Регистрационный № 15458 от 08.11.2022г. в Едином реестре российских
          программ для электронных вычислительных машин и баз данных
        </div>
      </div>
    </section>
  );
}

export default LoginLogo;
