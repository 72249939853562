import { isNumber } from 'lodash';
import { useMemo } from 'react';

import { findById } from '../../../../../../../helpers/findBy';
import { MapPropsDetailed } from '../../../../../../../stores/detailedStore/detailedStore.model';
import {
  getCircleDiameter,
  getRate,
} from '../../../../../../../stores/helpers/schemaHelpers';
import rootStore from '../../../../../../../stores/rootStore/rootStore';
import { CrossroadSchemaProps } from '../../CrossroadSchema/CrossroadSchema';

type CrossroadProps = Omit<CrossroadSchemaProps, 'crossroadOverlay' | 'map'>;

interface UseCrossroadTLWrapperProps {
  tlId: number;
  mapPropsDetailed: N<MapPropsDetailed>;
}

const useCrossroadTLWrapper = ({
  tlId,
  mapPropsDetailed,
}: UseCrossroadTLWrapperProps) => {
  const { tls } = rootStore.mapDataStore;

  const tlInfo = useMemo(() => {
    const tl = findById(tls, tlId);

    if (!tl) return;

    const { longitude, latitude } = tl;

    const coord = [longitude, latitude];

    return { tl, coord };
  }, [tlId, tls]);

  const rate = mapPropsDetailed
    ? getRate(
        getCircleDiameter(
          mapPropsDetailed.currZoom,
          mapPropsDetailed.resolution
        )
      )
    : null;

  const crossroadBasicProps: N<CrossroadProps> =
    tlInfo && isNumber(rate)
      ? {
          id: tlInfo.tl.id,
          coord: tlInfo.coord,
          rate,
          tlCrossroad: tlInfo?.tl?.dai_jdt83?.crossroad ?? null,
        }
      : null;

  return { tl: tlInfo?.tl, crossroadBasicProps };
};

export default useCrossroadTLWrapper;
