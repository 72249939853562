import {
  IWeatherDataset,
  IWeatherLayerItem,
  IWeatherTimeModel,
} from '../../Map/IWeatherTimeModel';

import { WEATHER_STYLES } from './constants';
import { averageValue, WeatherPropertyNames } from './utils/averageValue';

import styles from './WeatherButton.module.scss';

export function WeatherTimeItem(props: {
  onClick: () => void;
  item: IWeatherTimeModel;
  currentWeatherTime: IWeatherTimeModel;
  currentWeatherLayer: IWeatherLayerItem;
  dark: boolean;
  datasets: IWeatherDataset[];
}) {
  const { datasets, item, currentWeatherTime, currentWeatherLayer } = props;
  const { time } = item;

  const average = averageValue(
    datasets,
    time,
    currentWeatherLayer.propertyName as WeatherPropertyNames
  );

  return (
    <div
      onClick={props.onClick}
      style={
        props.dark ? WEATHER_STYLES.timeIcon : WEATHER_STYLES.timeIconLight
      }
      className={
        item.time === currentWeatherTime.time
          ? styles.timeCardSelected
          : styles.timeCard
      }
      aria-hidden={true}
    >
      {
        <div
          className={
            item.time === currentWeatherTime.time
              ? styles.timeTitleSelected
              : styles.timeTitle
          }
        >
          {average.label}
          <div className={styles.unitsText}>{average.units}</div>
        </div>
      }

      <div
        className={
          item.time === currentWeatherTime.time
            ? styles.timeSubtitleSelected
            : styles.timeSubtitle
        }
      >
        {item.title || item.subtitle}
      </div>
    </div>
  );
}
