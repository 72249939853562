import { getAppPathHeaders } from '../../../helpers/getAppPathHeaders';
import http from '../../../http';
import tryCatchWrapper from '../../../tryCatchWrapper';
import { DT_STATISTIC } from '../constants/constants';
import { DtStateProps } from '../model/dtStatistics.model';

import useDtStateQuery from './query/useDtStateQuery';

const STATE = DT_STATISTIC + '/dt/state';

class DtStateApi {
  fetchDtStateByDeviceId = ({ id, appPath }: DtStateProps) =>
    tryCatchWrapper<boolean>(
      async () => {
        const res = await http.get(`${STATE}/${id}`, {
          headers: getAppPathHeaders(appPath),
        });

        return res.data?.ok;
      },
      {
        isBlockedNotify: true,
      }
    );

  useDtState = (props: DtStateProps) =>
    useDtStateQuery({
      ...props,
      fetchDtStateByDeviceId: this.fetchDtStateByDeviceId,
    });
}

const dtStateApi = new DtStateApi();

export default dtStateApi;
