import { useCallback, useEffect, useState } from 'react';
import type { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart';

import {
  DEFAULT_HEIGHT,
  IAreaX,
  IDomain,
  INCREASE_GRAPH_HEIGHT,
  INITIAL_AREA_X,
  INITIAL_DOMAIN,
  INITIAL_X_DOMAIN,
} from '../constants/constants';
import { getPeakHighestNumber } from '../helpers/getPeakHighestNumber';
import { CalcData } from '../models/chart.model';

interface IUseScaleGraph {
  data: CalcData[];
}

const useScaleGraph = ({ data }: IUseScaleGraph) => {
  const [areaX, setAreaX] = useState<IAreaX>(INITIAL_AREA_X);
  const [domain, setDomain] = useState<IDomain>(INITIAL_DOMAIN);

  const setInitialDomain = useCallback(
    (isClearOnlyAreaX = false) => {
      !isClearOnlyAreaX &&
        setDomain((prev) => {
          const y: [number, number] = [...prev.y];

          y.splice(0, 2, 0, getPeakHighestNumber(data) || DEFAULT_HEIGHT);

          return { x: INITIAL_X_DOMAIN, y };
        });

      setAreaX(INITIAL_AREA_X);
    },
    [data]
  );

  useEffect(() => {
    if (!data.length) return;

    setInitialDomain();
  }, [data, setInitialDomain]);

  const getAxisYDomain = (
    data: CalcData[],
    from: number,
    to: number,
    offset: number
  ) => {
    const [fromVal, toVal] = from > to ? [to, from] : [from, to];

    const refData = [...data].slice(fromVal, toVal + 1);

    return (
      ((getPeakHighestNumber(refData) * INCREASE_GRAPH_HEIGHT) | 0) + offset
    );
  };

  const zoom = () => {
    const { left, right } = areaX;

    if (left === right || right === 0 || !data) {
      setAreaX({ left: 0, right: 0 });

      return;
    }

    const isInverse = left > right;

    // xAxis domain
    if (isInverse) {
      setAreaX({ left: right, right: left });
    }

    // yAxis domain
    const top = getAxisYDomain(data, left, right, 1);

    const xDomain: [number, number] = isInverse ? [right, left] : [left, right];

    setDomain({ x: xDomain, y: [0, top] });
    setAreaX({ left: 0, right: 0 });
  };

  const handleMouseDown = (e: CategoricalChartState) => {
    if (!e) return;

    setAreaX((prev) => ({ ...prev, left: e.activeTooltipIndex ?? 0 }));
  };

  const handleMouseMove = (e: CategoricalChartState) => {
    if (!e.isTooltipActive) return setInitialDomain(true);

    areaX.left &&
      setAreaX((prev) => ({ ...prev, right: e.activeTooltipIndex ?? 0 }));
  };

  const handleZoomOut = () => {
    setInitialDomain();
  };

  return {
    domain,
    areaX,
    zoom,
    handleMouseDown,
    handleMouseMove,
    handleZoomOut,
  };
};

export default useScaleGraph;
