import { BREAK_POINTS } from '../../../../../constants/constants';
import { IPhasesValue } from '../PhaseHold.model';

export const INITIAL_PHASES_VALUE: IPhasesValue = {
  valueSelect: null,
  radioPhasesValue: 0,
};

export const BREAK_POINTS_PHASES: typeof BREAK_POINTS = {
  XL: 0,
  L: 0,
  M: 1280,
  S: 0,
};
