import { isNumber } from 'lodash';

import { DATE_FORMAT } from '../../../../../constants/constants';
import { getTimeWithTimeZone } from '../../../../TrafficLightDetailed/TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeWithTimeZone';

import { IData } from './getAverage/getAverage';
import { getDateRangeStr } from './getDateRangeStr';

export const getDatePeriod = (data: IData[]) => {
  const dateNow = getTimeWithTimeZone().dateFormattedJS;

  const defaultDateFrom = dateNow.subtract(-1, 'day').format(DATE_FORMAT);
  const defaultDateTo = dateNow.format(DATE_FORMAT);

  const [dateFromStr, dateToStr] = [data.at(0)?.dateFrom, data.at(-1)?.dateTo];

  if (isNumber(dateFromStr) || isNumber(dateToStr))
    return [defaultDateFrom, defaultDateTo];

  const datePeriod = getDateRangeStr(
    dateFromStr ?? defaultDateFrom,
    dateToStr ?? defaultDateTo
  ).normalDate;

  return datePeriod;
};
