export enum PanelType {
  Cartography = 'cartography',
  ModulesSubsystems = 'modulesSubsystems',
  Reports = 'reports',
  Constructor = 'constructor',
  Admins = 'admins',
  Profile = 'profile',
  VideoWall = 'videoWall',
  Testing = 'testing',
  CoordManagement = 'coordManagement',
}

export enum System {
  Lights = 'светофорные объекты',
  Detectors = 'детекторы транспорта',
  Cameras = 'камеры',
  SpecialTransport = 'специальная техника',
  PublicTransport = 'общественный транспорт',
  Meteo = 'метеостанции',
}

export enum Measurement {
  Traffic = 'дорожный затор',
}

export const Components = {
  Basemaps: 'Basemaps',
};

export enum Events {
  RTA = 'ДТП',
  ROAD_WORKS = 'дорожные работы',
  ACTIVITIES = 'мероприятия',
  TRAFFIC_RESTRICTIONS = 'ограничения движения',
}

export enum MapObject {
  tl = 'tl',
  cm = 'cm',
  dt = 'dt',
  mt = 'mt',
  uds = 'uds',
}

export enum MapObjectFull {
  trafficLights = 'trafficLights',
  cameras = 'cameras',
  detectors = 'detectors',
  greenTrafficLights = 'greenTrafficLights',
  meteo = 'meteo',
}

export enum ActualMapObj {
  tls = 'tls',
  cameras = 'cameras',
  detectors = 'detectors',
  meteo = 'meteo',
}

export enum ImagePostfix {
  Off = 'off',
  AllRed = 'ar',
  AllYellow = 'yf',
}

export enum ErrorText {
  SomethingWentWrong = 'Что-то пошло не так...',
  NoCrossroadsImg = 'Изображение перекрестка временно отсутствует',
}

export enum ChanelTypes {
  CommandsLogsNew = 'commands_logs_new',
  TlCurrentTacts = 'tlCurrentTacts',
  MkudTriggers = 'mkud_triggers',
}

export enum FeaturesTypes {
  FeatureOnCluster = 'selectclusterfeature',
  ClusterLine = 'selectclusterlink',
  Features = 'features',
  GISFeature = 'system',
}

export enum FeatureDeviceProps {
  Id = 'id',
  System = 'system',
  Mode = 'mode',
  DeviceStatus = 'deviceStatus',
  IsDisabled = 'isDisabled',
}

export enum CategoryLayerGIS {
  PublicTransport = 'category.transport',
  Social = 'category.social',
  Weather = 'category.weather',
  Raster = 'category.raster',
  Common = 'category.common',
  Traffic = 'category.traffic',
}

export enum FeaturePropertiesCategoryLayer {
  Direction = 'direction',
  Route = 'route',
  VehicleNumber = 'mobileName',
}

export enum RightPanelType {
  Constructor = 'constructor',
  CoordControl = 'coordControl',
  GroupDispatch = 'groupDispatch',
  InfoPanel = 'infoPanel',
  Cluster = 'cluster',
  IdentificationGis = 'identificationGis',
}

export enum PanelTypes {
  LeftPanel = 'leftPanel',
  InfoPanel = 'infoPanel',
}
