import { useState } from 'react';

import rootStore from '../../stores/rootStore/rootStore';

import handleSocketMessage from './helpers/handleSocketMessage';

// TODO: set type to all messages here
const useSocketUpdate = () => {
  const [lastUpdate, setLastUpdate] = useState(Date.now());
  const { mapDataStore } = rootStore;

  const addItemHandler = (message: any) => {
    const { lastUpdate, targetType, targetId, value } =
      handleSocketMessage(message);

    if (!targetType) return;
    mapDataStore.addItemWS(targetType, targetId, value);
    setLastUpdate(lastUpdate);
  };

  const deleteItemHandler = (message: any) => {
    const { lastUpdate, targetType, targetId } = handleSocketMessage(message);

    mapDataStore.deleteItemWS(targetType, targetId);
    setLastUpdate(lastUpdate);
  };

  const fieldChangedHandler = (message: any) => {
    const { lastUpdate, targetType, targetId, value, path } =
      handleSocketMessage(message);

    if (!targetType) return;
    mapDataStore.updateItemWS(targetType, targetId, path, value);
    setLastUpdate(lastUpdate);
  };

  const refreshMapObjectsData = () => mapDataStore.fetchDataByRegion();

  return {
    addItemHandler,
    deleteItemHandler,
    fieldChangedHandler,
    refreshMapObjectsData,
    lastUpdate,
  };
};

export default useSocketUpdate;
