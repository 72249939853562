import { SEPARATOR_KEY } from '../../../../components/Panel/ReportsPanel/ReportTree/helpers/helpersTree';
import { AdminTitleKeys } from '../../../../constants/adminConstants';

type SystemType =
  | AdminTitleKeys.Detector
  | AdminTitleKeys.TrafficLight
  | AdminTitleKeys.Video
  | AdminTitleKeys.Meteo;

export interface ParsedMenuKeyData {
  type:
    | AdminTitleKeys.EputsSystem
    | AdminTitleKeys.Region
    | AdminTitleKeys.System
    | SystemType;
  regionId: N<number>;
  subsystemId: N<number>;
  systemId: N<number>;
}

export const getParsedMenuKeyData = (key: string): ParsedMenuKeyData => {
  const result: ParsedMenuKeyData = {
    type: AdminTitleKeys.EputsSystem,
    regionId: null,
    subsystemId: null,
    systemId: null,
  };

  const isHasSharp = key.includes(SEPARATOR_KEY);

  if (!isHasSharp) return result;

  const elements = key.split(SEPARATOR_KEY);

  result.type = elements[0] as ParsedMenuKeyData['type'];

  if (elements.length === 2) {
    result.type = AdminTitleKeys.Region;
    result.regionId = Number(elements.at(-1));

    return result;
  }

  result.type = elements[0] as ParsedMenuKeyData['type'];

  const regionId = Number(elements[2]);

  result.regionId = regionId;

  if (result.type === AdminTitleKeys.System) {
    result.systemId = Number(elements[1]);

    return result;
  }

  result.subsystemId = Number(elements[1]);

  return result;
};
