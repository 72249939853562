import {
  lineString as turfLineString,
  point as turfPoint,
} from '@turf/helpers';
import { toMercator as turfToMercator } from '@turf/projection';
import { toMercator, toWgs84 } from '@turf/turf';
import DOMPurify from 'dompurify';
import { Feature } from 'ol';
import { Point } from 'ol/geom';

import {
  ERROR_GEOCODING_OPERATION,
  MAX_FEATURES_FROM_LAYER,
  SEARCH_TYPE_COORDINATES,
  SEARCH_TYPE_GEOCODER,
  SEARCH_TYPE_LAYER,
} from '../../components/MapBtns/SearchButton/constants';
import { IQueryFeaturesResult } from '../../components/MapBtns/SearchButton/search.model';
import { DEFAULT_NAME_FIELD_OBJECTID } from '../../constants/constants';
import { ILayer } from '../../stores/gisDataStore/gisDataStore.model';
import { TMap } from '../../stores/mapStore/mapStore.model';
import { CategoryLayerGIS } from '../../ts/enums/enums';
import {
  DEFAULT_COORDINATES_RESPONSE,
  sqlKeywords,
} from '../constants/geocode';
import { WKID_MERCATOR } from '../constants/srs';
import { ISearchItem } from '../models/search';
import { IGetEsriFeatures } from '../models/sources/sourceDefinitions';
import { queryFeatures } from '../sources/FeatureServerSource';
import { geocode } from '../tasks/geocode';

const searchSanitize = (text: string): string => {
  if (!text) {
    return '';
  }

  let outTextParts = text;

  for (let i = 0; i < sqlKeywords.length; i++) {
    const word = sqlKeywords[i];

    outTextParts = outTextParts.split(word).join(' ');
  }

  return DOMPurify.sanitize(outTextParts);
};

export const getMapCenterText = (map: TMap) => {
  if (!map) {
    return DEFAULT_COORDINATES_RESPONSE;
  }

  const center = map.getView().getCenter();

  if (!center) {
    return DEFAULT_COORDINATES_RESPONSE;
  }

  const point = toWgs84(turfPoint(center));

  const [x, y] = point.geometry.coordinates;

  return [y, x].map((value) => value.toFixed(1)).join(', ');
};

export const locationToGeometry = (lon: number, lat: number) => {
  const point = toMercator(turfPoint([lon, lat]));

  return new Point(point.geometry.coordinates);
};

export const geocoderSearch = async (
  map: TMap,
  text: string,
  regionExtent: number[] | undefined
): Promise<ISearchItem[]> => {
  const sanitizedText = searchSanitize(text);

  const results = await geocode(sanitizedText);

  return results
    .filter((item) => {
      const { code } = item.quality;

      return code !== ERROR_GEOCODING_OPERATION;
    })
    .filter((item) => {
      const { location } = item;

      const { lat, lon } = location;

      if (regionExtent) {
        const [xmin, ymin, xmax, ymax] = regionExtent;

        return lon >= xmin && lon <= xmax && lat >= ymin && lat <= ymax;
      }

      return true;
    })
    .map((item) => {
      const { address, location } = item;

      const { lat, lon } = location;

      const { coordinates } = turfToMercator(turfPoint([lon, lat])).geometry;

      const feature = new Feature({
        geometry: new Point(coordinates),
        id: Math.random(),
      });

      const result: ISearchItem = {
        title: address,
        type: SEARCH_TYPE_GEOCODER,
        feature,
      };

      return result;
    });
};

export const layersSearch = async (
  map: TMap,
  text: string,
  regionExtent: number[] | undefined,
  layersDefinitions: ILayer[]
): Promise<ISearchItem[]> => {
  if (!text || !map || !layersDefinitions) {
    return [];
  }

  const results: ISearchItem[] = [];

  const queries = [];

  const sanitizedText = searchSanitize(text);

  for (let i = 0; i < layersDefinitions.length; i++) {
    const layer = layersDefinitions[i];

    const { searchFields, tooltipDefinition } = layer;

    if (layer?.category?.category !== CategoryLayerGIS.Common) {
      continue;
    }

    if (!searchFields || searchFields.length === 0) {
      continue;
    }

    const where = searchFields
      .map((field) => {
        return `${field} LIKE '%${sanitizedText}%'`;
      })
      .join(` OR `);

    const params: IGetEsriFeatures = {
      bbox: '',
      url: layer.url,
      where,
      num: MAX_FEATURES_FROM_LAYER,
    };

    if (regionExtent) {
      const [xmin, ymin, xmax, ymax] = regionExtent;
      const radius = turfToMercator(
        turfLineString([
          [xmin, ymin],
          [xmax, ymax],
        ])
      );

      const { coordinates } = radius.geometry;

      const [p1, p2] = coordinates;

      params.bbox = `{"xmin":${p1[0]},"ymin":${p1[1]},"xmax":${p2[0]},"ymax":${p2[1]},"spatialReference":{"wkid":${WKID_MERCATOR}}}`;
    }

    const query = new Promise((resolve) => {
      queryFeatures(params)
        .then((data) => {
          const result: IQueryFeaturesResult = {
            featuresSet: data,
            tooltipDefinition,
          };

          resolve(result);
        })
        .catch(() => {
          console.log(`err`);

          const result: IQueryFeaturesResult = {
            featuresSet: {
              features: [],
              objectIdFieldName: DEFAULT_NAME_FIELD_OBJECTID,
            },
            tooltipDefinition: {
              fields: [],
              template: '',
            },
          };

          resolve(result);
        });
    });

    queries.push(query);
  }

  const responses = await Promise.all(queries);

  for (let i = 0; i < responses.length; i++) {
    const data = responses[i] as IQueryFeaturesResult;

    const { featuresSet, tooltipDefinition } = data;

    const { features } = featuresSet;

    const items: ISearchItem[] = features
      .filter((feature: Feature) => {
        const geometry = feature.getGeometry();

        return !!geometry;
      })
      .map((feature: Feature) => {
        const properties = feature.getProperties();

        const { fields } = tooltipDefinition;

        const title =
          fields
            ?.map((field: string) => properties[field])
            .filter((value) => !!value)
            .join(', ') || properties[DEFAULT_NAME_FIELD_OBJECTID];

        const result: ISearchItem = {
          title,
          type: SEARCH_TYPE_LAYER,
          feature,
        };

        return result;
      });

    items.forEach((item) => results.push(item));
  }

  return results;
};

export const coordinatesSearch = async (
  map: TMap,
  text: string,
  regionExtent: number[] | undefined
): Promise<ISearchItem[]> => {
  if (!text) {
    return [];
  }

  const sanitizedText = searchSanitize(text);

  const parts = sanitizedText
    .split(' ')
    .join('')
    .split(',')
    .map((item) => Number(item));

  if (parts.length !== 2) {
    return [];
  }

  const results = [];

  const [lat, lon] = parts;

  if (!(Number.isFinite(lat) && Number.isFinite(lon))) {
    return [];
  }

  if (regionExtent) {
    const [xmin, ymin, xmax, ymax] = regionExtent;

    if (lon < xmin || lon > xmax || lat < ymin || lat > ymax) {
      return [];
    }
  }

  const { coordinates } = turfToMercator(turfPoint([lon, lat])).geometry;

  const feature = new Feature({
    geometry: new Point(coordinates),
    id: Math.random(),
  });

  const result: ISearchItem = {
    title: `Координата (широта: ${lat}, долгота: ${lon})`,
    type: SEARCH_TYPE_COORDINATES,
    feature,
  };

  const latLonText = [lon, lat].join(',');
  const geocodeResults = await geocode(latLonText, 1);

  if (geocodeResults.length > 0) {
    const [geocodeResult] = geocodeResults;

    const { address, location } = geocodeResult;
    const { code } = geocodeResult.quality;

    if (code !== ERROR_GEOCODING_OPERATION) {
      result.title += `. Ближайший адрес: ${address} (широта: ${location.lat}, долгота: ${location.lon})`;
    }
  }

  results.push(result);

  return results;
};
