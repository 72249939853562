/* eslint-disable camelcase */
import { z } from 'zod';

import {
  CONTROL_ACCESS_DEVICES,
  DEVICES_TYPES,
} from '../../constants/constants';
import { DEVICES_STATUS } from '../../constants/devicesConstants';
import {
  MessageType,
  getZodMessageCustom,
} from '../helpers/getZodMessageCustom';
import { NNInt, latitude, longitude } from '../zod/utils.zod';

export const controlAccess = z.nativeEnum(CONTROL_ACCESS_DEVICES).nullable();
const deviceIds = NNInt.array().nullable();

export const DeviceInfoSchema = z.object({
  caption: z.string(),
  controlAccess: controlAccess,
  deviceId: NNInt,
  deviceTypeId: NNInt.refine(
    (val) => DEVICES_TYPES.includes(val),
    getZodMessageCustom({
      nameParent: 'deviceInfo',
      nameValue: 'deviceTypeId',
      rangeArr: DEVICES_TYPES,
      messageType: MessageType.OnArray,
    })
  ),
  dsc: z.string(),
  externalDeviceId: NNInt.nullable(),
  latitude,
  longitude,
  ogcFid1: z.number().nonnegative().nullable(),
  regionId: NNInt.nullable(),
  uid: z.string().nullable(),
  deviceStatusId: NNInt.refine(
    (val) => DEVICES_STATUS.includes(val),
    getZodMessageCustom({
      nameParent: 'deviceInfo',
      nameValue: 'deviceStatusId',
      rangeArr: DEVICES_STATUS,
      messageType: MessageType.NotAccessNull,
    })
  ),
  last_insert_time: z.string(),
  pointUdsUid: z.string().uuid().nullable(),
  pointUdsName: z.string().nullable(),
});

export const LinkedDeviceIdsSchema = z.object({
  cameras: deviceIds,
  detectors: deviceIds,
  greenTrafficLights: deviceIds,
  trafficLights: NNInt.nullable(),
  meteo: deviceIds,
});

const EputsAdapterSchema = z.object({
  scSystemId: NNInt,
  sourceType: NNInt,
  scSystemTypeId: NNInt,
});

export const HeaderSchema = z.object({
  code: z.string(),
  type: z.union([
    z.literal('trafficlight'),
    z.literal('detector'),
    z.literal('cameras'),
    z.literal('meteo'),
  ]),
  ver: z.string(),
});

export const BasicMapDataSchema = z.object({
  deviceInfo: DeviceInfoSchema,
  id: z.number().int(),
  eputsAdapter: EputsAdapterSchema,
  addinfo: z.unknown(),
  linkedDeviceIds: LinkedDeviceIdsSchema.nullable().optional(),
  header: HeaderSchema,
  regionId: NNInt.nullable(),
});

export const CoordinatesSchema = z.tuple([z.number(), z.number()]);

export const OtherSchema = z.string().nullable().optional();
