import { isNumber } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC, memo, useMemo } from 'react';

import rootStore from '../../../../stores/rootStore/rootStore';
import Accordion from '../../../ui-kit/Accordion/Accordion';

import { SystemsTabProps } from './AllSystemsTab.model';
import { getFilteredSystems } from './helpers';

const AllSystemsTab: FC<SystemsTabProps> = ({
  searchedValue,
  searchedArea,
}) => {
  const {
    setIsMarkers,
    handlePopupFromTab,
    infoData,
    isDetailedForm,
    clickedCartographyObj,
  } = rootStore.uiStore;
  const { systems, systemsInfo } = rootStore.mapDataStore;

  const filteredSystems = useMemo(
    () =>
      getFilteredSystems({
        searchedArea,
        systems,
        systemsInfo,
        searchedValue,
        isDetailedForm,
      }),
    [searchedArea, systems, systemsInfo, searchedValue, isDetailedForm]
  );

  return (
    <div>
      {filteredSystems.map(({ title, data, isMarkers }) => {
        const isItemOnAccordion =
          (data.some(
            ({ id }) => infoData?.id !== undefined && id === infoData?.id
          ) &&
            !!searchedValue) ||
          !!searchedValue;
        const isOpen = isItemOnAccordion && isMarkers && !!data.length;

        const activeId =
          infoData && isNumber(infoData?.id) ? infoData.id : undefined;

        const idSelect = isDetailedForm ? activeId : clickedCartographyObj?.id;

        return (
          <Accordion
            activeId={activeId ?? idSelect}
            data={data}
            isDisabled={isMarkers}
            key={title}
            onClick={(id: number) => {
              handlePopupFromTab(id, title);
            }}
            setIsMarkers={setIsMarkers}
            title={title}
            isOpen={isOpen}
            isNeedScroll={true}
            isEmpty={!data.length}
          />
        );
      })}
    </div>
  );
};

export default memo(observer(AllSystemsTab));
