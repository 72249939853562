import { ManagementModules } from '../../../../stores/uiStore/uiStore.model';
import { PanelType } from '../../../../ts/enums/enums';
import { PANEL_TITLES } from '../../../Sidebar/MainBlock/constants/constants';

const { Profile, CoordManagement } = PanelType;

export const getPanelTitle = (
  panelType: N<PanelType>,
  ManagementModulesType: N<ManagementModules>
) => {
  if (!panelType) return null;

  switch (panelType) {
    case Profile:
      return PANEL_TITLES.profile;
    case CoordManagement:
      return ManagementModulesType
        ? PANEL_TITLES[ManagementModulesType]
        : PANEL_TITLES[panelType];
    default:
      return PANEL_TITLES[panelType] ?? 'Свернуть панель';
  }
};
