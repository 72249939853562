/* eslint-disable camelcase */

import { isUndefined } from 'lodash';
import { FaRoad } from 'react-icons/fa';

import {
  CONDITION_ROADWAY,
  METEO_INFO,
  MeteoDataInfo,
  MeteoInfoKeys,
  MeteoProcessType,
  PRECIPITATION,
  ROUND_CONCENTRATE,
} from '../../../../../../constants/meteo/meteo';
import { neverTypeCheck } from '../../../../../../helpers/neverTypeCheck';
import {
  MeteoKeys,
  TMeteoData,
  TPrecipitationType,
} from '../../../../../../ts/models/MapObjects/meteo.model';

const { Dotted, Round, Nothing, RoundConcentration } = MeteoProcessType;

const EMPTY_DATA = '(Нет данных)';

export const EMPTY = '-';

interface IIMeteoInfoData {
  id: MeteoInfoKeys;
  icon: JSX.Element;
  value: string | number;
  tooltip: string;
}
type TAdditionKeys = 'weather' | 'roadState';
interface IIMeteoAddition extends Omit<IIMeteoInfoData, 'id'> {
  id: TAdditionKeys;
}
type TMeteoInfoData = Partial<Record<MeteoInfoKeys, IIMeteoInfoData>>;
export type TValueMeteo = U<IIMeteoInfoData | IIMeteoAddition>;

export type MeteoInfoReturn = N<
  Partial<
    Record<MeteoInfoKeys | TAdditionKeys, IIMeteoInfoData | IIMeteoAddition>
  >
>;

export type MeteoInfoNonNullable = NonNullable<MeteoInfoReturn>;

const getRoundConcentrate = (value: number) =>
  value !== 0 ? value.toFixed(ROUND_CONCENTRATE) : 0;

const getMeteoFormatData = (processType: MeteoProcessType, value: number) => {
  switch (processType) {
    case Round:
      return Math.round(value);
    case Dotted:
      return Number.isInteger(value) ? value : value.toFixed(1);
    case RoundConcentration:
      return getRoundConcentrate(value);
    case Nothing:
      return value;
    default:
      neverTypeCheck(processType);

      return value;
  }
};

const getCapitalizeText = (firstWord: string, secondWord: string) => {
  return `${firstWord.at(0)?.toUpperCase() ?? ''}${firstWord.slice(
    1
  )} ${secondWord.toLowerCase()}`;
};

const getMeteoWeather = (
  precipitationType: N<TPrecipitationType>,
  intensity: N<number>
) => {
  const weatherInfo = PRECIPITATION.find(
    ({ CODE }) => CODE === precipitationType
  );

  const defaultValue = weatherInfo ?? { ICON: <></>, TEXT: '' };

  if (intensity === null || !weatherInfo) return defaultValue;

  const option = weatherInfo.OPTIONS?.find(({ INTENSITY: { MIN, MAX } }) => {
    if (!MIN && MAX) return intensity <= MAX;

    if (!MAX && MIN) return intensity > MIN;

    return false;
  });

  if (!option) return defaultValue;

  const text = getCapitalizeText(option.LABEL, defaultValue.TEXT);

  return { ICON: defaultValue.ICON, TEXT: text };
};

export const getMeteoInfo = (meteo: U<TMeteoData>): MeteoInfoReturn => {
  if (!meteo) return null;

  const {
    device_uid,
    timestamp,
    precipitation_type,
    road_state,
    muec_add,
    ...rest
  } = meteo;

  const data = Object.entries({ ...rest, ...muec_add }) as [
    MeteoKeys,
    N<number>
  ][];

  const meteoData = data.reduce((acc: TMeteoInfoData, [keyItem, valueItem]) => {
    const directoryItem: U<MeteoDataInfo> = METEO_INFO[keyItem];

    if (!directoryItem) return acc;

    const resDefault = {
      id: directoryItem.key,
      icon: directoryItem.icon,
      value: EMPTY,
      tooltip: directoryItem.tooltip,
    };

    if (valueItem !== null) {
      const value = getMeteoFormatData(directoryItem.processType, valueItem);
      const valueWithType = directoryItem.valueType
        ? `${value} ${directoryItem.valueType}`
        : value;

      acc[directoryItem.key] = { ...resDefault, value: valueWithType };
    } else {
      acc[directoryItem.key] = {
        ...resDefault,
        tooltip: `${resDefault.tooltip} ${EMPTY_DATA}`,
      };
    }

    return acc;
  }, {} as TMeteoInfoData);

  const weatherInfo =
    !isUndefined(precipitation_type) &&
    !isUndefined(rest.precipitation_intensity)
      ? getMeteoWeather(precipitation_type, rest.precipitation_intensity)
      : null;

  const roadState = CONDITION_ROADWAY.find(
    ({ CODE }) => CODE === road_state
  ) ?? { TEXT: EMPTY };

  if (!Object.keys(meteoData).length && !weatherInfo && isUndefined(road_state))
    return null;

  return {
    ...meteoData,
    weather: {
      id: 'weather',
      icon: weatherInfo?.ICON ?? <></>,
      value: weatherInfo?.TEXT ?? EMPTY_DATA,
      tooltip: `Тип осадков ${precipitation_type === null ? EMPTY_DATA : ''}`,
    },
    roadState: {
      id: 'roadState',
      icon: <FaRoad />,
      value: roadState.TEXT,
      tooltip: `Состояние дорожного полотна ${
        road_state === null ? EMPTY_DATA : ''
      }`,
    },
  };
};
