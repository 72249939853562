import { useEffect, RefObject } from 'react';

import { MapOverlaysKeys } from '../../../stores/mapStore/mapStore.model';
import rootStore from '../../../stores/rootStore/rootStore';
import createOverlay from '../helpers/createOverlay';

const useCreateOverlay = (
  key: MapOverlaysKeys,
  ref: RefObject<HTMLDivElement>,
  autoPan?: boolean
) => {
  const { setMapValue } = rootStore.mapStore;

  useEffect(() => {
    setMapValue(key, createOverlay({ element: ref, autoPan }));
  }, [ref, setMapValue, key, autoPan]);
};

export default useCreateOverlay;
