import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { MdDeleteForever } from 'react-icons/md';

import rootStore from '../../../../../stores/rootStore/rootStore';
import Confirm from '../../../../ui-kit/Confirm/Confirm';

import styles from './InputDeleteBtn.module.scss';

interface IInputDeleteBtn {
  idx: number;
}

const InputDeleteBtn: FC<IInputDeleteBtn> = ({ idx }) => {
  const { deleteFromArray } = rootStore.constructorStore;

  return (
    <Confirm
      onConfirm={() => deleteFromArray('phases', idx)}
      placement="left"
      title="Безвозвратно удалить раздел?"
    >
      <Button
        icon={<MdDeleteForever />}
        className={styles.deleteBtn}
        disabled
      />
    </Confirm>
  );
};

export default observer(InputDeleteBtn);
