import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { IoIosWarning } from 'react-icons/io';

import ModalBody from '../../../PhaseHold/ModalBody/ModalBody';
import ModalConfirm from '../../../PhaseHold/ModalConfirm/ModalConfirm';
import styles from '../../../styles/basicStyles.module.scss';
import { UseConfirmCurrentPlanProps } from '../../model/currentPlan.model';

import useConfirmCurrentPlan from './useConfirmCurrentPlan';

const Confirm: FC<UseConfirmCurrentPlanProps> = (props) => {
  const { modalBodyProps, modalConfirmProps } = useConfirmCurrentPlan(props);

  return (
    <ModalConfirm
      {...modalConfirmProps}
      icon={<IoIosWarning className={styles.iconInfo} />}
    >
      <ModalBody {...modalBodyProps} />
    </ModalConfirm>
  );
};

export default observer(Confirm);
