import rootStore from '../../../../../../stores/rootStore/rootStore';
import { getModuleAccessConfInfo } from '../../../GroupCoordCard/helpers/getModuleAccessConfInfo';
import { MODULES_CARDS } from '../../constants/constants';

const getOnlyOneModule = () => {
  const { hasAccess } = rootStore.userDataStore;
  const { regionData } = rootStore.uiStore;

  const modules = MODULES_CARDS.filter(({ code, type }) => {
    const isAccess = hasAccess(code);

    const { isVisible } = getModuleAccessConfInfo(
      regionData?.licenceOptions?.data,
      type
    );

    return isAccess && isVisible;
  });

  const isOnceModule = modules.length === 1;

  const module = modules.at(0);

  return { isOnceModule, module };
};

export default getOnlyOneModule;
