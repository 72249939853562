import { DeviceTypeId } from '../../../../ts/models/mapObject.model';
import { DevicesList } from '../PointUdsOverlays.model';

export const POINT_UDS_DISTANCE = 0.002; // it's about 200 meters

export const DEVICES_EMPTY: DevicesList = {
  tlsList: [],
  cmsList: [],
  dtsList: [],
  mtsList: [],
};

export const DEVICE_TYPE: Record<DeviceTypeId, string> = {
  2: 'trafficLight',
  3: 'camera',
  4: 'detector',
  6: 'meteo',
};

export const BROKEN_LINKS_NOT_FOUND =
  'Devices with broken links are not found.';
