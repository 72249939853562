import { getObjectEntries } from '../../../../helpers/getObjectEntries';

export const BREAK_POINT = {
  XXS: [0, 110],
  XS: [111, 250],
  S: [251, 289],
  M: [290, 320],
  L: [321],
} as const;

const BREAK_POINT_VALUES = getObjectEntries(BREAK_POINT);

export const getBreakpoint = (width: number) => {
  return (
    BREAK_POINT_VALUES.find(([key, listBr]) => {
      const [min, max] = listBr;

      if (!max) return true;

      return width >= min && width <= max;
    })?.[0] ?? null
  );
};
