import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';

import parseTLMode from '../../../helpers/parseTLMode';
import rootStore from '../../../stores/rootStore/rootStore';

import PhaseCircleEmpty from './PhaseCircleEmpty/PhaseCircleEmpty';
import PhaseCircleMain from './PhaseCircleMain/PhaseCircleMain';
import { PhaseCircleOverlayProps } from './PhaseCircleOverlay.model';

import styles from './PhaseCircleOverlay.module.scss';

const PhaseCircleOverlay: FC<PhaseCircleOverlayProps> = ({ selectedTL }) => {
  const { pointsUds } = rootStore.mapDataStore;

  const {
    tlStatus: { mode },
    deviceInfo: { pointUdsUid },
  } = selectedTL;
  const { color, isEmpty } = parseTLMode(mode);

  const pointUds = useMemo(
    () => pointsUds.find(({ deviceInfo }) => deviceInfo.uid === pointUdsUid),
    [pointUdsUid, pointsUds]
  );

  const circleProps = { selectedTL, color, pointUds };

  return (
    <div className={styles.popupContainer}>
      {isEmpty ? (
        <PhaseCircleEmpty {...circleProps} />
      ) : (
        <PhaseCircleMain {...circleProps} />
      )}
    </div>
  );
};

export default observer(PhaseCircleOverlay);
