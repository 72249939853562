export const columnsChDir = [
  {
    title: 'Направление',
    dataIndex: 'direction',
    key: 'direction',
  },
  {
    title: 'Каналы',
    dataIndex: 'channels',
    key: 'channels',
  },
  {
    title: 'Начало',
    dataIndex: 'lenBegin',
    key: 'lenBegin',
  },
  {
    title: 'Конец',
    dataIndex: 'lenEnd',
    key: 'lenEnd',
  },
  {
    title: 'Миганий',
    dataIndex: 'flashCount',
    key: 'flashCount',
  },
];
