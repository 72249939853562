import { FC } from 'react';

import { getTLModeName } from '../../../../../helpers/statusHelpers';
import { ImagePostfix } from '../../../../../ts/enums/enums';
import { TLDetailsImage, TlMode } from '../../../../../ts/models/tl.model';
import Img from '../../../../ui-kit/Img/Img';
import getImg from '../../helpers/getImg';

import styles from './NotPhaseCard.module.scss';

const TITLE = 'Включен режим:';

interface INotPhaseCard {
  mode: TlMode;
  images: N<TLDetailsImage[]>;
}

const NotPhaseCard: FC<INotPhaseCard> = ({ mode, images }) => {
  const name = getTLModeName(mode);

  return (
    <div className={styles.wrapper}>
      <h3>
        {TITLE}
        {name}
      </h3>

      <div className={styles.imgWrapper}>
        <Img
          src={getImg(ImagePostfix.Off, mode, images)}
          alt="Схема"
          className={styles.img}
        />
      </div>
    </div>
  );
};

export default NotPhaseCard;
