import { TableData } from '../../../../ts/models/table.model';
import { getAppPathHeaders } from '../../../helpers/getAppPathHeaders';
import http from '../../../http';
import tryCatchWrapper from '../../../tryCatchWrapper';
import { SYSTEMS } from '../../constants/constants';

const DEVICES = '/devices/';

interface getExternalSystemDevicesListParams {
  systemId: number;
  subsystemId: U<number>;
  regionId: number;
  deviceId?: U<number>;
  appPath: string;
}

const devices = {
  getExistSystemDevicesList: (
    regions: number[],
    subsystems: number[],
    appPath: string
  ) =>
    tryCatchWrapper(async () => {
      const res = await http.post(
        SYSTEMS + DEVICES,
        {
          regions,
          subsystems,
        },
        {
          headers: getAppPathHeaders(appPath),
        }
      );

      const data: TableData = res.data;

      return data;
    }),
  getExternalSystemDevicesList: ({
    deviceId,
    systemId,
    subsystemId,
    regionId,
    appPath,
  }: getExternalSystemDevicesListParams) =>
    tryCatchWrapper(async () => {
      const res = await http.get(`${SYSTEMS}/${systemId}/devices`, {
        params: {
          deviceId,
          subsystemId,
          regionId,
        },
        headers: getAppPathHeaders(appPath),
      });

      const data: TableData = res.data;

      return data;
    }),
  add: (id: string, deviceType: string, body: any, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.post(
        `${SYSTEMS}/${id}${DEVICES + deviceType}`,
        body,
        {
          headers: getAppPathHeaders(appPath),
        }
      );

      return res;
    }),
  updateDevice: (
    systemId: string,
    deviceType: string,
    deviceId: number,
    body: { reasonDsc: string },
    appPath: string
  ) =>
    tryCatchWrapper(async () => {
      const res = await http.put(
        `${SYSTEMS}/${systemId}${DEVICES + deviceType}/${deviceId}`,
        body,
        {
          headers: getAppPathHeaders(appPath),
        }
      );

      return res;
    }),
  delete: (
    systemId: string,
    deviceType: string,
    deviceId: number,
    data: { reasonDsc: string },
    appPath: string
  ) =>
    tryCatchWrapper(async () => {
      const res = await http.delete(
        `${SYSTEMS}/${systemId}${DEVICES + deviceType}/${deviceId}`,
        {
          data,
          headers: getAppPathHeaders(appPath),
        }
      );

      return res;
    }),
};

export default devices;
