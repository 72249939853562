import { Table } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { read, utils } from 'xlsx';

import rootStore from '../../../../../stores/rootStore/rootStore';

import styles from './ExelViewer.module.scss';

const FILE_READ_ERROR_MESSAGE_PASSWORD_PROTECTED = 'File is password-protected';

const ExelViewer = () => {
  const [file, setFile] = useState(null);
  const { viewFile } = rootStore.documentsStore;
  const [rows, setRows] = useState<any>([]);
  const [columns, setColumns] = useState<any>([]);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    setFile(null);
    if (viewFile) {
      viewFile.file.arrayBuffer().then((res: any) => setFile(res));
    }
  }, [viewFile]);

  useEffect(() => {
    setRows([]);
    setColumns([]);
    setError('');

    if (!file) return;

    try {
      const wb = read(file);
      const ws = wb.Sheets[wb.SheetNames[0]];
      const data = utils.sheet_to_json<any>(ws);

      if (!data.length) return;

      setRows(
        data.map((item, i) => {
          const newItem = {
            ...item,
            key: `${i}`,
          };

          return newItem;
        })
      );
      setColumns(
        Object.keys(data[0]).map((item) => {
          const newItem = {
            title: item,
            dataIndex: item,
            key: item,
            ellipsis: {
              showTitle: true,
            },
          };

          return newItem;
        })
      );
    } catch (e: any) {
      const mes = e?.message || 'Непредвиденная ошибка';

      setError(mes);
    }
  }, [file]);

  const getErrorMessage = () => {
    if (error === FILE_READ_ERROR_MESSAGE_PASSWORD_PROTECTED) {
      return 'Документ защищён паролем';
    }

    return error;
  };

  if (error)
    return (
      <div className={styles.wrapper}>
        <div className={styles.errorBox}>
          <h2 className={styles.title}>Ошибка при чтении документа</h2>
          <p className={styles.text}>Описание: {getErrorMessage()}</p>
          <p className={styles.text}>Вы по прежнему можете скачать документ</p>
        </div>
      </div>
    );

  return (
    <div className={styles.wrapper}>
      <Table columns={columns} dataSource={rows} size="small" />
    </div>
  );
};

export default observer(ExelViewer);
