import classNames from 'classnames';
import { FC } from 'react';
import { MdTimeline } from 'react-icons/md';
import type { Props } from 'recharts/types/component/Legend';

import styles from './CustomLegend.module.scss';

enum HandleType {
  Leave = 'leave',
  Enter = 'enter',
}

interface CustomLegendProps extends Props {
  hoverLine: string;
  setHoverLine: SetState<string>;
}

const CustomLegend: FC<CustomLegendProps> = ({
  payload,
  hoverLine,
  setHoverLine,
}) => {
  return (
    <div className={styles.container}>
      {payload?.map(({ value, color }) => {
        const handleHover = (eventType: HandleType) =>
          setHoverLine(eventType === HandleType.Enter ? value : '');

        const isOpacity = hoverLine === value || !hoverLine;

        const spanStyle = classNames({
          [styles.title]: true,
          [styles.hover]: !isOpacity,
        });

        return (
          <span
            key={value}
            className={spanStyle}
            onMouseEnter={() => handleHover(HandleType.Enter)}
            onMouseLeave={() => handleHover(HandleType.Leave)}
          >
            <MdTimeline color={color} />
            <p style={{ color }}>{value}</p>
          </span>
        );
      })}
    </div>
  );
};

export default CustomLegend;
