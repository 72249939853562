import { FC, RefObject, useEffect } from 'react';

import { StreamType } from '../../../api/mapObjects/cameras/cameras.zod';

type PointerEvents =
  | 'auto'
  | 'visiblePainted'
  | 'visibleFill'
  | 'visibleStroke'
  | 'visible'
  | 'painted'
  | 'fill'
  | 'stroke'
  | 'all'
  | 'none';

interface LoadPropertiesOptions {
  onLoad: () => void;
  onError: () => void;
}

const isFrameRef = (
  cameraRef: RefObject<HTMLElement>,
  streamType: StreamType
): cameraRef is RefObject<HTMLIFrameElement> =>
  streamType === StreamType.Iframe;

interface ICamera {
  src: string;
  pointerEvents?: PointerEvents;
  cameraRef: RefObject<HTMLElement>;
  loadProperties: LoadPropertiesOptions;
  streamType: StreamType;
}
const Camera: FC<ICamera> = ({
  src,
  pointerEvents = 'all',
  cameraRef,
  loadProperties,
  streamType,
}) => {
  const { onError, onLoad } = loadProperties;

  //need to catch errors on iframe
  useEffect(() => {
    const el = document.createElement('script');

    el.onerror = onError;

    el.src = src + new Date().getTime();
    document.body.appendChild(el);

    return () => {
      document.body.removeChild(el);
    };
  }, [onError, src]);

  if (!isFrameRef(cameraRef, streamType)) return null;

  return (
    <iframe
      ref={cameraRef}
      loading="lazy"
      allowFullScreen
      title="Camera"
      data-testid="iframe"
      onError={onError}
      onLoad={onLoad}
      width="100%"
      height="100%"
      src={src}
      style={{ pointerEvents }}
    />
  );
};

export default Camera;
