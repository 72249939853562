import { Path } from '../../../../api/api.model';
import plannedCommandsApi from '../../../../api/mapObjects/trafficLights/commands/plannedCommands';
import { getDate } from '../../TlManagement/helpers/getDate';

interface IGetPlannedCommandsArray {
  id: number;
  dateIndexFrom?: number;
  dateIndexTo?: number;
  selectedDate?: string[];
  appPath: Path;
  isBlockedNotify?: boolean;
  isNeedValidate?: boolean;
}

export const getPlannedCommandsArray = async ({
  id,
  dateIndexFrom = -1,
  dateIndexTo = 1,
  selectedDate,
  appPath,
  isBlockedNotify,
  isNeedValidate = true,
}: IGetPlannedCommandsArray) => {
  const dateFrom = getDate(dateIndexFrom);
  const dateTo = getDate(dateIndexTo);

  const selectedDateDefault: string[] = [dateFrom, dateTo];

  const select = !selectedDate?.length ? selectedDateDefault : selectedDate;

  const res = await plannedCommandsApi.getPlannedCommands({
    id,
    selectedDate: select,
    appPath,
    isBlockedNotify,
    isNeedValidate,
  });

  return res;
};
