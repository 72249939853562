import { useEffect } from 'react';

import rootStore from '../../../../stores/rootStore/rootStore';
import { TLDetailedCode } from '../../../../ts/enums/userData';
import logBrokenLinks from '../helpers/logBrokenLinks';

const useDeviceLinksChecking = () => {
  const { isVideoDtLinksLog } = rootStore.uiStore;
  const { detectors, cameras } = rootStore.mapDataStore;
  const { hasAccess } = rootStore.userDataStore;

  useEffect(() => {
    if (
      !hasAccess(TLDetailedCode.DevelopTools) ||
      !isVideoDtLinksLog ||
      !cameras.length ||
      !detectors.length
    )
      return;

    logBrokenLinks(cameras, detectors);
  }, [isVideoDtLinksLog, cameras, detectors, hasAccess]);
};

export default useDeviceLinksChecking;
