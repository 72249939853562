import renderersApi from '../../api/integration/gis/renderers/index';
import { IRenderer } from '../../ts/models/gis/renderer.model';

const renderersService = {
  async getList(appPath: string, regionId: number) {
    const defaultData: IRenderer[] = [];
    const data = await renderersApi.getList(appPath, regionId);

    const hasData = Boolean(data?.length);

    if (hasData) {
      return { data: data || defaultData, hasIntegrationGIS: true };
    }

    return { data: [] || defaultData, hasIntegrationGIS: false };
  },
};

export default renderersService;
