import { Feature } from 'ol';
import EsriJSON from 'ol/format/EsriJSON';
import { Geometry } from 'ol/geom';
import OlVectorSource from 'ol/source/Vector';
import { Socket } from 'socket.io-client';

import { DEFAULT_NAME_FIELD_OBJECTID } from '../../../constants/constants';
import { TMap } from '../../../stores/mapStore/mapStore.model';
import {
  ICheckLayerVisibility,
  ICreateSource,
  IFeatureSet,
} from '../../models/sources/sourceDefinitions';

const isLayerVisible = (params: ICheckLayerVisibility) => {
  const { map, id } = params;

  if (!map) {
    return;
  }

  const layer = map
    .getLayers()
    .getArray()
    .find((item) => item.get('id') === id);

  if (layer) {
    return layer.getVisible();
  }

  return false;
};

const readFeatures = (data: IFeatureSet) => {
  const format = new EsriJSON();

  return format.readFeatures(data, {});
};

const getDicts = (
  sourceFeatures: Feature<Geometry>[],
  objectIdFieldName: string,
  features: any[]
) => {
  const serviceDict = new Set();
  const sourceDict = new Set();

  for (let i = 0; i < sourceFeatures.length; i += 1) {
    const key = sourceFeatures[i].get(
      objectIdFieldName || DEFAULT_NAME_FIELD_OBJECTID
    );

    sourceDict.add(key);
  }

  for (let i = 0; i < features.length; i += 1) {
    const key = features[i].get(
      objectIdFieldName || DEFAULT_NAME_FIELD_OBJECTID
    );

    serviceDict.add(key);
  }

  return { sourceDict, serviceDict };
};

const addFeatures = (source: OlVectorSource, featureSet: IFeatureSet) => {
  const { features, objectIdFieldName } = featureSet;

  const sourceFeatures = source.getFeatures();

  const { sourceDict } = getDicts(sourceFeatures, objectIdFieldName, features);

  for (let i = 0; i < features.length; i++) {
    const feature = features[i];

    const id = feature.get(objectIdFieldName || DEFAULT_NAME_FIELD_OBJECTID);

    !sourceDict.has(id) && source.addFeature(feature);
  }
};

const updateFeatures = (source: OlVectorSource, featureSet: IFeatureSet) => {
  const { features, objectIdFieldName } = featureSet;

  const sourceFeatures = source.getFeatures();

  const { sourceDict } = getDicts(sourceFeatures, objectIdFieldName, features);

  for (let i = 0; i < features.length; i++) {
    const feature = features[i];

    const id = feature.get(objectIdFieldName || DEFAULT_NAME_FIELD_OBJECTID);

    if (sourceDict.has(id)) {
      const updateFeature = sourceFeatures.find(
        (feature) =>
          feature.get(objectIdFieldName || DEFAULT_NAME_FIELD_OBJECTID) === id
      );

      if (updateFeature) {
        updateFeature.setGeometry(feature.getGeometry());
        updateFeature.setProperties(feature.getProperties());
      }
    }
  }
};

const deleteFeatures = (source: OlVectorSource, featureSet: IFeatureSet) => {
  const { features, objectIdFieldName } = featureSet;

  const sourceFeatures = source.getFeatures();

  const { sourceDict } = getDicts(sourceFeatures, objectIdFieldName, features);

  for (let i = 0; i < features.length; i++) {
    const feature = features[i];

    const id = feature.get(objectIdFieldName || DEFAULT_NAME_FIELD_OBJECTID);

    if (sourceDict.has(id)) {
      const deleteFeature = sourceFeatures.find(
        (feature) =>
          feature.get(objectIdFieldName || DEFAULT_NAME_FIELD_OBJECTID) === id
      );

      if (deleteFeature) {
        source.removeFeature(deleteFeature);
      }
    }
  }
};

const queryFeatures = (map: TMap, socket: Socket, path: string) => {
  if (!map) {
    return;
  }

  const extent = map.getView().calculateExtent();
  const [xmin, ymin, xmax, ymax] = extent;

  const props = {
    path,
    operation: 'layer:query',
    params: {
      geometry: { xmin, ymin, xmax, ymax },
    },
  };

  socket.emit(props.operation, props);
};

const createSource = (props: ICreateSource) => {
  const { map, layer, socketPool, layerDefinition } = props;

  const objectIdFieldName = props.objectIdFieldName || 'OBJECTID';

  const { id, socketDefinition } = layerDefinition;

  if (!socketPool || !socketDefinition || !map) {
    return new OlVectorSource({
      features: [],
    });
  }

  const { path } = socketDefinition;

  const socket: Socket = socketPool.getSocketServer(socketDefinition);

  const source = new OlVectorSource({
    features: [],
  });

  socket.on('create', (evt: any) => {
    const features = readFeatures({
      features: evt.features,
      objectIdFieldName,
    }) as Feature[];

    addFeatures(source, { features, objectIdFieldName });
  });

  socket.on('update', (evt: any) => {
    const features = readFeatures({
      features: evt.features,
      objectIdFieldName,
    }) as Feature[];

    updateFeatures(source, { features, objectIdFieldName });
  });

  socket.on('delete', (evt: any) => {
    const features = readFeatures({
      features: evt.features,
      objectIdFieldName,
    }) as Feature[];

    deleteFeatures(source, { features, objectIdFieldName });
  });

  socket.on('layer:query-result', (evt: any) => {
    const features = readFeatures({
      features: evt.features,
      objectIdFieldName,
    }) as Feature[];

    addFeatures(source, { features, objectIdFieldName });
  });

  map &&
    layer &&
    map.on('moveend', () => {
      if (isLayerVisible({ map, id })) {
        queryFeatures(map, socket, path);
      }
    });

  map &&
    layer &&
    layer.on('change:visible', (evt: any) => {
      if (isLayerVisible({ map, id })) {
        queryFeatures(map, socket, path);
      }
    });

  return source;
};

export { createSource };
