import { FC } from 'react';

import { IServerData } from '../../NJSInfo.model';
import InfoBox from '../InfoBox/InfoBox';

import styles from './DBInfo.module.scss';

interface DBInfoProps {
  data: IServerData;
}

const DBInfo: FC<DBInfoProps> = ({ data }) => {
  return (
    <InfoBox title="Базы данных" className={styles.infoBox}>
      <>
        <p>Пользователь: {data.databasesUser}</p>
        <ul>
          {data.databases.map((el) => (
            <li key={el}>{el}</li>
          ))}
        </ul>
      </>
    </InfoBox>
  );
};

export default DBInfo;
