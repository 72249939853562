import { useEffect, useState } from 'react';

import rootStore from '../../../../stores/rootStore/rootStore';
import { ChanelTypes } from '../../../../ts/enums/enums';
import { MessageType } from '../../../../ts/models/logsPanel.model';
import useChannel from '../../../Socket/useChannel';

const useLogMessages = (id: number) => {
  const { categories } = rootStore.channelsDataStore;

  const [logMessages, setLogMessages] = useState<MessageType[]>([]);

  const message: N<MessageType> = useChannel(
    categories,
    ChanelTypes.CommandsLogsNew,
    `tl_${id}`
  );

  const clearLogs = () => setLogMessages([]);

  useEffect(() => {
    if (!message) return;

    setLogMessages((prev) => [...prev, message]);
  }, [message]);

  return { logMessages, clearLogs };
};

export default useLogMessages;
