import { isNumber } from 'lodash';

import { getComparableValue } from '../getComparableValue';
import { getDateRangeStr } from '../getDateRangeStr';
import { ICalcData } from '../getFormatDataDt';
import 'dayjs/locale/ru';
import { initialDates } from '../getGeneral';

export type IData = OWKS<SN>;

export type TDataDetectorsBars = [IData, ...IData[]];

export const getAverage = (
  data: [IData, ...IData[]],
  dateFormat?: string
): ICalcData[] => {
  const calcData = data.map((item: IData) => {
    const comparableValue = getComparableValue(item);

    const { dateFrom, dateTo } = item;

    if (isNumber(dateFrom) || isNumber(dateTo)) return initialDates;

    const {
      normalDateTime,
      timeFrom,
      normalDate: interval,
    } = getDateRangeStr(dateFrom, dateTo, dateFormat);

    return { comparableValue, date: normalDateTime, timeFrom, interval };
  });

  return calcData;
};
