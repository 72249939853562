import { observer } from 'mobx-react-lite';

import DefaultChecked from '../../../../../ui-kit/DefaultChecked/DefaultChecked';

import getLinkedCameras from './helpers/getLinkedCameras';
import LinkedVideoCamera from './LinkedVideoCamera/LinkedVideoCamera';

import styles from '../styles/BasicContent.module.scss';

const VideoCard = () => {
  const { cameraIds, currentCamId } = getLinkedCameras();

  return (
    <div className={styles.container}>
      <DefaultChecked
        noDataProps={{ isNeedBackground: false }}
        isEmptyData={!cameraIds.length}
      >
        <LinkedVideoCamera cameraIds={cameraIds} currentCamId={currentCamId} />
      </DefaultChecked>
    </div>
  );
};

export default observer(VideoCard);
