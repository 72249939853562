import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { useState, useMemo } from 'react';

import pointUdsApi from '../../../../../api/pointUds/pointUdsApi';
import rootStore from '../../../../../stores/rootStore/rootStore';
import checkPointUdsName from '../../../../Panel/ConstructorPanel/helpers/checkPointUdsName';
import Confirm from '../../../../ui-kit/Confirm/Confirm';
import { POINT_UDS_RENAMING_PATH } from '../../../constants/pathConstants';
import PointUdsConstructor from '../../../PointUdsConstructor/PointUdsConstructor';

import styles from './PointUdsRenaming.module.scss';

const PointUdsRenaming = () => {
  const { pointUdsUid, pointUdsList, setConstructorData } =
    rootStore.constructorStore;

  const point = useMemo(
    () => pointUdsList?.find((el) => el?.pointUdsUid === pointUdsUid),
    [pointUdsList, pointUdsUid]
  );

  const [pointUdsName, setPointUdsName] = useState(point?.pointUdsName ?? '');
  const [pointUdsDsc, setPointUdsDsc] = useState(point?.pointUdsDsc ?? '');

  if (!pointUdsUid) {
    return <h3>Выберите точку УДС в левой панели</h3>;
  }

  const handleRenaming = async () => {
    await pointUdsApi.updateByUid({
      uid: pointUdsUid,
      appPath: POINT_UDS_RENAMING_PATH,
      name: pointUdsName,
      dsc: pointUdsDsc,
    });

    const list = await pointUdsApi.fetchPointUdsList(POINT_UDS_RENAMING_PATH);

    list && setConstructorData('pointUdsList', list);
  };

  const isNotUniqueName = checkPointUdsName(pointUdsName);

  const pointUdsProps = {
    pointUdsName,
    setPointUdsName,
    pointUdsDsc,
    setPointUdsDsc,
    isNotUniqueName,
  };

  const isDisabledBtn =
    !pointUdsName ||
    (pointUdsName === point?.pointUdsName &&
      pointUdsDsc === point?.pointUdsDsc) ||
    isNotUniqueName;

  return (
    <>
      <h3>Изменение имени и описания точки</h3>
      <hr />

      <PointUdsConstructor {...pointUdsProps} />

      <Confirm onConfirm={handleRenaming}>
        <Button
          className={styles.pointBtn}
          type="primary"
          disabled={isDisabledBtn}
        >
          Сохранить
        </Button>
      </Confirm>
    </>
  );
};

export default observer(PointUdsRenaming);
