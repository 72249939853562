import { Button, Input, Table } from 'antd';
import { Key, TableRowSelection } from 'antd/lib/table/interface';
import { useEffect, useState } from 'react';
import { HiArrowLeft, HiDownload } from 'react-icons/hi';

import {
  flyTo,
  showFeatures,
} from '../../../../../apiGIS/layers/utils/flashFeatures';
import { TMap } from '../../../../../stores/mapStore/mapStore.model';
import { TABLE_SETTINGS } from '../../constants';
import {
  ILayerDataNode,
  ILayerQueryResult,
  ILayerTableHeader,
} from '../../layers.model';
import styles from '../../LayersButton.module.scss';

import {
  queryLayerData,
  getHeaders,
  getRowItems,
  downloadLayerTable,
} from './helpers';

const LayerTable = (props: {
  map: TMap;
  selectedNode: ILayerDataNode;
  onBack: (evt: any) => void;
}) => {
  const { map, selectedNode } = props;

  const [columns, setColumns] = useState<ILayerTableHeader[]>([]);
  const [dataSource, setDataSource] = useState<Record<string, any>[]>([]);
  const [layerDataset, setLayerDataset] = useState<ILayerQueryResult>({
    features: [],
    objectIdFieldName: '',
  });
  const [search, setSearch] = useState('');

  const id = selectedNode.item?.id || '';
  const alias = selectedNode.item?.alias || '';

  useEffect(() => {
    setColumns(getHeaders(selectedNode));
    fetchItems(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNode, search]);

  const fetchItems = async (text?: string) => {
    queryLayerData(selectedNode, text).then((queryData) => {
      const { features, objectIdFieldName } = queryData;

      const items = getRowItems(
        features,
        objectIdFieldName,
        selectedNode,
        text
      );

      setLayerDataset(queryData);
      setDataSource(items);
    });
  };

  const onFeatureSelection = (selectedRows: Record<string, any>[]) => {
    const ids = selectedRows.map(
      (item) => item[layerDataset.objectIdFieldName]
    );

    const selected = layerDataset.features.filter((feature) => {
      const idValue = feature.get(layerDataset.objectIdFieldName);

      return ids.includes(idValue);
    });

    showFeatures(selected);
  };

  const onFeatureAllSelection = () => {
    showFeatures(layerDataset.features);
  };

  const onBack = () => {
    showFeatures([]);

    props.onBack({});
  };

  const onRowClick = (evt: Record<string, any>) => {
    const idValue = evt[layerDataset.objectIdFieldName];

    const feature = layerDataset.features.find(
      (element) =>
        element.getProperties()[layerDataset.objectIdFieldName] === idValue
    );

    feature && flyTo(feature);
  };

  const getReport = () => {
    downloadLayerTable(alias || id, dataSource, columns);
  };

  const handleRow = (record: Record<string, any>) => ({
    onClick: () => onRowClick(record),
  });

  const handleOnChange = (keys: Key[], selectedRows: Record<string, any>[]) => {
    onFeatureSelection(selectedRows);
  };

  const handleOnSelect = (
    record: Record<string, any>,
    selected: boolean,
    selectedRows: Record<string, any>[]
  ) => {
    onFeatureSelection(selectedRows);
  };

  const rowSelectionProps: TableRowSelection<Record<string, any>> = {
    type: 'checkbox',
    onSelect: handleOnSelect,
    onSelectAll: onFeatureAllSelection,
    onChange: handleOnChange,
  };

  if (!map || !selectedNode) {
    return null;
  }

  return (
    <>
      <div className={styles.nodeContainer}>
        <HiArrowLeft className={styles.arrowBackIcon} onClick={onBack} />
        <div>{alias || id}</div>
      </div>

      <div className={styles.inputGroup}>
        <Input.Search
          value={search}
          onChange={(evt) => {
            setSearch(evt.target.value);
          }}
          onSearch={setSearch}
          className={styles.searchField}
        />
      </div>
      <Table
        dataSource={dataSource}
        columns={columns}
        rowSelection={rowSelectionProps}
        onRow={handleRow}
        pagination={TABLE_SETTINGS.PAGINATION}
        scroll={TABLE_SETTINGS.SCROLL}
      ></Table>
      <Button
        type={'text'}
        className={styles.downloadButton}
        onClick={getReport}
      >
        <HiDownload className={styles.downloadIcon} />
        Экспорт данных в Excel (xlsx)
      </Button>
    </>
  );
};

export default LayerTable;
