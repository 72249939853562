import { isNumber } from 'lodash';
import { FC } from 'react';

import Popover, { PopoverProps } from '../../../../../ui-kit/Popover/Popover';
import UserCard from '../../../../../UserCard/UserCard';

const SYSTEM_MESSAGE = 'Системное сообщение';

interface PopoverMessageProps {
  id: U<number>;
  children: JSX.Element;
}

const PopoverMessage: FC<PopoverMessageProps> = ({ id, children }) => {
  const popoverProps: PopoverProps = {
    placement: 'left',
    content: isNumber(id) ? (
      <UserCard userId={id} isBgClean />
    ) : (
      <p>{SYSTEM_MESSAGE}</p>
    ),
    children: null,
  };

  return (
    <Popover isClearParent={isNumber(id)} {...popoverProps}>
      {children}
    </Popover>
  );
};

export default PopoverMessage;
