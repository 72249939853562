import { observer } from 'mobx-react-lite';
import { RiRoadsterFill } from 'react-icons/ri';

import rootStore from '../../../../../stores/rootStore/rootStore';
import ButtonIco from '../../../../ui-kit/ButtonIco/ButtonIco';
import Popover from '../../../../ui-kit/Popover/Popover';
import { PLACEMENT_MAP_BTNS } from '../../../LayersButton/LayersButton';

import handleIsLanes from './helpers/handleIsLanes';

const TIP = 'Вкл./Откл. полос загруженности.';

const LaneBtns = () => {
  const { mapButtonsTipsDelay } = rootStore.uiStore;

  const { isTrafficLanes } = rootStore.pointsUdsStore;

  const popOverProps = {
    placement: PLACEMENT_MAP_BTNS,
    tipsDelay: mapButtonsTipsDelay,
  } as const;

  return (
    <ButtonIco onClick={handleIsLanes} isActive={isTrafficLanes}>
      <Popover content={TIP} {...popOverProps}>
        <RiRoadsterFill />
      </Popover>
    </ButtonIco>
  );
};

export default observer(LaneBtns);
