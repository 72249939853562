import { DictPredictKeys } from '../../../../../api/services/dtStatistics/constants/constants';
import { findBy } from '../../../../../helpers/findBy';
import rootStore from '../../../../../stores/rootStore/rootStore';

const { Predict } = DictPredictKeys;

const getTranslateKey = (key: string) => {
  const predictDict = rootStore.uiStore.dictionaries[Predict];

  const translateKey = findBy(predictDict ?? [], key, 'mnemocode')?.name ?? key;

  return translateKey;
};

export default getTranslateKey;
