import Heatmap from 'ol/layer/Heatmap';
import VectorLayer from 'ol/layer/Vector';
import VectorImageLayer from 'ol/layer/VectorImage';

import { TMap } from '../../../stores/mapStore/mapStore.model';

export const getLayer = (map: TMap, id: string) => {
  if (!map || !id) {
    return undefined;
  }

  const layer = map.getAllLayers().find((item) => item.get('id') === id);

  if (
    layer &&
    (layer instanceof VectorLayer ||
      layer instanceof VectorImageLayer ||
      layer instanceof Heatmap)
  ) {
    return layer;
  }

  return undefined;
};
