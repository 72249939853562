import { Tag } from 'antd';
import { DescriptionsProps } from 'antd/lib';

import { DEVICE_TYPES_ID } from '../../../../../../../constants/constants';
import {
  DEVICES_INFO,
  DEVICES_INFO_STATUS,
} from '../../../../../../../constants/devicesConstants';
import { findById } from '../../../../../../../helpers/findBy';
import { TObjMap } from '../../../../../../../stores/mapDataStore/mapDataStore.model';
import rootStore from '../../../../../../../stores/rootStore/rootStore';
import SkeletonInput from '../../../../SkeletonInput/SceletonInput';
import getMonitoringDevice from '../../helpers/getMonitoringDevice';

export const getStatusName = (
  deviceStatusId: TObjMap['deviceInfo']['deviceStatusId']
) => {
  const statusArr =
    DEVICES_INFO_STATUS.find(([key, value]) => deviceStatusId === value) ?? [];
  const statusName = statusArr[0] ?? 'Undefined';

  const { color, dsc } = DEVICES_INFO.STATE[statusName];

  return { statusColor: color, statusName: dsc };
};

const getGeneralItems = (): NonNullable<DescriptionsProps['items']> => {
  const { addresses } = rootStore.monitoringPanelStore;

  const device = getMonitoringDevice();

  if (!device) return [];

  const {
    caption,
    dsc,
    deviceStatusId,
    deviceTypeId,
    externalDeviceId,
    longitude,
    latitude,
    pointUdsName,
    pointUdsUid,
    uid,
  } = device.deviceInfo;

  const { statusColor, statusName } = getStatusName(deviceStatusId);

  const isLoadingAddress = addresses?.loading ?? true;
  const address =
    findById(addresses?.data ?? [], device.id)?.address ?? 'Не найден';

  const items: DescriptionsProps['items'] = [
    {
      key: 'name',
      label: 'Название устройства',
      children: caption,
      span: 2,
    },
    {
      key: 'status',
      label: 'Статус',
      children: <Tag color={statusColor}>{statusName}</Tag>,
    },
    {
      key: 'dsc',
      label: 'Описание',
      children: dsc || 'Отсутствует',
      span: 2,
    },
    {
      key: 'deviceTypeId',
      label: 'Тип устройства',
      children: DEVICE_TYPES_ID[deviceTypeId],
    },
    {
      key: 'address',
      label: 'Адрес',
      children: <SkeletonInput loading={isLoadingAddress} value={address} />,
      span: 2,
    },
    {
      key: 'longitude',
      label: 'Долгота',
      children: longitude,
    },
    {
      key: 'latitude',
      label: 'Широта',
      children: latitude,
    },
    {
      key: 'pointUdsName',
      label: 'Название точки УДС',
      children: pointUdsName || 'Нет привязки к точке УДС',
    },
    {
      key: 'pointUdsUid',
      label: 'УИ точки УДС',
      children: pointUdsUid || 'Нет привязки к точке УДС',
      span: 2,
    },
    {
      key: 'uid',
      label: 'УИ устройства',
      children: uid,
      span: 2,
    },
    {
      key: 'externalDeviceId',
      label: 'Внешн. УИ устройства',
      children: externalDeviceId,
    },
  ].filter((el) => el.children);

  return items;
};

export default getGeneralItems;
