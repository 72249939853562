import type { Extent } from 'ol/extent';

import { CLUSTERS_INFO } from '../../../../constants/mapClusterConstants';
import { TMap } from '../../../../stores/mapStore/mapStore.model';
import { PaddingExtent } from '../../../ui-kit/MapLayers/ClusterLayers/constants/constants';

const handleZoomToExtent = (
  map: TMap,
  extent: Extent,
  paddingExtent: PaddingExtent,
  duration = CLUSTERS_INFO.ANIMATION_DURATION
) => {
  if (!map) return;

  const view = map.getView();

  view.fit(extent, {
    size: map.getSize(),
    padding: paddingExtent,
    duration,
  });
};

export default handleZoomToExtent;
