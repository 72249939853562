import { FC } from 'react';

import { CircleMarkerPopupProps } from '../CircleMarkerPopup/CircleMarkerPopup';

import styles from './PointerMarker.module.scss';

const PointerMarker: FC<CircleMarkerPopupProps> = ({
  colorWithMode,
  icoInfo,
}) => {
  const { ico, borderColor } = icoInfo;

  return (
    <>
      <div
        className={styles.crosshairPopupMain}
        style={{
          backgroundColor: colorWithMode,
          borderColor: borderColor ?? colorWithMode,
        }}
      >
        {ico}
      </div>

      <div
        className={styles.crosshairPopupArrow}
        style={{ borderTopColor: colorWithMode }}
      />
      <div className={styles.crosshairPopupPoint} />
    </>
  );
};

export default PointerMarker;
