import { z } from 'zod';

import { COMMANDS } from '../../../../constants/commands';

const CommandsEnum = z.nativeEnum(COMMANDS);

const param = z.number().or(z.string());

const CommandInfoSchema = z.object({
  param1: param,
});

const SenderAddInfoSchema = z.object({
  command: CommandInfoSchema,
});

const CommandsSchema = z.object({
  dcc_type: CommandsEnum,
  dtbegin_command: z.string(),
  dtend_command: z.string().nullable(),
  jcmd_sender_add_info: SenderAddInfoSchema.nullable(),
});

export const PlannedCommandsSchema = z.object({
  dataRows: CommandsSchema.array(),
});
