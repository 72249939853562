import { Space } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import NoData from '../../../ui-kit/Errors/NoData/NoData';
import { BasicControlTabProps } from '../model/tlManagement.model';

import EditButton from './EditButton/EditButton';
import Confirm from './EditCurrentProgram/Confirm/Confirm';
import EditModal from './EditCurrentProgram/EditModal/EditModal';
import useTemporaryPlanTableEdit from './hooks/useTemproryPlanTableEdit';

const PhaseTimeChangeCurrentPlanMode: FC<BasicControlTabProps> = ({ id }) => {
  const {
    sgnProgramName,
    dataTable,
    isError,
    setOpenEditForm,
    editProps,
    editTableProps,
    clearPlanSelect,
  } = useTemporaryPlanTableEdit(id);

  if (isError) return <NoData />;

  return (
    <Space direction="vertical" size="middle">
      <h3>Текущий план: {sgnProgramName}</h3>
      <EditButton handleClickEdit={() => setOpenEditForm(true)} />
      <EditModal editProps={editProps} editTableProps={editTableProps} />
      <Confirm
        baseProgramProps={editTableProps.baseProgramProps}
        clearPlanSelect={clearPlanSelect}
        id={id}
        readyDataPhases={dataTable}
      />
    </Space>
  );
};

export default observer(PhaseTimeChangeCurrentPlanMode);
