import { Button } from 'antd';

import { TipType } from '../../../stores/uiStore/uiStore.model';
import Popover from '../../ui-kit/Popover/Popover';

const PLACEMENT = 'bottom';
const BORDER_RADIUS = 6;

const LOCAL_STYLES = {
  leftButton: {
    margin: 0,
    width: 40,
    height: 40,
    border: 0,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: BORDER_RADIUS,
    borderTopLeftRadius: BORDER_RADIUS,
    paddingTop: 7,
  },
  button: {
    margin: 0,
    width: 40,
    height: 40,
    border: 0,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    paddingTop: 7,
  },
  rightButton: {
    margin: 0,
    width: 40,
    height: 40,
    border: 0,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    borderBottomRightRadius: BORDER_RADIUS,
    borderTopRightRadius: BORDER_RADIUS,
    paddingTop: 7,
  },
};

export const MeasurementItem = (props: {
  title: string;
  onClick: () => void;
  icon: JSX.Element;
  delay: TipType;
  num: number;
  count: number;
  className: string;
}) => {
  let style = LOCAL_STYLES.button;

  if (props.num === 0) {
    style = LOCAL_STYLES.leftButton;
  }

  if (props.num === props.count - 1) {
    style = LOCAL_STYLES.rightButton;
  }

  return (
    <Popover
      content={props.title}
      placement={PLACEMENT}
      tipsDelay={props.delay}
    >
      <Button
        icon={props.icon}
        style={style}
        onClick={props.onClick}
        className={props.className}
      ></Button>
    </Popover>
  );
};
