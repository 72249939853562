import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { findById } from '../../../../../../../helpers/findBy';
import rootStore from '../../../../../../../stores/rootStore/rootStore';
import { TL } from '../../../../../../../ts/models/tl.model';
import { getLinkedLanes } from '../../../../../../Overlays/PointUdsOverlay/helpers/getLinkedLanes';

import LinkedDevices from './LinkedDevices/LinkedDevices';

interface LinkedDevicesProps {
  selectedTL: TL;
  isShowLanes: boolean;
  rate: number;
}

const LinkedDevicesWrapper: FC<LinkedDevicesProps> = ({
  selectedTL,
  isShowLanes,
  rate,
}) => {
  const { activeDtId } = rootStore.detailedStore;
  const { infoData } = rootStore.uiStore;

  const { linkedDeviceIds } = selectedTL;
  const dtIds = linkedDeviceIds?.detectors ?? [];
  const lanes = getLinkedLanes(dtIds) ?? [];
  const selectedLanes = activeDtId ? findById(lanes, activeDtId) : null;

  if (!isShowLanes || !infoData || !selectedLanes) return null;

  return (
    <LinkedDevices rate={rate} trafficLane={selectedLanes} linkedDts={dtIds} />
  );
};

export default observer(LinkedDevicesWrapper);
