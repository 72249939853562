import api from '../../../../../api';
import { LibraryKeys } from '../../../../../api/libraries/enums/enums';
import { findById } from '../../../../../helpers/findBy';

export const getSystemName = async (systemId: U<number>, appPath: string) => {
  if (!systemId) return null;

  const systemDict =
    (await api.libraries.getLibrariesByKey(LibraryKeys.SystemId, appPath)) ??
    [];

  const systemName = findById(systemDict, systemId)?.name;

  return systemName ?? null;
};
