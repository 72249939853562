import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useState, Suspense, lazy } from 'react';

import { AdminTypes } from '../../constants/adminConstants';
import rootStore from '../../stores/rootStore/rootStore';
import Detailed from '../ui-kit/Detailed/Detailed';

import AdminModal from './AdminModal/AdminModal';
import BreadCrumbs, { IBreadCrumbsItem } from './BreadCrumbs/BreadCrumbs';
import Equipments from './Equipments/Equipments';
import getAdminHeader from './helpers/getAdminHeader';
import getBreadCrumbs from './helpers/getBreadCrumbs';
import { IAdminTypes } from './models/adminTypes.model';
import NJInfo from './NJSInfo/NJInfo';
import Organizations from './Organizations/Organizations';

const VersionsTable = lazy(() => import('./VersionsTable/VersionsTable'));
const SystemAbout = lazy(() => import('./SystemAbout/SystemAbout'));
const AdminSettingUsers = lazy(
  () => import('./AdminSettingUsers/AdminSettingUsers')
);
const AdminsSettingGroups = lazy(
  () => import('./AdminsSettingGroups/AdminsSettingGroups')
);
const AdminsSettingsConfig = lazy(
  () => import('./AdminsSettingsConfig/AdminsSettingsConfig')
);
const AdminSystemObject = lazy(
  () => import('./AdminSystemObject/AdminSystemObject')
);
const ASORegionTabs = lazy(
  () => import('./AdminSystemObject/ASORegionTabs/ASORegionTabs')
);

const adminTypes: IAdminTypes = {
  accessNJSGroups: (
    <>
      <AdminsSettingGroups type={AdminTypes.NjsGroups} />
      <AdminsSettingsConfig />
    </>
  ),
  accessWebUsers: <AdminSettingUsers />,
  infoSystem: <SystemAbout />,
  infoVersions: <VersionsTable />,
  accessWebGroups: (
    <>
      <AdminsSettingGroups type={AdminTypes.WebGroups} />
      <AdminsSettingsConfig />
    </>
  ),
  eputsSystem: <ASORegionTabs />,
  region: <ASORegionTabs isRegion />,
  trafficLight: <AdminSystemObject isSubsystem />,
  video: <AdminSystemObject isSubsystem />,
  detector: <AdminSystemObject isSubsystem />,
  meteo: <AdminSystemObject isSubsystem />,
  system: <AdminSystemObject isSubsystem={false} />,
  njsInfo: <NJInfo />,
  equipment: <Equipments />,
  organizations: <Organizations />,
};

const Admin = () => {
  const {
    setAdminsData,
    adminTableType,
    systemMenu,
    isModalOpen,
    modalContent,
    isAdminPanel,
    isBreadCrumbs,
  } = rootStore.adminPanelStore;

  const [breadCrumbs, setBreadCrumbs] = useState<IBreadCrumbsItem[]>([]);

  const onClose = () => {
    setAdminsData('adminTableType', null);
  };

  useEffect(() => {
    const displayheight = window.innerHeight;

    setAdminsData('displayHeight', displayheight);

    return () => setAdminsData('adminTableType', null);
  }, [setAdminsData]);

  useEffect(() => {
    if (isBreadCrumbs) return;

    setAdminsData('adminPanelKeys', null);
    setBreadCrumbs([]);
  }, [isBreadCrumbs, setAdminsData]);

  useEffect(() => {
    if (!systemMenu || !adminTableType) return setBreadCrumbs([]);

    const { formattedKeys, newBreadCrumbsItems } = getBreadCrumbs(
      systemMenu,
      adminTableType
    );

    setBreadCrumbs(newBreadCrumbsItems);
    setAdminsData('adminPanelKeys', formattedKeys);
  }, [adminTableType, systemMenu, setAdminsData]);

  if (!adminTableType) return <h2>Ошибка</h2>;

  if (!isAdminPanel) return null;

  const { header, type } = getAdminHeader();

  return (
    <Suspense fallback={<Spin />}>
      <Detailed header={header} onClose={onClose}>
        <>
          {breadCrumbs.length ? (
            <BreadCrumbs breadCrumbsItems={breadCrumbs} />
          ) : null}
          {type && adminTypes[type]}
        </>
      </Detailed>
      {isModalOpen && modalContent && (
        <AdminModal title={modalContent.title}>
          {modalContent.content}
        </AdminModal>
      )}
    </Suspense>
  );
};

export default observer(Admin);
