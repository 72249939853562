import { TimePicker as TimePickerAntd } from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { TimePickerProps } from 'antd/lib';
import classNames from 'classnames';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { FC } from 'react';

import { TIME_FORMAT } from '../../../../constants/constants';
import {
  IDisabledTimePickerProps,
  disabledTimePicker,
} from '../../../TrafficLightDetailed/TlManagement/helpers/disabledTimePicker';

import Footer from './Footer/Footer';

import styles from './TimePicker.module.scss';

dayjs.extend(customParseFormat);

export interface TimePickerBasicProps
  extends Omit<TimePickerProps, 'className'> {
  disabledTimeProps?: N<IDisabledTimePickerProps>;
  format?: string;
  pickerStyle?: string;
}

const TimePicker: FC<TimePickerBasicProps> = ({
  disabledTimeProps,
  pickerStyle = '',
  ...timeProps
}) => {
  const disabledTime =
    disabledTimeProps !== undefined
      ? () => disabledTimePicker(disabledTimeProps)
      : undefined;

  const format = timeProps.format ?? TIME_FORMAT;

  const timePickerProps: TimePickerProps = {
    ...timeProps,
    showNow: timeProps.showNow ?? false,
    locale,
    placeholder: timeProps.placeholder ?? 'Укажите интервал',
    format,
    changeOnScroll: true,
    needConfirm: true,
    renderExtraFooter: () => <Footer format={format} />,
    disabledTime,
  };

  const pickerContainer = classNames({
    [styles.picker]: true,
    [pickerStyle]: pickerStyle,
  });

  return (
    <TimePickerAntd
      {...timePickerProps}
      className={pickerContainer}
      data-testid="time-picker"
    />
  );
};

export default TimePicker;
