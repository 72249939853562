import classNames from 'classnames';
import { FC } from 'react';

import { System } from '../../ts/enums/enums';
import { switchIco } from '../DetailedMain/helpers/switchHeaderIco';
import { ALL_SYSTEM_TITLE } from '../DetailedStatistics/constants/constants';
import { DevicesProps } from '../InfoPanel/SystemsInfo/LinkedsInfo/LinkedDeviceItem/LinkedDeviceItem';
import { getDeviceColor } from '../MapBtns/MapInfoWrapper/InfoBtn/ContentInfo/helpers/getMapInfo';
import { getTLIcon } from '../Mapper/helpers/getStyle';

import styles from './IcoDevice.module.scss';

const IcoDevice: FC<DevicesProps> = ({
  system,
  mode,
  statusDevice,
  bgColor,
  iconStyle,
}) => {
  const { ico, isBg } =
    system !== ALL_SYSTEM_TITLE
      ? switchIco(system)
      : { isBg: false, ico: null };

  const isCorrectStatus = Number.isFinite(mode);

  const isTL = system === System.Lights && isCorrectStatus;

  const { ico: icoSystem, borderColor } = isTL
    ? getTLIcon(mode ?? null)
    : { ico, borderColor: null };

  if (isBg) return icoSystem;

  const colorStatus = statusDevice ? getDeviceColor(statusDevice) : 'none';

  const styleContainer = classNames(styles.ico, iconStyle);

  return (
    <span
      className={styleContainer}
      style={{
        backgroundColor: bgColor ?? colorStatus,
        borderWidth: !borderColor ? 0 : undefined,
        borderColor: borderColor ?? undefined,
      }}
    >
      {icoSystem}
    </span>
  );
};

export default IcoDevice;
