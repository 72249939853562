import { IServerData } from '../../../components/Admin/NJSInfo/NJSInfo.model';
import { VERSION } from '../../../constants/versions';
import { getAppPathHeaders } from '../../helpers/getAppPathHeaders';
import http from '../../http';
import tryCatchWrapper from '../../tryCatchWrapper';

const ENDPOINT = `${VERSION}/server/table-validation`;

export const tableValidation = {
  setStatus: async (
    status: IServerData['tableValidationStatus'],
    appPath: string
  ) =>
    tryCatchWrapper(async () => {
      const res = await http.patch(
        ENDPOINT + '/status',
        {
          status,
        },
        {
          headers: getAppPathHeaders(appPath),
        }
      );

      const message: string = res.data.message;

      return message;
    }),
};
