import cameras from './cameras';
import detectors from './detectors';
import mapObjectsApi from './mapObjectsApi';
import pointsUds from './pointsUds';
import trafficLights from './trafficLights';

export default {
  mapObjectsApi,
  trafficLights,
  cameras,
  pointsUds,
  detectors,
};
