import { RefObject, useCallback, useEffect, useState } from 'react';

const OPEN_PANEL_DURATION = 1000;

interface UseResizeProps {
  wrapRef: U<RefObject<HTMLElement>>;
  isPanel?: boolean;
  isLoading?: boolean;
}

const useResize = ({ wrapRef, isPanel, isLoading }: UseResizeProps) => {
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  const handleResize = useCallback(() => {
    if (wrapRef?.current) {
      const newWidth = wrapRef.current.getBoundingClientRect().width;
      const newHeight = wrapRef.current.getBoundingClientRect().height;

      setHeight(newHeight);
      setWidth(newWidth);
    }
  }, [wrapRef]);

  useEffect(() => {
    const timeId = setTimeout(() => {
      handleResize();
    }, OPEN_PANEL_DURATION);

    return () => {
      clearTimeout(timeId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPanel]);

  useEffect(() => {
    handleResize();
  }, [handleResize, isLoading]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (wrapRef?.current) {
      const newHeight = wrapRef.current.getBoundingClientRect().height;

      setHeight(newHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  return { width, height };
};

export default useResize;
