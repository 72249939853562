import type { Map } from 'ol';

import rootStore from '../../../stores/rootStore/rootStore';
import { getFeaturesExtent } from '../../Overlays/InfoPopup/helpers/getFeaturesExtent';

export const DURATION_CORRECTION = 50;

interface ClustersZoomProps {
  map: Map;
  isNeedClicked?: boolean;
}

export const setClustersZoomChange = ({
  map,
  isNeedClicked = true,
}: ClustersZoomProps) => {
  const { setClusters } = rootStore.clustersStore;
  const { infoData, setKeyValue } = rootStore.uiStore;

  const clusters = getFeaturesExtent(map);

  clusters && setClusters(clusters.featuresClusters);
  isNeedClicked &&
    infoData &&
    setKeyValue('clickedCartographyObj', {
      id: infoData.id,
      system: infoData.system,
    });
};
