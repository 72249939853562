import { Style, Icon } from 'ol/style';

import borderDefault from '../../../assets/icons/map/border.svg';
import frame from '../../../assets/icons/map/frame.svg';
import camerasIcon from '../../../assets/icons/map/ic_camera.svg';
import detectorsIcon from '../../../assets/icons/map/ic_detectors.svg';
import meteoIcon from '../../../assets/icons/map/ic_meteo.svg';
import publicTransportIcon from '../../../assets/icons/map/ic_public_transport.svg';
import roadWorksIcon from '../../../assets/icons/map/ic_road_works.svg';
import rtaIcon from '../../../assets/icons/map/ic_rta.svg';
import specialTransportIcon from '../../../assets/icons/map/ic_special_transport.svg';
import { MAP_ICONS_COLORS } from '../../../constants/colorsConstants';
import { EVENTS, SYSTEM } from '../../../constants/constants';
import { TDeviceStatus } from '../../../constants/devicesConstants';
import { TL_DETAILED } from '../../../constants/tlDetailedConstants';
import { TL_STATUS_COLORS_HEX } from '../../../constants/tlStatusConstants';
import { findBy } from '../../../helpers/findBy';
import rootStore from '../../../stores/rootStore/rootStore';
import { TlMode } from '../../../ts/models/tl.model';
import { getDeviceColor } from '../../MapBtns/MapInfoWrapper/InfoBtn/ContentInfo/helpers/getMapInfo';

const {
  LIGHTS,
  DETECTORS,
  CAMERAS,
  PUBLIC_TRANSPORT,
  METEO,
  SPECIAL_TRANSPORT,
} = SYSTEM;

const { ROAD_WORKS, RTA } = EVENTS;
const { STATIC_OBJECT, TRANSPORT, ACIDENT } = MAP_ICONS_COLORS;

interface ISetIcoStyleProps {
  ico: string;
  color: string;
  scale: number;
  isDefaultStyle?: boolean;
  borderColor?: N<string>;
}

export const setIcoStyle = ({
  ico,
  color,
  scale,
  isDefaultStyle = true,
  borderColor,
}: ISetIcoStyleProps) => {
  const icoStyle = new Style({
    image: new Icon({
      src: ico,
      scale,
    }),
  });

  if (isDefaultStyle) return [icoStyle];

  return [
    new Style({
      image: new Icon({
        src: frame,
        scale,
        color,
      }),
    }),
    new Style({
      image: new Icon({
        src: borderDefault,
        scale,
        color: borderColor ?? undefined,
      }),
    }),
    icoStyle,
  ];
};

export const getColor = (set: string) => {
  switch (set) {
    case LIGHTS:
      return STATIC_OBJECT;
    case DETECTORS:
      return STATIC_OBJECT;
    case CAMERAS:
      return STATIC_OBJECT;
    case PUBLIC_TRANSPORT:
      return TRANSPORT;
    case SPECIAL_TRANSPORT:
      return TRANSPORT;
    case METEO:
      return STATIC_OBJECT;
    case ROAD_WORKS:
      return ACIDENT;
    case RTA:
      return ACIDENT;
    default:
      return STATIC_OBJECT;
  }
};

const getStatusIcoColor = (
  deviceStatus: N<TDeviceStatus>,
  set: string,
  isDisabled: boolean
) => {
  if (isDisabled) return TL_STATUS_COLORS_HEX.GREY;

  if (deviceStatus) return getDeviceColor(deviceStatus);

  const color = getColor(set);

  return color;
};

interface BorderTLIcon {
  borderColor: N<string>;
}

type TLIconReturn<T> = T extends undefined
  ? { ico: JSX.Element }
  : { ico: string };

export const getTLIcon = <T extends U<true> = undefined>(
  mode: N<TlMode>,
  isStrSvg?: T
): BorderTLIcon & TLIconReturn<T> => {
  if (mode === null) {
    const unknownItem = TL_DETAILED.MODE.Unknown;

    const unknownIco = !isStrSvg ? unknownItem.iconJSX : unknownItem.icon;

    return {
      ico: unknownIco,
      borderColor: unknownItem.borderColor,
    } as BorderTLIcon & TLIconReturn<T>;
  }

  const modeInfo =
    findBy(Object.values(TL_DETAILED.MODE), mode, 'com') ??
    TL_DETAILED.MODE.Unknown;

  const ico = !isStrSvg ? modeInfo.iconJSX : modeInfo.icon;

  return { ico, borderColor: modeInfo.borderColor } as BorderTLIcon &
    TLIconReturn<T>;
};

export const getStyle = (
  set: string,
  mode: N<TlMode>,
  deviceStatus: N<TDeviceStatus>,
  isDisabled: boolean,
  scale = 0.6
) => {
  const { isConstructor } = rootStore.constructorStore;

  const color = isConstructor
    ? TL_STATUS_COLORS_HEX.GREY
    : getStatusIcoColor(deviceStatus, set, isDisabled);

  switch (set) {
    case LIGHTS:
      return setIcoStyle({
        color,
        scale,
        isDefaultStyle: false,
        ...getTLIcon(mode, true),
      });
    case DETECTORS:
      return setIcoStyle({
        ico: detectorsIcon,
        color,
        scale,
        isDefaultStyle: false,
      });
    case CAMERAS:
      return setIcoStyle({
        ico: camerasIcon,
        color,
        scale,
        isDefaultStyle: false,
      });
    case SPECIAL_TRANSPORT:
      return setIcoStyle({ ico: specialTransportIcon, color, scale });
    case PUBLIC_TRANSPORT:
      return setIcoStyle({ ico: publicTransportIcon, color, scale });
    case METEO:
      return setIcoStyle({
        ico: meteoIcon,
        color,
        scale,
        isDefaultStyle: false,
      });
    case ROAD_WORKS:
      return setIcoStyle({ ico: roadWorksIcon, color, scale });
    case RTA:
      return setIcoStyle({ ico: rtaIcon, color, scale });
    default:
      return setIcoStyle({ ico: rtaIcon, color, scale });
  }
};
