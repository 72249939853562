import { observer } from 'mobx-react-lite';
import { CSSProperties, FC, useMemo } from 'react';

import { ReactComponent as CameraIco } from '../../../../assets/icons/map/ic_camera.svg';
import { ReactComponent as DetectorIco } from '../../../../assets/icons/map/ic_detectors.svg';
import { COLORS } from '../../../../constants/colorsConstants';
import rootStore from '../../../../stores/rootStore/rootStore';
import { System } from '../../../../ts/enums/enums';
import { VIDEO_DETECTOR_ICO_RATE } from '../../constants/constants';
import DeviceWrapper from '../../DeviceWrapper/DeviceWrapper';
import getDeviceIcoFill from '../../helpers/getDeviceIcoFill';
import getIcoColor from '../../helpers/getIcoColor';
import getIconSize from '../../helpers/getIconSize';
import IconTooltip from '../../IconTooltip/IconTooltip';
import { CameraIconProps } from '../../models/camera.model';

import getDtBottomRate from './helpers/getDtBottomRate';
import VideoDtId from './VideoDtId';

import styles from './VideoDetectorIcon.module.scss';

const { PRIMARY } = COLORS;

export interface VideoDtIconProps extends Omit<CameraIconProps, 'isId'> {
  handleDt?: () => void;
  activeId?: number;
  icoRate?: number;
  isPrimary?: boolean;
}

const VideoDetectorIcon: FC<VideoDtIconProps> = ({
  camera,
  onClick,
  handleDt,
  rate,
  activeId,
  icoRate = VIDEO_DETECTOR_ICO_RATE,
  isPrimary,
}) => {
  const { cameras, detectors } = rootStore.mapDataStore;
  const { infoData } = rootStore.uiStore;

  const size = getIconSize(camera.size, rate) * icoRate;
  const { linkedDetectorId, id, linkColor } = camera;

  const cmDefaultColor = useMemo(
    () => getIcoColor(linkColor, getDeviceIcoFill(id, cameras)),
    [linkColor, id, cameras]
  );

  const dtDefaultColor = useMemo(
    () =>
      getIcoColor(
        linkColor,
        getDeviceIcoFill(linkedDetectorId ?? undefined, detectors)
      ),
    [linkColor, linkedDetectorId, detectors]
  );

  if (!linkedDetectorId || !id) return null;

  const isCmActive = isPrimary ?? (activeId === id || id === infoData?.id);

  const isDtActive =
    isPrimary ??
    (linkedDetectorId === activeId || linkedDetectorId === infoData?.id);

  const cmBgColor = isCmActive ? PRIMARY : cmDefaultColor;
  const dtBgColor = isDtActive ? PRIMARY : dtDefaultColor;

  const wrapperProps = {
    size,
    id,
    device: camera,
  };

  const icoProps = {
    width: size / 2,
    height: size / 2,
  };

  const wrapperStyle: CSSProperties = { border: `${size / 20}px solid white` };

  const cmBtnStyle: CSSProperties = {
    backgroundColor: cmBgColor,
  };

  const dtBtnStyle: CSSProperties = {
    backgroundColor: dtBgColor,
  };

  const idStyle: CSSProperties = {
    fontSize: size / 5.5,
    marginRight: size / 12,
  };

  const dtIdStyle: CSSProperties = {
    ...idStyle,
    ...dtBtnStyle,
    bottom: size / getDtBottomRate(),
    right: 0,
  };

  const cmIdStyle: CSSProperties = {
    ...idStyle,
    ...cmBtnStyle,
    top: size / 10,
    right: 0,
  };

  return (
    <DeviceWrapper {...wrapperProps} rate={rate}>
      <div className={styles.btnWrapper} style={wrapperStyle}>
        <IconTooltip id={id} size={size} system={System.Cameras}>
          <button className={styles.dtBtn} onClick={onClick} style={cmBtnStyle}>
            <CameraIco {...icoProps} className={styles.cmIco} />
            <VideoDtId style={cmIdStyle} id={id} />
          </button>
        </IconTooltip>

        <IconTooltip
          id={linkedDetectorId}
          size={size}
          system={System.Detectors}
        >
          <button
            className={styles.cmBtn}
            onClick={handleDt}
            style={dtBtnStyle}
          >
            <DetectorIco {...icoProps} className={styles.dtIco} />
            <VideoDtId style={dtIdStyle} id={linkedDetectorId} />
          </button>
        </IconTooltip>
      </div>
    </DeviceWrapper>
  );
};

export default observer(VideoDetectorIcon);
