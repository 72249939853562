import Feature from 'ol/Feature';

import {
  getClassBreaksDefaultSymbol,
  getClassBreaksSymbol,
  getClassBreaksSymbolsDict,
} from './classBreaksUtils';
import {
  DrawingInfoRenderer,
  IClassBreaksRendererDefinition,
  ISimpleRendererDefinition,
  IUniqueValuesRendererDefinition,
} from './renderer.model';
import { SimpleRendererStyle } from './simpleStyleUtils';
import {
  getUniqueValuesDefaultSymbol,
  getUniqueValuesSymbol,
  getUniqueValueSymbolsDict,
} from './uniqueValuesUtils';

const createStyleFunction = (renderer: DrawingInfoRenderer) => {
  if (!renderer) {
    return undefined;
  }

  const { type } = renderer;

  if (!type) {
    return undefined;
  }

  if (type === 'simple') {
    return SimpleRendererStyle(renderer as ISimpleRendererDefinition);
  }

  if (type === 'uniqueValue') {
    const defaultSymbol = getUniqueValuesDefaultSymbol(
      renderer as IUniqueValuesRendererDefinition
    );

    const uniqueSymbolsDict = getUniqueValueSymbolsDict(
      renderer as IUniqueValuesRendererDefinition
    );

    return function (feature: Feature, resolution: number) {
      return getUniqueValuesSymbol(
        renderer as IUniqueValuesRendererDefinition,
        feature,
        resolution,
        defaultSymbol,
        uniqueSymbolsDict
      );
    };
  }

  if (type === 'classBreaks') {
    const defaultSymbol = getClassBreaksDefaultSymbol(
      renderer as IClassBreaksRendererDefinition
    );

    const classSymbolsDict = getClassBreaksSymbolsDict(
      renderer as IClassBreaksRendererDefinition
    );

    return function (feature: Feature, resolution: number) {
      return getClassBreaksSymbol(
        renderer as IClassBreaksRendererDefinition,
        feature,
        resolution,
        defaultSymbol,
        classSymbolsDict
      );
    };
  }

  return undefined;
};

export { createStyleFunction };
