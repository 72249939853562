import { findById } from '../../../../../helpers/findBy';
import { ICamera } from '../../../../../ts/models/camera.model';
import { Detector } from '../../../../../ts/models/mapObject.model';
import { DtDaiJdt83 } from '../../../../../ts/models/MapObjects/detectors.model';

const getDtProfiles = (
  dtIds: N<number[]>,
  detectors: Detector[],
  cameras: ICamera[],
  isCheckVideoDt = false
) => {
  return (
    dtIds?.reduce((res: DtDaiJdt83[], dtId) => {
      const dt = findById(detectors, dtId);

      const final = dt?.dai_jdt83;
      const isCmLinked =
        Boolean(final?.linkedCameraId) &&
        cameras.some((cm) => cm.id === final?.linkedCameraId);

      const isVideoDt = isCheckVideoDt && isCmLinked;

      final && !isVideoDt && res?.push(final);

      return res;
    }, []) ?? []
  );
};

export default getDtProfiles;
