import { FC, RefObject, useState } from 'react';
import { IoIosWarning } from 'react-icons/io';

import { COMMANDS_CONTENT } from '../../../../../constants/commands';
import Img from '../../../../ui-kit/Img/Img';
import { postCommand } from '../../helpers/postCommand';
import { IPostCommandProps } from '../../model/postCommands.model';
import ModalBody from '../../PhaseHold/ModalBody/ModalBody';
import BodyContent from '../../PhaseHold/ModalConfirm/BodyContent/BodyContent';
import ModalConfirm from '../../PhaseHold/ModalConfirm/ModalConfirm';
import styles from '../../styles/basicStyles.module.scss';

const PATH = 'Web.tl.detailed.control';

export interface IModalCommand {
  radioValue: U<number>;
  sgnProgramName: string;
  tlName: string;
  imgRef: RefObject<string>;
  propsPostCommand: IPostCommandProps;
  openModal: boolean;
  setOpenModal: SetState<boolean>;
}

const ModalCommand: FC<IModalCommand> = ({
  radioValue,
  sgnProgramName,
  tlName,
  imgRef,
  propsPostCommand,
  openModal,
  setOpenModal,
}) => {
  const [isLoadingRequest, setIsLoadingRequest] = useState<boolean>(false);

  const onOkConfirm = async () => {
    setIsLoadingRequest(true);

    await postCommand(propsPostCommand, PATH);

    setIsLoadingRequest(false);
    setOpenModal(false);
  };

  const commandName = COMMANDS_CONTENT.find(({ id }) => id === radioValue);

  const title = commandName
    ? `Подтверждаете включение режима "${commandName.body}"?`
    : 'Команда не найдена';

  const phaseContentModal = [`Сигнальная программа: ${sgnProgramName}`];

  const modalBodyProps = {
    content: (
      <BodyContent
        title={<strong>{title}</strong>}
        content={phaseContentModal}
      />
    ),
    image: (
      <Img
        src={imgRef.current ?? ''}
        alt={`Режим № ${radioValue}`}
        className={styles.imgConfirm}
        isShowIcons
      />
    ),
  };

  const modalConfirmProps = {
    modalTitle: `Отправка команды управления для светофорного объекта ${tlName}`,
    icon: <IoIosWarning className={styles.iconInfo} />,
    openModal: openModal,
    setOpenModal,
    onOkConfirm,
    width: '900px',
    okButtonProps: { loading: isLoadingRequest },
  };

  return (
    <ModalConfirm {...modalConfirmProps}>
      <ModalBody {...modalBodyProps} />
    </ModalConfirm>
  );
};

export default ModalCommand;
