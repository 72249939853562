import { FC } from 'react';

import setDisplay from '../../Overlays/helpers/setDisplay';
import { XMLNS } from '../constants/constants';

import styles from './CollapseListBtn.module.scss';

interface ListBtnProps {
  onClick: () => void;
  isToTop: boolean;
}

const CollapseListBtn: FC<ListBtnProps> = ({ onClick, isToTop }) => {
  const btnStyle = isToTop ? styles.btnPrimary : styles.btn;

  return (
    <div className={styles.collapseListWrapper}>
      <svg
        width="38"
        height="8"
        viewBox="0 0 38 8"
        className={styles.collapseList}
        xmlns={XMLNS}
        onClick={onClick}
      >
        <path
          d="M0 6C0 2.68629 2.68629 0 6 0H32C35.3137 0 38 2.68629 38 6V8H0V6Z"
          className={btnStyle}
        />

        <path
          d="M15 6.5L23.5 6.5L19 2.5L15 6.5Z"
          className={styles.arrowToTop}
          style={{ display: setDisplay(isToTop) }}
        />

        <path
          d="M23.5 2.5H15L19.5 6.5L23.5 2.5Z"
          className={styles.arrowToBottom}
          style={{ display: setDisplay(!isToTop) }}
        />
      </svg>
    </div>
  );
};

export default CollapseListBtn;
