import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useState } from 'react';

import { ReactComponent as TrafficIco } from '../../../assets/icons/traffic_graph/traffic_graph_icon.svg';
import { MAP_BUTTONS_NAMES } from '../../../constants/uiComponentConstants';
import rootStore from '../../../stores/rootStore/rootStore';
import ButtonIco from '../../ui-kit/ButtonIco/ButtonIco';
import { PopoverProps } from '../../ui-kit/Popover/Popover';

import { NODE_MERGE_OPTION_ATTRIBUTE, RADIUS_INF_CURVE } from './constants';
import GraphSettingsPanel from './GraphSettingsPanel';
import GraphVisualizer from './GraphVisualizer';
import {
  getDataset,
  getDateEnd,
  getDateStart,
  getLinksItems,
  getVertexesItems,
  IDateRange,
} from './helpers';
import { IGraphDataSet, IGraphSet } from './trafficGraph.model';

const mem: IGraphSet[] = [];

const PLACEMENT = 'bottom';

interface IDateTimeRange {
  dateStart: string | null;
  dateEnd: string | null;
}

const TrafficGraphButton: FC = () => {
  const { mapDataStore, uiStore } = rootStore;
  const { mapProxy } = mapDataStore;

  const { mapButtonsTipsDelay, activeMapButton, setKeyValue } = uiStore;

  const [isActive, setIsActive] = useState(false);
  const [mergeMethod, setMergeMethod] = useState(NODE_MERGE_OPTION_ATTRIBUTE);

  const [range, setRange] = useState<IDateRange>({
    dateStart: undefined,
    dateEnd: undefined,
  });
  const [radius] = useState(RADIUS_INF_CURVE);
  const [dataset, setDataset] = useState<IGraphDataSet | null>(null);

  const regionId = Number(localStorage.getItem('regionId'));

  const tooltipText = 'Граф потоков ТС';

  const popOverProps: Omit<PopoverProps, 'children'> = {
    tipsDelay: mapButtonsTipsDelay,
    content: tooltipText,
    placement: PLACEMENT,
  } as const;

  const toggleComponent = () => {
    setIsActive(!isActive);
  };

  const drawGraph = async () => {
    const dateStartView = getDateStart(range.dateStart);
    const dateEndView = getDateEnd(range.dateEnd);

    const key = [dateStartView, dateEndView].join('-');

    const graphDataItem: U<IGraphSet> = mem.find(
      (element) => element.key === key
    );

    const data = graphDataItem
      ? graphDataItem
      : await getDataset(regionId, dateStartView, dateEndView);

    if (!graphDataItem) {
      mem.push(data);
    }

    const { vertexes, links } = data;

    const serviceVertexes = await getVertexesItems(
      mapProxy,
      vertexes,
      regionId,
      mergeMethod
    );

    const serviceLinks = await getLinksItems(serviceVertexes, links);

    setDataset({ links: serviceLinks, vertexes: serviceVertexes });
  };

  const removeGraph = () => {};

  useEffect(() => {
    if (activeMapButton !== MAP_BUTTONS_NAMES.TRAFFIC_GRAPH) {
      setIsActive(false);
    }
  }, [activeMapButton]);

  useEffect(() => {
    isActive ? drawGraph() : removeGraph();

    if (isActive) {
      setKeyValue('activeMapButton', MAP_BUTTONS_NAMES.TRAFFIC_GRAPH);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  useEffect(() => {
    drawGraph();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mergeMethod, range.dateStart, range.dateEnd, radius]);

  const onDateIntervalChange = (value: string[]) => {
    const [from, to] = value;

    const rangeItem: IDateTimeRange = {
      dateStart: from,
      dateEnd: to,
    };

    // @ts-ignore
    setRange(rangeItem);
  };

  return (
    <>
      <ButtonIco
        isActive={isActive}
        onClick={toggleComponent}
        popoverProps={popOverProps}
      >
        <TrafficIco />
      </ButtonIco>

      {isActive ? (
        <>
          <GraphSettingsPanel
            onChange={setMergeMethod}
            mergeMethod={mergeMethod}
            dateStart={range.dateStart}
            dateEnd={range.dateEnd}
            onDateIntervalChange={onDateIntervalChange}
          />
          <GraphVisualizer map={mapProxy} dataset={dataset} />
        </>
      ) : null}
    </>
  );
};

export default observer(TrafficGraphButton);
