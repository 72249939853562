import { SpinProps } from 'antd/lib';

import { COLORS } from '../../../../constants/colorsConstants';

const {
  SUCCESS,
  WARNING,
  DANGER,
  PURPLE,
  TURQUOISE,
  BLUE900,
  BROWN_RED,
  GREEN_DARK,
} = COLORS;

export const COLORS_SETS = [
  SUCCESS,
  WARNING,
  DANGER,
  PURPLE,
  TURQUOISE,
  BLUE900,
  BROWN_RED,
  GREEN_DARK,
];

export const CIRCLE_WEIGHT = 20;

export const spinProps: SpinProps = {
  size: 'large',
};

export const HALF_PIE_ANGLE = { start: 180, end: 0 };
export const PIE_ANGLE = { start: 0, end: 360 };
export const DEFAULT_INDEX = 0;
export const PADDING_ANGLE = 5;

export const CIRCLE_MARGIN = {
  left: 100,
  bottom: 40,
};
