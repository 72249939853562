import type { FeatureLike } from 'ol/Feature';
import { toLonLat } from 'ol/proj';
import RenderFeature from 'ol/render/Feature';

import { MultipleClusters } from '../../../stores/clustersStore/clustersStore.model';

export const getSelectedMultipleCluster = (
  featuresObj: FeatureLike
): MultipleClusters => {
  const geom = featuresObj.getGeometry() as RenderFeature;
  const coordinate = toLonLat(geom?.getFlatCoordinates() ?? []);

  return {
    coordinate,
    cluster: featuresObj,
    isClusterClicked: true,
  };
};
