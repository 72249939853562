import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { FiMapPin } from 'react-icons/fi';

import rootStore from '../../../../../../../../stores/rootStore/rootStore';
import handleCenterZoom from '../../../../../../../Map/helpers/zoomHandlers/handleCenterZoom';
import ButtonOnlyIcon from '../../../../../../../ui-kit/ButtonOnlyIcon/ButtonOnlyIcon';

import styles from './ActionButtons.module.scss';

const POPOVER_CONTENT = 'Убрать СО из группы';

interface ActionButtonsProps {
  id: number;
}

const ActionButtons: FC<ActionButtonsProps> = ({ id }) => {
  const { getCoordinatesById } = rootStore.mapDataStore;
  const { setSelectedFeatureSomeArray } = rootStore.scriptsControlStore;

  const handleDeleteTL = () => setSelectedFeatureSomeArray(id);
  const handleZoomToTL = () => handleCenterZoom(getCoordinatesById(id));

  return (
    <div className={styles.icoBlock}>
      <ButtonOnlyIcon
        onClick={handleZoomToTL}
        isSmall
        tip={'Поиск объекта на карте'}
      >
        <FiMapPin />
      </ButtonOnlyIcon>
      <ButtonOnlyIcon
        onClick={handleDeleteTL}
        isSmall
        isDanger
        tip={POPOVER_CONTENT}
      >
        <AiOutlineDelete />
      </ButtonOnlyIcon>
    </div>
  );
};

export default observer(ActionButtons);
