/* eslint-disable camelcase */
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { COLORS } from '../../../constants/colorsConstants';
import { findById } from '../../../helpers/findBy';
import rootStore from '../../../stores/rootStore/rootStore';
import {
  IInfoPanelData,
  MapActions,
} from '../../../stores/uiStore/uiStore.model';
import { System } from '../../../ts/enums/enums';
import { getDeviceColor } from '../../MapBtns/MapInfoWrapper/InfoBtn/ContentInfo/helpers/getMapInfo';
import { getTLIcon } from '../../Mapper/helpers/getStyle';
import TLIcon from '../PhaseCircleOverlay/TLIcon/TLIcon';

import { TLIconProps } from './PointUdsOverlay.model';

const { PRIMARY, WHITE } = COLORS;

const LinkedTLIcon: FC<TLIconProps> = ({ tlId }) => {
  const { markers, setInfoData, infoData } = rootStore.uiStore;
  const { tls } = rootStore.mapDataStore;

  const tl = findById(tls, tlId);

  if (!markers.isLights || !tl || !tlId) return null;

  const { id, longitude, latitude, tlStatus, dai_jdt83 } = tl;
  const { mode, state } = tlStatus;

  const tlInfoData: IInfoPanelData = {
    system: System.Lights,
    coordinate: [longitude, latitude],
    id,
  };

  const statusFill = getDeviceColor(state);
  const { ico: Icon, borderColor } = getTLIcon(mode);

  const iconProps = dai_jdt83?.tlMain;
  const isActiveTL = tlId === infoData?.id;
  const newData = !isActiveTL ? tlInfoData : null;

  const handleTL = () => setInfoData(newData, MapActions.NoActions);

  if (!iconProps) return null;
  const { iconOffsetX, iconOffsetY } = iconProps;

  const fill = isActiveTL ? PRIMARY : statusFill;

  return (
    <TLIcon
      isVisible
      offsetX={iconOffsetX}
      offsetY={iconOffsetY}
      onClick={handleTL}
      fill={fill}
      Icon={Icon}
      borderColor={borderColor ?? WHITE}
      id={tlId}
    />
  );
};

export default observer(LinkedTLIcon);
