import { TObjMap } from '../../stores/mapDataStore/mapDataStore.model';
import { System } from '../../ts/enums/enums';
import { ICamera } from '../../ts/models/camera.model';
import { TDeviceInfo } from '../../ts/models/mapObject.model';

export interface InfoMainProps {
  currentObject: TObjMap;
  system: System;
  id: number;
}

export interface PointUdsInfoProps {
  deviceInfo: TDeviceInfo;
}

export const isCamera = (mapObj: TObjMap): mapObj is ICamera =>
  Object.hasOwn(mapObj, 'srcId');
