import { TlMode } from '../ts/models/tl.model';

import { DEVICES_INFO } from './devicesConstants';

export const TL_STATUS_COLORS = {
  DEFAULT: 'default',
  GREEN: 'green',
  YELLOW: 'yellow',
  RED: 'red',
  GREY: 'grey',
};

const { DEFAULT, GREEN, YELLOW, RED, GREY } = TL_STATUS_COLORS;

type TCom = Record<string, TlMode>;

export const COMMANDS_MODE_WORK: TCom = {
  Work: 1,
  ManualControlVPU: 3,
  TechnologicalMode: 4,
  YellowFlash: 5,
  AllRed: 6,
  AdaptiveControl: 9,
  CoordinatedManagement: 10,
  localAdaptiveControl: 11,
} as const;

export const COMMANDS_MODE_NOT_ACTIVE: TCom = {
  Off: 0,
} as const;

export const COMMANDS_MODE_ERRORS: TCom = {
  Critical: 2,
  UnknownMode: 255,
} as const;

export const COMMANDS_MODE_REMOTE_CONTROL: TCom = {
  RemoteControl: 7,
} as const;

export const COMMANDS_MODE_NUM: TCom = {
  ...COMMANDS_MODE_WORK,
  ...COMMANDS_MODE_NOT_ACTIVE,
  ...COMMANDS_MODE_ERRORS,
  ...COMMANDS_MODE_REMOTE_CONTROL,
} as const;

type TTL_STATUS = Readonly<Record<TlMode, string>>;

export const TL_STATUS: TTL_STATUS = {
  0: GREY,
  1: GREEN,
  2: GREY,
  3: GREEN,
  4: GREEN,
  5: YELLOW,
  6: RED,
  7: DEFAULT,
  9: DEFAULT,
  10: GREEN,
  11: GREEN,
  255: GREY,
};

export const TL_STATUS_COLORS_HEX = {
  DEFAULT: '#37B571',
  GREEN: '#37B571',
  YELLOW: '#ECAF12',
  RED: '#E35744',
  GREY: '#84879b',
  BLUE: '#1573FF',
};

export const TL_STATUS_HEX: TTL_STATUS = {
  0: TL_STATUS_COLORS_HEX.GREY,
  1: TL_STATUS_COLORS_HEX.GREEN,
  2: TL_STATUS_COLORS_HEX.GREY,
  3: TL_STATUS_COLORS_HEX.BLUE,
  4: TL_STATUS_COLORS_HEX.BLUE,
  5: TL_STATUS_COLORS_HEX.YELLOW,
  6: TL_STATUS_COLORS_HEX.RED,
  7: TL_STATUS_COLORS_HEX.BLUE,
  9: TL_STATUS_COLORS_HEX.BLUE,
  10: TL_STATUS_COLORS_HEX.BLUE,
  11: TL_STATUS_COLORS_HEX.BLUE,
  255: TL_STATUS_COLORS_HEX.GREY,
};

const { NotConnection, Delete, NotAvailable, Undefined } = DEVICES_INFO.STATE;

export const TL_NOT_CONTROL_STATUSES = [
  NotConnection,
  Delete,
  NotAvailable,
  Undefined,
].map(({ com }) => com);
