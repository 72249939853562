import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import rootStore from '../../../../../stores/rootStore/rootStore';
import NoData from '../../../../ui-kit/Errors/NoData/NoData';
import MainContent from '../MainContent/MainContent';
import Mapper from '../Mapper/Mapper';
import Title from '../Title/Title';

const ContentCard = () => {
  const { deviceInfo, setMonitoringValue } = rootStore.monitoringPanelStore;

  useEffect(() => {
    return () => setMonitoringValue('deviceInfo', null);
  }, [setMonitoringValue]);

  if (!deviceInfo) return <NoData textError="Устройство не найдено" />;

  return (
    <>
      <Title />
      <Mapper />
      <MainContent />
    </>
  );
};

export default observer(ContentCard);
