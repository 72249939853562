import { isNumber } from 'lodash';

const getCircleSec = (
  lsec: None | number,
  lcycle: U<number>,
  timeRcv: None | number
) => {
  if (!timeRcv || lsec === undefined || lsec === null || !isNumber(lcycle))
    return 0;

  const datesDiff = Date.now() / 1000 - timeRcv - lsec,
    iterAmount = Math.floor(datesDiff / lcycle);

  return Math.round(datesDiff - iterAmount * lcycle);
};

export default getCircleSec;
