import { TL_NAME_STORE } from '../../../constants/mapConstants';
import rootStore from '../../../stores/rootStore/rootStore';
import { MapObjectFull } from '../../../ts/enums/enums';

// TODO: set type
const handleTargetType = (type: any) => {
  switch (type) {
    case MapObjectFull.trafficLights:
      return TL_NAME_STORE;
    default:
      return type;
  }
};

// TODO: set type
const handleSocketMessage = (message: any) => {
  const { mapDataStore } = rootStore;

  const {
    change: { path, value },
    lastUpdate,
  } = message;

  const incomingType = path.shift();
  const targetId = path.shift();
  let targetType = handleTargetType(incomingType);
  const key = targetType as keyof typeof mapDataStore;

  if (!mapDataStore[key]) {
    targetType = undefined;
    console.error(`Failed update socket. Received unknown key: ${key}.`);
  }

  return {
    lastUpdate,
    targetType,
    targetId,
    value,
    path,
  };
};

export default handleSocketMessage;
