import { WEATHER_ITEMS } from '../../../../apiGIS/constants/weather';
import {
  IWeatherDataset,
  IWeatherFeature,
} from '../../../Map/IWeatherTimeModel';

export enum WeatherPropertyNames {
  temperature = 'temp',
  humidity = 'humidity',
  pressure = 'pressure',
  precipitation = 'precipitation',
  windSpeed = 'windSpeed',
  dewPoint = 'dewPoint',
  iceProbability = 'iceProbability',
}

export interface IAverageValue {
  value: string | number | undefined;
  label?: string;
  units?: string;
}

export const averageValue = (
  datasets: IWeatherDataset[],
  time: string,
  propertyName: WeatherPropertyNames
): IAverageValue => {
  const dataset = datasets.find((element) => element.time === time);

  if (dataset) {
    const { features } = dataset;

    let value = 0;

    const item = WEATHER_ITEMS.find(
      (element) => element.propertyName === propertyName
    );

    features.forEach((feature: IWeatherFeature) => {
      const { attributes } = feature;

      const featureValue = attributes[propertyName] || 0;

      value += featureValue;
    });

    value = value / features.length;

    const label = value.toFixed(1).toString();

    return {
      value,
      label,
      units: item?.units,
    };
  }

  return {
    value: 0,
    label: '',
    units: '',
  };
};
