import { timeToDuration } from '../../components/MapBtns/SearchButton/helpers';
import { SECOND } from '../../constants/constants';
import { geomToLatLon } from '../geometry/helpers';
import { ISearchItem } from '../models/search';
import { IDirectionPoint, IDirectionResult } from '../models/tasks/tasks.model';
import { getDirectionVariants } from '../tasks/direction';

export const getDirections = async (
  directionPoint1: IDirectionPoint,
  directionPoint2: IDirectionPoint
) => {
  let variants = await getDirectionVariants(directionPoint1, directionPoint2);

  variants = variants.map((variant) => {
    const { instructions } = variant;

    let timeSummary = 0;
    let distance = 0;

    instructions.forEach((instruction) => {
      timeSummary += instruction.time * SECOND;
      distance += instruction.distance;
    });

    variant.distance = Number(distance.toFixed(2));

    variant.time = timeToDuration(timeSummary);

    return variant;
  });

  return variants;
};

export const getDirectionsBySearchPoints = async (
  point1: ISearchItem,
  point2: ISearchItem
): Promise<IDirectionResult[]> => {
  const { feature: feature1 } = point1;
  const { feature: feature2 } = point2;

  if (feature1 && feature2) {
    const p1 = geomToLatLon(feature1.getGeometry());
    const p2 = geomToLatLon(feature2.getGeometry());

    if (!p1 || !p2) {
      return [];
    }

    const [lon1, lat1] = p1;
    const [lon2, lat2] = p2;

    const directionPoint1: IDirectionPoint = {
      x: lon1,
      y: lat1,
      address: point1.title,
    };

    const directionPoint2: IDirectionPoint = {
      x: lon2,
      y: lat2,
      address: point2.title,
    };

    return await getDirections(directionPoint1, directionPoint2);
  }

  return [];
};
