import { Item } from '../../../../../InfoPanel/ClusterInfo/model/cluster.model';
import TabLabel from '../../../../../InfoPanel/ClusterInfo/TabLabel/TabLabel';
import AdapterInfo from '../AdapterInfo/AdapterInfo';
import GeneralInfo from '../GeneralInfo/GeneralInfo';
import VideoCard from '../VideoCard/VideoCard';

export const DEVICE_CARD_TAB_ITEMS: Item[] = [
  {
    label: <TabLabel tabName="Основная информация" />,
    key: '1',
    children: <GeneralInfo />,
  },
  {
    label: <TabLabel tabName="Адаптер" />,
    key: '2',
    children: <AdapterInfo />,
  },
  {
    label: <TabLabel tabName="Видеонаблюдение" />,
    key: '3',
    children: <VideoCard />,
  },
];
