import { Fill, Icon, RegularShape, Stroke, Style } from 'ol/style';
import ImageStyle from 'ol/style/Image';
import { useEffect, useState } from 'react';

import FillSelector from './FillSelector';
import { hasFill, hasImage, hasStroke } from './helpers';
import ImageSelector from './ImageSelector';
import StrokeSelector from './StrokeSelector';

const StyleSelector = (props: {
  style?: Style;
  geometryType?: string | undefined;
  onChange: (value: Style | undefined | null) => void;
}) => {
  const [fill, setFill] = useState(props.style?.getFill());
  const [stroke, setStroke] = useState(props.style?.getStroke());

  const [image, setImage] = useState<
    ImageStyle | RegularShape | Icon | undefined | null
  >(props.style?.getImage() || undefined);

  useEffect(() => {
    const style = new Style({});

    fill && style.setFill(fill);
    stroke && style.setStroke(stroke);
    image && style.setImage(image);

    props.onChange(style);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fill, stroke, image]);

  const geometryType = props.geometryType || 'Polygon';

  const updateFill = (value: Fill | undefined) => {
    setFill(value);
  };

  const updateStroke = (value: Stroke | undefined) => {
    setStroke(value);
  };

  const updateImage = (
    value: ImageStyle | RegularShape | Icon | undefined | null
  ) => {
    setImage(value);
  };

  return (
    <>
      {hasFill(geometryType) ? (
        <FillSelector fill={fill} onChange={updateFill} />
      ) : null}

      {hasStroke(geometryType) ? (
        <StrokeSelector stroke={stroke} onChange={updateStroke} />
      ) : null}

      {hasImage(geometryType) ? (
        <ImageSelector image={image} onChange={updateImage} />
      ) : null}
    </>
  );
};

export default StyleSelector;
