import { List } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';

import useScrollToElement from '../../../../../../hooks/useScrollToElement';
import rootStore from '../../../../../../stores/rootStore/rootStore';
import { IDataList } from '../../../model/monitoring.model';

import DeviceItem from './DeviceItem/DeviceItem';

import styles from './DevicesList.module.scss';

interface IDevicesList {
  data: IDataList[];
  addressLoading: boolean;
}

const DevicesList: FC<IDevicesList> = ({ data, addressLoading }) => {
  const { activeSystemMonitoring } = rootStore.monitoringPanelStore;

  const { blocksRefsArray, addToRefs, handleScroll } = useScrollToElement();

  useEffect(() => {
    blocksRefsArray.current = [];
  }, [activeSystemMonitoring, blocksRefsArray]);

  return (
    <div className={styles.listContainer}>
      <List
        dataSource={data}
        renderItem={(item) => (
          <DeviceItem
            {...item}
            key={item.id}
            addressLoading={addressLoading}
            addToRefs={addToRefs}
            handleScroll={handleScroll}
          />
        )}
      />
    </div>
  );
};

export default observer(DevicesList);
