export function typeGuard<T>(value: unknown, type: any): value is T {
  let result = false;

  for (const item in type) {
    if (value === type[item]) {
      result = true;
    }
  }

  return result;
}
