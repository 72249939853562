import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import { System } from '../../../ts/enums/enums';
import { TWidgetsOptionalData } from '../../Panel/StatisticsPanel/MonitoringCard/models/monitoring.model';
import MonitoringCard from '../../Panel/StatisticsPanel/MonitoringCard/MonitoringCard';
import SystemItem from '../../Panel/StatisticsPanel/SystemsButtonsList/SystemCard/SystemItem/SystemItem';
import NoData from '../../ui-kit/Errors/NoData/NoData';
import { ALL_SYSTEM_TITLE } from '../constants/constants';

import AdaptersCard from './AdaptersCard/AdaptersCard';
import DeviceCard from './DeviceCard/DeviceCard';
import { getCorrectNameSystem } from './helpers/getCorrectNameSystem';
import { getStatusData } from './helpers/getStatusData';
import SystemMonitoring from './SystemMonitoring/SystemMonitoring';

import styles from './Monitoring.module.scss';

const Monitoring = () => {
  const { activeSystemMonitoring } = rootStore.monitoringPanelStore;
  const { widgetsFromMap } = rootStore.monitoringPanelStore;

  const [statusData, setStatusData] = useState<U<TWidgetsOptionalData>>();
  const [systems, setSystems] = useState<System[]>([]);
  const [isEmptyData, setIsEmptyData] = useState(false);

  useEffect(() => {
    if (!activeSystemMonitoring) return;

    const { isNoData, ...statusDataRes } = getStatusData(
      widgetsFromMap,
      activeSystemMonitoring
    );

    const systemsData = Object.values(statusDataRes).map((el) => el.system);

    setSystems(systemsData);
    setStatusData(statusDataRes);
    setIsEmptyData(isNoData);
  }, [activeSystemMonitoring, widgetsFromMap]);

  if (!activeSystemMonitoring) return null;

  const title = getCorrectNameSystem(activeSystemMonitoring);
  const isAllSystems = activeSystemMonitoring === ALL_SYSTEM_TITLE;
  const titleSystem = isAllSystems ? title : `Подсистема ${title}`;
  const titleCard = !isAllSystems ? title : `всех подсистем`;

  const systemIcoStyle = classNames(styles.systemBlock, {
    [styles.allSystem]: isAllSystems,
  });

  return (
    <div className={styles.container}>
      <div className={styles.containerRowTitle}>
        <SystemItem
          system={activeSystemMonitoring}
          classNameItem={systemIcoStyle}
          isHover={false}
        />
        <h2>{titleSystem}</h2>
      </div>
      {!isEmptyData ? (
        <div className={styles.containerRow}>
          <div className={styles.column} key={activeSystemMonitoring}>
            <MonitoringCard
              title={`Состояние оборудования ${titleCard}`}
              statusData={statusData}
            />
            <AdaptersCard title={`Состояние адаптеров ${titleCard}`} />
          </div>
          <SystemMonitoring systemsList={systems} />
          <DeviceCard />
        </div>
      ) : (
        <NoData
          textError="В данной подсистеме нет устройств"
          isNeedBackground
        />
      )}
    </div>
  );
};

export default observer(Monitoring);
