/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Tooltip } from 'antd';
import { FC } from 'react';
import { FaUserAlt } from 'react-icons/fa';

import { sliceTitleBy } from '../../../../helpers/sliceTitle';
import { IUserData } from '../../../../stores/userDataStore/userDataStore';

import styles from './Header.module.scss';

interface IHeader {
  user: IUserData;
  handleAvatarClick: () => void;
}
const NAME_LENGTH = 7;
const POINTS_LENGTH = 3;

const Header: FC<IHeader> = ({ user, handleAvatarClick }) => (
  <div className={styles.head}>
    <div className={styles.header}>
      <div className={styles.avatar} onClick={handleAvatarClick}>
        <FaUserAlt className={styles.icon} size={50} />
      </div>
      <h2 className={styles.title}>
        {user.name.length > NAME_LENGTH + POINTS_LENGTH ? (
          <Tooltip placement="top" title={user.name}>
            {sliceTitleBy(user.name, NAME_LENGTH)}
          </Tooltip>
        ) : (
          user.name
        )}
      </h2>
    </div>
  </div>
);

export default Header;
