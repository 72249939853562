import { FC } from 'react';

import { IDataList } from '../../../../../model/monitoring.model';
import SkeletonInput from '../../../../../SkeletonInput/SceletonInput';
import DeviceIcon from '../../../DevicesList/DeviceIcon/DeviceIcon';

import styles from './DevicePopoverContent.module.scss';

type IDevicePopoverContentProps = Omit<IDataList, 'id' | 'system'>;

interface DevicePopoverContentProps extends IDevicePopoverContentProps {
  isActive: boolean;
  addressLoading: boolean;
}

const DevicePopoverContent: FC<DevicePopoverContentProps> = ({
  title,
  avatar,
  description,
  status,
  isActive,
  address,
  addressLoading,
}) => {
  const addressValue = address ?? 'Не найден';

  return (
    <div className={styles.container}>
      <div className={styles.containerRow}>
        <DeviceIcon status={status} iconSystem={avatar} />
        <p>{title}</p>
      </div>
      <p>{description}</p>
      <p>
        Адрес: <SkeletonInput loading={addressLoading} value={addressValue} />
      </p>
      {!isActive && (
        <p className={styles.special}>
          Нажмите, чтобы открыть карточку устройства
        </p>
      )}
    </div>
  );
};

export default DevicePopoverContent;
