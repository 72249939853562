import { Style } from 'ol/style';

import { VectorStyleItem } from '../../requests/getVectorTileStyle';

import { getStyle } from './index';

const styleFunction = (styles: VectorStyleItem[]) => {
  const mem: Record<string, Style | undefined> = {};

  return function (feature: any, resolution: number): Style | undefined {
    const properties = feature.getProperties();
    const featureKey = `${Object.keys(properties)
      .map((key) => properties[key])
      .join('_')}_${resolution}`;

    if (mem[featureKey]) {
      return mem[featureKey];
    }

    const item: VectorStyleItem | undefined = styles.find(
      (element: VectorStyleItem) => {
        const { filter } = element;

        for (const key in filter) {
          const value = filter[key];

          if (properties[key] !== value) {
            return false;
          }
        }

        return true;
      }
    );

    if (!item) {
      return undefined;
    }

    const { style } = item;
    const value: Style | undefined = getStyle({
      params: style,
      feature,
      resolution,
    });

    mem[featureKey] = value;

    return value;
  };
};

export { styleFunction };
