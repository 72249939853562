import { observer } from 'mobx-react-lite';
import { RefObject } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import { Scene } from '../../../ts/models/MapObjects/detectors.model';
import useResize from '../../NewSignalProgram/hooks/useResize';
import { SIZE_FACTOR } from '../constants/constants';
import { useDrawGrid } from '../hooks/useDrawGrid';

import styles from './Canvas.module.scss';

interface CameraRefProps {
  cameraWrapperRef: RefObject<HTMLDivElement>;
}

type CanvasProps<T> = {
  width?: T;
  scene: Scene;
  isShowAllInfo?: boolean;
} & (T extends number ? Partial<CameraRefProps> : CameraRefProps);

const Canvas = <T extends U<number> = undefined>({
  cameraWrapperRef,
  width,
  scene,
  isShowAllInfo,
}: CanvasProps<T>) => {
  const { isPanel } = rootStore.uiStore;

  const { width: widthCanvas } = useResize({
    isPanel,
    wrapRef: cameraWrapperRef,
  });

  const correctWidth = width ?? Math.floor(widthCanvas);

  const height = correctWidth / SIZE_FACTOR;
  const { canvasRef } = useDrawGrid({
    width: correctWidth,
    height,
    scene,
    isShowAllInfo,
  });

  return (
    <canvas
      data-testid="canvas"
      ref={canvasRef}
      width={correctWidth}
      height={height}
      className={styles.canvas}
    />
  );
};

export default observer(Canvas);
