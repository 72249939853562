import { TitleTable } from '../../../constants/constants';
import { validateTableSgnProgram } from '../../../EditableCell/constants/constants';

export const getTextValidate = (title: TitleTable) => {
  const {
    time,
    spanTime,
    spanGreenBlinkTime,
    spanYellowTime,
    spanRedYellowTime,
  } = TitleTable;
  const { minFlash, colorFlash } = validateTableSgnProgram;

  const requirementsText = ['Длина должна быть больше: '];

  switch (title) {
    case time:
      requirementsText[0] =
        'Минимальная длительность основного такта может различаться у каждой фазы';
      break;
    case spanTime:
      requirementsText[0] =
        'Минимальная длительность пром такта может различаться у каждой фазы';
      break;
    default:
      requirementsText[0] += `${colorFlash}с. либо = ${minFlash}c.`;
      requirementsText.push(
        `Сумма длин полей ('${spanGreenBlinkTime}', '${spanYellowTime}', '${spanRedYellowTime}') не может быть больше поля - '${spanTime}'`
      );
  }

  return requirementsText;
};
