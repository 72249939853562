import { StreamType } from '../../../../../api/mapObjects/cameras/cameras.zod';
import { VideoCameraProps } from '../../../../VideoCamera/VideoCamera';

export const VIDEO_CAMERA_PROPS: Readonly<VideoCameraProps> = {
  isWatermark: false,
  cameraData: {
    videoSources: [
      {
        defaultView: true,
        id: 'stream_1',
        name: 'Поток модели 3D',
        streamType: StreamType.Mjpeg,
        //temporary for display
        url: 'http://localhost:1234/mjpeg',
      },
    ],
    isMapAction: false,
  },
  videoActions: {
    isDtLanesBtn: false,
    isInfoBtn: false,
    isPinBtn: false,
    isThreadsBtn: false,
    isUpdateBtn: false,
    isMenuBtn: false,
  },
};

export enum ThreadType {
  D3 = '3D',
  D2 = '2D',
}
