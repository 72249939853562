import Feature from 'ol/Feature';

const getIsFeatureOnArray = (featuresArr: Feature[], idItem: number) =>
  featuresArr.some((feature) => feature.get('id') === idItem);

export const getClusterWithInfoDataFeature = (
  features: Feature[],
  id: number
) =>
  features.find((el) => {
    const featArr = el.get('features');
    const isCluster = featArr.length > 1;

    return isCluster && getIsFeatureOnArray(el.get('features'), id);
  });
