import { Button, Popover } from 'antd';
import { FC } from 'react';
import { IoMdRefresh } from 'react-icons/io';

import rootStore from '../../../../../stores/rootStore/rootStore';
import { IQueryParamRequest } from '../../../../../ts/models/table.model';
import { ITable } from '../../Table';

import styles from './RefreshButton.module.scss';

interface ManualUpdateButtonProps {
  queryParamsRequest: IQueryParamRequest;
  refreshData: ITable['refreshData'];
}

const RefreshButton: FC<ManualUpdateButtonProps> = ({
  queryParamsRequest,
  refreshData,
}) => {
  const { interfaceTipsDelay } = rootStore.uiStore;

  const popoverProps = {
    placement: 'left',
    tipsDelay: interfaceTipsDelay,
    content: 'Обновить данные',
  } as const;

  return (
    <Popover {...popoverProps}>
      <Button
        onClick={() => refreshData(queryParamsRequest)}
        className={styles.refreshButton}
        type={'primary'}
        icon={<IoMdRefresh size={20} />}
      />
    </Popover>
  );
};

export default RefreshButton;
