import classNames from 'classnames';
import { FC } from 'react';

import { TValueMeteo } from '../../../../Mapper/TooltipContent/MapIconContent/MeteoInfo/helpers/getMeteoInfo';
import MeteoItem from '../../../../Mapper/TooltipContent/MapIconContent/MeteoInfo/MeteoItem/MeteoItem';
import NoData from '../../../../ui-kit/Errors/NoData/NoData';

import styles from './MeteoContainer.module.scss';

interface IMeteoContainerProps {
  meteoInfo: NonNullable<TValueMeteo>[];
  isNeedBg?: boolean;
  isCenter?: boolean;
}

const MeteoContainer: FC<IMeteoContainerProps> = ({
  meteoInfo,
  isNeedBg = true,
  isCenter = false,
}) => {
  const containerStyle = classNames({
    [styles.container]: true,
    [styles.bg]: isNeedBg,
    [styles.center]: isCenter,
  });

  if (!meteoInfo.length) return <NoData />;

  return (
    <div className={containerStyle}>
      {meteoInfo.map(({ id, ...info }) => (
        <MeteoItem key={id} {...info} isVertical />
      ))}
    </div>
  );
};

export default MeteoContainer;
