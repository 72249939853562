import { MINUTE, SECOND } from './constants';

export const DEFAULT_UPDATE_TIME = 5 * MINUTE;
export const AUTO_UPDATE_STATIC = 5 * SECOND;
export const UPDATE_PREDICT_CHART_INTERVAL = 15 * MINUTE;
export const UPDATE_WEATHER_TIME = 15 * MINUTE;

export const QUERY_GROUP_KEY = {
  POINT_UDS: ['pointUds'],
};

export const QUERY_KEY = {
  VEHICLES_AMOUNT: [...QUERY_GROUP_KEY.POINT_UDS, 'vehicles', 'amount'],
  STATISTIC_MENU: 'statisticMenu',
  DETECTORS_DATA: 'detectorsData',
  DETECTORS_PREDICT_DATA: 'detectorsPredictData',
  STATISTIC_ADAPTERS: 'statistic',
  DEVICE_INFO: 'deviceInfo',
  USER_INFO: 'userInfo',
  DETECTORS_STATE: 'detectorsState',
  WEATHER_INFO: 'weatherInfo',
};
