import { Card } from 'antd';

import StatusBlock from './StatusBlock/StatusBlock';

import styles from './UncontrolledStatusCard.module.scss';

const UncontrolledStatusCard = () => {
  return (
    <Card className={styles.card}>
      <div className={styles.container}>
        <h3>Статусы СО:</h3>
        <StatusBlock />
      </div>
    </Card>
  );
};

export default UncontrolledStatusCard;
