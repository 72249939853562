/* eslint-disable camelcase */
import { IS_DEV } from '../../../constants/constants';
import { sendError } from '../../../helpers/sendError';
import { getAppPathHeaders } from '../../helpers/getAppPathHeaders';
import http from '../../http';
import tryCatchWrapper from '../../tryCatchWrapper';
import { SERVER } from '../constants';

import {
  IRegionData,
  RegionDataOptionalSchema,
  RegionDataRequiredSchema,
  UrlConfigurationsScheme,
  VideoStreamsProxyListScheme,
} from './web.zod';

const REGION = SERVER + '/web/region';

const URL_CONFIGURATIONS = SERVER + '/web/url-configurations';
const URL_DNS_PROXY_VIDEOSTREAM = SERVER + '/web/dns-proxy-videoservers';

export const web = {
  fetchRegionById: async (
    regionId: number,
    appPath: string
  ): Promise<IRegionData> => {
    const url = REGION + '/' + regionId;
    const res = await http.get(url, {
      headers: getAppPathHeaders(appPath),
    });

    const validate = RegionDataOptionalSchema.safeParse({
      licenceOptions: res.data?.licenceOptions,
      region_capital: res.data?.region_capital,
    });

    if (!validate.success) {
      const errorTitle = `Ошибка валидации регина с id: ${regionId}`;

      !IS_DEV && sendError(errorTitle, validate.error, appPath);
      console.error(errorTitle, validate.error);
    }

    const validateRequired = RegionDataRequiredSchema.parse(res.data);

    return {
      ...validateRequired,
      licenceOptions: res.data?.licenceOptions ?? [],
      region_capital: res.data?.region_capital ?? '',
    };
  },

  getUrlConfigurations: async (appPath: string) => {
    try {
      const res = await http.get(URL_CONFIGURATIONS, {
        headers: getAppPathHeaders(appPath),
      });

      return UrlConfigurationsScheme.parse(res.data);
    } catch (e: unknown) {
      console.error(e);

      return null;
    }
  },

  getVideoStreamsProxyList: async (regionId: number, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.get(URL_DNS_PROXY_VIDEOSTREAM, {
        params: { regionId },
        headers: getAppPathHeaders(appPath),
      });

      return VideoStreamsProxyListScheme.parse(res.data);
    }),
};
