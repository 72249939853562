import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import rootStore from '../../stores/rootStore/rootStore';
import { SidebarCode } from '../../ts/enums/userData';
import Logo from '../ui-kit/Logo/Logo';

import ColorTheme from './ColorTheme/ColorTheme';
import InfoBlock from './InfoBlock/InfoBlock';
import MainBlock from './MainBlock/MainBlock';
import ProfileBtn from './ProfileBtn/ProfileBtn';

import styles from './Sidebar.module.scss';

const { Profile, Theme } = SidebarCode;

function Sidebar() {
  const { isSidebar, setIsNot } = rootStore.uiStore;
  const { hasAccess } = rootStore.userDataStore;

  useEffect(() => setIsNot('isSidebar', true), []); // eslint-disable-line

  const asideStyle = classNames({
    [styles.container]: true,
    [styles.sidebar]: isSidebar,
  });

  return (
    <aside className={asideStyle}>
      <div className={styles.top}>
        <Logo isSmall />
        <InfoBlock />
      </div>

      <MainBlock />

      <div className={styles.bottom}>
        {hasAccess(Profile) && <ProfileBtn />}
        {hasAccess(Theme) && <ColorTheme />}
      </div>
    </aside>
  );
}

export default observer(Sidebar);
