export const STROKE_PATTERN_SOLID = '------------';

export const STROKE_PATTERNS = [
  { label: STROKE_PATTERN_SOLID, dash: null, value: null },
  { label: '- - - - - - ', dash: [10, 10, 10, 10], value: '10_10_10_10' },
  { label: '-- - -- - --', dash: [10, 5, 5, 5], value: '10_5_5_5' },
  { label: '-- -- -- -- ', dash: [10, 5, 10, 5], value: '10_5_10_5' },
  { label: '--- - --- - ', dash: [15, 5, 15, 5], value: '15_5_15_5' },
  { label: '---   ---   ', dash: [15, 15, 15, 15], value: '15_15_15_15' },
];

export const MIN_LABEL_STROKE_WIDTH = 0.1;
export const MAX_LABEL_STROKE_WIDTH = 10;
export const STEP_LABEL_STROKE_WIDTH = 0.1;
