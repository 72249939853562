import { Tag } from 'antd';
import { isNull } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { LibraryKeys } from '../../../../api/libraries/enums/enums';
import { TDeviceStatus } from '../../../../constants/devicesConstants';
import { findById } from '../../../../helpers/findBy';
import rootStore from '../../../../stores/rootStore/rootStore';
import { getStatusDevice } from '../../../Mapper/TooltipContent/MapIconContent/ModeInfo/helpers/getStatusInfo';
import { getDeviceStatusColor } from '../LinkedsInfo/LinkedDeviceItem/StatusColor/helpers/getColorDevice';

import styles from './InfoRow.module.scss';

const { SystemTypeId } = LibraryKeys;

interface IInfoRow {
  modeName: string;
  currentId: number;
  isLights: boolean;
  deviceStatusId: TDeviceStatus;
  scSystemTypeId: number;
}

const InfoRow: FC<IInfoRow> = ({
  modeName,
  currentId,
  isLights,
  deviceStatusId,
  scSystemTypeId,
}) => {
  const systemTypeDict = rootStore.uiStore.dictionaries[SystemTypeId];

  const status = getStatusDevice(deviceStatusId).dsc;
  const color = getDeviceStatusColor(deviceStatusId);

  const adapterName = findById(systemTypeDict, scSystemTypeId)?.name || null;

  return (
    <div className={styles.infoRow}>
      <p>
        Состояние оборудования: <Tag color={color}>{status}</Tag>
      </p>
      {isLights && !isNull(adapterName) && (
        <>
          <p>Режим СО: {modeName}</p>
          <p>
            {' '}
            ID в {adapterName}: {currentId}{' '}
          </p>
        </>
      )}
    </div>
  );
};

export default observer(InfoRow);
