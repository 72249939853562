import { ItemType } from 'antd/lib/menu/hooks/useItems';

import {
  ActiveStream,
  VideoSourcesCamera,
} from '../../../../models/VideoCamera.model';

export const getStreamItems = (
  videoSources: VideoSourcesCamera[],
  activeStream: ActiveStream,
  labelStyle: string
): ItemType[] => {
  const { id } = activeStream;

  return videoSources.map((videoSource) => {
    const isActive = videoSource.id === id;

    return {
      key: videoSource.id,
      label: videoSource.name,
      className: isActive ? labelStyle : undefined,
      disabled: isActive,
    };
  });
};
