import { ITimePoints } from '../../../../../ts/models/signalPrograms.model';

export const getCardData = (phase: ITimePoints) => {
  return [
    {
      label: 'Т осн',
      value: phase.length - phase.time,
    },
    {
      label: 'Т пром',
      value: phase.time,
    },
    {
      label: 'Т фазы',
      value: phase.length,
    },
  ];
};
