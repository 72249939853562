import { getPointUdsByDeviceId } from '../../../../../../../helpers/mapHelpers/pointUds/getPointUdsByDeviceId';
import rootStore from '../../../../../../../stores/rootStore/rootStore';
import { System } from '../../../../../../../ts/enums/enums';
import getDeviceBySystem from '../../../Mapper/CrossroadSchema/helpers/getDeviceBySystem';

const { Cameras } = System;

const getLinkedCameras = () => {
  const { deviceInfo } = rootStore.monitoringPanelStore;

  if (!deviceInfo) return { currentCamId: null, cameraIds: [] };

  const { id, system } = deviceInfo;

  const pointUds = getPointUdsByDeviceId(id, system);
  const device = getDeviceBySystem(id, system);

  const isCamera = system === Cameras;

  const cameraIds =
    (pointUds?.linkedDeviceIds ?? device?.linkedDeviceIds)?.cameras ?? [];

  if (isCamera) {
    return {
      currentCamId: id,
      cameraIds: [...new Set([id, ...cameraIds])],
    };
  }

  return {
    currentCamId: null,
    cameraIds,
  };
};

export default getLinkedCameras;
