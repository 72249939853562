import { getObjectEntries } from '../../../../../../helpers/getObjectEntries';
import {
  isDateDJSValues,
  isRangeDateDJSValues,
} from '../../../../../../ts/models/table.model';

const getFormattedDefaultValues = (defaultValues: {
  [key: string]: unknown;
}) => {
  return getObjectEntries(defaultValues).reduce(
    (acc: { [key: string]: unknown }, [key, value]) => {
      if (isRangeDateDJSValues(value)) {
        const { from, to } = value;

        acc[key] = from && to ? [from, to] : null;

        return acc;
      }

      if (isDateDJSValues(value)) {
        const { value: dateValue } = value;

        acc[key] = dateValue;

        return acc;
      }

      acc[key] = value;

      return acc;
    },
    {}
  );
};

export default getFormattedDefaultValues;
