import rootStore from '../../../../stores/rootStore/rootStore';
import { System } from '../../../../ts/enums/enums';
import {
  CROSSROAD_TOOLTIPS,
  IDENTIFICATION_LAYERS_TOOLTIPS,
  LAYER_TOOLTIPS,
} from '../constants/constants';

export const getTabTooltips = (
  link: N<Partial<Record<System, number[]>>>,
  isDisabledCrossroads: boolean,
  isDisabledLayers: boolean
) => {
  const { isLayersOn } = rootStore.gisDataStore;

  const title = isDisabledCrossroads ? CROSSROAD_TOOLTIPS.INACTIVE_TL : '';

  const titleCrossroads = !link?.[System.Lights]
    ? CROSSROAD_TOOLTIPS.EMPTY
    : title;

  const gisLayerTooltip =
    isLayersOn && isDisabledLayers
      ? LAYER_TOOLTIPS.EMPTY
      : IDENTIFICATION_LAYERS_TOOLTIPS;

  return { titleCrossroads, gisLayerTooltip };
};
