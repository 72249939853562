/* eslint-disable camelcase */
import message from '../../../../components/ui-kit/Notification/Notification';
import { getAppPathHeaders } from '../../../helpers/getAppPathHeaders';
import http from '../../../http';
import tryCatchWrapper from '../../../tryCatchWrapper';
import { ITS_LAB } from '../coordination/coordination';

import { ItsTlsSchema } from './trafficObjects.zod';

const TLS = ITS_LAB + '/tl';
const REQUEST_COORD_PARAMS = {
  isExternalError: true,
};

const trafficObjectsApi = {
  getTLList: (appPath: string, isNeedNotifyError = true) =>
    tryCatchWrapper(
      async () => {
        const res = await http.get(TLS, {
          headers: getAppPathHeaders(appPath),
        });

        const validate = ItsTlsSchema.array().safeParse(res?.data);

        if (!validate.success) {
          isNeedNotifyError && message.error('INCORRECT_DATA_TYPE');

          return console.error(validate.error);
        }

        return res?.data;
      },
      {
        ...REQUEST_COORD_PARAMS,
        isHideErrorNotify: !isNeedNotifyError,
        errorMessage: 'GET_COORD_GROUP_ERROR',
        ignoreCodes: [423],
      }
    ),
};

export { trafficObjectsApi };
