import { observer } from 'mobx-react-lite';
import { IoIosClose } from 'react-icons/io';

import rootStore from '../../../../../../stores/rootStore/rootStore';
import ButtonOnlyIcon from '../../../../../ui-kit/ButtonOnlyIcon/ButtonOnlyIcon';

import styles from './FiltersClear.module.scss';

const FiltersClear = () => {
  const { statusFilteredMonitoring, setStatusFilteredMonitoring } =
    rootStore.monitoringPanelStore;

  const handleClose = () => {
    setStatusFilteredMonitoring(null);
  };

  if (!statusFilteredMonitoring.length) return null;

  return (
    <div className={styles.container}>
      <p> Сбросить все фильтры</p>
      <ButtonOnlyIcon onClick={handleClose} isSmall>
        <IoIosClose />
      </ButtonOnlyIcon>
    </div>
  );
};

export default observer(FiltersClear);
