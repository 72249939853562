import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { CSSProperties, FC } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';

import styles from './PhaseCircleImgWrapper.module.scss';

interface ImgProps {
  children?: U<JSX.Element>;
  style?: CSSProperties;
  isFixedSizeImg?: boolean;
}

const PhaseCircleImgWrapper: FC<ImgProps> = ({
  style,
  children,
  isFixedSizeImg = false,
}) => {
  const { isUnderCircleFill } = rootStore.pointsUdsStore;

  const zIndex = isUnderCircleFill && !isFixedSizeImg ? -1 : 0;

  const containerStyle = classNames({
    [styles.imgContainer]: true,
  });

  return (
    <div className={containerStyle} style={{ zIndex }}>
      <div className={styles.imgWrapper} style={style}>
        {children}
      </div>
    </div>
  );
};

export default observer(PhaseCircleImgWrapper);
