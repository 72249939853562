import { ADMIN_TITLE } from '../../../constants/adminConstants';
import rootStore from '../../../stores/rootStore/rootStore';
import { getIsAdminKeys } from '../../Panel/AdminPanel/helpers/getIsAdminKeys';
import { getSystemAdminType } from '../../Panel/AdminPanel/helpers/getSystemAdminType';

const getAdminHeader = () => {
  const { adminTableType } = rootStore.adminPanelStore;

  const type = getSystemAdminType(adminTableType);
  const adminType = getIsAdminKeys(type) ? type : null;

  const header = adminType ? ADMIN_TITLE[adminType] : ADMIN_TITLE.system;

  return { type, header };
};

export default getAdminHeader;
