import { observer } from 'mobx-react-lite';

import { BORDER_R_XS } from '../../../../constants/themeTokens';
import rootStore from '../../../../stores/rootStore/rootStore';
import ButtonsBlock from '../../../ui-kit/ButtonsBlock/ButtonsBlock';
import styles from '../../styles/BtnsBlock.module.scss';

import CircleBtn from './CircleBtn/CircleBtn';
import LanesBtn from './LanesBtn/LanesBtn';

const PointUdsBtns = () => {
  const { isCrossroadBorder } = rootStore.mapDataStore;
  const { isConstructor } = rootStore.constructorStore;

  if (!isCrossroadBorder || isConstructor) return null;

  return (
    <ButtonsBlock borderRadius={BORDER_R_XS} className={styles.btnsBlock}>
      <CircleBtn />
      <LanesBtn />
    </ButtonsBlock>
  );
};

export default observer(PointUdsBtns);
