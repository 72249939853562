import { observer } from 'mobx-react-lite';
import OLTileLayer from 'ol/layer/Tile';
import { FC, useEffect } from 'react';

import { BASEMAPS_PROPS } from '../../../apiGIS/constants/basemaps/utils';
import { SECOND } from '../../../constants/constants';
import { TMap } from '../../../stores/mapStore/mapStore.model';
import rootStore from '../../../stores/rootStore/rootStore';

interface TileLayerProps {
  source: any;
  zIndex?: number;
  map?: TMap;
  isNeedRefresh?: boolean;
}

const REFRESH_TTL = 30 * SECOND;

const TileLayer: FC<TileLayerProps> = ({
  source,
  zIndex = 0,
  map: uiMap,
  isNeedRefresh = false,
}) => {
  const map = uiMap === undefined ? rootStore.mapStore.map : uiMap;

  useEffect(() => {
    if (!map) {
      return;
    }

    const tileLayer = new OLTileLayer({
      source,
      zIndex,
    });

    tileLayer.set(
      BASEMAPS_PROPS.CATEGORY_PROPERTY,
      BASEMAPS_PROPS.CATEGORY_PROPERTY_VALUE
    );

    map.addLayer(tileLayer);
    tileLayer.setZIndex(zIndex);

    let refreshIntervalId: U<NodeJS.Timer> = undefined;

    if (isNeedRefresh) {
      refreshIntervalId = setInterval(() => {
        source.refresh();
      }, REFRESH_TTL);
    }

    return () => {
      if (map) {
        isNeedRefresh && clearInterval(refreshIntervalId);

        map.removeLayer(tileLayer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  return null;
};

export default observer(TileLayer);
