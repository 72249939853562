import { isEqual } from 'lodash';
import Feature from 'ol/Feature';
import { Point } from 'ol/geom';

import { FeatureDeviceProps, FeaturesTypes } from '../../../ts/enums/enums';

import { IFeaturesArr } from './getFeaturesCluster';

const { Id } = FeatureDeviceProps;

export const getFeaturesOverlaysInExtent = (
  featuresWithOverlays: IFeaturesArr[],
  features: Feature<Point>[],
  isOverlaysForAllFeatures: boolean,
  isOverlaysForClusters: boolean
) => {
  const featuresData = featuresWithOverlays.reduce(
    (acc: IFeaturesArr[], item) => {
      const { feature, isSelectFeature } = item;

      const featuresCluster: U<Feature<Point>[]> = feature.get(
        FeaturesTypes.Features
      );
      const featIds = featuresCluster?.map((el) => el.get(Id)) ?? [];

      const isOnExtent = features.some((el) => {
        const featClusterExtent: Feature<Point>[] =
          el.get(FeaturesTypes.Features) ?? [];
        const isCluster = featClusterExtent?.length > 1;

        if (!isOverlaysForClusters && isCluster) return false;

        if (isCluster) {
          const elIds = featClusterExtent.map((feat) => feat.get(Id));

          return isEqual(featIds, elIds);
        }

        return (
          featClusterExtent.at(0)?.get(FeatureDeviceProps.Id) ===
          feature.get(FeatureDeviceProps.Id)
        );
      });

      if (isSelectFeature || isOnExtent) {
        acc.push(item);
      }

      return acc;
    },
    []
  );

  const singleFeatures = features.reduce((acc: IFeaturesArr[], value) => {
    const features = value.get(FeaturesTypes.Features);

    if (features.length === 1) {
      const feature = features[0];

      if (!feature) return acc;

      const coord = feature.getGeometry()?.getCoordinates();

      acc.push({
        feature,
        coord,
      });
    }

    return acc;
  }, []);

  return isOverlaysForAllFeatures ? singleFeatures : featuresData;
};
