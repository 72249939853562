/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { isNumber } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import {
  DEFAULT_UPDATE_TIME,
  QUERY_KEY,
} from '../../../constants/queryConstants';
import rootStore from '../../../stores/rootStore/rootStore';
import { LaneDirectionKey } from '../../Constructor/TrafficLaneConstructor/TLaneConstructor.model';

import { INFO_BORDER, NO_DATA } from './constants/constants';
import fetchVehiclesAmount from './helpers/fetchVehiclesAmount';
import getStyles from './helpers/getStyles';
import LaneNumber from './LaneNumber/LaneNumber';
import { TLanesBlockProps } from './TLanesBlock.model';

import styles from './TrafficLanesBlock.module.scss';

const { ToCamera } = LaneDirectionKey;
const { VEHICLES_AMOUNT } = QUERY_KEY;

const TrafficLanesBlock: FC<TLanesBlockProps> = ({
  trafficLane,
  isTLCrossroad,
  directionKey = ToCamera,
  rate,
  isActive = false,
  onClick,
}) => {
  const { currentZoom } = rootStore.mapDataStore;
  const { id, toCamera, fromCamera } = trafficLane;

  const laneDirection =
    (directionKey === ToCamera ? toCamera : fromCamera) ?? trafficLane;

  const { angle, laneParams } = laneDirection;
  const isMain = !isTLCrossroad;

  const { data, isFetching } = useQuery({
    queryKey: [...VEHICLES_AMOUNT, id, directionKey, laneParams, isMain],
    queryFn: async () =>
      await fetchVehiclesAmount(id, directionKey, laneParams, isMain),
    refetchInterval: DEFAULT_UPDATE_TIME,
  });

  const vehiclesAmount = data?.vehiclesAmount;
  const lanesAmountData = data?.lanesAmountData ?? {};
  const isInfo = !isNumber(rate) ? currentZoom > INFO_BORDER : true;
  const vehiclesData = vehiclesAmount ?? NO_DATA;

  const { laneStyle, mainLaneStyle, barStyles, wrapperStyle, laneStyles } =
    getStyles({ trafficLane, isMain, laneDirection, rate, isActive });

  const isOneLine = laneStyles.length === 1 && isActive;

  const lanesProps = {
    angle: angle,
    isLoading: isFetching,
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper} style={wrapperStyle}>
        {laneStyles.map((item) => {
          const { id, number, ...barStyle } = item;

          const lanesData = lanesAmountData[number ?? -1] ?? NO_DATA;
          const data = isMain ? vehiclesData : lanesData;

          return (
            <div className={styles.bar} style={barStyle} key={`bar_${id}`}>
              <div
                className={classNames(styles.lane, {
                  [styles.laneClick]: !!onClick,
                })}
                style={laneStyle}
                onClick={onClick}
              />
              {isInfo && (
                <LaneNumber
                  {...lanesProps}
                  data={data}
                  rate={rate}
                  isActiveLane={isOneLine && isActive}
                />
              )}
            </div>
          );
        })}

        {barStyles.length > 1 && isInfo && !isMain && (
          <div
            className={classNames(styles.generalBlock, {
              [styles.generalBlockClick]: !!onClick,
            })}
            onClick={onClick}
            style={mainLaneStyle}
          >
            <LaneNumber
              angle={angle}
              data={vehiclesData}
              isLoading={isFetching}
              isGeneral
              rate={rate}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(TrafficLanesBlock);
