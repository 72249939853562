import type { Coordinate } from 'ol/coordinate';
import { fromLonLat, toLonLat } from 'ol/proj';

import { findById } from '../../../helpers/findBy';
import { getPointCenter } from '../../../helpers/findPointUds';
import { TInfoDataSystem } from '../../../stores/mapDataStore/mapDataStore.model';
import rootStore from '../../../stores/rootStore/rootStore';
import { MapActions } from '../../../stores/uiStore/uiStore.model';
import { System } from '../../../ts/enums/enums';

interface HandlePanel {
  id: number;
  coordinates: Coordinate;
  isFromMap: boolean;
  system: TInfoDataSystem;
  coordFeatureOnCluster?: N<Coordinate>;
}

const handleInfoPanel = ({
  id,
  coordinates,
  isFromMap,
  system,
  coordFeatureOnCluster,
}: HandlePanel) => {
  const { setInfoData } = rootStore.uiStore;
  const { tls } = rootStore.mapDataStore;

  const isLights = system === System.Lights;

  const getCoordinates = () => {
    if (!isLights) return coordinates;
    const tl = findById(tls, id);

    if (!tl) return coordinates;

    const [lon, lat] = getPointCenter(tl.deviceInfo.pointUdsUid);

    return lon && lat ? fromLonLat([lon, lat]) : coordinates;
  };

  const coords = getCoordinates();

  if (isFromMap && coordinates) {
    setInfoData(
      {
        id,
        coordinate: toLonLat(coords),
        system,
        coordFeatureOnCluster,
      },
      MapActions.NoActions
    );
  }
};

export default handleInfoPanel;
