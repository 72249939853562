import api from '../../../../../api';
import notification from '../../../../ui-kit/Notification/Notification';
import { ActionType, IDrawerBlock } from '../../models/Organizations.model';

const { create, update } = api.catalogs.organizations;

const PATH = 'Admin.Organizations.OrgResForm';

const handleSubmit = async (values: any, props?: IDrawerBlock) => {
  if (!props) return;
  const { type, collaboration, onClose, setIsUpdate } = props;
  const isAdd = type === ActionType.Add;

  const res = isAdd
    ? await create(values, PATH)
    : await update(values, collaboration?.id, PATH);

  if (!res) return;

  notification.success(isAdd ? 'ORGANIZATION_ADD' : 'ORGANIZATION_UPDATE');

  onClose();
  setIsUpdate(true);
};

export default handleSubmit;
