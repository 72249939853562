import { FC } from 'react';

import { SIZE_FACTOR } from '../constants/constants';
interface IPreviewImage {
  src: string;
  width: number;
  setIsErrorPreview: SetState<boolean>;
  alt?: string;
}

const PreviewImage: FC<IPreviewImage> = ({
  src,
  width,
  setIsErrorPreview,
  alt = 'Нет изображения с детектора',
}) => {
  const height = width / SIZE_FACTOR;

  return (
    <img
      width={width}
      height={height}
      src={src}
      alt={alt}
      onError={() => setIsErrorPreview(true)}
    />
  );
};

export default PreviewImage;
