import { IWeatherLayerItem } from '../../../components/Map/IWeatherTimeModel';

import PressureIcon from './../../../assets/icons/weather/barometer.svg';
import BlackIceIcon from './../../../assets/icons/weather/blackIce.svg';
import DewPointIcon from './../../../assets/icons/weather/dewPoints.svg';
import HumidityIcon from './../../../assets/icons/weather/humidity.svg';
import RainIcon from './../../../assets/icons/weather/precipitation.svg';
import TemperatureIcon from './../../../assets/icons/weather/temperature.svg';
import WindIcon from './../../../assets/icons/weather/wind.svg';

export const PROPERTY_NAMES = {
  TEMPERATURE: 'temp',
  PRECIPITATION: 'precipitation',
  HUMIDITY: 'humidity',
  PRESSURE: 'pressure',
  WIND_SPEED: 'windSpeed',
  BLACK_ICE: 'iceProbability',
  DEW_POINT: 'dewPoint',
  NONE: 'none',
};

export const WEATHER_ITEMS: IWeatherLayerItem[] = [
  {
    id: 'temperature',
    text: 'Температура, °C',
    buttonText: 'Температура',
    propertyName: PROPERTY_NAMES.TEMPERATURE,
    imageSrc: TemperatureIcon,
    gradient: {
      colorStops: [
        { color: 'rgba(25, 9, 69, 1)', value: -40 },
        { color: 'rgba(59, 0, 125, 1)', value: -35 },
        { color: 'rgba(59, 0, 125, 1)', value: -30 },
        { color: 'rgba(19, 40, 255, 1)', value: -25 },
        { color: 'rgba(19, 40, 255, 1)', value: -20 },
        { color: 'rgba(10, 150, 252, 1)', value: -15 },
        { color: 'rgba(10, 150, 252, 1)', value: -10 },
        { color: 'rgba(67, 219, 240, 1)', value: 0 },
        { color: 'rgba(5, 198, 103, 1)', value: 10 },
        { color: 'rgba(90, 150, 0, 1)', value: 20 },
        { color: 'rgba(222, 223, 17, 1)', value: 30 },
        { color: 'rgba(233, 84, 40, 1)', value: 35 },
        { color: 'rgba(130, 17, 43, 1)', value: 40 },
      ],
    },
    units: '°C',
  },
  {
    id: 'precipitation',
    text: 'Осадки, мм/ч',
    buttonText: 'Осадки',
    propertyName: PROPERTY_NAMES.PRECIPITATION,
    imageSrc: RainIcon,
    gradient: {
      colorStops: [
        { color: 'rgba(249, 249, 154, 0)', value: 0 },
        { color: 'rgba(32, 200, 248, 1)', value: 1 },
        { color: 'rgba(32, 180, 248, 1)', value: 2 },
        { color: 'rgba(16, 138, 208, 1)', value: 5 },
        { color: 'rgb(3,80,196)', value: 10 },
        { color: 'rgb(2,74,183)', value: 20 },
        { color: 'rgba(0, 67, 168, 1)', value: 30 },
      ],
    },
    units: 'мм/ч',
  },
  {
    id: 'humidity',
    text: 'Влажность, %',
    buttonText: 'Влажность',
    propertyName: PROPERTY_NAMES.HUMIDITY,
    imageSrc: HumidityIcon,
    gradient: {
      colorStops: [
        { color: 'rgba(220, 220, 220, 1)', value: 0 },
        { color: 'rgba(230, 237, 220, 1)', value: 10 },
        { color: 'rgba(230, 232, 190, 1)', value: 20 },
        { color: 'rgba(240, 237, 180, 1)', value: 30 },
        { color: 'rgba(240, 245, 151, 1)', value: 40 },
        { color: 'rgba(249, 249, 154, 1)', value: 50 },
        { color: 'rgba(227, 240, 125, 1)', value: 60 },
        { color: 'rgba(169, 210, 121, 1)', value: 70 },
        { color: 'rgba(36, 174, 148, 1)', value: 80 },
        { color: 'rgba(18, 135, 192, 1)', value: 90 },
        { color: 'rgba(0, 95, 238, 1)', value: 100 },
      ],
    },
    units: '%',
  },
  {
    id: 'pressure',
    text: 'Давление, мм.рт.ст.',
    buttonText: 'Давление',
    propertyName: PROPERTY_NAMES.PRESSURE,
    imageSrc: PressureIcon,
    gradient: {
      colorStops: [
        { color: 'rgba(227, 240, 125, 1)', value: 650 },
        { color: 'rgba(169, 222, 111, 1)', value: 740 },
        { color: 'rgba(36, 174, 148, 1)', value: 760 },
        { color: 'rgba(45, 41, 138, 1)', value: 780 },
        { color: 'rgba(32, 14, 45, 1)', value: 850 },
      ],
    },
    units: 'мм.рт.ст.',
  },
  {
    id: 'wind',
    text: 'Ветер, м/с',
    buttonText: 'Ветер',
    propertyName: PROPERTY_NAMES.WIND_SPEED,
    imageSrc: WindIcon,
    gradient: {
      colorStops: [
        { color: 'rgba(67, 219, 240, 1)', value: 0 },
        { color: 'rgba(21, 157, 255, 1)', value: 1 },
        { color: 'rgba(10, 120, 252, 1)', value: 2 },
        { color: 'rgba(10, 100, 252, 1)', value: 3 },
        { color: 'rgba(19, 40, 255, 1)', value: 4 },
        { color: 'rgba(19, 40, 255, 1)', value: 5 },
        { color: 'rgba(39, 27, 181, 1)', value: 10 },
        { color: 'rgba(59, 15, 107, 1)', value: 15 },
        { color: 'rgba(83, 0, 19, 1)', value: 20 },
      ],
    },
    units: 'м/c',
  },
  {
    id: 'dewPoint',
    text: 'Точка росы, °С',
    buttonText: 'Точка росы',
    propertyName: PROPERTY_NAMES.DEW_POINT,
    imageSrc: DewPointIcon,
    gradient: {
      colorStops: [
        { color: 'rgba(25, 9, 69, 1)', value: -40 },
        { color: 'rgba(59, 0, 125, 1)', value: -35 },
        { color: 'rgba(59, 0, 125, 1)', value: -30 },
        { color: 'rgba(19, 40, 255, 1)', value: -25 },
        { color: 'rgba(19, 40, 255, 1)', value: -20 },
        { color: 'rgba(10, 150, 252, 1)', value: -15 },
        { color: 'rgba(10, 150, 252, 1)', value: -10 },
        { color: 'rgba(67, 219, 240, 1)', value: 0 },
        { color: 'rgba(5, 198, 103, 1)', value: 10 },
        { color: 'rgba(90, 150, 0, 1)', value: 20 },
        { color: 'rgba(222, 223, 17, 1)', value: 30 },
        { color: 'rgba(233, 84, 40, 1)', value: 35 },
        { color: 'rgba(130, 17, 43, 1)', value: 40 },
      ],
    },
    units: '°С',
  },
  {
    id: 'iceProbability',
    text: 'Вероятность образования наледи, %',
    buttonText: 'Наледь',
    propertyName: PROPERTY_NAMES.BLACK_ICE,
    imageSrc: BlackIceIcon,
    gradient: {
      colorStops: [
        { color: 'rgba(255,255,255,0.06)', value: 0 },
        { color: 'rgba(255,227,227,0.7)', value: 20 },
        { color: 'rgba(255,161,161,0.9)', value: 30 },
        { color: 'rgba(255,103,103,0.9)', value: 50 },
        { color: 'rgba(138,0,0,0.95)', value: 100 },
      ],
    },
    units: '%',
  },
];

export const WEATHER_LAYER_ITEMS = {
  factor: {
    id: 'weather_gradient_layer',
  },
  windAnimation: {
    id: 'weather_wind_animation_layer',
  },
  precipitationLayer: {
    id: 'weather_precipitation_animation_layer',
  },
  labelsLayer: {
    id: 'weather_labels_layer',
  },
};

export const WIND_SCALE = 0.000006;
export const MAX_WIND_VALUE = 0.005;
export const WIND_PATHS = 5000;
export const WIND_TRAIL_WIDTH = 2.5;

export const PRECIPITATION_SCALE = 0.000005;
export const PRECIPITATION_PATHS = 1000;
export const PRECIPITATION_TRAIL_WIDTH = 3.5;
export const PRECIPITATION_HAS_VALUE_COLOR = [255, 255, 255, 200];
export const PRECIPITATION_NO_VALUE_COLOR = [255, 255, 255, 0];

export const DX = 5000;
export const DY = 6000;

export const IDW_LAYER_OPACITY = 0.5;
export const IDW_PRECIPITATION_OPACITY = 0.9;
export const GRADIENT_LAYER_SCALE = 16;
export const GRADIENT_PRECIPITATION_SCALE = 24;

export const WEATHER_MODULE_ID = 'bc69205d-6779-4828-92c8-9e87841c4574';
export const WEATHER_METHOD_NAME = 'getById';

export interface IDistanceValue {
  radius: number;
  value: number;
}
