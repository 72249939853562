import { TabsProps } from 'antd/lib';

import rootStore from '../../../stores/rootStore/rootStore';
import { System } from '../../../ts/enums/enums';
import { TLDetailedCode } from '../../../ts/enums/userData';
import { DETAILED_CAMERA_ITEMS } from '../DetailedWrapper/constants/cameraItems';
import { BASIC_DETAILED_ITEMS } from '../DetailedWrapper/constants/constants';
import { DETAILED_DETECTORS_ITEMS } from '../DetailedWrapper/constants/detectorItems';
import { TL_DETAILED_ITEMS } from '../DetailedWrapper/constants/tlItems';

const { Lights, Cameras, Detectors } = System;

type ItemsProps = NonNullable<TabsProps['items']>;

export const getSystemTabPanel = (system: System) => {
  switch (system) {
    case Lights:
      return TL_DETAILED_ITEMS;
    case Cameras:
      return DETAILED_CAMERA_ITEMS;
    case Detectors:
      return DETAILED_DETECTORS_ITEMS;
    default:
      return [];
  }
};

export const getItemsDetailed = (
  id: number,
  system: System,
  isAccessManagement?: boolean,
  activeKey?: string
) => {
  const { hasAccess } = rootStore.userDataStore;

  const systemItems = [...getSystemTabPanel(system), ...BASIC_DETAILED_ITEMS];

  const items = systemItems.reduce(
    (acc: ItemsProps, { label, key, Component, isLoaded, type, code }) => {
      const isAccess =
        system === Lights && code === TLDetailedCode.Management
          ? hasAccess(code) && isAccessManagement
          : hasAccess(code);

      const isLoad = isLoaded || activeKey === key;

      if (isAccess) {
        acc.push({
          label,
          key,
          children: isLoad ? (
            <Component id={id} curentActiveKey={key} type={type} />
          ) : null,
        });
      }

      return acc;
    },
    []
  );

  return { items, systemItems };
};
