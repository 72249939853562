import rootStore from '../../../../../stores/rootStore/rootStore';

const getArrowSizeRate = () => {
  const { currentZoom } = rootStore.mapDataStore;

  if (currentZoom > 18) return 1.3;
  if (currentZoom > 17.5) return 1.25;
  if (currentZoom > 17) return 1.2;

  return 1.15;
};

export default getArrowSizeRate;
