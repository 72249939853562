import { TL_DETAILED } from '../../../../constants/tlDetailedConstants';

const {
  PHASE_ID: { COMMON },
} = TL_DETAILED;

const dictPhaseIDs = {
  AR: 'Режим "Все красное"',
  ED: 'Режим "Желтое мигание"',
  OFF: 'Выносной ПУ',
  YF: 'Режим "Отключено"',
};

export const getPhaseTitle = (phase: any) => {
  const commonTitle = phase !== COMMON ? `Фаза ${phase}` : 'Общая схема';

  return phase in dictPhaseIDs
    ? dictPhaseIDs[phase as keyof typeof dictPhaseIDs]
    : commonTitle;
};
