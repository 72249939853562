import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { MINUTE } from '../../../constants/constants';
import { BroadcastIncorrectTimeProps } from '../../../eventBus/events/models/event.model';
import { getTimeFormatted } from '../../ui-kit/Chart/helpers/getIntervalFormatted';

dayjs.extend(duration);

enum ServerTimeKeys {
  Timestamp = 'timestamp',
}

type ServerTimeProps = Record<ServerTimeKeys, number>;

const isServerTime = (serverTime: unknown): serverTime is ServerTimeProps => {
  if (!serverTime) return false;

  return Object.hasOwn(serverTime, ServerTimeKeys.Timestamp);
};

export const getTimeDiff = (
  serverTime: unknown
): BroadcastIncorrectTimeProps => {
  const correctTime = {
    dateServerDJS: null,
    timeDiff: null,
    isCorrectTime: true,
  };

  if (!isServerTime(serverTime)) return correctTime;

  const { timestamp } = serverTime;

  const dateNow = dayjs().locale('ru');
  const dateServer = dayjs(timestamp).locale('ru');

  const diff = dateServer.diff(dateNow, 'm');

  if (Math.abs(diff) < 1) return correctTime;

  const duration = dayjs.duration(Math.abs(diff) * MINUTE);

  const years = Math.floor(duration.asYears());
  const days = Math.floor(duration.days());
  const hours = Math.floor(duration.hours());
  const minutes = Math.floor(duration.minutes());
  const seconds = Math.floor(duration.seconds());

  const titleClock = diff > 0 ? 'отстают' : 'спешат';

  const yearsStr = getTimeFormatted(years, years < 5 ? 'г.' : 'л.');
  const daysStr = getTimeFormatted(days, days === 1 ? 'сутки' : 'д.');
  const hoursStr = getTimeFormatted(hours, 'ч.');
  const minStr = getTimeFormatted(minutes, 'мин.');
  const secStr = getTimeFormatted(seconds, 'c.');

  const time = `Ваши часы ${titleClock} на ${yearsStr} ${daysStr} 
  ${hoursStr} ${minStr} ${secStr} от серверного времени.`;

  return { dateServerDJS: dateServer, timeDiff: time, isCorrectTime: false };
};
