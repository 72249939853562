import { observer } from 'mobx-react-lite';
import type { Overlay } from 'ol';
import { fromLonLat } from 'ol/proj';
import { FC, useEffect } from 'react';

import { DEFAULT_CROSSROAD_COLORS } from '../../../../../../constants/colorsConstants';
import { TMap } from '../../../../../../stores/mapStore/mapStore.model';
import rootStore from '../../../../../../stores/rootStore/rootStore';
import { TLCrossroad } from '../../../../../../ts/models/tl.model';
import { useCrossroadImg } from '../../../../../Overlays/hooks';
import PhaseCircleImg from '../../../../../Overlays/PhaseCircleImg/PhaseCircleImg';

const { MAIN, CROSSWALK } = DEFAULT_CROSSROAD_COLORS;
const APP_PATH = '.TLDetectors.CrossroadSchemaWrapper.CrossroadSchema';

export interface CrossroadSchemaProps {
  id: U<number>;
  map: TMap;
  crossroadOverlay: N<Overlay>;
  rate: number;
  coord: number[];
  tlCrossroad: N<TLCrossroad>;
}

const CrossroadSchema: FC<CrossroadSchemaProps> = ({
  id,
  map,
  crossroadOverlay,
  rate,
  coord,
  tlCrossroad,
}) => {
  const { calcCircleVal } = rootStore.pointsUdsStore;

  const { data } = useCrossroadImg({
    id: id ?? 0,
    color: MAIN,
    crosswalkColor: CROSSWALK,
    strokeWidth: tlCrossroad?.strokeWidth,
    appPath: APP_PATH,
    isNotify: false,
  });

  useEffect(() => {
    if (!map || !coord.length || !crossroadOverlay) return;

    const correctCoord = fromLonLat(coord);

    crossroadOverlay?.set('position', correctCoord, true);

    const elementOverlay = crossroadOverlay?.getElement();

    if (elementOverlay) {
      elementOverlay.style.display = 'block';
    }

    map.render();
  }, [map, coord, crossroadOverlay]);

  if (!id || !tlCrossroad || !data) return null;

  const {
    angle,
    size,
    positionXY: [offsetX, offsetY],
  } = tlCrossroad;

  const style = {
    top: calcCircleVal(offsetY, rate),
    left: calcCircleVal(offsetX, rate),
    width: calcCircleVal(size, rate),
    height: calcCircleVal(size, rate),
    transform: `translate(-50%,-50%) rotate(${angle}deg)`,
    overflow: 'visible',
  };

  return <PhaseCircleImg data={data} style={style} isFixedSizeImg />;
};

export default observer(CrossroadSchema);
