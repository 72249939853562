import { useState, useEffect, useCallback, FC } from 'react';

import api from '../../../api';
import { TlJournalName } from '../../../constants/detailedTabsConstant';
import { IQueryParamRequest, TableData } from '../../../ts/models/table.model';
import { DetailedItemProps } from '../../DetailedMain/DetailedWrapper/constants/constants';
import { DEFAULT_TABLE } from '../../ui-kit/Table/constants';
import Table from '../../ui-kit/Table/Table';
import { getSuccesNoti } from '../helpers/journalsHelpers';

import 'dayjs/locale/ru';
import styles from './TlJournalTable.module.scss';

const TlJournalTable: FC<DetailedItemProps> = ({ id, type }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState<TableData>(DEFAULT_TABLE);

  const clearTable = () => {
    setTableData(DEFAULT_TABLE);
  };

  const getStatusJournalData = useCallback(
    async (queryParamsReq?: IQueryParamRequest) => {
      setIsLoading(true);
      clearTable();

      if (!type) return;

      const journalData = await api.mapObjects.trafficLights.journal.getJournal(
        id,
        type,
        queryParamsReq
      );

      if (!journalData) return setIsLoading(false);

      getSuccesNoti(queryParamsReq);

      setTableData(journalData);
      setIsLoading(false);
    },
    [type, id]
  );

  useEffect(() => {
    getStatusJournalData();
  }, [getStatusJournalData]);

  if (!type) return null;

  return (
    <section className={styles.wrapper}>
      <Table
        title={TlJournalName[type]}
        tableData={tableData}
        tableConfigType="statusTable"
        refreshData={getStatusJournalData}
        isLoading={isLoading}
      />
    </section>
  );
};

export default TlJournalTable;
