import { isNumber } from 'lodash';

import { getIsCoordinatesOnExtent } from '../../../../../../Map/helpers/getIsCoordinatesOnExtent';

const getIsMapAction = (longitude: U<number>, latitude: U<number>) => {
  const isMapAction =
    isNumber(longitude) && isNumber(latitude)
      ? getIsCoordinatesOnExtent([longitude, latitude])
      : false;

  return isMapAction;
};

export default getIsMapAction;
