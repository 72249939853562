import { VERSION } from '../../constants/versions';
import { getAppPathHeaders } from '../helpers/getAppPathHeaders';
import http from '../http';
import tryCatchWrapper from '../tryCatchWrapper';

const FILES = VERSION + '/files';

const filesApi = {
  fetchFilesList: (deviceId: number, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.get(FILES, {
        params: {
          deviceId,
        },
        headers: getAppPathHeaders(appPath),
      });

      return res;
    }),
  fetchFile: (
    profileId: number,
    appPath: string,
    controller: AbortController
  ) =>
    tryCatchWrapper(async () => {
      const res = http.get(FILES + `/${profileId}`, {
        responseType: 'blob',
        headers: getAppPathHeaders(appPath),
        signal: controller.signal,
      });

      return res;
    }),
  deleteFile: (profileId: number, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = http.delete(FILES + `/${profileId}`, {
        headers: getAppPathHeaders(appPath),
      });

      return res;
    }),

  uploadFile: (body: any, appPath: string) =>
    tryCatchWrapper(
      async () => {
        const res = await http.post(FILES, body, {
          headers: getAppPathHeaders(appPath),
        });

        return res;
      },
      {
        ignoreCodes: [409],
      }
    ),
};

export { filesApi };
