export const columnsSigProg = [
  {
    title: 'Фаза',
    dataIndex: 'phaseNum',
    key: 'phaseNum',
  },
  {
    title: 't от',
    dataIndex: 'lenMain',
    key: 'lenMain',
  },
  {
    title: 't пт',
    dataIndex: 'lenMid',
    key: 'lenMid',
  },
  {
    title: '%',
    dataIndex: 'ratio',
    key: 'ratio',
  },
];

export const LAST_ROW_TITLE = 'Цикл';
