import { Tag } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import { ITimePoints } from '../../../ts/models/signalPrograms.model';
import { getCurPhaseInfo } from '../../SignalProgramsBand/helpers/getCurPhaseInfo';
import Popover from '../../ui-kit/Popover/Popover';

import getTitlePhase from './helpers/getTitlePhase';
import PhaseNowInfoPopover from './PhaseNowInfoPopover/PhaseNowInfoPopover';
import SettingBox from './SettingsBox/SettingBox';

import styles from './Settings.module.scss';

interface ISettings {
  phasesSet: ITimePoints[];
  isStatic: boolean;
}

const Settings: FC<ISettings> = ({ phasesSet, isStatic }) => {
  const { interfaceTipsDelay, secTL } = rootStore.uiStore;

  const { curPhase, isPromTact } = getCurPhaseInfo(phasesSet, secTL);

  const titlePhase = getTitlePhase(curPhase, isPromTact);

  const popoverProps = {
    placement: 'right',
    tipsDelay: interfaceTipsDelay,
  } as const;

  if (isStatic) return null;

  return (
    <div className={styles.wrapper}>
      <SettingBox />
      <div className={styles.counterBox}>
        <span>{secTL}</span>
      </div>
      <Popover
        {...popoverProps}
        content={<PhaseNowInfoPopover phasesSet={phasesSet} sec={secTL} />}
      >
        {curPhase && <Tag className={styles.phaseNowBox}>{titlePhase}</Tag>}
      </Popover>
    </div>
  );
};

export default observer(Settings);
