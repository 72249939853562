import { useEffect, useState } from 'react';

const useAnimate = (isShow: boolean) => {
  const [isAnimate, setIsAnimate] = useState<boolean>(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => setIsAnimate(isShow));

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isShow]);

  return isAnimate;
};

export default useAnimate;
