import { TLDetailsImage } from '../../../../../ts/models/tl.model';
import translateCode from '../../../../Constructor/DetailedConstructor/ConstructorPhaseImages/helpers/translateCode';

const ERROR_IMAGE = 'ошибка';
const URL_IMAGES_PATH = '/media';

export const getModeImage = (images: TLDetailsImage[], radioValue: number) =>
  URL_IMAGES_PATH +
    images.find(({ code }) => {
      if (!code) return false;

      const { mode } = translateCode(code, null);

      if (!mode) return false;

      const isCurrentMode = mode.some((el) => el === radioValue);

      return isCurrentMode;
    })?.image ?? ERROR_IMAGE;
