import { type UploadFile } from 'antd';

import notification from '../../../../../ui-kit/Notification/Notification';

const checkIsImgValid = (file: UploadFile) => {
  const isPng = file.type === 'image/png';
  const isLt2M = (file.size ?? 0) / 1024 / 1024 < 0.5;

  !isPng && notification.error('FILE_WITH_INCORRECT_FORMAT');
  !isLt2M && notification.error('FILE_TOО_BIG');

  return isLt2M && isPng;
};

export default checkIsImgValid;
