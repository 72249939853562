import { Tree } from 'antd';
import { observer } from 'mobx-react-lite';

import { LAYERS_CONTENT, LOCAL_STYLES } from '../constants';
import { ILayerDataNode } from '../layers.model';

import { LayerGroupFolderIcon } from './LayerGroupFolderIcon';
import LayerTreeItem from './LayerTreeItem';

const LayersTree = (props: {
  treeData: ILayerDataNode[];
  onSettingsClick: (evt: ILayerDataNode) => void;
  onTableClick: (evt: ILayerDataNode) => void;
}) => (
  <>
    <div>{LAYERS_CONTENT.title}</div>
    <div style={LOCAL_STYLES.innerContainer}>
      <Tree
        treeData={props.treeData}
        switcherIcon={(data: any) => {
          return <LayerGroupFolderIcon expanded={data.expanded} />;
        }}
        defaultExpandAll={false}
        selectable={false}
        showLine={false}
        titleRender={(node: ILayerDataNode) =>
          LayerTreeItem({
            ...node,
            onSettingsClick: () => {
              props.onSettingsClick(node);
            },
            onTableClick: () => {
              props.onTableClick(node);
            },
          })
        }
      />
    </div>
  </>
);

export default observer(LayersTree);
