import { isNumber } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { DEVICES_STATE } from '../../../../../constants/devicesConstants';
import { findBy } from '../../../../../helpers/findBy';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { isDeviceStatusName } from '../../../../../ts/models/mapObject.model';
import { getGeneralStatusDevices } from '../helpers/getGeneralStatusDevices';
import {
  IWidgetPieInfo,
  TWidgetsOptionalData,
} from '../models/monitoring.model';

const useMonitoringCard = (statusData: U<TWidgetsOptionalData>) => {
  const { statusFilteredMonitoring, setStatusFilteredMonitoring } =
    rootStore.monitoringPanelStore;

  const [dataPieChart, setDataPieChart] = useState<IWidgetPieInfo>();

  const handleClickSector = useCallback(
    (sectorName: string, len: U<number>) => {
      const status =
        isDeviceStatusName(sectorName) &&
        findBy(DEVICES_STATE, sectorName, 'dsc')?.com;

      if (!isNumber(status)) return;

      setStatusFilteredMonitoring(status, len);
    },
    [setStatusFilteredMonitoring]
  );

  const activeSectors = useMemo(
    () =>
      statusFilteredMonitoring.reduce((acc: string[], { status }) => {
        const statusName = findBy(DEVICES_STATE, status, 'com')?.dsc;

        statusName && acc.push(statusName);

        return acc;
      }, []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [statusFilteredMonitoring.length]
  );

  useEffect(() => {
    if (!statusData) return;

    setDataPieChart(getGeneralStatusDevices(statusData));
  }, [statusData]);

  return { dataPieChart, activeSectors, handleClickSector };
};

export default useMonitoringCard;
