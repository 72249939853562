import { Upload } from 'antd';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { observer } from 'mobx-react-lite';
import { useState, FC } from 'react';

import developmentApi from '../../../../../api/development/developmentApi';
import { SECOND, PHASE_IMG, SIGN1 } from '../../../../../constants/constants';
import {} from '../../../../../constants/versions';
import rootStore from '../../../../../stores/rootStore/rootStore';
import notification from '../../../../ui-kit/Notification/Notification';
import getPhaseImagesFolder from '../helpers/getPhaseImagesFolder';

import checkIsImgValid from './helpers/checkIsImgValid';
import PlusBtn from './PlusBtn/PlusBtn';

import styles from './UploadImg.module.scss';

const APP_PATH =
  'Constructor.DetailedConstructor.ConstructorPhaseImages.UploadImg';

export interface UploadImgProps {
  postfix: string;
  idx: number;
}

const UploadImg: FC<UploadImgProps> = ({ postfix, idx }) => {
  const {
    tl: { id },
    setPhase,
    setPhaseImagePath,
    isNewPhaseImages,
  } = rootStore.constructorStore;

  const { regionData } = rootStore.uiStore;

  const [imgFile, setImgFile] = useState<UploadFile>();
  const [imgName, setImgName] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  if (!regionData) return null;

  const folder = getPhaseImagesFolder();

  const handleUpload: UploadProps['onChange'] = async () => {
    if (!id || !imgFile || !checkIsImgValid(imgFile)) return;

    setIsLoading(true);
    const prefix = isNewPhaseImages ? '' : 'Sg';
    const name = prefix + id + postfix + '.png';
    const formData = new FormData();

    formData.append('file', imgFile as RcFile, name);

    const params = isNewPhaseImages ? undefined : { folderName: SIGN1 };
    const res = await developmentApi.uploadSchemePhase(
      formData,
      APP_PATH,
      params
    );

    if (!res) {
      notification.error('FILE_DUPLICATE');
      setImgName(undefined);

      return setIsLoading(false);
    }

    notification.success('FILE_UPLOAD_SUCCESS');
    setImgName(name);
    setIsLoading(false);

    setTimeout(() => {
      setPhase(PHASE_IMG, name, idx);
      setPhaseImagePath(idx, folder.path + name);
    }, SECOND);
  };

  const uploadProps: UploadProps = {
    beforeUpload: (file) => {
      setImgName(undefined);
      setImgFile(file);

      return false;
    },
    onChange: handleUpload,
    name: 'scheme picture',
    listType: 'picture-card',
    showUploadList: false,
    className: styles.upload,
  };

  const name = imgName + '?cache=' + Math.random();

  return (
    <div className={styles.wrapper}>
      <Upload {...uploadProps}>
        {imgName ? (
          <img
            src={folder.fullPath + name}
            alt="scheme"
            className={styles.img}
          />
        ) : (
          <PlusBtn isLoading={isLoading} />
        )}
      </Upload>

      <span className={styles.limits}>Допустимый формат: png</span>
      <span className={styles.limits}>Максимальный размер: 0.5 Мб</span>
    </div>
  );
};

export default observer(UploadImg);
