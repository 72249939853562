import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { toLonLat } from 'ol/proj';

import rootStore from '../../../stores/rootStore/rootStore';
import { MapActions } from '../../../stores/uiStore/uiStore.model';
import { getItemsDetailed } from '../../DetailedMain/helpers/getItemsDetailed';

export const openDetailedForm = (feature: Feature<Point>, e: any) => {
  const { setInfoData, setKeyValue, setIsNot } = rootStore.uiStore;
  const { getCoordinatesById } = rootStore.mapDataStore;

  const system = feature.get('system');
  const id = feature.get('id');
  const featureCenter = getCoordinatesById(id);

  setInfoData(
    {
      id,
      coordinate: featureCenter ? toLonLat(featureCenter) : [],
      system,
    },
    MapActions.NoActions
  );

  const { systemItems } = getItemsDetailed(id, system);

  const key = systemItems.at(0)?.key;

  key && setKeyValue('activeKey', key);

  setIsNot('isDetailedForm', true);
};
