import { notification } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

import { TIME_FORMAT } from '../../../../constants/constants';
import NOTICE from '../../../../constants/notificationConstants';
import { getTimeWithTimeZone } from '../../TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeWithTimeZone';
import {
  COMMANDS_VALUES,
  DEFAULT_DISABLED_TIME_DURATION,
} from '../constants/constants';

export const changeDate = (
  value: dayjs.Dayjs | null,
  cmd: keyof typeof COMMANDS_VALUES,
  disabledTime = DEFAULT_DISABLED_TIME_DURATION
) => {
  let valueDate = null;
  let statusDate: 'error' | 'warning' | undefined;
  const dateNowJS = getTimeWithTimeZone().dateFormattedJS;

  const dateNow = dateNowJS.format(TIME_FORMAT);

  const notifyMessage = {
    ...NOTICE.DATE_ERROR_PLAN,
    description: `Включение режима ${COMMANDS_VALUES[cmd]} доступно не раньше`,
  };

  if (!value) {
    return;
  }

  if (!disabledTime && !dateNowJS.isBefore(value)) {
    statusDate = 'error';
    notifyMessage.description += ` текущего времени (${dateNow})`;
  }

  if (disabledTime && !dateNowJS.add(disabledTime, 'minutes').isBefore(value)) {
    statusDate = 'error';
    notifyMessage.description += `, чем через ${disabledTime} минут`;
  }

  if (statusDate === 'error') {
    notification.error(notifyMessage);
    valueDate = null;

    return;
  }

  valueDate = value;
  statusDate = undefined;

  return { valueDate, statusDate };
};
