import { containsCoordinate } from 'ol/extent';
import { fromLonLat } from 'ol/proj';

import rootStore from '../../../../stores/rootStore/rootStore';
import { PointUds } from '../../../../ts/models/pointUds.model';
import notification from '../../../ui-kit/Notification/Notification';
import { Device } from '../PointUdsOverlays.model';

const notificationsCache: number[] = [];

const getWarningForIncorrectCoordinate = (
  devices: Device[],
  pointsUds: PointUds[]
) => {
  const { map } = rootStore.mapStore;
  const warnIds: number[] = [];

  if (!map) return;
  const extent = map.getView().calculateExtent();

  const pushIds = (ids?: number[]) =>
    devices.forEach(
      ({
        deviceInfo: { longitude, latitude, deviceId, pointUdsUid },
        isCorrectCoordinate,
      }) => {
        if (!pointUdsUid) return;

        const isDynamicCheckResult = ids
          ? ids.includes(deviceId)
          : containsCoordinate(extent, fromLonLat([longitude, latitude]));

        if (
          isCorrectCoordinate ||
          notificationsCache.includes(deviceId) ||
          !isDynamicCheckResult
        )
          return;

        notificationsCache.push(deviceId);
        warnIds.push(deviceId);
      }
    );

  pushIds();

  pointsUds.forEach(
    ({ basicProfileInfo: { linkedDeviceIds, centerPoint } }) => {
      if (!containsCoordinate(extent, fromLonLat(centerPoint))) return;

      const { trafficLights, cameras, detectors, meteo } = linkedDeviceIds;
      const ids = [trafficLights, cameras, detectors, meteo]
        .filter(Boolean)
        .flat();

      pushIds(ids);
    }
  );

  if (!warnIds.length) return;

  const warning = `Устройства с идентификаторами: ${warnIds.join(', ')} 
    имеют координаты, выходящие за допустимые для точки УДС пределы.`;

  console.warn(warning);
  notification.warning('ATTENTION', { dsc: warning });
};

export default getWarningForIncorrectCoordinate;
