import { List } from 'antd';
import React from 'react';

import { IDirectionInstruction } from '../../../../apiGIS/models/tasks/tasks.model';
import { SECOND } from '../../../../constants/constants';
import { timeToDuration } from '../helpers';

import styles from '../SearchButton.module.scss';

const DirectionResultItem = (props: { item: IDirectionInstruction }) => (
  <>
    {props.item.directionName ? (
      <div className={styles.listItem}>
        <List.Item className={styles.directionResultListItem}>
          <div className={styles.narrativeItem}>
            <div className={styles.narrativeItemTitle}>
              {props.item.directionName}
            </div>
            <div className={styles.narrativeItemDistance}>
              {props.item.distance ? props.item.distance : '-'}
              {props.item.distance ? 'км' : ''}
            </div>
            <div className={styles.narrativeItemTime}>
              {timeToDuration(props.item.time * SECOND)}
            </div>
          </div>
        </List.Item>
      </div>
    ) : null}
  </>
);

export default DirectionResultItem;
