import { Button, Tag } from 'antd';
import { FC, useEffect } from 'react';
import { RiRestartLine } from 'react-icons/ri';

import styles from './PingContainer.module.scss';

interface IPingContainer {
  isLoading: boolean;
  pingInfo: {
    time: number;
    color: string;
  };
  handleReset: () => void;
}

const PingContainer: FC<IPingContainer> = ({
  isLoading,
  pingInfo,
  handleReset,
}) => {
  useEffect(() => {
    const itervalId = setInterval(() => {
      handleReset();
    }, 5000);

    return () => {
      clearInterval(itervalId);
    };
  }, [handleReset]);

  return (
    <div className={styles.pingWrapper}>
      <p>
        Пинг:{' '}
        <Tag className={styles.ping} color={pingInfo.color}>
          {pingInfo.time} мс
        </Tag>
      </p>
      <Button
        className={styles.actionBtn}
        type="primary"
        size="middle"
        icon={<RiRestartLine />}
        onClick={handleReset}
        loading={isLoading}
      >
        Обновить
      </Button>
    </div>
  );
};

export default PingContainer;
