import { TQueryToolsBtnPosition } from '../../../../../stores/uiStore/uiStore.model';

interface TOptions {
  value: TQueryToolsBtnPosition;
  label: string;
}

export const BTN_POSITION: Record<string, TQueryToolsBtnPosition> = {
  TOP_LEFT: 'top-left',
  TOP_RIGHT: 'top-right',
  BOTTOM_RIGHT: 'bottom-right',
  BOTTOM_LEFT: 'bottom-left',
};

const { TOP_LEFT, TOP_RIGHT, BOTTOM_RIGHT, BOTTOM_LEFT } = BTN_POSITION;

const QUERY_BTN_POSITION_OPTIONS: TOptions[] = [
  { value: TOP_LEFT, label: 'вверху-слева' },
  { value: TOP_RIGHT, label: 'вверху-справа' },
  { value: BOTTOM_RIGHT, label: 'внизу-справа' },
  { value: BOTTOM_LEFT, label: 'внизу-слева' },
];

export default QUERY_BTN_POSITION_OPTIONS;
