import { createContext, useState } from 'react';

import { MultipleClusters } from '../../stores/clustersStore/clustersStore.model';
import { IInfoPanelData } from '../../stores/uiStore/uiStore.model';

import RightPanel from './RightPanel';

export type PanelContent = N<IInfoPanelData | MultipleClusters>;

interface PanelContentContext {
  panelContent: PanelContent;
  setPanelContent: SetState<PanelContent>;
}

const initialContext: PanelContentContext = {
  panelContent: null,
  setPanelContent: (value) => value,
};

export const PanelDataContext =
  createContext<PanelContentContext>(initialContext);

const InfoPanelWithContext = () => {
  const [panelContent, setPanelContent] = useState<PanelContent>(null);

  return (
    <PanelDataContext.Provider
      value={{
        panelContent,
        setPanelContent,
      }}
    >
      <RightPanel />
    </PanelDataContext.Provider>
  );
};

export default InfoPanelWithContext;
