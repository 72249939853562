import { isString } from 'lodash';
import { FC } from 'react';

import { COLORS } from '../../../../constants/colorsConstants';
import { KEY_EVENT_COMPONENT } from '../../../../constants/constants';
import useKeyCombination from '../../../../keyCombination/useKeyCombination';
import rootStore from '../../../../stores/rootStore/rootStore';
import CloseBtn from '../../../Svg/CloseBtn/CloseBtn';

import styles from './DetailedHeader.module.scss';

export interface DetailedHeaderProps {
  header: string | JSX.Element;
  headerIco?: JSX.Element;
  isRightPanel: boolean;
  onClose: () => void;
}

const DetailedHeader: FC<DetailedHeaderProps> = ({
  header,
  headerIco = null,
  isRightPanel,
  onClose,
}) => {
  const { setKeyValue } = rootStore.uiStore;

  const onCloseEvent = () => {
    setKeyValue('isModalLoading', false);
    onClose();
  };

  useKeyCombination(
    KEY_EVENT_COMPONENT.ESCAPE.infoPanel,
    onCloseEvent,
    !isRightPanel
  );

  const headerTitleStyle = isRightPanel
    ? styles.headerTitleRightPanel
    : styles.headerTitle;

  const bgColor = !isRightPanel ? COLORS.BG_TINT : undefined;

  return (
    <header className={styles.header}>
      {isString(header) ? (
        <div className={headerTitleStyle}>
          {headerIco}
          <h1>{header}</h1>
        </div>
      ) : (
        header
      )}
      <CloseBtn onClick={onCloseEvent} bgColor={bgColor} />
    </header>
  );
};

export default DetailedHeader;
