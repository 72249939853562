/* eslint-disable camelcase */

import { BarColors } from '../../../../../../constants/colorsConstants';
import { IColors } from '../../helpers/getDetectorsBarsStatus';

export enum ChartKeys {
  Vehicles = 'vehicles',
  AvSpeed = 'avSpeed',
  Density = 'density',
}

export enum ChartBDKeys {
  PassageCount = 'passage_count',
  SpeedAvg = 'speed_avg',
  Density = 'density',
}

export const CHART_NAMES: Record<ChartKeys, ChartBDKeys> = {
  vehicles: ChartBDKeys.PassageCount,
  avSpeed: ChartBDKeys.SpeedAvg,
  density: ChartBDKeys.Density,
};

export const CHART_UNITS: Record<ChartBDKeys, string> = {
  speed_avg: 'км/ч',
  passage_count: 'ТС',
  density: 'ТС/км',
};

export const Y_AXIS_LABELS: Record<ChartBDKeys, string> = {
  speed_avg: `скорость, ${CHART_UNITS.speed_avg}`,
  passage_count: `кол-во ${CHART_UNITS.passage_count}`,
  density: CHART_UNITS.density,
};

export const DAY_INTERVAL = 3600;

export const AVERAGE_COLORS: IColors = {
  average: BarColors.Average,
  dangerous: BarColors.Normal,
  normal: BarColors.Dangerous,
};

export const INTANCY_COLORS: IColors = {
  average: BarColors.Average,
  dangerous: BarColors.Dangerous,
  normal: BarColors.Normal,
};

export const COLORS_BAR: Record<ChartBDKeys, IColors> = {
  passage_count: INTANCY_COLORS,
  density: INTANCY_COLORS,
  speed_avg: AVERAGE_COLORS,
};
