import { isNumber } from 'lodash';
import { useEffect, useState } from 'react';
import type { CategoricalChartFunc } from 'recharts/types/chart/generateCategoricalChart';

import api from '../../../../../api';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { getTimeWithTimeZone } from '../../../../TrafficLightDetailed/TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeWithTimeZone';
import { DEFAULT_RANGE } from '../constants/constants';
import getPredictKeyToSec from '../helpers/getPredictKeyToSec';
import { UseCandleChartProps } from '../models/CandleChart.model';

const { useDtStatisticData } =
  api.services.dtStatistics.predictData.dtStatisticApi;

const APP_PATH = 'Chart.CandleChart.hooks.useCandleChart';

const useCandleChart = ({
  id,
  valueType,
  isUpdate,
  predictInterval,
  setTimeUpdate,
}: UseCandleChartProps) => {
  const { filterDtData } = rootStore.detailedStore;

  const [activeIndex, setActiveIndex] = useState<N<number>>(null);

  const { data, isError, isFetching, refetch } = useDtStatisticData({
    appPath: APP_PATH,
    id,
    left: DEFAULT_RANGE,
    right: getPredictKeyToSec(predictInterval),
    valueType,
    directions: filterDtData.directions ?? null,
    enabled: Boolean(filterDtData.directions && isNumber(id)),
    specialKeys: [valueType],
  });

  useEffect(() => {
    refetch();
  }, [isUpdate, refetch]);

  useEffect(() => {
    const { dateFormatted } = getTimeWithTimeZone();

    setTimeUpdate((prev) => ({ ...prev, CandlesChart: dateFormatted }));
  }, [isFetching, setTimeUpdate]);

  const onMouseMove: CategoricalChartFunc = (data) => {
    const index = Number(data?.activeLabel);

    !isNaN(index) && setActiveIndex(index);
  };

  const onMouseLeave = () => setActiveIndex(null);

  return {
    calcData: data?.calcData ?? [],
    chartNames: data?.chartNames ?? null,
    isError,
    isFetching,
    activeIndex,
    onMouseMove,
    onMouseLeave,
  };
};

export default useCandleChart;
