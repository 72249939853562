import { isNumber } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import {
  COMMANDS,
  TlManagementKeys,
} from '../../../../../../constants/commands';
import eventBus from '../../../../../../eventBus';
import rootStore from '../../../../../../stores/rootStore/rootStore';
import { TL } from '../../../../../../ts/models/tl.model';
import { postCommand } from '../../../helpers/postCommand';
import { IPostCommandProps } from '../../../model/postCommands.model';
import { getCorrectDateText } from '../../../PhaseHold/helpers/getCorrectDateText';
import { secondsToTime } from '../../../PhaseHold/helpers/secondsToTime';
import BodyContent from '../../../PhaseHold/ModalConfirm/BodyContent/BodyContent';
import { ModalConfirmProps } from '../../../PhaseHold/ModalConfirm/ModalConfirm';
import ModalSignalProgram from '../../ModalSignalProgram/ModalSignalProgram';
import { ProgramsInfo, SelectedTime } from '../../models/plansControl.model';

const { SET_OBJECT_PLAN, SET_OBJECT_PLAN_TIME_ON_SCHEDULER } = COMMANDS;
const { PlanSelection } = TlManagementKeys;
const PATH =
  '.TrafficLightDetailed.TlManagement.PlanSelect.ModalPlan.useModalPlan';

interface UseModalPlanProps {
  selectedPlan: N<number>;
  selectedTime: SelectedTime;
  activeTL: TL;
  programInfo: ProgramsInfo;
  clearPlanSelect: () => void;
}

const useModalPlan = ({
  selectedPlan,
  selectedTime,
  activeTL,
  programInfo,
  clearPlanSelect,
}: UseModalPlanProps) => {
  const { activeControlKey } = rootStore.detailedStore;

  const [openModal, setOpenModal] = useState(false);
  const [isLoadingRequest, setIsLoadingRequest] = useState<boolean>(false);

  useEffect(() => {
    const unsubscribe = eventBus.clickedControlBtn.subscribe(() => {
      activeControlKey === PlanSelection && setOpenModal(true);
    });

    return () => unsubscribe();
  }, [activeControlKey]);

  const onOkConfirm = useCallback(async () => {
    if (!selectedTime.duration) return;

    setIsLoadingRequest(true);

    const selectedDate = selectedTime.date?.unix();

    const cmdInfo = `План № ${selectedPlan} (запущен${
      !selectedDate
        ? ` на ${secondsToTime(selectedTime.duration)}`
        : ` ${getCorrectDateText(selectedDate, selectedTime.duration)}`
    })`;

    const propsPostCommand: IPostCommandProps = {
      id: activeTL.id,
      command: selectedPlan,
      cmdTitle: !selectedDate
        ? SET_OBJECT_PLAN
        : SET_OBJECT_PLAN_TIME_ON_SCHEDULER,
      cmdInfo,
      selectedDate: selectedTime.date,
      durationTime: selectedTime.duration ?? undefined,
      disabledTime: 0,
    };

    await postCommand(propsPostCommand, PATH);

    setIsLoadingRequest(false);
    setOpenModal(false);
    clearPlanSelect();
  }, [activeTL.id, clearPlanSelect, selectedPlan, selectedTime]);

  const modalConfirmProps: Omit<ModalConfirmProps, 'icon'> = {
    modalTitle: `Отправка команды управления для светофорного объекта ${activeTL.deviceInfo.externalDeviceId} ${activeTL.caption}`,
    openModal,
    setOpenModal,
    onOkConfirm,
    width: '1400px',
    okButtonProps: { loading: isLoadingRequest },
  };

  const content = [
    selectedPlan ? `План № ${selectedPlan}` : 'План не найден',
    selectedTime.date && selectedTime.duration
      ? `Период: ${getCorrectDateText(
          selectedTime.date.unix(),
          selectedTime.duration
        )}`
      : '',
    `Длительность: ${secondsToTime(selectedTime.duration)}`,
  ];

  const title = `Подтверждаете включение режима "${
    !selectedTime.date
      ? 'выбор плана управления'
      : 'выбор плана управления по времени'
  }"?`;

  const { isPlansLoading, programs, sgnProgramValues } = programInfo;

  const activePlanIndex = isNumber(selectedPlan) && selectedPlan - 1;

  const modalBodyProps = {
    content: <BodyContent title={<strong>{title}</strong>} content={content} />,
    image: isNumber(activePlanIndex) ? (
      <ModalSignalProgram
        programExecTime={programs.at(activePlanIndex)?.execTime ?? null}
        csdData={sgnProgramValues.at(activePlanIndex)?.csdData}
        phasesSet={sgnProgramValues.at(activePlanIndex)?.phasesSet}
        isError={!!sgnProgramValues.at(activePlanIndex)?.isError}
        isLoadingProgram={!isPlansLoading}
        id={activeTL.id}
      />
    ) : null,
  };

  return { modalConfirmProps, modalBodyProps };
};

export default useModalPlan;
