import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../../../stores/rootStore/rootStore';
import Popover from '../../../../../ui-kit/Popover/Popover';

import styles from './MeteoItem.module.scss';

interface MeteoItemProps {
  icon: JSX.Element;
  value: number | string;
  tooltip: string;
  isVertical?: boolean;
}

const MeteoItem: FC<MeteoItemProps> = ({
  icon,
  value,
  tooltip,
  isVertical = false,
}) => {
  const { interfaceTipsDelay } = rootStore.uiStore;

  const containerStyles = classNames({
    [styles.container]: true,
    [styles.vertical]: isVertical,
  });

  return (
    <Popover content={tooltip} tipsDelay={interfaceTipsDelay}>
      <span className={containerStyles}>
        <span className={styles.icon}>{icon}</span>
        <p>{value}</p>
      </span>
    </Popover>
  );
};

export default observer(MeteoItem);
