import classNames from 'classnames';
import { isNumber } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { ReactComponent as DtIco } from '../../../assets/icons/map/ic_detectors.svg';
import { COLORS } from '../../../constants/colorsConstants';
import rootStore from '../../../stores/rootStore/rootStore';
import { System } from '../../../ts/enums/enums';
import { ICON_SIZE } from '../../Constructor/constants/constants';
import DeviceIcon from '../DeviceIcon/DeviceIcon';
import DeviceId from '../DeviceIcon/DeviceId/DeviceId';
import getDeviceIcoFill from '../helpers/getDeviceIcoFill';
import getIconSize from '../helpers/getIconSize';
import { DeviceIconProps } from '../models/device.model';

import styles from './DtIcon.module.scss';

interface IconProps {
  isVisible?: boolean;
  offsetX?: number;
  offsetY?: number;
  size?: number;
  onClick?: () => void;
  id: number;
  isId?: boolean;
  rate?: number;
  activeId?: N<number>;
  isIcoWithId?: boolean;
}

const DtIcon: FC<IconProps> = ({
  isVisible = true,
  offsetX = 0,
  offsetY = 0,
  size = ICON_SIZE.DEFAULT,
  rate,
  onClick,
  id,
  activeId,
  isIcoWithId = false,
  isId = false,
}) => {
  const { detectors } = rootStore.mapDataStore;
  const { infoData } = rootStore.uiStore;

  if (!isVisible) return null;

  const icoSize = getIconSize(size, rate);
  const sizeProps = { width: icoSize, height: icoSize };
  const isActiveId = activeId === id;
  const isDtActive = id === infoData?.id;
  const fill =
    isDtActive || isActiveId ? COLORS.PRIMARY : getDeviceIcoFill(id, detectors);

  const containerStyle = classNames({
    [styles.icoCursor]: true,
    [styles.inactive]: isNumber(activeId),
    [styles.activeId]: isActiveId,
  });

  const deviceIcoProps: DeviceIconProps = {
    offsetX,
    offsetY,
    rate,
    size: icoSize,
    onClick,
    className: containerStyle,
    fill,
    id,
    system: System.Detectors,
  };

  if (isIcoWithId)
    return (
      <DeviceIcon {...deviceIcoProps}>
        <DeviceId id={id} size={icoSize} isIcoWithId />
        <DtIco {...sizeProps} className={styles.dtIco} />
      </DeviceIcon>
    );

  return (
    <DeviceIcon {...deviceIcoProps}>
      {isId ? (
        <DeviceId id={id} size={icoSize} />
      ) : (
        <DtIco {...sizeProps} className={styles.dtIco} />
      )}
    </DeviceIcon>
  );
};

export default observer(DtIcon);
