export const columnsPhDir = [
  {
    title: 'Фаза',
    dataIndex: 'phase',
    key: 'phase',
  },
  {
    title: 'T min',
    dataIndex: 'timeMin',
    key: 'timeMin',
  },
  {
    title: 'T max',
    dataIndex: 'timeMax',
    key: 'timeMax',
  },
  {
    title: 'Направления',
    dataIndex: 'allowedDirs',
    key: 'allowedDirs',
  },
];
