import { FC } from 'react';

import { secondsToTime } from '../../../TrafficLightDetailed/TlManagement/PhaseHold/helpers/secondsToTime';

import styles from './TooltipPhasesContent.module.scss';

interface TooltipPhasesContentProps {
  length: number;
}

const TooltipPhasesContent: FC<TooltipPhasesContentProps> = ({ length }) => {
  return (
    <>
      <p className={styles.phaseBlockTitleTime}>
        Общая длительность фазы:{' '}
        {secondsToTime(length, { H: false, M: true, S: true })}
      </p>
    </>
  );
};

export default TooltipPhasesContent;
