import { COLORS } from '../../../constants/colorsConstants';

export const XMLNS = 'http://www.w3.org/2000/svg';

export const MAP_ICO_SIZE = 40;

export const MAP_ICO_BASIC = {
  width: MAP_ICO_SIZE,
  height: MAP_ICO_SIZE,
  viewBox: '0 0 36 36',
  xmlns: XMLNS,
  stroke: COLORS.WHITE,
  strokeWidth: '2px',
  fill: COLORS.PRIMARY,
};
