import {
  AdapterTypes,
  FormattedStatistic,
  MainAdapter,
  StatisticByType,
} from '../../../../../api/adminObjects/systems/adapter/model/adapter.model';
import { findById } from '../../../../../helpers/findBy';
import { getObjectEntries } from '../../../../../helpers/getObjectEntries';
import { LibraryValues } from '../../../../../stores/uiStore/uiStore.model';

const getRegionNames = (
  regionIds: N<number[]>,
  regionsDict: LibraryValues[]
) => {
  return (
    regionIds?.reduce((acc: FormattedStatistic['regions'], id) => {
      const regionName = findById(regionsDict, id)?.name;

      regionName &&
        acc.push({
          id,
          name: regionName,
        });

      return acc;
    }, []) ?? []
  );
};

export const getSystemNames = (
  statistics: StatisticByType,
  systemDict: LibraryValues[],
  regionsDict: LibraryValues[]
): FormattedStatistic[] => {
  const adapterInfo = getObjectEntries(statistics) as [
    AdapterTypes,
    MainAdapter[]
  ][];

  if (!adapterInfo.length) return [];

  const statistic = adapterInfo
    .map(([type, adapters]) =>
      adapters.map((el) => {
        const systemName = findById(systemDict, el.sc_system_id)?.name;
        const regionNames = getRegionNames(
          el.sc_system_regions_id ?? null,
          regionsDict
        );

        return {
          ...el,
          systemName: systemName ?? null,
          regions: regionNames,
          adapterType: type,
        };
      })
    )
    .flat();

  return statistic;
};
