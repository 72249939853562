import { getAppPathHeaders } from '../../helpers/getAppPathHeaders';
import http from '../../http';
import tryCatchWrapper from '../../tryCatchWrapper';
import { SERVER } from '../constants';

const INTEGRATION_CONFIGURATION = SERVER + '/integration-configuration';

export const integrationConfiguration = {
  setAccessEnable: async (appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.patch(
        INTEGRATION_CONFIGURATION + '/access-enable',
        undefined,
        {
          headers: getAppPathHeaders(appPath),
        }
      );

      return res.status;
    }),
  setAccessDisable: async (appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.patch(
        INTEGRATION_CONFIGURATION + '/access-disable',
        undefined,
        {
          headers: getAppPathHeaders(appPath),
        }
      );

      return res.status;
    }),
};
