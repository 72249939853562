import { findById } from '../../../../helpers/findBy';
import { TL } from '../../../../ts/models/tl.model';
import { getCircleTime } from '../../../NewSignalProgram/helpers/getTLSignalData';
import {
  DEFAULT_DISABLED_TIME_DURATION,
  MINUTE_SECONDS,
} from '../constants/constants';

const NO_PLAN = 'План не найден';
const UNKNOWN_TL = 'СО не найден';
const DISABLED_DEFAULT = 0;

export const getCurrentTLInfo = (tls: TL[], id: number) => {
  const currentObject = findById(tls, id);

  if (!currentObject)
    return {
      sgnProgramName: NO_PLAN,
      tlName: UNKNOWN_TL,
      progNum: null,
      disabledTime: DISABLED_DEFAULT,
    };

  const {
    caption,
    tlStatus,
    deviceInfo: { externalDeviceId },
    tlTacts,
    tlGeneral: { progNum },
  } = currentObject;

  const sgnProgramName = tlStatus.command?.full ?? NO_PLAN;
  const tlName = externalDeviceId + ' ' + caption;

  const disabledTime =
    Math.ceil((getCircleTime(tlTacts) * 2) / MINUTE_SECONDS) ??
    DEFAULT_DISABLED_TIME_DURATION;

  return {
    sgnProgramName,
    tlName,
    progNum,
    disabledTime,
  };
};
