import { SECOND } from '../../../constants/constants';
import rootStore from '../../../stores/rootStore/rootStore';
import { PanelType } from '../../../ts/enums/enums';
import { isPanelType } from '../../../ts/typeGuards/typeGuards';
import { MAIN_BTNS } from '../../Sidebar/MainBlock/constants/constants';

const applyPanel = (panelType: PanelType, isPanel: boolean) => {
  const { setKeysValues, switchPanel } = rootStore.uiStore;

  setTimeout(() => {
    setKeysValues({
      panelType,
      isPanel,
    });

    switchPanel(panelType, false);
  }, SECOND);
};

const handlePanel = (params: URLSearchParams) => {
  const { hasAccess } = rootStore.userDataStore;

  const panelType = params.get('panelType');
  const isPanel = params.get('isPanel') === 'true';

  if (!isPanelType(panelType)) return;
  const btnData = MAIN_BTNS.find(({ name }) => name === panelType);

  panelType === PanelType.Profile && applyPanel(panelType, isPanel);

  if (!btnData) return;
  const { code, additionalCode } = btnData;

  if (!hasAccess(code) || (additionalCode && !hasAccess(additionalCode)))
    return;

  applyPanel(panelType, isPanel);
};

export default handlePanel;
