import { useState } from 'react';

import api from '../api';
import { Path } from '../api/api.model';
import rootStore from '../stores/rootStore/rootStore';
import { isMarkerKey, isPointKey } from '../ts/models/profileSettings.model';

const useRemoteSettings = () => {
  const { uiStore, pointsUdsStore } = rootStore;
  const { setKeyValue, setTheme, setMarkerKey } = uiStore;
  const { setPointsValue } = pointsUdsStore;
  const [isLoading, setIsLoading] = useState(false);

  const applySettings = (settings: any) => {
    for (const settingKey in settings) {
      const value = settings[settingKey];

      switch (settingKey) {
        case 'theme':
          setTheme(value);
          break;
        case 'isWidgetLoad':
          continue;
        default:
          if (isMarkerKey(settingKey)) {
            setTimeout(() => {
              setMarkerKey(settingKey, value);
            }, 0);
            break;
          }

          if (isPointKey(settingKey)) {
            setPointsValue(settingKey, value);
            break;
          }

          setKeyValue(settingKey as keyof typeof uiStore, value);
      }
    }
  };

  const saveSettingsItem = async (
    name: string,
    value: unknown,
    appPath: Path
  ) => {
    setIsLoading(true);
    const res = await api.user.settings.saveItem(name, value, appPath);

    setIsLoading(false);

    return res;
  };

  return { applySettings, saveSettingsItem, isLoading };
};

export default useRemoteSettings;
