import { Modal } from 'antd';
import { useEffect } from 'react';

import {
  APP_VERSION,
  IS_DEV,
  KEY_EVENT_COMPONENT,
} from '../constants/constants';
import useKeyCombination from '../keyCombination/useKeyCombination';

const LOCAL_STORAGE_COMMIT_HASH_KEY = 'commitHash';
const LOCAL_STORAGE_APP_VERSION_KEY = 'appVersion';

const RELOAD_BTN_TITLE = 'Перезагрузить';

const useExtraReload = () => {
  const envCommitHash = process.env.REACT_APP_COMMIT_HASH;

  const updateWindow = (event: any) => {
    if (event?.target) {
      event.preventDefault();
    }

    localStorage.setItem(LOCAL_STORAGE_COMMIT_HASH_KEY, envCommitHash ?? '');
    localStorage.setItem(LOCAL_STORAGE_APP_VERSION_KEY, APP_VERSION ?? '');

    //@ts-ignore
    window.location.reload(true);
  };

  useKeyCombination(KEY_EVENT_COMPONENT.SHIFT_F5.app, updateWindow);

  const createModal = () => {
    const localStorageAppVersion = localStorage.getItem(
      LOCAL_STORAGE_APP_VERSION_KEY
    );

    const localStorageHash = localStorage.getItem(
      LOCAL_STORAGE_COMMIT_HASH_KEY
    );

    const versionFieldContent = localStorageAppVersion
      ? localStorageAppVersion + ' => ' + APP_VERSION
      : APP_VERSION;

    const hashFieldContent = localStorageHash
      ? localStorageHash + ' => ' + envCommitHash
      : envCommitHash;

    const modalContent = (
      <div>
        <p>Версия приложения была обновлена</p>
        <p>Версия: {versionFieldContent}</p>
        <p>Хэш коммита: {hashFieldContent}</p>
        <p>
          Для корректной работы приложения, пожалуйста обновите кэш страницы
          комбинацией клавиш Shift+F5 или нажатием кнопки {RELOAD_BTN_TITLE}
        </p>
      </div>
    );

    Modal.info({
      title: 'Необходимо обновить кэш страницы',
      content: modalContent,
      okText: RELOAD_BTN_TITLE,
      onOk: updateWindow,
      centered: true,
    });
  };

  useEffect(() => {
    if (IS_DEV) return;

    const localStorageAppVersion = localStorage.getItem(
      LOCAL_STORAGE_APP_VERSION_KEY
    );

    if (APP_VERSION !== localStorageAppVersion) {
      createModal();
    }

    if (!envCommitHash) return;

    const localStorageHash = localStorage.getItem(
      LOCAL_STORAGE_COMMIT_HASH_KEY
    );

    if (envCommitHash !== localStorageHash) {
      createModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useExtraReload;
