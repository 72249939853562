import { Switch } from 'antd';
import { SwitchChangeEventHandler } from 'antd/lib/switch';
import { FC, useState } from 'react';

import api from '../../../../../api';
import { wait } from '../../../../../helpers/wait';
import { IServerData } from '../../NJSInfo.model';
import InfoBox from '../InfoBox/InfoBox';

import styles from './IntegrationApiInfo.module.scss';

interface IntegrationApiInfoProps {
  data: IServerData;
}

const INTEGRATION_API_INFO_TITLE = 'Интеграционное API';
const APP_PATH = '/adminPanel/webserver/info/IntegrationApiInfo';

const IntegrationApiInfo: FC<IntegrationApiInfoProps> = ({ data }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick: SwitchChangeEventHandler = async (
    checked: boolean,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setIsLoading(true);
    const request =
      api.server.integrationConfiguration[
        checked ? 'setAccessEnable' : 'setAccessDisable'
      ];

    await request(APP_PATH);
    await wait(1500);

    setIsLoading(false);
  };

  return (
    <InfoBox title={INTEGRATION_API_INFO_TITLE} className={styles.infoBox}>
      <p>
        Статус доступности:{' '}
        <Switch
          checked={data.integrationApiAccess}
          checkedChildren="Включено"
          unCheckedChildren="Выключено"
          onClick={handleClick}
          loading={isLoading}
        />
      </p>
    </InfoBox>
  );
};

export default IntegrationApiInfo;
