/* eslint-disable camelcase */
import { z } from 'zod';

import { LinesTypes, ZonesTypes } from '../../../constants/devicesConstants';
import { NN, NNInt, latitude, longitude, XY, Int } from '../../zod/utils.zod';
import { BasicMapDataSchema } from '../mapObjects.zod';

const BindGpsPointSchema = z.object({
  latitude,
  longitude,
});

const LocationSchema = z.object({
  bindAddress: z.string(),
  bindGpsPoint: BindGpsPointSchema,
});

const ResolutionSchema = z.object({
  width: NNInt,
  height: NNInt,
});

export const PointSchema = z.object({
  x: NN,
  y: NN,
});

export const BasicLaneSchema = z.object({
  uid: z.string().nullable(),
  number: NNInt.optional(),
  points: PointSchema.array(),
});

export const LaneSchema = BasicLaneSchema.extend({
  isEnabled: z.boolean(),
});

const SceneSchema = z.object({
  lanes: LaneSchema.array(),
  trafficLines: BasicLaneSchema.array(),
});

export const CameraSettingSchema = z.object({
  description: z.string().optional(),
  designation: z.string().optional(),
  source: z.string().optional(),
  uid: z.string().optional(),
  vendor: z.string().optional(),
  location: LocationSchema.optional(),
  resolution: ResolutionSchema.optional(),
  scene: SceneSchema.optional(),
});

export const PointSchema2 = PointSchema.extend({
  item: NNInt,
});

const BasicZonesSchema = z.object({
  uid: z.string().nullable(),
  number: NNInt,
  isEnabled: z.boolean(),
  points: PointSchema2.array().nullable(),
});

export const ZonesSchema = BasicZonesSchema.extend({
  type: z.nativeEnum(ZonesTypes).array(),
});

export const LinesSchema = BasicZonesSchema.extend({
  type: z.nativeEnum(LinesTypes).array(),
});

const SceneSchema2 = z.object({
  zones: ZonesSchema.array().nullable(),
  lines: LinesSchema.array().nullable(),
});

export const CameraSettingSchema2 = z.object({
  scene: SceneSchema2,
  uid: z.string().nullable(),
  camera_uid: z.string().nullable(),
});

const DtExtJSettings = z.object({
  dt_ext_jsetting: CameraSettingSchema2,
});

export const ExtOptionsSchema = z.object({
  camera_jsetting: CameraSettingSchema.nullable().optional(),
  camera_jsetting2: DtExtJSettings.nullable().optional(),
});

const SourceDtSchema = z.object({
  ext_options: ExtOptionsSchema.optional(),
});

export const InfoDtSchema = z.object({
  latitude,
  longitude,
  source_info: SourceDtSchema.optional(),
});

export const LaneParamsSchema = z.object({
  id: Int.positive(),
  span: NN,
  width: NN,
  offset: z.number(),
  number: NNInt.nullable(),
});

export const LaneDirectionSchema = z.object({
  isApplied: z.boolean().optional(),
  isMain: z.boolean().optional(),
  angle: NNInt,
  offsetX: z.number(),
  offsetY: z.number(),
  mainOffsetX: z.number(),
  mainOffsetY: z.number(),
  mainWidth: NNInt,
  width: NN,
  length: NNInt,
  opacity: NN.lte(1),
  laneParams: LaneParamsSchema.array(),
  color: z.string(),
});

export const TLanesFromProfilesSchema = LaneDirectionSchema.extend({
  isEnabled: z.boolean().optional(),
  centerPoint: XY,
  id: z.number(),
  caption: z.string(),
  isGradient: z.boolean(),
  comment: z.string(),
  longitude: longitude.optional(),
  latitude: latitude.optional(),
  linkedCameraId: NNInt.nullable().optional(), // temporary optional
  toCamera: LaneDirectionSchema.optional(),
  fromCamera: LaneDirectionSchema.optional(),
});

export const DtDaiJdt83Schema = TLanesFromProfilesSchema.extend({});

export const DetectorSchema = BasicMapDataSchema.extend({
  regionId: z.number(),
  latitude,
  longitude,
  dai_jdt80: InfoDtSchema,
  finalFromProfiles: TLanesFromProfilesSchema.nullable(),
  dai_jdt83: DtDaiJdt83Schema.nullable(),
});
