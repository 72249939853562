import { Dayjs } from 'dayjs';

import { ChartBDKeys } from '../../../InfoPanel/SystemsInfo/PopupDetector/Chart/constants/constants';
import { DATES, DatesType, PREDICT_PERIOD } from '../constants/constants';

export type TSelectDates = [Dayjs, Dayjs];
export type TInterval = N<Dayjs>;

export interface IDateInfo {
  selectedDate: TSelectDates;
  interval: number;
  radioValueDate: string;
  isNeedUtcFormatted: boolean;
}

export interface IPickerValue {
  rangePicker: N<TSelectDates>;
  timePicker: TInterval;
  status: U<'error' | 'warning'>;
}

export type RadioValue = ChartBDKeys;

export interface IDtInfo {
  title: string;
  dtId: number;
}

export interface IGraphValues {
  graphTitle: RadioValue;
  dtInfo: IDtInfo;
}

export interface ITimesInfo {
  id: number;
  timeZone: number;
  label: string;
}

export interface FormattedDataGraph {
  title: string;
  data: OWKS<SN>[];
}

export type DatesKeys = keyof DatesType;

export type PeriodType = typeof PREDICT_PERIOD;
export type PeriodKeys = keyof PeriodType;

export const isLineChartPeriod = (
  value: Nullish<string>
): value is DatesKeys => {
  return Boolean(value && value in DATES);
};

export const isCandleChartPeriod = (
  value: Nullish<string>
): value is PeriodKeys => {
  return Boolean(value && value in PREDICT_PERIOD);
};
