import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { BsLayers } from 'react-icons/bs';

import rootStore from '../../../stores/rootStore/rootStore';

import GISContent from './GISContent/GISContent';

import styles from './LayerTooltipContent.module.scss';

interface LayerTooltipContentProps {
  className?: string;
}

const LayerTooltipContent: FC<LayerTooltipContentProps> = ({ className }) => {
  const { layerTooltipData } = rootStore.uiStore;

  if (!layerTooltipData) return null;

  const { fields, isWeather, title } = layerTooltipData;

  if (isWeather)
    return <div className={styles.weatherTooltipContainer}>{title}</div>;

  return (
    <>
      <div className={styles.gisLayersTooltipContainer}>
        <div className={styles.tooltipTitle}>
          <BsLayers className={styles.avatar} />
          {title}
        </div>
      </div>

      <GISContent fields={fields} />
    </>
  );
};

export default observer(LayerTooltipContent);
