import { FC } from 'react';

import {
  MODES_INFO,
  TlManagementKeys,
} from '../../../../../constants/commands';
import ManagementCommands from '../../ManagementCommands/ManagementCommands';
import { BasicControlTabProps } from '../../model/tlManagement.model';
import PhaseHoldChild from '../../PhaseHold/PhaseHold';
import ChangeCurrentPlan from '../../PhaseTimeChangeCurrentPlanMode/PhaseTimeChangeCurrentPlanMode';
import PlanSelect from '../../PlanSelect/PlanSelect';
import TemporaryPlan from '../../TemporaryPlan/TemporaryPlan';

const {
  Management,
  CoordinatedManagement,
  PhaseHold,
  PhaseTimeChangeCurrentPlanMode,
  PlanSelection,
} = TlManagementKeys;

export const TABS_CHILDREN: Record<
  TlManagementKeys,
  FC<BasicControlTabProps>
> = {
  [Management]: ManagementCommands,
  [PhaseHold]: PhaseHoldChild,
  [PlanSelection]: PlanSelect,
  [CoordinatedManagement]: TemporaryPlan,
  [PhaseTimeChangeCurrentPlanMode]: ChangeCurrentPlan,
};

const PLAN_SELECT_ACCESS_COMMANDS = [
  MODES_INFO.Work.dsc,
  MODES_INFO.AdaptiveControl.dsc,
];

export const REMOTE_CONTROL_NAME: Record<TlManagementKeys, N<string[]>> = {
  [Management]: null,
  [PhaseHold]: [MODES_INFO.RemoteControl.dsc],
  [PlanSelection]: PLAN_SELECT_ACCESS_COMMANDS,
  [CoordinatedManagement]: PLAN_SELECT_ACCESS_COMMANDS,
  [PhaseTimeChangeCurrentPlanMode]: PLAN_SELECT_ACCESS_COMMANDS,
};
