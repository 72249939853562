import { ReactNode } from 'react';
import { RiTrafficLightLine } from 'react-icons/ri';

import { findById } from '../../../../../helpers/findBy';
import parseTLMode from '../../../../../helpers/parseTLMode';
import { TL } from '../../../../../ts/models/tl.model';

export interface IDataTlsList {
  title: string;
  avatar: ReactNode;
  description: string;
  id: number;
}

export const getTlsDataList = (tlsIds: number[], tls: TL[]) => {
  const dataList = tlsIds.reduce((acc: IDataTlsList[], id) => {
    const activeTl = findById(tls, id);

    if (!activeTl?.tlStatus) return acc;

    const {
      tlStatus: { mode },
      deviceInfo: { dsc, caption },
    } = activeTl;

    const { color } = parseTLMode(mode);

    const description = dsc || 'Данные об адресе отсутствуют';

    acc.push({
      title: `id${id} | ${caption}`,
      avatar: <RiTrafficLightLine color={color} />,
      description,
      id,
    });

    return acc;
  }, []);

  return dataList;
};
