import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/ru';
import utc from 'dayjs/plugin/utc';

import { DATE_FORMAT } from '../../../../../../../constants/constants';

import { getTimeZones } from './getTimeZone';

dayjs.extend(utc);

export const MINUTES_IN_HOUR = 60;

export interface IGetTimeWithZoneProps {
  timeZone?: number;
  dateJS?: Dayjs;
  dateFormat?: string;
  isUTCZero?: boolean;
  isConvertToRegionTZ?: boolean;
}

interface ReturnValue {
  dateFormatted: string;
  dateFormattedJS: Dayjs;
}

type FuncGetTimeWithTZ = (options?: IGetTimeWithZoneProps) => ReturnValue;

export const getTimeWithTimeZone: FuncGetTimeWithTZ = ({
  timeZone = getTimeZones().timeZoneRegion,
  dateJS = dayjs(),
  dateFormat = DATE_FORMAT,
  isUTCZero = false,
  isConvertToRegionTZ = false,
} = {}) => {
  const utc = !isUTCZero ? dateJS.utcOffset() / MINUTES_IN_HOUR : 0;

  const difTimeZone = timeZone - utc;

  const dateRegionTZ = dateJS.utc(true).subtract(timeZone, 'hour');

  const dateFormattedJS = !isConvertToRegionTZ
    ? dateJS.add(difTimeZone, 'hour').locale('ru')
    : dateRegionTZ;

  const dateFormatted = dateFormattedJS.format(dateFormat);

  return { dateFormatted, dateFormattedJS };
};
