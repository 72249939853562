import { Feature } from 'ol';
import { Draw } from 'ol/interaction';
import VectorSource from 'ol/source/Vector';
import { Style } from 'ol/style';

import { TMap } from '../../stores/mapStore/mapStore.model';
import { ICON_STYLE } from '../styles/predefined/graphics';

const POINTER_INTERACTION = 'map_point_interaction';

export interface IDrawResult {
  feature?: Feature;
}

export const getMapPoint = async (map: TMap): Promise<IDrawResult> => {
  const errorResponse: IDrawResult = {};

  if (!map) {
    return errorResponse;
  }

  const interactions = map.getInteractions().getArray();

  let interaction = interactions.find(
    (item) => item.get('id') === POINTER_INTERACTION
  );

  if (!interaction) {
    interaction = new Draw({
      type: 'Point',
      stopClick: true,
      source: new VectorSource({
        features: [],
      }),
      style: new Style({
        image: ICON_STYLE,
      }),
    });

    interaction.set('id', POINTER_INTERACTION);

    map.addInteraction(interaction);
  }

  interaction.setActive(false);

  return new Promise((resolve) => {
    if (!interaction) {
      resolve(errorResponse);
    }

    interaction && interaction.setActive(true);

    interaction &&
      interaction instanceof Draw &&
      interaction.on('drawend', (evt) => {
        interaction && interaction.setActive(false);
        interaction && map.removeInteraction(interaction);

        const { feature } = evt;

        resolve({ feature });
      });
  });
};
