import rootStore from '../../../../stores/rootStore/rootStore';
import { handleZoomUI } from '../../../ui-kit/UiMap/helpers/handleZoomUI';

export const handleZoom = (zoom: number) => {
  const { setKeyValue } = rootStore.uiStore;
  const { map } = rootStore.mapStore;

  if (!map) return;

  setKeyValue('clickedCartographyObj', null);

  handleZoomUI(zoom, map);
};
