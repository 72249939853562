import { FC } from 'react';

import { ModalConfirmProps } from '../../model/dispatchGroup.model';

import { MANAGE_PANEL } from './constants/constants';
import ControlBtn from './ControlBtn/ControlBtn';

import styles from './ControlPanel.module.scss';

interface ControlPanelProps {
  tlIds: number | number[];
  isActiveCoordTL: boolean;
  isDisabledControl?: boolean;
  ModalComponent?: FC<ModalConfirmProps>;
}

const ControlPanel: FC<ControlPanelProps> = ({
  tlIds,
  isActiveCoordTL,
  ModalComponent,
  isDisabledControl = false,
}) => {
  return (
    <div className={styles.container}>
      {MANAGE_PANEL.map((el) => (
        <ControlBtn
          key={el.com}
          mode={el.com}
          tlIds={tlIds}
          isActiveCoordTL={isActiveCoordTL}
          ModalComponent={ModalComponent}
          isDisabledControl={isDisabledControl}
        />
      ))}
    </div>
  );
};

export default ControlPanel;
