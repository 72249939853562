import { FC } from 'react';

import { IServerData } from '../../NJSInfo.model';

import getStatusStyle from './helpers/getStatusStyle';

import styles from './OsInfo.module.scss';

interface OsInfoProps {
  data: IServerData;
}

const TITLE = 'Информация о нагрузке системы';

const OsInfo: FC<OsInfoProps> = ({ data }) => {
  const { cpuCount, cpuPercentage, totalMemMb, usedMemMb } = data.os;

  const memory = Math.round((usedMemMb * 100) / totalMemMb);

  return (
    <section className={styles.section}>
      <h3 className={styles.title}>{TITLE}</h3>
      <div className={styles.wrapper}>
        <div className={styles.item} style={getStatusStyle(cpuPercentage)}>
          <p className={styles.data}>{cpuPercentage}</p>
          <p>Загрузка CPU процессами, %</p>
        </div>

        <div className={styles.item} style={getStatusStyle(memory)}>
          <p className={styles.data}>
            {`${Math.round(usedMemMb)} / ${Math.round(totalMemMb)}`}
          </p>
          <p>Выделено памяти процессами, мб</p>
        </div>

        <div className={styles.item}>
          <p className={styles.data}>{cpuCount}</p>
          <p>Всего ядер, шт.</p>
        </div>
      </div>
    </section>
  );
};

export default OsInfo;
