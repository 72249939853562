import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';

import useArrayWithDelay from '../../../../hooks/useArrayWithDelay';
import rootStore from '../../../../stores/rootStore/rootStore';
import { LinkedDtsProps } from '../../PointUdsOverlay/PointUdsOverlay.model';
import TLanesDirections from '../../TLanesDirections/TLanesDirections';

import getDtProfiles from './helpers/getDtProfiles';

const LinkedDetectors: FC<LinkedDtsProps> = ({ dtIds, isSchemaODD }) => {
  const { markers } = rootStore.uiStore;
  const { isCrossroadBorder, detectors, cameras } = rootStore.mapDataStore;

  const lanes = useMemo(
    () => getDtProfiles(dtIds, detectors, cameras, true),
    [dtIds, detectors, cameras]
  );

  const tLanes = useArrayWithDelay({
    arr: lanes,
    isClean: !lanes.length || !isCrossroadBorder,
  });

  if (!markers.isDetectors) return null;

  return (
    <>
      {tLanes.map((lane) => {
        lane.isEnabled = true;

        return (
          <TLanesDirections
            key={`lane_${lane.id}`}
            trafficLane={lane}
            isTLCrossroad={isSchemaODD}
          />
        );
      })}
    </>
  );
};

export default observer(LinkedDetectors);
