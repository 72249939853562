import { Skeleton } from 'antd';
import { FC } from 'react';

interface SkeletonInputProps {
  value: SN;
  loading: boolean;
}

const SkeletonInput: FC<SkeletonInputProps> = ({ loading, value }) => {
  if (loading) return <Skeleton.Input active size={'default'} />;

  return <>{value}</>;
};

export default SkeletonInput;
