import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import rootStore from '../../../stores/rootStore/rootStore';

import useInitSearchParams from './useInitSearchParams';

const useAppSearchParams = () => {
  const { regionData, panelType, isPanel, infoData } = rootStore.uiStore;

  const isInit = useInitSearchParams();

  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (isInit) return;

    setSearchParams({
      regionId: regionData?.id.toString() ?? '',
      isPanel: isPanel.toString(),
      panelType: panelType ?? '',
      deviceId: infoData?.id.toString() ?? '',
    });
  }, [setSearchParams, regionData, panelType, isPanel, infoData?.id, isInit]);
};

export default useAppSearchParams;
