import { notification } from 'antd';
import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';

import { CONFIG } from '../constants/configConstants';
import { getCookiesFromObject } from '../helpers/getCookiesFromObject';
import onRejectedError from '../helpers/onRejectedError';
import rootStore from '../stores/rootStore/rootStore';

import { refreshTokensRequest } from './helpers/refreshTokensRequest';

const HEADERS: AxiosRequestHeaders = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};

const requestSettings: AxiosRequestConfig = {
  baseURL: CONFIG.EPUTS_URL,
  headers: HEADERS,
};

const http = axios.create(requestSettings);

http.interceptors.response.use((config) => {
  config.status !== 502 &&
    config.status !== 0 &&
    notification.destroy('error-notification');

  return config;
}, onRejectedError);

http.interceptors.request.use((config: any) => {
  const { userDataStore, uiStore } = rootStore;
  const accessToken: any = userDataStore?.accessToken;

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;

    const usedTimeZone = uiStore.regionData?.timeZone;

    if (usedTimeZone) config.headers['Query-Timezone'] = usedTimeZone;

    config.headers.Cookies = getCookiesFromObject({
      regionId: uiStore.regionData?.id ?? 0,
      timeZone: -new Date().getTimezoneOffset() / 60,
    });
  }

  return config;
});

http.interceptors.response.use(
  (config) => config,
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response.status === 401 &&
      originalRequest &&
      !error.config._isRetry
    ) {
      originalRequest._isRetry = true;

      try {
        await refreshTokensRequest(originalRequest.url);

        return http.request(originalRequest);
      } catch (e: any) {
        throw error;
      }
    }

    throw error;
  }
);

export default http;
