import classNames from 'classnames';
import { isString } from 'lodash';
import { Sector } from 'recharts';
import type { Props } from 'recharts/types/shape/Sector';

import { COLORS } from '../../../../constants/colorsConstants';
import { IPieChartData } from '../models/circleChart.model';
import { HandleType } from '../Utils/CustomLegend/CustomLegend';

import { getActiveInfo } from './helpers/getActiveInfo';
import PercentCircle from './PercentCircle/PercentCircle';
import Rect from './Rect/Rect';
import Text from './Text/Text';

import styles from './ActiveShape.module.scss';

const DEFAULT_OPACITY = 0.5;
const OFFSET_SHADOW = 7;

interface ActiveShapeProps extends Props {
  isActive?: boolean;
  total: number;
  hoverType: string;
  isHalfPie: boolean;
  isPercents: boolean;
  isOneRecord: boolean;
  setHoverLine: SetState<string>;
  value?: number;
  percent?: number;
  payload?: {
    name: string;
  };
  activeSectors: U<string[]>;
  activeIndex: number | number[];
  data: IPieChartData[];
  handleClickSector: U<(sectorName: string, len: U<number>) => void>;
  isAnimation: boolean;
}

const ActiveShape = (props: ActiveShapeProps) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
    isPercents,
    isHalfPie,
    total,
    data,
    isActive = true,
    hoverType = '',
    activeSectors,
    activeIndex,
    isOneRecord,
    isAnimation,
    setHoverLine,
    handleClickSector,
  } = props;

  const x = cx ?? 0;
  const y = cy ?? 0;

  const yPercents = isHalfPie ? y - 10 : y;

  const { isActiveSection, isActiveText } = getActiveInfo(
    hoverType,
    payload?.name,
    activeIndex,
    data,
    activeSectors,
    isActive
  );

  const inRadius = (innerRadius ?? 0) + (isHalfPie ? 5 : 15);
  const outRadius = (outerRadius ?? 0) + (isActiveSection ? 20 : 15);

  const externalRadius = isHalfPie ? outerRadius ?? 0 : outRadius;

  const basicProps = {
    cx,
    cy,
    innerRadius: inRadius,
    outerRadius: externalRadius,
    startAngle,
    endAngle,
    fill,
    opacity: DEFAULT_OPACITY,
    className: classNames(styles.sector, {
      [styles.default]: isOneRecord,
    }),
  };

  const handleHover = (eventType: HandleType) => {
    const name = payload?.name;

    isString(name) && setHoverLine(eventType === HandleType.Enter ? name : '');
  };

  return (
    <g>
      <g
        onClick={() =>
          payload?.name &&
          !isOneRecord &&
          handleClickSector?.(payload.name, value)
        }
        onMouseEnter={() => handleHover(HandleType.Enter)}
        onMouseLeave={() => handleHover(HandleType.Leave)}
      >
        <Sector {...basicProps} opacity={undefined} />
        <Sector
          {...basicProps}
          innerRadius={inRadius - OFFSET_SHADOW}
          outerRadius={inRadius}
        />
        <Sector
          {...basicProps}
          innerRadius={inRadius - 10}
          outerRadius={inRadius - OFFSET_SHADOW}
          fill={COLORS.BLACK900}
        />
        <Sector
          {...basicProps}
          innerRadius={externalRadius}
          outerRadius={externalRadius + 1}
          fill={COLORS.BLACK900}
        />
      </g>
      {isActiveText ? (
        <>
          {isHalfPie ? (
            <g x={x * 1.53} y={20}>
              <Rect x={x * 1.43} fill={fill ?? COLORS.BLACK900} />
              <Text x={x * 1.7} y={20} title={`${value}/${total}`} />
              <Text x={x} y={y + 25} title={payload?.name ?? ''} />
            </g>
          ) : null}
          {isPercents && (
            <PercentCircle
              cx={x}
              cy={yPercents}
              percent={Number(((percent ?? 0) * 100).toFixed(2))}
              isSmall={isHalfPie}
              value={value ?? 0}
              isAnimation={isAnimation}
            />
          )}
        </>
      ) : null}
    </g>
  );
};

export default ActiveShape;
