import { isUndefined } from 'lodash';
import { useEffect, useState } from 'react';

import { findBy } from '../../../../../../../../../../../../../helpers/findBy';
import { IMG_PATH } from '../../../../../../../../../../../../../ts/enums/tl';
import usePhaseInfo from '../../../../../../../../../../../../DetailedStatistics/Monitoring/SystemMonitoring/DevicesBlock/DevicesList/DeviceItem/DeviceAvatar/PhaseInfo/hooks/usePhaseInfo';

const usePhaseImage = (id: number) => {
  const [imgUrl, setImgUrl] = useState<N<string>>(null);

  const { curPhase, activeTL } = usePhaseInfo(id);

  useEffect(() => {
    const images = activeTL?.dai_jdt83?.images ?? [];
    const phase = curPhase?.phase;

    const imgPhase =
      !isUndefined(phase) && findBy(images, phase, 'phase')?.image;

    if (!imgPhase) return setImgUrl(null);

    const url = IMG_PATH + imgPhase;

    setImgUrl(url);
  }, [activeTL?.dai_jdt83?.images, curPhase?.phase]);

  return imgUrl;
};

export default usePhaseImage;
