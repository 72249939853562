import type { Color as DeckColor } from '@deck.gl/core/dist/types/layer-props';

export const SCENE_WIDTH_COEFFICIENT = 3;

export const SCENE_WKID = 102100;

export const SCENE_MAX_WIDTH = 2560;
export const SCENE_MAX_HEIGHT = 1600;

export const DEFAULT_SCENE_COLOR: DeckColor = [255, 0, 0];

export const COEFFICIENT_ALPHA = 255;
export const MAX_ALPHA = 250;
export const BASE_COLOR_VALUE = 100;

export const PI_DEG = 90;
export const PI_2_DEG = 180;

export const SCENE_DEFAULT_TILE_SIZE = 255;
