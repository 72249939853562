import type { Feature } from 'ol';
import type { Point } from 'ol/geom';
import { useEffect, useState } from 'react';

import { Z_INDEX_CLUSTER_LAYER } from '../../../../../../../apiGIS/constants/map';
import { CLUSTERS_INFO } from '../../../../../../../constants/mapClusterConstants';
import { TMap } from '../../../../../../../stores/mapStore/mapStore.model';
import rootStore from '../../../../../../../stores/rootStore/rootStore';
import { IClusterSource } from '../../../../../../ui-kit/MapLayers/ClusterLayers/ChartClusterLayer';
import { addFeaturesCluster } from '../../../../../../ui-kit/MapLayers/ClusterLayers/helpers/addFeaturesCluster';
import {
  generateAnimatedClusters,
  generateNewClusterSource,
} from '../../../../../../ui-kit/MapLayers/ClusterLayers/helpers/helpers';

const { LOCATION_DISTANCE } = CLUSTERS_INFO;

interface UseVectorLayerProps {
  map: TMap;
  features: Feature<Point>[];
}

const useVectorLayer = ({ map, features }: UseVectorLayerProps) => {
  const { isClusters } = rootStore.uiStore;

  const [clusterSource, setClusterSource] = useState<IClusterSource>({
    clusterAnimated: null,
    cluster: null,
  });

  useEffect(() => {
    if (!map) return;

    // initialization
    const cluster = generateNewClusterSource();
    const clusterAnimated = generateAnimatedClusters(cluster);

    map.addLayer(clusterAnimated);
    clusterAnimated.setZIndex(Z_INDEX_CLUSTER_LAYER);
    setClusterSource({ clusterAnimated, cluster });

    return () => {
      if (map) {
        map.removeLayer(clusterAnimated);
      }
    };
  }, [map]);

  // add features to layer
  useEffect(() => {
    if (!map || !clusterSource.cluster) return;

    const source = clusterSource.cluster.getSource();

    source?.refresh();

    if (!features.length || !source) return source?.clear();

    addFeaturesCluster(source, features);
  }, [clusterSource, features, map]);

  // setting to on/off clusters
  useEffect(() => {
    if (!clusterSource.cluster) return;

    clusterSource.cluster.setDistance(isClusters ? LOCATION_DISTANCE : 0);
  }, [isClusters, clusterSource]);

  return clusterSource;
};

export default useVectorLayer;
