import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../stores/rootStore/rootStore';
import { TSchemaODD } from '../../../../ts/models/pointUds.model';
import { TactsImagesEl, TLTact } from '../../../../ts/models/tl.model';
import PhaseCircleCrossroad from '../PhaseCircleCrossroad/PhaseCircleCrossroad';
import PhaseCircleImages from '../PhaseCircleImages/PhaseCircleImages';

interface Items {
  phaseIdx: number;
  tlTactImages: TactsImagesEl[][];
  tlTacts: TLTact[];
  schemaODD: Nullish<TSchemaODD>;
}

const PhaseCircleItems: FC<Items> = ({
  tlTactImages,
  tlTacts,
  phaseIdx,
  schemaODD,
}) => {
  const tact = tlTacts[phaseIdx];

  if (!tact) return null;

  const { isCrossroadBorder } = rootStore.mapDataStore;

  const { animation, main } = tact;
  const isBlinkingAnimation = Boolean(animation?.opacity) || !main;

  if (isCrossroadBorder && schemaODD?.id) {
    const crossroadProps = {
      id: schemaODD.id,
      phaseIdx,
      tlCrossroad: schemaODD,
      tlTacts,
    };

    return <PhaseCircleCrossroad {...crossroadProps} />;
  }

  return (
    <PhaseCircleImages
      data={tlTactImages[phaseIdx]}
      isBlinkingAnimation={isBlinkingAnimation}
      tact={tact}
    />
  );
};

export default observer(PhaseCircleItems);
