import { FC } from 'react';

import { IServerData } from '../../NJSInfo.model';
import InfoBox from '../InfoBox/InfoBox';

import styles from './NotifyChannelsInfo.module.scss';

interface NotifyChannelsInfoProps {
  data: IServerData;
}

const NotifyChannelsInfo: FC<NotifyChannelsInfoProps> = ({ data }) => {
  return (
    <InfoBox title="Каналы notify" className={styles.infoBox}>
      <ul>
        {data.notifyChannels.map((el, i) => (
          <li key={i} className={styles.listItem}>
            <div className={styles.point}>
              <h4>Канал: </h4>
              <p>{el.channel}</p>
            </div>
            <div className={styles.point}>
              <h4>База данных: </h4>
              <p>{el.database}</p>
            </div>
          </li>
        ))}
      </ul>
    </InfoBox>
  );
};

export default NotifyChannelsInfo;
