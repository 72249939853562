import { useState } from 'react';

import { SECOND } from '../../../../../constants/constants';
import { COLLAPSE_DATA, CollapseProps } from '../constants/constants';
import FilterCard from '../FilterCard/FilterCard';
import ModelData from '../ModelData/ModelData';

import styles from './ControlModel.module.scss';

const ControlModel = () => {
  const [modelData, setModelData] = useState<CollapseProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleModelData = () => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
      setModelData(COLLAPSE_DATA);
    }, SECOND);
  };

  return (
    <div className={styles.container}>
      <FilterCard handleModelData={handleModelData} isLoading={isLoading} />
      <ModelData data={modelData} isLoading={isLoading} />
    </div>
  );
};

export default ControlModel;
