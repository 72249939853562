import rootStore from '../../../../../stores/rootStore/rootStore';
import { ChartBDKeys } from '../../../../InfoPanel/SystemsInfo/PopupDetector/Chart/constants/constants';
import getChartParamName from '../../../../InfoPanel/SystemsInfo/PopupDetector/Chart/helpers/getChartParamName';
import { GRAPH_TYPE_TITLE, GRAPHS_TITLE } from '../../constants/constants';

const getTitleGraph = (graphTitle: ChartBDKeys) => {
  const { graphType } = rootStore.detailedStore;

  const graphParam = getChartParamName(graphTitle);

  if (!graphParam) return GRAPH_TYPE_TITLE[graphType];

  return `${GRAPH_TYPE_TITLE[graphType]}. ${GRAPHS_TITLE[graphParam].value} потока`;
};

export default getTitleGraph;
