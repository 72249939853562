import { MenuProps } from 'antd/lib';
import { toLonLat } from 'ol/proj';

import rootStore from '../../../../../../stores/rootStore/rootStore';
import { MapActions } from '../../../../../../stores/uiStore/uiStore.model';
import { MAIN_KEY } from '../../../../../DetailedMain/DetailedWrapper/constants/tlItems';
import { MANAGEMENT_KEY } from '../../../../../InfoPanel/SystemsInfo/InfoBtns/InfoBtns';
import { DropdownKeys } from '../enums/enums';
import getDropdownItems from '../helpers/getDropdownItems';

const useDetailedBtn = () => {
  const { setInfoData } = rootStore.uiStore;
  const { openDetailed } = rootStore.detailedStore;
  const { getCoordinatesById } = rootStore.mapDataStore;
  const { deviceInfo, clearMonitoringStore } = rootStore.monitoringPanelStore;

  const items = getDropdownItems();

  const handleClick: MenuProps['onClick'] = ({ key }) => {
    if (!deviceInfo) return;

    const { id, system } = deviceInfo;

    clearMonitoringStore(true);

    const isDetailed = key === DropdownKeys.Detailed;

    if (isDetailed || key === DropdownKeys.Control) {
      openDetailed({
        key: isDetailed ? MAIN_KEY : MANAGEMENT_KEY,
        isNeedInfoData: true,
        id,
        system,
      });
    }

    if (key === DropdownKeys.MapPin) {
      const coordinate = getCoordinatesById(id);

      coordinate &&
        setInfoData(
          {
            id,
            system,
            coordinate: toLonLat(coordinate),
            coordFeatureOnCluster: null,
          },
          MapActions.CenterZoom
        );
    }
  };

  return { items, handleClick };
};

export default useDetailedBtn;
