/* eslint-disable camelcase */
import { z } from 'zod';

import { NNInt } from '../../zod/utils.zod';
import { BasicMapDataSchema } from '../mapObjects.zod';

export enum StreamType {
  Iframe = 'iframe',
  Mjpeg = 'mjpeg',
  Jpeg = 'jpeg',
  Rtsp = 'rtsp',
}

export const FinalFromProfilesSchema = z.object({
  id: NNInt.optional(),
  angle: NNInt,
  offsetX: z.number(),
  offsetY: z.number(),
  size: NNInt,
  color: z.string(),
  distance: NNInt,
  widthRate: z.number(),
  arcHeight: NNInt,
  preDistanceRate: z.number(),
  opacity: z.number(),
  linkedDetectorId: NNInt.nullable(),
  linkColor: z.string().nullable().optional(),
});

export const VideoSourceSchema = z.object({
  url: z.string(),
  name: z.string(),
  deviceId: NNInt.nullable(),
  streamType: z.nativeEnum(StreamType),
  playerType: z.string().optional(),
  defaultView: z.boolean(),
});

const CameraInfoSchema = z.object({
  videoSources: VideoSourceSchema.array().optional(),
});

export const CmDaiJdt83Schema = FinalFromProfilesSchema.extend({});

export const CameraSchema = BasicMapDataSchema.extend({
  regionId: z.number(),
  srcId: z.string(),
  finalFromProfiles: FinalFromProfilesSchema.nullable().optional(),
  latitude: z.number(),
  longitude: z.number(),
  dai_jdt80: CameraInfoSchema,
  dai_jdt83: CmDaiJdt83Schema.nullable(),
});
