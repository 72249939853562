import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import rootStore from '../../../stores/rootStore/rootStore';
import { FeaturesTypes } from '../../../ts/enums/enums';
import useDynamicOverlays from '../../Map/hooks/useDynamicOverlays';

import styles from './AlarmPopup.module.scss';

const ALARMS_RANGES = {
  MEDIUM: 25,
  LARGE: 35,
} as const;

const { MEDIUM, LARGE } = ALARMS_RANGES;

const AlarmPopup = () => {
  const { isAlarmAlert } = rootStore.uiStore;
  const { errorsTl, center } = rootStore.mapDataStore;
  const { map } = rootStore.mapStore;

  const {
    elementRefs,
    isRemovedOverlays,
    featuresInExtent,
    isReadyElementRefs,
  } = useDynamicOverlays({
    map,
    isShowOverlay: isAlarmAlert,
    featuresWithOverlays: errorsTl || [],
    center,
    isOverlaysForAllFeatures: false,
    isOverlaysForClusters: true,
  });

  if (
    !(
      isReadyElementRefs &&
      featuresInExtent.length &&
      isRemovedOverlays &&
      errorsTl
    )
  )
    return null;

  return (
    <div>
      {featuresInExtent.map((el, i) => {
        const { colorAlarm } = el;

        const featuresLen = el.feature.get(FeaturesTypes.Features)?.length;

        const isLarge = featuresLen >= LARGE;
        const isMedium = featuresLen >= MEDIUM;

        return (
          <div
            key={`alarms${i}`}
            className={classNames({
              [styles.alarmPopup]: true,
              [styles.mediumAlarm]: isMedium,
              [styles.largeAlarm]: isLarge,
            })}
            style={{
              borderColor: colorAlarm,
            }}
            ref={elementRefs[i]}
          ></div>
        );
      })}
    </div>
  );
};

export default observer(AlarmPopup);
