import { uniqueId } from 'lodash';
import { FC } from 'react';

import { ITimePoints } from '../../../../ts/models/signalPrograms.model';
import { getCurPhaseInfo } from '../../../SignalProgramsBand/helpers/getCurPhaseInfo';
import { secondsToTime } from '../../../TrafficLightDetailed/TlManagement/PhaseHold/helpers/secondsToTime';
import getTitlePhase from '../helpers/getTitlePhase';

import styles from './PhaseNowInfoPopover.module.scss';

interface PhaseNowInfoPopoverProps {
  phasesSet: ITimePoints[];
  sec: number;
}

const PhaseNowInfoPopover: FC<PhaseNowInfoPopoverProps> = ({
  phasesSet,
  sec,
}) => {
  const { curPhase, isPromTact, secToEnd, lenPhase, mainTactLen, promTactLen } =
    getCurPhaseInfo(phasesSet, sec);

  const titlePhase = getTitlePhase(curPhase, isPromTact);

  const titleText = [
    {
      title: 'До конца фазы',
      value: secToEnd,
    },
    {
      title: 'Длительность фазы',
      value: lenPhase,
    },
    {
      title: 'Длительность основного такта',
      value: mainTactLen,
    },
    {
      title: 'Длительность пром такта',
      value: promTactLen,
    },
  ];

  return (
    <div className={styles.container}>
      <h3>{titlePhase}</h3>
      {titleText.map(({ value, title }) => (
        <p key={uniqueId('title_')}>
          {`${title}: ${secondsToTime(value, {
            H: false,
            M: true,
            S: true,
          })} (${value} с.)`}
        </p>
      ))}
    </div>
  );
};

export default PhaseNowInfoPopover;
