import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { uniqueId } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import Slider from 'react-slick';

import { MODES_INFO } from '../../../../../../../../constants/commands';
import { getSystemTitle } from '../../../../../../../../helpers/getSystemTitle';
import rootStore from '../../../../../../../../stores/rootStore/rootStore';
import { System } from '../../../../../../../../ts/enums/enums';
import { getTypeTactObj } from '../../../../../../../TrafficLightDetailed/TlMainInfo/PhaseCardsList/helpers/getTypeTactObj';
import PhaseCard, {
  IPhaseCard,
} from '../../../../../../../TrafficLightDetailed/TlMainInfo/PhaseCardsList/PhaseCard/PhaseCard';
import { ConfirmProps } from '../../../../../../../ui-kit/Confirm/Confirm';
import NoData from '../../../../../../../ui-kit/Errors/NoData/NoData';

import { SLIDER_SETTINGS } from './constants/constants';
import usePhase from './hooks/usePhase';

import styles from './PhasesSlider.module.scss';

const { RemoteControl } = MODES_INFO;

interface PhasesSliderProps {
  tlId: number;
  sec: number;
}

const PhasesSlider: FC<PhasesSliderProps> = ({ tlId, sec }) => {
  const { tls } = rootStore.mapDataStore;

  const {
    activeTL,
    isError,
    phasesArray,
    handlePostCommand,
    isDisabled,
    isLoadingRequest,
    isOpenConfirm,
    setSelectedPhase,
  } = usePhase({
    tlId,
    tls,
  });

  if (!activeTL)
    return <NoData textError="Нет данных о фазах СО" isNeedBackground />;

  const { tlStatus, dai_jdt83 } = activeTL;

  const tlName = getSystemTitle(System.Lights, activeTL);

  const imgProps = {
    phasesSet: phasesArray,
    tlStatus,
    id: tlId,
    isError,
    images: dai_jdt83?.images ?? [],
  };

  return (
    <div className={styles.container}>
      <Slider {...SLIDER_SETTINGS} className={styles.slider}>
        {phasesArray.map((phase) => {
          const title = `Вы уверены, что хотите удержать фазу № ${phase.phase} для ${tlName}}?`;

          const confirmProps: ConfirmProps = {
            title,
            okButtonProps: { danger: true, loading: isLoadingRequest },
            disabled: isDisabled,
            overlayClassName: styles.confirm,
            isOpen: isOpenConfirm,
            onConfirm: handlePostCommand,
          };

          const phasesProps: IPhaseCard = {
            ...imgProps,
            phase: phase,
            className: styles.phaseCard,
            tactTypeObj: getTypeTactObj(phase.tactType),
            secTL: sec,
            onSelectPhase: setSelectedPhase,
            isSliderCard: true,
            isDisabled,
            confirmProps,
            tipPopover: isDisabled
              ? `Для удержания фазы необходимо включить режим: ${RemoteControl.dsc}`
              : undefined,
          };

          return (
            <div key={uniqueId('#')} className={styles.phaseCardWrapper}>
              <PhaseCard {...phasesProps} />
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default observer(PhasesSlider);
