import { isNumber } from 'lodash';
import { FC } from 'react';

import { COLORS } from '../../../../constants/colorsConstants';
import getIcoColor from '../../helpers/getIcoColor';
import { DeviceIdProps } from '../../models/device.model';

import styles from './DeviceId.module.scss';

const { WHITE, BG_DARK } = COLORS;

const DeviceId: FC<DeviceIdProps> = ({
  size,
  id,
  color,
  isIcoWithId = false,
}) => {
  if (!isNumber(id)) return null;

  const icoColor = getIcoColor(color);
  const bgColor = isIcoWithId ? BG_DARK : icoColor;

  const ratioSize = size / 3;

  const idStyle = {
    top: isIcoWithId ? -ratioSize : '50%',
    fontSize: ratioSize,
    border: id.toString().length > 3 ? `2px solid ${WHITE}` : '',
    backgroundColor: bgColor,
  };

  return (
    <div style={idStyle} className={styles.id}>
      {id}
    </div>
  );
};

export default DeviceId;
