import { observer } from 'mobx-react-lite';

import useArrayWithDelay from '../../../../hooks/useArrayWithDelay';
import rootStore from '../../../../stores/rootStore/rootStore';

import DetectorImg from './DetectorImg/DetectorImg';

const DetectorsDetailed = () => {
  const { sortedTLanes, view, profileId } = rootStore.constructorStore;

  const lanesWithDelay = useArrayWithDelay({
    arr: sortedTLanes,
    trigger: profileId,
  });

  if (!view.isTrafficLanes || !sortedTLanes.length) {
    return <h3>Не выбраны детекторы для отображения</h3>;
  }

  return (
    <>
      {lanesWithDelay.map((lane) => (
        <DetectorImg key={lane.id} lane={lane} />
      ))}
    </>
  );
};

export default observer(DetectorsDetailed);
