import { observer } from 'mobx-react-lite';
import { FC, useEffect, useRef, useState } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import { getTimeWithTimeZone } from '../../TrafficLightDetailed/TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeWithTimeZone';
import { getTimeZones } from '../../TrafficLightDetailed/TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeZone';

import styles from './Watermark.module.scss';

interface IWatermark {
  content?: string;
  timeZone?: N<number>;
}
const DEFAULT_POSITION = {
  top: '0',
  left: '0',
};
const MIN = 10;
const MAX = 87;
const INTERVAL = 5000;
const DATE_FORMAT_LOCALE = 'D MMM, HH:mm';

function getRandomPercent() {
  let percent = Math.floor(Math.random() * 100);

  while (percent > MAX || percent < MIN) {
    percent = getRandomPercent();
  }

  return percent;
}

const Watermark: FC<IWatermark> = ({
  content,
  timeZone = getTimeZones().timeZoneRegion,
}) => {
  const { user } = rootStore.userDataStore;

  const dateInitial =
    timeZone !== null
      ? getTimeWithTimeZone({
          dateFormat: DATE_FORMAT_LOCALE,
          timeZone,
        }).dateFormatted
      : null;

  const spanRef = useRef<HTMLSpanElement>(null);
  const [{ top, left }, setPosition] = useState(DEFAULT_POSITION);
  const [date, setDate] = useState(dateInitial);

  useEffect(() => {
    if (!timeZone) return;

    const interval = setInterval(() => {
      const newPosition = {
        top: getRandomPercent() + '%',
        left: getRandomPercent() + '%',
      };

      setPosition((_prev) => newPosition);
      setDate(
        getTimeWithTimeZone({ dateFormat: DATE_FORMAT_LOCALE, timeZone })
          .dateFormatted
      );
    }, INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, [timeZone]);

  if (!date) return null;

  return (
    <span
      className={styles.watermark}
      style={{ top, left, transform: `translate(-${top}, -${left})` }}
      ref={spanRef}
    >
      {content ? content : `id: ${user?.id}, ${date}`}
    </span>
  );
};

export default observer(Watermark);
