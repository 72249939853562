import { Button, Checkbox, Col } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { observer } from 'mobx-react-lite';
import { Fragment, useState, useMemo } from 'react';

import pointUdsApi from '../../../../../api/pointUds/pointUdsApi';
import profilesApi from '../../../../../api/pointUds/profiles/profilesApi';
import rootStore from '../../../../../stores/rootStore/rootStore';
import Confirm from '../../../../ui-kit/Confirm/Confirm';
import { POINT_UDS_PROFILES_PATH } from '../../../constants/pathConstants';
import getDataFromProfilesList from '../helpers/getDataFromProfilesList';

import styles from './PointUdsProfiles.module.scss';

const PointUdsProfiles = () => {
  const {
    fetchPointUdsList,
    clearStore,
    pointUdsUid,
    profilesList,
    fetchProfilesList,
    profileId,
    setConstructorData,
  } = rootStore.constructorStore;

  const [checkedList, setCheckedList] = useState<number[]>([]);

  const { fullList, isFinalExist, isNotFinalExist } = useMemo(
    () => getDataFromProfilesList(profilesList),
    [profilesList]
  );

  if (!profilesList?.length || !fullList) {
    return <h3>Для выбранной точки УДС профили отсутствуют</h3>;
  }

  const handleCheckbox = (list: CheckboxValueType[]) =>
    setCheckedList(list as number[]);

  const handleCheckAll = (e: CheckboxChangeEvent) =>
    setCheckedList(e.target.checked ? fullList : []);

  const deleteProfiles = async () => {
    const res = await profilesApi.deleteByIds(
      checkedList,
      POINT_UDS_PROFILES_PATH
    );

    fetchProfilesList(POINT_UDS_PROFILES_PATH);

    if (res && profileId && checkedList.includes(profileId)) {
      setConstructorData('profileId', null);
    }
  };

  const deleteByUid = async (deleteAllVersions?: boolean) => {
    if (!pointUdsUid) return;

    const res = await pointUdsApi.deleteByUid(
      pointUdsUid,
      POINT_UDS_PROFILES_PATH,
      deleteAllVersions
    );

    if (!res) return;

    clearStore(false);
    fetchPointUdsList(POINT_UDS_PROFILES_PATH);
  };

  const checkAll = profilesList.length === checkedList.length;
  const indeterminate =
    checkedList.length > 0 && checkedList.length < profilesList.length;

  const btns = [
    {
      label: 'Удалить выбранные профили',
      onConfirm: deleteProfiles,
      disabled: !checkedList.length || checkedList.length === fullList.length,
    },
    {
      label: 'Удалить точку УДС',
      onConfirm: () => deleteByUid(true),
    },
    {
      label: 'Удалить все профили, кроме финального',
      onConfirm: () => deleteByUid(),
      disabled: !isFinalExist || !isNotFinalExist,
    },
  ];

  return (
    <>
      <h3>Список профилей для выбранной точки</h3>
      <hr />

      <Checkbox.Group onChange={handleCheckbox} value={checkedList}>
        <Col>
          {profilesList?.map(({ profileId, profileName }) => (
            <Fragment key={profileId + profileName}>
              <Checkbox value={profileId}>{profileName}</Checkbox>
              <br />
            </Fragment>
          ))}
        </Col>
      </Checkbox.Group>

      <Col className={styles.allCheckboxes}>
        <Checkbox
          indeterminate={indeterminate}
          onChange={handleCheckAll}
          checked={checkAll}
        >
          Выбрать все
        </Checkbox>
      </Col>
      <hr />

      {btns.map(({ label, disabled, onConfirm }) => (
        <Confirm key={label} onConfirm={onConfirm}>
          <Button className={styles.btn} type="primary" disabled={disabled}>
            {label}
          </Button>
        </Confirm>
      ))}
    </>
  );
};

export default observer(PointUdsProfiles);
