import { FC } from 'react';
import { IoIosDocument } from 'react-icons/io';

import { findById } from '../../../../../helpers/findBy';
import { NORMATIVE_VALUES } from '../../constants/constants';

import styles from './NormativeIco.module.scss';

interface NormativeIcoProps {
  id: number;
}

const NormativeIco: FC<NormativeIcoProps> = ({ id }) => {
  const ico = findById(NORMATIVE_VALUES, id)?.icon ?? <IoIosDocument />;

  return <span className={styles.ico}>{ico}</span>;
};

export default NormativeIco;
