import { computed, makeAutoObservable } from 'mobx';

import { TDeviceStatus } from '../../constants/devicesConstants';
import { PanelType } from '../../ts/enums/enums';
import {
  MapPropsDetailed,
  SetMapPropsDetailed,
} from '../detailedStore/detailedStore.model';
import { getWidgetsData } from '../helpers/combineWgData';
import getWidgetAdapters from '../helpers/getWidgetAdapters';
import RootStore from '../rootStore/rootStore';
import { StoreUtils } from '../storeUtils/storeUtils';

import {
  Address,
  DeviceInfo,
  StatusFilteredMonitoring,
  TActiveSystemMonitoring,
} from './monitoringPanelStore.model';

class MonitoringPanelStore {
  rootStore;
  activeSystemMonitoring: TActiveSystemMonitoring = null;
  statusFilteredMonitoring: StatusFilteredMonitoring[] = [];
  searchMonitoring: N<string> = null;
  mapPropsDetailed: N<MapPropsDetailed> = null;
  isMapLoading = true;
  deviceInfo: N<DeviceInfo> = null;
  addresses: N<Address> = null;
  setMonitoringValue;

  constructor(rootStore: typeof RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
      widgetsFromMap: computed.struct,
      adapterIds: computed.struct,
    });
    this.rootStore = rootStore;

    const utils = new StoreUtils(this);

    this.setMonitoringValue = utils.setKeyValue;
  }

  setActiveSystemMonitoring = (
    value: N<TActiveSystemMonitoring>,
    isClearDeviceInfo = true
  ) => {
    const { setKeyValue } = this.rootStore.uiStore;

    setKeyValue('externalResource', null);

    this.activeSystemMonitoring =
      this.activeSystemMonitoring !== value ? value : null;

    setTimeout(() => {
      isClearDeviceInfo && this.setMonitoringValue('deviceInfo', null);
    }, 0);
  };

  setStatusFilteredMonitoring = (status: N<TDeviceStatus>, len = 0) => {
    if (!status) return (this.statusFilteredMonitoring = []);

    const isDelete = this.statusFilteredMonitoring.some(
      ({ status: elStatus }) => elStatus === status
    );

    if (isDelete) {
      this.statusFilteredMonitoring = this.statusFilteredMonitoring.filter(
        ({ status: elStatus }) => elStatus !== status
      );

      return;
    }

    this.statusFilteredMonitoring.push({ status, len });
  };

  setMapPropsDetailed: SetMapPropsDetailed = (
    currZoom: number,
    resolution: number
  ) => {
    this.mapPropsDetailed = { currZoom, resolution };
  };

  clearMonitoringStore = (isClearSystem = true) => {
    isClearSystem && this.setActiveSystemMonitoring(null);
    this.setStatusFilteredMonitoring(null);
    this.setMonitoringValue('searchMonitoring', null);
  };

  get isMonitoringPanel() {
    const { panelType } = this.rootStore.uiStore;

    return panelType === PanelType.ModulesSubsystems;
  }

  get isModulesSubsystemsDetailed() {
    return !!this.activeSystemMonitoring && this.isMonitoringPanel;
  }

  get widgetsFromMap() {
    const { tls, detectors, cameras, meteo } = this.rootStore.mapDataStore;

    return getWidgetsData({
      tls,
      detectors,
      cameras,
      meteo,
    });
  }

  get adapterIds() {
    const { systemsInfo } = this.rootStore.mapDataStore;
    const { activeSystemMonitoring } = this;

    return getWidgetAdapters(systemsInfo, activeSystemMonitoring);
  }
}

export default MonitoringPanelStore;
