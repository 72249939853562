import { Empty } from 'antd';
import classNames from 'classnames';
import { FC } from 'react';

import styles from './NoData.module.scss';

export interface INoDataProps {
  textError?: string;
  isNeedBackground?: boolean;
  classNameEmpty?: string;
}

const NoData: FC<INoDataProps> = ({
  textError = 'Нет данных',
  isNeedBackground = false,
  classNameEmpty = '',
}) => {
  const cardStyle = classNames({
    [styles.container]: true,
    [styles.background]: isNeedBackground,
  });

  return (
    <div className={cardStyle} data-testid="noData">
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={<p>{textError}</p>}
        className={classNameEmpty}
      />
    </div>
  );
};

export default NoData;
