import { useRef } from 'react';

import { Scene } from '../../ts/models/MapObjects/detectors.model';
import Canvas from '../DetectorGrid/Canvas/Canvas';
import { getTimeZones } from '../TrafficLightDetailed/TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeZone';
import DefaultChecked from '../ui-kit/DefaultChecked/DefaultChecked';
import Watermark from '../ui-kit/Watermark/Watermark';

import ActionBlock, { ActionProps } from './ActionBlock/ActionBlock';
import CameraTitle from './CameraTitle/CameraTitle';
import CameraWrapper from './CameraWrapper/CameraWrapper';
import useCameraHandlers from './hooks/useCameraHandlers/useCameraHandlers';
import useVideoCamera from './hooks/useVideoCamera/useVideoCamera';
import {
  DefaultCheckedProps,
  IVideoCamera,
  VideoActions,
} from './models/VideoCamera.model';

import styles from './VideoCamera.module.scss';

const ERROR_TEXT = 'Видеопоток отсутствует';

export type VideoCameraProps<T extends U<true> = undefined> =
  Partial<IVideoCamera> & {
    isDetectorLanes?: T;
    isShowAllZones?: boolean;
    videoActions?: Partial<VideoActions>;
    isWatermark?: boolean;
    isSingleCamera?: boolean;
  } & (T extends true ? { scene: U<Scene> } : { scene?: U<Scene> });

const VideoCamera = <T extends U<true> = undefined>({
  cameraData,
  timeZone = getTimeZones().timeZoneRegion,
  width,
  scene,
  videoActions = {},
  isDetectorLanes,
  isShowAllZones,
  isWatermark = true,
  isSingleCamera = true,
}: VideoCameraProps<T>) => {
  const cameraWrapperRef = useRef<HTMLDivElement>(null);

  const {
    cameraRef,
    onError,
    onLoad,
    handleUpdate,
    activeStream,
    setStream,
    loadProperties: { textError, isLoad },
  } = useVideoCamera({ cameraData, isSingleCamera });

  const {
    status,
    isDtLanes,
    isMouseEnter,
    isDisabledPin,
    handleEnter,
    handleInfo,
    handleLeave,
    handleToggleFullScreen,
    onDtLanesClick,
    onPinClick,
  } = useCameraHandlers({
    cameraData,
    cameraWrapperRef,
    activeStream,
    timeZone,
  });

  const isError = !!textError;
  const isEmptyData = !activeStream || isError;

  const isSceneValid = Boolean(
    scene && isDetectorLanes && !isLoad && !isEmptyData
  );

  const isShowActions = isMouseEnter || isError;

  const {
    isDtLanesBtn = true,
    isFullScreen = true,
    isInfoBtn = true,
    isPinBtn = true,
    isThreadsBtn = true,
    isUpdateBtn = true,
    isMenuBtn = false,
  } = videoActions;

  const actionProps: ActionProps = {
    onUpdateClick: isUpdateBtn ? handleUpdate : null,
    onPinClick: isPinBtn ? onPinClick : null,
    onInfoClick: isInfoBtn ? handleInfo : null,
    onFullScreen: isFullScreen ? handleToggleFullScreen : null,
    isDisabledPin,
    isMenuBtn,
    activeStream,
    setStream: isThreadsBtn ? setStream : null,
    videoSources: cameraData?.videoSources,
    dtLanesProps:
      isDetectorLanes && isDtLanesBtn
        ? { isDtLanes, isDtLanesValid: isSceneValid, onDtLanesClick }
        : undefined,
    statusFullScreen: status,
    cameraWrapperRef: cameraWrapperRef,
  };

  const defaultCheckedProps: DefaultCheckedProps = {
    isLoading: isLoad,
    isEmptyData: isEmptyData,
    noDataProps: {
      isNeedBackground: false,
      textError: textError ?? ERROR_TEXT,
      classNameEmpty: styles.empty,
    },
    isBackdropFilter: true,
  };

  return (
    <div
      ref={cameraWrapperRef}
      data-testid="camera-wrapper"
      className={styles.wrapper}
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
      style={{ width: width ?? '100%' }}
      key={cameraData?.deviceId}
    >
      {isShowActions && activeStream && (
        <>
          <CameraTitle
            camera={cameraData}
            timeZone={timeZone}
            threadName={activeStream?.name}
            cameraWrapperRef={cameraWrapperRef}
          />
          <ActionBlock {...actionProps} />
        </>
      )}
      <DefaultChecked {...defaultCheckedProps}>
        <>
          {isWatermark && <Watermark timeZone={timeZone} />}
          {activeStream ? (
            <CameraWrapper
              cameraRef={cameraRef}
              streamType={activeStream.streamType}
              src={activeStream.url}
              loadProperties={{ onLoad, onError: () => onError() }}
            />
          ) : null}
          {isSceneValid && isDtLanes && (
            <Canvas
              cameraWrapperRef={cameraWrapperRef}
              scene={scene}
              isShowAllInfo={isShowAllZones}
            />
          )}
        </>
      </DefaultChecked>
    </div>
  );
};

export default VideoCamera;
