import { isNumber } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { findById } from '../../../../../../../../../../helpers/findBy';
import rootStore from '../../../../../../../../../../stores/rootStore/rootStore';
import { IInfoPanelData } from '../../../../../../../../../../stores/uiStore/uiStore.model';
import { System } from '../../../../../../../../../../ts/enums/enums';
import { TrafficLane } from '../../../../../../../../../../ts/models/constructor.model';
import DtIcon from '../../../../../../../../../Overlays/DtIcon/DtIcon';

interface LinkedDtProps {
  lane: TrafficLane;
  rate: number;
  isTL: boolean;
}

const LinkedDt: FC<LinkedDtProps> = (props) => {
  const { infoData, setInfoData } = rootStore.uiStore;
  const { activeDtId, setActiveDtId } = rootStore.detailedStore;
  const { detectors } = rootStore.mapDataStore;

  const { id, dtIconOffsetX, dtIconOffsetY, dtIconSize } = props.lane;

  const isDtIconVisible = isNumber(dtIconOffsetX) && isNumber(dtIconOffsetY);
  const activeId = props.isTL ? activeDtId : infoData?.id;

  const handleDtClick = () => {
    if (infoData?.id === id) return;

    const dt = findById(detectors, id);

    const infoDataDt: N<IInfoPanelData> = dt
      ? {
          id,
          coordinate: [dt.longitude, dt.latitude],
          system: System.Detectors,
        }
      : null;

    props.isTL ? setActiveDtId(id) : setInfoData(infoDataDt);
  };

  return (
    <DtIcon
      isVisible={isDtIconVisible}
      offsetX={dtIconOffsetX}
      offsetY={dtIconOffsetY}
      size={dtIconSize}
      id={id}
      isIcoWithId
      activeId={activeId}
      rate={props.rate}
      onClick={handleDtClick}
    />
  );
};

export default observer(LinkedDt);
