import { makeAutoObservable, toJS } from 'mobx';

import { CACHED_IMAGES } from '../../constants/constants';

type CasheImages = Record<string, string>;

interface CacheStore {
  cachedImages: CasheImages;
  cachedImagesOrder: any[];
}

class CacheStore {
  cachedImages: CasheImages = {};
  cachedImagesOrder: any[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  cleanCachedImages = () => {
    const newCachedImagesOrder = toJS(this.cachedImagesOrder);
    const newCachedImages = toJS(this.cachedImages);
    const imagesToRemove = newCachedImagesOrder.splice(
      0,
      CACHED_IMAGES.TO_REMOVE_AMOUNT
    );

    imagesToRemove.forEach((el) => {
      URL.revokeObjectURL(newCachedImages[el] as any);
      delete newCachedImages[el];
    });

    this.cachedImages = newCachedImages;
    this.cachedImagesOrder = newCachedImagesOrder;
  };

  setCachedImages = (key: string, value: string) => {
    this.cachedImages[key] = value;
    this.cachedImagesOrder.push(key);

    if (this.cachedImagesOrder.length > CACHED_IMAGES.MAX_AMOUNT) {
      this.cleanCachedImages();
    }
  };
}

export default new CacheStore();
