import classNames from 'classnames';
import { uniqueId } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../../../stores/rootStore/rootStore';
import { ALL } from '../../../../../TrafficLightDetailed/TlDetectors/constants/constants';
import ButtonIco from '../../../../ButtonIco/ButtonIco';
import ButtonsBlock from '../../../../ButtonsBlock/ButtonsBlock';
import { FilterInfo } from '../../../models/chart.model';

import FilterItem, { FilterItemData } from './FilterItem/FilterItem';
import { getBtnInfo } from './helpers/helpers';
import NestedFilter from './NestedFilter/NestedFilter';

import styles from './FilterGroup.module.scss';

interface FilterGroupProps extends FilterInfo {
  isLoading: boolean;
}

const FilterGroup: FC<FilterGroupProps> = ({
  filterType,
  title,
  data,
  isLoading,
}) => {
  const { filterDtData, setFilteredDtData } = rootStore.detailedStore;

  if (data.length <= 1) return null;

  const {
    isShowAll,
    contentAllBtn,
    isAllActive,
    isDisabledAll,
    isDisabledItems,
  } = getBtnInfo(filterType, filterDtData, title);

  return (
    <div className={styles.container}>
      <h4>{title}</h4>
      <ButtonsBlock isHorizontal className={styles.btnsBlock}>
        {isShowAll && (
          <ButtonIco
            key={`${filterType}_all`}
            onClick={() =>
              setFilteredDtData({ filteredType: filterType, value: ALL })
            }
            popoverProps={{ content: contentAllBtn }}
            isActive={isAllActive}
            disabled={isLoading || isDisabledAll}
            isRoundLeft
          >
            все
          </ButtonIco>
        )}
        {data.map((values, i) => {
          const isLast = i === data.length - 1;
          const isFirst = !i && !isShowAll;

          const isNested = !!values.nestedValues;

          const defaultProps: FilterItemData = {
            isDisabledItems,
            isLoading,
            filterType,
            isLast: isLast && !isNested,
          };

          return (
            <div
              key={uniqueId(`${filterType}_`)}
              className={classNames({
                [styles.nestedBlock]: true,
                [styles.nested]: isNested,
              })}
            >
              <FilterItem
                key={uniqueId('filter_')}
                {...values}
                {...defaultProps}
                isFirst={isFirst}
              />
              <NestedFilter
                nestedValues={values.nestedValues}
                defaultFilterProps={defaultProps}
                isLastParent={isLast}
                parentValue={values}
              />
            </div>
          );
        })}
      </ButtonsBlock>
    </div>
  );
};

export default observer(FilterGroup);
