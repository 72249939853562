import { MapObject } from '../../../ts/enums/enums';
import mapObjectsApi from '../mapObjectsApi';

const tlsApi = {
  fetchAll: async (regionId: number) =>
    await mapObjectsApi.fetchFullObject(MapObject.tl, regionId),

  fetchItem: async (id: number) =>
    await mapObjectsApi.fetchObjectItem(MapObject.tl, id),
};

export default tlsApi;
