import { ReactComponent as Cameras } from '../../assets/icons/signs/camera.svg';
import { ReactComponent as Detectors } from '../../assets/icons/signs/detector.svg';
import { ReactComponent as Meteo } from '../../assets/icons/signs/meteo.svg';
import { ReactComponent as PublicTransport } from '../../assets/icons/signs/public_transport.svg';
import { ReactComponent as RoadWorks } from '../../assets/icons/signs/road_works.svg';
import { ReactComponent as Rta } from '../../assets/icons/signs/rta.svg';
import { ReactComponent as SpecialTransport } from '../../assets/icons/signs/special_transport.svg';
import { ReactComponent as Lights } from '../../assets/icons/signs/tr_lights.svg';
import { EVENTS, SECOND, SYSTEM } from '../../constants/constants';
import { MapButtonsCode } from '../../ts/enums/userData';

import { ISystemButton } from './ts/MapButtons.model';

const {
  LIGHTS,
  DETECTORS,
  CAMERAS,
  SPECIAL_TRANSPORT,
  PUBLIC_TRANSPORT,
  METEO,
} = SYSTEM;
const { RTA, ROAD_WORKS } = EVENTS;

export const getMapBtns = (markers: any): ISystemButton[] => {
  const {
    isLights,
    isCameras,
    isDetectors,
    isSpecialTransport,
    isPublicTransport,
    isRta,
    isRoadWorks,
    isMeteo,
  } = markers;

  const mapBtns: ISystemButton[] = [
    {
      system: LIGHTS,
      isActive: isLights,
      component: <Lights />,
      code: MapButtonsCode.TrafficLightsButton,
    },
    {
      system: DETECTORS,
      isActive: isDetectors,
      component: <Detectors />,
      code: MapButtonsCode.DetectorsButton,
    },
    {
      system: CAMERAS,
      isActive: isCameras,
      component: <Cameras />,
      code: MapButtonsCode.VideocamsButton,
    },
    {
      system: SPECIAL_TRANSPORT,
      isActive: isSpecialTransport,
      component: <SpecialTransport />,
      code: MapButtonsCode.SpecialTransportButton,
    },
    {
      system: METEO,
      isActive: isMeteo,
      component: <Meteo />,
      code: MapButtonsCode.MeteoStationsButton,
    },
    {
      system: PUBLIC_TRANSPORT,
      isActive: isPublicTransport,
      component: <PublicTransport />,
      code: MapButtonsCode.PublicTransportButton,
    },
    {
      system: RTA,
      isActive: isRta,
      component: <Rta />,
      code: MapButtonsCode.СarAccidentButton,
    },
    {
      system: ROAD_WORKS,
      isActive: isRoadWorks,
      component: <RoadWorks />,
      code: MapButtonsCode.RoadWorksButton,
    },
  ];

  return mapBtns;
};

export const COMPONENT_VISIBILITY_TIMEOUT = 30 * SECOND;
