import {
  TL_STATUS_COLORS_HEX,
  TL_STATUS_HEX,
} from '../constants/tlStatusConstants';
import { TlMode } from '../ts/models/tl.model';

const { RED, YELLOW, GREY } = TL_STATUS_COLORS_HEX;

interface ReturnParseTLMode {
  color: string;
  isEmpty: boolean;
  isSpecialMode: boolean;
}

type ParseTLReturn<T> = T extends undefined ? ReturnParseTLMode : string;

const parseTLMode = <T extends U<true> = undefined>(
  mode: N<TlMode>,
  isOnlyColor?: T
): ParseTLReturn<T> => {
  const color = mode ? TL_STATUS_HEX[mode] : GREY;

  if (isOnlyColor !== undefined) return color as any;

  const isSpecialMode = [RED, YELLOW, GREY].some((el) => el === color);
  const isEmpty = mode === null || isSpecialMode;

  return { color, isEmpty, isSpecialMode } as any;
};

export default parseTLMode;
