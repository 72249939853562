import { cloneDeep } from 'lodash';

import { AdminTitleKeys } from '../../../constants/adminConstants';
import rootStore from '../../../stores/rootStore/rootStore';
import { IAdminsPanelTree } from '../../Panel/AdminPanel/helpers/helpers';
import { IBreadCrumbsItem } from '../BreadCrumbs/BreadCrumbs';

import { parsingNavigationPath } from './helpers';

const { EputsSystem } = AdminTitleKeys;

const getBreadCrumbs = (
  systemMenu: IAdminsPanelTree[],
  adminTableType: string
) => {
  const { adminPanelKeys } = rootStore.adminPanelStore;

  if (!adminPanelKeys) return { formattedKeys: [], newBreadCrumbsItems: [] };

  const newBreadCrumbsItems: IBreadCrumbsItem[] = [];

  const keysClone = cloneDeep(adminPanelKeys);

  const activeIndex = keysClone.indexOf(adminTableType);
  const isNeedReverse = activeIndex <= 0;

  if (isNeedReverse) {
    keysClone.reverse();
  }

  const panelKeys = !isNeedReverse
    ? keysClone.slice(0, activeIndex + 1)
    : keysClone;

  const formattedKeys =
    adminTableType === EputsSystem ? [EputsSystem] : panelKeys;

  formattedKeys.forEach((key) => {
    parsingNavigationPath(key, systemMenu, newBreadCrumbsItems);
  });

  return { formattedKeys, newBreadCrumbsItems };
};

export default getBreadCrumbs;
