import { findBy, findById } from '../../../../../../../../helpers/findBy';
import { Detector } from '../../../../../../../../ts/models/mapObject.model';
import { Scene } from '../../../../../../../../ts/models/MapObjects/detectors.model';

type Lanes = NonNullable<Scene>['lanes'];

export const getFilteredScene = (
  scene: U<Scene>,
  detectors: Detector[],
  id: N<number>
): Scene => {
  const activeDt = findById(detectors, id);

  const laneParams = activeDt?.dai_jdt83?.laneParams;

  if (!laneParams?.length || !scene) return scene;

  const filteredScene = scene.lanes.reduce((acc: Lanes, lane) => {
    const laneParam = lane.number && findBy(laneParams, lane.number, 'number');

    if (laneParam) {
      acc.push({
        ...lane,
        number: laneParam.id,
      });
    }

    return acc;
  }, []);

  const correctLanes = filteredScene.length ? filteredScene : scene.lanes;

  return { lanes: correctLanes, trafficLines: scene.trafficLines };
};
