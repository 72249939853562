import { Card, Input, List } from 'antd';
import React, { useLayoutEffect, useState } from 'react';
import { HiArrowLeft } from 'react-icons/hi';
import { TiLocation } from 'react-icons/ti';

import ButtonOnlyIcon from '../../../ui-kit/ButtonOnlyIcon/ButtonOnlyIcon';
import {
  DEFAULT_PAGE_SETTINGS,
  DIRECTION_PAGE_SETTINGS,
  DIRECTIONS_PANEL_CONTAINER_ID,
  SEARCH_CONTENT,
  SEARCH_RESULT_ICON_SIZE,
  SEARCH_STYLES,
  START_POINT_ICON_SIZE,
  START_POINT_STYLE,
} from '../constants';
import { getDirectionPageSize } from '../helpers';
import { IDirectionPanelProps } from '../search.model';

import styles from '../SearchButton.module.scss';

const { Search } = Input;

const DirectionPanel = (props: IDirectionPanelProps) => {
  const [directionPageSize, setDirectionPageSize] = useState(
    getDirectionPageSize(DIRECTIONS_PANEL_CONTAINER_ID)
  );

  const filteredItems = props.direction.instructions.filter(
    (item) => item.directionName
  );

  useLayoutEffect(() => {
    const updateSize = () => {
      setDirectionPageSize(getDirectionPageSize(DIRECTIONS_PANEL_CONTAINER_ID));
    };

    window.addEventListener('resize', updateSize);

    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const directionPaginationSettings = () => {
    if (filteredItems.length < directionPageSize) {
      return DEFAULT_PAGE_SETTINGS;
    }

    return {
      ...DIRECTION_PAGE_SETTINGS,
      pageSize: directionPageSize,
    };
  };

  return (
    <Card
      bordered={false}
      className={styles.searchPanel}
      styles={{ body: SEARCH_STYLES.panelBodyStyle }}
    >
      <div className={styles.searchPanelTitle}>
        <HiArrowLeft
          size={SEARCH_RESULT_ICON_SIZE}
          style={{ marginRight: 5 }}
          onClick={props.onClick}
        />
        {SEARCH_CONTENT.directionsTitle}
      </div>

      <div className={styles.searchContainer}>
        <Search
          value={props.value}
          placeholder="Начальный объект..."
          onPressEnter={props.onPressEnter}
          onInput={props.onInput}
          loading={props.loading}
          addonBefore={
            <div style={{ width: 8 }}>
              <ButtonOnlyIcon
                onClick={props.onLocationSelected}
                style={START_POINT_STYLE}
              >
                <TiLocation
                  size={START_POINT_ICON_SIZE}
                  className={
                    props.selectStartPoint ? styles.selectionActive : ''
                  }
                />
              </ButtonOnlyIcon>
            </div>
          }
        />
      </div>
      <div className={styles.searchContainer}>
        <Search
          placeholder="Конечный объект..."
          readOnly
          value={props.point2.title}
          onChange={(evt) => {
            if (evt.target.value === '') {
              props.onReset();
            }
          }}
        />
      </div>

      {props.direction && props.direction.instructions.length > 0 ? (
        <>
          {props.direction.time && props.direction.distance ? (
            <div className={styles.directionSummaryContainer}>
              <div className={styles.directionSummaryResultItem}>
                Расстояние: <b>{props.direction.distance}км</b>
              </div>
              <div className={styles.directionSummaryResultItem}>
                Длительность: <b>{props.direction.time} [авто]</b>
              </div>
            </div>
          ) : null}

          <div className={styles.narrativeItem}>
            <div className={styles.narrativeItemTitle}>Направление</div>
            <div className={styles.narrativeItemDistance}>Расстояние</div>
            <div className={styles.narrativeItemTime}>Время</div>
          </div>
          <List
            id={DIRECTIONS_PANEL_CONTAINER_ID}
            className={styles.directionListContainer}
            dataSource={filteredItems}
            pagination={directionPaginationSettings() || props.pagination}
            renderItem={props.renderItem}
          />
        </>
      ) : null}
    </Card>
  );
};

export default DirectionPanel;
