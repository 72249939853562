import labelingApi from '../../api/integration/gis/labeling/index';

const labelingService = {
  async getList(appPath: string, regionId: number) {
    const data = await labelingApi.getList(appPath, regionId);

    const hasData = Boolean(data?.length);

    if (hasData) {
      return { data: data, hasIntegrationGIS: true };
    }

    return { data: [], hasIntegrationGIS: false };
  },
};

export default labelingService;
