import api from '../../../../api';
import notification from '../../../ui-kit/Notification/Notification';
import { DIV_PATH } from '../Divisions/Divisions';
import {
  isDivisionBody,
  SubmitDivisionsArgs,
  SubmitDivValues,
} from '../models/Divisions.model';

const { update, create } = api.catalogs.organizations.divisions;

const submitDivisions = async (
  values: SubmitDivValues,
  props?: SubmitDivisionsArgs
) => {
  if (!props) return;
  const { collaboration, setIsUpdate, setIsModalOpen, id, isEdit } = props;

  const res = isEdit
    ? isDivisionBody(values) &&
      (await update(values, collaboration.id, DIV_PATH))
    : !isDivisionBody(values) &&
      (await create(
        {
          name: values.name.trim(),
          dsc: values.dsc.trim(),
          organizationId: id,
        },
        DIV_PATH
      ));

  if (res) {
    setIsUpdate(true);

    notification.success(isEdit ? 'DIVISION_DATA_UPDATE' : 'DIVISION_DATA_ADD');
  }
  setIsModalOpen(false);
};

export default submitDivisions;
