import { FC } from 'react';
import { HiMinusSm, HiPlus } from 'react-icons/hi';
import { TbZoomReset } from 'react-icons/tb';

import rootStore from '../../../../../stores/rootStore/rootStore';
import ButtonIco from '../../../ButtonIco/ButtonIco';
import ButtonsBlock from '../../../ButtonsBlock/ButtonsBlock';

import styles from './UiZoomBtns.module.scss';

const PLACEMENT = 'left';

export interface UiZoomBtnsProps {
  handlers: {
    reset: () => void;
    increase: () => void;
    decrease: () => void;
  };
  disabled: {
    disabledIncrease: boolean;
    disabledDecrease: boolean;
    disabledReset: boolean;
  };
}

const UiZoomBtns: FC<UiZoomBtnsProps> = ({ handlers, disabled }) => {
  const { mapButtonsTipsDelay } = rootStore.uiStore;

  const { increase, decrease, reset } = handlers;
  const { disabledIncrease, disabledDecrease, disabledReset } = disabled;

  const popOverProps = {
    placement: PLACEMENT,
    tipsDelay: mapButtonsTipsDelay,
  } as const;

  return (
    <div className={styles.zoom}>
      <ButtonsBlock borderRadius={3} className={styles.buttonBox}>
        <ButtonIco
          onClick={increase}
          disabled={disabledIncrease}
          isSmall
          popoverProps={popOverProps}
        >
          <HiPlus />
        </ButtonIco>

        <ButtonIco
          onClick={decrease}
          disabled={disabledDecrease}
          isRoundBottom
          isSmall
          popoverProps={popOverProps}
        >
          <HiMinusSm />
        </ButtonIco>
      </ButtonsBlock>
      <div className={styles.reset}>
        <ButtonIco
          onClick={reset}
          disabled={disabledReset}
          isSmall
          popoverProps={popOverProps}
        >
          <TbZoomReset />
        </ButtonIco>
      </div>
    </div>
  );
};

export default UiZoomBtns;
