import classNames from 'classnames';
import { uniqueId } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import { ITimePoints } from '../../../ts/models/signalPrograms.model';
import { secondsToTime } from '../../TrafficLightDetailed/TlManagement/PhaseHold/helpers/secondsToTime';
import Popover from '../../ui-kit/Popover/Popover';
import { getPhases } from '../helpers/getPhases';
import { IBasicProps } from '../model/signalProgramsBand.model';

import TooltipPhasesContent from './TooltipPhasesContent/TooltipPhasesContent';

import styles from './PhasesTime.module.scss';

interface PhasesTimeProps extends IBasicProps {
  isSmall: boolean;
}

const PhasesTime: FC<PhasesTimeProps> = ({
  phasesSet,
  len,
  width,
  isSmall,
}) => {
  const { tipsValues, setKeyValue } = rootStore.uiStore;

  const [phasesTime, setPhasesTime] = useState<ITimePoints[]>([]);

  useEffect(() => {
    if (phasesSet?.length) {
      const res = getPhases(phasesSet);

      setKeyValue('phasesArray', res);

      setPhasesTime(res);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phasesSet]);

  return (
    <div className={styles.phaseRowTime}>
      {phasesTime.map((item, i) => {
        const { stop, phase, length } = item;

        const isFirst = !i;
        const isLast = i === phasesTime.length - 1;

        return (
          <Popover
            key={uniqueId()}
            content={<TooltipPhasesContent length={length} />}
            placement={'bottom'}
            tipsDelay={tipsValues.interfaceTipsDelay}
          >
            <div
              className={classNames(styles.blockTime, {
                [styles.smallBlock]: isSmall,
                [styles.first]: isFirst,
                [styles.last]: isLast,
              })}
              style={{
                width: `${(width / Number(len)) * length}px`,
              }}
            >
              <p
                className={classNames(styles.phaseBlockTime, {
                  [styles.small]: isSmall,
                  [styles.odd]: i % 2 !== 0,
                })}
              >
                {secondsToTime(stop, { H: false, M: true, S: true })} ({stop}{' '}
                c.)
              </p>

              <div className={styles.titleBlockPhase}>
                <p className={styles.phaseBlockTitleTime}>Ф{phase}</p>
              </div>
            </div>
          </Popover>
        );
      })}
    </div>
  );
};

export default observer(PhasesTime);
