import { Tabs } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC, ReactNode } from 'react';

import parseTLMode from '../../../../../../../../helpers/parseTLMode';
import DefaultChecked from '../../../../../../../ui-kit/DefaultChecked/DefaultChecked';

import { DETAILED_CONTROL_ITEMS } from './constants/controlTabs';
import useTLSec from './hooks/useTLSec';

import styles from './TabsDetailed.module.scss';

interface TabsDetailedProps {
  tlId: number;
  children: ReactNode;
}

const TabsDetailed: FC<TabsDetailedProps> = ({ tlId, children }) => {
  const { sec, activeTL } = useTLSec(tlId);

  const tabsDetailed = DETAILED_CONTROL_ITEMS.map(
    ({ children: Item, ...el }) => {
      return { ...el, children: <Item tlId={tlId} sec={sec} /> };
    }
  );

  const isSpecialMode = parseTLMode(
    activeTL?.tlStatus.mode ?? null
  ).isSpecialMode;

  return (
    <div className={styles.container}>
      <DefaultChecked
        isEmptyData={isSpecialMode}
        noDataProps={{
          textError: 'В данном режиме информация о фазах отсутствует',
          classNameEmpty: styles.noData,
        }}
      >
        <Tabs
          className={styles.tabsCustom}
          defaultActiveKey="1"
          items={tabsDetailed}
        />
      </DefaultChecked>
      {children}
    </div>
  );
};

export default observer(TabsDetailed);
