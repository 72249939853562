import { Descriptions } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../../../../stores/rootStore/rootStore';
import { GraphTypes } from '../../../../../../TrafficLightDetailed/TlDetectors/constants/constants';

import getDictItems from './helpers/getDictItems';

interface DictDescProps {
  descStyle: string;
}

const DictDesc: FC<DictDescProps> = ({ descStyle }) => {
  const { graphType } = rootStore.detailedStore;

  if (graphType !== GraphTypes.CandlesChart) return null;

  const items = getDictItems();

  return (
    <Descriptions
      items={items}
      layout="vertical"
      title="Справочник"
      className={descStyle}
    />
  );
};

export default observer(DictDesc);
