import { Badge, Dropdown } from 'antd';
import { observer } from 'mobx-react-lite';
import { VscSettings } from 'react-icons/vsc';

import { COLORS } from '../../../../../constants/colorsConstants';
import rootStore from '../../../../../stores/rootStore/rootStore';
import Popover from '../../../../ui-kit/Popover/Popover';

import DropdownStatus from './DropdownStatus/DropdownStatus';

import styles from './StatusLegend.module.scss';

const StatusLegend = () => {
  const { interfaceTipsDelay } = rootStore.uiStore;
  const { statusFilteredMonitoring } = rootStore.monitoringPanelStore;

  return (
    <Dropdown dropdownRender={() => <DropdownStatus />} trigger={['click']}>
      <span className={styles.ico}>
        <Popover content={'Фильтр устройств'} tipsDelay={interfaceTipsDelay}>
          <Badge
            count={statusFilteredMonitoring.length}
            className={styles.ico}
            color={COLORS.PRIMARY}
            size="small"
          >
            <VscSettings />
          </Badge>
        </Popover>
      </span>
    </Dropdown>
  );
};

export default observer(StatusLegend);
