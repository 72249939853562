import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { ReactComponent as GridIco } from '../../../../assets/icons/grid.svg';
import { ReactComponent as OneToManyIco } from '../../../../assets/icons/OneToOthersVideo.svg';
import rootStore from '../../../../stores/rootStore/rootStore';
import Popover from '../../../ui-kit/Popover/Popover';
import { PopoverPropsFormatted } from '../../temporary/VsSwitcher/VsSwitcher';

import styles from './CamerasVsSwitcher.module.scss';

const POPOVER_CAMERA_CONTENT = {
  GRID: 'Отображение сеткой',
  ONE_TO_MANY: 'Выделить одну камеру',
};

export enum CamerasTemplate {
  EQUAL,
  ONE_TO_MANY,
}

interface CamerasVsSwitcherProps {
  toggler: (stateString: CamerasTemplate) => void;
  templateVer: CamerasTemplate;
}

const CamerasVsSwitcher: FC<CamerasVsSwitcherProps> = ({
  toggler,
  templateVer,
}) => {
  const { interfaceTipsDelay } = rootStore.uiStore;

  const popoverProps: PopoverPropsFormatted = {
    tipsDelay: interfaceTipsDelay,
    placement: 'bottom',
  };

  return (
    <div className={styles.container}>
      <input
        defaultChecked
        checked={templateVer === CamerasTemplate.EQUAL}
        id="gridCameras"
        name="VsSwitchInput"
        onClick={() => {
          toggler(CamerasTemplate.EQUAL);
        }}
        type="radio"
      />

      <Popover {...popoverProps} content={POPOVER_CAMERA_CONTENT.GRID}>
        <label htmlFor="gridCameras">
          <GridIco className={styles.gridIco} />
        </label>
      </Popover>

      <input
        id="OneToManyVerInput"
        name="VsSwitchInput"
        checked={templateVer === CamerasTemplate.ONE_TO_MANY}
        onClick={() => {
          toggler(CamerasTemplate.ONE_TO_MANY);
        }}
        type="radio"
      />

      <Popover {...popoverProps} content={POPOVER_CAMERA_CONTENT.ONE_TO_MANY}>
        <label htmlFor="OneToManyVerInput">
          <OneToManyIco className={styles.listIco} />
        </label>
      </Popover>
    </div>
  );
};

export default observer(CamerasVsSwitcher);
