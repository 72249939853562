import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { sliceTitleBy } from '../../../../../helpers/sliceTitle';
import rootStore from '../../../../../stores/rootStore/rootStore';
import Popover, { PopoverProps } from '../../../../ui-kit/Popover/Popover';

import styles from './TreeItem.module.scss';

interface TreeItemProps {
  deviceId: Nullish<number>;
  title: string;
  maxLen: number;
  count: U<number>;
}

const TreeItem: FC<TreeItemProps> = ({ deviceId, title, maxLen, count }) => {
  const { interfaceTipsDelay } = rootStore.uiStore;

  const maxLenCorrect = maxLen + (deviceId ? 4 : 0);
  const maxLenFormatted = maxLenCorrect - (Number.isFinite(count) ? 6 : 0);

  const isNeedTooltip = title.length > maxLenFormatted;

  const titleContent = isNeedTooltip
    ? sliceTitleBy(title, maxLenFormatted)
    : title;

  const popoverProps: Omit<PopoverProps, 'children'> = {
    placement: 'right',
    content: title,
    tipsDelay: isNeedTooltip ? interfaceTipsDelay : null,
  };

  const isDisabled = count === 0;

  const titleStyle = classNames({
    [styles.title]: true,
    [styles.disabled]: isDisabled,
  });

  return (
    <Popover {...popoverProps}>
      <div className={styles.container}>
        {deviceId && <span className={styles.idBlock}>{deviceId}</span>}
        <h4 className={titleStyle}>{titleContent}</h4>
        <span className={styles.count}>{count}</span>
      </div>
    </Popover>
  );
};

export default observer(TreeItem);
