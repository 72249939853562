import type { Coordinate } from 'ol/coordinate';

import { CLUSTERS_INFO } from '../../../../constants/mapClusterConstants';
import { ZOOM } from '../../../../constants/mapConstants';
import rootStore from '../../../../stores/rootStore/rootStore';
import { setClustersZoomChange } from '../setClustersZoomChange';

import handleCenter from './handleCenter';

const handleCenterZoom = (center: U<Coordinate>, zoom?: number) => {
  const { map } = rootStore.mapStore;

  if (!map) return;

  const { isConstructor } = rootStore.constructorStore;
  const view = map.getView();
  const zoomLevel = view.getZoom();

  if (
    (zoomLevel !== undefined && zoomLevel >= ZOOM.CROSSROAD_BORDER) ||
    isConstructor
  ) {
    return handleCenter(center, { isNeedOpenCluster: true, zoom });
  }

  view.animate(
    {
      center,
      zoom: zoom ?? ZOOM.CROSSROAD_BORDER,
      duration: CLUSTERS_INFO.ANIMATION_DURATION,
    },
    (isEnd) => isEnd && setClustersZoomChange({ map })
  );
};

export default handleCenterZoom;
