import { FC } from 'react';

import styles from './CircleMarkerPopup.module.scss';
export interface CircleMarkerPopupProps {
  colorWithMode: string;
  icoInfo: {
    borderColor: N<string>;
    ico: JSX.Element;
  };
}

const CircleMarkerPopup: FC<CircleMarkerPopupProps> = ({
  colorWithMode,
  icoInfo,
}) => {
  const { ico, borderColor } = icoInfo;

  return (
    <>
      <div
        className={styles.crosshairPopupMain}
        style={{
          backgroundColor: colorWithMode,
          borderColor: borderColor ?? colorWithMode,
        }}
      >
        {ico}
      </div>
    </>
  );
};

export default CircleMarkerPopup;
