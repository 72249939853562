import { ManipulateType } from 'dayjs';

import { TSelectDates } from '../../model/graph.model';
import { getTimeWithTimeZone } from '../TitleBtns/TimeLocalCard/helpers/getTimeWithTimeZone';
import { getTimeZones } from '../TitleBtns/TimeLocalCard/helpers/getTimeZone';

import { getSubtractDay } from './getSubtractDay';

export enum SliceTypes {
  hour = 'hour',
  day = 'day',
}

interface IOptionsSlice {
  sliceType?: SliceTypes;
  isNeedUtcFormatted?: boolean;
  isMinuteAccuracy?: boolean;
  timeType?: ManipulateType;
}

type GetSliceDates = (day: number, options?: IOptionsSlice) => TSelectDates;

export const getSliceDates: GetSliceDates = (
  day,
  {
    timeType = 'days',
    sliceType = SliceTypes.hour,
    isNeedUtcFormatted = false,
    isMinuteAccuracy = false,
  } = {}
) => {
  const [dateFrom, dateTo] = getSubtractDay(day, timeType);

  const dates: TSelectDates = isMinuteAccuracy
    ? [dateFrom.startOf('minute'), dateTo.startOf('minute')]
    : [
        dateFrom.startOf(sliceType),
        dateTo.add(1, sliceType).startOf(sliceType),
      ];

  if (isNeedUtcFormatted) {
    const timeZone = getTimeZones().timeZoneRegion;

    return dates.map(
      (date) => getTimeWithTimeZone({ timeZone, dateJS: date }).dateFormattedJS
    ) as TSelectDates;
  }

  return dates;
};
