import { observer } from 'mobx-react-lite';

import { DEVICES_STORE_NAMES } from '../../../../../constants/devicesConstants';
import { getSystemTitle } from '../../../../../helpers/getSystemTitle';
import rootStore from '../../../../../stores/rootStore/rootStore';

import styles from './Title.module.scss';

const Title = () => {
  const { deviceInfo } = rootStore.monitoringPanelStore;
  const { getById } = rootStore.mapDataStore;

  const deviceName = deviceInfo && DEVICES_STORE_NAMES[deviceInfo.system];

  const activeDevice =
    deviceInfo && deviceName && getById(deviceInfo.id, deviceName);

  if (!activeDevice) return null;

  const {
    deviceInfo: { caption },
  } = activeDevice;

  const title = getSystemTitle(deviceInfo.system, activeDevice);

  return (
    <div className={styles.container}>
      <h4>
        {title} {caption}
      </h4>
    </div>
  );
};

export default observer(Title);
