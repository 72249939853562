import { Button, Select } from 'antd';
import { FC } from 'react';

import DatePicker from '../../../../ui-kit/Picker/DatePicker/DatePicker';
import { OPTIONS_MACRO } from '../constants/constants';

import styles from './FilterCard.module.scss';

interface FilterCardProps {
  handleModelData: () => void;
  isLoading: boolean;
}

const FilterCard: FC<FilterCardProps> = ({ isLoading, handleModelData }) => {
  return (
    <div className={styles.container}>
      <h4 className={styles.title}>Запрос данных:</h4>
      <div className={styles.row}>
        <div>
          <p>От ДТ:</p>
          <DatePicker onChangeDate={() => {}} pickerClassName={styles.item} />
        </div>
        <div>
          <p>Из макромодели:</p>
          <Select
            options={OPTIONS_MACRO}
            placeholder="Выберите время суток"
            className={styles.input}
            allowClear
          />
        </div>
      </div>
      <div className={styles.btnBlock}>
        {/* fake requests for presentation */}
        <Button type="primary" onClick={handleModelData} loading={isLoading}>
          Запросить
        </Button>
      </div>
    </div>
  );
};

export default FilterCard;
