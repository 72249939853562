/* eslint-disable camelcase */
import { z } from 'zod';

import { ViewStatus } from '../../../ts/enums/constructor';
import { MapObjectFull } from '../../../ts/enums/enums';
import { CircleElEnum } from '../../../ts/enums/tl';
import { LinkedDeviceIdsSchema } from '../../mapObjects/mapObjects.zod';
import { TLCrossroadSchema } from '../../mapObjects/trafficLights/zod/tls.zod';
import { Int, NN, NNInt, XY } from '../../zod/utils.zod';
import { PointUdsSchema } from '../pointUds.zod';

const isFinalVersion = z.boolean();
const nullableString = z.string().optional().nullable();
const profile_id = NNInt.nullable();
const id = z.number().int().positive();

const BasicElSchema = z.object({
  offsetX: z.number().int(),
  offsetY: z.number().int(),
  angle: NNInt,
  size: NNInt,
  color: z.string(),
});

const ElExtendedSchema = BasicElSchema.extend({
  id,
  opacity: NN,
});

const UserSchema = z.object({
  id,
  login: z.string(),
  name: z.string(),
  email: z.string().email(),
});

export const ProfileHistorySchema = z.object({
  date: z.string(),
  user: UserSchema,
});

export const ProfileSchema = PointUdsSchema.extend({
  profile_dsc: z.string().nullable(),
  profile_id,
  profile_name: z.string(),
  profileHistory: ProfileHistorySchema.array().optional(),
  isFinalVersion,
});

export const HeaderSchema = z.object({
  code: Int,
  type: z.literal('WebDesigner'),
  ver: z.string(),
});

export const MeteoFromProfileSchema = BasicElSchema.omit({
  color: true,
  angle: true,
}).extend({
  id,
  caption: z.string(),
  type: z.literal(MapObjectFull.meteo),
  longitude: z.number(),
  latitude: z.number(),
});

export const SchemaODDSchema = TLCrossroadSchema.nullable().optional();

export const BasicProfileInfoSchema = ProfileSchema.extend({
  centerPoint: XY,
  deviceIds: z.number().array(),
  linkedDeviceIds: LinkedDeviceIdsSchema,
  meteos: MeteoFromProfileSchema.array().nullable().optional(),
  schemaODD: SchemaODDSchema,
  regionId: NNInt.optional(),
});

export const CameraFinalSchema = ElExtendedSchema.extend({
  arcHeight: NNInt,
  distance: NNInt,
  linkedDetectorId: NNInt.nullable(),
  widthRate: NN,
  longitude: z.number().optional(),
  latitude: z.number().optional(),
});

export const CameraSchema = CameraFinalSchema.extend({
  caption: z.string(),
  final: CameraFinalSchema.nullable(),
  isEnabled: z.boolean(),
  isExtended: z.boolean(),
});

const CrossroadPhasesSchema = z.object({
  phase: NNInt,
  checked: z.boolean(),
});

export const CrossroadGeneralSchema = BasicElSchema.extend({
  id: NNInt.optional().nullable(),
  colorTransition: z.string(),
  crosswalkColor: z.string(),
  crosswalkColorTransition: z.string(),
  isBlinkingTransitionTact: z.boolean(),
  isTrafficLanesOffset: z.boolean(),
  isCamerasOffset: z.boolean(),
  isDirections: z.boolean(),
  isMainTact: z.boolean(),
  phases: CrossroadPhasesSchema.array(),
  strokeWidth: z.number().optional(),
  roadOpacity: z.number(),
  isBorders: z.boolean(),
  isMarkup: z.boolean(),
  isBlackRoad: z.boolean(),
  isTls: z.boolean(),
  isCrosswalksMarkup: z.boolean(),
  isEnabled: z.boolean(),
});

export const LaneParamsSchema = z.object({
  id,
  number: z.number().optional().nullable(),
  offset: z.number(),
  span: z.number(),
  width: NN,
  zoneUID: nullableString,
});

export const TrafficLanesFinalSchema = ElExtendedSchema.extend({
  caption: z.string(),
  comment: z.string().nullable(),
  isGradient: z.boolean(),
  laneParams: LaneParamsSchema.array(),
  length: NNInt,
  mainOffsetX: z.number().int(),
  mainOffsetY: z.number().int(),
  mainWidth: NNInt,
  width: NN,
  linkedCameraId: NNInt.nullable().optional(), // temporary optional
}).omit({ size: true });

export const TrafficLanesSchema = TrafficLanesFinalSchema.extend({
  isEnabled: z.boolean().optional(),
  isMain: z.boolean().optional(),
});

export const CrossroadSchema = z.object({
  general: CrossroadGeneralSchema,
  trafficLanes: TrafficLanesSchema.array(),
});

export const CircleElDynamicSchema = BasicElSchema.extend({
  bottomAngle: NNInt,
  additionalTopAngle: NNInt,
  strokeWidth: z.number(),
  sizeRate: z.number(),
  dash: XY.optional(),
}).omit({ color: true });

export const CircleElsDynamicSchema = z.record(
  z.string(),
  CircleElDynamicSchema.array()
);

export const CircleElGeneralSchema = z.object({
  direction: z.number(),
  type: z.nativeEnum(CircleElEnum),
  isCircleElement: z.boolean(),
  isDashed: z.boolean(),
  isDirectPlus: z.boolean(),
  isAdditionalTop: z.boolean(),
  isDashAnimation: z.boolean(),
  isOpacityAnimation: z.boolean(),
  isColorAnimation: z.boolean(),
  additionalTopAngle: NNInt.optional(),
  color: z.string(),
  dash: XY,
  animation: z.literal(null),
  hue: NNInt,
  strokeWidth: NNInt.optional(),
  sizeRate: NN.optional(),
});

export const CircleParamsSchema = z.object({
  circleDiameter: NNInt,
  circleOpacity: z.number(),
  circleHue: NNInt,
  circleBorderWidth: NNInt,
  isCircleCenter: z.boolean(),
  circleCenter: XY,
  isCircleConstructor: z.boolean(),
  isBlinkingAnimation: z.boolean(),
});

export const PhaseSchema = z.object({
  phaseNumber: z.number().nullable(),
  directions: z.number().array(),
  image: z.string().optional(),
  code: NNInt,
  name: z.string().optional(),
  cache: z.number().optional(),
});

export const TLSchema = z.object({
  circleElsDynamic: CircleElsDynamicSchema,
  circleElsGeneral: CircleElGeneralSchema.array(),
  circleParams: CircleParamsSchema,
  currentPhaseIdx: NNInt,
  phases: PhaseSchema.array(),
  zoomRange: z.number().array().nonempty(),
});

export const ViewSchema = z.record(z.string().startsWith('is'), z.boolean());

export const JProfileSchema = z.object({
  basicProfileInfo: BasicProfileInfoSchema,
  cameras: CameraSchema.array(),
  create: z.coerce.date().nullable().optional(),
  crossroad: CrossroadSchema,
  header: HeaderSchema,
  isCenteredByClick: z.boolean(),
  profile: ProfileSchema,
  trafficLights: TLSchema,
  update: z.coerce.date(),
  view: ViewSchema,
  viewStatus: z.nativeEnum(ViewStatus).optional(),
});

export const ProfileWrapperSchema = PointUdsSchema.extend({
  dsc: nullableString,
  isFinalVersion,
  jprofile: JProfileSchema,
  name: nullableString,
  profile_id,
});
