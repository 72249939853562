import { Button, Popconfirm, Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../stores/rootStore/rootStore';

import styles from './ActionButtons.module.scss';

type ConfirmProps = {
  name: string;
  entity: string;
  confirm: () => Promise<void>;
};

interface IButtonProps {
  content?: JSX.Element;
  isDisabled: number | boolean;
  modaltype?: string;
  modalTitle?: string;
  icon: JSX.Element;
  tooltip: U<string>;
  handler?: () => void;
  placement?: TooltipPlacement;
  confirmProps?: ConfirmProps;
}

interface IActionButtons {
  actionButtons: IButtonProps[];
}

const ActionButtons: FC<IActionButtons> = ({ actionButtons }) => {
  const { setAdminsData } = rootStore.adminPanelStore;

  const handleOpenModal = (
    modalTitle?: string,
    modaltype?: string,
    content?: JSX.Element
  ) => {
    setAdminsData('isModalOpen', true);
    setAdminsData('modalContent', {
      title: modalTitle ?? '',
      content: content,
    });
    setAdminsData('adminModalType', modaltype ?? '');
  };

  return (
    <div className={styles.wrapper}>
      {actionButtons.map(
        (
          {
            tooltip,
            isDisabled,
            modalTitle,
            modaltype,
            icon,
            content,
            handler,
            placement,
            confirmProps,
          },
          i
        ) => {
          const onConfirm = async () => await confirmProps?.confirm();

          return confirmProps ? (
            <Popconfirm
              title={`Удалить ${confirmProps.entity}: ${confirmProps.name}`}
              onConfirm={onConfirm}
              okText="Да"
              cancelText="Нет"
              key={i}
              okButtonProps={{ danger: true }}
            >
              <Tooltip title={tooltip} placement={placement ?? 'top'} key={i}>
                <Button
                  icon={icon}
                  onClick={() =>
                    handler
                      ? handler()
                      : handleOpenModal(modalTitle, modaltype, content)
                  }
                  disabled={Boolean(isDisabled)}
                />
              </Tooltip>
            </Popconfirm>
          ) : (
            <Tooltip title={tooltip} placement={placement ?? 'top'} key={i}>
              <Button
                icon={icon}
                onClick={() =>
                  handler
                    ? handler()
                    : handleOpenModal(modalTitle, modaltype, content)
                }
                disabled={Boolean(isDisabled)}
              />
            </Tooltip>
          );
        }
      )}
    </div>
  );
};

export default observer(ActionButtons);
