import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import IconTooltip from '../IconTooltip/IconTooltip';
import { DeviceWrapperProps } from '../models/device.model';

import styles from './DeviceWrapper.module.scss';

const DeviceWrapper: FC<DeviceWrapperProps> = ({
  device,
  size,
  rate,
  children,
  isActive = true,
  offsetX = 0,
  offsetY = 0,
  id,
  system,
}) => {
  const { calcCircleVal } = rootStore.pointsUdsStore;
  const { focusedPointUdsIconId, setKeyValue } = rootStore.uiStore;

  const offset = {
    y: device?.offsetY ?? offsetY,
    x: device?.offsetX ?? offsetX,
  };

  const zIndex = id === focusedPointUdsIconId ? 2 : undefined;

  const onMouseEnter = () => id && setKeyValue('focusedPointUdsIconId', id);

  const tooltipProps = { size, system, id };

  const wrapperStyle = {
    top: calcCircleVal(offset.y, rate),
    left: calcCircleVal(offset.x, rate),
    width: size,
    height: size,
  };

  const deviceContainerStyle = classNames({
    [styles.container]: true,
    [styles.unActive]: !isActive,
  });

  return (
    <div className={deviceContainerStyle} style={{ zIndex }}>
      <div
        style={wrapperStyle}
        className={deviceContainerStyle}
        onMouseEnter={onMouseEnter}
      >
        <IconTooltip {...tooltipProps}>{children}</IconTooltip>
      </div>
    </div>
  );
};

export default observer(DeviceWrapper);
