import { IPieChartData } from '../../models/circleChart.model';

export const getActiveInfo = (
  hoverType: string,
  sectorName: U<string>,
  activeIndex: number | number[],
  data: IPieChartData[],
  activeSectors: U<string[]>,
  isActive: boolean
) => {
  const isActiveSector = hoverType ? sectorName === hoverType : isActive;
  const isSelectedSector = activeSectors?.some((el) => el === sectorName);
  const isActiveSection = isSelectedSector || isActiveSector;

  const selectedIndex = data.findIndex(({ name }) => name === sectorName);

  const isActiveIndex = Array.isArray(activeIndex)
    ? activeIndex.at(-1) === selectedIndex
    : selectedIndex === activeIndex;

  const isActiveText = hoverType ? sectorName === hoverType : isActiveIndex;

  return { isActiveSection, isActiveText };
};
