import type { DefaultOptionType } from 'antd/lib/select';

import { TMap } from '../../../stores/mapStore/mapStore.model';
import { CARTOGRAPHIC_SCALES } from '../../constants/view';
import { getScaleResolution } from '../../layers/helpers';

export const scalesToResolutions = (map: TMap): DefaultOptionType[] => {
  if (!map?.getView()) {
    return [];
  }

  return CARTOGRAPHIC_SCALES.map((scale) => {
    const value = getScaleResolution(map, scale);

    return {
      label: `M1:${scale}`,
      value,
    };
  });
};
