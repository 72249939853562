interface MainSizeProps {
  baseSize: number;
  baseDiff: number;
  baseMinMaxDiff: number;
  isFirst: boolean;
  isLast: boolean;
  x: number;
}

const getMainSizes = ({
  baseDiff,
  baseSize,
  baseMinMaxDiff,
  isFirst,
  isLast,
  x,
}: MainSizeProps) => {
  const size = isFirst || isLast ? baseSize / 2 : baseSize;
  const diff = isFirst || isLast ? -baseDiff / 2 : baseDiff;

  const baseProps = {
    size,
    diff,
  };

  if (isFirst) {
    return {
      ...baseProps,
      xMinMax: x,
      xMain: x - 2,
    };
  }

  const xMinMax = x - baseMinMaxDiff;
  const xMain = x - baseDiff;

  if (isLast) {
    return {
      ...baseProps,
      xMinMax,
      xMain: xMain + 2,
    };
  }

  return {
    ...baseProps,
    xMinMax,
    xMain,
  };
};

export default getMainSizes;
