import { isInteger } from 'lodash';

import { IData } from './getAverage/getAverage';

export type TArr = SN[];

export const getArrSumInfo = (arr: TArr) => {
  const numberArr = arr.filter((val) => !isNaN(Number(val))) as SN[];

  const predictSum = numberArr.reduce(
    (acc: number, value) => acc + Number(value),
    0
  );

  return {
    sum: isInteger(predictSum) ? predictSum : Number(predictSum.toFixed(2)),
    len: numberArr.length,
  };
};

export const getComparableValue = (dataArr: IData) => {
  const { sum, len } = getArrSumInfo(Object.values(dataArr));

  const comparableValue = Math.ceil(sum / len);

  return comparableValue;
};
