import { QueryKey } from '@tanstack/react-query';
import { useEffect } from 'react';

import queryClient from '../components/ReactQuery/queryClient';

interface RemoveQueriesArgs {
  queryKey: QueryKey;
  isRemove?: boolean;
  isUnmountCleaning?: boolean;
}

const useRemoveQueries = ({
  queryKey,
  isRemove = true,
  isUnmountCleaning = true,
}: RemoveQueriesArgs) => {
  useEffect(() => {
    isRemove && queryClient.removeQueries({ queryKey });
  }, [queryKey, isRemove]);

  useEffect(() => {
    return () => {
      isUnmountCleaning && queryClient.removeQueries({ queryKey });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useRemoveQueries;
