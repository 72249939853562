import { Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../../../../stores/rootStore/rootStore';
import CameraInfo from '../../../../../../InfoPanel/SystemsInfo/CameraInfo/CameraInfo';
import Popover from '../../../../../../ui-kit/Popover/Popover';

import useLinkedVideoCamera from './hooks/useLinkedVideoCamera';

import styles from './LinkedVideoCamera.module.scss';

interface LinkedVideoCameraProps {
  cameraIds: number[];
  currentCamId: N<number>;
}

const LinkedVideoCamera: FC<LinkedVideoCameraProps> = ({
  cameraIds,
  currentCamId,
}) => {
  const { interfaceTipsDelay } = rootStore.uiStore;

  const { cameras, selectedCamId, handleChangeSelect } = useLinkedVideoCamera(
    cameraIds,
    currentCamId
  );

  const popoverProps = {
    tipsDelay: interfaceTipsDelay,
    placement: 'left',
    content: 'Выбрать камеру из списка',
  } as const;

  return (
    <div className={styles.container}>
      {cameraIds.length > 1 && (
        <Popover {...popoverProps}>
          <Select
            className={styles.select}
            placeholder="Выберите камеру"
            value={selectedCamId}
            options={cameras}
            size="small"
            onChange={handleChangeSelect}
            title=""
          />
        </Popover>
      )}

      <CameraInfo id={selectedCamId} />
    </div>
  );
};

export default observer(LinkedVideoCamera);
