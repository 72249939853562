import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';

import rootStore from '../../../../../../../../stores/rootStore/rootStore';
import { System } from '../../../../../../../../ts/enums/enums';
import getDtProfiles from '../../../../../../../Overlays/PhaseCircleOverlay/LinkedDevices/helpers/getDtProfiles';
import { HandleClickDevice } from '../../models/crossroadSchema.model';

import LinkedDetector from './LinkedDetector/LinkedDetector';
import TLanes from './TLanes/TLanes';

interface LinkedDetectorsProps {
  linkedDts: number[];
  rate: number;
  activeId: number;
  isSchemaODD: boolean;
  handleClickDevice?: HandleClickDevice;
}

const LinkedDetectors: FC<LinkedDetectorsProps> = ({
  linkedDts,
  rate,
  activeId,
  isSchemaODD,
  handleClickDevice,
}) => {
  const { detectors, cameras } = rootStore.mapDataStore;

  const lanes = useMemo(
    () => getDtProfiles(linkedDts, detectors, cameras),
    [linkedDts, detectors, cameras]
  );

  return (
    <>
      {lanes.map((lane) => {
        const isActive = activeId === lane.id;

        lane.isEnabled = true;

        const onClick = () => handleClickDevice?.(lane.id, System.Detectors);

        return (
          <div key={`dt_${lane.id}`}>
            <TLanes
              trafficLane={lane}
              isTLCrossroad={isSchemaODD}
              rate={rate}
              isActive={isActive}
              onClick={onClick}
            />
            <LinkedDetector
              lane={lane}
              rate={rate}
              id={activeId}
              onClick={onClick}
            />
          </div>
        );
      })}
    </>
  );
};

export default observer(LinkedDetectors);
