import { ColorPicker, InputNumber, Select } from 'antd';
import { Color } from 'antd/lib/color-picker';
import { Stroke } from 'ol/style';
import { useEffect, useState } from 'react';

import styles from '../../LayersButton.module.scss';

import {
  MAX_STROKE_WIDTH,
  MIN_STROKE_WIDTH,
  STEP_STROKE_WIDTH,
} from './constants';
import { getColor, getDashes, getDashValue } from './helpers';

const StrokeSelector = (props: {
  stroke?: Stroke | null | undefined;
  onChange: (stroke?: Stroke | undefined) => void;
}) => {
  const [color, setColor] = useState(getColor(props.stroke));
  const [width, setWidth] = useState(props.stroke?.getWidth());
  const [lineDash, setLineDash] = useState(
    props.stroke?.getLineDash() || undefined
  );
  const [dashOptions] = useState(getDashes(props.stroke));

  useEffect(() => {
    props.onChange(
      new Stroke({
        color,
        width,
        lineDash,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color, width, lineDash]);

  const updateColor = (value: Color, hex: string) => {
    setColor(hex);
  };

  const updateWidth = (value: number | null) => {
    value !== null && setWidth(value);
  };

  const updateLineDash = (value: string | null | undefined) => {
    const element = getDashes(props.stroke).find(
      (item) => item.value === value
    );

    if (!element) {
      return;
    }

    if (element.dash) {
      setLineDash(element.dash);
    } else {
      setLineDash(undefined);
    }
  };

  return (
    <>
      <div className={styles.rowLine}>
        <div className={styles.blockContainer}>
          <div className={styles.inputTitle}>Цвет контура</div>
          <ColorPicker value={color} onChange={updateColor} />
        </div>
        <div className={styles.blockContainer}>
          <div className={styles.inputTitle}>Толщина линии</div>
          <InputNumber
            defaultValue={1}
            value={width}
            min={MIN_STROKE_WIDTH}
            max={MAX_STROKE_WIDTH}
            step={STEP_STROKE_WIDTH}
            onChange={updateWidth}
          />
        </div>
        <div className={styles.blockContainer}>
          <div className={styles.inputTitle}>Шаблон</div>
          <Select
            value={getDashValue(props.stroke)}
            onChange={updateLineDash}
            options={dashOptions}
            style={{ width: 180 }}
            allowClear
          />
        </div>
      </div>
    </>
  );
};

export default StrokeSelector;
