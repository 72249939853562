import { COLORS } from '../../../../../../constants/colorsConstants';

const getBGColor = (percent: number) => {
  if (percent < 30) return COLORS.GREEN_DARK;
  if (percent < 60) return COLORS.YELLOW;
  if (percent < 90) return COLORS.DARK_ORANGE;

  return COLORS.ERROR;
};

const getFontColor = (percent: number) =>
  percent < 90 && percent >= 30 ? COLORS.BLACK100 : COLORS.WHITY;

const getStatusStyle = (percent: number) => ({
  backgroundColor: getBGColor(percent),
  color: getFontColor(percent),
});

export default getStatusStyle;
