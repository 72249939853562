import { Dayjs } from 'dayjs';
import { useCallback, useEffect, useState } from 'react';

import {
  IGetTimeWithZoneProps,
  getTimeWithTimeZone,
} from '../components/TrafficLightDetailed/TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeWithTimeZone';
import { SECOND } from '../constants/constants';

interface Time {
  timeDJS: N<Dayjs>;
  timeStr: N<string>;
}

const useTime = ({
  timeZone,
  dateFormat,
  dateJS,
  isUTCZero,
}: IGetTimeWithZoneProps) => {
  const [time, setTime] = useState<Time>({
    timeDJS: null,
    timeStr: null,
  });

  const tick = useCallback(() => {
    const date = getTimeWithTimeZone({
      timeZone,
      dateFormat,
      dateJS,
      isUTCZero,
    });

    setTime({ timeDJS: date.dateFormattedJS, timeStr: date.dateFormatted });
  }, [timeZone, dateFormat, dateJS, isUTCZero]);

  useEffect(() => {
    let timeId: U<NodeJS.Timer> = undefined;

    tick();
    timeId = setInterval(() => tick(), SECOND);

    return () => clearInterval(timeId);
  }, [tick]);

  return time;
};

export default useTime;
