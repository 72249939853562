import { Descriptions } from 'antd';
import { observer } from 'mobx-react-lite';

import DefaultChecked from '../../../../../ui-kit/DefaultChecked/DefaultChecked';

import getGeneralItems from './helpers/getGeneralItems';

import styles from '../styles/BasicContent.module.scss';

const GeneralInfo = () => {
  const items = getGeneralItems();

  return (
    <div className={styles.container}>
      <DefaultChecked
        isEmptyData={!items.length}
        noDataProps={{ isNeedBackground: false }}
      >
        <Descriptions layout="vertical" items={items} />
      </DefaultChecked>
    </div>
  );
};

export default observer(GeneralInfo);
