import type { SelectProps } from 'antd/lib';

import { IProcessedDataInitial } from '../../../../../ts/models/signalPrograms.model';

export const getLabelsSelect = (programs: IProcessedDataInitial[]) => {
  return programs
    .reduce(
      (
        acc: NonNullable<SelectProps['options']>,
        { execTime, phaseGroupNum }
      ) => {
        if (execTime && phaseGroupNum) {
          acc.push({ label: execTime ?? '', value: phaseGroupNum.toString() });
        }

        return acc;
      },
      []
    )
    .sort((a, b) => a.phaseGroupNum - b.phaseGroupNum);
};
