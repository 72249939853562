import { Checkbox, ColorPicker, InputNumber, Select } from 'antd';
import { Color } from 'antd/lib/color-picker';
import { useEffect, useState } from 'react';

import {
  hasLabels,
  setTextStyle,
} from '../../../../../apiGIS/styles/text/text';
import { TMap } from '../../../../../stores/mapStore/mapStore.model';
import {
  LABELS_SETTINGS_DEFAULT_FONT_FAMILY,
  LABELS_SETTINGS_DEFAULT_FONT_SIZE,
  LABELS_SETTINGS_FONT_FAMILIES,
  LABELS_SETTINGS_FONT_SIZES,
  SETTINGS_LABELS_HINT,
} from '../../constants';
import { getLayerLabelingSettings } from '../../helpers';
import { ILabelingSettings, ILayerDataNode } from '../../layers.model';
import styles from '../../LayersButton.module.scss';

import {
  MAX_LABEL_STROKE_WIDTH,
  MIN_LABEL_STROKE_WIDTH,
  STEP_LABEL_STROKE_WIDTH,
} from './constants';

const LayerSettingsLabeling = (props: {
  map: TMap;
  selectedNode: ILayerDataNode;
}) => {
  const { map, selectedNode } = props;

  const id = selectedNode.item?.id;

  const [showLabels, setShowLabels] = useState(hasLabels(map, id));
  const [labelSettings, setLabelSettings] = useState<ILabelingSettings>(
    getLayerLabelingSettings(map, selectedNode.item)
  );

  const [fontSize, setFontSize] = useState(labelSettings.fontSize);
  const [fontFamily, setFontFamily] = useState(labelSettings.fontFamily);
  const [labelProperty, setLabelProperty] = useState(
    labelSettings.labelProperty
  );
  const [strokeWidth, setStrokeWidth] = useState(labelSettings.strokeWidth);
  const [strokeColor, setStrokeColor] = useState(labelSettings.strokeColor);
  const [fillColor, setFillColor] = useState(labelSettings.fillColor);

  const changeLabeling = () => {
    const hasLabels = !showLabels;

    setTextStyle(map, selectedNode?.item, hasLabels, labelSettings);

    setShowLabels(!showLabels);
  };

  const updateLabelingSettings = (value: ILabelingSettings) => {
    setTextStyle(map, selectedNode?.item, showLabels, value);

    setLabelSettings(value);
  };

  useEffect(() => {
    const result: ILabelingSettings = {
      fontSize,
      fontFamily,
      labelProperty,
      strokeColor,
      strokeWidth,
      fillColor,
      fields: labelSettings.fields,
    };

    updateLabelingSettings(result);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fontSize,
    fontFamily,
    labelProperty,
    strokeWidth,
    strokeColor,
    fillColor,
  ]);

  const updateFontSize = (value: number) => {
    setFontSize(value);
  };

  const updateFontFamily = (value: string) => {
    setFontFamily(value);
  };

  const updateProperties = (value: string) => {
    setLabelProperty(value);
  };

  const updateFillColor = (value: Color, hex: string) => {
    setFillColor(hex);
  };

  const updateStrokeColor = (value: Color, hex: string) => {
    setStrokeColor(hex);
  };

  const updateStrokeWidth = (value: number | null) => {
    value !== null && setStrokeWidth(value);
  };

  return (
    <>
      <div>
        <div className={styles.labelsOptionsContainer}>
          <Checkbox checked={showLabels} onChange={changeLabeling} />
          <div className={styles.optionTitle}>Показывать подписи слоя</div>
        </div>
        <div className={styles.settingsHint}>{SETTINGS_LABELS_HINT}</div>
        {showLabels ? (
          <div className={styles.labelsSettingsContainer}>
            <div className={styles.inputTitle}>Размер шрифта</div>
            <Select
              defaultValue={LABELS_SETTINGS_DEFAULT_FONT_SIZE}
              className={styles.settingsInput}
              value={fontSize}
              options={LABELS_SETTINGS_FONT_SIZES}
              onChange={updateFontSize}
            />

            <div className={styles.inputTitle}>Шрифт</div>
            <Select
              defaultValue={LABELS_SETTINGS_DEFAULT_FONT_FAMILY}
              className={styles.settingsInput}
              value={fontFamily}
              options={LABELS_SETTINGS_FONT_FAMILIES}
              onChange={updateFontFamily}
            />

            <div className={styles.inputTitle}>Свойство для подписи</div>
            <Select
              className={styles.settingsInput}
              value={labelProperty}
              options={labelSettings.fields}
              allowClear
              onChange={updateProperties}
            ></Select>

            <div className={styles.inputGroup}>
              <div>
                <div className={styles.inputTitle}>Цвет текста</div>
                <ColorPicker value={fillColor} onChange={updateFillColor} />
              </div>

              <div>
                <div className={styles.inputTitle}>Цвет обводки</div>
                <ColorPicker value={strokeColor} onChange={updateStrokeColor} />
              </div>

              <div>
                <div className={styles.inputTitle}>Толщина обводки</div>
                <InputNumber
                  min={MIN_LABEL_STROKE_WIDTH}
                  max={MAX_LABEL_STROKE_WIDTH}
                  step={STEP_LABEL_STROKE_WIDTH}
                  value={strokeWidth}
                  onChange={updateStrokeWidth}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default LayerSettingsLabeling;
