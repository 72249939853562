import { IPhasesData } from '../../../../PhaseHold/PhaseHold.model';
import { validateTableItems } from '../../helpers/validateTableItems';

export const recordDataOnTable = (
  setDataTable: SetState<N<IPhasesData[]>>,
  newRows: IPhasesData,
  durationUpdateTable: number,
  setIsValidateTable: SetState<boolean>,
  durationMinArray: number[]
) =>
  setTimeout(() => {
    setDataTable((prev: N<IPhasesData[]>) => {
      if (!prev) return prev;

      const editData = prev.map((item) => {
        if (item.id === newRows.id) {
          return newRows;
        }

        return item;
      });

      const isValidate = validateTableItems(editData, durationMinArray);

      setIsValidateTable(isValidate);

      return editData;
    });
  }, durationUpdateTable);
