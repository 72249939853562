import { uniqueId } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../../../../../stores/rootStore/rootStore';
import { TrafficLane } from '../../../../../../../../ts/models/constructor.model';
import TLanesDirectionsDetailed from '../../../../../../../Overlays/TLanesDirections/TLanesDirectionsDetailed';
import { FuncClickLane } from '../../../../../../../Overlays/TrafficLanesBlock/TLanesBlock.model';
import {
  FilterValues,
  FilteredTypes,
} from '../../../../../constants/constants';

import LinkedDetectors from './LinkedDetectors/LinkedDetectors';

const { Lanes, Directions } = FilteredTypes;

interface LinkedDevicesProps {
  rate: number;
  trafficLane: U<TrafficLane>;
  linkedDts: number[];
}

const LinkedDevices: FC<LinkedDevicesProps> = ({
  rate,
  trafficLane,
  linkedDts,
}) => {
  const { filterDtData, setFilteredDtData } = rootStore.detailedStore;

  const handleClickLane: FuncClickLane = (laneId, dirKey) => {
    if (laneId === 'all')
      return setFilteredDtData({
        filteredType: Directions,
        value: FilterValues[dirKey],
      });

    setFilteredDtData({
      filteredType: Lanes,
      value: laneId,
      parentFilter: Directions,
      parentType: FilterValues[dirKey],
    });
  };

  return (
    <>
      {trafficLane && (
        <TLanesDirectionsDetailed
          key={uniqueId(`trafficLane_`)}
          trafficLane={trafficLane}
          handleClickLane={handleClickLane}
          activeDirs={filterDtData.directions}
          rate={rate}
        />
      )}
      <LinkedDetectors linkedDts={linkedDts} rate={rate} />
    </>
  );
};

export default observer(LinkedDevices);
