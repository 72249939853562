import dayjs from 'dayjs';
import { isUndefined } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { FiInfo } from 'react-icons/fi';

import { SECOND } from '../../../../../constants/constants';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { TMeteoData } from '../../../../../ts/models/MapObjects/meteo.model';
import {
  MeteoInfoNonNullable,
  TValueMeteo,
} from '../../../../Mapper/TooltipContent/MapIconContent/MeteoInfo/helpers/getMeteoInfo';
import { getTimeWithTimeZone } from '../../../../TrafficLightDetailed/TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeWithTimeZone';
import { getTimeZones } from '../../../../TrafficLightDetailed/TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeZone';
import Popover from '../../../../ui-kit/Popover/Popover';
import MeteoContainer from '../MeteoContainer/MeteoContainer';

import MeteoMainInfo from './MeteoMainInfo/MeteoMainInfo';

import styles from './MeteoCard.module.scss';

interface IMeteoCardProps {
  meteoInfo: MeteoInfoNonNullable;
  timestamp: TMeteoData['timestamp'];
}

const MeteoCard: FC<IMeteoCardProps> = ({ meteoInfo, timestamp }) => {
  const { interfaceTipsDelay } = rootStore.uiStore;
  const [timeUpdate, setTimeUpdate] = useState<string>();

  const popoverProps = {
    tipsDelay: interfaceTipsDelay,
  };

  const {
    humidity,
    precipitation,
    pressure,
    dewPoint,
    envTemp,
    weather,
    windSpeed,
    windDir,
  } = meteoInfo;

  const meteoInfoValues = Object.values({
    humidity,
    precipitation,
    pressure,
    dewPoint,
  }).filter((el) => !isUndefined(el)) as NonNullable<TValueMeteo>[];

  useEffect(() => {
    if (!Number.isFinite(timestamp) || !timestamp) return;

    const updateDate = dayjs(timestamp * SECOND);

    const isDif = updateDate.diff(dayjs()) > 0;

    const { timeZoneRegion } = getTimeZones();
    const { dateFormatted } = getTimeWithTimeZone({
      timeZone: timeZoneRegion,
      dateJS: isDif ? dayjs() : updateDate,
    });

    setTimeUpdate(dateFormatted);
  }, [timestamp]);

  const content = `Время обновления данных метеостанции: ${
    timeUpdate ?? 'нет данных'
  }`;

  return (
    <div className={styles.container}>
      <span className={styles.updateInfo}>
        <Popover {...popoverProps} content={content}>
          <FiInfo className={styles.iconInfo} size={20} />
        </Popover>
      </span>
      <MeteoMainInfo
        temp={envTemp}
        weather={weather}
        compassInfo={{ windSpeed: windSpeed?.value, windDir: windDir?.value }}
      />
      <MeteoContainer meteoInfo={meteoInfoValues} />
    </div>
  );
};

export default observer(MeteoCard);
