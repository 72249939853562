import { PHASE_DIR_TYPES } from '../../../../constants/signalsConstants';

const { Y } = PHASE_DIR_TYPES;

const parseChannel = (chSet = '') =>
  chSet
    .split('')
    .map((el, i) => {
      return el === Y ? i + 1 : null;
    })
    .filter((e) => e);

const parseChannels = (chSets = []) => {
  const res: any = chSets.map((set) =>
    parseChannel((set as any).replace(' ', ''))
  );
  const flattenedRes: number[] = res
    .flat()
    .filter((e: any, i: any, self: any) => e && self.indexOf(e) === i);

  return `${flattenedRes}`;
};

export const getChDirSource = (dirs: any[] = []): any =>
  dirs.map((dir: any, i: any) => ({
    key: i,
    direction: dir?.dir_num,
    channels: parseChannels([
      dir?.red,
      dir?.end,
      dir?.green,
      dir?.begin,
    ] as any),
    lenBegin: dir?.begin_len,
    lenEnd: dir?.end_len,
    flashCount: dir?.flash_count,
  }));
