import { DATE_FORMAT_TO_SHOW } from '../../../../../../../constants/constants';
import roundToNearestMinutes from '../../../../../../../helpers/dateHelpers/roundToNearestMinutes';
import { getTimeWithTimeZone } from '../../../../../../TrafficLightDetailed/TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeWithTimeZone';
import { IDateInfo } from '../../../../../../TrafficLightDetailed/TlDetectors/model/graph.model';
import { DEFAULT_RANGE } from '../../../../CandleChart/constants/constants';
import getPredictKeyToSec from '../../../../CandleChart/helpers/getPredictKeyToSec';

interface HandleSelectedPeriodProps extends IDateInfo {
  isCandleChart: boolean;
}

const getDateByInterval = (radioValueDate: string) => {
  const intervalSec = getPredictKeyToSec(radioValueDate);

  const dateNow = getTimeWithTimeZone().dateFormattedJS;

  const leftDate = dateNow.subtract(DEFAULT_RANGE, 'second');
  const rightDate = dateNow.add(intervalSec, 'second');

  return [leftDate, rightDate]
    .map((date) => roundToNearestMinutes(date).format(DATE_FORMAT_TO_SHOW))
    .join(' - ');
};

const getSelectedPeriod = ({
  isCandleChart,
  selectedDate,
  isNeedUtcFormatted,
  radioValueDate,
}: HandleSelectedPeriodProps) => {
  if (isCandleChart) return getDateByInterval(radioValueDate);

  return selectedDate
    .map((date) =>
      isNeedUtcFormatted
        ? getTimeWithTimeZone({ dateJS: date, dateFormat: DATE_FORMAT_TO_SHOW })
            .dateFormatted
        : date.format(DATE_FORMAT_TO_SHOW)
    )
    .join(' - ');
};

export default getSelectedPeriod;
