import classNames from 'classnames';
import { isNumber } from 'lodash';

import { IDataGraph } from '../../PopupDetector.model';

import styles from './Label.module.scss';

const OFFSET = 10;

interface LabelProps {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  value?: SN;
  index?: number;
  data: IDataGraph[];
  activeIndex: number;
}

const Label = ({
  x,
  y,
  width,
  value,
  data,
  index,
  activeIndex,
}: LabelProps) => {
  const formattedX = isNumber(x) && isNumber(width) ? x + width / 2 : 0;
  const formattedY = isNumber(y) ? y - OFFSET : 0;
  const activeStatus = data[index ?? 0].status;
  const isActive = activeIndex === index;

  const textStyle = classNames({
    [styles.text]: true,
    [styles.active]: isActive,
  });

  return (
    <g className={textStyle} fill={activeStatus}>
      <text
        x={formattedX}
        y={formattedY}
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {value}
      </text>
    </g>
  );
};

export default Label;
