import { uniqueId } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';

import xeomaApi from '../../../api/xeoma/xeoma';
import rootStore from '../../../stores/rootStore/rootStore';
import { getAuthKey } from '../helpers/getAuthKey';
import WebRTCPlayer from '../WebRTCPlayer';

import styles from './CamerasList.module.scss';

const CamerasList = () => {
  const { isUpdateXeoma, setKeyValue } = rootStore.videoWallPanelStore;
  const [list, setList] = useState<any[]>([]);

  const getAuthXeoma = useCallback(async () => {
    setKeyValue('isUpdateXeoma', false);

    const sessionKey = await xeomaApi.createAuthentication(getAuthKey());

    if (!sessionKey) return;

    const camerasList = await xeomaApi.getCameras(sessionKey);

    if (!camerasList) return;

    setList(camerasList);
  }, [setKeyValue]);

  useEffect(() => {
    getAuthXeoma();
  }, [getAuthXeoma]);

  useEffect(() => {
    isUpdateXeoma && getAuthXeoma();
  }, [getAuthXeoma, isUpdateXeoma]);

  if (!list.length) return <p>Нет доступных камер</p>;

  return (
    <div className={styles.wrapper}>
      {list.map((item, i) => {
        if (!item['mainPageStreamHref']) return null;

        return (
          <WebRTCPlayer
            key={uniqueId(`#${i}`)}
            streamCameraHref={item['mainPageStreamHref'].substr(1)}
          />
        );
      })}
    </div>
  );
};

export default observer(CamerasList);
