import { LaneDirectionKey } from '../../../components/Constructor/TrafficLaneConstructor/TLaneConstructor.model';
import { LANE_DIRECTION_DEFAULT } from '../../../constants/constructorConstants';
import { findById } from '../../../helpers/findBy';
import rootStore from '../../rootStore/rootStore';

const getLaneDirection = (id: SN, directionKey: LaneDirectionKey) => {
  const { trafficLanes } = rootStore.constructorStore;

  const trafficLane = findById(trafficLanes, id);

  if (!trafficLane) return { direction: null };
  let direction = trafficLane[directionKey];

  if (!direction) {
    direction = LANE_DIRECTION_DEFAULT;
  }

  return { direction };
};

export default getLaneDirection;
