import { observer } from 'mobx-react-lite';

import rootStore from '../../../../stores/rootStore/rootStore';
import { System } from '../../../../ts/enums/enums';
import NoData from '../../../ui-kit/Errors/NoData/NoData';

import useLinkedTL from './hooks/useLinkedTL';

import styles from './GeneralDtInfo.module.scss';

const APP_PATH = '.TrafficLightDetailed.TlDetectors.GeneralDtInfo';

const GeneralDtInfo = () => {
  const { detectors, tls } = rootStore.mapDataStore;
  const { infoData } = rootStore.uiStore;
  const { activeDtId } = rootStore.detailedStore;

  const isTL = infoData?.system === System.Lights;
  const dtId = isTL ? activeDtId : infoData?.id ?? null;

  const { dtInfo } = useLinkedTL({
    detectors,
    dtId,
    tls,
    appPath: APP_PATH,
  });

  if (!infoData) return null;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {dtInfo.length ? (
          dtInfo.map(({ label, value }, i) => {
            const valueCorrection = value || 'Неизвестно';

            return (
              <p key={i}>
                {label}: {valueCorrection}.
              </p>
            );
          })
        ) : (
          <NoData textError="Информация не найдена" isNeedBackground={false} />
        )}
      </div>
    </div>
  );
};

export default observer(GeneralDtInfo);
