import { VERSION } from '../../../constants/versions';
import { getAppPathHeaders } from '../../helpers/getAppPathHeaders';
import http from '../../http';
import tryCatchWrapper from '../../tryCatchWrapper';

const TRADEMARKS = VERSION + '/catalogs/trademarks';
const EXTENDED = TRADEMARKS + '/join-producers';

export const tradeMarks = {
  fetchStandartData: async (appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.get(TRADEMARKS, {
        headers: getAppPathHeaders(appPath),
      });

      return res.data;
    }),
  fetchExtendedData: async (appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.get(EXTENDED, {
        headers: getAppPathHeaders(appPath),
      });

      return res.data;
    }),
};
