import { observer } from 'mobx-react-lite';
import { FC, cloneElement, useEffect, useState } from 'react';

import { TDeviceStatus } from '../../../../../constants/devicesConstants';
import rootStore from '../../../../../stores/rootStore/rootStore';
import Confirm from '../../../../ui-kit/Confirm/Confirm';
import Popover from '../../../../ui-kit/Popover/Popover';

import { getTooltipContent } from './helpers/getTooltipContent';
import useGroupsControl from './hooks/useGroupsControl';

import styles from './ActiveButton.module.scss';

interface ActiveButtonWrapperProps {
  isAccessControl: boolean;
  isActiveBtn: boolean;
  deviceStatusId: TDeviceStatus;
  openTutorialTooltip: U<boolean>;
  onClick: () => void;
  children: JSX.Element;
}

const TITLE_CONFIRM = `Вы уверены, что хотите управлять этим СО?
  СО находится в координации.
  Это может привести к нарушению работы запущенной группы координации`;

const APP_PATH =
  '.TrafficLightDetailed.TLManagement.StatusCard.ActiveButtonWrapper';

const ActiveButtonWrapper: FC<ActiveButtonWrapperProps> = ({
  isAccessControl,
  openTutorialTooltip,
  isActiveBtn,
  deviceStatusId,
  onClick,
  children,
}) => {
  const { interfaceTipsDelay, regionData } = rootStore.uiStore;
  const { categories } = rootStore.channelsDataStore;
  const { isActiveCoordTL, regionTlsIds } = rootStore.scriptsControlStore;

  const [isOpen, setIsOpen] = useState(false);

  const { fetchAllLists } = useGroupsControl({
    categories,
    regionId: regionData?.id ?? 0,
    appPath: APP_PATH,
    regionTlsIds,
  });

  useEffect(() => {
    fetchAllLists({ isNeedLoading: false, isNeedNotifyError: false });
  }, [fetchAllLists]);

  const tooltipBtnText = getTooltipContent(
    isActiveBtn,
    deviceStatusId,
    isAccessControl
  );

  const isConfirm = isActiveCoordTL && isAccessControl && !isActiveBtn;

  if (isConfirm)
    return (
      <Confirm
        title={TITLE_CONFIRM}
        disabled={!isAccessControl}
        onConfirm={() => {
          setIsOpen(false);
          onClick();
        }}
        okButtonProps={{
          danger: true,
        }}
        okText="Активировать"
        cancelText="Отмена"
        overlayClassName={styles.overlay}
        isOpen={isOpen}
        onCancel={() => setIsOpen(false)}
      >
        <Popover
          tipsDelay={interfaceTipsDelay}
          content={tooltipBtnText}
          open={openTutorialTooltip}
        >
          {cloneElement(children, {
            onClick: () => setIsOpen((prev) => !prev),
          })}
        </Popover>
      </Confirm>
    );

  return (
    <Popover
      placement="right"
      tipsDelay={interfaceTipsDelay}
      content={tooltipBtnText}
      open={isAccessControl ? openTutorialTooltip : undefined}
    >
      {children}
    </Popover>
  );
};

export default observer(ActiveButtonWrapper);
