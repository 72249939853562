import { Spin } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, ReactNode } from 'react';

import rootStore from '../../../../stores/rootStore/rootStore';

import styles from './LaneNumber.module.scss';

interface LaneNumberProps {
  data: ReactNode;
  angle: number;
  isLoading?: boolean;
  isActiveLane?: boolean;
  isGeneral?: boolean;
  isIco?: boolean;
  rate?: number;
}

const LaneNumber: FC<LaneNumberProps> = ({
  data,
  angle,
  isLoading = false,
  isActiveLane = false,
  isGeneral = false,
  isIco = false,
  rate,
}) => {
  const { calcCircleVal } = rootStore.pointsUdsStore;

  const fontSize = isGeneral ? 3.5 : 2;

  const icoWidthStyle = isIco
    ? {
        width: `${calcCircleVal(7, rate)}px`,
        height: `${calcCircleVal(7, rate)}px`,
      }
    : {};

  const laneNumberStyle = {
    fontSize: `${calcCircleVal(fontSize, rate)}px`,
    top: !isGeneral ? calcCircleVal(3, rate) : undefined,
    transform: `translate(-50%, -50%) rotate(${-angle}deg)`,
    ...icoWidthStyle,
  };

  const laneContainerStyle = classNames({
    [styles.laneNumber]: true,
    [styles.numbers]: !isGeneral,
    [styles.active]: isActiveLane,
  });

  return (
    <div className={laneContainerStyle} style={laneNumberStyle}>
      {isLoading ? <Spin size="small" /> : data}
    </div>
  );
};

export default observer(LaneNumber);
