import { FC } from 'react';

import { GRAPHS_TITLE } from '../../../../../TrafficLightDetailed/TlDetectors/constants/constants';
import { RadioValue } from '../../../../../TrafficLightDetailed/TlDetectors/model/graph.model';
import getChartParamName from '../../Chart/helpers/getChartParamName';

import styles from './TitleInfo.module.scss';

interface ITitleInfo {
  dateInterval: string[];
  timeUpdate: U<string>;
  radioValue: RadioValue;
}

const TitleInfo: FC<ITitleInfo> = ({
  dateInterval,
  timeUpdate,
  radioValue,
}) => {
  const graphParam = getChartParamName(radioValue);

  return (
    <div className={styles.title}>
      <h2>
        {graphParam ? GRAPHS_TITLE[graphParam].value : 'Неизвестный параметр'}
      </h2>
      <p>
        Период: {dateInterval[0]} по {dateInterval[1]}
      </p>
      {timeUpdate ? <p>Обновлено: {timeUpdate}</p> : null}
    </div>
  );
};

export default TitleInfo;
