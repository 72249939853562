import Feature from 'ol/Feature';

import { COLORS } from '../../../../../constants/colorsConstants';
import {
  DEVICES_INFO,
  DEVICES_STATE,
  TDeviceStatus,
} from '../../../../../constants/devicesConstants';
import { TL_STATUS_COLORS_HEX } from '../../../../../constants/tlStatusConstants';
import { findBy } from '../../../../../helpers/findBy';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { FeatureDeviceProps } from '../../../../../ts/enums/enums';
import { getDeviceColor } from '../../../../MapBtns/MapInfoWrapper/InfoBtn/ContentInfo/helpers/getMapInfo';
import { STATUS_TLS, STATUS_TLS_CREATE_GROUPS } from '../constants/constants';

export enum FeaturesMode {
  Disabled = 'disabled',
  Status = 'status',
}

type TDataStatus = Partial<Record<TDeviceStatus, number>>;
interface StatusInfo {
  colors: string[];
  statusArr: string[];
}

interface DataReturn extends StatusInfo {
  data: number[];
}

const getFeaturesModeInfo = (features: Feature[]) => {
  const dataInfo: TDataStatus = {};

  for (let i = 0; i < features.length; i++) {
    const deviceSt: TDeviceStatus = features[i].get(
      FeatureDeviceProps.DeviceStatus
    );

    dataInfo[deviceSt] = (dataInfo[deviceSt] ?? 0) + 1;
  }

  return dataInfo;
};

type StatusData = Pick<DataReturn, 'colors' | 'data'>;

type StatusDataReturn<T> = T extends undefined ? DataReturn : StatusData;

const getStatusData = <T extends U<true> = undefined>(
  features: Feature[],
  isMap?: T
): StatusDataReturn<T> => {
  const dataInfo = getFeaturesModeInfo(features);

  const data = Object.values(dataInfo);

  if (isMap) {
    const colors = Object.keys(dataInfo).map((status) =>
      getDeviceColor(Number(status) as TDeviceStatus)
    );

    return { colors, data } as any;
  }

  const { colors, statusArr } = Object.keys(dataInfo).reduce(
    (acc: StatusInfo, status) => {
      const st = Number(status) as TDeviceStatus;
      const color = getDeviceColor(st);
      const statusName =
        findBy(DEVICES_STATE, st, 'com')?.dsc ??
        DEVICES_INFO.STATE.Undefined.dsc;

      acc.colors.push(color);
      acc.statusArr.push(statusName);

      return acc;
    },
    { colors: [], statusArr: [] }
  );

  return { colors, statusArr, data } as any;
};

enum DisabledType {
  disabled = 'disabled',
  active = 'active',
}

const getDisabledData = <T extends U<true> = undefined>(
  features: Feature[],
  isMap?: T
): StatusDataReturn<T> => {
  const { isScriptsCreateDetailed } = rootStore.scriptsControlStore;

  const dataInfo = {
    active: 0,
    disabled: 0,
  };

  features.forEach((feat) => {
    const isDisabled: boolean = feat.get('isDisabled');
    const key = isDisabled ? DisabledType.disabled : DisabledType.active;

    dataInfo[key] += 1;
  });

  const data = Object.values(dataInfo);
  const colors = [COLORS.SUCCESS, TL_STATUS_COLORS_HEX.GREY];

  if (isMap) return { data, colors } as any;

  const statusArr = isScriptsCreateDetailed
    ? STATUS_TLS_CREATE_GROUPS
    : STATUS_TLS;

  return { colors, statusArr, data } as any;
};

type FeaturesModeData<T> = Record<
  FeaturesMode,
  (features: Feature[], isMap?: T) => StatusDataReturn<T>
>;

export const getStatusDataCluster = <T extends U<true> = undefined>(
  features: Feature[],
  featureMode: FeaturesMode,
  isMap?: T
): StatusDataReturn<T> => {
  const featuresModeData: FeaturesModeData<T> = {
    [FeaturesMode.Disabled]: (features: Feature[], isMap) =>
      getDisabledData(features, isMap),
    [FeaturesMode.Status]: (features: Feature[], isMap) =>
      getStatusData(features, isMap),
  };

  return featuresModeData[featureMode](features, isMap);
};
