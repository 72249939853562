import { TlManagementKeys } from '../../../../../constants/commands';
import { REMOTE_CONTROL_NAME } from '../constants/controlConstants';

const ACCESS_CONTENT = 'Данная вкладка работает только в режиме:';

export const getRemoteContent = (controlKey: TlManagementKeys) => {
  const accessCommands = REMOTE_CONTROL_NAME[controlKey];

  if (!accessCommands?.length) return null;

  return `${ACCESS_CONTENT} "${accessCommands.map((el) => `${el} `)}"`;
};
