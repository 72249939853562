import { AVG_KEY_VALUE } from '../constants/constants';
import { DataValue } from '../models/chart.model';

export const getChartNames = (
  data: DataValue[],
  isAvgValue: boolean,
  keyX: string,
  keyY: string
) => {
  const dataNames = Object.keys(data.at(0) ?? [])
    .filter((item) => item !== keyY && item !== keyX)
    .sort((a, b) => {
      if (a === b) return 0;

      return a > b ? 1 : -1;
    });

  isAvgValue && dataNames.length >= 2 && dataNames.unshift(AVG_KEY_VALUE);

  return dataNames;
};
