import rootStore from '../../../../../stores/rootStore/rootStore';

const getDtBottomRate = () => {
  const { currentZoom } = rootStore.mapDataStore;

  if (currentZoom < 17.5) return 7;
  if (currentZoom < 18.5) return 8;

  return 10;
};

export default getDtBottomRate;
