import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../../stores/rootStore/rootStore';
import Detailed, { DetailedType } from '../../../../ui-kit/Detailed/Detailed';
import RequestComponent from '../RequestComponent/RequestComponent';

const TestingDetailed: FC = () => {
  const { setIsNot, setKeyValue } = rootStore.uiStore;
  const { pickedRequest } = rootStore.testingPanelStore;

  const onTestingClose = () => {
    setIsNot('isPanel', false);
    setKeyValue('panelType', null);
  };

  const detailedProps: DetailedType = {
    header: 'Интерфейс тестирования',
    onClose: onTestingClose,
  };

  return (
    <div>
      <Detailed {...detailedProps}>
        <RequestComponent request={pickedRequest} />
      </Detailed>
    </div>
  );
};

export default observer(TestingDetailed);
