import { ICamera } from '../../../../ts/models/camera.model';
import { Detector } from '../../../../ts/models/mapObject.model';
import { DEVICE_TYPE, BROKEN_LINKS_NOT_FOUND } from '../constants/constants';
import { LogTableData } from '../PointUdsOverlays.model';

const logBrokenLinks = (cms: ICamera[], dts: Detector[]) => {
  const data: LogTableData[] = [];

  cms.forEach(({ deviceInfo, dai_jdt83 }) => {
    const { deviceId, caption, pointUdsUid, pointUdsName } = deviceInfo;
    const linkedDtId = dai_jdt83?.linkedDetectorId;
    const isValidLink = !linkedDtId || dts.some(({ id }) => id === linkedDtId);

    !isValidLink &&
      data.push({
        deviceId,
        caption,
        type: DEVICE_TYPE[3],
        linkedId: linkedDtId,
        pointUdsName,
        pointUdsUid,
      });
  });

  dts.forEach(({ deviceInfo, dai_jdt83 }) => {
    const { deviceId, caption, pointUdsUid, pointUdsName } = deviceInfo;
    const linkedCmId = dai_jdt83?.linkedCameraId;
    const isValidLink = !linkedCmId || cms.some(({ id }) => id === linkedCmId);

    !isValidLink &&
      data.push({
        deviceId,
        caption,
        type: DEVICE_TYPE[4],
        linkedId: linkedCmId,
        pointUdsName,
        pointUdsUid,
      });
  });

  data.length ? console.table(data) : console.log(BROKEN_LINKS_NOT_FOUND);
};

export default logBrokenLinks;
