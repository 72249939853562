import {
  DEVICES_ERRORS,
  DEVICES_INFO,
  DEVICES_STATE,
} from '../../../../../constants/devicesConstants';
import { findBy } from '../../../../../helpers/findBy';
import { TObjMap } from '../../../../../stores/mapDataStore/mapDataStore.model';
import { System } from '../../../../../ts/enums/enums';
import { TL } from '../../../../../ts/models/tl.model';
import { getDeviceColor } from '../../../../MapBtns/MapInfoWrapper/InfoBtn/ContentInfo/helpers/getMapInfo';

interface IGetDeviceTitleProps {
  currentObject: TObjMap;
  id: number;
  notSlice?: boolean;
  maxLength?: number;
}

export const getDeviceTitle = ({
  currentObject,
  id,
  notSlice = false,
  maxLength = 35,
}: IGetDeviceTitleProps) => {
  const { caption } = currentObject.deviceInfo;

  const title = `${caption} - id: ${id}`;

  return title.length > maxLength && !notSlice
    ? title.slice(0, maxLength) + '...'
    : title;
};

export const getLinkedsInfo = (currentObject: TObjMap, system: System) => {
  const deviceStatus = currentObject.deviceInfo.deviceStatusId;

  const isTl = system === System.Lights;

  const mode = isTl ? (currentObject as TL).tlStatus?.mode : undefined;

  const statusText =
    findBy(DEVICES_STATE, deviceStatus, 'com')?.dsc ??
    DEVICES_INFO.STATE.Undefined.com;

  const res = {
    mode,
    deviceStatus,
    isError: DEVICES_ERRORS.includes(deviceStatus),
    statusColor: getDeviceColor(deviceStatus),
    errorText: statusText,
  };

  return res;
};
