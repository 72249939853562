import { Card } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useState } from 'react';
import { FaDirections } from 'react-icons/fa';

import { clearDirections } from '../../../apiGIS/tasks/direction';
import { MAP_BUTTONS_NAMES } from '../../../constants/uiComponentConstants';
import rootStore from '../../../stores/rootStore/rootStore';
import { MapButtonsCode } from '../../../ts/enums/userData';
import ButtonIco from '../../ui-kit/ButtonIco/ButtonIco';

import RoutePanel from './components/RoutePanel';
import { ROUTE_CONTENT } from './constants';

import styles from './RouteButton.module.scss';

export const LOCAL_STYLES = {
  panelBodyStyle: {
    margin: 0,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
  },
  borderRadius: 6,
  emptyCard: { width: 96, height: 96 },
};

const RouteButton: FC = () => {
  const { uiStore } = rootStore;
  const { mapProxy } = rootStore.mapDataStore;

  const { activeMapButton, mapButtonsTipsDelay, setKeyValue, isBasemapBtnGis } =
    uiStore;

  const { hasAccess } = rootStore.userDataStore;
  const [isShowPanel, setShowPanel] = useState(false);

  const togglePanel = () => {
    setShowPanel(!isShowPanel);
  };

  useEffect(() => {
    if (activeMapButton !== MAP_BUTTONS_NAMES.ROUTE) {
      setShowPanel(false);
    }
  }, [activeMapButton]);

  useEffect(() => {
    if (isShowPanel) {
      setKeyValue('activeMapButton', MAP_BUTTONS_NAMES.ROUTE);
    } else {
      clearDirections(mapProxy);
    }
  }, [isShowPanel, setKeyValue, mapProxy]);

  const popOverProps = {
    tipsDelay: mapButtonsTipsDelay,
  } as const;

  const isAccess = hasAccess(MapButtonsCode.BasemapsButton);

  if (!isAccess || !isBasemapBtnGis) return null;

  return (
    <>
      <ButtonIco
        isActive={isShowPanel}
        onClick={() => {
          togglePanel();
        }}
        popoverProps={{ ...popOverProps, content: ROUTE_CONTENT.content }}
      >
        <FaDirections />
      </ButtonIco>

      {isShowPanel ? (
        <>
          <Card
            bordered={false}
            className={styles.routePanel}
            styles={{ body: LOCAL_STYLES.panelBodyStyle }}
          >
            <div className={styles.searchPanelTitle}>
              {ROUTE_CONTENT.content}
            </div>
            <RoutePanel />
          </Card>
        </>
      ) : null}
    </>
  );
};

export default observer(RouteButton);
