import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import MonitoringWidget from '../MonitoringWidget/MonitoringWidget';

import useAdaptersCard from './hooks/useAdaptersCard';

interface AdaptersCardProps {
  title: string;
}

const AdaptersCard: FC<AdaptersCardProps> = ({ title }) => {
  const { dataPieChart, isError, isLoading } = useAdaptersCard();

  return (
    <MonitoringWidget
      isEmptyData={isError || !dataPieChart}
      dataPieChart={dataPieChart}
      title={title}
      isLoading={isLoading}
      footerTitle={'Кол-во адаптеров'}
    />
  );
};

export default observer(AdaptersCard);
