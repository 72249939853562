import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../../../stores/rootStore/rootStore';
import Popover from '../../../../../ui-kit/Popover/Popover';

import styles from './EditPlanButton.module.scss';

interface EditPlanButtonProps {
  isDisabled: boolean;
  isEdit: boolean;
  isError: boolean;
  handleClickEdit: () => void;
}

const EditPlanButton: FC<EditPlanButtonProps> = ({
  isDisabled,
  isEdit,
  isError,
  handleClickEdit,
}) => {
  const { interfaceTipsDelay } = rootStore.uiStore;

  const contentTooltip = isEdit
    ? 'Редактировать выбранный план?'
    : 'Необходимо выбрать план';

  const popoverProps = {
    content: isError
      ? 'Недостаточно данных о выбранном плане для редактирования'
      : contentTooltip,
    placement: 'right',
    tipsDelay: interfaceTipsDelay,
  } as const;

  return (
    <Popover {...popoverProps}>
      <Button
        type="primary"
        onClick={handleClickEdit}
        disabled={isDisabled}
        className={styles.btn}
      >
        Редактировать
      </Button>
    </Popover>
  );
};

export default observer(EditPlanButton);
