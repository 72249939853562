import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';

import {
  ICreateInfo,
  coordinationControlApi,
} from '../../../../../api/integration/itslab/coordination/coordination';
import rootStore from '../../../../../stores/rootStore/rootStore';
import Confirm from '../../../../ui-kit/Confirm/Confirm';
import { CONFIRM_CREATE_TITLE } from '../../constants/constants';

const isNotEmptyArray = (array: string[]): array is UnEmptyArray<string> =>
  !!array.length;

const CreateGroupBtn = () => {
  const { selectedIds, setSelectedFeatureSomeArray } =
    rootStore.scriptsControlStore;
  const [isLoading, setIsLoading] = useState(false);

  const isDisabled = selectedIds.length < 2;

  const handleCreateGroup = useCallback(async () => {
    if (selectedIds.length < 2) return;

    const [deviceIdNumber, ...followedDevicesIdsNumbers] = selectedIds;

    const deviceId = deviceIdNumber.toString();
    const followedDevicesIds = followedDevicesIdsNumbers.map((el) =>
      el.toString()
    );

    if (!isNotEmptyArray(followedDevicesIds)) return;

    const createInfo: ICreateInfo = {
      deviceId,
      followedDevicesIds,
    };

    setIsLoading(true);

    const res = await coordinationControlApi.createGroup(
      createInfo,
      'coordControlDetailed'
    );

    setIsLoading(false);

    if (!res) return;

    setSelectedFeatureSomeArray(null);
  }, [selectedIds, setSelectedFeatureSomeArray]);

  return (
    <Confirm
      onConfirm={handleCreateGroup}
      disabled={isDisabled}
      title={CONFIRM_CREATE_TITLE}
      okButtonProps={{ loading: isLoading }}
    >
      <Button type="primary" disabled={isDisabled}>
        Создать
      </Button>
    </Confirm>
  );
};

export default observer(CreateGroupBtn);
