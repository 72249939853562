import { Skeleton } from 'antd';
import classNames from 'classnames';
import React, { ReactNode } from 'react';

import styles from './PreLoader.module.scss';

interface PreLoaderProps {
  loading?: boolean;
  loadersNum?: number;
  children?: ReactNode;
}

const PreLoader: React.FC<PreLoaderProps> = ({
  loading,
  loadersNum = 1,
  children,
}) => (
  <>
    {loading &&
      [...(Array(loadersNum).keys() as any)].map((el) => (
        <Skeleton key={`key${el}`} loading={loading} active></Skeleton>
      ))}
    <div
      className={classNames({
        [styles.optionalHidden]: loading,
      })}
    >
      {children}
    </div>
  </>
);

export default PreLoader;
