import { IGroupsInfo, TCoordGroupZod } from '../model/coordControl.model';

export const getFormattedGroupInfo = (
  groupInfo: TCoordGroupZod[],
  regionTlsIds: number[]
) =>
  groupInfo.reduce((acc: IGroupsInfo[], el) => {
    const tlsGroupIds = [...el.followed_objects, el.main_object].map((el) =>
      Number(el)
    ) as UnEmptyArray<number>;

    const isGroupInvalid = tlsGroupIds.some((id) => !regionTlsIds.includes(id));

    if (!isGroupInvalid) {
      acc.push({
        groupUid: el.group_uuid,
        isStarted: el.started,
        tlsIds: tlsGroupIds,
        endTime: el.end_time,
        startTime: el.start_time,
      });
    }

    return acc;
  }, []);
