import basemapsApi from '../../api/integration/gis/basemaps/index';

const basemapsService = {
  async getList(appPath: string, regionId: number) {
    const data = await basemapsApi.getList(appPath, regionId);

    const hasData = Boolean(data?.length);

    if (hasData) {
      return { data: data, hasIntegrationGIS: true };
    }

    return { data: [], hasIntegrationGIS: false };
  },
};

export default basemapsService;
