import { CSSProperties } from 'react';

import { TCWrapperParams } from '../../../../api/tryCatchWrapper';

export const PANEL_WIDTH = 500;
export const PANEL_SIDE_PADDING = 20;
export const DISPLAY_RATIO = 16 / 9;
export const DISPLAY_WIDTH = PANEL_WIDTH - PANEL_SIDE_PADDING * 2;

export const SIZE = {
  width: DISPLAY_WIDTH,
  height: DISPLAY_WIDTH / DISPLAY_RATIO,
};

export const DETECTOR_TITLE_STYLE: CSSProperties = {
  textAlign: 'left',
  fontSize: '1.7rem',
  paddingLeft: '5px',
};

export const IMG_WRAPPER_STYLE = {
  marginBottom: '2rem',
};

export const PREVIEW_ERROR_DSC: TCWrapperParams = {
  isBlockedNotify: true,
};

export const DETECTOR_IMG_PATH =
  'components.Constructor.DetailedConstructor.DetectorsDetailed.DetectorImg';
