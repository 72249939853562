import { uniqueId } from 'lodash';
import { FC } from 'react';

import {
  DEFAULT_TACT_TYPE,
  TACT_TYPES_INFO,
} from '../../../constants/signalsConstants';
import { findBy } from '../../../helpers/findBy';
import { ITimePoints } from '../../../ts/models/signalPrograms.model';
import { getPhases } from '../../SignalProgramsBand/helpers/getPhases';
import { getFontSize } from '../helpers/getFontSize';

import styles from './Phases.module.scss';

interface IPhases {
  phasesSet: ITimePoints[];
  width: number;
  len: number;
}
const TEXT = 'Ф.';
const PX = 'px';
const FZ = '1rem';
const FZ_RATIO = 1.1;
const MAX_BLOCK_WIDTH = 1000;

const Phases: FC<IPhases> = ({ phasesSet, width, len }) => {
  if (!width) return null;
  const phasesTimes = getPhases(phasesSet);

  return (
    <div className={styles.wrapper}>
      {phasesTimes.map((phase) => {
        const tactTypeObj =
          findBy(TACT_TYPES_INFO, phase.tactType ?? DEFAULT_TACT_TYPE, 'code')
            ?.dsc ?? '';

        const tactTypeText = tactTypeObj ? `- ${tactTypeObj}` : '';

        const phaseText = `${TEXT}${phase.phase} ${tactTypeText}`;

        return (
          <div
            className={styles.phase}
            key={uniqueId('"')}
            style={{ width: (width / len) * phase.length + PX }}
          >
            <p
              className={styles.text}
              style={{
                fontSize:
                  width > MAX_BLOCK_WIDTH
                    ? FZ
                    : getFontSize(width, FZ_RATIO) + PX,
              }}
            >
              {phaseText}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default Phases;
