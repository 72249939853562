import { uniqueId } from 'lodash';
import type { Map } from 'ol';
import type { FeatureLike } from 'ol/Feature';
import RenderFeature from 'ol/render/Feature';

import { CLUSTERS } from '../../../constants/mapConstants';
import rootStore from '../../../stores/rootStore/rootStore';

import { handlePointer, TGetTooltip } from './handlers';

interface ISetTooltipOnClusterProps {
  map: Map;
  feature: FeatureLike;
  isSameCluster: boolean;
  getTooltip: TGetTooltip;
}

export const setTooltipOnCluster = ({
  map,
  feature,
  isSameCluster,
  getTooltip,
}: ISetTooltipOnClusterProps) => {
  const { setKeyValue } = rootStore.uiStore;
  const features = feature.get('features') ?? [feature];

  const geom = feature.getGeometry() as RenderFeature;

  if (!geom) return false;

  if (isSameCluster) {
    setKeyValue('isTooltip', false);
    handlePointer(map, { isPoint: false });

    return false;
  }

  const coord = geom.getFlatCoordinates();

  getTooltip(Number(uniqueId()), CLUSTERS, coord, features);
  setKeyValue('isTooltip', true);

  return false;
};
