import type { Color as DeckColor } from '@deck.gl/core';

import {
  BASE_COLOR_VALUE,
  COEFFICIENT_ALPHA,
  DEFAULT_SCENE_COLOR,
  MAX_ALPHA,
  SCENE_WIDTH_COEFFICIENT,
} from './constants';

export interface ISceneSymbolDefinition {
  line?: boolean;
  fill?: boolean;
  image?: boolean;
  text?: boolean;

  lineColor?: N<DeckColor>;
  lineWidth?: N<number>;

  fillColor?: N<DeckColor>;

  imageData?: N<string>;
  imageWidth?: N<number>;
  imageHeight?: N<number>;
}

export const getRandomStrokeColor: () => DeckColor = () => {
  const value = Math.round(Math.random() * BASE_COLOR_VALUE + BASE_COLOR_VALUE);

  return [value, value, value, MAX_ALPHA];
};

export const getRandomFillColor: () => DeckColor = () => {
  const value =
    BASE_COLOR_VALUE + Math.round(Math.random() * (BASE_COLOR_VALUE / 4));

  return [value, 0, 0, MAX_ALPHA];
};

const normalizeColor = (color: DeckColor | string): DeckColor => {
  if (!color) {
    return DEFAULT_SCENE_COLOR;
  }

  if (Array.isArray(color)) {
    const count = color.length;

    if (count === 4) {
      const [r, g, b, alpha] = color;

      if (alpha > 1 && alpha < 255) {
        return [r, g, b, alpha];
      }

      if (alpha === 255) {
        return [r, g, b];
      }

      if (alpha <= 1) {
        color[3] = Math.round(alpha * COEFFICIENT_ALPHA);

        if (color[3] === 255) {
          return [r, g, b];
        }
      }
    }

    return color as DeckColor;
  }

  return DEFAULT_SCENE_COLOR;
};

export const symbolToStyle = (
  rendererSymbol: any
): ISceneSymbolDefinition | undefined => {
  const symbolType = rendererSymbol.type;

  if (symbolType === 'esriSLS') {
    const { color, width } = rendererSymbol;

    return {
      line: true,
      fill: false,
      image: false,
      fillColor: null,
      lineColor: normalizeColor(color),
      lineWidth: width * SCENE_WIDTH_COEFFICIENT,
    };
  }

  if (symbolType === 'esriSFS') {
    const { color, outline } = rendererSymbol;

    return {
      line: true,
      fill: true,
      image: false,
      fillColor: normalizeColor(color),
      lineColor: normalizeColor(outline.color),
      lineWidth: outline.width * SCENE_WIDTH_COEFFICIENT,
    };
  }

  if (symbolType === 'esriPMS') {
    const { imageData, width, height } = rendererSymbol;

    return {
      line: false,
      fill: false,
      image: true,
      fillColor: null,
      lineColor: null,
      lineWidth: null,
      imageData: 'data:image/png;base64,' + imageData,
      imageWidth: width,
      imageHeight: height,
    };
  }
};
