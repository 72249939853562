import { MdOutlineFolder, MdOutlineFolderOpen } from 'react-icons/md';

import { LOCAL_STYLES } from '../constants';

export function LayerGroupFolderIcon(props: { expanded: boolean }) {
  return (
    <>
      {props.expanded ? (
        <MdOutlineFolderOpen
          size={LOCAL_STYLES.GROUP_FOLDER_ICON.size}
          style={{ marginTop: LOCAL_STYLES.GROUP_FOLDER_ICON.marginTop }}
        />
      ) : (
        <MdOutlineFolder
          size={LOCAL_STYLES.GROUP_FOLDER_ICON.size}
          style={{ marginTop: LOCAL_STYLES.GROUP_FOLDER_ICON.marginTop }}
        />
      )}
    </>
  );
}
