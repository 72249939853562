import { makeAutoObservable } from 'mobx';

import { IRequestItem } from '../../components/Panel/TestPanel/ts/TestingPanel.model';
import { PanelType } from '../../ts/enums/enums';
import RootStore from '../rootStore/rootStore';

class TestingPanelStore {
  rootStore;
  pickedRequest: N<IRequestItem> = null;

  constructor(rootStore: typeof RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
    this.rootStore = rootStore;
  }

  setPickedRequest = (request: IRequestItem) => {
    this.pickedRequest = request;
  };

  get isTestingPanel() {
    const { panelType } = this.rootStore.uiStore;

    return panelType === PanelType.Testing;
  }
}

export default TestingPanelStore;
