import { useEffect, useState } from 'react';

import api from '../../../../../api';
import { IProcessedDataInitial } from '../../../../../ts/models/signalPrograms.model';
import getCsdData from '../../../../NewSignalProgram/helpers/getCsdData';
import { getCorrectPrograms } from '../../helpers/getCorrectPrograms';
import { SgnProgramValues } from '../models/plansControl.model';

const { getProgramsData } = api.mapObjects.trafficLights.info.signalProgramsApi;

const useTLPrograms = (id: number) => {
  const [programs, setPrograms] = useState<IProcessedDataInitial[]>([]);
  const [isPlansLoading, setIsPlansLoading] = useState(true);
  const [sgnProgramValues, setSgnProgramValues] = useState<SgnProgramValues[]>(
    []
  );

  useEffect(() => {
    (async () => {
      setIsPlansLoading(true);
      setSgnProgramValues([]);

      const programs = await getProgramsData(id);

      setIsPlansLoading(false);

      setPrograms(getCorrectPrograms(programs ?? []));
    })();
  }, [id]);

  useEffect(() => {
    programs.forEach((program) => {
      if (!program.tlSignalProgram2 || !program.tlTacts) return;
      const res = getCsdData({
        data: program,
        isStatic: true,
      });

      setSgnProgramValues((programValues) => [
        ...programValues,
        {
          phasesSet: res?.timePoints ?? [],
          csdData: res?.csdData ?? null,
          isError: !res,
        },
      ]);
    });
  }, [programs]);

  return { isPlansLoading, sgnProgramValues, programs };
};

export default useTLPrograms;
