import { Card, Button } from 'antd';
import { isNumber } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';

import NOTICE from '../../../../constants/notificationConstants';
import { getTLModeName } from '../../../../helpers/statusHelpers';
import { checkDisabledControl } from '../../../../stores/mapDataStore/helpers/checkDisabledControl';
import rootStore from '../../../../stores/rootStore/rootStore';
import { TL } from '../../../../ts/models/tl.model';
import { getTLWarnings } from '../../../Panel/ScriptsControl/SelectModule/DispatchGroupControl/DipatchGroupDetailed/ControlCard/helpers/helpers';
import { StatusInfoBtn } from '../../../ui-kit/InfoBtn/constants/constants';
import InfoBtn from '../../../ui-kit/InfoBtn/InfoBtn';
import { getTimeWithTimeZone } from '../../TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeWithTimeZone';
import useTimerPlannedComand from '../hooks/useTimerPlannedComand';

import ActiveButtonWrapper from './ActiveButtonWrapper/ActiveButtonWrapper';
import Timers from './Timers/Timers';
import WarningNotify from './WarningNotify/WarningNotify';

import styles from './StatusCard.module.scss';

export interface IStatusCardProps {
  activeTL: TL;
  isOpenTutorialTooltip: U<boolean>;
  setIsOpenTutorialTooltip: SetState<U<boolean>>;
}

const StatusCard: FC<IStatusCardProps> = ({
  activeTL,
  isOpenTutorialTooltip,
  setIsOpenTutorialTooltip,
}) => {
  const { isActiveControlBtn, setIsNotDetailed } = rootStore.detailedStore;
  const { isActiveCoordTL } = rootStore.scriptsControlStore;

  const { timerInfo, isError } = useTimerPlannedComand(activeTL.id);

  const [updateTime, setUpdateTime] = useState(
    getTimeWithTimeZone().dateFormatted
  );

  const { deviceStatusId, controlAccess } = activeTL.deviceInfo;

  useEffect(() => {
    const timeRcvNormalize = getTimeWithTimeZone().dateFormatted;

    setUpdateTime(timeRcvNormalize);
  }, [activeTL.tlStatus.mode]);

  const onClick = () => {
    setIsOpenTutorialTooltip(undefined);
    setIsNotDetailed('isActiveControlBtn');
  };

  const isSpecialMode = checkDisabledControl(deviceStatusId);
  const { warningText, isAccessMode } = getTLWarnings(controlAccess);

  const actionText = !isActiveControlBtn ? 'Активировать' : 'Отключить';
  const isDisabledBtn = !isAccessMode || isSpecialMode;

  const mode = activeTL.tlStatus.mode;
  const name = isNumber(mode) ? getTLModeName(mode) : null;

  return (
    <Card className={styles.statusCard}>
      <InfoBtn
        isShow={isError}
        status={StatusInfoBtn.Error}
        popoverContent={NOTICE.PLANNED_CMD_ERROR}
      />
      <div className={styles.columnBlocks}>
        <h4>Текущий режим: {name ?? 'Неизвестен'}</h4>
        <h4>Получено: {updateTime}</h4>
        <div className={styles.block}>
          <WarningNotify
            isActiveNotify={!isAccessMode}
            warningTitle={warningText}
          />
          <WarningNotify isActiveNotify={isActiveCoordTL} />
        </div>
        <Timers timerInfo={timerInfo} />
      </div>
      <ActiveButtonWrapper
        openTutorialTooltip={isOpenTutorialTooltip}
        isAccessControl={isAccessMode}
        deviceStatusId={deviceStatusId}
        isActiveBtn={isActiveControlBtn}
        onClick={onClick}
      >
        <Button
          type="primary"
          onClick={onClick}
          disabled={isDisabledBtn}
          className={styles.activateBtn}
        >
          {actionText}
        </Button>
      </ActiveButtonWrapper>
    </Card>
  );
};

export default observer(StatusCard);
