import { ButtonProps } from 'antd/lib';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { COLORS } from '../../../../../../../../../constants/colorsConstants';
import WarningNotify from '../../../../../../../../TrafficLightDetailed/TlManagement/StatusCard/WarningNotify/WarningNotify';
import { getTLControlInfo } from '../../../ControlCard/helpers/helpers';

import styles from './Footer.module.scss';

const TL_NOT_CONTROL_NOTIFY =
  'По некоторым СО не будет отправлена команда (доступен только просмотр)';

const TL_COORD_CONTROL =
  'Некоторые СО находятся в режиме координированного управления';
const TL_COORD = 'Это может привести к нарушению работы других СО';

interface FooterProps {
  OkBtn: FC<ButtonProps>;
  CancelBtn: FC<ButtonProps>;
  tlIds: number[];
  isSomeTLCoordControl: boolean;
}

const Footer: FC<FooterProps> = ({
  CancelBtn,
  OkBtn,
  tlIds,
  isSomeTLCoordControl,
}) => {
  const isSomeTLNotControl = tlIds.some(
    (id) => !getTLControlInfo(id)?.isAccessMode
  );

  return (
    <div className={styles.container}>
      <div className={styles.warnings}>
        <WarningNotify
          isActiveNotify={isSomeTLNotControl}
          warningTitle={TL_NOT_CONTROL_NOTIFY}
        />
        <WarningNotify
          isActiveNotify={isSomeTLCoordControl}
          warningTitle={TL_COORD_CONTROL}
        />
        <WarningNotify
          isActiveNotify={isSomeTLCoordControl}
          warningTitle={TL_COORD}
        />
      </div>
      <div className={styles.btn}>
        <CancelBtn />
        <OkBtn
          color={COLORS.WARNING}
          style={{ backgroundColor: COLORS.WARNING }}
        />
      </div>
    </div>
  );
};

export default observer(Footer);
