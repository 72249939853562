import { useEffect } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';

const useFetchRegionData = () => {
  const { regionData } = rootStore.uiStore;
  const { isAuth } = rootStore.userDataStore;
  const { fetchDataByRegion } = rootStore.mapDataStore;

  useEffect(() => {
    isAuth && regionData && fetchDataByRegion();
  }, [fetchDataByRegion, regionData, isAuth]);
};

export default useFetchRegionData;
