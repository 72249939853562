import { observer } from 'mobx-react-lite';
import type { Overlay } from 'ol';
import { FC, useCallback } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { TMap } from '../../../../../../../stores/mapStore/mapStore.model';
import rootStore from '../../../../../../../stores/rootStore/rootStore';
import { System } from '../../../../../../../ts/enums/enums';
import PhaseCircleNoData from '../../../../../../Overlays/PhaseCircleNoData/PhaseCircleNoData';
import CrossroadSchema, {
  CrossroadSchemaProps,
} from '../../../../../../TrafficLightDetailed/TlDetectors/GeneralDtTabs/CrossroadSchemaWrapper/CrossroadSchema/CrossroadSchema';
import useCrossroadUds from '../../../../../../TrafficLightDetailed/TlDetectors/GeneralDtTabs/CrossroadSchemaWrapper/CrossroadSchemaUds/hooks/useCrossroadUds';
import { ALL_SYSTEM_TITLE } from '../../../../../constants/constants';
import { HandleClickDevice } from '../models/crossroadSchema.model';

import LinkedDevicesUds from './LinkedDevicesUds/LinkedDevicesUds';

interface CrossroadUdsProps {
  map: TMap;
  id: number;
  crossroadOverlay: N<Overlay>;
  system: System;
}

const CrossroadUds: FC<CrossroadUdsProps> = ({
  crossroadOverlay,
  id,
  map,
  system,
}) => {
  const {
    mapPropsDetailed,
    setMonitoringValue,
    activeSystemMonitoring,
    setActiveSystemMonitoring,
  } = rootStore.monitoringPanelStore;

  const { crossroadBasicProps, pointUdsInfo } = useCrossroadUds({
    id,
    mapPropsDetailed,
    system,
  });

  const handleClickDevice = useCallback<HandleClickDevice>(
    (clickedId, clickedSystem) => {
      if (id === clickedId) return;

      const isNotSameSystem =
        activeSystemMonitoring !== clickedSystem &&
        activeSystemMonitoring !== ALL_SYSTEM_TITLE;

      isNotSameSystem && setActiveSystemMonitoring(clickedSystem, false);
      setMonitoringValue('deviceInfo', {
        id: clickedId,
        system: clickedSystem,
      });
    },
    [activeSystemMonitoring, id, setActiveSystemMonitoring, setMonitoringValue]
  );

  if (!crossroadBasicProps) return null;

  const crossroadSchemaProps: CrossroadSchemaProps = {
    ...crossroadBasicProps,
    crossroadOverlay,
    map,
  };

  return (
    <ErrorBoundary
      fallback={<PhaseCircleNoData />}
      resetKeys={[pointUdsInfo?.pointUds]}
    >
      <CrossroadSchema {...crossroadSchemaProps} />
      <LinkedDevicesUds
        rate={crossroadBasicProps.rate}
        id={id}
        pointUds={pointUdsInfo?.pointUds}
        handleClickDevice={handleClickDevice}
      />
    </ErrorBoundary>
  );
};

export default observer(CrossroadUds);
