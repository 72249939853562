import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';

import { findById } from '../../../../../../../../helpers/findBy';
import rootStore from '../../../../../../../../stores/rootStore/rootStore';
import { System } from '../../../../../../../../ts/enums/enums';
import { MeteoDaiJdt83 } from '../../../../../../../../ts/models/MapObjects/meteo.model';
import MeteoIcon from '../../../../../../../Overlays/MeteoIcon/MeteoIcon';
import { HandleClickDevice } from '../../models/crossroadSchema.model';

interface LinkedMeteoProps {
  mtIds: N<number[]>;
  rate: number;
  activeId: number;
  handleClickDevice?: HandleClickDevice;
}

const LinkedMeteo: FC<LinkedMeteoProps> = ({
  mtIds,
  rate,
  activeId,
  handleClickDevice,
}) => {
  const { meteo } = rootStore.mapDataStore;

  const meteos = useMemo(
    () =>
      mtIds?.reduce((res: MeteoDaiJdt83[], dtId) => {
        const mt = findById(meteo, dtId);
        const final = mt?.dai_jdt83;

        final && res.push(final);

        return res;
      }, []),
    [mtIds, meteo]
  );

  if (!meteos?.length) return null;

  return (
    <>
      {meteos.map((meteo) => {
        const { id } = meteo;

        const onClick = () => handleClickDevice?.(id, System.Meteo);

        return (
          <MeteoIcon
            {...meteo}
            key={id}
            isShowIdUnder
            rate={rate}
            activeId={activeId}
            onClick={onClick}
          />
        );
      })}
    </>
  );
};

export default observer(LinkedMeteo);
