import { getPointCenter } from '../../../../helpers/findPointUds';
import { TL } from '../../../../ts/models/tl.model';

const formatTls = (tls: TL[]) =>
  tls.map((tl) => {
    const { deviceId, longitude, latitude, pointUdsUid } = tl.deviceInfo;

    const coord = getPointCenter(pointUdsUid);

    return {
      id: deviceId,
      longitude: coord[0] ?? longitude,
      latitude: coord[1] ?? latitude,
    };
  });

export default formatTls;
