import { isNumber } from 'lodash';

import { MODES_INFO } from '../constants/commands';
import { TlMode } from '../ts/models/tl.model';

import { findBy } from './findBy';

const UNKNOWN_MODE = 'Режим неизвестен';

export const getTLModeName = (mode: N<TlMode>) => {
  if (isNumber(mode))
    return findBy(Object.values(MODES_INFO), mode, 'com')?.dsc ?? UNKNOWN_MODE;

  return UNKNOWN_MODE;
};
