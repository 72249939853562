import { COMMANDS } from '../../../../../constants/commands';

export interface TitleValues {
  title: string;
  titleHold: string;
  paragraph: string;
  paragraphHold: string;
}

type Titles = Record<COMMANDS, N<TitleValues>>;

export const COMMAND_TITLES: Titles = {
  SET_OBJECT_PHASE: {
    title: 'До удержания фазы',
    titleHold: 'Удержание фазы',
    paragraph: 'Включение фазы начнётся',
    paragraphHold: 'Фаза будет удержана до',
  },
  SET_OBJECT_PLAN_TIME_ON_SCHEDULER: {
    title: 'До старта временного плана',
    titleHold: 'Временный план',
    paragraph: 'Временный план будет применен',
    paragraphHold: 'Временный план запущен до',
  },
  SET_OBJECT_SYNC_PLAN: {
    title: 'До старта измененного плана',
    titleHold: 'Измененный план',
    paragraph: 'Измененный план будет применен',
    paragraphHold: 'Измененный план запущен до',
  },
  SET_OBJECT_MODE: null,
  SET_OBJECT_PHASE_FLOW: null,
  SET_OBJECT_PHASE_NOW: null,
  SET_OBJECT_PLAN: null,
};
