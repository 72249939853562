import { Feature } from 'ol';
import { Point } from 'ol/geom';

import { findById } from '../../../helpers/findBy';
import rootStore from '../../../stores/rootStore/rootStore';
import { getDeviceInfo } from '../../Map/helpers/getDeviceInfo';
import { IFeaturesArr } from '../../Map/helpers/getFeaturesCluster';

const checkIsUnlinked = (feature: Feature<Point>) => {
  const { systemsInfo } = rootStore.mapDataStore;

  const { system, id } = getDeviceInfo(feature);
  const { data } = systemsInfo[system];
  const el = findById(data, id);

  return !el?.deviceInfo.pointUdsUid;
};

export const getUnlinkedFeatures = (arr: Feature<Point>[]) =>
  arr.filter((f) => checkIsUnlinked(f));

export const getUnlinkedPopup = (arr: IFeaturesArr[]) =>
  arr.filter(({ feature: f }) => checkIsUnlinked(f));
