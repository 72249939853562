import type { FeatureLike } from 'ol/Feature';

import { WIDTH } from '../../../../../constants/constants';
import { CLUSTERS_INFO } from '../../../../../constants/mapClusterConstants';
import { getOpenClusterStyle } from '../helpers/clusterStyles';

const { POINT_RADIUS } = CLUSTERS_INFO;
const { PANEL_MAIN, SIDEBAR } = WIDTH;

export const SELECT_INTERACTIONS = {
  pointRadius: POINT_RADIUS,
  featureStyle: (features: FeatureLike) => getOpenClusterStyle({ features }),
  style: null,
  selectCluster: true,
  animate: true,
  animationDuration: CLUSTERS_INFO.ANIMATION_DURATION,
};

export const CIRCLE_CORRECT = 1.3;

export const TRANSPARENT_FEATURE = [0, 0, 0, 0.01];

const CORRECTION_PADDING = 100;

export type PaddingExtent = [number, number, number, number];

export const PADDING_EXTENT: [number, number, number, number] = [
  CORRECTION_PADDING,
  CORRECTION_PADDING * 2,
  CORRECTION_PADDING,
  PANEL_MAIN + SIDEBAR + CORRECTION_PADDING,
];

export const STATUS_TLS_CREATE_GROUPS = ['Доступно', 'Недоступно'];
export const STATUS_TLS = [
  'Находятся в группе КУ',
  'Не находятся ни в одной группе КУ',
];
