import { TL_NAME_STORE } from '../../../constants/mapConstants';
import { TObjMap } from '../../../stores/mapDataStore/mapDataStore.model';
import rootStore from '../../../stores/rootStore/rootStore';
import { MapObjectFull, System } from '../../../ts/enums/enums';
import { TLinkedDevices } from '../../../ts/models/mapObject.model';
import { ClusterLinks } from '../ClusterInfo/model/cluster.model';

export const getFilteredLinkedDevices = (currentObject: TObjMap) => {
  const { getById } = rootStore.mapDataStore;

  if (!currentObject?.linkedDeviceIds) return null;

  const arrLinks = Object.entries(currentObject.linkedDeviceIds) as [
    keyof NonNullable<TLinkedDevices>,
    number | number[] | null
  ][];

  if (!arrLinks.length) return null;

  const filtered = arrLinks.reduce((acc: ClusterLinks, [key, value]) => {
    if (!value || key === MapObjectFull.greenTrafficLights) return acc;

    const systemKey = key === MapObjectFull.trafficLights ? TL_NAME_STORE : key;
    let objKey = System.Lights;

    switch (key) {
      case MapObjectFull.trafficLights:
        objKey = System.Lights;
        break;
      case MapObjectFull.cameras:
        objKey = System.Cameras;
        break;
      case MapObjectFull.detectors:
        objKey = System.Detectors;
        break;
      case MapObjectFull.meteo:
        objKey = System.Meteo;
        break;
      default:
        break;
    }

    const isArray = Array.isArray(value);

    let newLinks: number[] = [];

    if (isArray) {
      newLinks = value.filter((id) => getById(id, systemKey));
    } else {
      const isValidTL = !!getById(value, systemKey);

      newLinks = isValidTL ? [value] : [];
    }

    acc[objKey] = newLinks;

    return acc;
  }, {} as ClusterLinks);

  return filtered;
};
