import { ReactComponent as AccessGo } from '../assets/icons/dirTypes/access_go.svg';
import { ReactComponent as AllRed } from '../assets/icons/dirTypes/all_red.svg';
import { ReactComponent as ArrowRotate } from '../assets/icons/dirTypes/arrow_rotate.svg';
import { ReactComponent as ArrowUp } from '../assets/icons/dirTypes/arrow_up.svg';
import { ReactComponent as ErrorIco } from '../assets/icons/dirTypes/error.svg';
import { ReactComponent as NotAllowedGo } from '../assets/icons/dirTypes/not_allowed_go.svg';
import { ReactComponent as Tram } from '../assets/icons/dirTypes/tram.svg';
import { ActualMapObj, System } from '../ts/enums/enums';
import { DirectionTypes } from '../ts/enums/tl';
import { DirValues } from '../ts/models/signalPrograms.model';

import { COLORS } from './colorsConstants';
import { IStatusSets } from './constants';

const {
  SUCCESS,
  PURPLE,
  GREY_ROAD,
  DANGER,
  DARK_ORANGE,
  WARNING_WHITE,
  GREY500,
} = COLORS;

export const DEVICES_INFO = {
  STATE: {
    Work: {
      com: 1,
      dsc: 'Работает',
      color: SUCCESS,
    },
    NotWork: {
      com: 2,
      dsc: 'Не работает',
      color: GREY500,
    },
    Error: {
      com: 3,
      dsc: 'Ошибка',
      color: DANGER,
    },
    NotConnection: {
      com: 4,
      dsc: 'Нет связи',
      color: DARK_ORANGE,
    },
    Undefined: {
      com: 5,
      dsc: 'Неизвестно',
      color: WARNING_WHITE,
    },
    Delete: {
      com: 6,
      dsc: 'Удалено',
      color: PURPLE,
    },
    NotAvailable: {
      com: 7,
      dsc: 'Недоступен',
      color: GREY_ROAD,
    },
  },
} as const;

export type TDeviceStatus = typeof DEVICES_STATUS[0];

export const DEVICES_STATE = Object.values(DEVICES_INFO.STATE);

export const DEVICES_STATUS = DEVICES_STATE.map((el) => el.com);
export const DEVICES_STATUS_NAMES = DEVICES_STATE.map((el) => el.dsc);

export type TKeysStatus = keyof typeof DEVICES_INFO.STATE;

export type TDeviceInfoStatus = [TKeysStatus, TDeviceStatus][];
type TStateArr = [TKeysStatus, ValueOf<typeof DEVICES_INFO.STATE>][];

export const DEVICES_INFO_STATUS = (
  Object.entries(DEVICES_INFO.STATE) as TStateArr
).reduce((acc: TDeviceInfoStatus, [key, { com }]) => {
  acc.push([key, com]);

  return acc;
}, []);

export enum ZonesTypes {
  Unknown,
  Intensity,
  AverageSpeed,
  Speed,
  Interval,
  ZoneEmployment,
  VirtualLoop,
  AverageEmpPerCycle,
}

export enum LinesTypes {
  Intensity = 1,
  AverageSpeed = 2,
}

enum SystemTypeId {
  DTFactor = 13,
  MvsDcCircon = 14,
}

const { DTFactor, MvsDcCircon } = SystemTypeId;

export const DTS_NO_IMAGE = [DTFactor, MvsDcCircon];

const { Error, NotConnection, NotAvailable } = DEVICES_INFO.STATE;

const ERROR_DEVICE_STATUS = [Error, NotConnection, NotAvailable];

export const DEVICES_ERRORS = ERROR_DEVICE_STATUS.map(({ com }) => com);

export const DEVICES_STATUS_SETS: Record<string, IStatusSets> = {
  ERRORS: {
    mode: DEVICES_ERRORS,
    isAlarm: true,
    color: 'var(--danger)',
  },
};

export const DIR_TYPES_DICTIONARY: Record<DirectionTypes, DirValues> = {
  [DirectionTypes.Transport]: {
    title: 'транспортное',
    iconOn: <ArrowUp />,
    iconOff: null,
  },
  [DirectionTypes.Rotary]: {
    title: 'поворотное',
    iconOn: <ArrowRotate />,
    iconOff: null,
  },
  [DirectionTypes.Pedestrian]: {
    title: 'пешеходное',
    iconOn: <AccessGo />,
    iconOff: <NotAllowedGo />,
  },
  [DirectionTypes.Tramway]: {
    title: 'трамвайное',
    iconOn: <Tram />,
    iconOff: null,
  },
  [DirectionTypes.Special]: {
    title: 'специальное (пост. красное)',
    iconOn: <AllRed />,
    iconOff: null,
  },
  [DirectionTypes.Unknown]: {
    title: 'не определено (другой тип ДК)',
    iconOn: null,
    iconOff: null,
  },
  [DirectionTypes.Errors]: {
    title: 'не определено (ошибка)',
    iconOn: <ErrorIco />,
    iconOff: null,
  },
};

export const DEVICES_STORE_NAMES: Record<System, N<ActualMapObj>> = {
  [System.Lights]: ActualMapObj.tls,
  [System.Detectors]: ActualMapObj.detectors,
  [System.Cameras]: ActualMapObj.cameras,
  [System.Meteo]: ActualMapObj.meteo,
  [System.PublicTransport]: null,
  [System.SpecialTransport]: null,
};
