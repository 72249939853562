import {
  DEFAULT_INDEX,
  HALF_PIE_ANGLE,
  PADDING_ANGLE,
  PIE_ANGLE,
} from '../constants/pieConstants';
import { IPieChartData } from '../models/circleChart.model';

export const getChartProps = (
  data: IPieChartData[],
  isHalfPie: boolean,
  activeIndexValue: number[] | number
) => {
  const isOneRecord = data.length === 1;
  const isNotNullableData = isHalfPie
    ? data.every(({ value }) => value > 0)
    : !isOneRecord;
  const paddingAngle = isNotNullableData ? PADDING_ANGLE : 0;

  if (isHalfPie) {
    return {
      startAngle: HALF_PIE_ANGLE.start,
      endAngle: HALF_PIE_ANGLE.end,
      activeIndex: DEFAULT_INDEX,
      cy: '70%',
      paddingAngle,
    };
  }

  return {
    startAngle: PIE_ANGLE.start,
    endAngle: PIE_ANGLE.end,
    activeIndex: activeIndexValue,
    cy: '50%',
    paddingAngle,
  };
};
