import { VERSION } from '../../constants/versions';
import { TableData } from '../../ts/models/table.model';
import { getAppPathHeaders } from '../helpers/getAppPathHeaders';
import http from '../http';
import tryCatchWrapper from '../tryCatchWrapper';

import { about } from './about/about';
import { groups } from './groups/groups';
import systems from './systems/systems';

const ADMIN = VERSION + '/admin';

const admin = {
  groups,
  systems,
  about,
  getUsersList: (appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.get<TableData>(`${ADMIN}/users`, {
        headers: getAppPathHeaders(appPath),
      });

      return res.data;
    }),
};

export default admin;
