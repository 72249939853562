import { HiArrowLeft } from 'react-icons/hi';

import styles from '../../LayersButton.module.scss';

const ErrorHandler = (
  title: string,
  errorText: string,
  onBack: (evt: any) => void
) => (
  <div>
    <div className={styles.nodeContainer}>
      <HiArrowLeft
        onClick={onBack}
        style={{ marginRight: 5, cursor: 'pointer' }}
      />
      <div>{title}</div>
    </div>
    <div>{errorText}</div>
  </div>
);

export default ErrorHandler;
