import { useEffect, useState } from 'react';

import rootStore from '../../../../../stores/rootStore/rootStore';
import { ITimePoints } from '../../../../../ts/models/signalPrograms.model';
import getCsdData from '../../../../NewSignalProgram/helpers/getCsdData';

const useSgnProgram = (id: number) => {
  const { tls } = rootStore.mapDataStore;

  const [phasesSet, setPhasesSet] = useState<ITimePoints[]>([]);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    setIsError(false);

    const res = getCsdData({
      id,
      isStatic: false,
      tlsData: tls,
    });

    if (!res?.timePoints.length) return setIsError(() => true);

    setPhasesSet(res.timePoints);
  }, [id, tls]);

  return { phasesSet, isError };
};

export default useSgnProgram;
