import { ReactComponent as CoordIcon } from '../../../../../assets/icons/modules/coord_control_icon.svg';
import { ReactComponent as GroupControlIcon } from '../../../../../assets/icons/modules/group_dispatch_control_icon.svg';
import { ManagementModules } from '../../../../../stores/uiStore/uiStore.model';
import { SidebarCode } from '../../../../../ts/enums/userData';

export const MODULES_CARDS = [
  {
    type: ManagementModules.CoordControl,
    icon: <CoordIcon />,
    title: 'Координированное управление',
    description: 'Управление групп КУ',
    code: SidebarCode.CoordControl,
  },
  {
    type: ManagementModules.GroupDispatchManagement,
    icon: <GroupControlIcon />,
    title: 'Групповое диспетчерское управление',
    description: 'Возможность управления несколькими СО',
    code: SidebarCode.GroupDispatchControl,
  },
];
