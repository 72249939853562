import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { VscOrganization } from 'react-icons/vsc';

interface IGetBtnsProps {
  collaboration: any;
  setDivisionsId: SetState<N<number>>;
  setIsOpen: SetState<boolean>;
  handleDelete: (id: number) => void;
  handleEdit: (collaboration: any) => void;
}

export const getOrganizationsBtns = ({
  collaboration,
  setDivisionsId,
  setIsOpen,
  handleDelete,
  handleEdit,
}: IGetBtnsProps) => {
  const actionButtons = [
    {
      isDisabled: false,
      icon: <VscOrganization />,
      tooltip: 'Показать подразделения',
      handler: () => {
        setDivisionsId(collaboration.id);
        setIsOpen(true);
      },
    },
    {
      isDisabled: false,
      icon: <EditOutlined />,
      tooltip: 'Редактировать',
      handler: () => {
        handleEdit(collaboration);
      },
    },
    {
      isDisabled: false,
      icon: <DeleteOutlined />,
      tooltip: 'Удалить',
      placement: 'right' as TooltipPlacement,
      handler: () => {
        return;
      },
      confirmProps: {
        name: collaboration.name,
        entity: 'организацию',
        confirm: async () => handleDelete(collaboration.id),
      },
    },
  ];

  return actionButtons;
};
