import { isEqual } from 'lodash';
import type { Map } from 'ol';
import { toLonLat } from 'ol/proj';
import RenderFeature from 'ol/render/Feature';

import rootStore from '../../../../../stores/rootStore/rootStore';
import {
  IClickedCartographyObj,
  MapActions,
} from '../../../../../stores/uiStore/uiStore.model';
import { getSelectedMultipleCluster } from '../../../../Map/helpers/getSelectedMultipleCluster';
import { DURATION_CORRECTION } from '../../../../Map/helpers/setClustersZoomChange';
import { getFeaturesExtent } from '../../../../Overlays/InfoPopup/helpers/getFeaturesExtent';

import { getClusterWithInfoDataFeature } from './getClusterWithInfoDataFeature';
import { getSelectInteraction } from './getSelectInteraction';
import { setCenteredCluster } from './setCenteredCluster';

interface setCenterObjCartographyProps {
  map: Map;
  clickedCartographyObj: IClickedCartographyObj;
}

export const setCenterObjCartography = ({
  map,
  clickedCartographyObj,
}: setCenterObjCartographyProps) => {
  const { clickedCluster, setSelectedClusters } = rootStore.clustersStore;
  const { setInfoData, setKeyValue } = rootStore.uiStore;
  const { getCoordinatesById } = rootStore.mapDataStore;
  const { isMultipleSelect } = rootStore.scriptsControlStore;
  const { id, system } = clickedCartographyObj;

  const extentInfo = getFeaturesExtent(map);

  if (!extentInfo) return;

  const { extentFeatures } = extentInfo;

  const cluster = getClusterWithInfoDataFeature(extentFeatures, id);
  const select = getSelectInteraction(map);

  if (!cluster || !select) {
    select?.clear();
    const coord = getCoordinatesById(id);

    !isMultipleSelect &&
      id &&
      coord &&
      setInfoData(
        { id, system, coordinate: toLonLat(coord) },
        MapActions.NoActions
      );

    return setSelectedClusters(null);
  }

  const clusterCoordinate = toLonLat(
    (cluster.getGeometry() as U<RenderFeature>)?.getFlatCoordinates() ?? []
  );

  const isClickedOnSameCluster = !!(
    clickedCluster && isEqual(clickedCluster.coordinate, clusterCoordinate)
  );

  if (!isClickedOnSameCluster) {
    select?.clear();
    setSelectedClusters(null);
    setTimeout(() => {
      setSelectedClusters(getSelectedMultipleCluster(cluster));
      select.selectCluster(cluster);
    }, DURATION_CORRECTION);
  }

  if (isMultipleSelect) return;

  setKeyValue('isInfoPopup', false);

  setCenteredCluster(
    map,
    id,
    system,
    cluster.get('features'),
    isClickedOnSameCluster
  );
};
