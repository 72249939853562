import { Icon, RegularShape } from 'ol/style';
import ImageStyle from 'ol/style/Image';
import { useState } from 'react';

import IconSelector from './IconSelector';
import RegularShapeSelector from './RegularShapeSelector';

const IMAGE_TYPES = {
  SHAPE: 'shape',
  ICON: 'icon',
};

const getType = (
  image?: ImageStyle | RegularShape | Icon | undefined | null
) => {
  if (!image) {
    return IMAGE_TYPES.SHAPE;
  }

  if (image instanceof RegularShape) {
    return IMAGE_TYPES.SHAPE;
  }

  return IMAGE_TYPES.SHAPE;
};

const ImageSelector = (props: {
  image?: ImageStyle | RegularShape | Icon | null | undefined;
  onChange: (evt: ImageStyle | RegularShape | Icon | null | undefined) => void;
}) => {
  const [type] = useState(getType(props.image));
  const [image, setImage] = useState(props.image);

  const updateRegularImage = (
    value: RegularShape | ImageStyle | undefined | null
  ) => {
    props.onChange(value);

    setImage(value);
  };

  const updateIcon = (value: Icon | ImageStyle | undefined | null) => {
    props.onChange(value);

    setImage(value);
  };

  const renderSwitch = (type: string) => {
    switch (type) {
      case IMAGE_TYPES.SHAPE:
        return (
          <RegularShapeSelector image={image} onChange={updateRegularImage} />
        );
      case IMAGE_TYPES.ICON:
        return <IconSelector onChange={updateIcon} />;
      default:
        return (
          <RegularShapeSelector image={image} onChange={updateRegularImage} />
        );
    }
  };

  return <>{renderSwitch(type)}</>;
};

export default ImageSelector;
