import { Tabs } from 'antd';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo } from 'react';

import { CONTROL_ACCESS_DEVICES } from '../../constants/constants';
import { findById } from '../../helpers/findBy';
import rootStore from '../../stores/rootStore/rootStore';
import { System } from '../../ts/enums/enums';

import { getItemsDetailed } from './helpers/getItemsDetailed';

import styles from './DetailedSwitcher.module.scss';

const { OnlyRead, ReadAndControl } = CONTROL_ACCESS_DEVICES;

let systemPrev: U<System>;

const DOCS = 'documents';

interface DetailedDataProps {
  system: System;
  id: number;
}

interface DetailedSwitcherProps {
  detailedData: DetailedDataProps;
}

function DetailedSwitcher(props: DetailedSwitcherProps) {
  const { activeKey, setKeyValue } = rootStore.uiStore;
  const { tls } = rootStore.mapDataStore;

  const { detailedData } = props;
  const { system, id } = detailedData;

  const isAccessManagement = useMemo(() => {
    const currentObject = findById(tls, id);

    if (!currentObject) return false;

    const {
      deviceInfo: { controlAccess },
    } = currentObject;

    const isAccess =
      controlAccess === OnlyRead || controlAccess === ReadAndControl;

    return isAccess;
  }, [id, tls]);

  const handleChange = useCallback(
    (key: string) => setKeyValue('activeKey', key),
    [setKeyValue]
  );

  const { items, systemItems } = getItemsDetailed(
    id,
    system,
    isAccessManagement,
    activeKey
  );

  useEffect(() => {
    if (!systemPrev) {
      systemPrev = system;

      return;
    }

    if (system !== systemPrev) {
      const isDocs =
        activeKey === DOCS && systemItems.some(({ key }) => key === DOCS);

      if (isDocs) return;
      const key = systemItems.at(0)?.key;

      key && handleChange(key);
      systemPrev = system;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleChange, system, systemItems]);

  return (
    <Tabs
      className={styles.tabsCustom}
      items={items}
      onChange={handleChange}
      activeKey={activeKey}
    />
  );
}

export default observer(DetailedSwitcher);
