import { observer } from 'mobx-react-lite';
import type { Overlay } from 'ol';
import { FC } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { TMap } from '../../../../../../stores/mapStore/mapStore.model';
import rootStore from '../../../../../../stores/rootStore/rootStore';
import { System } from '../../../../../../ts/enums/enums';
import PhaseCircleNoData from '../../../../../Overlays/PhaseCircleNoData/PhaseCircleNoData';
import CrossroadSchema, {
  CrossroadSchemaProps,
} from '../CrossroadSchema/CrossroadSchema';

import useCrossroadUds from './hooks/useCrossroadUds';
import LinkedDevicesUdsWrapper from './LinkedDevicesUdsWrapper/LinkedDevicesUdsWrapper';

interface CrossroadSchemaUdsProps {
  map: TMap;
  dtId: number;
  crossroadOverlay: N<Overlay>;
}

const CrossroadSchemaUds: FC<CrossroadSchemaUdsProps> = ({
  map,
  dtId,
  crossroadOverlay,
}) => {
  const { mapPropsDetailed } = rootStore.detailedStore;

  const { crossroadBasicProps, pointUdsInfo } = useCrossroadUds({
    id: dtId,
    mapPropsDetailed,
    system: System.Detectors,
  });

  if (!crossroadBasicProps) return null;

  const crossroadSchemaProps: CrossroadSchemaProps = {
    ...crossroadBasicProps,
    crossroadOverlay,
    map,
  };

  return (
    <ErrorBoundary
      fallback={<PhaseCircleNoData />}
      resetKeys={[pointUdsInfo?.pointUds]}
    >
      <CrossroadSchema {...crossroadSchemaProps} />
      <LinkedDevicesUdsWrapper rate={crossroadBasicProps.rate} dtId={dtId} />
    </ErrorBoundary>
  );
};

export default observer(CrossroadSchemaUds);
