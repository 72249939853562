import type { Props } from 'recharts/types/component/Label';

import styles from './LabelGraph.module.scss';

const OFFSET_MAIN = 10;

interface ILabelGraphProps extends Props {
  isX: boolean;
  viewBox?: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
}

const LabelGraph = ({ viewBox, value, isX }: ILabelGraphProps) => {
  if (!viewBox) return null;

  if (isX) {
    return (
      <g>
        <text
          className={styles.labelGraph}
          x={viewBox.width / 2 + OFFSET_MAIN}
          y={viewBox.y + viewBox.height + OFFSET_MAIN * 4}
        >
          {value}
        </text>
      </g>
    );
  }

  return (
    <g>
      <text
        className={styles.labelGraph}
        x={viewBox.x + OFFSET_MAIN * 5}
        y={viewBox.y + OFFSET_MAIN}
        textAnchor={'end'}
      >
        {value}
      </text>
    </g>
  );
};

export default LabelGraph;
