import { isUndefined } from 'lodash';
import { FC } from 'react';

import {
  MeteoInfoNonNullable,
  TValueMeteo,
} from '../../../../Mapper/TooltipContent/MapIconContent/MeteoInfo/helpers/getMeteoInfo';
import MeteoContainer from '../MeteoContainer/MeteoContainer';

import styles from './MeteoRoad.module.scss';

interface IMeteoRoadProps {
  meteoInfo: MeteoInfoNonNullable;
}

const MeteoRoad: FC<IMeteoRoadProps> = ({ meteoInfo }) => {
  const { roadGrip, roadTemp, roadState } = meteoInfo;

  const meteoInfoValues = Object.values({
    roadGrip,
    roadTemp,
    roadState,
  }).filter((el) => !isUndefined(el)) as NonNullable<TValueMeteo>[];

  return (
    <div className={styles.container}>
      <MeteoContainer isNeedBg={false} meteoInfo={meteoInfoValues} isCenter />
    </div>
  );
};

export default MeteoRoad;
