import { useEffect } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import { handleChangeBasemap } from '../helpers/handleBasemaps';

const useBasemap = () => {
  const { map } = rootStore.mapStore;
  const { basemap } = rootStore.gisDataStore;

  useEffect(() => {
    if (map && basemap) {
      handleChangeBasemap(map, basemap);
    }
  }, [map, basemap]);

  return null;
};

export default useBasemap;
