import http from '../../http';
import tryCatchWrapper from '../../tryCatchWrapper';
import { SERVER } from '../constants';

const SESSIONS = SERVER + '/sessions';

const sessions = {
  getListById: (id: number) =>
    tryCatchWrapper(async () => {
      const res = http.get(SESSIONS + `/${id}`);

      return res;
    }),

  deleteSessionBySID: (id: string) =>
    tryCatchWrapper(async () => {
      const res = await http.delete(SESSIONS + `/${id}`);

      return res;
    }),
};

export default sessions;
