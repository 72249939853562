import NoData from '../../ui-kit/Errors/NoData/NoData';

import styles from './IdentificationInfo.module.scss';

const TITLE = 'Объекты ГИС не обнаружены в области клика по кластеру';
const SUBTITLE =
  'Если Вы хотели посмотреть информацию по ГИС объектам, выполните выбор в другом месте карты и, при необходимости, измените масштаб или видимую область карты.';

const EmptyListGISObjects = () => {
  return (
    <>
      <NoData isNeedBackground={false} textError={TITLE} />
      <p className={styles.noDataText}> {SUBTITLE} </p>
    </>
  );
};

export default EmptyListGISObjects;
