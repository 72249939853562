import { notification } from 'antd';

import {
  ReportKey,
  ReportsTreeItem,
} from '../../components/Panel/ReportsPanel/ReportTree/model/reports.model';
import { IS_DEV } from '../../constants/constants';
import NOTICE from '../../constants/notificationConstants';
import { VERSION } from '../../constants/versions';
import { sendError } from '../../helpers/sendError';
import { IQueryParamRequest } from '../../ts/models/table.model';
import { Path } from '../api.model';
import { getAppPathHeaders } from '../helpers/getAppPathHeaders';
import http from '../http';
import tryCatchWrapper from '../tryCatchWrapper';

import { ReportsTreeSchema } from './reports.zod';

const REPORTS = VERSION + '/reports';
const TREE = REPORTS + '/tree';

export const reports = {
  fetchTree: (appPath: string): Promise<U<ReportsTreeItem[]>> =>
    tryCatchWrapper(async () => {
      const res = await http.get(TREE, {
        headers: getAppPathHeaders(appPath),
      });

      const validate = ReportsTreeSchema.array().safeParse(res.data);

      if (!validate.success) {
        console.error('Uncorrect Data Reports', validate.error);
        const { issues } = validate.error;

        notification.error(NOTICE.INCORRECT_REPORTS_TREE);

        !IS_DEV &&
          sendError(
            NOTICE.INCORRECT_REPORTS_TREE.description,
            {
              dsc: `Ошибка при получении отчётов.`,
              issues,
            },
            appPath
          );

        return;
      }

      return res.data as ReportsTreeItem[];
    }),

  fetchReportByKey: (
    reportKey: ReportKey,
    appPath: Path,
    params: ReportsTreeItem['params'],
    queryParamRequest?: IQueryParamRequest,
    signal?: AbortSignal
  ) =>
    tryCatchWrapper(async () => {
      const isQueryParams = Boolean(
        queryParamRequest && Object.keys(queryParamRequest).length
      );
      const res = await http.get(`${REPORTS}/${reportKey}`, {
        headers: getAppPathHeaders(appPath),
        params: {
          params,
          queryParamsRequest: isQueryParams ? queryParamRequest : undefined,
        },
        signal,
      });

      return res.data;
    }),
};
