import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { FC } from 'react';

import styles from './PlusBtn.module.scss';

interface PlusBtnProps {
  isLoading: boolean;
}

const PlusBtn: FC<PlusBtnProps> = ({ isLoading }) => (
  <button className={styles.btn} type="button">
    {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
    <div className={styles.text}>Загрузить</div>
  </button>
);

export default PlusBtn;
