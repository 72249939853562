const getFmtpLineIndex = (sdpLines: any, payloadType: any) => {
  let index = -1;

  for (let i = 0; i < sdpLines.length; ++i) {
    if (sdpLines[i].startsWith('a=fmtp:' + payloadType)) {
      index = i;
      break;
    }
  }

  return index;
};

export const setOpusParams = (sdp: any) => {
  let sdpLines = sdp.split('\n');

  for (let i = 0; i < sdpLines.length; ++i) {
    const rtpMapPos = sdpLines[i].indexOf('a=rtpmap:');
    const opusPos = sdpLines[i].indexOf(' opus');

    if (rtpMapPos >= 0 && opusPos > 0 && opusPos > rtpMapPos) {
      const startPos = rtpMapPos + 'a=rtpmap:'.length;
      const len = opusPos - startPos;
      const payloadType = sdpLines[i].substr(startPos, len);

      const fmtpLineIndex = getFmtpLineIndex(sdpLines, payloadType);

      if (fmtpLineIndex === -1) {
        let newLines = sdpLines.slice(0, i);

        newLines.push('a=fmtp:' + payloadType + ' stereo=1; sprop-stereo=1');
        newLines = newLines.concat(sdpLines.slice(i, sdpLines.length));
        sdpLines = newLines;
      } else {
        sdpLines[fmtpLineIndex].replace('stereo=0', 'stereo=1');
        sdpLines[fmtpLineIndex].replace('sprop-stereo=0', 'sprop-stereo=1');

        if (sdpLines[fmtpLineIndex].indexOf('stereo=1') === -1) {
          if (
            !sdpLines[fmtpLineIndex].endsWith(';') &&
            !sdpLines[fmtpLineIndex].endsWith('a=fmtp:' + payloadType) &&
            !sdpLines[fmtpLineIndex].endsWith('a=fmtp:' + payloadType + ' ')
          ) {
            sdpLines[fmtpLineIndex] += ';';
          }

          sdpLines[fmtpLineIndex] += 'stereo=1';
        }

        if (sdpLines[fmtpLineIndex].indexOf('sprop-stereo=1') === -1) {
          if (!sdpLines[fmtpLineIndex].endsWith(';')) {
            sdpLines[fmtpLineIndex] += ';';
          }

          sdpLines[fmtpLineIndex] += 'sprop-stereo=1';
        }
      }

      break;
    }
  }

  return sdpLines.join('\n');
};
