import { DEVICES_INFO } from '../../../../../constants/devicesConstants';
import { getDeviceColor } from '../../../../MapBtns/MapInfoWrapper/InfoBtn/ContentInfo/helpers/getMapInfo';
import { IPieChartData } from '../../../../ui-kit/CircleChart/models/circleChart.model';
import { getStatusSumAggregate } from '../../WorksWidget/helpers/widgetHelper';
import {
  TGeneralFormattedArr,
  TWidgetsOptionalData,
} from '../models/monitoring.model';

interface IStatusPie {
  data: IPieChartData[];
  colors: string[];
}

export const getGeneralStatusDevices = (widgetsData: TWidgetsOptionalData) => {
  const widgetTotal = getStatusSumAggregate(widgetsData);

  const { ratio, total, disabled, system, ...rest } = widgetTotal;

  const statusInfoArr = Object.entries(rest) as TGeneralFormattedArr;

  const { data, colors } = statusInfoArr.reduce(
    (acc: IStatusPie, [key, value]) => {
      if (!value) return acc;

      const statusInfo = DEVICES_INFO.STATE[key];

      acc.data.push({ name: statusInfo.dsc, value });
      acc.colors.push(getDeviceColor(statusInfo.com));

      return acc;
    },
    { data: [], colors: [] }
  );

  return {
    total,
    colors,
    data,
  };
};
