import { getAppPathHeaders } from '../../../helpers/getAppPathHeaders';
import http from '../../../http';
import tryCatchWrapper, { TCWrapperParams } from '../../../tryCatchWrapper';
import { DT_PREVIEW } from '../data/detectorsData';

export const PREVIEW = '/preview';

const previewApi = {
  getPreviewById: (appPath: string, id: number, tcParams?: TCWrapperParams) =>
    tryCatchWrapper(async () => {
      const res = await http.get(`${DT_PREVIEW}/${id}${PREVIEW}`, {
        headers: getAppPathHeaders(appPath),
        responseType: 'blob',
      });

      return res?.data;
    }, tcParams),

  getPreviewByUid: (appPath: string, systemId: number, uid: number) =>
    tryCatchWrapper(async () => {
      const res = await http.get(DT_PREVIEW + PREVIEW, {
        params: {
          systemId,
          uid,
        },
        headers: getAppPathHeaders(appPath),
        responseType: 'blob',
      });

      return res?.data;
    }),
};

export default previewApi;
