import {
  IRequired,
  IType,
} from '../../../../../../../constants/models/formConstants.model';

export const rulesTableEdit: [IRequired, IType] = [
  {
    required: true,
    message: `Поле является обязательным!`,
  },
  {
    type: 'number',
    message: `Длина может быть только числом!`,
  },
];

export const validateTableSgnProgram = {
  mainTact: 10,
  minFlash: 0,
  colorFlash: 3,
  maxTact: 1000,
};

export const DURATION_CHANGE = {
  SUCCESS: 500,
  ERROR: 3000,
};
