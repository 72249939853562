import { PanelTree } from '../../components/Panel/AdminPanel/helpers/getMenuLabelTooltips';

export const getTreeSorter = <T extends PanelTree>(
  items: T[],
  sortFunc: (prev: T, current: T) => number
): T[] =>
  items
    .map((item) =>
      item.children
        ? { ...item, children: getTreeSorter(item.children as T[], sortFunc) }
        : item
    )
    .sort(sortFunc);
