import { FC } from 'react';

import { DetailedItemProps } from '../../DetailedMain/DetailedWrapper/constants/constants';

import ControlPanel from './ControlPanel/ControlPanel';
import TrafficModelThread from './TrafficModelThread/TrafficModelThread';

import styles from './TLTrafficModel.module.scss';

const TLTrafficModel: FC<DetailedItemProps> = () => {
  return (
    <div className={styles.container}>
      <TrafficModelThread />
      <ControlPanel />
    </div>
  );
};

export default TLTrafficModel;
