import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  COMMANDS,
  TlManagementKeys,
} from '../../../../../../constants/commands';
import eventBus from '../../../../../../eventBus';
import rootStore from '../../../../../../stores/rootStore/rootStore';
import { postCommand } from '../../../helpers/postCommand';
import { IPostCommandProps } from '../../../model/postCommands.model';
import BodyContent from '../../../PhaseHold/ModalConfirm/BodyContent/BodyContent';
import ModalSignalProgram from '../../../PlanSelect/ModalSignalProgram/ModalSignalProgram';
import { getCurrentSgnProgramInfo } from '../../helpers/getCurrentSgnProgramInfo';
import { UseConfirmCurrentPlanProps } from '../../model/currentPlan.model';

const { PhaseTimeChangeCurrentPlanMode } = TlManagementKeys;
const { SET_OBJECT_PHASE_FLOW } = COMMANDS;

const PATH =
  '.TrafficLightDetailed.TlManagement.PhaseTimeChangeCurrentPlanMode.EditCurrentProgram.Confirm.useConfirmCurrentPlan';

const useConfirmCurrentPlan = ({
  id,
  readyDataPhases,
  baseProgramProps,
  clearPlanSelect,
}: UseConfirmCurrentPlanProps) => {
  const { activeControlKey } = rootStore.detailedStore;
  const { tls } = rootStore.mapDataStore;

  const [openModal, setOpenModal] = useState(false);
  const [isLoadingRequest, setIsLoadingRequest] = useState<boolean>(false);

  const { sgnProgramName, tlName } = useMemo(
    () => getCurrentSgnProgramInfo(tls, id),
    [id, tls]
  );

  useEffect(() => {
    const unsubscribe = eventBus.clickedControlBtn.subscribe(() => {
      activeControlKey === PhaseTimeChangeCurrentPlanMode && setOpenModal(true);
    });

    return () => unsubscribe();
  }, [activeControlKey]);

  const onOkConfirm = useCallback(async () => {
    setIsLoadingRequest(true);

    const cmdInfo = `Изменение длительности фаз текущего плана (${sgnProgramName})`;

    const propsPostCommand: IPostCommandProps = {
      id,
      command: null,
      cmdTitle: SET_OBJECT_PHASE_FLOW,
      cmdInfo,
      phasesValue: readyDataPhases,
      disabledTime: 0,
    };

    await postCommand(propsPostCommand, PATH);

    setIsLoadingRequest(false);
    setOpenModal(false);
    clearPlanSelect();
  }, [sgnProgramName, id, readyDataPhases, clearPlanSelect]);

  const modalConfirmProps = {
    openModal: openModal,
    modalTitle: `Отправка команды управления для светофорного объекта ${tlName}`,
    setOpenModal,
    onOkConfirm,
    width: '1400px',
    okButtonProps: { loading: isLoadingRequest },
  };

  const title = (
    <strong>{`Подтверждаете изменение текущего плана? (${sgnProgramName})`}</strong>
  );

  const modalBodyProps = {
    content: <BodyContent title={title} content={[]} />,
    image: <ModalSignalProgram {...baseProgramProps} />,
  };

  return { modalConfirmProps, modalBodyProps };
};

export default useConfirmCurrentPlan;
