import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import DefaultChecked from '../../../ui-kit/DefaultChecked/DefaultChecked';
import SelectPlanNumber from '../components/SelectPlanNumber/SelectPlanNumber';
import { BasicControlTabProps } from '../model/tlManagement.model';
import useTLPrograms from '../PlanSelect/hooks/useTLPrograms';

import EditProgram from './EditProgram/EditProgram';
import useTemporaryPlan from './hooks/useTemporaryPlan';
import TimePickerCard from './TimePickerCard/TimePickerCard';

import styles from './TemporaryPlan.module.scss';

const TemporaryPlan: FC<BasicControlTabProps> = ({ id }) => {
  const programInfo = useTLPrograms(id);

  const {
    selectedPlan,
    selectedTime,
    setSelectedPlan,
    setSelectedTime,
    clearPlan,
    tlInfo,
  } = useTemporaryPlan(id);

  const { sgnProgramName, disabledTime } = tlInfo;

  return (
    <div className={styles.container}>
      <h3>Текущий план: {sgnProgramName}</h3>
      <DefaultChecked
        isLoading={programInfo.isPlansLoading}
        isEmptyData={!programInfo.programs.length}
      >
        <SelectPlanNumber
          id={id}
          programInfo={programInfo}
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
        />
        <TimePickerCard
          disabledTime={disabledTime}
          selectedTimeInfo={selectedTime}
          setSelectedTimeInfo={setSelectedTime}
        />
        <EditProgram
          selectedPlanId={selectedPlan.planId}
          id={id}
          confirmProps={{ selectedTime, programInfo }}
          clearPlan={clearPlan}
        />
      </DefaultChecked>
    </div>
  );
};

export default observer(TemporaryPlan);
