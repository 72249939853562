import rootStore from '../../../../stores/rootStore/rootStore';

const checkPointUdsName = (pointUdsName: string) => {
  const { pointUdsList, pointUdsUid } = rootStore.constructorStore;

  return (
    pointUdsList?.some(
      (item) =>
        pointUdsUid !== item.pointUdsUid && pointUdsName === item.pointUdsName
    ) ?? false
  );
};

export default checkPointUdsName;
