import { Select } from 'antd';
import type { BaseOptionType, DefaultOptionType } from 'antd/es/select';
import { observer } from 'mobx-react-lite';
import { FC, useState, useEffect, useMemo } from 'react';

import rootStore from '../../../../stores/rootStore/rootStore';
import { ICamera } from '../../../../ts/models/camera.model';
import Popover from '../../../ui-kit/Popover/Popover';
import CameraInfo from '../CameraInfo/CameraInfo';

import styles from './CamerasSwitcher.module.scss';

interface ICamerasSwitcher {
  title: string;
  camerasIdList: number[];
}

const CamerasSwitcher: FC<ICamerasSwitcher> = ({ title, camerasIdList }) => {
  const { getById, selectedCamera } = rootStore.mapDataStore;
  const { tipsValues } = rootStore.uiStore;

  const [selectedCamId, setSelectedCamId] = useState<number>(camerasIdList[0]);

  const cameras = useMemo(
    () =>
      camerasIdList.reduce(
        (acc: (BaseOptionType | DefaultOptionType)[], cameraId: number) => {
          const camera: U<ICamera> = getById(cameraId, 'cameras');

          if (!camera) return acc;

          acc.push({
            value: camera.id,
            label: `Камера: ${camera.caption}`,
          });

          return acc;
        },
        []
      ),
    [camerasIdList, getById]
  );

  useEffect(() => setSelectedCamId(camerasIdList[0]), [camerasIdList]);

  useEffect(() => {
    selectedCamera && setSelectedCamId(selectedCamera.id);
  }, [selectedCamera]);

  const popoverProps = {
    tipsDelay: tipsValues.interfaceTipsDelay,
    placement: 'left',
    content: 'Выбрать камеру из списка',
  } as const;

  return (
    <div>
      <h3 className={styles.title}>{title}</h3>
      {camerasIdList.length > 1 && (
        <Popover {...popoverProps}>
          <Select
            className={styles.select}
            placeholder="Выберите камеру"
            value={selectedCamId}
            options={cameras}
            onChange={(value) => setSelectedCamId(value)}
            title=""
          />
        </Popover>
      )}

      <CameraInfo id={selectedCamId} />
    </div>
  );
};

export default observer(CamerasSwitcher);
