import { Spin } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';

import ActiveShape from './ActiveShape/ActiveShape';
import {
  CIRCLE_MARGIN,
  CIRCLE_WEIGHT,
  COLORS_SETS,
  spinProps,
} from './constants/pieConstants';
import { getChartProps } from './helpers/getChartProps';
import useCircleChart from './hooks/useCircleChart';
import useInitialCircle from './hooks/useInitialCircle';
import { ICircleChartProps } from './models/circleChart.model';
import CustomLegend from './Utils/CustomLegend/CustomLegend';

import styles from './CircleChart.module.scss';

const CircleChart: FC<ICircleChartProps> = ({
  data,
  dataKey = 'value',
  colors = COLORS_SETS,
  isLegend = true,
  isLabel = true,
  isPercents = true,
  isHalfPie = false,
  isHover = true,
  activeSectors,
  handleClickSector,
}) => {
  const [hoverLine, setHoverLine] = useState('');

  const { containerRef, height, radiusWeight, width } = useInitialCircle();
  const {
    activeIndex,
    total,
    isAnimation,
    handleMouseLeave,
    handlerMouseEnter,
  } = useCircleChart({ activeSectors, data, isHalfPie });

  const cellStyle = classNames({
    [styles.cell]: true,
    [styles.cellDefaultCursor]: isHalfPie,
  });

  const isOneRecord = data.length === 1;

  const shapeProps = {
    isPercents,
    isHalfPie,
    total,
    isOneRecord,
    isHover,
    hoverType: hoverLine,
    setHoverLine,
    activeSectors,
    activeIndex,
    data,
    handleClickSector,
    isAnimation,
  };

  return (
    <div className={styles.container} ref={containerRef}>
      {width ? (
        <ResponsiveContainer>
          <PieChart
            className={styles.chart}
            width={width}
            height={height}
            margin={isLegend ? CIRCLE_MARGIN : undefined}
          >
            {isLegend && (
              <Legend
                content={
                  <CustomLegend
                    setHoverLine={setHoverLine}
                    hoverLine={hoverLine}
                    activeSectors={activeSectors}
                    isOneRecord={isOneRecord}
                    handleClickSector={handleClickSector}
                  />
                }
                verticalAlign="middle"
              />
            )}
            <Pie
              className={cellStyle}
              data={data}
              activeShape={<ActiveShape {...shapeProps} />}
              inactiveShape={<ActiveShape {...shapeProps} isActive={false} />}
              innerRadius={radiusWeight}
              outerRadius={radiusWeight + CIRCLE_WEIGHT}
              dataKey={dataKey}
              animationBegin={0}
              minAngle={5}
              label={isLabel}
              onMouseEnter={handlerMouseEnter}
              onMouseLeave={handleMouseLeave}
              {...getChartProps(data, isHalfPie, activeIndex)}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={colors[index]}
                  className={cellStyle}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      ) : (
        <Spin {...spinProps} className={styles.spin} />
      )}
    </div>
  );
};

export default observer(CircleChart);
