import { ZOOM } from '../../../../constants/mapConstants';
import { TMap } from '../../../../stores/mapStore/mapStore.model';

export const handleZoomUI = (
  zoom: number,
  map: TMap,
  handleEndAnimate?: (isEnd: boolean) => void
) => {
  if (!map) return;

  map.getView().animate(
    {
      zoom: zoom,
      duration: ZOOM.DURATION,
    },
    (isEnd) => {
      handleEndAnimate?.(isEnd);
    }
  );
};
