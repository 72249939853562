import { getAppPathHeaders } from '../../../helpers/getAppPathHeaders';
import http from '../../../http';
import tryCatchWrapper from '../../../tryCatchWrapper';
import { SYSTEMS } from '../../constants/constants';

const SYS_SETTINGS = SYSTEMS + '/settings/';

const settings = {
  getSystemSettings: (id: number, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.get(SYS_SETTINGS + id, {
        headers: getAppPathHeaders(appPath),
      });

      return res;
    }),
  updateSystemSettings: (id: number, params: any[], appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.put(SYS_SETTINGS + id, { params });

      return res;
    }),
};

export default settings;
