import { FC } from 'react';

import styles from './Rect.module.scss';

interface IRectProps {
  x?: number;
  y?: number;
  fill: string;
  rx?: number;
  ry?: number;
}

const Rect: FC<IRectProps> = ({ x = 0, y = 0, fill, rx = 15, ry = 15 }) => {
  return (
    <rect className={styles.rect} x={x} y={y} fill={fill} rx={rx} ry={ry} />
  );
};

export default Rect;
