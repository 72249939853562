import { Circle, Fill, Style } from 'ol/style';

const INITIAL_PROPS = {
  radius: 15,
  fill: new Fill({
    color: [0, 0, 0, 0.01],
  }),
};

interface EmptyClusterProps {
  radius?: number;
  fill?: Fill;
}

export const getEmptyCluster = (props?: EmptyClusterProps) => {
  const { radius = INITIAL_PROPS.radius, fill = INITIAL_PROPS.fill } =
    props ?? INITIAL_PROPS;

  return new Style({
    image: new Circle({
      fill: fill,
      radius,
    }),
    fill: fill,
  });
};
