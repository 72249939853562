import { observer } from 'mobx-react-lite';
import React from 'react';

import rootStore from '../../stores/rootStore/rootStore';
import { DEFAULT_SCENE_ZOOM } from '../MapBtns/TrafficGraphButton/constants';
import Scene from '../Scene/Scene';

import styles from './sceneWrapper.module.scss';

export interface ISceneWrapperProps {
  visible: boolean;
  basemapId?: string;
  zoom?: number;
}

const SceneWrapper = (props: ISceneWrapperProps) => {
  const { mapProxy } = rootStore.mapDataStore;

  const zoom =
    props.zoom || mapProxy?.getView()?.getZoom() || DEFAULT_SCENE_ZOOM;

  return (
    <div className={props.visible ? '' : styles.sceneNoDisplay}>
      {
        <Scene
          map={mapProxy}
          visible={props.visible}
          basemapId={props.basemapId}
          zoom={zoom}
        />
      }
    </div>
  );
};

export default observer(SceneWrapper);
