import { isNumber } from 'lodash';
import { MapBrowserEvent } from 'ol';
import Feature, { type FeatureLike } from 'ol/Feature';
import { Point } from 'ol/geom';
import { useCallback } from 'react';

import { TMap } from '../../../../../../stores/mapStore/mapStore.model';
import rootStore from '../../../../../../stores/rootStore/rootStore';
import {
  FeatureDeviceProps,
  FeaturesTypes,
} from '../../../../../../ts/enums/enums';

type MapClickHandler = (event: MapBrowserEvent<any>) => void;

const { Id, System } = FeatureDeviceProps;
const { Features } = FeaturesTypes;

const useMapHandlers = (map: TMap) => {
  const { deviceInfo, setMonitoringValue } = rootStore.monitoringPanelStore;

  const handleMapClick = useCallback<MapClickHandler>(
    (e) => {
      if (!map || !deviceInfo) return;

      const { id } = deviceInfo;

      map.forEachFeatureAtPixel(e.pixel, (cluster: FeatureLike) => {
        const features: Feature<Point>[] = cluster.get(Features) ?? [cluster];
        const isFeature = features.length === 1;
        const feature = features[0];
        const featureId = feature.get(Id);
        const featureSystem = feature.get(System);

        const isNeedChangeDevice =
          isNumber(featureId) && isFeature && id !== featureId && featureSystem;

        isNeedChangeDevice &&
          setMonitoringValue('deviceInfo', {
            id: featureId,
            system: featureSystem,
          });
      });
    },
    [map, deviceInfo, setMonitoringValue]
  );

  return handleMapClick;
};

export default useMapHandlers;
