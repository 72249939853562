import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { ReactComponent as MeteoIco } from '../../../assets/icons/map/ic_meteo.svg';
import { COLORS } from '../../../constants/colorsConstants';
import rootStore from '../../../stores/rootStore/rootStore';
import { System } from '../../../ts/enums/enums';
import { ICON_SIZE } from '../../Constructor/constants/constants';
import DeviceIcon from '../DeviceIcon/DeviceIcon';
import DeviceId from '../DeviceIcon/DeviceId/DeviceId';
import getDeviceIcoFill from '../helpers/getDeviceIcoFill';
import getIconSize from '../helpers/getIconSize';
import { DeviceIconProps, IconProps } from '../models/device.model';

import styles from './MeteoIcon.module.scss';

const MeteoIcon: FC<IconProps> = ({
  isVisible = true,
  offsetX,
  offsetY,
  size = ICON_SIZE.DEFAULT,
  onClick,
  activeId,
  id,
  isId = false,
  isShowIdUnder = false,
  isClickable = true,
  rate,
}) => {
  const { meteo } = rootStore.mapDataStore;
  const { infoData } = rootStore.uiStore;

  if (!isVisible) return null;

  const icoSize = getIconSize(size, rate);
  const sizeProps = { width: icoSize, height: icoSize };
  const isActive = activeId === id;
  const isMtActive = id === infoData?.id;
  const fill =
    isMtActive || isActive ? COLORS.PRIMARY : getDeviceIcoFill(id, meteo);

  const mtIcoStyle = classNames(styles.dt, {
    [styles.icoCursor]: isClickable,
  });

  const deviceIcoProps: DeviceIconProps = {
    offsetX,
    offsetY,
    size: icoSize,
    onClick,
    className: mtIcoStyle,
    fill,
    id,
    system: System.Meteo,
  };

  if (isShowIdUnder)
    return (
      <DeviceIcon {...deviceIcoProps}>
        <DeviceId id={id} size={icoSize} isIcoWithId />
        <MeteoIco {...sizeProps} className={styles.mtIco} />
      </DeviceIcon>
    );

  return (
    <DeviceIcon {...deviceIcoProps}>
      {isId ? (
        <DeviceId id={id} size={icoSize} />
      ) : (
        <MeteoIco {...sizeProps} className={styles.mtIco} />
      )}
    </DeviceIcon>
  );
};

export default observer(MeteoIcon);
