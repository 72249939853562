import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { SECOND } from '../../../../constants/constants';

dayjs.extend(duration);

export const getTimeFormatted = (count: number, type: string) => {
  return count ? `${count} ${type}` : '';
};

export const getIntervalFormatted = (interval: number) => {
  const duration = dayjs.duration(interval * SECOND);

  const days = Math.floor(duration.asDays());
  const hours = Math.floor(duration.hours());
  const min = Math.floor(duration.minutes());
  const sec = Math.floor(duration.seconds());

  const dayStr = days === 1 ? 'сутки' : 'д.';

  const dayFormatted = getTimeFormatted(days, dayStr);
  const hoursFormatted = getTimeFormatted(hours, 'ч.');
  const minFormatted = getTimeFormatted(min, 'мин.');
  const secFormatted = getTimeFormatted(sec, 'с.');

  return `${dayFormatted} ${hoursFormatted} ${minFormatted} ${secFormatted}`;
};
