import { useQuery } from '@tanstack/react-query';

import {
  QUERY_KEY,
  UPDATE_PREDICT_CHART_INTERVAL,
} from '../../../../../constants/queryConstants';
import { UseDtStateProps } from '../../model/dtStatistics.model';

const { DETECTORS_STATE } = QUERY_KEY;

const useDtStateQuery = ({
  id,
  appPath,
  fetchDtStateByDeviceId,
}: UseDtStateProps) => {
  const {
    data: isWork = false,
    isLoading,
    isError,
  } = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [DETECTORS_STATE, id],
    queryFn: async () => {
      const res = await fetchDtStateByDeviceId({
        id,
        appPath,
      });

      return res;
    },
    refetchInterval: UPDATE_PREDICT_CHART_INTERVAL,
  });

  return {
    isWork,
    isLoading,
    isError,
  };
};

export default useDtStateQuery;
