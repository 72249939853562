import { Badge, Dropdown, type InputRef } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useRef } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';

import rootStore from '../../../../../../stores/rootStore/rootStore';
import Popover from '../../../../../ui-kit/Popover/Popover';

import SearchDropdown from './SearchDropdown/SearchDropdown';

import styles from './SearchBlock.module.scss';

const SearchBlock = () => {
  const { interfaceTipsDelay } = rootStore.uiStore;
  const { searchMonitoring } = rootStore.monitoringPanelStore;

  const searchInput = useRef<InputRef>(null);

  const onOpenChange = (value: boolean) => {
    if (!value) return;

    setTimeout(() => searchInput.current?.focus({ cursor: 'end' }), 0);
  };

  const icoStyle = classNames({
    [styles.ico]: true,
    [styles.disabled]: false,
  });

  return (
    <Dropdown
      dropdownRender={() => <SearchDropdown searchInput={searchInput} />}
      trigger={['click']}
      disabled={false}
      onOpenChange={onOpenChange}
    >
      <span className={icoStyle}>
        <Popover content={'Поиск устройства'} tipsDelay={interfaceTipsDelay}>
          <Badge dot={!!searchMonitoring} className={styles.ico}>
            <AiOutlineSearch />
          </Badge>
        </Popover>
      </span>
    </Dropdown>
  );
};

export default observer(SearchBlock);
