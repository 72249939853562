import { isNumber } from 'lodash';
import { useMemo } from 'react';

import { getPointUdsByDeviceId } from '../../../../../../../helpers/mapHelpers/pointUds/getPointUdsByDeviceId';
import { MapPropsDetailed } from '../../../../../../../stores/detailedStore/detailedStore.model';
import {
  getCircleDiameter,
  getRate,
} from '../../../../../../../stores/helpers/schemaHelpers';
import rootStore from '../../../../../../../stores/rootStore/rootStore';
import { System } from '../../../../../../../ts/enums/enums';
import { CrossroadSchemaProps } from '../../CrossroadSchema/CrossroadSchema';

type CrossroadProps = Omit<CrossroadSchemaProps, 'crossroadOverlay' | 'map'>;

interface UseCrossroadUdsProps {
  id: number;
  system: System;
  mapPropsDetailed: N<MapPropsDetailed>;
}

const useCrossroadUds = ({
  id,
  system,
  mapPropsDetailed,
}: UseCrossroadUdsProps) => {
  const { detectors, pointsUds } = rootStore.mapDataStore;

  const pointUdsInfo = useMemo(() => {
    const pointUds = getPointUdsByDeviceId(id, system);

    if (!pointUds) return;

    const { longitude, latitude } = pointUds;

    const coord = [longitude, latitude];

    return { pointUds, coord };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detectors, id, system, pointsUds]);

  const rate = mapPropsDetailed
    ? getRate(
        getCircleDiameter(
          mapPropsDetailed.currZoom,
          mapPropsDetailed.resolution
        )
      )
    : null;

  const schemaODD = pointUdsInfo?.pointUds?.basicProfileInfo?.schemaODD;

  const crossroadBasicProps: N<CrossroadProps> = isNumber(rate)
    ? {
        rate,
        tlCrossroad: schemaODD ?? null,
        coord: pointUdsInfo?.coord ?? [],
        id: schemaODD?.id ?? undefined,
      }
    : null;

  return { crossroadBasicProps, pointUdsInfo };
};

export default useCrossroadUds;
