import { notification } from 'antd';
import { Dayjs } from 'dayjs';
import 'dayjs/locale/ru';

import { TIME_FORMAT } from '../../../../constants/constants';
import NOTICE from '../../../../constants/notificationConstants';
import { getTimeWithTimeZone } from '../../TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeWithTimeZone';
import { COMMANDS_VALUES } from '../constants/constants';

export const validatePostCommand = (
  cmd: keyof typeof COMMANDS_VALUES,
  disabledTime = 0,
  selectedDate: Nullish<Dayjs>
) => {
  const notifyMessage = {
    ...NOTICE.DATE_ERROR_PLAN,
    description: `Включение режима ${COMMANDS_VALUES[cmd]} доступно не раньше`,
  };

  let isValid = true;
  const dateNowJS = getTimeWithTimeZone().dateFormattedJS;
  const dateNow = dateNowJS.format(TIME_FORMAT);

  if (!disabledTime && selectedDate && !dateNowJS.isBefore(selectedDate)) {
    notifyMessage.description += ` текущего времени (${dateNow})`;
    isValid = false;
  }

  if (
    disabledTime &&
    !dateNowJS.add(disabledTime, 'minutes').isBefore(selectedDate)
  ) {
    notifyMessage.description += `, чем через ${disabledTime} минут`;
    isValid = false;
  }

  !isValid && notification.error(notifyMessage);

  return isValid;
};
