import { Divider as DividerAntD } from 'antd';
import { FC, CSSProperties } from 'react';

import styles from './Divider.module.scss';

interface DividerProps {
  title: string;
  margin?: CSSProperties['margin'];
}

const Divider: FC<DividerProps> = ({ title, margin = '20px 0 5px' }) => {
  return (
    <div className={styles.blockTitle}>
      <DividerAntD plain style={{ margin }}>
        {title}
      </DividerAntD>
    </div>
  );
};

export default Divider;
