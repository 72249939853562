import { Card, Input } from 'antd';
import { observer } from 'mobx-react-lite';
import {
  useCallback,
  useEffect,
  useState,
  useRef,
  FC,
  ChangeEvent,
} from 'react';

import { signalProgramsExample } from '../../../data/ExampleDataSignalProgram';
import downloadFile from '../../../helpers/downloadFile';
import getNormalizeDateTime from '../../../helpers/getNormalizeDateTime';
import rootStore from '../../../stores/rootStore/rootStore';
import {
  ICsdData,
  IProcessedDataInitial,
  ITimePoints,
} from '../../../ts/models/signalPrograms.model';
import { DetailedItemProps } from '../../DetailedMain/DetailedWrapper/constants/constants';
import getCsdData from '../../NewSignalProgram/helpers/getCsdData';
import notification from '../../ui-kit/Notification/Notification';
import SignalProgramCard from '../TlManagementPlans/SignalProgramCard/SignalProgramCard';

import ImportExportBlock from './ImportExportBlock/ImportExportBlock';

import styles from './TlSignalProgramSetting.module.scss';

const TlSignalProgramSetting: FC<DetailedItemProps> = ({
  curentActiveKey,
  id,
}) => {
  const { activeKey } = rootStore.uiStore;
  const { TextArea } = Input;

  const refImportBtn = useRef<HTMLInputElement>(null);

  const [fileName, setFileName] = useState('Файл не выбран');
  const [programs, setPrograms] = useState<IProcessedDataInitial[]>([]);
  const [phasesSet, setPhasesSet] = useState<ITimePoints[][]>([]);
  const [csdData, setCsdData] = useState<ICsdData[]>([]);
  const [data, setData] = useState<any>();
  const [textJson, setTextJson] = useState<string>(signalProgramsExample);
  const [isError, setIsError] = useState<boolean[]>([]);

  const resetData = () => {
    setPrograms([]);
    setData(null);
    setPhasesSet([]);
    setCsdData([]);
  };

  const onApply = () => {
    resetData();

    if (textJson) {
      const obj = JSON.parse(textJson);

      setData(obj);
    }
  };

  const onSave = () => {
    downloadFile(textJson, `${getNormalizeDateTime()} signalPrograms.json`);
  };

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const file = e.target.files[0];

    resetData();
    setTextJson('');

    if (file.type !== 'application/json') {
      notification.error('IMPORT_ERROR');

      if (refImportBtn.current) {
        refImportBtn.current.value = '';

        return;
      }
    }

    const reader = new FileReader();

    reader.readAsText(file);

    reader.onload = function () {
      const data = reader.result;

      setTextJson(data as string);
      setFileName(file.name);

      const obj = JSON.parse(data as string);

      setData(obj);
    };
  };

  const getData = useCallback((program: IProcessedDataInitial) => {
    const res = getCsdData({
      data: program,
      isStatic: true,
    });

    if (!res) {
      setIsError((prevState) => [...prevState, true]);

      return;
    }

    const { timePoints, csdData } = res;

    setIsError((prevState) => [...prevState, false]);
    setPhasesSet((prevState) => [...prevState, timePoints]);
    setCsdData((prevState) => [...prevState, csdData]);
  }, []);

  useEffect(() => {
    if (data) {
      const programsData = data.tlSignalPrograms;

      setPrograms(programsData);

      programsData.forEach((program: IProcessedDataInitial) => {
        getData(program);
      });
    }
  }, [data, getData]);

  useEffect(() => {
    if (curentActiveKey !== activeKey) {
      resetData();
    }
  }, [activeKey, curentActiveKey]);

  if (curentActiveKey !== activeKey) return null;

  return (
    <>
      <div className={styles.container}>
        <div className={styles.containerColumn}>
          <Card className={styles.buttonsBoxCard}>
            <h3>Настройка отображения сигнальной программы</h3>
            <br />
            <input
              accept="application/json"
              className={styles.uploadBtn}
              onChange={(e) => handleFileUpload(e)}
              ref={refImportBtn}
              type="file"
            />
            <ImportExportBlock
              refImportBtn={refImportBtn}
              onApply={onApply}
              onSave={onSave}
            />
            <h4 className={styles.fileName}> {fileName} </h4>
          </Card>
          <TextArea
            className={styles.textArea}
            onChange={(e) => {
              setTextJson(e.target.value);
            }}
            value={textJson}
            placeholder="Введите JSON файл..."
          />
        </div>

        <Card className={styles.signalPrgCard}>
          <h3>Сигнальные программы:</h3>

          <SignalProgramCard
            programs={programs}
            csdData={csdData}
            phasesSet={phasesSet}
            isError={isError}
            id={id}
          />
        </Card>
      </div>
    </>
  );
};

export default observer(TlSignalProgramSetting);
