/* eslint-disable camelcase */
import { z } from 'zod';

import { FINAL_PREFIX } from '../../../../constants/constructorConstants';
import { ProfilesList } from '../../../../ts/models/constructor.model';
import { ProfileSchema } from '../profiles.zod';

export type ProfileDB = z.infer<typeof ProfileSchema>;
type FormatProfileList = (itemJ: ProfileDB) => ProfilesList;

export const formatProfileItem: FormatProfileList = (item) => {
  const { profile_id, profile_name, isFinalVersion, profile_dsc } = item;

  const prefix = isFinalVersion ? FINAL_PREFIX : '';

  return {
    profileDsc: profile_dsc,
    profileId: profile_id,
    profileName: prefix + profile_name,
    isFinalVersion,
  };
};

export const formatProfileList = (data: U<ProfileDB[]>) =>
  data?.map((item) => formatProfileItem(item));
