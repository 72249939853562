import { FC, useEffect, useState } from 'react';

import http from '../../../../../api/http';
import { IRequestItem, StatusTypes } from '../../ts/TestingPanel.model';

import useRequest from './hooks/useRequest';
import RequestInfo, { RequestInfoProps } from './ui/RequestInfo/RequestInfo';
import ResponseInfo, {
  ResponseInfoProps,
} from './ui/ResponseInfo/ResponseInfo';

import styles from './RequestBlock.module.scss';

interface RequestBlockProps {
  request: IRequestItem;
}

const RequestBlock: FC<RequestBlockProps> = ({ request }) => {
  const { title, href, method, body, status } = request;

  const [statusMessage, setStatusMessage] = useState<StatusTypes>(
    StatusTypes.init
  );
  const [newBody, setNewBody] = useState<object | null>(body);

  const { send, isLoading, error, statusCode, response } = useRequest({
    method: method ?? 'GET',
    url: href ?? '',
    body: newBody ? newBody : undefined,
  });

  useEffect(() => {
    setNewBody(body);
  }, [body]);

  useEffect(() => {
    if (isLoading) {
      return setStatusMessage(StatusTypes.loading);
    }

    if (error) {
      return setStatusMessage(StatusTypes.faliled);
    }

    if (response === undefined) {
      return setStatusMessage(StatusTypes.init);
    }

    setStatusMessage(StatusTypes.successful);
  }, [isLoading, error, response]);

  const RequestInfoProps: RequestInfoProps = {
    title: title,
    url: http.defaults.baseURL + '/' + href,
    method: method ?? 'GET',
    body: newBody,
    setBody: setNewBody,
    waitingStatus: status ?? 200,
    statusCode: statusCode,
    statusMessage: statusMessage,
    send: send,
  };

  const ResponseInfoProps: ResponseInfoProps = {
    response: response,
    statusMessage: statusMessage,
    isLoading: isLoading,
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapItem}>
        <RequestInfo {...RequestInfoProps} />
      </div>
      <div className={styles.separator} />
      <div className={styles.wrapItem}>
        <ResponseInfo {...ResponseInfoProps} />
      </div>
    </div>
  );
};

export default RequestBlock;
