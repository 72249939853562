import { useState, useEffect, useCallback } from 'react';

import { wait } from '../helpers/wait';

export const TIME_BETWEEN_REQUESTS = 200;

interface DelayArgs<T> {
  arr: T[];
  delay?: number;
  isClean?: boolean;
  trigger?: Nullish<SN>;
}

type UseDelay = <T>(params: DelayArgs<T>) => T[];

const useArrayWithDelay: UseDelay = ({
  arr,
  delay,
  trigger,
  isClean = false,
}) => {
  const [formedArr, setFormedArr] = useState<typeof arr>([]);

  const addArrWithDelay = useCallback(async () => {
    setFormedArr([]);
    if (isClean) return;

    for (let i = 0; i < arr.length; i++) {
      await wait(delay || TIME_BETWEEN_REQUESTS);
      setFormedArr((prev) => [...prev, arr[i]]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arr, delay, isClean, trigger]);

  useEffect(() => {
    addArrWithDelay();
  }, [addArrWithDelay]);

  return formedArr;
};

export default useArrayWithDelay;
