import { Descriptions } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { System } from '../../../../../../../../ts/enums/enums';

import getDescriptionInfo from './helpers/getDescriptionItems';
import Warnings from './Warnings/Warnings';

import styles from './Description.module.scss';

interface DescriptionProps {
  id: number;
  system: System;
  isActive?: boolean;
  address: Nullish<string>;
  addressLoading: boolean;
  isShowWarnings: boolean;
}

const Description: FC<DescriptionProps> = ({
  id,
  system,
  isActive = false,
  address,
  addressLoading,
  isShowWarnings,
}) => {
  const items = getDescriptionInfo({ id, system, address, addressLoading });

  const descriptionStyle = classNames(styles.description, {
    [styles.active]: isActive,
  });

  return (
    <div className={styles.container}>
      {isShowWarnings ? <Warnings id={id} /> : null}
      <Descriptions
        items={items}
        layout="vertical"
        className={descriptionStyle}
      />
    </div>
  );
};

export default observer(Description);
