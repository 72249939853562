import { Card } from 'antd';
import Meta from 'antd/es/card/Meta';
import classNames from 'classnames';
import { FC } from 'react';

import { IDataList } from '../../../../../../../../../../DetailedStatistics/Monitoring/model/monitoring.model';
import DeviceAvatar from '../../../../../../../../../../DetailedStatistics/Monitoring/SystemMonitoring/DevicesBlock/DevicesList/DeviceItem/DeviceAvatar/DeviceAvatar';

import BackgroundContent from './BackgroundContent/BackgroundContent';
import Content from './Content/Content';
import useHandleMove from './hooks/useHandleMove';
import MainImage from './MainImage/MainImage';

import styles from './TLCard.module.scss';

interface TLCardProps extends IDataList {
  addressLoading: boolean;
}

const TLCard: FC<TLCardProps> = ({
  id,
  avatar,
  status,
  title,
  addressLoading,
  address,
  description,
}) => {
  const { container, isMainContent } = useHandleMove();

  return (
    <Card
      cover={<MainImage id={id} isShow={isMainContent} />}
      className={classNames(styles.card, {
        [styles.back]: !isMainContent,
      })}
      ref={container}
    >
      <Meta
        avatar={
          <DeviceAvatar
            id={id}
            status={status}
            iconSystem={avatar}
            isPhase
            isShow={isMainContent}
          />
        }
        title={
          <p
            className={classNames(styles.title, {
              [styles.invisible]: !isMainContent,
            })}
          >
            {title}
          </p>
        }
        description={
          <Content
            description={description}
            address={address}
            addressLoading={addressLoading}
            isShow={isMainContent}
          />
        }
      />
      <BackgroundContent
        isShow={!isMainContent}
        address={address}
        addressLoading={addressLoading}
        id={id}
      />
    </Card>
  );
};

export default TLCard;
