import { FC } from 'react';

import { TMap } from '../../../../stores/mapStore/mapStore.model';

import useZoom from './hooks/useZoom';
import UiZoomBtns from './UiZoomBtns/UiZoomBtns';

export interface ZoomBtnsWrapperProps {
  map: TMap;
  handleZoomProps?: (zoom: number, resolution: number) => void;
  zoomProps: {
    zoom: number;
    maxZoom: number;
    minZoom: number;
  };
}

const ZoomBtnsWrapper: FC<ZoomBtnsWrapperProps> = (props) => {
  const { disabled, handlers } = useZoom(props);

  if (!handlers) return null;

  return <UiZoomBtns handlers={handlers} disabled={disabled} />;
};

export default ZoomBtnsWrapper;
