import { FC } from 'react';

interface NoCurrentObjProps {
  title?: string;
}

export const TEXT_EMPTY = 'Объекта с данным id не найдено';

const NoCurrentObj: FC<NoCurrentObjProps> = ({ title = TEXT_EMPTY }) => {
  return <div>{title}</div>;
};

export default NoCurrentObj;
