/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { TooltipPlacement } from 'antd/lib/tooltip';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { CSSProperties, FC, ReactNode } from 'react';

import { cropHeaderByParams } from '../../../helpers/cropHeaderByParams';
import rootStore from '../../../stores/rootStore/rootStore';
import Popover from '../Popover/Popover';

import styles from './Title.module.scss';

export interface ITitle {
  title: string;
  maxLength: number;
  titleAddition?: string;
  onClick?: () => void;
  popoverContent?: string | ReactNode;
  placement?: TooltipPlacement;
  className?: string;
  titleStyle?: CSSProperties;
}

const Title: FC<ITitle> = ({
  title,
  maxLength,
  titleAddition = '',
  onClick,
  popoverContent = title,
  placement = 'top',
  titleStyle,
}) => {
  const { interfaceTipsDelay } = rootStore.uiStore;

  if (title.length <= maxLength)
    return (
      <p className={styles.title} onClick={onClick} style={titleStyle}>
        {title + titleAddition}
      </p>
    );

  const titlePopoverStyle = classNames({
    [styles.title]: true,
    [styles.popover]: true,
  });

  return (
    <Popover
      placement={placement}
      content={popoverContent}
      tipsDelay={interfaceTipsDelay}
    >
      <p className={titlePopoverStyle} onClick={onClick} style={titleStyle}>
        {cropHeaderByParams(title, maxLength) + titleAddition}
      </p>
    </Popover>
  );
};

export default observer(Title);
