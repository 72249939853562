import { Spin } from 'antd';
import { SpinProps } from 'antd/lib';
import { observer } from 'mobx-react-lite';
import { Suspense, lazy } from 'react';

import rootStore from '../../stores/rootStore/rootStore';
import { PanelType } from '../../ts/enums/enums';
import UnexpectedError from '../ui-kit/Errors/UnexpectedError/UnexpectedError';

import PanelLayout from './PanelLayout/PanelLayout';

import styles from './Panel.module.scss';

const Cartography = lazy(() => import('./MainPanel/MainPanel'));
const ModulesSubsystems = lazy(
  () => import('./StatisticsPanel/StatisticsPanel')
);
const Reports = lazy(() => import('./ReportsPanel/ReportsPanel'));
const Profile = lazy(() => import('./ProfilePanel/ProfilePanel'));
const Constructor = lazy(() => import('./ConstructorPanel/ConstructorPanel'));
const Admins = lazy(() => import('./AdminPanel/AdminPanel'));
const VideoWall = lazy(() => import('./VideoWallPanel/VideoWallPanel'));
const Testing = lazy(() => import('./TestPanel/TestingPanel'));
const CoordManagement = lazy(() => import('./ScriptsControl/ScriptsControl'));

const PANEL: Record<PanelType, JSX.Element> = {
  cartography: <Cartography />,
  modulesSubsystems: <ModulesSubsystems />,
  reports: <Reports />,
  profile: <Profile />,
  constructor: <Constructor />,
  admins: <Admins />,
  videoWall: <VideoWall />,
  testing: <Testing />,
  coordManagement: <CoordManagement />,
};

const spinProps: SpinProps = {
  size: 'large',
  className: styles.spinner,
};

const Panel = () => {
  const { panelType } = rootStore.uiStore;

  if (!panelType) return null;

  return (
    <PanelLayout>
      <UnexpectedError resetKeys={[panelType]}>
        <Suspense fallback={<Spin {...spinProps} />}>
          {PANEL[panelType]}
        </Suspense>
      </UnexpectedError>
    </PanelLayout>
  );
};

export default observer(Panel);
