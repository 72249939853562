import classNames from 'classnames';
import { FC } from 'react';

import {
  TYPE_COLORS,
  TactsVal,
} from '../../../../../constants/signalsConstants';

import { getPopoverTitle } from './helpers/getPopoverTitle';

import styles from './PopoverContent.module.scss';

export interface PopoverContentProps {
  type: TactsVal;
  isPromTact: boolean;
  title: U<string>;
  isStatic: boolean;
  dirNum: number;
}

const PopoverContent: FC<PopoverContentProps> = ({
  isPromTact,
  title,
  type,
  isStatic,
  dirNum,
}) => {
  return (
    <div>
      <span className={styles.tooltipTitleTl}>
        {!isStatic ? (
          <span
            className={classNames({
              [styles.status]: true,
              [styles.statusPromTact]: isPromTact,
            })}
            style={{ backgroundColor: TYPE_COLORS[type] }}
          ></span>
        ) : null}
        <span>{getPopoverTitle(title, dirNum, isStatic, type)}</span>
      </span>
    </div>
  );
};

export default PopoverContent;
