import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import {
  DEVICES_STATE,
  DEVICES_STATUS,
  TDeviceStatus,
} from '../../../../../../constants/devicesConstants';
import rootStore from '../../../../../../stores/rootStore/rootStore';
import { getGeneralStatusDevices } from '../../../../../Panel/StatisticsPanel/MonitoringCard/helpers/getGeneralStatusDevices';
import { IPieChartData } from '../../../../../ui-kit/CircleChart/models/circleChart.model';
import { getStatusData } from '../../../helpers/getStatusData';

import DropdownItem from './DropdownItem/DropdownItem';

import styles from './DropdownStatus.module.scss';

export type TStatusDevices = Record<TDeviceStatus, string>;

const STATUS_INFO = Object.values(DEVICES_STATE).reduce(
  (acc: TStatusDevices, { com, dsc }) => {
    acc[com] = dsc;

    return acc;
  },
  {} as TStatusDevices
);

const DropdownStatus = () => {
  const { widgetsFromMap } = rootStore.monitoringPanelStore;

  const { activeSystemMonitoring, clearMonitoringStore } =
    rootStore.monitoringPanelStore;

  const [systemsData, setSystemsData] = useState<IPieChartData[]>([]);

  useEffect(() => {
    if (!activeSystemMonitoring) return;

    const { isNoData, ...statusDataRes } = getStatusData(
      widgetsFromMap,
      activeSystemMonitoring
    );

    setSystemsData(getGeneralStatusDevices(statusDataRes).data);
  }, [activeSystemMonitoring, widgetsFromMap]);

  useEffect(() => {
    return () => {
      clearMonitoringStore(false);
    };
  }, [activeSystemMonitoring, clearMonitoringStore]);

  return (
    <div className={styles.containerRow}>
      {DEVICES_STATUS.map((statusId) => {
        const name = DEVICES_STATE.find(({ com }) => com === statusId)?.dsc;
        const systemsLen =
          systemsData.find((el) => el.name === name)?.value ?? 0;

        return (
          <DropdownItem
            key={statusId}
            statusId={statusId}
            statusInfos={STATUS_INFO}
            systemsLen={systemsLen}
          />
        );
      })}
    </div>
  );
};

export default observer(DropdownStatus);
