import { useMemo } from 'react';

import { findById } from '../../../helpers/findBy';
import rootStore from '../../../stores/rootStore/rootStore';
import { System } from '../../../ts/enums/enums';

const useLinkedTls = () => {
  const { tlsInExtent } = rootStore.pointsUdsStore;
  const { infoData } = rootStore.uiStore;
  const { tls } = rootStore.mapDataStore;

  const activeTL = useMemo(() => {
    const id = infoData?.system === System.Lights ? infoData.id : null;

    return id && findById(tls, id);
  }, [infoData, tls]);

  const linkedTls = useMemo(
    () =>
      activeTL
        ? [...tlsInExtent.filter(({ id }) => id !== activeTL.id), activeTL]
        : tlsInExtent,
    [activeTL, tlsInExtent]
  );

  return linkedTls;
};

export default useLinkedTls;
