import { type Feature } from 'ol';
import { type Point } from 'ol/geom';
import { FC, useMemo } from 'react';

import { TMap } from '../../../../../../stores/mapStore/mapStore.model';
import { FeatureDeviceProps } from '../../../../../../ts/enums/enums';
import useDynamicOverlays from '../../../../../Map/hooks/useDynamicOverlays';
import InfoOverlay from '../../../../../Overlays/InfoPopup/InfoOverlay/InfoOverlay';

interface InfoBlockProps {
  map: TMap;
  features: Feature<Point>[];
  isMapLoading: boolean;
}

const InfoBlock: FC<InfoBlockProps> = ({ map, features, isMapLoading }) => {
  const featuresWithOverlays = useMemo(
    () => features.map((feature) => ({ feature })),
    [features]
  );

  const {
    elementRefs,
    isRemovedOverlays,
    featuresInExtent,
    isReadyElementRefs,
  } = useDynamicOverlays({
    map,
    featuresWithOverlays,
    isShowOverlay: !isMapLoading,
    center: [],
    isInsertFirstOverlay: true,
  });

  if (
    !(
      isReadyElementRefs &&
      featuresInExtent.length &&
      !isMapLoading &&
      isRemovedOverlays &&
      elementRefs.length
    )
  )
    return null;

  return (
    <div>
      {featuresInExtent.map(({ feature }, i) => {
        const id = feature.get(FeatureDeviceProps.Id);

        return (
          <InfoOverlay
            key={`info_block_${id}`}
            feature={feature}
            infoRef={elementRefs[i]}
          />
        );
      })}
    </div>
  );
};

export default InfoBlock;
