import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

import rootStore from '../../../../../../stores/rootStore/rootStore';
import Popover from '../../../../../ui-kit/Popover/Popover';
import { TitleTable } from '../constants/constants';

import ColumnTooltip from './ColumnTooltip/ColumnTooltip';

import styles from './TitleColumn.module.scss';

interface TitleColumnProps {
  title: TitleTable;
}

const TitleColumn: FC<TitleColumnProps> = ({ title }) => {
  const { tipsValues } = rootStore.uiStore;

  const popOverProps = {
    placement: 'right',
    tipsDelay: tipsValues.profileTipsDelay,
  } as const;

  if (title === TitleTable.phase) {
    return (
      <div className={styles.rowContainer}>
        <p>{title}</p>
      </div>
    );
  }

  return (
    <div className={styles.rowContainer}>
      <p>{title}</p>
      <Popover {...popOverProps} content={<ColumnTooltip title={title} />}>
        <AiOutlineQuestionCircle size={20} />
      </Popover>
    </div>
  );
};

export default observer(TitleColumn);
