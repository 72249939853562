import { Modal } from 'antd';
import { useEffect, useState } from 'react';

import rootStore from '../stores/rootStore/rootStore';

const useNeedReload = (isNeedUpdate: boolean) => {
  const { userDataStore } = rootStore;
  const { setKeyValue } = userDataStore;
  const [timeoutId, setTimeoutId] = useState<N<NodeJS.Timeout>>(null);

  const POSTOPONE_UPDATE_DELAY = 300000; // ms

  const createModal = () => {
    const modalContent = (
      <div>
        <p>
          Ваши пользовательские данные или уровень доступа был изменён
          администратором.
        </p>
        <p>Для корректной работы приложения необходимо обновление страницы</p>
      </div>
    );

    Modal.confirm({
      title: 'Необходимо обновление страницы',
      content: modalContent, //'Для корректной работы приложения, пожалуйста обновите кэш страницы',
      cancelText: 'Отложить на 5 минут',
      onCancel: postponeUpdate,
      okText: 'Перезагрузить',
      onOk: updateWindow,
      centered: true,
    });
  };

  const updateWindow = () => {
    window.location.reload();
  };

  const postponeUpdate = () => {
    userDataStore.setKeyValue('isNeedPageReload', false);

    const timeoutIdCur = setTimeout(() => {
      setTimeoutId(null);
      setKeyValue('isNeedPageReload', true);
    }, POSTOPONE_UPDATE_DELAY);

    setTimeoutId(timeoutIdCur);
  };

  useEffect(() => {
    if (!isNeedUpdate || timeoutId) return;
    createModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNeedUpdate, timeoutId]);
};

export default useNeedReload;
