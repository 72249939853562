import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';

import { findById } from '../../../../../helpers/findBy';
import rootStore from '../../../../../stores/rootStore/rootStore';

import DtItem from './DtItem/DtItem';

import styles from './DtList.module.scss';

interface IDtListProps {
  tlId: number;
}

const DtList: FC<IDtListProps> = ({ tlId }) => {
  const { tls } = rootStore.mapDataStore;
  const { setActiveDtId } = rootStore.detailedStore;

  const [linkDts, setLinkDts] = useState<number[]>([]);

  const activeTrafficLight = findById(tls, tlId);

  useEffect(() => {
    const links = activeTrafficLight?.linkedDeviceIds?.detectors ?? [];

    setLinkDts(links);
    setActiveDtId(links[0] ?? null);
  }, [activeTrafficLight?.linkedDeviceIds?.detectors, setActiveDtId]);

  return (
    <div className={styles.container}>
      {linkDts.map((dtId) => (
        <DtItem key={dtId} id={dtId} />
      ))}
    </div>
  );
};

export default observer(DtList);
