import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useState } from 'react';
import { MdSearch } from 'react-icons/md';

import { getDirectionsBySearchPoints } from '../../../apiGIS/helpers/direction';
import { ISearchItem } from '../../../apiGIS/models/search';
import {
  IDirectionInstruction,
  IDirectionResult,
} from '../../../apiGIS/models/tasks/tasks.model';
import {
  clearDirections,
  drawDirections,
} from '../../../apiGIS/tasks/direction';
import { MAP_BUTTONS_NAMES } from '../../../constants/uiComponentConstants';
import rootStore from '../../../stores/rootStore/rootStore';
import { MapButtonsCode } from '../../../ts/enums/userData';
import ButtonIco from '../../ui-kit/ButtonIco/ButtonIco';

import DirectionPanel from './components/DirectionPanel';
import DirectionResultItem from './components/DirectionResultItem';
import SearchPanel from './components/SearchPanel';
import SearchResultItem from './components/SearchResultItem';
import { SearchSettings } from './components/SearchSettings';
import {
  DEFAULT_DIRECTION_POINT,
  MODES,
  PLACEMENT,
  SEARCH_CONTENT,
  SEARCH_TYPE_COORDINATES,
  SEARCH_TYPE_GEOCODER,
  SEARCH_TYPE_LAYER,
  SEARCH_TYPE_NONE,
} from './constants';
import {
  clearSearchResults,
  drawSearchResults,
  getLocationFromMap,
  getSearchItems,
  hideFeature,
  showFeature,
  zoomTo,
} from './helpers';

const SearchButton: FC = () => {
  const {
    mapButtonsTipsDelay,
    regionData,
    activeMapButton,
    setKeyValue,
    isSearchBtnGis,
  } = rootStore.uiStore;
  const { hasAccess } = rootStore.userDataStore;

  const { mapProxy } = rootStore.mapDataStore;
  const { layersState } = rootStore.gisDataStore;

  const [isShowPanel, setShowPanel] = useState(false);
  const [mode, setMode] = useState(MODES.SEARCH);
  const [settings, setSettings] = useState(false);

  const [searchText, setSearchText] = useState<string>('');
  const [hasLayersProvider, setHasLayersProvider] = useState(true);
  const [hasGeocodeProvider, setHasGeocodeProvider] = useState(true);
  const [hasCoordinatesProvider, setHasCoordinatesProvider] = useState(true);

  const [items, setItems] = useState<ISearchItem[]>([]);

  const [loading, setLoading] = useState(false);

  const [point1, setPoint1] = useState<ISearchItem>(DEFAULT_DIRECTION_POINT);

  const [point2, setPoint2] = useState<ISearchItem>(DEFAULT_DIRECTION_POINT);

  const [directionTitle, setDirectionTitle] = useState<string | undefined>(
    undefined
  );

  const [directions, setDirections] = useState<IDirectionResult[]>([]);

  const [direction, setDirection] = useState<IDirectionResult>({
    coordinates: [],
    instructions: [],
    points: [],
  });

  const [selectStartPoint, setSelectStartPoint] = useState(false);

  const popOverProps = {
    placement: PLACEMENT,
    tipsDelay: mapButtonsTipsDelay,
  } as const;

  useEffect(() => {
    if (activeMapButton !== MAP_BUTTONS_NAMES.SEARCH) {
      setShowPanel(false);
      setPoint1(DEFAULT_DIRECTION_POINT);
      setPoint2(DEFAULT_DIRECTION_POINT);
      setDirections([]);
      clearDirections(mapProxy);
    }
  }, [activeMapButton, mapProxy]);

  useEffect(() => {
    getDirectionsBySearchPoints(point1, point2).then((directionsVariants) => {
      setDirections(directionsVariants);

      if (directionsVariants && directionsVariants.length > 0) {
        setDirection(directionsVariants[0]);
      } else {
        setDirection({ coordinates: [], instructions: [], points: [] });
      }
    });
  }, [point1, point2]);

  useEffect(() => {
    clearDirections(mapProxy);
    drawDirections(mapProxy, directions, [point1, point2]);
  }, [directions, mapProxy, point1, point2]);

  useEffect(() => {
    if (mode === MODES.SEARCH) {
      clearDirections(mapProxy);
    }
  }, [mode, mapProxy]);

  const isAccess = hasAccess(MapButtonsCode.SearchButton);

  if (!isSearchBtnGis || !isAccess) return null;

  const togglePanel = () => {
    if (!isShowPanel) {
      setItems([]);
      setKeyValue('activeMapButton', MAP_BUTTONS_NAMES.SEARCH);
    }
    setShowPanel(!isShowPanel);
  };

  const searchItems = async (text: string) => {
    const extent = regionData?.extent;

    const providers: string[] = [SEARCH_TYPE_NONE];

    hasGeocodeProvider && providers.push(SEARCH_TYPE_GEOCODER);
    hasLayersProvider && providers.push(SEARCH_TYPE_LAYER);
    hasCoordinatesProvider && providers.push(SEARCH_TYPE_COORDINATES);

    clearSearchResults(mapProxy);

    const items = await getSearchItems(
      mapProxy,
      text,
      extent,
      layersState,
      providers
    );

    drawSearchResults(mapProxy, items);

    return items;
  };

  const search = async (text: string) => {
    setLoading(true);
    setItems([]);
    setSearchText(text);

    searchItems(text)
      .then((items) => {
        setItems(items);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const searchDirection = async (evt: any) => {
    const text = evt.target.value;

    const extent = regionData?.extent;

    const searchItems = await getSearchItems(
      mapProxy,
      text,
      extent,
      layersState,
      [SEARCH_TYPE_GEOCODER]
    );

    if (searchItems.length > 0) {
      const [item] = searchItems;

      setPoint1(item);

      setDirectionTitle(item.title);
    }
  };

  const toDirections = (item: ISearchItem) => {
    setMode(MODES.DIRECTIONS);
    setPoint2(item);
    setPoint1(DEFAULT_DIRECTION_POINT);
  };

  const selectStartLocation = async () => {
    setSelectStartPoint(true);

    const item: ISearchItem | undefined = await getLocationFromMap(mapProxy);

    if (!item) {
      setSelectStartPoint(false);
    }

    item && setPoint1(item);

    item && setDirectionTitle(item.title);

    setSelectStartPoint(false);
  };

  const resetDirectionStartPoint = () => {
    const item: ISearchItem = {
      title: '',
      type: 'geocoder',
    };

    item && setPoint1(item);

    item && setDirectionTitle('');
  };

  const toggleSettings = () => {
    setSettings(!settings);
  };

  return (
    <>
      <ButtonIco
        isActive={isShowPanel}
        onClick={togglePanel}
        popoverProps={{ ...popOverProps, content: SEARCH_CONTENT.content }}
      >
        <MdSearch />
      </ButtonIco>
      {isShowPanel && mode === MODES.SEARCH && !settings ? (
        <SearchPanel
          searchText={searchText}
          map={mapProxy}
          onChange={setSearchText}
          onSearch={search}
          onSettings={toggleSettings}
          loading={loading}
          dataSource={items}
          renderItem={(item: ISearchItem, index: number) => (
            <SearchResultItem
              item={item}
              key={'feature-' + index}
              onMouseOver={() => showFeature(item)}
              onMouseLeave={() => hideFeature(item)}
              onZoomClick={() => zoomTo(item)}
              onDirectionClick={() => toDirections(item)}
            />
          )}
        />
      ) : null}
      {isShowPanel && mode === MODES.DIRECTIONS && !settings ? (
        <DirectionPanel
          onReset={() => {
            resetDirectionStartPoint();
          }}
          onClick={() => {
            setMode(MODES.SEARCH);
          }}
          value={directionTitle}
          onInput={(evt: any) => {
            setDirectionTitle(evt.target.value);
          }}
          onPressEnter={searchDirection}
          loading={loading}
          onLocationSelected={selectStartLocation}
          selectStartPoint={selectStartPoint}
          point2={point2}
          direction={direction}
          renderItem={(item: IDirectionInstruction, index: number) => (
            <DirectionResultItem key={'point-' + index} item={item} />
          )}
        />
      ) : null}
      {isShowPanel && mode === MODES.SEARCH && settings ? (
        <SearchSettings
          onClick={toggleSettings}
          hasGeocodeProvider={hasGeocodeProvider}
          hasLayersProvider={hasLayersProvider}
          hasCoordinatesProvider={hasCoordinatesProvider}
          onGeocodeProviderChange={() => {
            setHasGeocodeProvider(!hasGeocodeProvider);
          }}
          onLayersProviderChange={() => {
            setHasLayersProvider(!hasLayersProvider);
          }}
          onCoordinatesProviderChange={() => {
            setHasCoordinatesProvider(!hasCoordinatesProvider);
          }}
        />
      ) : null}
    </>
  );
};

export default observer(SearchButton);
