import { TYPE_COLORS } from '../../../../../../../../constants/signalsConstants';
import { TitleTable } from '../../../constants/constants';

export const getTypeColor = (title: TitleTable) => {
  switch (title) {
    case TitleTable.spanGreenBlinkTime:
      return TYPE_COLORS[2];
    case TitleTable.spanYellowTime:
      return TYPE_COLORS[3];
    case TitleTable.spanRedYellowTime:
      return TYPE_COLORS[4];
    default:
      return;
  }
};
