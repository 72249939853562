import { FC } from 'react';

import LinkedDeviceItem from '../../LinkedsInfo/LinkedDeviceItem/LinkedDeviceItem';

interface ICrossroad {
  crossroad: {
    list: number[];
    system: any;
  };
}

const Crossroad: FC<ICrossroad> = ({ crossroad }) => {
  return (
    <ul>
      {crossroad.list.map((id) => (
        <li key={id}>
          <LinkedDeviceItem id={id} system={crossroad.system} />
        </li>
      ))}
    </ul>
  );
};

export default Crossroad;
