import { useEffect, useState } from 'react';

import rootStore from '../../../../../../../../../stores/rootStore/rootStore';
import { IDataList } from '../../../../../../../../DetailedStatistics/Monitoring/model/monitoring.model';
import { getFormattedListDevices } from '../../../../../../../../DetailedStatistics/Monitoring/SystemMonitoring/helpers/helpers';
import useGeocode from '../../../../../../../../DetailedStatistics/Monitoring/SystemMonitoring/hooks/useGeocode';
import getDeviceData from '../helpers/getDeviceData';

const useTLInfo = (tlIds: number[]) => {
  const { tls } = rootStore.mapDataStore;

  const [dataList, setDataList] = useState<IDataList[]>([]);

  const { addressInfo, isLoading } = useGeocode();

  useEffect(() => {
    const deviceData = getDeviceData(tls, tlIds);

    setDataList(
      getFormattedListDevices({
        deviceData,
        addressInfo,
      })
    );
  }, [tls, tlIds, addressInfo]);

  return { dataList, addressLoading: isLoading };
};

export default useTLInfo;
