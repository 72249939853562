import { QueryParamItem } from '../../../../../../ts/models/table.model';
import { Options } from '../SearchForm';

export const getOptionsByAssociativeKey = (
  param: QueryParamItem,
  formValues: Record<string, any>
): Options => {
  if (!param.webElementAssociativeOptions || !param.associativeKey) return [];

  const associativeKey = param.associativeKey;

  const associativeValue = formValues[associativeKey];

  if (!associativeValue) return [];

  const isArray = Array.isArray(associativeValue);

  if (!isArray) {
    return param.webElementAssociativeOptions[associativeValue.toString()];
  }

  if (!associativeValue.length) return [];

  return associativeValue.reduce((acc: Options, value) => {
    const parsedOptions =
      //@ts-ignore
      param.webElementAssociativeOptions[value.toString()];

    return [...acc, ...parsedOptions];
  }, []);
};
