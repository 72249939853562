import { useEffect, useMemo, useState } from 'react';

import { LibraryKeys } from '../../../../../api/libraries/enums/enums';
import { findById } from '../../../../../helpers/findBy';
import { getSystemTitle } from '../../../../../helpers/getSystemTitle';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { System } from '../../../../../ts/enums/enums';
import { Detector } from '../../../../../ts/models/mapObject.model';
import { TL } from '../../../../../ts/models/tl.model';
import { IDtInfo, getGeneralDtInfo } from '../helpers/getGeneralDtInfo';
import { getSystemName } from '../helpers/getSystemName';

const { SystemTypeId } = LibraryKeys;

interface IUseLinkedTLProps {
  detectors: Detector[];
  dtId: N<number>;
  tls: TL[];
  appPath: string;
}

const NO_LINKED_TLS = 'Нет привязанных СО';

const useLinkedTL = ({ detectors, dtId, tls, appPath }: IUseLinkedTLProps) => {
  const systemTypeDict = rootStore.uiStore.dictionaries[SystemTypeId];

  const [dtInfo, setDtInfo] = useState<IDtInfo[]>([]);

  const { dt, linkedTl } = useMemo(() => {
    if (!dtId) return { dt: null, linkedTl: null };
    const dt: U<Detector> = findById(detectors, dtId);
    const linkedTl = dt?.linkedDeviceIds?.trafficLights ?? null;

    return { dt, linkedTl };
  }, [detectors, dtId]);

  const { titleTl, linkTl } = useMemo(() => {
    if (!linkedTl) return { titleTl: NO_LINKED_TLS, linkTl: null };
    const linkTl = findById(tls, linkedTl);

    const titleTl = linkTl
      ? `${getSystemTitle(System.Lights, linkTl)} 
      ${linkTl?.deviceInfo.caption ?? ''}`
      : NO_LINKED_TLS;

    return { titleTl, linkTl };
  }, [linkedTl, tls]);

  const systemName = useMemo(
    () => getSystemName(dt?.eputsAdapter?.scSystemId, appPath),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dt?.id, appPath]
  );

  useEffect(() => {
    dt && setDtInfo(getGeneralDtInfo(dt, systemTypeDict));
  }, [dt, systemName, systemTypeDict]);

  useEffect(() => {
    systemName.then((value) => {
      if (!value) return;

      setDtInfo((prev) => [
        ...prev,
        {
          label: 'Название системы',
          value,
        },
      ]);
    });
  }, [systemName]);

  return { titleTl, linkTl, dt, dtInfo };
};

export default useLinkedTL;
