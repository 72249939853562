import { Spin } from 'antd';
import { FC, useState } from 'react';

import styles from './PdfViewer.module.scss';

export interface IViewer {
  fileUrl: any;
}

const PdfViewer: FC<IViewer> = ({ fileUrl }) => {
  const [isLoading, setIsloading] = useState(true);

  return (
    <>
      {isLoading && <Spin size="large" />}
      <iframe
        title="Pdf viewer"
        src={fileUrl}
        className={styles.iframe}
        onLoad={() => setIsloading(false)}
      />
    </>
  );
};

export default PdfViewer;
