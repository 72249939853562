import { ActualMapObj, System } from '../ts/enums/enums';
import { DeviceTypeId } from '../ts/models/mapObject.model';

export const DEVICE_TYPE_IDS: DeviceTypeId[] = [2, 3, 4, 6];

export const DEVICE_BY_TYPE_ID: Record<DeviceTypeId, ActualMapObj> = {
  2: ActualMapObj.tls,
  3: ActualMapObj.cameras,
  4: ActualMapObj.detectors,
  6: ActualMapObj.meteo,
};

export const SYSTEM_BY_TYPE_ID: Record<DeviceTypeId, System> = {
  2: System.Lights,
  3: System.Cameras,
  4: System.Detectors,
  6: System.Meteo,
};
