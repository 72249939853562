import { IRequestSender } from '../../../../../components/TrafficLightDetailed/TlManagement/model/postCommands.model';
import { VERSION } from '../../../../../constants/versions';
import { getAppPathHeaders } from '../../../../helpers/getAppPathHeaders';
import http from '../../../../http';

const postCommandTlApi = {
  postCommand: async (
    id: number,
    requestSender: IRequestSender,
    appPath: string
  ) =>
    http.post(`${VERSION}/objects/tl/${id}/command`, requestSender, {
      headers: getAppPathHeaders(appPath),
    }),
};

export default postCommandTlApi;
