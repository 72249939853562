import { useEffect, useState } from 'react';

import setDisplayStyle from '../../../../helpers/setDisplayStyle';
import rootStore from '../../../../stores/rootStore/rootStore';
import { TL } from '../../../../ts/models/tl.model';
import { POINTS_AMOUNT } from '../../constants/constants';
import handlePointsInExtent from '../../helpers/handlePointsInExtent';
import { useOverlays } from '../../hooks';
import formatTls from '../helpers/formatTls';
import { setPopupsRefsData } from '../helpers/helpers';

const usePhaseCircleOverlays = () => {
  const { tls } = rootStore.mapDataStore;
  const { map } = rootStore.mapStore;
  const { setPointsValue, isPhaseCircle } = rootStore.pointsUdsStore;

  const { refs, popups } = useOverlays(map, POINTS_AMOUNT);

  const [activeTls, setActiveTls] = useState<TL[]>([]);

  useEffect(() => {
    const handleTls = handlePointsInExtent(tls, refs);

    const handleActiveTls = () => {
      const { pointsInExtent, newPoints } = handleTls();

      if (!pointsInExtent || !newPoints) return;

      const formattedTls = formatTls(newPoints);

      setPopupsRefsData(formattedTls, popups, refs);
      setActiveTls(newPoints);
      setPointsValue('tlsInExtent', pointsInExtent);
    };

    if (isPhaseCircle && map) {
      handleActiveTls();
      map.on('moveend', handleActiveTls);
    } else {
      setDisplayStyle(refs);
      setActiveTls([]);
    }

    return () => {
      map && map.un('moveend', handleActiveTls);
    };
  }, [map, tls, isPhaseCircle, refs, setPointsValue, popups]);

  useEffect(() => {
    !isPhaseCircle && setPointsValue('tlsInExtent', []);
  }, [isPhaseCircle, setPointsValue]);

  return { tls: activeTls, refs };
};

export default usePhaseCircleOverlays;
