import detectorsDataApi from '../../../../api/mapObjects/detectors/data/detectorsData';
import { LaneParams } from '../../../../ts/models/constructor.model';
import { LaneDirectionKey } from '../../../Constructor/TrafficLaneConstructor/TLaneConstructor.model';
import { INTERVAL_DATES } from '../../../TrafficLightDetailed/TlDetectors/constants/constants';
import { getSliceDates } from '../../../TrafficLightDetailed/TlDetectors/IntancyGraph/helpers/getSliceDates';
import { getTimeZones } from '../../../TrafficLightDetailed/TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeZone';
import {
  DETECTOR_DIRECTION,
  USE_VEHICLES_AMOUNT_PATH,
} from '../constants/constants';
import { LanesAmountData } from '../TLanesBlock.model';

const { CAMERA_IN, CAMERA_OUT } = DETECTOR_DIRECTION;
const { ToCamera } = LaneDirectionKey;

const { fetchDetectorsData } = detectorsDataApi;

const fetchVehiclesAmount = async (
  id: number,
  directionKey: LaneDirectionKey,
  laneParams: LaneParams[],
  isMain: boolean
) => {
  const lanesNumbers = laneParams?.reduce((res: number[], { number }) => {
    number && res.push(number);

    return res;
  }, []);

  if (!isMain && !lanesNumbers?.length) {
    return { lanesAmountData: {}, vehiclesAmount: undefined };
  }

  const selectedDates = getSliceDates(1, {
    timeType: 'h',
    isMinuteAccuracy: true,
  });

  const timeZone = getTimeZones().timeZoneRegion;
  const direction = directionKey === ToCamera ? CAMERA_IN : CAMERA_OUT;

  const lanesRes = await fetchDetectorsData({
    ids: [id],
    appPath: USE_VEHICLES_AMOUNT_PATH,
    interval: INTERVAL_DATES.HOUR,
    selectedDates,
    timeZone,
    zones: isMain ? [] : lanesNumbers,
    directions: [direction],
  });

  let vehiclesAmount = 0;

  const filteredLanes: U<LanesAmountData> = lanesRes?.data
    ?.at(-1)
    ?.info?.reduce((res: LanesAmountData, cur) => {
      res[cur.lane] = cur.passage_count;
      vehiclesAmount += cur.passage_count;

      return res;
    }, {});

  return {
    lanesAmountData: filteredLanes ?? {},
    vehiclesAmount: filteredLanes && vehiclesAmount,
  };
};

export default fetchVehiclesAmount;
