import { COLORS } from '../../../../../constants/colorsConstants';

export const HISTORY_COLUMNS = [
  {
    title: 'Дата изменения',
    dataIndex: 'date',
    key: 'date',
    width: 180,
  },
  {
    title: 'Пользователь',
    dataIndex: 'user',
    key: 'user',
  },
];

export const CUSTOM_TABLE_THEME = {
  Table: {
    colorBgContainer: COLORS.BLACK900,
  },
};
