import { IWeatherLayerItem } from '../../Map/IWeatherTimeModel';

import styles from './WeatherButton.module.scss';

const PLATE_BORDER_RADIUS = 6;

const plateStyle = (num: number, count: number, dark: boolean) => {
  const baseColors = {
    color: 'var(--white)',
    background: 'rgba(79, 81, 100, 1)',
  };

  const leftPlateStyle = {
    borderTopLeftRadius: PLATE_BORDER_RADIUS,
    borderBottomLeftRadius: PLATE_BORDER_RADIUS,
    ...baseColors,
  };

  const rightPlateStyle = {
    borderTopRightRadius: PLATE_BORDER_RADIUS,
    borderBottomRightRadius: PLATE_BORDER_RADIUS,
    ...baseColors,
  };

  let style = {
    ...baseColors,
  };

  if (num === 0) {
    style = leftPlateStyle;
  }

  if (num === count - 1) {
    style = rightPlateStyle;
  }

  if (!dark) {
    style.background = 'rgba(188,188,188,1)';
  }

  return style;
};

export const WeatherPlate = (props: {
  onClick: () => void;
  item: IWeatherLayerItem;
  currentWeatherLayer: IWeatherLayerItem;
  dark: boolean;
  num: number;
  count: number;
}) => (
  <>
    <div
      onClick={props.onClick}
      className={
        props.item.propertyName === props.currentWeatherLayer.propertyName
          ? styles.weatherPlateSelected
          : styles.weatherPlate
      }
      style={plateStyle(props.num, props.count, props.dark)}
      aria-hidden="true"
    >
      <div className={styles.weatherPlateContainer}>
        <img
          alt={props.item.text || ''}
          src={props.item.imageSrc}
          className={styles.weatherPlateImg}
        />

        <div className={styles.weatherPlateText}>{props.item.buttonText}</div>
      </div>
    </div>
  </>
);
