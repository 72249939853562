/* eslint-disable camelcase */
import { VERSION } from '../../../../constants/versions';
import { getAppPathHeaders } from '../../../helpers/getAppPathHeaders';
import getLinearArray from '../../../helpers/getLinearArray';
import http from '../../../http';
import tryCatchWrapper from '../../../tryCatchWrapper';

const GRAPH_API = `${VERSION}/integration/gis`;

const REQUEST_PARAMS = {
  isExternalError: true,
  errorMessage: 'Ошибка получения доступных узлов',
  ignoreCodes: [423],
};

const hours = getLinearArray(24, 0);
const days = getLinearArray(7, 1);

const graphApi = {
  getVertexes: (appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.post(`${GRAPH_API}/vehicle-source`, {
        headers: getAppPathHeaders(appPath),
      });

      return res?.data;
    }, REQUEST_PARAMS),
  getLinks: (
    appPath: string,
    ids: number[],
    dateStart: string,
    dateEnd: string
  ) =>
    tryCatchWrapper(
      async () => {
        const res = await http.post(`${GRAPH_API}/vehicle-track-points`, {
          date_begin: dateStart,
          date_end: dateEnd,
          mc_vehicle_source_id_arr: ids,
          of_the_week_day_arr: days,
          of_the_hour_arr: hours,
        });

        return res?.data;
      },
      {
        ...REQUEST_PARAMS,
        errorMessage: 'Ошибка получения доступных узлов',
        ignoreCodes: [423],
      }
    ),
};

export default graphApi;
