import { System } from '../../../ts/enums/enums';
import { TrafficLane } from '../../../ts/models/constructor.model';

interface SwitcherProps {
  segment: any;
  obj: any;
  id?: number | undefined;
  phasesData?: any;
  cashedIMGs?: any;
  loading?: boolean;
  setIsOpenDetailed?: () => void;
  searchedValue?: string;
  searchedArea?: System[];
  item?: TrafficLane;
}

function Switcher(props: SwitcherProps) {
  const { segment, obj, ...otherProps } = props;
  const Handler = obj[segment];

  if (!Handler) {
    return <></>;
  }

  return <Handler {...otherProps} />;
}

export default Switcher;
