import { isNumber } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { findById } from '../../../../../helpers/findBy';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { TlMode } from '../../../../../ts/models/tl.model';
import { getModeImage } from '../helpers/getModeImage';

const PLAN_NOT_FOUND = 'План не найден';
const TL_NOT_FOUND = 'CO не найден';

const useManagementCommands = (id: number) => {
  const { tls } = rootStore.mapDataStore;

  const [radioValue, setRadioValue] = useState<number>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isImgLoading, setIsImgLoading] = useState<boolean>(true);
  const [isClickedBtn, setIsClickedButton] = useState<boolean>(false);

  const imgRef = useRef<string>('');
  const [selected, setSelected] = useState<number>(0);

  const mode = useMemo(() => findById(tls, id)?.tlStatus.mode, [tls, id]);

  const { images, sgnProgramName, tlName } = useMemo(() => {
    const currentObject = findById(tls, id);
    const images =
      currentObject?.dai_jdt83?.images?.filter((el) => !el.phase) ?? [];
    const sgnProgramName =
      currentObject?.tlStatus.command?.full ?? PLAN_NOT_FOUND;

    const tlName = currentObject
      ? `${currentObject.deviceInfo.externalDeviceId} ${currentObject.caption}`
      : TL_NOT_FOUND;

    return {
      images,
      sgnProgramName,
      tlName,
    };
  }, [id, tls]);

  const getPhasesImage = useCallback(() => {
    if (radioValue === undefined) return;

    imgRef.current = getModeImage(images, radioValue);
    setIsImgLoading(true);
  }, [images, radioValue, setIsImgLoading]);

  const onClickPhase = useCallback(
    (value: TlMode) => {
      setRadioValue(value);
      setIsClickedButton(true);
      setIsImgLoading(false);
    },
    [setRadioValue]
  );

  useEffect(() => {
    getPhasesImage();
  }, [getPhasesImage]);

  useEffect(() => {
    isNumber(mode) && setSelected(mode);
  }, [mode]);

  useEffect(() => {
    if (!isClickedBtn || !isImgLoading) return;

    setOpenModal(true);
    setIsClickedButton(false);
  }, [isImgLoading, isClickedBtn]);

  useEffect(() => {
    !openModal && setRadioValue(undefined);
  }, [openModal, setRadioValue]);

  return {
    radioValue,
    sgnProgramName,
    tlName,
    imgRef,
    selected,
    openModal,
    isImgLoading,
    setOpenModal,
    onClickPhase,
  };
};

export default useManagementCommands;
