// This is a test module, most of the logic in the future should move to the backend side.
// Therefore, it was decided not to pay more attention to typing

import tryCatchWrapper from '../tryCatchWrapper';

import { createAuthorizationHash } from './helpers/createAuthorizationHash';

// const XEOMA_VOR = 'http://192.168.10.155:10090/';
const {
  REACT_APP_XEOMA_LOGIN,
  REACT_APP_XEOMA_PASSWORD,
  REACT_APP_XEOMA_MJPEG_LOGIN,
  REACT_APP_XEOMA_MJPEG_PASSWORD,
} = process.env;

const XEOMA = 'http://192.168.102.240:10090/';
const LOGIN = XEOMA + '?loginpage=';
const CAMERAS = XEOMA + '?get_sources_list_view_cameras_data=';
const WEBRTS = XEOMA + 'webrtc?cmd=connect&';
const WEBRTC_OFFER = XEOMA + 'webrtc?cmd=createoffer&';
const WEBRTC_ANSWER = XEOMA + 'webrtc?cmd=setanswer&';
const WEBRTC_MESSAGE = XEOMA + 'webrtc?cmd=getmessage&';
const WEBRTC_CANDIDATE = XEOMA + 'webrtc?cmd=addicecandidate&';
const WEBRTC_ID = '&webrtcid=';

const password = REACT_APP_XEOMA_PASSWORD ?? '';
const login = REACT_APP_XEOMA_LOGIN ?? '';

const mjpegLogin = REACT_APP_XEOMA_MJPEG_LOGIN ?? '';
const mjpegPassword = REACT_APP_XEOMA_MJPEG_PASSWORD ?? '';

const xeomaApi = {
  createAuthentication: (keyAuth: string) =>
    tryCatchWrapper(async () => {
      const options: RequestInit = {
        method: 'POST',
        body: JSON.stringify({
          authKey: keyAuth,
          authToken: createAuthorizationHash({ keyAuth, login, password }),
          isNeedGetSessionKeyInBody: true,
        }),
      };

      const res = await fetch(LOGIN, options);
      const resObject: any = await res.json();

      const sessionKey = resObject['sessionkey'];

      return sessionKey;
    }),
  getCameras: (sessionKey: string) =>
    tryCatchWrapper(async () => {
      const res = await fetch(CAMERAS, {
        method: 'POST',
        body: JSON.stringify({
          typeSourceCamera: 'webrtc',
          sessionkey: sessionKey,
        }),
      });

      const viewCamerasResObj: any = await res.json();
      const camerasList = viewCamerasResObj['arrayCameras'];

      return camerasList;
    }),
  fetchWebRtcConnect: (streamCameraHref: string) =>
    tryCatchWrapper(async () => {
      const res = await fetch(WEBRTS + streamCameraHref);

      return res;
    }),
  fetchWebRtcOffer: (streamCameraHref: string, webRtcID: string) =>
    tryCatchWrapper(async () => {
      await fetch(WEBRTC_OFFER + streamCameraHref + WEBRTC_ID + webRtcID);
    }),
  createWebRtcAnswer: (
    streamCameraHref: string,
    webrtcId: string,
    sessionDescription: any
  ) =>
    tryCatchWrapper(async () => {
      await fetch(WEBRTC_ANSWER + streamCameraHref + WEBRTC_ID + webrtcId, {
        method: 'POST',
        body: JSON.stringify(sessionDescription),
      });
    }),
  fetchWebRtcMessage: (streamCameraHref: string, webrtcId: string) =>
    tryCatchWrapper(async () => {
      const res = await fetch(
        WEBRTC_MESSAGE + streamCameraHref + WEBRTC_ID + webrtcId
      );

      return res;
    }),
  updateWebRtcIceCandidate: (
    streamCameraHref: string,
    webrtcId: string,
    candidate: any
  ) =>
    tryCatchWrapper(async () => {
      await fetch(WEBRTC_CANDIDATE + streamCameraHref + WEBRTC_ID + webrtcId, {
        method: 'POST',
        body: JSON.stringify(candidate),
      });
    }),
  getModules: async () => {
    const res = await fetch(
      `${XEOMA}api?login=${mjpegLogin}&password=${mjpegPassword + '$'}&modules=`
    );

    if (!res) return;

    return await res.json();
  },
  getSettings: async (transmitter: string) => {
    const res = await fetch(
      `${XEOMA}api?login=${mjpegLogin}&password=${
        mjpegPassword + '$'
      }&settings=${transmitter}&get=`
    );

    if (!res) return;

    return res.json();
  },
};

export default xeomaApi;
