import type { Feature } from 'ol';
import type { Point } from 'ol/geom';
import { useEffect } from 'react';

import { TMap } from '../../../../../../../stores/mapStore/mapStore.model';
import rootStore from '../../../../../../../stores/rootStore/rootStore';
import { FeaturesTypes } from '../../../../../../../ts/enums/enums';
import { IClusterSource } from '../../../../../../ui-kit/MapLayers/ClusterLayers/ChartClusterLayer';
import {
  getIconZoomRatio,
  getOpenClusterStyle,
  getStyleForCluster,
  StyleCache,
} from '../../../../../../ui-kit/MapLayers/ClusterLayers/helpers/clusterStyles';
import { getSelectInteraction } from '../../../../../../ui-kit/MapLayers/ClusterLayers/helpers/getSelectInteraction';
import { updateFeatures } from '../../../../../../ui-kit/MapLayers/ClusterLayers/helpers/updateFeatures';

interface UseFeatureStylesProps {
  map: TMap;
  features: Feature<Point>[];
  activeId: number;
  clusterSource: IClusterSource;
}

const useFeatureStyles = ({
  map,
  features,
  activeId,
  clusterSource,
}: UseFeatureStylesProps) => {
  const { mapIconsSize, isNeedOpenedCluster } = rootStore.uiStore;

  useEffect(() => {
    if (!map) return;

    const sourceSelect = getSelectInteraction(map)?.getLayer();

    const size = getIconZoomRatio(mapIconsSize);
    const styleCache: StyleCache = {};

    sourceSelect?.setStyle((feature) =>
      getOpenClusterStyle({
        features: feature,
        infoDataId: activeId,
        scale: size,
        styleCache,
      })
    );
  }, [activeId, map, mapIconsSize]);

  useEffect(() => {
    if (!clusterSource.clusterAnimated) return;

    const styleCache: StyleCache = {};

    const scale = getIconZoomRatio(mapIconsSize);

    clusterSource.clusterAnimated.setStyle((feature) => {
      const cluster: Feature<Point>[] =
        feature.get(FeaturesTypes.Features) ?? [];

      updateFeatures(cluster, features);

      return getStyleForCluster({
        feature,
        activeId,
        styleCache,
        scale,
        isColorActive: true,
      });
    });
  }, [
    features,
    clusterSource,
    map,
    mapIconsSize,
    activeId,
    isNeedOpenedCluster,
  ]);
};

export default useFeatureStyles;
