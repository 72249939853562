import { FC } from 'react';

import { PointUds } from '../../../../../../../ts/models/pointUds.model';
import LinkedDetectors from '../../CrossroadSchema/LinkedDevices/LinkedDetectors/LinkedDetectors';
import { HandleClickDevice } from '../models/crossroadSchema.model';

import LinkedCameras from './LinkedCameras/LinkedCameras';
import LinkedMeteo from './LinkedMeteo/LinkedMeteo';
import LinkedTls from './LinkedTls/LinkedTls';

interface LinkedDevicesProps {
  rate: number;
  linkedDevices: Partial<PointUds['basicProfileInfo']['linkedDeviceIds']>;
  id: number;
  isSchemaODD: boolean;
  handleClickDevice?: HandleClickDevice;
}

const LinkedDevices: FC<LinkedDevicesProps> = ({
  linkedDevices,
  rate,
  id,
  isSchemaODD,
  handleClickDevice,
}) => {
  const { detectors, meteo, trafficLights, cameras } = linkedDevices;

  const basicProps = {
    activeId: id,
    rate,
    handleClickDevice,
  };

  return (
    <>
      <LinkedCameras {...basicProps} linkedCams={cameras ?? []} />
      <LinkedDetectors
        {...basicProps}
        linkedDts={detectors ?? []}
        isSchemaODD={isSchemaODD}
      />
      <LinkedTls {...basicProps} tlId={trafficLights ?? null} />
      <LinkedMeteo {...basicProps} mtIds={meteo ?? null} />
    </>
  );
};

export default LinkedDevices;
