import {
  BasicMapDataZod,
  IBasicMapData,
} from '../../ts/models/mapObject.model';

export const formattedMapData = <T extends BasicMapDataZod>(
  data: T[]
): U<(T & IBasicMapData)[]> => {
  return data.map((el) => ({
    ...el,
    caption: el.deviceInfo.caption,
    latitude: el.deviceInfo.latitude,
    longitude: el.deviceInfo.longitude,
  }));
};
