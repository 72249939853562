import { observer } from 'mobx-react-lite';
import { RefObject } from 'react';

import { QUERY_KEY } from '../../../constants/queryConstants';
import { findById } from '../../../helpers/findBy';
import useRemoveQueries from '../../../hooks/useRemoveQueries';
import rootStore from '../../../stores/rootStore/rootStore';
import PointUdsOverlay from '../PointUdsOverlay/PointUdsOverlay';

import useDeviceLinksChecking from './hooks/useDeviceLinksChecking';
import useIncorrectDeviceCoordinate from './hooks/useIncorrectDeviceCoordinate';
import usePhaseCircleOverlays from './hooks/usePhaseCircleOverlays';
import usePointUdsOverlays from './hooks/usePointUdsOverlays';

import styles from './PointUdsOverlays.module.scss';

const { VEHICLES_AMOUNT } = QUERY_KEY;

const PointUdsOverlays = () => {
  const { isCrossroadBorder } = rootStore.mapDataStore;
  const { pointsInExtent } = rootStore.pointsUdsStore;

  const { refs } = usePointUdsOverlays();
  const { tls } = usePhaseCircleOverlays();

  useDeviceLinksChecking();
  useIncorrectDeviceCoordinate();
  useRemoveQueries({ queryKey: VEHICLES_AMOUNT, isRemove: !isCrossroadBorder });

  return (
    <>
      {refs.map((el: RefObject<HTMLDivElement>, i: number) => {
        const point = findById(pointsInExtent, Number(el.current?.id ?? -1));
        const tlId = point?.basicProfileInfo.linkedDeviceIds?.trafficLights;
        const tl = findById(tls, tlId);

        return (
          <div className={styles.container} key={`pointUds_${i}`} ref={el}>
            <PointUdsOverlay pointUds={point} tl={tl} />
          </div>
        );
      })}
    </>
  );
};

export default observer(PointUdsOverlays);
