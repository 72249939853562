import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { FiInfo } from 'react-icons/fi';

import { VIEW_MODE } from '../../../../stores/gisDataStore/gisDataStore.model';
import rootStore from '../../../../stores/rootStore/rootStore';
import ButtonIco from '../../../ui-kit/ButtonIco/ButtonIco';
import Popover, { PopoverProps } from '../../../ui-kit/Popover/Popover';

import ContentInfo from './ContentInfo/ContentInfo';

import styles from './InfoBtn.module.scss';

const InfoBtn = () => {
  const { isInfoBtn, mapButtonsTipsDelay, isOffsetRightPanel } =
    rootStore.uiStore;
  const { viewMode } = rootStore.gisDataStore;

  const [isInfo, setIsInfo] = useState<boolean>(false);

  const handleIsInfo = () => setIsInfo(!isInfo);

  if (!isInfoBtn) return null;

  const tooltipText = `${
    !isInfo ? 'Показать' : 'Скрыть'
  } информацию об обозначении иконок карты?`;

  const popOverProps = {
    tipsDelay: mapButtonsTipsDelay,
    content: tooltipText,
    placement: 'left',
  } as PopoverProps;

  const mapInfoBtnStyles = classNames({
    [styles.mapInfoBtn]: true,
    [styles.isInfoPanel]: isOffsetRightPanel,
  });

  return (
    <>
      {viewMode === VIEW_MODE['2D'] ? (
        <>
          <Popover {...popOverProps}>
            <div className={mapInfoBtnStyles}>
              <ButtonIco
                isRound
                isHover
                isActive={isInfo}
                onClick={handleIsInfo}
              >
                <FiInfo />
              </ButtonIco>
            </div>
          </Popover>
          {isInfo && <ContentInfo />}
        </>
      ) : null}
    </>
  );
};

export default observer(InfoBtn);
