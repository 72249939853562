import { Card } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';
import { FiInfo } from 'react-icons/fi';

import { ReactComponent as SourceIco } from '../../../../assets/icons/internal_data.svg';
import { ReactComponent as MagistralIco } from '../../../../assets/icons/map_road.svg';
import { ReactComponent as ControlModeIco } from '../../../../assets/icons/settings_horizontal.svg';
import { TL_DETAILED } from '../../../../constants/tlDetailedConstants';
import { findBy, findById } from '../../../../helpers/findBy';
import rootStore from '../../../../stores/rootStore/rootStore';
import NoData from '../../../ui-kit/Errors/NoData/NoData';
import Popover from '../../../ui-kit/Popover/Popover';
import { GeneralInfo } from '../../models/tlMainInfo.model';
import { getTimeWithTimeZone } from '../../TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeWithTimeZone';

import styles from './TLGeneralInfo.module.scss';

const TLGeneralInfo: FC<GeneralInfo> = ({ mag, name, source }) => {
  const { tls } = rootStore.mapDataStore;
  const { interfaceTipsDelay, infoData } = rootStore.uiStore;
  const sourceNow =
    source !== null &&
    findBy(Object.values(TL_DETAILED.SOURCE), source, 'com')?.dsc;

  const activeTL = findById(tls, infoData?.id);

  const popoverProps = {
    content: 'Время обновления данных СО',
    tipsDelay: interfaceTipsDelay,
  };

  const updateData = useMemo(
    () => getTimeWithTimeZone().dateFormatted,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      activeTL?.tlStatus?.mode,
      activeTL?.tlGeneral?.mag,
      activeTL?.tlStatus?.source,
    ]
  );

  if (!activeTL)
    return (
      <Card className={styles.mainTLInfo}>
        <h3>Общая информация</h3>
        <NoData isNeedBackground={false} />
      </Card>
    );

  return (
    <Card className={styles.mainTLInfo}>
      <div className={styles.infheader}>
        <h3>Общая информация</h3>
        <div className={styles.timeStamp}>
          <p>{updateData}</p>
          <Popover {...popoverProps}>
            <FiInfo className={styles.iconInfo} size={20} />
          </Popover>
        </div>
      </div>
      <div className={styles.infcontent}>
        <div>
          <MagistralIco />
          <h4>Магистраль</h4>
          <hr />
          <p id={'inf_num_mag'}>{mag || 'Временно нет данных'}</p>
          <hr />
        </div>
        <div>
          <ControlModeIco />
          <h4>Режим управления</h4>
          <hr />
          <p id={'inf_desc_mode'} className={styles.infdescription}>
            {name}
          </p>
          <hr />
        </div>
        <div>
          <SourceIco />
          <h4>Источник состояния</h4>
          <hr />
          <p id={'inf_desc_source'} className={styles.infdescription}>
            {sourceNow ?? 'АСУДД ЦИРКОН'}
          </p>
          <hr />
        </div>
      </div>
    </Card>
  );
};

export default observer(TLGeneralInfo);
