import { MapObjectFull, System } from '../../ts/enums/enums';
import { ICamera } from '../../ts/models/camera.model';
import { Detector, IBasicMapData } from '../../ts/models/mapObject.model';
import { TMeteo } from '../../ts/models/MapObjects/meteo.model';
import { TL, TLData } from '../../ts/models/tl.model';

export type LinkedDevices = Record<MapObjectFull, number[]>;

export interface IMapExtendedData {
  object: any;
  detect: IBasicMapData[];
  camera: ICamera[];
}

export interface IWSItem {
  id: number;
}

export type MapDataArrays =
  | 'tls'
  | 'cameras'
  | 'detectors'
  | 'tlCurrentTacts'
  | 'meteo';

type Data = TLData & Pick<IBasicMapData, 'caption'>;

export interface TLRes {
  isOk: boolean;
  data: Data[];
}

export interface ISystemsInfo {
  title: System;
  data: TObjMap[];
  isMarkers: boolean;
  isHaveDetailed: boolean;
}

export type TObjMap = IBasicMapData & (TL | ICamera | Detector | TMeteo);

export interface ICurObjWs {
  currentObject: N<IWSItem>;
  curInd: N<number>;
}

export type TSystemsInfo = Record<System, ISystemsInfo>;

export type TInfoDataSystem = System;

export enum LonLat {
  Longitude = 'longitude',
  Latitude = 'latitude',
}
