import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import {
  CartesianGrid,
  Customized,
  Label,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { COLORS } from '../../../../constants/colorsConstants';
import DefaultChecked from '../../DefaultChecked/DefaultChecked';
import {
  LINES_WIDTH,
  MARGIN_GRAPH,
  NO_DATA_INTERVAL_TEXT,
  TICK_COUNT,
  X_KEY,
} from '../constants/constants';
import CustomedTick from '../CustomedTick/CustomedTick';
import CustomTooltip from '../CustomTooltip/CustomTooltip';
import LabelGraph from '../LabelGraph/LabelGraph';

import CandleRect from './CandleRect/CandleRect';
import { VALUE_KEY, Y_STATISTIC_KEY } from './constants/constants';
import getLineKeys from './helpers/getLineKeys';
import getTranslateKey from './helpers/getTranslateKey';
import useCandleChart from './hooks/useCandleChart';
import { UseCandleChartProps } from './models/CandleChart.model';

import styles from '../Chart.module.scss';

interface CandleChartProps extends UseCandleChartProps {
  yAxisName: string;
}

const CandleChart: FC<CandleChartProps> = ({ yAxisName, ...candleProps }) => {
  const {
    calcData,
    chartNames,
    isError,
    isFetching,
    activeIndex,
    onMouseMove,
    onMouseLeave,
  } = useCandleChart(candleProps);

  const valueKey = getTranslateKey(VALUE_KEY);

  const lineKeys = getLineKeys(chartNames, valueKey);

  return (
    <DefaultChecked
      isLoading={isFetching}
      isEmptyData={!calcData.length || isError}
      noDataProps={{
        isNeedBackground: false,
        textError: NO_DATA_INTERVAL_TEXT,
        classNameEmpty: styles.empty,
      }}
      isBackdropFilter
    >
      <div className={styles.graphContainer} style={{ userSelect: 'none' }}>
        <ResponsiveContainer>
          <LineChart
            data={calcData}
            margin={MARGIN_GRAPH}
            onMouseMove={onMouseMove}
            onMouseLeave={onMouseLeave}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey={X_KEY}
              allowDecimals={false}
              tick={(props) => (
                <CustomedTick
                  {...props}
                  isX
                  data={calcData}
                  labelKey={Y_STATISTIC_KEY}
                />
              )}
              type="number"
              allowDataOverflow
            >
              <Label value={''} content={<LabelGraph isX />} />
            </XAxis>
            <YAxis
              allowDataOverflow
              allowDecimals={false}
              tickCount={TICK_COUNT}
              tick={(props) => <CustomedTick {...props} isX={false} />}
            >
              <Label value={yAxisName} content={<LabelGraph isX={false} />} />
            </YAxis>
            <Tooltip
              content={(props) => (
                <CustomTooltip
                  {...props}
                  calcData={calcData}
                  keyValue={Y_STATISTIC_KEY}
                />
              )}
            />
            {lineKeys.map((el) => (
              <Line
                key={el}
                dataKey={el}
                strokeOpacity={0}
                dot={false}
                activeDot={false}
              />
            ))}
            {/* @ts-ignore */}
            <Customized
              component={(props) => (
                <CandleRect {...(props ?? {})} activeIndex={activeIndex} />
              )}
            />
            <Line
              dataKey={valueKey}
              stroke={COLORS.BLUE100}
              strokeOpacity={0.8}
              type="monotone"
              dot={false}
              strokeWidth={LINES_WIDTH}
              activeDot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </DefaultChecked>
  );
};

export default observer(CandleChart);
