import { uniqueId } from 'lodash';
import { observer } from 'mobx-react-lite';

import rootStore from '../../../../stores/rootStore/rootStore';
import AccordionSimple from '../../../ui-kit/AccordionSimple/AccordionSimple';
import SummaryInfoItem from '../../../ui-kit/SummaryInfoItem/SummaryInfoItem';

import { PARAMS_DATA } from './constants/constants';

const ParamsTab = () => {
  const { isSummaryInfo } = rootStore.uiStore;

  return (
    <AccordionSimple isClosed={!isSummaryInfo} title="Сводная информация">
      {PARAMS_DATA.map((data) => (
        <SummaryInfoItem key={uniqueId(`${data.title}_`)} data={data} />
      ))}
    </AccordionSimple>
  );
};

export default observer(ParamsTab);
