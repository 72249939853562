import { Card, DatePicker, Select } from 'antd';
import type { RangePickerTimeProps } from 'antd/es/time-picker';
import { Dayjs } from 'dayjs';
import React, { useState } from 'react';

import { NODE_MERGE_OPTIONS, PANEL_STYLE } from './constants';

import styles from './TrafficGraphButton.module.scss';

const { RangePicker } = DatePicker;

const GraphSettingsPanel = (props: {
  onChange: (value: ((prevState: string) => string) | string) => void;
  mergeMethod: string;
  dateStart: string | undefined;
  dateEnd: string | undefined;
  onDateIntervalChange: (value: string[]) => void;
}) => {
  const [dateInterval] = useState<
    RangePickerTimeProps<Dayjs> | null | undefined
  >(undefined);

  return (
    <Card className={styles.trafficGraphPanel} styles={{ body: PANEL_STYLE }}>
      <div>Граф корреспонденции</div>

      <div className={styles.title}>Способ объединения узлов</div>

      <Select
        className={styles.mergeTypeField}
        onChange={props.onChange}
        options={NODE_MERGE_OPTIONS}
        value={props.mergeMethod}
      />

      <div className={styles.title}>Период</div>
      <div className={styles.timeSelectorsContainer}>
        <RangePicker
          // @ts-ignore
          value={dateInterval}
          showTime={{ format: 'HH:mm' }}
          format="YYYY-MM-DD HH:mm"
          onChange={(value, interval) => {
            props.onDateIntervalChange(interval);
          }}
        />
      </div>
    </Card>
  );
};

export default GraphSettingsPanel;
