import { FC } from 'react';

import styles from './Title.module.scss';

interface TitleProps {
  title: string;
}

const Title: FC<TitleProps> = ({ title }) => {
  return <p className={styles.title}>{title}</p>;
};

export default Title;
