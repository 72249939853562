/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Tag } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { TDeviceStatus } from '../../../../../../../constants/devicesConstants';
import { findBy } from '../../../../../../../helpers/findBy';
import rootStore from '../../../../../../../stores/rootStore/rootStore';
import { getDeviceColor } from '../../../../../../MapBtns/MapInfoWrapper/InfoBtn/ContentInfo/helpers/getMapInfo';
import { TStatusDevices } from '../DropdownStatus';

import styles from './DropdownItem.module.scss';

interface IDropdownItemProps {
  statusInfos: TStatusDevices;
  statusId: TDeviceStatus;
  systemsLen: number;
}

const DropdownItem: FC<IDropdownItemProps> = ({
  statusInfos,
  statusId,
  systemsLen,
}) => {
  const {
    activeSystemMonitoring,
    statusFilteredMonitoring,
    setStatusFilteredMonitoring,
  } = rootStore.monitoringPanelStore;

  if (!activeSystemMonitoring) return null;

  const handleClick: React.MouseEventHandler<HTMLDivElement> = () => {
    setStatusFilteredMonitoring(statusId, systemsLen);
  };

  const isActive = Boolean(
    findBy(statusFilteredMonitoring, statusId, 'status')
  );

  const cardStyle = classNames({
    [styles.statusCard]: true,
    [styles.active]: isActive,
  });

  return (
    <div className={cardStyle} onClick={handleClick}>
      <Tag color={getDeviceColor(statusId)} className={styles.tag}>
        {systemsLen}
      </Tag>
      <p>{statusInfos[statusId]}</p>
    </div>
  );
};

export default observer(DropdownItem);
