import { isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import rootStore from '../../../../../stores/rootStore/rootStore';
import { IHeaderItem, IPhasesData } from '../../PhaseHold/PhaseHold.model';
import { setTableData } from '../../TemporaryPlan/EditSgnProgramTable/helpers/setTableData';
import { UseEditModalProps } from '../model/currentPlan.model';

import useCurrentProgram from './useCurrentProgram';
import useEditCurrentProgram from './useEditCurrentProgram';

const useTemporaryPlanTableEdit = (id: number) => {
  const { setIsNotDetailed } = rootStore.detailedStore;

  const [dataTable, setDataTable] = useState<IPhasesData[]>([]);
  const [isEditTable, setIsEditTable] = useState<boolean>(false);
  const [isValidateTable, setIsValidateTable] = useState<boolean>(true);
  const [columns, setColumns] = useState<IHeaderItem[]>([]);

  const { sgnProgramValue, isError } = useCurrentProgram(id);

  const setDefaultDataTable = useCallback(() => {
    setColumns([]);
    if (!sgnProgramValue || isError) return;

    const { csdData, phasesSet } = sgnProgramValue;

    const res = setTableData(csdData, phasesSet);

    setDataTable(res);
    setIsValidateTable(true);
  }, [isError, sgnProgramValue]);

  const { sgnProgramName, baseProgramProps, editProps, setOpenEditForm } =
    useEditCurrentProgram({
      id,
      isEditTable,
      csdData: sgnProgramValue?.csdData,
      dataTable,
      setDefaultDataTable,
    });

  useEffect(() => {
    setDefaultDataTable();
  }, [setDefaultDataTable]);

  useEffect(() => {
    if (!dataTable.length || !sgnProgramValue) return;

    const { csdData, phasesSet } = sgnProgramValue;

    const defaultDataTable = setTableData(csdData, phasesSet);

    const isChanged = !isEqual(dataTable, defaultDataTable);

    setIsEditTable(isChanged && isValidateTable);
  }, [sgnProgramValue, dataTable, isValidateTable]);

  useEffect(() => {
    setIsNotDetailed('isDisabledControlCmd', !isEditTable);
  }, [isEditTable, setIsNotDetailed]);

  const editTableProps: UseEditModalProps = {
    baseProgramProps,
    isValidateTable,
    defaultPhasesSet: sgnProgramValue?.phasesSet ?? [],
    dataTable,
    setDataTable,
    setIsValidateTable,
    columns,
    setColumns,
  };

  const clearPlanSelect = useCallback(() => {
    setIsEditTable(false);
    setIsValidateTable(true);
    setDataTable([]);
  }, []);

  return {
    sgnProgramName,
    dataTable,
    isError,
    setOpenEditForm,
    editProps,
    editTableProps,
    clearPlanSelect,
  };
};

export default useTemporaryPlanTableEdit;
