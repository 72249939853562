import { FC } from 'react';

import PhasesContainer from '../../PhasesContainer/PhasesContainer';
import CurrentPlan from '../CurrentPlan/CurrentPlan';

interface ControlItemProps {
  label: string;
  key: string;
  children: FC<{
    tlId: number;
    sec: number;
  }>;
}

export const DETAILED_CONTROL_ITEMS: ControlItemProps[] = [
  {
    label: 'Фазы СО',
    key: 'phases',
    children: PhasesContainer,
  },
  {
    label: 'Текущий план',
    key: 'currentPlan',
    children: CurrentPlan,
  },
];
