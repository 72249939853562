export enum CircleElEnum {
  Gathered = 'gathered',
  Top = 'top',
  Bottom = 'bottom',
  Direct = 'direct',
  Right = 'right',
  Left = 'left',
  DirectRight = 'direct-right',
  DirectLeft = 'direct-left',
  Crosswalk = 'crosswalk',
}

export enum TLPhaseCodes {
  Undefined,
  Common,
  Phase,
  AllRed = 31,
  Edit,
  Off,
  AllYellow,
}

export const IMG_PATH = '/media';

export enum DirectionTypes {
  Transport = 0,
  Rotary = 1,
  Pedestrian = 2,
  Tramway = 3,
  Special = 4,
  Unknown = 5,
  Errors = 6,
}
