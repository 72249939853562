import rootStore from '../../../../../../../stores/rootStore/rootStore';
import { FilteredTypes } from '../../../../../../TrafficLightDetailed/TlDetectors/constants/constants';
import { DISABLED_BTN_TEXT } from '../../../../CandleChart/constants/constants';
import { FilterDataValues } from '../../../../models/chart.model';

export const WARNING_TEXT =
  'Нельзя отключить фильтр - "Полосы" с включенной фильтрацией - "Типы ТС"';

const getContentBtnInfo = (
  isAllActive: boolean,
  isDisabledAll: boolean,
  title: string,
  isDisabled: boolean
) => {
  if (isDisabled) return DISABLED_BTN_TEXT;

  const contentBtn = `фильтр: "Все ${title.toLowerCase()}"`;
  const contentAll = `${isAllActive ? 'Отключить' : 'Включить'} ${contentBtn}`;

  return isDisabledAll ? `${WARNING_TEXT}` : contentAll;
};

export const getBtnInfo = (
  filterType: FilteredTypes,
  filterDtData: FilterDataValues,
  title: string
) => {
  const {
    disabledChartTypes: { vehicleTypes },
  } = rootStore.detailedStore;

  const isDisabledDirections =
    filterType === FilteredTypes.Directions &&
    !!filterDtData[FilteredTypes.VehicleTypes];

  const isAllActive = filterDtData[filterType]?.length === 0;

  const isDisabledItems =
    isDisabledDirections && filterDtData[filterType]?.length === 1;
  const isDisabledAll = (isDisabledDirections && isAllActive) || vehicleTypes;

  const contentAllBtn = getContentBtnInfo(
    isAllActive,
    isDisabledAll,
    title,
    vehicleTypes
  );

  const isShowAll =
    filterType !== FilteredTypes.Directions &&
    filterType !== FilteredTypes.Normative;

  return {
    isAllActive,
    isDisabledAll,
    isDisabledItems,
    contentAllBtn,
    isShowAll,
  };
};
