import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { FC, useState, useEffect } from 'react';

import { findById } from '../../../helpers/findBy';
import rootStore from '../../../stores/rootStore/rootStore';
import { MapActions } from '../../../stores/uiStore/uiStore.model';
import { System } from '../../../ts/enums/enums';
import CameraIcon from '../Camera/CameraIcon/CameraIcon';
import CameraProjection from '../Camera/CameraProjection/CameraProjection';
import VideoDetectorPointUds from '../Camera/VideoDetectorIcon/VideoDetectorPointUds';
import { CamerasProps, LinkedCamera } from '../models/camera.model';

const Cameras: FC<CamerasProps> = ({ linkedCams }) => {
  const { cameras, setMapData, detectors } = rootStore.mapDataStore;
  const { setInfoData, markers, infoData } = rootStore.uiStore;
  const [cams, setCams] = useState<LinkedCamera[]>([]);

  useEffect(() => {
    const newCams = linkedCams.reduce((acc: LinkedCamera[], id) => {
      const camera = toJS(findById(cameras, id));

      if (camera) {
        const cameraExtended: LinkedCamera = {
          ...camera,
          isExtended: false,
        };

        acc.push(cameraExtended);
      }

      return acc;
    }, []);

    setCams(newCams);

    return () => {
      setMapData('selectedCamera', null);
    };
  }, [linkedCams, cameras, setMapData]);

  const handleCameraClick = (id: number) => {
    const camera = findById(cams, id);

    if (!camera) return;

    const newCams = cams.map((cam) => {
      if (cam.id !== id) {
        id === infoData?.id && setInfoData(null);
        cam.isExtended = false;
      } else {
        const { longitude, latitude, id } = camera;

        camera.isExtended = !camera.isExtended;

        setMapData('selectedCamera', camera.isExtended ? camera : null);
        setInfoData(
          {
            system: System.Cameras,
            coordinate: [longitude, latitude],
            id,
          },
          MapActions.NoActions
        );
      }

      return cam;
    });

    const sortedCams = newCams.sort((a) => (a.isExtended ? -1 : 1));

    setCams(sortedCams);
  };

  if (!markers.isCameras && !markers.isDetectors) return null;

  return (
    <>
      {cams.map((camera) => {
        const { dai_jdt83 } = camera;

        if (!dai_jdt83) return null;

        return (
          <CameraProjection
            key={camera.id}
            camera={dai_jdt83}
            isExtended={camera.isExtended}
          />
        );
      })}

      {cams.map((camera) => {
        const { dai_jdt83 } = camera;

        if (!dai_jdt83) return null;

        const { linkedDetectorId } = dai_jdt83;
        const isDtExist = detectors.some((dt) => dt.id === linkedDetectorId);
        const key = `camIco_${camera.id}`;

        const props = {
          camera: dai_jdt83,
          onClick: () => handleCameraClick(camera.id),
        };

        if (isDtExist && linkedDetectorId) {
          return <VideoDetectorPointUds key={key} {...props} />;
        }

        return <CameraIcon key={key} {...props} />;
      })}
    </>
  );
};

export default observer(Cameras);
