type FindObject = Record<string, any>;

export const findById = <
  T extends FindObject,
  V extends T[K],
  K extends keyof T
>(
  arr: T[],
  val: V,
  key: 'id' = 'id'
) => arr.find((el) => el[key] === val);

export const findBy = <T extends FindObject, V extends T[K], K extends keyof T>(
  arr: T[],
  val: V,
  key: K
) => arr.find((el) => el[key] === val);
