import { FunctionComponent } from 'react';

import { TlJournalType } from '../../../../constants/detailedTabsConstant';
import { AccessCode, DocumentsCode } from '../../../../ts/enums/userData';
import Documents from '../../../TrafficLightDetailed/Documents/Documents';

export interface DetailedItemProps {
  id: number;
  curentActiveKey: string;
  type?: TlJournalType;
}

export interface PanelTabConfig {
  key: string;
  label: string;
  code: AccessCode;
  Component: FunctionComponent<DetailedItemProps>;
  isLoaded: boolean;
  type?: TlJournalType;
}

export const BASIC_DETAILED_ITEMS: PanelTabConfig[] = [
  {
    label: 'Документы',
    key: 'documents',
    code: DocumentsCode,
    Component: Documents,
    isLoaded: true,
  },
];
