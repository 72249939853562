import { QueryParamItem } from '../../../../ts/models/table.model';

import { getIsValidItem } from './getIsValidItem';

export const getSearchCount = (queryParams: QueryParamItem[]) => {
  const count = queryParams.reduce((sum, item) => {
    const isValid = getIsValidItem(item.jvalueType, item.currentValue);

    if (isValid) return sum + 1;

    return sum;
  }, 0);

  return count;
};
