import { notification } from 'antd';

import postCommandTlApi from '../../../../api/mapObjects/trafficLights/commands/postCommand/postCommandTl';
import NOTICE from '../../../../constants/notificationConstants';
import tryCatchWrapper from '../../../../helpers/tryCatchWrapper';
import { getTimeWithTimeZone } from '../../TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeWithTimeZone';
import {
  IParamsSender,
  IPostCommandProps,
  IRequestSender,
} from '../model/postCommands.model';

import { getRequestSenderData } from './getRequestSenderData';
import { validatePostCommand } from './validatePostCommand';

export const postCommand = tryCatchWrapper(
  async (postCommandProps: IPostCommandProps, appPath: string) => {
    const {
      id,
      command,
      cmdTitle,
      cmdInfo,
      selectedDate,
      durationTime,
      phasesValue,
      disabledTime = 0,
    } = postCommandProps;

    const date = selectedDate
      ? getTimeWithTimeZone({
          dateJS: selectedDate,
          isConvertToRegionTZ: true,
        }).dateFormattedJS.unix()
      : undefined;

    const editData: U<IParamsSender> = getRequestSenderData({
      cmdTitle,
      selectedDate: date,
      durationTime,
      phasesValue,
      command,
    });

    if (!editData) return notification.error(NOTICE.INCORRECT_COMMAND);

    const requestSender: IRequestSender = {
      commandInfo: {
        param2: '',
        param3: '',
        param4: '',
        cmd: cmdTitle,
        comment: cmdInfo,
        jparam1: null,
        ...editData,
      },
    };

    const isValidate = validatePostCommand(
      cmdTitle,
      disabledTime,
      selectedDate
    );

    if (!isValidate) return;

    await postCommandTlApi.postCommand(id, requestSender, appPath);
  }
);
