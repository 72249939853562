import { IUserData } from '../../../stores/userDataStore/userDataStore';

export const getInfoData = (user: IUserData) => {
  const { id, dsc, login, email, regDate } = user;

  const infoDataList = [
    {
      key: 'ID: ',
      value: id,
    },
    {
      key: 'Описание: ',
      value: dsc,
    },
    {
      key: 'Логин: ',
      value: login,
    },
    {
      key: 'Почта: ',
      value: email,
    },
    {
      key: 'Дата регистрации: ',
      value: regDate,
    },
  ];

  return infoDataList;
};
