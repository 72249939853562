import { FC } from 'react';

import { ChartBDKeys, Y_AXIS_LABELS } from '../constants/constants';

import styles from './InfoBlock.module.scss';

interface InfoBlockProps {
  activeDate: string;
  radioValue: ChartBDKeys;
}

const InfoBlock: FC<InfoBlockProps> = ({ activeDate, radioValue }) => {
  const title = Y_AXIS_LABELS[radioValue];
  const label = `${title} ${activeDate}`;

  return <p className={styles.timeTitle}>{label}</p>;
};

export default InfoBlock;
