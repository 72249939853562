import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import rootStore from '../../../../stores/rootStore/rootStore';
import ButtonVisibleEye from '../../../ui-kit/ButtonVisibleEye/ButtonVisibleEye';
import {
  DEFAULT_LAYERS_GROUP_TITLE,
  GROUP_VISIBILITY_STATES,
  VISIBLE_TOOLTIPS,
} from '../constants';
import { getGroupVisibilityStatus, toggleGroupVisibility } from '../helpers';
import { ILayerDataNode } from '../layers.model';

import styles from '../LayersButton.module.scss';

const {
  GROUP_LAYERS_NOT_VISIBLE,
  GROUP_LAYERS_VISIBLE,
  GROUP_LAYER_PARTIALLY_VISIBLE,
} = GROUP_VISIBILITY_STATES;

const LayerGroupNode = (props: { data: ILayerDataNode }) => {
  const { layersVisibilityProxy } = rootStore.gisDataStore;

  const { map, children } = props.data;

  const [visibleStatus, setVisibleStatus] = useState(GROUP_LAYERS_NOT_VISIBLE);

  useEffect(() => {
    const status = getGroupVisibilityStatus(map, children);

    setVisibleStatus(status);
  }, [layersVisibilityProxy, children, map]);

  const changeGroupVisibility = () => {
    let outStatus = 0;

    if (visibleStatus === GROUP_LAYERS_NOT_VISIBLE) {
      outStatus = 1;
    }

    if (visibleStatus === GROUP_LAYERS_VISIBLE) {
      outStatus = 0;
    }

    if (visibleStatus === GROUP_LAYER_PARTIALLY_VISIBLE) {
      outStatus = 0;
    }

    const value = Boolean(outStatus);

    toggleGroupVisibility(map, children, value);
  };

  return (
    <div className={styles.nodeTitleContainer}>
      <div className={styles.groupContainer}>
        <div className={styles.groupTitle}>
          {props.data.item?.group ?? DEFAULT_LAYERS_GROUP_TITLE}
        </div>
        <ButtonVisibleEye
          isVisible={visibleStatus === GROUP_LAYERS_VISIBLE}
          isSpecialStatus={visibleStatus === GROUP_LAYER_PARTIALLY_VISIBLE}
          tip={VISIBLE_TOOLTIPS[visibleStatus]}
          onChangeVisibility={changeGroupVisibility}
        />
      </div>
    </div>
  );
};

export default observer(LayerGroupNode);
