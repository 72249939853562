import { ICoordManagment } from '../../../model/postCommands.model';
import { IPhasesData } from '../../../PhaseHold/PhaseHold.model';
import { validateTableSgnProgram } from '../EditableCell/constants/constants';

export const validateTableItems = (
  phasesTableData: IPhasesData[],
  durationMinArray: number[]
) => {
  const { mainTact, minFlash, colorFlash, maxTact } = validateTableSgnProgram;

  const validateData = phasesTableData.reduce(
    (acc: ICoordManagment[], item) => {
      const { id, key, ...phases } = item;

      acc.push(phases);

      return acc;
    },
    []
  );

  const isUncorrectType = validateData.some((item) =>
    Object.values(item).some(
      (el) => isNaN(el) || el < 0 || !Number.isInteger(el)
    )
  );

  const isUncorrectValues = validateData.some((item) => {
    const {
      time,
      spanTime,
      spanGreenBlinkTime,
      spanYellowTime,
      spanRedYellowTime,
      phase,
    } = item;

    const durMin = durationMinArray[phase - 1] ?? mainTact;

    const isUncorrectMainTact = time < durMin;
    const isUncorrectPromTact = spanTime < durMin;
    const isUncorrectSpanTime =
      spanTime < spanGreenBlinkTime + spanRedYellowTime + spanYellowTime;
    const isUncorrectSpanGreenBlinkTime =
      spanGreenBlinkTime > minFlash && spanGreenBlinkTime < colorFlash;
    const isUncorrectSpanYellowTime =
      spanYellowTime > minFlash && spanYellowTime < colorFlash;
    const isUncorrectSpanRedYellowTime =
      spanRedYellowTime > minFlash && spanRedYellowTime < colorFlash;
    const isLargeNumber =
      time > maxTact ||
      spanTime > maxTact ||
      spanGreenBlinkTime > maxTact ||
      spanYellowTime > maxTact ||
      spanRedYellowTime > maxTact;

    return (
      isUncorrectSpanTime ||
      isUncorrectMainTact ||
      isUncorrectPromTact ||
      isUncorrectSpanGreenBlinkTime ||
      isUncorrectSpanYellowTime ||
      isUncorrectSpanRedYellowTime ||
      isLargeNumber
    );
  });

  return !isUncorrectType && !isUncorrectValues;
};
