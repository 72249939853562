import { FC } from 'react';

import { TooltipProps } from '../Mapper/Mapper.model';

import MapTooltipContent from './MapTooltipContent/MapTooltipContent';

import styles from './MapTooltip.module.scss';

const MapTooltip: FC<TooltipProps> = ({
  tooltipRef,
  isTooltip,
  children,
  rerenderData,
}) => (
  <div ref={tooltipRef} className={styles.tooltipContainer}>
    <MapTooltipContent
      isTooltip={isTooltip}
      content={children}
      rerenderData={rerenderData}
    />
  </div>
);

export default MapTooltip;
