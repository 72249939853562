import { Form, type FormInstance } from 'antd';
import React, { FC } from 'react';

import {
  DEFAULT_ROWS_COUNT,
  DIVISIONS_FORM_SETTINGS,
  FORM_COMPONENTS,
} from '../constants/constants';

interface IDivisionsForm {
  form: FormInstance;
}

const DivisionsForm: FC<IDivisionsForm> = ({ form }) => (
  <Form form={form} layout="vertical">
    {DIVISIONS_FORM_SETTINGS.map(({ label, type, name, rules }, i) => {
      const FormComponent = FORM_COMPONENTS[type];

      return (
        <Form.Item key={i} label={label} name={name} rules={rules}>
          <FormComponent rows={DEFAULT_ROWS_COUNT} allowClear />
        </Form.Item>
      );
    })}
  </Form>
);

export default DivisionsForm;
