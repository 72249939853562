/* eslint-disable jsx-a11y/no-static-element-interactions */
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';

import { HandleScrollType } from '../../../../../../../hooks/useScrollToElement';
import rootStore from '../../../../../../../stores/rootStore/rootStore';
import { getDeviceColor } from '../../../../../../MapBtns/MapInfoWrapper/InfoBtn/ContentInfo/helpers/getMapInfo';
import Popover from '../../../../../../ui-kit/Popover/Popover';
import { IDataList } from '../../../../model/monitoring.model';

import DevicePopoverContent from './DevicePopoverContent/DevicePopoverContent';

import styles from './DeviceTile.module.scss';

interface DeviceTileProps extends IDataList {
  addressLoading: boolean;
  addToRefs: (el: N<HTMLElement>, id: number) => void;
  handleScroll: HandleScrollType;
}

const DeviceTile: FC<DeviceTileProps> = ({
  status,
  id,
  title,
  avatar,
  description,
  system,
  addressLoading,
  address,
  addToRefs,
  handleScroll,
}) => {
  const { interfaceTipsDelay } = rootStore.uiStore;
  const { deviceInfo, setMonitoringValue } = rootStore.monitoringPanelStore;

  const isActive = deviceInfo?.id === id;

  const handleClick = () =>
    !isActive && setMonitoringValue('deviceInfo', { id, system });

  useEffect(() => {
    const isCurrentElement = deviceInfo?.id === id;

    if (!isCurrentElement) return;

    let timeoutId: NodeJS.Timeout;

    const getTimeoutId = (timeout: NodeJS.Timeout) => {
      timeoutId = timeout;
    };

    handleScroll(id, getTimeoutId);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [deviceInfo?.id, id, handleScroll]);

  const popOverProps = {
    content: (
      <DevicePopoverContent
        title={title}
        avatar={avatar}
        description={description}
        status={status}
        isActive={isActive}
        address={address}
        addressLoading={addressLoading}
      />
    ),
    tipsDelay: interfaceTipsDelay,
  };

  const tileStyle = classNames(styles.tile, {
    [styles.active]: isActive,
  });

  return (
    <Popover {...popOverProps}>
      <div
        id={id.toString()}
        ref={(el) => {
          addToRefs(el, id);
        }}
        className={tileStyle}
        style={{ backgroundColor: getDeviceColor(status) }}
        onClick={handleClick}
      >
        <p> {id} </p>
      </div>
    </Popover>
  );
};

export default observer(DeviceTile);
