import type { PickingInfo } from '@deck.gl/core';
import { List } from 'antd';
import React, { useEffect, useState } from 'react';

import { TMap } from '../../../stores/mapStore/mapStore.model';
import Scene from '../../Scene/Scene';

import {
  DEFAULT_PAGE_SETTINGS,
  DEFAULT_SCENE_ZOOM,
  TRAFFIC_LAYER_VERTEX,
} from './constants';
import {
  getLinkFeatures,
  getNodeIdView,
  getSelectedNodeId,
  getTooltip,
  getVertexesFeatures,
  linkLayer,
  vertexLayer,
  vertexTextLayer,
} from './helpers';
import {
  IGraphDataSet,
  IVertex,
  LinkSegmentData,
  VertexData,
} from './trafficGraph.model';

import styles from './TrafficGraphButton.module.scss';

const GraphVisualizer = (props: {
  dataset: IGraphDataSet | null;
  map: TMap;
}) => {
  const { dataset } = props;

  const [layerData, setLayerData] = useState<{
    links: LinkSegmentData[];
    vertexes: VertexData[];
  }>({
    links: [],
    vertexes: [],
  });

  const [selectedNode, setSelectedNode] = useState<IVertex | null>(null);

  const layers = [
    vertexLayer(layerData.vertexes),
    linkLayer(layerData.links),
    vertexTextLayer(layerData.vertexes),
  ];

  useEffect(() => {
    const vertexesFeatures: VertexData[] = getVertexesFeatures(dataset);
    const linkFeatures: LinkSegmentData[] = getLinkFeatures(
      dataset,
      selectedNode
    );

    setLayerData({ links: linkFeatures, vertexes: vertexesFeatures });
  }, [dataset, selectedNode]);

  if (!dataset) {
    return null;
  }

  const onFeatureClick = (info: PickingInfo) => {
    if (info?.object && info?.layer?.id === TRAFFIC_LAYER_VERTEX) {
      const item: IVertex = {
        id: info?.object.id,
        name: info?.object.name,
        nodes: [],
      };

      setSelectedNode(item);
    } else {
      setSelectedNode(null);
    }
  };

  return (
    <div>
      {selectedNode ? (
        <div className={styles.infoContainer}>
          <div className={styles.title}>Выбранный узел (условный)</div>
          <div>{getSelectedNodeId(selectedNode)}</div>

          <div className={styles.title}>Проезды</div>
          <div className={styles.rowItem}>
            <div className={styles.rowItemNum}>#</div>
            <div className={styles.rowItemId}>Идентификатор узла</div>
            <div className={styles.rowItemCount}>ТС, [шт]</div>
          </div>

          <List
            dataSource={layerData.links}
            renderItem={(item: LinkSegmentData, index: number) => (
              <div className={styles.rowItem}>
                <div className={styles.rowItemNum}>{index + 1}</div>
                <div className={styles.rowItemId}>{getNodeIdView(item)}</div>
                <div className={styles.rowItemCount}>{item.count}</div>
              </div>
            )}
            pagination={DEFAULT_PAGE_SETTINGS}
          ></List>
        </div>
      ) : null}

      <Scene
        map={props.map}
        visible={true}
        zoom={DEFAULT_SCENE_ZOOM}
        layers={layers}
        onClick={onFeatureClick}
        getTooltip={getTooltip}
      />
    </div>
  );
};

export default GraphVisualizer;
