import { COLORS } from '../../../constants/colorsConstants';
import { findById } from '../../../helpers/findBy';
import rootStore from '../../../stores/rootStore/rootStore';
import { TDeviceInfo } from '../../../ts/models/mapObject.model';
import { getDeviceColor } from '../../MapBtns/MapInfoWrapper/InfoBtn/ContentInfo/helpers/getMapInfo';

const { PRIMARY, DANGEROUS } = COLORS;

interface Device {
  id: number;
  deviceInfo: {
    deviceStatusId: TDeviceInfo['deviceStatusId'];
  };
}

const getDeviceIcoFill = (id: U<number>, devices: Device[]) => {
  const { isConstructor } = rootStore.constructorStore;

  if (isConstructor || !id) return PRIMARY;

  const device = findById(devices, id);

  return device ? getDeviceColor(device.deviceInfo.deviceStatusId) : DANGEROUS;
};

export default getDeviceIcoFill;
