import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import { LuWifi, LuWifiOff } from 'react-icons/lu';

import rootStore from '../../stores/rootStore/rootStore';
import Popover from '../ui-kit/Popover/Popover';

import Canvas from './Canvas/Canvas';
import { IDetectorGrid } from './models/DG.model';
import PreviewImage from './PreviewImage/PreviewImage';

import styles from './DetectorGrid.module.scss';

export const TITLE = 'Предпросмотр изображения с устройства';
const LABEL = 'Вкл/Выкл отображение зон детекции';
const EMPTY_DATA = 'Недостаточно данных для отображения зон детекции';

const DetectorGrid: FC<IDetectorGrid> = ({
  scene,
  src,
  width = 600,
  controller = true,
  title = TITLE,
  titleStyle,
  wrapperStyle,
  isShowAllInfo,
  isDefaultCanvas = true,
}) => {
  const { interfaceTipsDelay } = rootStore.uiStore;
  const [isCanvas, setIsCanvas] = useState(isDefaultCanvas);
  const [isErrorPreview, setIsErrorPreview] = useState(false);

  const handleClick = () => {
    setIsCanvas(!isCanvas);
  };

  const isSceneValid = isCanvas && scene && !isErrorPreview;
  const isEmptyData = !scene?.lanes?.length && !scene?.trafficLines?.length;
  const popoverLabel = !isEmptyData ? LABEL : EMPTY_DATA;

  const popoverProps = {
    content: popoverLabel,
    tipsDelay: interfaceTipsDelay,
    placement: 'top',
  } as const;

  return (
    <section className={styles.dtPreview} style={wrapperStyle}>
      <h2 className={styles.title} style={titleStyle}>
        {title}
      </h2>
      <div className={styles.wrapper}>
        {controller && !isErrorPreview && (
          <div className={styles.controller}>
            <Popover {...popoverProps}>
              <Button
                onClick={handleClick}
                disabled={isEmptyData}
                data-testid="sceneButton"
              >
                {isCanvas ? (
                  <LuWifi data-testid="on" />
                ) : (
                  <LuWifiOff data-testid="off" />
                )}
              </Button>
            </Popover>
          </div>
        )}
        <PreviewImage
          src={isErrorPreview ? 'assets/not_available.gif' : src}
          width={width}
          setIsErrorPreview={setIsErrorPreview}
        />
        {isErrorPreview && (
          <h3 className={styles.titleError}>Изображение отсутствует</h3>
        )}
        {isSceneValid && (
          <Canvas width={width} scene={scene} isShowAllInfo={isShowAllInfo} />
        )}
      </div>
    </section>
  );
};

export default observer(DetectorGrid);
