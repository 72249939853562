import { useCallback, useEffect, useState } from 'react';

import eventBus from '../../../../eventBus';
import rootStore from '../../../../stores/rootStore/rootStore';
import { NearestCommand } from '../../../../ts/models/tl.model';
import { getPlannedCommandsArray } from '../../TlPlanning/helpers/getPlannedCommandsArray';
import { getNearestCommand } from '../helpers/getNearestCommand';
import { TCommands } from '../model/postCommands.model';

const APP_PATH =
  '.TrafficLightDetailed.TLManagement.hooks.useTimerPlannedComand';

const useTimerPlannedComand = (id: number) => {
  const { stopTimerTrigger } = rootStore.detailedStore;

  const [plannedCommands, setPlannedCommands] = useState<TCommands[]>([]);
  const [timerInfo, setTimerInfo] = useState<N<NearestCommand>>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const getPlanned = useCallback(async () => {
    setIsError(false);

    const commands = await getPlannedCommandsArray({
      id,
      appPath: APP_PATH,
      isBlockedNotify: true,
    });

    setIsLoading(false);

    if (!commands) {
      return setIsError(true);
    }

    setPlannedCommands(commands.dataRows);
  }, [id]);

  useEffect(() => {
    const unsubscribe = eventBus.phaseHoldChange.subscribe(getPlanned);

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPlanned();
  }, [getPlanned]);

  useEffect(() => {
    const filteredCommands = getNearestCommand(plannedCommands);

    setTimerInfo(filteredCommands);
  }, [plannedCommands, stopTimerTrigger]);

  return { timerInfo, isLoading, isError };
};

export default useTimerPlannedComand;
