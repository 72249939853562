import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { PointUds } from '../../../../../../../../ts/models/pointUds.model';
import LinkedDevices from '../../LinkedDevices/LinkedDevices';
import { HandleClickDevice } from '../../models/crossroadSchema.model';

interface LinkedDevicesUdsProps {
  rate: number;
  id: number;
  pointUds: U<PointUds>;
  handleClickDevice?: HandleClickDevice;
}

const LinkedDevicesUds: FC<LinkedDevicesUdsProps> = ({
  id,
  rate,
  pointUds,
  handleClickDevice,
}) => {
  if (!pointUds) return null;

  const { linkedDeviceIds, schemaODD } = pointUds.basicProfileInfo;
  const isSchemaODD = Boolean(schemaODD);

  return (
    <LinkedDevices
      rate={rate}
      id={id}
      linkedDevices={linkedDeviceIds}
      isSchemaODD={isSchemaODD}
      handleClickDevice={handleClickDevice}
    />
  );
};

export default observer(LinkedDevicesUds);
