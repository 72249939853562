import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import { ANIMATION_DURATION_CLUSTER } from '../../../constants/mapClusterConstants';
import rootStore from '../../../stores/rootStore/rootStore';
import { getSelectInteraction } from '../../ui-kit/MapLayers/ClusterLayers/helpers/getSelectInteraction';
import { getFeaturesCluster } from '../helpers/getFeaturesCluster';
import handleCenter from '../helpers/zoomHandlers/handleCenter';
import { ClustersProps } from '../Map.model';

const Clusters = ({ setFeaturesSelect }: ClustersProps) => {
  const {
    clickedCluster,
    isFeatureOnCluster,
    isClusterClicked,
    triggerCenteredCoord,
    setSelectedClusters,
    setTriggerCenteredCoord,
  } = rootStore.clustersStore;

  const {
    infoData,
    isDetailedForm,
    markers,
    isOpenInfoPanel,
    regionData,
    setRightPanel,
    setInfoData,
  } = rootStore.uiStore;

  const { isPhaseCircle } = rootStore.pointsUdsStore;
  const { isMultipleSelect } = rootStore.scriptsControlStore;
  const { isConstructor } = rootStore.constructorStore;
  const { map } = rootStore.mapStore;

  // centered cluster
  useEffect(() => {
    if (!map || !triggerCenteredCoord) return;

    handleCenter(triggerCenteredCoord);
  }, [map, triggerCenteredCoord]);

  // clear trigger centered cluster when change region
  useEffect(() => {
    regionData && setTriggerCenteredCoord(null);
  }, [regionData, setTriggerCenteredCoord]);

  // clear selected cluster when phase circle active
  useEffect(() => {
    isPhaseCircle && setSelectedClusters(null);
  }, [isPhaseCircle, setSelectedClusters]);

  // clear selected cluster when detailed open
  useEffect(() => {
    (!isOpenInfoPanel || isDetailedForm) && setSelectedClusters(null);
  }, [isOpenInfoPanel, isDetailedForm, setSelectedClusters]);

  // clear selected cluster when open constructor | change markers button | open coord control
  useEffect(() => {
    setSelectedClusters(null);
  }, [isMultipleSelect, setSelectedClusters]);

  // check cluster and close it if change markers button with this system
  useEffect(() => {
    setSelectedClusters(null, true);
  }, [markers, setSelectedClusters]);

  // clear selected cluster when clicked on feature out of cluster
  useEffect(() => {
    if (isMultipleSelect) {
      !isClusterClicked && !isFeatureOnCluster && setSelectedClusters(null);

      return;
    }
    !isFeatureOnCluster && infoData && setSelectedClusters(null);
  }, [
    infoData,
    isFeatureOnCluster,
    isMultipleSelect,
    isClusterClicked,
    setSelectedClusters,
  ]);

  // manual close cluster when we clear selected clusters
  useEffect(() => {
    if (!map) return;

    const sourceSelect = getSelectInteraction(map);

    if (clickedCluster || isFeatureOnCluster) return;

    sourceSelect?.clear();
  }, [clickedCluster, map, isFeatureOnCluster]);

  // close infoPanel when we clear selected clusters or not open infoPanel when we on coord control
  useEffect(() => {
    if (isMultipleSelect) {
      return;
    }

    if (clickedCluster || infoData) return;

    !isConstructor && setRightPanel(null);
  }, [
    clickedCluster,
    infoData,
    isMultipleSelect,
    setRightPanel,
    isConstructor,
  ]);

  // set errors features with coordinates on opened cluster with alarm
  useEffect(() => {
    if (!map) return;

    setFeaturesSelect([]);

    //the solution is due to the fact that features are not drawn immediately, but with the end of the animation
    const idTimeout = setTimeout(() => {
      setFeaturesSelect(getFeaturesCluster(map) || []);
    }, ANIMATION_DURATION_CLUSTER);

    return () => {
      clearTimeout(idTimeout);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, clickedCluster]);

  //clear clusters and right panel if open coord Management
  useEffect(() => {
    if (!isMultipleSelect) return;
    setRightPanel(null);
    setInfoData(null);
  }, [isMultipleSelect, setRightPanel, setInfoData]);

  return null;
};

export default observer(Clusters);
