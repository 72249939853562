import classNames from 'classnames';
import { FC, ReactNode } from 'react';

import styles from './ButtonsBlock.module.scss';
interface ButtonsBlockProps {
  children: ReactNode;
  borderRadius?: number;
  className?: string;
  isHorizontal?: boolean;
}

const ButtonsBlock: FC<ButtonsBlockProps> = ({
  children,
  className = '',
  borderRadius = 0,
  isHorizontal = false,
}) => {
  const bRadius = `${borderRadius}px`;

  const blockStyle = classNames({
    [styles.block]: true,
    [styles.blockHorizontal]: isHorizontal,
    [className]: Boolean(className),
  });

  return (
    <div className={blockStyle} style={{ borderRadius: bRadius }}>
      {children}
    </div>
  );
};

export default ButtonsBlock;
