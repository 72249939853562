import { Button } from 'antd';
import { observer } from 'mobx-react-lite';

import { TlManagementKeys } from '../../../../../constants/commands';
import eventBus from '../../../../../eventBus';
import rootStore from '../../../../../stores/rootStore/rootStore';

import styles from './ConfirmBtn.module.scss';

const { Management } = TlManagementKeys;

const onConfirm = () => eventBus.clickedControlBtn.broadcast();

const ConfirmBtn = () => {
  const { activeControlKey, isDisabledControlCmd, isActiveControlBtn } =
    rootStore.detailedStore;

  const isDefaultActiveKey = activeControlKey === Management;
  const isBtnDisabled = isDisabledControlCmd || !isActiveControlBtn;

  if (isDefaultActiveKey) return null;

  return (
    <div className={styles.btnBlock}>
      <Button
        type="primary"
        onClick={onConfirm}
        disabled={isBtnDisabled}
        className={styles.btn}
      >
        Применить
      </Button>
    </div>
  );
};

export default observer(ConfirmBtn);
