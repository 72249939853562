import type { PickingInfo } from '@deck.gl/core';
import { TileLayer } from '@deck.gl/geo-layers';
import { BitmapLayer, GeoJsonLayer } from '@deck.gl/layers';

import { IRegionData } from '../../api/server/web/web.zod';
import { getLayer } from '../../apiGIS/layers/utils/getLayer';
import {
  SCENE_MAX_HEIGHT,
  SCENE_MAX_WIDTH,
  SCENE_WIDTH_COEFFICIENT,
  SCENE_WKID,
} from '../../apiGIS/scene/constants';
import { getMapCenter, getSceneBBox } from '../../apiGIS/scene/projections';
import {
  getRandomFillColor,
  getRandomStrokeColor,
  symbolToStyle,
} from '../../apiGIS/scene/styles';
import { tileUrl } from '../../apiGIS/utils/basemaps';
import { ILayer } from '../../stores/gisDataStore/gisDataStore.model';
import { TMap } from '../../stores/mapStore/mapStore.model';
import { IRenderer } from '../../ts/models/gis/renderer.model';

import {
  DEFAULT_OSM_URL,
  HIGHLIGHT_COLOR,
  MAX_ALLOCABLE_OFFSET_BASE,
  MAX_CONCURRENT_SERVICE_REQUESTS,
  MAX_FEATURES_BY_REQUEST,
  MAX_SAFE_SCENE_ZOOM,
  MIN_ELEVATION,
  MIN_SAFE_SCENE_ZOOM,
  PICKABLE_TILE_LAYER,
  SCENE_BEARING,
  SCENE_MAX_PITCH,
  SCENE_MAX_ZOOM,
  SCENE_MIN_WIDTH_PIXELS,
  SCENE_MIN_ZOOM,
  SCENE_PITCH,
  STANDARD_TILE_SIZE,
} from './constants';
import {
  ISceneBaseMapLayer,
  ISceneLayerPref,
  ISceneProps,
  ISceneViewState,
} from './model';

const devicePixelRatio =
  (typeof window !== 'undefined' && window.devicePixelRatio) || 1;

export const baseMapLayer = (props: ISceneBaseMapLayer) => {
  const urlTransformed = props.basemap ? tileUrl(props.basemap) : null;

  return new TileLayer<ImageBitmap>({
    data: [urlTransformed || DEFAULT_OSM_URL],
    maxRequests: MAX_CONCURRENT_SERVICE_REQUESTS,
    pickable: PICKABLE_TILE_LAYER,
    highlightColor: HIGHLIGHT_COLOR,
    minZoom: MIN_SAFE_SCENE_ZOOM,
    maxZoom: MAX_SAFE_SCENE_ZOOM,
    tileSize: STANDARD_TILE_SIZE,
    zoomOffset: devicePixelRatio === 1 ? -1 : 0,
    renderSubLayers: (subLayersProps: any) => {
      if (!subLayersProps.tile.boundingBox) {
        return null;
      }

      const [[west, south], [east, north]] = subLayersProps.tile.boundingBox;
      const { data, ...otherProps } = subLayersProps;

      return [
        new BitmapLayer(otherProps, {
          image: data,
          bounds: [west, south, east, north],
        }),
      ];
    },
  });
};

export const getTooltip = ({ object }: PickingInfo) =>
  object && `${JSON.stringify(object)}`;

export const getLayersDefinitions = (definitions: ILayer[]) => {
  return definitions.filter((layer) => layer.url && layer.id);
};

export const getRequestUrl = (
  viewState: any,
  definition: ILayer,
  url: string,
  pref: ISceneLayerPref
) => {
  const { longitude, latitude, zoom, width, height } = viewState;

  const bbox = getSceneBBox(longitude, latitude, zoom, width, height);

  const extent = `{"xmin":${bbox[0]},"ymin":${bbox[1]},"xmax":${bbox[2]},"ymax":${bbox[3]},"spatialReference":{"wkid":${SCENE_WKID}}}`;

  const maxAllowableOffset = MAX_ALLOCABLE_OFFSET_BASE / Math.pow(zoom + 1, 2);

  const definitionExpression = definition.definitionExpression;

  let requestUrl = url + `/query?`;

  requestUrl += `f=geojson&outSR=4326`;
  requestUrl += `&geometry=${extent}`;
  requestUrl += `&geometryType=esriGeometryEnvelope`;
  requestUrl += `&spatialRel=esriSpatialRelIntersects`;
  requestUrl += `&resultRecordCount=${MAX_FEATURES_BY_REQUEST}`;
  requestUrl += `&maxAllowableOffset=${maxAllowableOffset}`;

  if (definitionExpression) {
    requestUrl += `&where=${encodeURIComponent(definitionExpression)}`;
  }

  if (pref) {
    const { orderBy, outFields } = pref;

    requestUrl += `&outFields=${outFields}`;
    requestUrl += `&orderByFields=${orderBy}`;
  }

  if (pref && pref.useSimpleUrl) {
    requestUrl = `${definition.url}/query?f=geojson&outSR=4326`;
  }

  return requestUrl;
};

export const getSceneLayers = (
  definitions: ILayer[],
  viewState: any,
  renderers: IRenderer[]
) => {
  const filteredDefinitions = getLayersDefinitions(definitions);

  return filteredDefinitions.map((definition) => {
    const { url, id } = definition;

    const zPropertyDefinition = definition.zPropertyDefinition || {
      field: null,
    };

    const pref: ISceneLayerPref = {
      outFields: definition.outFields,
      hField: zPropertyDefinition.field,
      orderBy: definition.orderByFields,
      useSimpleUrl: !definition.dynamic,
    };

    const requestUrl = getRequestUrl(viewState, definition, url, pref);

    const renderer = renderers.find((item) => item.className === id);

    const rendererInfo = getRendererProps(renderer);

    return new GeoJsonLayer<unknown>({
      id: definition.id,
      data: requestUrl,
      stroked: true,
      filled: true,
      getFillColor: () => {
        return rendererInfo?.fillColor ?? getRandomFillColor();
      },
      getLineWidth: (item) => {
        return rendererInfo?.lineWidth ?? SCENE_WIDTH_COEFFICIENT;
      },
      lineWidthMinPixels: SCENE_MIN_WIDTH_PIXELS,
      getLineColor: (item) => {
        return rendererInfo?.lineColor ?? getRandomStrokeColor();
      },
      extruded: !!pref?.hField,
      _full3d: !!pref?.hField,
      getElevation: (item: any) => {
        const { hField } = pref;

        const props = item && item.properties ? item.properties : {};

        if (!hField) {
          return 0;
        }

        const hValue = Number(props[hField]);

        if (!Number.isNaN(hValue)) {
          return hValue;
        }

        return MIN_ELEVATION;
      },
      pickable: false,
    });
  });
};

export const getMapLayers = (map: TMap, definitions: ILayer[]) => {
  return definitions
    .map((item) => {
      return getLayer(map, item.id);
    })
    .filter((layer) => !!layer);
};

export const getRendererProps = (definition: IRenderer | undefined) => {
  if (!definition) {
    return undefined;
  }

  const { type } = definition.renderer;

  if (type === 'simple') {
    // @ts-ignore
    const { symbol: rendererSymbol } = definition.renderer;

    return symbolToStyle(rendererSymbol);
  }

  return undefined;
};

export const updateMapLayers = (
  layersState: ILayer[],
  viewState: any,
  basemapProxy: any,
  mapProxy: TMap,
  renderers: IRenderer[]
) => {
  const layers = [];

  const definitionLayers = getSceneLayers(layersState, viewState, renderers);

  const baseLayer = baseMapLayer({
    basemap: basemapProxy,
    showTileBorders: true,
  });

  layers.push(baseLayer);

  definitionLayers.forEach((layer) => {
    const mapLayer = getLayer(mapProxy, layer.id);

    if (mapLayer?.getVisible()) {
      layers.push(layer);
    }
  });

  return layers;
};

export const getInitialMapState = (props: ISceneProps, currentZoom: number) => {
  const center = getMapCenter(props.map);

  const [longitude, latitude] = center;

  return {
    latitude,
    longitude,
    zoom: currentZoom || props.zoom,
    maxZoom: SCENE_MAX_ZOOM,
    minZoom: SCENE_MIN_ZOOM,
    maxPitch: SCENE_MAX_PITCH,
    pitch: props.pitch || SCENE_PITCH,
    bearing: props.bearing || SCENE_BEARING,
    width: SCENE_MAX_WIDTH,
    height: SCENE_MAX_HEIGHT,
  };
};

export const getRegionViewState = (
  currentViewState: ISceneViewState,
  regionData: N<IRegionData>
) => {
  const extent = regionData?.extent;

  if (!extent) {
    return null;
  }

  const [xmin, ymin, xmax, ymax] = extent;

  const x = (xmin + xmax) / 2;
  const y = (ymin + ymax) / 2;

  const viewState = { ...currentViewState };

  viewState.longitude = x;
  viewState.latitude = y;

  return viewState;
};
