import { Spin } from 'antd';
import { SpinProps } from 'antd/lib';
import classNames from 'classnames';
import { FC, ReactNode } from 'react';

import NoData, { INoDataProps } from '../Errors/NoData/NoData';

import styles from './DefaultChecked.module.scss';

export type DefaultCheckedProps = Omit<IDefaultChecked, 'children'>;

export interface IDefaultChecked {
  isLoading?: boolean;
  isEmptyData?: boolean;
  noDataProps?: INoDataProps;
  isBackdropFilter?: boolean;
  children?: ReactNode;
}

const spinProps: SpinProps = {
  size: 'large',
};

const DefaultChecked: FC<IDefaultChecked> = ({
  isLoading = false,
  isEmptyData = false,
  noDataProps = {},
  isBackdropFilter = false,
  children,
}) => {
  const isLoad = !isLoading && !isEmptyData;
  const isCanShow = isBackdropFilter ? !isEmptyData : isLoad;

  const styleContainer = classNames({
    [styles.spin]: true,
    [styles.filter]: isBackdropFilter,
  });

  return (
    <>
      {isLoading && (
        <div className={styleContainer}>
          <Spin {...spinProps} />
        </div>
      )}
      {!isLoading && isEmptyData && <NoData {...noDataProps} />}
      {isCanShow && children}
    </>
  );
};

export default DefaultChecked;
