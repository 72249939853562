import { observer } from 'mobx-react-lite';

import SelectedTls from './ControlCard/SelectedTls/SelectedTls';
import GroupControl from './GroupControl/GroupControl';

import styles from './DispatchGroupDetailed.module.scss';

const DispatchGroupDetailed = () => {
  return (
    <div className={styles.container}>
      <GroupControl />
      <SelectedTls />
    </div>
  );
};

export default observer(DispatchGroupDetailed);
