import { Rule, RuleObject } from 'antd/lib/form';

import {
  IMaxLength,
  IMinLength,
  IRequired,
  IWhitespace,
} from '../models/formConstants.model';

const regExpNotWhitespace = /^[^\s()-]*$/;
const whiteSpaceFailedMessage = 'Значение не может содержать пробелы';

export const MAX_FIELD_LENGTH = 250;

export const EMAIL_RULES: Rule[] = [
  { required: true, message: 'Введите ваш почтовый ящик!' },
  {
    type: 'email',
    message: 'Введите корректную почту в формате Example@mail.ru',
  },
];

export const PASSWORD_RULES: [
  IRequired,
  IMinLength,
  IWhitespace,
  () => RuleObject
] = [
  { required: true, message: 'Введите пароль' },
  { min: 12, message: 'Пароль должен быть не короче 12 символов' },
  { whitespace: false, message: 'Пароль не должен содержать пробелы' },
  () => ({
    validator(_: any, value: string) {
      const newReg = new RegExp(
        /(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{6,}/,
        'g'
      );

      if (!value || (newReg.test(value) && regExpNotWhitespace.test(value))) {
        return Promise.resolve();
      }

      return Promise.reject(
        'Пароль должен содержать символы "!@#$%^&*", цифры и заглавные буквы'
      );
    },
  }),
];

export const LOGIN_RULES: [
  IRequired,
  IWhitespace,
  IMinLength,
  IMaxLength,
  () => RuleObject
] = [
  { required: true, message: 'Введите логин' },
  { whitespace: false, message: 'Логин не должен содержать пробелы' },
  { min: 4, message: 'Логин должен быть не короче 4 символов' },
  { max: 12, message: 'Логин должен быть не длиннее 12 символов' },
  () => ({
    validator(_: any, value: string) {
      if (!value || regExpNotWhitespace.test(value)) {
        return Promise.resolve();
      }

      return Promise.reject(whiteSpaceFailedMessage);
    },
  }),
];

export const STATUS_DATE_RULES = [
  {
    required: true,
    message: 'При активном доступе поле является обязательным',
  },
];

export const DESC_RULES = [
  { required: true, message: 'Заполните описание' },
  { whitespace: true, message: 'Описание не должно состоять из пробелов' },
  { max: MAX_FIELD_LENGTH, message: 'Не длиннее 250 символов' },
  { min: 5, message: 'Не менее 5 символов' },
];

export const DELETE_REASON_RULES = [
  { required: true, message: 'Заполните причину удаления' },
  {
    whitespace: true,
    message: 'Причина удаления не должна состоять из пробелов',
  },
  { max: MAX_FIELD_LENGTH, message: 'Не длиннее 250 символов' },
  { min: 5, message: 'Не менее 5 символов' },
];

export const GROUPS_RULES = [{ required: true, message: 'Выберите группу' }];

export const REGION_RULES = [{ required: true, message: 'Выберите регион' }];
export const SUBSYSTEM_RULES = [
  { required: true, message: 'Выберите систему' },
];
export const SYSTEMTYPES_RULES = [
  { required: true, message: 'Выберите тип системы' },
];
export const SYSTEM_STATUS_RULES = [
  { required: true, message: 'Выберите статус системы' },
];
export const SYSTEM_NAME_RULES: [
  IRequired,
  IWhitespace,
  IMaxLength,
  IMinLength
] = [
  { required: true, message: 'Введите Название' },
  { whitespace: true },
  { max: 100, message: 'Не длиннее 100 символов' },
  { min: 5, message: 'Не менее 5 символов' },
];

export const NAME_RULES: [IRequired, IWhitespace, IMaxLength, IMinLength] = [
  { required: true, message: 'Введите имя' },
  { whitespace: true, message: 'Имя не должно состоять из пробелов' },
  { max: 150, message: 'Не длиннее 150 символов' },
  { min: 5, message: 'Не менее 5 символов' },
];
