import { RefObject } from 'react';

import createOverlay from '../../Mapper/helpers/createOverlay';

interface ICreateOverlays {
  elements: RefObject<HTMLDivElement>[];
  autoPan?: boolean;
  insertFirst?: boolean;
}

const createOverlays = (props: ICreateOverlays) => {
  return props.elements.map((el) => createOverlay({ ...props, element: el }));
};

export default createOverlays;
