import {
  TStatusInfoDvc,
  TWidgetsData,
  TWidgetsDataKeys,
} from '../../../../stores/helpers/combineWgData';
import { TActiveSystemMonitoring } from '../../../../stores/monitoringPanelStore/monitoringPanelStore.model';
import { TWidgetsOptionalData } from '../../../Panel/StatisticsPanel/MonitoringCard/models/monitoring.model';
import { ALL_SYSTEM_TITLE } from '../../constants/constants';

interface IStatusData extends TWidgetsOptionalData {
  isNoData: boolean;
}

export const getStatusData = (
  widgetsFromMap: TWidgetsData,
  activeSystem: TActiveSystemMonitoring
) => {
  if (activeSystem === ALL_SYSTEM_TITLE) {
    const widgetsLen = Object.values(widgetsFromMap).reduce(
      (acc, el) => acc + el.total,
      0
    );

    return { isNoData: !widgetsLen, ...widgetsFromMap };
  }

  return (
    Object.entries(widgetsFromMap) as [TWidgetsDataKeys, TStatusInfoDvc][]
  ).reduce((acc, [key, value]) => {
    if (value.system === activeSystem)
      return { [key]: value, isNoData: !value.total };

    return acc;
  }, {} as IStatusData);
};
