import { setTimeString } from './setTimeString';

const initialTime = {
  H: true,
  M: true,
  S: true,
};

export const secondsToTime = (
  duration: Nullish<number>,
  { H, M, S } = initialTime
) => {
  if (!duration) return 'неизвестно';
  const hours = (duration / 3600) ^ 0;
  const minutes = ((duration - hours * 3600) / 60) ^ 0;
  const seconds = duration - hours * 3600 - minutes * 60;

  const time: any = { hours, minutes, seconds };
  const access: any = { 0: H, 1: M, 2: S };

  let res = '';
  let c = 0;

  for (const key in time) {
    res =
      res +
      setTimeString(time[key], access[c]) +
      (c !== 2 && access[c] ? ':' : '');
    c = c + 1;
  }

  return res;
};
