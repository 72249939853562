import { IoAdd } from 'react-icons/io5';

import rootStore from '../../../../stores/rootStore/rootStore';
import { PanelType, RightPanelType } from '../../../../ts/enums/enums';
import { CollapseBtnProps } from '../../CollapseBtn/CollapseBtn';

export const getCollapseProps = (
  panelType: N<PanelType>,
  isScriptsCreateDetailed: boolean,
  isConstructorDetailed: boolean,
  isScriptsControl: boolean
): CollapseBtnProps => {
  const { setRightPanel, setKeyValue } = rootStore.uiStore;
  const collapseProps: CollapseBtnProps = {};

  if (isScriptsControl)
    return {
      onAction: () => setRightPanel(RightPanelType.CoordControl),
      actionPopoverContent: `${
        !isScriptsCreateDetailed ? 'Открыть' : 'Закрыть'
      } окно добавления группы координированного управления?`,
      isActive: isScriptsCreateDetailed,
      actionIco: <IoAdd />,
    };

  if (panelType === PanelType.Constructor)
    return {
      onAction: () => {
        setKeyValue('infoData', null);
        setRightPanel(RightPanelType.Constructor);
      },
      actionPopoverContent: 'Настройки',
      isActive: isConstructorDetailed,
    };

  return collapseProps;
};
