import rootStore from '../../../../../../stores/rootStore/rootStore';
import getDeviceBySystem from '../../Mapper/CrossroadSchema/helpers/getDeviceBySystem';

const getMonitoringDevice = () => {
  const { deviceInfo } = rootStore.monitoringPanelStore;

  if (!deviceInfo) return null;

  const device = getDeviceBySystem(deviceInfo.id, deviceInfo.system);

  return device;
};

export default getMonitoringDevice;
