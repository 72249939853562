export const SCENE_MAX_ZOOM = 20;
export const SCENE_MIN_ZOOM = 12;
export const SCENE_MAX_PITCH = 70;
export const SCENE_PITCH = 55;
export const SCENE_BEARING = 0;

export const DEFAULT_OSM_URL = 'https://tile.openstreetmap.org/{z}/{x}/{y}.png';

export const HIGHLIGHT_COLOR = [60, 60, 60, 40];
export const STANDARD_TILE_SIZE = 256;
export const MIN_SAFE_SCENE_ZOOM = 0;
export const MAX_SAFE_SCENE_ZOOM = 19;
export const MAX_CONCURRENT_SERVICE_REQUESTS = 6;

export const DEFAULT_CENTER = [37, 55];

export const PICKABLE_TILE_LAYER = false;

export const MAX_FEATURES_BY_REQUEST = 15000;
export const MAX_ALLOCABLE_OFFSET_BASE = 100;
export const SCENE_MIN_WIDTH_PIXELS = 1;

export const MIN_ELEVATION = 3;

export const SCENE_CHANGE_STATE_TIMOUT = 500;
