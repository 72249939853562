import { Fill, Stroke } from 'ol/style';

import { GEOMETRY_TYPE_NAMES } from '../../../../../apiGIS/constants/geometry/types';
import { GIS_APP_PATH } from '../../../../../apiGIS/layers/FeatureLayer';
import { COLORS } from '../../../../../constants/colorsConstants';
import { ILayer } from '../../../../../stores/gisDataStore/gisDataStore.model';
import layersService from '../../../../../stores/mapDataStore/layers.service';
import { STROKE_PATTERN_SOLID, STROKE_PATTERNS } from '../Settings/constants';

export const getColor = (data?: Fill | Stroke | null | undefined) => {
  if (!data) {
    return COLORS.DANGEROUS;
  }

  return data.getColor()?.toString() || COLORS.DANGEROUS;
};

export const getLayerGeometryType = async (definition?: ILayer) => {
  if (!definition) {
    return GEOMETRY_TYPE_NAMES.Point;
  }

  const { data } = await layersService.getDefinition(
    GIS_APP_PATH,
    definition.url
  );

  const serviceGeometryType: string | undefined =
    data?.geometryType || 'esriGeometryPoint';

  if (serviceGeometryType === 'esriGeometryPoint') {
    return GEOMETRY_TYPE_NAMES.Point;
  }

  if (serviceGeometryType === 'esriGeometryPolyline') {
    return GEOMETRY_TYPE_NAMES.LineString;
  }

  if (serviceGeometryType === 'esriGeometryPolygon') {
    return GEOMETRY_TYPE_NAMES.Polygon;
  }

  return GEOMETRY_TYPE_NAMES.Point;
};

export const getDashValue = (stroke: Stroke | null | undefined) => {
  if (!stroke) {
    return;
  }

  const lineDash = stroke.getLineDash();

  if (!lineDash) {
    return null;
  }

  if (Array.isArray(lineDash)) {
    return lineDash
      .map((value: number) => {
        return value;
      })
      .join('_');
  }
};

export const getDashLabel = (stroke: Stroke | null | undefined) => {
  if (!stroke) {
    return STROKE_PATTERN_SOLID;
  }

  const lineDash = stroke.getLineDash();

  if (!lineDash) {
    return STROKE_PATTERN_SOLID;
  }

  const value = lineDash
    .map((value: number, index: number) => {
      if ((index + 1) % 2 === 0) {
        return ' ';
      }

      if (value >= 15) {
        return '---';
      }

      if (value >= 10) {
        return '--';
      }

      return '-';
    })
    .join('');

  return value || STROKE_PATTERN_SOLID;
};

export const getDashes = (stroke?: Stroke | null | undefined) => {
  const items = STROKE_PATTERNS;

  const lineDash = stroke?.getLineDash();
  const dashValue = getDashValue(stroke);

  const isIncluded = items.find((element) => element.value === dashValue);

  if (!isIncluded && !!dashValue && lineDash) {
    items.push({
      value: dashValue,
      label: getDashLabel(stroke),
      dash: lineDash,
    });
  }

  return items;
};

export const hasFill = (geometryType: string) =>
  geometryType === GEOMETRY_TYPE_NAMES.Polygon ||
  geometryType === GEOMETRY_TYPE_NAMES.MultiPolygon;

export const hasStroke = (geometryType: string) =>
  geometryType === GEOMETRY_TYPE_NAMES.LineString ||
  geometryType === GEOMETRY_TYPE_NAMES.MultiLineString ||
  geometryType === GEOMETRY_TYPE_NAMES.Polygon ||
  geometryType === GEOMETRY_TYPE_NAMES.MultiPolygon;

export const hasImage = (geometryType: string) =>
  geometryType === GEOMETRY_TYPE_NAMES.Point;
