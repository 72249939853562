import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { COMMANDS } from '../../../../../constants/commands';
import rootStore from '../../../../../stores/rootStore/rootStore';
import DatePicker from '../../../../ui-kit/Picker/DatePicker/DatePicker';
import TimePicker from '../../../../ui-kit/Picker/TimePicker/TimePicker';
import Popover from '../../../../ui-kit/Popover/Popover';
import useTimeCard from '../../hooks/timeCards/useTimeCard';
import { TimeCardBasicProps } from '../../model/timeCard.model';

import styles from './TimeCardRequiredDuration.module.scss';

const { SET_OBJECT_PLAN_TIME_ON_SCHEDULER } = COMMANDS;

const DISABLED_DATE_TIME = 0;

const TimeCardRequiredDuration: FC<TimeCardBasicProps> = ({
  isChecked,
  selectedTimeInfo,
  setIsChecked,
  setSelectedTimeInfo,
}) => {
  const { interfaceTipsDelay } = rootStore.uiStore;
  const { isActiveControlBtn } = rootStore.detailedStore;

  const isDisabled = !isActiveControlBtn;

  const { datePickerProps, timePickerProps, isAnimate, durationBlockRef } =
    useTimeCard({
      command: SET_OBJECT_PLAN_TIME_ON_SCHEDULER,
      isChecked,
      selectedTimeInfo,
      setSelectedTimeInfo,
      disabledTime: DISABLED_DATE_TIME,
      isDisabled,
    });

  const obligatoryFieldProps = {
    content: 'Обязательное поле для заполнения',
    placement: 'right',
    tipsDelay: interfaceTipsDelay,
  } as const;

  const checkboxContentStyle = classNames({
    [styles.timeCardStyle]: true,
    [styles.timeCardAnimate]: isAnimate,
  });

  return (
    <div className={styles.timePickerCard}>
      <div className={styles.rowContainer}>
        <Popover {...obligatoryFieldProps}>
          <div className={styles.spaceHorizontalDuration}>
            <h4>Длительность: *</h4>
            <TimePicker {...timePickerProps} />
          </div>
        </Popover>
        <Checkbox
          checked={isChecked}
          disabled={isDisabled}
          onChange={(e: CheckboxChangeEvent) => setIsChecked(e.target.checked)}
        >
          <h4
            className={classNames({
              [styles.labelCheckboxDisabled]: isDisabled,
            })}
          >
            Включение плана ДК в определенное время
          </h4>
        </Checkbox>
      </div>
      {isChecked && (
        <div className={checkboxContentStyle}>
          <div
            className={styles.spaceHorizontalDuration}
            ref={durationBlockRef}
          >
            <h4>Дата:</h4>
            <DatePicker {...datePickerProps} />
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(TimeCardRequiredDuration);
