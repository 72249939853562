import { FC, RefObject } from 'react';

import { StreamType } from '../../../api/mapObjects/cameras/cameras.zod';
import Camera from '../../ui-kit/Camera/Camera';
import CameraMgpeg from '../../ui-kit/Camera/CameraMgpeg/CameraMgpeg';

const { Mjpeg, Jpeg } = StreamType;

export interface LoadPropertiesOptions {
  onLoad: () => void;
  onError: () => void;
}

interface ICameraWrapperProps {
  streamType: StreamType;
  src: string;
  cameraRef: RefObject<HTMLElement>;
  loadProperties: LoadPropertiesOptions;
}

const isImageRef = (
  cameraRef: RefObject<HTMLElement>,
  streamType: StreamType
): cameraRef is RefObject<HTMLImageElement> =>
  streamType === Mjpeg || streamType === Jpeg;

const CameraWrapper: FC<ICameraWrapperProps> = ({
  streamType,
  src,
  cameraRef,
  loadProperties,
}) => {
  if (isImageRef(cameraRef, streamType))
    return (
      <CameraMgpeg
        src={src}
        cameraRef={cameraRef}
        loadProperties={loadProperties}
      />
    );

  return (
    <Camera
      src={src}
      pointerEvents="none"
      cameraRef={cameraRef}
      loadProperties={loadProperties}
      streamType={streamType}
    />
  );
};

export default CameraWrapper;
