import { Dayjs } from 'dayjs';

import { MINUTE_SECONDS } from '../../../TlManagement/constants/constants';
import { IIntervals } from '../../constants/constants';
import { TSelectDates } from '../../model/graph.model';
import { MINUTES_IN_HOUR } from '../TitleBtns/TimeLocalCard/helpers/getTimeWithTimeZone';

const getRoundRange = (range: TSelectDates): TSelectDates => [
  range[0].startOf('day'),
  range[1].startOf('day'),
];

const getClosestRange = (
  stepOnHour: number,
  standardIntervalMin: number,
  min: number
) => {
  const range: number[] = [];

  for (let i = 1; i <= stepOnHour; i++) {
    //get minute from available
    const rangeMin = standardIntervalMin * i;

    const last = range.length && (range.at(-1) as number);

    if (rangeMin === min) {
      range[0] = min;

      break;
    }

    //if within our range
    if (last <= min && min <= rangeMin) {
      !last && range.push(last);
      range.push(rangeMin);
    } else {
      //if range is ready
      if (range.length === 2) break;
      range[0] = rangeMin;
    }
  }

  return range;
};

export const getRoundDates = (
  rangePicker: [Dayjs, Dayjs],
  standardInterval: U<IIntervals>
): TSelectDates => {
  if (!standardInterval) return rangePicker;

  if (standardInterval.label === 'СУТКИ') return getRoundRange(rangePicker);

  const standardIntervalMin = standardInterval.duration / MINUTE_SECONDS;
  const stepOnHour = MINUTES_IN_HOUR / standardIntervalMin;

  const dateFormatted = rangePicker.map((date, i) => {
    const min = date.get('m');

    //get the range of closest values for the required minute
    const range = getClosestRange(stepOnHour, standardIntervalMin, min);

    const formatMin = range.length === 1 ? min : range[i];

    const formDate = date.set('m', formatMin).set('s', 0);
    const isNewHour = formatMin === MINUTES_IN_HOUR;

    return !isNewHour ? formDate : formDate.subtract(1, 'hour');
  });

  return dateFormatted as TSelectDates;
};
