import { TlJournalType } from '../../../../constants/detailedTabsConstant';
import { TLDetailedCode } from '../../../../ts/enums/userData';
import { MANAGEMENT_KEY } from '../../../InfoPanel/SystemsInfo/InfoBtns/InfoBtns';
import TlCameras from '../../../TrafficLightDetailed/TlCameras/TlCameras';
import TlDetectors from '../../../TrafficLightDetailed/TlDetectors/TlDetectors';
import TlJournalTable from '../../../TrafficLightDetailed/TlJournalTable/TlJournalTable';
import TlMainInfo from '../../../TrafficLightDetailed/TlMainInfo/TlMainInfo';
import TlManagement from '../../../TrafficLightDetailed/TlManagement/TlManagement';
import TlManagementPlans from '../../../TrafficLightDetailed/TlManagementPlans/TlManagementPlans';
import TlObjectPassport from '../../../TrafficLightDetailed/TlObjectPassport/TlObjectPassport';
import TlPhaseScheme from '../../../TrafficLightDetailed/TlPhaseScheme/TlPhaseScheme';
import TlPlanning from '../../../TrafficLightDetailed/TlPlanning/TlPlanning';
import TlSignalProgramSetting from '../../../TrafficLightDetailed/TlSignalProgramSetting/TlSignalProgramSetting';
import TLTrafficModel from '../../../TrafficLightDetailed/TLTrafficModel/TLTrafficModel';

import { PanelTabConfig } from './constants';

export const MAIN_KEY = '1';

export const TL_DETAILED_ITEMS: PanelTabConfig[] = [
  {
    key: MAIN_KEY,
    label: 'ОСНОВНАЯ ИНФОРМАЦИЯ',
    code: TLDetailedCode.MainInfo,
    Component: TlMainInfo,
    isLoaded: false,
  },
  {
    key: '2',
    label: 'ПАСПОРТ ОБЪЕКТА',
    code: TLDetailedCode.Passport,
    Component: TlObjectPassport,
    isLoaded: true,
  },
  {
    key: '3',
    label: 'ДЕТЕКТОРЫ',
    code: TLDetailedCode.Detectors,
    Component: TlDetectors,
    isLoaded: true,
  },
  {
    key: '4',
    label: 'НАСТРОЙКА ОТОБРАЖЕНИЯ СИГНАЛЬНОЙ ПРОГРАММЫ',
    code: TLDetailedCode.SignalProgramSettings,
    Component: TlSignalProgramSetting,
    isLoaded: true,
  },
  {
    key: '5',
    label: 'ТРАНСПОРТНАЯ МОДЕЛЬ',
    code: TLDetailedCode.TrafficModel,
    Component: TLTrafficModel,
    isLoaded: false,
  },
  {
    key: '6',
    label: 'КАМЕРЫ',
    code: TLDetailedCode.Cameras,
    Component: TlCameras,
    isLoaded: false,
  },
  {
    key: '7',
    label: 'СХЕМА ПОФАЗНОГО РАЗЪЕЗДА',
    code: TLDetailedCode.PhaseScheme,
    Component: TlPhaseScheme,
    isLoaded: true,
  },
  {
    key: MANAGEMENT_KEY,
    label: 'УПРАВЛЕНИE',
    code: TLDetailedCode.Management,
    Component: TlManagement,
    isLoaded: false,
  },
  {
    key: '9',
    label: 'ПЛАНИРОВАНИЕ',
    code: TLDetailedCode.Planning,
    Component: TlPlanning,
    isLoaded: false,
  },
  {
    key: '10',
    label: 'ПЛАН УПРАВЛЕНИЯ',
    code: TLDetailedCode.ManagementPlan,
    Component: TlManagementPlans,
    isLoaded: true,
  },
  {
    key: '11',
    label: 'ЖУРНАЛ СОСТОЯНИЯ',
    code: TLDetailedCode.StatusJournal,
    Component: TlJournalTable,
    isLoaded: false,
    type: TlJournalType.Status,
  },
  {
    key: '12',
    label: 'ЖУРНАЛ УПРАВЛЕНИЯ',
    code: TLDetailedCode.ManageJournal,
    Component: TlJournalTable,
    isLoaded: false,
    type: TlJournalType.Control,
  },
  {
    key: '13',
    label: 'ЖУРНАЛ СЛУЖЕБНЫХ СООБЩЕНИЙ',
    code: TLDetailedCode.ServiceJournal,
    Component: TlJournalTable,
    isLoaded: false,
    type: TlJournalType.Service,
  },
];
