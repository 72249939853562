import { IoIosWarning } from 'react-icons/io';

import styles from './TitleConfirm.module.scss';

const TITLE = `Отправка команд управления для группы светофорных объектов`;

const TitleConfirm = () => {
  return (
    <div className={styles.titleContainer}>
      <IoIosWarning className={styles.iconInfo} />
      <h3>{TITLE}</h3>
    </div>
  );
};

export default TitleConfirm;
