import { Form } from 'antd';
import type { ValidateStatus } from 'antd/es/form/FormItem';
import { FC, ReactNode } from 'react';

import { TipType } from '../../../../stores/uiStore/uiStore.model';
import Popover, { Content } from '../../../ui-kit/Popover/Popover';

import styles from './Item.module.scss';

interface ItemProps {
  label: string;
  children: ReactNode;
  validateStatus?: ValidateStatus;
  help?: ReactNode;
  tipsDelay?: TipType;
  content?: Content;
  required?: boolean;
}

const Item: FC<ItemProps> = ({
  label,
  children,
  validateStatus,
  content,
  tipsDelay,
  help,
  required,
}) => {
  return (
    <Form.Item
      label={label}
      className={styles.formItem}
      validateStatus={validateStatus}
      help={help}
      required={required}
    >
      <Popover placement="right" tipsDelay={tipsDelay} content={content}>
        {children}
      </Popover>
    </Form.Item>
  );
};

export default Item;
