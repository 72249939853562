import { FC, useMemo, useState } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Label,
  Legend,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import type { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart';

import { COLORS } from '../../../../constants/colorsConstants';
import { IDateInfo } from '../../../TrafficLightDetailed/TlDetectors/model/graph.model';
import DefaultChecked from '../../DefaultChecked/DefaultChecked';
import {
  ANIMATION_GRAPH_DURATION,
  AREA_COLOR,
  AVG_KEY_VALUE,
  IAreaX,
  IDomain,
  LINES_WIDTH,
  MARGIN_GRAPH,
  NO_DATA_INTERVAL_TEXT,
  TICK_COUNT,
  X_KEY,
  X_TICK_COUNT,
} from '../constants/constants';
import CustomedTick from '../CustomedTick/CustomedTick';
import CustomLegend from '../CustomLegend/CustomLegend';
import CustomTooltip from '../CustomTooltip/CustomTooltip';
import { getAreaProps } from '../helpers/getAreaProps';
import { getChartNames } from '../helpers/getChartNames';
import LabelGraph from '../LabelGraph/LabelGraph';
import {
  CalcData,
  DataValue,
  DefaultLaneProps,
  IColors,
} from '../models/chart.model';

import styles from '../Chart.module.scss';

interface LineChartProps {
  data: DataValue[];
  verticalLabel: {
    key: string;
    value: string;
  };
  horizontalLabel: {
    key: string;
    value: string;
  };
  colors?: IColors[];
  isAvgValue: boolean;
  isLoading: boolean;
  isError: boolean;
  interval: IDateInfo['interval'];
  chartHelpers: {
    areaX: IAreaX;
    domain: IDomain;
    handleMouseDown: (e: CategoricalChartState) => void;
    handleMouseMove: (e: CategoricalChartState) => void;
    zoom: () => void;
    calcData: CalcData[];
  };
}

const LineChart: FC<LineChartProps> = ({
  data,
  isAvgValue,
  horizontalLabel,
  verticalLabel,
  isLoading,
  isError,
  colors,
  interval,
  chartHelpers,
}) => {
  const [hoverLine, setHoverLine] = useState('');

  const { areaX, calcData, domain, handleMouseDown, handleMouseMove, zoom } =
    chartHelpers;

  const chartNames = useMemo(
    () =>
      getChartNames(data, isAvgValue, horizontalLabel.key, verticalLabel.key),
    [data, horizontalLabel.key, isAvgValue, verticalLabel.key]
  );

  return (
    <DefaultChecked
      isLoading={isLoading}
      isEmptyData={!data.length || isError}
      noDataProps={{
        isNeedBackground: false,
        textError: NO_DATA_INTERVAL_TEXT,
        classNameEmpty: styles.empty,
      }}
      isBackdropFilter
    >
      <div className={styles.graphContainer} style={{ userSelect: 'none' }}>
        <ResponsiveContainer>
          <AreaChart
            data={calcData}
            margin={MARGIN_GRAPH}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={zoom}
          >
            <defs>
              <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={AREA_COLOR} stopOpacity={0.8} />
                <stop offset="95%" stopColor={AREA_COLOR} stopOpacity={0.1} />
              </linearGradient>
            </defs>

            <XAxis
              dataKey={X_KEY}
              allowDecimals={false}
              tick={(props) => (
                <CustomedTick
                  {...props}
                  isX={true}
                  data={calcData}
                  labelKey={horizontalLabel.key}
                />
              )}
              type="number"
              tickCount={X_TICK_COUNT}
              allowDataOverflow
              domain={domain.x}
            >
              <Label
                value={horizontalLabel.value}
                content={<LabelGraph isX={true} />}
              />
            </XAxis>

            <YAxis
              dataKey={verticalLabel.key}
              allowDataOverflow
              allowDecimals={false}
              domain={domain.y}
              tickCount={TICK_COUNT}
              tick={(props) => <CustomedTick {...props} isX={false} />}
            >
              <Label
                value={verticalLabel.value}
                content={<LabelGraph isX={false} />}
              />
            </YAxis>

            <Legend
              content={
                <CustomLegend
                  setHoverLine={setHoverLine}
                  hoverLine={hoverLine}
                />
              }
              verticalAlign="top"
              layout="horizontal"
              align="left"
            />

            {areaX.left && areaX.right ? (
              <ReferenceArea
                x1={areaX.left}
                x2={areaX.right}
                strokeOpacity={0.3}
              />
            ) : null}

            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip
              content={(props) => (
                <CustomTooltip
                  {...props}
                  calcData={calcData}
                  keyValue={horizontalLabel.key}
                  interval={interval}
                />
              )}
            />
            {chartNames.map((el, index) => {
              const { opacity, stroke } = getAreaProps(
                colors,
                el,
                index,
                hoverLine
              );

              const defaultProps: DefaultLaneProps = {
                dataKey: el,
                type: 'monotone',
                animationDuration: ANIMATION_GRAPH_DURATION,
              };

              if (el === AVG_KEY_VALUE) {
                return (
                  <Area
                    {...defaultProps}
                    key={el}
                    strokeWidth={LINES_WIDTH}
                    fillOpacity={opacity}
                    stroke={AREA_COLOR}
                    fill="url(#colorGradient)"
                  />
                );
              }

              return (
                <Area
                  {...defaultProps}
                  key={el}
                  strokeWidth={LINES_WIDTH}
                  stroke={stroke ?? COLORS.PURPLE}
                  strokeOpacity={opacity}
                  fillOpacity={0}
                />
              );
            })}
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </DefaultChecked>
  );
};

export default LineChart;
