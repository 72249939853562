import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';

import rootStore from '../../../../../stores/rootStore/rootStore';
import { FilterInfo } from '../../models/chart.model';

import FilterGroup from './FilterGroup/FilterGroup';

import styles from './FilterButtons.module.scss';

interface FilterButtonsProps {
  filterInfo: FilterInfo[];
  isLoading: boolean;
}

const FilterButtons: FC<FilterButtonsProps> = ({ filterInfo, isLoading }) => {
  const { filterDtData, setFilteredDtData } = rootStore.detailedStore;

  //change filter type if disabled
  useEffect(() => {
    filterInfo.forEach(({ filterType, data }) =>
      data.forEach(({ type, disabled }) => {
        disabled &&
          filterDtData[filterType]?.some((el) => el === type) &&
          setFilteredDtData({ filteredType: filterType, value: type });
      })
    );
  }, [filterDtData, filterInfo, setFilteredDtData]);

  return (
    <div className={styles.container}>
      {filterInfo.map((el) => {
        return (
          <FilterGroup key={el.filterType} {...el} isLoading={isLoading} />
        );
      })}
    </div>
  );
};

export default observer(FilterButtons);
