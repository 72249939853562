import { Overlay } from 'ol';
import type { Coordinate } from 'ol/coordinate';
import { RefObject, useCallback } from 'react';

import setDisplayStyle from '../../../helpers/setDisplayStyle';
import rootStore from '../../../stores/rootStore/rootStore';

interface UseLayerTooltip {
  tooltipRef: RefObject<HTMLDivElement>;
  tooltip: U<Overlay>;
}

const useLayerTooltip = (props: UseLayerTooltip) => {
  const { tooltipRef, tooltip } = props;
  const { setLayerTooltipData } = rootStore.uiStore;

  const closeLayerTooltip: () => void = useCallback(() => {
    setDisplayStyle([tooltipRef]);
    setLayerTooltipData(null);
  }, [setLayerTooltipData, tooltipRef]);

  const getLayerTooltip = useCallback(
    async (
      id: U<number | string>,
      coordinates: U<Coordinate>,
      fields: { key: string; value: SN }[],
      displayField: string,
      isWeather?: boolean,
      title?: string,
      text?: string
    ) => {
      closeLayerTooltip();

      if (!coordinates) {
        return;
      }

      setLayerTooltipData({
        id,
        coordinates,
        fields,
        displayField,
        isWeather,
        title,
        text,
      });
      tooltip?.setPosition(coordinates);

      setDisplayStyle([tooltipRef], 'block');
    },
    [closeLayerTooltip, setLayerTooltipData, tooltip, tooltipRef]
  );

  return { closeLayerTooltip, getLayerTooltip };
};

export default useLayerTooltip;
