import { ILayer } from '../../../stores/gisDataStore/gisDataStore.model';
import { TMap } from '../../../stores/mapStore/mapStore.model';

import { isLayerInScaleRange } from './isLayerInScaleRange';
import { isLayerVisible } from './isLayerVisible';

export const hasIdentifiedLayers = (map: TMap, definitions: ILayer[]) => {
  if (!map) {
    return false;
  }

  return definitions.some(
    ({ id }) => isLayerVisible(map, id) && isLayerInScaleRange({ map, id })
  );
};
