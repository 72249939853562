import { Tag } from 'antd';
import { uniqueId } from 'lodash';
import { FC } from 'react';

import { MODES_INFO } from '../../../../../../constants/commands';
import { TlMode } from '../../../../../../ts/models/tl.model';
import { Tip } from '../../../model/tlManagement.model';

import styles from './TooltipCommandContent.module.scss';

interface TooltipCommandContentProps {
  title: string;
  infoTooltip: Tip;
  mode: TlMode;
  blocked?: boolean;
  isCurrentMode?: boolean;
  isStatus: boolean;
}

const TooltipCommandContent: FC<TooltipCommandContentProps> = ({
  title,
  infoTooltip,
  mode,
  blocked = false,
  isCurrentMode = false,
  isStatus,
}) => {
  const { btn, info } = infoTooltip;
  const { accessCmd, durationOn, workSgnProgram } = info;

  const accessCommand = accessCmd.map((el, i, arr) =>
    i === arr.length - 1 ? `${el}.` : `${el}, `
  );

  const durationText = durationOn
    ? `${durationOn} мин. (автоматически переключится на ${MODES_INFO.Work.dsc})`
    : 'будет активен, пока не включится другой режим';

  const accessValue = accessCmd.length
    ? accessCommand
    : 'доступно только переключение режимов';

  const MODE_CONTENT = [
    { label: 'Состояние СО:', value: workSgnProgram },
    { label: 'Длительность режима:', value: durationText },
    {
      label: 'Доступные команды:',
      value: isStatus ? null : accessValue,
    },
  ];

  if (blocked)
    return (
      <div className={styles.tooltipContainer}>
        <h5>
          <b>Режим: </b>
          {title} (mode: {mode})
        </h5>
        <p>{btn}</p>
      </div>
    );

  return (
    <div className={styles.tooltipContainer}>
      <h5>
        <b>Режим: </b>
        {title} (mode: {mode})
      </h5>
      {MODE_CONTENT.map(({ label, value }) => {
        if (!value) return null;

        return (
          <p key={uniqueId()}>
            <b>{label}</b> {value}
          </p>
        );
      })}
      {isCurrentMode ? (
        <Tag color="success" className={styles.tag}>
          Текущий
        </Tag>
      ) : null}
    </div>
  );
};

export default TooltipCommandContent;
