import type { MenuProps } from 'antd/lib';
import { AiOutlineMessage, AiOutlineStar } from 'react-icons/ai';

export const getMenuItems = (iconSize: number): MenuProps['items'] => [
  {
    key: '1',
    label: 'Добавить в избранное',
    icon: <AiOutlineStar size={iconSize} />,
    disabled: true,
  },
  {
    key: '2',
    label: 'Сообщить о проблеме',
    icon: <AiOutlineMessage size={iconSize} />,
    disabled: true,
  },
];
