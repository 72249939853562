import { TABLE_CSV_SEPARATOR } from '../../../../constants/constants';
import downloadFile from '../../../../helpers/downloadFile';
import getNormalizeDateTime from '../../../../helpers/getNormalizeDateTime';
import notification from '../../Notification/Notification';
import { IExport, isHeaderItem } from '../Export.model';

const ID_STATE = 'id';

type ExportData<T extends Record<K, ValueOf<T>>, K extends keyof T> = Pick<
  IExport<T, K>,
  'data' | 'headers'
>;

const useCsvExport = <T extends Record<K, ValueOf<T>>, K extends keyof T>({
  data,
  headers,
}: ExportData<T, K>) => {
  const exportData = () => {
    const rows = data;

    let result = '';

    if (rows.length === 0) {
      notification.error('EXPORT_ERROR');

      return;
    }

    headers?.forEach((el) => {
      const reference = el.referens;

      if (isHeaderItem(el) && reference === ID_STATE) {
        result += ID_STATE;
      }
    });

    rows.forEach((dataItem) => {
      let dataItemStr = '';

      for (const fieldName in dataItem) {
        const columnItem = headers?.find(
          //@ts-ignore
          ({ referens }) => fieldName === referens
        );

        if (!columnItem && headers) {
          continue;
        }

        dataItemStr +=
          TABLE_CSV_SEPARATOR + dataItem[fieldName as keyof typeof dataItem];
      }

      result += `\n${dataItemStr}`;
    });

    downloadFile(result, `${getNormalizeDateTime()} Таблица.csv`);
  };

  return { exportData };
};

export default useCsvExport;
