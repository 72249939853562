import { Modal } from 'antd';
import { ReactNode, FC } from 'react';

import Footer from './Footer/Footer';

import styles from '../PhaseHold.module.scss';

export type ModalConfirmProps = Omit<IModalConfirm, 'children'>;

const CANCEL_TEXT = 'Закрыть';

interface IModalConfirm {
  children: ReactNode;
  icon: ReactNode;
  modalTitle: string;
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  onOkConfirm: () => void;
  width: string;
  okText?: string;
  okButtonProps?: any;
  disabledReset?: boolean;
  handleCancel?: () => void;
  handleReset?: () => void;
}

const ModalConfirm: FC<IModalConfirm> = ({
  children,
  icon,
  modalTitle,
  openModal,
  setOpenModal,
  onOkConfirm,
  handleCancel,
  handleReset,
  width,
  disabledReset = false,
  okButtonProps,
  okText = 'Отправить',
}) => {
  return (
    <Modal
      title={
        <div className={styles.titleContainer}>
          {icon}
          <strong>{modalTitle}</strong>
        </div>
      }
      styles={{ body: { maxHeight: 800, overflowY: 'auto' } }}
      okText={okText}
      width={width}
      open={openModal}
      cancelText={CANCEL_TEXT}
      centered
      onCancel={() => {
        setOpenModal(false);
        handleCancel?.();
      }}
      onOk={onOkConfirm}
      okButtonProps={okButtonProps}
      footer={
        handleReset
          ? (_, { OkBtn, CancelBtn }) => (
              <Footer
                CancelBtn={CancelBtn}
                OkBtn={OkBtn}
                disabledReset={disabledReset}
                handleReset={handleReset}
              />
            )
          : undefined
      }
    >
      {children}
    </Modal>
  );
};

export default ModalConfirm;
