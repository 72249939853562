import { Dayjs } from 'dayjs';
import { useCallback, useEffect, useState } from 'react';

import { SECOND } from '../../../../../constants/constants';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { getTimeWithTimeZone } from '../../../TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeWithTimeZone';

const useTimer = (startTime: Dayjs, endTime: Dayjs) => {
  const { setStopTimerTrigger } = rootStore.detailedStore;

  const [sec, setSec] = useState<N<number>>(null);
  const [isTimeEnd, setIsTimeEnd] = useState<boolean>(false);
  const [timerId, setTimerId] = useState<NodeJS.Timer>();

  const tick = useCallback(() => {
    const dateNow = getTimeWithTimeZone().dateFormattedJS;

    const isHold = startTime.diff(dateNow) < 0 && endTime.diff(dateNow) > 0;

    const diff = (!isHold ? startTime : endTime).diff(dateNow, 's');

    setIsTimeEnd(startTime.diff(dateNow) < 0 && endTime.diff(dateNow) > 0);
    setSec(diff);
  }, [startTime, endTime]);

  useEffect(() => {
    if (!sec || sec >= 0) return;

    clearInterval(timerId);
    setSec(0);
    setStopTimerTrigger();
  }, [sec, setStopTimerTrigger, timerId]);

  useEffect(() => {
    let timer: U<NodeJS.Timer> = undefined;

    clearInterval(timer);
    tick();
    timer = setInterval(() => tick(), SECOND);

    setTimerId(timer);

    return () => {
      clearInterval(timer);
    };
  }, [tick]);

  return { sec, isTimeEnd };
};

export default useTimer;
