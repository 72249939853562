import { z } from 'zod';

import { VERSION } from '../../constants/versions';
import { getAppPathHeaders } from '../helpers/getAppPathHeaders';
import http from '../http';
import tryCatchWrapper from '../tryCatchWrapper';

const MEDIA = `${VERSION}/media/`;

const CrossroadSchema = z.string();

const mediaApi = {
  fetchCrossroad: (id: number, appPath: string, isNotify = true) =>
    tryCatchWrapper(
      async () => {
        const res = await http(`${MEDIA}crossroads/crossroad${id}.svg`, {
          headers: getAppPathHeaders(appPath),
        });

        return CrossroadSchema.parse(res.data);
      },
      {
        notifyKey: `GET_ERROR_CROSSROAD_${id}`,
        errorMessage: `Ошибка запроса изображения перекрестка ЕПУТС id${id}`,
        isHideErrorNotify: !isNotify,
      }
    ),
  fetchCrossroadsList: () =>
    tryCatchWrapper(async () => {
      const res = await http(`${MEDIA}crossroadsList`);

      return CrossroadSchema.array().parse(res.data);
    }),
};

export default mediaApi;
