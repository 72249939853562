import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { RouterProvider } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';
import useExtraReload from '../../hooks/useExtraReload';
import useLibraries from '../../hooks/useLibraries/useLibraries';
import useNeedReload from '../../hooks/useNeedReload';
import rootStore from '../../stores/rootStore/rootStore';
import Login from '../Login/Login';
import RegionSwitcher from '../RegionSwitcher/RegionSwitcher';
import useSocket from '../Socket/useSocket';

import useFetchRegionData from './hooks/useFetchRegionData';
import router from './router';

const App = () => {
  const { regionData } = rootStore.uiStore;
  const { isAuth, isAppReady, isNeedPageReload } = rootStore.userDataStore;

  useAuth();
  useExtraReload();
  useNeedReload(isNeedPageReload);
  useSocket();
  useLibraries();
  useFetchRegionData();

  if (!isAppReady) {
    return <Spin size="large" fullscreen />;
  }
  if (!isAuth) {
    return <Login />;
  }
  if (!regionData) {
    return <RegionSwitcher />;
  }

  return <RouterProvider router={router} />;
};

export default observer(App);
