import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../stores/rootStore/rootStore';
import { useCrossroadDirsImg } from '../../hooks';
import { CircleCrossroad } from '../../models/crossroad.model';
import PhaseCircleImg from '../../PhaseCircleImg/PhaseCircleImg';

import styles from './PhaseCircleCrossroad.module.scss';

const PhaseCircleCrossroad: FC<CircleCrossroad> = (props) => {
  const { calcCircleVal } = rootStore.pointsUdsStore;

  const {
    positionXY: [offsetX, offsetY],
    size,
    angle,
  } = props.tlCrossroad;

  const { data, isLoading } = useCrossroadDirsImg(props);

  if (isLoading) {
    return (
      <div className={styles.imgContainer}>
        <Spin size="large" />
      </div>
    );
  }

  if (!data) return null;

  const style = {
    top: calcCircleVal(offsetY),
    left: calcCircleVal(offsetX),
    width: calcCircleVal(size),
    height: calcCircleVal(size),
    transform: `translate(-50%,-50%) rotate(${angle}deg)`,
    overflow: 'visible',
  };

  return <PhaseCircleImg data={data} style={style} />;
};

export default observer(PhaseCircleCrossroad);
