import { IS_DEV } from '../../constants/constants';
import { VERSION } from '../../constants/versions';
import { sendError } from '../../helpers/sendError';
import { getAppPathHeaders } from '../helpers/getAppPathHeaders';
import http from '../http';
import tryCatchWrapper from '../tryCatchWrapper';

import { LibraryKeys } from './enums/enums';
import { LibrarySchema } from './libraries.zod';

const LIBRARY = VERSION + '/libraries';

export const libraries = {
  getLibrariesByKey: (key: LibraryKeys, appPath: string) =>
    tryCatchWrapper(
      async () => {
        const response = await http.get(`${LIBRARY}/${key}`, {
          headers: getAppPathHeaders(appPath),
        });

        const validate = LibrarySchema.array().safeParse(response.data);

        if (!validate.success) {
          const errorTitle = `Ошибка валидации словаря по ключу - ${key}`;

          console.error(errorTitle, validate.error);

          !IS_DEV &&
            sendError(
              errorTitle,
              {
                issues: validate.error.issues,
              },
              appPath
            );

          return;
        }

        return validate.data;
      },
      {
        isBlockedNotify: true,
      }
    ),
};
