import { Form, InputNumber } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { COLORS } from '../../../../constants/colorsConstants';
import { THEME } from '../../../../constants/constants';
import rootStore from '../../../../stores/rootStore/rootStore';
import CircularSlider from '../../../ui-kit/CircularSlider/CircularSlider';

import styles from './Angle.module.scss';

const { BLUE500, BLUE100 } = COLORS;

interface AngleProps {
  onChange: (val: number) => void;
  angle: number;
  label?: string;
  title?: string;
  isInput?: boolean;
  isVisible?: boolean;
}

const Angle: FC<AngleProps> = ({
  onChange,
  angle,
  label = 'Поворот:',
  title = 'УГОЛ',
  isInput = false,
  isVisible = true,
}) => {
  if (!isVisible) return null;

  const { theme } = rootStore.uiStore;

  return (
    <Form.Item label={label}>
      <div className={styles.circularContainer}>
        <CircularSlider
          label={title}
          onChange={onChange}
          labelColor={theme === THEME.LIGHT ? BLUE500 : BLUE100}
          value={angle}
        />

        {isInput && (
          <Form.Item label="Ввод:">
            <InputNumber
              min={0}
              max={359}
              value={angle}
              onChange={(val) => val !== null && onChange(val)}
              style={{ width: 60 }}
            />
          </Form.Item>
        )}
      </div>
    </Form.Item>
  );
};

export default observer(Angle);
