import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import { FeatureDeviceProps } from '../../../ts/enums/enums';
import useDynamicOverlays from '../../Map/hooks/useDynamicOverlays';
import useInfoPopup from '../../Map/hooks/useInfoPopup';

import InfoOverlay from './InfoOverlay/InfoOverlay';

const InfoPopup: FC = () => {
  const { map } = rootStore.mapStore;
  const { isCrossroadBorder, center } = rootStore.mapDataStore;
  const { infoData, isInfoPopup } = rootStore.uiStore;
  const { isPhaseCircle } = rootStore.pointsUdsStore;
  const { isConstructor } = rootStore.constructorStore;
  const { selectedFeatureSomeArray } = rootStore.scriptsControlStore;
  const { unlinkedFeatures } = rootStore.clustersStore;

  const features = unlinkedFeatures.map((feature) => ({ feature }));

  useInfoPopup();

  const {
    elementRefs,
    isRemovedOverlays,
    featuresInExtent,
    isReadyElementRefs,
  } = useDynamicOverlays({
    map,
    featuresWithOverlays: isCrossroadBorder ? features : [],
    isShowOverlay: isCrossroadBorder && !isPhaseCircle,
    center,
    isConstructor,
    isInsertFirstOverlay: true,
  });

  if (
    !(
      isReadyElementRefs &&
      featuresInExtent.length &&
      isCrossroadBorder &&
      isRemovedOverlays &&
      elementRefs.length
    )
  )
    return null;

  return (
    <div>
      {featuresInExtent.map(({ feature }, i) => {
        const id = feature.get(FeatureDeviceProps.Id);

        const isSelectFeatureScriptsControl = !!selectedFeatureSomeArray.find(
          (el) => el.id === id
        );
        const isSelectedFeature =
          (id === infoData?.id && isInfoPopup) || isSelectFeatureScriptsControl;

        return (
          <InfoOverlay
            key={`info_block_${id}`}
            feature={feature}
            isSelect={isSelectedFeature}
            infoRef={elementRefs[i]}
          />
        );
      })}
    </div>
  );
};

export default observer(InfoPopup);
