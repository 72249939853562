import {
  BASEMAPS_PROPS,
  BASEMAPS_TYPES,
} from '../../../apiGIS/constants/basemaps/utils';
import { createBasemapLayer } from '../../../apiGIS/utils/basemaps';
import {
  IBaseMapItem,
  ILayer,
} from '../../../stores/gisDataStore/gisDataStore.model';
import { TMap } from '../../../stores/mapStore/mapStore.model';
import { CategoryLayerGIS } from '../../../ts/enums/enums';

export const getBasemapLayer = (map: TMap, id: string) => {
  if (!map) {
    return;
  }

  const mapLayers = map.getLayers().getArray();

  return mapLayers.find(
    (layer) => layer.get(BASEMAPS_PROPS.LAYER_ID_PROPERTY) === id
  );
};

export const disableTrafficLayers = (map: TMap, definitions: ILayer[]) => {
  if (!map || !definitions?.length) {
    return;
  }

  const mvtDefinitions = definitions.filter((item) => {
    return item?.category?.category === CategoryLayerGIS.Traffic;
  });

  const ids = mvtDefinitions.map(
    (item) => item.id + BASEMAPS_PROPS.MVT_ID_PROPERTY
  );

  const mapLayers = map.getLayers().getArray();

  ids.forEach((id) => {
    const layer = mapLayers.find(
      (item) => item.get(BASEMAPS_PROPS.LAYER_ID_PROPERTY) === id
    );

    layer && layer.setVisible(false);
  });
};

export const enableTrafficLayers = (map: TMap, definitions: ILayer[]) => {
  disableTrafficLayers(map, definitions);

  if (!map || !definitions?.length) {
    return;
  }

  const mvtDefinitions = definitions.filter((item) => {
    return item?.category?.category === CategoryLayerGIS.Traffic;
  });

  mvtDefinitions.forEach((item) => {
    const { url } = item;

    const id = item.id + BASEMAPS_PROPS.MVT_ID_PROPERTY;

    const mapLayer = getBasemapLayer(map, id);

    if (mapLayer) {
      mapLayer.setVisible(true);

      return;
    }

    const props: IBaseMapItem = {
      id: id,
      url,
      type: BASEMAPS_TYPES.VECTOR,
      persistent: BASEMAPS_PROPS.IS_PERSISTENT,
      zIndex: BASEMAPS_PROPS.MVT_LAYER_Z_INDEX,
    };

    const layer = createBasemapLayer(props);

    layer?.setVisible(true);

    layer && map.addLayer(layer);
  });
};
