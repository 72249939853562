import { cloneDeep } from 'lodash';

import { FilesDataType } from '../../../models/documents.model';

const getFilteredData = (data: FilesDataType[], value: string) =>
  cloneDeep(data).map((item) => {
    const { children } = item;

    if (!children) return item;

    const filteredChildren = children.filter(({ profile: { name } }) =>
      name.includes(value)
    );

    item.children = filteredChildren;

    return item;
  });

export default getFilteredData;
