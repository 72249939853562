import Heatmap from 'ol/layer/Heatmap';
import VectorLayer from 'ol/layer/Vector';
import VectorImageLayer from 'ol/layer/VectorImage';

import { TMap } from '../../../stores/mapStore/mapStore.model';
import { WebGLLayer } from '../helpers';

export const isLayerVisible = (map: TMap, id: U<string>) => {
  if (!map || !id) {
    return false;
  }

  const layer = map
    .getLayers()
    .getArray()
    .find((element) => element.get('id') === id);

  if (
    layer &&
    (layer instanceof VectorLayer ||
      layer instanceof WebGLLayer ||
      layer instanceof VectorImageLayer ||
      layer instanceof Heatmap)
  ) {
    return layer.getVisible();
  }

  return false;
};
