import ExelViewer from '../Documents/DocViewWindow/ExelViewer/ExelViewer';
import ImgViewer from '../Documents/DocViewWindow/ImgViewer/ImgViewer';
import PdfViewer from '../Documents/DocViewWindow/PdfViewer/PdfViewer';

export const ALLOWED_EXTENTIONS = {
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  png: 'image/png',
  pdf: 'application/pdf',
  txt: 'text/plain',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
} as const;

const { jpeg, jpg, png, pdf, xls, xlsx } = ALLOWED_EXTENTIONS;

export const typesComponents = [
  {
    types: [jpeg, jpg, png],
    Component: ImgViewer,
  },
  {
    types: [pdf],
    Component: PdfViewer,
  },
  {
    types: [xls, xlsx],
    Component: ExelViewer,
  },
];
