import { Button, Card } from 'antd';
import { uniqueId } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useRef } from 'react';
import 'dayjs/locale/ru';

import rootStore from '../../../../stores/rootStore/rootStore';
import { MessageType } from '../../../../ts/models/logsPanel.model';
import Popover from '../../../ui-kit/Popover/Popover';

import Message from './Message/Message';

import styles from './LogsPanel.module.scss';

export interface ILogsPanel {
  className: string;
  title: string;
  messages: MessageType[];
  clearLogs?: () => void;
}

const POPOVER_CONTENT = 'Очистить окно сообщений светофорного объекта?';

const LogsPanel: FC<ILogsPanel> = ({
  className,
  title,
  messages,
  clearLogs,
}) => {
  const { tipsValues } = rootStore.uiStore;
  const { user } = rootStore.userDataStore;

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const height = containerRef.current?.scrollHeight;

    containerRef.current?.scrollTo({ top: height, behavior: 'smooth' });
  }, [messages]);

  return (
    <Card className={className}>
      <div className={styles.titleBlock}>
        <h3>{title}</h3>
        <Popover
          placement="right"
          tipsDelay={tipsValues.interfaceTipsDelay}
          content={POPOVER_CONTENT}
        >
          <Button onClick={clearLogs} className={styles.btn}>
            Очистить
          </Button>
        </Popover>
      </div>
      <hr className={styles.line}></hr>
      <div className={styles.block} ref={containerRef}>
        {messages.map((item) => (
          <Message user={user} item={item} key={uniqueId('#')} />
        ))}
        <br />
      </div>
    </Card>
  );
};

export default observer(LogsPanel);
