import { Form } from 'antd';
import { type Rule } from 'antd/es/form';
import dayjs, { Dayjs } from 'dayjs';
import { isString } from 'lodash';
import { FC } from 'react';

import { VALUE_TYPES } from '../../../../../../ts/models/table.model';
import DatePicker from '../../../../Picker/DatePicker/DatePicker';
import RangePicker from '../../../../Picker/RangePicker/RangePicker';
import { getPresets } from '../helpers';

import getCorrectFormat from './helpers/getCorrectFormat';

import styles from './DateFormatPicker.module.scss';

interface DateFormatPickerProps {
  format: string;
  onChange: (value: N<[Dayjs, Dayjs] | Dayjs>) => void;
  formProps: {
    formValues: Record<string, any>;
    jkey: string;
    jvalueType: ValueOf<typeof VALUE_TYPES>;
  };
  basicParams: {
    label: string;
    name: string;
    rules: U<Rule[]>;
  };
}

const DateFormatPicker: FC<DateFormatPickerProps> = ({
  format,
  formProps,
  onChange,
  basicParams,
}) => {
  const { formValues, jkey } = formProps;

  const value: N<Dayjs[]> = formValues[jkey];

  const correctFormat = getCorrectFormat(format, formProps.jvalueType);

  if (!correctFormat) return null;

  const { dateFormat, isRange, picker, isTime } = correctFormat;

  const isRangeFormat = isRange && isString(dateFormat) && !picker;
  const checkValuesRange =
    !value ||
    (value?.length === 2 && value.map((el: Dayjs) => dayjs.isDayjs(el)));
  const isRangePicker = isRangeFormat && checkValuesRange;

  const isDatePicker = !isRange && (dayjs.isDayjs(value) || !value);

  if (value && !isDatePicker && !isRangePicker) return null;

  const main = isRangePicker ? (
    <RangePicker
      onChangeDate={onChange}
      dateFormat={dateFormat}
      presets={getPresets()}
      allowClear={false}
      pickerClassName={styles.date}
    />
  ) : (
    <DatePicker
      dateFormat={dateFormat}
      onChangeDate={onChange}
      isTime={isTime}
      picker={picker}
      allowClear={false}
      pickerClassName={styles.date}
    />
  );

  return (
    <Form.Item key={basicParams.name} {...basicParams}>
      {main}
    </Form.Item>
  );
};

export default DateFormatPicker;
