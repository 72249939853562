import { observer } from 'mobx-react-lite';
import { Outlet } from 'react-router-dom';

import DetailedWrapper from '../DetailedMain/DetailedWrapper/DetailedWrapper';
import InfoPanelWithContext from '../InfoPanel/InfoPanelWithContext';
import Mapper from '../Mapper/Mapper';
import Panel from '../Panel/Panel';
import QueryDevTools from '../ReactQuery/QueryDevTools';
import Sidebar from '../Sidebar/Sidebar';

import useAppSearchParams from './hooks/useAppSearchParams';

const AppLayout = () => {
  useAppSearchParams();

  return (
    <>
      <Sidebar />
      <Panel />
      <Mapper />
      <InfoPanelWithContext />
      <DetailedWrapper />
      <QueryDevTools />
      <Outlet />
    </>
  );
};

export default observer(AppLayout);
