import { END_TIME } from '../constants/constants';

export const checkDate = (progNumFrom: N<string>) => {
  if (!progNumFrom || progNumFrom === END_TIME) return '';

  const dateArr = progNumFrom.split(' ');

  if (dateArr.length === 1) return progNumFrom;

  const dateNow = new Date().toLocaleDateString('ru');

  if (dateArr[0] === dateNow) {
    return dateArr[1];
  }

  return progNumFrom;
};
