import type { Coordinate } from 'ol/coordinate';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { fromLonLat } from 'ol/proj';
import { Style } from 'ol/style';

import { TDeviceStatus } from '../../../constants/devicesConstants';
import { getIsDisabledControl } from '../../../stores/mapDataStore/helpers/getIsDisabledControl';
import { TObjMap } from '../../../stores/mapDataStore/mapDataStore.model';
import { FeatureDeviceProps, System } from '../../../ts/enums/enums';
import { isTL } from '../../../ts/models/mapObject.model';
import { TlMode } from '../../../ts/models/tl.model';

import { getStyle } from './getStyle';

interface BasicInfo {
  isDisabled?: boolean;
}

type FeatureInfo = BasicInfo & TObjMap;

interface MarkerData {
  id: number;
  lonLat: Coordinate;
  mode: N<TlMode>;
  deviceStatus: TDeviceStatus;
  isDisabled: boolean;
}

const {
  DeviceStatus,
  Id,
  IsDisabled,
  Mode,
  System: SystemProps,
} = FeatureDeviceProps;

interface FeatureProps {
  [Id]: number;
  [SystemProps]: System;
  [Mode]: N<TlMode>;
  [DeviceStatus]: TDeviceStatus;
  [IsDisabled]: boolean;
  geometry: Point;
  style: Style[];
}

export const getMarker = (
  item: FeatureInfo,
  isGroupDispatch = false
): MarkerData => ({
  id: item.id,
  lonLat: [item.longitude, item.latitude],
  mode: isTL(item) ? item.tlStatus.mode : null,
  deviceStatus: item.deviceInfo.deviceStatusId,
  isDisabled: isGroupDispatch
    ? getIsDisabledControl(item.deviceInfo.deviceStatusId)
    : item.isDisabled ?? false,
});

const getMarkersData = (data: FeatureInfo[], isGroupDispatch: boolean) =>
  data.map((item) => getMarker(item, isGroupDispatch));

export const addMarker = (
  { id, lonLat, mode, deviceStatus, isDisabled }: MarkerData,
  set: System
) => {
  const featureProps: FeatureProps = {
    geometry: new Point(fromLonLat(lonLat)),
    style: getStyle(set, mode, deviceStatus, isDisabled),
    id: id,
    system: set,
    mode,
    deviceStatus,
    isDisabled,
  };

  return new Feature(featureProps);
};

const addMarkers = (
  data: FeatureInfo[],
  set: System,
  isGroupDispatch = false
) => {
  if (!data) return [];

  const features = getMarkersData(data, isGroupDispatch).map((el) => {
    const feature = addMarker(el, set);

    return feature;
  });

  return features;
};

export default addMarkers;
