import { cloneDeep, isString } from 'lodash';

import { getTreeSorter } from '../../../../../helpers/treeHelpers/getTreeSorter';
import { PanelTree } from '../../../AdminPanel/helpers/getMenuLabelTooltips';
import { ReportsTreeItem } from '../model/reports.model';
import TreeItem from '../TreeItem/TreeItem';

interface FormattedTree extends Omit<ReportsTreeItem, 'label'> {
  label: string | JSX.Element;
  count?: number;
  disabled?: boolean;
}

const updateCounts = <T extends PanelTree>(tree: T) => {
  if (!tree.children) return 1;

  tree.count = tree.count ?? 0;

  tree.children.forEach((item) => {
    const r = updateCounts(item);

    tree.count = (tree.count ?? 0) + r;
  });

  return tree.count;
};

const getCountsTree = <T extends PanelTree>(tree: T[]) => {
  const cloneTree = cloneDeep(tree);

  cloneTree.forEach((tree) => {
    updateCounts(tree);
  });

  return cloneTree;
};

const setLabelsToTreeItems = (menu: FormattedTree[], maxLen: number) => {
  menu.forEach((item) => {
    if (isString(item.label)) {
      item.children && (item.disabled = item.count === 0);
      item.label = (
        <TreeItem
          title={item.label}
          deviceId={item.params?.device_id}
          maxLen={maxLen}
          count={item.count}
        />
      );
    }

    if (item.children) {
      return setLabelsToTreeItems(item.children, maxLen);
    }
  });

  return menu;
};

const sorterByDeviceId = (prev: ReportsTreeItem, current: ReportsTreeItem) => {
  const prevId = prev.params?.device_id;
  const curId = current.params?.device_id;

  if (prevId && curId) {
    return prevId - curId;
  }

  return 0;
};

export const getFormattedTreeByDevId = (
  menu: ReportsTreeItem[],
  maxLen = 25
) => {
  const cloneTree = cloneDeep(menu);

  const sortTree = getTreeSorter(cloneTree, sorterByDeviceId);

  const updatedTree = getCountsTree(sortTree);

  setLabelsToTreeItems(updatedTree, maxLen);

  return updatedTree;
};
