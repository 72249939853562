/* eslint-disable jsx-a11y/no-static-element-interactions */
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, useRef, useState } from 'react';

import parseTLMode from '../../../../../../../../../helpers/parseTLMode';
import rootStore from '../../../../../../../../../stores/rootStore/rootStore';
import { System } from '../../../../../../../../../ts/enums/enums';
import { TlMode } from '../../../../../../../../../ts/models/tl.model';
import { getAvatarIcon } from '../../../../../../../../DetailedStatistics/Monitoring/SystemMonitoring/helpers/helpers';
import { postCommand } from '../../../../../../../../TrafficLightDetailed/TlManagement/helpers/postCommand';
import TooltipCommandContent from '../../../../../../../../TrafficLightDetailed/TlManagement/ManagementCommands/CommandCard/TooltipCommandContent/TooltipCommandContent';
import Confirm from '../../../../../../../../ui-kit/Confirm/Confirm';
import Popover from '../../../../../../../../ui-kit/Popover/Popover';
import { ModalConfirmProps } from '../../../model/dispatchGroup.model';

import { getCommandInfo } from './helpers/getCommandInfo';

import styles from './ControlBtn.module.scss';

const APP_PATH =
  '.Panel.ScriptsControl.SelectModule.DispatchGroupControl.ControlBtn';

interface ControlBtnProps {
  mode: TlMode;
  tlIds: number | number[];
  isActiveCoordTL: boolean;
  isDisabledControl: boolean;
  ModalComponent?: FC<ModalConfirmProps>;
}

const ControlBtn: FC<ControlBtnProps> = ({
  mode,
  tlIds,
  isActiveCoordTL,
  isDisabledControl,
  ModalComponent,
}) => {
  const { interfaceTipsDelay } = rootStore.uiStore;

  const [isLoadingRequest, setIsLoadingRequest] = useState<boolean>(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);

  const color = parseTLMode(mode).color;
  const isModal = !!ModalComponent;

  const commandInfo = getCommandInfo({
    btnMode: mode,
    tlIds,
    isActiveCoordTL,
    setIsOpenConfirm,
    cardRef,
  });

  if (!commandInfo) return null;

  const {
    infoTooltip,
    isCurrentMode = false,
    isDisabled = false,
    modeLabel,
    propsPostCommand,
    title,
  } = commandInfo;

  const isMainDisabled = isDisabledControl || isDisabled;

  const handlePostCommand = async () => {
    setIsLoadingRequest(true);

    const { ids, ...props } = propsPostCommand;

    const commands = ids.map((el) =>
      postCommand({ ...props, id: el }, APP_PATH)
    );

    await Promise.allSettled(commands);

    setIsLoadingRequest(false);
    setIsOpenConfirm(false);
  };

  const blockContainer = classNames({
    [styles.blockCard]: true,
    [styles.disabled]: isMainDisabled,
  });

  return (
    <>
      <Confirm
        title={title}
        okButtonProps={{ danger: true, loading: isLoadingRequest }}
        disabled={isMainDisabled}
        overlayClassName={styles.confirm}
        onConfirm={handlePostCommand}
        isOpen={!isModal ? isOpenConfirm : false}
        onCancel={() => setIsOpenConfirm(false)}
      >
        <Popover
          tipsDelay={interfaceTipsDelay}
          open={isOpenConfirm ? false : undefined}
          content={
            <TooltipCommandContent
              title={modeLabel}
              infoTooltip={infoTooltip}
              mode={mode}
              isCurrentMode={isCurrentMode}
              isStatus={false}
            />
          }
        >
          <div
            ref={cardRef}
            className={blockContainer}
            onClick={() => !isMainDisabled && setIsOpenConfirm((prev) => !prev)}
          >
            <span style={{ color }}>{getAvatarIcon(System.Lights)}</span>
          </div>
        </Popover>
      </Confirm>
      {isModal && Array.isArray(tlIds) ? (
        <ModalComponent
          isOpen={isOpenConfirm}
          setIsOpen={setIsOpenConfirm}
          mode={mode}
          onOk={handlePostCommand}
          tlIds={tlIds}
          okButtonProps={{ loading: isLoadingRequest }}
        />
      ) : null}
    </>
  );
};

export default observer(ControlBtn);
