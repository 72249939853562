import { useEffect, useMemo, useState } from 'react';

import { findById } from '../../../../../helpers/findBy';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { TimeCardBasicProps } from '../../model/timeCard.model';
import { INITIAL_SELECTED_TIME } from '../../PlanSelect/constants/constants';
import { SelectedTime } from '../../PlanSelect/models/plansControl.model';
import { ModalPhaseProps } from '../PhaseHold.model';

type ModalProps = Omit<ModalPhaseProps, 'activeTL'>;

const usePhaseHold = (id: number) => {
  const { tls } = rootStore.mapDataStore;
  const { activeControlKey } = rootStore.detailedStore;

  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [selectedTimeInfo, setSelectedTimeInfo] = useState<SelectedTime>(
    INITIAL_SELECTED_TIME
  );

  const activeTL = useMemo(() => findById(tls, id), [id, tls]);

  const { count, sgnProgramName } = useMemo(() => {
    const tacts = activeTL?.tlTacts;
    const count = tacts?.reduce((acc, item) => acc + item.main, 0) ?? 0;
    const sgnProgramName = activeTL?.tlStatus.command?.full ?? 'План не найден';

    return {
      count,
      sgnProgramName,
    };
  }, [activeTL]);

  useEffect(() => {
    setIsChecked(false);
  }, [activeControlKey, setIsChecked]);

  useEffect(() => {
    !isChecked && setSelectedTimeInfo(INITIAL_SELECTED_TIME);
  }, [isChecked, setSelectedTimeInfo]);

  const modalPhaseProps: ModalProps = {
    isChecked,
    selectedTimeInfo,
    setSelectedTimeInfo,
    setIsChecked,
  };

  const timeCardProps: TimeCardBasicProps = {
    isChecked,
    setIsChecked,
    selectedTimeInfo,
    setSelectedTimeInfo,
  };

  return {
    sgnProgramName,
    count,
    activeTL,
    modalPhaseProps,
    timeCardProps,
  };
};

export default usePhaseHold;
