import { IRegionData } from '../../../../../api/server/web/web.zod';
import { ModulesTypes } from '../../../../../constants/constants';
import { findBy } from '../../../../../helpers/findBy';
import { ManagementModules } from '../../../../../stores/uiStore/uiStore.model';

type LicenseOptions = IRegionData['licenceOptions']['data'];

const { CoordControl, GroupDispatchManagement } = ManagementModules;

const ModulesMatch: Record<ManagementModules, string> = {
  [CoordControl]: ModulesTypes.CoordControl,
  [GroupDispatchManagement]: ModulesTypes.DispatchControl,
};

export const getModuleAccessConfInfo = (
  licenseOptions: U<LicenseOptions>,
  module: ManagementModules
) => {
  const moduleInfo = findBy(
    licenseOptions ?? [],
    ModulesMatch[module],
    'moduleType'
  );

  return {
    isDisabled: moduleInfo?.isDisabled ?? false,
    isVisible: moduleInfo?.isVisible ?? true,
  };
};
