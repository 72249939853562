import { Map } from 'ol';

import { ZOOM } from '../../../../../constants/mapConstants';
import { UiZoomBtnsProps } from '../UiZoomBtns/UiZoomBtns';
import { ZoomBtnsWrapperProps } from '../ZoomBtnsWrapper';

const getDisabledZoom = (
  map: Map,
  zoomProps: ZoomBtnsWrapperProps['zoomProps']
): UiZoomBtnsProps['disabled'] => {
  const newZoom = map.getView().getZoom();

  if (!newZoom)
    return {
      disabledDecrease: false,
      disabledIncrease: false,
      disabledReset: false,
    };

  const disabledIncrease = Math.floor(newZoom + ZOOM.STEP) >= zoomProps.maxZoom;
  const disabledDecrease = Math.floor(newZoom - ZOOM.STEP) <= zoomProps.minZoom;
  const disabledReset = Math.abs(Math.floor(newZoom) - zoomProps.minZoom) <= 1;

  return {
    disabledDecrease,
    disabledIncrease,
    disabledReset,
  };
};

export default getDisabledZoom;
