import { FC } from 'react';

import NoData from '../../../../ui-kit/Errors/NoData/NoData';
import VideoCamera from '../../../../VideoCamera/VideoCamera';
import { ThreadType, VIDEO_CAMERA_PROPS } from '../constants/constants';

interface ThreadWrapperProps {
  threadType: ThreadType;
}

const ThreadWrapper: FC<ThreadWrapperProps> = ({ threadType }) => {
  if (threadType === ThreadType.D2)
    return <NoData isNeedBackground={false} textError="В разработке" />;

  return <VideoCamera {...VIDEO_CAMERA_PROPS} />;
};

export default ThreadWrapper;
