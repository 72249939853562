import { FC } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { Children } from '../../ts/models/general.model';

import AntdProvider from './AntdProvider';
import QueryClientProvider from './QueryClientProvider';

const AppProvider: FC<Children> = ({ children }) => (
  <QueryClientProvider>
    <AntdProvider>
      <DndProvider backend={HTML5Backend}>{children}</DndProvider>
    </AntdProvider>
  </QueryClientProvider>
);

export default AppProvider;
