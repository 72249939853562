export const formatVersion = (vers: number) => `/v${vers}`;

export const formatVersions = (versObj: Record<string, number>) => {
  const res: Record<string, string> = {};

  for (const key in versObj) {
    res[key] = formatVersion(versObj[key]);
  }

  return res;
};
