import { QueryClientProvider as Provider } from '@tanstack/react-query';
import { FC } from 'react';

import { Children } from '../../ts/models/general.model';
import queryClient from '../ReactQuery/queryClient';

const QueryClientProvider: FC<Children> = ({ children }) => (
  <Provider client={queryClient}>{children}</Provider>
);

export default QueryClientProvider;
