import { buffer, type Extent } from 'ol/extent';

import { POINT_UDS_DISTANCE } from '../components/Overlays/PointUdsOverlays/constants/constants';
import { Device } from '../components/Overlays/PointUdsOverlays/PointUdsOverlays.model';
import { LonLat } from '../ts/models/mapObject.model';

interface InExtentArgs extends LonLat {
  extent: Extent;
  isNeedLonLatTransform?: boolean;
}

export const checkIsInExtent = ({
  longitude,
  latitude,
  extent,
}: InExtentArgs) => {
  const [minX, minY, maxX, maxY] = extent;

  const isX = longitude >= minX && longitude <= maxX;
  const isY = latitude >= minY && latitude <= maxY;

  return isX && isY;
};

export const checkIsInPointExtent = (
  point: XY,
  longitude: number,
  latitude: number
) => {
  const extent = buffer(point.concat(point), POINT_UDS_DISTANCE);

  return checkIsInExtent({ longitude, latitude, extent });
};

export const checkIsDeviceInExtent = (
  devices: Device[],
  point: XY,
  id: number
) => {
  const device = devices.find(({ deviceInfo }) => deviceInfo.deviceId === id);

  if (!device) return true;

  const { longitude, latitude } = device;

  return checkIsInPointExtent(point, longitude, latitude);
};
