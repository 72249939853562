import { TabsProps } from 'antd/lib';

import { ReactComponent as AutoIco } from '../../../../../assets/icons/vehicle_types/ic_auto.svg';
import { ReactComponent as BusIco } from '../../../../../assets/icons/vehicle_types/ic_bus.svg';
import { ReactComponent as MotoIco } from '../../../../../assets/icons/vehicle_types/ic_moto.svg';
import { ReactComponent as TruckIco } from '../../../../../assets/icons/vehicle_types/ic_truck.svg';
import { VehicleTypes } from '../../../../InfoPanel/SystemsInfo/PopupDetector/PopupDetector.model';
import NoData from '../../../../ui-kit/Errors/NoData/NoData';
import ControlModel from '../ControlModel/ControlModel';

interface Options {
  label: string | JSX.Element;
  value: SN;
}

export interface CollapseProps {
  title: string;
  intensity: number;
  speed: number;
  vehicles: {
    icon: JSX.Element;
    value: number;
    label: string;
    type: VehicleTypes;
  }[];
  turn: {
    label: string;
    value: number;
  }[];
}

export const OPTIONS_MACRO: Options[] = [
  {
    label: 'Утро',
    value: 'morning',
  },
  {
    label: 'День',
    value: 'day',
  },
  {
    label: 'Вечер',
    value: 'evening',
  },
  {
    label: 'Ночь',
    value: 'night',
  },
];

const { Auto, Bus, Moto, Truck } = VehicleTypes;

export const COLLAPSE_DATA: CollapseProps[] = [
  {
    intensity: 3212,
    speed: 37.16,
    title: 'Лихачевский проспект - из Москвы',
    vehicles: [
      {
        icon: <AutoIco />,
        type: Auto,
        label: 'Легковой автомобиль',
        value: 84.12,
      },
      { icon: <MotoIco />, type: Moto, label: 'Мотоцикл', value: 2.63 },
      {
        icon: <TruckIco />,
        type: Truck,
        label: 'Грузовой автомобиль',
        value: 8.18,
      },
      { icon: <BusIco />, type: Bus, label: 'Автобус', value: 5.07 },
    ],
    turn: [
      { label: 'Лихачевский проезд', value: 12.23 },
      { label: 'Лихачевский проспект - в Москву', value: 0 },
    ],
  },
  {
    intensity: 2943,
    speed: 33.45,
    title: 'Лихачевский проспект - в Москву',
    vehicles: [
      {
        icon: <AutoIco />,
        type: Auto,
        label: 'Легковой автомобиль',
        value: 79.16,
      },
      { icon: <MotoIco />, type: Moto, label: 'Мотоцикл', value: 1.72 },
      {
        icon: <TruckIco />,
        type: Truck,
        label: 'Грузовой автомобиль',
        value: 10.09,
      },
      { icon: <BusIco />, type: Bus, label: 'Автобус', value: 9.03 },
    ],
    turn: [],
  },
  {
    intensity: 362,
    speed: 27.34,
    title: 'Лихачевский проезд',
    vehicles: [
      {
        icon: <AutoIco />,
        type: Auto,
        label: 'Легковой автомобиль',
        value: 90.34,
      },
      { icon: <MotoIco />, type: Moto, label: 'Мотоцикл', value: 1.5 },
      {
        icon: <TruckIco />,
        type: Truck,
        label: 'Грузовой автомобиль',
        value: 1.89,
      },
      { icon: <BusIco />, type: Bus, label: 'Автобус', value: 6.27 },
    ],
    turn: [
      { label: 'Лихачевский проспект - в Москву', value: 46.23 },
      { label: 'Лихачевский проспект - из Москвы', value: 53.77 },
    ],
  },
];

export const CONTROL_MODEL_TABS: TabsProps['items'] = [
  {
    key: '1',
    label: 'Входные параметры',
    children: <ControlModel />,
  },
  {
    key: '2',
    label: 'Моделирование инцидентов',
    children: <NoData isNeedBackground={false} textError="В разработке" />,
  },
  {
    key: '3',
    label: 'Выходные параметры',
    children: <NoData isNeedBackground={false} textError="В разработке" />,
  },
];
