import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import rootStore from '../../../../../stores/rootStore/rootStore';
import FiltersClear from '../DisplayBtns/FiltersClear/FiltersClear';
import FilterBlock from '../FilterBlock/FilterBlock';

import FilterItem from './FilterItem/FilterItem';

import styles from './FiltersPanel.module.scss';

const FiltersPanel = () => {
  const { statusFilteredMonitoring } = rootStore.monitoringPanelStore;

  const isEmptyData = !statusFilteredMonitoring.length;

  const containerStyle = classNames({
    [styles.container]: true,
    [styles.empty]: isEmptyData,
  });

  return (
    <div className={containerStyle}>
      <FilterBlock />
      <>
        <div className={styles.filterBlock}>
          {statusFilteredMonitoring.length ? (
            statusFilteredMonitoring.map(({ status, len }) => (
              <FilterItem key={status} status={status} len={len} />
            ))
          ) : (
            <p>Нет примененных фильтров</p>
          )}
        </div>
        <FiltersClear />
      </>
    </div>
  );
};

export default observer(FiltersPanel);
