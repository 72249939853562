import { VERSION } from '../../../../constants/versions';
import { ILabeling } from '../../../../ts/models/gis/renderer.model';
import { getAppPathHeaders } from '../../../helpers/getAppPathHeaders';
import http from '../../../http';
import tryCatchWrapper from '../../../tryCatchWrapper';

const GIS_LABELING = `${VERSION}/integration/gis/labeling-configuration`;

const REQUEST_PARAMS = {
  isExternalError: true,
};

const labelingApi = {
  getList: (appPath: string, id: number) =>
    tryCatchWrapper(
      async (): Promise<ILabeling[]> => {
        const res = await http.get(`${GIS_LABELING}`, {
          headers: getAppPathHeaders(appPath),
          params: { regionId: id },
        });

        return res?.data;
      },
      {
        ...REQUEST_PARAMS,
        errorMessage: 'Ошибка получения доступных стилей для подписывания',
        ignoreCodes: [423],
      }
    ),
};

export default labelingApi;
