import { COLORS } from '../../../../../constants/colorsConstants';
import {
  DEVICES_INFO,
  TKeysStatus,
} from '../../../../../constants/devicesConstants';
import { TStatusInfoDvc } from '../../../../../stores/helpers/combineWgData';
import {
  TGeneralArr,
  TWidgetsOptionalData,
} from '../../MonitoringCard/models/monitoring.model';

const { SUCCESS, DANGER } = COLORS;

export const getStatusSumAggregate = (widgetsData: TWidgetsOptionalData) =>
  Object.values(widgetsData).reduce((acc, statusObj) => {
    const statusArr = Object.entries(statusObj) as TGeneralArr;

    return statusArr.reduce((accKeys, [key, value]) => {
      accKeys[key] = acc[key] + value;

      return accKeys;
    }, {} as TStatusInfoDvc);
  });

const getSumOfArray = (arr: number[]) =>
  arr.reduce((acc, value) => acc + value, 0);

export const getWidgetData = (
  widgetsData: TWidgetsOptionalData,
  statusType: TKeysStatus
) => {
  const widgetTotal = getStatusSumAggregate(widgetsData);

  const { ratio, total, disabled, system, ...rest } = widgetTotal;

  const statusInfoArr = Object.values(rest);

  const sumOther = getSumOfArray(statusInfoArr);

  const mainValue = widgetTotal[statusType];

  const data = [
    { name: DEVICES_INFO.STATE[statusType].dsc, value: mainValue },
    { name: 'Другие', value: sumOther - mainValue },
  ];

  return {
    total,
    data,
    colors: [SUCCESS, DANGER],
  };
};
