import { RadioValue } from '../../../../TrafficLightDetailed/TlDetectors/model/graph.model';
import { ChartBDKeys, COLORS_BAR } from '../Chart/constants/constants';
import { IDataGraph } from '../PopupDetector.model';

import { TDataDetectorsBars, getAverage } from './getAverage/getAverage';
import { getArrSumInfo } from './getComparableValue';
import { getDatePeriod } from './getDatePeriod';
import { getDetectorsBarsStatus } from './getDetectorsBarsStatus';
import { getGeneral } from './getGeneral';

export interface ICalcData {
  comparableValue: number;
  date: string;
  timeFrom: string;
  interval: string[];
}

const DATE_FORMAT = 'DD MMM';

export const getFormatDataDt = (
  data: TDataDetectorsBars,
  graphTitle: RadioValue
) => {
  const calcData: ICalcData[] = [];

  switch (graphTitle) {
    case ChartBDKeys.SpeedAvg:
      calcData.push(...getAverage(data, DATE_FORMAT));
      break;
    case ChartBDKeys.PassageCount:
      calcData.push(...getGeneral(data, DATE_FORMAT));
      break;
    case ChartBDKeys.Density:
      calcData.push(...getGeneral(data, DATE_FORMAT, false));
      break;
    default:
      calcData.push(...getAverage(data, DATE_FORMAT));
  }

  const notNullablesData = calcData.filter(
    ({ comparableValue }) => comparableValue
  );

  const dataCompValues = notNullablesData.map(
    ({ comparableValue }) => comparableValue
  );

  const { sum } = getArrSumInfo(dataCompValues);

  const averageCalcData = Math.round(sum / notNullablesData.length);

  const colorBars = COLORS_BAR[graphTitle];

  const formattedData = calcData.reduce(
    (acc: IDataGraph[], value: ICalcData) => {
      const status = getDetectorsBarsStatus(
        value.comparableValue,
        averageCalcData,
        colorBars
      );

      acc.push({ ...value, status });

      return acc;
    },
    []
  );

  const datePeriod = getDatePeriod(data);
  const isNullableData = formattedData.every((el) => !el.comparableValue);

  return {
    formattedData,
    datePeriod,
    isNullableData,
  };
};
