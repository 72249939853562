import { ALL_SYSTEM_TITLE } from '../../components/DetailedStatistics/constants/constants';
import { TSystemsInfo } from '../mapDataStore/mapDataStore.model';
import { TActiveSystemMonitoring } from '../monitoringPanelStore/monitoringPanelStore.model';

const getWidgetAdapters = (
  systemsInfo: TSystemsInfo,
  activeSystemMonitoring: TActiveSystemMonitoring
) => {
  if (!activeSystemMonitoring) return [];

  const systemsArray =
    activeSystemMonitoring === ALL_SYSTEM_TITLE
      ? Object.values(systemsInfo)
          .map(({ data }) => data)
          .flat()
      : systemsInfo[activeSystemMonitoring].data;

  return systemsArray.reduce((acc: number[], { eputsAdapter }) => {
    const { scSystemId } = eputsAdapter;

    if (!acc.includes(scSystemId)) acc.push(scSystemId);

    return acc;
  }, []);
};

export default getWidgetAdapters;
