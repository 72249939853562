import eventBus from '../eventBusCore';

import { EventBusEvents } from './enums/eventEnums';
import { BroadcastIncorrectTimeProps } from './models/event.model';

const eventName = EventBusEvents.IncorrectTimeTrigger;

const subscribe = (
  callback: (timeProps: BroadcastIncorrectTimeProps) => void
) => {
  return eventBus.subscribe(eventName, callback);
};

const broadcast = (timeProps: BroadcastIncorrectTimeProps) => {
  eventBus.broadcast(eventName, timeProps);
};

export default {
  subscribe,
  broadcast,
};
