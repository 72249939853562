import { Drawer } from 'antd';
import { FC } from 'react';

import DrawerHeader from '../../AdminSettingUsers/DrawerHeader/DrawerHeader';
import { adminDrawerStyles } from '../../styles/drawerStyles';
import { DIVISIONS, ORG_TITLES } from '../constants/constants';
import Divisions from '../Divisions/Divisions';
import { IDrawerBlock } from '../models/Organizations.model';
import OrgRegForm from '../OrgRegForm/OrgRegForm';

const DrawerBlock: FC<IDrawerBlock> = (props) => {
  const { isOpen, onClose, isOrg, divisionsId, type } = props;

  const fullRegTitle = `Форма ${ORG_TITLES[type]} организации`;

  return (
    <Drawer
      title={
        <DrawerHeader
          onClose={onClose}
          title={isOrg ? fullRegTitle : DIVISIONS}
        />
      }
      open={isOpen}
      closable={false}
      onClose={onClose}
      height="100vh"
      placement={isOrg ? 'right' : 'bottom'}
      width={isOrg ? '500px' : undefined}
      styles={adminDrawerStyles}
    >
      {divisionsId && !isOrg ? (
        <Divisions id={divisionsId} />
      ) : (
        <OrgRegForm {...props} />
      )}
    </Drawer>
  );
};

export default DrawerBlock;
