import { notification } from 'antd';
import { Dayjs } from 'dayjs';

import NOTICE from '../../../../../constants/notificationConstants';
import { IPickerValue } from '../../model/graph.model';
import { getTimeWithTimeZone } from '../TitleBtns/TimeLocalCard/helpers/getTimeWithTimeZone';
import { getTimeZones } from '../TitleBtns/TimeLocalCard/helpers/getTimeZone';

type pickerRangeValues = Omit<IPickerValue, 'timePicker'>;

type TonChangeDateRPF = (value: N<[Dayjs, Dayjs]>) => pickerRangeValues;

export const onChangeDateRP: TonChangeDateRPF = (value) => {
  const timeZone = getTimeZones()?.timeZoneRegion;

  if (!value || !timeZone) return { rangePicker: null, status: undefined };

  const dateTo = value[1];

  const isFutureDate =
    dateTo.diff(getTimeWithTimeZone({ timeZone }).dateFormattedJS, 'hour') > 1;

  if (isFutureDate) {
    notification.error(NOTICE.INCORRECT_DATE);

    return { rangePicker: null, status: 'error' };
  }

  return { rangePicker: value, status: undefined };
};
