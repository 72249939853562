import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import rootStore from '../../../stores/rootStore/rootStore';
import CamerasPointUds from '../Cameras/CamerasPointUds';
import Meteos from '../Meteos/Meteos';
import PhaseCircleNoData from '../PhaseCircleNoData/PhaseCircleNoData';
import LinkedDetectors from '../PhaseCircleOverlay/LinkedDevices/LinkedDetectors';
import PhaseCircleOverlay from '../PhaseCircleOverlay/PhaseCircleOverlay';

import LinkedTLanes from './LinkedTLanes';
import LinkedTLIcon from './LinkedTLIcon';
import { PointUdsProps } from './PointUdsOverlay.model';
import SchemaODD from './SchemaODD';

const PointUdsOverlay: FC<PointUdsProps> = ({ pointUds, tl }) => {
  const { isConstructor } = rootStore.constructorStore;
  const { isMultipleSelect } = rootStore.scriptsControlStore;

  if (!pointUds || isConstructor || isMultipleSelect) return null;

  const { schemaODD, linkedDeviceIds } = pointUds.basicProfileInfo;

  const {
    cameras: cmIds,
    trafficLights: tlId,
    detectors: dtIds,
    meteo: mtIds,
  } = linkedDeviceIds;

  return (
    <ErrorBoundary fallback={<PhaseCircleNoData />} resetKeys={[pointUds, tl]}>
      {tl ? (
        <PhaseCircleOverlay selectedTL={tl} />
      ) : (
        <SchemaODD schemaODD={schemaODD} />
      )}
      {/* Order is important for cameras*/}
      <CamerasPointUds linkedCams={cmIds ?? []} />
      <LinkedTLanes pointUds={pointUds} />
      <LinkedDetectors dtIds={dtIds} isSchemaODD={Boolean(schemaODD)} />
      <LinkedTLIcon tlId={tlId} />
      <Meteos mtIds={mtIds} />
    </ErrorBoundary>
  );
};

export default observer(PointUdsOverlay);
