import { FC } from 'react';

import { IDataList } from '../../../../../../../../../DetailedStatistics/Monitoring/model/monitoring.model';
import { ShowBtnsDevices } from '../../../../../../../../../DetailedStatistics/Monitoring/SystemMonitoring/DisplayBtns/constants/constants';
import CardsDisplay from '../CardsDisplay/CardsDisplay';
import ListDisplay from '../ListDisplay/ListDisplay';

export interface DisplayTypeProps {
  addressLoading: boolean;
  dataList: IDataList[];
}

type TlsDisplayType = Record<ShowBtnsDevices, FC<DisplayTypeProps>>;

const { List, Tiles } = ShowBtnsDevices;

export const TLS_DISPLAY_TYPE: TlsDisplayType = {
  [List]: ListDisplay,
  [Tiles]: CardsDisplay,
};
