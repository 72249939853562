import CircleParamsConstructor from '../../CircleParamsConstructor/CircleParamsConstructor';
import CamerasDetailed from '../CamerasDetailed/CamerasDetailed';
import ConstructorPhaseImages from '../ConstructorPhaseImages/ConstructorPhaseImages';
import DetectorsDetailed from '../DetectorsDetailed/DetectorsDetailed';
import HistoryDetailed from '../HistoryDetailed/HistoryDetailed';
import PointUdsDetailed from '../PointUdsDetailed/PointUdsDetailed';
import TrafficLanesDetailed from '../TrafficLanesDetailed/TrafficLanesDetailed';

export const DETAILED_ITEMS = [
  {
    label: 'Оформление',
    key: 'settings',
    children: <CircleParamsConstructor />,
  },
  {
    label: 'Точка УДС',
    key: 'pointUds',
    children: <PointUdsDetailed />,
  },
  {
    label: 'Изображения',
    key: 'images',
    children: <ConstructorPhaseImages />,
  },
  {
    label: 'Загруженность',
    key: 'lanes',
    children: <TrafficLanesDetailed />,
  },
  {
    label: 'Детекторы',
    key: 'detectors',
    children: <DetectorsDetailed />,
  },
  {
    label: 'Камеры',
    key: 'cameras',
    children: <CamerasDetailed />,
  },
  {
    label: 'История',
    key: 'history',
    children: <HistoryDetailed />,
  },
];
