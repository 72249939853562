import { PHASE_DIR_TYPES } from '../../../../constants/signalsConstants';

const { Y } = PHASE_DIR_TYPES,
  parseDirs = (dirSet: any) =>
    dirSet
      .split('')
      .map((el: any, i: any) => (el === Y ? i + 1 : null))
      .filter((e: any) => e)
      .join(', ');

export const getPhDirSource = (phDir: any[] = []): any =>
  phDir.map((ph: any, i) => ({
    key: i,
    phase: ph?.ph_num,
    timeMin: ph?.min,
    timeMax: ph?.max,
    allowedDirs: parseDirs(ph?.dir),
  }));
