import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';

import { DIR_TYPES_DICTIONARY } from '../../../../constants/devicesConstants';
import { TYPE_OF_TACTS } from '../../../../constants/signalsConstants';
import rootStore from '../../../../stores/rootStore/rootStore';
import { DirectionTypes } from '../../../../ts/enums/tl';
import {
  IDirectionInfo,
  IDirections,
} from '../../../../ts/models/signalPrograms.model';
import Popover from '../../../ui-kit/Popover/Popover';

import PopoverContent, {
  PopoverContentProps,
} from './PopoverContent/PopoverContent';

import styles from './Name.module.scss';

interface IName {
  direction: IDirections;
  len: number;
  isStatic: boolean;
  dirType: DirectionTypes;
}

const { Pedestrian } = DirectionTypes;
const { GREEN_FLASH, YELLOW, YELLOW_AND_RED, RED } = TYPE_OF_TACTS;

const PROM_TACT_TYPES = [GREEN_FLASH, YELLOW, YELLOW_AND_RED];

const Name: FC<IName> = ({ direction, len, isStatic, dirType }) => {
  const { interfaceTipsDelay, secTL } = rootStore.uiStore;

  const { type } = direction.tacts.reduce(
    (acc: IDirectionInfo, tact) => {
      if (acc.len <= secTL && secTL <= acc.len + tact.length) {
        return { type: tact.type, len: len + tact.length };
      }

      return {
        type: acc.type,
        len: acc.len + tact.length,
      };
    },
    { type: RED, len: 0 }
  );

  const isPromTact = useMemo(
    () => PROM_TACT_TYPES.some((tactType) => tactType === type),
    [type]
  );

  const dirInfo = DIR_TYPES_DICTIONARY[dirType];

  const popoverContentProps: PopoverContentProps = {
    dirNum: direction.dirNum,
    isPromTact,
    isStatic,
    title: dirInfo?.title,
    type,
  };

  const popoProps = {
    tipsDelay: interfaceTipsDelay,
    content: <PopoverContent {...popoverContentProps} />,
    placement: 'left',
  } as const;

  const isNotAllowed = type === RED;
  const isWalker = dirType === Pedestrian;

  const dirIco =
    !isStatic && isNotAllowed && isWalker ? dirInfo?.iconOff : dirInfo?.iconOn;

  return (
    <Popover {...popoProps}>
      <div className={styles.directionName}>
        <span
          className={classNames({
            [styles.ico]: true,
            [styles.notAllowed]: !isStatic && isNotAllowed && !isWalker,
            [styles.animate]: !isStatic && isPromTact,
          })}
        >
          {dirIco}
        </span>
        <strong>{direction.viewName}</strong>
      </div>
    </Popover>
  );
};

export default observer(Name);
