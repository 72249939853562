import { FC, CSSProperties } from 'react';

import styles from './VideoDetectorIcon.module.scss';

interface IdProps {
  id: SN;
  style: CSSProperties;
}

const VideoDtId: FC<IdProps> = ({ style, id }) => (
  <div style={style} className={styles.id}>
    {id}
  </div>
);

export default VideoDtId;
