export const signalProgramsExample = `{
  "tl_id": 1219,
  "create": "26.01.2023 06:08:37",
  "header": {
    "ver": "1.0.1",
    "code": "1012",
    "type": "trafficlightDetailes"
  },
  "viewName": "Конфигурация установлена: Исаев Александр Александрович (Isaev) 19.09.2022 04:55:31",
  "device_id": 0,
  "tlSignalPrograms": [
    {
      "addInfo": null,
      "tlTacts": [
        {
          "main": 1,
          "tact": 1,
          "time": 57,
          "color": "#00ff40",
          "phase": 1,
          "direction": [
            1,
            2,
            3
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 2,
          "time": 9,
          "color": "#ce6f4f",
          "phase": 1,
          "direction": [
            1,
            2,
            3
          ],
          "taсtType": 1
        },
        {
          "main": 1,
          "tact": 3,
          "time": 13,
          "color": "#00ff40",
          "phase": 2,
          "direction": [
            1,
            2,
            4
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 4,
          "time": 12,
          "color": "#ce6f4f",
          "phase": 2,
          "direction": [
            1,
            2,
            4
          ],
          "taсtType": 1
        },
        {
          "main": 1,
          "tact": 5,
          "time": 32,
          "color": "#00ff40",
          "phase": 3,
          "direction": [
            5,
            6,
            7,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 6,
          "time": 6,
          "color": "#ce6f4f",
          "phase": 3,
          "direction": [
            5,
            6,
            7,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 1,
          "tact": 7,
          "time": 37,
          "color": "#00ff40",
          "phase": 4,
          "direction": [
            3,
            8,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 8,
          "time": 9,
          "color": "#ce6f4f",
          "phase": 4,
          "direction": [
            3,
            8,
            9
          ],
          "taсtType": 1
        }
      ],
      "execTime": "ежедневно (план №1)",
      "viewItem": "нет",
      "phaseGroupNum": 1,
      "tlSignalProgram": [
        [
          {
            "stop": 66,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 79,
            "type": 1,
            "color": null,
            "start": 66,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 82,
            "type": 2,
            "color": null,
            "start": 79,
            "dbgdir": 1,
            "dbgstep": 2,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 85,
            "type": 3,
            "color": null,
            "start": 82,
            "dbgdir": 1,
            "dbgstep": 3,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 91,
            "type": 5,
            "color": null,
            "start": 85,
            "dbgdir": 1,
            "dbgstep": 4,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 129,
            "type": 5,
            "color": null,
            "start": 91,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 175,
            "type": 5,
            "color": null,
            "start": 129,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 66,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 79,
            "type": 1,
            "color": null,
            "start": 66,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 82,
            "type": 2,
            "color": null,
            "start": 79,
            "dbgdir": 2,
            "dbgstep": 2,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 85,
            "type": 3,
            "color": null,
            "start": 82,
            "dbgdir": 2,
            "dbgstep": 3,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 91,
            "type": 5,
            "color": null,
            "start": 85,
            "dbgdir": 2,
            "dbgstep": 4,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 129,
            "type": 5,
            "color": null,
            "start": 91,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 175,
            "type": 5,
            "color": null,
            "start": 129,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 57,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 60,
            "type": 2,
            "color": null,
            "start": 57,
            "dbgdir": 3,
            "dbgstep": 2,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 63,
            "type": 3,
            "color": null,
            "start": 60,
            "dbgdir": 3,
            "dbgstep": 3,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 66,
            "type": 5,
            "color": null,
            "start": 63,
            "dbgdir": 3,
            "dbgstep": 4,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 91,
            "type": 5,
            "color": null,
            "start": 66,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 129,
            "type": 5,
            "color": null,
            "start": 91,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 175,
            "type": 1,
            "color": null,
            "start": 129,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 1
          }
        ],
        [
          {
            "stop": 66,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 79,
            "type": 1,
            "color": null,
            "start": 66,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 82,
            "type": 2,
            "color": null,
            "start": 79,
            "dbgdir": 4,
            "dbgstep": 2,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 85,
            "type": 3,
            "color": null,
            "start": 82,
            "dbgdir": 4,
            "dbgstep": 3,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 91,
            "type": 5,
            "color": null,
            "start": 85,
            "dbgdir": 4,
            "dbgstep": 4,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 129,
            "type": 5,
            "color": null,
            "start": 91,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 175,
            "type": 5,
            "color": null,
            "start": 129,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 66,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 91,
            "type": 5,
            "color": null,
            "start": 66,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 123,
            "type": 1,
            "color": null,
            "start": 91,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 126,
            "type": 2,
            "color": null,
            "start": 123,
            "dbgdir": 5,
            "dbgstep": 2,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 129,
            "type": 5,
            "color": null,
            "start": 126,
            "dbgdir": 5,
            "dbgstep": 4,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 175,
            "type": 5,
            "color": null,
            "start": 129,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 66,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 91,
            "type": 5,
            "color": null,
            "start": 66,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 123,
            "type": 1,
            "color": null,
            "start": 91,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 126,
            "type": 2,
            "color": null,
            "start": 123,
            "dbgdir": 6,
            "dbgstep": 2,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 129,
            "type": 5,
            "color": null,
            "start": 126,
            "dbgdir": 6,
            "dbgstep": 4,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 175,
            "type": 5,
            "color": null,
            "start": 129,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 66,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 91,
            "type": 5,
            "color": null,
            "start": 66,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 123,
            "type": 1,
            "color": null,
            "start": 91,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 126,
            "type": 2,
            "color": null,
            "start": 123,
            "dbgdir": 7,
            "dbgstep": 2,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 129,
            "type": 5,
            "color": null,
            "start": 126,
            "dbgdir": 7,
            "dbgstep": 4,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 175,
            "type": 5,
            "color": null,
            "start": 129,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 66,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 91,
            "type": 5,
            "color": null,
            "start": 66,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 129,
            "type": 5,
            "color": null,
            "start": 91,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 166,
            "type": 1,
            "color": null,
            "start": 129,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 169,
            "type": 2,
            "color": null,
            "start": 166,
            "dbgdir": 8,
            "dbgstep": 2,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 172,
            "type": 3,
            "color": null,
            "start": 169,
            "dbgdir": 8,
            "dbgstep": 3,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 175,
            "type": 5,
            "color": null,
            "start": 172,
            "dbgdir": 8,
            "dbgstep": 4,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          }
        ],
        [
          {
            "stop": 66,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 91,
            "type": 5,
            "color": null,
            "start": 66,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 129,
            "type": 1,
            "color": null,
            "start": 91,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 166,
            "type": 1,
            "color": null,
            "start": 129,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 169,
            "type": 2,
            "color": null,
            "start": 166,
            "dbgdir": 9,
            "dbgstep": 2,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 172,
            "type": 3,
            "color": null,
            "start": 169,
            "dbgdir": 9,
            "dbgstep": 3,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 175,
            "type": 5,
            "color": null,
            "start": 172,
            "dbgdir": 9,
            "dbgstep": 4,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          }
        ]
      ],
      "tlSignalProgram2": [
        {
          "data": [
            {
              "stop": 66,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 79,
              "type": 1,
              "color": null,
              "start": 66,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 82,
              "type": 2,
              "color": null,
              "start": 79,
              "dbgdir": 1,
              "dbgstep": 2,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 85,
              "type": 3,
              "color": null,
              "start": 82,
              "dbgdir": 1,
              "dbgstep": 3,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 91,
              "type": 5,
              "color": null,
              "start": 85,
              "dbgdir": 1,
              "dbgstep": 4,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 129,
              "type": 5,
              "color": null,
              "start": 91,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 175,
              "type": 5,
              "color": null,
              "start": 129,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 1,
          "viewName": "H.1",
          "phaseGroupNum": 1
        },
        {
          "data": [
            {
              "stop": 66,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 79,
              "type": 1,
              "color": null,
              "start": 66,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 82,
              "type": 2,
              "color": null,
              "start": 79,
              "dbgdir": 2,
              "dbgstep": 2,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 85,
              "type": 3,
              "color": null,
              "start": 82,
              "dbgdir": 2,
              "dbgstep": 3,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 91,
              "type": 5,
              "color": null,
              "start": 85,
              "dbgdir": 2,
              "dbgstep": 4,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 129,
              "type": 5,
              "color": null,
              "start": 91,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 175,
              "type": 5,
              "color": null,
              "start": 129,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 2,
          "viewName": "H.2",
          "phaseGroupNum": 1
        },
        {
          "data": [
            {
              "stop": 57,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 60,
              "type": 2,
              "color": null,
              "start": 57,
              "dbgdir": 3,
              "dbgstep": 2,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 63,
              "type": 3,
              "color": null,
              "start": 60,
              "dbgdir": 3,
              "dbgstep": 3,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 66,
              "type": 5,
              "color": null,
              "start": 63,
              "dbgdir": 3,
              "dbgstep": 4,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 91,
              "type": 5,
              "color": null,
              "start": 66,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 129,
              "type": 5,
              "color": null,
              "start": 91,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 175,
              "type": 1,
              "color": null,
              "start": 129,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 1
            }
          ],
          "dirNum": 3,
          "viewName": "H.3",
          "phaseGroupNum": 1
        },
        {
          "data": [
            {
              "stop": 66,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 79,
              "type": 1,
              "color": null,
              "start": 66,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 82,
              "type": 2,
              "color": null,
              "start": 79,
              "dbgdir": 4,
              "dbgstep": 2,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 85,
              "type": 3,
              "color": null,
              "start": 82,
              "dbgdir": 4,
              "dbgstep": 3,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 91,
              "type": 5,
              "color": null,
              "start": 85,
              "dbgdir": 4,
              "dbgstep": 4,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 129,
              "type": 5,
              "color": null,
              "start": 91,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 175,
              "type": 5,
              "color": null,
              "start": 129,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 4,
          "viewName": "H.4",
          "phaseGroupNum": 1
        },
        {
          "data": [
            {
              "stop": 66,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 91,
              "type": 5,
              "color": null,
              "start": 66,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 123,
              "type": 1,
              "color": null,
              "start": 91,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 126,
              "type": 2,
              "color": null,
              "start": 123,
              "dbgdir": 5,
              "dbgstep": 2,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 129,
              "type": 5,
              "color": null,
              "start": 126,
              "dbgdir": 5,
              "dbgstep": 4,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 175,
              "type": 5,
              "color": null,
              "start": 129,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 5,
          "viewName": "H.5",
          "phaseGroupNum": 1
        },
        {
          "data": [
            {
              "stop": 66,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 91,
              "type": 5,
              "color": null,
              "start": 66,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 123,
              "type": 1,
              "color": null,
              "start": 91,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 126,
              "type": 2,
              "color": null,
              "start": 123,
              "dbgdir": 6,
              "dbgstep": 2,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 129,
              "type": 5,
              "color": null,
              "start": 126,
              "dbgdir": 6,
              "dbgstep": 4,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 175,
              "type": 5,
              "color": null,
              "start": 129,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 6,
          "viewName": "H.6",
          "phaseGroupNum": 1
        },
        {
          "data": [
            {
              "stop": 66,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 91,
              "type": 5,
              "color": null,
              "start": 66,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 123,
              "type": 1,
              "color": null,
              "start": 91,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 126,
              "type": 2,
              "color": null,
              "start": 123,
              "dbgdir": 7,
              "dbgstep": 2,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 129,
              "type": 5,
              "color": null,
              "start": 126,
              "dbgdir": 7,
              "dbgstep": 4,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 175,
              "type": 5,
              "color": null,
              "start": 129,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 7,
          "viewName": "H.7",
          "phaseGroupNum": 1
        },
        {
          "data": [
            {
              "stop": 66,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 91,
              "type": 5,
              "color": null,
              "start": 66,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 129,
              "type": 5,
              "color": null,
              "start": 91,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 166,
              "type": 1,
              "color": null,
              "start": 129,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 169,
              "type": 2,
              "color": null,
              "start": 166,
              "dbgdir": 8,
              "dbgstep": 2,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 172,
              "type": 3,
              "color": null,
              "start": 169,
              "dbgdir": 8,
              "dbgstep": 3,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 175,
              "type": 5,
              "color": null,
              "start": 172,
              "dbgdir": 8,
              "dbgstep": 4,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            }
          ],
          "dirNum": 8,
          "viewName": "H.8",
          "phaseGroupNum": 1
        },
        {
          "data": [
            {
              "stop": 66,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 91,
              "type": 5,
              "color": null,
              "start": 66,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 129,
              "type": 1,
              "color": null,
              "start": 91,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 166,
              "type": 1,
              "color": null,
              "start": 129,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 169,
              "type": 2,
              "color": null,
              "start": 166,
              "dbgdir": 9,
              "dbgstep": 2,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 172,
              "type": 3,
              "color": null,
              "start": 169,
              "dbgdir": 9,
              "dbgstep": 3,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 175,
              "type": 5,
              "color": null,
              "start": 172,
              "dbgdir": 9,
              "dbgstep": 4,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            }
          ],
          "dirNum": 9,
          "viewName": "H.9",
          "phaseGroupNum": 1
        }
      ]
    },
    {
      "addInfo": null,
      "tlTacts": [
        {
          "main": 1,
          "tact": 1,
          "time": 40,
          "color": "#00ff40",
          "phase": 1,
          "direction": [
            1,
            2,
            3
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 2,
          "time": 9,
          "color": "#ce6f4f",
          "phase": 1,
          "direction": [
            1,
            2,
            3
          ],
          "taсtType": 1
        },
        {
          "main": 1,
          "tact": 3,
          "time": 13,
          "color": "#00ff40",
          "phase": 2,
          "direction": [
            1,
            2,
            4
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 4,
          "time": 12,
          "color": "#ce6f4f",
          "phase": 2,
          "direction": [
            1,
            2,
            4
          ],
          "taсtType": 1
        },
        {
          "main": 1,
          "tact": 5,
          "time": 28,
          "color": "#00ff40",
          "phase": 3,
          "direction": [
            5,
            6,
            7,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 6,
          "time": 6,
          "color": "#ce6f4f",
          "phase": 3,
          "direction": [
            5,
            6,
            7,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 1,
          "tact": 7,
          "time": 37,
          "color": "#00ff40",
          "phase": 4,
          "direction": [
            3,
            8,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 8,
          "time": 9,
          "color": "#ce6f4f",
          "phase": 4,
          "direction": [
            3,
            8,
            9
          ],
          "taсtType": 1
        }
      ],
      "execTime": "ежедневно (план №2)",
      "viewItem": "нет",
      "phaseGroupNum": 2,
      "tlSignalProgram": [
        [
          {
            "stop": 49,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 62,
            "type": 1,
            "color": null,
            "start": 49,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 65,
            "type": 2,
            "color": null,
            "start": 62,
            "dbgdir": 1,
            "dbgstep": 2,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 68,
            "type": 3,
            "color": null,
            "start": 65,
            "dbgdir": 1,
            "dbgstep": 3,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 74,
            "type": 5,
            "color": null,
            "start": 68,
            "dbgdir": 1,
            "dbgstep": 4,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 108,
            "type": 5,
            "color": null,
            "start": 74,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 154,
            "type": 5,
            "color": null,
            "start": 108,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 49,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 62,
            "type": 1,
            "color": null,
            "start": 49,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 65,
            "type": 2,
            "color": null,
            "start": 62,
            "dbgdir": 2,
            "dbgstep": 2,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 68,
            "type": 3,
            "color": null,
            "start": 65,
            "dbgdir": 2,
            "dbgstep": 3,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 74,
            "type": 5,
            "color": null,
            "start": 68,
            "dbgdir": 2,
            "dbgstep": 4,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 108,
            "type": 5,
            "color": null,
            "start": 74,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 154,
            "type": 5,
            "color": null,
            "start": 108,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 40,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 43,
            "type": 2,
            "color": null,
            "start": 40,
            "dbgdir": 3,
            "dbgstep": 2,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 46,
            "type": 3,
            "color": null,
            "start": 43,
            "dbgdir": 3,
            "dbgstep": 3,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 49,
            "type": 5,
            "color": null,
            "start": 46,
            "dbgdir": 3,
            "dbgstep": 4,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 74,
            "type": 5,
            "color": null,
            "start": 49,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 108,
            "type": 5,
            "color": null,
            "start": 74,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 154,
            "type": 1,
            "color": null,
            "start": 108,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 1
          }
        ],
        [
          {
            "stop": 49,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 62,
            "type": 1,
            "color": null,
            "start": 49,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 65,
            "type": 2,
            "color": null,
            "start": 62,
            "dbgdir": 4,
            "dbgstep": 2,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 68,
            "type": 3,
            "color": null,
            "start": 65,
            "dbgdir": 4,
            "dbgstep": 3,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 74,
            "type": 5,
            "color": null,
            "start": 68,
            "dbgdir": 4,
            "dbgstep": 4,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 108,
            "type": 5,
            "color": null,
            "start": 74,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 154,
            "type": 5,
            "color": null,
            "start": 108,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 49,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 74,
            "type": 5,
            "color": null,
            "start": 49,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 102,
            "type": 1,
            "color": null,
            "start": 74,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 105,
            "type": 2,
            "color": null,
            "start": 102,
            "dbgdir": 5,
            "dbgstep": 2,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 108,
            "type": 5,
            "color": null,
            "start": 105,
            "dbgdir": 5,
            "dbgstep": 4,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 154,
            "type": 5,
            "color": null,
            "start": 108,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 49,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 74,
            "type": 5,
            "color": null,
            "start": 49,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 102,
            "type": 1,
            "color": null,
            "start": 74,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 105,
            "type": 2,
            "color": null,
            "start": 102,
            "dbgdir": 6,
            "dbgstep": 2,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 108,
            "type": 5,
            "color": null,
            "start": 105,
            "dbgdir": 6,
            "dbgstep": 4,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 154,
            "type": 5,
            "color": null,
            "start": 108,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 49,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 74,
            "type": 5,
            "color": null,
            "start": 49,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 102,
            "type": 1,
            "color": null,
            "start": 74,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 105,
            "type": 2,
            "color": null,
            "start": 102,
            "dbgdir": 7,
            "dbgstep": 2,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 108,
            "type": 5,
            "color": null,
            "start": 105,
            "dbgdir": 7,
            "dbgstep": 4,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 154,
            "type": 5,
            "color": null,
            "start": 108,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 49,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 74,
            "type": 5,
            "color": null,
            "start": 49,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 108,
            "type": 5,
            "color": null,
            "start": 74,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 145,
            "type": 1,
            "color": null,
            "start": 108,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 148,
            "type": 2,
            "color": null,
            "start": 145,
            "dbgdir": 8,
            "dbgstep": 2,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 151,
            "type": 3,
            "color": null,
            "start": 148,
            "dbgdir": 8,
            "dbgstep": 3,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 154,
            "type": 5,
            "color": null,
            "start": 151,
            "dbgdir": 8,
            "dbgstep": 4,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          }
        ],
        [
          {
            "stop": 49,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 74,
            "type": 5,
            "color": null,
            "start": 49,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 108,
            "type": 1,
            "color": null,
            "start": 74,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 145,
            "type": 1,
            "color": null,
            "start": 108,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 148,
            "type": 2,
            "color": null,
            "start": 145,
            "dbgdir": 9,
            "dbgstep": 2,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 151,
            "type": 3,
            "color": null,
            "start": 148,
            "dbgdir": 9,
            "dbgstep": 3,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 154,
            "type": 5,
            "color": null,
            "start": 151,
            "dbgdir": 9,
            "dbgstep": 4,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          }
        ]
      ],
      "tlSignalProgram2": [
        {
          "data": [
            {
              "stop": 49,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 62,
              "type": 1,
              "color": null,
              "start": 49,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 65,
              "type": 2,
              "color": null,
              "start": 62,
              "dbgdir": 1,
              "dbgstep": 2,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 68,
              "type": 3,
              "color": null,
              "start": 65,
              "dbgdir": 1,
              "dbgstep": 3,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 74,
              "type": 5,
              "color": null,
              "start": 68,
              "dbgdir": 1,
              "dbgstep": 4,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 108,
              "type": 5,
              "color": null,
              "start": 74,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 154,
              "type": 5,
              "color": null,
              "start": 108,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 1,
          "viewName": "H.1",
          "phaseGroupNum": 2
        },
        {
          "data": [
            {
              "stop": 49,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 62,
              "type": 1,
              "color": null,
              "start": 49,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 65,
              "type": 2,
              "color": null,
              "start": 62,
              "dbgdir": 2,
              "dbgstep": 2,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 68,
              "type": 3,
              "color": null,
              "start": 65,
              "dbgdir": 2,
              "dbgstep": 3,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 74,
              "type": 5,
              "color": null,
              "start": 68,
              "dbgdir": 2,
              "dbgstep": 4,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 108,
              "type": 5,
              "color": null,
              "start": 74,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 154,
              "type": 5,
              "color": null,
              "start": 108,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 2,
          "viewName": "H.2",
          "phaseGroupNum": 2
        },
        {
          "data": [
            {
              "stop": 40,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 43,
              "type": 2,
              "color": null,
              "start": 40,
              "dbgdir": 3,
              "dbgstep": 2,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 46,
              "type": 3,
              "color": null,
              "start": 43,
              "dbgdir": 3,
              "dbgstep": 3,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 49,
              "type": 5,
              "color": null,
              "start": 46,
              "dbgdir": 3,
              "dbgstep": 4,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 74,
              "type": 5,
              "color": null,
              "start": 49,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 108,
              "type": 5,
              "color": null,
              "start": 74,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 154,
              "type": 1,
              "color": null,
              "start": 108,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 1
            }
          ],
          "dirNum": 3,
          "viewName": "H.3",
          "phaseGroupNum": 2
        },
        {
          "data": [
            {
              "stop": 49,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 62,
              "type": 1,
              "color": null,
              "start": 49,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 65,
              "type": 2,
              "color": null,
              "start": 62,
              "dbgdir": 4,
              "dbgstep": 2,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 68,
              "type": 3,
              "color": null,
              "start": 65,
              "dbgdir": 4,
              "dbgstep": 3,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 74,
              "type": 5,
              "color": null,
              "start": 68,
              "dbgdir": 4,
              "dbgstep": 4,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 108,
              "type": 5,
              "color": null,
              "start": 74,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 154,
              "type": 5,
              "color": null,
              "start": 108,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 4,
          "viewName": "H.4",
          "phaseGroupNum": 2
        },
        {
          "data": [
            {
              "stop": 49,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 74,
              "type": 5,
              "color": null,
              "start": 49,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 102,
              "type": 1,
              "color": null,
              "start": 74,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 105,
              "type": 2,
              "color": null,
              "start": 102,
              "dbgdir": 5,
              "dbgstep": 2,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 108,
              "type": 5,
              "color": null,
              "start": 105,
              "dbgdir": 5,
              "dbgstep": 4,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 154,
              "type": 5,
              "color": null,
              "start": 108,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 5,
          "viewName": "H.5",
          "phaseGroupNum": 2
        },
        {
          "data": [
            {
              "stop": 49,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 74,
              "type": 5,
              "color": null,
              "start": 49,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 102,
              "type": 1,
              "color": null,
              "start": 74,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 105,
              "type": 2,
              "color": null,
              "start": 102,
              "dbgdir": 6,
              "dbgstep": 2,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 108,
              "type": 5,
              "color": null,
              "start": 105,
              "dbgdir": 6,
              "dbgstep": 4,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 154,
              "type": 5,
              "color": null,
              "start": 108,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 6,
          "viewName": "H.6",
          "phaseGroupNum": 2
        },
        {
          "data": [
            {
              "stop": 49,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 74,
              "type": 5,
              "color": null,
              "start": 49,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 102,
              "type": 1,
              "color": null,
              "start": 74,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 105,
              "type": 2,
              "color": null,
              "start": 102,
              "dbgdir": 7,
              "dbgstep": 2,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 108,
              "type": 5,
              "color": null,
              "start": 105,
              "dbgdir": 7,
              "dbgstep": 4,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 154,
              "type": 5,
              "color": null,
              "start": 108,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 7,
          "viewName": "H.7",
          "phaseGroupNum": 2
        },
        {
          "data": [
            {
              "stop": 49,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 74,
              "type": 5,
              "color": null,
              "start": 49,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 108,
              "type": 5,
              "color": null,
              "start": 74,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 145,
              "type": 1,
              "color": null,
              "start": 108,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 148,
              "type": 2,
              "color": null,
              "start": 145,
              "dbgdir": 8,
              "dbgstep": 2,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 151,
              "type": 3,
              "color": null,
              "start": 148,
              "dbgdir": 8,
              "dbgstep": 3,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 154,
              "type": 5,
              "color": null,
              "start": 151,
              "dbgdir": 8,
              "dbgstep": 4,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            }
          ],
          "dirNum": 8,
          "viewName": "H.8",
          "phaseGroupNum": 2
        },
        {
          "data": [
            {
              "stop": 49,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 74,
              "type": 5,
              "color": null,
              "start": 49,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 108,
              "type": 1,
              "color": null,
              "start": 74,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 145,
              "type": 1,
              "color": null,
              "start": 108,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 148,
              "type": 2,
              "color": null,
              "start": 145,
              "dbgdir": 9,
              "dbgstep": 2,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 151,
              "type": 3,
              "color": null,
              "start": 148,
              "dbgdir": 9,
              "dbgstep": 3,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 154,
              "type": 5,
              "color": null,
              "start": 151,
              "dbgdir": 9,
              "dbgstep": 4,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            }
          ],
          "dirNum": 9,
          "viewName": "H.9",
          "phaseGroupNum": 2
        }
      ]
    },
    {
      "addInfo": null,
      "tlTacts": [
        {
          "main": 1,
          "tact": 1,
          "time": 42,
          "color": "#00ff40",
          "phase": 1,
          "direction": [
            1,
            2,
            3
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 2,
          "time": 9,
          "color": "#ce6f4f",
          "phase": 1,
          "direction": [
            1,
            2,
            3
          ],
          "taсtType": 1
        },
        {
          "main": 1,
          "tact": 3,
          "time": 13,
          "color": "#00ff40",
          "phase": 2,
          "direction": [
            1,
            2,
            4
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 4,
          "time": 12,
          "color": "#ce6f4f",
          "phase": 2,
          "direction": [
            1,
            2,
            4
          ],
          "taсtType": 1
        },
        {
          "main": 1,
          "tact": 5,
          "time": 24,
          "color": "#00ff40",
          "phase": 3,
          "direction": [
            5,
            6,
            7,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 6,
          "time": 6,
          "color": "#ce6f4f",
          "phase": 3,
          "direction": [
            5,
            6,
            7,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 1,
          "tact": 7,
          "time": 44,
          "color": "#00ff40",
          "phase": 4,
          "direction": [
            3,
            8,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 8,
          "time": 9,
          "color": "#ce6f4f",
          "phase": 4,
          "direction": [
            3,
            8,
            9
          ],
          "taсtType": 1
        }
      ],
      "execTime": "ежедневно (план №3)",
      "viewItem": "нет",
      "phaseGroupNum": 3,
      "tlSignalProgram": [
        [
          {
            "stop": 51,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 64,
            "type": 1,
            "color": null,
            "start": 51,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 67,
            "type": 2,
            "color": null,
            "start": 64,
            "dbgdir": 1,
            "dbgstep": 2,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 70,
            "type": 3,
            "color": null,
            "start": 67,
            "dbgdir": 1,
            "dbgstep": 3,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 76,
            "type": 5,
            "color": null,
            "start": 70,
            "dbgdir": 1,
            "dbgstep": 4,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 106,
            "type": 5,
            "color": null,
            "start": 76,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 159,
            "type": 5,
            "color": null,
            "start": 106,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 51,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 64,
            "type": 1,
            "color": null,
            "start": 51,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 67,
            "type": 2,
            "color": null,
            "start": 64,
            "dbgdir": 2,
            "dbgstep": 2,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 70,
            "type": 3,
            "color": null,
            "start": 67,
            "dbgdir": 2,
            "dbgstep": 3,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 76,
            "type": 5,
            "color": null,
            "start": 70,
            "dbgdir": 2,
            "dbgstep": 4,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 106,
            "type": 5,
            "color": null,
            "start": 76,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 159,
            "type": 5,
            "color": null,
            "start": 106,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 42,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 45,
            "type": 2,
            "color": null,
            "start": 42,
            "dbgdir": 3,
            "dbgstep": 2,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 48,
            "type": 3,
            "color": null,
            "start": 45,
            "dbgdir": 3,
            "dbgstep": 3,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 51,
            "type": 5,
            "color": null,
            "start": 48,
            "dbgdir": 3,
            "dbgstep": 4,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 76,
            "type": 5,
            "color": null,
            "start": 51,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 106,
            "type": 5,
            "color": null,
            "start": 76,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 159,
            "type": 1,
            "color": null,
            "start": 106,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 1
          }
        ],
        [
          {
            "stop": 51,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 64,
            "type": 1,
            "color": null,
            "start": 51,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 67,
            "type": 2,
            "color": null,
            "start": 64,
            "dbgdir": 4,
            "dbgstep": 2,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 70,
            "type": 3,
            "color": null,
            "start": 67,
            "dbgdir": 4,
            "dbgstep": 3,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 76,
            "type": 5,
            "color": null,
            "start": 70,
            "dbgdir": 4,
            "dbgstep": 4,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 106,
            "type": 5,
            "color": null,
            "start": 76,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 159,
            "type": 5,
            "color": null,
            "start": 106,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 51,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 76,
            "type": 5,
            "color": null,
            "start": 51,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 100,
            "type": 1,
            "color": null,
            "start": 76,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 103,
            "type": 2,
            "color": null,
            "start": 100,
            "dbgdir": 5,
            "dbgstep": 2,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 106,
            "type": 5,
            "color": null,
            "start": 103,
            "dbgdir": 5,
            "dbgstep": 4,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 159,
            "type": 5,
            "color": null,
            "start": 106,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 51,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 76,
            "type": 5,
            "color": null,
            "start": 51,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 100,
            "type": 1,
            "color": null,
            "start": 76,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 103,
            "type": 2,
            "color": null,
            "start": 100,
            "dbgdir": 6,
            "dbgstep": 2,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 106,
            "type": 5,
            "color": null,
            "start": 103,
            "dbgdir": 6,
            "dbgstep": 4,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 159,
            "type": 5,
            "color": null,
            "start": 106,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 51,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 76,
            "type": 5,
            "color": null,
            "start": 51,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 100,
            "type": 1,
            "color": null,
            "start": 76,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 103,
            "type": 2,
            "color": null,
            "start": 100,
            "dbgdir": 7,
            "dbgstep": 2,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 106,
            "type": 5,
            "color": null,
            "start": 103,
            "dbgdir": 7,
            "dbgstep": 4,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 159,
            "type": 5,
            "color": null,
            "start": 106,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 51,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 76,
            "type": 5,
            "color": null,
            "start": 51,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 106,
            "type": 5,
            "color": null,
            "start": 76,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 150,
            "type": 1,
            "color": null,
            "start": 106,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 153,
            "type": 2,
            "color": null,
            "start": 150,
            "dbgdir": 8,
            "dbgstep": 2,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 156,
            "type": 3,
            "color": null,
            "start": 153,
            "dbgdir": 8,
            "dbgstep": 3,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 159,
            "type": 5,
            "color": null,
            "start": 156,
            "dbgdir": 8,
            "dbgstep": 4,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          }
        ],
        [
          {
            "stop": 51,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 76,
            "type": 5,
            "color": null,
            "start": 51,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 106,
            "type": 1,
            "color": null,
            "start": 76,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 150,
            "type": 1,
            "color": null,
            "start": 106,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 153,
            "type": 2,
            "color": null,
            "start": 150,
            "dbgdir": 9,
            "dbgstep": 2,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 156,
            "type": 3,
            "color": null,
            "start": 153,
            "dbgdir": 9,
            "dbgstep": 3,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 159,
            "type": 5,
            "color": null,
            "start": 156,
            "dbgdir": 9,
            "dbgstep": 4,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          }
        ]
      ],
      "tlSignalProgram2": [
        {
          "data": [
            {
              "stop": 51,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 64,
              "type": 1,
              "color": null,
              "start": 51,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 67,
              "type": 2,
              "color": null,
              "start": 64,
              "dbgdir": 1,
              "dbgstep": 2,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 70,
              "type": 3,
              "color": null,
              "start": 67,
              "dbgdir": 1,
              "dbgstep": 3,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 76,
              "type": 5,
              "color": null,
              "start": 70,
              "dbgdir": 1,
              "dbgstep": 4,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 106,
              "type": 5,
              "color": null,
              "start": 76,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 159,
              "type": 5,
              "color": null,
              "start": 106,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 1,
          "viewName": "H.1",
          "phaseGroupNum": 3
        },
        {
          "data": [
            {
              "stop": 51,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 64,
              "type": 1,
              "color": null,
              "start": 51,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 67,
              "type": 2,
              "color": null,
              "start": 64,
              "dbgdir": 2,
              "dbgstep": 2,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 70,
              "type": 3,
              "color": null,
              "start": 67,
              "dbgdir": 2,
              "dbgstep": 3,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 76,
              "type": 5,
              "color": null,
              "start": 70,
              "dbgdir": 2,
              "dbgstep": 4,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 106,
              "type": 5,
              "color": null,
              "start": 76,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 159,
              "type": 5,
              "color": null,
              "start": 106,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 2,
          "viewName": "H.2",
          "phaseGroupNum": 3
        },
        {
          "data": [
            {
              "stop": 42,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 45,
              "type": 2,
              "color": null,
              "start": 42,
              "dbgdir": 3,
              "dbgstep": 2,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 48,
              "type": 3,
              "color": null,
              "start": 45,
              "dbgdir": 3,
              "dbgstep": 3,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 51,
              "type": 5,
              "color": null,
              "start": 48,
              "dbgdir": 3,
              "dbgstep": 4,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 76,
              "type": 5,
              "color": null,
              "start": 51,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 106,
              "type": 5,
              "color": null,
              "start": 76,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 159,
              "type": 1,
              "color": null,
              "start": 106,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 1
            }
          ],
          "dirNum": 3,
          "viewName": "H.3",
          "phaseGroupNum": 3
        },
        {
          "data": [
            {
              "stop": 51,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 64,
              "type": 1,
              "color": null,
              "start": 51,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 67,
              "type": 2,
              "color": null,
              "start": 64,
              "dbgdir": 4,
              "dbgstep": 2,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 70,
              "type": 3,
              "color": null,
              "start": 67,
              "dbgdir": 4,
              "dbgstep": 3,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 76,
              "type": 5,
              "color": null,
              "start": 70,
              "dbgdir": 4,
              "dbgstep": 4,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 106,
              "type": 5,
              "color": null,
              "start": 76,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 159,
              "type": 5,
              "color": null,
              "start": 106,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 4,
          "viewName": "H.4",
          "phaseGroupNum": 3
        },
        {
          "data": [
            {
              "stop": 51,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 76,
              "type": 5,
              "color": null,
              "start": 51,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 100,
              "type": 1,
              "color": null,
              "start": 76,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 103,
              "type": 2,
              "color": null,
              "start": 100,
              "dbgdir": 5,
              "dbgstep": 2,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 106,
              "type": 5,
              "color": null,
              "start": 103,
              "dbgdir": 5,
              "dbgstep": 4,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 159,
              "type": 5,
              "color": null,
              "start": 106,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 5,
          "viewName": "H.5",
          "phaseGroupNum": 3
        },
        {
          "data": [
            {
              "stop": 51,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 76,
              "type": 5,
              "color": null,
              "start": 51,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 100,
              "type": 1,
              "color": null,
              "start": 76,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 103,
              "type": 2,
              "color": null,
              "start": 100,
              "dbgdir": 6,
              "dbgstep": 2,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 106,
              "type": 5,
              "color": null,
              "start": 103,
              "dbgdir": 6,
              "dbgstep": 4,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 159,
              "type": 5,
              "color": null,
              "start": 106,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 6,
          "viewName": "H.6",
          "phaseGroupNum": 3
        },
        {
          "data": [
            {
              "stop": 51,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 76,
              "type": 5,
              "color": null,
              "start": 51,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 100,
              "type": 1,
              "color": null,
              "start": 76,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 103,
              "type": 2,
              "color": null,
              "start": 100,
              "dbgdir": 7,
              "dbgstep": 2,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 106,
              "type": 5,
              "color": null,
              "start": 103,
              "dbgdir": 7,
              "dbgstep": 4,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 159,
              "type": 5,
              "color": null,
              "start": 106,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 7,
          "viewName": "H.7",
          "phaseGroupNum": 3
        },
        {
          "data": [
            {
              "stop": 51,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 76,
              "type": 5,
              "color": null,
              "start": 51,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 106,
              "type": 5,
              "color": null,
              "start": 76,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 150,
              "type": 1,
              "color": null,
              "start": 106,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 153,
              "type": 2,
              "color": null,
              "start": 150,
              "dbgdir": 8,
              "dbgstep": 2,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 156,
              "type": 3,
              "color": null,
              "start": 153,
              "dbgdir": 8,
              "dbgstep": 3,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 159,
              "type": 5,
              "color": null,
              "start": 156,
              "dbgdir": 8,
              "dbgstep": 4,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            }
          ],
          "dirNum": 8,
          "viewName": "H.8",
          "phaseGroupNum": 3
        },
        {
          "data": [
            {
              "stop": 51,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 76,
              "type": 5,
              "color": null,
              "start": 51,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 106,
              "type": 1,
              "color": null,
              "start": 76,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 150,
              "type": 1,
              "color": null,
              "start": 106,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 153,
              "type": 2,
              "color": null,
              "start": 150,
              "dbgdir": 9,
              "dbgstep": 2,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 156,
              "type": 3,
              "color": null,
              "start": 153,
              "dbgdir": 9,
              "dbgstep": 3,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 159,
              "type": 5,
              "color": null,
              "start": 156,
              "dbgdir": 9,
              "dbgstep": 4,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            }
          ],
          "dirNum": 9,
          "viewName": "H.9",
          "phaseGroupNum": 3
        }
      ]
    },
    {
      "addInfo": null,
      "tlTacts": [
        {
          "main": 1,
          "tact": 1,
          "time": 38,
          "color": "#00ff40",
          "phase": 1,
          "direction": [
            1,
            2,
            3
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 2,
          "time": 9,
          "color": "#ce6f4f",
          "phase": 1,
          "direction": [
            1,
            2,
            3
          ],
          "taсtType": 1
        },
        {
          "main": 1,
          "tact": 3,
          "time": 13,
          "color": "#00ff40",
          "phase": 2,
          "direction": [
            1,
            2,
            4
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 4,
          "time": 12,
          "color": "#ce6f4f",
          "phase": 2,
          "direction": [
            1,
            2,
            4
          ],
          "taсtType": 1
        },
        {
          "main": 1,
          "tact": 5,
          "time": 25,
          "color": "#00ff40",
          "phase": 3,
          "direction": [
            5,
            6,
            7,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 6,
          "time": 6,
          "color": "#ce6f4f",
          "phase": 3,
          "direction": [
            5,
            6,
            7,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 1,
          "tact": 7,
          "time": 28,
          "color": "#00ff40",
          "phase": 4,
          "direction": [
            3,
            8,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 8,
          "time": 9,
          "color": "#ce6f4f",
          "phase": 4,
          "direction": [
            3,
            8,
            9
          ],
          "taсtType": 1
        }
      ],
      "execTime": "ежедневно (план №4)",
      "viewItem": "нет",
      "phaseGroupNum": 4,
      "tlSignalProgram": [
        [
          {
            "stop": 47,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 60,
            "type": 1,
            "color": null,
            "start": 47,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 63,
            "type": 2,
            "color": null,
            "start": 60,
            "dbgdir": 1,
            "dbgstep": 2,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 66,
            "type": 3,
            "color": null,
            "start": 63,
            "dbgdir": 1,
            "dbgstep": 3,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 72,
            "type": 5,
            "color": null,
            "start": 66,
            "dbgdir": 1,
            "dbgstep": 4,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 103,
            "type": 5,
            "color": null,
            "start": 72,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 140,
            "type": 5,
            "color": null,
            "start": 103,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 47,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 60,
            "type": 1,
            "color": null,
            "start": 47,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 63,
            "type": 2,
            "color": null,
            "start": 60,
            "dbgdir": 2,
            "dbgstep": 2,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 66,
            "type": 3,
            "color": null,
            "start": 63,
            "dbgdir": 2,
            "dbgstep": 3,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 72,
            "type": 5,
            "color": null,
            "start": 66,
            "dbgdir": 2,
            "dbgstep": 4,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 103,
            "type": 5,
            "color": null,
            "start": 72,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 140,
            "type": 5,
            "color": null,
            "start": 103,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 38,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 41,
            "type": 2,
            "color": null,
            "start": 38,
            "dbgdir": 3,
            "dbgstep": 2,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 44,
            "type": 3,
            "color": null,
            "start": 41,
            "dbgdir": 3,
            "dbgstep": 3,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 47,
            "type": 5,
            "color": null,
            "start": 44,
            "dbgdir": 3,
            "dbgstep": 4,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 72,
            "type": 5,
            "color": null,
            "start": 47,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 103,
            "type": 5,
            "color": null,
            "start": 72,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 140,
            "type": 1,
            "color": null,
            "start": 103,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 1
          }
        ],
        [
          {
            "stop": 47,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 60,
            "type": 1,
            "color": null,
            "start": 47,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 63,
            "type": 2,
            "color": null,
            "start": 60,
            "dbgdir": 4,
            "dbgstep": 2,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 66,
            "type": 3,
            "color": null,
            "start": 63,
            "dbgdir": 4,
            "dbgstep": 3,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 72,
            "type": 5,
            "color": null,
            "start": 66,
            "dbgdir": 4,
            "dbgstep": 4,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 103,
            "type": 5,
            "color": null,
            "start": 72,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 140,
            "type": 5,
            "color": null,
            "start": 103,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 47,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 72,
            "type": 5,
            "color": null,
            "start": 47,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 97,
            "type": 1,
            "color": null,
            "start": 72,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 100,
            "type": 2,
            "color": null,
            "start": 97,
            "dbgdir": 5,
            "dbgstep": 2,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 103,
            "type": 5,
            "color": null,
            "start": 100,
            "dbgdir": 5,
            "dbgstep": 4,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 140,
            "type": 5,
            "color": null,
            "start": 103,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 47,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 72,
            "type": 5,
            "color": null,
            "start": 47,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 97,
            "type": 1,
            "color": null,
            "start": 72,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 100,
            "type": 2,
            "color": null,
            "start": 97,
            "dbgdir": 6,
            "dbgstep": 2,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 103,
            "type": 5,
            "color": null,
            "start": 100,
            "dbgdir": 6,
            "dbgstep": 4,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 140,
            "type": 5,
            "color": null,
            "start": 103,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 47,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 72,
            "type": 5,
            "color": null,
            "start": 47,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 97,
            "type": 1,
            "color": null,
            "start": 72,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 100,
            "type": 2,
            "color": null,
            "start": 97,
            "dbgdir": 7,
            "dbgstep": 2,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 103,
            "type": 5,
            "color": null,
            "start": 100,
            "dbgdir": 7,
            "dbgstep": 4,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 140,
            "type": 5,
            "color": null,
            "start": 103,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 47,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 72,
            "type": 5,
            "color": null,
            "start": 47,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 103,
            "type": 5,
            "color": null,
            "start": 72,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 131,
            "type": 1,
            "color": null,
            "start": 103,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 134,
            "type": 2,
            "color": null,
            "start": 131,
            "dbgdir": 8,
            "dbgstep": 2,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 137,
            "type": 3,
            "color": null,
            "start": 134,
            "dbgdir": 8,
            "dbgstep": 3,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 140,
            "type": 5,
            "color": null,
            "start": 137,
            "dbgdir": 8,
            "dbgstep": 4,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          }
        ],
        [
          {
            "stop": 47,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 72,
            "type": 5,
            "color": null,
            "start": 47,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 103,
            "type": 1,
            "color": null,
            "start": 72,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 131,
            "type": 1,
            "color": null,
            "start": 103,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 134,
            "type": 2,
            "color": null,
            "start": 131,
            "dbgdir": 9,
            "dbgstep": 2,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 137,
            "type": 3,
            "color": null,
            "start": 134,
            "dbgdir": 9,
            "dbgstep": 3,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 140,
            "type": 5,
            "color": null,
            "start": 137,
            "dbgdir": 9,
            "dbgstep": 4,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          }
        ]
      ],
      "tlSignalProgram2": [
        {
          "data": [
            {
              "stop": 47,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 60,
              "type": 1,
              "color": null,
              "start": 47,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 63,
              "type": 2,
              "color": null,
              "start": 60,
              "dbgdir": 1,
              "dbgstep": 2,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 66,
              "type": 3,
              "color": null,
              "start": 63,
              "dbgdir": 1,
              "dbgstep": 3,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 72,
              "type": 5,
              "color": null,
              "start": 66,
              "dbgdir": 1,
              "dbgstep": 4,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 103,
              "type": 5,
              "color": null,
              "start": 72,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 140,
              "type": 5,
              "color": null,
              "start": 103,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 1,
          "viewName": "H.1",
          "phaseGroupNum": 4
        },
        {
          "data": [
            {
              "stop": 47,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 60,
              "type": 1,
              "color": null,
              "start": 47,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 63,
              "type": 2,
              "color": null,
              "start": 60,
              "dbgdir": 2,
              "dbgstep": 2,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 66,
              "type": 3,
              "color": null,
              "start": 63,
              "dbgdir": 2,
              "dbgstep": 3,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 72,
              "type": 5,
              "color": null,
              "start": 66,
              "dbgdir": 2,
              "dbgstep": 4,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 103,
              "type": 5,
              "color": null,
              "start": 72,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 140,
              "type": 5,
              "color": null,
              "start": 103,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 2,
          "viewName": "H.2",
          "phaseGroupNum": 4
        },
        {
          "data": [
            {
              "stop": 38,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 41,
              "type": 2,
              "color": null,
              "start": 38,
              "dbgdir": 3,
              "dbgstep": 2,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 44,
              "type": 3,
              "color": null,
              "start": 41,
              "dbgdir": 3,
              "dbgstep": 3,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 47,
              "type": 5,
              "color": null,
              "start": 44,
              "dbgdir": 3,
              "dbgstep": 4,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 72,
              "type": 5,
              "color": null,
              "start": 47,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 103,
              "type": 5,
              "color": null,
              "start": 72,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 140,
              "type": 1,
              "color": null,
              "start": 103,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 1
            }
          ],
          "dirNum": 3,
          "viewName": "H.3",
          "phaseGroupNum": 4
        },
        {
          "data": [
            {
              "stop": 47,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 60,
              "type": 1,
              "color": null,
              "start": 47,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 63,
              "type": 2,
              "color": null,
              "start": 60,
              "dbgdir": 4,
              "dbgstep": 2,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 66,
              "type": 3,
              "color": null,
              "start": 63,
              "dbgdir": 4,
              "dbgstep": 3,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 72,
              "type": 5,
              "color": null,
              "start": 66,
              "dbgdir": 4,
              "dbgstep": 4,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 103,
              "type": 5,
              "color": null,
              "start": 72,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 140,
              "type": 5,
              "color": null,
              "start": 103,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 4,
          "viewName": "H.4",
          "phaseGroupNum": 4
        },
        {
          "data": [
            {
              "stop": 47,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 72,
              "type": 5,
              "color": null,
              "start": 47,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 97,
              "type": 1,
              "color": null,
              "start": 72,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 100,
              "type": 2,
              "color": null,
              "start": 97,
              "dbgdir": 5,
              "dbgstep": 2,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 103,
              "type": 5,
              "color": null,
              "start": 100,
              "dbgdir": 5,
              "dbgstep": 4,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 140,
              "type": 5,
              "color": null,
              "start": 103,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 5,
          "viewName": "H.5",
          "phaseGroupNum": 4
        },
        {
          "data": [
            {
              "stop": 47,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 72,
              "type": 5,
              "color": null,
              "start": 47,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 97,
              "type": 1,
              "color": null,
              "start": 72,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 100,
              "type": 2,
              "color": null,
              "start": 97,
              "dbgdir": 6,
              "dbgstep": 2,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 103,
              "type": 5,
              "color": null,
              "start": 100,
              "dbgdir": 6,
              "dbgstep": 4,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 140,
              "type": 5,
              "color": null,
              "start": 103,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 6,
          "viewName": "H.6",
          "phaseGroupNum": 4
        },
        {
          "data": [
            {
              "stop": 47,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 72,
              "type": 5,
              "color": null,
              "start": 47,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 97,
              "type": 1,
              "color": null,
              "start": 72,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 100,
              "type": 2,
              "color": null,
              "start": 97,
              "dbgdir": 7,
              "dbgstep": 2,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 103,
              "type": 5,
              "color": null,
              "start": 100,
              "dbgdir": 7,
              "dbgstep": 4,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 140,
              "type": 5,
              "color": null,
              "start": 103,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 7,
          "viewName": "H.7",
          "phaseGroupNum": 4
        },
        {
          "data": [
            {
              "stop": 47,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 72,
              "type": 5,
              "color": null,
              "start": 47,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 103,
              "type": 5,
              "color": null,
              "start": 72,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 131,
              "type": 1,
              "color": null,
              "start": 103,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 134,
              "type": 2,
              "color": null,
              "start": 131,
              "dbgdir": 8,
              "dbgstep": 2,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 137,
              "type": 3,
              "color": null,
              "start": 134,
              "dbgdir": 8,
              "dbgstep": 3,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 140,
              "type": 5,
              "color": null,
              "start": 137,
              "dbgdir": 8,
              "dbgstep": 4,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            }
          ],
          "dirNum": 8,
          "viewName": "H.8",
          "phaseGroupNum": 4
        },
        {
          "data": [
            {
              "stop": 47,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 72,
              "type": 5,
              "color": null,
              "start": 47,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 103,
              "type": 1,
              "color": null,
              "start": 72,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 131,
              "type": 1,
              "color": null,
              "start": 103,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 134,
              "type": 2,
              "color": null,
              "start": 131,
              "dbgdir": 9,
              "dbgstep": 2,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 137,
              "type": 3,
              "color": null,
              "start": 134,
              "dbgdir": 9,
              "dbgstep": 3,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 140,
              "type": 5,
              "color": null,
              "start": 137,
              "dbgdir": 9,
              "dbgstep": 4,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            }
          ],
          "dirNum": 9,
          "viewName": "H.9",
          "phaseGroupNum": 4
        }
      ]
    },
    {
      "addInfo": null,
      "tlTacts": [
        {
          "main": 1,
          "tact": 1,
          "time": 45,
          "color": "#00ff40",
          "phase": 1,
          "direction": [
            1,
            2,
            3
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 2,
          "time": 9,
          "color": "#ce6f4f",
          "phase": 1,
          "direction": [
            1,
            2,
            3
          ],
          "taсtType": 1
        },
        {
          "main": 1,
          "tact": 3,
          "time": 13,
          "color": "#00ff40",
          "phase": 2,
          "direction": [
            1,
            2,
            4
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 4,
          "time": 12,
          "color": "#ce6f4f",
          "phase": 2,
          "direction": [
            1,
            2,
            4
          ],
          "taсtType": 1
        },
        {
          "main": 1,
          "tact": 5,
          "time": 29,
          "color": "#00ff40",
          "phase": 3,
          "direction": [
            5,
            6,
            7,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 6,
          "time": 6,
          "color": "#ce6f4f",
          "phase": 3,
          "direction": [
            5,
            6,
            7,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 1,
          "tact": 7,
          "time": 60,
          "color": "#00ff40",
          "phase": 4,
          "direction": [
            3,
            8,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 8,
          "time": 9,
          "color": "#ce6f4f",
          "phase": 4,
          "direction": [
            3,
            8,
            9
          ],
          "taсtType": 1
        }
      ],
      "execTime": "ежедневно (план №5)",
      "viewItem": "нет",
      "phaseGroupNum": 5,
      "tlSignalProgram": [
        [
          {
            "stop": 54,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 79,
            "type": 5,
            "color": null,
            "start": 54,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 108,
            "type": 1,
            "color": null,
            "start": 79,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 111,
            "type": 2,
            "color": null,
            "start": 108,
            "dbgdir": 7,
            "dbgstep": 2,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 114,
            "type": 5,
            "color": null,
            "start": 111,
            "dbgdir": 7,
            "dbgstep": 4,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 183,
            "type": 5,
            "color": null,
            "start": 114,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 54,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 67,
            "type": 1,
            "color": null,
            "start": 54,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 70,
            "type": 2,
            "color": null,
            "start": 67,
            "dbgdir": 1,
            "dbgstep": 2,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 73,
            "type": 3,
            "color": null,
            "start": 70,
            "dbgdir": 1,
            "dbgstep": 3,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 79,
            "type": 5,
            "color": null,
            "start": 73,
            "dbgdir": 1,
            "dbgstep": 4,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 114,
            "type": 5,
            "color": null,
            "start": 79,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 183,
            "type": 5,
            "color": null,
            "start": 114,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 54,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 67,
            "type": 1,
            "color": null,
            "start": 54,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 70,
            "type": 2,
            "color": null,
            "start": 67,
            "dbgdir": 2,
            "dbgstep": 2,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 73,
            "type": 3,
            "color": null,
            "start": 70,
            "dbgdir": 2,
            "dbgstep": 3,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 79,
            "type": 5,
            "color": null,
            "start": 73,
            "dbgdir": 2,
            "dbgstep": 4,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 114,
            "type": 5,
            "color": null,
            "start": 79,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 183,
            "type": 5,
            "color": null,
            "start": 114,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 45,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 48,
            "type": 2,
            "color": null,
            "start": 45,
            "dbgdir": 3,
            "dbgstep": 2,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 51,
            "type": 3,
            "color": null,
            "start": 48,
            "dbgdir": 3,
            "dbgstep": 3,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 54,
            "type": 5,
            "color": null,
            "start": 51,
            "dbgdir": 3,
            "dbgstep": 4,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 79,
            "type": 5,
            "color": null,
            "start": 54,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 114,
            "type": 5,
            "color": null,
            "start": 79,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 183,
            "type": 1,
            "color": null,
            "start": 114,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 1
          }
        ],
        [
          {
            "stop": 54,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 67,
            "type": 1,
            "color": null,
            "start": 54,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 70,
            "type": 2,
            "color": null,
            "start": 67,
            "dbgdir": 4,
            "dbgstep": 2,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 73,
            "type": 3,
            "color": null,
            "start": 70,
            "dbgdir": 4,
            "dbgstep": 3,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 79,
            "type": 5,
            "color": null,
            "start": 73,
            "dbgdir": 4,
            "dbgstep": 4,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 114,
            "type": 5,
            "color": null,
            "start": 79,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 183,
            "type": 5,
            "color": null,
            "start": 114,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 54,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 79,
            "type": 5,
            "color": null,
            "start": 54,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 108,
            "type": 1,
            "color": null,
            "start": 79,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 111,
            "type": 2,
            "color": null,
            "start": 108,
            "dbgdir": 5,
            "dbgstep": 2,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 114,
            "type": 5,
            "color": null,
            "start": 111,
            "dbgdir": 5,
            "dbgstep": 4,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 183,
            "type": 5,
            "color": null,
            "start": 114,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 54,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 79,
            "type": 5,
            "color": null,
            "start": 54,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 108,
            "type": 1,
            "color": null,
            "start": 79,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 111,
            "type": 2,
            "color": null,
            "start": 108,
            "dbgdir": 6,
            "dbgstep": 2,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 114,
            "type": 5,
            "color": null,
            "start": 111,
            "dbgdir": 6,
            "dbgstep": 4,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 183,
            "type": 5,
            "color": null,
            "start": 114,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 54,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 79,
            "type": 5,
            "color": null,
            "start": 54,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 114,
            "type": 5,
            "color": null,
            "start": 79,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 174,
            "type": 1,
            "color": null,
            "start": 114,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 177,
            "type": 2,
            "color": null,
            "start": 174,
            "dbgdir": 8,
            "dbgstep": 2,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 180,
            "type": 3,
            "color": null,
            "start": 177,
            "dbgdir": 8,
            "dbgstep": 3,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 183,
            "type": 5,
            "color": null,
            "start": 180,
            "dbgdir": 8,
            "dbgstep": 4,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          }
        ],
        [
          {
            "stop": 54,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 79,
            "type": 5,
            "color": null,
            "start": 54,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 114,
            "type": 1,
            "color": null,
            "start": 79,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 174,
            "type": 1,
            "color": null,
            "start": 114,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 177,
            "type": 2,
            "color": null,
            "start": 174,
            "dbgdir": 9,
            "dbgstep": 2,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 180,
            "type": 3,
            "color": null,
            "start": 177,
            "dbgdir": 9,
            "dbgstep": 3,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 183,
            "type": 5,
            "color": null,
            "start": 180,
            "dbgdir": 9,
            "dbgstep": 4,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          }
        ]
      ],
      "tlSignalProgram2": [
        {
          "data": [
            {
              "stop": 54,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 79,
              "type": 5,
              "color": null,
              "start": 54,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 108,
              "type": 1,
              "color": null,
              "start": 79,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 111,
              "type": 2,
              "color": null,
              "start": 108,
              "dbgdir": 7,
              "dbgstep": 2,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 114,
              "type": 5,
              "color": null,
              "start": 111,
              "dbgdir": 7,
              "dbgstep": 4,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 183,
              "type": 5,
              "color": null,
              "start": 114,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 7,
          "viewName": "H.7",
          "phaseGroupNum": 5
        },
        {
          "data": [
            {
              "stop": 54,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 67,
              "type": 1,
              "color": null,
              "start": 54,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 70,
              "type": 2,
              "color": null,
              "start": 67,
              "dbgdir": 1,
              "dbgstep": 2,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 73,
              "type": 3,
              "color": null,
              "start": 70,
              "dbgdir": 1,
              "dbgstep": 3,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 79,
              "type": 5,
              "color": null,
              "start": 73,
              "dbgdir": 1,
              "dbgstep": 4,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 114,
              "type": 5,
              "color": null,
              "start": 79,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 183,
              "type": 5,
              "color": null,
              "start": 114,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 1,
          "viewName": "H.1",
          "phaseGroupNum": 5
        },
        {
          "data": [
            {
              "stop": 54,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 67,
              "type": 1,
              "color": null,
              "start": 54,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 70,
              "type": 2,
              "color": null,
              "start": 67,
              "dbgdir": 2,
              "dbgstep": 2,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 73,
              "type": 3,
              "color": null,
              "start": 70,
              "dbgdir": 2,
              "dbgstep": 3,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 79,
              "type": 5,
              "color": null,
              "start": 73,
              "dbgdir": 2,
              "dbgstep": 4,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 114,
              "type": 5,
              "color": null,
              "start": 79,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 183,
              "type": 5,
              "color": null,
              "start": 114,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 2,
          "viewName": "H.2",
          "phaseGroupNum": 5
        },
        {
          "data": [
            {
              "stop": 45,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 48,
              "type": 2,
              "color": null,
              "start": 45,
              "dbgdir": 3,
              "dbgstep": 2,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 51,
              "type": 3,
              "color": null,
              "start": 48,
              "dbgdir": 3,
              "dbgstep": 3,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 54,
              "type": 5,
              "color": null,
              "start": 51,
              "dbgdir": 3,
              "dbgstep": 4,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 79,
              "type": 5,
              "color": null,
              "start": 54,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 114,
              "type": 5,
              "color": null,
              "start": 79,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 183,
              "type": 1,
              "color": null,
              "start": 114,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 1
            }
          ],
          "dirNum": 3,
          "viewName": "H.3",
          "phaseGroupNum": 5
        },
        {
          "data": [
            {
              "stop": 54,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 67,
              "type": 1,
              "color": null,
              "start": 54,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 70,
              "type": 2,
              "color": null,
              "start": 67,
              "dbgdir": 4,
              "dbgstep": 2,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 73,
              "type": 3,
              "color": null,
              "start": 70,
              "dbgdir": 4,
              "dbgstep": 3,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 79,
              "type": 5,
              "color": null,
              "start": 73,
              "dbgdir": 4,
              "dbgstep": 4,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 114,
              "type": 5,
              "color": null,
              "start": 79,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 183,
              "type": 5,
              "color": null,
              "start": 114,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 4,
          "viewName": "H.4",
          "phaseGroupNum": 5
        },
        {
          "data": [
            {
              "stop": 54,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 79,
              "type": 5,
              "color": null,
              "start": 54,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 108,
              "type": 1,
              "color": null,
              "start": 79,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 111,
              "type": 2,
              "color": null,
              "start": 108,
              "dbgdir": 5,
              "dbgstep": 2,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 114,
              "type": 5,
              "color": null,
              "start": 111,
              "dbgdir": 5,
              "dbgstep": 4,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 183,
              "type": 5,
              "color": null,
              "start": 114,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 5,
          "viewName": "H.5",
          "phaseGroupNum": 5
        },
        {
          "data": [
            {
              "stop": 54,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 79,
              "type": 5,
              "color": null,
              "start": 54,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 108,
              "type": 1,
              "color": null,
              "start": 79,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 111,
              "type": 2,
              "color": null,
              "start": 108,
              "dbgdir": 6,
              "dbgstep": 2,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 114,
              "type": 5,
              "color": null,
              "start": 111,
              "dbgdir": 6,
              "dbgstep": 4,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 183,
              "type": 5,
              "color": null,
              "start": 114,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 6,
          "viewName": "H.6",
          "phaseGroupNum": 5
        },
        {
          "data": [
            {
              "stop": 54,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 79,
              "type": 5,
              "color": null,
              "start": 54,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 114,
              "type": 5,
              "color": null,
              "start": 79,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 174,
              "type": 1,
              "color": null,
              "start": 114,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 177,
              "type": 2,
              "color": null,
              "start": 174,
              "dbgdir": 8,
              "dbgstep": 2,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 180,
              "type": 3,
              "color": null,
              "start": 177,
              "dbgdir": 8,
              "dbgstep": 3,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 183,
              "type": 5,
              "color": null,
              "start": 180,
              "dbgdir": 8,
              "dbgstep": 4,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            }
          ],
          "dirNum": 8,
          "viewName": "H.8",
          "phaseGroupNum": 5
        },
        {
          "data": [
            {
              "stop": 54,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 79,
              "type": 5,
              "color": null,
              "start": 54,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 114,
              "type": 1,
              "color": null,
              "start": 79,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 174,
              "type": 1,
              "color": null,
              "start": 114,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 177,
              "type": 2,
              "color": null,
              "start": 174,
              "dbgdir": 9,
              "dbgstep": 2,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 180,
              "type": 3,
              "color": null,
              "start": 177,
              "dbgdir": 9,
              "dbgstep": 3,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 183,
              "type": 5,
              "color": null,
              "start": 180,
              "dbgdir": 9,
              "dbgstep": 4,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            }
          ],
          "dirNum": 9,
          "viewName": "H.9",
          "phaseGroupNum": 5
        }
      ]
    },
    {
      "addInfo": null,
      "tlTacts": [
        {
          "main": 1,
          "tact": 1,
          "time": 42,
          "color": "#00ff40",
          "phase": 1,
          "direction": [
            1,
            2,
            3
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 2,
          "time": 9,
          "color": "#ce6f4f",
          "phase": 1,
          "direction": [
            1,
            2,
            3
          ],
          "taсtType": 1
        },
        {
          "main": 1,
          "tact": 3,
          "time": 13,
          "color": "#00ff40",
          "phase": 2,
          "direction": [
            1,
            2,
            4
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 4,
          "time": 12,
          "color": "#ce6f4f",
          "phase": 2,
          "direction": [
            1,
            2,
            4
          ],
          "taсtType": 1
        },
        {
          "main": 1,
          "tact": 5,
          "time": 28,
          "color": "#00ff40",
          "phase": 3,
          "direction": [
            5,
            6,
            7,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 6,
          "time": 6,
          "color": "#ce6f4f",
          "phase": 3,
          "direction": [
            5,
            6,
            7,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 1,
          "tact": 7,
          "time": 44,
          "color": "#00ff40",
          "phase": 4,
          "direction": [
            3,
            8,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 8,
          "time": 9,
          "color": "#ce6f4f",
          "phase": 4,
          "direction": [
            3,
            8,
            9
          ],
          "taсtType": 1
        }
      ],
      "execTime": "ежедневно (план №6)",
      "viewItem": "нет",
      "phaseGroupNum": 6,
      "tlSignalProgram": [
        [
          {
            "stop": 51,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 76,
            "type": 5,
            "color": null,
            "start": 51,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 104,
            "type": 1,
            "color": null,
            "start": 76,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 107,
            "type": 2,
            "color": null,
            "start": 104,
            "dbgdir": 7,
            "dbgstep": 2,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 110,
            "type": 5,
            "color": null,
            "start": 107,
            "dbgdir": 7,
            "dbgstep": 4,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 163,
            "type": 5,
            "color": null,
            "start": 110,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 51,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 64,
            "type": 1,
            "color": null,
            "start": 51,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 67,
            "type": 2,
            "color": null,
            "start": 64,
            "dbgdir": 1,
            "dbgstep": 2,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 70,
            "type": 3,
            "color": null,
            "start": 67,
            "dbgdir": 1,
            "dbgstep": 3,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 76,
            "type": 5,
            "color": null,
            "start": 70,
            "dbgdir": 1,
            "dbgstep": 4,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 110,
            "type": 5,
            "color": null,
            "start": 76,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 163,
            "type": 5,
            "color": null,
            "start": 110,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 51,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 64,
            "type": 1,
            "color": null,
            "start": 51,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 67,
            "type": 2,
            "color": null,
            "start": 64,
            "dbgdir": 2,
            "dbgstep": 2,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 70,
            "type": 3,
            "color": null,
            "start": 67,
            "dbgdir": 2,
            "dbgstep": 3,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 76,
            "type": 5,
            "color": null,
            "start": 70,
            "dbgdir": 2,
            "dbgstep": 4,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 110,
            "type": 5,
            "color": null,
            "start": 76,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 163,
            "type": 5,
            "color": null,
            "start": 110,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 42,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 45,
            "type": 2,
            "color": null,
            "start": 42,
            "dbgdir": 3,
            "dbgstep": 2,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 48,
            "type": 3,
            "color": null,
            "start": 45,
            "dbgdir": 3,
            "dbgstep": 3,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 51,
            "type": 5,
            "color": null,
            "start": 48,
            "dbgdir": 3,
            "dbgstep": 4,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 76,
            "type": 5,
            "color": null,
            "start": 51,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 110,
            "type": 5,
            "color": null,
            "start": 76,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 163,
            "type": 1,
            "color": null,
            "start": 110,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 1
          }
        ],
        [
          {
            "stop": 51,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 64,
            "type": 1,
            "color": null,
            "start": 51,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 67,
            "type": 2,
            "color": null,
            "start": 64,
            "dbgdir": 4,
            "dbgstep": 2,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 70,
            "type": 3,
            "color": null,
            "start": 67,
            "dbgdir": 4,
            "dbgstep": 3,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 76,
            "type": 5,
            "color": null,
            "start": 70,
            "dbgdir": 4,
            "dbgstep": 4,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 110,
            "type": 5,
            "color": null,
            "start": 76,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 163,
            "type": 5,
            "color": null,
            "start": 110,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 51,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 76,
            "type": 5,
            "color": null,
            "start": 51,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 104,
            "type": 1,
            "color": null,
            "start": 76,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 107,
            "type": 2,
            "color": null,
            "start": 104,
            "dbgdir": 5,
            "dbgstep": 2,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 110,
            "type": 5,
            "color": null,
            "start": 107,
            "dbgdir": 5,
            "dbgstep": 4,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 163,
            "type": 5,
            "color": null,
            "start": 110,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 51,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 76,
            "type": 5,
            "color": null,
            "start": 51,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 104,
            "type": 1,
            "color": null,
            "start": 76,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 107,
            "type": 2,
            "color": null,
            "start": 104,
            "dbgdir": 6,
            "dbgstep": 2,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 110,
            "type": 5,
            "color": null,
            "start": 107,
            "dbgdir": 6,
            "dbgstep": 4,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 163,
            "type": 5,
            "color": null,
            "start": 110,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 51,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 76,
            "type": 5,
            "color": null,
            "start": 51,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 110,
            "type": 5,
            "color": null,
            "start": 76,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 154,
            "type": 1,
            "color": null,
            "start": 110,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 157,
            "type": 2,
            "color": null,
            "start": 154,
            "dbgdir": 8,
            "dbgstep": 2,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 160,
            "type": 3,
            "color": null,
            "start": 157,
            "dbgdir": 8,
            "dbgstep": 3,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 163,
            "type": 5,
            "color": null,
            "start": 160,
            "dbgdir": 8,
            "dbgstep": 4,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          }
        ],
        [
          {
            "stop": 51,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 76,
            "type": 5,
            "color": null,
            "start": 51,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 110,
            "type": 1,
            "color": null,
            "start": 76,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 154,
            "type": 1,
            "color": null,
            "start": 110,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 157,
            "type": 2,
            "color": null,
            "start": 154,
            "dbgdir": 9,
            "dbgstep": 2,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 160,
            "type": 3,
            "color": null,
            "start": 157,
            "dbgdir": 9,
            "dbgstep": 3,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 163,
            "type": 5,
            "color": null,
            "start": 160,
            "dbgdir": 9,
            "dbgstep": 4,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          }
        ]
      ],
      "tlSignalProgram2": [
        {
          "data": [
            {
              "stop": 51,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 76,
              "type": 5,
              "color": null,
              "start": 51,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 104,
              "type": 1,
              "color": null,
              "start": 76,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 107,
              "type": 2,
              "color": null,
              "start": 104,
              "dbgdir": 7,
              "dbgstep": 2,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 110,
              "type": 5,
              "color": null,
              "start": 107,
              "dbgdir": 7,
              "dbgstep": 4,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 163,
              "type": 5,
              "color": null,
              "start": 110,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 7,
          "viewName": "H.7",
          "phaseGroupNum": 6
        },
        {
          "data": [
            {
              "stop": 51,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 64,
              "type": 1,
              "color": null,
              "start": 51,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 67,
              "type": 2,
              "color": null,
              "start": 64,
              "dbgdir": 1,
              "dbgstep": 2,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 70,
              "type": 3,
              "color": null,
              "start": 67,
              "dbgdir": 1,
              "dbgstep": 3,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 76,
              "type": 5,
              "color": null,
              "start": 70,
              "dbgdir": 1,
              "dbgstep": 4,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 110,
              "type": 5,
              "color": null,
              "start": 76,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 163,
              "type": 5,
              "color": null,
              "start": 110,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 1,
          "viewName": "H.1",
          "phaseGroupNum": 6
        },
        {
          "data": [
            {
              "stop": 51,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 64,
              "type": 1,
              "color": null,
              "start": 51,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 67,
              "type": 2,
              "color": null,
              "start": 64,
              "dbgdir": 2,
              "dbgstep": 2,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 70,
              "type": 3,
              "color": null,
              "start": 67,
              "dbgdir": 2,
              "dbgstep": 3,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 76,
              "type": 5,
              "color": null,
              "start": 70,
              "dbgdir": 2,
              "dbgstep": 4,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 110,
              "type": 5,
              "color": null,
              "start": 76,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 163,
              "type": 5,
              "color": null,
              "start": 110,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 2,
          "viewName": "H.2",
          "phaseGroupNum": 6
        },
        {
          "data": [
            {
              "stop": 42,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 45,
              "type": 2,
              "color": null,
              "start": 42,
              "dbgdir": 3,
              "dbgstep": 2,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 48,
              "type": 3,
              "color": null,
              "start": 45,
              "dbgdir": 3,
              "dbgstep": 3,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 51,
              "type": 5,
              "color": null,
              "start": 48,
              "dbgdir": 3,
              "dbgstep": 4,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 76,
              "type": 5,
              "color": null,
              "start": 51,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 110,
              "type": 5,
              "color": null,
              "start": 76,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 163,
              "type": 1,
              "color": null,
              "start": 110,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 1
            }
          ],
          "dirNum": 3,
          "viewName": "H.3",
          "phaseGroupNum": 6
        },
        {
          "data": [
            {
              "stop": 51,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 64,
              "type": 1,
              "color": null,
              "start": 51,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 67,
              "type": 2,
              "color": null,
              "start": 64,
              "dbgdir": 4,
              "dbgstep": 2,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 70,
              "type": 3,
              "color": null,
              "start": 67,
              "dbgdir": 4,
              "dbgstep": 3,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 76,
              "type": 5,
              "color": null,
              "start": 70,
              "dbgdir": 4,
              "dbgstep": 4,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 110,
              "type": 5,
              "color": null,
              "start": 76,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 163,
              "type": 5,
              "color": null,
              "start": 110,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 4,
          "viewName": "H.4",
          "phaseGroupNum": 6
        },
        {
          "data": [
            {
              "stop": 51,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 76,
              "type": 5,
              "color": null,
              "start": 51,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 104,
              "type": 1,
              "color": null,
              "start": 76,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 107,
              "type": 2,
              "color": null,
              "start": 104,
              "dbgdir": 5,
              "dbgstep": 2,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 110,
              "type": 5,
              "color": null,
              "start": 107,
              "dbgdir": 5,
              "dbgstep": 4,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 163,
              "type": 5,
              "color": null,
              "start": 110,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 5,
          "viewName": "H.5",
          "phaseGroupNum": 6
        },
        {
          "data": [
            {
              "stop": 51,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 76,
              "type": 5,
              "color": null,
              "start": 51,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 104,
              "type": 1,
              "color": null,
              "start": 76,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 107,
              "type": 2,
              "color": null,
              "start": 104,
              "dbgdir": 6,
              "dbgstep": 2,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 110,
              "type": 5,
              "color": null,
              "start": 107,
              "dbgdir": 6,
              "dbgstep": 4,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 163,
              "type": 5,
              "color": null,
              "start": 110,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 6,
          "viewName": "H.6",
          "phaseGroupNum": 6
        },
        {
          "data": [
            {
              "stop": 51,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 76,
              "type": 5,
              "color": null,
              "start": 51,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 110,
              "type": 5,
              "color": null,
              "start": 76,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 154,
              "type": 1,
              "color": null,
              "start": 110,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 157,
              "type": 2,
              "color": null,
              "start": 154,
              "dbgdir": 8,
              "dbgstep": 2,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 160,
              "type": 3,
              "color": null,
              "start": 157,
              "dbgdir": 8,
              "dbgstep": 3,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 163,
              "type": 5,
              "color": null,
              "start": 160,
              "dbgdir": 8,
              "dbgstep": 4,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            }
          ],
          "dirNum": 8,
          "viewName": "H.8",
          "phaseGroupNum": 6
        },
        {
          "data": [
            {
              "stop": 51,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 76,
              "type": 5,
              "color": null,
              "start": 51,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 110,
              "type": 1,
              "color": null,
              "start": 76,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 154,
              "type": 1,
              "color": null,
              "start": 110,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 157,
              "type": 2,
              "color": null,
              "start": 154,
              "dbgdir": 9,
              "dbgstep": 2,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 160,
              "type": 3,
              "color": null,
              "start": 157,
              "dbgdir": 9,
              "dbgstep": 3,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 163,
              "type": 5,
              "color": null,
              "start": 160,
              "dbgdir": 9,
              "dbgstep": 4,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            }
          ],
          "dirNum": 9,
          "viewName": "H.9",
          "phaseGroupNum": 6
        }
      ]
    },
    {
      "addInfo": null,
      "tlTacts": [
        {
          "main": 1,
          "tact": 1,
          "time": 60,
          "color": "#00ff40",
          "phase": 1,
          "direction": [
            1,
            2,
            3,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 2,
          "time": 9,
          "color": "#ce6f4f",
          "phase": 1,
          "direction": [
            1,
            2,
            3,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 1,
          "tact": 3,
          "time": 13,
          "color": "#00ff40",
          "phase": 2,
          "direction": [
            1,
            2,
            4
          ],
          "taсtType": 2
        },
        {
          "main": 0,
          "tact": 4,
          "time": 9,
          "color": "#ce6f4f",
          "phase": 2,
          "direction": [
            1,
            2,
            4
          ],
          "taсtType": 2
        },
        {
          "main": 1,
          "tact": 5,
          "time": 20,
          "color": "#00ff40",
          "phase": 3,
          "direction": [
            1,
            5,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 6,
          "time": 9,
          "color": "#ce6f4f",
          "phase": 3,
          "direction": [
            1,
            5,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 1,
          "tact": 7,
          "time": 24,
          "color": "#00ff40",
          "phase": 4,
          "direction": [
            5,
            6,
            7,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 8,
          "time": 9,
          "color": "#ce6f4f",
          "phase": 4,
          "direction": [
            5,
            6,
            7,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 1,
          "tact": 9,
          "time": 35,
          "color": "#00ff40",
          "phase": 5,
          "direction": [
            3,
            8,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 10,
          "time": 9,
          "color": "#ce6f4f",
          "phase": 5,
          "direction": [
            3,
            8,
            9
          ],
          "taсtType": 1
        }
      ],
      "execTime": "ежедневно (план №7)",
      "viewItem": "нет",
      "phaseGroupNum": 7,
      "tlSignalProgram": [
        [
          {
            "stop": 69,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 91,
            "type": 1,
            "color": null,
            "start": 69,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 111,
            "type": 1,
            "color": null,
            "start": 91,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 114,
            "type": 2,
            "color": null,
            "start": 111,
            "dbgdir": 1,
            "dbgstep": 2,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 117,
            "type": 3,
            "color": null,
            "start": 114,
            "dbgdir": 1,
            "dbgstep": 3,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 120,
            "type": 5,
            "color": null,
            "start": 117,
            "dbgdir": 1,
            "dbgstep": 4,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 153,
            "type": 5,
            "color": null,
            "start": 120,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 197,
            "type": 5,
            "color": null,
            "start": 153,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 69,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 82,
            "type": 1,
            "color": null,
            "start": 69,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 85,
            "type": 2,
            "color": null,
            "start": 82,
            "dbgdir": 2,
            "dbgstep": 2,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 88,
            "type": 3,
            "color": null,
            "start": 85,
            "dbgdir": 2,
            "dbgstep": 3,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 91,
            "type": 5,
            "color": null,
            "start": 88,
            "dbgdir": 2,
            "dbgstep": 4,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 120,
            "type": 5,
            "color": null,
            "start": 91,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 153,
            "type": 5,
            "color": null,
            "start": 120,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 197,
            "type": 5,
            "color": null,
            "start": 153,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 60,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 63,
            "type": 2,
            "color": null,
            "start": 60,
            "dbgdir": 3,
            "dbgstep": 2,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 66,
            "type": 3,
            "color": null,
            "start": 63,
            "dbgdir": 3,
            "dbgstep": 3,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 69,
            "type": 5,
            "color": null,
            "start": 66,
            "dbgdir": 3,
            "dbgstep": 4,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 91,
            "type": 5,
            "color": null,
            "start": 69,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 120,
            "type": 5,
            "color": null,
            "start": 91,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 153,
            "type": 5,
            "color": null,
            "start": 120,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 197,
            "type": 1,
            "color": null,
            "start": 153,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 1
          }
        ],
        [
          {
            "stop": 69,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 82,
            "type": 1,
            "color": null,
            "start": 69,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 85,
            "type": 2,
            "color": null,
            "start": 82,
            "dbgdir": 4,
            "dbgstep": 2,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 88,
            "type": 3,
            "color": null,
            "start": 85,
            "dbgdir": 4,
            "dbgstep": 3,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 91,
            "type": 5,
            "color": null,
            "start": 88,
            "dbgdir": 4,
            "dbgstep": 4,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 120,
            "type": 5,
            "color": null,
            "start": 91,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 153,
            "type": 5,
            "color": null,
            "start": 120,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 197,
            "type": 5,
            "color": null,
            "start": 153,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 69,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 91,
            "type": 5,
            "color": null,
            "start": 69,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 120,
            "type": 1,
            "color": null,
            "start": 91,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 144,
            "type": 1,
            "color": null,
            "start": 120,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 147,
            "type": 2,
            "color": null,
            "start": 144,
            "dbgdir": 5,
            "dbgstep": 2,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 150,
            "type": 3,
            "color": null,
            "start": 147,
            "dbgdir": 5,
            "dbgstep": 3,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 153,
            "type": 5,
            "color": null,
            "start": 150,
            "dbgdir": 5,
            "dbgstep": 4,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 197,
            "type": 5,
            "color": null,
            "start": 153,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 69,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 91,
            "type": 5,
            "color": null,
            "start": 69,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 120,
            "type": 5,
            "color": null,
            "start": 91,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 144,
            "type": 1,
            "color": null,
            "start": 120,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 147,
            "type": 2,
            "color": null,
            "start": 144,
            "dbgdir": 6,
            "dbgstep": 2,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 150,
            "type": 3,
            "color": null,
            "start": 147,
            "dbgdir": 6,
            "dbgstep": 3,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 153,
            "type": 5,
            "color": null,
            "start": 150,
            "dbgdir": 6,
            "dbgstep": 4,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 197,
            "type": 5,
            "color": null,
            "start": 153,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 69,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 91,
            "type": 5,
            "color": null,
            "start": 69,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 120,
            "type": 5,
            "color": null,
            "start": 91,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 144,
            "type": 1,
            "color": null,
            "start": 120,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 147,
            "type": 2,
            "color": null,
            "start": 144,
            "dbgdir": 7,
            "dbgstep": 2,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 150,
            "type": 3,
            "color": null,
            "start": 147,
            "dbgdir": 7,
            "dbgstep": 3,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 153,
            "type": 5,
            "color": null,
            "start": 150,
            "dbgdir": 7,
            "dbgstep": 4,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 197,
            "type": 5,
            "color": null,
            "start": 153,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 69,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 91,
            "type": 5,
            "color": null,
            "start": 69,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 120,
            "type": 5,
            "color": null,
            "start": 91,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 153,
            "type": 5,
            "color": null,
            "start": 120,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 188,
            "type": 1,
            "color": null,
            "start": 153,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 191,
            "type": 2,
            "color": null,
            "start": 188,
            "dbgdir": 8,
            "dbgstep": 2,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 194,
            "type": 3,
            "color": null,
            "start": 191,
            "dbgdir": 8,
            "dbgstep": 3,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 197,
            "type": 5,
            "color": null,
            "start": 194,
            "dbgdir": 8,
            "dbgstep": 4,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 0
          }
        ],
        [
          {
            "stop": 60,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 63,
            "type": 2,
            "color": null,
            "start": 60,
            "dbgdir": 9,
            "dbgstep": 2,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 66,
            "type": 3,
            "color": null,
            "start": 63,
            "dbgdir": 9,
            "dbgstep": 3,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 69,
            "type": 5,
            "color": null,
            "start": 66,
            "dbgdir": 9,
            "dbgstep": 4,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 91,
            "type": 5,
            "color": null,
            "start": 69,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 120,
            "type": 1,
            "color": null,
            "start": 91,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 153,
            "type": 1,
            "color": null,
            "start": 120,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 197,
            "type": 1,
            "color": null,
            "start": 153,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 1
          }
        ]
      ],
      "tlSignalProgram2": [
        {
          "data": [
            {
              "stop": 69,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 91,
              "type": 1,
              "color": null,
              "start": 69,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 111,
              "type": 1,
              "color": null,
              "start": 91,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 114,
              "type": 2,
              "color": null,
              "start": 111,
              "dbgdir": 1,
              "dbgstep": 2,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 117,
              "type": 3,
              "color": null,
              "start": 114,
              "dbgdir": 1,
              "dbgstep": 3,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 120,
              "type": 5,
              "color": null,
              "start": 117,
              "dbgdir": 1,
              "dbgstep": 4,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 153,
              "type": 5,
              "color": null,
              "start": 120,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 197,
              "type": 5,
              "color": null,
              "start": 153,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 1,
          "viewName": "H.1",
          "phaseGroupNum": 7
        },
        {
          "data": [
            {
              "stop": 69,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 82,
              "type": 1,
              "color": null,
              "start": 69,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 85,
              "type": 2,
              "color": null,
              "start": 82,
              "dbgdir": 2,
              "dbgstep": 2,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 88,
              "type": 3,
              "color": null,
              "start": 85,
              "dbgdir": 2,
              "dbgstep": 3,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 91,
              "type": 5,
              "color": null,
              "start": 88,
              "dbgdir": 2,
              "dbgstep": 4,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 120,
              "type": 5,
              "color": null,
              "start": 91,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 153,
              "type": 5,
              "color": null,
              "start": 120,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 197,
              "type": 5,
              "color": null,
              "start": 153,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 2,
          "viewName": "H.2",
          "phaseGroupNum": 7
        },
        {
          "data": [
            {
              "stop": 60,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 63,
              "type": 2,
              "color": null,
              "start": 60,
              "dbgdir": 3,
              "dbgstep": 2,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 66,
              "type": 3,
              "color": null,
              "start": 63,
              "dbgdir": 3,
              "dbgstep": 3,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 69,
              "type": 5,
              "color": null,
              "start": 66,
              "dbgdir": 3,
              "dbgstep": 4,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 91,
              "type": 5,
              "color": null,
              "start": 69,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 120,
              "type": 5,
              "color": null,
              "start": 91,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 153,
              "type": 5,
              "color": null,
              "start": 120,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 197,
              "type": 1,
              "color": null,
              "start": 153,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 1
            }
          ],
          "dirNum": 3,
          "viewName": "H.3",
          "phaseGroupNum": 7
        },
        {
          "data": [
            {
              "stop": 69,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 82,
              "type": 1,
              "color": null,
              "start": 69,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 85,
              "type": 2,
              "color": null,
              "start": 82,
              "dbgdir": 4,
              "dbgstep": 2,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 88,
              "type": 3,
              "color": null,
              "start": 85,
              "dbgdir": 4,
              "dbgstep": 3,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 91,
              "type": 5,
              "color": null,
              "start": 88,
              "dbgdir": 4,
              "dbgstep": 4,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 120,
              "type": 5,
              "color": null,
              "start": 91,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 153,
              "type": 5,
              "color": null,
              "start": 120,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 197,
              "type": 5,
              "color": null,
              "start": 153,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 4,
          "viewName": "H.4",
          "phaseGroupNum": 7
        },
        {
          "data": [
            {
              "stop": 69,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 91,
              "type": 5,
              "color": null,
              "start": 69,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 120,
              "type": 1,
              "color": null,
              "start": 91,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 144,
              "type": 1,
              "color": null,
              "start": 120,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 147,
              "type": 2,
              "color": null,
              "start": 144,
              "dbgdir": 5,
              "dbgstep": 2,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 150,
              "type": 3,
              "color": null,
              "start": 147,
              "dbgdir": 5,
              "dbgstep": 3,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 153,
              "type": 5,
              "color": null,
              "start": 150,
              "dbgdir": 5,
              "dbgstep": 4,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 197,
              "type": 5,
              "color": null,
              "start": 153,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 5,
          "viewName": "H.5",
          "phaseGroupNum": 7
        },
        {
          "data": [
            {
              "stop": 69,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 91,
              "type": 5,
              "color": null,
              "start": 69,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 120,
              "type": 5,
              "color": null,
              "start": 91,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 144,
              "type": 1,
              "color": null,
              "start": 120,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 147,
              "type": 2,
              "color": null,
              "start": 144,
              "dbgdir": 6,
              "dbgstep": 2,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 150,
              "type": 3,
              "color": null,
              "start": 147,
              "dbgdir": 6,
              "dbgstep": 3,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 153,
              "type": 5,
              "color": null,
              "start": 150,
              "dbgdir": 6,
              "dbgstep": 4,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 197,
              "type": 5,
              "color": null,
              "start": 153,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 6,
          "viewName": "H.6",
          "phaseGroupNum": 7
        },
        {
          "data": [
            {
              "stop": 69,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 91,
              "type": 5,
              "color": null,
              "start": 69,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 120,
              "type": 5,
              "color": null,
              "start": 91,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 144,
              "type": 1,
              "color": null,
              "start": 120,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 147,
              "type": 2,
              "color": null,
              "start": 144,
              "dbgdir": 7,
              "dbgstep": 2,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 150,
              "type": 3,
              "color": null,
              "start": 147,
              "dbgdir": 7,
              "dbgstep": 3,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 153,
              "type": 5,
              "color": null,
              "start": 150,
              "dbgdir": 7,
              "dbgstep": 4,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 197,
              "type": 5,
              "color": null,
              "start": 153,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 7,
          "viewName": "H.7",
          "phaseGroupNum": 7
        },
        {
          "data": [
            {
              "stop": 69,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 91,
              "type": 5,
              "color": null,
              "start": 69,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 120,
              "type": 5,
              "color": null,
              "start": 91,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 153,
              "type": 5,
              "color": null,
              "start": 120,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 188,
              "type": 1,
              "color": null,
              "start": 153,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 191,
              "type": 2,
              "color": null,
              "start": 188,
              "dbgdir": 8,
              "dbgstep": 2,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 194,
              "type": 3,
              "color": null,
              "start": 191,
              "dbgdir": 8,
              "dbgstep": 3,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 197,
              "type": 5,
              "color": null,
              "start": 194,
              "dbgdir": 8,
              "dbgstep": 4,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 0
            }
          ],
          "dirNum": 8,
          "viewName": "H.8",
          "phaseGroupNum": 7
        },
        {
          "data": [
            {
              "stop": 60,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 63,
              "type": 2,
              "color": null,
              "start": 60,
              "dbgdir": 9,
              "dbgstep": 2,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 66,
              "type": 3,
              "color": null,
              "start": 63,
              "dbgdir": 9,
              "dbgstep": 3,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 69,
              "type": 5,
              "color": null,
              "start": 66,
              "dbgdir": 9,
              "dbgstep": 4,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 91,
              "type": 5,
              "color": null,
              "start": 69,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 120,
              "type": 1,
              "color": null,
              "start": 91,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 153,
              "type": 1,
              "color": null,
              "start": 120,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 197,
              "type": 1,
              "color": null,
              "start": 153,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 1
            }
          ],
          "dirNum": 9,
          "viewName": "H.9",
          "phaseGroupNum": 7
        }
      ]
    },
    {
      "addInfo": null,
      "tlTacts": [
        {
          "main": 1,
          "tact": 1,
          "time": 50,
          "color": "#00ff40",
          "phase": 1,
          "direction": [
            1,
            2,
            3,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 2,
          "time": 9,
          "color": "#ce6f4f",
          "phase": 1,
          "direction": [
            1,
            2,
            3,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 1,
          "tact": 3,
          "time": 13,
          "color": "#00ff40",
          "phase": 2,
          "direction": [
            1,
            2,
            4
          ],
          "taсtType": 2
        },
        {
          "main": 0,
          "tact": 4,
          "time": 12,
          "color": "#ce6f4f",
          "phase": 2,
          "direction": [
            1,
            2,
            4
          ],
          "taсtType": 2
        },
        {
          "main": 1,
          "tact": 5,
          "time": 20,
          "color": "#00ff40",
          "phase": 3,
          "direction": [
            1,
            5,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 6,
          "time": 9,
          "color": "#ce6f4f",
          "phase": 3,
          "direction": [
            1,
            5,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 1,
          "tact": 7,
          "time": 25,
          "color": "#00ff40",
          "phase": 4,
          "direction": [
            5,
            6,
            7,
            9
          ],
          "taсtType": 2
        },
        {
          "main": 0,
          "tact": 8,
          "time": 9,
          "color": "#ce6f4f",
          "phase": 4,
          "direction": [
            5,
            6,
            7,
            9
          ],
          "taсtType": 2
        },
        {
          "main": 1,
          "tact": 9,
          "time": 28,
          "color": "#00ff40",
          "phase": 5,
          "direction": [
            3,
            8,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 10,
          "time": 9,
          "color": "#ce6f4f",
          "phase": 5,
          "direction": [
            3,
            8,
            9
          ],
          "taсtType": 1
        }
      ],
      "execTime": "ежедневно (план №8)",
      "viewItem": "нет",
      "phaseGroupNum": 8,
      "tlSignalProgram": [
        [
          {
            "stop": 59,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 84,
            "type": 1,
            "color": null,
            "start": 59,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 104,
            "type": 1,
            "color": null,
            "start": 84,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 107,
            "type": 2,
            "color": null,
            "start": 104,
            "dbgdir": 1,
            "dbgstep": 2,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 110,
            "type": 3,
            "color": null,
            "start": 107,
            "dbgdir": 1,
            "dbgstep": 3,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 113,
            "type": 5,
            "color": null,
            "start": 110,
            "dbgdir": 1,
            "dbgstep": 4,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 147,
            "type": 5,
            "color": null,
            "start": 113,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 184,
            "type": 5,
            "color": null,
            "start": 147,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 59,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 72,
            "type": 1,
            "color": null,
            "start": 59,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 75,
            "type": 2,
            "color": null,
            "start": 72,
            "dbgdir": 2,
            "dbgstep": 2,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 78,
            "type": 3,
            "color": null,
            "start": 75,
            "dbgdir": 2,
            "dbgstep": 3,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 84,
            "type": 5,
            "color": null,
            "start": 78,
            "dbgdir": 2,
            "dbgstep": 4,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 113,
            "type": 5,
            "color": null,
            "start": 84,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 147,
            "type": 5,
            "color": null,
            "start": 113,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 184,
            "type": 5,
            "color": null,
            "start": 147,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 50,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 53,
            "type": 2,
            "color": null,
            "start": 50,
            "dbgdir": 3,
            "dbgstep": 2,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 56,
            "type": 3,
            "color": null,
            "start": 53,
            "dbgdir": 3,
            "dbgstep": 3,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 59,
            "type": 5,
            "color": null,
            "start": 56,
            "dbgdir": 3,
            "dbgstep": 4,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 84,
            "type": 5,
            "color": null,
            "start": 59,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 113,
            "type": 5,
            "color": null,
            "start": 84,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 147,
            "type": 5,
            "color": null,
            "start": 113,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 184,
            "type": 1,
            "color": null,
            "start": 147,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 1
          }
        ],
        [
          {
            "stop": 59,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 72,
            "type": 1,
            "color": null,
            "start": 59,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 75,
            "type": 2,
            "color": null,
            "start": 72,
            "dbgdir": 4,
            "dbgstep": 2,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 78,
            "type": 3,
            "color": null,
            "start": 75,
            "dbgdir": 4,
            "dbgstep": 3,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 84,
            "type": 5,
            "color": null,
            "start": 78,
            "dbgdir": 4,
            "dbgstep": 4,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 113,
            "type": 5,
            "color": null,
            "start": 84,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 147,
            "type": 5,
            "color": null,
            "start": 113,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 184,
            "type": 5,
            "color": null,
            "start": 147,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 59,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 84,
            "type": 5,
            "color": null,
            "start": 59,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 113,
            "type": 1,
            "color": null,
            "start": 84,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 138,
            "type": 1,
            "color": null,
            "start": 113,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 141,
            "type": 2,
            "color": null,
            "start": 138,
            "dbgdir": 5,
            "dbgstep": 2,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 144,
            "type": 3,
            "color": null,
            "start": 141,
            "dbgdir": 5,
            "dbgstep": 3,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 147,
            "type": 5,
            "color": null,
            "start": 144,
            "dbgdir": 5,
            "dbgstep": 4,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 184,
            "type": 5,
            "color": null,
            "start": 147,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 59,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 84,
            "type": 5,
            "color": null,
            "start": 59,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 113,
            "type": 5,
            "color": null,
            "start": 84,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 138,
            "type": 1,
            "color": null,
            "start": 113,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 141,
            "type": 2,
            "color": null,
            "start": 138,
            "dbgdir": 6,
            "dbgstep": 2,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 144,
            "type": 3,
            "color": null,
            "start": 141,
            "dbgdir": 6,
            "dbgstep": 3,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 147,
            "type": 5,
            "color": null,
            "start": 144,
            "dbgdir": 6,
            "dbgstep": 4,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 184,
            "type": 5,
            "color": null,
            "start": 147,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 59,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 84,
            "type": 5,
            "color": null,
            "start": 59,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 113,
            "type": 5,
            "color": null,
            "start": 84,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 138,
            "type": 1,
            "color": null,
            "start": 113,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 141,
            "type": 2,
            "color": null,
            "start": 138,
            "dbgdir": 7,
            "dbgstep": 2,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 144,
            "type": 3,
            "color": null,
            "start": 141,
            "dbgdir": 7,
            "dbgstep": 3,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 147,
            "type": 5,
            "color": null,
            "start": 144,
            "dbgdir": 7,
            "dbgstep": 4,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 184,
            "type": 5,
            "color": null,
            "start": 147,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 59,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 84,
            "type": 5,
            "color": null,
            "start": 59,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 113,
            "type": 5,
            "color": null,
            "start": 84,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 147,
            "type": 5,
            "color": null,
            "start": 113,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 175,
            "type": 1,
            "color": null,
            "start": 147,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 178,
            "type": 2,
            "color": null,
            "start": 175,
            "dbgdir": 8,
            "dbgstep": 2,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 181,
            "type": 3,
            "color": null,
            "start": 178,
            "dbgdir": 8,
            "dbgstep": 3,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 184,
            "type": 5,
            "color": null,
            "start": 181,
            "dbgdir": 8,
            "dbgstep": 4,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 0
          }
        ],
        [
          {
            "stop": 50,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 53,
            "type": 2,
            "color": null,
            "start": 50,
            "dbgdir": 9,
            "dbgstep": 2,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 56,
            "type": 3,
            "color": null,
            "start": 53,
            "dbgdir": 9,
            "dbgstep": 3,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 59,
            "type": 5,
            "color": null,
            "start": 56,
            "dbgdir": 9,
            "dbgstep": 4,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 84,
            "type": 5,
            "color": null,
            "start": 59,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 113,
            "type": 1,
            "color": null,
            "start": 84,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 147,
            "type": 1,
            "color": null,
            "start": 113,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 184,
            "type": 1,
            "color": null,
            "start": 147,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 1
          }
        ]
      ],
      "tlSignalProgram2": [
        {
          "data": [
            {
              "stop": 59,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 84,
              "type": 1,
              "color": null,
              "start": 59,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 104,
              "type": 1,
              "color": null,
              "start": 84,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 107,
              "type": 2,
              "color": null,
              "start": 104,
              "dbgdir": 1,
              "dbgstep": 2,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 110,
              "type": 3,
              "color": null,
              "start": 107,
              "dbgdir": 1,
              "dbgstep": 3,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 113,
              "type": 5,
              "color": null,
              "start": 110,
              "dbgdir": 1,
              "dbgstep": 4,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 147,
              "type": 5,
              "color": null,
              "start": 113,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 184,
              "type": 5,
              "color": null,
              "start": 147,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 1,
          "viewName": "H.1",
          "phaseGroupNum": 8
        },
        {
          "data": [
            {
              "stop": 59,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 72,
              "type": 1,
              "color": null,
              "start": 59,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 75,
              "type": 2,
              "color": null,
              "start": 72,
              "dbgdir": 2,
              "dbgstep": 2,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 78,
              "type": 3,
              "color": null,
              "start": 75,
              "dbgdir": 2,
              "dbgstep": 3,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 84,
              "type": 5,
              "color": null,
              "start": 78,
              "dbgdir": 2,
              "dbgstep": 4,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 113,
              "type": 5,
              "color": null,
              "start": 84,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 147,
              "type": 5,
              "color": null,
              "start": 113,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 184,
              "type": 5,
              "color": null,
              "start": 147,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 2,
          "viewName": "H.2",
          "phaseGroupNum": 8
        },
        {
          "data": [
            {
              "stop": 50,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 53,
              "type": 2,
              "color": null,
              "start": 50,
              "dbgdir": 3,
              "dbgstep": 2,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 56,
              "type": 3,
              "color": null,
              "start": 53,
              "dbgdir": 3,
              "dbgstep": 3,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 59,
              "type": 5,
              "color": null,
              "start": 56,
              "dbgdir": 3,
              "dbgstep": 4,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 84,
              "type": 5,
              "color": null,
              "start": 59,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 113,
              "type": 5,
              "color": null,
              "start": 84,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 147,
              "type": 5,
              "color": null,
              "start": 113,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 184,
              "type": 1,
              "color": null,
              "start": 147,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 1
            }
          ],
          "dirNum": 3,
          "viewName": "H.3",
          "phaseGroupNum": 8
        },
        {
          "data": [
            {
              "stop": 59,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 72,
              "type": 1,
              "color": null,
              "start": 59,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 75,
              "type": 2,
              "color": null,
              "start": 72,
              "dbgdir": 4,
              "dbgstep": 2,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 78,
              "type": 3,
              "color": null,
              "start": 75,
              "dbgdir": 4,
              "dbgstep": 3,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 84,
              "type": 5,
              "color": null,
              "start": 78,
              "dbgdir": 4,
              "dbgstep": 4,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 113,
              "type": 5,
              "color": null,
              "start": 84,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 147,
              "type": 5,
              "color": null,
              "start": 113,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 184,
              "type": 5,
              "color": null,
              "start": 147,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 4,
          "viewName": "H.4",
          "phaseGroupNum": 8
        },
        {
          "data": [
            {
              "stop": 59,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 84,
              "type": 5,
              "color": null,
              "start": 59,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 113,
              "type": 1,
              "color": null,
              "start": 84,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 138,
              "type": 1,
              "color": null,
              "start": 113,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 141,
              "type": 2,
              "color": null,
              "start": 138,
              "dbgdir": 5,
              "dbgstep": 2,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 144,
              "type": 3,
              "color": null,
              "start": 141,
              "dbgdir": 5,
              "dbgstep": 3,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 147,
              "type": 5,
              "color": null,
              "start": 144,
              "dbgdir": 5,
              "dbgstep": 4,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 184,
              "type": 5,
              "color": null,
              "start": 147,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 5,
          "viewName": "H.5",
          "phaseGroupNum": 8
        },
        {
          "data": [
            {
              "stop": 59,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 84,
              "type": 5,
              "color": null,
              "start": 59,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 113,
              "type": 5,
              "color": null,
              "start": 84,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 138,
              "type": 1,
              "color": null,
              "start": 113,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 141,
              "type": 2,
              "color": null,
              "start": 138,
              "dbgdir": 6,
              "dbgstep": 2,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 144,
              "type": 3,
              "color": null,
              "start": 141,
              "dbgdir": 6,
              "dbgstep": 3,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 147,
              "type": 5,
              "color": null,
              "start": 144,
              "dbgdir": 6,
              "dbgstep": 4,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 184,
              "type": 5,
              "color": null,
              "start": 147,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 6,
          "viewName": "H.6",
          "phaseGroupNum": 8
        },
        {
          "data": [
            {
              "stop": 59,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 84,
              "type": 5,
              "color": null,
              "start": 59,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 113,
              "type": 5,
              "color": null,
              "start": 84,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 138,
              "type": 1,
              "color": null,
              "start": 113,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 141,
              "type": 2,
              "color": null,
              "start": 138,
              "dbgdir": 7,
              "dbgstep": 2,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 144,
              "type": 3,
              "color": null,
              "start": 141,
              "dbgdir": 7,
              "dbgstep": 3,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 147,
              "type": 5,
              "color": null,
              "start": 144,
              "dbgdir": 7,
              "dbgstep": 4,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 184,
              "type": 5,
              "color": null,
              "start": 147,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 7,
          "viewName": "H.7",
          "phaseGroupNum": 8
        },
        {
          "data": [
            {
              "stop": 59,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 84,
              "type": 5,
              "color": null,
              "start": 59,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 113,
              "type": 5,
              "color": null,
              "start": 84,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 147,
              "type": 5,
              "color": null,
              "start": 113,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 175,
              "type": 1,
              "color": null,
              "start": 147,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 178,
              "type": 2,
              "color": null,
              "start": 175,
              "dbgdir": 8,
              "dbgstep": 2,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 181,
              "type": 3,
              "color": null,
              "start": 178,
              "dbgdir": 8,
              "dbgstep": 3,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 184,
              "type": 5,
              "color": null,
              "start": 181,
              "dbgdir": 8,
              "dbgstep": 4,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 0
            }
          ],
          "dirNum": 8,
          "viewName": "H.8",
          "phaseGroupNum": 8
        },
        {
          "data": [
            {
              "stop": 50,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 53,
              "type": 2,
              "color": null,
              "start": 50,
              "dbgdir": 9,
              "dbgstep": 2,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 56,
              "type": 3,
              "color": null,
              "start": 53,
              "dbgdir": 9,
              "dbgstep": 3,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 59,
              "type": 5,
              "color": null,
              "start": 56,
              "dbgdir": 9,
              "dbgstep": 4,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 84,
              "type": 5,
              "color": null,
              "start": 59,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 113,
              "type": 1,
              "color": null,
              "start": 84,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 147,
              "type": 1,
              "color": null,
              "start": 113,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 184,
              "type": 1,
              "color": null,
              "start": 147,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 1
            }
          ],
          "dirNum": 9,
          "viewName": "H.9",
          "phaseGroupNum": 8
        }
      ]
    },
    {
      "addInfo": null,
      "tlTacts": [
        {
          "main": 1,
          "tact": 1,
          "time": 50,
          "color": "#00ff40",
          "phase": 1,
          "direction": [
            1,
            2,
            3,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 2,
          "time": 9,
          "color": "#ce6f4f",
          "phase": 1,
          "direction": [
            1,
            2,
            3,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 1,
          "tact": 3,
          "time": 13,
          "color": "#00ff40",
          "phase": 2,
          "direction": [
            1,
            2,
            4
          ],
          "taсtType": 2
        },
        {
          "main": 0,
          "tact": 4,
          "time": 12,
          "color": "#ce6f4f",
          "phase": 2,
          "direction": [
            1,
            2,
            4
          ],
          "taсtType": 2
        },
        {
          "main": 1,
          "tact": 5,
          "time": 20,
          "color": "#00ff40",
          "phase": 3,
          "direction": [
            1,
            5,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 6,
          "time": 9,
          "color": "#ce6f4f",
          "phase": 3,
          "direction": [
            1,
            5,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 1,
          "tact": 7,
          "time": 25,
          "color": "#00ff40",
          "phase": 4,
          "direction": [
            5,
            6,
            7,
            9
          ],
          "taсtType": 2
        },
        {
          "main": 0,
          "tact": 8,
          "time": 9,
          "color": "#ce6f4f",
          "phase": 4,
          "direction": [
            5,
            6,
            7,
            9
          ],
          "taсtType": 2
        },
        {
          "main": 1,
          "tact": 9,
          "time": 37,
          "color": "#00ff40",
          "phase": 5,
          "direction": [
            3,
            8,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 10,
          "time": 9,
          "color": "#ce6f4f",
          "phase": 5,
          "direction": [
            3,
            8,
            9
          ],
          "taсtType": 1
        }
      ],
      "execTime": "ежедневно (план №9)",
      "viewItem": "нет",
      "phaseGroupNum": 9,
      "tlSignalProgram": [
        [
          {
            "stop": 59,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 84,
            "type": 1,
            "color": null,
            "start": 59,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 104,
            "type": 1,
            "color": null,
            "start": 84,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 107,
            "type": 2,
            "color": null,
            "start": 104,
            "dbgdir": 1,
            "dbgstep": 2,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 110,
            "type": 3,
            "color": null,
            "start": 107,
            "dbgdir": 1,
            "dbgstep": 3,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 113,
            "type": 5,
            "color": null,
            "start": 110,
            "dbgdir": 1,
            "dbgstep": 4,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 147,
            "type": 5,
            "color": null,
            "start": 113,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 193,
            "type": 5,
            "color": null,
            "start": 147,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 59,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 72,
            "type": 1,
            "color": null,
            "start": 59,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 75,
            "type": 2,
            "color": null,
            "start": 72,
            "dbgdir": 2,
            "dbgstep": 2,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 78,
            "type": 3,
            "color": null,
            "start": 75,
            "dbgdir": 2,
            "dbgstep": 3,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 84,
            "type": 5,
            "color": null,
            "start": 78,
            "dbgdir": 2,
            "dbgstep": 4,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 113,
            "type": 5,
            "color": null,
            "start": 84,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 147,
            "type": 5,
            "color": null,
            "start": 113,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 193,
            "type": 5,
            "color": null,
            "start": 147,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 50,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 53,
            "type": 2,
            "color": null,
            "start": 50,
            "dbgdir": 3,
            "dbgstep": 2,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 56,
            "type": 3,
            "color": null,
            "start": 53,
            "dbgdir": 3,
            "dbgstep": 3,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 59,
            "type": 5,
            "color": null,
            "start": 56,
            "dbgdir": 3,
            "dbgstep": 4,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 84,
            "type": 5,
            "color": null,
            "start": 59,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 113,
            "type": 5,
            "color": null,
            "start": 84,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 147,
            "type": 5,
            "color": null,
            "start": 113,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 193,
            "type": 1,
            "color": null,
            "start": 147,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 1
          }
        ],
        [
          {
            "stop": 59,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 72,
            "type": 1,
            "color": null,
            "start": 59,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 75,
            "type": 2,
            "color": null,
            "start": 72,
            "dbgdir": 4,
            "dbgstep": 2,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 78,
            "type": 3,
            "color": null,
            "start": 75,
            "dbgdir": 4,
            "dbgstep": 3,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 84,
            "type": 5,
            "color": null,
            "start": 78,
            "dbgdir": 4,
            "dbgstep": 4,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 113,
            "type": 5,
            "color": null,
            "start": 84,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 147,
            "type": 5,
            "color": null,
            "start": 113,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 193,
            "type": 5,
            "color": null,
            "start": 147,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 59,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 84,
            "type": 5,
            "color": null,
            "start": 59,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 113,
            "type": 1,
            "color": null,
            "start": 84,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 138,
            "type": 1,
            "color": null,
            "start": 113,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 141,
            "type": 2,
            "color": null,
            "start": 138,
            "dbgdir": 5,
            "dbgstep": 2,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 144,
            "type": 3,
            "color": null,
            "start": 141,
            "dbgdir": 5,
            "dbgstep": 3,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 147,
            "type": 5,
            "color": null,
            "start": 144,
            "dbgdir": 5,
            "dbgstep": 4,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 193,
            "type": 5,
            "color": null,
            "start": 147,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 59,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 84,
            "type": 5,
            "color": null,
            "start": 59,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 113,
            "type": 5,
            "color": null,
            "start": 84,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 138,
            "type": 1,
            "color": null,
            "start": 113,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 141,
            "type": 2,
            "color": null,
            "start": 138,
            "dbgdir": 6,
            "dbgstep": 2,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 144,
            "type": 3,
            "color": null,
            "start": 141,
            "dbgdir": 6,
            "dbgstep": 3,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 147,
            "type": 5,
            "color": null,
            "start": 144,
            "dbgdir": 6,
            "dbgstep": 4,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 193,
            "type": 5,
            "color": null,
            "start": 147,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 59,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 84,
            "type": 5,
            "color": null,
            "start": 59,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 113,
            "type": 5,
            "color": null,
            "start": 84,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 138,
            "type": 1,
            "color": null,
            "start": 113,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 141,
            "type": 2,
            "color": null,
            "start": 138,
            "dbgdir": 7,
            "dbgstep": 2,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 144,
            "type": 3,
            "color": null,
            "start": 141,
            "dbgdir": 7,
            "dbgstep": 3,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 147,
            "type": 5,
            "color": null,
            "start": 144,
            "dbgdir": 7,
            "dbgstep": 4,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 193,
            "type": 5,
            "color": null,
            "start": 147,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 59,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 84,
            "type": 5,
            "color": null,
            "start": 59,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 113,
            "type": 5,
            "color": null,
            "start": 84,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 147,
            "type": 5,
            "color": null,
            "start": 113,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 184,
            "type": 1,
            "color": null,
            "start": 147,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 187,
            "type": 2,
            "color": null,
            "start": 184,
            "dbgdir": 8,
            "dbgstep": 2,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 190,
            "type": 3,
            "color": null,
            "start": 187,
            "dbgdir": 8,
            "dbgstep": 3,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 193,
            "type": 5,
            "color": null,
            "start": 190,
            "dbgdir": 8,
            "dbgstep": 4,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 0
          }
        ],
        [
          {
            "stop": 50,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 53,
            "type": 2,
            "color": null,
            "start": 50,
            "dbgdir": 9,
            "dbgstep": 2,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 56,
            "type": 3,
            "color": null,
            "start": 53,
            "dbgdir": 9,
            "dbgstep": 3,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 59,
            "type": 5,
            "color": null,
            "start": 56,
            "dbgdir": 9,
            "dbgstep": 4,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 84,
            "type": 5,
            "color": null,
            "start": 59,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 113,
            "type": 1,
            "color": null,
            "start": 84,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 147,
            "type": 1,
            "color": null,
            "start": 113,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 193,
            "type": 1,
            "color": null,
            "start": 147,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 1
          }
        ]
      ],
      "tlSignalProgram2": [
        {
          "data": [
            {
              "stop": 59,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 84,
              "type": 1,
              "color": null,
              "start": 59,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 104,
              "type": 1,
              "color": null,
              "start": 84,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 107,
              "type": 2,
              "color": null,
              "start": 104,
              "dbgdir": 1,
              "dbgstep": 2,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 110,
              "type": 3,
              "color": null,
              "start": 107,
              "dbgdir": 1,
              "dbgstep": 3,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 113,
              "type": 5,
              "color": null,
              "start": 110,
              "dbgdir": 1,
              "dbgstep": 4,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 147,
              "type": 5,
              "color": null,
              "start": 113,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 193,
              "type": 5,
              "color": null,
              "start": 147,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 1,
          "viewName": "H.1",
          "phaseGroupNum": 9
        },
        {
          "data": [
            {
              "stop": 59,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 72,
              "type": 1,
              "color": null,
              "start": 59,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 75,
              "type": 2,
              "color": null,
              "start": 72,
              "dbgdir": 2,
              "dbgstep": 2,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 78,
              "type": 3,
              "color": null,
              "start": 75,
              "dbgdir": 2,
              "dbgstep": 3,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 84,
              "type": 5,
              "color": null,
              "start": 78,
              "dbgdir": 2,
              "dbgstep": 4,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 113,
              "type": 5,
              "color": null,
              "start": 84,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 147,
              "type": 5,
              "color": null,
              "start": 113,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 193,
              "type": 5,
              "color": null,
              "start": 147,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 2,
          "viewName": "H.2",
          "phaseGroupNum": 9
        },
        {
          "data": [
            {
              "stop": 50,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 53,
              "type": 2,
              "color": null,
              "start": 50,
              "dbgdir": 3,
              "dbgstep": 2,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 56,
              "type": 3,
              "color": null,
              "start": 53,
              "dbgdir": 3,
              "dbgstep": 3,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 59,
              "type": 5,
              "color": null,
              "start": 56,
              "dbgdir": 3,
              "dbgstep": 4,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 84,
              "type": 5,
              "color": null,
              "start": 59,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 113,
              "type": 5,
              "color": null,
              "start": 84,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 147,
              "type": 5,
              "color": null,
              "start": 113,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 193,
              "type": 1,
              "color": null,
              "start": 147,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 1
            }
          ],
          "dirNum": 3,
          "viewName": "H.3",
          "phaseGroupNum": 9
        },
        {
          "data": [
            {
              "stop": 59,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 72,
              "type": 1,
              "color": null,
              "start": 59,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 75,
              "type": 2,
              "color": null,
              "start": 72,
              "dbgdir": 4,
              "dbgstep": 2,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 78,
              "type": 3,
              "color": null,
              "start": 75,
              "dbgdir": 4,
              "dbgstep": 3,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 84,
              "type": 5,
              "color": null,
              "start": 78,
              "dbgdir": 4,
              "dbgstep": 4,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 113,
              "type": 5,
              "color": null,
              "start": 84,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 147,
              "type": 5,
              "color": null,
              "start": 113,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 193,
              "type": 5,
              "color": null,
              "start": 147,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 4,
          "viewName": "H.4",
          "phaseGroupNum": 9
        },
        {
          "data": [
            {
              "stop": 59,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 84,
              "type": 5,
              "color": null,
              "start": 59,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 113,
              "type": 1,
              "color": null,
              "start": 84,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 138,
              "type": 1,
              "color": null,
              "start": 113,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 141,
              "type": 2,
              "color": null,
              "start": 138,
              "dbgdir": 5,
              "dbgstep": 2,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 144,
              "type": 3,
              "color": null,
              "start": 141,
              "dbgdir": 5,
              "dbgstep": 3,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 147,
              "type": 5,
              "color": null,
              "start": 144,
              "dbgdir": 5,
              "dbgstep": 4,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 193,
              "type": 5,
              "color": null,
              "start": 147,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 5,
          "viewName": "H.5",
          "phaseGroupNum": 9
        },
        {
          "data": [
            {
              "stop": 59,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 84,
              "type": 5,
              "color": null,
              "start": 59,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 113,
              "type": 5,
              "color": null,
              "start": 84,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 138,
              "type": 1,
              "color": null,
              "start": 113,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 141,
              "type": 2,
              "color": null,
              "start": 138,
              "dbgdir": 6,
              "dbgstep": 2,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 144,
              "type": 3,
              "color": null,
              "start": 141,
              "dbgdir": 6,
              "dbgstep": 3,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 147,
              "type": 5,
              "color": null,
              "start": 144,
              "dbgdir": 6,
              "dbgstep": 4,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 193,
              "type": 5,
              "color": null,
              "start": 147,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 6,
          "viewName": "H.6",
          "phaseGroupNum": 9
        },
        {
          "data": [
            {
              "stop": 59,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 84,
              "type": 5,
              "color": null,
              "start": 59,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 113,
              "type": 5,
              "color": null,
              "start": 84,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 138,
              "type": 1,
              "color": null,
              "start": 113,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 141,
              "type": 2,
              "color": null,
              "start": 138,
              "dbgdir": 7,
              "dbgstep": 2,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 144,
              "type": 3,
              "color": null,
              "start": 141,
              "dbgdir": 7,
              "dbgstep": 3,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 147,
              "type": 5,
              "color": null,
              "start": 144,
              "dbgdir": 7,
              "dbgstep": 4,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 193,
              "type": 5,
              "color": null,
              "start": 147,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 7,
          "viewName": "H.7",
          "phaseGroupNum": 9
        },
        {
          "data": [
            {
              "stop": 59,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 84,
              "type": 5,
              "color": null,
              "start": 59,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 113,
              "type": 5,
              "color": null,
              "start": 84,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 147,
              "type": 5,
              "color": null,
              "start": 113,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 184,
              "type": 1,
              "color": null,
              "start": 147,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 187,
              "type": 2,
              "color": null,
              "start": 184,
              "dbgdir": 8,
              "dbgstep": 2,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 190,
              "type": 3,
              "color": null,
              "start": 187,
              "dbgdir": 8,
              "dbgstep": 3,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 193,
              "type": 5,
              "color": null,
              "start": 190,
              "dbgdir": 8,
              "dbgstep": 4,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 0
            }
          ],
          "dirNum": 8,
          "viewName": "H.8",
          "phaseGroupNum": 9
        },
        {
          "data": [
            {
              "stop": 50,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 53,
              "type": 2,
              "color": null,
              "start": 50,
              "dbgdir": 9,
              "dbgstep": 2,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 56,
              "type": 3,
              "color": null,
              "start": 53,
              "dbgdir": 9,
              "dbgstep": 3,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 59,
              "type": 5,
              "color": null,
              "start": 56,
              "dbgdir": 9,
              "dbgstep": 4,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 84,
              "type": 5,
              "color": null,
              "start": 59,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 113,
              "type": 1,
              "color": null,
              "start": 84,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 147,
              "type": 1,
              "color": null,
              "start": 113,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 193,
              "type": 1,
              "color": null,
              "start": 147,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 1
            }
          ],
          "dirNum": 9,
          "viewName": "H.9",
          "phaseGroupNum": 9
        }
      ]
    },
    {
      "addInfo": null,
      "tlTacts": [
        {
          "main": 1,
          "tact": 1,
          "time": 50,
          "color": "#00ff40",
          "phase": 1,
          "direction": [
            1,
            2,
            3,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 2,
          "time": 9,
          "color": "#ce6f4f",
          "phase": 1,
          "direction": [
            1,
            2,
            3,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 1,
          "tact": 3,
          "time": 13,
          "color": "#00ff40",
          "phase": 2,
          "direction": [
            1,
            2,
            4
          ],
          "taсtType": 2
        },
        {
          "main": 0,
          "tact": 4,
          "time": 12,
          "color": "#ce6f4f",
          "phase": 2,
          "direction": [
            1,
            2,
            4
          ],
          "taсtType": 2
        },
        {
          "main": 1,
          "tact": 5,
          "time": 20,
          "color": "#00ff40",
          "phase": 3,
          "direction": [
            1,
            5,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 6,
          "time": 9,
          "color": "#ce6f4f",
          "phase": 3,
          "direction": [
            1,
            5,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 1,
          "tact": 7,
          "time": 25,
          "color": "#00ff40",
          "phase": 4,
          "direction": [
            5,
            6,
            7,
            9
          ],
          "taсtType": 2
        },
        {
          "main": 0,
          "tact": 8,
          "time": 9,
          "color": "#ce6f4f",
          "phase": 4,
          "direction": [
            5,
            6,
            7,
            9
          ],
          "taсtType": 2
        },
        {
          "main": 1,
          "tact": 9,
          "time": 55,
          "color": "#00ff40",
          "phase": 5,
          "direction": [
            3,
            8,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 10,
          "time": 9,
          "color": "#ce6f4f",
          "phase": 5,
          "direction": [
            3,
            8,
            9
          ],
          "taсtType": 1
        }
      ],
      "execTime": "ежедневно (план №10)",
      "viewItem": "нет",
      "phaseGroupNum": 10,
      "tlSignalProgram": [
        [
          {
            "stop": 59,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 84,
            "type": 1,
            "color": null,
            "start": 59,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 104,
            "type": 1,
            "color": null,
            "start": 84,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 107,
            "type": 2,
            "color": null,
            "start": 104,
            "dbgdir": 1,
            "dbgstep": 2,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 110,
            "type": 3,
            "color": null,
            "start": 107,
            "dbgdir": 1,
            "dbgstep": 3,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 113,
            "type": 5,
            "color": null,
            "start": 110,
            "dbgdir": 1,
            "dbgstep": 4,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 147,
            "type": 5,
            "color": null,
            "start": 113,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 211,
            "type": 5,
            "color": null,
            "start": 147,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 59,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 72,
            "type": 1,
            "color": null,
            "start": 59,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 75,
            "type": 2,
            "color": null,
            "start": 72,
            "dbgdir": 2,
            "dbgstep": 2,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 78,
            "type": 3,
            "color": null,
            "start": 75,
            "dbgdir": 2,
            "dbgstep": 3,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 84,
            "type": 5,
            "color": null,
            "start": 78,
            "dbgdir": 2,
            "dbgstep": 4,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 113,
            "type": 5,
            "color": null,
            "start": 84,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 147,
            "type": 5,
            "color": null,
            "start": 113,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 211,
            "type": 5,
            "color": null,
            "start": 147,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 50,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 53,
            "type": 2,
            "color": null,
            "start": 50,
            "dbgdir": 3,
            "dbgstep": 2,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 56,
            "type": 3,
            "color": null,
            "start": 53,
            "dbgdir": 3,
            "dbgstep": 3,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 59,
            "type": 5,
            "color": null,
            "start": 56,
            "dbgdir": 3,
            "dbgstep": 4,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 84,
            "type": 5,
            "color": null,
            "start": 59,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 113,
            "type": 5,
            "color": null,
            "start": 84,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 147,
            "type": 5,
            "color": null,
            "start": 113,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 211,
            "type": 1,
            "color": null,
            "start": 147,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 1
          }
        ],
        [
          {
            "stop": 59,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 72,
            "type": 1,
            "color": null,
            "start": 59,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 75,
            "type": 2,
            "color": null,
            "start": 72,
            "dbgdir": 4,
            "dbgstep": 2,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 78,
            "type": 3,
            "color": null,
            "start": 75,
            "dbgdir": 4,
            "dbgstep": 3,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 84,
            "type": 5,
            "color": null,
            "start": 78,
            "dbgdir": 4,
            "dbgstep": 4,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 113,
            "type": 5,
            "color": null,
            "start": 84,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 147,
            "type": 5,
            "color": null,
            "start": 113,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 211,
            "type": 5,
            "color": null,
            "start": 147,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 59,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 84,
            "type": 5,
            "color": null,
            "start": 59,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 113,
            "type": 1,
            "color": null,
            "start": 84,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 138,
            "type": 1,
            "color": null,
            "start": 113,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 141,
            "type": 2,
            "color": null,
            "start": 138,
            "dbgdir": 5,
            "dbgstep": 2,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 144,
            "type": 3,
            "color": null,
            "start": 141,
            "dbgdir": 5,
            "dbgstep": 3,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 147,
            "type": 5,
            "color": null,
            "start": 144,
            "dbgdir": 5,
            "dbgstep": 4,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 211,
            "type": 5,
            "color": null,
            "start": 147,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 59,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 84,
            "type": 5,
            "color": null,
            "start": 59,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 113,
            "type": 5,
            "color": null,
            "start": 84,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 138,
            "type": 1,
            "color": null,
            "start": 113,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 141,
            "type": 2,
            "color": null,
            "start": 138,
            "dbgdir": 6,
            "dbgstep": 2,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 144,
            "type": 3,
            "color": null,
            "start": 141,
            "dbgdir": 6,
            "dbgstep": 3,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 147,
            "type": 5,
            "color": null,
            "start": 144,
            "dbgdir": 6,
            "dbgstep": 4,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 211,
            "type": 5,
            "color": null,
            "start": 147,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 59,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 84,
            "type": 5,
            "color": null,
            "start": 59,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 113,
            "type": 5,
            "color": null,
            "start": 84,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 138,
            "type": 1,
            "color": null,
            "start": 113,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 141,
            "type": 2,
            "color": null,
            "start": 138,
            "dbgdir": 7,
            "dbgstep": 2,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 144,
            "type": 3,
            "color": null,
            "start": 141,
            "dbgdir": 7,
            "dbgstep": 3,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 147,
            "type": 5,
            "color": null,
            "start": 144,
            "dbgdir": 7,
            "dbgstep": 4,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 211,
            "type": 5,
            "color": null,
            "start": 147,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 59,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 84,
            "type": 5,
            "color": null,
            "start": 59,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 113,
            "type": 5,
            "color": null,
            "start": 84,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 147,
            "type": 5,
            "color": null,
            "start": 113,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 202,
            "type": 1,
            "color": null,
            "start": 147,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 205,
            "type": 2,
            "color": null,
            "start": 202,
            "dbgdir": 8,
            "dbgstep": 2,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 208,
            "type": 3,
            "color": null,
            "start": 205,
            "dbgdir": 8,
            "dbgstep": 3,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 211,
            "type": 5,
            "color": null,
            "start": 208,
            "dbgdir": 8,
            "dbgstep": 4,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 0
          }
        ],
        [
          {
            "stop": 50,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 53,
            "type": 2,
            "color": null,
            "start": 50,
            "dbgdir": 9,
            "dbgstep": 2,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 56,
            "type": 3,
            "color": null,
            "start": 53,
            "dbgdir": 9,
            "dbgstep": 3,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 59,
            "type": 5,
            "color": null,
            "start": 56,
            "dbgdir": 9,
            "dbgstep": 4,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 84,
            "type": 5,
            "color": null,
            "start": 59,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 113,
            "type": 1,
            "color": null,
            "start": 84,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 147,
            "type": 1,
            "color": null,
            "start": 113,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 211,
            "type": 1,
            "color": null,
            "start": 147,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 1
          }
        ]
      ],
      "tlSignalProgram2": [
        {
          "data": [
            {
              "stop": 59,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 84,
              "type": 1,
              "color": null,
              "start": 59,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 104,
              "type": 1,
              "color": null,
              "start": 84,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 107,
              "type": 2,
              "color": null,
              "start": 104,
              "dbgdir": 1,
              "dbgstep": 2,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 110,
              "type": 3,
              "color": null,
              "start": 107,
              "dbgdir": 1,
              "dbgstep": 3,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 113,
              "type": 5,
              "color": null,
              "start": 110,
              "dbgdir": 1,
              "dbgstep": 4,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 147,
              "type": 5,
              "color": null,
              "start": 113,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 211,
              "type": 5,
              "color": null,
              "start": 147,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 1,
          "viewName": "H.1",
          "phaseGroupNum": 10
        },
        {
          "data": [
            {
              "stop": 59,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 72,
              "type": 1,
              "color": null,
              "start": 59,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 75,
              "type": 2,
              "color": null,
              "start": 72,
              "dbgdir": 2,
              "dbgstep": 2,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 78,
              "type": 3,
              "color": null,
              "start": 75,
              "dbgdir": 2,
              "dbgstep": 3,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 84,
              "type": 5,
              "color": null,
              "start": 78,
              "dbgdir": 2,
              "dbgstep": 4,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 113,
              "type": 5,
              "color": null,
              "start": 84,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 147,
              "type": 5,
              "color": null,
              "start": 113,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 211,
              "type": 5,
              "color": null,
              "start": 147,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 2,
          "viewName": "H.2",
          "phaseGroupNum": 10
        },
        {
          "data": [
            {
              "stop": 50,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 53,
              "type": 2,
              "color": null,
              "start": 50,
              "dbgdir": 3,
              "dbgstep": 2,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 56,
              "type": 3,
              "color": null,
              "start": 53,
              "dbgdir": 3,
              "dbgstep": 3,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 59,
              "type": 5,
              "color": null,
              "start": 56,
              "dbgdir": 3,
              "dbgstep": 4,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 84,
              "type": 5,
              "color": null,
              "start": 59,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 113,
              "type": 5,
              "color": null,
              "start": 84,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 147,
              "type": 5,
              "color": null,
              "start": 113,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 211,
              "type": 1,
              "color": null,
              "start": 147,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 1
            }
          ],
          "dirNum": 3,
          "viewName": "H.3",
          "phaseGroupNum": 10
        },
        {
          "data": [
            {
              "stop": 59,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 72,
              "type": 1,
              "color": null,
              "start": 59,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 75,
              "type": 2,
              "color": null,
              "start": 72,
              "dbgdir": 4,
              "dbgstep": 2,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 78,
              "type": 3,
              "color": null,
              "start": 75,
              "dbgdir": 4,
              "dbgstep": 3,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 84,
              "type": 5,
              "color": null,
              "start": 78,
              "dbgdir": 4,
              "dbgstep": 4,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 113,
              "type": 5,
              "color": null,
              "start": 84,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 147,
              "type": 5,
              "color": null,
              "start": 113,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 211,
              "type": 5,
              "color": null,
              "start": 147,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 4,
          "viewName": "H.4",
          "phaseGroupNum": 10
        },
        {
          "data": [
            {
              "stop": 59,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 84,
              "type": 5,
              "color": null,
              "start": 59,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 113,
              "type": 1,
              "color": null,
              "start": 84,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 138,
              "type": 1,
              "color": null,
              "start": 113,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 141,
              "type": 2,
              "color": null,
              "start": 138,
              "dbgdir": 5,
              "dbgstep": 2,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 144,
              "type": 3,
              "color": null,
              "start": 141,
              "dbgdir": 5,
              "dbgstep": 3,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 147,
              "type": 5,
              "color": null,
              "start": 144,
              "dbgdir": 5,
              "dbgstep": 4,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 211,
              "type": 5,
              "color": null,
              "start": 147,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 5,
          "viewName": "H.5",
          "phaseGroupNum": 10
        },
        {
          "data": [
            {
              "stop": 59,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 84,
              "type": 5,
              "color": null,
              "start": 59,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 113,
              "type": 5,
              "color": null,
              "start": 84,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 138,
              "type": 1,
              "color": null,
              "start": 113,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 141,
              "type": 2,
              "color": null,
              "start": 138,
              "dbgdir": 6,
              "dbgstep": 2,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 144,
              "type": 3,
              "color": null,
              "start": 141,
              "dbgdir": 6,
              "dbgstep": 3,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 147,
              "type": 5,
              "color": null,
              "start": 144,
              "dbgdir": 6,
              "dbgstep": 4,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 211,
              "type": 5,
              "color": null,
              "start": 147,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 6,
          "viewName": "H.6",
          "phaseGroupNum": 10
        },
        {
          "data": [
            {
              "stop": 59,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 84,
              "type": 5,
              "color": null,
              "start": 59,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 113,
              "type": 5,
              "color": null,
              "start": 84,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 138,
              "type": 1,
              "color": null,
              "start": 113,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 141,
              "type": 2,
              "color": null,
              "start": 138,
              "dbgdir": 7,
              "dbgstep": 2,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 144,
              "type": 3,
              "color": null,
              "start": 141,
              "dbgdir": 7,
              "dbgstep": 3,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 147,
              "type": 5,
              "color": null,
              "start": 144,
              "dbgdir": 7,
              "dbgstep": 4,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 211,
              "type": 5,
              "color": null,
              "start": 147,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 7,
          "viewName": "H.7",
          "phaseGroupNum": 10
        },
        {
          "data": [
            {
              "stop": 59,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 84,
              "type": 5,
              "color": null,
              "start": 59,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 113,
              "type": 5,
              "color": null,
              "start": 84,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 147,
              "type": 5,
              "color": null,
              "start": 113,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 202,
              "type": 1,
              "color": null,
              "start": 147,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 205,
              "type": 2,
              "color": null,
              "start": 202,
              "dbgdir": 8,
              "dbgstep": 2,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 208,
              "type": 3,
              "color": null,
              "start": 205,
              "dbgdir": 8,
              "dbgstep": 3,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 211,
              "type": 5,
              "color": null,
              "start": 208,
              "dbgdir": 8,
              "dbgstep": 4,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 0
            }
          ],
          "dirNum": 8,
          "viewName": "H.8",
          "phaseGroupNum": 10
        },
        {
          "data": [
            {
              "stop": 50,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 53,
              "type": 2,
              "color": null,
              "start": 50,
              "dbgdir": 9,
              "dbgstep": 2,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 56,
              "type": 3,
              "color": null,
              "start": 53,
              "dbgdir": 9,
              "dbgstep": 3,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 59,
              "type": 5,
              "color": null,
              "start": 56,
              "dbgdir": 9,
              "dbgstep": 4,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 84,
              "type": 5,
              "color": null,
              "start": 59,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 113,
              "type": 1,
              "color": null,
              "start": 84,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 147,
              "type": 1,
              "color": null,
              "start": 113,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 211,
              "type": 1,
              "color": null,
              "start": 147,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 1
            }
          ],
          "dirNum": 9,
          "viewName": "H.9",
          "phaseGroupNum": 10
        }
      ]
    },
    {
      "addInfo": null,
      "tlTacts": [
        {
          "main": 1,
          "tact": 1,
          "time": 58,
          "color": "#00ff40",
          "phase": 1,
          "direction": [
            1,
            2,
            3,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 2,
          "time": 9,
          "color": "#ce6f4f",
          "phase": 1,
          "direction": [
            1,
            2,
            3,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 1,
          "tact": 3,
          "time": 13,
          "color": "#00ff40",
          "phase": 2,
          "direction": [
            1,
            2,
            4
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 4,
          "time": 12,
          "color": "#ce6f4f",
          "phase": 2,
          "direction": [
            1,
            2,
            4
          ],
          "taсtType": 1
        },
        {
          "main": 1,
          "tact": 5,
          "time": 20,
          "color": "#00ff40",
          "phase": 3,
          "direction": [
            5,
            6,
            7,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 6,
          "time": 9,
          "color": "#ce6f4f",
          "phase": 3,
          "direction": [
            5,
            6,
            7,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 1,
          "tact": 7,
          "time": 40,
          "color": "#00ff40",
          "phase": 4,
          "direction": [
            3,
            8,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 8,
          "time": 9,
          "color": "#ce6f4f",
          "phase": 4,
          "direction": [
            3,
            8,
            9
          ],
          "taсtType": 1
        }
      ],
      "execTime": "ежедневно (план №11)",
      "viewItem": "нет",
      "phaseGroupNum": 11,
      "tlSignalProgram": [
        [
          {
            "stop": 67,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 80,
            "type": 1,
            "color": null,
            "start": 67,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 83,
            "type": 2,
            "color": null,
            "start": 80,
            "dbgdir": 1,
            "dbgstep": 2,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 86,
            "type": 3,
            "color": null,
            "start": 83,
            "dbgdir": 1,
            "dbgstep": 3,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 92,
            "type": 5,
            "color": null,
            "start": 86,
            "dbgdir": 1,
            "dbgstep": 4,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 121,
            "type": 5,
            "color": null,
            "start": 92,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 170,
            "type": 5,
            "color": null,
            "start": 121,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 67,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 80,
            "type": 1,
            "color": null,
            "start": 67,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 83,
            "type": 2,
            "color": null,
            "start": 80,
            "dbgdir": 2,
            "dbgstep": 2,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 86,
            "type": 3,
            "color": null,
            "start": 83,
            "dbgdir": 2,
            "dbgstep": 3,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 92,
            "type": 5,
            "color": null,
            "start": 86,
            "dbgdir": 2,
            "dbgstep": 4,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 121,
            "type": 5,
            "color": null,
            "start": 92,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 170,
            "type": 5,
            "color": null,
            "start": 121,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 58,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 61,
            "type": 2,
            "color": null,
            "start": 58,
            "dbgdir": 3,
            "dbgstep": 2,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 64,
            "type": 3,
            "color": null,
            "start": 61,
            "dbgdir": 3,
            "dbgstep": 3,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 67,
            "type": 5,
            "color": null,
            "start": 64,
            "dbgdir": 3,
            "dbgstep": 4,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 92,
            "type": 5,
            "color": null,
            "start": 67,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 121,
            "type": 5,
            "color": null,
            "start": 92,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 170,
            "type": 1,
            "color": null,
            "start": 121,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 1
          }
        ],
        [
          {
            "stop": 67,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 80,
            "type": 1,
            "color": null,
            "start": 67,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 83,
            "type": 2,
            "color": null,
            "start": 80,
            "dbgdir": 4,
            "dbgstep": 2,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 86,
            "type": 3,
            "color": null,
            "start": 83,
            "dbgdir": 4,
            "dbgstep": 3,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 92,
            "type": 5,
            "color": null,
            "start": 86,
            "dbgdir": 4,
            "dbgstep": 4,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 121,
            "type": 5,
            "color": null,
            "start": 92,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 170,
            "type": 5,
            "color": null,
            "start": 121,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 67,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 92,
            "type": 5,
            "color": null,
            "start": 67,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 112,
            "type": 1,
            "color": null,
            "start": 92,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 115,
            "type": 2,
            "color": null,
            "start": 112,
            "dbgdir": 5,
            "dbgstep": 2,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 118,
            "type": 3,
            "color": null,
            "start": 115,
            "dbgdir": 5,
            "dbgstep": 3,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 121,
            "type": 5,
            "color": null,
            "start": 118,
            "dbgdir": 5,
            "dbgstep": 4,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 170,
            "type": 5,
            "color": null,
            "start": 121,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 67,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 92,
            "type": 5,
            "color": null,
            "start": 67,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 112,
            "type": 1,
            "color": null,
            "start": 92,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 115,
            "type": 2,
            "color": null,
            "start": 112,
            "dbgdir": 6,
            "dbgstep": 2,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 118,
            "type": 3,
            "color": null,
            "start": 115,
            "dbgdir": 6,
            "dbgstep": 3,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 121,
            "type": 5,
            "color": null,
            "start": 118,
            "dbgdir": 6,
            "dbgstep": 4,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 170,
            "type": 5,
            "color": null,
            "start": 121,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 67,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 92,
            "type": 5,
            "color": null,
            "start": 67,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 112,
            "type": 1,
            "color": null,
            "start": 92,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 115,
            "type": 2,
            "color": null,
            "start": 112,
            "dbgdir": 7,
            "dbgstep": 2,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 118,
            "type": 3,
            "color": null,
            "start": 115,
            "dbgdir": 7,
            "dbgstep": 3,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 121,
            "type": 5,
            "color": null,
            "start": 118,
            "dbgdir": 7,
            "dbgstep": 4,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 170,
            "type": 5,
            "color": null,
            "start": 121,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 67,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 92,
            "type": 5,
            "color": null,
            "start": 67,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 121,
            "type": 5,
            "color": null,
            "start": 92,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 161,
            "type": 1,
            "color": null,
            "start": 121,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 164,
            "type": 2,
            "color": null,
            "start": 161,
            "dbgdir": 8,
            "dbgstep": 2,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 167,
            "type": 3,
            "color": null,
            "start": 164,
            "dbgdir": 8,
            "dbgstep": 3,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 170,
            "type": 5,
            "color": null,
            "start": 167,
            "dbgdir": 8,
            "dbgstep": 4,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          }
        ],
        [
          {
            "stop": 58,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 61,
            "type": 2,
            "color": null,
            "start": 58,
            "dbgdir": 9,
            "dbgstep": 2,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 64,
            "type": 3,
            "color": null,
            "start": 61,
            "dbgdir": 9,
            "dbgstep": 3,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 67,
            "type": 5,
            "color": null,
            "start": 64,
            "dbgdir": 9,
            "dbgstep": 4,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 92,
            "type": 5,
            "color": null,
            "start": 67,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 121,
            "type": 1,
            "color": null,
            "start": 92,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 170,
            "type": 1,
            "color": null,
            "start": 121,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 1
          }
        ]
      ],
      "tlSignalProgram2": [
        {
          "data": [
            {
              "stop": 67,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 80,
              "type": 1,
              "color": null,
              "start": 67,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 83,
              "type": 2,
              "color": null,
              "start": 80,
              "dbgdir": 1,
              "dbgstep": 2,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 86,
              "type": 3,
              "color": null,
              "start": 83,
              "dbgdir": 1,
              "dbgstep": 3,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 92,
              "type": 5,
              "color": null,
              "start": 86,
              "dbgdir": 1,
              "dbgstep": 4,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 121,
              "type": 5,
              "color": null,
              "start": 92,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 170,
              "type": 5,
              "color": null,
              "start": 121,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 1,
          "viewName": "H.1",
          "phaseGroupNum": 11
        },
        {
          "data": [
            {
              "stop": 67,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 80,
              "type": 1,
              "color": null,
              "start": 67,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 83,
              "type": 2,
              "color": null,
              "start": 80,
              "dbgdir": 2,
              "dbgstep": 2,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 86,
              "type": 3,
              "color": null,
              "start": 83,
              "dbgdir": 2,
              "dbgstep": 3,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 92,
              "type": 5,
              "color": null,
              "start": 86,
              "dbgdir": 2,
              "dbgstep": 4,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 121,
              "type": 5,
              "color": null,
              "start": 92,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 170,
              "type": 5,
              "color": null,
              "start": 121,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 2,
          "viewName": "H.2",
          "phaseGroupNum": 11
        },
        {
          "data": [
            {
              "stop": 58,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 61,
              "type": 2,
              "color": null,
              "start": 58,
              "dbgdir": 3,
              "dbgstep": 2,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 64,
              "type": 3,
              "color": null,
              "start": 61,
              "dbgdir": 3,
              "dbgstep": 3,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 67,
              "type": 5,
              "color": null,
              "start": 64,
              "dbgdir": 3,
              "dbgstep": 4,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 92,
              "type": 5,
              "color": null,
              "start": 67,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 121,
              "type": 5,
              "color": null,
              "start": 92,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 170,
              "type": 1,
              "color": null,
              "start": 121,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 1
            }
          ],
          "dirNum": 3,
          "viewName": "H.3",
          "phaseGroupNum": 11
        },
        {
          "data": [
            {
              "stop": 67,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 80,
              "type": 1,
              "color": null,
              "start": 67,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 83,
              "type": 2,
              "color": null,
              "start": 80,
              "dbgdir": 4,
              "dbgstep": 2,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 86,
              "type": 3,
              "color": null,
              "start": 83,
              "dbgdir": 4,
              "dbgstep": 3,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 92,
              "type": 5,
              "color": null,
              "start": 86,
              "dbgdir": 4,
              "dbgstep": 4,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 121,
              "type": 5,
              "color": null,
              "start": 92,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 170,
              "type": 5,
              "color": null,
              "start": 121,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 4,
          "viewName": "H.4",
          "phaseGroupNum": 11
        },
        {
          "data": [
            {
              "stop": 67,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 92,
              "type": 5,
              "color": null,
              "start": 67,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 112,
              "type": 1,
              "color": null,
              "start": 92,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 115,
              "type": 2,
              "color": null,
              "start": 112,
              "dbgdir": 5,
              "dbgstep": 2,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 118,
              "type": 3,
              "color": null,
              "start": 115,
              "dbgdir": 5,
              "dbgstep": 3,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 121,
              "type": 5,
              "color": null,
              "start": 118,
              "dbgdir": 5,
              "dbgstep": 4,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 170,
              "type": 5,
              "color": null,
              "start": 121,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 5,
          "viewName": "H.5",
          "phaseGroupNum": 11
        },
        {
          "data": [
            {
              "stop": 67,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 92,
              "type": 5,
              "color": null,
              "start": 67,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 112,
              "type": 1,
              "color": null,
              "start": 92,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 115,
              "type": 2,
              "color": null,
              "start": 112,
              "dbgdir": 6,
              "dbgstep": 2,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 118,
              "type": 3,
              "color": null,
              "start": 115,
              "dbgdir": 6,
              "dbgstep": 3,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 121,
              "type": 5,
              "color": null,
              "start": 118,
              "dbgdir": 6,
              "dbgstep": 4,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 170,
              "type": 5,
              "color": null,
              "start": 121,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 6,
          "viewName": "H.6",
          "phaseGroupNum": 11
        },
        {
          "data": [
            {
              "stop": 67,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 92,
              "type": 5,
              "color": null,
              "start": 67,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 112,
              "type": 1,
              "color": null,
              "start": 92,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 115,
              "type": 2,
              "color": null,
              "start": 112,
              "dbgdir": 7,
              "dbgstep": 2,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 118,
              "type": 3,
              "color": null,
              "start": 115,
              "dbgdir": 7,
              "dbgstep": 3,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 121,
              "type": 5,
              "color": null,
              "start": 118,
              "dbgdir": 7,
              "dbgstep": 4,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 170,
              "type": 5,
              "color": null,
              "start": 121,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 7,
          "viewName": "H.7",
          "phaseGroupNum": 11
        },
        {
          "data": [
            {
              "stop": 67,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 92,
              "type": 5,
              "color": null,
              "start": 67,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 121,
              "type": 5,
              "color": null,
              "start": 92,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 161,
              "type": 1,
              "color": null,
              "start": 121,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 164,
              "type": 2,
              "color": null,
              "start": 161,
              "dbgdir": 8,
              "dbgstep": 2,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 167,
              "type": 3,
              "color": null,
              "start": 164,
              "dbgdir": 8,
              "dbgstep": 3,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 170,
              "type": 5,
              "color": null,
              "start": 167,
              "dbgdir": 8,
              "dbgstep": 4,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            }
          ],
          "dirNum": 8,
          "viewName": "H.8",
          "phaseGroupNum": 11
        },
        {
          "data": [
            {
              "stop": 58,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 61,
              "type": 2,
              "color": null,
              "start": 58,
              "dbgdir": 9,
              "dbgstep": 2,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 64,
              "type": 3,
              "color": null,
              "start": 61,
              "dbgdir": 9,
              "dbgstep": 3,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 67,
              "type": 5,
              "color": null,
              "start": 64,
              "dbgdir": 9,
              "dbgstep": 4,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 92,
              "type": 5,
              "color": null,
              "start": 67,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 121,
              "type": 1,
              "color": null,
              "start": 92,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 170,
              "type": 1,
              "color": null,
              "start": 121,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 1
            }
          ],
          "dirNum": 9,
          "viewName": "H.9",
          "phaseGroupNum": 11
        }
      ]
    },
    {
      "addInfo": null,
      "tlTacts": [
        {
          "main": 1,
          "tact": 1,
          "time": 118,
          "color": "#00ff40",
          "phase": 1,
          "direction": [
            1,
            2,
            3,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 2,
          "time": 9,
          "color": "#ce6f4f",
          "phase": 1,
          "direction": [
            1,
            2,
            3,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 1,
          "tact": 3,
          "time": 13,
          "color": "#00ff40",
          "phase": 2,
          "direction": [
            1,
            2,
            4
          ],
          "taсtType": 2
        },
        {
          "main": 0,
          "tact": 4,
          "time": 12,
          "color": "#ce6f4f",
          "phase": 2,
          "direction": [
            1,
            2,
            4
          ],
          "taсtType": 2
        },
        {
          "main": 1,
          "tact": 5,
          "time": 20,
          "color": "#00ff40",
          "phase": 3,
          "direction": [
            1,
            5,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 6,
          "time": 9,
          "color": "#ce6f4f",
          "phase": 3,
          "direction": [
            1,
            5,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 1,
          "tact": 7,
          "time": 25,
          "color": "#00ff40",
          "phase": 4,
          "direction": [
            5,
            6,
            7,
            9
          ],
          "taсtType": 2
        },
        {
          "main": 0,
          "tact": 8,
          "time": 9,
          "color": "#ce6f4f",
          "phase": 4,
          "direction": [
            5,
            6,
            7,
            9
          ],
          "taсtType": 2
        },
        {
          "main": 1,
          "tact": 9,
          "time": 37,
          "color": "#00ff40",
          "phase": 5,
          "direction": [
            3,
            8,
            9
          ],
          "taсtType": 1
        },
        {
          "main": 0,
          "tact": 10,
          "time": 9,
          "color": "#ce6f4f",
          "phase": 5,
          "direction": [
            3,
            8,
            9
          ],
          "taсtType": 1
        }
      ],
      "execTime": "ежедневно (план №12)",
      "viewItem": "нет",
      "phaseGroupNum": 12,
      "tlSignalProgram": [
        [
          {
            "stop": 127,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 152,
            "type": 1,
            "color": null,
            "start": 127,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 172,
            "type": 1,
            "color": null,
            "start": 152,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 175,
            "type": 2,
            "color": null,
            "start": 172,
            "dbgdir": 1,
            "dbgstep": 2,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 178,
            "type": 3,
            "color": null,
            "start": 175,
            "dbgdir": 1,
            "dbgstep": 3,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 181,
            "type": 5,
            "color": null,
            "start": 178,
            "dbgdir": 1,
            "dbgstep": 4,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 215,
            "type": 5,
            "color": null,
            "start": 181,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 261,
            "type": 5,
            "color": null,
            "start": 215,
            "dbgdir": 1,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 127,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 140,
            "type": 1,
            "color": null,
            "start": 127,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 143,
            "type": 2,
            "color": null,
            "start": 140,
            "dbgdir": 2,
            "dbgstep": 2,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 146,
            "type": 3,
            "color": null,
            "start": 143,
            "dbgdir": 2,
            "dbgstep": 3,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 152,
            "type": 5,
            "color": null,
            "start": 146,
            "dbgdir": 2,
            "dbgstep": 4,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 181,
            "type": 5,
            "color": null,
            "start": 152,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 215,
            "type": 5,
            "color": null,
            "start": 181,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 261,
            "type": 5,
            "color": null,
            "start": 215,
            "dbgdir": 2,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 118,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 121,
            "type": 2,
            "color": null,
            "start": 118,
            "dbgdir": 3,
            "dbgstep": 2,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 124,
            "type": 3,
            "color": null,
            "start": 121,
            "dbgdir": 3,
            "dbgstep": 3,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 127,
            "type": 5,
            "color": null,
            "start": 124,
            "dbgdir": 3,
            "dbgstep": 4,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 152,
            "type": 5,
            "color": null,
            "start": 127,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 181,
            "type": 5,
            "color": null,
            "start": 152,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 215,
            "type": 5,
            "color": null,
            "start": 181,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 261,
            "type": 1,
            "color": null,
            "start": 215,
            "dbgdir": 3,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 1
          }
        ],
        [
          {
            "stop": 127,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 140,
            "type": 1,
            "color": null,
            "start": 127,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 143,
            "type": 2,
            "color": null,
            "start": 140,
            "dbgdir": 4,
            "dbgstep": 2,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 146,
            "type": 3,
            "color": null,
            "start": 143,
            "dbgdir": 4,
            "dbgstep": 3,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 152,
            "type": 5,
            "color": null,
            "start": 146,
            "dbgdir": 4,
            "dbgstep": 4,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 181,
            "type": 5,
            "color": null,
            "start": 152,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 215,
            "type": 5,
            "color": null,
            "start": 181,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 261,
            "type": 5,
            "color": null,
            "start": 215,
            "dbgdir": 4,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 127,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 152,
            "type": 5,
            "color": null,
            "start": 127,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 181,
            "type": 1,
            "color": null,
            "start": 152,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 206,
            "type": 1,
            "color": null,
            "start": 181,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 209,
            "type": 2,
            "color": null,
            "start": 206,
            "dbgdir": 5,
            "dbgstep": 2,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 212,
            "type": 3,
            "color": null,
            "start": 209,
            "dbgdir": 5,
            "dbgstep": 3,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 215,
            "type": 5,
            "color": null,
            "start": 212,
            "dbgdir": 5,
            "dbgstep": 4,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 261,
            "type": 5,
            "color": null,
            "start": 215,
            "dbgdir": 5,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 127,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 152,
            "type": 5,
            "color": null,
            "start": 127,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 181,
            "type": 5,
            "color": null,
            "start": 152,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 206,
            "type": 1,
            "color": null,
            "start": 181,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 209,
            "type": 2,
            "color": null,
            "start": 206,
            "dbgdir": 6,
            "dbgstep": 2,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 212,
            "type": 3,
            "color": null,
            "start": 209,
            "dbgdir": 6,
            "dbgstep": 3,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 215,
            "type": 5,
            "color": null,
            "start": 212,
            "dbgdir": 6,
            "dbgstep": 4,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 261,
            "type": 5,
            "color": null,
            "start": 215,
            "dbgdir": 6,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 127,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 152,
            "type": 5,
            "color": null,
            "start": 127,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 181,
            "type": 5,
            "color": null,
            "start": 152,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 206,
            "type": 1,
            "color": null,
            "start": 181,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 209,
            "type": 2,
            "color": null,
            "start": 206,
            "dbgdir": 7,
            "dbgstep": 2,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 212,
            "type": 3,
            "color": null,
            "start": 209,
            "dbgdir": 7,
            "dbgstep": 3,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 215,
            "type": 5,
            "color": null,
            "start": 212,
            "dbgdir": 7,
            "dbgstep": 4,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 261,
            "type": 5,
            "color": null,
            "start": 215,
            "dbgdir": 7,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 2
          }
        ],
        [
          {
            "stop": 127,
            "type": 5,
            "color": null,
            "start": 0,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 152,
            "type": 5,
            "color": null,
            "start": 127,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 181,
            "type": 5,
            "color": null,
            "start": 152,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 215,
            "type": 5,
            "color": null,
            "start": 181,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 252,
            "type": 1,
            "color": null,
            "start": 215,
            "dbgdir": 8,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 255,
            "type": 2,
            "color": null,
            "start": 252,
            "dbgdir": 8,
            "dbgstep": 2,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 258,
            "type": 3,
            "color": null,
            "start": 255,
            "dbgdir": 8,
            "dbgstep": 3,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 261,
            "type": 5,
            "color": null,
            "start": 258,
            "dbgdir": 8,
            "dbgstep": 4,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 0
          }
        ],
        [
          {
            "stop": 118,
            "type": 1,
            "color": null,
            "start": 0,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 121,
            "type": 2,
            "color": null,
            "start": 118,
            "dbgdir": 9,
            "dbgstep": 2,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 124,
            "type": 3,
            "color": null,
            "start": 121,
            "dbgdir": 9,
            "dbgstep": 3,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 127,
            "type": 5,
            "color": null,
            "start": 124,
            "dbgdir": 9,
            "dbgstep": 4,
            "dbgphase": 1,
            "animation": null,
            "dbgonlygreen": 0
          },
          {
            "stop": 152,
            "type": 5,
            "color": null,
            "start": 127,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 2,
            "animation": null,
            "dbgonlygreen": 2
          },
          {
            "stop": 181,
            "type": 1,
            "color": null,
            "start": 152,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 3,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 215,
            "type": 1,
            "color": null,
            "start": 181,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 4,
            "animation": null,
            "dbgonlygreen": 1
          },
          {
            "stop": 261,
            "type": 1,
            "color": null,
            "start": 215,
            "dbgdir": 9,
            "dbgstep": 1,
            "dbgphase": 5,
            "animation": null,
            "dbgonlygreen": 1
          }
        ]
      ],
      "tlSignalProgram2": [
        {
          "data": [
            {
              "stop": 127,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 152,
              "type": 1,
              "color": null,
              "start": 127,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 172,
              "type": 1,
              "color": null,
              "start": 152,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 175,
              "type": 2,
              "color": null,
              "start": 172,
              "dbgdir": 1,
              "dbgstep": 2,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 178,
              "type": 3,
              "color": null,
              "start": 175,
              "dbgdir": 1,
              "dbgstep": 3,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 181,
              "type": 5,
              "color": null,
              "start": 178,
              "dbgdir": 1,
              "dbgstep": 4,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 215,
              "type": 5,
              "color": null,
              "start": 181,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 261,
              "type": 5,
              "color": null,
              "start": 215,
              "dbgdir": 1,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 1,
          "viewName": "H.1",
          "phaseGroupNum": 12
        },
        {
          "data": [
            {
              "stop": 127,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 140,
              "type": 1,
              "color": null,
              "start": 127,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 143,
              "type": 2,
              "color": null,
              "start": 140,
              "dbgdir": 2,
              "dbgstep": 2,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 146,
              "type": 3,
              "color": null,
              "start": 143,
              "dbgdir": 2,
              "dbgstep": 3,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 152,
              "type": 5,
              "color": null,
              "start": 146,
              "dbgdir": 2,
              "dbgstep": 4,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 181,
              "type": 5,
              "color": null,
              "start": 152,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 215,
              "type": 5,
              "color": null,
              "start": 181,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 261,
              "type": 5,
              "color": null,
              "start": 215,
              "dbgdir": 2,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 2,
          "viewName": "H.2",
          "phaseGroupNum": 12
        },
        {
          "data": [
            {
              "stop": 118,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 121,
              "type": 2,
              "color": null,
              "start": 118,
              "dbgdir": 3,
              "dbgstep": 2,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 124,
              "type": 3,
              "color": null,
              "start": 121,
              "dbgdir": 3,
              "dbgstep": 3,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 127,
              "type": 5,
              "color": null,
              "start": 124,
              "dbgdir": 3,
              "dbgstep": 4,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 152,
              "type": 5,
              "color": null,
              "start": 127,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 181,
              "type": 5,
              "color": null,
              "start": 152,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 215,
              "type": 5,
              "color": null,
              "start": 181,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 261,
              "type": 1,
              "color": null,
              "start": 215,
              "dbgdir": 3,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 1
            }
          ],
          "dirNum": 3,
          "viewName": "H.3",
          "phaseGroupNum": 12
        },
        {
          "data": [
            {
              "stop": 127,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 140,
              "type": 1,
              "color": null,
              "start": 127,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 143,
              "type": 2,
              "color": null,
              "start": 140,
              "dbgdir": 4,
              "dbgstep": 2,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 146,
              "type": 3,
              "color": null,
              "start": 143,
              "dbgdir": 4,
              "dbgstep": 3,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 152,
              "type": 5,
              "color": null,
              "start": 146,
              "dbgdir": 4,
              "dbgstep": 4,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 181,
              "type": 5,
              "color": null,
              "start": 152,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 215,
              "type": 5,
              "color": null,
              "start": 181,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 261,
              "type": 5,
              "color": null,
              "start": 215,
              "dbgdir": 4,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 4,
          "viewName": "H.4",
          "phaseGroupNum": 12
        },
        {
          "data": [
            {
              "stop": 127,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 152,
              "type": 5,
              "color": null,
              "start": 127,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 181,
              "type": 1,
              "color": null,
              "start": 152,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 206,
              "type": 1,
              "color": null,
              "start": 181,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 209,
              "type": 2,
              "color": null,
              "start": 206,
              "dbgdir": 5,
              "dbgstep": 2,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 212,
              "type": 3,
              "color": null,
              "start": 209,
              "dbgdir": 5,
              "dbgstep": 3,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 215,
              "type": 5,
              "color": null,
              "start": 212,
              "dbgdir": 5,
              "dbgstep": 4,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 261,
              "type": 5,
              "color": null,
              "start": 215,
              "dbgdir": 5,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 5,
          "viewName": "H.5",
          "phaseGroupNum": 12
        },
        {
          "data": [
            {
              "stop": 127,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 152,
              "type": 5,
              "color": null,
              "start": 127,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 181,
              "type": 5,
              "color": null,
              "start": 152,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 206,
              "type": 1,
              "color": null,
              "start": 181,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 209,
              "type": 2,
              "color": null,
              "start": 206,
              "dbgdir": 6,
              "dbgstep": 2,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 212,
              "type": 3,
              "color": null,
              "start": 209,
              "dbgdir": 6,
              "dbgstep": 3,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 215,
              "type": 5,
              "color": null,
              "start": 212,
              "dbgdir": 6,
              "dbgstep": 4,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 261,
              "type": 5,
              "color": null,
              "start": 215,
              "dbgdir": 6,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 6,
          "viewName": "H.6",
          "phaseGroupNum": 12
        },
        {
          "data": [
            {
              "stop": 127,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 152,
              "type": 5,
              "color": null,
              "start": 127,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 181,
              "type": 5,
              "color": null,
              "start": 152,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 206,
              "type": 1,
              "color": null,
              "start": 181,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 209,
              "type": 2,
              "color": null,
              "start": 206,
              "dbgdir": 7,
              "dbgstep": 2,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 212,
              "type": 3,
              "color": null,
              "start": 209,
              "dbgdir": 7,
              "dbgstep": 3,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 215,
              "type": 5,
              "color": null,
              "start": 212,
              "dbgdir": 7,
              "dbgstep": 4,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 261,
              "type": 5,
              "color": null,
              "start": 215,
              "dbgdir": 7,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 2
            }
          ],
          "dirNum": 7,
          "viewName": "H.7",
          "phaseGroupNum": 12
        },
        {
          "data": [
            {
              "stop": 127,
              "type": 5,
              "color": null,
              "start": 0,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 152,
              "type": 5,
              "color": null,
              "start": 127,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 181,
              "type": 5,
              "color": null,
              "start": 152,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 215,
              "type": 5,
              "color": null,
              "start": 181,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 252,
              "type": 1,
              "color": null,
              "start": 215,
              "dbgdir": 8,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 255,
              "type": 2,
              "color": null,
              "start": 252,
              "dbgdir": 8,
              "dbgstep": 2,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 258,
              "type": 3,
              "color": null,
              "start": 255,
              "dbgdir": 8,
              "dbgstep": 3,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 261,
              "type": 5,
              "color": null,
              "start": 258,
              "dbgdir": 8,
              "dbgstep": 4,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 0
            }
          ],
          "dirNum": 8,
          "viewName": "H.8",
          "phaseGroupNum": 12
        },
        {
          "data": [
            {
              "stop": 118,
              "type": 1,
              "color": null,
              "start": 0,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 121,
              "type": 2,
              "color": null,
              "start": 118,
              "dbgdir": 9,
              "dbgstep": 2,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 124,
              "type": 3,
              "color": null,
              "start": 121,
              "dbgdir": 9,
              "dbgstep": 3,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 127,
              "type": 5,
              "color": null,
              "start": 124,
              "dbgdir": 9,
              "dbgstep": 4,
              "dbgphase": 1,
              "animation": null,
              "dbgonlygreen": 0
            },
            {
              "stop": 152,
              "type": 5,
              "color": null,
              "start": 127,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 2,
              "animation": null,
              "dbgonlygreen": 2
            },
            {
              "stop": 181,
              "type": 1,
              "color": null,
              "start": 152,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 3,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 215,
              "type": 1,
              "color": null,
              "start": 181,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 4,
              "animation": null,
              "dbgonlygreen": 1
            },
            {
              "stop": 261,
              "type": 1,
              "color": null,
              "start": 215,
              "dbgdir": 9,
              "dbgstep": 1,
              "dbgphase": 5,
              "animation": null,
              "dbgonlygreen": 1
            }
          ],
          "dirNum": 9,
          "viewName": "H.9",
          "phaseGroupNum": 12
        }
      ]
    }
  ]
}`;
