/* eslint-disable camelcase */
import { z } from 'zod';

import { ReportsTreeItem } from '../../components/Panel/ReportsPanel/ReportTree/model/reports.model';
import { NNInt } from '../zod/utils.zod';

const ParamsSchema = z.object({
  device_id: NNInt.nullable().optional(),
});

export const BasicReportsSchema = z.object({
  id: NNInt,
  key: NNInt,
  label: z.string(),
  params: ParamsSchema.nullable(),
});

export const ReportsTreeSchema: z.ZodType<ReportsTreeItem> =
  BasicReportsSchema.extend({
    children: z.lazy(() => ReportsTreeSchema.array().nullable()),
  });
