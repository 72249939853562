import dayjs from 'dayjs';

import 'dayjs/locale/ru';

export const DATE_FORMAT_GRAPH = 'DD MMMM YYYY';

const DATE_FORMAT_RU = 'DD MMM YYYY';
const DATE_NUM = 0;
const TIME_NUM = 1;

export const getDateRangeStr = (
  dateFromData: string,
  dateToData: string,
  format = DATE_FORMAT_RU
) => {
  const dateTimeFrom = dateFromData.split(' ');
  const dateTimeTo = dateToData.split(' ');

  const dateFrom = dateTimeFrom[DATE_NUM];
  const dateTo = dateTimeTo[DATE_NUM];

  const timeFrom = dateTimeFrom[TIME_NUM];
  const timeTo = dateTimeTo[TIME_NUM];

  const dateFromDJ = dayjs(Date.parse(dateFrom)).locale('ru');
  const dateToDJ = dayjs(Date.parse(dateTo)).locale('ru');

  const normalDateFrom = dateFromDJ.format(DATE_FORMAT_GRAPH);
  const normalDateTo = dateToDJ.format(DATE_FORMAT_GRAPH);

  const range = `${timeFrom} до ${timeTo}`;
  const dateTimeRangeStr = `${dateFromDJ.format(format)} с ${range}`;

  const dateData = {
    timeFrom,
    normalDate: [normalDateFrom, normalDateTo],
  };

  if (normalDateFrom === normalDateTo)
    return {
      ...dateData,
      normalDateTime: dateTimeRangeStr,
    };

  const dateRangeStr = `с ${dateFromDJ.format(
    format
  )}, ${timeFrom} до ${dateToDJ.format(format)}, ${timeTo}`;

  return {
    ...dateData,
    normalDateTime: dateRangeStr,
  };
};
