export enum MessageType {
  NotAccessNull = 'NotAccessNull',
  Positive = 'Positive',
  OnArray = 'OnArray',
  IsNaN = 'IsNaN',
}

interface IGetZodMessageCustomProps {
  nameParent?: string;
  nameValue: string;
  rangeArr?: number[];
  messageType: MessageType;
}

export const getZodMessageCustom = ({
  nameParent = 'TlStatus',
  nameValue,
  rangeArr = [],
  messageType,
}: IGetZodMessageCustomProps) => {
  const startMessage = `${nameParent} -> ${nameValue}`;

  switch (messageType) {
    case MessageType.Positive:
      return { message: `${startMessage} need be > 0` };
    case MessageType.OnArray:
      return { message: `${startMessage} need be on array: [${rangeArr}]` };
    case MessageType.NotAccessNull:
      return { message: `${startMessage} not access null` };
    case MessageType.IsNaN:
      return {
        message: `${startMessage} expected string with number, but received NaN`,
      };
  }
};
