import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { FC } from 'react';
import Slider from 'react-slick';

import { COMMANDS } from '../../../../../constants/commands';
import { SECOND } from '../../../../../constants/constants';
import {
  NearestCommand,
  NearestCommandValue,
} from '../../../../../ts/models/tl.model';
import { SLIDER_SETTINGS } from '../../../../Panel/ScriptsControl/SelectModule/DispatchGroupControl/DipatchGroupDetailed/ControlCard/PhasesSlider/constants/constants';

import TimerItem from './TimerItem/TimerItem';

import styles from './Timers.module.scss';

export type TimerEntries = [COMMANDS, NearestCommandValue][];

interface TimersProps {
  timerInfo: N<NearestCommand>;
}

const Timers: FC<TimersProps> = ({ timerInfo }) => {
  if (!timerInfo)
    return <p className={styles.noData}>Нет запланированных команд</p>;

  const timerValues = Object.entries(timerInfo) as TimerEntries;

  if (timerValues.length === 1) return <TimerItem item={timerValues[0]} />;

  return (
    <div className={styles.container}>
      <Slider
        {...SLIDER_SETTINGS}
        slidesToShow={1}
        autoplay
        infinite
        autoplaySpeed={10 * SECOND}
        className={styles.slider}
      >
        {timerValues.map((item) => (
          <TimerItem key={item[0]} item={item} />
        ))}
      </Slider>
    </div>
  );
};

export default Timers;
