import { observer } from 'mobx-react-lite';
import { FC, useEffect, useMemo, useState } from 'react';

import { findById } from '../../../helpers/findBy';
import useCircleTimer from '../../../hooks/useCircleTimer';
import rootStore from '../../../stores/rootStore/rootStore';
import { ChanelTypes } from '../../../ts/enums/enums';
import { ITimePoints } from '../../../ts/models/signalPrograms.model';
import getCsdData from '../../NewSignalProgram/helpers/getCsdData';
import useChannel from '../../Socket/useChannel';

type WSDataUnion =
  | 'currIter'
  | 'tactCurrentStartFrom'
  | 'tactNext'
  | 'serverTimestamp';

export type WSData = Record<WSDataUnion, number>;

interface IUseSetSecProps {
  id: N<number>;
}

const TLTimeSec: FC<IUseSetSecProps> = ({ id }) => {
  const { categories } = rootStore.channelsDataStore;
  const { tls } = rootStore.mapDataStore;
  const { setSecTls } = rootStore.uiStore;
  const [phasesSet, setPhasesSet] = useState<ITimePoints[]>([]);

  const activeTl = useMemo(() => (id ? findById(tls, id) : null), [tls, id]);

  const tlStatus = activeTl?.tlStatus;

  const wsData: N<WSData> = useChannel(
    categories,
    ChanelTypes.TlCurrentTacts,
    id ?? 0,
    true
  );

  const sec = useCircleTimer(tlStatus, phasesSet, wsData);

  useEffect(() => {
    if (!id) return;

    const res = getCsdData({
      id,
      isStatic: false,
      tlsData: tls,
    });

    if (!res) return;

    const { timePoints } = res;

    if (!timePoints.length) return;

    setPhasesSet(timePoints);
  }, [id, tls]);

  useEffect(() => {
    if (!id) return;

    setSecTls(id, sec);
  }, [id, sec, setSecTls]);

  useEffect(() => {
    return () => {
      id && setSecTls(id, null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default observer(TLTimeSec);
