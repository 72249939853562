import { FC, ReactNode } from 'react';

import styles from './DscItem.module.scss';

interface DscItemProps {
  label: string;
  children: ReactNode;
}

const DscItem: FC<DscItemProps> = ({ label, children }) => {
  return (
    <p className={styles.dsc}>
      <span className={styles.label}>{label}:</span>
      <span className={styles.children}>{children}</span>
    </p>
  );
};

export default DscItem;
