import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { COMMANDS } from '../../../../../constants/commands';
import rootStore from '../../../../../stores/rootStore/rootStore';
import DatePicker from '../../../../ui-kit/Picker/DatePicker/DatePicker';
import TimePicker from '../../../../ui-kit/Picker/TimePicker/TimePicker';
import Popover from '../../../../ui-kit/Popover/Popover';
import useTimeCard from '../../hooks/timeCards/useTimeCard';
import { TimeCardBasicProps } from '../../model/timeCard.model';

import styles from './TimePickerCard.module.scss';

const { SET_OBJECT_SYNC_PLAN } = COMMANDS;

type TimePickerCardProps = Omit<
  TimeCardBasicProps,
  'isChecked' | 'setIsChecked'
> & {
  disabledTime: number;
};

const TimePickerCard: FC<TimePickerCardProps> = ({
  selectedTimeInfo,
  setSelectedTimeInfo,
  disabledTime,
}) => {
  const { interfaceTipsDelay } = rootStore.uiStore;
  const { isActiveControlBtn } = rootStore.detailedStore;

  const isDisabled = !isActiveControlBtn;

  const { datePickerProps, timePickerProps } = useTimeCard({
    command: SET_OBJECT_SYNC_PLAN,
    selectedTimeInfo,
    setSelectedTimeInfo,
    disabledTime,
    isDisabled,
  });

  const obligatoryFieldProps = {
    content: 'Обязательное поле для заполнения',
    placement: 'right',
    tipsDelay: interfaceTipsDelay,
  } as const;

  return (
    <div className={styles.timePickerCard}>
      <div className={styles.spaceVertical}>
        <Popover {...obligatoryFieldProps}>
          <div className={styles.spaceHorizontalDuration}>
            <h4>Дата: *</h4>
            <DatePicker {...datePickerProps} />
          </div>
        </Popover>

        <Popover {...obligatoryFieldProps}>
          <div className={styles.spaceHorizontalDuration}>
            <h4>Длительность: *</h4>
            <TimePicker {...timePickerProps} />
          </div>
        </Popover>
      </div>
    </div>
  );
};

export default observer(TimePickerCard);
