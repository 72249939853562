import { getObjectEntries } from '../../../../helpers/getObjectEntries';
import { LaneDirectionKey } from '../../../Constructor/TrafficLaneConstructor/TLaneConstructor.model';
import { FilterValues } from '../../../TrafficLightDetailed/TlDetectors/constants/constants';

export const checkIsActiveDir = (
  directions: N<number[]>,
  directionKey: LaneDirectionKey
) => {
  const isActive = !!directions?.some((el) => {
    const dir = getObjectEntries(FilterValues)
      .find(([, value]) => value === el)
      ?.at(0);

    return directionKey === dir;
  });

  return isActive;
};
