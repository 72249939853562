import {
  legacyLogicalPropertiesTransformer,
  StyleProvider,
  type StyleProviderProps,
} from '@ant-design/cssinjs';
import { ConfigProvider, App as AntdApp } from 'antd';
import ruRu from 'antd/lib/locale/ru_RU';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { getAntdTheme } from '../../helpers/theme/getAntdTheme';
import rootStore from '../../stores/rootStore/rootStore';
import { Children } from '../../ts/models/general.model';

const STYLE_PROPS: StyleProviderProps = {
  transformers: [legacyLogicalPropertiesTransformer],
  hashPriority: 'high',
};

const AntdProvider: FC<Children> = ({ children }) => {
  const { isDark } = rootStore.uiStore;

  return (
    <StyleProvider {...STYLE_PROPS}>
      <ConfigProvider locale={ruRu} theme={getAntdTheme(isDark)}>
        <AntdApp>{children}</AntdApp>
      </ConfigProvider>
    </StyleProvider>
  );
};

export default observer(AntdProvider);
