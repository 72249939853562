import type { Coordinate } from 'ol/coordinate';

import { CENTERING } from '../../../../constants/mapConstants';
import rootStore from '../../../../stores/rootStore/rootStore';
import { setClustersZoomChange } from '../setClustersZoomChange';

interface HandleCenterProps {
  duration?: number;
  isNeedOpenCluster?: boolean;
  zoom?: number;
}

const handleCenter = (
  center: U<Coordinate>,
  {
    duration = CENTERING.ANIMATION_DURATION,
    isNeedOpenCluster = false,
    zoom,
  }: HandleCenterProps = {}
) => {
  const { setMapData } = rootStore.mapDataStore;
  const { map } = rootStore.mapStore;

  if (!map) return;

  const view = map.getView();

  setMapData('isMapMoveEnd', false);

  view.animate({ center, duration, zoom }, (isEnd) => {
    if (!isEnd) return;

    setMapData('isMapMoveEnd', isEnd);
    setClustersZoomChange({
      map,
      isNeedClicked: isNeedOpenCluster,
    });
  });
};

export default handleCenter;
