import { INTERVAL_DATES } from '../../constants/constants';
import { DatesKeys, IDateInfo } from '../../model/graph.model';

import { SliceTypes, getSliceDates } from './getSliceDates';

const { DAY, HOUR } = INTERVAL_DATES;

export const changeDateGraph = (value: DatesKeys): IDateInfo => {
  const dateGraph: IDateInfo = {
    selectedDate: [...getSliceDates(1)],
    interval: HOUR,
    radioValueDate: value,
    isNeedUtcFormatted: true,
  };

  switch (value) {
    case 'DAY':
      return { ...dateGraph, selectedDate: [...getSliceDates(1)] };
    case 'THREE_DAYS':
      return { ...dateGraph, selectedDate: [...getSliceDates(3)] };
    case 'WEEK':
      return { ...dateGraph, selectedDate: [...getSliceDates(7)] };
    case 'MONTH':
      return {
        ...dateGraph,
        selectedDate: [...getSliceDates(31, { sliceType: SliceTypes.day })],
        interval: DAY,
        isNeedUtcFormatted: false,
      };
    default:
      return dateGraph;
  }
};
