import { type FormInstance } from 'antd';

import { QueryParamItem } from '../../../../../../ts/models/table.model';
import { Options } from '../SearchForm';

interface FilterFormItemValuesByOptionsParams {
  param: QueryParamItem;
  form: FormInstance;
  options: Options;
  isMultiple: boolean;
  setFormValues: SetState<Record<string, any>>;
}

export const filterFormItemValuesByOptions = ({
  form,
  param,
  options,
  isMultiple,
  setFormValues,
}: FilterFormItemValuesByOptionsParams) => {
  const itemValue = form.getFieldValue(param.jkey);

  if (!itemValue) return;

  if (isMultiple) {
    const filteredValues = itemValue.filter((value: number | string) =>
      options.find((el) => value.toString() === el.value.toString())
    );

    if (filteredValues.length !== itemValue.length) {
      form.setFieldValue(param.jkey, filteredValues);

      /* костыль!!! просьба не трогать
				ругается варнингом в консоль, но отрабатывает перерендер цепочки связанности элементов формы
				сет стейт нужен для корректного ререндра цепочки асоциативных селектов, если их больше 2-х
			*/
      setFormValues((prev) => ({ ...prev, [param.jkey]: filteredValues }));
    }

    return;
  }

  const isExistValue = options.find(
    (el) => itemValue.toString() === el.value.toString()
  );

  if (!isExistValue) {
    form.setFieldValue(param.jkey, undefined);
  }
};
