import { FC, useMemo } from 'react';
import type { Payload } from 'recharts/types/component/DefaultLegendContent';

import { CalcData } from '../../models/chart.model';
import getDateValues from '../helpers/getDateValues';
import removeComma from '../helpers/removeComma';

import styles from '../CustomedTick.module.scss';

interface XTickProps {
  x: number;
  y: number;
  payload: Payload;
  data?: CalcData[];
  labelKey: string;
  visibleTicksCount: number;
}

const XTick: FC<XTickProps> = ({
  labelKey,
  payload,
  visibleTicksCount,
  x,
  y,
  data,
}) => {
  const dateValues = useMemo(
    () => getDateValues(payload, data, labelKey, visibleTicksCount),
    [data, labelKey, payload, visibleTicksCount]
  );

  if (!dateValues) return null;

  const { dateStr, isDifDates, textAnchor, time } = dateValues;

  return (
    <g transform={`translate(${x},${y})`} className={styles.textLabel}>
      <text x={0} y={0} dy={16} textAnchor={textAnchor}>
        {time}
      </text>
      {isDifDates && (
        <text x={0} y={20} dy={16} textAnchor={textAnchor}>
          {removeComma(dateStr)}
        </text>
      )}
    </g>
  );
};

export default XTick;
