import { Button } from 'antd';
import { FC } from 'react';

interface FooterProps {
  OkBtn: FC;
  CancelBtn: FC;
  disabledReset: boolean;
  handleReset: () => void;
}

const Footer: FC<FooterProps> = ({
  OkBtn,
  CancelBtn,
  disabledReset,
  handleReset,
}) => {
  return (
    <>
      <Button
        danger
        type="primary"
        disabled={disabledReset}
        onClick={handleReset}
      >
        Отменить изменения
      </Button>
      <CancelBtn />
      <OkBtn />
    </>
  );
};

export default Footer;
