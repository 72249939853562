import { uniqueId } from 'lodash';
import { FC } from 'react';

import { TitleTable } from '../../constants/constants';

import { getTextValidate } from './helpers/getTextValidate';
import { getTypeColor } from './helpers/getTypeColor';

import styles from './ColumnTooltip.module.scss';

interface ColumnTooltipProps {
  title: TitleTable;
}

const ColumnTooltip: FC<ColumnTooltipProps> = ({ title }) => {
  const requirementsText = getTextValidate(title);

  const color = getTypeColor(title);

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <strong className={styles.title}>{title}</strong>
        {color ? (
          <span
            className={styles.colorType}
            style={{ backgroundColor: color }}
          ></span>
        ) : null}
      </div>
      <div className={styles.requirementsContainer}>
        <strong>Требования:</strong>
        {requirementsText.map((text, i) => (
          <p key={uniqueId('reqText_')}>{`${i + 1}. ${text}`}</p>
        ))}
      </div>
    </div>
  );
};

export default ColumnTooltip;
