import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { IoIosWarning } from 'react-icons/io';

import { TL } from '../../../../../ts/models/tl.model';
import ModalBody from '../../PhaseHold/ModalBody/ModalBody';
import ModalConfirm from '../../PhaseHold/ModalConfirm/ModalConfirm';
import styles from '../../styles/basicStyles.module.scss';
import { ProgramsInfo, SelectedTime } from '../models/plansControl.model';

import useModalPlan from './hooks/useModalPlan';

interface ModalPlanProps {
  selectedPlan: N<number>;
  activeTL: TL;
  selectedTime: SelectedTime;
  programInfo: ProgramsInfo;
  clearPlanSelect: () => void;
}

const ModalPlan: FC<ModalPlanProps> = ({
  selectedPlan,
  activeTL,
  selectedTime,
  programInfo,
  clearPlanSelect,
}) => {
  const { modalBodyProps, modalConfirmProps } = useModalPlan({
    selectedPlan,
    activeTL,
    selectedTime,
    programInfo,
    clearPlanSelect,
  });

  return (
    <ModalConfirm
      {...modalConfirmProps}
      icon={<IoIosWarning className={styles.iconInfo} />}
    >
      <ModalBody {...modalBodyProps} />
    </ModalConfirm>
  );
};

export default observer(ModalPlan);
