import { Button } from 'antd';
import { FC, useState, useEffect } from 'react';

import JsonView from '../../../../../../ui-kit/JsonView/JsonView';
import { StatusTypes, TMethods } from '../../../../ts/TestingPanel.model';
import TextItem from '../../../TextItem/TextItem';
import { getStatusCodeDescription } from '../../helpers/getStatusCodeDescription';

import styles from './RequestInfo.module.scss';

export interface RequestInfoProps {
  title: string;
  url: string;
  method: TMethods;
  body: N<object>;
  setBody: SetState<object>;
  waitingStatus: number;
  statusCode: N<number>;
  send: () => Promise<void>;
  statusMessage: StatusTypes;
}

const RequestInfo: FC<RequestInfoProps> = (props) => {
  const {
    title,
    url,
    method,
    body,
    setBody,
    waitingStatus,
    statusCode,
    send,
    statusMessage,
  } = props;

  const [reqTime, setReqTime] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setReqTime(0);
  }, [url]);

  const handleSend = async () => {
    setIsLoading(true);
    const timeStart = Date.now();

    await send();

    setIsLoading(false);
    setReqTime(Date.now() - timeStart);
  };

  const getRequestBody = (): JSX.Element => {
    if (!body) return <></>;

    return <JsonView object={body} editSetObject={setBody} />;
  };

  return (
    <div className={styles.box}>
      <h3 className={styles.title}>Информация о запросе</h3>
      <TextItem label="Запрос" text={title} />
      <TextItem label="Адрес запроса" text={url} />
      <TextItem label="Метод запроса" text={method} />
      <TextItem label="Ожидаемый код ответа" text={waitingStatus} />
      <br />
      <TextItem
        label="Текущий код ответа"
        text={
          statusCode
            ? statusCode + ' - ' + getStatusCodeDescription(statusCode)
            : 'Ожидает отправки'
        }
      />
      <TextItem label="Текущий статус запроса" text={statusMessage} />
      <TextItem label="Время выполнения запроса" text={reqTime + 'мс'} />
      <TextItem
        label="Тело запроса"
        text={body ? '' : 'Запрос не содержит тела'}
      >
        {getRequestBody()}
      </TextItem>
      <Button loading={isLoading} type="primary" onClick={handleSend}>
        Отправить запрос
      </Button>
    </div>
  );
};

export default RequestInfo;
