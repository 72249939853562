import { type Rule } from 'antd/es/form';
import { isNumber, isString } from 'lodash';

import {
  isDateValues,
  isRangeDateValues,
  QueryParamItem,
  VALUE_TYPES,
} from '../../../../../../ts/models/table.model';
import { getInputNumberRules } from '../helpers';

const getIsRequiredValue = (
  value: QueryParamItem['defaultValue'],
  type: QueryParamItem['jvalueType']
) => {
  if (isNumber(value)) return true;

  if (isDateValues(value, type)) return isString(value.value);

  if (isRangeDateValues(value, type))
    return isString(value.from) && isString(value.to);

  return Boolean(Array.isArray(value) ? value.length : value);
};

const getRulesFiltration = (param: QueryParamItem): U<Rule[]> => {
  const { minValue, maxValue } = param;

  const isRequiredDefault = getIsRequiredValue(
    param.defaultValue,
    param.jvalueType
  );

  const rules: Rule[] = [];

  if (param.jvalueType === VALUE_TYPES.number)
    rules.push(...getInputNumberRules({ minValue, maxValue }));

  if (isRequiredDefault)
    rules.push({ required: true, message: 'Обязательное поле' });

  return rules.length ? rules : undefined;
};

export default getRulesFiltration;
