import { FC } from 'react';

import { LaneDirectionKey } from '../../Constructor/TrafficLaneConstructor/TLaneConstructor.model';
import { TLanesDetailedProps } from '../TrafficLanesBlock/TLanesBlock.model';
import TLanesBlockDetailed from '../TrafficLanesBlock/TLanesBlockDetailed';

const { ToCamera, FromCamera } = LaneDirectionKey;

const TLanesDirectionsDetailed: FC<TLanesDetailedProps> = (props) => {
  const { trafficLane } = props;
  const { fromCamera } = trafficLane;

  return (
    <>
      <TLanesBlockDetailed {...props} directionKey={ToCamera} />

      {fromCamera ? (
        <TLanesBlockDetailed {...props} directionKey={FromCamera} />
      ) : null}
    </>
  );
};

export default TLanesDirectionsDetailed;
