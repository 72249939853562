import { DEVICES_STORE_NAMES } from '../../../../../../../constants/devicesConstants';
import rootStore from '../../../../../../../stores/rootStore/rootStore';
import { System } from '../../../../../../../ts/enums/enums';

const getDeviceBySystem = (id: number, system: System) => {
  const { getById } = rootStore.mapDataStore;

  const deviceStoreName = DEVICES_STORE_NAMES[system];

  if (!deviceStoreName) return null;

  return getById(id, deviceStoreName);
};

export default getDeviceBySystem;
