import classNames from 'classnames';
import { type Feature } from 'ol';
import { type Point } from 'ol/geom';
import { FC, RefObject } from 'react';

import { FeatureDeviceProps } from '../../../../ts/enums/enums';

import styles from './InfoOverlay.module.scss';

const { Id } = FeatureDeviceProps;

interface InfoOverlayProps {
  feature: Feature<Point>;
  isSelect?: boolean;
  infoRef?: RefObject<HTMLDivElement>;
}

const InfoOverlay: FC<InfoOverlayProps> = ({
  feature,
  isSelect = false,
  infoRef,
}) => {
  const id = feature.get(Id);

  const infoPopupChosenFeatureStyle = classNames({
    [styles.infoOverlay]: true,
    [styles.infoOverlaySelect]: isSelect,
  });

  return (
    <div
      className={infoPopupChosenFeatureStyle}
      key={`info_${id}`}
      ref={infoRef}
    >
      <p>{id}</p>
    </div>
  );
};

export default InfoOverlay;
