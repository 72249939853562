import rootStore from '../stores/rootStore/rootStore';
import { PointUds } from '../ts/models/pointUds.model';

type Uid = N<string>;

export const findPointUds = (uid: Uid) => {
  if (!uid) return null;
  const { pointsUds } = rootStore.mapDataStore;

  return pointsUds.find(({ deviceInfo }) => uid === deviceInfo.pointUdsUid);
};

export const getPointBasicInfo = (
  uid: Uid
): Partial<PointUds['basicProfileInfo']> =>
  findPointUds(uid)?.basicProfileInfo ?? {};

export const getPointCenter = (uid: Uid): XY | [] =>
  getPointBasicInfo(uid)?.centerPoint ?? [];
