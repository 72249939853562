import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { FiInfo } from 'react-icons/fi';

import rootStore from '../../../../../stores/rootStore/rootStore';
import Popover from '../../../Popover/Popover';
import { IButtonsPanelProps } from '../ButtonsPanel';

import PopoverInfoContent from './PopoverInfoContent/PopoverInfoContent';

import styles from './InfoButton.module.scss';

export type InfoButtonProps = Pick<
  IButtonsPanelProps,
  'timeUpdate' | 'graphInfo'
>;

const InfoButton: FC<InfoButtonProps> = (info) => {
  const { interfaceTipsDelay } = rootStore.uiStore;

  return (
    <Popover
      tipsDelay={interfaceTipsDelay}
      content={<PopoverInfoContent {...info} />}
    >
      <FiInfo className={styles.icon} />
    </Popover>
  );
};

export default observer(InfoButton);
