import { observer } from 'mobx-react-lite';

import { BORDER_R_XS } from '../../../constants/themeTokens';
import rootStore from '../../../stores/rootStore/rootStore';
import ButtonIco from '../../ui-kit/ButtonIco/ButtonIco';
import ButtonsBlock from '../../ui-kit/ButtonsBlock/ButtonsBlock';
import Popover from '../../ui-kit/Popover/Popover';
import { getMapBtns } from '../constants';
import { PLACEMENT_MAP_BTNS } from '../LayersButton/LayersButton';

import styles from '../styles/BtnsBlock.module.scss';

const SystemsBtns = () => {
  const { markers, setIsMarkers, mapButtonsTipsDelay } = rootStore.uiStore;
  const { hasAccess } = rootStore.userDataStore;

  const mapBtns = getMapBtns(markers);

  const popOverProps = {
    placement: PLACEMENT_MAP_BTNS,
    mouseEnterDelay: 2.2,
    tipsDelay: mapButtonsTipsDelay,
  } as const;

  return (
    <ButtonsBlock borderRadius={BORDER_R_XS} className={styles.btnsBlock}>
      {mapBtns.map(({ system, isActive, component, code }) => {
        const isAccess = hasAccess(code);

        if (!isAccess) return null;

        return (
          <ButtonIco
            onClick={() => setIsMarkers(system)}
            isActive={isActive}
            key={`mapBtn_${system}`}
          >
            <Popover content={`Вкл./откл. ${system}`} {...popOverProps}>
              {component}
            </Popover>
          </ButtonIco>
        );
      })}
    </ButtonsBlock>
  );
};

export default observer(SystemsBtns);
