import xeomaApi from '../../../api/xeoma/xeoma';

import { onRemoteTrack } from './onRemoteTrack';
import { setBandwidth } from './setBandwidth';
import { setOpusParams } from './setOpusParams';

const MAX_BAND_WIDTH_KBPS = 50_000;
const MEDIA_CONSTRAINTS = {
  mandatory: { OfferToReceiveAudio: true, OfferToReceiveVideo: true },
};

const onCreateAnswer = async (
  sessionDescription: any,
  streamCameraHref: string,
  webrtcId: string,
  pc: any
) => {
  sessionDescription.sdp = setBandwidth(
    sessionDescription.sdp,
    MAX_BAND_WIDTH_KBPS
  );
  sessionDescription.sdp = setOpusParams(sessionDescription.sdp);

  pc.setLocalDescription(
    sessionDescription,
    function () {},
    function (error: any) {
      console.error('Set local description failed: ', error);
    }
  );

  await xeomaApi.createWebRtcAnswer(
    streamCameraHref,
    webrtcId,
    sessionDescription
  );

  requestMessage(streamCameraHref, webrtcId, pc);
};

const processOffer = (
  message: any,
  streamCameraHref: string,
  webrtcId: string,
  pc: any
) => {
  const jsonData = JSON.parse(message);

  pc.setRemoteDescription(
    new RTCSessionDescription(jsonData),
    function () {},
    function (error: any) {
      console.error('Set remote description failed: ', error);
    }
  );
  pc.createAnswer(
    (sessionDescription: any) =>
      onCreateAnswer(sessionDescription, streamCameraHref, webrtcId, pc),
    function (error: any) {
      console.error('Create answer failed: ', error);
    },
    MEDIA_CONSTRAINTS
  );
};

const addIceCandidate = (
  message: any,
  streamCameraHref: string,
  webrtcId: string,
  pc: any
) => {
  const candidateList = JSON.parse(message);

  if (candidateList) {
    for (let i = 0; i < candidateList.length; i++) {
      const candidate = new RTCIceCandidate(candidateList[i]);

      pc.addIceCandidate(
        candidate,
        function () {},
        function (error: any) {
          console.error('Add ice candidate failed: ' + JSON.stringify(error));
        }
      );
    }
  }

  setTimeout(() => requestMessage(streamCameraHref, webrtcId, pc), 0);
};

const processMessage = (
  message: any,
  streamCameraHref: string,
  webrtcId: string,
  pc: any
) => {
  if (message === '')
    return setTimeout(
      () => requestMessage(streamCameraHref, webrtcId, pc),
      1000
    );

  if (message === 'hangup') return pc?.close();

  if (message.includes('offer'))
    return processOffer(message, streamCameraHref, webrtcId, pc);

  addIceCandidate(message, streamCameraHref, webrtcId, pc);
};

export const requestMessage = async (
  streamCameraHref: string,
  webrtcId: string,
  pc: any
) => {
  if (pc.connectionState === 'closed') return;

  const webrtcGetMessageRes = await xeomaApi.fetchWebRtcMessage(
    streamCameraHref,
    webrtcId
  );

  if (!webrtcGetMessageRes) return;

  const message = await webrtcGetMessageRes.text();

  processMessage(message, streamCameraHref, webrtcId, pc);
};

const onIceCandidate = async (
  event: any,
  streamCameraHref: string,
  webrtcId: string
) => {
  if (event.candidate) {
    const candidate = {
      sdpMLineIndex: event.candidate.sdpMLineIndex,
      sdpMid: event.candidate.sdpMid,
      candidate: event.candidate.candidate,
    };

    await xeomaApi.updateWebRtcIceCandidate(
      streamCameraHref,
      webrtcId,
      candidate
    );
  }
};

export const preparePeerConnection = async (
  streamCameraHref: string,
  webrtcId: string,
  pc: any,
  elements: { audio: HTMLElement | null; video: HTMLElement | null }
) => {
  pc.onicecandidate = (e: any) => onIceCandidate(e, streamCameraHref, webrtcId);

  if (typeof pc.ontrack === 'undefined') {
    pc.onaddstream = (event: any) => onRemoteTrack(event, elements);
  } else {
    pc.ontrack = (event: any) => onRemoteTrack(event, elements);
  }
};
