import { IHeaderItem } from '../../../PhaseHold/PhaseHold.model';
import { TitleTable } from '../constants/constants';
import TitleColumn from '../TitleColumn/TitleColumn';

type TitleKeys = keyof typeof TitleTable;

const { spanGreenBlinkTime, spanRedYellowTime, phase, spanYellowTime } =
  TitleTable;

const SPAN_TIMES = [spanGreenBlinkTime, spanYellowTime, spanRedYellowTime];

const getHeaderItem = (isPhaseChangeTable = false) => {
  const headerArray = Object.keys(TitleTable) as Array<TitleKeys>;

  const headerItem = headerArray.reduce(
    (acc: IHeaderItem[], item: TitleKeys) => {
      const title = TitleTable[item];
      const isPhaseChange = SPAN_TIMES.some((el) => title === el);

      acc.push({
        title: <TitleColumn title={title} />,
        dataIndex: item,
        key: item,
        editable: title !== phase,
        visible: isPhaseChangeTable ? !isPhaseChange : true,
      });

      return acc;
    },
    []
  );

  return headerItem;
};

export default getHeaderItem;
