import { isNumber } from 'lodash';
import { useState, useEffect } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import {
  TactsImagesEl,
  TLTact,
  TLZoomsTacts,
} from '../../../ts/models/tl.model';
import { INITIAL_ZOOM_TACTS } from '../constants/constants';
import getTLZoomTact from '../helpers/getTLZoomTact';

interface UseTLTactsArgs {
  currentZoom: number;
  selectedTL: any;
}

type UseTLTacts = (
  d: UseTLTactsArgs
) => [TLTact[], U<TLZoomsTacts>, TactsImagesEl[][]];

const useTLTacts: UseTLTacts = ({ selectedTL, currentZoom }) => {
  const tlTactsState: TLTact[] = selectedTL.tlTacts;
  const [tlTacts, setTLTacts] = useState<TLTact[]>(tlTactsState);
  const [tlZoomTact, setTLZoomTact] =
    useState<TLZoomsTacts>(INITIAL_ZOOM_TACTS);
  const [tlTactImages, setTLTactImages] = useState<TactsImagesEl[][]>([]);
  const { setKeyValue } = rootStore.uiStore;

  useEffect(() => {
    const [newTLZoomTact, newTLTactImages] = getTLZoomTact(
      selectedTL,
      currentZoom
    );

    setTLZoomTact(newTLZoomTact);
    setTLTactImages(newTLTactImages);
  }, [selectedTL, currentZoom, setKeyValue]);

  useEffect(() => {
    if (!tlTactsState && !tlTactImages) return;
    const idx = tlZoomTact?.tlTactsImagesIndex;
    const tactImages = isNumber(idx) ? tlTactImages[idx] : [];

    const newTLTacts = tlTactsState
      .sort((a, b) => a.tact - b.tact)
      .map((tlTact) => {
        tlTact.tactImages = tactImages;

        return tlTact;
      });

    setTLTacts(newTLTacts);
  }, [tlTactImages, tlTactsState, tlZoomTact?.tlTactsImagesIndex]);

  return [tlTacts, tlZoomTact, tlTactImages];
};

export default useTLTacts;
