import { Button, Modal, Progress, Upload } from 'antd';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { observer } from 'mobx-react-lite';
import { useState, FC, useEffect } from 'react';

import { filesApi } from '../../../../../../api/files/files';
import rootStore from '../../../../../../stores/rootStore/rootStore';
import message from '../../../../../ui-kit/Notification/Notification';
import { ALLOWED_EXTENTIONS } from '../../../../constants/documents';
import { validateFileExtension } from '../../../../helpers/validateFiles';

import styles from './UploadDocument.module.scss';

const { Dragger } = Upload;

interface IUploadDocument {
  categoryId: number;
  deviceId: number;
}

const BIG_SIZE = 'Превышен допустимый объем файла';
const NOT_VALID = 'Неподходящий формат документа';
const DARG_TEXT = 'Нажмите или перетащите файл';
const MAX_SIZE = 52428800;
const ERROR_STATUS = 409;

const UploadDocument: FC<IUploadDocument> = ({ categoryId, deviceId }) => {
  const { setDocumentsKeysValues } = rootStore.documentsStore;
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [isBigSize, setIsBigSize] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [percent, setPercent] = useState(0);
  const isDisabled =
    fileList.length > 1 || fileList.length === 0 || !isValid || isBigSize;

  useEffect(() => {
    if (!fileList.length) return setIsValid(true);

    let isInvalid = false;

    fileList.forEach((file) => {
      if (!validateFileExtension(file.name.split('.').reverse()[0]))
        isInvalid = true;
    });

    setIsValid(!isInvalid);
  }, [fileList]);

  const handleUpload = async () => {
    setIsLoading(true);
    const interval = setInterval(() => {
      setPercent((prev) => {
        if (prev === 100) return 0;

        return prev + 1;
      });
    }, 500);
    const formData = new FormData();

    fileList.forEach((file) => {
      formData.append('file', file as RcFile);
      formData.append('deviceId', String(deviceId));
      formData.append('categoryId', String(categoryId));
      formData.append('dsc', '');
    });

    setUploading(true);

    const res = await filesApi.uploadFile(formData, 'tl.detailed.documents');

    if (!res) {
      clearInterval(interval);
      setIsLoading(false);

      return setUploading(false);
    }

    if (res.status === ERROR_STATUS) {
      message.error('FILE_DUPLICATE');
      setUploading(false);
      clearInterval(interval);
      setIsLoading(false);

      return;
    }

    setPercent(100);
    clearInterval(interval);
    setTimeout(() => Modal.destroyAll());
    setIsLoading(false);
    message.success('FILE_UPLOAD_SUCCESS');
    setUploading(false);
    setDocumentsKeysValues({
      isUpdate: true,
    });
    setFileList([]);
  };

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();

      newFileList.splice(index, 1);
      setFileList(newFileList);
      setIsValid(false);
    },
    beforeUpload: (file) => {
      if (file.size > MAX_SIZE) {
        setIsBigSize(true);
        setFileList([{ ...file, status: 'error', name: file.name }]);

        return false;
      }

      if (!validateFileExtension(file.name.split('.').reverse()[0])) {
        setFileList([{ ...file, status: 'error', name: file.name }]);
        setIsValid(false);
        setIsBigSize(false);

        return false;
      }

      setFileList([file]);
      setIsBigSize(false);

      return false;
    },
    fileList,
  };

  return (
    <div className={styles.wrapper}>
      {isBigSize && <p className={styles.notValid}>{BIG_SIZE}</p>}
      {!isValid && !isBigSize && <p className={styles.notValid}>{NOT_VALID}</p>}
      <Dragger {...props}>
        <p className={styles.draggerText}>{DARG_TEXT}</p>
      </Dragger>
      <Button
        type="primary"
        onClick={handleUpload}
        disabled={isDisabled}
        loading={uploading}
        style={{ marginTop: 16 }}
        className={styles.addBtn}
      >
        {uploading ? 'Загрузка' : 'Начать загрузку'}
      </Button>
      {isLoading && <Progress percent={percent} />}
      <p className={styles.text}>Разрешенные форматы:</p>
      <ul className={styles.formats}>
        {Object.keys(ALLOWED_EXTENTIONS).map((key) => (
          <li key={key}>{key}</li>
        ))}
      </ul>
    </div>
  );
};

export default observer(UploadDocument);
