import { useEffect } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import { handleChangeWeather } from '../helpers/handleWeather';

const useWeather = () => {
  const { map } = rootStore.mapStore;
  const { weatherLayer } = rootStore.gisDataStore;

  useEffect(() => {
    if (map && weatherLayer) {
      handleChangeWeather(map, weatherLayer);
    }
  }, [map, weatherLayer]);

  return null;
};

export default useWeather;
