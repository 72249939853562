import { AliasToken, OverrideToken } from 'antd/lib/theme/interface';

import { COLORS } from './colorsConstants';

export const BORDER_R_XS = 6;

const {
  PRIMARY,
  BLACK100,
  NONE,
  WHITY,
  BG,
  BLUE100,
  BG_MASK,
  GREY100,
  BG_DARK,
  WHITE,
} = COLORS;

const PADDING = 10;
const MARGIN = 10;
const ICONS_SIZE = 17;
const TABLE_CELL_PADDING = 5;

const tableToken: OverrideToken['Table'] = {
  padding: PADDING,
  margin: MARGIN,
  cellPaddingBlockSM: TABLE_CELL_PADDING,
  cellPaddingInlineSM: TABLE_CELL_PADDING,
};

const darkToken: Partial<AliasToken> | undefined = {
  colorBgContainer: BLACK100,
  borderRadiusXS: BORDER_R_XS,
  colorPrimaryBg: PRIMARY,
  colorPrimary: PRIMARY,
};

const darkComponentsToken: OverrideToken = {
  Table: {
    colorBgContainer: BLACK100,
    ...tableToken,
  },
  Button: {
    controlOutline: NONE,
    colorPrimary: PRIMARY,
  },
  Menu: {
    colorFillQuaternary: BLACK100,
    itemSelectedBg: BG,
    radiusItem: 0,
  },
  Segmented: {
    colorBgElevated: PRIMARY,
    colorBgLayout: BLACK100,
  },
  Switch: {
    colorPrimary: PRIMARY,
  },
  Slider: {
    colorPrimary: WHITY,
    colorPrimaryBorder: WHITY,
    colorPrimaryBorderHover: BLUE100,
    colorBgElevated: PRIMARY,
  },
  Image: {
    colorBgMask: BG_MASK,
    fontSizeIcon: ICONS_SIZE,
  },
  Input: {
    colorTextPlaceholder: WHITE,
  },
  Tooltip: {
    colorBgSpotlight: BG_MASK,
    colorTextLightSolid: WHITY,
  },
};

const lightToken: Partial<AliasToken> | undefined = {};

const lightComponentsToken: OverrideToken = {
  Menu: {
    itemBg: WHITY,
    colorActiveBarBorderSize: 0,
  },
  Table: tableToken,
  Segmented: {
    colorTextDisabled: GREY100,
    colorBgElevated: PRIMARY,
    colorTextLabel: PRIMARY,
    colorText: WHITY,
  },
  Image: {
    colorBgMask: BG_MASK,
    fontSizeIcon: ICONS_SIZE,
  },
  DatePicker: {
    colorTextPlaceholder: GREY100,
  },
  Input: {
    colorTextPlaceholder: BG_DARK,
  },
  Select: {
    colorTextPlaceholder: GREY100,
  },
  Tooltip: {
    colorBgSpotlight: WHITE,
    colorTextLightSolid: BLACK100,
  },
};

export { darkToken, darkComponentsToken, lightComponentsToken, lightToken };
