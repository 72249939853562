import { DEFAULT_CROSSROAD_COLORS } from '../../../constants/colorsConstants';
import { CircleCrossroad } from '../models/crossroad.model';

import useDirections from './useDirections';

import { useCrossroadDirsEls, useFetchCrossroadSvg } from '.';

const APP_PATH = '.Overlays.hooks.useCrossroadDirsImg';

const { MAIN, MAIN_TRANSITION, CROSSWALK, CROSSWALK_TRANSITION } =
  DEFAULT_CROSSROAD_COLORS;

const useCrossroadDirsImg = ({
  phaseIdx,
  tlCrossroad,
  id,
  tlTacts,
}: CircleCrossroad) => {
  const {
    color,
    colorTransition,
    crosswalkColor,
    crosswalkColorTransition,
    strokeWidth,
    isBlinkingTransitionTact = true,
  } = tlCrossroad;

  const tactType = ((phaseIdx + 1) % 2) as 0 | 1;
  const tact = tlTacts?.[phaseIdx];
  const mainColor = color ?? MAIN;
  const curColor = tactType ? mainColor : colorTransition ?? MAIN_TRANSITION;

  const curCrosswalkColor = tactType
    ? crosswalkColor ?? CROSSWALK
    : crosswalkColorTransition ?? CROSSWALK_TRANSITION;

  const { data, isLoading } = useFetchCrossroadSvg({ id, appPath: APP_PATH });

  useCrossroadDirsEls({
    data,
    id,
    color: curColor,
    crosswalkColor: curCrosswalkColor,
    strokeWidth,
    tact,
    mainColor,
    tactType,
  });

  useDirections({
    phaseIdx,
    id,
    tact,
    tactType,
    isBlinkingTransitionTact,
    data,
  });

  return { data, isLoading };
};

export default useCrossroadDirsImg;
