import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { FiInfo } from 'react-icons/fi';

import rootStore from '../../../stores/rootStore/rootStore';
import Popover from '../Popover/Popover';

import { INFO_COLOR, StatusInfoBtn } from './constants/constants';

import styles from './InfoBtn.module.scss';

interface InfoBtnProps {
  isShow?: boolean;
  status?: StatusInfoBtn;
  popoverContent: string;
}

const { Info } = StatusInfoBtn;

const InfoBtn: FC<InfoBtnProps> = ({
  isShow = true,
  status = Info,
  popoverContent,
}) => {
  const { interfaceTipsDelay } = rootStore.uiStore;

  if (!isShow) return null;

  return (
    <Popover content={popoverContent} tipsDelay={interfaceTipsDelay}>
      <span className={styles.ico}>
        <FiInfo style={{ color: INFO_COLOR[status] }} />
      </span>
    </Popover>
  );
};

export default observer(InfoBtn);
