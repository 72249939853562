import { Feature } from 'ol';
import type { FeatureLike } from 'ol/Feature';
import { Fill, Stroke, Style, Text } from 'ol/style';

import { TEMPORARY_GRAPHICS_STYLE } from '../../constants/styles';

export const styleFunction = () => {
  return (feature: FeatureLike | Feature): Style | undefined => {
    if (!feature) {
      return;
    }

    const properties = feature.getProperties();

    const num = properties['objectNumber'] || '';

    return new Style({
      text: new Text({
        text: num,
        font: 'bold 16px arial',
        offsetY: 8,
        stroke: new Stroke({
          width: TEMPORARY_GRAPHICS_STYLE.TEXT_STROKE_WIDTH,
          color: TEMPORARY_GRAPHICS_STYLE.TEXT_STROKE_COLOR,
        }),
      }),
      stroke: new Stroke({
        color: TEMPORARY_GRAPHICS_STYLE.STROKE_COLOR,
        width: TEMPORARY_GRAPHICS_STYLE.STROKE_WIDTH,
      }),
      fill: new Fill({
        color: TEMPORARY_GRAPHICS_STYLE.FILL_COLOR,
      }),
    });
  };
};
