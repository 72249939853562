import { IS_DEV } from '../../../../constants/constants';
import rootStore from '../../../../stores/rootStore/rootStore';
import { Path } from '../../../api.model';
import { getAppPathHeaders } from '../../../helpers/getAppPathHeaders';
import http from '../../../http';
import tryCatchWrapper from '../../../tryCatchWrapper';

import { Weather } from './model/weather.model';
import { WeatherSchema } from './weather.zod';

const GUID = 'a54ce54e-57ed-4204-b9b3-75cd6184eb91';

const WEATHER = `serverless/guid/${GUID}/getWeatherTime`;

interface WeatherProps {
  longitude: number;
  latitude: number;
  appPath: Path;
}

export const weatherApi = {
  fetchWeather: ({ longitude, latitude, appPath }: WeatherProps) =>
    tryCatchWrapper(
      async () => {
        const { urlConfigurations } = rootStore.userDataStore;

        if (!urlConfigurations) {
          console.error('Ошибка получения urlConfigurations');

          return;
        }

        const { gisApplicationServer } = urlConfigurations;

        const res = await http.get<Weather>(
          `${gisApplicationServer}/${WEATHER}`,
          {
            params: {
              x: longitude,
              y: latitude,
            },
            headers: getAppPathHeaders(appPath),
          }
        );

        const validate = WeatherSchema.safeParse(res.data);

        if (!validate.success) {
          IS_DEV &&
            console.error('Ошибка валидации получения погоды', validate.error);

          return;
        }

        return res.data;
      },
      {
        isBlockedNotify: true,
      }
    ),
};

export default weatherApi;
