import { uniqueId } from 'lodash';
import { FC } from 'react';

import styles from './BodyContent.module.scss';

interface IBodyContent {
  title: N<JSX.Element>;
  content?: string[];
  classNameContainer?: string;
}

const BodyContent: FC<IBodyContent> = ({
  title,
  content,
  classNameContainer = styles.container,
}) => {
  const contentLabels = content?.sort();

  return (
    <div className={classNameContainer}>
      {title}
      {contentLabels?.map((item) => <h3 key={uniqueId('label_')}>{item}</h3>) ??
        null}
    </div>
  );
};

export default BodyContent;
