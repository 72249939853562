/* eslint-disable jsx-a11y/no-static-element-interactions */
import classNames from 'classnames';
import { FC } from 'react';

import styles from './ArrowCustom.module.scss';

interface ISampleArrowProps {
  className?: string;
  onClick?: () => void;
}

const ArrowCustom: FC<ISampleArrowProps> = ({ className = '', onClick }) => {
  const styleArrow = classNames({
    [styles.arrow]: true,
    [className]: className,
  });

  return <div className={styleArrow} onClick={onClick} />;
};

export default ArrowCustom;
