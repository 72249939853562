import classNames from 'classnames';
import { FC } from 'react';

import styles from './Counter.module.scss';

interface ICounter {
  count: number;
  isSmall?: boolean;
}

const Counter: FC<ICounter> = ({ count, isSmall = false }) => {
  if (!count) return null;

  const wrapperStyle = classNames({
    [styles.wrapper]: true,
    [styles.small]: isSmall,
  });

  return <span className={wrapperStyle}>{count}</span>;
};

export default Counter;
