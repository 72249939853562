import classNames from 'classnames';
import { FC } from 'react';

import { TActiveSystemMonitoring } from '../../../../../../stores/monitoringPanelStore/monitoringPanelStore.model';
import { getAvatarIcon } from '../../../../../DetailedStatistics/Monitoring/SystemMonitoring/helpers/helpers';

import styles from './SystemItem.module.scss';

interface ISystemItemProps {
  system: TActiveSystemMonitoring;
  classNameItem?: string;
  isHover?: boolean;
  isActive?: boolean;
}

const SystemItem: FC<ISystemItemProps> = ({
  system,
  classNameItem = '',
  isHover = true,
  isActive = false,
}) => {
  const styleContainer = classNames({
    [styles.circle]: true,
    [styles.circleHover]: isHover,
    [styles.active]: isActive,
    [classNameItem]: classNameItem,
  });

  return <div className={styleContainer}>{getAvatarIcon(system)}</div>;
};

export default SystemItem;
