import { COLORS } from '../../../constants/colorsConstants';
import rootStore from '../../../stores/rootStore/rootStore';

const getIcoColor = (color: Nullish<string>, defaultColor = COLORS.PRIMARY) => {
  const { isLinkedStatus } = rootStore.constructorStore;

  return (isLinkedStatus && color) || defaultColor;
};

export default getIcoColor;
