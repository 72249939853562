/* eslint-disable no-template-curly-in-string */
import { CONFIG } from '../../../constants/configConstants';
import { IBaseMapItem } from '../../../stores/gisDataStore/gisDataStore.model';

import { BASEMAPS_TYPES } from './utils';

const { OSM_SOURCE } = CONFIG;
const OSM_URL = OSM_SOURCE?.url
  ? OSM_SOURCE.url
  : 'https://${subDomain}.openstreetmap.org/${level}/${col}/${row}.png';

const imageSrc = OSM_URL.replace('${subDomain}', 'tile')
  .replace('${level}', '13')
  .replace('${col}', '4791')
  .replace('${row}', '2387')
  .replace('{z}', '13')
  .replace('{x}', '4791')
  .replace('{y}', '2387');

export const OSM_ITEM: IBaseMapItem = {
  text: 'OSM',
  imageSrc,
  url: OSM_URL,
  subDomains: ['tile'],
  id: 'OSM',
  type: BASEMAPS_TYPES.XYZ,
  maxZoom: 22,
  description: 'Базовая карта OSM (NJS)',
};
