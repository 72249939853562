import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import LayerTooltipContent from '../../Layers/LayerTooltipContent/LayerTooltipContent';
import MapTooltip from '../../MapTooltip/MapTooltip';
import { useCreateOverlay } from '../hooks';
import TooltipContent from '../TooltipContent/TooltipContent';

const Tooltips: FC = () => {
  const { tooltipData, layerTooltipData, isTooltip, isLayerTooltip } =
    rootStore.uiStore;

  const { tooltipRef, layerTooltipRef } = rootStore.mapStore;

  useCreateOverlay('tooltip', tooltipRef);
  useCreateOverlay('layerTooltip', layerTooltipRef);

  const systemsTooltip = {
    rerenderData: tooltipData,
    tooltipRef,
    isTooltip,
  };

  const layerTooltip = {
    rerenderData: layerTooltipData,
    tooltipRef: layerTooltipRef,
    isTooltip: isLayerTooltip,
  };

  return (
    <>
      <MapTooltip {...systemsTooltip}>
        <TooltipContent />
      </MapTooltip>
      <MapTooltip {...layerTooltip}>
        <LayerTooltipContent />
      </MapTooltip>
    </>
  );
};

export default observer(Tooltips);
