import { FC } from 'react';
import { Joystick } from 'react-joystick-component';

import { COLORS } from '../../../../constants/colorsConstants';
import Item from '../Item/Item';
import Slider from '../Slider/Slider';

import useOffset from './hooks/useOffset';
import { OffsetProps } from './Offset.model';

import styles from './Offset.module.scss';

export const OFFSET_STEP = 1;

const Offset: FC<OffsetProps> = (props) => {
  const {
    isVisible = true,
    label = 'Смещение элемента',
    step = OFFSET_STEP,
  } = props;

  const {
    xOffset,
    yOffset,
    range,
    handleOffsetX,
    handleOffsetY,
    handleJoystick,
  } = useOffset(props);

  if (!isVisible) return null;

  return (
    <>
      <Item label={`${label} XY:`}>
        <div className={styles.joystick}>
          <Joystick
            size={100}
            sticky
            baseColor={COLORS.PRIMARY}
            stickColor={COLORS.TURQUOISE}
            move={handleJoystick}
          />
        </div>
      </Item>

      <Slider
        label={`${label} (ось X):`}
        value={xOffset}
        min={-range}
        max={range}
        step={step}
        onChange={handleOffsetX}
      />

      <Slider
        label={`${label} (ось Y):`}
        value={-yOffset}
        min={-range}
        max={range}
        step={step}
        onChange={handleOffsetY}
      />
    </>
  );
};

export default Offset;
