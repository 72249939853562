import classNames from 'classnames';
import { uniqueId } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../../stores/rootStore/rootStore';
import ImageGallery from '../../../../ui-kit/ImageGallery/ImageGallery';
import { getTypeTactObj } from '../helpers/getTypeTactObj';
import PhaseCard from '../PhaseCard/PhaseCard';
import { PhasesInfo } from '../PhaseCardsList';

import styles from './PhasesCards.module.scss';

interface IPhaseCardList {
  phases: PhasesInfo;
  className?: string;
}

const PhasesCards: FC<IPhaseCardList> = ({ phases, className = '' }) => {
  const { phasesArray } = rootStore.uiStore;

  const listContainerStyle = classNames({
    [styles.list]: true,
    [className]: className,
  });

  return (
    <ul className={listContainerStyle}>
      <ImageGallery>
        {phasesArray.map((phase) => (
          <PhaseCard
            key={uniqueId('#')}
            phase={phase}
            tactTypeObj={getTypeTactObj(phase.tactType)}
            {...phases}
          />
        ))}
      </ImageGallery>
    </ul>
  );
};

export default observer(PhasesCards);
