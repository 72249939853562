import { VERSION } from '../../constants/versions';
import { MapObject } from '../../ts/enums/enums';
import { BasicMapDataZod } from '../../ts/models/mapObject.model';
import { Path } from '../api.model';
import { formattedMapData } from '../helpers/formattedMapData';
import { getAppPathHeaders } from '../helpers/getAppPathHeaders';
import { validateMapObj } from '../helpers/validateData';
import http from '../http';
import tryCatchWrapper from '../tryCatchWrapper';

const OBJECTS = `${VERSION}/objects/`;

interface ObjectItemProps {
  appPath?: Path;
  isBlockedNotify?: boolean;
}

const mapObjectsApi = {
  fetchFullObject: <T extends BasicMapDataZod>(
    type: MapObject,
    regionId: number
  ) =>
    tryCatchWrapper(async () => {
      const res = await http(OBJECTS + type, {
        params: {
          regionId,
        },
      });
      const data = res?.data?.data;

      const validateData = validateMapObj<T>(type, data);

      if (!validateData) return;

      return formattedMapData<T>(validateData);
    }),

  fetchObjectItem: (type: MapObject, id: number, options?: ObjectItemProps) =>
    tryCatchWrapper(
      async () => {
        const res = await http.get(`${OBJECTS + type}/${id}`, {
          headers: options?.appPath
            ? getAppPathHeaders(options.appPath)
            : undefined,
        });
        const data = res?.data?.data;

        return validateMapObj(type, [data]);
      },
      {
        isBlockedNotify: options?.isBlockedNotify,
      }
    ),

  fetchDeviceActualInfo: (deviceId: number, appPath: Path) =>
    tryCatchWrapper(async () => {
      const res = await http.get(OBJECTS + deviceId, {
        headers: getAppPathHeaders(appPath),
        params: {
          dataTypeId: 82,
        },
      });

      const tLanes = res?.data?.crossroad?.trafficLanes ?? [];

      return tLanes[0] ?? res?.data;
    }),
};

export default mapObjectsApi;
