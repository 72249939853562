const data = {
  meteo: [
    {
      id: 3261,
      caption: 'МС-261',
      longitude: 43.63278782507757,
      latitude: 43.50325865479446,
      info: 'Сведения о погодных условиях:\n 1)\tОтносительная влажность воздуха, 80%;\n2)\tАтмосферное давление, 755 мм рт. ст.;\n3)\tОсадки - нет;\n4)\tПорывы ветра, 2  м/с;\n5)\tСкорость ветра, 1 м/с;\n6)\tТочка росы 2 ;\n7)\tИнтенсивность осадков, 0 мм/ч;\n8)\tТемпература воздуха, 10 °С; \n',
    },
    {
      id: 3167,
      caption: 'МС-167',
      longitude: 43.60957675582372,
      latitude: 43.518918363471975,
      info: 'Сведения о погодных условиях:\n 1)\tОтносительная влажность воздуха, 80%;\n2)\tАтмосферное давление, 755 мм рт. ст.;\n3)\tОсадки - нет;\n4)\tПорывы ветра, 2  м/с;\n5)\tСкорость ветра, 1 м/с;\n6)\tТочка росы 2 ;\n7)\tИнтенсивность осадков, 0 мм/ч;\n8)\tТемпература воздуха, 10 °С; \n',
    },
    {
      id: 3725,
      caption: 'МС-725',
      longitude: 43.648320790274106,
      latitude: 43.46884125258505,
      info: 'Сведения о погодных условиях:\n 1)\tОтносительная влажность воздуха, 80%;\n2)\tАтмосферное давление, 755 мм рт. ст.;\n3)\tОсадки - нет;\n4)\tПорывы ветра, 2  м/с;\n5)\tСкорость ветра, 1 м/с;\n6)\tТочка росы 2 ;\n7)\tИнтенсивность осадков, 0 мм/ч;\n8)\tТемпература воздуха, 10 °С; \n',
    },
    {
      id: 3026,
      caption: 'МС-26',
      longitude: 43.57929570401366,
      latitude: 43.46876983501002,
      info: 'Сведения о погодных условиях:\n 1)\tОтносительная влажность воздуха, 80%;\n2)\tАтмосферное давление, 755 мм рт. ст.;\n3)\tОсадки - нет;\n4)\tПорывы ветра, 2  м/с;\n5)\tСкорость ветра, 1 м/с;\n6)\tТочка росы 2 ;\n7)\tИнтенсивность осадков, 0 мм/ч;\n8)\tТемпература воздуха, 10 °С; \n',
    },
  ],
  rta: [
    {
      id: 4015,
      caption: 'ДТП-15',
      longitude: 43.643150827382151,
      latitude: 43.503804619157563,
      info: 'Информация о ДТП:\n1)\tВремя ДТП: 10.09.2022 09:15;\n2)\tМесто происшествия: ул. Ашурова д.30;\n3)\tНаличие пострадавших: нет;',
    },
    {
      id: 4021,
      caption: 'ДТП-21',
      longitude: 43.626385551645321,
      latitude: 43.49631693277621,
      info: 'Информация о ДТП:\n1)\tВремя ДТП: 10.09.2022 09:15;\n2)\tМесто происшествия: ул. Ломоносова д.98;\n3)\tНаличие пострадавших: нет;',
    },
  ],
  publicTransport: [
    {
      id: 5100,
      caption: 'Автобус-100',
      longitude: 43.642338452466255,
      latitude: 43.507867051686922,
      info: 'Информация:\n1)\tНомер маршрута: 102;\n2)\tГРНЗ: У653КС07;\n3)\tПодрядчик: ООО "АвтоВ";\n',
    },
    {
      id: 5251,
      caption: 'Автобус-251',
      longitude: 43.638182842319537,
      latitude: 43.50378732271367,
      info: 'Информация:\n1)\tНомер маршрута: 12;\n2)\tГРНЗ: Р67ТКС07;\n3)\tПодрядчик: ООО "АвтоВ";\n',
    },
    {
      id: 5258,
      caption: 'Автобус-258',
      longitude: 43.626881011071958,
      latitude: 43.492360510709801,
      info: 'Информация:\n1)\tНомер маршрута: 78;\n2)\tГРНЗ: У123КС07;\n3)\tПодрядчик: ООО "АвтоВ";\n',
    },
    {
      id: 5257,
      caption: 'Автобус-257',
      longitude: 43.630255491491852,
      latitude: 43.498395295799341,
      info: 'Информация:\n1)\tНомер маршрута: 22;\n2)\tГРНЗ: У652КС07;\n3)\tПодрядчик: ООО "АвтоВ";\n',
    },
    {
      id: 5256,
      caption: 'Автобус-256',
      longitude: 43.634951197049695,
      latitude: 43.49511008734823,
      info: 'Информация:\n1)\tНомер маршрута: 43;\n2)\tГРНЗ: У634КС07;\n3)\tПодрядчик: ООО "АвтоВ";\n',
    },
    {
      id: 5255,
      caption: 'Маршрутка-255',
      longitude: 43.630023384373025,
      latitude: 43.495645719160912,
      info: 'Информация:\n1)\tНомер маршрута: М17;\n2)\tГРНЗ: У678КС07;\n3)\tПодрядчик: ООО "МигАвтоВ";\n',
    },
    {
      id: 5254,
      caption: 'Маршрутка-254',
      longitude: 43.633540699942962,
      latitude: 43.499288015487146,
      info: 'Информация:\n1)\tНомер маршрута: М54;\n2)\tГРНЗ: У263КС07;\n3)\tПодрядчик: ООО "АвтоСкВ";\n',
    },
    {
      id: 5252,
      caption: 'Маршрутка-252',
      longitude: 43.626175762518599,
      latitude: 43.501251998800292,
      info: 'Информация:\n1)\tНомер маршрута: М2;\n2)\tГРНЗ: У234КС07;\n3)\tПодрядчик: ООО "МфАвтоВ";\n',
    },
  ],
  specialTransport: [
    {
      id: 6100,
      caption: 'СП-100',
      longitude: 43.617259618360507,
      latitude: 43.500785233934977,
      info: 'Информация:\n1)  Модель / марка ТС:   Камаз;\n2)\tГРНЗ: У653КС07;\n3)\tПодрядчик: ООО "АвтоВ";\n',
    },
    {
      id: 6251,
      caption: 'СП-251',
      longitude: 43.638182842319537,
      latitude: 43.50378732271367,
      info: 'Информация:\n1)  Модель / марка ТС:   Камаз;\n2)\tГРНЗ: Р67ТКС07;\n3)\tПодрядчик: ООО "АвтоВ";\n',
    },
    {
      id: 6257,
      caption: 'СП-257',
      longitude: 43.632563384437127,
      latitude: 43.488049099722325,
      info: 'Информация:\n1)  Модель / марка ТС:   Камаз;\n2)\tГРНЗ: У652КС07;\n3)\tПодрядчик: ООО "АвтоВ";\n',
    },
    {
      id: 6258,
      caption: 'СП-258',
      longitude: 43.626881011071958,
      latitude: 43.492360510709801,
      info: 'Информация:\n1)  Модель / марка ТС:   Камаз;\n2)\tГРНЗ: У123КС07;\n3)\tПодрядчик: ООО "АвтоВ";\n',
    },
    {
      id: 6256,
      caption: 'СП-256',
      longitude: 43.632818447205075,
      latitude: 43.496534187802581,
      info: 'Информация:\n1)  Модель / марка ТС:   Камаз;\n2)\tГРНЗ: У634КС07;\n3)\tПодрядчик: ООО "АвтоВ";\n',
    },
    {
      id: 6255,
      caption: 'СП-255',
      longitude: 43.629077526608569,
      latitude: 43.505036280067365,
      info: 'Информация:\n1)  Модель / марка ТС:   Камаз;\n2)\tГРНЗ: У678КС07;\n3)\tПодрядчик: ООО "МигАвтоВ";\n',
    },
    {
      id: 6254,
      caption: 'СП-254',
      longitude: 43.640538346981501,
      latitude: 43.490038589312285,
      info: 'Информация:\n1)  Модель / марка ТС:   Камаз;\n2)\tГРНЗ: У263КС07;\n3)\tПодрядчик: ООО "АвтоСкВ";\n',
    },
    {
      id: 6252,
      caption: 'СП-252',
      longitude: 43.625302597643,
      latitude: 43.496823258939585,
      info: 'Информация:\n1)  Модель / марка ТС:   Камаз;\n2)\tГРНЗ: У234КС07;\n3)\tПодрядчик: ООО "МфАвтоВ";\n',
    },
  ],
  roadWorks: [
    {
      id: 7253,
      caption: 'ДР-253',
      longitude: 43.638325677469595,
      latitude: 43.500654992509084,
      info: 'Информация о Дорожных работах:\n1)\tТип работ: Замена опоры освещения;\n2)\tАдрес: ул. Мускаева д.196;\n3)\tПодрядчик: ООО "СТВ";\n4)\tОкончание: 01.11.2022;\n',
    },
    {
      id: 7251,
      caption: 'ДР-251',
      longitude: 43.622064788356269,
      latitude: 43.497676656450658,
      info: 'Информация о Дорожных работах:\n1)\tТип работ: Ремонт асфальтофого покрытия;\n2)\tАдрес: район "Завокзальный"\n3)\tПодрядчик: ООО "СпецСтройД";\n4)\tОкончание: 10.11.2022;\n',
    },
    {
      id: 7258,
      caption: 'ДР-258',
      longitude: 43.623078025201927,
      latitude: 43.498399759397778,
      info: 'Информация о Дорожных работах:\n1)\tТип работ: Ремонт асфальтофого покрытия;\n2)\tАдрес: район "Завокзальный"\n3)\tПодрядчик: ООО "СпецСтройД";\n4)\tОкончание: 10.11.2022;\n',
    },
    {
      id: 7257,
      caption: 'ДР-257',
      longitude: 43.621752336465541,
      latitude: 43.498399759397778,
      info: 'Информация о Дорожных работах:\n1)\tТип работ: Ремонт асфальтофого покрытия;\n2)\tАдрес: район "Завокзальный"\n3)\tПодрядчик: ООО "СпецСтройД";\n4)\tОкончание: 10.11.2022;\n',
    },
    {
      id: 7256,
      caption: 'ДР-256',
      longitude: 43.622453121420463,
      latitude: 43.496132251390755,
      info: 'Информация о Дорожных работах:\n1)\tТип работ: Ремонт асфальтофого покрытия;\n2)\tАдрес: район "Завокзальный"\n3)\tПодрядчик: ООО "СпецСтройД";\n4)\tОкончание: 10.11.2022;\n',
    },
    {
      id: 7255,
      caption: 'ДР-255',
      longitude: 43.624278733182017,
      latitude: 43.49679732755817,
      info: 'Информация о Дорожных работах:\n1)\tТип работ: Ремонт асфальтофого покрытия;\n2)\tАдрес: район "Завокзальный"\n3)\tПодрядчик: ООО "СпецСтройД";\n4)\tОкончание: 10.11.2022;\n',
    },
    {
      id: 7254,
      caption: 'ДР-254',
      longitude: 43.622667374145536,
      latitude: 43.497181197023927,
      info: 'Информация о Дорожных работах:\n1)\tТип работ: Ремонт асфальтофого покрытия;\n2)\tАдрес: район "Завокзальный"\n3)\tПодрядчик: ООО "СпецСтройД";\n4)\tОкончание: 10.11.2022;\n',
    },
    {
      id: 7252,
      caption: 'ДР-252',
      longitude: 43.623823446141238,
      latitude: 43.497926617963238,
      info: 'Информация о Дорожных работах:\n1)\tТип работ: Ремонт асфальтофого покрытия;\n2)\tАдрес: район "Завокзальный"\n3)\tПодрядчик: ООО "СпецСтройД";\n4)\tОкончание: 10.11.2022;\n',
    },
  ],
};

export default data;
