import { LibraryKeysZod } from '../../../../../../api/libraries/libraries.zod';

export const getParsedLibraryValue = (
  value: string | number,
  library?: LibraryKeysZod[]
): string | number => {
  if (!library) return value;

  const findValue = library.find(
    ({ id }) => String(id) === String(value)
  )?.name;

  return findValue || value;
};
