import { useEffect } from 'react';

import { DEFAULT_CROSSROAD_COLORS } from '../../../constants/colorsConstants';
import {
  changeCrossroadEls,
  changeCrosswalkEls,
} from '../helpers/changeCrossroadEls';
import { CrossroadDirsElsArgs } from '../models/crossroad.model';
import { getAllDirsElements } from '../PhaseCircleOverlay/helpers/getDirsElements';

const { MAIN } = DEFAULT_CROSSROAD_COLORS;

const useCrossroadDirsEls = ({
  data,
  id,
  color = '',
  crosswalkColor = 'blue',
  strokeWidth = 1,
  isCrossroad = true,
  tact,
  mainColor,
  tactType,
}: CrossroadDirsElsArgs) => {
  useEffect(() => {
    if (!data || !id || !isCrossroad || !tact) return;
    const { direction_green_in_phase: greenDirs, direction } = tact;

    const { dirsEls, greenDirsEls } = getAllDirsElements(
      direction,
      greenDirs,
      id
    );

    const crosswalkLines = document.querySelectorAll(`.st${id}-crosswalk-line`);

    const crosswalkPolygons = document.querySelectorAll(
      `.st${id}-crosswalk-polygon`
    );

    changeCrossroadEls({
      dirs: greenDirsEls,
      color: mainColor ?? MAIN,
      strokeWidth,
    });

    changeCrossroadEls({ dirs: dirsEls, color, strokeWidth });
    changeCrosswalkEls(crosswalkLines, crosswalkColor);
    changeCrosswalkEls(crosswalkPolygons, crosswalkColor);
  }, [
    color,
    data,
    id,
    strokeWidth,
    crosswalkColor,
    isCrossroad,
    tact,
    mainColor,
    tactType,
  ]);
};

export default useCrossroadDirsEls;
