import rootStore from '../../../stores/rootStore/rootStore';

const MIN_ICO_SIZE = 18;

const getIconSize = (size: number, rate?: number) => {
  const { calcCircleVal } = rootStore.pointsUdsStore;

  const curSize = calcCircleVal(size, rate);

  return curSize < MIN_ICO_SIZE ? MIN_ICO_SIZE : curSize;
};

export default getIconSize;
