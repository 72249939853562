import getImagePostfix from '../../../../helpers/getImagePostfix';
import { IMG_PATH } from '../../../../ts/enums/tl';
import { TLDetailsImage, TlMode } from '../../../../ts/models/tl.model';

const getImg = (
  tkt: number | string,
  mode: TlMode,
  images: N<TLDetailsImage[]>
) => {
  const type = getImagePostfix(mode) ?? tkt;

  const imageUrl = images?.find(({ image }) =>
    image.includes(type.toString().toUpperCase())
  )?.image;

  return imageUrl ? IMG_PATH + imageUrl : null;
};

export default getImg;
