import { Feature } from 'ol';
import EsriJSON from 'ol/format/EsriJSON';
import { Point } from 'ol/geom';
import ImageLayer from 'ol/layer/Image';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style, Text } from 'ol/style';
import IDW from 'ol-ext/source/IDW';
import { WindLayer } from 'ol-wind';

import { Z_INDEX_WEATHER_LAYER } from '../../../apiGIS/constants/map';
import {
  DX,
  DY,
  GRADIENT_LAYER_SCALE,
  GRADIENT_PRECIPITATION_SCALE,
  IDistanceValue,
  IDW_LAYER_OPACITY,
  IDW_PRECIPITATION_OPACITY,
  MAX_WIND_VALUE,
  PRECIPITATION_HAS_VALUE_COLOR,
  PRECIPITATION_NO_VALUE_COLOR,
  PRECIPITATION_PATHS,
  PRECIPITATION_SCALE,
  PRECIPITATION_TRAIL_WIDTH,
  PROPERTY_NAMES,
  WEATHER_ITEMS,
  WEATHER_LAYER_ITEMS,
  WIND_PATHS,
  WIND_SCALE,
  WIND_TRAIL_WIDTH,
} from '../../../apiGIS/constants/weather';
import {
  addBWFilter,
  getBasemap,
  restoreDefaultFilter,
} from '../../../apiGIS/utils/basemaps';
import weatherService from '../../../stores/mapDataStore/serverapi.service';
import { TMap } from '../../../stores/mapStore/mapStore.model';
import rootStore from '../../../stores/rootStore/rootStore';
import {
  IWeatherClientDataset,
  IWeatherCombination,
  IWeatherDataset,
  IWeatherItem,
  IWeatherLayerItem,
} from '../IWeatherTimeModel';

const weatherCombinations: IWeatherCombination[] = [
  {
    propertyName: PROPERTY_NAMES.TEMPERATURE,
    windAnimation: true,
    precipitationAnimation: false,
    labels: true,
    definition: getWeatherItem(PROPERTY_NAMES.TEMPERATURE),
  },
  {
    propertyName: PROPERTY_NAMES.PRECIPITATION,
    windAnimation: false,
    precipitationAnimation: true,
    labels: true,
    definition: getWeatherItem(PROPERTY_NAMES.PRECIPITATION),
  },
  {
    propertyName: PROPERTY_NAMES.HUMIDITY,
    windAnimation: true,
    precipitationAnimation: false,
    labels: true,
    definition: getWeatherItem(PROPERTY_NAMES.HUMIDITY),
  },
  {
    propertyName: PROPERTY_NAMES.PRESSURE,
    windAnimation: true,
    precipitationAnimation: false,
    labels: true,
    definition: getWeatherItem(PROPERTY_NAMES.PRESSURE),
  },
  {
    propertyName: PROPERTY_NAMES.WIND_SPEED,
    windAnimation: true,
    precipitationAnimation: false,
    labels: true,
    definition: getWeatherItem(PROPERTY_NAMES.WIND_SPEED),
  },
  {
    propertyName: PROPERTY_NAMES.DEW_POINT,
    windAnimation: true,
    precipitationAnimation: false,
    labels: true,
    definition: getWeatherItem(PROPERTY_NAMES.DEW_POINT),
  },
  {
    propertyName: PROPERTY_NAMES.BLACK_ICE,
    windAnimation: true,
    precipitationAnimation: false,
    labels: true,
    definition: getWeatherItem(PROPERTY_NAMES.BLACK_ICE),
  },
];

const { setGisValue } = rootStore.gisDataStore;

const getDataset = async (
  map: TMap,
  propertyName: string,
  time: string
): Promise<{
  features: Feature[];
  uv: any;
  precipitation: any;
  time: string;
}> => {
  try {
    const regionId = localStorage.getItem('regionId');

    const { data: weatherPackage } = await weatherService.execute(
      regionId || '',
      time
    );

    const timeData: any = weatherPackage.find(
      (item: any) => item.time === time
    );

    const data = {
      features: timeData.features,
    };

    const uv = timeData.uv;
    const precipitation = timeData.precipitation;

    const format = new EsriJSON();
    const features = format.readFeatures(data, {}) as Feature[];

    return {
      time,
      features,
      uv,
      precipitation,
    };
  } catch (e) {
    return {
      time: '',
      features: [],
      uv: null,
      precipitation: null,
    };
  }
};

const getMapLayers = (map: TMap) => {
  if (!map) {
    return [];
  }

  return map.getLayers().getArray();
};

function getWeatherItem(propertyName: string): IWeatherLayerItem {
  const defaultItem = WEATHER_ITEMS[0];

  return (
    WEATHER_ITEMS.find((element) => element.propertyName === propertyName) ||
    defaultItem
  );
}

const getScaledValue = (
  value: number,
  sourceMin: number,
  sourceMax: number,
  targetMin: number,
  targetMax: number
) => {
  const targetRange = targetMax - targetMin;
  const sourceRange = sourceMax - sourceMin;

  return ((value - sourceMin) * targetRange) / sourceRange + targetMin;
};

const getLayer = (map: TMap, id: string) => {
  const layers = getMapLayers(map);

  return layers.find((item) => item.get('id') === id);
};

const removeLayer = (map: TMap, id: string) => {
  const layer = getLayer(map, id);

  if (map && layer) {
    map.removeLayer(layer);
  }
};

const removeGradientLayer = (map: TMap) => {
  const layerId = WEATHER_LAYER_ITEMS.factor.id;

  removeLayer(map, layerId);
};

const normalizeWeight = (
  value: number,
  propertyName: string,
  definition: IWeatherLayerItem
) => {
  const { gradient } = definition;
  const colorStops = gradient?.colorStops || [];
  const values = colorStops.map((stop) => stop.value);
  const min = Math.min(...values);
  const max = Math.max(...values);

  return getScaledValue(value, min, max, 0, 100);
};

// TODO temporary solutions - fix it after design will corrected
const getPrecipitationColor = (value: number) => {
  if (value < 0.001) {
    return PRECIPITATION_NO_VALUE_COLOR;
  }

  if (value < 0.01) {
    return [10, 40, 120, 10];
  }

  if (value < 0.02) {
    return [10, 40, 120, 120];
  }

  if (value < 0.25) {
    return [10, 40, 120, 160];
  }

  return [10, 40, 120, 180];
};

function getWeatherColor(
  value: number,
  propertyName: string,
  definition: IWeatherLayerItem,
  minValue?: number,
  maxValue?: number
) {
  const { gradient } = definition;
  const colorStops = gradient?.colorStops || [];
  const values = colorStops.map((stop) => stop.value);

  const rgbaValues = colorStops.map((stop) => {
    const { color } = stop;
    const rgbaParts = color
      .replace('rgba(', '')
      .replace(')', '')
      .split(',')
      .map((part) => Number(part));

    const [r, g, b, a] = rgbaParts;

    return { r, g, b, a };
  });

  const min = Math.min(...values);
  const max = Math.max(...values);

  if (propertyName === 'precipitation') {
    return getPrecipitationColor(value);
  }

  const baseValue = getScaledValue(value, 0, 100, min, max);

  let startIdx = 0;
  let endIdx = colorStops.length - 1;

  const minColorStopValue = colorStops[0].value;
  const maxColorStopValue = colorStops[colorStops.length - 1].value;

  for (let i = 0; i < colorStops.length - 1; i++) {
    const stop1 = colorStops[i];
    const stop2 = colorStops[i + 1];

    const value1 = stop1.value;
    const value2 = stop2.value;

    if (baseValue <= minColorStopValue) {
      startIdx = 0;
      endIdx = 0;
      break;
    }

    if (baseValue >= maxColorStopValue) {
      startIdx = colorStops.length - 1;
      endIdx = colorStops.length - 1;
      break;
    }

    if (baseValue >= value1 && baseValue <= value2) {
      startIdx = i;
      endIdx = i + 1;
    }
  }

  const color1 = rgbaValues[startIdx];
  const color2 = rgbaValues[endIdx];

  const r_ = Math.round((color1.r + color2.r) / 2);
  const g_ = Math.round((color1.g + color2.g) / 2);
  const b_ = Math.round((color1.b + color2.b) / 2);
  const a_ = Math.round((255 * (color1.a + color2.a)) / 2);

  return [r_, g_, b_, a_];
}

function createIDWSource(
  dataset: IWeatherDataset,
  propertyName: string,
  definition: IWeatherLayerItem
) {
  const { features } = dataset;

  const SCALE =
    propertyName === PROPERTY_NAMES.PRECIPITATION
      ? GRADIENT_PRECIPITATION_SCALE
      : GRADIENT_LAYER_SCALE;

  const sourceSettings = {
    scale: SCALE,
    useWorker: false,
    getColor: (value: number) => {
      return getWeatherColor(value, propertyName, definition);
    },
    source: new VectorSource({ features }),
    weight: (feature: Feature) => {
      const value = feature.get(propertyName);

      return normalizeWeight(value, propertyName, definition);
    },
  } as const;

  return new IDW(sourceSettings);
}

const addGradientLayer = (
  map: TMap,
  propertyName: string,
  time: string,
  dataset: any,
  definition: IWeatherLayerItem
) => {
  if (!map) {
    return;
  }

  if (!propertyName) {
    return;
  }

  const layerId = WEATHER_LAYER_ITEMS.factor.id;

  removeGradientLayer(map);

  const source = createIDWSource(dataset, propertyName, definition);

  const OPACITY =
    propertyName === PROPERTY_NAMES.PRECIPITATION
      ? IDW_PRECIPITATION_OPACITY
      : IDW_LAYER_OPACITY;

  const layer = new ImageLayer({
    source,
    opacity: OPACITY,
  });

  layer.set('id', layerId);

  map.addLayer(layer);

  return layer;
};

const removeWindAnimation = (map: TMap) => {
  const layerId = WEATHER_LAYER_ITEMS.windAnimation.id;

  removeLayer(map, layerId);
};

const addWindAnimation = (
  map: TMap,
  propertyName: string,
  time: string,
  dataset: any,
  definition: IWeatherLayerItem
) => {
  if (!map) {
    return;
  }

  const layerId = WEATHER_LAYER_ITEMS.windAnimation.id;

  removeWindAnimation(map);

  const uv = dataset.uv;

  const layer = new WindLayer(uv, {
    windOptions: {
      forceRender: false,
      velocityScale: () => {
        // @ts-ignore
        const value = WIND_SCALE * map.getView().getResolution();

        return value >= MAX_WIND_VALUE ? MAX_WIND_VALUE : value;
      },
      paths: WIND_PATHS,
      colorScale: ['rgba(255,255,255,255)'],
      lineWidth: WIND_TRAIL_WIDTH,
      globalAlpha: 0.9,
    },
    fieldOptions: {
      wrapX: true,
    },
  });

  layer.set('id', layerId);
  layer.setZIndex(Z_INDEX_WEATHER_LAYER);

  map.addLayer(layer);
};

const removePrecipitationAnimation = (map: TMap) => {
  const layerId = WEATHER_LAYER_ITEMS.precipitationLayer.id;

  removeLayer(map, layerId);
};

const addPrecipitationAnimation = (
  map: TMap,
  propertyName: string,
  time: string,
  dataset: any,
  definition: IWeatherLayerItem
) => {
  if (!map) {
    return;
  }

  const layerId = WEATHER_LAYER_ITEMS.precipitationLayer.id;

  removePrecipitationAnimation(map);

  const uv = dataset.precipitation;

  const layer = new WindLayer(uv, {
    windOptions: {
      forceRender: false,
      velocityScale: () => {
        // @ts-ignore
        return PRECIPITATION_SCALE * map.getView().getResolution();
      },
      colorScale: (value: number) => {
        if (value < 1) {
          return PRECIPITATION_NO_VALUE_COLOR;
        }

        return PRECIPITATION_HAS_VALUE_COLOR;
      },
      paths: PRECIPITATION_PATHS,
      lineWidth: PRECIPITATION_TRAIL_WIDTH,
      generateParticleOption: false,
    },
    fieldOptions: {
      wrapX: true,
    },
  });

  layer.set('id', layerId);
  layer.setZIndex(0);

  map.addLayer(layer);
};

const removeLabels = (map: TMap) => {
  const layerId = WEATHER_LAYER_ITEMS.labelsLayer.id;

  removeLayer(map, layerId);
};

const addLabels = (
  map: TMap,
  propertyName: string,
  time: string,
  dataset: IWeatherClientDataset,
  definition: IWeatherLayerItem
) => {
  if (!map) {
    return;
  }

  const layerId = WEATHER_LAYER_ITEMS.labelsLayer.id;
  const units = definition.units;

  removeLabels(map);

  const { features } = dataset;

  const labelFeatures = features
    .filter((feature) => {
      const geometry = feature.getGeometry();

      return geometry instanceof Point;
    })
    .map((feature) => {
      // @ts-ignore
      const coordinates = feature.getGeometry().getCoordinates();

      const [x, y] = coordinates;

      const dx = DX - Math.random() * DX * 2;
      const dy = DY - Math.random() * DY * 2;

      const geometry = new Point([x + dx, y + dy]);

      const labelFeature = new Feature({ geometry });

      labelFeature.set(propertyName, feature.get(propertyName));

      return labelFeature;
    });

  const source = new VectorSource({ features: labelFeatures });

  const layer = new VectorLayer({
    source,
    style: (feature, resolution) => {
      const value = feature.get(propertyName)
        ? feature.get(propertyName)
        : null;
      let label = value ? value.toString() : ``;

      if (Number.isFinite(value) && value) {
        label = `${value.toString()} ${units}`;

        if (propertyName === PROPERTY_NAMES.PRESSURE) {
          label = `${Number(value).toFixed(0)} ${units}`;
        }

        if (propertyName === PROPERTY_NAMES.TEMPERATURE) {
          label = `${Number(value).toFixed(0)} ${units}`;
        }

        if (propertyName === PROPERTY_NAMES.HUMIDITY) {
          label = `${Number(value).toFixed(0)} ${units}`;
        }
      }

      return new Style({
        text: new Text({
          text: label,
          font: '18px arial',
          stroke: new Stroke({
            width: 5,
            color: 'rgba(1,1,1,255)',
          }),
          fill: new Fill({
            color: 'rgba(255,255,255,255)',
          }),
        }),
      });
    },
  });

  layer.set('id', layerId);
  layer.set('propertyName', propertyName);

  map.addLayer(layer);
};

const setBasemapBW = (map: TMap) => {
  const basemap = getBasemap(map);

  if (basemap instanceof TileLayer) {
    addBWFilter(basemap);
  }
};

const setBasemapNormal = (map: TMap) => {
  const basemap = getBasemap(map);

  restoreDefaultFilter(basemap);
};

export const handleChangeWeather = async (
  map: TMap,
  weatherLayer: IWeatherItem
) => {
  const { propertyName, time, showAnimation } = weatherLayer;

  if (!map || !time || !propertyName) {
    setGisValue('isLoadWeather', false);

    return;
  }

  if (propertyName === PROPERTY_NAMES.NONE) {
    removeWindAnimation(map);
    removePrecipitationAnimation(map);
    removeGradientLayer(map);
    removeLabels(map);
    setBasemapNormal(map);

    return;
  }

  setGisValue('isLoadWeather', true);

  const dataset = (await getDataset(map, propertyName, time)) || [];

  const combination = weatherCombinations.find(
    (element) => element.propertyName === propertyName
  );

  if (!combination) {
    setGisValue('isLoadWeather', false);

    return;
  }

  const { windAnimation, precipitationAnimation, labels, definition } =
    combination;

  removeGradientLayer(map);
  removeWindAnimation(map);
  removePrecipitationAnimation(map);
  removeLabels(map);
  setBasemapNormal(map);

  addGradientLayer(map, propertyName, time, dataset, definition);
  setBasemapBW(map);

  showAnimation &&
    windAnimation &&
    addWindAnimation(map, propertyName, time, dataset, definition);

  showAnimation &&
    precipitationAnimation &&
    addPrecipitationAnimation(map, propertyName, time, dataset, definition);

  labels && addLabels(map, propertyName, time, dataset, definition);
  setGisValue('isLoadWeather', false);
};

export const getWeatherParams = (map: TMap, coordinates: number[]) => {
  const layerId = WEATHER_LAYER_ITEMS.labelsLayer.id;
  const layer = getLayer(map, layerId);

  if (!layer || !(layer instanceof VectorLayer)) {
    return {};
  }

  const propertyName = layer.get('propertyName');
  const features = layer.getSource().getFeatures();

  const distancesValues: IDistanceValue[] = [];

  for (let i = 0; i < features.length; i++) {
    const feature = features[i];
    const geometry = feature.getGeometry() as Point;
    const geometryCoordinates = geometry.getCoordinates();

    const [xCenter, yCenter] = coordinates;
    const [x, y] = geometryCoordinates;

    const dx2 = Math.pow(xCenter - x, 2);
    const dy2 = Math.pow(yCenter - y, 2);

    const radius = Math.sqrt(dx2 + dy2);
    const value = feature.get(propertyName);

    distancesValues.push({ radius, value });
  }

  let distance = Infinity;
  let value = 0;

  for (let i = 0; i < distancesValues.length; i++) {
    const item = distancesValues[i];

    if (item.radius <= distance) {
      distance = item.radius;
      value = item.value;
    }
  }

  const weatherItem = WEATHER_ITEMS.find(
    (element) => element.propertyName === propertyName
  );

  if (!weatherItem) {
    return {
      title: 'нет данных',
    };
  }

  const title = Number.isFinite(value)
    ? weatherItem.buttonText + `: ${value} ` + weatherItem.units
    : weatherItem.buttonText + ': нет данных';

  return {
    title,
  };
};
