import { useEffect, useMemo } from 'react';

import api from '../../../../../../api';
import rootStore from '../../../../../../stores/rootStore/rootStore';
import { GraphTypes } from '../../../../../TrafficLightDetailed/TlDetectors/constants/constants';

const { useDtState } = api.services.dtStatistics.dt.dtStateApi;

const APP_PATH = 'UIKit.Chart.ButtonsPanel.GraphTypesBtns.hooks.useGraphTypes';

interface UseGraphTypesProps {
  id: number;
}

const useGraphTypes = ({ id }: UseGraphTypesProps) => {
  const { graphType, setDetailedValue } = rootStore.detailedStore;

  const { isError, isWork, isLoading } = useDtState({
    id,
    appPath: APP_PATH,
  });

  const isCorrectWork = useMemo(() => isWork || isLoading, [isLoading, isWork]);

  // change graphType if CandleChart disabled
  useEffect(() => {
    if (graphType !== GraphTypes.CandlesChart) return;

    !isCorrectWork && setDetailedValue('graphType', GraphTypes.LineChart);
  }, [graphType, isCorrectWork, setDetailedValue]);

  const onChangeGraphTitle = (value: GraphTypes) => {
    setDetailedValue('graphType', value);
  };

  return {
    onChangeGraphTitle,
    isDtReady: (isWork && !isLoading) || isError,
    isWork: isCorrectWork,
  };
};

export default useGraphTypes;
