import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';

import rootStore from '../../../../../stores/rootStore/rootStore';
import TlsList from '../../TlsList/TlsList';

const CreateCoordGroup: FC = () => {
  const { selectedFeatureSomeArray, setSelectedFeatureSomeArray } =
    rootStore.scriptsControlStore;

  const [tlsIds, setTlsIds] = useState<number[]>([]);

  const isSelectedSomeObj = selectedFeatureSomeArray.length > 1;

  useEffect(() => {
    const ids = selectedFeatureSomeArray.map(({ id }) => Number(id));

    setTlsIds(ids);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFeatureSomeArray.length]);

  if (!isSelectedSomeObj)
    return (
      <p>
        Для создания группы нужно выбрать минимум два СО на карте или в списке
        выше
      </p>
    );

  const handleDelete = (id: number) => setSelectedFeatureSomeArray(id);

  return <TlsList tlsIds={tlsIds} handleAction={handleDelete} pageSize={8} />;
};

export default observer(CreateCoordGroup);
