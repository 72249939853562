import { Button } from 'antd';
import { FC } from 'react';

import { OPTION_BUTTON_OPTIONS } from '../constants';
import { IChangeExportType } from '../Export.model';

import useExportType from './hooks/useExportType';

import styles from './ChangeExportType.module.scss';

const ChangeExportType: FC<IChangeExportType> = ({ onChange, value }) => {
  const options = useExportType();

  return (
    <div className={styles.optionsContainer}>
      {options.map((option) => {
        return (
          <Button
            key={option}
            className={
              option === value ? styles.optionButtonActive : styles.optionButton
            }
            size={OPTION_BUTTON_OPTIONS.size}
            type={OPTION_BUTTON_OPTIONS.type}
            onClick={() => {
              onChange(option);
            }}
          >
            {option}
          </Button>
        );
      })}
    </div>
  );
};

export default ChangeExportType;
