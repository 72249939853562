/* eslint-disable camelcase */
import { getAppPathHeaders } from '../../../helpers/getAppPathHeaders';
import http from '../../../http';
import tryCatchWrapper from '../../../tryCatchWrapper';
import { SYSTEMS } from '../../constants/constants';

import {
  GeneralAdapterStatistic,
  StatisticProps,
  useStatisticQueryOptions,
} from './model/adapter.model';
import useStatisticQuery from './query/useStatisticQuery';

const ADAPTER = '/adapter';
const STATISTIC = SYSTEMS + ADAPTER + '/statistics-all-systems';

class AdapterApi {
  fetchAllSystemStatistic = ({
    appPath,
    systemIds,
    systemTypes,
    regionIds,
    subSystemTypes,
    isBlockedNotify = false,
  }: StatisticProps) =>
    tryCatchWrapper(
      async () => {
        const res = await http.get<GeneralAdapterStatistic[]>(STATISTIC, {
          headers: getAppPathHeaders(appPath),
          params: {
            systemIds: JSON.stringify(systemIds),
            systemTypes: JSON.stringify(systemTypes),
            subSystemFilter:
              regionIds?.length &&
              JSON.stringify({
                sc_system_regions_id: regionIds,
                sub_system_type_arr: subSystemTypes,
              }),
          },
        });

        return res?.data;
      },
      {
        isBlockedNotify,
      }
    );
  useStatistic = (statisticProps: useStatisticQueryOptions) =>
    useStatisticQuery({
      ...statisticProps,
      fetchAllSystemStatistic: this.fetchAllSystemStatistic,
    });
}

export const adapter = new AdapterApi();
