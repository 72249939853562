import { ReactNode, RefObject } from 'react';

import {
  COMMANDS,
  COMMANDS_CONTENT,
} from '../../../../../../../../../../constants/commands';
import { CONTROL_ACCESS_DEVICES } from '../../../../../../../../../../constants/constants';
import { findById } from '../../../../../../../../../../helpers/findBy';
import { getTLModeName } from '../../../../../../../../../../helpers/statusHelpers';
import rootStore from '../../../../../../../../../../stores/rootStore/rootStore';
import { TlMode } from '../../../../../../../../../../ts/models/tl.model';
import { IPostCommandProps } from '../../../../../../../../../TrafficLightDetailed/TlManagement/model/postCommands.model';
import { Tip } from '../../../../../../../../../TrafficLightDetailed/TlManagement/model/tlManagement.model';
import ConfirmBlock from '../ConfirmBlock/ConfirmBlock';

interface CommandProps {
  btnMode: TlMode;
  tlIds: number | number[];
  isActiveCoordTL: boolean;
  setIsOpenConfirm: SetState<boolean>;
  cardRef: RefObject<HTMLDivElement>;
}

type basicPostProps = Omit<IPostCommandProps, 'id'>;

interface ReturnCommandInfo {
  modeLabel: string;
  infoTooltip: Tip;
  propsPostCommand: basicPostProps & { ids: number[] };
  title: ReactNode;
  isCurrentMode?: boolean;
  isDisabled?: boolean;
}

type GetCommandInfo = (props: CommandProps) => N<ReturnCommandInfo>;

const { SET_OBJECT_MODE } = COMMANDS;
const { ReadAndControl } = CONTROL_ACCESS_DEVICES;

const BASIC_CONFIRM =
  'Это может привести к нарушению работы запущенной группы координации.';
const TITLE_TL_CONFIRM = `СО находится в координации.
${BASIC_CONFIRM}`;
const TITLE_TLS_CONFIRM = `СО находятся в координации.
${BASIC_CONFIRM}`;

const getTLInfo = (id: number, btnMode: TlMode) => {
  const { tls } = rootStore.mapDataStore;

  const activeTL = findById(tls, id);

  if (!activeTL) return null;

  const {
    deviceInfo: { caption, controlAccess },
    tlStatus: { mode },
  } = activeTL;

  const isCurrentMode = mode === btnMode;

  const isAccessMode = controlAccess === ReadAndControl;

  const isDisabled = !isAccessMode || isCurrentMode;

  const tlName = `СО ${id}. ${caption}`;

  return { isDisabled, tlName, isCurrentMode };
};

export const getCommandInfo: GetCommandInfo = ({
  btnMode,
  tlIds,
  isActiveCoordTL,
  setIsOpenConfirm,
  cardRef,
}) => {
  const modeLabel = getTLModeName(btnMode);
  const commandInfo = findById(COMMANDS_CONTENT, btnMode);

  if (!commandInfo) return null;

  const cmdInfo = `${btnMode} ${commandInfo.title}`;
  const basicTitle = `Вы уверены, что хотите включить режим "${modeLabel}"`;

  const basicPostCommand: basicPostProps = {
    command: btnMode,
    cmdTitle: SET_OBJECT_MODE,
    cmdInfo,
    disabledTime: 0,
  };

  if (!Array.isArray(tlIds)) {
    const tlInfo = getTLInfo(tlIds, btnMode);

    if (!tlInfo || !commandInfo) return null;

    const { isDisabled, tlName, isCurrentMode } = tlInfo;

    const title = `${
      isActiveCoordTL ? TITLE_TL_CONFIRM : ''
    } ${basicTitle} для "${tlName}"?`;

    return {
      title: (
        <ConfirmBlock
          title={title}
          setIsOpenConfirm={setIsOpenConfirm}
          cardRef={cardRef}
        />
      ),
      isCurrentMode,
      isDisabled,
      modeLabel,
      infoTooltip: commandInfo.tip,
      propsPostCommand: {
        ...basicPostCommand,
        ids: [tlIds],
      },
    };
  }

  const accessIds = tlIds.reduce((acc: number[], id) => {
    const tlInfo = getTLInfo(id, btnMode);

    tlInfo && !tlInfo.isDisabled && acc.push(id);

    return acc;
  }, []);

  const title = `${
    isActiveCoordTL ? TITLE_TLS_CONFIRM : ''
  } ${basicTitle} для выбранных СО?`;

  return {
    title: (
      <ConfirmBlock
        title={title}
        setIsOpenConfirm={setIsOpenConfirm}
        cardRef={cardRef}
      />
    ),
    modeLabel,
    infoTooltip: commandInfo.tip,
    propsPostCommand: { ...basicPostCommand, ids: accessIds },
  };
};
