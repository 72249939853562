/* eslint-disable jsx-a11y/no-static-element-interactions */
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import rootStore from '../../stores/rootStore/rootStore';
import ButtonCloseOverviewMap from '../Controls/OverviewMapControl/ButtonCloseOverviewMap/ButtonCloseOverviewMap';

import ControlButtons from './ControlButtons/ControlButtons';
import MainButtons from './MainButtons/MainButtons';

import styles from './MapButtons.module.scss';

const MapButtons = () => {
  const { isOffsetRightPanel } = rootStore.uiStore;

  const containerStyles = classNames({
    [styles.container]: true,
    [styles.containerOffset]: isOffsetRightPanel,
  });

  return (
    <>
      <ButtonCloseOverviewMap />
      <div className={containerStyles} onClick={(e) => e.stopPropagation()}>
        {/* <NotificationWidgetButton /> */}
        <MainButtons />
        <ControlButtons />
      </div>
    </>
  );
};

export default observer(MapButtons);
