import { useEffect } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import { handleMeasuring } from '../helpers/handleMeasuring';

const useMeasuring = () => {
  const { map } = rootStore.mapStore;
  const { measureAction } = rootStore.gisDataStore;

  useEffect(() => {
    handleMeasuring(map, measureAction);
  }, [map, measureAction]);
};

export default useMeasuring;
