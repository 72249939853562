import { useEffect } from 'react';

import KeyCombination, {
  CallBackKeyCombination,
  KeyCombinationProps,
} from './KeyCombination';
const keyCombination = new KeyCombination();

const useKeyCombination = (
  props: KeyCombinationProps,
  callback: CallBackKeyCombination,
  isActive = true
) => {
  useEffect(() => {
    if (!isActive) return;

    keyCombination.addCombination(props, callback);

    return () => {
      keyCombination.removeCombination(props, callback);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);
};

export default useKeyCombination;
