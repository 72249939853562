import { FC } from 'react';

import Img from '../../../../ui-kit/Img/Img';
import { IViewer } from '../PdfViewer/PdfViewer';

import styles from './ImgViewer.module.scss';

const ImgViewer: FC<IViewer> = ({ fileUrl }) => (
  <Img
    className={styles.wrapper}
    src={fileUrl}
    alt={'Изображение'}
    isNotCustom={true}
    width="100%"
    height="100%"
  />
);

export default ImgViewer;
