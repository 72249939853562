import { uniqueId } from 'lodash';

import { COMMANDS } from '../../../../constants/commands';
import {
  IChangePhaseTime,
  ICoordManagment,
  IParamsSender,
} from '../model/postCommands.model';
import { IPhasesData } from '../PhaseHold/PhaseHold.model';

const {
  SET_OBJECT_PLAN,
  SET_OBJECT_PLAN_TIME_ON_SCHEDULER,
  SET_OBJECT_PHASE,
  SET_OBJECT_SYNC_PLAN,
  SET_OBJECT_PHASE_FLOW,
} = COMMANDS;

interface IGetRequestData {
  cmdTitle: COMMANDS;
  selectedDate: U<number>;
  durationTime: U<number>;
  phasesValue: U<N<IPhasesData[]>>;
  command: N<number>;
}

export const getRequestSenderData = ({
  cmdTitle,
  selectedDate,
  durationTime,
  phasesValue,
  command,
}: IGetRequestData): U<IParamsSender> => {
  const isCmd = cmdTitle in COMMANDS;

  if (!isCmd) return;

  const res = { param1: command !== null ? command : '' };

  const basicPhasesValue = phasesValue?.length
    ? phasesValue?.reduce((acc: ICoordManagment[], item) => {
        const { id, key, ...phases } = item;

        acc.push(phases);

        return acc;
      }, [])
    : null;

  const durTime = durationTime ?? '';
  const phaseFlowJparam = basicPhasesValue?.reduce(
    (acc: IChangePhaseTime[], item) => {
      const {
        spanGreenBlinkTime,
        spanYellowTime,
        spanRedYellowTime,
        ...phases
      } = item;

      return [...acc, phases];
    },
    []
  );

  switch (cmdTitle) {
    case SET_OBJECT_PHASE:
      return { ...res, param2: selectedDate ?? 0, param3: durTime };
    case SET_OBJECT_PLAN:
      return { ...res, param2: durTime };
    case SET_OBJECT_PLAN_TIME_ON_SCHEDULER:
      return { ...res, param2: durTime, param3: selectedDate ?? 0 };
    case SET_OBJECT_SYNC_PLAN:
      return {
        ...res,
        param2: selectedDate ?? 0,
        param3: durTime,
        param4: Number(uniqueId()),
        jparam1: basicPhasesValue,
      };
    case SET_OBJECT_PHASE_FLOW:
      return { ...res, jparam1: phaseFlowJparam };
    default:
      return res;
  }
};
