import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { ReactComponent as Arrow } from '../../../../assets/icons/map/ic_camera_arrow.svg';
import rootStore from '../../../../stores/rootStore/rootStore';
import getDeviceIcoFill from '../../helpers/getDeviceIcoFill';
import getIcoColor from '../../helpers/getIcoColor';
import { CameraCompactProps } from '../../models/camera.model';

import getArrowSizeRate from './helpers/getArrowSizeRate';

import styles from './CameraCompact.module.scss';

const CameraCompact: FC<CameraCompactProps> = ({ camera, size }) => {
  const { cameras } = rootStore.mapDataStore;

  const { angle, linkColor, linkedDetectorId, id } = camera;

  const defaultFill = getDeviceIcoFill(id, cameras);

  const fill = linkedDetectorId
    ? getIcoColor(linkColor, defaultFill)
    : defaultFill;

  const arrowSize = size * getArrowSizeRate() * 1.4;

  const triangleContainerStyle = {
    transform: `translate(-50%, -50%) rotate(${angle}deg)`,
  };

  return (
    <div style={triangleContainerStyle} className={styles.triangleWrapper}>
      <Arrow
        className={styles.triangle}
        width={arrowSize}
        height={arrowSize}
        fill={fill}
      />
    </div>
  );
};

export default observer(CameraCompact);
