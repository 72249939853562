import Feature from 'ol/Feature';
import { Style } from 'ol/style';

import { FeatureSymbol } from './FeatureSymbol';
import {
  ClassBreakInfo,
  IClassBreaksInfo,
  IClassBreaksRendererDefinition,
} from './renderer.model';

const getClassBreaksSymbolsDict = (
  renderer: IClassBreaksRendererDefinition
): IClassBreaksInfo[] => {
  const items = renderer.classBreakInfos
    .filter(
      (element: ClassBreakInfo) =>
        Number.isFinite(element.classMaxValue) && element.symbol
    )
    .map((element) => {
      const value = element.classMaxValue;

      const symbol = FeatureSymbol(element.symbol);

      return {
        value,
        symbol,
      };
    });

  items.sort((a, b) => a.value - b.value);

  return items;
};

const getClassBreaksDefaultSymbol = (
  renderer: IClassBreaksRendererDefinition
): Style | undefined => {
  const defaultSymbol = renderer.defaultSymbol;

  return FeatureSymbol(defaultSymbol);
};

const getClassBreaksSymbol = (
  renderer: IClassBreaksRendererDefinition,
  feature: Feature,
  resolution: number,
  defaultSymbol: Style | undefined,
  symbolsDict: IClassBreaksInfo[]
): Style | undefined => {
  const properties = feature.getProperties();

  const { field } = renderer;

  const value = properties[field];

  if (!value) {
    return defaultSymbol;
  }

  const definition = symbolsDict.find((element) => value > element.value);

  return definition?.symbol || defaultSymbol;
};

export {
  getClassBreaksSymbol,
  getClassBreaksDefaultSymbol,
  getClassBreaksSymbolsDict,
};
