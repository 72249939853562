import { useEffect, useState } from 'react';

import { getReportServerStatus } from '../../../../../helpers/export/helpers';
import { DEFAULT_OPTIONS } from '../../constants';
import { FileExtensions } from '../../Export.model';

const useExportType = () => {
  const [options, setOptions] = useState<FileExtensions[]>(DEFAULT_OPTIONS);

  useEffect(() => {
    (async () => {
      const hasService = await getReportServerStatus();

      setOptions(
        hasService
          ? [...DEFAULT_OPTIONS, FileExtensions.xlsxService]
          : DEFAULT_OPTIONS
      );
    })();
  }, []);

  return options;
};

export default useExportType;
