import React, { useCallback, useEffect, useState } from 'react';

import xeomaApi from '../../api/xeoma/xeoma';

import Player from './Player/Player';

import styles from './MjpegPlayerList.module.scss';

const MjpegPlayerList = () => {
  const [transmitters, setModules] = useState<string[]>([]);
  const getXeoma = useCallback(async () => {
    const allModules: any = await xeomaApi.getModules();

    const newTransmitters: string[] = [];

    allModules.forEach(({ modules }: any) => {
      newTransmitters.push(modules[modules.length - 1]);
    });

    setModules(newTransmitters);
  }, []);

  useEffect(() => {
    getXeoma();
  }, [getXeoma]);

  return (
    <div className={styles.wrapper}>
      {transmitters.map((transmitter, i) => (
        <Player key={i} transmitter={transmitter} />
      ))}
    </div>
  );
};

export default MjpegPlayerList;
