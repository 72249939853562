import { CONTROL_ACCESS_DEVICES } from '../../../../../../../../constants/constants';
import { findById } from '../../../../../../../../helpers/findBy';
import rootStore from '../../../../../../../../stores/rootStore/rootStore';
import { TL } from '../../../../../../../../ts/models/tl.model';
import { ONLY_READ_MODE } from '../../../../../../../TrafficLightDetailed/TlManagement/StatusCard/ActiveButtonWrapper/helpers/getTooltipContent';

const { ReadAndControl } = CONTROL_ACCESS_DEVICES;

export const getIsTLOnStartedGroup = (tlId: number) => {
  const { coordGroups } = rootStore.scriptsControlStore;

  return coordGroups.some(
    ({ tlsIds, isStarted }) => isStarted && tlsIds.some((id) => id === tlId)
  );
};

export const getTLWarnings = (
  controlAccess: TL['deviceInfo']['controlAccess']
) => {
  const isAccessMode = controlAccess === ReadAndControl;

  const warningText = isAccessMode ? undefined : ONLY_READ_MODE;

  return { warningText, isAccessMode };
};

export const getTLControlInfo = (tlId: number) => {
  const { tls } = rootStore.mapDataStore;

  const isActiveCoordTL = getIsTLOnStartedGroup(tlId);
  const tl = findById(tls, tlId);

  if (!tl) return null;

  const { deviceInfo } = tl;

  const { isAccessMode, warningText } = getTLWarnings(deviceInfo.controlAccess);

  return { isActiveCoordTL, warningText, isAccessMode };
};
