import { IGroup } from '../../../api/adminObjects/groups/groups.zod';
import rootStore from '../../../stores/rootStore/rootStore';

export interface ISeparateGroups {
  web: IGroup[];
  njs: IGroup[];
}

export const getAllGroupsObj = () => {
  const {
    webSystemId,
    serverSystemId,
    groups: systemsWithGroups,
  } = rootStore.userDataStore;

  const groups: ISeparateGroups = {
    njs: [],
    web: [],
  };

  systemsWithGroups?.forEach((item) => {
    if (item.systemId === webSystemId) {
      groups.web = item.groups;
    }

    if (item.systemId === serverSystemId) {
      groups.njs = item.groups;
    }
  });

  return groups;
};
