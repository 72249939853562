import { FC, useEffect } from 'react';

import { sendError } from '../../../../../../../helpers/sendError';
import NoData from '../../../../../../ui-kit/Errors/NoData/NoData';

interface DtVideoNoDataProps {
  error: Error;
  resetErrorBoundary: () => void;
}

const ERR_TITLE = 'Ошибка видеопотока с ДТ';
const APP_PATH =
  '.TrafficLightDetailed.TlDetectors.DetectorsListCard.DetectorsWrapper.DtVideoPreview';

const DtVideoNoData: FC<DtVideoNoDataProps> = ({ error }) => {
  useEffect(() => {
    sendError(ERR_TITLE, error, APP_PATH);
  }, [error]);

  return <NoData isNeedBackground={false} textError="Видеопоток отсутствует" />;
};

export default DtVideoNoData;
