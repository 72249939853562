import { FC, MouseEventHandler } from 'react';

import { MAP_ICO_BASIC } from '../constants/constants';

interface MapIcoProps {
  fill?: string;
  onClick?: MouseEventHandler<SVGSVGElement>;
  className?: string;
  width?: number;
  height?: number;
  stroke?: string;
}

const MapSquareIco: FC<MapIcoProps> = (svgProps) => (
  <svg {...MAP_ICO_BASIC} {...svgProps}>
    <rect x="1" y="1" width="34" height="34" rx="9" />
  </svg>
);

export default MapSquareIco;
