import { Card, Checkbox } from 'antd';
import React from 'react';
import { HiArrowLeft } from 'react-icons/hi';

import {
  SEARCH_CONTENT,
  SEARCH_RESULT_ICON_SIZE,
  SEARCH_STYLES,
  SETTINGS_PANEL_ID,
} from '../constants';

import styles from '../SearchButton.module.scss';

export const SearchSettings = (props: {
  onClick: () => void;
  onGeocodeProviderChange: () => void;
  onLayersProviderChange: () => void;
  onCoordinatesProviderChange: () => void;
  hasGeocodeProvider: boolean;
  hasLayersProvider: boolean;
  hasCoordinatesProvider: boolean;
}) => (
  <Card
    id={SETTINGS_PANEL_ID}
    bordered={false}
    className={styles.searchPanel}
    styles={{ body: SEARCH_STYLES.panelBodyStyle }}
  >
    <div className={styles.searchPanelTitle}>
      <HiArrowLeft
        size={SEARCH_RESULT_ICON_SIZE}
        style={{ marginRight: 5 }}
        onClick={props.onClick}
      />
      {SEARCH_CONTENT.searchSettingsTitle}
    </div>
    <div className={styles.searchSettingsContainer}>
      <Checkbox
        onChange={props.onGeocodeProviderChange}
        checked={props.hasGeocodeProvider}
      >
        Выполнять поиск по геокодеру
      </Checkbox>
      <div className={styles.settingsHint}>
        {SEARCH_CONTENT.settings.geocodeHint}
      </div>

      <Checkbox
        onChange={props.onLayersProviderChange}
        checked={props.hasLayersProvider}
      >
        Выполнять поиск по слоям ГИС
      </Checkbox>
      <div className={styles.settingsHint}>
        {SEARCH_CONTENT.settings.layersHint}
      </div>

      <Checkbox
        onChange={props.onCoordinatesProviderChange}
        checked={props.hasCoordinatesProvider}
      >
        Выполнять поиск по координатам
      </Checkbox>
      <div className={styles.settingsHint}>
        {SEARCH_CONTENT.settings.coordinatesHint}
      </div>
    </div>
  </Card>
);
