import classNames from 'classnames';
import { FC } from 'react';

import 'dayjs/locale/ru';

import { ReactComponent as InfoIcon } from '../../../../assets/icons/confirm_icon.svg';
import { ReactComponent as InfoIconPhaseHold } from '../../../../assets/icons/icon_phase_hold.svg';
import { NearestCommandValue } from '../../../../ts/models/tl.model';

import { TitleValues } from './constants/constants';
import { getTimerInfo } from './helpers/getTimerInfo';
import useTimer from './hooks/useTimer';

import styles from './Timer.module.scss';

interface TimerProps extends NearestCommandValue {
  titles: TitleValues;
  className?: string;
}

const Timer: FC<TimerProps> = ({
  startTime,
  endTime,
  commandNum,
  titles,
  className = '',
}) => {
  const { sec, isTimeEnd } = useTimer(startTime, endTime);

  if (!sec) return null;

  const {
    time: [h, m, s],
    titlePhaseDate,
    titlePhaseTimer,
  } = getTimerInfo({ startTime, endTime, commandNum, sec, isTimeEnd, titles });

  const Icon = !isTimeEnd ? InfoIcon : InfoIconPhaseHold;

  const containerStyle = classNames({
    [styles.rowContainer]: true,
    [className]: className,
  });

  return (
    <div className={containerStyle}>
      <Icon className={styles.infoIcon} />
      <div className={styles.columnContainer}>
        <span>
          {`${titlePhaseTimer}: `}
          <p className={styles.time}>
            {h.toString().padStart(2, '0')}
            <strong> : </strong>
            {m.toString().padStart(2, '0')}
            <strong> : </strong>
            {s.toString().padStart(2, '0')}
          </p>
        </span>
        <span>{titlePhaseDate}</span>
      </div>
    </div>
  );
};

export default Timer;
