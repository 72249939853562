import { observer } from 'mobx-react-lite';
import Feature from 'ol/Feature';
import OLVectorImageLayer from 'ol/layer/VectorImage';
import { useEffect, FC } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import { vector } from '../MapSource';
interface VectorImageLayerProps {
  features: Feature[];
  style: any;
  zIndex?: number;
}

const VectorLayer: FC<VectorImageLayerProps> = ({
  features,
  style,
  zIndex = 0,
}) => {
  const { map } = rootStore.mapStore;

  useEffect(() => {
    if (!map) {
      return;
    }

    const vectorLayer = new OLVectorImageLayer({
      source: vector({ features }),
      style,
    });

    map.addLayer(vectorLayer);
    vectorLayer.setZIndex(zIndex);

    return () => {
      if (map) {
        map.removeLayer(vectorLayer);
      }
    };
  }, [map, features, zIndex, style]);

  return null;
};

export default observer(VectorLayer);
