import { useEffect } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import notification from '../../ui-kit/Notification/Notification';

const useIsTlsDataFetched = () => {
  const { isTlsDataFetched } = rootStore.mapDataStore;

  useEffect(() => {
    !isTlsDataFetched && notification.error('ERROR_TLS_FETCHING');
  }, [isTlsDataFetched]);
};

export default useIsTlsDataFetched;
