import classNames from 'classnames';
import { FC } from 'react';
import { IoIosArrowForward } from 'react-icons/io';

import styles from './BreadCrumbs.module.scss';

export interface IBreadCrumbsItem {
  label: string;
  key: string;
}

interface IBreadCrumbs {
  breadCrumbsItems: IBreadCrumbsItem[];
  activeKey: N<string>;
  setActiveKey?: (value: string) => void;
  setKeyPath?: (value: string[]) => void;
}

const BreadCrumbs: FC<IBreadCrumbs> = ({
  breadCrumbsItems,
  activeKey,
  setActiveKey,
  setKeyPath,
}) => {
  const isAccessClick = !!(setActiveKey && setKeyPath);

  const handleClick = (key: string, label: string) => {
    if (!isAccessClick) return;

    setActiveKey(`${label}#${key}`);

    const newKeys = breadCrumbsItems.reduce((acc: string[], { label, key }) => {
      if (key !== activeKey) {
        acc.push(`${label}#${key}`);
      }

      return acc;
    }, []);

    setKeyPath(newKeys.reverse());
  };

  const getClassNames = (key: string) => {
    return classNames({
      [styles.btn]: true,
      [styles.btnHover]: isAccessClick,
      [styles.btnActive]: key === activeKey,
    });
  };

  return (
    <ul className={styles.list}>
      {breadCrumbsItems.map((item, i) => (
        <li key={item.key}>
          <button
            onClick={() => handleClick(item.key, item.label)}
            className={getClassNames(item.key)}
          >
            {item.label}
          </button>
          {i < breadCrumbsItems.length - 1 && <IoIosArrowForward />}
        </li>
      ))}
    </ul>
  );
};

export default BreadCrumbs;
