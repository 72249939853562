/* eslint-disable jsx-a11y/no-static-element-interactions */
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { ReactComponent as CloseBtn } from '../../../../assets/icons/map/ic_minific_btn_close.svg';
import { ReactComponent as OpenBtn } from '../../../../assets/icons/map/ic_minific_btn_open.svg';
import rootStore from '../../../../stores/rootStore/rootStore';
import ButtonIco from '../../../ui-kit/ButtonIco/ButtonIco';

import styles from './ButtonCloseOverviewMap.module.scss';

const ButtonCloseOverviewMap = () => {
  const { isMinificMapOpen, setIsNot, isPanel, isMinificMap, tipsValues } =
    rootStore.uiStore;

  const popoverContent =
    (isMinificMapOpen ? 'Свернуть' : 'Развернуть') + ' минифицированную карту?';

  const popOverProps = {
    content: popoverContent,
    placement: 'right',
    tipsDelay: tipsValues.profileTipsDelay,
  } as const;

  const btnStyle = classNames({
    [styles.btnClose]: true,
    [styles.panelOpen]: isPanel,
  });

  const closeMinificMap = () => {
    setIsNot('isMinificMapOpen', !isMinificMapOpen);
  };

  if (!isMinificMap) return null;

  return (
    <span className={btnStyle}>
      <ButtonIco onClick={closeMinificMap} isRound popoverProps={popOverProps}>
        {isMinificMapOpen ? <OpenBtn /> : <CloseBtn />}
      </ButtonIco>
    </span>
  );
};

export default observer(ButtonCloseOverviewMap);
