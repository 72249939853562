import type { FeatureLike } from 'ol/Feature';
import { VectorImage } from 'ol/layer';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Fill, Icon, Stroke, Style, Text } from 'ol/style';
import type { StyleLike } from 'ol/style/Style';

import busIcon from '../../../assets/icons/map/bus.svg';
import directionTS from '../../../assets/icons/map/direction_tc.svg';
import { ILayer } from '../../../stores/gisDataStore/gisDataStore.model';
import {
  CategoryLayerGIS,
  FeaturePropertiesCategoryLayer,
} from '../../../ts/enums/enums';
import {
  MIN_RESOLUTION_VEHICLE_TEXT_VISIBILITY,
  RESOLUTIONS_SCALES,
  STANDARD_IMAGE_ANCHOR,
  VEHICLE_STYLE_PROPS,
} from '../../constants/styles';
import { parcer } from '../index';
import {
  DrawingInfoRenderer,
  ISimpleRendererDefinition,
} from '../utils/fromRenderer/renderer.model';

const createStyleByCategory = (
  layer: ILayer,
  vector: U<VectorLayer<VectorSource>> | U<VectorImage<VectorSource>>,
  renderer: DrawingInfoRenderer | undefined
): N<StyleLike> => {
  const category = layer?.category?.category ?? undefined;
  let style = null;

  switch (category) {
    case CategoryLayerGIS.Social: {
      break;
    }
    case CategoryLayerGIS.Common: {
      parcer.fromEsri(vector, renderer);
      break;
    }
    case CategoryLayerGIS.Weather: {
      break;
    }
    case CategoryLayerGIS.PublicTransport: {
      const displayField = layer.displayField;

      style = (feature: FeatureLike, resolution: number): Style[] => {
        const isVisibleText =
          resolution < MIN_RESOLUTION_VEHICLE_TEXT_VISIBILITY;

        const resolutionScaleItem = RESOLUTIONS_SCALES.find(
          (element) => element.resolution <= resolution
        );

        const scaleCoefficient = resolutionScaleItem?.value || 1;

        const angle =
          Number(feature.get(FeaturePropertiesCategoryLayer.Direction)) || 0;

        const direction = angle * (Math.PI / 180);

        const text =
          feature.get(
            displayField || FeaturePropertiesCategoryLayer.VehicleNumber
          ) || '';

        const textView =
          text.length > VEHICLE_STYLE_PROPS.MAX_LABEL_LENGTH
            ? text.substring(0, VEHICLE_STYLE_PROPS.MAX_LABEL_LENGTH - 3) +
              '...'
            : text;

        const textStyle = new Style({
          text: new Text({
            font: VEHICLE_STYLE_PROPS.FONT,
            text: textView,
            scale: scaleCoefficient,
            offsetX: VEHICLE_STYLE_PROPS.LABEL_POSITION,
            fill: new Fill({
              color: VEHICLE_STYLE_PROPS.FILL_COLOR,
            }),
            stroke: new Stroke({
              color: VEHICLE_STYLE_PROPS.STROKE_COLOR,
              width: VEHICLE_STYLE_PROPS.STROKE_WIDTH,
            }),
          }),
        });

        const directionStyle = new Style({
          image: new Icon({
            anchor: STANDARD_IMAGE_ANCHOR,
            scale: [
              VEHICLE_STYLE_PROPS.DIRECTION_SIZE * scaleCoefficient,
              VEHICLE_STYLE_PROPS.DIRECTION_SIZE * scaleCoefficient,
            ],
            rotation: direction,
            src: directionTS,
          }),
        });

        const render = renderer as ISimpleRendererDefinition;

        // @ts-ignore
        const src = `data:image/png;base64,` + render.symbol.imageData;

        const bus = new Style({
          image: new Icon({
            anchor: STANDARD_IMAGE_ANCHOR,
            scale: [
              VEHICLE_STYLE_PROPS.BUS_SIZE * scaleCoefficient,
              VEHICLE_STYLE_PROPS.BUS_SIZE * scaleCoefficient,
            ],
            src: src || busIcon,
          }),
        });

        const style = [directionStyle, bus];

        if (isVisibleText) {
          style.unshift(textStyle);
        }

        return style;
      };
      break;
    }
    case CategoryLayerGIS.Raster: {
      break;
    }
    default: {
      parcer.fromEsri(vector, renderer);
      break;
    }
  }

  return style;
};

export { createStyleByCategory };
