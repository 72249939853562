import { FC } from 'react';

import Quote from '../Quote';

import styles from './Chunk.module.scss';

enum TypeColors {
  green = 'green',
  red = 'red',
  grey = 'grey',
  orange = 'orange',
}

interface ChunkProps {
  entry: [key: string, value: string | object];
  isLastElement: boolean;
  indentLevel: number;
  parent: object;
}

const Chunk: FC<ChunkProps> = ({
  entry,
  isLastElement,
  indentLevel,
  parent,
}) => {
  const [key, value] = entry;

  const valueType = typeof value;

  const printByType = (): JSX.Element => {
    switch (valueType) {
      case 'number':
        return (
          <span className={styles[TypeColors.red]}>{value.toString()}</span>
        );

      case 'boolean':
        return (
          <span className={styles[TypeColors.orange]}>{value.toString()}</span>
        );

      case 'object': {
        if (value === null) {
          return <span className={styles[TypeColors.grey]}>null</span>;
        }

        return (
          <>
            {Object.entries(value).map((entryItem, i, array) => (
              <Chunk
                key={i}
                entry={entryItem}
                parent={value as object}
                isLastElement={array.length - 1 !== i}
                indentLevel={indentLevel}
              />
            ))}
          </>
        );
      }

      case 'undefined':
        return <span className={styles[TypeColors.grey]}>undefined</span>;

      default:
        return (
          <span className={styles[TypeColors.green]}>
            {'"' + value.toString() + '"'}
          </span>
        );
    }
  };

  return (
    <div
      className={styles.chunk}
      style={{ marginLeft: indentLevel * 15 + 'px' }}
    >
      <span className={styles.keyStyle}>
        {Array.isArray(parent) ? '' : '"' + key + '": '}
      </span>
      <Quote parentObj={value} position="start" />
      {printByType()}
      <Quote parentObj={value} position="end" />
      {isLastElement && ','}
    </div>
  );
};

export default Chunk;
