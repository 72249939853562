import { Tabs } from 'antd';
import { observer } from 'mobx-react-lite';

import { DETAILED_GENERAL_ITEMS } from '../constants/detailedGeneralItems';

import styles from './GeneralDtTabs.module.scss';

const GeneralDtTabs = () => {
  return (
    <div className={styles.detectorsInfo}>
      <Tabs
        className={styles.tabsCustom}
        defaultActiveKey="1"
        items={DETAILED_GENERAL_ITEMS}
      />
    </div>
  );
};

export default observer(GeneralDtTabs);
