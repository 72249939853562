import { BiBell } from 'react-icons/bi';
import { FaRoad } from 'react-icons/fa';
import { LiaMapMarkedAltSolid } from 'react-icons/lia';

import { ReactComponent as Districts } from '../../../assets/icons/cartography_tabs/districts.svg';
import { ReactComponent as Parameters } from '../../../assets/icons/cartography_tabs/parameters.svg';
import { AccessCode, CartographyTabsCode } from '../../../ts/enums/userData';
import AllSystemsTab from '../MainPanelTabs/AllSystemsTab/AllSystemsTab';
import IncidentsTab from '../MainPanelTabs/EventsTab/EventsTab';
import MapDistrictsTab from '../MainPanelTabs/MapDistrictsTab/MapDistrictsTab';
import ParamsTab from '../MainPanelTabs/ParamsTab/ParamsTab';
import RoadsTab from '../MainPanelTabs/RoadsTab/RoadsTab';

export enum CartographyTabName {
  Map = 'Карта',
  RoadNetwork = 'Дорожная сеть',
  Subsystems = 'Объекты мониторинга',
  Params = 'Параметры',
  Events = 'События',
}

interface IPanelTab {
  label: JSX.Element;
  value: CartographyTabName;
  code: AccessCode;
}

export const PANEL_TABS_MAIN: IPanelTab[] = [
  {
    label: <Districts />,
    value: CartographyTabName.Map,
    code: CartographyTabsCode.MapTab,
  },
  {
    label: <FaRoad />,
    value: CartographyTabName.RoadNetwork,
    code: CartographyTabsCode.RoadTab,
  },
  {
    label: <LiaMapMarkedAltSolid />,
    value: CartographyTabName.Subsystems,
    code: CartographyTabsCode.SubsystemsTab,
  },
  {
    label: <Parameters />,
    value: CartographyTabName.Params,
    code: CartographyTabsCode.ParamsTab,
  },
  {
    label: <BiBell />,
    value: CartographyTabName.Events,
    code: CartographyTabsCode.EventsTab,
  },
];

export const TABS = {
  [CartographyTabName.Map]: MapDistrictsTab,
  [CartographyTabName.RoadNetwork]: RoadsTab,
  [CartographyTabName.Subsystems]: AllSystemsTab,
  [CartographyTabName.Params]: ParamsTab,
  [CartographyTabName.Events]: IncidentsTab,
};
