/* eslint-disable jsx-a11y/media-has-caption */
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useMemo, useRef } from 'react';

import xeomaApi from '../../api/xeoma/xeoma';
import rootStore from '../../stores/rootStore/rootStore';

import { preparePeerConnection, requestMessage } from './helpers/connection';

import styles from './WebRTCPlayer.module.scss';

interface IWebRTCPlayer {
  streamCameraHref: string;
}

interface IWebRTCPlayer {
  streamCameraHref: string;
}

const WebRTCPlayer: FC<IWebRTCPlayer> = ({ streamCameraHref }) => {
  const { isUpdateXeoma, isXeoma } = rootStore.videoWallPanelStore;
  const videoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const pc = useMemo(() => new RTCPeerConnection(), [isUpdateXeoma]);

  const getConnect = useCallback(async () => {
    const webrtcConnectRes = await xeomaApi.fetchWebRtcConnect(
      streamCameraHref
    );

    if (!webrtcConnectRes) return;

    const webrtcId = await webrtcConnectRes.text();

    if (!webrtcId) return;

    const elements = {
      audio: audioRef.current,
      video: videoRef.current,
    };

    preparePeerConnection(streamCameraHref, webrtcId, pc, elements);

    await xeomaApi.fetchWebRtcOffer(streamCameraHref, webrtcId);
    requestMessage(streamCameraHref, webrtcId, pc);
  }, [pc, streamCameraHref]);

  useEffect(() => {
    getConnect();

    return () => {
      pc.close();
    };
  }, [getConnect, pc]);

  useEffect(() => {
    !isXeoma && pc.close();
  }, [isXeoma, pc]);

  return (
    <div className={styles.wrapper}>
      <audio id="remote-audio" ref={audioRef} />
      <video
        id="remote-video"
        ref={videoRef}
        autoPlay
        className={styles.video}
        controls
      />
    </div>
  );
};

export default observer(WebRTCPlayer);
