import { Overlay } from 'ol';
import { RefObject } from 'react';

interface ICreateOverlays {
  element: RefObject<HTMLDivElement>;
  autoPan?: boolean;
  insertFirst?: boolean;
  id?: SN;
}

const createOverlay = ({
  element,
  autoPan = false,
  insertFirst = false,
  id,
}: ICreateOverlays) => {
  const initialAutoPan = {
    autoPan,
    autoPanAnimation: { duration: 100 },
    autoPanMargin: 20,
    insertFirst,
  };

  return new Overlay({
    element: element.current ?? undefined,
    id,
    ...initialAutoPan,
  });
};

export default createOverlay;
