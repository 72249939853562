import { RefObject } from 'react';

const setDisplayStyle = (refs: RefObject<HTMLDivElement>[], style = 'none') => {
  refs.forEach((ref) => {
    if (ref.current?.style) {
      ref.current.style.display = style;
    }
  });
};

export default setDisplayStyle;
