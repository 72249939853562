import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';

import App from './components/App/App';
import AppError from './components/AppError/AppError';
import './assets/styles/index.scss';
import AppProvider from './components/AppProvider/AppProvider';

const container = document.getElementById('root');
const root = createRoot(container as any); // CreateRoot(container!) if you use TypeScript

root.render(
  <ErrorBoundary fallback={<AppError />}>
    <AppProvider>
      <App />
    </AppProvider>
  </ErrorBoundary>
);
