import { getAppPathHeaders } from '../../../helpers/getAppPathHeaders';
import http from '../../../http';
import tryCatchWrapper from '../../../tryCatchWrapper';
import { SYSTEMS } from '../../constants/constants';

const DEVICES_CM = '/devices/cm';

const camerasApi = {
  add: (id: number, body: any, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.post(`${SYSTEMS}/${id}${DEVICES_CM}`, body, {
        headers: getAppPathHeaders(appPath),
      });

      return res;
    }),
};

export default camerasApi;
