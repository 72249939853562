import { useCallback, useEffect, useState } from 'react';

import adminApi from '../api/adminObjects';

export type Option = {
  label: string;
  value: SN;
};

type Region = {
  id: number;
  name: string;
};

export const useRegions = (appPath: string) => {
  const [regions, setRegions] = useState<Region[]>([]);
  const [options, setOptions] = useState<Option[]>([]);

  const getRegionsData = useCallback(async () => {
    const regionsList = await adminApi.about.getAboutRegions(appPath);

    if (!regionsList) return;

    setRegions(regionsList);
    setOptions(
      regionsList.map((item: Region) => {
        const option = {
          label: item.name,
          value: item.id,
        };

        return option;
      })
    );
  }, [appPath]);

  useEffect(() => {
    getRegionsData();
  }, [getRegionsData]);

  return { regions, options };
};
