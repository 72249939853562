import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { Fill, Icon, Stroke, Text } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import Style from 'ol/style/Style';
import Chart, { type chartType } from 'ol-ext/style/Chart';

import group from '../../../../../assets/icons/map/group_ico.svg';
import { COLORS } from '../../../../../constants/colorsConstants';
import { MAP_ICONS } from '../../../../../constants/mapConstants';
import { FeatureDeviceProps, System } from '../../../../../ts/enums/enums';
import { CIRCLE_CORRECT } from '../constants/constants';

import { StyleCache } from './clusterStyles';
import { FeaturesMode, getStatusDataCluster } from './colors';

const CLUSTER_SIZES = {
  MIN: 15,
  MAX: 25,
  CORRECTION: 0.75,
  BORDER_MIN: 6,
  BORDER_MAX: 10,
};

const DEVICE_SIZE_CORRECTION = 1.4;
const CLUSTER_SIZE_CORRECTION = 1.75;

const { CORRECTION, MAX, MIN, BORDER_MAX, BORDER_MIN } = CLUSTER_SIZES;

const MIN_CLUSTER_RADIUS = MIN + 2;

const CIRCLE_PROPS = {
  stroke: new Stroke({
    color: COLORS.WHITE,
    width: 1.5,
  }),
};

interface IOptionsProps {
  styleChart?: chartType;
  scale?: number;
  styleCache: StyleCache;
}

const getClusterIcoProps = (features: Feature<Point>[], radius: number) => {
  let prevSystem: U<System>;
  let isMultiple = false;

  for (let i = 0; i < features.length; i++) {
    const system: U<System> = features[i].get(FeatureDeviceProps.System);

    if (prevSystem && prevSystem !== system) {
      isMultiple = true;

      break;
    }

    prevSystem = system;
  }

  if (isMultiple)
    return {
      ico: {
        anchor: [0.5, 1],
        src: group,
        width: radius * CORRECTION,
      },
      isMultiple,
    };

  const system: System = features[0].get(FeatureDeviceProps.System);

  return {
    ico: {
      anchor: [0.48, 0.75],
      src: MAP_ICONS[system],
      width: radius * DEVICE_SIZE_CORRECTION,
    },
    isMultiple,
  };
};

const calculateRadius = (len: number, styleChart: chartType) =>
  Math.max(MIN, Math.min(len * CORRECTION, MAX)) *
  (styleChart === 'donut' ? CORRECTION : 1);

interface IconRadius {
  len: number;
  styleChart: chartType;
  scale: number;
}

export const getIconRadius = ({ len, styleChart, scale }: IconRadius) =>
  Math.floor(
    calculateRadius(len, styleChart) * scale * CLUSTER_SIZE_CORRECTION
  );

const getBorderWidth = (radius: number) =>
  Math.max(BORDER_MIN, Math.min(Math.floor(radius * CORRECTION), BORDER_MAX));

export const getClusterStyle = (
  features: Feature<Point>[],
  featureMode: FeaturesMode,
  { styleCache, styleChart = 'pie', scale = 1 }: IOptionsProps
) => {
  const { colors, data } = getStatusDataCluster(features, featureMode, true);

  const len = features.length;
  const radius = Math.min(getIconRadius({ len, styleChart, scale }), MAX);

  const { ico, isMultiple } = getClusterIcoProps(features, radius);

  const key = `${len}_${colors.join('_')}_${data.join(
    '_'
  )}_${isMultiple}_${styleChart}`;

  if (styleCache[key]) return styleCache[key];

  const isEqualStatus = data.length === 1;

  const circleRadius = Math.max(
    MIN_CLUSTER_RADIUS,
    Math.floor(radius * CIRCLE_CORRECT)
  );

  const ChartStyle = new Style({
    image: new Chart({
      type: styleChart,
      radius: circleRadius,
      data,
      // @ts-ignore
      colors,
      ...CIRCLE_PROPS,
    }),
  });

  const CircleChart = [
    new Style({
      image: new CircleStyle({
        radius: circleRadius - 2,
        fill: undefined,
        stroke: new Stroke({
          color: colors.at(0),
          width: getBorderWidth(radius),
        }),
      }),
    }),
    new Style({
      image: new CircleStyle({
        radius: circleRadius + 2,
        fill: undefined,
        ...CIRCLE_PROPS,
      }),
    }),
    new Style({
      image: new CircleStyle({
        radius: circleRadius - 6,
        fill: undefined,
        ...CIRCLE_PROPS,
      }),
    }),
  ];

  const backgroundStyle = isEqualStatus ? CircleChart : [ChartStyle];

  if (styleChart === 'donut') {
    styleCache[key] = backgroundStyle;

    return backgroundStyle;
  }

  const style = [
    ...backgroundStyle,
    new Style({
      image: new CircleStyle({
        radius: radius,
        fill: new Fill({
          color: COLORS.PRIMARY,
        }),
        ...CIRCLE_PROPS,
      }),
    }),
    new Style({
      image: new Icon(ico),
    }),
    new Style({
      text: new Text({
        text: len.toString(),
        textAlign: 'center',
        font: `bold ${radius / 1.5}px Noto Sans`,
        fill: new Fill({
          color: COLORS.WHITE,
        }),
        textBaseline: 'top',
      }),
    }),
  ];

  if (styleCache) {
    styleCache[key] = style;
  }

  return style;
};
