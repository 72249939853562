import { useEffect } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import {
  removeVisibleLayers,
  setVisibleLayers,
} from '../helpers/handleGisLayers';

import useVisibleLayersState from './useVisibleLayersState';

const useVisibleLayers = () => {
  const { map } = rootStore.mapStore;
  const { layersState, visibleLayers, setGisValue } = rootStore.gisDataStore;
  const { markers } = rootStore.uiStore;

  useEffect(() => {
    if (!map) return;
    const visibleLayers = setVisibleLayers(map, layersState);

    setGisValue('visibleLayers', visibleLayers);

    return () => removeVisibleLayers(map, visibleLayers);
  }, [map, layersState, setGisValue]);

  useVisibleLayersState({ map, visibleLayers, markers });
};

export default useVisibleLayers;
