import { SliderBaseProps } from 'antd/lib/slider';

import { PopoverProps } from '../../components/ui-kit/Popover/Popover';
import { INITIAL_MARKERS } from '../../constants/initialSettingsConstants';
import PointsUdsStore from '../../stores/pointsUdsStore/pointsUdsStore';
import rootStore from '../../stores/rootStore/rootStore';
import UIStore from '../../stores/uiStore/uiStore';

const { uiStore, pointsUdsStore } = rootStore;

type MarkersKeys = keyof typeof uiStore.markers;
type UIStoreKeys = keyof UIStore;
type PointsStoreKeys = keyof PointsUdsStore;

type MarkersValue = ValueOf<typeof INITIAL_MARKERS>;
type UIStoreValue = ValueOf<typeof UIStore>;
export type PointsStoreValue = ValueOf<typeof PointsUdsStore>;

export type SettingsKeys = MarkersKeys | UIStoreKeys | PointsStoreKeys;
export type SettingsValues = MarkersValue | UIStoreValue | PointsStoreValue;

export const isMarkerKey = (key: string): key is MarkersKeys => {
  return Object.keys(INITIAL_MARKERS).includes(key);
};

export const isPointKey = (key: string): key is PointsStoreKeys => {
  return Object.keys(pointsUdsStore).includes(key);
};

type ProfileValues = MarkersValue | UIStoreValue | PointsStoreValue;

export const isMarkerValue = (
  key: MarkersKeys,
  value: ProfileValues
): value is MarkersValue => {
  return typeof INITIAL_MARKERS[key] === typeof value;
};

export const isUIStoreKey = (
  key: UIStoreKeys | PointsStoreKeys
): key is UIStoreKeys => uiStore?.key !== undefined;

export const isUIStoreValue = (
  key: UIStoreKeys,
  value: ProfileValues
): value is UIStoreValue => {
  return typeof uiStore[key] === typeof value;
};

export const isPointsStoreValue = (
  key: PointsStoreKeys,
  value: ProfileValues
): value is PointsStoreValue => {
  return typeof pointsUdsStore[key] === typeof value;
};

type HandleProfileHybrid = (
  name: SettingsKeys,
  value: UIStore[keyof typeof uiStore]
) => void;

export interface ISettingsProps {
  popOverProps: PopoverProps;
  name: SettingsKeys;
  popoverContent: string | JSX.Element;
  isReload: boolean;
  handleProfileHybrid?: HandleProfileHybrid;
  label: string;
  isLoading?: boolean;
}

export interface SliderSettingProps extends ISettingsProps {
  sliderProps: SliderBaseProps;
  value: number;
}

export interface ITips {
  interfaceTipsDelay: string;
  mapTipsDelay: string;
  mapButtonsTipsDelay: string;
  profileTipsDelay: string;
  gisTooltipsDelay: string;
}
