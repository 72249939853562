import { RefObject, useEffect, useState, useCallback } from 'react';

const useFullscreen = (ref: RefObject<HTMLElement>) => {
  const [status, setStatus] = useState<boolean>(!!document.fullscreenElement);

  const open = useCallback(() => {
    if (!ref.current) return;

    ref.current.requestFullscreen();
  }, [ref]);

  const close = () => {
    if (!status) return;

    document.exitFullscreen();
  };

  const onFullscreenChange = () => {
    setStatus(!!document.fullscreenElement);
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', onFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', onFullscreenChange);
    };
  }, []);

  return { open, close, status };
};

export default useFullscreen;
