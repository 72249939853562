import { getVideoSources } from '../../../../../../../VideoCamera/helpers/getCameraInfo/getCameraInfo';
import {
  VideoCameraProps,
  VideoSources,
} from '../../../../../../../VideoCamera/models/VideoCamera.model';
import getIsMapAction from '../../helpers/getIsMapAction';

export const STREAM_ID = 'stream_';

export const getCameraData = (
  collaboration: Record<string, any>
): VideoCameraProps => {
  const videoSourcesCollab: VideoSources[] =
    collaboration.ext_options?.videoSources ?? [];

  const videoSourcesArr = getVideoSources(videoSourcesCollab);

  const { device_longitude: longitude, device_latitude: latitude } =
    collaboration;

  const isMapAction = getIsMapAction(longitude, latitude);

  return {
    videoSources: videoSourcesArr,
    dsc: collaboration.eputs_dsc,
    caption: collaboration.eputs_name,
    deviceId: collaboration.eputsDeviceId,
    longitude,
    latitude,
    isMapAction,
  };
};
