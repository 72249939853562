/* eslint-disable camelcase */
import { z } from 'zod';

import { NN, NNInt } from '../../../zod/utils.zod';

export const GroupSchema = z.object({
  end_time: NNInt.nullable(),
  followed_objects: z.string().array(),
  group_uuid: z.string(),
  main_object: z.string(),
  start_time: NNInt.nullable(),
  started: z.boolean(),
});

export const GroupStartSchema = z.object({
  message: z.string(),
  start_time: NN,
  end_time: NN,
});
