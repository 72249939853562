import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';

import { ITypesInfo } from '../../../../../constants/signalsConstants';
import { findBy, findById } from '../../../../../helpers/findBy';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { IMG_PATH } from '../../../../../ts/enums/tl';
import { ITimePoints } from '../../../../../ts/models/signalPrograms.model';
import { DaiJdt83 } from '../../../../../ts/models/tl.model';
import { ConfirmProps } from '../../../../ui-kit/Confirm/Confirm';
import Img from '../../../../ui-kit/Img/Img';
import Popover from '../../../../ui-kit/Popover/Popover';

import { getCardData } from './helpers';
import SelectButton from './SelectButton/SelectButton';

import styles from './PhaseCard.module.scss';

export interface IPhaseCard {
  images: DaiJdt83['images'];
  tactTypeObj: ITypesInfo;
  phasesSet: ITimePoints[];
  id: number;
  phase: ITimePoints;
  secTL?: number;
  isSliderCard?: boolean;
  isDisabled?: boolean;
  className?: string;
  confirmProps?: ConfirmProps;
  tipPopover?: string;
  onSelectPhase?: (value: number) => void;
}

const PhaseCard: FC<IPhaseCard> = ({
  images,
  phasesSet,
  tactTypeObj,
  id,
  secTL,
  phase,
  isSliderCard = false,
  isDisabled = false,
  className = '',
  confirmProps,
  tipPopover,
  onSelectPhase,
}) => {
  const { secTls, interfaceTipsDelay } = rootStore.uiStore;
  const [imgUrl, setImgUrl] = useState<N<string>>('');

  const secInfoTls = secTL ?? findById(secTls, id)?.sec;
  const sec = secInfoTls ?? 0;

  const curPhase = phasesSet.find(
    ({ stop, start }) => sec <= stop && sec >= start
  );

  useEffect(() => {
    const pics = images ?? [];
    const imgPhase = findBy(pics, phase.phase, 'phase')?.image;

    if (!imgPhase) return setImgUrl(null);

    const url = IMG_PATH + imgPhase;

    setImgUrl(url);
  }, [curPhase, phase.phase, images]);

  const isCurrentPhase = sec <= phase.stop && sec >= phase.start;
  const remainder = phase.stop - sec;

  const PhaseCardStyles = classNames({
    [styles.card]: true,
    [className]: className,
    [styles.isCurrent]: isCurrentPhase,
    [styles.isPromTac]: remainder < phase.time,
    [styles.isSlider]: isSliderCard,
    [styles.isDisabled]: isDisabled,
  });

  const popOverProps = {
    placement: 'top',
    tipsDelay: interfaceTipsDelay,
  } as const;

  return (
    <li className={PhaseCardStyles}>
      <Img
        src={imgUrl}
        alt="Картинка Фазы"
        className={styles.img}
        isNeedBgEmpty
      />

      <p className={styles.phaseInfo}>
        <span className={styles.phase}>Ф {phase.phase}</span>
        {tactTypeObj.dsc && (
          <Popover {...popOverProps} content={tactTypeObj.name}>
            <span className={styles.phaseType}>{tactTypeObj.dsc}</span>
          </Popover>
        )}
        {isCurrentPhase && remainder > 0 && (
          <span className={styles.time}>{remainder}</span>
        )}
      </p>
      {getCardData(phase).map(({ label, value }, i) => (
        <p className={styles.phaseInfo} key={i}>
          <span>{label}</span>
          <span>{value} сек</span>
        </p>
      ))}
      {onSelectPhase ? (
        <SelectButton
          onClick={() => onSelectPhase(phase.phase)}
          phase={phase.phase}
          isDisabled={isDisabled}
          confirmProps={confirmProps}
          tipPopover={tipPopover}
        />
      ) : null}
    </li>
  );
};

export default observer(PhaseCard);
