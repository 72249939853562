import { Tabs } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import IdentificationInfo from '../IdentificationInfo/IdentificationInfo';
import LinkedsInfo from '../SystemsInfo/LinkedsInfo/LinkedDevicesInfo';
import Crossroads from '../SystemsInfo/Сrossroads/Crossroads';

import { ClusterTabKeys } from './constants/constants';
import useClusterTabs from './hooks/useClusterTabs';
import { ClusterTab } from './model/cluster.model';
import TabLabel from './TabLabel/TabLabel';

import styles from './ClusterInfo.module.scss';

const ClusterInfo: FC = () => {
  const { isDetailedForm } = rootStore.uiStore;
  const {
    isDisabledCrossroads,
    isDisabledLayers,
    titleCrossroads,
    gisLayerTooltip,
    link,
    activeKey,
    onTabClick,
  } = useClusterTabs();

  if (isDetailedForm || !link) return null;

  const tabsItems: ClusterTab[] = [
    {
      label: <TabLabel tabName="Все объекты" />,
      key: ClusterTabKeys.MainInfo,
      children: (
        <div className={styles.wrapper}>
          <LinkedsInfo linkedDevices={link} />
        </div>
      ),
    },
    {
      label: (
        <TabLabel
          tabName="Перекрёстки"
          tooltip={titleCrossroads}
          isDisabled={isDisabledCrossroads}
        />
      ),
      key: ClusterTabKeys.Crossroads,
      children: <Crossroads linkedDevices={link} />,
      disabled: isDisabledCrossroads,
    },
    {
      label: (
        <TabLabel
          tabName="Объекты ГИС"
          isDisabled={isDisabledLayers}
          tooltip={gisLayerTooltip}
        />
      ),
      key: ClusterTabKeys.Identification,
      children: <IdentificationInfo />,
      disabled: isDisabledLayers,
    },
  ];

  return (
    <div className={styles.info}>
      <Tabs items={tabsItems} activeKey={activeKey} onTabClick={onTabClick} />
    </div>
  );
};

export default observer(ClusterInfo);
