import DevicesBtns from './DevicesBtns/DevicesBtns';
import MapMainButtons from './MapMainButtons/MapMainButtons';

import styles from './MainButtons.module.scss';

const MainButtons = () => (
  <div className={styles.container}>
    <DevicesBtns />
    <MapMainButtons />
  </div>
);

export default MainButtons;
