import { App, Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC, MouseEvent } from 'react';
import { HiOutlineFolderAdd } from 'react-icons/hi';

import rootStore from '../../../../../stores/rootStore/rootStore';
import { TLDocumentsCode } from '../../../../../ts/enums/userData';
import Popover from '../../../../ui-kit/Popover/Popover';
import { FilesDataType } from '../../../models/documents.model';

import UploadDocument from './UploadDocument/UploadDocument';

import styles from './PanelHeader.module.scss';

interface IPanelHeader {
  data: FilesDataType;
  id: number;
}

type IconEvent = MouseEvent<HTMLElement, globalThis.MouseEvent>;

const PanelHeader: FC<IPanelHeader> = ({ data, id }) => {
  const { interfaceTipsDelay } = rootStore.uiStore;
  const { hasAccess } = rootStore.userDataStore;
  const isUploadBtn = hasAccess(TLDocumentsCode.uploadBtn);
  const { modal } = App.useApp();

  const handleAddFile = (e: IconEvent) => {
    e.stopPropagation();
    modal.info({
      title: `Форма добавления нового документа`,
      content: (
        <UploadDocument categoryId={data.lb_file_category_id} deviceId={id} />
      ),
      okText: 'Закрыть',
    });
  };

  return (
    <div className={styles.wrapper}>
      <p className={styles.header}>{data.categoryName}</p>
      <div className={styles.count}>{data.children?.length ?? 0}</div>
      {isUploadBtn && (
        <Popover
          content="Загрузить"
          tipsDelay={interfaceTipsDelay}
          placement="top"
        >
          <Button
            icon={<HiOutlineFolderAdd />}
            className={styles.addBtn}
            onClick={handleAddFile}
          />
        </Popover>
      )}
    </div>
  );
};

export default observer(PanelHeader);
