import { VERSION } from '../../../constants/versions';
import { getAppPathHeaders } from '../../helpers/getAppPathHeaders';
import http from '../../http';
import tryCatchWrapper from '../../tryCatchWrapper';

const SETTINGS = VERSION + '/user/settings';

export const settings = {
  saveItem: async (name: string, value: any, appPath: string) =>
    tryCatchWrapper(
      async () => {
        const res = http.put(
          `${SETTINGS}/item`,
          {
            key: name,
            value: value,
          },
          {
            headers: getAppPathHeaders(appPath),
          }
        );

        return res;
      },
      {
        errorMessage: 'SETTINGS_ERROR',
      }
    ),
  saveList: (userId: number | string, settings: object, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.put(
        `${SETTINGS}/${userId}`,
        { settings },
        {
          headers: getAppPathHeaders(appPath),
        }
      );

      return res;
    }),
};
