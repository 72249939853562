import createArray from '../../../helpers/createArray';
import { LaneDt, Point } from '../../../ts/models/MapObjects/detectors.model';

export type AreaSize = {
  x: number;
  y: number;
};

const FULL_HD = {
  x: 1920,
  y: 1080,
};

const YELLOW = '#eff542';
const WHITE = 'white';
const BLACK = 'black';
const FONT_STYLE = '20px BlinkMacSystemFont';
const ERROR_RATE = 5;
const INDENT = 20;

const LINES_WIDTH = {
  s: 1,
  m: 2,
};

enum RgbaColors {
  crimson = 'rgba(245, 66, 66, 0.3)',
  green = 'rgba(108, 245, 66, 0.3)',
  turquoise = 'rgba(66, 242, 245, 0.3)',
  blue = 'rgba(66, 108, 245, 0.3)',
  pink = 'rgba(236, 66, 245, 0.3)',
  orange = 'rgba(240, 116, 39, 0.3)',
  purple = 'rgba(148, 87, 235, 0.3)',
  yellow = 'rgba(244, 202, 22, 0.3)',
}

const colors = Object.values(RgbaColors);

export const getCurrentPointCoord = (
  defaultCoord: XY,
  currLength: AreaSize,
  defaultLength = FULL_HD
) => {
  //  ===It may be necessary to check for the correct aspect ratio.===

  // const isTheRightRatio = currLength.x / currLength.y === defaultLength.x / defaultLength.y;
  // if (!isTheRightRatio) throw new Error('Неверное соотношение сторон');

  const x1 = defaultCoord[0];
  const y1 = defaultCoord[1];

  const x2 = (x1 * currLength.x) / defaultLength.x;
  const y2 = (y1 * currLength.y) / defaultLength.y;

  return { x: x2, y: y2 };
};

export const drawShape = (
  lane: LaneDt,
  ctx: CanvasRenderingContext2D,
  curWidth: number,
  curHeight: number,
  index: number,
  isShowAllInfo: boolean
) => {
  const { uid, points, number } = lane;

  ctx.fillStyle = colors[index];
  ctx.strokeStyle = BLACK;

  ctx.beginPath();

  let [first, second, last] = createArray(3, { x: 0, y: 0 });

  points.forEach((point, i) => {
    const currentPointCoord = getCurrentPointCoord([point.x, point.y], {
      x: curWidth,
      y: curHeight,
    });
    const { x, y } = currentPointCoord;

    if (i === 0) {
      first = currentPointCoord;

      return ctx.moveTo(x, y);
    }

    if (i === 1) second = currentPointCoord;
    if (i === points.length - 1) last = currentPointCoord;

    ctx.lineTo(x, y);
  });
  ctx.stroke();
  ctx.fill();
  ctx.closePath();

  const zone = number ? String(number) : uid;

  ctx.font = FONT_STYLE;
  ctx.fillStyle = WHITE;

  const centerPointA = {
    x: (first.x + second.x) / 2,
    y: (first.y + second.y) / 2,
  };
  const centerPointB = {
    x: (centerPointA.x + last.x) / 2,
    y: (centerPointA.y + last.y) / 2,
  };

  const X = centerPointB.x - ERROR_RATE;
  const Y = centerPointB.y;

  ctx.fillText(zone ?? '', X, Y);
  number && isShowAllInfo && ctx.fillText(uid ?? '', X, Y + INDENT);
  ctx.closePath();
};

export const drawTLines = (
  points: Point[],
  ctx: CanvasRenderingContext2D,
  curWidth: number,
  curHeight: number
) => {
  ctx.fillStyle = WHITE;
  ctx.strokeStyle = BLACK;
  ctx.lineWidth = LINES_WIDTH.m;

  ctx.beginPath();
  const currentFirstCoord = getCurrentPointCoord([points[0].x, points[0].y], {
    x: curWidth,
    y: curHeight,
  });
  const currentSecondCoord = getCurrentPointCoord([points[1].x, points[1].y], {
    x: curWidth,
    y: curHeight,
  });

  ctx.moveTo(currentFirstCoord.x, currentFirstCoord.y);
  ctx.lineTo(currentSecondCoord.x, currentSecondCoord.y);
  ctx.stroke();
  ctx.fill();
  ctx.closePath();

  ctx.beginPath();
  ctx.lineWidth = LINES_WIDTH.s;
  ctx.strokeStyle = YELLOW;
  ctx.arc(currentSecondCoord.x, currentSecondCoord.y, 3, 0, 7);
  ctx.arc(currentFirstCoord.x, currentFirstCoord.y, 3, 0, 7);

  // == it will be needed later, for now Alexander asked to hide ==

  // const centralPoint = {
  //   x: (currentFirstCoord.x + currentSecondCoord.x) / 2,
  //   y: (currentFirstCoord.y + currentSecondCoord.y) / 2,
  // };
  // ctx.arc(centralPoint.x, centralPoint.y, 10, 0, 7);

  ctx.stroke();
  ctx.fill();
  ctx.closePath();
};
