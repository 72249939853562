import { Input, type InputRef } from 'antd';
import { ChangeEventHandler, FC, RefObject, useEffect } from 'react';

import { INPUT_MAX_LEN } from '../../../../../../../constants/constants';
import rootStore from '../../../../../../../stores/rootStore/rootStore';

interface SearchDropdownProps {
  searchInput: RefObject<InputRef>;
}

const SearchDropdown: FC<SearchDropdownProps> = ({ searchInput }) => {
  const { setMonitoringValue } = rootStore.monitoringPanelStore;

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setMonitoringValue('searchMonitoring', e.target.value || null);
  };

  useEffect(() => {
    return () => {
      setMonitoringValue('searchMonitoring', null);
    };
  }, [setMonitoringValue]);

  return (
    <Input
      ref={searchInput}
      placeholder="Поиск адаптера..."
      allowClear
      onChange={onChange}
      maxLength={INPUT_MAX_LEN}
    />
  );
};

export default SearchDropdown;
