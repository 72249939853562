import { attachMediaStream } from 'adapterjs';

const setRemoteStream = (
  stream: any,

  element: HTMLElement
) => {
  attachMediaStream(element, stream);
};

export const onRemoteTrack = (
  event: any,
  elements: { audio: HTMLElement | null; video: HTMLElement | null }
) => {
  let stream = null;
  let element = null;

  if (!event.streams) {
    stream = event.stream;

    if (stream.getAudioTracks().length > 0) {
      element = elements.audio;
    }
    if (stream.getVideoTracks().length > 0) {
      element = elements.video;
    }
  }

  if (event.streams) {
    stream = event.streams[0];

    if (event.track.kind === 'audio') {
      element = elements.audio;
    }
    if (event.track.kind === 'video') {
      element = elements.video;
    }
  }

  if (stream && element) {
    setRemoteStream(stream, element);
  }
};
