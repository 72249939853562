import { observer } from 'mobx-react-lite';
import { FC, useRef } from 'react';

import { ITimePoints } from '../../../../../../../../../ts/models/signalPrograms.model';
import useResize from '../../../../../../../../NewSignalProgram/hooks/useResize';
import SignalProgramsBand from '../../../../../../../../SignalProgramsBand/SignalProgramsBand';
import useCurrentProgram from '../../../../../../../../TrafficLightDetailed/TlManagement/PhaseTimeChangeCurrentPlanMode/hooks/useCurrentProgram';
import DefaultChecked from '../../../../../../../../ui-kit/DefaultChecked/DefaultChecked';

import styles from './CurrentPlan.module.scss';

const CORRECTION_SIZE = 20;

interface CurrentPlanProps {
  tlId: number;
  sec: number;
}

const CurrentPlan: FC<CurrentPlanProps> = ({ tlId, sec }) => {
  const wrapRef = useRef<HTMLDivElement>(null);

  const { sgnProgramValue, isError, planName } = useCurrentProgram(tlId);
  const { width } = useResize({ wrapRef });

  const phasesSet = sgnProgramValue?.phasesSet;

  return (
    <div className={styles.container} ref={wrapRef}>
      <DefaultChecked isEmptyData={!phasesSet?.length}>
        <h4>Текущий план: {planName}</h4>
        <SignalProgramsBand
          isError={isError}
          phasesSet={phasesSet as ITimePoints[]}
          width={width - CORRECTION_SIZE}
          isSmall
          sec={sec}
        />
      </DefaultChecked>
    </div>
  );
};

export default observer(CurrentPlan);
