export enum ClusterTabKeys {
  MainInfo = 'mainInfo',
  Crossroads = 'crossroads',
  Identification = 'identification',
}

export const CROSSROAD_TOOLTIPS = {
  INACTIVE_TL: 'Необходимо активировать слой СО на карте',
  EMPTY: 'На данном участке перекрёстки отсутствуют',
};

export const LAYER_TOOLTIPS = {
  INACTIVE_GIS_LAYERS:
    'Для просмотра слоёв необходимо добавить хотя бы один Гис слой на карту',
  EMPTY: 'На данном участке слои отсутствуют',
};

export const IDENTIFICATION_LAYERS_TOOLTIPS =
  'Объекты слоёв ГИС, в радиусе последнего клика по карте';
