import { observer } from 'mobx-react-lite';

import styles from './PhaseCircleNoData.module.scss';

const PhaseCircleNoData = () => (
  <div className={styles.notData}>
    <p> Недостаточно данных для отображения</p>
  </div>
);

export default observer(PhaseCircleNoData);
