import { LoadProperties } from '../models/VideoCamera.model';

export const INITIAL_PROPERTIES: LoadProperties = {
  isLoad: true,
  textError: null,
};

export const ERROR_TEXT = 'Видеопоток отсутствует';
export const ERROR_CAMERA = 'Отсутствует привязка к камере';
export const ERROR_URL = 'Неккоректная ссылка на поток камеры';
