import { findById } from '../../../../../../../../../helpers/findBy';
import { System } from '../../../../../../../../../ts/enums/enums';
import { TL } from '../../../../../../../../../ts/models/tl.model';

const getDeviceData = (tls: TL[], ids: number[]) => {
  const data = ids.reduce((acc: TL[], id) => {
    const tl = findById(tls, id);

    tl && acc.push(tl);

    return acc;
  }, []);

  const deviceData = [{ system: System.Lights, data }];

  return deviceData;
};

export default getDeviceData;
