import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import WarningNotify from '../../../../../../../TrafficLightDetailed/TlManagement/StatusCard/WarningNotify/WarningNotify';
import ControlPanel from '../ConrolPanel/ControlPanel';
import { getTLControlInfo } from '../helpers/helpers';

import styles from './MainContent.module.scss';

interface MainContentProps {
  tlId: number;
}

const MainContent: FC<MainContentProps> = ({ tlId }) => {
  const tlControlInfo = getTLControlInfo(tlId);

  if (!tlControlInfo) return null;

  const { isActiveCoordTL, warningText, isAccessMode } = tlControlInfo;

  return (
    <div className={styles.container}>
      <ControlPanel tlIds={tlId} isActiveCoordTL={isActiveCoordTL} />
      <WarningNotify
        isActiveNotify={isActiveCoordTL || !isAccessMode}
        warningTitle={warningText}
      />
    </div>
  );
};

export default observer(MainContent);
