import { Tabs } from 'antd';

import { DEVICE_CARD_TAB_ITEMS } from './constants/constants';

import styles from './MainContent.module.scss';

const MainContent = () => {
  return (
    <div className={styles.container}>
      <Tabs items={DEVICE_CARD_TAB_ITEMS} destroyInactiveTabPane />
    </div>
  );
};

export default MainContent;
