import { FilterValue } from '../../detailedStore.model';

import { getFilteredValue } from './getFilteredValue';

export const getParentFilterValue = (
  arr: N<number[]>,
  value: Exclude<FilterValue, 'default'>,
  parentType: number,
  filteredData: N<number[]>
) => {
  const isNeedOffOtherFilters = !!value && arr?.length && !filteredData;

  const isNeedOnDir = !!value && !arr;

  const isNeedAddFilter = Boolean(value && arr?.length && filteredData);

  if (isNeedOnDir) return getFilteredValue(arr, parentType);

  if (isNeedOffOtherFilters || isNeedAddFilter) return [parentType];

  return;
};
