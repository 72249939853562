import { Button, App } from 'antd';
import { observer } from 'mobx-react-lite';
import { MouseEventHandler, FC } from 'react';
import { FaFileUpload } from 'react-icons/fa';

import rootStore from '../../../../../stores/rootStore/rootStore';
import Popover from '../../../../ui-kit/Popover/Popover';
import UploadImg, { UploadImgProps } from '../UploadImg/UploadImg';

import styles from './UploadBtn.module.scss';

type HandleAddImg = MouseEventHandler<HTMLAnchorElement> &
  MouseEventHandler<HTMLButtonElement>;

const UploadBtn: FC<UploadImgProps> = (props) => {
  const { interfaceTipsDelay } = rootStore.uiStore;

  const { modal } = App.useApp();

  const handleAddImg: HandleAddImg = (e) => {
    e.stopPropagation();
    modal.info({
      title: `Загрузка изображения`,
      content: <UploadImg {...props} />,
      okText: 'Закрыть',
      width: 500,
    });
  };

  return (
    <Popover
      content="Загрузить изображение"
      tipsDelay={interfaceTipsDelay}
      placement="left"
    >
      <Button
        icon={<FaFileUpload />}
        className={styles.uploadBtn}
        onClick={handleAddImg}
      />
    </Popover>
  );
};

export default observer(UploadBtn);
