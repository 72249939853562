import { isString } from 'lodash';
import type { Feature } from 'ol';
import type { Point } from 'ol/geom';
import { useCallback, useEffect, useState } from 'react';

import eventBus from '../../../../../../../eventBus';
import { DeviceInfo } from '../../../../../../../stores/monitoringPanelStore/monitoringPanelStore.model';
import {
  addMarker,
  getMarker,
} from '../../../../../../Mapper/helpers/addMarkers';
import getDeviceBySystem from '../../CrossroadSchema/helpers/getDeviceBySystem';

const useFeatures = (deviceInfo: DeviceInfo) => {
  const [features, setFeatures] = useState<Feature<Point>[]>([]);

  const updateFeatures = useCallback(() => {
    const { id, system } = deviceInfo;

    const device = getDeviceBySystem(id, system);

    const isPointUds = isString(device?.deviceInfo.pointUdsUid);

    if (!device || isPointUds) return setFeatures([]);

    const newFeature = addMarker(getMarker(device), system);

    setFeatures([newFeature]);
  }, [deviceInfo]);

  useEffect(() => {
    const unsubscribe = eventBus.updateFeatures.subscribe(updateFeatures);

    updateFeatures();

    return () => unsubscribe();
  }, [updateFeatures]);

  return features;
};

export default useFeatures;
