import dayjs, { Dayjs } from 'dayjs';

import { COMMANDS } from '../../../../constants/commands';
import { DATE_FORMAT } from '../../../../constants/constants';
import { NearestCommand } from '../../../../ts/models/tl.model';
import 'dayjs/locale/ru';
import { getTimeWithTimeZone } from '../../TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeWithTimeZone';
import { TCommands } from '../model/postCommands.model';

const {
  SET_OBJECT_PHASE,
  SET_OBJECT_PLAN_TIME_ON_SCHEDULER,
  SET_OBJECT_SYNC_PLAN,
} = COMMANDS;

const COMMANDS_TIMES = [
  SET_OBJECT_PHASE,
  SET_OBJECT_PLAN_TIME_ON_SCHEDULER,
  SET_OBJECT_SYNC_PLAN,
];

const getPlannedDate = (date: Dayjs) => {
  const formatDate = getTimeWithTimeZone({
    dateJS: date,
    isUTCZero: true,
  }).dateFormattedJS;

  const formatDateNow = getTimeWithTimeZone().dateFormattedJS;

  const isPlanned = formatDate.diff(formatDateNow) > 0;

  return { isPlanned, formatDate };
};

export const getNearestCommand = (commands: TCommands[]): N<NearestCommand> => {
  const filteredCommands = commands.reduce((acc: N<NearestCommand>, item) => {
    const { dtbegin_command, dtend_command, dcc_type, jcmd_sender_add_info } =
      item;

    const dateStart = dayjs(dtbegin_command, DATE_FORMAT, true);
    const dateEnd = dayjs(dtend_command, DATE_FORMAT, true);

    const isValidStart = dateStart.isValid();
    const isValidEnd = dateEnd.isValid();

    const isSimpleCommand = COMMANDS_TIMES.every((el) => el !== dcc_type);

    if (isSimpleCommand || !isValidStart || !isValidEnd) {
      return acc;
    }

    const plannedStart = getPlannedDate(dateStart);
    const plannedEnd = getPlannedDate(dateEnd);

    if (!plannedEnd.isPlanned) return acc;

    const commandItem = acc?.[dcc_type];

    const commandNum = jcmd_sender_add_info
      ? Number(jcmd_sender_add_info.command.param1)
      : null;

    if (!commandItem) {
      return {
        [dcc_type]: {
          startTime: plannedStart.formatDate,
          endTime: plannedEnd.formatDate,
          commandNum,
        },
      };
    }

    const isNearest = plannedStart.formatDate.diff(commandItem.startTime) < 0;

    if (isNearest) {
      acc[dcc_type] = {
        startTime: plannedStart.formatDate,
        endTime: plannedEnd.formatDate,
        commandNum,
      };
    }

    return acc;
  }, null);

  return filteredCommands;
};
