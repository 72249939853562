import { LibraryValues } from '../../../../stores/uiStore/uiStore.model';
import { Path } from '../../../api.model';
import { getAppPathHeaders } from '../../../helpers/getAppPathHeaders';
import http from '../../../http';
import tryCatchWrapper from '../../../tryCatchWrapper';
import { DictPredictKeys, DT_STATISTIC } from '../constants/constants';

const DICT = DT_STATISTIC + '/dict';

const dictApi = {
  fetchLibraryByKeyword: (libraryKey: DictPredictKeys, appPath: Path) =>
    tryCatchWrapper<LibraryValues[]>(async () => {
      const res = await http.get(`${DICT}/${libraryKey}`, {
        headers: getAppPathHeaders(appPath),
      });

      return res.data;
    }),
};

export default dictApi;
