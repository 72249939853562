import { CrossroadImgArgs } from '../models/crossroad.model';

import { useCrossroadEls, useFetchCrossroadSvg } from '.';

const useCrossroadImg = ({
  id,
  color,
  crosswalkColor,
  strokeWidth,
  isCrossroad,
  isNotify = true,
  appPath,
}: CrossroadImgArgs) => {
  const { data, isLoading } = useFetchCrossroadSvg({ id, isNotify, appPath });

  useCrossroadEls({
    data,
    id,
    color,
    crosswalkColor,
    strokeWidth,
    isCrossroad,
  });

  return { data, isLoading };
};

export default useCrossroadImg;
