import { isEqual } from 'lodash';
import { type Feature } from 'ol';
import type { FeatureLike } from 'ol/Feature';
import type { Point } from 'ol/geom';
import { Style } from 'ol/style';

import { MultipleClusters } from '../../../../../stores/clustersStore/clustersStore.model';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { FeaturesTypes } from '../../../../../ts/enums/enums';

import { StyleCache } from './clusterStyles';
import { getClusterStyle } from './getClusterStyle';
import { getEmptyCluster } from './getEmptyCluster';

interface IGetStyleClickedCluster {
  feature: FeatureLike;
  clickedCluster: MultipleClusters;
  scale: number;
  styleCache: StyleCache;
}

export const getStyleClickedCluster = ({
  feature,
  clickedCluster,
  scale,
  styleCache,
}: IGetStyleClickedCluster): U<Style | Style[]> => {
  const { isNeedOpenedCluster } = rootStore.uiStore;
  const { featureMode } = rootStore.clustersStore;

  const isSelectCluster = isEqual(
    feature.get(FeaturesTypes.Features),
    clickedCluster.cluster.get(FeaturesTypes.Features)
  );

  const features: Feature<Point>[] = feature.get('features');

  if (!isNeedOpenedCluster && isSelectCluster) {
    return getClusterStyle(features, featureMode, {
      scale,
      styleChart: 'donut',
      styleCache,
    });
  }

  if (isSelectCluster) return getEmptyCluster();
};
