import { FC } from 'react';

import { IFilteredInfo, TableData } from '../../../../../ts/models/table.model';
import { getTypeRendersWithLibrary } from '../../helpers/getColumns/helpers/prepareColumnDataView/prepareColumnDataView';
import { getLibraryItem } from '../../helpers/getLibraryItem';
import { showFilterTagText } from '../../helpers/showFilterTagText';
import FilterItem from '../FilterItem/FilterItem';

import styles from './FilterList.module.scss';

interface IFilterList {
  filters: string[];
  filteredInfo: IFilteredInfo;
  handleClose: (filter: string) => void;
  tableData: TableData;
}

const FilterList: FC<IFilterList> = ({
  filters,
  filteredInfo,
  handleClose,
  tableData,
}) => {
  return (
    <ul className={styles.list}>
      {filteredInfo &&
        filters.map((filter: string, i: number) => {
          if (!filteredInfo || !filteredInfo[filter]) return null;

          const column = tableData.headerItem.find(
            (item) => item.dataIndex === filter
          );

          if (!column) return null;

          const preparedFilterItem = filteredInfo[filter]?.map((el) => {
            const libraryItem = getLibraryItem(column, tableData);

            const renderTypes = getTypeRendersWithLibrary(libraryItem);

            const renderFunc =
              renderTypes[typeof el as keyof typeof renderTypes];

            return renderFunc?.(el as any)?.toString() || '';
          }) as string[];

          const value = showFilterTagText(column.title, preparedFilterItem);

          return (
            <FilterItem
              key={i}
              filter={filter}
              handleClose={handleClose}
              value={value}
            />
          );
        })}
    </ul>
  );
};

export default FilterList;
