import { Col, Row } from 'antd';
import { AiOutlineClose } from 'react-icons/ai';

import { IMeasurementResult } from '../../../apiGIS/utils/measurement';

import styles from './MeasurementsButton.module.scss';

export const MeasurementResultItem = (props: {
  onMouseOver: () => void;
  onClose: () => void;
  item: IMeasurementResult;
}) => (
  <div onMouseOver={props.onMouseOver} onFocus={props.onMouseOver}>
    <Row justify={'start'} align={'middle'}>
      <Col span={2}>
        <div className={styles.measureNumber}>{props.item.id}</div>
      </Col>
      <Col span={1}></Col>
      <Col span={18}>
        <div className={styles.measureValue}>{props.item.valueView}</div>
      </Col>
      <Col span={1}></Col>
      <Col span={2}>
        <AiOutlineClose
          size={10}
          className={styles.closeIcon}
          onClick={props.onClose}
        />
      </Col>
    </Row>
  </div>
);
