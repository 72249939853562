import { Dayjs } from 'dayjs';
import { useCallback, useEffect, useRef, useState } from 'react';

import { COMMANDS } from '../../../../../constants/commands';
import { DATE_FORMAT_TO_SHOW } from '../../../../../constants/constants';
import { DatePickerProps } from '../../../../ui-kit/Picker/DatePicker/DatePicker';
import { TimePickerBasicProps } from '../../../../ui-kit/Picker/TimePicker/TimePicker';
import {
  DEFAULT_DISABLED_TIME_DURATION,
  INITIAL_TIME,
} from '../../constants/constants';
import { changeDate } from '../../helpers/changeDate';
import { getFormattedDayJSToSec } from '../../helpers/getFormattedDayJSToSec';
import { disabledDateTime } from '../../PhaseHold/helpers/disabledDateTime';
import getCorrectTimeValue from '../../PhaseHold/TimePhaseCard/helpers/getCorrectTimeValue';
import { SelectedTime } from '../../PlanSelect/models/plansControl.model';

import styles from './styles/basicTimeCard.module.scss';

const TIME_TO_POST_COMMAND = 2;

interface UseTimeCardProps {
  command: COMMANDS;
  isChecked?: boolean;
  disabledTime?: number;
  isDisabled?: boolean;
  selectedTimeInfo: SelectedTime;
  setSelectedTimeInfo: SetState<SelectedTime>;
}

const useTimeCard = ({
  command,
  disabledTime = DEFAULT_DISABLED_TIME_DURATION,
  isChecked = false,
  isDisabled = false,
  selectedTimeInfo,
  setSelectedTimeInfo,
}: UseTimeCardProps) => {
  const [isAnimate, setIsAnimate] = useState<boolean>(false);

  const durationBlockRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const timeoutId = setTimeout(() => setIsAnimate(isChecked));

    if (!isChecked) return;

    durationBlockRef.current?.scrollIntoView({ behavior: 'smooth' });

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isChecked]);

  const handlerChangeDate = useCallback(
    (value: N<Dayjs>) => {
      const res = changeDate(value, command, disabledTime);

      setSelectedTimeInfo((prev) => ({
        ...prev,
        status: res?.statusDate ?? prev.status,
        date: res?.valueDate ?? null,
      }));
    },
    [command, disabledTime, setSelectedTimeInfo]
  );

  const { duration, status, date } = selectedTimeInfo;

  const datePickerProps: DatePickerProps = {
    pickerClassName: styles.picker,
    status,
    value: date,
    showNow: false,
    dateFormat: DATE_FORMAT_TO_SHOW,
    onChangeDate: handlerChangeDate,
    disabledDateOptions: { countPast: 0, typePast: 'day', countFeature: 1 },
    handleDisableTime: (value: N<Dayjs>) =>
      disabledDateTime(value, disabledTime + TIME_TO_POST_COMMAND),
    disabled: isDisabled,
  };

  const timePickerProps: TimePickerBasicProps = {
    pickerStyle: styles.picker,
    onChange: (time) =>
      setSelectedTimeInfo((prev) => ({
        ...prev,
        duration: getFormattedDayJSToSec(time),
      })),
    placeholder: 'Укажите длительность',
    disabledTimeProps: INITIAL_TIME,
    value: getCorrectTimeValue(duration),
    disabled: isDisabled,
  };

  return {
    datePickerProps,
    timePickerProps,
    isAnimate,
    durationBlockRef,
  };
};

export default useTimeCard;
