import { Card } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { getSystemTitle } from '../../../helpers/getSystemTitle';
import rootStore from '../../../stores/rootStore/rootStore';
import { MapObjectFull, System } from '../../../ts/enums/enums';
import CameraInfo from '../../InfoPanel/SystemsInfo/CameraInfo/CameraInfo';

import styles from './GeneralCameraInfo.module.scss';

interface GeneralCameraInfoProps {
  id: number;
}

const GeneralCameraInfo: FC<GeneralCameraInfoProps> = ({ id }) => {
  const { getById } = rootStore.mapDataStore;

  const camera = getById(id, MapObjectFull.cameras);

  if (!camera) return null;

  return (
    <div className={styles.container}>
      <Card className={styles.mainInfoCard}>
        <div className={styles.titleRowContainer}>
          <h3>Система: </h3>
          <p>{getSystemTitle(System.Cameras, camera)}</p>
        </div>
        <div className={styles.titleRowContainer}>
          <h3>Адрес: </h3>
          <p>{camera?.caption}</p>
        </div>
      </Card>
      <Card className={styles.cameraLive}>
        <CameraInfo id={id} />
      </Card>
    </div>
  );
};

export default observer(GeneralCameraInfo);
