import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../../stores/rootStore/rootStore';
import Popover from '../../../../ui-kit/Popover/Popover';

import styles from './SelectItem.module.scss';

interface SelectItemProps {
  id: number;
  caption: string;
  isDisabled: boolean;
}

const SelectItem: FC<SelectItemProps> = ({ id, caption, isDisabled }) => {
  const { interfaceTipsDelay } = rootStore.uiStore;
  const content = `id${id} | ${caption.replace(/"/g, '')}`;

  const popoverContent = isDisabled
    ? 'Данный СО уже находится в группе КУ'
    : '';

  return (
    <Popover
      tipsDelay={interfaceTipsDelay}
      content={popoverContent}
      placement="left"
    >
      <p className={styles.title}>{content}</p>
    </Popover>
  );
};

export default observer(SelectItem);
