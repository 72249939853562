import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { ReactComponent as ProfileIco } from '../../../assets/icons/ic_profile_sidebar.svg';
import rootStore from '../../../stores/rootStore/rootStore';
import { PanelType } from '../../../ts/enums/enums';
import Popover from '../../ui-kit/Popover/Popover';

import styles from './ProfileBtn.module.scss';

const ProfileBtn = () => {
  const { isPanel, panelType, switchPanel, interfaceTipsDelay } =
    rootStore.uiStore;

  const handleProfile = () => switchPanel(PanelType.Profile);

  const profileIcoStyle = classNames({
    [styles.profileIco]: true,
    [styles.active]: isPanel && panelType === 'profile',
  });

  return (
    <Popover
      content="Профиль пользователя"
      placement="left"
      tipsDelay={interfaceTipsDelay}
    >
      <div
        className={styles.profileWrapper}
        id="profile"
        onClick={handleProfile}
        role="presentation"
      >
        <ProfileIco className={profileIcoStyle} />
      </div>
    </Popover>
  );
};

export default observer(ProfileBtn);
