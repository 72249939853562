import { useEffect, useState } from 'react';

import {
  PhaseData,
  PhaseDataArgs,
} from '../PhaseCircleOverlay/PhaseCircleOverlay.model';

const usePhaseData = ({ timer, formattedData }: PhaseDataArgs) => {
  const [phaseData, setPhaseData] = useState<PhaseData>(formattedData[0]);

  useEffect(() => {
    const newPhaseData = formattedData.find(({ stop }) => timer <= stop);

    newPhaseData && setPhaseData(newPhaseData);
  }, [formattedData, timer]);

  return phaseData;
};

export default usePhaseData;
