import { FC, RefObject, useEffect, useRef } from 'react';

import { ConfirmProps } from '../../../../../../../../../ui-kit/Confirm/Confirm';

interface ConfirmBlockProps {
  title: ConfirmProps['title'];
  setIsOpenConfirm: SetState<boolean>;
  cardRef: RefObject<HTMLElement>;
}

const ConfirmBlock: FC<ConfirmBlockProps> = ({
  title,
  setIsOpenConfirm,
  cardRef,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick: EventListener = (e) => {
      if (!containerRef.current || !cardRef.current) return;

      const path = e.composedPath();

      if (
        !path.includes(containerRef.current) &&
        !path.includes(cardRef.current)
      ) {
        setIsOpenConfirm(false);
      }
    };

    window.addEventListener('click', handleClick);

    return () => window.removeEventListener('click', handleClick);
  }, [cardRef, setIsOpenConfirm]);

  return <div ref={containerRef}>{title}</div>;
};

export default ConfirmBlock;
