import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { isUndefined, uniqueId } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC, MouseEventHandler, useEffect } from 'react';

import rootStore from '../../../../../stores/rootStore/rootStore';
import Popover from '../../../../ui-kit/Popover/Popover';
import { TDtInfo } from '../TitleBtns/helpers/getTitleDetectors';

import styles from './RadioButtonsGraph.module.scss';

interface RadioButtonsGraphProps {
  btnsDscArr: TDtInfo[];
  value: string;
  isDisabled?: boolean;
  size?: SizeType;
  radioContainerClassName?: string;
  onChange?: (value: any) => void;
  onClick?: MouseEventHandler<HTMLInputElement>;
}

const RadioButtonsGraph: FC<RadioButtonsGraphProps> = ({
  btnsDscArr,
  value,
  size,
  isDisabled = false,
  radioContainerClassName = styles.radioGroup,
  onChange,
  onClick,
}) => {
  const { interfaceTipsDelay } = rootStore.uiStore;

  // change key if btn Disabled
  useEffect(() => {
    const el = btnsDscArr.find((el) => el.valueDt === value);

    const defaultValue = btnsDscArr.at(0)?.valueDt;

    el?.isDisabled && !isUndefined(defaultValue) && onChange?.(defaultValue);
  }, [btnsDscArr, onChange, value]);

  const onChangeValue = (e: RadioChangeEvent) => onChange?.(e.target.value);

  return (
    <Radio.Group
      onChange={onChangeValue}
      value={value}
      className={radioContainerClassName}
      buttonStyle="solid"
      size={size}
      disabled={isDisabled}
    >
      {btnsDscArr.map(
        ({
          caption,
          valueDt,
          dsc,
          isDisabled = false,
          specialWarning = null,
        }) => (
          <Popover
            tipsDelay={interfaceTipsDelay}
            content={dsc}
            key={uniqueId()}
          >
            <Radio.Button
              value={valueDt}
              onClick={onClick}
              disabled={isDisabled}
              className={styles.btn}
            >
              <>
                {caption}
                <span className={styles.specialInfo}>{specialWarning}</span>
              </>
            </Radio.Button>
          </Popover>
        )
      )}
    </Radio.Group>
  );
};

export default observer(RadioButtonsGraph);
