import { useContext } from 'react';

import { PanelContent, PanelDataContext } from '../InfoPanelWithContext';

type PanelContext<T extends PanelContent> = {
  panelContent: T;
  setPanelContent: SetState<T>;
};

const usePanelData = <T extends PanelContent>() => {
  const panelData: unknown = useContext(PanelDataContext);

  return panelData as PanelContext<T>;
};

export default usePanelData;
