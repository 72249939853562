import { MapObjectFull } from '../../../ts/enums/enums';
import { TLinkedDevices } from '../../../ts/models/mapObject.model';

export const getFilteredTLLinkedDevices = (
  linkedDevices: U<TLinkedDevices>,
  id: number
) =>
  (linkedDevices &&
    (
      Object.entries(linkedDevices) as [MapObjectFull, N<number[] | number>][]
    ).reduce((acc: any, [key, value]) => {
      const isNotTlLinks =
        !value ||
        key !== MapObjectFull.greenTrafficLights ||
        !Array.isArray(value);

      const val = isNotTlLinks ? value : value.filter((tlId) => tlId !== id);

      acc[key] = val;

      return acc;
    }, {})) as TLinkedDevices;
