import { FormattedStatistic } from '../../../../../api/adminObjects/systems/adapter/model/adapter.model';
import { UNKNOWN_STATUS } from '../../../../../constants/adminConstants';
import { COLORS } from '../../../../../constants/colorsConstants';
import { findBy } from '../../../../../helpers/findBy';
import {
  isAdapterStatus,
  STATUS_COLORS,
} from '../../../../Admin/AdminSystemObject/ASOMonitoring/helpers/getAdapterStatistic';
import getAdapterStatusDict from '../../../../Admin/AdminSystemObject/ASOMonitoring/helpers/getAdapterStatusDict';
import { IWidgetPieInfo } from '../../../../Panel/StatisticsPanel/MonitoringCard/models/monitoring.model';
import { IPieChartData } from '../../../../ui-kit/CircleChart/models/circleChart.model';

const { NOT_ALLOWED } = COLORS;

interface IStatusPie {
  data: IPieChartData[];
  colors: string[];
}

const getAdapterInfo = (acc: IStatusPie, status: string, color: string) => {
  const adapterInfo = findBy(acc.data, status, 'name');

  if (!adapterInfo) {
    acc.data.push({ name: status, value: 1 });
    acc.colors.push(color);

    return acc;
  }

  adapterInfo.value += 1;

  return acc;
};

const getAdapterData = (
  statistic: FormattedStatistic[],
  adapterIds: number[]
): IWidgetPieInfo => {
  const { data, colors } = adapterIds.reduce(
    (acc: IStatusPie, systemAdapterId) => {
      const adapter = findBy(statistic, systemAdapterId, 'sc_system_id');

      if (!adapter) {
        const statusName =
          getAdapterStatusDict(UNKNOWN_STATUS)?.name ?? UNKNOWN_STATUS;

        return getAdapterInfo(acc, statusName, NOT_ALLOWED);
      }

      const color = isAdapterStatus(adapter.status)
        ? STATUS_COLORS[adapter.status]
        : NOT_ALLOWED;

      const status =
        getAdapterStatusDict(adapter.status)?.name ?? adapter.status;

      return getAdapterInfo(acc, status ?? UNKNOWN_STATUS, color);
    },
    { data: [], colors: [] }
  );

  return {
    total: adapterIds.length,
    colors,
    data,
  };
};

export default getAdapterData;
