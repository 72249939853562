import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../../stores/rootStore/rootStore';
import RadioButtonsGraph from '../../../../TrafficLightDetailed/TlDetectors/IntancyGraph/RadioButtonsGraph/RadioButtonsGraph';

import getBtnInfo from './helpers/getBtnInfo';
import useGraphTypes from './hooks/useGraphTypes';

import styles from './GraphTypesBtns.module.scss';

interface GraphTypesBtnsProps {
  id: number;
}

const GraphTypesBtns: FC<GraphTypesBtnsProps> = ({ id }) => {
  const { graphType } = rootStore.detailedStore;

  const { isDtReady, isWork, onChangeGraphTitle } = useGraphTypes({ id });

  return (
    <RadioButtonsGraph
      radioContainerClassName={styles.radioContainer}
      btnsDscArr={getBtnInfo(isDtReady, isWork)}
      value={graphType}
      onChange={onChangeGraphTitle}
    />
  );
};

export default observer(GraphTypesBtns);
