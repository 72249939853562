/* eslint-disable jsx-a11y/no-static-element-interactions */
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';

import rootStore from '../../../../../../../../stores/rootStore/rootStore';
import Popover from '../../../../../../../ui-kit/Popover/Popover';
import { ShowBtns } from '../../constants/constants';
import { ISettingsTitles } from '../../TimeSettings/TimeSettings';

import styles from './SettingLabel.module.scss';

type ISettingLabelProps = Omit<ISettingsTitles, 'id'>;

const SettingLabel: FC<ISettingLabelProps> = ({ title, displayIcons }) => {
  const { interfaceTipsDelay, setKeyValue } = rootStore.uiStore;
  const [activeId, setActiveId] = useState(0);

  const handleClickIcon = (id: number, type: ShowBtns) => {
    setKeyValue('displayTimeCardDtShow', type);
    setActiveId(id);
  };

  const popoverProps = {
    placement: 'top',
    tipsDelay: interfaceTipsDelay,
  } as const;

  return (
    <div className={styles.titleRow}>
      <h5 className={styles.title}>{title}:</h5>
      {displayIcons ? (
        <div className={styles.icons}>
          {displayIcons.map(({ icon, id, tooltip, type }) => (
            <Popover key={id} {...popoverProps} content={tooltip}>
              <span
                className={classNames({
                  [styles.icon]: true,
                  [styles.active]: activeId === id,
                  [styles.same]: activeId === id,
                })}
                onClick={() => handleClickIcon(id, type)}
              >
                {icon}
              </span>
            </Popover>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default observer(SettingLabel);
