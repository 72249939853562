/* eslint-disable camelcase */
import { VERSION } from '../../../../constants/versions';
import { ILayer } from '../../../../stores/gisDataStore/gisDataStore.model';
import { getAppPathHeaders } from '../../../helpers/getAppPathHeaders';
import http from '../../../http';
import tryCatchWrapper from '../../../tryCatchWrapper';

const GIS_LAYERS = VERSION + '/integration/gis/layers-configuration';

const REQUEST_PARAMS = {
  isExternalError: true,
};

const layersApi = {
  getList: (appPath: string, id: number) =>
    tryCatchWrapper(
      async () => {
        const res = await http.get<ILayer[]>(`${GIS_LAYERS}`, {
          headers: getAppPathHeaders(appPath),
          params: {
            regionId: id,
          },
        });

        return res?.data;
      },
      {
        ...REQUEST_PARAMS,
        errorMessage: 'Ошибка получения доступных слоев',
        ignoreCodes: [423],
      }
    ),
  getDefinition: (appPath: string, url: string) =>
    tryCatchWrapper(
      async () => {
        const res = await http.get(url, {
          headers: getAppPathHeaders(appPath),
        });

        return res?.data;
      },
      {
        ...REQUEST_PARAMS,
        errorMessage: 'GIS_LAYERS_ERROR',
      }
    ),
};

export default layersApi;
