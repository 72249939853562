import { Tag } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { LibraryKeys } from '../../../../../api/libraries/enums/enums';
import { findById } from '../../../../../helpers/findBy';
import { getIsDisabledControl } from '../../../../../stores/mapDataStore/helpers/getIsDisabledControl';
import { TObjMap } from '../../../../../stores/mapDataStore/mapDataStore.model';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { System } from '../../../../../ts/enums/enums';
import { DISABLED_POPOVER_TEXT } from '../../../../Panel/MainPanelTabs/AllSystemsTab/constants/constants';
import { getTLWarnings } from '../../../../Panel/ScriptsControl/SelectModule/DispatchGroupControl/DipatchGroupDetailed/ControlCard/helpers/helpers';

import { getStatusInfo } from './helpers/getStatusInfo';

import styles from './ModeInfo.module.scss';

const { SystemTypeId } = LibraryKeys;

interface IModeInfoProps {
  activeItem: TObjMap;
  system: System;
  isLine?: boolean;
  isExtId?: boolean;
}

const ModeInfo: FC<IModeInfoProps> = ({
  activeItem,
  system,
  isLine = true,
  isExtId = true,
}) => {
  const { disabledTlsIds, isGroupDispatch } = rootStore.scriptsControlStore;
  const systemTypeDict = rootStore.uiStore.dictionaries[SystemTypeId];

  const isDisabled = disabledTlsIds.some((id) => id === activeItem.id);

  const isTL = system === System.Lights;

  const { color, name, optionalTitle } = getStatusInfo(
    activeItem,
    system,
    isDisabled
  );

  const {
    deviceInfo: { externalDeviceId, deviceStatusId, controlAccess },
    eputsAdapter: { scSystemTypeId },
  } = activeItem;

  const { warningText } = getTLWarnings(controlAccess);
  const adapterName = findById(systemTypeDict, scSystemTypeId)?.name || null;

  const isIncorrectControl =
    isGroupDispatch && getIsDisabledControl(deviceStatusId);
  const isWarning = isGroupDispatch && !!warningText;

  const containerStyle = classNames({
    [styles.tooltipTitleTl]: true,
    [styles.line]: isLine,
  });

  return (
    <div className={containerStyle}>
      {isIncorrectControl && (
        <p className={styles.disabledText}>
          {DISABLED_POPOVER_TEXT.IncorrectControlStatus}
        </p>
      )}
      {isWarning && <p className={styles.warningText}>{warningText}</p>}
      {name && color ? (
        <div className={styles.status}>
          <p>Статус: </p>
          <Tag color={color}>{name}</Tag>
        </div>
      ) : null}
      {optionalTitle && <p>Режим СО: {optionalTitle}</p>}
      {isTL && isExtId && adapterName && (
        <p>
          Id в {adapterName}: {externalDeviceId}
        </p>
      )}
    </div>
  );
};

export default observer(ModeInfo);
