import { point as turfPoint } from '@turf/helpers';
import { toWgs84 as turfToWgs84 } from '@turf/projection';
import { Geometry, LineString, Point, Polygon } from 'ol/geom';

export const geomToLatLon = (
  geom: Geometry | undefined
): number[] | undefined => {
  if (!geom) {
    return;
  }

  if (geom instanceof LineString || geom instanceof Polygon) {
    const coordinates = geom.getFlatCoordinates();

    const [x, y] = coordinates;

    const wgsPoint = turfToWgs84(turfPoint([x, y]));

    return wgsPoint.geometry.coordinates;
  }

  if (geom instanceof Point) {
    const coordinates = geom.getCoordinates();

    const [x, y] = coordinates;

    const wgsPoint = turfToWgs84(turfPoint([x, y]));

    return wgsPoint.geometry.coordinates;
  }

  return;
};

export const mercatorToWgs84 = (coords: number[]) => {
  const point = turfToWgs84(turfPoint(coords));

  return point.geometry.coordinates;
};
