import type { Payload } from 'recharts/types/component/DefaultLegendContent';

import { IDataGraph } from '../../PopupDetector.model';

import { getTickValues } from './helpers/getTickValues';

import styles from './Tick.module.scss';

interface TickProps<T extends IDataGraph> {
  x: number;
  y: number;
  payload: Payload;
  data?: T[];
}

const Tick = <T extends IDataGraph>({ x, y, payload, data }: TickProps<T>) => {
  const { date, isLastIndex, value } = getTickValues(data, payload.value);

  const textAnchor = !isLastIndex ? 'start' : 'end';
  const xFormatted = isLastIndex ? -2 : -7;

  return (
    <g transform={`translate(${x},${y})`} className={styles.textLabel}>
      <text x={xFormatted} y={0} dy={5} textAnchor={textAnchor}>
        {value}
      </text>
      {date && (
        <text x={xFormatted} y={0} dy={20} textAnchor={textAnchor}>
          {date}
        </text>
      )}
    </g>
  );
};

export default Tick;
