import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { FC } from 'react';
import Slider from 'react-slick';

import { SLIDER_SETTINGS } from '../../../../ControlCard/PhasesSlider/constants/constants';
import { DisplayTypeProps } from '../constants/devicesDisplayTypes';

import TLCard from './TLCard/TLCard';

import styles from './CardsDisplay.module.scss';

const CardsDisplay: FC<DisplayTypeProps> = ({ addressLoading, dataList }) => {
  return (
    <div className={styles.container}>
      <Slider {...SLIDER_SETTINGS} slidesToShow={3}>
        {dataList.map((el) => (
          <TLCard key={el.id} {...el} addressLoading={addressLoading} />
        ))}
      </Slider>
    </div>
  );
};

export default CardsDisplay;
