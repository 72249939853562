import classNames from 'classnames';
import { isNumber } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC, cloneElement } from 'react';

import { ReactComponent as TLIco } from '../../../../assets/icons/map/ic_tr_light.svg';
import { COLORS } from '../../../../constants/colorsConstants';
import { System } from '../../../../ts/enums/enums';
import { ICON_SIZE } from '../../../Constructor/constants/constants';
import DeviceIcon from '../../DeviceIcon/DeviceIcon';
import DeviceId from '../../DeviceIcon/DeviceId/DeviceId';
import getIconSize from '../../helpers/getIconSize';
import { TLIconProps } from '../../models/device.model';

import styles from './TLIcon.module.scss';

const { PRIMARY, WHITE } = COLORS;

const TLIcon: FC<TLIconProps> = ({
  isVisible,
  offsetX,
  offsetY,
  size = ICON_SIZE.DEFAULT,
  onClick,
  fill = PRIMARY,
  activeId,
  Icon,
  borderColor = WHITE,
  isIcoWithId = false,
  rate,
  id,
}) => {
  if (!isVisible) return null;

  const icoSize = getIconSize(size, rate);

  const wrapperProps = {
    device: { offsetX, offsetY },
    size: icoSize,
    id,
    system: System.Lights,
  };

  const containerStyle = classNames({
    [styles.tlIco]: true,
    [styles.inactive]: isNumber(activeId),
    [styles.activeId]: activeId === id,
  });

  const icoProps = {
    onClick,
    className: styles.tlIco,
    width: icoSize,
    height: icoSize,
  };

  const ico = Icon ? cloneElement(Icon, icoProps) : <TLIco {...icoProps} />;

  if (isIcoWithId)
    return (
      <DeviceIcon
        {...wrapperProps}
        fill={fill}
        stroke={borderColor}
        className={containerStyle}
      >
        <DeviceId id={id} size={icoSize} isIcoWithId />
        {ico}
      </DeviceIcon>
    );

  return (
    <DeviceIcon
      {...wrapperProps}
      fill={fill}
      stroke={borderColor}
      className={containerStyle}
    >
      {ico}
    </DeviceIcon>
  );
};

export default observer(TLIcon);
