import type { Coordinate } from 'ol/coordinate';
import { containsCoordinate } from 'ol/extent';

import { IRegionData } from '../../../api/server/web/web.zod';
import rootStore from '../../../stores/rootStore/rootStore';

//received coordinates must be in the EPSG:4326 coordinate system

export const getIsCoordinatesOnExtent = (
  coord: Coordinate,
  extent?: IRegionData['extent']
) => {
  const { regionData } = rootStore.uiStore;

  if (!regionData || coord.length !== 2) return false;

  const ext = extent ?? regionData.extent;

  return containsCoordinate(ext, coord);
};
