import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import {
  TL_MANAGEMENT_TABS,
  TlManagementKeys,
} from '../../../../../constants/commands';
import rootStore from '../../../../../stores/rootStore/rootStore';
import Popover from '../../../../ui-kit/Popover/Popover';
import { getRemoteContent } from '../helpers/getRemoteContent';

import styles from './TabLabel.module.scss';

interface TabLabelProps {
  controlKey: TlManagementKeys;
}

const TabLabel: FC<TabLabelProps> = ({ controlKey }) => {
  const { interfaceTipsDelay } = rootStore.uiStore;

  const tooltip = getRemoteContent(controlKey);

  const popoverProps = {
    tipsDelay: tooltip ? interfaceTipsDelay : null,
    content: tooltip,
  };

  return (
    <Popover {...popoverProps}>
      <p className={styles.labelTabs}>{TL_MANAGEMENT_TABS[controlKey]}</p>
    </Popover>
  );
};

export default observer(TabLabel);
