import { VERSION } from '../../../constants/versions';
import { getAppPathHeaders } from '../../helpers/getAppPathHeaders';
import http from '../../http';
import tryCatchWrapper from '../../tryCatchWrapper';

const PRODUCERS = VERSION + '/catalogs/producers';

export const producers = {
  fetchProducers: async (appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.get(PRODUCERS, {
        headers: getAppPathHeaders(appPath),
      });

      return res.data;
    }),
};
