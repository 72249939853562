import { Tabs } from 'antd';
import { useCallback, useEffect, useState } from 'react';

import api from '../../../api';
import { TableData } from '../../../ts/models/table.model';
import { DEFAULT_TABLE } from '../../ui-kit/Table/constants';
import Table from '../../ui-kit/Table/Table';

import styles from './Equipments.module.scss';

const Equipments = () => {
  const [standartsData, setStandartData] = useState<TableData>(DEFAULT_TABLE);
  const [extendedData, setExtendedData] = useState<TableData>(DEFAULT_TABLE);
  const [manufacturers, setManufacturers] = useState<TableData>(DEFAULT_TABLE);
  const [isLoadingStandart, setIsLoadingStandart] = useState(true);
  const [isLoadingExtended, setIsLoadingExtended] = useState(true);
  const [isLoadingManufacturers, setIsLoadingManufacturers] = useState(true);

  const getStandartData = useCallback(async () => {
    setStandartData(DEFAULT_TABLE);
    setIsLoadingStandart(true);
    const data = await api.catalogs.tradeMarks.fetchStandartData(
      'admin.tradeMarks'
    );

    setIsLoadingStandart(false);

    if (!data) return;

    setStandartData(data);
  }, []);

  const getExtendedData = useCallback(async () => {
    setExtendedData(DEFAULT_TABLE);
    setIsLoadingExtended(true);
    const data = await api.catalogs.tradeMarks.fetchExtendedData(
      'admin.tradeMarks'
    );

    setIsLoadingExtended(false);

    if (!data) return;

    setExtendedData(data);
  }, []);

  const getManufacturersData = useCallback(async () => {
    setIsLoadingManufacturers(true);
    setManufacturers(DEFAULT_TABLE);
    const data = await api.catalogs.producers.fetchProducers(
      'admin.manufacturers'
    );

    setIsLoadingManufacturers(false);

    if (!data) return;

    setManufacturers(data);
  }, []);

  useEffect(() => {
    getStandartData();
    getExtendedData();
    getManufacturersData();
  }, [getExtendedData, getManufacturersData, getStandartData]);

  const tabsItems = [
    {
      label: 'Оборудование',
      key: 'item-1',
      children: (
        <Table
          tableData={standartsData}
          tableConfigType="fullScreen"
          isLoading={isLoadingStandart}
          refreshData={getStandartData}
          isOrderNumber
        />
      ),
    },
    {
      label: 'Производители',
      key: 'item-2',
      children: (
        <Table
          tableData={manufacturers}
          tableConfigType="fullScreen"
          isLoading={isLoadingManufacturers}
          refreshData={getManufacturersData}
          isOrderNumber
        />
      ),
    },
    {
      label: 'Оборудование + Производитель',
      key: 'item-3',
      children: (
        <Table
          tableData={extendedData}
          tableConfigType="fullScreen"
          isLoading={isLoadingExtended}
          refreshData={getExtendedData}
          isOrderNumber
        />
      ),
    },
  ];

  return (
    <div className={styles.tabs}>
      <Tabs items={tabsItems} type="card" />
    </div>
  );
};

export default Equipments;
