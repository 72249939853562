import { BarColors } from '../../../../../constants/colorsConstants';

export interface IColors {
  normal: BarColors;
  average: BarColors;
  dangerous: BarColors;
}

export const getDetectorsBarsStatus = (
  comparableValue: U<number>,
  averageCalcData: number,
  colors: IColors
) => {
  if (!comparableValue) return colors.normal;

  const percent = (comparableValue / averageCalcData) * 100 - 100;

  if (percent >= 20) return colors.dangerous;
  if (percent <= -20) return colors.normal;

  return colors.average;
};
