import { IWeatherLayerItem } from '../../../Map/IWeatherTimeModel';

export const getGradient = (item: IWeatherLayerItem) => {
  const { gradient } = item;

  const colorStops = gradient?.colorStops;

  if (colorStops) {
    const gradientItems = colorStops
      .map((colorStop) => {
        const { color } = colorStop;

        return color;
      })
      .join(', ');

    return `linear-gradient(90deg, ${gradientItems})`;
  }

  return 'linear-gradient(90deg, #F9F99A 4%, #20D1F8 23%, #108AD0 58.2%, #0043A8 94.4%);';
};
