import { FC } from 'react';

import { ErrorText } from '../../../../ts/enums/enums';

import styles from './EmptyImg.module.scss';

interface Empty {
  title?: ErrorText;
}

const EmptyImg: FC<Empty> = ({ title = ErrorText.SomethingWentWrong }) => {
  return (
    <div className={styles.imgError}>
      <img alt="traffic_light_crossroad_error" src="assets/not_available.gif" />
      <h2> {title} </h2>
    </div>
  );
};

export default EmptyImg;
