import { observer } from 'mobx-react-lite';

import checkIsTLValid from '../../helpers/checkIsTLValid';
import UnexpectedError from '../ui-kit/Errors/UnexpectedError/UnexpectedError';

import useLinkedTls from './hooks/useLinkedTls';
import TLTimeSec from './TLTimeSec/TLTimeSec';

const TLTimeSecWrapper = () => {
  const linkedTls = useLinkedTls();

  return (
    <UnexpectedError>
      {linkedTls.map((tl) => {
        if (!checkIsTLValid(tl)) return null;
        const { id } = tl;

        return <TLTimeSec key={id} id={id} />;
      })}
    </UnexpectedError>
  );
};

export default observer(TLTimeSecWrapper);
