import { Overlay } from 'ol';
import { fromLonLat } from 'ol/proj';
import { RefObject } from 'react';

import { checkIsInExtent } from '../../../../helpers/checkIsInExtent';
import rootStore from '../../../../stores/rootStore/rootStore';
import { PointUds } from '../../../../ts/models/pointUds.model';
import { DeviceInExtent } from '../PointUdsOverlays.model';

export const getDevicesInExtent = <T extends DeviceInExtent>(devices: T[]) => {
  const { map } = rootStore.mapStore;

  if (!map) return [];

  const extent = map.getView().calculateExtent();

  return devices.filter(({ longitude, latitude }) => {
    const [long, lat] = fromLonLat([longitude, latitude]);

    return checkIsInExtent({ longitude: long, latitude: lat, extent });
  });
};

export const setPopupsRefsData = <T extends DeviceInExtent>(
  devices: T[],
  popups: Overlay[],
  refs: RefObject<HTMLDivElement>[]
) => {
  devices.forEach(({ longitude, latitude, id }, i) => {
    const ref = refs[i];

    if (!ref?.current || !popups[i]) return;

    ref.current.id = `${id}`;
    popups[i].set('position', fromLonLat([longitude, latitude]), true);
  });
};

export const formatPoints = (points: PointUds[]) =>
  points.map((point) => {
    const { basicProfileInfo, longitude, latitude } = point;

    if (longitude && latitude) return point;
    const [lon, lat] = basicProfileInfo.centerPoint;

    point.longitude = lon;
    point.latitude = lat;

    return point;
  });
