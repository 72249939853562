import Feature from 'ol/Feature';
// eslint-disable-next-line no-duplicate-imports
import type { FeatureLike } from 'ol/Feature';
import { Point } from 'ol/geom';
import { Style, Stroke } from 'ol/style';

import { TDeviceStatus } from '../../../../../constants/devicesConstants';
import { MultipleClusters } from '../../../../../stores/clustersStore/clustersStore.model';
import rootStore from '../../../../../stores/rootStore/rootStore';
import {
  FeatureDeviceProps,
  FeaturesTypes,
  System,
} from '../../../../../ts/enums/enums';
import { TlMode } from '../../../../../ts/models/tl.model';
import { getStyle } from '../../../../Mapper/helpers';

import { FeaturesMode } from './colors';
import { getClusterStyle } from './getClusterStyle';
import { getEmptyCluster } from './getEmptyCluster';
import { getStyleClickedCluster } from './getStyleClickedCluster';

export interface IClusterCache {
  style: Style[];
  systems: System[];
}

export interface IFeatureCache {
  style: Style[];
  mode: TlMode;
  deviceStatus: N<TDeviceStatus>;
  system: System;
  isDisabled: boolean;
}

interface OpenClusterProps {
  features: FeatureLike;
  infoDataId?: number;
  scale?: number;
  styleCache?: StyleCache;
}

export type StyleCache = Record<string, Style[] | Style>;

export function getIconZoomRatio(mapIconsSize: number) {
  return mapIconsSize * 0.8;
}

interface FeatureStyleProps {
  features: Feature<Point>[];
  infoDataId: U<number>;
  scale: U<number>;
  styleCache?: StyleCache;
  isColorActive?: boolean;
}

export function getFeatureStyle({
  features,
  infoDataId,
  scale,
  styleCache,
  isColorActive = false,
}: FeatureStyleProps) {
  const feature = features?.at(0);

  const id: U<number> = feature?.get(FeatureDeviceProps.Id);
  const isSelectFeature = infoDataId === id;

  const isEmptyStyle = !isColorActive && isSelectFeature;

  if (!feature || isEmptyStyle) return getEmptyCluster();

  const system: System = feature.get(FeatureDeviceProps.System);
  const mode: TlMode = feature.get(FeatureDeviceProps.Mode);
  const deviceStatus: N<TDeviceStatus> = feature.get(
    FeatureDeviceProps.DeviceStatus
  );
  const isDisabled = feature.get(FeatureDeviceProps.IsDisabled);

  const key = `${system}_${mode}_${deviceStatus}_${isDisabled}`;

  if (styleCache && styleCache[key]) {
    return styleCache[key];
  }

  const style = getStyle(system, mode, deviceStatus, isDisabled, scale);

  if (styleCache) {
    styleCache[key] = style;
  }

  return style;
}

const CLUSTER_LINE_STYLE = new Style({
  stroke: new Stroke({
    color: '#ffffff',
    width: 2,
  }),
});

export function getOpenClusterStyle({
  features,
  infoDataId,
  scale,
  styleCache,
}: OpenClusterProps) {
  const cluster: U<Feature<Point>[]> = features.get(FeaturesTypes.Features);
  const isClusterLine = !!features.get(FeaturesTypes.ClusterLine);
  const isFeature = cluster?.length === 1;

  if (isClusterLine) return CLUSTER_LINE_STYLE;

  if (isFeature)
    return getFeatureStyle({
      features: cluster,
      infoDataId,
      scale,
      styleCache,
    });

  return getEmptyCluster();
}

interface StyleFuncProps {
  feature: FeatureLike;
  activeId: U<number>;
  styleCache: StyleCache;
  clickedCluster?: N<MultipleClusters>;
  featureMode?: FeaturesMode;
  scale: number;
  isColorActive?: boolean;
}

export const getStyleForCluster = ({
  feature,
  activeId,
  styleCache,
  clickedCluster,
  featureMode = FeaturesMode.Status,
  scale,
  isColorActive = false,
}: StyleFuncProps) => {
  const { isClusterOpen } = rootStore.clustersStore;
  const features: Feature<Point>[] = feature.get('features');

  if (!features.length) return getEmptyCluster();

  const isCluster = features.length > 1;

  if (!isCluster)
    return getFeatureStyle({
      features,
      infoDataId: activeId,
      scale,
      styleCache,
      isColorActive,
    });

  if (clickedCluster && isCluster && isClusterOpen) {
    const styleInfoData = getStyleClickedCluster({
      feature,
      clickedCluster,
      scale,
      styleCache,
    });

    if (styleInfoData) return styleInfoData;
  }

  const styleCluster = getClusterStyle(features, featureMode, {
    scale,
    styleCache,
  });

  return styleCluster;
};
