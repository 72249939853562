import { Button } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { findById } from '../../../../helpers/findBy';
import rootStore from '../../../../stores/rootStore/rootStore';
import { MapActions } from '../../../../stores/uiStore/uiStore.model';
import { System } from '../../../../ts/enums/enums';
import { TLDetailedCode } from '../../../../ts/enums/userData';
import Popover from '../../../ui-kit/Popover/Popover';
import { PhaseCircleLayoutProps } from '../PhaseCircleOverlay.model';

import styles from './PhaseCircleLayout.module.scss';

const PhaseCircleLayout: FC<PhaseCircleLayoutProps> = ({
  children,
  id,
  isTLValid = true,
}) => {
  const { setInfoData, infoData } = rootStore.uiStore;
  const { isPhaseCircleLabel } = rootStore.pointsUdsStore;
  const { hasAccess } = rootStore.userDataStore;
  const { tls } = rootStore.mapDataStore;

  const isActive = id === infoData?.id;

  const footerStyles = classNames({
    [styles.trafficLightId]: true,
    [styles.trafficLightIdOffset]: isPhaseCircleLabel,
    [styles.isActive]: isActive,
  });

  const isDetailed = hasAccess(TLDetailedCode.isDetailed);
  const isValid = id && isDetailed && isTLValid;

  const openDetails = () => {
    if (isActive) return setInfoData(null);
    const tl = findById(tls, id);

    if (!tl) return;
    const { id: tlId, longitude, latitude } = tl;

    setInfoData(
      {
        system: System.Lights,
        coordinate: [longitude, latitude],
        id: tlId,
      },
      MapActions.NoActions
    );
  };

  return (
    <div className={styles.phaseCircleContainer}>
      {children}

      <span className={footerStyles}>
        <Popover placement="top" content="Выбрать светофорный объект">
          <Button
            type={isActive ? 'primary' : 'default'}
            onClick={openDetails}
            className={isValid ? styles.detailedBtn : styles.disabledBtn}
            disabled={!isValid}
          >
            {id ? `№ ${id}` : 'нет данных'}
          </Button>
        </Popover>
      </span>
    </div>
  );
};

export default observer(PhaseCircleLayout);
