import { SelectedPlan, SelectedTime } from '../models/plansControl.model';

export const INITIAL_SELECTED_TIME: SelectedTime = {
  date: null,
  duration: null,
  status: undefined,
};

export const INITIAL_SELECTED_PLAN: SelectedPlan = {
  label: null,
  planId: null,
};
