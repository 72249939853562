const DIR_WIND_INFO = [
  { name: 'С', minDeg: 0, maxDeg: 22.5 },
  { name: 'СВ', minDeg: 22.5, maxDeg: 67.5 },
  { name: 'В', minDeg: 67.5, maxDeg: 112.5 },
  { name: 'ЮВ', minDeg: 112.5, maxDeg: 157.5 },
  { name: 'Ю', minDeg: 157.5, maxDeg: 202.5 },
  { name: 'ЮЗ', minDeg: 202.5, maxDeg: 247.5 },
  { name: 'З', minDeg: 247.5, maxDeg: 292.5 },
  { name: 'СЗ', minDeg: 292.5, maxDeg: 337.5 },
  { name: 'С', minDeg: 337.5, maxDeg: 360 },
];

export const getDirWind = (dir: N<number>) => {
  if (dir === null) return '';

  const nameDir =
    DIR_WIND_INFO.find(({ minDeg, maxDeg }) => minDeg <= dir && dir <= maxDeg)
      ?.name ?? '';

  return nameDir;
};
