import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { COMMANDS, TlManagementKeys } from '../../../../../constants/commands';
import eventBus from '../../../../../eventBus';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { TL } from '../../../../../ts/models/tl.model';
import { postCommand } from '../../helpers/postCommand';
import { IPostCommandProps } from '../../model/postCommands.model';
import { INITIAL_SELECTED_TIME } from '../../PlanSelect/constants/constants';
import { SelectedTime } from '../../PlanSelect/models/plansControl.model';
import { getCorrectDateText } from '../helpers/getCorrectDateText';
import { secondsToTime } from '../helpers/secondsToTime';
import { ModalConfirmProps } from '../ModalConfirm/ModalConfirm';

const { PhaseHold } = TlManagementKeys;
const { SET_OBJECT_PHASE_NOW, SET_OBJECT_PHASE } = COMMANDS;

const PATH = '.TrafficLightDetailed.TlManagement.PhaseHold.UsePhaseHoldInfo';

interface UseModalPhaseHoldProps {
  activeTL: TL;
  isChecked: boolean;
  selectedTimeInfo: SelectedTime;
  setSelectedTimeInfo: SetState<SelectedTime>;
  setIsChecked: SetState<boolean>;
}

const useModalPhaseHold = ({
  activeTL,
  isChecked,
  selectedTimeInfo,
  setSelectedTimeInfo,
  setIsChecked,
}: UseModalPhaseHoldProps) => {
  const {
    activeControlKey,
    selectedPhase,
    setIsNotDetailed,
    setSelectedPhase,
  } = rootStore.detailedStore;

  const { duration, date } = selectedTimeInfo;

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isLoadingRequest, setIsLoadingRequest] = useState<boolean>(false);

  const imgRef = useRef<string>('');

  const { images, sgnProgramName } = useMemo(() => {
    const images = activeTL.dai_jdt83?.images?.filter((el) => el.phase) ?? [];
    const sgnProgramName = activeTL.tlStatus.command?.full ?? 'План не найден';

    return {
      images,
      sgnProgramName,
    };
  }, [activeTL]);

  const phaseContentModal = useMemo(
    () => [
      `Фаза № ${selectedPhase}`,
      `Сигнальная программа: ${sgnProgramName}`,
      date && duration
        ? `Период: ${getCorrectDateText(date.unix(), duration)}`
        : '',
      duration ? `Длительность: ${secondsToTime(duration)}` : '',
    ],
    [duration, date, selectedPhase, sgnProgramName]
  );

  const clearPhaseHold = useCallback(() => {
    setOpenModal(false);
    setSelectedPhase(null);
    setIsChecked(false);
    setSelectedTimeInfo(INITIAL_SELECTED_TIME);
  }, [setIsChecked, setSelectedPhase, setSelectedTimeInfo]);

  const onOkConfirm = useCallback(async () => {
    setIsLoadingRequest(true);

    const textHoldPhases = `Фаза ${selectedPhase} (на удержании${
      !duration || !date ? '' : ` ${getCorrectDateText(date.unix(), duration)}`
    })`;

    const propsPostCommand: IPostCommandProps = {
      id: activeTL.id,
      command: selectedPhase,
      cmdTitle: !duration ? SET_OBJECT_PHASE_NOW : SET_OBJECT_PHASE,
      cmdInfo: textHoldPhases,
      selectedDate: date,
      durationTime: duration ?? undefined,
    };

    await postCommand(propsPostCommand, PATH);

    setIsLoadingRequest(false);
    clearPhaseHold();
  }, [activeTL.id, clearPhaseHold, duration, date, selectedPhase]);

  const {
    caption,
    deviceInfo: { externalDeviceId },
  } = activeTL;

  const modalConfirmProps: Omit<ModalConfirmProps, 'icon'> = {
    modalTitle: `Отправка команды управления для светофорного объекта ${externalDeviceId} ${caption}`,
    openModal,
    setOpenModal,
    onOkConfirm,
    width: '900px',
    okButtonProps: { loading: isLoadingRequest },
  };

  useEffect(() => {
    if (activeControlKey !== PhaseHold) return;

    const isCheck = date && duration;

    const isReady = selectedPhase && (!isChecked || isCheck);

    setIsNotDetailed('isDisabledControlCmd', !isReady);
  }, [
    selectedPhase,
    isChecked,
    date,
    duration,
    activeControlKey,
    setIsNotDetailed,
  ]);

  useEffect(() => {
    const unsubscribe = eventBus.clickedControlBtn.subscribe(() => {
      activeControlKey === TlManagementKeys.PhaseHold && setOpenModal(true);
    });

    return () => unsubscribe();
  }, [activeControlKey]);

  useEffect(() => {
    const getPhaseImage = () => {
      if (activeControlKey !== PhaseHold || !selectedPhase) return;

      const image = images.at(selectedPhase - 1)?.image;

      image && (imgRef.current = '/media' + image);
    };

    getPhaseImage();
  }, [images, selectedPhase, activeControlKey]);

  return {
    phaseContentModal,
    imgRef,
    modalConfirmProps,
  };
};

export default useModalPhaseHold;
