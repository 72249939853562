import { Switch } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../stores/rootStore/rootStore';
import Popover from '../../../ui-kit/Popover/Popover';

import styles from './Toggle.module.scss';

interface ToggleProps {
  label: string;
  onClick: (val: boolean) => void;
  checked: U<boolean>;
  isVisible?: boolean;
  disabled?: boolean;
  tooltip?: string;
}

const Toggle: FC<ToggleProps> = ({
  label,
  checked,
  onClick,
  isVisible = true,
  disabled = false,
  tooltip = '',
}) => {
  if (!isVisible) return null;

  const { interfaceTipsDelay } = rootStore.uiStore;

  return (
    <Popover placement="right" content={tooltip} tipsDelay={interfaceTipsDelay}>
      <div className={styles.row}>
        <span>{label}</span>
        <Switch
          size="small"
          onClick={onClick}
          checked={checked}
          disabled={disabled}
        />
      </div>
    </Popover>
  );
};

export default observer(Toggle);
