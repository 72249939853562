/* eslint-disable camelcase */
import { notification } from 'antd';

import NOTICE from '../../constants/notificationConstants';
import { VERSION } from '../../constants/versions';
import showNotify, { MessageResponse } from '../../helpers/showNotify';
import { Path } from '../api.model';
import { getAppPathHeaders } from '../helpers/getAppPathHeaders';
import http from '../http';
import tryCatchWrapper from '../tryCatchWrapper';

import { PointUdsSchema } from './pointUds.zod';

export const POINT_UDS = VERSION + '/pointuds/';

const { INCORRECT_POINT_UDS_LIST } = NOTICE;

interface UpdateByUidArgs {
  uid: string;
  appPath: Path;
  name: string;
  dsc?: string;
}

const pointUdsApi = {
  fetchPointUdsList: (appPath: Path) =>
    tryCatchWrapper(async () => {
      const res = await http(POINT_UDS, {
        headers: getAppPathHeaders(appPath),
      });

      const validate = PointUdsSchema.array().safeParse(res?.data);

      if (!validate.success) {
        notification.error(INCORRECT_POINT_UDS_LIST);

        return console.error(validate.error);
      }

      return validate?.data?.map(
        ({ point_uds_dsc, point_uds_name, point_uds_uid }) => ({
          pointUdsDsc: point_uds_dsc,
          pointUdsName: point_uds_name,
          pointUdsUid: point_uds_uid,
        })
      );
    }),

  deleteByUid: (uid: string, appPath: Path, deleteAllVersions = false) =>
    tryCatchWrapper(async () => {
      const res = await http.delete(POINT_UDS + uid, {
        headers: getAppPathHeaders(appPath),
        data: {
          deleteAllVersions,
        },
      });

      showNotify(res, NOTICE.DELETED_SUCCESSFULLY);

      return res?.data;
    }),

  updateByUid: ({
    uid,
    appPath,
    name,
    dsc = 'без описания',
  }: UpdateByUidArgs) =>
    tryCatchWrapper(async () => {
      const res: MessageResponse = await http.put(
        POINT_UDS + uid,
        { name, dsc },
        { headers: getAppPathHeaders(appPath) }
      );

      showNotify(res);

      return res.data;
    }),
};

export default pointUdsApi;
