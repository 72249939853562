import classNames from 'classnames';
import { isNumber } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import { IBasicProps } from '../model/signalProgramsBand.model';

import styles from './PhaseRow.module.scss';

export interface PhaseRowProps extends IBasicProps {
  isStatic: boolean;
  sec?: number;
}

const PhaseRow: FC<PhaseRowProps> = ({
  len,
  phasesSet,
  width,
  isStatic,
  sec,
}) => {
  const { secTL } = rootStore.uiStore;

  const seconds = sec ?? secTL;

  const curPhase = phasesSet.find(
    ({ stop, start }) => seconds && seconds <= stop && seconds >= start
  );

  const containerStyle = classNames({
    [styles.timePointer]: true,
    [styles.timePointerMiddle]: Boolean(curPhase) && !curPhase?.main,
  });

  const step = width / Number(len);

  if (!isNumber(seconds)) return null;

  return (
    <div className={styles.phaseRow}>
      {!isStatic && (
        <div
          className={containerStyle}
          style={{
            width: seconds * step,
          }}
        />
      )}

      {phasesSet.map((item, i) => (
        <div
          key={i}
          className={styles.block}
          style={{
            width: `${(width / len) * item.length}px`,
            borderRightWidth: i === phasesSet.length - 1 ? '0px' : '1px',
            borderRightStyle: item.main ? 'dashed' : 'solid',
          }}
        >
          {item.main ? (
            <div className={styles.titleBlock} />
          ) : (
            <p className={styles.phaseBlockTitleMiddle}></p>
          )}
        </div>
      ))}
    </div>
  );
};

export default observer(PhaseRow);
