import { observer } from 'mobx-react-lite';

import rootStore from '../../../../stores/rootStore/rootStore';

import PointUdsProfiles from './PointUdsProfiles/PointUdsProfiles';
import PointUdsRenaming from './PointUdsRenaming/PointUdsRenaming';

const PointUdsDetailed = () => {
  const { pointUdsUid } = rootStore.constructorStore;

  if (!pointUdsUid) {
    return <h3>Выберите точку УДС в левой панели</h3>;
  }

  return (
    <>
      <PointUdsRenaming />
      <PointUdsProfiles />
    </>
  );
};

export default observer(PointUdsDetailed);
