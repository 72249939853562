import { useEffect } from 'react';

import {
  handleVisibleLayersState,
  handleVisibleMainLayer,
} from '../helpers/handlers';
import { UseVisibleLayersStateProps } from '../Map.model';

const useVisibleLayersState = ({
  map,
  visibleLayers,
  markers,
}: UseVisibleLayersStateProps) => {
  useEffect(
    () => handleVisibleLayersState(map, visibleLayers),
    [map, visibleLayers]
  );

  useEffect(() => handleVisibleMainLayer(map, markers), [map, markers]);

  return null;
};

export default useVisibleLayersState;
