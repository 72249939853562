import AdminPanelStore from '../adminPanelStore/adminPanelStore';
import ChannelsDataStore from '../channelsDataStore/channelsDataStore';
import ClustersStore from '../clustersStore/clustersStore';
import ConstructorStore from '../constructorStore/constructorStore';
import DetailedStore from '../detailedStore/detailedStore';
import DocumentsStore from '../documentsStore/documentsStore';
import GisDataStore from '../gisDataStore/gisDataStore';
import MapDataStore from '../mapDataStore/mapDataStore';
import MapStore from '../mapStore/mapStore';
import MonitoringPanelStore from '../monitoringPanelStore/monitoringPanelStore';
import PointsUdsStore from '../pointsUdsStore/pointsUdsStore';
import ReportsPanelStore from '../reportsPanelStore/reportsPanelStore';
import ScriptsControlStore from '../scriptsControlStore/scriptsControlStore';
import TestingPanelStore from '../testingPanelStore/testingPanelStore';
import UIStore from '../uiStore/uiStore';
import UserDataStore from '../userDataStore/userDataStore';
import VideoWallPanelStore from '../videoWallPanelStore/videoWallPanelStore';

class RootStore {
  gisDataStore;
  mapDataStore;
  userDataStore;
  uiStore;
  constructorStore;
  adminPanelStore;
  channelsDataStore;
  videoWallPanelStore;
  testingPanelStore;
  documentsStore;
  detailedStore;
  scriptsControlStore;
  reportsPanelStore;
  clustersStore;
  monitoringPanelStore;
  pointsUdsStore;
  mapStore;

  constructor() {
    this.gisDataStore = new GisDataStore(this);
    this.mapDataStore = new MapDataStore(this);
    this.userDataStore = new UserDataStore(this);
    this.uiStore = new UIStore(this);
    this.constructorStore = new ConstructorStore(this);
    this.adminPanelStore = new AdminPanelStore(this);
    this.channelsDataStore = new ChannelsDataStore(this);
    this.videoWallPanelStore = new VideoWallPanelStore(this);
    this.testingPanelStore = new TestingPanelStore(this);
    this.documentsStore = new DocumentsStore(this);
    this.detailedStore = new DetailedStore(this);
    this.scriptsControlStore = new ScriptsControlStore(this);
    this.reportsPanelStore = new ReportsPanelStore(this);
    this.clustersStore = new ClustersStore(this);
    this.monitoringPanelStore = new MonitoringPanelStore(this);
    this.pointsUdsStore = new PointsUdsStore(this);
    this.mapStore = new MapStore(this);
  }
}

export default new RootStore();
