import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../../stores/rootStore/rootStore';
import ButtonIco from '../../../../ui-kit/ButtonIco/ButtonIco';
import ButtonsBlock from '../../../../ui-kit/ButtonsBlock/ButtonsBlock';
import { ThreadType } from '../constants/constants';

import styles from './ButtonsModelBlock.module.scss';

interface ButtonsModelBlockProps {
  threadType: ThreadType;
  setThreadType: SetState<ThreadType>;
}

const ButtonsModelBlock: FC<ButtonsModelBlockProps> = ({
  threadType,
  setThreadType,
}) => {
  const { interfaceTipsDelay } = rootStore.uiStore;

  const popoverProps = {
    tipsDelay: interfaceTipsDelay,
  };

  return (
    <ButtonsBlock isHorizontal borderRadius={6} className={styles.block}>
      <ButtonIco
        isActive={threadType === ThreadType.D3}
        onClick={() => setThreadType(ThreadType.D3)}
        isSmall
        popoverProps={{ ...popoverProps, content: 'Поток 3Д модели' }}
      >
        <p className={styles.btnText}>3D</p>
      </ButtonIco>
      <ButtonIco
        isActive={threadType === ThreadType.D2}
        onClick={() => setThreadType(ThreadType.D2)}
        isSmall
        popoverProps={{ ...popoverProps, content: 'Поток 2Д модели' }}
      >
        <p className={styles.btnText}>2D</p>
      </ButtonIco>
    </ButtonsBlock>
  );
};

export default observer(ButtonsModelBlock);
