import { Spin } from 'antd';
import React, { FC, useCallback, useEffect, useState } from 'react';

import xeomaApi from '../../../api/xeoma/xeoma';
import Img from '../../ui-kit/Img/Img';

import styles from './Player.module.scss';

interface IPlayer {
  transmitter: string;
}

const XEOMA = '192.168.102.240:10090';

const Player: FC<IPlayer> = ({ transmitter }) => {
  const [src, setSrc] = useState('');
  const getSettings = useCallback(async () => {
    const settings: any = await xeomaApi.getSettings(transmitter);

    settings.forEach((item: any) => {
      if (item.id === 'FullMjpegURLEditBRO') {
        const newSrc: string[] = item.value.split('/');

        newSrc[2] = XEOMA;

        setSrc(newSrc.join('/'));
      }
    });
  }, [transmitter]);

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  if (!src) return <Spin />;

  return (
    <Img
      src={src}
      alt="Xeoma mjpeg"
      className={styles.video}
      isNotCustom={true}
    />
  );
};

export default Player;
