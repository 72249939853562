import { observer } from 'mobx-react-lite';

import rootStore from '../../../../stores/rootStore/rootStore';
import SystemsBtns from '../../SystemsBtns/SystemsBtns';
import PointUdsBtns from '../PointUdsBtns/PointUdsBtns';

const DevicesBtns = () => {
  const { isMultipleSelect } = rootStore.scriptsControlStore;

  if (isMultipleSelect) return null;

  return (
    <>
      <PointUdsBtns />
      <SystemsBtns />
    </>
  );
};

export default observer(DevicesBtns);
