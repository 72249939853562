import { observer } from 'mobx-react-lite';
import React, { useState, useEffect, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { findById } from '../../../helpers/findBy';
import rootStore from '../../../stores/rootStore/rootStore';
import { TL } from '../../../ts/models/tl.model';
import { DetailedItemProps } from '../../DetailedMain/DetailedWrapper/constants/constants';
import DefaultChecked from '../../ui-kit/DefaultChecked/DefaultChecked';

import Fallback from './Fallback/Fallback';
import useLogMessages from './hooks/useLogMessages';
import LogsPanel from './LogsPanel/LogsPanel';
import ManageCard from './ManageCard/ManageCard';
import StatusCard from './StatusCard/StatusCard';
import UncontrolledStatusCard from './UncontrolledStatusCard/UncontrolledStatusCard';

import styles from './TlManagement.module.scss';

const TlManagement: React.FC<DetailedItemProps> = ({ id }) => {
  const { tls } = rootStore.mapDataStore;
  const { clearControlTab } = rootStore.detailedStore;

  const [isOpenTutorialTooltip, setIsOpenTutorialTooltip] =
    useState<U<boolean>>();

  const { clearLogs, logMessages } = useLogMessages(id);

  const activeTL = useMemo(() => findById(tls, id), [id, tls]);

  useEffect(() => {
    clearControlTab();

    return () => clearControlTab();
  }, [id, clearControlTab]);

  return (
    <ErrorBoundary FallbackComponent={Fallback}>
      <div className={styles.rowContainer}>
        <DefaultChecked isEmptyData={!activeTL}>
          <UncontrolledStatusCard />
          <div className={styles.column}>
            <StatusCard
              activeTL={activeTL as TL}
              isOpenTutorialTooltip={isOpenTutorialTooltip}
              setIsOpenTutorialTooltip={setIsOpenTutorialTooltip}
            />
            <ManageCard
              activeTL={activeTL as TL}
              setIsOpenTutorialTooltip={setIsOpenTutorialTooltip}
            />
          </div>

          <LogsPanel
            title={'Сообщения СО:'}
            className={styles.logsCard}
            messages={logMessages}
            clearLogs={clearLogs}
          />
        </DefaultChecked>
      </div>
    </ErrorBoundary>
  );
};

export default observer(TlManagement);
