import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import NoData from '../../../ui-kit/Errors/NoData/NoData';
import { BasicControlTabProps } from '../model/tlManagement.model';

import usePhaseHold from './hooks/usePhaseHold';
import ModalPhase from './ModalPhase/ModalPhase';
import SliderPhase from './SliderPhase/SliderPhase';
import TimePhaseCard from './TimePhaseCard/TimePhaseCard';

import styles from './PhaseHold.module.scss';

const PhaseHold: FC<BasicControlTabProps> = ({ id }) => {
  const { sgnProgramName, count, activeTL, modalPhaseProps, timeCardProps } =
    usePhaseHold(id);

  if (!count || !activeTL) {
    return (
      <NoData textError="Нет данных о фазах СО" isNeedBackground={false} />
    );
  }

  return (
    <div className={styles.container}>
      <h3>
        Удержание фазы: {sgnProgramName} (Количество фаз - {count})
      </h3>
      <SliderPhase activeTL={activeTL} />
      <TimePhaseCard {...timeCardProps} />
      <ModalPhase {...modalPhaseProps} activeTL={activeTL} />
    </div>
  );
};

export default observer(PhaseHold);
