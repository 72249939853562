import { DataType } from '../../../../ts/models/table.model';

export enum ActionType {
  Edit = 'edit',
  Add = 'add',
}

export interface IOrganizationRowValues extends DataType {
  id: number;
  name: string;
  dsc: string;
  regions_id: number[];
}

export interface IDrawerBlock {
  isOpen: boolean;
  onClose: () => void;
  isOrg: boolean;
  divisionsId: N<number>;
  setIsUpdate: SetState<boolean>;
  type: ActionType;
  collaboration: any;
}
