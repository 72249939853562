import { Feature, MapBrowserEvent } from 'ol';
import BaseEvent from 'ol/events/Event';
import type { FeatureLike } from 'ol/Feature';
import { Point } from 'ol/geom';
import BaseLayer from 'ol/layer/Base';
import AnimatedCluster from 'ol-ext/layer/AnimatedCluster';
import { useEffect } from 'react';

import { CURSOR_MAP } from '../../../constants/mapConstants';
import checkIsTLValid from '../../../helpers/checkIsTLValid';
import { findById } from '../../../helpers/findBy';
import rootStore from '../../../stores/rootStore/rootStore';
import { System } from '../../../ts/enums/enums';
import { handleClickFeature } from '../helpers/handleClickFeature';
import { addOverlays, handleTooltip } from '../helpers/handlers';
import { openDetailedForm } from '../helpers/openDetailedForm';

import useLayerTooltip from './useLayerTooltip';
import useTooltip from './useTooltip';

const { GRAB, GRABBING } = CURSOR_MAP;

const useMapEventHandlers = () => {
  const { systemsArray, isCrossroadBorder, setMapData, setKeysValues } =
    rootStore.mapDataStore;

  const {
    map,
    tooltip,
    tooltipRef,
    layerTooltip,
    layerTooltipRef,
    refs,
    overlays,
  } = rootStore.mapStore;

  const {
    setIsNot,
    setInfoData,
    setKeyValue,
    gisTooltipsDelay,
    infoData,
    initialMapCenter,
    isRightPanel,
  } = rootStore.uiStore;

  const {
    setSelectedClusters,
    setIsClusterClicked,
    setClusters,
    featuresOnCluster,
    isClusterOpen,
  } = rootStore.clustersStore;

  const { isMultipleSelect } = rootStore.scriptsControlStore;

  const { isConstructor } = rootStore.constructorStore;
  const { measureAction } = rootStore.gisDataStore;

  const { closeTooltip, getTooltip } = useTooltip({
    tooltipRef,
    tooltip,
  });

  const { closeLayerTooltip, getLayerTooltip } = useLayerTooltip({
    tooltipRef: layerTooltipRef,
    tooltip: layerTooltip,
  });

  useEffect(() => {
    if (!map) return;

    const setTooltip = handleTooltip(
      closeTooltip,
      getTooltip,
      getLayerTooltip,
      closeLayerTooltip,
      gisTooltipsDelay
    );

    function listener(e: MapBrowserEvent<PointerEvent>) {
      if (!map) return;

      setTooltip(e, map, featuresOnCluster, infoData);
    }

    map.on('pointermove', listener);

    return () => {
      map.un('pointermove', listener);
    };
  }, [
    map,
    getTooltip,
    closeTooltip,
    featuresOnCluster,
    infoData,
    getLayerTooltip,
    closeLayerTooltip,
    gisTooltipsDelay,
    measureAction.type,
  ]);

  useEffect(() => {
    if (!map) {
      return;
    }

    addOverlays(map, overlays);

    const dblClickHandler = (e: MapBrowserEvent<any>) => {
      if (isConstructor) return;

      const features: FeatureLike[] = [];

      map.forEachFeatureAtPixel(e.pixel, (feature) => features.push(feature));

      const featureArr = features[0]?.get('features');
      const isCluster = featureArr?.length > 1;
      const isFeature = featureArr?.length === 1;
      const isMeteo =
        isFeature && featureArr[0]?.get('system') === System.Meteo;

      if (isCluster || isMeteo) return setInfoData(null);

      if (featureArr?.length !== 1) return;

      const feat = featureArr[0];

      const mapObj = findById(systemsArray, feat.get('id'));

      if (!mapObj) return;

      if (!checkIsTLValid(mapObj, feat.get('system'))) return;

      openDetailedForm(feat, e);
    };

    const moveEndHandler = () => {
      const view = map.getView();
      const center = view.getCenter();

      map.getViewport().style.cursor = GRAB;

      initialMapCenter && setMapData('center', center ?? initialMapCenter);
    };

    map.on('dblclick', dblClickHandler);
    map.on('moveend', moveEndHandler);

    return () => {
      map.un('dblclick', dblClickHandler);
      map.un('moveend', moveEndHandler);
    };
  }, [map, systemsArray, isConstructor]); // eslint-disable-line

  useEffect(() => {
    if (!map) return;
    const currZoom = map.getView().getZoom();

    const handleZoom = () => {
      const newZoom = map.getView().getZoom();

      if (currZoom !== newZoom && isClusterOpen) {
        setSelectedClusters(null);
        closeTooltip();
        !infoData && setIsNot('isRightPanel', false);
        closeLayerTooltip();

        return;
      }
    };

    map.on('movestart', handleZoom);

    return () => {
      map.un('movestart', handleZoom);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    closeTooltip,
    isClusterOpen,
    map,
    setInfoData,
    infoData,
    setIsNot,
    setSelectedClusters,
  ]);

  useEffect(() => {
    if (!map) return;

    const handleClick = (e: MapBrowserEvent<any>) => {
      handleClickFeature({
        e,
        map,
        idSelectFeature: infoData?.id,
        refs,
      });
    };

    map.on('singleclick', handleClick);

    return () => {
      map.un('singleclick', handleClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isMultipleSelect,
    infoData,
    isRightPanel,
    map,
    refs,
    setIsNot,
    setMapData,
    setIsClusterClicked,
  ]);

  useEffect(() => {
    if (!map) return;

    const onPointerDrag = () => {
      map.getViewport().style.cursor = GRABBING;
    };

    map.on('pointerdrag', onPointerDrag);

    return () => {
      map.un('pointerdrag', onPointerDrag);
    };
  }, [map]);

  useEffect(() => {
    if (!map) return;

    const clusterLayer = map
      .getLayers()
      .getArray()
      .find((layer: BaseLayer) =>
        layer instanceof AnimatedCluster ? layer : null
      ) as U<AnimatedCluster>;

    if (!clusterLayer) return;

    const onPostRender = () => {
      const features = clusterLayer.getSource()?.getFeatures() as U<
        Feature<Point>[]
      >;

      if (!map || !features?.length) return;

      setClusters(features);
    };

    clusterLayer.on('postrender', onPostRender);

    map.on('postrender', onPostRender);

    return () => {
      map.un('postrender', onPostRender);
    };
  }, [map, isCrossroadBorder, setClusters]);

  useEffect(() => {
    if (!map) return;

    const mapDiv = map.getTargetElement();

    const cb = (event: MouseEvent) => {
      const x = event.clientX;
      const y = event.clientY;
      const elementUnderCursor = document.elementFromPoint(x, y);

      const isCursorOnMapChildElement = mapDiv.contains(elementUnderCursor);

      setKeyValue('isCursorOnMapChildElement', isCursorOnMapChildElement);
    };

    document.addEventListener('mousemove', cb);

    return () => {
      document.removeEventListener('mousemove', cb);
    };
  }, [map, setKeyValue]);

  useEffect(() => {
    if (!map) return;
    const view = map.getView();
    let prevZoom = view.getZoom();

    const handleChange = (e?: BaseEvent) => {
      const curZoom = e?.target.getZoom();
      const resolution = e?.target.getResolution() ?? view.getResolution();

      if (curZoom === prevZoom) return;
      const currentZoom = curZoom ?? prevZoom;

      setKeysValues({ currentZoom, resolution });
      prevZoom = currentZoom;
    };

    handleChange();
    view.on('change', handleChange);

    return () => view.un('change', handleChange);
  }, [map, setKeysValues]);

  return null;
};

export default useMapEventHandlers;
