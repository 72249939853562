import { isNumber } from 'lodash';
import { FC } from 'react';

import { TrafficLane } from '../../../../../../../../../ts/models/constructor.model';
import DtIcon from '../../../../../../../../Overlays/DtIcon/DtIcon';

interface LinkedDetectorProps {
  lane: TrafficLane;
  rate: number;
  id: number;
  onClick?: () => void;
}

const LinkedDetector: FC<LinkedDetectorProps> = ({
  id,
  lane,
  rate,
  onClick,
}) => {
  const {
    id: laneId,
    dtIconOffsetX,
    dtIconOffsetY,
    dtIconSize,
    linkedCameraId,
  } = lane;

  const isDtIconVisible =
    !linkedCameraId && isNumber(dtIconOffsetX) && isNumber(dtIconOffsetY);

  return (
    <DtIcon
      isVisible={isDtIconVisible}
      offsetX={dtIconOffsetX}
      offsetY={dtIconOffsetY}
      size={dtIconSize}
      id={laneId}
      isIcoWithId
      activeId={id}
      rate={rate}
      onClick={onClick}
    />
  );
};

export default LinkedDetector;
