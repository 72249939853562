import { type DescriptionsProps } from 'antd/lib';

import { LibraryKeys } from '../../../../../../../../../api/libraries/enums/enums';
import { findById } from '../../../../../../../../../helpers/findBy';
import rootStore from '../../../../../../../../../stores/rootStore/rootStore';
import { System } from '../../../../../../../../../ts/enums/enums';
import getDeviceBySystem from '../../../../../../DeviceCard/Mapper/CrossroadSchema/helpers/getDeviceBySystem';
import SkeletonInput from '../../../../../../SkeletonInput/SceletonInput';

const { SystemId } = LibraryKeys;

interface DescriptionInfo {
  id: number;
  system: System;
  address: Nullish<string>;
  addressLoading: boolean;
  isAdditional?: boolean;
}

const getDescriptionInfo = ({
  id,
  system,
  address,
  addressLoading,
  isAdditional = false,
}: DescriptionInfo) => {
  const systemDict = rootStore.uiStore.dictionaries[SystemId];

  const device = getDeviceBySystem(id, system);

  if (!device) return [];

  const { dsc, externalDeviceId, pointUdsName, pointUdsUid } =
    device.deviceInfo;

  const systemName = findById(systemDict, device.eputsAdapter.scSystemId)?.name;

  const addressValue = address || 'Не найден';

  const additionalItems: DescriptionsProps['items'] = [
    {
      key: 'systemName',
      label: 'Система',
      children: systemName || 'Без названия',
    },
    {
      key: 'pointUdsName',
      label: 'Название точки УДС',
      children: pointUdsName ?? 'Нет привязки к точке УДС',
    },
    {
      key: 'pointUdsUid',
      label: 'УИ точки УДС',
      children: pointUdsUid ?? 'Нет привязки к точке УДС',
    },
    {
      key: 'externalDeviceId',
      label: 'Внешн. УИ устройства',
      children: externalDeviceId,
    },
  ];

  const mainItems: DescriptionsProps['items'] = [
    {
      key: 'dsc',
      label: 'Описание',
      children: dsc || 'Отсутствует',
      span: 2,
    },
    {
      key: 'address',
      label: 'Адрес',
      children: <SkeletonInput loading={addressLoading} value={addressValue} />,
      span: 2,
    },
  ];

  additionalItems.splice(1, 0, ...(!isAdditional ? mainItems : []));

  return additionalItems.filter((el) => el.children);
};

export default getDescriptionInfo;
