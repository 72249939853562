import { isNumber } from 'lodash';

import {
  ColumnTypes,
  ITableColumn,
} from '../../../../../../ts/models/table.model';

const ORDER_NUMBER_COL = {
  title: '№',
  visible: true,
  dataIndex: 'orderNumber',
  type: ColumnTypes.number,
  referens: 'orderNumber',
  readonly: true,
  filterSettings: { sorted: false, searched: false, filters: null },
};

type NumberFilterColumn = Omit<ITableColumn, 'visibleNumber'> & {
  visibleNumber: number;
};

interface ColumnsFilter {
  numbers: NumberFilterColumn[];
  other: ITableColumn[];
}

export const getSortedColumns = (
  columns: ITableColumn[],
  isOrderNumber?: boolean
) => {
  const { numbers, other } = columns.reduce(
    (acc: ColumnsFilter, item) => {
      const filterNumber = item.visibleNumber;

      if (isNumber(filterNumber)) {
        acc.numbers.push({ ...item, visibleNumber: filterNumber });
      } else {
        acc.other.push(item);
      }

      return acc;
    },
    { numbers: [], other: [] }
  );

  const orderNumberCol = isOrderNumber ? [ORDER_NUMBER_COL] : [];

  numbers.sort((a, b) => a.visibleNumber - b.visibleNumber);

  return [...orderNumberCol, ...numbers, ...other];
};
