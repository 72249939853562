import api from '../api';
import { getSendErrorBody } from '../api/helpers/getSendErrorBody';
import rootStore from '../stores/rootStore/rootStore';

export const sendError =
  (title: string, e: unknown, appPath: string) => async () => {
    try {
      const isAuth = rootStore.userDataStore.isAuth;

      if (!isAuth) return;

      await api.server.sendError(getSendErrorBody(title, e), appPath);
    } catch (error) {
      console.error({
        message: 'Отчет об ошибке не отправлен',
        description: error,
      });
    }
  };
