import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';
import { BiGridAlt } from 'react-icons/bi';
import { HiMinusSm, HiPlus } from 'react-icons/hi';
import { ImSphere } from 'react-icons/im';
import { TbZoomReset } from 'react-icons/tb';

import { ZOOM } from '../../../constants/mapConstants';
import { VIEW_MODE } from '../../../stores/gisDataStore/gisDataStore.model';
import rootStore from '../../../stores/rootStore/rootStore';
import { handleZoom } from '../../Map/helpers/zoomHandlers/handleZoom';
import SceneWrapper from '../../SceneWrapper/SceneWrapper';
import ButtonIco from '../../ui-kit/ButtonIco/ButtonIco';
import ButtonsBlock from '../../ui-kit/ButtonsBlock/ButtonsBlock';
import Popover from '../../ui-kit/Popover/Popover';

import { CONTENT } from './constants/constants';
import handleBtnZoom from './helpers/handleBtnZoom';

import styles from './ControlButtons.module.scss';

const { ALIENATE, MODE, BRING_CLOSER, RESET } = CONTENT;

const ControlButtons: FC = () => {
  const { isConstructor } = rootStore.constructorStore;
  const { mapButtonsTipsDelay, isOffsetRightPanel, isDevMapInfo, regionData } =
    rootStore.uiStore;
  const { viewMode, setGisValue, basemapId } = rootStore.gisDataStore;
  const { currentZoom } = rootStore.mapDataStore;
  const zoomStep =
    isConstructor || isDevMapInfo ? ZOOM.STEP_CONSTRUCTOR : ZOOM.STEP;

  const disabledMinZoom = regionData?.initialZoom ?? ZOOM.MIN;

  const increaseZoom = () => handleBtnZoom(zoomStep);
  const decreaseZoom = () => handleBtnZoom(-zoomStep);
  const resetZoom = () => handleZoom(disabledMinZoom);

  const zoomStyles = classNames({
    [styles.zoom]: true,
    [styles.systemsOffset]: isOffsetRightPanel,
  });

  const popOverProps = {
    placement: 'left',
    tipsDelay: mapButtonsTipsDelay,
  } as const;

  const isIncreaseDisabled = currentZoom + zoomStep > ZOOM.MAX;
  const isDecreaseDisabled = currentZoom - zoomStep < disabledMinZoom;
  const isResetDisabled = Math.floor(currentZoom) === disabledMinZoom;

  const changeViewMode = () => {
    const mode =
      viewMode === VIEW_MODE['2D'] ? VIEW_MODE['3D'] : VIEW_MODE['2D'];

    setGisValue('viewMode', mode);
  };

  useEffect(() => {
    setGisValue('viewMode', VIEW_MODE['2D']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionData]);

  return (
    <div className={zoomStyles}>
      <div className={styles.mode}>
        <ButtonIco onClick={changeViewMode} disabled={isIncreaseDisabled}>
          <Popover content={MODE} {...popOverProps}>
            {viewMode === VIEW_MODE['2D'] ? <ImSphere /> : <BiGridAlt />}
          </Popover>
        </ButtonIco>
      </div>

      <ButtonsBlock borderRadius={6} className={styles.buttonBox}>
        <ButtonIco onClick={increaseZoom} disabled={isIncreaseDisabled}>
          <Popover content={BRING_CLOSER} {...popOverProps}>
            <HiPlus />
          </Popover>
        </ButtonIco>

        <ButtonIco
          onClick={decreaseZoom}
          disabled={isDecreaseDisabled}
          isRoundBottom
        >
          <Popover content={ALIENATE} {...popOverProps}>
            <HiMinusSm />
          </Popover>
        </ButtonIco>
      </ButtonsBlock>

      <div className={styles.reset}>
        <ButtonIco onClick={resetZoom} disabled={isResetDisabled}>
          <Popover content={RESET} {...popOverProps}>
            <TbZoomReset />
          </Popover>
        </ButtonIco>
      </div>

      <SceneWrapper
        visible={viewMode === VIEW_MODE['3D']}
        basemapId={basemapId}
        zoom={currentZoom}
      />
    </div>
  );
};

export default observer(ControlButtons);
