import { COLORS } from '../../../../../../constants/colorsConstants';
import { neverTypeCheck } from '../../../../../../helpers/neverTypeCheck';
import { ViewStatus } from '../../../../../../ts/enums/constructor';
import { TrafficLane } from '../../../../../../ts/models/constructor.model';

const { Linked, Disabled, Transferred } = ViewStatus;
const { BG_TINT, DANGER } = COLORS;

const getStatusColor = (
  status: ViewStatus,
  linkColor: TrafficLane['linkColor'],
  isTransferred: TrafficLane['isTransferred']
) => {
  switch (status) {
    case Linked:
      return linkColor ?? BG_TINT;
    case Transferred:
      return isTransferred ? DANGER : BG_TINT;
    case Disabled:
      return BG_TINT;
    default:
      return neverTypeCheck(status);
  }
};

export default getStatusColor;
