import { z } from 'zod';

import {
  DetectorsDataSchema,
  DirectionsScheme,
} from '../../../../api/mapObjects/detectors/data/detectorsData.zod';
import { BarColors } from '../../../../constants/colorsConstants';

export enum DirectionsTypes {
  OnCamera = 1,
  OutCamera = 2,
  Undefined = 3,
}

export enum VehicleTypes {
  Unknown = 1,
  Moto = 3,
  Auto = 4,
  Truck = 5,
  Bus = 6,
}

export interface PopupDetectorProps {
  id: number;
  setIsOpenDetailed: () => void;
}

export type TDataDetectors = z.infer<typeof DetectorsDataSchema>;

export interface DetectorsDataBase extends z.infer<typeof DirectionsScheme> {
  lane?: number;
  lb_vehicle_type_id?: VehicleTypes;
}

export type DetectorsDataInfo = DetectorsDataBase & { [key: string]: number };

type DataDt = Omit<TDataDetectors['data'][number], 'info'>;

interface DataDetectors extends DataDt {
  info: DetectorsDataInfo[];
}

type DetectorsLanesInfo = Required<DetectorsDataInfo>;

export interface DataLanesDetectors extends DataDt {
  info: DetectorsLanesInfo[];
}

export interface DetectorsDataGraph extends Omit<TDataDetectors, 'data'> {
  data: DataDetectors[];
}

export interface VehiclesDataLanes extends Omit<TDataDetectors, 'data'> {
  data: DataLanesDetectors[];
}

export interface IDataGraph {
  date: string;
  comparableValue?: U<number>;
  status: BarColors;
  timeFrom: string;
  interval: string[];
}
