import { VehiclesDataLanes } from '../../../../components/InfoPanel/SystemsInfo/PopupDetector/PopupDetector.model';
import { getFormattedDate } from '../../../../components/TrafficLightDetailed/TlDetectors/IntancyGraph/helpers/getFormattedDate';
import { formatedDataOnUtc } from '../../../../components/TrafficLightDetailed/TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/formatedDataOnUtc';
import notification from '../../../../components/ui-kit/Notification/Notification';
import { IS_DEV } from '../../../../constants/constants';
import NOTICE from '../../../../constants/notificationConstants';
import { VERSION } from '../../../../constants/versions';
import { sendError } from '../../../../helpers/sendError';
import { getAppPathHeaders } from '../../../helpers/getAppPathHeaders';
import http from '../../../http';
import tryCatchWrapper from '../../../tryCatchWrapper';

import { DetectorsDataSchema } from './detectorsData.zod';
import {
  IGetDetectorsListProps,
  UseDetectorsQueryOptions,
} from './model/detectors.model';
import useDetectorsDataQuery from './query/useDetectorsDataQuery';

const APP_PATH = 'api.mapObjects.detectors.data';
const OBJECTS = VERSION + '/objects';
const DATA = '/data';

export const DT_PREVIEW = OBJECTS + '/dt';

export const DT = DT_PREVIEW + DATA;

class DetectorsDataApi {
  fetchDetectorsData = ({
    ids,
    appPath,
    interval,
    selectedDates,
    isNeedUtcFormatted = true,
    timeZone,
    directions,
    zones,
    lanes,
    vehicleTypes,
  }: IGetDetectorsListProps): Promise<U<VehiclesDataLanes>> =>
    tryCatchWrapper(
      async () => {
        const [dateFrom, dateTo] = isNeedUtcFormatted
          ? formatedDataOnUtc(selectedDates, timeZone)
          : getFormattedDate(selectedDates);

        const res = await http.get(DT, {
          params: {
            interval,
            dateFrom,
            dateTo,
            timeZone,
            dtIds: JSON.stringify(ids),
            directions: JSON.stringify(directions),
            db2_lane: JSON.stringify(zones),
            vehicleTypes: JSON.stringify(vehicleTypes),
            db3_lane: JSON.stringify(lanes),
          },
          headers: getAppPathHeaders(appPath),
        });

        const validate = DetectorsDataSchema.safeParse(res?.data);

        if (!validate.success) {
          console.group('Detectors graph ERRORS: ');
          ids.map((id) => console.error(`id:${id}`, validate.error.issues));
          console.groupEnd();

          const errors = ids.map((el) => ({
            id: el,
            errors: validate.error.issues,
          }));

          notification.error('DETECTORS_DATA');

          !IS_DEV &&
            sendError(
              NOTICE.DETECTORS_DATA.description,
              {
                dsc: `Ошибка в детекторах: ${ids.slice(0, -2)}.`,
                issues: errors,
              },
              APP_PATH
            );

          return;
        }

        return res?.data;
      },
      {
        isBlockedNotify: true,
      }
    );
  useDetectorsData = <T extends U<true> = undefined>(
    props: UseDetectorsQueryOptions<T>
  ) =>
    useDetectorsDataQuery({
      ...props,
      fetchDetectorsData: this.fetchDetectorsData,
    });
}

const detectorsDataApi = new DetectorsDataApi();

export default detectorsDataApi;
