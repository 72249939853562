import { isNumber, isString } from 'lodash';

import { AVG_KEY_VALUE } from '../constants/constants';
import { CalcData, DataValue } from '../models/chart.model';

interface CalcDataProps {
  data: DataValue[];
  isAvgValue?: boolean;
  keyX: string;
  keyY?: string;
}

export const getCalcData = ({
  data,
  isAvgValue,
  keyX,
  keyY,
}: CalcDataProps): CalcData[] => {
  return (
    data.map((item, index) => {
      const newItem: CalcData = {};

      if (isAvgValue) {
        const avgValue = Object.values(item).reduce(
          (acc: number, directionValue) => {
            const value = isNumber(directionValue) ? directionValue : 0;

            return acc + value;
          },
          0
        );

        const elementsCount = Object.keys(item).filter(
          (item) => item !== keyY && item !== keyX
        );

        if (elementsCount.length >= 2) {
          newItem[AVG_KEY_VALUE] = Math.ceil(avgValue / elementsCount.length);
        }
      }

      if (isString(item[keyX])) {
        return { ...newItem, ...item, index };
      }

      return { ...newItem, ...item };
    }) ?? []
  );
};
