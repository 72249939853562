import { Feature } from 'ol';
import type { FeatureLike } from 'ol/Feature';

import { getFeatureProperty } from '../../../../helpers/mapHelpers/getFeatureProperty';
import {
  FeatureDeviceProps,
  FeaturesTypes,
  System,
} from '../../../../ts/enums/enums';
import { ClusterLinks } from '../model/cluster.model';

const { Features } = FeaturesTypes;

export const getClusterLink = (cluster: U<FeatureLike>) => {
  const features = cluster && getFeatureProperty<Feature[]>(cluster, Features);

  if (!features) return null;

  const link = features.reduce((acc: ClusterLinks, value: Feature) => {
    const system = getFeatureProperty<System>(value, FeatureDeviceProps.System);
    const id = getFeatureProperty<number>(value, FeatureDeviceProps.Id);

    if (!system || !id) return acc;

    if (!acc[system]) acc[system] = [];

    acc[system]?.push(id);

    return acc;
  }, {} as ClusterLinks);

  return link;
};
