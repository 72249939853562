import { toJS } from 'mobx';
import { useMemo } from 'react';

import { findById } from '../../../../../../../../../helpers/findBy';
import rootStore from '../../../../../../../../../stores/rootStore/rootStore';
import { LinkedCamera } from '../../../../../../../../Overlays/models/camera.model';

interface UseLinkedCamerasProps {
  linkedCams: number[];
}

const useLinkedCameras = ({ linkedCams }: UseLinkedCamerasProps) => {
  const { cameras } = rootStore.mapDataStore;

  const cams = useMemo(
    () =>
      linkedCams.reduce((acc: LinkedCamera[], id) => {
        const camera = toJS(findById(cameras, id));

        if (camera) {
          const cameraExtended: LinkedCamera = {
            ...camera,
            isExtended: false,
          };

          acc.push(cameraExtended);
        }

        return acc;
      }, []),
    [linkedCams, cameras]
  );

  return cams;
};

export default useLinkedCameras;
