import { useEffect } from 'react';

import rootStore from '../../../../stores/rootStore/rootStore';

const useCleanViewFile = (id: number) => {
  const { setDocumentsKeysValues } = rootStore.documentsStore;

  useEffect(() => {
    setDocumentsKeysValues({ viewFile: null });
  }, [id, setDocumentsKeysValues]);
};

export default useCleanViewFile;
