import { FC, ReactNode } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import { System } from '../../../ts/enums/enums';
import MapIconContent from '../../Mapper/TooltipContent/MapIconContent/MapIconContent';
import Popover from '../../ui-kit/Popover/Popover';

import styles from './IconTooltip.module.scss';

interface TooltipProps {
  id: U<number>;
  system?: System;
  size?: number;
  children: JSX.Element | ReactNode;
}

const IconTooltip: FC<TooltipProps> = ({ children, id, system, size }) => {
  const { mapTipsDelay } = rootStore.uiStore;

  if (!id || !system) return <>{children}</>;

  return (
    <Popover
      content={
        <MapIconContent
          id={id}
          system={system}
          style={{ padding: '1rem 2rem' }}
        />
      }
      tipsDelay={mapTipsDelay}
      overlayClassName={styles.popover}
      isClearParent
    >
      <div style={{ height: size }} className={styles.icoWrapper}>
        {children}
      </div>
    </Popover>
  );
};

export default IconTooltip;
