import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { AiOutlineArrowUp } from 'react-icons/ai';

import signalProgramsApi from '../../../api/mapObjects/trafficLights/info/signalPrograms';
import rootStore from '../../../stores/rootStore/rootStore';
import {
  ICsdData,
  IProcessedDataInitial,
  ITimePoints,
} from '../../../ts/models/signalPrograms.model';
import { DetailedItemProps } from '../../DetailedMain/DetailedWrapper/constants/constants';
import getCsdData from '../../NewSignalProgram/helpers/getCsdData';
import DefaultChecked from '../../ui-kit/DefaultChecked/DefaultChecked';
import { getCorrectPrograms } from '../TlManagement/helpers/getCorrectPrograms';

import SelectPlan from './SelectPlan/SelectPlan';
import SignalProgramCard from './SignalProgramCard/SignalProgramCard';

import styles from './TlManagementPlans.module.scss';

const TlManagementPlans: FC<DetailedItemProps> = ({ id, curentActiveKey }) => {
  const { activeKey } = rootStore.uiStore;
  const { setSelectPlanNumber } = rootStore.detailedStore;
  const blockRef = useRef<HTMLDivElement>(null);
  const [isLoadingProgram, setIsLoadingProgram] = useState<boolean>(false);
  const [programs, setPrograms] = useState<IProcessedDataInitial[]>([]);
  const [phasesSet, setPhasesSet] = useState<ITimePoints[][]>([]);
  const [csdData, setCsdData] = useState<ICsdData[]>([]);
  const [isError, setIsError] = useState<boolean[]>([]);
  const [isShowArrow, setIsShowArrow] = useState(false);

  const propsSignalProgramCard = {
    programs,
    csdData,
    phasesSet,
    isError,
    id,
  };

  const clearStates = () => {
    setPrograms([]);
    setPhasesSet([]);
    setCsdData([]);
    setIsError([]);
    setIsLoadingProgram(false);
  };

  const getPrograms = useCallback(async () => {
    clearStates();
    const programs = await signalProgramsApi.getProgramsData(id);

    if (!programs) return setIsLoadingProgram(true);

    setIsLoadingProgram(true);
    setPrograms(getCorrectPrograms(programs));
  }, [id]);

  useEffect(() => {
    if (curentActiveKey === activeKey) {
      setIsLoadingProgram(false);

      getPrograms();
    }
  }, [getPrograms, activeKey, curentActiveKey]);

  const getData = (program: IProcessedDataInitial) => {
    const res = getCsdData({
      data: program,
      isStatic: true,
    });

    if (!res) {
      setIsError((prevState) => [...prevState, true]);

      return;
    }

    const { timePoints, csdData } = res;

    setPhasesSet((prevState) => [...prevState, timePoints]);
    setCsdData((prevState) => [...prevState, csdData]);
    setIsError((prevState) => [...prevState, false]);
  };

  useEffect(() => {
    programs.forEach((program) => {
      if (program?.tlSignalProgram2 && program.tlTacts) {
        getData(program);
      }
    });
  }, [programs]);

  const onScroll = () => {
    setIsShowArrow(
      !!blockRef.current?.scrollTop && blockRef.current?.scrollTop > 100
    );
  };

  const onClickArrow = () => {
    blockRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
    setSelectPlanNumber(null);
  };

  if (curentActiveKey !== activeKey) return null;

  return (
    <div className={styles.container} ref={blockRef} onScroll={onScroll}>
      <DefaultChecked
        isLoading={!isLoadingProgram}
        isEmptyData={!programs.length}
      >
        <div className={styles.columnContainer}>
          <SelectPlan programs={programs} />
          <SignalProgramCard {...propsSignalProgramCard} />
          {isShowArrow && (
            <AiOutlineArrowUp className={styles.icon} onClick={onClickArrow} />
          )}
        </div>
      </DefaultChecked>
    </div>
  );
};

export default observer(TlManagementPlans);
