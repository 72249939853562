import { cloneDeep, isNumber } from 'lodash';
import { useMemo } from 'react';

import ModalSignalProgram from '../../../../PlanSelect/ModalSignalProgram/ModalSignalProgram';
import EditSignalProgramTable from '../../../EditSgnProgramTable/EditSgnProgramTable';
import { editSignalProgram } from '../../../helpers/editSignalProgram';
import { getDurationMinArray } from '../../../helpers/getDurationMinArray';
import { UseEditTableProps } from '../../../model/temporaryPlan.model';

const useEditTable = ({
  id,
  programInfo,
  isValidateTable,
  dataTable,
  selectedPlanId,
  setIsValidateTable,
  setDataTable,
  columns,
  setColumns,
}: UseEditTableProps) => {
  const { sgnProgramValues } = programInfo;

  const minDurations = useMemo(() => {
    if (!isNumber(selectedPlanId)) return [];

    const program = sgnProgramValues.at(selectedPlanId - 1);

    if (!program) return [];

    return getDurationMinArray(program.phasesSet);
  }, [selectedPlanId, sgnProgramValues]);

  const programsData = useMemo(() => {
    if (!isNumber(selectedPlanId) || !dataTable.length) return null;

    const program = sgnProgramValues.at(selectedPlanId - 1);

    const csdData = program?.csdData;

    if (!csdData) return null;

    return editSignalProgram(dataTable, cloneDeep(csdData));
  }, [dataTable, sgnProgramValues, selectedPlanId]);

  const isErrorSgnProgram = programsData?.isError || !isValidateTable;

  const editBodyProps = {
    content: (
      <EditSignalProgramTable
        setIsValidateTable={setIsValidateTable}
        dataTable={dataTable}
        setDataTable={setDataTable}
        durationMinArray={minDurations}
        columns={columns}
        setColumns={setColumns}
      />
    ),
    image: (
      <ModalSignalProgram
        programExecTime={
          isNumber(selectedPlanId)
            ? programInfo.programs[selectedPlanId - 1]?.execTime
            : null
        }
        csdData={programsData?.newCsdDataPhase}
        phasesSet={programsData?.newPhasesSet}
        isError={isErrorSgnProgram}
        isLoadingProgram
        id={id}
        isEditTempPlan
      />
    ),
    phasesEdit: true,
  };

  return { editBodyProps, isErrorSgnProgram };
};

export default useEditTable;
