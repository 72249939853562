/* eslint-disable camelcase */
import { VERSION } from '../../../../constants/versions';
import { getAppPathHeaders } from '../../../helpers/getAppPathHeaders';
import http from '../../../http';
import tryCatchWrapper from '../../../tryCatchWrapper';

const GIS_BASEMAPS = `${VERSION}/integration/gis/basemaps-configuration`;

const REQUEST_PARAMS = {
  isExternalError: true,
};

const basemapsApi = {
  getList: (appPath: string, id: number) =>
    tryCatchWrapper(
      async () => {
        const res = await http.get(`${GIS_BASEMAPS}?regionId=${id}`, {
          headers: getAppPathHeaders(appPath),
        });

        return res?.data;
      },
      {
        ...REQUEST_PARAMS,
        errorMessage: 'Ошибка получения доступных подложек',
        ignoreCodes: [423],
      }
    ),
};

export default basemapsApi;
