import { Collapse, type CollapseProps } from 'antd';
import { FC, useMemo } from 'react';

import { SYSTEM } from '../../../../constants/constants';
import { System } from '../../../../ts/enums/enums';
import Switcher from '../../../ui-kit/Switcher/Switcher';
import { getFilteredLinkedDevices } from '../../helpers/getFilteredLinkedDevices';
import { InfoMainProps, isCamera } from '../../SystemsInfo.model';
import CameraInfo from '../CameraInfo/CameraInfo';
import CamerasSwitcher from '../CamerasSwitcher/CamerasSwitcher';
import LinkedDevicesInfo from '../LinkedsInfo/LinkedDevicesInfo';
import MeteoInfoPopup from '../MeteoInfoPopup/MeteoInfoPopup';
import PointUdsInfo from '../PointUdsInfo/PointUdsInfo';
import PopupDetector from '../PopupDetector/PopupDetector';
import TrafficLightInfo from '../TrafficLightInfo/TrafficLightInfo';

import styles from './InfoMain.module.scss';

type TMAP_ITEM = { [key: string]: any };

const { LIGHTS, CAMERAS, DETECTORS, METEO } = SYSTEM;
const MAP_ITEM = {} as TMAP_ITEM;

MAP_ITEM[LIGHTS] = TrafficLightInfo;
MAP_ITEM[CAMERAS] = CameraInfo;
MAP_ITEM[DETECTORS] = PopupDetector;
MAP_ITEM[METEO] = MeteoInfoPopup;

const InfoMain: FC<InfoMainProps> = ({ currentObject, system, id }) => {
  const filteredLinkedDevices = useMemo(
    () => getFilteredLinkedDevices(currentObject),
    [currentObject]
  );

  const filteredLinkedCameras =
    (filteredLinkedDevices && filteredLinkedDevices[System.Cameras]) ?? [];

  const cameraId =
    isCamera(currentObject) && currentObject.dai_jdt83?.linkedDetectorId
      ? id
      : null;

  const items: CollapseProps['items'] = [
    {
      label: 'Основная Информация',
      children: <Switcher segment={system} id={id} obj={MAP_ITEM} />,
    },
    {
      label: 'Связанное видеонаблюдение',
      children: filteredLinkedCameras.length ? (
        <CamerasSwitcher title={''} camerasIdList={filteredLinkedCameras} />
      ) : (
        <p className={styles.linkedText}>Нет привязанных камер</p>
      ),
    },
    {
      label: 'Связанные устройства',
      children: (
        <LinkedDevicesInfo
          linkedDevices={filteredLinkedDevices}
          cameraId={cameraId}
        />
      ),
    },
    {
      label: 'Точка УДС',
      children: <PointUdsInfo deviceInfo={currentObject.deviceInfo} />,
    },
  ];

  return (
    <div className={styles.wrapper}>
      <Collapse defaultActiveKey={0} items={items} />
    </div>
  );
};

export default InfoMain;
