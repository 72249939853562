import { Map } from 'ol';
import BaseLayer from 'ol/layer/Base';

import { BASEMAPS_PROPS } from '../../../apiGIS/constants/basemaps/utils';
import { createBasemapLayer } from '../../../apiGIS/utils/basemaps';
import { IBaseMapItem } from '../../../stores/gisDataStore/gisDataStore.model';
import { TMap } from '../../../stores/mapStore/mapStore.model';
import rootStore from '../../../stores/rootStore/rootStore';

const removeBasemapLayers = (map: TMap) => {
  if (!map) {
    return;
  }

  const layers = map.getLayers().getArray();

  const baseMapLayers = layers.filter((item: any) => {
    const category = item.get(BASEMAPS_PROPS.CATEGORY_PROPERTY);

    return category === BASEMAPS_PROPS.CATEGORY_PROPERTY_VALUE;
  });

  baseMapLayers.forEach((item: any) => {
    map.removeLayer(item);
  });
};

const addBasemapLayer = (basemap: IBaseMapItem, map: TMap) => {
  if (!map) {
    return;
  }

  const layer = createBasemapLayer(basemap) as BaseLayer;

  if (layer) {
    map.addLayer(layer);

    const view = map.getView();
    const zoom = view.getZoom();

    const { maxZoom } = basemap;

    if (zoom && maxZoom && zoom > maxZoom) {
      view.setZoom(maxZoom);
    }
  }
};

export const handleChangeBasemap = (map: Map, basemap: IBaseMapItem) => {
  const { url } = basemap;

  if (!url) {
    return;
  }

  const changedBasemap = {
    ...basemap,
    url: url + '?regionId=' + rootStore.uiStore.regionData?.id,
  };

  removeBasemapLayers(map);
  addBasemapLayer(changedBasemap, map);
};
