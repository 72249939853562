import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';

import { findById } from '../../../../../../../helpers/findBy';
import rootStore from '../../../../../../../stores/rootStore/rootStore';
import LinkedDevices from '../../CrossroadSchema/LinkedDevicesWrapper/LinkedDevices/LinkedDevices';

interface LinkedDevicesUdsWrapperProps {
  rate: number;
  dtId: number;
}

const LinkedDevicesUdsWrapper: FC<LinkedDevicesUdsWrapperProps> = ({
  rate,
  dtId,
}) => {
  const { cameras, detectors } = rootStore.mapDataStore;

  const lanesInfo = useMemo(() => {
    const dt = findById(detectors, dtId);

    if (!dt) return;

    const { linkedDeviceIds } = dt;

    const linkedCams = linkedDeviceIds?.cameras?.filter((id) => {
      const camera = findById(cameras, id);

      const linkedDt = camera?.dai_jdt83?.linkedDetectorId;

      return !!linkedDt && linkedDt === dtId;
    });

    const linkedDts = [...(linkedDeviceIds?.detectors ?? []), dtId];

    return { linkedCams, trafficLane: dt.dai_jdt83, linkedDts };
  }, [cameras, detectors, dtId]);

  if (!lanesInfo) return null;

  const { trafficLane, linkedDts } = lanesInfo;

  return (
    <LinkedDevices
      rate={rate}
      trafficLane={trafficLane ?? undefined}
      linkedDts={linkedDts}
    />
  );
};

export default observer(LinkedDevicesUdsWrapper);
