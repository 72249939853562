import { TLDetailedCode } from '../../../../ts/enums/userData';
import GeneralCameraInfo from '../../../CameraDetailed/GeneralCameraInfo/GeneralCameraInfo';

import { PanelTabConfig } from './constants';

export const DETAILED_CAMERA_ITEMS: PanelTabConfig[] = [
  {
    label: 'Основная информация',
    key: '1',
    code: TLDetailedCode.MainInfo,
    Component: GeneralCameraInfo,
    isLoaded: false,
  },
];
