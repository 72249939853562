import { FC } from 'react';

import { System } from '../../../../ts/enums/enums';
import { TlMode } from '../../../../ts/models/tl.model';
import { switchIco } from '../../../DetailedMain/helpers/switchHeaderIco';
import { getTLIcon } from '../../../Mapper/helpers/getStyle';
import CircleMarkerPopup, {
  CircleMarkerPopupProps,
} from '../CircleMarkerPopup/CircleMarkerPopup';
import PointerMarker from '../PointerMarker/PointerMarker';

interface IMarkerContentProps {
  system: System;
  colorWithMode: string;
  isSelectFeature: U<boolean>;
  mode: N<TlMode>;
}

const MarkerContent: FC<IMarkerContentProps> = ({
  system,
  colorWithMode,
  isSelectFeature,
  mode,
}) => {
  const icoInfo =
    system === System.Lights
      ? getTLIcon(mode)
      : { ico: switchIco(system).ico, borderColor: null };

  const markerProps: CircleMarkerPopupProps = {
    colorWithMode,
    icoInfo,
  };

  return isSelectFeature ? (
    <CircleMarkerPopup {...markerProps} />
  ) : (
    <PointerMarker {...markerProps} />
  );
};

export default MarkerContent;
