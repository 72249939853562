import { ILayerSocketDefinition } from '../../apiGIS/socketServerPool';
import { IWeatherDataset } from '../../components/Map/IWeatherTimeModel';
import { CategoryLayerGIS } from '../../ts/enums/enums';
import { IServiceBasemapItem } from '../../ts/models/gis/basemap';
import { ILabeling } from '../../ts/models/gis/renderer.model';

export interface IBaseMapItem {
  id?: string;
  url?: string;
  type?: string;
  imageSrc?: string;
  text?: string;
  style?: Array<unknown>;
  subDomains?: Array<string>;
  maxZoom?: number;
  description?: string;
  persistent?: boolean;
  zIndex?: number;
}

interface GisBasicData {
  hasIntegrationGIS: boolean;
}

export interface GisLabelData extends GisBasicData {
  data: ILabeling[];
}

export interface GisBaseMapData extends GisBasicData {
  data: IServiceBasemapItem[];
}

export interface IZDefinition {
  field?: N<string>;
}

export interface ILayer {
  url: string;
  id: string;
  visible: boolean;
  group?: string;
  alias: string;
  editable: boolean;
  identifiable: boolean;
  minScale: number;
  maxScale: number;
  outFields: string[];
  orderByFields?: N<string>;
  searchFields: string[];
  type: string;
  organization: string;
  dynamic: boolean;
  load: boolean;
  refreshInterval: number;
  definitionExpression?: string;
  socketDefinition?: ILayerSocketDefinition;
  category: {
    id?: CategoryLayerGIS;
    category: CategoryLayerGIS;
    text: string;
  };
  displayField: U<string>;
  tooltipDefinition: { fields: U<string[]>; template: U<string> };
  fields?: {
    name: string;
    alias: string;
    type:
      | 'esriFieldTypeInteger'
      | 'esriFieldTypeOID'
      | 'esriFieldTypeGeometry'
      | 'esriFieldTypeString'
      | 'esriFieldTypeDouble';
  }[];
  subDomains?: string[];
  zPropertyDefinition?: IZDefinition;
}

export interface IVisibleLayer {
  id: string;
  visible: boolean;
  group?: string;
}

export interface WeatherData extends GisBasicData {
  data: IWeatherDataset[];
}

export const VIEW_MODE = {
  '2D': '2D',
  '3D': '3D',
};
