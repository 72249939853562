import { isUndefined } from 'lodash';
import { FC, ReactElement } from 'react';
import { Rectangle } from 'recharts';

import { COLORS } from '../../../../../constants/colorsConstants';
import getRectangleSize from '../helpers/getRectangleSize';

import getMainSizes from './helpers/getMainSizes';

const WHITE_MAGENTA = '#ff98ff';

interface CandleRectProps {
  formattedGraphicalItems?: {
    props?: {
      points: {
        x: number;
        y: number;
        payload: {
          name: string;
        };
      }[];
    };
  }[];
  activeIndex: N<number>;
}

//@ts-ignore
const CandleRect: FC<CandleRectProps> = (props) => {
  const { formattedGraphicalItems, activeIndex } = props;

  if (!formattedGraphicalItems) return null;

  const [min, q2, med, q4, max] = formattedGraphicalItems;

  const points = min?.props?.points;

  const baseSize = getRectangleSize(points?.length);
  const baseDiff = Math.floor(baseSize / 2);

  const baseMinMaxDiff = baseDiff / 2;

  return (
    points?.reduce((acc: ReactElement[], minPoint: any, index, arr) => {
      const medPoint = med?.props?.points[index];
      const maxPoint = max?.props?.points[index];
      const q2Point = q2?.props?.points[index];
      const q4Point = q4?.props?.points[index];

      if (
        isUndefined(medPoint) ||
        isUndefined(maxPoint) ||
        isUndefined(q2Point) ||
        isUndefined(q4Point)
      )
        return acc;

      const isFirst = !index;
      const isLast = index === arr.length - 1;

      const { diff, size, xMain, xMinMax } = getMainSizes({
        baseDiff,
        baseMinMaxDiff,
        baseSize,
        isFirst,
        isLast,
        x: minPoint.x,
      });

      const yDifferenceDown = q2Point.y - medPoint.y;
      const yDifferenceUp = medPoint.y - q4Point.y;
      const q2diff = minPoint.y - q2Point.y;
      const q4diff = maxPoint.y - q4Point.y;

      const color = COLORS.MAGENTA;

      const isActive = activeIndex === index;
      const opacity = isActive ? 0.7 : 1;

      acc.push(
        <svg key={`${minPoint.payload.name}_${index}`}>
          <linearGradient
            id="linear-gradient-up"
            x1="0%"
            x2="0%"
            y1="0%"
            y2="100%"
          >
            <stop offset="0%" stopColor={WHITE_MAGENTA} />
            <stop offset="90%" stopColor={color} />
            <stop offset="100%" stopColor={COLORS.BLACK} />
          </linearGradient>
          <linearGradient
            id="linear-gradient-down"
            x1="0%"
            x2="0%"
            y1="100%"
            y2="0%"
          >
            <stop offset="0%" stopColor={WHITE_MAGENTA} />
            <stop offset="90%" stopColor={color} />
            <stop offset="100%" stopColor={COLORS.BLACK} />
          </linearGradient>

          <Rectangle
            width={Math.abs(diff)}
            height={2}
            x={xMinMax}
            y={minPoint.y}
            stroke={COLORS.BG_TINT}
            fill={color}
          />
          <Rectangle
            width={3}
            height={q2diff}
            x={q2Point.x - 1}
            y={q2Point.y}
            stroke={COLORS.BG_TINT}
            fill={color}
          />
          <Rectangle
            width={size}
            height={yDifferenceUp}
            x={xMain}
            y={q4Point.y}
            stroke={COLORS.BG_TINT}
            radius={4}
            fill={'url(#linear-gradient-up)'}
            opacity={opacity}
          />
          <Rectangle
            width={size}
            height={yDifferenceDown}
            x={xMain}
            y={medPoint.y}
            stroke={COLORS.BG_TINT}
            radius={4}
            fill={'url(#linear-gradient-down)'}
            opacity={opacity}
          />
          <Rectangle
            y={medPoint?.y}
            x={medPoint?.x - diff}
            height={1}
            width={size}
            fill={COLORS.BG_TINT}
          />
          <Rectangle
            width={3}
            height={q4diff}
            x={q4Point.x - 1}
            y={q4Point.y}
            stroke={COLORS.BG_TINT}
            fill={color}
          />
          <Rectangle
            width={Math.abs(diff)}
            height={2}
            x={xMinMax}
            y={maxPoint.y}
            stroke={COLORS.BG_TINT}
            fill={color}
          />
        </svg>
      );

      return acc;
    }, []) ?? null
  );
};

export default CandleRect;
