import clickedControlBtn from './events/clickedControlBtn';
import incorrectTimeChange from './events/incorrectTimeChange';
import phaseHoldChange from './events/phaseHoldChange';
import tablePaginationChange from './events/tablePaginationChange';
import tokensUpdateComplete from './events/tokensUpdateComplete';
import updateFeatures from './events/updateFeatures';
import userDataUpdate from './events/userDataUpdate';
import videoListUpdate from './events/videoListUpdate';

export default {
  userDataUpdate,
  tokensUpdateComplete,
  tablePaginationChange,
  videoListUpdate,
  incorrectTimeChange,
  phaseHoldChange,
  updateFeatures,
  clickedControlBtn,
};
