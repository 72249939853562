import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';

import { ReactComponent as TrafficIco } from '../../../assets/icons/signs/ic_traffic.svg';
import { MAP_BUTTONS_NAMES } from '../../../constants/uiComponentConstants';
import rootStore from '../../../stores/rootStore/rootStore';
import { CategoryLayerGIS } from '../../../ts/enums/enums';
import { MapButtonsCode } from '../../../ts/enums/userData';
import ButtonIco from '../../ui-kit/ButtonIco/ButtonIco';
import { PopoverProps } from '../../ui-kit/Popover/Popover';

import { enableTrafficLayers, disableTrafficLayers } from './helpers';

const PLACEMENT = 'bottom';

const TrafficButton: FC = () => {
  const { uiStore, mapDataStore } = rootStore;
  const {
    mapButtonsTipsDelay,
    activeMapButton,
    setKeyValue,
    markers,
    isTrafficBtnGis,
  } = uiStore;
  const { mapProxy } = mapDataStore;

  const { layersState, hasIntegrationLayersGIS } = rootStore.gisDataStore;

  const { hasAccess } = rootStore.userDataStore;

  const [isTrafficLayer, setIsTrafficLayer] = useState<boolean>(
    markers.isTraffic
  );

  const [isActiveBtn, setActiveBtn] = useState(false);

  const handleIsTrafficLayer = () => {
    setActiveBtn(!isActiveBtn);
    setIsTrafficLayer(!isTrafficLayer);
  };

  let tooltipText = `${!isTrafficLayer ? 'Показать' : 'Скрыть'} дорожный затор`;
  let trafficLayerIsEmpty = true;

  layersState.forEach((layer) => {
    if (layer?.category?.category === CategoryLayerGIS.Traffic) {
      trafficLayerIsEmpty = false;

      return;
    }
  });

  const tooltipEmptyLayers = `Для выбранного региона ${
    hasIntegrationLayersGIS
      ? 'слой дорожного затора временно недоступны'
      : 'интеграция с ГИС недоступна'
  }`;

  tooltipText = trafficLayerIsEmpty ? tooltipEmptyLayers : tooltipText;

  const popOverProps: Omit<PopoverProps, 'children'> = {
    tipsDelay: mapButtonsTipsDelay,
    content: tooltipText,
    placement: PLACEMENT,
  } as const;

  useEffect(() => {
    if (isTrafficLayer) {
      setKeyValue('activeMapButton', MAP_BUTTONS_NAMES.TRAFFIC);
    }
  }, [isTrafficLayer, setKeyValue]);

  useEffect(() => {
    if (activeMapButton !== MAP_BUTTONS_NAMES.TRAFFIC) {
      setIsTrafficLayer(false);
    }
  }, [activeMapButton]);

  useEffect(() => {
    if (isActiveBtn) {
      enableTrafficLayers(mapProxy, layersState);
    } else {
      disableTrafficLayers(mapProxy, layersState);
    }
  }, [isActiveBtn, mapProxy, layersState]);

  const isAccess = hasAccess(MapButtonsCode.TrafficButton);

  if (!isAccess || !hasIntegrationLayersGIS || !isTrafficBtnGis) return null;

  return (
    <ButtonIco
      isActive={isActiveBtn}
      onClick={handleIsTrafficLayer}
      disabled={trafficLayerIsEmpty}
      popoverProps={popOverProps}
    >
      <TrafficIco />
    </ButtonIco>
  );
};

export default observer(TrafficButton);
