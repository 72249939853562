import { Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';

import rootStore from '../../../../stores/rootStore/rootStore';
import { IProcessedDataInitial } from '../../../../ts/models/signalPrograms.model';

import { getLabelsSelect } from './helpers/getLabelsSelect';

import styles from './SelectPlan.module.scss';

interface SelectPlanProps {
  programs: IProcessedDataInitial[];
}

const LABEL_SELECT = 'Выберите план: ';
const SELECT_PLACEHOLDER = 'выбор плана...';

const SelectPlan: FC<SelectPlanProps> = ({ programs }) => {
  const { selectPlanNumber, setSelectPlanNumber } = rootStore.detailedStore;
  const options = getLabelsSelect(programs);

  useEffect(() => {
    return () => {
      setSelectPlanNumber(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.container}>
      <label htmlFor="plan">{LABEL_SELECT}</label>
      <Select
        id="plan"
        className={styles.select}
        showSearch
        allowClear
        placeholder={SELECT_PLACEHOLDER}
        filterOption={(input, option) =>
          (option?.label ?? '').toString().includes(input)
        }
        onChange={(planNumber) => setSelectPlanNumber(planNumber ?? null)}
        options={options}
        value={selectPlanNumber}
      />
    </div>
  );
};

export default observer(SelectPlan);
