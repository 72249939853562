import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { FiInfo } from 'react-icons/fi';

import rootStore from '../../../../../stores/rootStore/rootStore';
import RangePicker, {
  IRangePickerProps,
} from '../../../../ui-kit/Picker/RangePicker/RangePicker';
import TimePicker, {
  TimePickerBasicProps,
} from '../../../../ui-kit/Picker/TimePicker/TimePicker';
import Popover from '../../../../ui-kit/Popover/Popover';
import BodyContent from '../../../TlManagement/PhaseHold/ModalConfirm/BodyContent/BodyContent';
import { INTERVALS, INTERVALS_NAMES } from '../../constants/constants';
import RadioButtonsGraph from '../RadioButtonsGraph/RadioButtonsGraph';

import styles from './SelectPeriodModal.module.scss';

interface ISelectPeriodModalProps {
  title: string;
  rangePickerProps: IRangePickerProps;
  timePickerProps: TimePickerBasicProps;
  standardInterval: U<INTERVALS_NAMES>;
  setStandardInterval: SetState<U<INTERVALS_NAMES>>;
  onChangeChecked: (value: boolean) => void;
}

const SelectPeriodModal: FC<ISelectPeriodModalProps> = ({
  title,
  rangePickerProps,
  timePickerProps,
  standardInterval,
  setStandardInterval,
  onChangeChecked,
}) => {
  const { interfaceTipsDelay } = rootStore.uiStore;
  const [isArbitrary, setIsArbitrary] = useState(false);

  const handleSelectInterval = (e: CheckboxChangeEvent) => {
    setIsArbitrary((prev) => !prev);
    onChangeChecked(e.target.checked);
    e.target.checked && setStandardInterval(undefined);
  };
  const handleChangeInterval = (e: any) => {
    setIsArbitrary(false);
    setStandardInterval((prev: U<INTERVALS_NAMES>) =>
      prev === e.target.value ? undefined : e.target.value
    );
  };

  const [isAnimate, setIsAnimate] = useState<boolean>(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => setIsAnimate(!!isArbitrary));

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isArbitrary]);

  useEffect(() => {
    standardInterval && setIsArbitrary(false);
  }, [standardInterval]);

  const checkboxContentStyle = classNames({
    [styles.timeCardStyle]: true,
    [styles.timeCardAnimate]: isAnimate,
  });

  const intervalNames = INTERVALS.map(({ label }) => ({
    caption: label,
    valueDt: label,
    dsc: '',
  }));

  return (
    <BodyContent
      title={
        <div className={styles.modalContent}>
          <div className={styles.containerRowModal}>
            <div className={styles.titleBlock}>
              <p>Выберите диапазон: </p>
              {title ? (
                <Popover content={title} tipsDelay={interfaceTipsDelay}>
                  <FiInfo size={20} className={styles.iconInfoBtn} />
                </Popover>
              ) : null}
            </div>
            <RangePicker
              {...rangePickerProps}
              pickerClassName={styles.picker}
            />
          </div>
          <div className={styles.containerRowModal}>
            <p>Выберите интервал: </p>
            <RadioButtonsGraph
              btnsDscArr={intervalNames}
              value={standardInterval ?? ''}
              size={'small'}
              radioContainerClassName={styles.radioGroup}
              onClick={handleChangeInterval}
            />
          </div>
          <div className={styles.containerRowModal}>
            <p>Произвольный интервал: </p>
            <Checkbox
              checked={isArbitrary}
              onChange={handleSelectInterval}
              className={styles.checkbox}
            />
          </div>
          {isArbitrary ? (
            <div className={checkboxContentStyle}>
              <TimePicker {...timePickerProps} />
            </div>
          ) : null}
        </div>
      }
      classNameContainer={styles.modalContentContainer}
    />
  );
};

export default observer(SelectPeriodModal);
