import dayjs, { Dayjs, UnitType } from 'dayjs';
import 'dayjs/locale/ru';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { getTimeWithTimeZone } from '../components/TrafficLightDetailed/TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeWithTimeZone';
import { getTimeZones } from '../components/TrafficLightDetailed/TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeZone';
import { DAY_NAMES } from '../constants/constants';

dayjs.extend(utc);
dayjs.extend(timezone);

export const formatDate = (date: number) => (date > 9 ? date : `0${date}`);

export const getDayInfo = () => {
  const { timeZoneRegion } = getTimeZones();
  const { dateFormatted, dateFormattedJS } = getTimeWithTimeZone({
    timeZone: timeZoneRegion,
  });
  const time = dateFormatted.split(', ')[1];
  const timeList = time.split(':');

  timeList.pop();

  return {
    time: timeList,
    timezone: timeZoneRegion > 0 ? `+${timeZoneRegion}` : timeZoneRegion,
    day: dateFormatted.split(', ')[0].slice(0, 5),
    dayName: DAY_NAMES[dateFormattedJS.day()],
  };
};

export const getTimeFormat = (time?: number) => {
  if (!time) {
    return '00:00';
  }

  const minutes = formatDate(Math.floor(time / 60));
  const seconds = formatDate(time - Number(minutes) * 60);

  return `${minutes}:${seconds}`;
};

export const getFormattedTimeToDayJS = (
  { h = 0, m = 0, s = 0 },
  date = dayjs(0)
) => {
  const intervalInfo = Object.entries({ h, m, s }) as [UnitType, number][];

  return intervalInfo.reduce(
    (acc: Dayjs, [time, value]) => acc.set(time, value),
    date
  );
};

export const getSecByDayJS = (time: N<Dayjs>) => {
  if (!time) return 0;

  const h = time.get('h');
  const m = time.get('m');
  const s = time.get('s');

  const sec = dayjs(0).utc().set('h', h).set('m', m).set('s', s).unix();

  return sec;
};
