export const NO_WEATHER_DATA = {
  temp: 'н/д',
  weather: '',
} as const;

export enum StyleBlock {
  InfoTop = 'infoTop',
  InfoCenter = 'infoCenter',
  InfoBottom = 'infoBottom',
}
