import { ReactElement } from 'react';
import { GoRows, GoEyeClosed } from 'react-icons/go';
import { TfiLayoutSlider } from 'react-icons/tfi';

import TimeSettings from '../TimeSettings/TimeSettings';

export enum ShowBtns {
  Slider = 'Slider',
  Rows = 'Rows',
  UnShow = 'UnShow',
}

export interface IDisplayIcons {
  id: number;
  icon: ReactElement;
  type: ShowBtns;
  tooltip: string;
}

export const SETTINGS_DT_GRAPH_ITEMS = [
  {
    label: 'Отображение времени',
    key: 'item-1',
    children: <TimeSettings />,
  },
];

export const DISPLAY_ICONS: IDisplayIcons[] = [
  {
    id: 0,
    icon: <TfiLayoutSlider />,
    type: ShowBtns.Slider,
    tooltip: 'Отображать в виде слайдера',
  },
  {
    id: 1,
    icon: <GoRows />,
    type: ShowBtns.Rows,
    tooltip: 'Отображать построчно',
  },
  { id: 2, icon: <GoEyeClosed />, type: ShowBtns.UnShow, tooltip: 'Скрыть' },
];
