import type { Payload } from 'recharts/types/component/DefaultLegendContent';

import convertDateFormat from '../../../../../../../helpers/dateHelpers/convertDateFormat';
import { DATE_FORMAT_GRAPH } from '../../../helpers/getDateRangeStr';
import { IDataGraph } from '../../../PopupDetector.model';

const FORMAT_TO_SHOW = 'DD MMM';

export const getTickValues = <T extends IDataGraph>(
  data: U<T[]>,
  payloadValue: Payload['value']
) => {
  const indexPayload = payloadValue;

  const isFirstIndex = !indexPayload;
  const isLastIndex = indexPayload === data?.length;

  const curValue = data?.[indexPayload + (isLastIndex ? -1 : 0)];
  const value = curValue?.timeFrom.toString();

  let date: N<string> = null;

  const interval = curValue?.interval;
  const isCorrectDate = interval?.length === 2;

  if (isFirstIndex && isCorrectDate) {
    date = convertDateFormat(interval[0], DATE_FORMAT_GRAPH, FORMAT_TO_SHOW);
  }
  if (isLastIndex && isCorrectDate) {
    date = convertDateFormat(interval[1], DATE_FORMAT_GRAPH, FORMAT_TO_SHOW);
  }

  return { date, value, isLastIndex };
};
