import rootStore from '../../../../../../../stores/rootStore/rootStore';
import { FilteredTypes } from '../../../../../../TrafficLightDetailed/TlDetectors/constants/constants';

export const checkIsLaneActive = (
  filterType: FilteredTypes,
  childType: number,
  parentFilter: FilteredTypes,
  parentType: U<number>
) => {
  const { filterDtData } = rootStore.detailedStore;
  const isActiveChild = !!filterDtData[filterType]?.find(
    (el) => el === childType
  );

  const isParentActive = Boolean(
    parentFilter && filterDtData[parentFilter]?.find((el) => el === parentType)
  );

  return isActiveChild && isParentActive;
};
