/* eslint-disable camelcase */
import uniqueId from 'lodash/uniqueId';

import { ICamera } from '../../../../ts/models/camera.model';
import { STREAM_ID } from '../../../Admin/AdminSystemObject/ASOTable/EditDeviceWrapper/EditDeviceForm/TurnTable/helpers/getCameraData';
import {
  VideoCameraProps,
  VideoSources,
  VideoSourcesCamera,
} from '../../models/VideoCamera.model';

interface VideoSourcesFilter {
  videoSourcesArr: VideoSourcesCamera[];
  names: string[];
  uniqIndx: number;
}

export const getVideoSources = (videoSources: VideoSources[]) => {
  const { videoSourcesArr } = videoSources.reduce(
    (acc: VideoSourcesFilter, { deviceId, playerType, name, ...rest }) => {
      const isSameName = acc.names.includes(name);

      const filteredName = isSameName ? `${name}_${++acc.uniqIndx}` : name;

      acc.names.push(filteredName);
      acc.videoSourcesArr.push({
        ...rest,
        name: filteredName,
        id: uniqueId(STREAM_ID),
      });

      return acc;
    },
    { videoSourcesArr: [], names: [], uniqIndx: 0 }
  );

  return videoSourcesArr;
};

type ReturnValue<T extends U<ICamera>> = T extends undefined
  ? undefined
  : VideoCameraProps;

export const getCameraInfo = <T extends U<ICamera>>(
  camera: T,
  isMapAction = true
): ReturnValue<T> => {
  if (!camera) return undefined as ReturnValue<T>;

  const { dai_jdt80, deviceInfo, longitude, latitude } = camera;

  const { dsc, caption, deviceId } = deviceInfo;

  const { videoSources } = dai_jdt80;

  const videoSourcesArr = getVideoSources(videoSources ?? []);

  const videoProps: VideoCameraProps = {
    videoSources: videoSourcesArr,
    dsc,
    caption,
    deviceId,
    longitude,
    latitude,
    isMapAction,
  };

  return videoProps as ReturnValue<T>;
};
