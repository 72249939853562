import { RefObject } from 'react';

export const getPopupContainer = (
  containerRef: U<RefObject<HTMLElement>>,
  status = true
) => {
  const container = containerRef?.current;

  return container && status
    ? { func: () => container, ref: containerRef }
    : undefined;
};
