import { useCallback, useEffect, useRef, useState } from 'react';

import { coordinationControlApi } from '../../../../../../api/integration/itslab/coordination/coordination';
import { ICategory } from '../../../../../../stores/channelsDataStore/channelsDataStore';
import rootStore from '../../../../../../stores/rootStore/rootStore';
import { ChanelTypes } from '../../../../../../ts/enums/enums';
import { getFormattedGroupInfo } from '../../../../../Panel/ScriptsControl/helpers/getFormattedGroupInfo';
import useChannel from '../../../../../Socket/useChannel';
import message from '../../../../../ui-kit/Notification/Notification';
import { getTimeWithTimeZone } from '../../../../TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeWithTimeZone';
import { getTimeZones } from '../../../../TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeZone';

interface GroupControlProps {
  categories: ICategory[];
  regionId: number;
  regionTlsIds: number[];
  appPath: string;
}

const useGroupsControl = ({
  categories,
  regionId,
  regionTlsIds,
  appPath,
}: GroupControlProps) => {
  const { setCoordGroups, setStartGroupInfo, fetchCoordTls } =
    rootStore.scriptsControlStore;

  const [isLoading, setIsLoading] = useState(true);
  const [timeUpdate, setTimeUpdate] = useState<string>();
  const [hasIntegration, setHasIntegration] = useState(true);

  const firstUpdate = useRef(true);

  const timeMkudUpdate = useChannel(
    categories,
    ChanelTypes.MkudTriggers,
    regionId
  );

  const fetchGroupsList = useCallback(
    async (isNeedLoading: boolean, isNeedNotifyError: boolean) => {
      isNeedLoading && setIsLoading(true);

      const groups = await coordinationControlApi.getGroupsList(appPath, {
        isNeedNotifyError,
      });

      isNeedLoading && setIsLoading(false);

      if (!groups) return setStartGroupInfo(null);

      if (groups?.status === 423) {
        setHasIntegration(false);

        return setStartGroupInfo(null);
      }

      const formattedGroups = getFormattedGroupInfo(groups, regionTlsIds);

      setCoordGroups(formattedGroups);

      const { timeZoneRegion } = getTimeZones();
      const { dateFormatted } = getTimeWithTimeZone({
        timeZone: timeZoneRegion,
      });

      setTimeUpdate(dateFormatted);
      isNeedLoading && message.success('SUCCESS_GROUPS_LIST');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setCoordGroups, setStartGroupInfo]
  );

  const fetchAllLists = useCallback(
    ({ isNeedLoading = true, isNeedNotifyError = true } = {}) => {
      fetchGroupsList(isNeedLoading, isNeedNotifyError);
      fetchCoordTls({ isNeedNotify: isNeedLoading, isNeedNotifyError });
    },
    [fetchCoordTls, fetchGroupsList]
  );

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;

      return;
    }

    fetchAllLists({ isNeedLoading: false });
  }, [fetchAllLists, timeMkudUpdate]);

  return { fetchAllLists, isLoading, timeUpdate, hasIntegration };
};

export default useGroupsControl;
