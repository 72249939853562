import { COLORS } from '../../../constants/colorsConstants';

export const STYLE_CONSTANTS = {
  WIDTH: {
    DEFAULT: 1,
  },
  COLOR: {
    DEFAULT: COLORS.MAGENTA,
    FILL: COLORS.GREY_TRANSPARENT,
  },
  RADIUS: {
    DEFAULT: 12,
    SCALE: 64,
  },
  ANGLE: {
    DEFAULT: 0,
  },
  POINTS: {
    DEFAULT: 12,
  },
  PLACEMENTS: {
    LINE: 'line',
    POINT: 'point',
  },
  TEXT_ALIGN: {
    CENTER: 'center',
    LEFT: 'left',
    TOP: 'top',
    BOTTOM: 'bottom',
  },
} as const;

export const DEFAULT_IMAGE_SCALE = 2;
export const DIRECTION_POINT_SCALE = 0.07;
export const DEFAULT_IMAGE_ANCHOR = [0.5, 0.5];

export const DIRECTION_CONSTANTS = {
  WIDTH_MAIN_LINE: 8,
  WIDTH_SECONDARY_LINE: 5,
  WIDTH_OVERPASS_LINE: 4,
  WIDTH_OVERPASS_SECONDARY_LINE: 2,
  LINE_DASH_OVERPASS_LINE: [0.2, 5],
};

export const TEMPORARY_GRAPHICS_STYLE = {
  STROKE_COLOR: COLORS.MAGENTA,
  STROKE_WIDTH: 12,
  ICON_SIZE: 15,
  FILL_COLOR: COLORS.BLUE0,
  ICON_SCALE: 0.1,
  TEXT_STROKE_COLOR: COLORS.WHITE,
  TEXT_STROKE_WIDTH: 0.1,
};

export const DEFAULT_TEXT_STYLE_PROPS = {
  FONT: 'bold 12px arial',
  FONT_SIZE: '12px',
  FONT_FAMILY: 'arial',
  FILL: {
    COLOR: COLORS.BLACK,
  },
  STROKE: {
    WIDTH: 1,
    COLOR: COLORS.WHITE,
  },
};

export const RESOLUTIONS_SCALES = [
  { resolution: 15, value: 0.5 },
  { resolution: 10, value: 0.7 },
  { resolution: 7, value: 0.95 },
  { resolution: 3, value: 1.2 },
];

export const MIN_RESOLUTION_VEHICLE_TEXT_VISIBILITY = 1;

export const STANDARD_IMAGE_ANCHOR = [0.5, 0.5];

export const VEHICLE_STYLE_PROPS = {
  BUS_SIZE: 0.26,
  DIRECTION_SIZE: 1.5,
  PLATE_SIZE: 1.7,
  LABEL_POSITION: 24,
  MAX_LABEL_LENGTH: 12,
  FILL_COLOR: '#FFF',
  STROKE_COLOR: '#000',
  BACKGROUND_COLOR: '#FFF',
  STROKE_WIDTH: 3,
  FONT: 'arial 11px',
};
