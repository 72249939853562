/* eslint-disable camelcase */

export const columnsChDir = [
  {
    title: 'Направление',
    dataIndex: 'direction',
    key: 'direction',
  },
  {
    title: 'Каналы',
    dataIndex: 'channels',
    key: 'channels',
  },
  {
    title: 'Начало',
    dataIndex: 'lenBegin',
    key: 'lenBegin',
  },
  {
    title: 'Конец',
    dataIndex: 'lenEnd',
    key: 'lenEnd',
  },
  {
    title: 'Миганий',
    dataIndex: 'flashCount',
    key: 'flashCount',
  },
];

export const columnsPhDir = [
  {
    title: 'Фаза',
    dataIndex: 'phase',
    key: 'phase',
  },
  {
    title: 'T min',
    dataIndex: 'timeMin',
    key: 'timeMin',
  },
  {
    title: 'T max',
    dataIndex: 'timeMax',
    key: 'timeMax',
  },
  {
    title: 'Направления',
    dataIndex: 'allowedDirs',
    key: 'allowedDirs',
  },
];

export const columnsSigProg = [
  {
    title: 'Фаза',
    dataIndex: 'phaseNum',
    key: 'phaseNum',
  },
  {
    title: 't от',
    dataIndex: 'lenMain',
    key: 'lenMain',
  },
  {
    title: 't пт',
    dataIndex: 'lenMid',
    key: 'lenMid',
  },
  {
    title: '%',
    dataIndex: 'ratio',
    key: 'ratio',
  },
];

export const LAST_ROW_TITLE = 'Цикл';

export const TEST_PH_DIR = [
  { min: 5, max: 60, dir: 'YYNNNYYYNY', ph_num: 1 },
  { min: 15, max: 60, dir: 'NNYYYNYYNY', ph_num: 2 },
  { min: 5, max: 60, dir: 'NYNYYYNNYN', ph_num: 3 },
];

export const TEST_CH_DIR = [
  {
    dir_num: 1,
    red: 'YNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN',
    end: 'NYNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN',
    green: 'NNYNNNNNNNNNNNNNNNNNNNNNNNNNNNNN',
    begin: 'YYNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN',
    add_gr: 0,
    add_gg: 0,
    end_len: 3,
    begin_len: 1,
    flash_count: 3,
  },
  {
    dir_num: 2,
    red: 'NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN',
    end: 'NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN',
    green: 'NNNYNNNNNNNNNNNNNNNNNNNNNNNNNNNN',
    begin: 'NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN',
    add_gr: 0,
    add_gg: 0,
    end_len: 0,
    begin_len: 0,
    flash_count: 3,
  },
  {
    dir_num: 3,
    red: 'NNNNYNNNNNNNNNNNNNNNNNNNNNNNNNNN',
    end: 'NNNNYNNNNNNNNNNNNNNNNNNNNNNNNNNN',
    green: 'NNNNNYNNNNNNNNNNNNNNNNNNNNNNNNNN',
    begin: 'NNNNYNNNNNNNNNNNNNNNNNNNNNNNNNNN',
    add_gr: 0,
    add_gg: 0,
    end_len: 0,
    begin_len: 0,
    flash_count: 3,
  },
  {
    dir_num: 4,
    red: 'NNNNNNYNNNNNNNNNNNNNNNNNNNNNNNNN',
    end: 'NNNNNNNYNNNNNNNNNNNNNNNNNNNNNNNN',
    green: 'NNNNNNNNYNNNNNNNNNNNNNNNNNNNNNNN',
    begin: 'NNNNNNYYNNNNNNNNNNNNNNNNNNNNNNNN',
    add_gr: 0,
    add_gg: 0,
    end_len: 3,
    begin_len: 1,
    flash_count: 3,
  },
  {
    dir_num: 5,
    red: 'NNNNNNNNNYNNNNNNNNNNNNNNNNNNNNNN',
    end: 'NNNNNNNNNNYNNNNNNNNNNNNNNNNNNNNN',
    green: 'NNNNNNNNNNNYNNNNNNNNNNNNNNNNNNNN',
    begin: 'NNNNNNNNNYYNNNNNNNNNNNNNNNNNNNNN',
    add_gr: 0,
    add_gg: 0,
    end_len: 3,
    begin_len: 1,
    flash_count: 3,
  },
  {
    dir_num: 6,
    red: 'NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN',
    end: 'NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN',
    green: 'NNNNNNNNNNNNYNNNNNNNNNNNNNNNNNNN',
    begin: 'NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN',
    add_gr: 0,
    add_gg: 0,
    end_len: 3,
    begin_len: 1,
    flash_count: 3,
  },
  {
    dir_num: 7,
    red: 'NNNNNNNNNNNNNYNNNNNNNNNNNNNNNNNN',
    end: 'NNNNNNNNNNNNNNYNNNNNNNNNNNNNNNNN',
    green: 'NNNNNNNNNNNNNNNYNNNNNNNNNNNNNNNN',
    begin: 'NNNNNNNNNNNNNYYNNNNNNNNNNNNNNNNN',
    add_gr: 0,
    add_gg: 0,
    end_len: 3,
    begin_len: 1,
    flash_count: 3,
  },
  {
    dir_num: 8,
    red: 'NNNNNNNNNNNNNNNNYNNNNNNNNNNNNNNN',
    end: 'NNNNNNNNNNNNNNNNNYNNNNNNNNNNNNNN',
    green: 'NNNNNNNNNNNNNNNNNNYNNNNNNNNNNNNN',
    begin: 'NNNNNNNNNNNNNNNNYYNNNNNNNNNNNNNN',
    add_gr: 0,
    add_gg: 0,
    end_len: 3,
    begin_len: 1,
    flash_count: 3,
  },
  {
    dir_num: 9,
    red: 'NNNNNNNNNNNNNNNNNNNYNNNNNNNNNNNN',
    end: 'NNNNNNNNNNNNNNNNNNNNYNNNNNNNNNNN',
    green: 'NNNNNNNNNNNNNNNNNNNNNYNNNNNNNNNN',
    begin: 'NNNNNNNNNNNNNNNNNNNYYNNNNNNNNNNN',
    add_gr: 0,
    add_gg: 0,
    end_len: 3,
    begin_len: 1,
    flash_count: 3,
  },
  {
    dir_num: 10,
    red: 'NNNNNNNNNNNNNNNNNNNNNNYYYNNNNNNN',
    end: 'NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN',
    green: 'NNNNNNNNNNNNNNNNNNNNNNNYNYNNNNNN',
    begin: 'NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN',
    add_gr: 0,
    add_gg: 0,
    end_len: 0,
    begin_len: 0,
    flash_count: 0,
  },
];

export const TEST_SIG_PROGS = [
  {
    num: 1,
    len: 108,
    len_pt: [8, 8, 8],
    len_ph: [32, 20, 32],
    ph: [1, 2, 3],
    tacts: 3,
  },
  {
    num: 2,
    len: 108,
    len_pt: [8, 8, 8],
    len_ph: [32, 20, 32],
    ph: [1, 3, 2],
    tacts: 3,
  },
  {
    num: 3,
    len: 108,
    len_pt: [8, 8, 8],
    len_ph: [32, 20, 32],
    ph: [3, 1, 2],
    tacts: 3,
  },
];

/*
 * ** Demo-template-array-[dispshedule]-list-full-09-09-2022:
 * ** http://185.191.215.133:8088/api/1.0/json/dispshedule?list=full
 */

export const TEST_DISPSHEDULE = [
  {
    tasks: [],
    TimeOn: '2023-07-04 23:27:06 +0300',
    type: 6,
    guid: '30D478DB-C410-4A1A-9F2B-A36257921918',
    TimeOff: '2023-07-04 23:53:46 +0300',
    objs: [{ cmd: 0, id: 4097, command: 0 }],
    caption: 'Тест времени',
  },
  {
    tasks: [],
    TimeOn: '2022-07-05 00:00:00 +0300',
    type: 3,
    guid: 'B2EF5505-89DD-4F93-AE4D-CE5469E7DDDD',
    TimeOff: '2022-07-05 03:00:00 +0300',
    objs: [{ cmd: 2, id: 4097, command: 2 }],
    caption: 'Test',
  },
  {
    tasks: [],
    TimeOn: '2022-07-25 11:03:14 +0300',
    type: 0,
    guid: '8950A7F6-5C3F-45E2-AC3F-501D0EBA4D4C',
    TimeOff: '2022-07-25 11:03:14 +0300',
    objs: [{ cmd: 2, id: 4097, command: 2 }],
    caption: '123123123',
  },
  {
    tasks: [],
    TimeOn: '2022-07-25 11:06:13 +0300',
    type: 0,
    guid: '5FB51E1C-4EF2-440B-A917-EE52BE9C4648',
    TimeOff: '2022-07-25 11:06:13 +0300',
    objs: [{ cmd: 2, id: 4097, command: 2 }],
    caption: 'test123',
  },
  {
    tasks: [],
    TimeOn: '2022-05-27 01:58:21 +0300',
    type: 1,
    guid: '9A2F20A0-3B5C-4B16-82A7-A597E4F14828',
    TimeOff: '2022-05-27 01:58:21 +0300',
    objs: [{ cmd: 2, id: 4220, command: 2 }],
    caption: 'РЅР°Р·РІР°РЅРёРµ',
  },
  {
    tasks: [],
    TimeOn: '2022-07-25 11:28:20 +0300',
    type: 0,
    guid: '37908BD8-A91E-4FD1-97CE-6F465684F457',
    TimeOff: '2022-07-25 11:28:20 +0300',
    objs: [{ cmd: 2, id: 4097, command: 2 }],
    caption: '123123123',
  },
  {
    tasks: [],
    TimeOn: '2022-07-25 11:46:44 +0300',
    type: 0,
    guid: 'E85B2F10-442B-40C5-AEA7-87479B3585A3',
    TimeOff: '2022-07-25 11:46:44 +0300',
    objs: [{ cmd: 2, id: 4097, command: 2 }],
    caption: '',
  },
  {
    tasks: [],
    TimeOn: '2022-07-25 11:46:46 +0300',
    type: 0,
    guid: 'A60221C9-08EE-4B8A-A664-6F2726C28AB4',
    TimeOff: '2022-07-25 11:46:46 +0300',
    objs: [{ cmd: 2, id: 4097, command: 2 }],
    caption: '',
  },
  {
    tasks: [],
    TimeOn: '2022-07-25 11:47:18 +0300',
    type: 0,
    guid: '48DC5072-25E6-45F1-9E83-BD5127ECA9B4',
    TimeOff: '2022-07-25 11:47:18 +0300',
    objs: [{ cmd: 2, id: 4097, command: 2 }],
    caption: '',
  },
  {
    tasks: [],
    TimeOn: '2022-07-25 12:18:27 +0300',
    type: 0,
    guid: '8F36940D-EE3D-4E39-818E-63EA148A5327',
    TimeOff: '2022-07-25 12:18:27 +0300',
    objs: [{ cmd: 2, id: 4112, command: 2 }],
    caption: '123',
  },
  {
    tasks: [],
    TimeOn: '2022-07-26 07:06:29 +0300',
    type: 0,
    guid: 'F66865C9-3E2C-46A9-83A8-E7594A6C4CFD',
    TimeOff: '2022-07-26 07:06:29 +0300',
    objs: [{ cmd: 128, id: 4112, command: 128 }],
    caption: '123',
  },
  {
    tasks: [],
    TimeOn: '2022-07-26 07:08:56 +0300',
    type: 0,
    guid: '1FD6E025-3B70-4590-A1A3-6D33C29350D1',
    TimeOff: '2022-07-26 07:08:56 +0300',
    objs: [{ cmd: 2, id: 4097, command: 2 }],
    caption: 'test777',
  },
  {
    tasks: [],
    TimeOn: '2022-07-26 07:11:34 +0300',
    type: 0,
    guid: 'EFE2E210-C118-42A4-8190-98FC73841042',
    TimeOff: '2022-07-26 07:11:34 +0300',
    objs: [{ cmd: 64, id: 4112, command: 64 }],
    caption: 'Тестовое расп-е',
  },
  {
    tasks: [],
    TimeOn: '2022-07-26 07:21:14 +0300',
    type: 1,
    guid: '4FB3E63B-0B31-42D9-9563-206FCE07E744',
    TimeOff: '2022-07-26 07:21:14 +0300',
    objs: [{ cmd: 64, id: 4112, command: 64 }],
    caption: 'тест 1 СО',
  },
  {
    tasks: [],
    TimeOn: '2022-07-26 07:16:01 +0300',
    type: 0,
    guid: 'AC48F746-C99C-4C7C-B335-142AF2E3FC5A',
    TimeOff: '2022-07-26 07:16:01 +0300',
    objs: [
      { cmd: 64, id: 4112, command: 64 },
      { cmd: 64, id: 4099, command: 64 },
    ],
    caption: '123',
  },
  {
    tasks: [],
    TimeOn: '2022-07-26 07:54:51 +0300',
    type: 1,
    guid: 'F1862E62-0BA1-4C4A-AE31-ABCEF738D66A',
    TimeOff: '2022-07-26 07:54:51 +0300',
    objs: [
      { cmd: 2, id: 4104, command: 2 },
      { cmd: 2, id: 4109, command: 2 },
    ],
    caption: 'Тестовое расписание',
  },
  {
    tasks: [],
    TimeOn: '2022-07-26 08:00:56 +0300',
    type: 0,
    guid: '727862FC-78BE-4FE3-809C-DC6688BE3904',
    TimeOff: '2022-07-26 08:00:56 +0300',
    objs: [
      { cmd: 128, id: 4097, command: 128 },
      { cmd: 128, id: 4109, command: 128 },
    ],
    caption: 'SO',
  },
  {
    tasks: [],
    TimeOn: '2022-07-28 10:27:02 +0300',
    type: 1,
    guid: '8B637A03-D6EA-4FEC-9FE9-7E539B4BDDEA',
    TimeOff: '2022-07-28 10:27:02 +0300',
    objs: [
      { cmd: 2, id: 4097, command: 2 },
      { cmd: 3, id: 4098, command: 3 },
    ],
    caption: 'TEST TEST',
  },
  {
    tasks: [],
    TimeOn: '2022-07-28 16:25:24 +0300',
    type: 1,
    guid: '7D216BAB-3EB5-449C-A25C-A63A7EEB8536',
    TimeOff: '2022-07-28 16:25:24 +0300',
    objs: [
      { cmd: 2, id: 4104, command: 2 },
      { cmd: 3, id: 4109, command: 3 },
    ],
    caption: 'TEST1 TEST1',
  },
  {
    tasks: [],
    TimeOn: '2022-07-28 16:25:24 +0300',
    type: 1,
    guid: '4C362293-80D8-461F-BC3B-495844368CA4',
    TimeOff: '2022-07-28 16:25:24 +0300',
    objs: [
      { cmd: 2, id: 4097, command: 2 },
      { cmd: 3, id: 4109, command: 3 },
    ],
    caption: 'TEST2 TEST2',
  },
  {
    tasks: [],
    TimeOn: '2022-07-28 16:41:20 +0300',
    type: 1,
    guid: 'F0125B06-FC0B-4FC3-A7FE-DD5566C5936C',
    TimeOff: '2022-07-28 16:41:20 +0300',
    objs: [
      { cmd: 2, id: 4104, command: 2 },
      { cmd: 3, id: 4109, command: 3 },
    ],
    caption: 'TEST1 TEST1',
  },
  {
    tasks: [],
    TimeOn: '2022-07-28 16:41:20 +0300',
    type: 1,
    guid: '1ACDDB86-F145-4109-B708-A2B027524F04',
    TimeOff: '2022-07-28 16:41:20 +0300',
    objs: [
      { cmd: 2, id: 4097, command: 2 },
      { cmd: 3, id: 4109, command: 3 },
    ],
    caption: 'TEST2 TEST2',
  },
  {
    tasks: [],
    TimeOn: '2022-07-28 16:42:28 +0300',
    type: 1,
    guid: '86C5A6AF-DA5E-408A-937B-C9B71105D6B2',
    TimeOff: '2022-07-28 16:42:28 +0300',
    objs: [
      { cmd: 2, id: 4104, command: 2 },
      { cmd: 3, id: 4109, command: 3 },
    ],
    caption: 'TEST1 TEST1',
  },
  {
    tasks: [],
    TimeOn: '2022-07-28 16:42:28 +0300',
    type: 1,
    guid: 'D373AB0D-D28E-4B2A-9280-E589276840BB',
    TimeOff: '2022-07-28 16:42:28 +0300',
    objs: [
      { cmd: 2, id: 4097, command: 2 },
      { cmd: 3, id: 4109, command: 3 },
    ],
    caption: 'TEST2 TEST2',
  },
  {
    tasks: [],
    TimeOn: '2022-07-28 16:48:47 +0300',
    type: 1,
    guid: 'F408DA72-DF4C-4453-9202-C93AA50455A0',
    TimeOff: '2022-07-28 16:48:47 +0300',
    objs: [
      { cmd: 2, id: 4097, command: 2 },
      { cmd: 3, id: 4098, command: 3 },
    ],
    caption: 'TEST22 TEST22',
  },
  {
    tasks: [],
    TimeOn: '2022-07-29 06:59:19 +0300',
    type: 1,
    guid: 'CFC6349F-FB19-46DB-8004-6CBCBFE28825',
    TimeOff: '2022-07-29 06:59:19 +0300',
    objs: [
      { cmd: 2, id: 4097, command: 2 },
      { cmd: 3, id: 4098, command: 3 },
    ],
    caption: 'TEST2200 TEST2200',
  },
  {
    tasks: [],
    TimeOn: '2022-05-01 01:01:41 +0300',
    type: 1,
    guid: '976D536E-5F64-41E7-AB6A-2F3B672FDE42',
    TimeOff: '2022-05-08 05:51:41 +0300',
    objs: [
      { cmd: 2, id: 4097, command: 2 },
      { cmd: 3, id: 4098, command: 3 },
    ],
    caption: 'TEST2200 TEST2200',
  },
  {
    tasks: [],
    TimeOn: '2022-07-28 16:48:47 +0300',
    type: 1,
    guid: '3B62FF0E-0D9C-4ECF-8581-C5967A3F713E',
    TimeOff: '2022-07-28 16:48:47 +0300',
    objs: [
      { cmd: 2, id: 4174, command: 2 },
      { cmd: 3, id: 4109, command: 3 },
      { cmd: 3, id: 4097, command: 3 },
    ],
    caption: 'TEST11 TEST11',
  },
  {
    tasks: [],
    TimeOn: '2022-07-29 06:59:19 +0300',
    type: 1,
    guid: 'B44C4A5B-394F-443B-A44F-4A554063A5CC',
    TimeOff: '2022-07-29 06:59:19 +0300',
    objs: [
      { cmd: 2, id: 4174, command: 2 },
      { cmd: 3, id: 4109, command: 3 },
      { cmd: 3, id: 4097, command: 3 },
    ],
    caption: 'TEST1100 TEST1100',
  },
  {
    tasks: [],
    TimeOn: '2022-05-01 01:01:41 +0300',
    type: 1,
    guid: '54EF43A5-D88D-4C4A-BD92-517B30A898F8',
    TimeOff: '2022-05-08 05:51:41 +0300',
    objs: [
      { cmd: 2, id: 4174, command: 2 },
      { cmd: 3, id: 4109, command: 3 },
      { cmd: 3, id: 4097, command: 3 },
    ],
    caption: 'TEST1100 TEST1100',
  },
];

export const columnsDailyShedules = [
  {
    title: 'Тип',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Начало',
    dataIndex: 'timeOn',
    key: 'timeOn',
  },
  {
    title: 'Окончание',
    dataIndex: 'timeOff',
    key: 'timeOff',
  },
  {
    title: 'Программа',
    dataIndex: 'programs',
    key: 'programs',
  },
  {
    title: 'Описание',
    dataIndex: 'caption',
    key: 'caption',
  },
];

export enum TlJournalType {
  Control = 'commands',
  Status = 'status',
  Service = 'control',
}

export const TlJournalName: Record<TlJournalType, string> = {
  commands: 'Журнал управления',
  status: 'Журнал состояния',
  control: 'Журнал служебных сообщений',
};

export const TEST_STATUS_JOURNAL = {
  code: 200,
  updated_at: '2022-09-19 13:08:29 +0300',
  events: [],
  result: {
    op: 'Get',
    note: '',
    code: 207,
  },
  type: 'object',
  subtype: 'journal',
  journal: [
    {
      fields: ['date-time', 'source', 'state', 'ast', 'phase', 'flags'],
      id: '4097',
      data: [
        ['2022-06-14 12:06:32', 254, 254, 0, 0, 32784],
        ['2022-06-14 12:07:28', 3, 3, 128, 0, 32785],
        ['2022-06-14 14:23:51', 6, 3, 128, 0, 35856],
        ['2022-06-14 14:25:11', 3, 3, 128, 0, 33808],
        ['2022-06-15 17:04:44', 3, 3, 182, 0, 33808],
        ['2022-06-15 17:05:10', 3, 3, 128, 0, 33808],
        ['2022-06-16 04:08:21', 3, 3, 182, 0, 33808],
        ['2022-06-16 04:09:01', 3, 3, 128, 0, 33808],
        ['2022-06-17 00:56:06', 3, 3, 182, 0, 33808],
        ['2022-06-17 00:56:21', 3, 3, 128, 0, 33808],
        ['2022-06-17 05:52:53', 3, 3, 182, 0, 33808],
        ['2022-06-17 05:53:21', 3, 3, 128, 0, 33808],
        ['2022-06-17 10:01:31', 0, 244, 0, 0, 32784],
        ['2022-06-17 10:02:49', 6, 3, 128, 0, 35856],
        ['2022-06-17 10:03:10', 3, 3, 128, 0, 33808],
        ['2022-06-17 13:38:29', 254, 254, 0, 0, 32784],
        ['2022-06-17 13:39:37', 3, 3, 128, 0, 33808],
        ['2022-06-17 16:42:38', 254, 254, 0, 0, 32784],
        ['2022-06-17 16:43:25', 3, 3, 128, 0, 33808],
        ['2022-06-17 16:46:29', 254, 254, 0, 0, 32784],
        ['2022-06-17 16:47:07', 3, 3, 128, 0, 33808],
        ['2022-06-17 17:22:42', 254, 254, 0, 0, 32784],
        ['2022-06-17 17:44:06', 6, 3, 128, 0, 33808],
        ['2022-06-17 17:45:11', 3, 3, 128, 0, 33808],
        ['2022-06-17 17:50:29', 254, 254, 0, 0, 32784],
        ['2022-06-17 17:51:54', 3, 3, 128, 0, 33808],
        ['2022-06-17 17:54:57', 3, 3, 128, 0, 33808],
        ['2022-06-17 17:56:29', 254, 254, 0, 0, 32784],
        ['2022-06-17 18:00:59', 3, 3, 128, 0, 33808],
        ['2022-06-17 18:04:29', 254, 254, 0, 0, 32784],
        ['2022-06-17 18:17:05', 6, 3, 128, 0, 33808],
        ['2022-06-17 18:20:35', 6, 3, 128, 0, 33808],
        ['2022-06-17 18:22:29', 254, 254, 0, 0, 32784],
        ['2022-06-17 18:26:47', 6, 3, 128, 0, 33808],
        ['2022-06-17 18:28:06', 254, 254, 0, 0, 32784],
        ['2022-06-17 18:29:46', 6, 3, 128, 0, 33808],
        ['2022-06-17 18:31:11', 3, 3, 128, 0, 33808],
        ['2022-06-17 18:34:31', 3, 3, 128, 0, 33808],
        ['2022-06-17 18:36:29', 254, 254, 0, 0, 32784],
        ['2022-06-17 18:37:39', 3, 3, 128, 0, 33808],
        ['2022-06-17 19:26:36', 254, 254, 0, 0, 32784],
        ['2022-06-17 19:27:40', 3, 3, 128, 0, 33808],
        ['2022-06-17 19:30:29', 254, 254, 0, 0, 32784],
        ['2022-06-17 19:31:49', 3, 3, 128, 0, 33808],
        ['2022-06-17 19:34:29', 254, 254, 0, 0, 32784],
        ['2022-06-17 19:38:02', 3, 3, 128, 0, 33808],
        ['2022-06-17 19:45:48', 254, 254, 0, 0, 32784],
        ['2022-06-17 19:47:27', 3, 3, 128, 0, 33808],
        ['2022-06-17 19:50:41', 3, 3, 128, 0, 33808],
        ['2022-06-17 20:28:53', 254, 254, 0, 0, 32784],
        ['2022-06-17 20:29:39', 3, 3, 128, 0, 33808],
        ['2022-06-17 21:04:29', 254, 254, 0, 0, 32784],
        ['2022-06-17 21:05:42', 3, 3, 128, 0, 33808],
        ['2022-06-17 21:14:29', 254, 254, 0, 0, 32784],
        ['2022-06-17 21:15:10', 3, 3, 128, 0, 33808],
        ['2022-06-19 05:08:07', 3, 3, 182, 0, 33808],
        ['2022-06-19 05:08:22', 3, 3, 128, 0, 33808],
        ['2022-06-19 08:40:32', 254, 254, 0, 0, 32784],
        ['2022-06-19 08:41:26', 3, 3, 128, 0, 33808],
        ['2022-06-19 21:02:31', 254, 254, 0, 0, 32784],
        ['2022-06-19 21:03:38', 3, 3, 128, 0, 33808],
        ['2022-06-19 21:55:24', 3, 3, 182, 0, 33808],
        ['2022-06-19 21:55:44', 3, 3, 128, 0, 33808],
        ['2022-06-20 00:58:34', 3, 3, 182, 0, 33808],
        ['2022-06-20 00:58:56', 3, 3, 128, 0, 33808],
        ['2022-06-20 07:31:39', 3, 3, 128, 0, 33808],
        ['2022-06-20 13:01:10', 3, 3, 182, 0, 33808],
        ['2022-06-20 13:01:21', 3, 3, 128, 0, 33808],
        ['2022-06-21 23:32:49', 3, 3, 182, 0, 33808],
        ['2022-06-21 23:33:05', 3, 3, 128, 0, 33808],
        ['2022-06-22 03:49:27', 254, 254, 0, 0, 32784],
        ['2022-06-22 04:12:07', 6, 3, 128, 0, 33808],
        ['2022-06-22 04:16:12', 3, 3, 128, 0, 33808],
        ['2022-06-22 04:29:21', 3, 3, 182, 0, 33808],
        ['2022-06-22 04:29:52', 3, 3, 128, 0, 33808],
        ['2022-06-23 19:04:40', 3, 3, 128, 0, 33808],
        ['2022-06-23 23:58:55', 3, 3, 128, 0, 35856],
        ['2022-06-24 02:43:45', 3, 3, 128, 0, 35856],
        ['2022-06-24 02:55:48', 3, 3, 182, 0, 33808],
        ['2022-06-24 02:55:57', 3, 3, 128, 0, 33808],
        ['2022-06-24 20:41:05', 3, 3, 182, 0, 33808],
        ['2022-06-24 20:41:11', 3, 3, 128, 0, 33808],
        ['2022-06-25 15:15:03', 3, 3, 128, 0, 35858],
        ['2022-06-25 16:37:52', 246, 246, 0, 0, 0],
        ['2022-06-25 16:42:53', 3, 3, 128, 66, 0],
        ['2022-06-25 16:42:55', 6, 3, 128, 66, 0],
        ['2022-06-25 16:46:04', 3, 3, 128, 0, 33810],
        ['2022-06-25 21:42:36', 254, 254, 0, 0, 32786],
        ['2022-06-25 21:43:11', 3, 3, 128, 0, 33810],
        ['2022-06-25 21:48:29', 254, 254, 0, 0, 32786],
        ['2022-06-25 21:49:37', 3, 3, 128, 0, 33810],
        ['2022-06-26 01:20:29', 3, 3, 182, 0, 33810],
        ['2022-06-26 01:20:45', 3, 3, 128, 0, 33810],
        ['2022-06-26 04:21:25', 254, 254, 0, 0, 32786],
        ['2022-06-26 04:22:29', 3, 3, 128, 0, 33810],
        ['2022-06-27 00:14:07', 3, 3, 182, 0, 33810],
        ['2022-06-27 00:14:25', 3, 3, 128, 0, 33810],
        ['2022-06-27 05:36:41', 3, 3, 182, 0, 33810],
        ['2022-06-27 05:37:09', 3, 3, 128, 0, 33810],
        ['2022-06-27 14:17:10', 3, 3, 182, 0, 33810],
        ['2022-06-27 14:17:16', 3, 3, 128, 0, 33810],
        ['2022-06-28 15:28:51', 0, 244, 0, 0, 32786],
        ['2022-06-28 15:29:01', 6, 3, 128, 0, 35858],
        ['2022-06-28 15:29:11', 3, 3, 128, 0, 33810],
        ['2022-06-29 00:26:06', 3, 3, 182, 0, 33810],
        ['2022-06-29 00:26:21', 3, 3, 128, 0, 33810],
        ['2022-06-29 05:52:14', 3, 3, 128, 0, 33810],
        ['2022-06-29 18:14:49', 3, 3, 182, 0, 33810],
        ['2022-06-29 18:15:10', 3, 3, 128, 0, 33810],
        ['2022-06-30 12:37:52', 3, 2, 128, 0, 33890],
        ['2022-06-30 12:37:57', 6, 3, 128, 0, 32866],
        ['2022-06-30 12:38:05', 3, 3, 128, 0, 33810],
        ['2022-06-30 12:38:19', 3, 3, 128, 0, 32867],
        ['2022-06-30 17:22:53', 246, 246, 0, 0, 0],
        ['2022-06-30 17:23:20', 3, 3, 128, 0, 39952],
        ['2022-06-30 22:39:30', 254, 254, 0, 0, 32784],
        ['2022-07-01 10:42:32', 3, 3, 128, 0, 35856],
        ['2022-07-01 10:43:10', 3, 3, 128, 0, 32785],
        ['2022-07-12 13:46:57', 6, 3, 128, 0, 35858],
        ['2022-07-12 13:47:11', 3, 3, 128, 0, 33810],
        ['2022-07-12 13:56:23', 6, 3, 128, 0, 33810],
        ['2022-07-12 13:57:11', 3, 3, 128, 0, 33810],
        ['2022-07-12 14:00:29', 254, 254, 0, 0, 32786],
        ['2022-07-12 14:01:54', 6, 3, 128, 0, 33810],
        ['2022-07-12 14:03:12', 3, 3, 128, 0, 33810],
        ['2022-07-12 16:35:01', 6, 3, 128, 0, 32866],
        ['2022-07-12 16:47:18', 246, 246, 0, 0, 0],
        ['2022-07-12 16:47:44', 3, 3, 128, 0, 33810],
        ['2022-07-12 16:50:03', 6, 3, 128, 0, 32864],
        ['2022-07-12 16:50:10', 6, 3, 128, 0, 35938],
        ['2022-07-12 17:30:01', 3, 3, 128, 0, 33810],
        ['2022-07-12 17:40:01', 6, 3, 128, 0, 32866],
        ['2022-07-12 17:41:02', 3, 3, 128, 0, 33810],
        ['2022-07-12 17:45:07', 6, 3, 128, 0, 32866],
        ['2022-07-12 17:50:03', 3, 3, 128, 0, 33810],
        ['2022-07-12 18:00:00', 6, 3, 128, 0, 32866],
        ['2022-07-15 17:52:13', 3, 2, 128, 0, 33890],
        ['2022-07-15 17:52:23', 3, 2, 128, 0, 32867],
        ['2022-07-15 17:52:29', 3, 3, 128, 0, 33810],
        ['2022-07-16 00:45:09', 3, 3, 182, 0, 33810],
        ['2022-07-16 00:45:37', 3, 3, 128, 0, 33810],
        ['2022-07-16 15:16:31', 254, 254, 0, 0, 32786],
        ['2022-07-16 15:18:08', 3, 3, 128, 0, 33810],
        ['2022-07-16 15:20:29', 254, 254, 0, 0, 32786],
        ['2022-07-16 15:21:12', 3, 3, 128, 0, 33810],
        ['2022-07-16 20:26:25', 3, 3, 182, 0, 33810],
        ['2022-07-16 20:26:40', 3, 3, 128, 0, 33810],
        ['2022-07-19 05:18:01', 3, 3, 182, 0, 33810],
        ['2022-07-19 05:18:12', 3, 3, 128, 0, 33810],
        ['2022-07-20 09:22:00', 3, 3, 182, 0, 33810],
        ['2022-07-20 09:22:10', 3, 3, 128, 0, 33810],
        ['2022-07-20 11:21:56', 6, 3, 128, 0, 32866],
        ['2022-07-20 13:59:13', 3, 3, 128, 0, 33890],
        ['2022-07-20 13:59:19', 3, 3, 128, 0, 32867],
        ['2022-07-20 14:20:23', 3, 3, 128, 0, 35938],
        ['2022-07-20 14:20:34', 3, 3, 128, 0, 32867],
        ['2022-07-20 14:35:20', 3, 3, 128, 0, 35938],
        ['2022-07-20 14:35:30', 3, 3, 128, 0, 32867],
        ['2022-07-20 14:44:19', 3, 3, 128, 0, 35938],
        ['2022-07-20 14:44:29', 3, 3, 128, 0, 32867],
        ['2022-07-20 14:53:32', 6, 3, 128, 0, 32866],
        ['2022-07-20 15:17:28', 3, 3, 128, 0, 33808],
        ['2022-07-20 15:18:49', 3, 3, 128, 0, 32787],
        ['2022-07-20 15:21:52', 3, 3, 128, 0, 35856],
        ['2022-07-20 15:21:57', 3, 3, 128, 0, 32787],
        ['2022-07-20 15:26:56', 3, 3, 128, 0, 32785],
        ['2022-07-20 15:37:25', 3, 3, 128, 0, 35856],
        ['2022-07-20 15:37:44', 3, 3, 128, 0, 32785],
        ['2022-07-20 15:53:45', 3, 3, 128, 0, 35856],
        ['2022-07-20 15:53:56', 3, 3, 128, 0, 32785],
        ['2022-07-20 16:13:19', 3, 3, 128, 0, 35856],
        ['2022-07-20 16:13:43', 3, 3, 128, 0, 32785],
        ['2022-07-20 16:23:01', 3, 3, 128, 0, 35856],
        ['2022-07-20 16:23:12', 3, 3, 128, 0, 32785],
        ['2022-07-20 17:08:39', 3, 3, 128, 0, 35858],
        ['2022-07-20 17:08:52', 3, 3, 128, 0, 32787],
        ['2022-07-20 17:20:38', 3, 3, 128, 0, 35858],
        ['2022-07-20 17:20:48', 3, 3, 128, 0, 32787],
        ['2022-07-20 18:10:06', 3, 3, 128, 0, 32787],
        ['2022-07-20 18:16:22', 3, 3, 128, 0, 34835],
        ['2022-07-20 19:07:01', 3, 3, 128, 0, 33810],
        ['2022-07-20 19:07:08', 3, 3, 128, 0, 32787],
        ['2022-07-20 19:18:48', 3, 3, 128, 0, 35858],
        ['2022-07-20 19:19:01', 3, 3, 128, 0, 32787],
        ['2022-07-20 19:56:40', 3, 3, 128, 0, 35858],
        ['2022-07-20 19:57:24', 3, 3, 128, 0, 32787],
        ['2022-07-20 20:22:25', 3, 3, 128, 0, 35858],
        ['2022-07-20 20:22:36', 3, 3, 128, 0, 32787],
        ['2022-07-20 20:28:40', 3, 3, 128, 0, 32787],
        ['2022-07-20 20:49:15', 3, 3, 128, 0, 35858],
        ['2022-07-20 20:49:36', 3, 3, 128, 0, 32787],
        ['2022-07-20 22:00:00', 3, 3, 128, 0, 33810],
        ['2022-07-20 22:02:20', 3, 3, 128, 0, 32787],
        ['2022-07-20 22:08:41', 3, 3, 128, 0, 35858],
        ['2022-07-20 22:09:04', 3, 3, 128, 0, 32787],
        ['2022-07-20 22:45:22', 3, 3, 128, 0, 35858],
        ['2022-07-20 22:45:32', 3, 3, 128, 0, 32787],
        ['2022-07-21 00:07:50', 3, 3, 128, 0, 35858],
        ['2022-07-21 00:07:53', 3, 3, 128, 0, 32787],
        ['2022-07-21 00:08:22', 3, 3, 128, 0, 33810],
        ['2022-07-21 00:09:01', 3, 3, 128, 0, 32787],
        ['2022-07-21 00:13:42', 3, 3, 128, 0, 35858],
        ['2022-07-21 00:13:46', 3, 3, 128, 0, 32787],
        ['2022-07-21 00:49:38', 3, 3, 128, 0, 35858],
        ['2022-07-21 00:49:45', 3, 3, 128, 0, 32787],
        ['2022-07-21 01:32:05', 3, 3, 128, 0, 35858],
        ['2022-07-21 01:32:10', 3, 3, 128, 0, 32787],
        ['2022-07-21 01:39:05', 3, 3, 128, 0, 32787],
        ['2022-07-21 01:44:23', 3, 3, 128, 0, 32787],
        ['2022-07-21 02:00:48', 3, 3, 128, 0, 33810],
        ['2022-07-21 02:00:52', 3, 3, 128, 0, 32787],
        ['2022-07-21 02:19:35', 3, 3, 128, 0, 35858],
        ['2022-07-21 02:19:40', 3, 3, 128, 0, 32787],
        ['2022-07-21 02:40:27', 3, 3, 128, 0, 32787],
        ['2022-07-21 02:45:52', 3, 3, 128, 0, 35858],
        ['2022-07-21 02:46:24', 3, 3, 128, 0, 32787],
        ['2022-07-21 02:50:30', 3, 3, 128, 0, 35858],
        ['2022-07-21 02:50:59', 3, 3, 128, 0, 32787],
        ['2022-07-21 02:55:44', 3, 3, 128, 0, 33810],
        ['2022-07-21 02:56:23', 3, 3, 128, 0, 32787],
        ['2022-07-21 03:10:10', 3, 3, 128, 0, 32787],
        ['2022-07-21 03:14:47', 3, 3, 128, 0, 35858],
        ['2022-07-21 03:15:05', 3, 3, 128, 0, 32787],
        ['2022-07-21 03:39:37', 3, 3, 128, 0, 32787],
        ['2022-07-21 04:07:08', 3, 3, 128, 0, 35858],
        ['2022-07-21 04:07:16', 3, 3, 128, 0, 32787],
        ['2022-07-21 04:41:57', 3, 3, 128, 0, 35858],
        ['2022-07-21 04:42:12', 3, 3, 128, 0, 32787],
        ['2022-07-21 04:51:50', 3, 3, 128, 0, 35858],
        ['2022-07-21 04:52:06', 3, 3, 128, 0, 32787],
        ['2022-07-21 05:24:41', 3, 3, 128, 0, 32787],
        ['2022-07-21 05:35:58', 3, 3, 128, 0, 35858],
        ['2022-07-21 05:36:37', 3, 3, 128, 0, 32787],
        ['2022-07-21 05:54:30', 3, 3, 128, 0, 35858],
        ['2022-07-21 05:54:37', 3, 3, 128, 0, 32787],
        ['2022-07-21 06:00:03', 3, 3, 128, 0, 33810],
        ['2022-07-21 06:01:45', 3, 3, 128, 0, 32787],
        ['2022-07-21 06:11:13', 3, 3, 128, 0, 32787],
        ['2022-07-21 06:23:51', 3, 3, 128, 0, 35858],
        ['2022-07-21 06:24:09', 3, 3, 128, 0, 32787],
        ['2022-07-21 06:50:48', 3, 3, 128, 0, 32787],
        ['2022-07-21 07:18:35', 3, 3, 128, 0, 32787],
        ['2022-07-21 07:24:42', 3, 3, 128, 0, 32787],
        ['2022-07-21 08:14:17', 3, 3, 128, 0, 32787],
        ['2022-07-21 08:20:42', 3, 3, 128, 0, 35858],
        ['2022-07-21 08:20:49', 3, 3, 128, 0, 32787],
        ['2022-07-21 08:39:19', 3, 3, 128, 0, 35858],
        ['2022-07-21 08:39:29', 3, 3, 128, 0, 32787],
        ['2022-07-21 09:10:36', 3, 3, 128, 0, 35858],
        ['2022-07-21 09:10:43', 3, 3, 128, 0, 32787],
        ['2022-07-21 09:16:32', 3, 3, 128, 0, 35858],
        ['2022-07-21 09:16:35', 3, 3, 128, 0, 32787],
        ['2022-07-21 09:53:57', 3, 3, 128, 0, 32787],
        ['2022-07-21 10:06:08', 3, 3, 128, 0, 35858],
        ['2022-07-21 10:06:32', 3, 3, 128, 0, 32787],
        ['2022-07-21 10:11:49', 3, 3, 128, 0, 32787],
        ['2022-07-21 10:18:13', 3, 3, 128, 0, 35858],
        ['2022-07-21 10:18:28', 3, 3, 128, 0, 32787],
        ['2022-07-21 11:05:31', 3, 3, 128, 0, 35858],
        ['2022-07-21 11:05:56', 3, 3, 128, 0, 32787],
        ['2022-07-21 11:26:35', 3, 3, 128, 0, 35858],
        ['2022-07-21 11:26:52', 3, 3, 128, 0, 32787],
        ['2022-07-21 12:07:21', 3, 3, 128, 0, 34835],
        ['2022-07-21 12:22:40', 3, 3, 128, 0, 32787],
        ['2022-07-21 12:32:51', 3, 3, 128, 0, 35858],
        ['2022-07-21 12:33:19', 3, 3, 128, 0, 32787],
        ['2022-07-21 12:39:13', 3, 3, 128, 0, 35858],
        ['2022-07-21 12:39:16', 3, 3, 128, 0, 32787],
        ['2022-07-21 13:21:36', 3, 3, 128, 0, 35858],
        ['2022-07-21 13:21:49', 3, 3, 128, 0, 32787],
        ['2022-07-21 13:30:17', 3, 3, 128, 0, 35858],
        ['2022-07-21 13:30:24', 3, 3, 128, 0, 32787],
        ['2022-07-21 14:13:35', 0, 244, 0, 0, 32787],
        ['2022-07-21 14:13:45', 6, 3, 128, 0, 35858],
        ['2022-07-21 15:00:01', 3, 3, 128, 0, 33810],
        ['2022-07-21 15:02:14', 3, 3, 128, 0, 32787],
        ['2022-07-21 15:08:12', 3, 3, 128, 0, 35858],
        ['2022-07-21 15:08:18', 3, 3, 128, 0, 32787],
        ['2022-07-21 15:13:18', 3, 3, 128, 0, 35858],
        ['2022-07-21 15:13:42', 3, 3, 128, 0, 32787],
        ['2022-07-21 15:24:23', 3, 3, 128, 0, 35858],
        ['2022-07-21 15:24:29', 3, 3, 128, 0, 32787],
        ['2022-07-21 15:34:34', 3, 3, 128, 0, 35858],
        ['2022-07-21 15:34:38', 3, 3, 128, 0, 32787],
        ['2022-07-21 15:54:43', 3, 3, 128, 0, 35858],
        ['2022-07-21 15:55:05', 3, 3, 128, 0, 32787],
        ['2022-07-21 17:31:58', 3, 3, 128, 0, 35858],
        ['2022-07-21 17:32:23', 3, 3, 128, 0, 32787],
        ['2022-07-21 17:41:51', 3, 3, 128, 0, 35858],
        ['2022-07-21 17:41:56', 3, 3, 128, 0, 32787],
        ['2022-07-21 18:00:08', 3, 3, 128, 0, 35858],
        ['2022-07-21 18:00:17', 3, 3, 128, 0, 32787],
        ['2022-07-21 18:23:30', 3, 3, 128, 0, 35858],
        ['2022-07-21 18:23:48', 3, 3, 128, 0, 32787],
        ['2022-07-21 19:09:34', 3, 3, 128, 0, 32787],
        ['2022-07-21 19:13:57', 3, 3, 128, 0, 35858],
        ['2022-07-21 19:14:12', 3, 3, 128, 0, 32787],
        ['2022-07-21 20:19:47', 3, 3, 128, 0, 35858],
        ['2022-07-21 20:19:50', 3, 3, 128, 0, 32787],
        ['2022-07-21 20:25:09', 3, 3, 128, 0, 35858],
        ['2022-07-21 20:25:44', 3, 3, 128, 0, 32787],
        ['2022-07-21 21:55:58', 3, 3, 128, 0, 35858],
        ['2022-07-21 21:56:13', 3, 3, 128, 0, 32787],
        ['2022-07-21 22:00:00', 3, 3, 128, 0, 33810],
        ['2022-07-21 22:02:21', 3, 3, 128, 0, 32787],
        ['2022-07-21 22:03:45', 3, 3, 128, 0, 33810],
        ['2022-07-21 22:04:09', 3, 3, 128, 0, 32787],
        ['2022-07-21 22:07:06', 3, 3, 128, 0, 35858],
        ['2022-07-21 22:07:16', 3, 3, 128, 0, 32787],
        ['2022-07-21 23:16:41', 3, 3, 128, 0, 35858],
        ['2022-07-21 23:16:48', 3, 3, 128, 0, 32787],
        ['2022-07-22 01:31:49', 3, 3, 128, 0, 33810],
        ['2022-07-22 01:32:28', 3, 3, 128, 0, 32787],
        ['2022-07-22 01:58:44', 3, 3, 128, 0, 35858],
        ['2022-07-22 01:58:48', 3, 3, 128, 0, 32787],
        ['2022-07-22 02:17:51', 3, 3, 128, 0, 35858],
        ['2022-07-22 02:17:56', 3, 3, 128, 0, 32787],
        ['2022-07-22 03:00:19', 3, 3, 128, 0, 35858],
        ['2022-07-22 03:00:40', 3, 3, 128, 0, 32787],
        ['2022-07-22 03:11:33', 3, 3, 128, 0, 32787],
        ['2022-07-22 03:48:17', 3, 3, 128, 0, 35858],
        ['2022-07-22 03:48:37', 3, 3, 128, 0, 32787],
        ['2022-07-22 04:10:36', 3, 3, 128, 0, 34835],
        ['2022-07-22 04:45:32', 3, 3, 128, 0, 33810],
        ['2022-07-22 04:45:35', 3, 3, 128, 0, 32787],
        ['2022-07-22 04:55:05', 3, 3, 128, 0, 35858],
        ['2022-07-22 04:55:12', 3, 3, 128, 0, 32787],
        ['2022-07-22 05:13:34', 3, 3, 128, 0, 35858],
        ['2022-07-22 05:13:52', 3, 3, 128, 0, 32787],
        ['2022-07-22 05:48:32', 3, 3, 128, 0, 32787],
        ['2022-07-22 06:00:00', 3, 3, 128, 0, 33810],
        ['2022-07-22 06:01:44', 3, 3, 128, 0, 32787],
        ['2022-07-22 06:06:45', 3, 3, 128, 0, 35858],
        ['2022-07-22 06:07:08', 3, 3, 128, 0, 32787],
        ['2022-07-22 06:37:48', 3, 3, 128, 0, 35858],
        ['2022-07-22 06:38:12', 3, 3, 128, 0, 32787],
        ['2022-07-22 08:12:28', 3, 3, 128, 0, 32787],
        ['2022-07-22 08:54:26', 3, 3, 128, 0, 35858],
        ['2022-07-22 08:54:32', 3, 3, 128, 0, 32787],
        ['2022-07-22 08:59:59', 3, 3, 128, 0, 32787],
        ['2022-07-22 09:00:00', 3, 3, 128, 0, 32787],
        ['2022-07-22 09:07:27', 3, 3, 128, 0, 35858],
        ['2022-07-22 09:07:37', 3, 3, 128, 0, 32787],
        ['2022-07-22 09:21:39', 3, 3, 128, 0, 35858],
        ['2022-07-22 09:21:42', 3, 3, 128, 0, 32787],
        ['2022-07-22 09:35:23', 3, 3, 128, 0, 35858],
        ['2022-07-22 09:35:33', 3, 3, 128, 0, 32787],
        ['2022-07-22 09:39:40', 3, 3, 128, 0, 35858],
        ['2022-07-22 09:40:01', 3, 3, 128, 0, 32787],
        ['2022-07-22 10:04:24', 3, 3, 128, 0, 35858],
        ['2022-07-22 10:04:44', 3, 3, 128, 0, 32787],
        ['2022-07-22 10:05:13', 3, 3, 128, 0, 32787],
        ['2022-07-22 10:36:39', 3, 3, 128, 0, 35858],
        ['2022-07-22 10:36:55', 3, 3, 128, 0, 32787],
        ['2022-07-22 11:04:16', 3, 3, 128, 0, 35858],
        ['2022-07-22 11:04:37', 3, 3, 128, 0, 32787],
        ['2022-07-22 12:37:04', 3, 3, 128, 0, 32787],
        ['2022-07-22 13:01:09', 3, 3, 128, 0, 33810],
        ['2022-07-22 13:01:24', 3, 3, 128, 0, 32787],
        ['2022-07-22 14:04:29', 3, 3, 128, 0, 34835],
        ['2022-07-22 14:20:39', 3, 3, 128, 0, 35858],
        ['2022-07-22 14:20:49', 3, 3, 128, 0, 32787],
        ['2022-07-22 14:30:55', 3, 3, 128, 0, 35858],
        ['2022-07-22 14:31:09', 3, 3, 128, 0, 32787],
        ['2022-07-22 14:37:14', 3, 3, 128, 0, 34835],
        ['2022-07-22 15:23:27', 3, 3, 128, 0, 34835],
        ['2022-07-22 15:33:59', 3, 3, 128, 0, 35858],
        ['2022-07-22 15:34:08', 3, 3, 128, 0, 32787],
        ['2022-07-22 16:04:53', 3, 3, 128, 0, 35858],
        ['2022-07-22 16:05:12', 3, 3, 128, 0, 32787],
        ['2022-07-22 17:24:00', 3, 3, 128, 0, 35858],
        ['2022-07-22 17:24:25', 3, 3, 128, 0, 32787],
        ['2022-07-22 17:29:33', 3, 3, 128, 0, 35858],
        ['2022-07-22 17:29:36', 3, 3, 128, 0, 32787],
        ['2022-07-22 18:08:18', 3, 3, 128, 0, 35858],
        ['2022-07-22 18:08:56', 3, 3, 128, 0, 32787],
        ['2022-07-22 18:29:12', 3, 3, 128, 0, 32787],
        ['2022-07-22 19:45:12', 3, 3, 128, 0, 33810],
        ['2022-07-22 19:45:28', 3, 3, 128, 0, 32787],
        ['2022-07-22 19:58:34', 3, 3, 128, 0, 35858],
        ['2022-07-22 19:58:44', 3, 3, 128, 0, 32787],
        ['2022-07-22 20:00:03', 3, 3, 128, 0, 33810],
        ['2022-07-22 20:01:00', 3, 3, 128, 0, 32787],
        ['2022-07-22 21:04:01', 3, 3, 128, 0, 35858],
        ['2022-07-22 21:04:22', 3, 3, 128, 0, 32787],
        ['2022-07-22 21:19:24', 3, 3, 128, 0, 35858],
        ['2022-07-22 21:19:45', 3, 3, 128, 0, 32787],
        ['2022-07-22 21:38:53', 3, 3, 128, 0, 33810],
        ['2022-07-22 21:39:00', 3, 3, 128, 0, 32787],
        ['2022-07-22 22:00:02', 3, 3, 128, 0, 33810],
        ['2022-07-22 22:02:20', 3, 3, 128, 0, 32787],
        ['2022-07-22 22:43:39', 3, 3, 128, 0, 35858],
        ['2022-07-22 22:43:44', 3, 3, 128, 0, 32787],
        ['2022-07-22 23:37:16', 3, 3, 128, 0, 35858],
        ['2022-07-22 23:37:44', 3, 3, 128, 0, 32787],
        ['2022-07-22 23:52:41', 3, 3, 128, 0, 35858],
        ['2022-07-22 23:52:47', 3, 3, 128, 0, 32787],
        ['2022-07-23 00:15:06', 3, 3, 128, 0, 33810],
        ['2022-07-23 00:15:22', 3, 3, 128, 0, 32787],
        ['2022-07-23 00:38:15', 3, 3, 128, 0, 35858],
        ['2022-07-23 00:38:29', 3, 3, 128, 0, 32787],
        ['2022-07-23 01:08:49', 3, 3, 128, 0, 33810],
        ['2022-07-23 01:09:04', 3, 3, 128, 0, 32787],
        ['2022-07-23 01:23:56', 3, 3, 128, 0, 33810],
        ['2022-07-23 01:24:36', 3, 3, 128, 0, 32787],
        ['2022-07-23 03:32:06', 3, 3, 128, 0, 35858],
        ['2022-07-23 03:32:18', 3, 3, 128, 0, 32787],
        ['2022-07-23 03:38:35', 3, 3, 128, 0, 35858],
        ['2022-07-23 03:38:57', 3, 3, 128, 0, 32787],
        ['2022-07-23 04:20:20', 3, 3, 128, 0, 32787],
        ['2022-07-23 05:27:44', 3, 3, 128, 0, 35858],
        ['2022-07-23 05:28:06', 3, 3, 128, 0, 32787],
        ['2022-07-23 06:00:02', 3, 3, 128, 0, 33810],
        ['2022-07-23 06:01:44', 3, 3, 128, 0, 32787],
        ['2022-07-23 06:56:00', 3, 3, 128, 0, 35858],
        ['2022-07-23 06:56:10', 3, 3, 128, 0, 32787],
        ['2022-07-23 08:16:55', 3, 3, 128, 0, 35858],
        ['2022-07-23 08:17:19', 3, 3, 128, 0, 32787],
        ['2022-07-23 09:04:56', 3, 3, 128, 0, 35858],
        ['2022-07-23 09:05:20', 3, 3, 128, 0, 32787],
        ['2022-07-23 09:10:28', 3, 3, 128, 0, 34835],
        ['2022-07-23 09:15:45', 3, 3, 128, 0, 35858],
        ['2022-07-23 09:16:08', 3, 3, 128, 0, 32787],
        ['2022-07-23 09:32:16', 3, 3, 128, 0, 35858],
        ['2022-07-23 09:32:20', 3, 3, 128, 0, 32787],
        ['2022-07-23 09:38:35', 3, 3, 128, 0, 35858],
        ['2022-07-23 09:38:52', 3, 3, 128, 0, 32787],
        ['2022-07-23 10:08:20', 3, 3, 128, 0, 33810],
        ['2022-07-23 10:08:48', 3, 3, 128, 0, 32787],
        ['2022-07-23 10:10:20', 3, 3, 128, 0, 35858],
        ['2022-07-23 10:10:36', 3, 3, 128, 0, 32787],
        ['2022-07-23 10:36:15', 3, 3, 128, 0, 35858],
        ['2022-07-23 10:36:28', 3, 3, 128, 0, 32787],
        ['2022-07-23 11:28:53', 3, 3, 128, 0, 35858],
        ['2022-07-23 11:29:18', 3, 3, 128, 0, 32787],
        ['2022-07-23 12:16:05', 3, 3, 128, 0, 35858],
        ['2022-07-23 12:16:08', 3, 3, 128, 0, 32787],
        ['2022-07-23 13:05:14', 3, 3, 128, 0, 35858],
        ['2022-07-23 13:05:18', 3, 3, 128, 0, 32787],
        ['2022-07-23 13:22:41', 3, 3, 128, 0, 35858],
        ['2022-07-23 13:22:45', 3, 3, 128, 0, 32787],
        ['2022-07-23 14:39:02', 3, 3, 128, 0, 35858],
        ['2022-07-23 14:39:19', 3, 3, 128, 0, 32787],
        ['2022-07-23 14:48:29', 3, 3, 128, 0, 33810],
        ['2022-07-23 14:49:08', 3, 3, 128, 0, 32787],
        ['2022-07-23 15:22:12', 3, 3, 128, 0, 35858],
        ['2022-07-23 15:22:41', 3, 3, 128, 0, 32787],
        ['2022-07-23 16:03:49', 3, 3, 128, 0, 32787],
        ['2022-07-23 16:33:49', 3, 3, 128, 0, 32787],
        ['2022-07-23 16:40:32', 3, 3, 128, 0, 35858],
        ['2022-07-23 16:40:44', 3, 3, 128, 0, 32787],
        ['2022-07-23 17:26:58', 3, 3, 128, 0, 35858],
        ['2022-07-23 17:27:32', 3, 3, 128, 0, 32787],
        ['2022-07-23 18:43:09', 3, 3, 128, 0, 33810],
        ['2022-07-23 18:43:37', 3, 3, 128, 0, 32787],
        ['2022-07-23 19:26:43', 3, 3, 128, 0, 34835],
        ['2022-07-23 19:48:37', 3, 3, 128, 0, 35858],
        ['2022-07-23 19:49:04', 3, 3, 128, 0, 32787],
        ['2022-07-23 20:08:03', 3, 3, 128, 0, 35858],
        ['2022-07-23 20:08:13', 3, 3, 128, 0, 32787],
        ['2022-07-23 20:28:13', 3, 3, 128, 0, 35858],
        ['2022-07-23 20:28:40', 3, 3, 128, 0, 32787],
        ['2022-07-23 21:01:44', 3, 3, 128, 0, 32787],
        ['2022-07-23 21:28:14', 3, 3, 128, 0, 34835],
        ['2022-07-23 22:00:02', 3, 3, 128, 0, 33810],
        ['2022-07-23 22:02:20', 3, 3, 128, 0, 32787],
        ['2022-07-23 22:38:28', 3, 3, 128, 0, 35858],
        ['2022-07-23 22:38:59', 3, 3, 128, 0, 32787],
        ['2022-07-23 22:58:59', 3, 3, 128, 0, 33810],
        ['2022-07-23 22:59:59', 3, 3, 128, 0, 32787],
        ['2022-07-23 23:00:01', 3, 3, 128, 0, 32787],
        ['2022-07-23 23:10:27', 3, 3, 128, 0, 34835],
        ['2022-07-23 23:45:42', 3, 3, 128, 0, 35858],
        ['2022-07-23 23:46:16', 3, 3, 128, 0, 32787],
        ['2022-07-24 00:02:38', 3, 3, 128, 0, 35858],
        ['2022-07-24 00:02:58', 3, 3, 128, 0, 32787],
        ['2022-07-24 00:08:16', 3, 3, 128, 0, 35858],
        ['2022-07-24 00:08:21', 3, 3, 128, 0, 32787],
        ['2022-07-24 04:00:55', 3, 3, 128, 0, 33810],
        ['2022-07-24 04:01:12', 3, 3, 128, 0, 32787],
        ['2022-07-24 04:06:09', 3, 3, 128, 0, 35858],
        ['2022-07-24 04:06:36', 3, 3, 128, 0, 32787],
        ['2022-07-24 04:17:33', 3, 3, 128, 0, 35858],
        ['2022-07-24 04:17:44', 3, 3, 128, 0, 32787],
        ['2022-07-24 05:04:39', 3, 3, 128, 0, 35858],
        ['2022-07-24 05:04:52', 3, 3, 128, 0, 32787],
        ['2022-07-24 05:20:18', 3, 3, 128, 0, 35858],
        ['2022-07-24 05:20:24', 3, 3, 128, 0, 32787],
        ['2022-07-24 05:27:16', 3, 3, 128, 0, 35858],
        ['2022-07-24 05:27:35', 3, 3, 128, 0, 32787],
        ['2022-07-24 05:31:48', 3, 3, 128, 0, 35858],
        ['2022-07-24 05:31:51', 3, 3, 128, 0, 32787],
        ['2022-07-24 06:00:00', 3, 3, 128, 0, 33810],
        ['2022-07-24 06:01:44', 3, 3, 128, 0, 32787],
        ['2022-07-24 06:13:39', 3, 3, 128, 0, 32787],
        ['2022-07-24 06:27:06', 3, 3, 128, 0, 35858],
        ['2022-07-24 06:27:23', 3, 3, 128, 0, 32787],
        ['2022-07-24 06:32:03', 3, 3, 128, 0, 35858],
        ['2022-07-24 06:32:19', 3, 3, 128, 0, 32787],
        ['2022-07-24 08:09:32', 3, 3, 128, 0, 32787],
        ['2022-07-24 08:26:28', 3, 3, 128, 0, 33810],
        ['2022-07-24 08:26:52', 3, 3, 128, 0, 32787],
        ['2022-07-24 08:55:08', 3, 3, 128, 0, 35858],
        ['2022-07-24 08:55:40', 3, 3, 128, 0, 32787],
        ['2022-07-24 09:00:28', 3, 3, 128, 0, 32787],
        ['2022-07-24 09:05:55', 3, 3, 128, 0, 35858],
        ['2022-07-24 09:06:28', 3, 3, 128, 0, 32787],
        ['2022-07-24 09:44:56', 3, 3, 128, 0, 32787],
        ['2022-07-24 11:02:54', 3, 3, 128, 0, 35858],
        ['2022-07-24 11:03:28', 3, 3, 128, 0, 32787],
        ['2022-07-24 11:30:59', 3, 3, 128, 0, 34835],
        ['2022-07-24 11:53:40', 3, 3, 128, 0, 35858],
        ['2022-07-24 11:53:53', 3, 3, 128, 0, 32787],
        ['2022-07-24 12:04:37', 3, 3, 128, 0, 35858],
        ['2022-07-24 12:04:41', 3, 3, 128, 0, 32787],
        ['2022-07-24 12:54:20', 3, 3, 128, 0, 33810],
        ['2022-07-24 12:54:24', 3, 3, 128, 0, 32787],
        ['2022-07-24 13:08:40', 3, 3, 128, 0, 33810],
        ['2022-07-24 13:08:48', 3, 3, 128, 0, 32787],
        ['2022-07-24 13:30:42', 3, 3, 128, 0, 35858],
        ['2022-07-24 13:30:56', 3, 3, 128, 0, 32787],
        ['2022-07-24 13:36:04', 3, 3, 128, 0, 34835],
        ['2022-07-24 13:57:03', 3, 3, 128, 0, 35858],
        ['2022-07-24 13:57:39', 3, 3, 128, 0, 32787],
        ['2022-07-24 13:58:15', 3, 3, 128, 0, 35858],
        ['2022-07-24 13:58:38', 3, 3, 128, 0, 32787],
        ['2022-07-24 13:58:44', 3, 3, 128, 0, 32787],
        ['2022-07-24 14:04:20', 3, 3, 128, 0, 35858],
        ['2022-07-24 14:04:35', 3, 3, 128, 0, 32787],
        ['2022-07-24 14:07:49', 3, 3, 128, 0, 35858],
        ['2022-07-24 14:08:13', 3, 3, 128, 0, 32787],
        ['2022-07-24 14:13:37', 3, 3, 128, 0, 32787],
        ['2022-07-24 14:23:14', 3, 3, 128, 0, 35858],
        ['2022-07-24 14:23:17', 3, 3, 128, 0, 32787],
        ['2022-07-24 14:39:01', 3, 3, 128, 0, 35858],
        ['2022-07-24 14:39:13', 3, 3, 128, 0, 32787],
        ['2022-07-24 14:58:21', 3, 3, 128, 0, 34835],
        ['2022-07-24 15:19:18', 3, 3, 128, 0, 35858],
        ['2022-07-24 15:19:44', 3, 3, 128, 0, 32787],
        ['2022-07-24 15:41:09', 3, 3, 128, 0, 35858],
        ['2022-07-24 15:41:20', 3, 3, 128, 0, 32787],
        ['2022-07-24 16:13:28', 3, 3, 128, 0, 35858],
        ['2022-07-24 16:13:44', 3, 3, 128, 0, 32787],
        ['2022-07-24 16:27:33', 3, 3, 128, 0, 35858],
        ['2022-07-24 16:28:05', 3, 3, 128, 0, 32787],
        ['2022-07-24 16:40:06', 3, 3, 128, 0, 32787],
        ['2022-07-24 16:45:44', 3, 3, 128, 0, 35858],
        ['2022-07-24 16:45:53', 3, 3, 128, 0, 32787],
        ['2022-07-24 18:40:42', 3, 3, 128, 0, 35858],
        ['2022-07-24 18:41:20', 3, 3, 128, 0, 32787],
        ['2022-07-24 18:55:44', 3, 3, 128, 0, 33810],
        ['2022-07-24 18:56:12', 3, 3, 128, 0, 32787],
        ['2022-07-24 19:37:58', 3, 3, 128, 0, 35858],
        ['2022-07-24 19:38:05', 3, 3, 128, 0, 32787],
        ['2022-07-24 20:46:59', 3, 3, 128, 0, 35858],
        ['2022-07-24 20:47:21', 3, 3, 128, 0, 32787],
        ['2022-07-24 21:08:17', 3, 3, 128, 0, 33810],
        ['2022-07-24 21:08:57', 3, 3, 128, 0, 32787],
        ['2022-07-24 21:37:00', 3, 3, 128, 0, 35858],
        ['2022-07-24 21:37:04', 3, 3, 128, 0, 32787],
        ['2022-07-24 21:53:17', 3, 3, 128, 0, 33810],
        ['2022-07-24 21:53:40', 3, 3, 128, 0, 32787],
        ['2022-07-24 22:00:02', 3, 3, 128, 0, 33810],
        ['2022-07-24 22:02:20', 3, 3, 128, 0, 32787],
        ['2022-07-24 23:34:37', 3, 3, 128, 0, 33810],
        ['2022-07-24 23:34:48', 3, 3, 128, 0, 32787],
        ['2022-07-24 23:57:11', 3, 3, 128, 0, 35858],
        ['2022-07-24 23:57:22', 3, 3, 128, 0, 32787],
        ['2022-07-25 01:09:37', 3, 3, 128, 0, 33810],
        ['2022-07-25 01:10:12', 3, 3, 128, 0, 32787],
        ['2022-07-25 02:02:13', 3, 3, 128, 0, 35858],
        ['2022-07-25 02:02:24', 3, 3, 128, 0, 32787],
        ['2022-07-25 05:04:56', 3, 3, 128, 0, 35858],
        ['2022-07-25 05:05:09', 3, 3, 128, 0, 32787],
        ['2022-07-25 05:34:48', 3, 3, 128, 0, 34835],
        ['2022-07-25 06:00:02', 3, 3, 128, 0, 33810],
        ['2022-07-25 06:01:45', 3, 3, 128, 0, 32787],
        ['2022-07-25 06:40:12', 254, 254, 0, 0, 32786],
        ['2022-07-25 06:59:01', 6, 3, 128, 0, 33810],
        ['2022-07-25 07:00:01', 3, 3, 128, 0, 33810],
        ['2022-07-25 07:00:28', 3, 3, 128, 0, 32787],
        ['2022-07-25 07:20:03', 3, 3, 128, 0, 35858],
        ['2022-07-25 07:20:16', 3, 3, 128, 0, 32787],
        ['2022-07-25 07:22:56', 3, 3, 128, 0, 35858],
        ['2022-07-25 07:23:12', 3, 3, 128, 0, 32787],
        ['2022-07-25 07:32:28', 3, 3, 128, 0, 33810],
        ['2022-07-25 07:32:36', 3, 3, 128, 0, 32787],
        ['2022-07-25 07:43:45', 3, 3, 128, 0, 35858],
        ['2022-07-25 07:44:20', 3, 3, 128, 0, 32787],
        ['2022-07-25 09:30:06', 3, 3, 128, 0, 35858],
        ['2022-07-25 09:30:21', 3, 3, 128, 0, 32787],
        ['2022-07-25 10:21:57', 3, 3, 128, 0, 34835],
        ['2022-07-25 10:56:16', 3, 3, 128, 0, 32787],
        ['2022-07-25 11:13:04', 3, 3, 128, 0, 35858],
        ['2022-07-25 11:13:08', 3, 3, 128, 0, 32787],
        ['2022-07-25 11:52:48', 3, 3, 128, 0, 35858],
        ['2022-07-25 11:52:51', 3, 3, 128, 0, 32787],
        ['2022-07-25 13:26:09', 3, 3, 128, 0, 35858],
        ['2022-07-25 13:26:21', 3, 3, 128, 0, 32787],
        ['2022-07-25 14:13:54', 3, 3, 128, 0, 34835],
        ['2022-07-25 14:46:12', 3, 3, 128, 0, 35858],
        ['2022-07-25 14:46:40', 3, 3, 128, 0, 32787],
        ['2022-07-25 15:23:39', 3, 3, 128, 0, 35858],
        ['2022-07-25 15:23:48', 3, 3, 128, 0, 32787],
        ['2022-07-25 15:29:25', 3, 3, 128, 0, 35858],
        ['2022-07-25 15:29:34', 3, 3, 128, 0, 32787],
        ['2022-07-25 16:00:27', 3, 3, 128, 0, 32787],
        ['2022-07-25 17:36:33', 3, 3, 128, 0, 33810],
        ['2022-07-25 17:37:01', 3, 3, 128, 0, 32787],
        ['2022-07-25 18:33:28', 3, 3, 128, 0, 35858],
        ['2022-07-25 18:33:38', 3, 3, 128, 0, 32787],
        ['2022-07-25 18:36:09', 3, 3, 128, 0, 35858],
        ['2022-07-25 18:36:23', 3, 3, 128, 0, 32787],
        ['2022-07-25 21:13:57', 3, 3, 128, 0, 33810],
        ['2022-07-25 21:14:21', 3, 3, 128, 0, 32787],
        ['2022-07-25 22:00:02', 3, 3, 128, 0, 33810],
        ['2022-07-25 22:02:21', 3, 3, 128, 0, 32787],
        ['2022-07-25 22:15:48', 3, 3, 128, 0, 35858],
        ['2022-07-25 22:16:17', 3, 3, 128, 0, 32787],
        ['2022-07-25 23:24:24', 3, 3, 128, 0, 35858],
        ['2022-07-25 23:24:33', 3, 3, 128, 0, 32787],
        ['2022-07-25 23:34:18', 3, 3, 128, 0, 35858],
        ['2022-07-25 23:34:27', 3, 3, 128, 0, 32787],
        ['2022-07-26 00:08:07', 3, 3, 128, 0, 35858],
        ['2022-07-26 00:08:21', 3, 3, 128, 0, 32787],
        ['2022-07-26 01:57:13', 3, 3, 128, 0, 35858],
        ['2022-07-26 01:57:40', 3, 3, 128, 0, 32787],
        ['2022-07-26 03:47:57', 3, 3, 128, 0, 32787],
        ['2022-07-26 03:56:59', 3, 3, 128, 0, 35858],
        ['2022-07-26 03:57:38', 3, 3, 128, 0, 32787],
        ['2022-07-26 06:00:00', 3, 3, 128, 0, 33810],
        ['2022-07-26 06:01:43', 3, 3, 128, 0, 32787],
        ['2022-07-26 06:19:05', 3, 3, 128, 0, 34835],
        ['2022-07-26 06:22:20', 3, 3, 128, 0, 35858],
        ['2022-07-26 06:22:39', 3, 3, 128, 0, 32787],
        ['2022-07-26 06:31:24', 3, 3, 128, 0, 35858],
        ['2022-07-26 06:32:43', 254, 254, 0, 0, 32786],
        ['2022-07-26 06:34:23', 3, 3, 128, 0, 33810],
        ['2022-07-26 06:34:35', 3, 3, 128, 0, 32787],
        ['2022-07-26 06:45:59', 6, 3, 128, 0, 35858],
        ['2022-07-26 06:46:11', 3, 3, 128, 0, 33810],
        ['2022-07-26 06:49:17', 254, 254, 0, 0, 32786],
        ['2022-07-26 07:10:51', 6, 3, 128, 0, 33810],
        ['2022-07-26 07:12:41', 254, 254, 0, 0, 32786],
        ['2022-07-26 07:14:09', 6, 3, 128, 0, 33810],
        ['2022-07-26 07:14:59', 3, 3, 128, 0, 33810],
        ['2022-07-26 07:16:00', 3, 3, 128, 0, 32787],
        ['2022-07-26 07:25:37', 3, 3, 128, 0, 34835],
        ['2022-07-26 07:28:54', 3, 3, 128, 0, 35858],
        ['2022-07-26 07:29:17', 3, 3, 128, 0, 32787],
        ['2022-07-26 07:36:05', 3, 3, 128, 0, 34835],
        ['2022-07-26 07:43:37', 254, 254, 0, 0, 32786],
        ['2022-07-26 08:02:17', 6, 3, 128, 0, 33810],
        ['2022-07-26 08:04:30', 3, 3, 128, 0, 33810],
        ['2022-07-26 08:05:16', 3, 3, 128, 0, 32787],
        ['2022-07-26 08:22:11', 3, 3, 128, 0, 35858],
        ['2022-07-26 08:22:16', 3, 3, 128, 0, 32787],
        ['2022-07-26 08:28:20', 3, 3, 128, 0, 35858],
        ['2022-07-26 08:28:39', 3, 3, 128, 0, 32787],
        ['2022-07-26 08:39:28', 3, 3, 128, 0, 35858],
        ['2022-07-26 08:39:39', 3, 3, 128, 0, 32787],
        ['2022-07-26 09:04:57', 3, 3, 128, 0, 35858],
        ['2022-07-26 09:05:20', 3, 3, 128, 0, 32787],
        ['2022-07-26 09:14:07', 3, 3, 128, 0, 35858],
        ['2022-07-26 09:14:20', 3, 3, 128, 0, 32787],
        ['2022-07-26 09:18:16', 3, 3, 128, 0, 35858],
        ['2022-07-26 09:18:25', 3, 3, 128, 0, 32787],
        ['2022-07-26 09:21:26', 3, 3, 128, 0, 35858],
        ['2022-07-26 09:21:32', 3, 3, 128, 0, 32787],
        ['2022-07-26 09:29:13', 3, 3, 128, 0, 35858],
        ['2022-07-26 09:29:33', 3, 3, 128, 0, 32787],
        ['2022-07-26 09:42:16', 3, 3, 128, 0, 34835],
        ['2022-07-26 09:57:32', 3, 3, 128, 0, 32787],
        ['2022-07-26 10:38:53', 3, 3, 128, 0, 35858],
        ['2022-07-26 10:38:56', 3, 3, 128, 0, 32787],
        ['2022-07-26 12:57:21', 3, 3, 128, 0, 33810],
        ['2022-07-26 12:57:32', 3, 3, 128, 0, 32787],
        ['2022-07-26 13:39:14', 3, 3, 128, 0, 32787],
        ['2022-07-26 13:41:43', 6, 3, 128, 0, 35858],
        ['2022-07-26 13:45:38', 6, 3, 128, 0, 33810],
        ['2022-07-26 13:48:41', 254, 254, 0, 0, 32786],
        ['2022-07-26 13:49:00', 6, 3, 128, 0, 33810],
        ['2022-07-26 13:50:29', 254, 254, 0, 0, 32786],
        ['2022-07-26 13:52:06', 6, 3, 128, 0, 33810],
        ['2022-07-26 13:52:48', 3, 3, 128, 0, 33810],
        ['2022-07-26 13:54:49', 6, 3, 128, 0, 35858],
        ['2022-07-26 13:57:41', 254, 254, 0, 0, 32786],
        ['2022-07-26 13:59:04', 6, 3, 128, 0, 33810],
        ['2022-07-26 13:59:14', 246, 246, 0, 0, 0],
        ['2022-07-26 14:00:59', 3, 3, 128, 0, 33810],
        ['2022-07-26 14:01:15', 3, 3, 128, 0, 33808],
        ['2022-07-26 14:01:40', 3, 3, 128, 0, 32785],
        ['2022-07-26 14:05:55', 3, 3, 128, 0, 32785],
        ['2022-07-26 14:15:56', 3, 3, 128, 0, 35856],
        ['2022-07-26 14:16:04', 3, 3, 128, 0, 32785],
        ['2022-07-26 14:19:26', 3, 3, 128, 0, 35856],
        ['2022-07-26 14:20:45', 254, 254, 0, 0, 32784],
        ['2022-07-26 14:22:25', 3, 3, 128, 0, 32785],
        ['2022-07-26 14:26:49', 3, 3, 128, 0, 35856],
        ['2022-07-26 14:26:52', 3, 3, 128, 0, 32785],
        ['2022-07-26 14:49:44', 3, 3, 128, 0, 35856],
        ['2022-07-26 14:50:08', 3, 3, 128, 0, 32785],
        ['2022-07-26 14:52:26', 254, 254, 0, 0, 32784],
        ['2022-07-26 14:54:06', 3, 3, 128, 0, 33808],
        ['2022-07-26 14:54:32', 3, 3, 128, 0, 32785],
        ['2022-07-26 14:58:23', 3, 3, 128, 0, 34833],
        ['2022-07-26 15:01:26', 6, 3, 128, 0, 33810],
        ['2022-07-26 15:02:45', 254, 254, 0, 0, 32786],
        ['2022-07-26 15:04:25', 6, 3, 128, 0, 33810],
        ['2022-07-26 15:06:05', 254, 254, 0, 0, 32786],
        ['2022-07-26 15:07:28', 6, 3, 128, 0, 33810],
        ['2022-07-26 15:08:27', 3, 3, 128, 0, 33810],
        ['2022-07-26 15:08:56', 3, 3, 128, 0, 32787],
        ['2022-07-26 15:18:37', 3, 3, 128, 0, 35858],
        ['2022-07-26 15:19:04', 3, 3, 128, 0, 32787],
        ['2022-07-26 15:39:13', 3, 3, 128, 0, 34835],
        ['2022-07-26 15:50:19', 3, 3, 128, 0, 32787],
        ['2022-07-26 16:09:24', 3, 3, 128, 0, 35858],
        ['2022-07-26 16:09:27', 3, 3, 128, 0, 32787],
        ['2022-07-26 16:13:38', 3, 3, 128, 0, 35858],
        ['2022-07-26 16:13:43', 3, 3, 128, 0, 32787],
        ['2022-07-26 16:22:04', 3, 3, 128, 0, 35858],
        ['2022-07-26 16:22:14', 3, 3, 128, 0, 32787],
        ['2022-07-26 16:32:53', 3, 3, 128, 0, 33810],
        ['2022-07-26 16:33:21', 3, 3, 128, 0, 32787],
        ['2022-07-26 16:43:01', 3, 3, 128, 0, 33810],
        ['2022-07-26 16:43:15', 3, 3, 128, 0, 32787],
        ['2022-07-26 19:53:49', 3, 3, 128, 0, 33810],
        ['2022-07-26 19:54:12', 3, 3, 128, 0, 32787],
        ['2022-07-26 20:10:04', 3, 3, 128, 0, 32787],
        ['2022-07-26 22:00:02', 3, 3, 128, 0, 33810],
        ['2022-07-26 22:02:21', 3, 3, 128, 0, 32787],
        ['2022-07-27 01:09:33', 3, 3, 128, 0, 33810],
        ['2022-07-27 01:09:51', 3, 3, 128, 0, 32787],
        ['2022-07-27 05:02:25', 3, 3, 128, 0, 33810],
        ['2022-07-27 05:03:05', 3, 3, 128, 0, 32787],
        ['2022-07-27 05:52:09', 3, 3, 128, 0, 33810],
        ['2022-07-27 05:52:48', 3, 3, 128, 0, 32787],
        ['2022-07-27 06:00:02', 3, 3, 128, 0, 33810],
        ['2022-07-27 06:01:44', 3, 3, 128, 0, 32787],
        ['2022-07-27 09:20:52', 3, 3, 128, 0, 33810],
        ['2022-07-27 09:21:03', 3, 3, 128, 0, 32787],
        ['2022-07-27 13:35:54', 3, 3, 128, 0, 33810],
        ['2022-07-27 13:36:28', 3, 3, 128, 0, 32787],
        ['2022-07-27 13:40:01', 6, 3, 128, 0, 35858],
        ['2022-07-27 13:40:12', 3, 3, 128, 0, 33810],
        ['2022-07-27 13:41:13', 3, 3, 128, 0, 32787],
        ['2022-07-27 19:17:20', 3, 3, 128, 0, 32787],
        ['2022-07-27 22:00:04', 3, 3, 128, 0, 33810],
        ['2022-07-27 22:02:21', 3, 3, 128, 0, 32787],
        ['2022-07-27 23:05:52', 3, 3, 128, 0, 33810],
        ['2022-07-27 23:06:01', 3, 3, 128, 0, 32787],
        ['2022-07-28 05:23:51', 3, 3, 128, 0, 33810],
        ['2022-07-28 05:24:00', 3, 3, 128, 0, 32787],
        ['2022-07-28 06:00:02', 3, 3, 128, 0, 33810],
        ['2022-07-28 06:01:44', 3, 3, 128, 0, 32787],
        ['2022-07-28 06:36:05', 3, 3, 128, 0, 33810],
        ['2022-07-28 06:36:24', 3, 3, 128, 0, 32787],
        ['2022-07-28 06:56:52', 3, 3, 128, 0, 33810],
        ['2022-07-28 06:57:02', 3, 3, 128, 0, 32787],
        ['2022-07-28 11:08:14', 3, 3, 128, 0, 33810],
        ['2022-07-28 11:08:53', 3, 3, 128, 0, 32787],
        ['2022-07-28 14:49:49', 3, 3, 128, 0, 33810],
        ['2022-07-28 14:50:16', 3, 3, 128, 0, 32787],
        ['2022-07-28 18:49:59', 3, 3, 128, 0, 32787],
        ['2022-07-28 18:50:59', 3, 3, 128, 0, 33810],
        ['2022-07-28 18:51:59', 3, 3, 128, 0, 32787],
        ['2022-07-28 18:52:59', 3, 3, 128, 0, 32787],
        ['2022-07-28 18:53:59', 3, 3, 128, 0, 32787],
        ['2022-07-28 18:54:59', 3, 3, 128, 0, 32787],
        ['2022-07-28 18:55:59', 3, 3, 128, 0, 32787],
        ['2022-07-28 18:56:59', 3, 3, 128, 0, 32787],
        ['2022-07-28 18:57:59', 3, 3, 128, 0, 33810],
        ['2022-07-28 18:58:59', 3, 3, 128, 0, 32787],
        ['2022-07-28 18:59:59', 3, 3, 128, 0, 32787],
        ['2022-07-28 19:00:00', 3, 3, 128, 0, 32787],
        ['2022-07-28 19:03:25', 3, 3, 128, 0, 33810],
        ['2022-07-28 19:04:04', 3, 3, 128, 0, 32787],
        ['2022-07-28 19:55:33', 3, 3, 128, 0, 33810],
        ['2022-07-28 19:55:36', 3, 3, 128, 0, 32787],
        ['2022-07-28 21:45:59', 3, 3, 128, 0, 32787],
        ['2022-07-28 21:46:59', 3, 3, 128, 0, 32787],
        ['2022-07-28 21:47:23', 3, 3, 128, 0, 32787],
        ['2022-07-28 21:54:17', 3, 3, 128, 0, 33810],
        ['2022-07-28 21:54:24', 3, 3, 128, 0, 32787],
        ['2022-07-28 22:00:02', 3, 3, 128, 0, 33810],
        ['2022-07-28 22:02:20', 3, 3, 128, 0, 32787],
        ['2022-07-29 05:34:09', 3, 3, 128, 0, 33810],
        ['2022-07-29 05:34:25', 3, 3, 128, 0, 32787],
        ['2022-07-29 06:00:00', 3, 3, 128, 0, 33810],
        ['2022-07-29 06:01:44', 3, 3, 128, 0, 32787],
        ['2022-07-29 09:18:25', 3, 3, 128, 0, 33810],
        ['2022-07-29 09:19:03', 3, 3, 128, 0, 32787],
        ['2022-07-29 15:08:58', 3, 3, 128, 0, 33810],
        ['2022-07-29 15:09:22', 3, 3, 128, 0, 32787],
        ['2022-07-29 19:07:02', 3, 3, 128, 0, 33810],
        ['2022-07-29 19:07:40', 3, 3, 128, 0, 32787],
        ['2022-07-29 20:05:16', 3, 3, 128, 0, 32787],
        ['2022-07-29 21:07:09', 3, 3, 128, 0, 33810],
        ['2022-07-29 21:07:36', 3, 3, 128, 0, 32787],
        ['2022-07-29 22:00:02', 3, 3, 128, 0, 33810],
        ['2022-07-29 22:02:20', 3, 3, 128, 0, 32787],
        ['2022-07-29 23:58:12', 3, 3, 128, 0, 33810],
        ['2022-07-29 23:58:22', 3, 3, 128, 0, 32787],
        ['2022-07-30 03:01:51', 3, 3, 128, 0, 32787],
        ['2022-07-30 06:00:03', 3, 3, 128, 0, 33810],
        ['2022-07-30 06:01:44', 3, 3, 128, 0, 32787],
        ['2022-07-30 07:59:52', 3, 3, 128, 0, 33810],
        ['2022-07-30 08:00:00', 3, 3, 128, 0, 32787],
        ['2022-07-30 08:37:40', 3, 3, 128, 0, 33810],
        ['2022-07-30 08:37:50', 3, 3, 128, 0, 32787],
        ['2022-07-30 12:17:57', 3, 3, 128, 0, 33810],
        ['2022-07-30 12:18:00', 3, 3, 128, 0, 32787],
        ['2022-07-30 15:25:04', 3, 3, 128, 0, 33810],
        ['2022-07-30 15:25:09', 3, 3, 128, 0, 32787],
        ['2022-07-30 18:02:13', 3, 3, 128, 0, 33810],
        ['2022-07-30 18:02:25', 3, 3, 128, 0, 32787],
        ['2022-07-30 21:10:45', 3, 3, 128, 0, 33810],
        ['2022-07-30 21:11:12', 3, 3, 128, 0, 32787],
        ['2022-07-30 22:00:00', 3, 3, 128, 0, 33810],
        ['2022-07-30 22:02:21', 3, 3, 128, 0, 32787],
        ['2022-07-30 22:04:10', 3, 3, 128, 0, 33810],
        ['2022-07-30 22:04:49', 3, 3, 128, 0, 32787],
        ['2022-07-31 00:19:31', 3, 3, 128, 0, 33810],
        ['2022-07-31 00:19:49', 3, 3, 128, 0, 32787],
        ['2022-07-31 00:20:58', 3, 3, 128, 0, 33810],
        ['2022-07-31 00:21:30', 3, 3, 128, 0, 32787],
        ['2022-07-31 01:28:48', 3, 3, 128, 0, 33810],
        ['2022-07-31 01:28:52', 3, 3, 128, 0, 32787],
        ['2022-07-31 04:36:50', 3, 3, 128, 0, 33810],
        ['2022-07-31 04:37:12', 3, 3, 128, 0, 32787],
        ['2022-07-31 06:00:00', 3, 3, 128, 0, 33810],
        ['2022-07-31 06:01:43', 3, 3, 128, 0, 32787],
        ['2022-07-31 06:18:24', 3, 3, 128, 0, 33810],
        ['2022-07-31 06:18:46', 3, 3, 128, 0, 32787],
        ['2022-07-31 07:37:08', 3, 3, 128, 0, 33810],
        ['2022-07-31 07:37:36', 3, 3, 128, 0, 32787],
        ['2022-07-31 09:58:28', 3, 3, 128, 0, 33810],
        ['2022-07-31 09:58:40', 3, 3, 128, 0, 32787],
        ['2022-07-31 11:02:48', 3, 3, 128, 0, 32787],
        ['2022-07-31 14:10:01', 3, 3, 128, 0, 33810],
        ['2022-07-31 14:10:46', 3, 3, 128, 0, 32787],
        ['2022-07-31 14:24:24', 3, 3, 128, 0, 33810],
        ['2022-07-31 14:24:42', 3, 3, 128, 0, 32787],
        ['2022-07-31 20:08:13', 3, 3, 128, 0, 33810],
        ['2022-07-31 20:08:52', 3, 3, 128, 0, 32787],
        ['2022-07-31 22:00:03', 3, 3, 128, 0, 33810],
        ['2022-07-31 22:02:21', 3, 3, 128, 0, 32787],
        ['2022-08-01 02:19:45', 3, 3, 128, 0, 33810],
        ['2022-08-01 02:20:17', 3, 3, 128, 0, 32787],
        ['2022-08-01 06:00:02', 3, 3, 128, 0, 33810],
        ['2022-08-01 06:01:45', 3, 3, 128, 0, 32787],
        ['2022-08-01 07:20:58', 3, 3, 128, 0, 33810],
        ['2022-08-01 07:21:09', 3, 3, 128, 0, 32787],
        ['2022-08-01 12:06:29', 3, 3, 128, 0, 33810],
        ['2022-08-01 12:07:08', 3, 3, 128, 0, 32787],
        ['2022-08-01 13:36:16', 3, 3, 128, 0, 33810],
        ['2022-08-01 13:36:28', 3, 3, 128, 0, 32787],
        ['2022-08-01 16:37:37', 3, 3, 128, 0, 33810],
        ['2022-08-01 16:38:16', 3, 3, 128, 0, 32787],
        ['2022-08-01 16:47:45', 6, 3, 128, 0, 35858],
        ['2022-08-01 16:49:12', 3, 3, 128, 0, 33810],
        ['2022-08-01 16:49:45', 3, 3, 128, 0, 32787],
        ['2022-08-01 18:01:53', 3, 3, 128, 0, 33810],
        ['2022-08-01 18:02:12', 3, 3, 128, 0, 32787],
        ['2022-08-01 22:00:00', 3, 3, 128, 0, 33810],
        ['2022-08-01 22:02:21', 3, 3, 128, 0, 32787],
        ['2022-08-01 22:37:13', 3, 3, 128, 0, 33810],
        ['2022-08-01 22:37:52', 3, 3, 128, 0, 32787],
        ['2022-08-02 00:13:18', 3, 3, 128, 0, 33810],
        ['2022-08-02 00:13:45', 3, 3, 128, 0, 32787],
        ['2022-08-02 05:36:42', 3, 3, 128, 0, 33810],
        ['2022-08-02 05:37:15', 3, 3, 128, 0, 32787],
        ['2022-08-02 06:00:00', 3, 3, 128, 0, 33810],
        ['2022-08-02 06:01:44', 3, 3, 128, 0, 32787],
        ['2022-08-02 08:14:24', 3, 3, 128, 0, 33810],
        ['2022-08-02 08:14:56', 3, 3, 128, 0, 32787],
        ['2022-08-02 09:18:25', 3, 3, 128, 0, 33810],
        ['2022-08-02 09:18:40', 3, 3, 128, 0, 32787],
        ['2022-08-02 12:15:28', 3, 3, 128, 0, 33810],
        ['2022-08-02 12:15:39', 3, 3, 128, 0, 32787],
        ['2022-08-02 16:39:06', 3, 3, 128, 0, 33810],
        ['2022-08-02 16:39:24', 3, 3, 128, 0, 32787],
        ['2022-08-02 17:05:12', 6, 3, 128, 0, 33810],
        ['2022-08-02 17:05:24', 3, 3, 128, 0, 33810],
        ['2022-08-02 17:07:44', 3, 3, 128, 0, 32787],
        ['2022-08-02 21:33:30', 3, 3, 128, 0, 33810],
        ['2022-08-02 21:34:02', 3, 3, 128, 0, 32787],
        ['2022-08-02 21:40:03', 3, 3, 128, 0, 33810],
        ['2022-08-02 21:40:42', 3, 3, 128, 0, 32787],
        ['2022-08-02 22:00:02', 3, 3, 128, 0, 33810],
        ['2022-08-02 22:02:21', 3, 3, 128, 0, 32787],
        ['2022-08-03 02:45:36', 3, 3, 128, 0, 33810],
        ['2022-08-03 02:45:46', 3, 3, 128, 0, 32787],
        ['2022-08-03 03:13:46', 3, 3, 128, 0, 33810],
        ['2022-08-03 03:14:25', 3, 3, 128, 0, 32787],
        ['2022-08-03 06:00:00', 3, 3, 128, 0, 33810],
        ['2022-08-03 06:01:45', 3, 3, 128, 0, 32787],
        ['2022-08-03 08:41:56', 3, 3, 128, 0, 33810],
        ['2022-08-03 08:42:05', 3, 3, 128, 0, 32787],
        ['2022-08-03 14:31:27', 3, 3, 128, 0, 33810],
        ['2022-08-03 14:31:36', 3, 3, 128, 0, 32787],
        ['2022-08-03 14:55:40', 3, 3, 128, 0, 32787],
        ['2022-08-03 19:14:53', 3, 3, 128, 0, 33810],
        ['2022-08-03 19:15:28', 3, 3, 128, 0, 32787],
        ['2022-08-03 20:40:36', 3, 3, 128, 0, 33810],
        ['2022-08-03 20:41:16', 3, 3, 128, 0, 32787],
        ['2022-08-03 22:00:03', 3, 3, 128, 0, 33810],
        ['2022-08-03 22:02:20', 3, 3, 128, 0, 32787],
        ['2022-08-04 04:14:29', 3, 3, 128, 0, 33810],
        ['2022-08-04 04:14:49', 3, 3, 128, 0, 32787],
        ['2022-08-04 06:00:03', 3, 3, 128, 0, 33810],
        ['2022-08-04 06:01:45', 3, 3, 128, 0, 32787],
        ['2022-08-04 08:17:13', 3, 3, 128, 0, 33810],
        ['2022-08-04 08:17:17', 3, 3, 128, 0, 32787],
        ['2022-08-04 08:34:45', 3, 3, 128, 0, 33810],
        ['2022-08-04 08:35:06', 3, 3, 128, 0, 32787],
        ['2022-08-04 12:45:30', 3, 3, 128, 0, 33810],
        ['2022-08-04 12:46:04', 3, 3, 128, 0, 32787],
        ['2022-08-04 16:05:24', 3, 3, 128, 0, 33810],
        ['2022-08-04 16:05:52', 3, 3, 128, 0, 32787],
        ['2022-08-04 16:28:09', 3, 3, 128, 0, 32787],
        ['2022-08-04 16:30:57', 3, 3, 128, 0, 32851],
        ['2022-08-04 16:32:57', 6, 3, 128, 0, 33810],
        ['2022-08-04 16:36:48', 3, 3, 128, 0, 33810],
        ['2022-08-04 16:38:57', 3, 3, 128, 0, 32787],
        ['2022-08-04 21:50:20', 3, 3, 128, 0, 33810],
        ['2022-08-04 21:50:45', 3, 3, 128, 0, 32787],
        ['2022-08-04 22:00:00', 3, 3, 128, 0, 33810],
        ['2022-08-04 22:02:20', 3, 3, 128, 0, 32787],
        ['2022-08-05 00:47:54', 3, 3, 128, 0, 33810],
        ['2022-08-05 00:47:57', 3, 3, 128, 0, 32787],
        ['2022-08-05 01:50:15', 3, 3, 128, 0, 33810],
        ['2022-08-05 01:50:56', 3, 3, 128, 0, 32787],
        ['2022-08-05 06:00:03', 3, 3, 128, 0, 33810],
        ['2022-08-05 06:01:45', 3, 3, 128, 0, 32787],
        ['2022-08-05 06:45:26', 3, 3, 128, 0, 33810],
        ['2022-08-05 06:46:05', 3, 3, 128, 0, 32787],
        ['2022-08-05 07:27:28', 3, 3, 128, 0, 33810],
        ['2022-08-05 07:27:38', 3, 3, 128, 0, 32787],
        ['2022-08-05 11:57:29', 3, 3, 128, 0, 33810],
        ['2022-08-05 11:57:51', 3, 3, 128, 0, 32787],
        ['2022-08-05 16:50:12', 3, 3, 128, 0, 33810],
        ['2022-08-05 16:50:46', 3, 3, 128, 0, 32787],
        ['2022-08-05 18:45:34', 3, 3, 128, 0, 33810],
        ['2022-08-05 18:46:04', 3, 3, 128, 0, 32787],
        ['2022-08-05 21:58:32', 3, 3, 128, 0, 33810],
        ['2022-08-05 21:58:40', 3, 3, 128, 0, 32787],
        ['2022-08-05 22:00:02', 3, 3, 128, 0, 33810],
        ['2022-08-05 22:02:21', 3, 3, 128, 0, 32787],
        ['2022-08-06 06:00:04', 3, 3, 128, 0, 33810],
        ['2022-08-06 06:01:44', 3, 3, 128, 0, 32787],
        ['2022-08-06 06:02:12', 3, 3, 128, 0, 33810],
        ['2022-08-06 06:02:27', 3, 3, 128, 0, 32787],
        ['2022-08-06 11:22:38', 3, 3, 128, 0, 33810],
        ['2022-08-06 11:23:04', 3, 3, 128, 0, 32787],
        ['2022-08-06 17:32:18', 3, 3, 128, 0, 33810],
        ['2022-08-06 17:32:58', 3, 3, 128, 0, 32787],
        ['2022-08-06 22:00:03', 3, 3, 128, 0, 33810],
        ['2022-08-06 22:02:22', 3, 3, 128, 0, 32787],
        ['2022-08-06 22:30:42', 3, 3, 128, 0, 33810],
        ['2022-08-06 22:30:59', 3, 3, 128, 0, 32787],
        ['2022-08-07 04:51:58', 3, 3, 128, 0, 33810],
        ['2022-08-07 04:52:16', 3, 3, 128, 0, 32787],
        ['2022-08-07 06:00:00', 3, 3, 128, 0, 33810],
        ['2022-08-07 06:01:45', 3, 3, 128, 0, 32787],
        ['2022-08-07 10:59:05', 3, 3, 128, 0, 33810],
        ['2022-08-07 10:59:12', 3, 3, 128, 0, 32787],
        ['2022-08-07 15:27:27', 3, 3, 128, 0, 33810],
        ['2022-08-07 15:28:06', 3, 3, 128, 0, 32787],
        ['2022-08-07 18:52:08', 3, 3, 128, 0, 33810],
        ['2022-08-07 18:52:36', 3, 3, 128, 0, 32787],
        ['2022-08-07 22:00:00', 3, 3, 128, 0, 33810],
        ['2022-08-07 22:02:20', 3, 3, 128, 0, 32787],
        ['2022-08-07 23:25:11', 3, 3, 128, 0, 33810],
        ['2022-08-07 23:25:47', 3, 3, 128, 0, 32787],
        ['2022-08-08 05:23:23', 3, 3, 128, 0, 32787],
        ['2022-08-08 06:00:00', 3, 3, 128, 0, 33810],
        ['2022-08-08 06:01:45', 3, 3, 128, 0, 32787],
        ['2022-08-08 10:12:20', 3, 3, 128, 0, 33810],
        ['2022-08-08 10:12:24', 3, 3, 128, 0, 32787],
        ['2022-08-08 15:47:14', 3, 3, 128, 0, 33810],
        ['2022-08-08 15:47:46', 3, 3, 128, 0, 32787],
        ['2022-08-08 19:27:28', 3, 3, 128, 0, 33810],
        ['2022-08-08 19:27:39', 3, 3, 128, 0, 32787],
        ['2022-08-08 20:49:09', 3, 3, 128, 0, 32787],
        ['2022-08-08 22:00:03', 3, 3, 128, 0, 33810],
        ['2022-08-08 22:02:21', 3, 3, 128, 0, 32787],
        ['2022-08-08 23:47:42', 3, 3, 128, 0, 33810],
        ['2022-08-08 23:48:04', 3, 3, 128, 0, 32787],
        ['2022-08-09 00:21:37', 3, 3, 128, 0, 33810],
        ['2022-08-09 00:22:17', 3, 3, 128, 0, 32787],
        ['2022-08-09 04:45:04', 3, 3, 128, 0, 33810],
        ['2022-08-09 04:45:32', 3, 3, 128, 0, 32787],
        ['2022-08-09 05:27:08', 3, 3, 128, 0, 33810],
        ['2022-08-09 05:27:36', 3, 3, 128, 0, 32787],
        ['2022-08-09 06:00:00', 3, 3, 128, 0, 33810],
        ['2022-08-09 06:01:44', 3, 3, 128, 0, 32787],
        ['2022-08-09 08:47:21', 3, 3, 128, 0, 33810],
        ['2022-08-09 08:47:48', 3, 3, 128, 0, 32787],
        ['2022-08-09 14:13:37', 3, 3, 128, 0, 33810],
        ['2022-08-09 14:13:50', 3, 3, 128, 0, 32787],
        ['2022-08-09 15:55:04', 3, 3, 128, 0, 33810],
        ['2022-08-09 15:55:15', 3, 3, 128, 0, 32787],
        ['2022-08-09 18:20:15', 3, 3, 128, 0, 33810],
        ['2022-08-09 18:20:52', 3, 3, 128, 0, 32787],
        ['2022-08-09 22:00:00', 3, 3, 128, 0, 33810],
        ['2022-08-09 22:02:20', 3, 3, 128, 0, 32787],
        ['2022-08-09 22:08:25', 3, 3, 128, 0, 33810],
        ['2022-08-09 22:08:50', 3, 3, 128, 0, 32787],
        ['2022-08-10 00:27:02', 3, 3, 128, 0, 33810],
        ['2022-08-10 00:27:23', 3, 3, 128, 0, 32787],
        ['2022-08-10 03:35:24', 3, 3, 128, 0, 32787],
        ['2022-08-10 05:39:33', 3, 3, 128, 0, 33810],
        ['2022-08-10 05:40:06', 3, 3, 128, 0, 32787],
        ['2022-08-10 06:00:00', 3, 3, 128, 0, 33810],
        ['2022-08-10 06:01:44', 3, 3, 128, 0, 32787],
        ['2022-08-10 09:14:21', 3, 3, 128, 0, 33810],
        ['2022-08-10 09:14:48', 3, 3, 128, 0, 32787],
        ['2022-08-10 11:22:38', 3, 3, 128, 0, 33810],
        ['2022-08-10 11:23:17', 3, 3, 128, 0, 32787],
        ['2022-08-10 13:49:59', 3, 3, 128, 0, 33810],
        ['2022-08-10 13:50:12', 3, 3, 128, 0, 32787],
        ['2022-08-10 18:27:05', 3, 3, 128, 0, 33810],
        ['2022-08-10 18:27:23', 3, 3, 128, 0, 32787],
        ['2022-08-10 19:38:56', 3, 3, 128, 0, 33810],
        ['2022-08-10 19:39:19', 3, 3, 128, 0, 32787],
        ['2022-08-10 22:00:00', 3, 3, 128, 0, 33810],
        ['2022-08-10 22:02:21', 3, 3, 128, 0, 32787],
        ['2022-08-11 04:14:58', 3, 3, 128, 0, 32787],
        ['2022-08-11 06:00:00', 3, 3, 128, 0, 33810],
        ['2022-08-11 06:01:45', 3, 3, 128, 0, 32787],
        ['2022-08-11 08:25:05', 3, 3, 128, 0, 33810],
        ['2022-08-11 08:25:23', 3, 3, 128, 0, 32787],
        ['2022-08-11 13:46:37', 3, 3, 128, 0, 33810],
        ['2022-08-11 13:47:02', 3, 3, 128, 0, 32787],
        ['2022-08-11 16:54:28', 3, 3, 128, 0, 33810],
        ['2022-08-11 16:54:38', 3, 3, 128, 0, 32787],
        ['2022-08-11 18:07:37', 3, 3, 128, 0, 33810],
        ['2022-08-11 18:08:16', 3, 3, 128, 0, 32787],
        ['2022-08-11 22:00:00', 3, 3, 128, 0, 33810],
        ['2022-08-11 22:02:20', 3, 3, 128, 0, 32787],
        ['2022-08-11 23:43:08', 3, 3, 128, 0, 33810],
        ['2022-08-11 23:43:47', 3, 3, 128, 0, 32787],
        ['2022-08-12 01:17:24', 3, 3, 128, 0, 33810],
        ['2022-08-12 01:17:35', 3, 3, 128, 0, 32787],
        ['2022-08-12 03:42:34', 3, 3, 128, 0, 33810],
        ['2022-08-12 03:43:13', 3, 3, 128, 0, 32787],
        ['2022-08-12 05:09:55', 3, 3, 128, 0, 32787],
        ['2022-08-12 06:00:07', 3, 3, 128, 0, 33810],
        ['2022-08-12 06:01:45', 3, 3, 128, 0, 32787],
        ['2022-08-12 06:09:51', 3, 3, 128, 0, 33810],
        ['2022-08-12 06:10:05', 3, 3, 128, 0, 32787],
        ['2022-08-12 12:10:06', 3, 3, 128, 0, 33810],
        ['2022-08-12 12:10:44', 3, 3, 128, 0, 32787],
        ['2022-08-12 14:45:33', 3, 3, 128, 0, 33810],
        ['2022-08-12 14:46:00', 3, 3, 128, 0, 32787],
        ['2022-08-12 15:01:19', 3, 3, 128, 0, 33810],
        ['2022-08-12 15:01:44', 3, 3, 128, 0, 32787],
        ['2022-08-12 16:19:37', 3, 3, 128, 0, 33810],
        ['2022-08-12 16:20:16', 3, 3, 128, 0, 32787],
        ['2022-08-12 17:41:39', 3, 3, 128, 0, 33810],
        ['2022-08-12 17:41:57', 3, 3, 128, 0, 32787],
        ['2022-08-12 21:38:14', 3, 3, 128, 0, 33810],
        ['2022-08-12 21:38:53', 3, 3, 128, 0, 32787],
        ['2022-08-12 22:00:00', 3, 3, 128, 0, 33810],
        ['2022-08-12 22:02:20', 3, 3, 128, 0, 32787],
        ['2022-08-13 03:23:36', 3, 3, 128, 0, 33810],
        ['2022-08-13 03:24:06', 3, 3, 128, 0, 32787],
        ['2022-08-13 04:05:10', 3, 3, 128, 0, 33810],
        ['2022-08-13 04:05:28', 3, 3, 128, 0, 32787],
        ['2022-08-13 06:00:01', 3, 3, 128, 0, 33810],
        ['2022-08-13 06:01:44', 3, 3, 128, 0, 32787],
        ['2022-08-13 09:00:25', 3, 3, 128, 0, 33810],
        ['2022-08-13 09:01:04', 3, 3, 128, 0, 32787],
        ['2022-08-13 09:57:53', 3, 3, 128, 0, 32787],
        ['2022-08-13 13:05:13', 3, 3, 128, 0, 33810],
        ['2022-08-13 13:05:30', 3, 3, 128, 0, 32787],
        ['2022-08-13 14:05:56', 3, 3, 128, 0, 33810],
        ['2022-08-13 14:06:24', 3, 3, 128, 0, 32787],
        ['2022-08-13 15:11:53', 3, 3, 128, 0, 33810],
        ['2022-08-13 15:12:11', 3, 3, 128, 0, 32787],
        ['2022-08-13 15:33:58', 3, 3, 128, 0, 33810],
        ['2022-08-13 15:34:08', 3, 3, 128, 0, 32787],
        ['2022-08-13 21:04:30', 3, 3, 128, 0, 33810],
        ['2022-08-13 21:04:41', 3, 3, 128, 0, 32787],
        ['2022-08-13 22:00:00', 3, 3, 128, 0, 33810],
        ['2022-08-13 22:02:21', 3, 3, 128, 0, 32787],
        ['2022-08-14 04:39:00', 3, 3, 128, 0, 33810],
        ['2022-08-14 04:39:11', 3, 3, 128, 0, 32787],
        ['2022-08-14 05:22:18', 3, 3, 128, 0, 33810],
        ['2022-08-14 05:22:52', 3, 3, 128, 0, 32787],
        ['2022-08-14 06:00:00', 3, 3, 128, 0, 33810],
        ['2022-08-14 06:01:44', 3, 3, 128, 0, 32787],
        ['2022-08-14 08:12:31', 3, 3, 128, 0, 33810],
        ['2022-08-14 08:13:09', 3, 3, 128, 0, 32787],
        ['2022-08-14 12:42:41', 3, 3, 128, 0, 33810],
        ['2022-08-14 12:43:08', 3, 3, 128, 0, 32787],
        ['2022-08-14 15:12:33', 3, 3, 128, 0, 33810],
        ['2022-08-14 15:13:01', 3, 3, 128, 0, 32787],
        ['2022-08-14 16:56:02', 3, 3, 128, 0, 33810],
        ['2022-08-14 16:56:16', 3, 3, 128, 0, 32787],
        ['2022-08-14 21:13:20', 3, 3, 128, 0, 33810],
        ['2022-08-14 21:13:40', 3, 3, 128, 0, 32787],
        ['2022-08-14 22:00:00', 3, 3, 128, 0, 33810],
        ['2022-08-14 22:02:20', 3, 3, 128, 0, 32787],
        ['2022-08-14 23:04:13', 3, 3, 128, 0, 33810],
        ['2022-08-14 23:04:53', 3, 3, 128, 0, 32787],
        ['2022-08-15 05:32:07', 3, 3, 128, 0, 33810],
        ['2022-08-15 05:32:21', 3, 3, 128, 0, 32787],
        ['2022-08-15 06:00:01', 3, 3, 128, 0, 33810],
        ['2022-08-15 06:01:43', 3, 3, 128, 0, 32787],
        ['2022-08-15 09:21:33', 3, 3, 128, 0, 33810],
        ['2022-08-15 09:21:42', 3, 3, 128, 0, 32787],
        ['2022-08-15 11:52:45', 3, 3, 128, 0, 33810],
        ['2022-08-15 11:53:07', 3, 3, 128, 0, 32787],
        ['2022-08-15 17:50:58', 3, 3, 128, 0, 33810],
        ['2022-08-15 17:51:21', 3, 3, 128, 0, 32787],
        ['2022-08-15 19:17:53', 3, 3, 128, 0, 35858],
        ['2022-08-15 19:18:26', 3, 3, 128, 0, 32787],
        ['2022-08-15 20:33:01', 3, 3, 128, 0, 35858],
        ['2022-08-15 20:33:39', 3, 3, 128, 0, 32787],
        ['2022-08-15 22:00:00', 3, 3, 128, 0, 33810],
        ['2022-08-15 22:02:21', 3, 3, 128, 0, 32787],
        ['2022-08-15 23:50:21', 3, 3, 128, 0, 33810],
        ['2022-08-15 23:50:35', 3, 3, 128, 0, 32787],
        ['2022-08-16 04:39:40', 3, 3, 128, 0, 33810],
        ['2022-08-16 04:40:08', 3, 3, 128, 0, 32787],
        ['2022-08-16 06:00:01', 3, 3, 128, 0, 33810],
        ['2022-08-16 06:01:45', 3, 3, 128, 0, 32787],
        ['2022-08-16 08:48:19', 3, 3, 128, 0, 33810],
        ['2022-08-16 08:49:08', 3, 3, 128, 0, 32787],
        ['2022-08-16 09:35:45', 3, 3, 128, 0, 33810],
        ['2022-08-16 09:35:56', 3, 3, 128, 0, 32787],
        ['2022-08-16 15:25:05', 3, 3, 128, 0, 33810],
        ['2022-08-16 15:25:09', 3, 3, 128, 0, 32787],
        ['2022-08-16 15:44:53', 3, 3, 128, 0, 33810],
        ['2022-08-16 15:44:56', 3, 3, 128, 0, 32787],
        ['2022-08-16 19:23:14', 3, 3, 128, 0, 33810],
        ['2022-08-16 19:23:34', 3, 3, 128, 0, 32787],
        ['2022-08-16 19:33:29', 3, 3, 128, 0, 33810],
        ['2022-08-16 19:33:33', 3, 3, 128, 0, 32787],
        ['2022-08-16 20:16:45', 3, 3, 128, 0, 33810],
        ['2022-08-16 20:17:01', 3, 3, 128, 0, 32787],
        ['2022-08-16 20:57:29', 3, 3, 128, 0, 33810],
        ['2022-08-16 20:57:35', 3, 3, 128, 0, 32787],
        ['2022-08-16 22:00:07', 3, 3, 128, 0, 33810],
        ['2022-08-16 22:02:21', 3, 3, 128, 0, 32787],
        ['2022-08-16 23:33:57', 3, 3, 128, 0, 33810],
        ['2022-08-16 23:34:08', 3, 3, 128, 0, 32787],
        ['2022-08-17 03:56:12', 3, 3, 128, 0, 33810],
        ['2022-08-17 03:56:29', 3, 3, 128, 0, 32787],
        ['2022-08-17 06:00:00', 3, 3, 128, 0, 33810],
        ['2022-08-17 06:01:45', 3, 3, 128, 0, 32787],
        ['2022-08-17 08:00:08', 3, 3, 128, 0, 33810],
        ['2022-08-17 08:00:32', 3, 3, 128, 0, 32787],
        ['2022-08-18 11:30:47', 6, 3, 128, 0, 33810],
        ['2022-08-18 11:30:58', 3, 3, 128, 0, 33810],
        ['2022-08-18 11:31:26', 246, 246, 0, 0, 0],
        ['2022-08-18 11:34:35', 3, 3, 128, 0, 39954],
        ['2022-08-18 11:34:44', 3, 3, 128, 0, 32787],
        ['2022-08-18 11:35:29', 3, 3, 128, 0, 35858],
        ['2022-08-18 11:35:52', 3, 3, 128, 0, 32787],
        ['2022-08-18 16:10:37', 3, 3, 128, 0, 33810],
        ['2022-08-18 16:11:16', 3, 3, 128, 0, 32787],
        ['2022-08-18 18:26:17', 3, 3, 128, 0, 33810],
        ['2022-08-18 18:26:54', 3, 3, 128, 0, 32787],
        ['2022-08-18 22:00:00', 3, 3, 128, 0, 33810],
        ['2022-08-18 22:02:21', 3, 3, 128, 0, 32787],
        ['2022-08-18 23:15:01', 3, 3, 128, 0, 33810],
        ['2022-08-18 23:15:40', 3, 3, 128, 0, 32787],
        ['2022-08-19 04:24:39', 3, 3, 128, 0, 32787],
        ['2022-08-19 06:00:03', 3, 3, 128, 0, 33810],
        ['2022-08-19 06:01:45', 3, 3, 128, 0, 32787],
        ['2022-08-19 07:44:49', 3, 3, 128, 0, 33810],
        ['2022-08-19 07:45:28', 3, 3, 128, 0, 32787],
        ['2022-08-19 09:49:16', 3, 3, 128, 0, 33810],
        ['2022-08-19 09:49:40', 3, 3, 128, 0, 32787],
        ['2022-08-19 11:12:24', 3, 3, 128, 0, 33810],
        ['2022-08-19 11:12:28', 3, 3, 128, 0, 32787],
        ['2022-08-19 11:57:29', 3, 3, 128, 0, 33810],
        ['2022-08-19 11:57:55', 3, 3, 128, 0, 32787],
        ['2022-08-19 12:38:30', 3, 3, 128, 0, 33810],
        ['2022-08-19 12:38:51', 3, 3, 128, 0, 32787],
        ['2022-08-19 18:08:56', 3, 3, 128, 0, 33810],
        ['2022-08-19 18:09:02', 3, 3, 128, 0, 32787],
        ['2022-08-19 21:41:50', 3, 3, 128, 0, 33810],
        ['2022-08-19 21:42:29', 3, 3, 128, 0, 32787],
        ['2022-08-19 22:00:02', 3, 3, 128, 0, 33810],
        ['2022-08-19 22:01:18', 3, 3, 182, 0, 33810],
        ['2022-08-19 22:01:38', 3, 3, 128, 0, 33810],
        ['2022-08-19 22:02:20', 3, 3, 128, 0, 32787],
        ['2022-08-20 02:43:09', 3, 3, 128, 0, 33810],
        ['2022-08-20 02:43:41', 3, 3, 128, 0, 32787],
        ['2022-08-20 03:38:08', 3, 3, 128, 0, 33810],
        ['2022-08-20 03:38:57', 3, 3, 128, 0, 32787],
        ['2022-08-20 06:00:03', 3, 3, 128, 0, 33810],
        ['2022-08-20 06:01:44', 3, 3, 128, 0, 32787],
        ['2022-08-20 06:55:05', 3, 3, 128, 0, 32787],
        ['2022-08-20 10:10:08', 3, 3, 128, 0, 33810],
        ['2022-08-20 10:10:36', 3, 3, 128, 0, 32787],
        ['2022-08-20 12:16:09', 3, 3, 128, 0, 33810],
        ['2022-08-20 12:16:37', 3, 3, 128, 0, 32787],
        ['2022-08-20 17:00:59', 3, 3, 128, 0, 33810],
        ['2022-08-20 17:01:03', 3, 3, 128, 0, 32787],
        ['2022-08-20 18:28:54', 3, 3, 128, 0, 33810],
        ['2022-08-20 18:29:13', 3, 3, 128, 0, 32787],
        ['2022-08-20 21:00:25', 3, 3, 128, 0, 33810],
        ['2022-08-20 21:01:04', 3, 3, 128, 0, 32787],
        ['2022-08-20 22:00:27', 3, 3, 128, 0, 33810],
        ['2022-08-20 22:02:20', 3, 3, 128, 0, 32787],
        ['2022-08-20 22:28:26', 3, 3, 128, 0, 33810],
        ['2022-08-20 22:28:51', 3, 3, 128, 0, 32787],
        ['2022-08-21 04:46:32', 3, 3, 128, 0, 33810],
        ['2022-08-21 04:46:52', 3, 3, 128, 0, 32787],
        ['2022-08-21 06:00:04', 3, 3, 128, 0, 33810],
        ['2022-08-21 06:01:45', 3, 3, 128, 0, 32787],
        ['2022-08-21 09:58:40', 3, 3, 128, 0, 33810],
        ['2022-08-21 09:58:50', 3, 3, 128, 0, 32787],
        ['2022-08-21 11:24:52', 3, 3, 128, 0, 35858],
        ['2022-08-21 11:25:05', 3, 3, 128, 0, 32787],
        ['2022-08-21 14:11:44', 3, 3, 128, 0, 33810],
        ['2022-08-21 14:11:49', 3, 3, 128, 0, 32787],
        ['2022-08-21 17:08:56', 3, 3, 128, 0, 33810],
        ['2022-08-21 17:09:54', 3, 3, 128, 0, 32787],
        ['2022-08-21 17:37:41', 3, 3, 128, 0, 33810],
        ['2022-08-21 17:38:21', 3, 3, 128, 0, 32787],
        ['2022-08-21 19:28:37', 3, 3, 128, 0, 33810],
        ['2022-08-21 19:29:16', 3, 3, 128, 0, 32787],
        ['2022-08-21 22:00:00', 3, 3, 128, 0, 33810],
        ['2022-08-21 22:02:21', 3, 3, 128, 0, 32787],
        ['2022-08-21 22:18:34', 3, 3, 128, 0, 33810],
        ['2022-08-21 22:18:44', 3, 3, 128, 0, 32787],
        ['2022-08-22 04:57:41', 3, 3, 128, 0, 33810],
        ['2022-08-22 04:58:08', 3, 3, 128, 0, 32787],
        ['2022-08-22 06:00:00', 3, 3, 128, 0, 33810],
        ['2022-08-22 06:01:45', 3, 3, 128, 0, 32787],
        ['2022-08-22 07:17:49', 3, 3, 128, 0, 33810],
        ['2022-08-22 07:18:13', 3, 3, 128, 0, 32787],
        ['2022-08-22 07:32:52', 3, 3, 128, 0, 33810],
        ['2022-08-22 07:33:02', 3, 3, 128, 0, 32787],
        ['2022-08-22 08:37:01', 3, 3, 128, 0, 33810],
        ['2022-08-22 08:37:24', 3, 3, 128, 0, 32787],
        ['2022-08-22 11:04:37', 3, 3, 128, 0, 33810],
        ['2022-08-22 11:04:53', 3, 3, 128, 0, 32787],
        ['2022-08-22 12:26:04', 3, 3, 128, 0, 33810],
        ['2022-08-22 12:26:16', 3, 3, 128, 0, 32787],
        ['2022-08-22 15:25:38', 3, 3, 128, 0, 33810],
        ['2022-08-22 15:26:12', 3, 3, 128, 0, 32787],
        ['2022-08-22 18:53:03', 3, 3, 144, 0, 50274],
        ['2022-08-22 18:53:54', 3, 3, 128, 0, 50194],
        ['2022-08-22 20:24:25', 3, 3, 128, 0, 49171],
        ['2022-08-22 21:00:00', 3, 3, 128, 0, 50274],
        ['2022-08-22 21:00:20', 3, 3, 144, 0, 50274],
        ['2022-08-22 21:14:30', 3, 3, 128, 0, 50194],
        ['2022-08-22 21:15:53', 3, 3, 144, 0, 49251],
        ['2022-08-22 21:16:13', 3, 3, 144, 0, 50274],
        ['2022-08-22 21:26:52', 3, 3, 128, 0, 50194],
        ['2022-08-22 21:27:27', 3, 3, 144, 0, 50274],
        ['2022-08-22 21:28:44', 3, 3, 144, 0, 49251],
        ['2022-08-22 21:39:25', 3, 3, 128, 0, 50194],
        ['2022-08-22 21:43:13', 3, 3, 144, 0, 50274],
        ['2022-08-22 21:43:17', 3, 3, 128, 0, 49251],
        ['2022-08-22 21:43:30', 3, 3, 144, 0, 50274],
        ['2022-08-22 21:44:17', 3, 3, 144, 0, 49251],
        ['2022-08-22 21:56:16', 3, 3, 128, 0, 50194],
        ['2022-08-22 22:00:19', 3, 3, 144, 0, 50274],
        ['2022-08-22 22:02:59', 3, 3, 144, 0, 50274],
        ['2022-08-22 22:03:06', 3, 3, 144, 0, 50274],
        ['2022-08-22 23:00:00', 3, 3, 144, 0, 49251],
        ['2022-08-22 23:03:55', 3, 3, 144, 0, 50274],
        ['2022-08-22 23:47:11', 3, 3, 182, 0, 49251],
        ['2022-08-22 23:47:34', 3, 3, 144, 0, 50274],
        ['2022-08-23 00:00:00', 3, 3, 144, 0, 49251],
        ['2022-08-23 00:51:21', 3, 3, 144, 0, 50274],
        ['2022-08-23 00:51:29', 3, 3, 144, 0, 49251],
        ['2022-08-23 01:00:00', 3, 3, 144, 0, 50274],
        ['2022-08-23 01:00:41', 3, 3, 144, 0, 49251],
        ['2022-08-23 02:06:02', 3, 3, 144, 0, 50274],
        ['2022-08-23 02:06:15', 3, 3, 144, 0, 49251],
        ['2022-08-23 02:34:18', 3, 3, 144, 0, 50274],
        ['2022-08-23 02:34:24', 3, 3, 144, 0, 49251],
        ['2022-08-23 03:05:59', 3, 3, 144, 0, 50274],
        ['2022-08-23 04:00:00', 3, 3, 144, 0, 49251],
        ['2022-08-23 05:31:37', 3, 3, 144, 0, 50274],
        ['2022-08-23 05:31:49', 3, 3, 144, 0, 49251],
        ['2022-08-23 05:54:49', 3, 3, 144, 0, 50274],
        ['2022-08-23 06:00:02', 3, 3, 144, 0, 49251],
        ['2022-08-23 07:00:01', 3, 3, 144, 0, 49251],
        ['2022-08-23 07:44:57', 3, 3, 144, 0, 50274],
        ['2022-08-23 07:45:09', 3, 3, 144, 0, 49251],
        ['2022-08-23 09:00:01', 3, 3, 144, 0, 49251],
        ['2022-08-23 09:57:50', 3, 3, 144, 0, 50274],
        ['2022-08-23 09:57:58', 3, 3, 144, 0, 49251],
        ['2022-08-23 11:00:00', 3, 3, 144, 0, 50274],
        ['2022-08-23 11:00:04', 3, 3, 144, 0, 49251],
        ['2022-08-23 11:18:19', 3, 3, 144, 0, 50274],
        ['2022-08-23 11:18:42', 3, 3, 144, 0, 49251],
        ['2022-08-23 13:00:00', 3, 3, 144, 0, 50274],
        ['2022-08-23 14:00:37', 3, 3, 144, 0, 49251],
        ['2022-08-23 15:00:03', 3, 3, 144, 0, 50274],
        ['2022-08-23 15:36:23', 3, 3, 144, 0, 49251],
        ['2022-08-23 15:40:33', 3, 3, 144, 0, 49251],
        ['2022-08-23 15:40:36', 3, 3, 144, 0, 50274],
        ['2022-08-23 16:00:00', 3, 3, 144, 0, 49251],
        ['2022-08-23 17:00:04', 3, 3, 144, 0, 50274],
        ['2022-08-23 17:34:49', 3, 3, 144, 0, 49251],
        ['2022-08-23 17:39:10', 3, 3, 144, 0, 49251],
        ['2022-08-23 17:39:50', 3, 3, 144, 0, 50274],
        ['2022-08-23 17:42:59', 3, 3, 144, 0, 49251],
        ['2022-08-23 17:46:59', 3, 3, 144, 0, 50274],
        ['2022-08-23 17:47:02', 3, 3, 144, 0, 50274],
        ['2022-08-23 17:50:59', 3, 3, 144, 0, 49251],
        ['2022-08-23 17:51:59', 3, 3, 144, 0, 49251],
        ['2022-08-23 17:52:59', 3, 3, 144, 0, 49251],
        ['2022-08-23 17:53:33', 3, 3, 144, 0, 49251],
        ['2022-08-23 18:04:59', 3, 3, 144, 0, 49251],
        ['2022-08-23 18:05:06', 3, 3, 144, 0, 50274],
        ['2022-08-23 18:07:27', 3, 3, 144, 0, 49251],
        ['2022-08-23 18:51:08', 3, 3, 144, 0, 50274],
        ['2022-08-23 18:51:20', 3, 3, 144, 0, 49251],
        ['2022-08-23 19:51:10', 3, 3, 144, 0, 50274],
        ['2022-08-23 19:51:33', 3, 3, 144, 0, 49251],
        ['2022-08-23 20:05:59', 3, 3, 144, 0, 50274],
        ['2022-08-23 20:22:11', 3, 3, 144, 0, 49251],
        ['2022-08-23 20:50:49', 3, 3, 144, 0, 50274],
        ['2022-08-23 20:51:10', 3, 3, 144, 0, 49251],
        ['2022-08-23 21:03:16', 3, 3, 144, 0, 50274],
        ['2022-08-23 21:32:12', 3, 3, 128, 0, 49251],
        ['2022-08-23 21:32:25', 3, 3, 144, 0, 50274],
        ['2022-08-23 21:41:15', 3, 3, 128, 0, 50194],
        ['2022-08-23 21:45:27', 3, 3, 144, 0, 50274],
        ['2022-08-23 21:49:01', 3, 3, 128, 0, 50194],
        ['2022-08-23 22:00:06', 3, 3, 128, 0, 49171],
        ['2022-08-23 22:00:39', 3, 3, 128, 0, 50194],
        ['2022-08-23 22:01:17', 3, 3, 128, 0, 49171],
        ['2022-08-23 22:46:57', 3, 3, 128, 0, 50194],
        ['2022-08-23 22:47:24', 3, 3, 128, 0, 49171],
        ['2022-08-24 03:06:01', 3, 3, 128, 0, 52242],
        ['2022-08-24 03:06:06', 3, 3, 128, 0, 49171],
        ['2022-08-24 03:21:38', 3, 3, 128, 0, 50194],
        ['2022-08-24 03:22:05', 3, 3, 128, 0, 49171],
        ['2022-08-24 06:00:03', 3, 3, 128, 0, 50194],
        ['2022-08-24 06:01:44', 3, 3, 128, 0, 49171],
        ['2022-08-24 07:37:26', 3, 3, 128, 0, 50194],
        ['2022-08-24 07:37:36', 3, 3, 128, 0, 49171],
        ['2022-08-24 12:49:00', 3, 3, 128, 0, 50194],
        ['2022-08-24 12:49:11', 3, 3, 128, 0, 49171],
        ['2022-08-24 18:32:49', 3, 3, 128, 0, 50194],
        ['2022-08-24 18:33:28', 3, 3, 128, 0, 49171],
        ['2022-08-24 22:00:03', 3, 3, 128, 0, 50194],
        ['2022-08-24 22:02:20', 3, 3, 128, 0, 49171],
        ['2022-08-24 23:19:48', 6, 3, 128, 0, 49250],
        ['2022-08-24 23:22:12', 3, 3, 128, 0, 50194],
        ['2022-08-24 23:22:22', 3, 3, 128, 0, 49171],
        ['2022-08-24 23:29:47', 6, 3, 128, 0, 49250],
        ['2022-08-25 12:47:57', 3, 3, 128, 0, 50194],
        ['2022-08-25 12:48:37', 3, 3, 128, 0, 49171],
        ['2022-08-25 14:39:05', 3, 3, 128, 0, 50194],
        ['2022-08-25 14:39:31', 3, 3, 128, 0, 49171],
        ['2022-08-25 14:52:51', 3, 3, 128, 0, 50194],
        ['2022-08-25 14:52:55', 3, 3, 128, 0, 49171],
        ['2022-08-25 15:00:03', 3, 3, 128, 0, 50194],
        ['2022-08-25 15:01:45', 3, 3, 128, 0, 49171],
        ['2022-08-25 15:11:44', 3, 3, 128, 0, 50194],
        ['2022-08-25 15:11:58', 3, 3, 128, 0, 49171],
        ['2022-08-25 17:18:24', 3, 3, 128, 0, 50194],
        ['2022-08-25 17:18:32', 3, 3, 128, 0, 49171],
        ['2022-08-25 21:23:50', 3, 3, 128, 0, 49171],
        ['2022-08-25 21:53:32', 3, 3, 128, 0, 50194],
        ['2022-08-25 21:53:56', 3, 3, 128, 0, 49171],
        ['2022-08-25 22:00:00', 3, 3, 128, 0, 50194],
        ['2022-08-25 22:02:20', 3, 3, 128, 0, 49171],
        ['2022-08-26 01:07:45', 3, 3, 128, 0, 50194],
        ['2022-08-26 01:08:24', 3, 3, 128, 0, 49171],
        ['2022-08-26 06:00:03', 3, 3, 128, 0, 50194],
        ['2022-08-26 06:01:44', 3, 3, 128, 0, 49171],
        ['2022-08-26 06:16:17', 3, 3, 128, 0, 50194],
        ['2022-08-26 06:16:36', 3, 3, 128, 0, 49171],
        ['2022-08-26 07:26:43', 3, 3, 128, 0, 52242],
        ['2022-08-26 07:26:48', 3, 3, 128, 0, 49171],
        ['2022-08-26 11:44:54', 3, 3, 128, 0, 50194],
        ['2022-08-26 11:45:33', 3, 3, 128, 0, 49171],
        ['2022-08-26 14:34:44', 3, 3, 128, 0, 50194],
        ['2022-08-26 14:35:04', 3, 3, 128, 0, 49171],
        ['2022-08-26 17:04:38', 3, 3, 128, 0, 50194],
        ['2022-08-26 17:05:17', 3, 3, 128, 0, 49171],
        ['2022-08-26 17:35:53', 3, 3, 128, 0, 50194],
        ['2022-08-26 17:36:10', 3, 3, 128, 0, 49171],
        ['2022-08-26 18:36:25', 3, 3, 128, 0, 50194],
        ['2022-08-26 18:36:32', 3, 3, 128, 0, 49171],
        ['2022-08-26 19:30:13', 3, 3, 128, 0, 52242],
        ['2022-08-26 19:30:24', 3, 3, 128, 0, 49171],
        ['2022-08-26 20:10:01', 3, 3, 128, 0, 50194],
        ['2022-08-26 20:10:34', 3, 3, 128, 0, 49171],
        ['2022-08-26 22:00:02', 3, 3, 128, 0, 50194],
        ['2022-08-26 22:02:20', 3, 3, 128, 0, 49171],
        ['2022-08-26 22:27:03', 3, 3, 128, 0, 49171],
        ['2022-08-27 00:05:41', 3, 3, 128, 0, 50194],
        ['2022-08-27 00:06:06', 3, 3, 128, 0, 49171],
        ['2022-08-27 01:47:21', 3, 3, 128, 0, 50194],
        ['2022-08-27 01:48:00', 3, 3, 128, 0, 49171],
        ['2022-08-27 06:00:00', 3, 3, 128, 0, 50194],
        ['2022-08-27 06:01:45', 3, 3, 128, 0, 49171],
        ['2022-08-27 08:01:41', 3, 3, 128, 0, 50194],
        ['2022-08-27 08:02:09', 3, 3, 128, 0, 49171],
        ['2022-08-27 08:50:49', 3, 3, 128, 0, 52242],
        ['2022-08-27 08:50:56', 3, 3, 128, 0, 49171],
        ['2022-08-27 14:45:33', 3, 3, 128, 0, 50194],
        ['2022-08-27 14:45:56', 3, 3, 128, 0, 49171],
        ['2022-08-27 17:37:02', 3, 3, 128, 0, 50194],
        ['2022-08-27 17:37:09', 3, 3, 128, 0, 49171],
        ['2022-08-27 18:02:13', 3, 3, 128, 0, 49171],
        ['2022-08-27 22:00:00', 3, 3, 128, 0, 50194],
        ['2022-08-27 22:02:20', 3, 3, 128, 0, 49171],
        ['2022-08-27 22:38:02', 3, 3, 128, 0, 50194],
        ['2022-08-27 22:38:20', 3, 3, 128, 0, 49171],
        ['2022-08-27 23:28:45', 3, 3, 128, 0, 50194],
        ['2022-08-27 23:29:24', 3, 3, 128, 0, 49171],
        ['2022-08-28 01:19:21', 3, 3, 128, 0, 50194],
        ['2022-08-28 01:19:52', 3, 3, 128, 0, 49171],
        ['2022-08-28 06:00:04', 3, 3, 128, 0, 50194],
        ['2022-08-28 06:01:45', 3, 3, 128, 0, 49171],
        ['2022-08-28 06:15:16', 3, 3, 128, 0, 50194],
        ['2022-08-28 06:15:29', 3, 3, 128, 0, 49171],
        ['2022-08-28 07:03:25', 3, 3, 128, 0, 50194],
        ['2022-08-28 07:03:45', 3, 3, 128, 0, 49171],
        ['2022-08-28 07:40:32', 3, 3, 128, 0, 49171],
        ['2022-08-28 08:11:21', 3, 3, 128, 0, 50194],
        ['2022-08-28 08:11:49', 3, 3, 128, 0, 49171],
        ['2022-08-28 13:29:43', 3, 3, 128, 0, 50194],
        ['2022-08-28 13:29:57', 3, 3, 128, 0, 49171],
        ['2022-08-28 15:15:29', 3, 3, 128, 0, 50194],
        ['2022-08-28 15:15:36', 3, 3, 128, 0, 49171],
        ['2022-08-28 16:10:01', 3, 3, 128, 0, 49171],
        ['2022-08-28 20:15:29', 3, 3, 128, 0, 50194],
        ['2022-08-28 20:16:03', 3, 3, 128, 0, 49171],
        ['2022-08-28 22:00:00', 3, 3, 128, 0, 50194],
        ['2022-08-28 22:02:20', 3, 3, 128, 0, 49171],
        ['2022-08-28 23:53:59', 3, 3, 128, 0, 49171],
        ['2022-08-28 23:54:59', 3, 3, 128, 0, 49171],
        ['2022-08-28 23:55:59', 3, 3, 128, 0, 49171],
        ['2022-08-28 23:56:07', 3, 3, 128, 0, 49171],
        ['2022-08-29 00:46:10', 3, 3, 128, 0, 50194],
        ['2022-08-29 00:46:38', 3, 3, 128, 0, 49171],
        ['2022-08-29 04:25:45', 3, 3, 128, 0, 50194],
        ['2022-08-29 04:26:20', 3, 3, 128, 0, 49171],
        ['2022-08-29 04:38:21', 3, 3, 128, 0, 50194],
        ['2022-08-29 04:39:00', 3, 3, 128, 0, 49171],
        ['2022-08-29 06:00:02', 3, 3, 128, 0, 50194],
        ['2022-08-29 06:01:44', 3, 3, 128, 0, 49171],
        ['2022-08-29 06:43:37', 3, 3, 128, 0, 50194],
        ['2022-08-29 06:43:54', 3, 3, 128, 0, 49171],
        ['2022-08-29 09:22:01', 3, 3, 128, 0, 50194],
        ['2022-08-29 09:22:15', 3, 3, 128, 0, 49171],
        ['2022-08-29 09:40:04', 3, 3, 128, 0, 50194],
        ['2022-08-29 09:40:40', 3, 3, 128, 0, 49171],
        ['2022-08-29 10:14:43', 3, 3, 128, 0, 50194],
        ['2022-08-29 10:14:52', 3, 3, 128, 0, 49171],
        ['2022-08-29 15:24:31', 3, 3, 128, 0, 50194],
        ['2022-08-29 15:25:28', 3, 3, 128, 0, 49171],
        ['2022-08-29 18:55:17', 3, 3, 128, 0, 50194],
        ['2022-08-29 18:55:43', 3, 3, 128, 0, 49171],
        ['2022-08-29 22:00:03', 3, 3, 128, 0, 50194],
        ['2022-08-29 22:02:20', 3, 3, 128, 0, 49171],
        ['2022-08-29 23:15:33', 3, 3, 128, 0, 50194],
        ['2022-08-29 23:15:40', 3, 3, 128, 0, 49171],
        ['2022-08-29 23:32:22', 3, 3, 128, 0, 49171],
        ['2022-08-30 05:09:37', 3, 3, 128, 0, 50194],
        ['2022-08-30 05:09:49', 3, 3, 128, 0, 49171],
        ['2022-08-30 06:00:02', 3, 3, 128, 0, 50194],
        ['2022-08-30 06:01:44', 3, 3, 128, 0, 49171],
        ['2022-08-30 07:21:05', 3, 3, 128, 0, 50194],
        ['2022-08-30 07:22:05', 3, 3, 128, 0, 49171],
        ['2022-08-30 08:29:21', 3, 3, 128, 0, 50194],
        ['2022-08-30 08:29:48', 3, 3, 128, 0, 49171],
        ['2022-08-30 14:24:24', 3, 3, 128, 0, 50194],
        ['2022-08-30 14:25:03', 3, 3, 128, 0, 49171],
        ['2022-08-30 15:17:18', 3, 3, 128, 0, 50194],
        ['2022-08-30 15:17:40', 3, 3, 128, 0, 49171],
        ['2022-08-30 16:37:50', 3, 3, 128, 0, 50194],
        ['2022-08-30 16:38:15', 3, 3, 128, 0, 49171],
        ['2022-08-30 18:51:28', 3, 3, 128, 0, 50194],
        ['2022-08-30 18:51:38', 3, 3, 128, 0, 49171],
        ['2022-08-30 19:23:12', 3, 3, 128, 0, 50194],
        ['2022-08-30 19:23:51', 3, 3, 128, 0, 49171],
        ['2022-08-30 22:00:00', 3, 3, 128, 0, 50194],
        ['2022-08-30 22:02:20', 3, 3, 128, 0, 49171],
        ['2022-08-30 22:38:21', 3, 3, 128, 0, 50194],
        ['2022-08-30 22:39:00', 3, 3, 128, 0, 49171],
        ['2022-08-31 05:24:59', 3, 3, 128, 0, 49171],
        ['2022-08-31 05:25:59', 3, 3, 128, 0, 49171],
        ['2022-08-31 05:26:59', 3, 3, 128, 0, 49171],
        ['2022-08-31 05:27:59', 3, 3, 128, 0, 49171],
        ['2022-08-31 05:28:49', 3, 3, 128, 0, 49171],
        ['2022-08-31 06:00:00', 3, 3, 128, 0, 50194],
        ['2022-08-31 06:01:44', 3, 3, 128, 0, 49171],
        ['2022-08-31 06:07:10', 3, 3, 128, 0, 50194],
        ['2022-08-31 06:07:17', 3, 3, 128, 0, 49171],
        ['2022-08-31 10:24:35', 3, 3, 128, 0, 50194],
        ['2022-08-31 10:25:00', 3, 3, 128, 0, 49171],
        ['2022-08-31 10:32:30', 3, 3, 128, 0, 50194],
        ['2022-08-31 10:32:52', 3, 3, 128, 0, 49171],
        ['2022-08-31 15:49:41', 3, 3, 128, 0, 50194],
        ['2022-08-31 15:50:10', 3, 3, 128, 0, 49171],
        ['2022-08-31 19:30:52', 3, 3, 128, 0, 50194],
        ['2022-08-31 19:31:04', 3, 3, 128, 0, 49171],
        ['2022-08-31 19:35:49', 3, 3, 128, 0, 50194],
        ['2022-08-31 19:36:28', 3, 3, 128, 0, 49171],
        ['2022-08-31 22:00:00', 3, 3, 128, 0, 50194],
        ['2022-08-31 22:02:21', 3, 3, 128, 0, 49171],
        ['2022-09-01 03:38:51', 3, 3, 128, 0, 50194],
        ['2022-09-01 03:38:58', 3, 3, 128, 0, 49171],
        ['2022-09-01 05:50:21', 3, 3, 128, 0, 50194],
        ['2022-09-01 05:50:30', 3, 3, 128, 0, 49171],
        ['2022-09-01 06:00:02', 3, 3, 128, 0, 50194],
        ['2022-09-01 06:01:45', 3, 3, 128, 0, 49171],
        ['2022-09-01 10:10:52', 3, 3, 128, 0, 50194],
        ['2022-09-01 10:11:16', 3, 3, 128, 0, 49171],
        ['2022-09-01 12:59:21', 3, 3, 128, 0, 50194],
        ['2022-09-01 12:59:48', 3, 3, 128, 0, 49171],
        ['2022-09-01 14:59:57', 3, 3, 128, 0, 50194],
        ['2022-09-01 15:00:00', 3, 3, 128, 0, 49171],
        ['2022-09-01 17:43:17', 3, 3, 128, 0, 50194],
        ['2022-09-01 17:43:45', 3, 3, 128, 0, 49171],
        ['2022-09-01 22:00:00', 3, 3, 128, 0, 50192],
        ['2022-09-01 22:02:20', 3, 3, 128, 0, 49169],
        ['2022-09-01 23:30:04', 3, 3, 128, 0, 50192],
        ['2022-09-01 23:30:32', 3, 3, 128, 0, 49169],
        ['2022-09-02 05:31:00', 3, 3, 128, 0, 50192],
        ['2022-09-02 05:31:12', 3, 3, 128, 0, 49169],
        ['2022-09-02 06:00:00', 3, 3, 128, 0, 50192],
        ['2022-09-02 06:01:45', 3, 3, 128, 0, 49169],
        ['2022-09-02 08:40:36', 3, 3, 128, 0, 50192],
        ['2022-09-02 08:41:02', 3, 3, 128, 0, 49169],
        ['2022-09-02 14:56:49', 3, 3, 128, 0, 50192],
        ['2022-09-02 14:57:12', 3, 3, 128, 0, 49169],
        ['2022-09-02 15:00:10', 3, 3, 128, 0, 50192],
        ['2022-09-02 15:00:24', 3, 3, 128, 0, 49169],
        ['2022-09-02 18:02:08', 3, 3, 128, 0, 52240],
        ['2022-09-02 18:02:13', 3, 3, 128, 0, 49169],
        ['2022-09-02 18:54:47', 3, 3, 128, 0, 50192],
        ['2022-09-02 18:55:04', 3, 3, 128, 0, 49169],
        ['2022-09-02 19:19:09', 3, 3, 128, 0, 52240],
        ['2022-09-02 19:19:31', 3, 3, 128, 0, 49169],
        ['2022-09-02 21:52:08', 3, 3, 128, 0, 50192],
        ['2022-09-02 21:52:36', 3, 3, 128, 0, 49169],
        ['2022-09-02 22:00:00', 3, 3, 128, 0, 50192],
        ['2022-09-02 22:02:20', 3, 3, 128, 0, 49169],
        ['2022-09-02 23:47:24', 3, 3, 128, 0, 50192],
        ['2022-09-02 23:47:35', 3, 3, 128, 0, 49169],
        ['2022-09-03 03:32:26', 3, 3, 128, 0, 50192],
        ['2022-09-03 03:32:34', 3, 3, 128, 0, 49169],
        ['2022-09-03 04:43:51', 3, 3, 128, 0, 50192],
        ['2022-09-03 04:44:24', 3, 3, 128, 0, 49169],
        ['2022-09-03 05:34:09', 3, 3, 128, 0, 51217],
        ['2022-09-03 06:00:02', 3, 3, 128, 0, 50194],
        ['2022-09-03 06:01:44', 3, 3, 128, 0, 49169],
        ['2022-09-03 08:39:29', 3, 3, 128, 0, 50192],
        ['2022-09-03 08:40:08', 3, 3, 128, 0, 49169],
        ['2022-09-03 13:28:33', 3, 3, 128, 0, 50192],
        ['2022-09-03 13:29:16', 3, 3, 128, 0, 49169],
        ['2022-09-03 14:53:42', 3, 3, 128, 0, 50192],
        ['2022-09-03 14:53:51', 3, 3, 128, 0, 49169],
        ['2022-09-03 20:11:49', 3, 3, 128, 0, 50192],
        ['2022-09-03 20:12:27', 3, 3, 128, 0, 49169],
        ['2022-09-03 22:00:00', 3, 3, 128, 0, 50192],
        ['2022-09-03 22:02:21', 3, 3, 128, 0, 49169],
        ['2022-09-03 22:43:17', 3, 3, 128, 0, 50192],
        ['2022-09-03 22:43:44', 3, 3, 128, 0, 49169],
        ['2022-09-04 04:23:56', 3, 3, 128, 0, 50192],
        ['2022-09-04 04:24:28', 3, 3, 128, 0, 49169],
        ['2022-09-04 06:00:00', 3, 3, 128, 0, 50192],
        ['2022-09-04 06:01:44', 3, 3, 128, 0, 49169],
        ['2022-09-04 06:28:14', 3, 3, 128, 0, 50192],
        ['2022-09-04 06:28:44', 3, 3, 128, 0, 49169],
        ['2022-09-04 08:14:09', 3, 3, 128, 0, 50192],
        ['2022-09-04 08:14:16', 3, 3, 128, 0, 49169],
        ['2022-09-04 11:00:48', 3, 3, 128, 0, 49169],
        ['2022-09-04 12:28:05', 3, 3, 128, 0, 50192],
        ['2022-09-04 12:28:36', 3, 3, 128, 0, 49169],
        ['2022-09-04 15:07:38', 3, 3, 128, 0, 50192],
        ['2022-09-04 15:08:00', 3, 3, 128, 0, 49169],
        ['2022-09-04 20:04:36', 3, 3, 128, 0, 50192],
        ['2022-09-04 20:05:03', 3, 3, 128, 0, 49169],
        ['2022-09-04 22:00:00', 3, 3, 128, 0, 50192],
        ['2022-09-04 22:02:21', 3, 3, 128, 0, 49169],
        ['2022-09-05 05:00:37', 3, 3, 128, 0, 50192],
        ['2022-09-05 05:00:44', 3, 3, 128, 0, 49169],
        ['2022-09-05 06:00:03', 3, 3, 128, 0, 50194],
        ['2022-09-05 06:01:44', 3, 3, 128, 0, 49169],
        ['2022-09-05 10:08:49', 3, 3, 128, 0, 50192],
        ['2022-09-05 10:09:29', 3, 3, 128, 0, 49169],
        ['2022-09-05 11:38:22', 3, 3, 128, 0, 50192],
        ['2022-09-05 11:38:45', 3, 3, 128, 0, 49169],
        ['2022-09-05 16:46:39', 3, 3, 128, 0, 50192],
        ['2022-09-05 16:47:16', 3, 3, 128, 0, 49169],
        ['2022-09-05 19:53:09', 3, 3, 128, 0, 50192],
        ['2022-09-05 19:53:19', 3, 3, 128, 0, 49169],
        ['2022-09-05 22:00:00', 3, 3, 128, 0, 50192],
        ['2022-09-05 22:02:20', 3, 3, 128, 0, 49169],
        ['2022-09-05 22:03:27', 3, 3, 128, 0, 50192],
        ['2022-09-05 22:03:40', 3, 3, 128, 0, 49169],
        ['2022-09-06 01:25:25', 3, 3, 128, 0, 50192],
        ['2022-09-06 01:25:44', 3, 3, 128, 0, 49169],
        ['2022-09-06 02:37:41', 3, 3, 128, 0, 50192],
        ['2022-09-06 02:37:45', 3, 3, 128, 0, 49169],
        ['2022-09-06 02:42:41', 3, 3, 128, 0, 50192],
        ['2022-09-06 02:43:08', 3, 3, 128, 0, 49169],
        ['2022-09-06 03:16:18', 3, 3, 128, 0, 50192],
        ['2022-09-06 03:16:53', 3, 3, 128, 0, 49169],
        ['2022-09-06 05:36:41', 3, 3, 128, 0, 50192],
        ['2022-09-06 05:37:17', 3, 3, 128, 0, 49169],
        ['2022-09-06 06:00:00', 3, 3, 128, 0, 50192],
        ['2022-09-06 06:01:04', 3, 3, 128, 0, 49169],
        ['2022-09-06 07:09:29', 3, 3, 128, 0, 50192],
        ['2022-09-06 07:10:08', 3, 3, 128, 0, 49169],
        ['2022-09-06 09:32:48', 3, 3, 128, 0, 49169],
        ['2022-09-06 09:45:24', 3, 3, 128, 0, 50192],
        ['2022-09-06 09:45:38', 3, 3, 128, 0, 49169],
        ['2022-09-06 10:30:12', 3, 3, 128, 0, 50192],
        ['2022-09-06 10:30:24', 3, 3, 128, 0, 49169],
        ['2022-09-06 12:21:24', 6, 3, 128, 0, 50194],
        ['2022-09-06 12:22:48', 3, 3, 128, 0, 50192],
        ['2022-09-06 12:23:49', 3, 3, 128, 0, 49169],
        ['2022-09-06 12:46:59', 3, 3, 128, 0, 54290],
        ['2022-09-06 12:47:00', 6, 3, 128, 0, 50194],
        ['2022-09-06 12:47:37', 3, 3, 128, 0, 50256],
        ['2022-09-06 12:47:47', 3, 3, 128, 0, 49233],
        ['2022-09-06 12:48:05', 6, 3, 128, 0, 50192],
        ['2022-09-06 12:49:47', 3, 3, 128, 0, 50192],
        ['2022-09-06 12:50:48', 3, 3, 128, 0, 49169],
        ['2022-09-06 13:49:50', 3, 3, 128, 0, 49233],
        ['2022-09-06 13:50:34', 6, 3, 128, 0, 50194],
        ['2022-09-06 13:51:02', 3, 3, 128, 0, 50192],
        ['2022-09-06 13:51:31', 3, 3, 128, 0, 49169],
        ['2022-09-06 13:51:57', 3, 3, 128, 0, 49233],
        ['2022-09-06 13:54:40', 6, 3, 128, 0, 50194],
        ['2022-09-06 13:56:24', 3, 3, 128, 0, 50192],
        ['2022-09-06 13:57:25', 3, 3, 128, 0, 49169],
        ['2022-09-06 16:32:13', 3, 3, 128, 0, 50192],
        ['2022-09-06 16:32:32', 3, 3, 128, 0, 49169],
        ['2022-09-06 22:00:03', 3, 3, 128, 0, 50194],
        ['2022-09-06 22:02:22', 3, 3, 128, 0, 49169],
        ['2022-09-06 22:06:10', 3, 3, 128, 0, 50192],
        ['2022-09-06 22:06:38', 3, 3, 128, 0, 49169],
        ['2022-09-06 23:16:28', 3, 3, 128, 0, 50192],
        ['2022-09-06 23:16:48', 3, 3, 128, 0, 49169],
        ['2022-09-07 02:57:33', 3, 3, 128, 0, 50192],
        ['2022-09-07 02:58:12', 3, 3, 128, 0, 49169],
        ['2022-09-07 05:19:48', 3, 3, 128, 0, 49169],
        ['2022-09-07 06:00:02', 3, 3, 128, 0, 50194],
        ['2022-09-07 06:01:44', 3, 3, 128, 0, 49169],
        ['2022-09-07 06:51:47', 3, 3, 128, 0, 52240],
        ['2022-09-07 06:51:53', 3, 3, 128, 0, 49169],
        ['2022-09-07 09:05:33', 3, 3, 128, 0, 50192],
        ['2022-09-07 09:05:48', 3, 3, 128, 0, 49169],
        ['2022-09-07 11:18:13', 3, 3, 128, 0, 50192],
        ['2022-09-07 11:18:32', 3, 3, 128, 0, 49169],
        ['2022-09-07 16:22:09', 3, 3, 128, 0, 50192],
        ['2022-09-07 16:22:44', 3, 3, 128, 0, 49169],
        ['2022-09-07 18:52:37', 3, 3, 128, 0, 49169],
        ['2022-09-07 22:00:00', 3, 3, 128, 0, 50192],
        ['2022-09-07 22:02:20', 3, 3, 128, 0, 49169],
        ['2022-09-07 22:05:40', 3, 3, 128, 0, 50192],
        ['2022-09-07 22:05:56', 3, 3, 128, 0, 49169],
        ['2022-09-08 01:13:09', 3, 3, 128, 0, 50192],
        ['2022-09-08 01:13:45', 3, 3, 128, 0, 49169],
        ['2022-09-08 04:39:41', 3, 3, 128, 0, 50192],
        ['2022-09-08 04:40:09', 3, 3, 128, 0, 49169],
        ['2022-09-08 04:59:57', 3, 3, 128, 0, 50192],
        ['2022-09-08 05:00:00', 3, 3, 128, 0, 49169],
        ['2022-09-08 06:00:00', 3, 3, 128, 0, 50192],
        ['2022-09-08 06:01:45', 3, 3, 128, 0, 49169],
        ['2022-09-08 10:31:07', 3, 3, 128, 0, 49169],
        ['2022-09-08 12:13:41', 3, 3, 128, 0, 50192],
        ['2022-09-08 12:14:04', 3, 3, 128, 0, 49169],
        ['2022-09-08 18:03:34', 3, 3, 128, 0, 50194],
        ['2022-09-08 18:04:32', 3, 3, 128, 0, 49171],
        ['2022-09-08 22:00:00', 3, 3, 128, 0, 50194],
        ['2022-09-08 22:03:00', 3, 3, 128, 0, 49171],
        ['2022-09-08 22:30:52', 3, 3, 128, 0, 50194],
        ['2022-09-08 22:31:08', 3, 3, 128, 0, 49171],
        ['2022-09-09 05:33:40', 3, 3, 128, 0, 49171],
        ['2022-09-09 06:00:00', 3, 3, 128, 0, 50194],
        ['2022-09-09 06:01:43', 3, 3, 128, 0, 49171],
        ['2022-09-09 09:05:49', 3, 3, 128, 0, 50194],
        ['2022-09-09 09:06:28', 3, 3, 128, 0, 49171],
        ['2022-09-09 14:10:27', 3, 3, 128, 0, 50194],
        ['2022-09-09 14:10:40', 3, 3, 128, 0, 49171],
        ['2022-09-09 17:00:59', 3, 3, 128, 0, 49171],
        ['2022-09-09 17:01:02', 3, 3, 128, 0, 49171],
        ['2022-09-09 19:23:19', 3, 3, 128, 0, 50194],
        ['2022-09-09 19:23:52', 3, 3, 128, 0, 49171],
        ['2022-09-09 22:00:00', 3, 3, 128, 0, 50194],
        ['2022-09-09 22:02:20', 3, 3, 128, 0, 49171],
        ['2022-09-10 00:21:39', 3, 3, 128, 0, 50194],
        ['2022-09-10 00:22:18', 3, 3, 128, 0, 49171],
        ['2022-09-10 02:03:04', 3, 3, 128, 0, 50194],
        ['2022-09-10 02:03:18', 3, 3, 128, 0, 49171],
        ['2022-09-10 06:00:01', 3, 3, 128, 0, 54290],
        ['2022-09-10 06:01:44', 3, 3, 128, 0, 49171],
        ['2022-09-10 07:16:11', 3, 3, 128, 0, 50194],
        ['2022-09-10 07:16:40', 3, 3, 128, 0, 49171],
        ['2022-09-10 11:07:45', 3, 3, 128, 0, 50194],
        ['2022-09-10 11:07:50', 3, 3, 128, 0, 49171],
        ['2022-09-10 13:05:13', 3, 3, 128, 0, 50194],
        ['2022-09-10 13:05:39', 3, 3, 128, 0, 49171],
        ['2022-09-10 18:27:24', 3, 3, 128, 0, 50194],
        ['2022-09-10 18:27:27', 3, 3, 128, 0, 49171],
        ['2022-09-10 20:45:33', 3, 3, 128, 0, 50194],
        ['2022-09-10 20:46:01', 3, 3, 128, 0, 49171],
        ['2022-09-10 21:40:33', 3, 3, 128, 0, 50194],
        ['2022-09-10 21:40:40', 3, 3, 128, 0, 49171],
        ['2022-09-10 22:00:00', 3, 3, 128, 0, 50194],
        ['2022-09-10 22:02:21', 3, 3, 128, 0, 49171],
        ['2022-09-11 00:16:00', 3, 3, 128, 0, 50194],
        ['2022-09-11 00:16:13', 3, 3, 128, 0, 49171],
        ['2022-09-11 03:14:14', 3, 3, 128, 0, 50194],
        ['2022-09-11 03:14:26', 3, 3, 128, 0, 49171],
        ['2022-09-11 06:00:03', 3, 3, 128, 0, 50194],
        ['2022-09-11 06:01:44', 3, 3, 128, 0, 49171],
        ['2022-09-11 06:22:40', 3, 3, 128, 0, 50194],
        ['2022-09-11 06:22:50', 3, 3, 128, 0, 49171],
        ['2022-09-11 07:57:25', 3, 3, 128, 0, 50194],
        ['2022-09-11 07:58:04', 3, 3, 128, 0, 49171],
        ['2022-09-11 11:33:17', 3, 3, 128, 0, 50194],
        ['2022-09-11 11:33:25', 3, 3, 128, 0, 49171],
        ['2022-09-11 14:46:01', 3, 3, 128, 0, 50194],
        ['2022-09-11 14:46:21', 3, 3, 128, 0, 49171],
        ['2022-09-11 16:17:21', 3, 3, 128, 0, 50194],
        ['2022-09-11 16:17:27', 3, 3, 128, 0, 49171],
        ['2022-09-11 20:47:55', 3, 3, 128, 0, 49171],
        ['2022-09-11 20:48:59', 3, 3, 128, 0, 49171],
        ['2022-09-11 20:49:59', 3, 3, 128, 0, 49171],
        ['2022-09-11 20:50:18', 3, 3, 128, 0, 49171],
        ['2022-09-11 22:00:02', 3, 3, 128, 0, 50194],
        ['2022-09-11 22:02:20', 3, 3, 128, 0, 49171],
        ['2022-09-11 23:52:09', 3, 3, 128, 0, 50194],
        ['2022-09-11 23:52:48', 3, 3, 128, 0, 49171],
        ['2022-09-12 01:54:04', 3, 3, 128, 0, 50194],
        ['2022-09-12 01:54:31', 3, 3, 128, 0, 49171],
        ['2022-09-12 06:00:00', 3, 3, 128, 0, 50194],
        ['2022-09-12 06:01:45', 3, 3, 128, 0, 49171],
        ['2022-09-12 07:50:13', 3, 3, 128, 0, 50194],
        ['2022-09-12 07:50:51', 3, 3, 128, 0, 49171],
        ['2022-09-12 13:36:29', 3, 3, 128, 0, 50194],
        ['2022-09-12 13:36:41', 3, 3, 128, 0, 49171],
        ['2022-09-12 15:56:17', 3, 3, 128, 0, 50194],
        ['2022-09-12 15:56:52', 3, 3, 128, 0, 49171],
        ['2022-09-12 18:01:04', 3, 3, 128, 0, 50194],
        ['2022-09-12 18:01:15', 3, 3, 128, 0, 49171],
        ['2022-09-12 22:00:02', 3, 3, 128, 0, 50194],
        ['2022-09-12 22:02:20', 3, 3, 128, 0, 49171],
        ['2022-09-12 23:45:33', 3, 3, 128, 0, 50194],
        ['2022-09-12 23:45:36', 3, 3, 128, 0, 49171],
        ['2022-09-13 02:25:50', 3, 3, 128, 0, 50194],
        ['2022-09-13 02:25:56', 3, 3, 128, 0, 49171],
        ['2022-09-13 05:24:40', 3, 3, 128, 0, 50194],
        ['2022-09-13 05:25:49', 3, 3, 128, 0, 49171],
        ['2022-09-13 06:00:00', 3, 3, 128, 0, 50194],
        ['2022-09-13 06:01:05', 3, 3, 128, 0, 49171],
        ['2022-09-13 06:09:26', 3, 3, 128, 0, 50194],
        ['2022-09-13 06:09:57', 3, 3, 128, 0, 49171],
        ['2022-09-13 08:57:28', 3, 3, 128, 0, 50194],
        ['2022-09-13 08:57:38', 3, 3, 128, 0, 49171],
        ['2022-09-13 13:34:40', 3, 3, 128, 0, 50194],
        ['2022-09-13 13:34:51', 3, 3, 128, 0, 49171],
        ['2022-09-13 16:57:24', 3, 3, 128, 0, 50194],
        ['2022-09-13 16:57:27', 3, 3, 128, 0, 49171],
        ['2022-09-13 22:00:00', 3, 3, 128, 0, 50194],
        ['2022-09-13 22:02:21', 3, 3, 128, 0, 49171],
        ['2022-09-13 22:18:05', 3, 3, 128, 0, 50194],
        ['2022-09-13 22:18:15', 3, 3, 128, 0, 49171],
        ['2022-09-13 23:25:49', 3, 3, 128, 0, 50194],
        ['2022-09-13 23:26:28', 3, 3, 128, 0, 49171],
        ['2022-09-14 05:07:09', 3, 3, 128, 0, 50194],
        ['2022-09-14 05:07:48', 3, 3, 128, 0, 49171],
        ['2022-09-14 06:00:00', 3, 3, 128, 0, 50194],
        ['2022-09-14 06:01:44', 3, 3, 128, 0, 49171],
        ['2022-09-14 07:41:36', 3, 3, 128, 0, 50194],
        ['2022-09-14 07:41:52', 3, 3, 128, 0, 49171],
        ['2022-09-14 08:40:00', 3, 3, 128, 0, 50194],
        ['2022-09-14 08:40:07', 3, 3, 128, 0, 49171],
        ['2022-09-14 14:15:37', 3, 3, 128, 0, 50194],
        ['2022-09-14 14:16:04', 3, 3, 128, 0, 49171],
        ['2022-09-14 14:20:49', 3, 3, 128, 0, 50194],
        ['2022-09-14 14:21:28', 3, 3, 128, 0, 49171],
        ['2022-09-14 18:02:19', 3, 3, 128, 0, 50194],
        ['2022-09-14 18:02:52', 3, 3, 128, 0, 49171],
        ['2022-09-14 21:13:23', 3, 3, 128, 0, 50194],
        ['2022-09-14 21:13:42', 3, 3, 128, 0, 49171],
        ['2022-09-14 22:00:00', 3, 3, 128, 0, 50194],
        ['2022-09-14 22:02:21', 3, 3, 128, 0, 49171],
        ['2022-09-14 23:46:50', 3, 3, 128, 0, 50194],
        ['2022-09-14 23:47:24', 3, 3, 128, 0, 49171],
        ['2022-09-15 01:00:06', 3, 3, 128, 0, 50194],
        ['2022-09-15 01:00:32', 3, 3, 128, 0, 49171],
        ['2022-09-15 06:00:02', 3, 3, 128, 0, 54290],
        ['2022-09-15 06:01:44', 3, 3, 128, 0, 49171],
        ['2022-09-15 07:17:14', 3, 3, 128, 0, 50194],
        ['2022-09-15 07:17:20', 3, 3, 128, 0, 49171],
        ['2022-09-15 08:53:52', 3, 3, 128, 0, 50194],
        ['2022-09-15 08:54:03', 3, 3, 128, 0, 49171],
        ['2022-09-15 11:26:14', 3, 3, 128, 0, 50194],
        ['2022-09-15 11:26:41', 3, 3, 128, 0, 49171],
        ['2022-09-15 13:16:55', 3, 3, 128, 0, 50194],
        ['2022-09-15 13:17:21', 3, 3, 128, 0, 49171],
        ['2022-09-15 14:46:00', 3, 3, 128, 0, 50194],
        ['2022-09-15 14:46:38', 3, 3, 128, 0, 49171],
        ['2022-09-15 18:50:09', 3, 3, 128, 0, 50194],
        ['2022-09-15 18:50:20', 3, 3, 128, 0, 49171],
        ['2022-09-15 22:00:00', 3, 3, 128, 0, 50194],
        ['2022-09-15 22:02:21', 3, 3, 128, 0, 49171],
        ['2022-09-15 23:33:41', 3, 3, 128, 0, 50194],
        ['2022-09-15 23:34:09', 3, 3, 128, 0, 49171],
        ['2022-09-16 02:44:29', 3, 3, 128, 0, 50194],
        ['2022-09-16 02:44:56', 3, 3, 128, 0, 49171],
        ['2022-09-16 05:38:25', 3, 3, 128, 0, 50194],
        ['2022-09-16 05:38:33', 3, 3, 128, 0, 49171],
        ['2022-09-16 06:00:00', 3, 3, 128, 0, 50194],
        ['2022-09-16 06:01:43', 3, 3, 128, 0, 49171],
        ['2022-09-16 09:05:21', 3, 3, 128, 0, 50194],
        ['2022-09-16 09:05:27', 3, 3, 128, 0, 49171],
        ['2022-09-16 10:03:51', 3, 3, 128, 0, 50194],
        ['2022-09-16 10:04:05', 3, 3, 128, 0, 49171],
        ['2022-09-16 13:09:29', 3, 3, 128, 0, 50194],
        ['2022-09-16 13:09:57', 3, 3, 128, 0, 49171],
        ['2022-09-16 14:11:49', 3, 3, 128, 0, 50194],
        ['2022-09-16 14:12:19', 3, 3, 128, 0, 49171],
        ['2022-09-16 15:24:35', 3, 3, 128, 0, 50194],
        ['2022-09-16 15:25:08', 3, 3, 128, 0, 49171],
        ['2022-09-16 20:49:15', 3, 3, 128, 0, 50194],
        ['2022-09-16 20:49:36', 3, 3, 128, 0, 49171],
        ['2022-09-16 22:00:00', 3, 3, 128, 0, 50194],
        ['2022-09-16 22:02:21', 3, 3, 128, 0, 49171],
        ['2022-09-17 05:46:17', 3, 3, 128, 0, 50194],
        ['2022-09-17 05:46:44', 3, 3, 128, 0, 49171],
        ['2022-09-17 06:00:03', 3, 3, 128, 0, 50194],
        ['2022-09-17 06:01:44', 3, 3, 128, 0, 49171],
        ['2022-09-17 08:58:47', 3, 3, 128, 0, 50194],
        ['2022-09-17 08:59:16', 3, 3, 128, 0, 49171],
        ['2022-09-17 12:52:10', 3, 3, 128, 0, 50194],
        ['2022-09-17 12:52:24', 3, 3, 128, 0, 49171],
        ['2022-09-17 13:39:56', 3, 3, 128, 0, 50194],
        ['2022-09-17 13:40:04', 3, 3, 128, 0, 49171],
        ['2022-09-17 18:16:09', 3, 3, 128, 0, 49171],
        ['2022-09-17 21:58:41', 3, 3, 128, 0, 50194],
        ['2022-09-17 21:58:52', 3, 3, 128, 0, 49171],
        ['2022-09-17 22:00:02', 3, 3, 128, 0, 50194],
        ['2022-09-17 22:02:20', 3, 3, 128, 0, 49171],
        ['2022-09-18 04:12:05', 3, 3, 128, 0, 50194],
        ['2022-09-18 04:12:40', 3, 3, 128, 0, 49171],
        ['2022-09-18 06:00:04', 3, 3, 128, 0, 50194],
        ['2022-09-18 06:01:45', 3, 3, 128, 0, 49171],
        ['2022-09-18 09:37:05', 3, 3, 128, 0, 50194],
        ['2022-09-18 09:37:44', 3, 3, 128, 0, 49171],
        ['2022-09-18 11:17:46', 3, 3, 128, 0, 50194],
        ['2022-09-18 11:17:52', 3, 3, 128, 0, 49171],
        ['2022-09-18 15:23:17', 3, 3, 128, 0, 50194],
        ['2022-09-18 15:23:22', 3, 3, 128, 0, 49171],
        ['2022-09-18 15:46:03', 3, 3, 128, 0, 50194],
        ['2022-09-18 15:46:06', 3, 3, 128, 0, 49171],
        ['2022-09-18 20:32:17', 3, 3, 128, 0, 50194],
        ['2022-09-18 20:32:56', 3, 3, 128, 0, 49171],
        ['2022-09-18 22:00:03', 3, 3, 128, 0, 50194],
        ['2022-09-18 22:02:20', 3, 3, 128, 0, 49171],
        ['2022-09-18 22:09:08', 3, 3, 128, 0, 50194],
        ['2022-09-18 22:09:32', 3, 3, 128, 0, 49171],
        ['2022-09-19 05:39:32', 3, 3, 128, 0, 49171],
        ['2022-09-19 06:00:00', 3, 3, 128, 0, 50194],
        ['2022-09-19 06:01:44', 3, 3, 128, 0, 49171],
        ['2022-09-19 07:56:08', 3, 3, 128, 0, 50194],
        ['2022-09-19 07:56:16', 3, 3, 128, 0, 49171],
        ['2022-09-19 08:00:06', 3, 3, 128, 0, 49171],
      ],
    },
  ],
};

export const testDataManageJournal = {
  code: 200,
  updated_at: '2022-09-20 10:20:41 +0300',
  events: [],
  result: {
    op: 'Get',
    note: '',
    code: 207,
  },
  type: 'object',
  subtype: 'journal_com',
  journal_com: [
    {
      fields: ['date-time', 'mode', 'command', 'cycle', 'phases'],
      id: 4097,
      data: [
        ['2022-06-30 12:37:50', 6, 2, 120, []],
        ['2022-06-30 12:37:51', 6, 4, 120, []],
        ['2022-06-30 12:37:56', 6, 254, 120, []],
        [
          '2022-06-30 12:38:04',
          1,
          128,
          120,
          [
            [1, 51],
            [2, 9],
            [5, 7],
            [3, 32],
            [4, 21],
          ],
        ],
        ['2022-06-30 12:38:18', 6, 64, 120, []],
        [
          '2022-06-30 17:23:22',
          1,
          128,
          120,
          [
            [1, 51],
            [2, 9],
            [5, 7],
            [3, 32],
            [4, 21],
          ],
        ],
        ['2022-07-12 16:35:00', 6, 254, 120, []],
        [
          '2022-07-12 16:47:42',
          1,
          128,
          120,
          [
            [1, 51],
            [2, 9],
            [5, 7],
            [3, 32],
            [4, 21],
          ],
        ],
        ['2022-07-12 16:50:01', 6, 254, 120, []],
        [
          '2022-07-12 17:29:59',
          1,
          128,
          120,
          [
            [1, 51],
            [2, 9],
            [5, 7],
            [3, 32],
            [4, 21],
          ],
        ],
        [
          '2022-07-12 17:31:10',
          1,
          128,
          120,
          [
            [1, 51],
            [2, 5],
            [5, 7],
            [3, 36],
            [4, 21],
          ],
        ],
        ['2022-07-12 17:40:00', 6, 254, 120, []],
        [
          '2022-07-12 17:41:01',
          1,
          128,
          120,
          [
            [1, 51],
            [2, 5],
            [5, 7],
            [3, 36],
            [4, 21],
          ],
        ],
        ['2022-07-12 17:45:06', 6, 254, 120, []],
        [
          '2022-07-12 17:50:00',
          1,
          128,
          120,
          [
            [1, 51],
            [2, 5],
            [5, 7],
            [3, 36],
            [4, 21],
          ],
        ],
        ['2022-07-12 17:59:59', 6, 254, 120, []],
        ['2022-07-15 17:52:12', 6, 2, 120, []],
        [
          '2022-07-15 17:52:28',
          1,
          128,
          120,
          [
            [1, 51],
            [2, 5],
            [5, 7],
            [3, 36],
            [4, 21],
          ],
        ],
        [
          '2022-07-15 20:22:09',
          1,
          128,
          120,
          [
            [1, 51],
            [2, 9],
            [5, 7],
            [3, 32],
            [4, 21],
          ],
        ],
        ['2022-07-20 11:21:44', 6, 254, 120, []],
        ['2022-07-20 13:59:10', 6, 64, 120, []],
        ['2022-07-20 14:53:31', 6, 254, 120, []],
        [
          '2022-07-20 15:17:26',
          1,
          128,
          120,
          [
            [1, 51],
            [2, 9],
            [5, 7],
            [3, 32],
            [4, 21],
          ],
        ],
        [
          '2022-07-20 15:18:48',
          1,
          128,
          108,
          [
            [1, 40],
            [2, 28],
            [3, 40],
          ],
        ],
        ['2022-08-02 17:03:52', 5, 64, 108, []],
        [
          '2022-08-02 17:05:11',
          1,
          128,
          108,
          [
            [1, 40],
            [2, 28],
            [3, 40],
          ],
        ],
        ['2022-08-04 16:30:56', 5, 64, 108, []],
        [
          '2022-08-04 16:32:56',
          1,
          128,
          108,
          [
            [1, 40],
            [2, 28],
            [3, 40],
          ],
        ],
        [
          '2022-08-22 18:53:01',
          6,
          148,
          49,
          [
            [1, 13],
            [2, 23],
            [3, 13],
          ],
        ],
        [
          '2022-08-22 18:53:37',
          1,
          128,
          49,
          [
            [1, 5],
            [2, 15],
            [3, 5],
          ],
        ],
        [
          '2022-08-22 20:22:09',
          1,
          128,
          108,
          [
            [1, 40],
            [2, 28],
            [3, 40],
          ],
        ],
        [
          '2022-08-22 20:56:15',
          6,
          148,
          49,
          [
            [1, 13],
            [2, 23],
            [3, 13],
          ],
        ],
        [
          '2022-08-22 21:14:28',
          1,
          128,
          49,
          [
            [1, 5],
            [2, 15],
            [3, 5],
          ],
        ],
        [
          '2022-08-22 21:15:52',
          6,
          148,
          49,
          [
            [1, 13],
            [2, 23],
            [3, 13],
          ],
        ],
        [
          '2022-08-22 21:26:50',
          1,
          128,
          49,
          [
            [1, 5],
            [2, 15],
            [3, 5],
          ],
        ],
        [
          '2022-08-22 21:26:52',
          6,
          148,
          49,
          [
            [1, 13],
            [2, 23],
            [3, 13],
          ],
        ],
        [
          '2022-08-22 21:39:23',
          1,
          128,
          49,
          [
            [1, 5],
            [2, 15],
            [3, 5],
          ],
        ],
        [
          '2022-08-22 21:43:11',
          6,
          148,
          49,
          [
            [1, 13],
            [2, 23],
            [3, 13],
          ],
        ],
        [
          '2022-08-22 21:56:14',
          1,
          128,
          49,
          [
            [1, 5],
            [2, 15],
            [3, 5],
          ],
        ],
        [
          '2022-08-22 21:56:33',
          6,
          148,
          49,
          [
            [1, 13],
            [2, 23],
            [3, 13],
          ],
        ],
        [
          '2022-08-23 21:41:12',
          1,
          128,
          49,
          [
            [1, 5],
            [2, 15],
            [3, 5],
          ],
        ],
        [
          '2022-08-23 21:43:01',
          6,
          148,
          49,
          [
            [1, 13],
            [2, 23],
            [3, 13],
          ],
        ],
        [
          '2022-08-23 21:49:00',
          1,
          128,
          49,
          [
            [1, 5],
            [2, 15],
            [3, 5],
          ],
        ],
        [
          '2022-08-23 22:00:02',
          1,
          128,
          108,
          [
            [1, 40],
            [2, 28],
            [3, 40],
          ],
        ],
        ['2022-08-24 23:19:47', 6, 254, 108, []],
        [
          '2022-08-24 23:21:56',
          1,
          128,
          108,
          [
            [1, 40],
            [2, 28],
            [3, 40],
          ],
        ],
        ['2022-08-24 23:29:45', 6, 254, 108, []],
        [
          '2022-08-25 12:47:55',
          1,
          128,
          108,
          [
            [1, 40],
            [2, 28],
            [3, 40],
          ],
        ],
        ['2022-09-06 12:19:14', 5, 64, 108, []],
        [
          '2022-09-06 12:21:22',
          1,
          128,
          108,
          [
            [1, 40],
            [2, 28],
            [3, 40],
          ],
        ],
        ['2022-09-06 12:46:29', 5, 64, 108, []],
        [
          '2022-09-06 12:46:59',
          1,
          128,
          108,
          [
            [1, 40],
            [2, 28],
            [3, 40],
          ],
        ],
        ['2022-09-06 12:47:34', 5, 64, 108, []],
        [
          '2022-09-06 12:48:04',
          1,
          128,
          108,
          [
            [1, 40],
            [2, 28],
            [3, 40],
          ],
        ],
        ['2022-09-06 13:49:48', 5, 64, 108, []],
        [
          '2022-09-06 13:50:34',
          1,
          128,
          108,
          [
            [1, 40],
            [2, 28],
            [3, 40],
          ],
        ],
        ['2022-09-06 13:51:55', 5, 64, 108, []],
        [
          '2022-09-06 13:54:39',
          1,
          128,
          108,
          [
            [1, 40],
            [2, 28],
            [3, 40],
          ],
        ],
      ],
    },
  ],
};

export const columnsStatusJournal = [
  {
    key: 'dateTime',
    title: 'Дата, время',
    dataIndex: 'dateTime',
  },
  {
    key: 'source',
    title: 'Источник',
    dataIndex: 'source',
  },
  {
    key: 'state',
    title: 'Состояние',
    dataIndex: 'state',
  },
  {
    key: 'command',
    title: 'Команда',
    dataIndex: 'command',
  },
];

export const columnsManageJournal = [
  {
    key: 'dateTime',
    title: 'Дата, время',
    dataIndex: 'dateTime',
  },
  {
    key: 'mode',
    title: 'Режим',
    dataIndex: 'mode',
  },
  {
    key: 'command',
    title: 'Команда',
    dataIndex: 'command',
  },
  {
    key: 'cycle',
    title: 'Цикл',
    dataIndex: 'cycle',
  },
  {
    key: 'phases',
    title: 'Фазы',
    dataIndex: 'phases',
  },
];
