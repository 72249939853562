import type { Payload } from 'recharts/types/component/DefaultLegendContent';

import { CalcData } from '../../models/chart.model';

const SEPARATE = '-';

const getDateInfo = (date: string) => {
  const dateInfo = date.trim().split(' ');

  if (dateInfo.length < 2) return null;

  const [dateStr, time] = dateInfo;

  return { dateStr, time };
};

const getDateTime = (data: U<CalcData[]>, index: number, labelKey: string) => {
  const date = data?.[index]?.[labelKey]?.toString();

  const dateArray = date?.split(SEPARATE);

  if (!dateArray || dateArray.length < 2) return null;

  const [dateFrom, dateTo] = dateArray;

  const dateFromInfo = getDateInfo(dateFrom);
  const dateToInfo = getDateInfo(dateTo);

  if (!dateFromInfo || !dateToInfo) return null;

  return { dateFromInfo, dateToInfo };
};

const getDateValues = (
  payload: Payload,
  data: U<CalcData[]>,
  labelKey: string,
  visibleTicksCount: number
) => {
  const indexPayload = payload.value;
  const defaultStep = data ? Math.ceil(data.length / visibleTicksCount) : 0;

  const isFirst = !indexPayload;
  const isLastValue = data && indexPayload === data.length - 1;

  const indexLeft =
    indexPayload - defaultStep < 0 ? 0 : indexPayload - defaultStep;

  const currentDate = getDateTime(data, indexPayload, labelKey);
  const leftDate = getDateTime(data, indexLeft, labelKey);

  if (!currentDate || !leftDate) return null;

  const isDifDates =
    isFirst || currentDate.dateToInfo.dateStr !== leftDate.dateToInfo.dateStr;

  const textAnchor = !isLastValue ? 'start' : 'end';

  return {
    ...currentDate.dateToInfo,
    isDifDates,
    textAnchor,
    isFirst,
    indexPayload,
  };
};

export default getDateValues;
