import { Checkbox } from 'antd';
import { Style } from 'ol/style';
import { useEffect, useState } from 'react';

import { GEOMETRY_TYPE_NAMES } from '../../../../../apiGIS/constants/geometry/types';
import { getLayer } from '../../../../../apiGIS/layers/utils/getLayer';
import { TMap } from '../../../../../stores/mapStore/mapStore.model';
import { SETTINGS_USE_STYLE } from '../../constants';
import { ILayerDataNode } from '../../layers.model';
import styles from '../../LayersButton.module.scss';
import { getLayerGeometryType } from '../Styles/helpers';
import StyleSelector from '../Styles/StyleSelector';

const getLayerStyle = (map: TMap, id: string) => {
  const layer = getLayer(map, id);

  if (!layer) {
    return undefined;
  }

  const style = layer.getStyle();

  if (style instanceof Style) {
    return style;
  }

  return undefined;
};

const LayerSettingsStyle = (props: {
  map: TMap;
  selectedNode: ILayerDataNode;
}) => {
  const { map, selectedNode } = props;

  const id = selectedNode.item?.id || '';

  const [hasStyle, setHasStyle] = useState(false);
  const [style] = useState(getLayerStyle(map, id));
  const [geometryType, setGeometryType] = useState(GEOMETRY_TYPE_NAMES.Point);

  useEffect(() => {
    getLayerGeometryType(selectedNode.item).then((value: string) => {
      setGeometryType(value);
    });
  }, [selectedNode]);

  if (!map || !id) {
    return null;
  }

  const layer = getLayer(map, id);

  const toggleHasStyle = (evt: any) => {
    setHasStyle(evt.target.checked);
  };

  const updateStyle = (style: Style | undefined | null) => {
    layer?.setStyle(style);
  };

  return (
    <>
      <div>
        <div className={styles.labelsOptionsContainer}>
          <Checkbox checked={hasStyle} onChange={toggleHasStyle} />
          <div className={styles.optionTitle}>Настроить стиль</div>
        </div>
        <div className={styles.settingsHint}>{SETTINGS_USE_STYLE}</div>

        {hasStyle ? (
          <StyleSelector
            onChange={updateStyle}
            style={style}
            geometryType={geometryType}
          />
        ) : null}
      </div>
    </>
  );
};

export default LayerSettingsStyle;
