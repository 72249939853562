import { Table } from 'antd';
import classNames from 'classnames';
import { FC, useEffect } from 'react';

import { DataType } from '../../../../../ts/models/table.model';
import DefaultChecked from '../../../../ui-kit/DefaultChecked/DefaultChecked';
import { IHeaderItem, IPhasesData } from '../../PhaseHold/PhaseHold.model';

import { EDIT_TABLE_PAGINATION, MAX_PAGE_SIZE } from './constants/constants';
import EditableCell from './EditableCell/EditableCell';
import EditableRow from './EditableRow/EditableRow';
import getHeaderItem from './helpers/getHeaderItem';

import styles from './EditSgnProgramTable.module.scss';

interface IEditSignalProgramTableProps {
  dataTable: IPhasesData[];
  setDataTable: SetState<IPhasesData[]>;
  setIsValidateTable: SetState<boolean>;
  isPhasesTable?: boolean;
  durationMinArray?: number[];
  columns: IHeaderItem[];
  setColumns: SetState<IHeaderItem[]>;
}

const DurationLoading = 1;

const EditSignalProgramTable: FC<IEditSignalProgramTableProps> = ({
  setIsValidateTable,
  isPhasesTable = false,
  dataTable,
  setDataTable,
  durationMinArray = [],
  columns,
  setColumns,
}) => {
  useEffect(() => {
    const headerItem = getHeaderItem(isPhasesTable);

    const visibleColumn = headerItem.filter((item) => !!item.visible);

    const correctedColumns = visibleColumn.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record: DataType) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
        }),
      };
    });

    setTimeout(() => {
      //@ts-ignore
      setColumns(correctedColumns);
    }, DurationLoading);
  }, [dataTable, isPhasesTable, setColumns]);

  const WrapperCell = (props: any) => {
    return (
      <EditableCell
        {...props}
        setIsValidateTable={setIsValidateTable}
        isPhasesTable={isPhasesTable}
        setDataTable={setDataTable}
        durationMinArray={durationMinArray}
      />
    );
  };

  const components = {
    body: {
      row: EditableRow,
      cell: WrapperCell,
    },
  };

  const tableStyles = classNames({
    [styles.tableWrapper]: true,
    [styles.tableWrapperPhase]: isPhasesTable,
  });

  const isPagination = dataTable.length > MAX_PAGE_SIZE;

  return (
    <div className={tableStyles}>
      <DefaultChecked
        isEmptyData={!dataTable.length}
        isLoading={!columns.length}
        noDataProps={{ textError: 'Нет данных о фазах' }}
      >
        <Table
          bordered
          columns={columns}
          components={components}
          dataSource={dataTable}
          pagination={isPagination ? EDIT_TABLE_PAGINATION : false}
          rowClassName={() => 'editable-row'}
        />
      </DefaultChecked>
    </div>
  );
};

export default EditSignalProgramTable;
