import {
  Point2,
  Scene,
  SceneUnion,
} from '../../../../ts/models/MapObjects/detectors.model';

export const isCameraSetting = (
  scene: SceneUnion
): scene is NonNullable<Scene> => {
  if (!scene) return false;

  return Object.hasOwn(scene, 'lanes');
};

const getCorrectPoints = (points: N<Point2[]>) => {
  return points?.map(({ item, ...coords }) => coords) ?? [];
};

export const getCorrectScene = (scene: U<SceneUnion>): Scene => {
  if (!scene) return;

  if (isCameraSetting(scene)) {
    return scene;
  }

  const { lines, zones } = scene;

  return {
    lanes:
      zones?.map(({ points, type, ...rest }) => {
        const pointsCorrect = getCorrectPoints(points);

        return { ...rest, points: pointsCorrect };
      }) ?? [],
    trafficLines:
      lines?.map(({ isEnabled, type, points, ...rest }) => {
        const pointsCorrect = getCorrectPoints(points);

        return { ...rest, points: pointsCorrect };
      }) ?? [],
  };
};
