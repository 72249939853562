import { isUndefined } from 'lodash';
import { FC } from 'react';

import {
  MeteoInfoNonNullable,
  TValueMeteo,
} from '../../../../Mapper/TooltipContent/MapIconContent/MeteoInfo/helpers/getMeteoInfo';
import MeteoContainer from '../MeteoContainer/MeteoContainer';

import styles from './MeteoRoadHeight.module.scss';

interface IMeteoRoadHeightProps {
  meteoInfo: MeteoInfoNonNullable;
}

const MeteoRoadHeight: FC<IMeteoRoadHeightProps> = ({ meteoInfo }) => {
  const { aiaFreezePoint, aiaPercent, icePercent, iceH, snowH, waterH } =
    meteoInfo;

  const meteoInfoValues = Object.values({
    aiaFreezePoint,
    aiaPercent,
    icePercent,
    iceH,
    snowH,
    waterH,
  }).filter((el) => !isUndefined(el)) as NonNullable<TValueMeteo>[];

  return (
    <div className={styles.container}>
      <MeteoContainer isNeedBg={false} meteoInfo={meteoInfoValues} />
    </div>
  );
};

export default MeteoRoadHeight;
