import { makeAutoObservable } from 'mobx';
import { Overlay } from 'ol';
import { createRef } from 'react';

import RootStore from '../rootStore/rootStore';
import { StoreUtils } from '../storeUtils/storeUtils';

import { DivRef, TMap } from './mapStore.model';

class MapStore {
  rootStore;
  setMapValue;
  map: TMap = null;

  // refs
  tooltipRef: DivRef = createRef();
  layerTooltipRef: DivRef = createRef();
  constructorCircleRef: DivRef = createRef();

  // overlays
  tooltip: U<Overlay>;
  layerTooltip: U<Overlay>;
  constructorCircle: U<Overlay>;
  // ========

  constructor(rootStore: typeof RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
    const utils = new StoreUtils(this);

    this.setMapValue = utils.setKeyValue;
    this.rootStore = rootStore;
  }

  get refs() {
    const { tooltipRef, layerTooltipRef, constructorCircleRef } = this;

    return { tooltipRef, layerTooltipRef, constructorCircleRef };
  }

  get overlays() {
    const { tooltip, layerTooltip, constructorCircle } = this;

    return { tooltip, layerTooltip, constructorCircle };
  }
}

export default MapStore;
