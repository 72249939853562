const MIN_SIZE = 15;
const MAX_SIZE = 50;

const getRectangleSize = (len: Nullish<number>) => {
  if (!len) return MIN_SIZE;

  const size = Math.floor((100 / len) * 5);

  if (size <= MIN_SIZE) return MIN_SIZE;

  if (size >= MAX_SIZE) return MAX_SIZE;

  return size;
};

export default getRectangleSize;
