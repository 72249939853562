export const setBandwidth = (sdp: any, bandwidth: any) => {
  let sdpLines = sdp.split('\n');

  for (let i = 0; i < sdpLines.length; ++i) {
    if (sdpLines[i].startsWith('m=video')) {
      do {
        ++i;
      } while (
        i < sdpLines.length &&
        (sdpLines[i].startsWith('c=') || sdpLines[i].startsWith('i='))
      );

      const newParam = 'b=AS:' + bandwidth.toString();

      if (i < sdpLines.length && sdpLines[i].startsWith('b=')) {
        sdpLines[i] = newParam;
      } else {
        let newLines = sdpLines.slice(0, i);

        newLines.push(newParam);
        newLines = newLines.concat(sdpLines.slice(i, sdpLines.length));
        sdpLines = newLines;
      }

      break;
    }
  }

  return sdpLines.join('\n');
};
