import { useEffect } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import { MapActions } from '../../../stores/uiStore/uiStore.model';

const useInfoPopup = () => {
  const { setInfoData, setKeyValue, isRightPanel, infoData } =
    rootStore.uiStore;
  const { clusterFeatures, isClusterOpen } = rootStore.clustersStore;

  useEffect(() => {
    if (!infoData || !isRightPanel) return;

    if (!clusterFeatures.length) {
      setKeyValue('isInfoPopup', true);
    }
  }, [clusterFeatures.length, infoData, isRightPanel, setKeyValue]);

  useEffect(() => {
    if (!isRightPanel) {
      setKeyValue('isInfoPopup', false);
    }
  }, [isRightPanel, setKeyValue]);

  useEffect(() => {
    if (!infoData || !isRightPanel || !clusterFeatures.length) return;

    const { id, coordFeatureOnCluster } = infoData;

    if (!isClusterOpen && coordFeatureOnCluster) {
      setInfoData(
        { ...infoData, coordFeatureOnCluster: undefined },
        MapActions.NoActions
      );
    }

    if (coordFeatureOnCluster) return setKeyValue('isInfoPopup', true);

    const isClusterFeatureOpen = clusterFeatures.reduce(
      (acc: boolean, item) => {
        const isOnCluster = item.some((el) => el.get('id') === id);

        if (isOnCluster) {
          return false;
        }

        return acc;
      },
      true
    );

    setKeyValue('isInfoPopup', isClusterFeatureOpen);
  }, [
    clusterFeatures,
    infoData,
    isRightPanel,
    isClusterOpen,
    setInfoData,
    setKeyValue,
  ]);
};

export default useInfoPopup;
