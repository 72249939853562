import { TMap } from '../../stores/mapStore/mapStore.model';

export const getMaxAllowableOffset = (map: TMap) => {
  if (!map) {
    return 0;
  }

  const view = map.getView();
  const zoom = view.getZoom() || 0;
  const maxZoom = view.getMaxZoom();
  const resolution = view.getResolution() || 0;

  if (resolution < 1 || zoom <= 1 || zoom === maxZoom) {
    return 0;
  }

  return resolution;
};
