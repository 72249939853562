const TIME_RES = [
  {
    name: 'ч',
    value: 'h',
  },
  {
    name: 'м',
    value: 'm',
  },
  {
    name: 'с',
    value: 's',
  },
];

export const getTimeArray = (time: string) => {
  return TIME_RES.reduce((acc: string[], { name, value }) => {
    if (time.includes(value) || time.includes(value.toUpperCase())) {
      acc.push(name);
    }

    return acc;
  }, []);
};

export const getTimeFooterArray = (dateFormat: string) => {
  const time = dateFormat.split(' ').at(1);

  if (!time) return [];

  return getTimeArray(time);
};
