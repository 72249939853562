import { useEffect, useState } from 'react';

import mapObjects from '../../../../../api/mapObjects';
import { getSliceDates } from '../../../../TrafficLightDetailed/TlDetectors/IntancyGraph/helpers/getSliceDates';
import { getTimeWithTimeZone } from '../../../../TrafficLightDetailed/TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeWithTimeZone';
import { getTimeZones } from '../../../../TrafficLightDetailed/TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeZone';
import { RadioValue } from '../../../../TrafficLightDetailed/TlDetectors/model/graph.model';
import { DAY_INTERVAL } from '../Chart/constants/constants';
import { IData, TDataDetectorsBars } from '../helpers/getAverage/getAverage';
import { DATE_FORMAT_GRAPH } from '../helpers/getDateRangeStr';
import { getFormatDataDt } from '../helpers/getFormatDataDt';
import { IDataGraph } from '../PopupDetector.model';

const APP_PATH = '.InfoPanel.SystemsInfo.PopupDetector.hooks.useGraphData';

const { useDetectorsData } =
  mapObjects.detectors.detectorsData.detectorsDataApi;

const isNotEmptyArray = (arr: U<IData[]>): arr is TDataDetectorsBars =>
  !!arr?.length;

interface UseGraphDataProps {
  id: number;
  radioValue: RadioValue;
}

const useGraphData = ({ id, radioValue }: UseGraphDataProps) => {
  const [dateInterval, setDateInterval] = useState<string[]>([]);
  const [dataGraph, setDataGraph] = useState<N<IDataGraph[]>>(null);
  const [isNullableData, setIsNullableData] = useState(false);
  const [timeUpdate, setTimeUpdate] = useState<string>();

  const { data, isError, isLoading, isFetching } = useDetectorsData({
    appPath: APP_PATH,
    ids: [id],
    interval: DAY_INTERVAL,
    selectedDates: [...getSliceDates(1, { isNeedUtcFormatted: true })],
    timeZone: getTimeZones().timeZoneRegion,
    isNeedUtcFormatted: false,
    directions: [],
    isMiniGraph: true,
    isNeedTransform: true,
    graphTitle: radioValue,
    specialKeys: [radioValue],
  });

  useEffect(() => {
    setDateInterval(
      [...getSliceDates(1, { isNeedUtcFormatted: true })].map((date) =>
        date.locale('ru').format(DATE_FORMAT_GRAPH)
      )
    );
    setTimeUpdate(
      getTimeWithTimeZone({
        timeZone: getTimeZones().timeZoneRegion,
      }).dateFormatted
    );
  }, [isFetching]);

  useEffect(() => {
    const preparedData = data?.data;

    if (isNotEmptyArray(preparedData)) {
      const { formattedData, datePeriod, isNullableData } = getFormatDataDt(
        preparedData,
        radioValue
      );

      setDataGraph(formattedData);
      setDateInterval(datePeriod);
      setIsNullableData(isNullableData);
    }
  }, [data, radioValue]);

  return {
    dataGraph,
    isLoading,
    dateInterval,
    timeUpdate,
    isError: isError || !data?.data.length,
    isNullableData,
  };
};

export default useGraphData;
