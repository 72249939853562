import { FC } from 'react';

import AccordionSimple, {
  AccordionSimpleProps,
} from '../../../ui-kit/AccordionSimple/AccordionSimple';
import BlockWrapper from '../BlockWrapper/BlockWrapper';

const AccordionConstructor: FC<AccordionSimpleProps> = (props) => {
  const { children, ...accordionProps } = props;

  return (
    <AccordionSimple {...accordionProps}>
      <BlockWrapper>{children}</BlockWrapper>
    </AccordionSimple>
  );
};

export default AccordionConstructor;
