import { DivisionBody } from '../../../../components/Admin/Organizations/models/Divisions.model';
import { VERSION } from '../../../../constants/versions';
import { getAppPathHeaders } from '../../../helpers/getAppPathHeaders';
import http from '../../../http';
import tryCatchWrapper from '../../../tryCatchWrapper';

const CATALOGS = VERSION + '/catalogs';
const DIVISIONS = CATALOGS + '/organizations/divisions';

export const divisions = {
  getList: (id: number, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.get(DIVISIONS + `/${id}`, {
        headers: getAppPathHeaders(appPath),
      });

      return res;
    }),
  create: (body: DivisionBody, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.post(DIVISIONS, body, {
        headers: getAppPathHeaders(appPath),
      });

      return res;
    }),
  delete: (id: number, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.delete(DIVISIONS + `/${id}`, {
        headers: getAppPathHeaders(appPath),
      });

      return res;
    }),
  update: (body: DivisionBody, id: number, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.put(DIVISIONS + `/${id}`, body, {
        headers: getAppPathHeaders(appPath),
      });

      return res;
    }),
};
