import { Dayjs } from 'dayjs';
import { isNumber } from 'lodash';

import { DATE_FORMAT_TO_SHOW } from '../../../../../constants/constants';
import { secondsToTime } from '../../PhaseHold/helpers/secondsToTime';
import { TitleValues } from '../constants/constants';

interface TimerInfoProps {
  startTime: Dayjs;
  endTime: Dayjs;
  commandNum: N<number>;
  sec: number;
  isTimeEnd: boolean;
  titles: TitleValues;
}

export const getTimerInfo = ({
  startTime,
  endTime,
  commandNum,
  sec,
  isTimeEnd,
  titles,
}: TimerInfoProps) => {
  const { title, titleHold, paragraph, paragraphHold } = titles;

  const numberTitle = isNumber(commandNum) ? `№ ${commandNum}` : null;

  const titlePhaseTimer = !isTimeEnd
    ? `${title} ${numberTitle ?? ''}`
    : `${titleHold} ${numberTitle ?? ''}`;

  const duration = secondsToTime(endTime.diff(startTime, 's'));

  const timeTitle = isTimeEnd ? endTime : startTime;

  const titlePhaseDate = `${!isTimeEnd ? paragraph : paragraphHold}
: ${timeTitle.format(DATE_FORMAT_TO_SHOW)}, длительностью: ${duration}`;

  const time = secondsToTime(sec).split(':');

  return { titlePhaseTimer, titlePhaseDate, time };
};
