interface Item {
  id: N<number | string>;
}

type SortById = <T extends Item>(arr: T[]) => T[];

const sortById: SortById = (arr) =>
  [...arr].sort((a, b) => Number(a.id) - Number(b.id));

export default sortById;
