/* eslint-disable camelcase */
import { z } from 'zod';

import { NNInt } from '../../../zod/utils.zod';
import { TLSignalProgramItemSchema2, TLTactSchema } from '../zod/tls.zod';

export const SignalProgramsSchema = z.object({
  addInfo: z.unknown().nullable().optional(),
  execTime: z.string(),
  phaseGroupNum: NNInt,
  viewItem: z.string().optional(),
  tlSignalProgram2: TLSignalProgramItemSchema2.array().nullable().optional(),
  tlTacts: TLTactSchema.array().nullable(),
});
