import { SYSTEM_BY_TYPE_ID } from '../../../constants/deviceConstants';
import { findById } from '../../../helpers/findBy';
import { TObjMap } from '../../../stores/mapDataStore/mapDataStore.model';
import rootStore from '../../../stores/rootStore/rootStore';
import { PanelType } from '../../../ts/enums/enums';
import { isActualDeviceType } from '../../../ts/typeGuards/mapObjectsTG';

const { Constructor } = PanelType;

const handleDeviceId = (params: URLSearchParams, systemsArray: TObjMap[]) => {
  const { setInfoData } = rootStore.uiStore;

  const panelType = params.get('panelType');

  if (panelType === Constructor) return;
  const deviceId = Number(params.get('deviceId'));
  const isValidDeviceId = Boolean(deviceId) && !isNaN(deviceId);
  const device = isValidDeviceId && findById(systemsArray, deviceId);

  if (!device) return;
  const { deviceTypeId, latitude, longitude } = device.deviceInfo;

  if (!isActualDeviceType(deviceTypeId)) return;

  setInfoData({
    id: deviceId,
    system: SYSTEM_BY_TYPE_ID[deviceTypeId],
    coordinate: [longitude, latitude],
  });
};

export default handleDeviceId;
