import type { DefaultOptionType } from 'antd/es/select';
import { FC } from 'react';

import {
  ChangeDevice,
  DeviceType,
  ExtDeviceType,
  SetKeyValue,
  SetKeyValueWithId,
} from '../../../stores/constructorStore/constructorStore.model';
import { MapObjectFull } from '../../../ts/enums/enums';
import {
  ICameraConstructor,
  Id,
  Meteo,
  TrafficLane,
} from '../../../ts/models/constructor.model';
import { LonLat, TDeviceInfo } from '../../../ts/models/mapObject.model';

interface Init {
  id: Id;
  caption: string;
  isDeleted?: boolean;
}

type SetBlock = SetKeyValueWithId<Init> | SetKeyValue<Init>;

export interface AddedDevice {
  id: N<Id>;
  isDeleted?: boolean;
}

export interface Device extends AddedDevice {
  caption: string;
  deviceInfo: TDeviceInfo;
}

interface BlockFuncs<T> {
  addBlock: ChangeDevice<T>;
  setBlock: SetBlock;
  changeBlock?: ChangeDevice<T>;
}

export interface DeviceSelectProps {
  setTitle: SetState<N<string>>;
  title: N<string>;
  options: U<DefaultOptionType[]>;
}

type ModalOption = DefaultOptionType & LonLat;

export interface DeviceInitProps<T> extends BlockFuncs<T> {
  isOpenModal: boolean;
  setIsOpenModal: SetState<boolean>;
  deviceId: N<Id>;
  setDeviceId: SetState<N<Id>>;
  modalOptions: U<ModalOption[]>;
  isTransfer: boolean;
  setIsTransfer: SetState<boolean>;
}

export interface BlockComponentProps<T> {
  openModal: (id: N<Id>) => void;
  item: T;
}

export interface BlockConstructorProps<T extends AddedDevice>
  extends BlockFuncs<T> {
  devices: Device[];
  addedDevices: T[];
  Component: FC<BlockComponentProps<T>>;
  btnTip?: string;
  isAddBtn?: boolean;
}

export function isTrafficLane(
  item: DeviceType | ExtDeviceType
): item is TrafficLane {
  return (item as TrafficLane).isMain !== undefined;
}

export function isCamera(
  item: DeviceType | ExtDeviceType
): item is ICameraConstructor {
  return (item as ICameraConstructor).isExtended !== undefined;
}

export function isMeteo(item: DeviceType | ExtDeviceType): item is Meteo {
  return (item as Meteo).type === MapObjectFull.meteo;
}
