import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { findById } from '../../../../../../helpers/findBy';
import rootStore from '../../../../../../stores/rootStore/rootStore';
import { System } from '../../../../../../ts/enums/enums';
import IcoDevice from '../../../../../IcoDevice/IcoDevice';
import { getDeviceTitle } from '../../../../../InfoPanel/SystemsInfo/LinkedsInfo/LinkedDeviceItem/helpers';
import ButtonStatus, {
  TPopoverProps,
} from '../../../../../ui-kit/ButtonStatus/ButtonStatus';

import styles from './DtItem.module.scss';

interface IDtItemProps {
  id: number;
}

const DtItem: FC<IDtItemProps> = ({ id }) => {
  const { detectors } = rootStore.mapDataStore;
  const { isPanel } = rootStore.uiStore;
  const { activeDtId, setActiveDtId } = rootStore.detailedStore;

  const handleClickItem = () => setActiveDtId(id);

  const system = System.Detectors;
  const maxLength = isPanel ? 25 : 33;

  const dt = findById(detectors, id);

  if (!dt?.deviceInfo) return null;

  const statusDevice = dt.deviceInfo.deviceStatusId;

  const title = getDeviceTitle({
    currentObject: dt,
    id,
    maxLength,
  });

  const popoverProps: TPopoverProps = {
    content: `Детектор: ${getDeviceTitle({
      currentObject: dt,
      id,
      notSlice: true,
    })}`,
    placement: 'left',
  };

  return (
    <ButtonStatus
      isActive={activeDtId === id}
      popoverProps={popoverProps}
      icon={
        <IcoDevice
          system={system}
          statusDevice={statusDevice}
          iconStyle={styles.icon}
        />
      }
      title={title}
      titleClassName={styles.title}
      handleClick={handleClickItem}
    />
  );
};

export default observer(DtItem);
