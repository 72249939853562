import { DEVICES_STORE_NAMES } from '../../../constants/devicesConstants';
import rootStore from '../../../stores/rootStore/rootStore';
import { System } from '../../../ts/enums/enums';

export const getPointUdsByDeviceId = (id: number, system: System) => {
  const { pointsUds, getById } = rootStore.mapDataStore;

  const deviceStoreName = DEVICES_STORE_NAMES[system];

  const device = deviceStoreName && getById(id, deviceStoreName);

  const linkedUdsSchemaUid = device?.deviceInfo?.pointUdsUid;

  if (!linkedUdsSchemaUid) return null;

  const pointUds = pointsUds.find(
    ({ deviceInfo }) => deviceInfo.pointUdsUid === linkedUdsSchemaUid
  );

  return pointUds;
};
