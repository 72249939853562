import NOTICE from '../../../constants/notificationConstants';
import showNotify from '../../../helpers/showNotify';
import { TableData } from '../../../ts/models/table.model';
import { getAppPathHeaders } from '../../helpers/getAppPathHeaders';
import http from '../../http';
import tryCatchWrapper from '../../tryCatchWrapper';
import { ADMIN } from '../constants/constants';

import { AdminGroupsSchema, WebGroupSettingsSchema } from './groups.zod';

const GROUPS = ADMIN + '/groups';
const ACCESS = ADMIN + '/access';

export const groups = {
  fetchGroups: async (appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.get(GROUPS, {
        headers: getAppPathHeaders(appPath),
      });

      const AdminGroupsSchemaArray = AdminGroupsSchema.array();

      const parsedData = AdminGroupsSchemaArray.parse(res.data);

      return parsedData;
    }),
  fetchGroupSettings: async (id: number, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.get(ACCESS + `/${id}`, {
        headers: getAppPathHeaders(appPath),
      });

      const validation = WebGroupSettingsSchema.safeParse(res.data);

      if (!validation.success) console.log(validation.error);

      const data: TableData = res.data;

      return data;
    }),
  updateGroupAccess: (item: any, groupId: number, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.post(
        ACCESS,
        {
          fieldId: item.id,
          groupId: Number(groupId),
          access: !item.access,
        },
        {
          headers: getAppPathHeaders(appPath),
        }
      );

      showNotify(res, NOTICE.SUCCESS_CONFIG_UPDATE);

      return res;
    }),
  addNewGroup: async (body: any, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.post(GROUPS, body, {
        headers: getAppPathHeaders(appPath),
      });

      return res.status;
    }),
  deleteGroup: async (id: string | number, body: any, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.delete(GROUPS + `/${id}`, {
        headers: getAppPathHeaders(appPath),
        data: body,
      });

      return res.status;
    }),
  changeGroupItem: async (id: number | string, body: any, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.put(GROUPS + `/${id}`, body, {
        headers: getAppPathHeaders(appPath),
      });

      return res.status;
    }),
};
