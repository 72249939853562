import { FC } from 'react';

import styles from './Text.module.scss';

interface ITextProps {
  x: number;
  y: number;
  textAnchor?: string;
  title: string;
}

const Text: FC<ITextProps> = ({ x, y, title, textAnchor = 'middle' }) => {
  return (
    <text x={x} y={y} textAnchor={textAnchor} className={styles.textHalf}>
      {title}
    </text>
  );
};

export default Text;
