import { useMemo, useState } from 'react';

import rootStore from '../../../../../stores/rootStore/rootStore';
import { ICsdData } from '../../../../../ts/models/signalPrograms.model';
import { ModalConfirmProps } from '../../PhaseHold/ModalConfirm/ModalConfirm';
import { IPhasesData } from '../../PhaseHold/PhaseHold.model';
import { editSignalProgram } from '../../TemporaryPlan/helpers/editSignalProgram';
import { getCurrentSgnProgramInfo } from '../helpers/getCurrentSgnProgramInfo';
import { BasicModalSignalProgram } from '../model/currentPlan.model';

interface UseEditCurrentProgramProps {
  id: number;
  isEditTable: boolean;
  csdData: U<ICsdData>;
  dataTable: IPhasesData[];
  setDefaultDataTable: () => void;
}

const useEditCurrentProgram = ({
  id,
  isEditTable,
  csdData,
  dataTable,
  setDefaultDataTable,
}: UseEditCurrentProgramProps) => {
  const { tls } = rootStore.mapDataStore;

  const [openEditForm, setOpenEditForm] = useState<boolean>(false);

  const { sgnProgramName, planName } = useMemo(
    () => getCurrentSgnProgramInfo(tls, id),
    [id, tls]
  );

  const readySgnProgram = useMemo(
    () => (dataTable ? editSignalProgram(dataTable, csdData) : null),
    [dataTable, csdData]
  );

  const baseProgramProps: BasicModalSignalProgram = {
    programExecTime: planName,
    csdData: readySgnProgram?.newCsdDataPhase,
    phasesSet: readySgnProgram?.newPhasesSet,
    isLoadingProgram: true,
    id,
    isError: Boolean(readySgnProgram?.isError),
  };

  const editProps: Omit<ModalConfirmProps, 'icon'> = {
    width: '1400px',
    modalTitle: `Режим редактирования временного плана`,
    openModal: openEditForm,
    onOkConfirm: () => setOpenEditForm(false),
    setOpenModal: setOpenEditForm,
    handleCancel: setDefaultDataTable,
    okText: 'Применить',
    okButtonProps: { disabled: !isEditTable },
  };

  return {
    sgnProgramName,
    editProps,
    baseProgramProps,
    setOpenEditForm,
  };
};

export default useEditCurrentProgram;
