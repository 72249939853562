import { Checkbox, Select, Slider } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { DefaultOptionType } from 'antd/lib/select';
import { useState } from 'react';
import { MdOpacity, MdOutlineZoomIn, MdOutlineZoomOut } from 'react-icons/md';

import { getScaleResolution } from '../../../../../apiGIS/layers/helpers';
import { getLayer } from '../../../../../apiGIS/layers/utils/getLayer';
import { getOpacity } from '../../../../../apiGIS/layers/utils/getOpacity';
import { isLayerVisible } from '../../../../../apiGIS/layers/utils/isLayerVisible';
import { scalesToResolutions } from '../../../../../apiGIS/utils/view';
import { TMap } from '../../../../../stores/mapStore/mapStore.model';
import {
  MAX_OPACITY,
  MIN_OPACITY,
  SETTINGS_MIN_SCALE_HINT,
  SETTINGS_MAX_SCALE_HINT,
  SETTINGS_OPACITY_HINT,
  SETTINGS_VISIBILITY,
  STEP_OPACITY,
} from '../../constants';
import { ILayerDataNode } from '../../layers.model';
import styles from '../../LayersButton.module.scss';

const getResolutionValue = (
  resolution: U<number>,
  resolutions: DefaultOptionType[]
) => {
  const element = resolutions.find(
    (item: DefaultOptionType) => item.value === resolution
  );

  if (!resolutions.length) {
    return 1;
  }

  if (!element) {
    return Number(resolutions[0].value);
  }

  return Number(element.value);
};

const getMinResolutionValue = (map: TMap, id: string) => {
  const value = getLayer(map, id)?.getMinResolution();

  const MIN_SCALE = 500;

  return Number.isFinite(value)
    ? value
    : getScaleResolution(map, MIN_SCALE) || 1;
};

const getMaxResolutionValue = (map: TMap, id: string) => {
  const value = getLayer(map, id)?.getMinResolution();

  const MAX_SCALE = 5000000;

  return Number.isFinite(value)
    ? value
    : getScaleResolution(map, MAX_SCALE) || 1;
};

const LayerSettingsCommon = (props: {
  map: TMap;
  selectedNode: ILayerDataNode;
}) => {
  const { map, selectedNode } = props;

  const id = selectedNode.item?.id || '';

  const [resolutions] = useState(scalesToResolutions(map));
  const [opacity, setOpacity] = useState(getOpacity(map, id));
  const [visible, setVisible] = useState(isLayerVisible(map, id));
  const [minResolution, setMinResolution] = useState(
    getResolutionValue(getMinResolutionValue(map, id), resolutions)
  );
  const [maxResolution, setMaxResolution] = useState(
    getResolutionValue(getMaxResolutionValue(map, id), resolutions)
  );

  if (!map || !id) {
    return null;
  }

  const layer = getLayer(map, id);

  const setLayerOpacity = (value: number) => {
    layer?.setOpacity(value);

    setOpacity(value);
  };

  const onVisibleChange = (evt: CheckboxChangeEvent) => {
    const layer = getLayer(map, id);

    const value = isLayerVisible(map, id);

    setVisible(!value);

    layer?.setVisible(!value);
  };

  const onMinResolutionChange = (value: number) => {
    const layer = getLayer(map, id);

    layer?.setMinResolution(value);

    setMinResolution(value);
  };

  const onMaxResolutionChange = (value: number) => {
    const layer = getLayer(map, id);

    layer?.setMaxResolution(value);

    setMaxResolution(value);
  };

  // @ts-ignore
  return (
    <>
      <div className={styles.labelsOptionsContainer}>
        <Checkbox checked={visible} onChange={onVisibleChange} />
        <div className={styles.optionTitle}>Видимость слоя</div>
      </div>
      <div className={styles.settingsHint}>{SETTINGS_VISIBILITY}</div>
      <div>
        <div className={styles.rowLine}>
          <MdOpacity size={16} />
          <div className={styles.optionTitle}>Прозрачность слоя</div>
        </div>
        <Slider
          className={styles.opacityStyle}
          value={opacity}
          min={MIN_OPACITY}
          max={MAX_OPACITY}
          step={STEP_OPACITY}
          onChange={setLayerOpacity}
        />
        <div className={styles.settingsHint}>{SETTINGS_OPACITY_HINT}</div>
      </div>
      <div>
        <div>
          <div className={styles.rowLine}>
            <MdOutlineZoomOut size={16} />
            <div className={styles.optionTitle}>Максимальный масштаб</div>
          </div>
          <Select
            value={minResolution}
            options={resolutions}
            className={styles.settingsInput}
            onChange={onMinResolutionChange}
          />
          <div className={styles.settingsHint}>{SETTINGS_MAX_SCALE_HINT}</div>
        </div>

        <div>
          <div className={styles.rowLine}>
            <MdOutlineZoomIn size={16} />
            <div className={styles.optionTitle}>Минимальный масштаб</div>
          </div>
          <Select
            value={maxResolution}
            options={resolutions}
            className={styles.settingsInput}
            onChange={onMaxResolutionChange}
          />
          <div className={styles.settingsHint}>{SETTINGS_MIN_SCALE_HINT}</div>
        </div>
      </div>
    </>
  );
};

export default LayerSettingsCommon;
