import { VideoStreamsProxyList } from '../../../../api/server/web/web.model';
import rootStore from '../../../../stores/rootStore/rootStore';

type VideoStreamsProxy = VideoStreamsProxyList[0];

const proxyListIterationsMap = new Map<string, number>();

function getCurrentProxyHost(
  url: VideoStreamsProxy['url'],
  proxyHosts: VideoStreamsProxy['proxyHosts']
) {
  let proxyHostsIndex = (proxyListIterationsMap.get(url) ?? -1) + 1;

  if (proxyHostsIndex === proxyHosts.length) {
    proxyListIterationsMap.set(url, 0);
    proxyHostsIndex = 0;
  } else {
    proxyListIterationsMap.set(url, proxyHostsIndex);
  }

  return proxyHosts.at(proxyHostsIndex);
}

export const getPreparedActiveStreamUrl = (activeSteamUrl: string): string => {
  const videoStreamsProxyList =
    rootStore.videoWallPanelStore.videoStreamsProxyList;

  if (!videoStreamsProxyList.length) return activeSteamUrl;

  const matchingProxyItem = videoStreamsProxyList.find((item) => {
    if (!item.url) return false;

    try {
      if (!item.url.includes('http')) item.url = 'http://' + item.url;

      const url = new URL(item.url);

      return activeSteamUrl.includes(url.hostname);
    } catch (error) {
      return false;
    }
  });

  if (!matchingProxyItem) return activeSteamUrl;

  const { url, proxyHosts } = matchingProxyItem;

  const currentProxyHost = getCurrentProxyHost(url, proxyHosts);

  if (!currentProxyHost) return activeSteamUrl;

  const { hostname } = new URL(url);

  const preparingActiveSteamUrl = activeSteamUrl.replace(
    hostname,
    currentProxyHost
  );

  return preparingActiveSteamUrl;
};
