import { useMemo, useRef } from 'react';

import rootStore from '../../../../stores/rootStore/rootStore';
import useResize from '../../../NewSignalProgram/hooks/useResize';
import { getWidthByPercent } from '../helpers/getWidthByPercent';

const RADIUS_PERCENT_OF_WIDTH = 0.2;

const useInitialCircle = () => {
  const { isPanel } = rootStore.uiStore;

  const containerRef = useRef<N<HTMLDivElement>>(null);

  const { width, height } = useResize({ wrapRef: containerRef, isPanel });

  const radiusWeight = useMemo(
    () => getWidthByPercent(width, RADIUS_PERCENT_OF_WIDTH),
    [width]
  );

  return { containerRef, width, height, radiusWeight };
};

export default useInitialCircle;
