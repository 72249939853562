import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { CSSProperties, FC } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import Title from '../../ui-kit/Title/Title';
import CollapseBtn from '../CollapseBtn/CollapseBtn';

import { getCollapseProps } from './helpers/getCollapseProps';
import { getPanelTitle } from './helpers/getPanelTitle';

import styles from './PanelLayout.module.scss';

interface LayoutProps {
  children: N<JSX.Element>;
}

const PanelLayout: FC<LayoutProps> = ({ children }) => {
  const { isPanel, panelType, moduleManagementType } = rootStore.uiStore;
  const { isScriptsCreateDetailed, isScriptsControl } =
    rootStore.scriptsControlStore;
  const { isConstructorDetailed } = rootStore.constructorStore;

  const title = getPanelTitle(panelType, moduleManagementType);

  const panelStyle = classNames({
    [styles.panelDefault]: true,
    [styles.isPanel]: isPanel,
  });

  const style: CSSProperties = {
    position: 'absolute',
    top: 25,
    left: 45,
    color: 'var(--white600)',
  };

  return (
    <section className={panelStyle}>
      <aside className={styles.container}>
        <CollapseBtn
          {...getCollapseProps(
            panelType,
            isScriptsCreateDetailed,
            isConstructorDetailed,
            isScriptsControl
          )}
        />
        {title && <Title title={title} maxLength={30} titleStyle={style} />}
        {children}
      </aside>
    </section>
  );
};

export default observer(PanelLayout);
