import { z } from 'zod';

import {
  MODES_ARR,
  SOURCE_ARR,
  STATES_ARR,
} from '../../../../constants/commands';
import {
  CircleElEnum,
  DirectionTypes,
  TLPhaseCodes,
} from '../../../../ts/enums/tl';
import { getTlStatusZodValidation } from '../../../helpers/getTlStatusZodValidation';
import {
  MessageType,
  getZodMessageCustom,
} from '../../../helpers/getZodMessageCustom';
import { Int, NNInt, XY } from '../../../zod/utils.zod';
import {
  BasicMapDataSchema,
  CoordinatesSchema,
  OtherSchema,
} from '../../mapObjects.zod';

export const EputsAdapterSchema = z.object({
  /* eslint-disable camelcase */
  device_id: NNInt,
  scSystemId: NNInt,
  sourceType: NNInt,
  tlId: NNInt,
  ogcFid1: NNInt.nullable(),
});

export const GeometrySchema = z.object({
  type: z.literal('MultiPoint'),
  coordinates: CoordinatesSchema.array(),
});

export const PropertiesSchema = z.object({
  highway: z.string(),
});

export const TLMainSchema = z.object({
  caption: z.string(),
  iconOffsetX: Int,
  iconOffsetY: Int,
  id: NNInt,
  isEnabled: z.boolean(),
  isIcon: z.boolean(),
});

export const TLDetailsImageSchema = z.object({
  code: z.nativeEnum(TLPhaseCodes).nullable(),
  tact: NNInt.nullable(),
  directions: z.array(z.number()),
  image: z.string().endsWith('.png'),
  main: z.union([z.literal(0), z.literal(1)]),
  phase: z.number().positive().nullable(),
});

export const TLAnimationSchema = z.record(z.string(), z.unknown()).nullable();

export const TLTactSchema = z.object({
  main: z.union([z.literal(0), z.literal(1)]),
  tact: z.number().positive(),
  phase: z.number().positive(),
  time: NNInt,
  color: z.string().nullable(),
  tactType: z.number().positive().lte(3).nullable(),
  animation: TLAnimationSchema.optional(),
  addInfo: z.unknown().nullable().optional(),
  direction: z.array(z.number()).nullable(),
  durationMin: NNInt,
});

export const TLZoomsTactSchema = z.object({
  range: CoordinatesSchema,
  diameter: NNInt,
  borderWidth: NNInt,
  hue: NNInt,
  other: OtherSchema,
  tlTactsImagesIndex: NNInt.nullable(),
});

export const DashSchema = z.object({
  sizeRate: z.number(),
  dashArray: CoordinatesSchema,
});

export const TLTactsImageSchema = z.object({
  type: z.nativeEnum(CircleElEnum),
  direction: Int.positive().optional(),
  angle: NNInt,
  positionXY: CoordinatesSchema,
  size: NNInt,
  color: z.string(),
  strokeWidth: NNInt,
  dash: DashSchema.nullable(),
  animation: TLAnimationSchema,
});

export const TLCrossroadSchema = z.object({
  id: NNInt.optional().nullable(), // temporary optional
  angle: NNInt.lte(360),
  positionXY: CoordinatesSchema,
  size: NNInt,
  range: XY.nullable().optional(),
  svgURL: z.string().nullable().optional(),
  strokeWidth: z.number().positive().optional(),
  color: z.string().optional(),
  colorTransition: z.string().optional(),
  isBlinkingTransitionTact: z.boolean().optional(),
  crosswalkColor: z.string().optional(),
  crosswalkColorTransition: z.string().optional(),
});

export const TdkSchema = z.object({
  codeNum: z.number().positive().lte(19),
  short: z.string(),
  full: z.string(),
});

export const TLGeneralSchema = z.object({
  currentMode: NNInt.optional().nullable(),
  progNum: NNInt.nullable(),
  progNumFrom: z.string().nullable(),
  progNumTo: z.string().nullable(),
  progViewName: z.string().nullable(),
  cycleTime: NNInt.nullable(),
  cycleTimeStart: z.string().datetime({ offset: true }).nullable(),
  tactsCount: NNInt.multipleOf(2).nullable(),
  tactsView: NNInt.nullable(),
  tactsTVP: NNInt.nullable(),
  tactsVPU: NNInt.nullable(),
  tdk: TdkSchema.nullable(),
  imagesPoint: z.object({ geometry: GeometrySchema }).nullable(),
  other: z.string().nullable().optional(),
  mag: z.number().nullable(),
});

export const TLSignalProgramItemSchema = z.object({
  type: z.number().positive().lte(6),
  start: NNInt,
  stop: NNInt.nullable(),
  onlygreen: NNInt.nullable().optional(),
  phase: NNInt,
  step: NNInt.nullable().optional(),
});

export const TLSignalProgramItemSchema2 = z.object({
  data: TLSignalProgramItemSchema.array(),
  dirNum: NNInt,
  viewName: z.string(),
  phaseGroupNum: NNInt,
  dirType: z.nativeEnum(DirectionTypes),
});

export const CommandSchema = z.object({
  cmd: z.string(),
  full: z.string(),
  short: z.string(),
});

export const TLStatusSchema = z
  .object({
    mode: NNInt.refine(
      (val) => MODES_ARR.includes(val),
      getZodMessageCustom({
        nameValue: 'mode',
        rangeArr: MODES_ARR,
        messageType: MessageType.OnArray,
      })
    ),
    cycle: NNInt.nullable(),
    timeRcv: NNInt.nullable(),
    lsec: NNInt.nullable(),
    last: z.number(),
    offset: NNInt,
    state: NNInt.refine(
      (val) => STATES_ARR.includes(val),
      getZodMessageCustom({
        nameValue: 'state',
        rangeArr: STATES_ARR,
        messageType: MessageType.OnArray,
      })
    ),
    source: NNInt.nullable().refine(
      (val) => val === null || SOURCE_ARR.includes(val),
      getZodMessageCustom({
        nameValue: 'source',
        rangeArr: SOURCE_ARR,
        messageType: MessageType.OnArray,
      })
    ),
    extmode: NNInt.nullable(),
    extsource: NNInt.nullable(),
    command: CommandSchema.nullable(),
  })
  .refine(
    (tlStatus) => getTlStatusZodValidation(tlStatus, 'cycle'),
    getZodMessageCustom({
      nameValue: 'cycle',
      messageType: MessageType.NotAccessNull,
    })
  )
  .refine(
    (tlStatus) => getTlStatusZodValidation(tlStatus, 'lsec'),
    getZodMessageCustom({
      nameValue: 'lsec',
      messageType: MessageType.NotAccessNull,
    })
  )
  .refine(
    (tlStatus) => getTlStatusZodValidation(tlStatus, 'timeRcv'),
    getZodMessageCustom({
      nameValue: 'timeRcv',
      messageType: MessageType.NotAccessNull,
    })
  )
  .refine(
    (tlStatus) => getTlStatusZodValidation(tlStatus, 'last'),
    getZodMessageCustom({
      nameValue: 'last',
      messageType: MessageType.Positive,
    })
  );

export const TLDaiJdt83Schema = z.object({
  tlMain: TLMainSchema,
  tlTactsImages: TLTactsImageSchema.array(),
  crossroad: TLCrossroadSchema.nullable().optional(),
  images: TLDetailsImageSchema.array().nullable(),
  imagesURL: z.string().nullable(),
  other: OtherSchema,
  tlZoomsTacts: TLZoomsTactSchema.array().nullable(),
});

export const TLSchema = BasicMapDataSchema.extend({
  dai_jdt83: TLDaiJdt83Schema.nullable(),
  id: NNInt,
  regionId: z.number(),
  tlGeneral: TLGeneralSchema,
  tlSignalProgram2: TLSignalProgramItemSchema2.array().nullable().optional(),
  tlStatus: TLStatusSchema,
  tlTacts: TLTactSchema.array().nullable(),
});
