import {
  TYPES_CONTENT,
  TactsVal,
} from '../../../../../../constants/signalsConstants';

export const getPopoverTitle = (
  title: U<string>,
  dirNum: number,
  isStatic: boolean,
  type: TactsVal
) => {
  const dirType = title ?? '';
  const comma = title ? ', ' : '';
  const status = !isStatic ? `${comma}${TYPES_CONTENT[type]}` : '';
  const popoverContent = `Направление № ${dirNum} (${dirType}${status})`;

  return popoverContent;
};
