import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { findById } from '../../../../helpers/findBy';
import rootStore from '../../../../stores/rootStore/rootStore';
import { getMeteoInfo } from '../../../Mapper/TooltipContent/MapIconContent/MeteoInfo/helpers/getMeteoInfo';
import NoData from '../../../ui-kit/Errors/NoData/NoData';

import MeteoCard from './MeteoCard/MeteoCard';
import MeteoEco from './MeteoEco/MeteoEco';
import MeteoRoad from './MeteoRoad/MeteoRoad';
import MeteoRoadHeight from './MeteoRoadHeight/MeteoRoadHeight';

import styles from './MeteoInfoPopup.module.scss';

interface IMeteoInfoPopupProps {
  id: number;
}

const MeteoInfoPopup: FC<IMeteoInfoPopupProps> = ({ id }) => {
  const { meteo } = rootStore.mapDataStore;

  const meteoItem = findById(meteo, id);

  const meteoInfo = getMeteoInfo(meteoItem?.data);

  if (!meteoItem || !meteoInfo) return <NoData />;

  return (
    <div className={styles.container}>
      <MeteoCard meteoInfo={meteoInfo} timestamp={meteoItem.data.timestamp} />
      <MeteoRoad meteoInfo={meteoInfo} />
      <MeteoRoadHeight meteoInfo={meteoInfo} />
      <MeteoEco meteoInfo={meteoInfo} />
    </div>
  );
};

export default observer(MeteoInfoPopup);
