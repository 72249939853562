import {
  RotateLeftOutlined,
  RotateRightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { Image, Space } from 'antd';
import { FC, ReactNode } from 'react';

import './ImageGallery.scss';

interface ImgGallery {
  children: ReactNode;
}

const ImageGallery: FC<ImgGallery> = ({ children }) => {
  return (
    <Image.PreviewGroup
      preview={{
        toolbarRender: (
          _,
          {
            transform: { scale },
            actions: { onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
          }
        ) => (
          <Space size={12} className="toolbar-wrapper">
            <RotateLeftOutlined onClick={onRotateLeft} />
            <RotateRightOutlined onClick={onRotateRight} />
            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
          </Space>
        ),
      }}
    >
      {children}
    </Image.PreviewGroup>
  );
};

export default ImageGallery;
