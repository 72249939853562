import { FC } from 'react';

import { getTimeArray } from '../../RangePicker/helpers/getTimeFooterArray';

import styles from './Footer.module.scss';

interface FooterProps {
  format: string;
}

const Footer: FC<FooterProps> = ({ format }) => {
  const timeArray = getTimeArray(format);

  return (
    <div className={styles.footer}>
      {timeArray.map((el) => (
        <p key={el}>{el}</p>
      ))}
    </div>
  );
};

export default Footer;
