import { type DescriptionsProps } from 'antd/lib';
import { isString, uniqueId } from 'lodash';

import { DictPredictKeys } from '../../../../../../../../api/services/dtStatistics/constants/constants';
import rootStore from '../../../../../../../../stores/rootStore/rootStore';
import getFirstLetterUpper from '../../../../../../../Admin/AdminSystemObject/ASOMonitoringRegion/SubSystemSelect/SelectItem/helpers/getFirstLetterUpper';

const { Predict } = DictPredictKeys;

const getDictItems = (): DescriptionsProps['items'] => {
  const predictDict = rootStore.uiStore.dictionaries[Predict];

  return predictDict
    .map(({ id, name, dsc }) => ({
      key: id ?? uniqueId('dict_key_'),
      label: name ? getFirstLetterUpper(name) : null,
      children: dsc ?? name,
      span: 3,
    }))
    .filter(({ label, children }) => isString(label) && isString(children));
};

export default getDictItems;
