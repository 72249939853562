import {
  IProcessedDataInitial,
  SignalPrograms,
} from '../../../../ts/models/signalPrograms.model';
import { isCorrectProgram } from '../PhaseHold/PhaseHold.model';

export const getCorrectPrograms = (programs: SignalPrograms[]) => {
  return programs.reduce((acc: IProcessedDataInitial[], program) => {
    isCorrectProgram(program) && acc.push(program);

    return acc;
  }, []);
};
