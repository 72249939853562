import { notification } from 'antd';
import { z } from 'zod';

import message from '../../../components/ui-kit/Notification/Notification';
import NOTICE from '../../../constants/notificationConstants';
import showNotify from '../../../helpers/showNotify';
import { Path } from '../../api.model';
import { getAppPathHeaders } from '../../helpers/getAppPathHeaders';
import http from '../../http';
import tryCatchWrapper from '../../tryCatchWrapper';
import { POINT_UDS } from '../pointUdsApi';

import {
  ProfileDB,
  formatProfileItem,
  formatProfileList,
} from './helpers/formatProfile';
import {
  JProfileSchema,
  ProfileSchema,
  ProfileWrapperSchema,
} from './profiles.zod';

const PROFILES = POINT_UDS + 'profiles/';
const DESCRIPTIONS = PROFILES + 'descriptions';

export type JProfile = z.infer<typeof JProfileSchema>;

const profilesApi = {
  // from descriptions postman folder
  fetchProfiles: (uid: string, appPath: Path) =>
    tryCatchWrapper(async () => {
      const res = await http(DESCRIPTIONS, {
        headers: getAppPathHeaders(appPath),
        params: {
          uid,
          isFinalVersion: false,
        },
      });

      const validate = ProfileSchema.array().safeParse(res?.data);

      if (!validate.success) {
        notification.error(NOTICE.INCORRECT_PROFILES_LIST);

        return console.error(
          'incorrect profile list data',
          validate.error.issues
        );
      }

      return formatProfileList(validate.data);
    }),

  fetchProfile: (id: number, appPath: Path) =>
    tryCatchWrapper(async () => {
      const res = await http(PROFILES + id, {
        headers: getAppPathHeaders(appPath),
      });

      const validate = ProfileWrapperSchema.safeParse(res?.data);

      if (!validate.success) {
        notification.error(NOTICE.INCORRECT_PROFILE_DATA);

        console.error('Incorrect profile data', validate.error.issues);
      }

      return res?.data;
    }),

  createProfile: (jprofile: JProfile, appPath: Path) =>
    tryCatchWrapper(async () => {
      const res = await http.post(
        PROFILES,
        {
          jprofile,
        },
        {
          headers: getAppPathHeaders(appPath),
        }
      );

      const profile: ProfileDB = res?.data?.jprofile?.profile;
      const newSelected = profile ? formatProfileItem(profile) : null;

      message.success('ADD_PROFILE');

      return { newSelected, data: res?.data };
    }),

  updateProfile: (id: number, jprofile: JProfile, appPath: Path) =>
    tryCatchWrapper(async () => {
      const res = await http.put(
        PROFILES + id,
        {
          jprofile,
        },
        {
          headers: getAppPathHeaders(appPath),
        }
      );

      showNotify(res);

      return res?.data;
    }),

  deleteProfile: (id: number, appPath: Path) =>
    tryCatchWrapper(async () => {
      const res = await http.delete(PROFILES + id, {
        headers: getAppPathHeaders(appPath),
      });

      showNotify(res);

      return res?.data;
    }),

  deleteByIds: (idsArray: number[], appPath: Path) =>
    tryCatchWrapper(async () => {
      const res = await http.delete(PROFILES, {
        headers: getAppPathHeaders(appPath),
        data: {
          idsArray,
        },
      });

      showNotify(res);

      return res?.data;
    }),
};

export default profilesApi;
