import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import rootStore from '../../../../stores/rootStore/rootStore';

import styles from './Point.module.scss';

const Point = () => {
  const { isWidthMode } = rootStore.uiStore;

  const pointStyles = classNames({
    [styles.point]: true,
    [styles.isGradient]: isWidthMode,
  });

  return <div className={pointStyles}></div>;
};

export default observer(Point);
