import { observer } from 'mobx-react-lite';
import type { Overlay } from 'ol';
import { FC } from 'react';

import { TMap } from '../../../../../../../stores/mapStore/mapStore.model';
import { DeviceInfo } from '../../../../../../../stores/monitoringPanelStore/monitoringPanelStore.model';
import rootStore from '../../../../../../../stores/rootStore/rootStore';
import CrossroadSchema, {
  CrossroadSchemaProps,
} from '../../../../../../TrafficLightDetailed/TlDetectors/GeneralDtTabs/CrossroadSchemaWrapper/CrossroadSchema/CrossroadSchema';
import useCrossroadTLWrapper from '../../../../../../TrafficLightDetailed/TlDetectors/GeneralDtTabs/CrossroadSchemaWrapper/CrossroadTLWrapper/hooks/useCrossroadTLWrapper';

import LinkedDevicesTL from './LinkedDevicesTL/LinkedDevicesTL';

interface CrossroadTLProps {
  tlId: number;
  map: TMap;
  crossroadOverlay: N<Overlay>;
  deviceInfo: DeviceInfo;
}

const CrossroadTL: FC<CrossroadTLProps> = ({
  tlId,
  crossroadOverlay,
  map,
  deviceInfo,
}) => {
  const { mapPropsDetailed } = rootStore.monitoringPanelStore;

  const { crossroadBasicProps, tl } = useCrossroadTLWrapper({
    tlId,
    mapPropsDetailed,
  });

  if (!crossroadBasicProps || !tl) return null;

  const crossroadSchemaProps: CrossroadSchemaProps = {
    ...crossroadBasicProps,
    crossroadOverlay,
    map,
  };

  return (
    <>
      <CrossroadSchema {...crossroadSchemaProps} />
      <LinkedDevicesTL
        selectedTL={tl}
        isShowLanes
        rate={crossroadBasicProps.rate}
        deviceInfo={deviceInfo}
      />
    </>
  );
};

export default observer(CrossroadTL);
