import { observer } from 'mobx-react-lite';

import { ReactComponent as GradientIco } from '../../../../assets/icons/gradientTimer.svg';
import { ReactComponent as LineIco } from '../../../../assets/icons/thinTimer.svg';
import rootStore from '../../../../stores/rootStore/rootStore';

import styles from './SettingBox.module.scss';

const SettingBox = () => {
  const { setKeyValue, isWidthMode } = rootStore.uiStore;

  return (
    <div className={styles.settingBox}>
      <div>
        <input
          type="checkbox"
          name="widthModeInput"
          id="widthModeInputID"
          onChange={(e) => {
            setKeyValue('isWidthMode', e.target.checked);
          }}
        />
        <label htmlFor="widthModeInputID">
          {isWidthMode ? <LineIco /> : <GradientIco />}
        </label>
      </div>
    </div>
  );
};

export default observer(SettingBox);
