import Map from '../Map/Map';
import MapButtons from '../MapBtns/MapButtons';
import MapInfoWrapper from '../MapBtns/MapInfoWrapper/MapInfoWrapper';
import MapObjectsLoader from '../MapObjectsLoader/MapObjectsLoader';
import TLTimeSecWrapper from '../TLTimeSecWrapper/TLTimeSecWrapper';

const Mapper = () => (
  <>
    <MapButtons />
    <MapObjectsLoader />
    <MapInfoWrapper />
    <Map />
    <TLTimeSecWrapper />
  </>
);

export default Mapper;
