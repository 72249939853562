import { useEffect } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import {
  changeCrossroadEls,
  changeCrosswalkEls,
} from '../helpers/changeCrossroadEls';
import { CrossroadElsArgs } from '../models/crossroad.model';

const useCrossroadEls = ({
  data,
  id,
  color = '',
  crosswalkColor = 'blue',
  strokeWidth = 1,
  isCrossroad = true,
}: CrossroadElsArgs) => {
  const { crossroad } = rootStore.constructorStore;
  const { isEnabled } = crossroad;

  useEffect(() => {
    if (!data || !id || !isCrossroad || !isEnabled) return;

    const dirs = document.querySelectorAll(`.st${id}-ph`);
    const crosswalkLines = document.querySelectorAll(`.st${id}-crosswalk-line`);
    const crosswalkPolygons = document.querySelectorAll(
      `.st${id}-crosswalk-polygon`
    );

    changeCrossroadEls({ dirs, color, strokeWidth });
    changeCrosswalkEls(crosswalkLines, crosswalkColor);
    changeCrosswalkEls(crosswalkPolygons, crosswalkColor);
  }, [color, data, id, strokeWidth, crosswalkColor, isCrossroad, isEnabled]);
};

export default useCrossroadEls;
