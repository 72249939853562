import { FC } from 'react';

import useTime from '../../../../hooks/useTime';

interface TimeInfoProps {
  timeZone: N<number>;
}

const TimeInfo: FC<TimeInfoProps> = ({ timeZone }) => {
  const { timeStr } = useTime({ timeZone: timeZone ?? undefined });

  if (!timeStr) return null;

  return <p data-testid="time-str">Местное время: {timeStr}</p>;
};

export default TimeInfo;
