import { Button } from 'antd';
import { FC, useEffect } from 'react';

import ConeImage from '../../../../assets/icons/images/cone.png';
import { UNEXPECTED_ERROR } from '../../../../constants/constants';
import { sendError } from '../../../../helpers/sendError';

import styles from './Fallback.module.scss';

interface IFallBack {
  error: Error;
  resetErrorBoundary: () => void;
}

const ERR_TITLE = 'Ошибка управления СО';
const IMG_ALT = 'Ошибка';
const APP_PATH = 'TlDetailed.TlManegment.Fallback';

const Fallback: FC<IFallBack> = ({ error, resetErrorBoundary }) => {
  useEffect(() => {
    sendError(ERR_TITLE, error, APP_PATH);
  }, [error]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <p className={styles.text}>{UNEXPECTED_ERROR}</p>
        <p className={styles.error}>{error.message}</p>
        <Button onClick={() => resetErrorBoundary()}>Обновить</Button>
      </div>
      <img className={styles.image} alt={IMG_ALT} src={ConeImage} />
    </div>
  );
};

export default Fallback;
