import { TimeRangePickerProps } from 'antd/lib';
import { Rule } from 'antd/lib/form';
import dayjs, { Dayjs } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isoWeek from 'dayjs/plugin/isoWeek';
import { isNull, isNumber } from 'lodash';

import { WEEK_FORMAT } from '../../../../../constants/constants';
import { getIsValidItem } from '../../helpers/getIsValidItem';

import {
  isDateValues,
  isRangeDateValues,
  QueryParamItem,
  VALUE_TYPES,
} from './../../../../../ts/models/table.model';
import checkReversedFormat from './DateFormatPicker/helpers/checkReversedFormat';
import formattedDate from './DateFormatPicker/helpers/formattedDate';

dayjs.extend(advancedFormat);
dayjs.extend(isoWeek);

require('dayjs/locale/ru');

export type ValuesType = 'currentValue' | 'defaultValue';

interface PeriodReturn {
  from: N<Dayjs>;
  to: N<Dayjs>;
}

interface StrToDayjsProps {
  from: N<string>;
  to?: N<string>;
  format: string;
  jvalueType: 'datePickerJson' | 'rangeDatePickerJson';
}

const strToDayjs = ({
  from,
  to,
  format,
  jvalueType,
}: StrToDayjsProps): PeriodReturn => {
  if (!from || isNull(to)) return { to: null, from: null };

  const correctionFormat = format.trim().toUpperCase();
  const correctFormat = formattedDate(correctionFormat);
  const fromDJS = dayjs(from, correctFormat).locale('ru');

  const basicProps = {
    to: null,
  };

  if (jvalueType === VALUE_TYPES.datePickerJson) {
    const isWeekFormat = checkReversedFormat(correctionFormat, WEEK_FORMAT);

    if (isWeekFormat) {
      const [weeks, years] = from.split('.');

      const reversedFormat = correctionFormat.split('').reverse().join('');

      const isReversed = reversedFormat === WEEK_FORMAT;

      const week = Number(isReversed ? years : weeks);
      const year = Number(isReversed ? weeks : years);

      const dateNow = dayjs(from, WEEK_FORMAT)
        .locale('ru')
        .set('year', year)
        .week(week);

      return {
        ...basicProps,
        from: dateNow,
      };
    }

    return { ...basicProps, from: fromDJS };
  }

  return {
    ...basicProps,
    from: fromDJS,
    to: dayjs(to, correctFormat).locale('ru'),
  };
};

interface ParamsProps {
  isNeedCheck?: boolean;
  isReturnDate?: boolean;
}

export const getFormFieldsetValues = (
  queryParams: QueryParamItem[],
  type: ValuesType,
  params: ParamsProps = {}
) => {
  const values: { [key: string]: unknown } = {};

  const { isNeedCheck = false, isReturnDate = true } = params;

  for (let i = 0; i < queryParams.length; i++) {
    const param = queryParams[i];
    const defaultValue = getIsValidItem(param.jvalueType, param.currentValue)
      ? param.currentValue
      : param.defaultValue;
    const paramValue = isNeedCheck ? defaultValue : param[type];

    if (
      param.jvalueType === VALUE_TYPES.rangeDatePickerJson ||
      param.jvalueType === VALUE_TYPES.datePickerJson
    ) {
      if (isDateValues(paramValue, param.jvalueType)) {
        const format = paramValue.dateFormat;

        if (!isReturnDate) {
          values[param.jkey] = paramValue;

          continue;
        }

        const { from } = strToDayjs({
          from: paramValue.value,
          format,
          jvalueType: param.jvalueType,
        });

        values[param.jkey] = {
          value: from,
          dateFormat: format,
        };

        continue;
      }

      if (isRangeDateValues(paramValue, param.jvalueType)) {
        const format = paramValue.dateFormat;

        if (!isReturnDate) {
          values[param.jkey] = paramValue;

          continue;
        }

        const { from, to } = strToDayjs({
          from: paramValue.from,
          to: paramValue.to,
          format,
          jvalueType: param.jvalueType,
        });

        values[param.jkey] = {
          from,
          to,
          dateFormat: format,
        };

        continue;
      }

      values[param.jkey] = null;

      continue;
    }

    values[param.jkey] = paramValue;
  }

  return values;
};

type InputRulesProps = {
  minValue?: N<number>;
  maxValue: N<number>;
};

export const getInputNumberRules = ({
  minValue,
  maxValue,
}: InputRulesProps) => {
  const rules: Rule[] = [
    () => ({
      validator(_: any, value: string) {
        const newReg = new RegExp(/(?<!-)\d+/, 'g');

        if (!value || newReg.test(value)) {
          if (isNumber(minValue) && Number(value) < minValue) {
            return Promise.reject(`Значение не должно быть меньше ${minValue}`);
          }

          if (isNumber(maxValue) && Number(value) > maxValue) {
            return Promise.reject(`Значение не должно быть больше ${maxValue}`);
          }

          return Promise.resolve();
        }

        return Promise.reject(
          'Можно использовать только положительные значения'
        );
      },
    }),
  ];

  return rules;
};

export const getPresets = () => {
  const dayNow = dayjs().locale('ru');
  const endOfDay = dayNow.endOf('day');

  const presets: TimeRangePickerProps['presets'] = [
    {
      label: 'Сегодняшний день',
      value: [dayNow.startOf('day'), endOfDay],
    },
    {
      label: 'Текущая неделя',
      value: [dayNow.startOf('week'), endOfDay],
    },
    {
      label: 'Текущий Месяц',
      value: [dayNow.startOf('month'), endOfDay],
    },
    {
      label: 'Текущий год',
      value: [dayNow.startOf('year'), endOfDay],
    },
  ];

  return presets;
};
