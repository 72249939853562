import Feature from 'ol/Feature';

import { System } from '../../../../../ts/enums/enums';

interface IClusters {
  name: System;
  length: number;
}

export const getClusterDevicesInfo = (clustersFeatures: Feature[]) =>
  clustersFeatures.reduce((acc: IClusters[], value) => {
    const system: System = value.get('system');
    const isSameSystem = acc.some((el) => el.name === system);

    if (isSameSystem) return acc;

    const length = clustersFeatures.filter(
      (feature) => feature.get('system') === system
    ).length;

    const item = { name: system, length };

    acc.push(item);

    return acc;
  }, []);
