import { cloneDeep } from 'lodash';

import { END_TIME } from '../../../../../constants/constants';
import { checkDate } from '../../../../../helpers/checkDate';
import { findById } from '../../../../../helpers/findBy';
import { TL } from '../../../../../ts/models/tl.model';
import { getTLSignalData } from '../../../../NewSignalProgram/helpers/getTLSignalData';

const NO_PLAN = 'План не найден';
const NAME_TL_UNKNOWN = 'Имя СО неизвестно';

export const getCurrentSgnProgramInfo = (tls: TL[], id: number) => {
  const currentObject = findById(tls, id) as U<TL>;

  const tlData = cloneDeep(getTLSignalData(id, tls));

  if (!currentObject) {
    return {
      sgnProgramName: NO_PLAN,
      tlName: NAME_TL_UNKNOWN,
      tlData,
      planName: NO_PLAN,
    };
  }

  const sgnProgramName = currentObject.tlStatus.command?.full ?? NO_PLAN;

  const isValidTLName = !!currentObject.deviceInfo.externalDeviceId;

  const tlName = isValidTLName
    ? `${currentObject.deviceInfo.externalDeviceId} ${currentObject.caption}`
    : NAME_TL_UNKNOWN;

  const dateFrom = checkDate(currentObject.tlGeneral.progNumFrom);
  const dateTo = checkDate(currentObject.tlGeneral.progNumTo);

  const planName = sgnProgramName
    ? `${sgnProgramName}. Расписание: с ${dateFrom}
  ${dateTo ? ` по ${dateTo}.` : ` (${END_TIME}).`}`
    : NO_PLAN;

  return {
    sgnProgramName,
    tlName,
    tlData,
    planName,
  };
};
