import { uniqueId } from 'lodash';
import { FC } from 'react';

import { ICsdData } from '../../../ts/models/signalPrograms.model';

import Direction from './Direction/Direction';

import styles from './Directions.module.scss';

interface IDirections {
  width: number;
  csdData: ICsdData | undefined;
}

const Directions: FC<IDirections> = ({ width, csdData }) => (
  <div className={styles.wrapper}>
    {width
      ? csdData?.directions?.map((direction) => (
          <Direction
            direction={direction}
            width={width}
            csdData={csdData}
            key={uniqueId('@')}
          />
        ))
      : null}
  </div>
);

export default Directions;
