import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import { MAP_BUTTONS_NAMES } from '../../../../../constants/uiComponentConstants';
import rootStore from '../../../../../stores/rootStore/rootStore';

import ContentCard from './ContentCard/ContentCard';
import { getMapInfo } from './helpers/getMapInfo';

import styles from './ContentInfo.module.scss';

const ContentInfo = () => {
  const { isOffsetRightPanel, activeMapButton } = rootStore.uiStore;
  const [isTimeSlider, setIsTimeSlider] = useState(
    activeMapButton === MAP_BUTTONS_NAMES.WEATHER
  );

  const containerStyles = classNames({
    [styles.container]: true,
    [styles.isInfoPanel]: isOffsetRightPanel,
    [styles.isTimeSlider]: isTimeSlider,
  });

  const mapInfo = getMapInfo();

  useEffect(() => {
    setIsTimeSlider(activeMapButton === MAP_BUTTONS_NAMES.WEATHER);
  }, [activeMapButton]);

  return (
    <div className={containerStyles}>
      {mapInfo.map((el) => (
        <ContentCard key={el.title} {...el} />
      ))}
    </div>
  );
};

export default observer(ContentInfo);
