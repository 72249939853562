import ContentCard from './ContentCard/ContentCard';
import DetailedBtn from './DetailedBtn/DetailedBtn';

import styles from './DeviceCard.module.scss';

const DeviceCard = () => {
  return (
    <div className={styles.container}>
      <h3> Карточка устройства: </h3>
      <DetailedBtn />
      <ContentCard />
    </div>
  );
};

export default DeviceCard;
