import { FC, ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { UNEXPECTED_ERROR } from '../../../../constants/constants';

const Error = () => <h3>{UNEXPECTED_ERROR}</h3>;

interface UnexpectedErrorProps {
  children: JSX.Element | ReactNode;
  resetKeys?: unknown[];
}

const UnexpectedError: FC<UnexpectedErrorProps> = ({ children, resetKeys }) => (
  <ErrorBoundary fallback={<Error />} resetKeys={resetKeys}>
    {children}
  </ErrorBoundary>
);

export default UnexpectedError;
