import Feature from 'ol/Feature';
import { Style } from 'ol/style';

import { FeatureSymbol } from './FeatureSymbol';
import { IUniqueValuesRendererDefinition } from './renderer.model';

const getUniqueValueSymbolsDict = (
  renderer: IUniqueValuesRendererDefinition
) => {
  const dict = new Map<string, Style | undefined>();

  renderer.uniqueValueInfos.forEach((element) => {
    const { value, symbol } = element;

    dict.set(String(value), FeatureSymbol(symbol));
  });

  return dict;
};

const getUniqueValuesDefaultSymbol = (
  renderer: IUniqueValuesRendererDefinition
): Style | undefined => {
  const defaultSymbol = renderer.defaultSymbol;

  return FeatureSymbol(defaultSymbol);
};

const getUniqueValuesSymbol = (
  renderer: IUniqueValuesRendererDefinition,
  feature: Feature,
  resolution: number,
  defaultSymbol: Style | undefined,
  symbolsDict: Map<string, Style | undefined>
): Style | undefined => {
  const properties = feature.getProperties();

  const { field1 } = renderer;

  const value = properties[field1];

  if (!value) {
    return defaultSymbol;
  }

  const definition = renderer.uniqueValueInfos.find(
    (element) => element.value === String(value)
  );

  if (definition) {
    return symbolsDict.get(String(value)) || FeatureSymbol(definition.symbol);
  }

  return defaultSymbol;
};

export {
  getUniqueValuesSymbol,
  getUniqueValuesDefaultSymbol,
  getUniqueValueSymbolsDict,
};
