import { Checkbox } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { COMMANDS } from '../../../../../constants/commands';
import rootStore from '../../../../../stores/rootStore/rootStore';
import DatePicker from '../../../../ui-kit/Picker/DatePicker/DatePicker';
import TimePicker from '../../../../ui-kit/Picker/TimePicker/TimePicker';
import useTimeCard from '../../hooks/timeCards/useTimeCard';
import { TimeCardBasicProps } from '../../model/timeCard.model';

import styles from './TimePhaseCard.module.scss';

const TimePhaseCard: FC<TimeCardBasicProps> = ({
  isChecked,
  selectedTimeInfo,
  setIsChecked,
  setSelectedTimeInfo,
}) => {
  const { isActiveControlBtn } = rootStore.detailedStore;

  const isDisabled = !isActiveControlBtn;

  const { datePickerProps, timePickerProps, isAnimate, durationBlockRef } =
    useTimeCard({
      command: COMMANDS.SET_OBJECT_PHASE,
      selectedTimeInfo,
      isDisabled,
      isChecked,
      setSelectedTimeInfo,
    });

  const checkboxContentStyle = classNames({
    [styles.timeCardStyle]: true,
    [styles.timeCardAnimate]: isAnimate,
  });

  return (
    <div className={styles.timePickerCard}>
      <Checkbox
        checked={isChecked}
        disabled={isDisabled}
        onChange={(e) => setIsChecked(e.target.checked)}
      >
        <h4 className={styles.title}>
          Включение удержания фазы ДК в определенное время с определенной
          длительностью
        </h4>
      </Checkbox>
      {isChecked && (
        <div className={checkboxContentStyle}>
          <div className={styles.spaceHorizontalDuration}>
            <h4>Дата:</h4>
            <DatePicker {...datePickerProps} />
          </div>

          <div
            className={styles.spaceHorizontalDuration}
            ref={durationBlockRef}
          >
            <h4>Длительность:</h4>
            <TimePicker {...timePickerProps} />
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(TimePhaseCard);
