import { useState } from 'react';

import createRefs from '../helpers/createRefs';

const useRefs = (num: number) => {
  const [refs] = useState(createRefs(num));

  return refs;
};

export default useRefs;
