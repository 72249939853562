import { cloneDeep, isEqual } from 'lodash';
import { action, computed, flow, makeAutoObservable } from 'mobx';
import { Feature } from 'ol';
import type { Coordinate } from 'ol/coordinate';
import { Point } from 'ol/geom';
import { fromLonLat, toLonLat } from 'ol/proj';

import { VideoStreamsProxyList } from '../../api/server/web/web.model';
import { IFeaturesArr } from '../../components/Map/helpers/getFeaturesCluster';
import { deleteSelectedClusterAlarm } from '../../components/Mapper/helpers/deleteSelectedClusterAlarm';
import { getErrorsObj } from '../../components/Mapper/helpers/getErrorsObj';
import { getFilteredTLLinkedDevices } from '../../components/TrafficLightDetailed/helpers/getFilteredTLLinkedDevices';
import { EVENTS, SYSTEM } from '../../constants/constants';
import {
  ADD_LAYER_CORRECTION,
  NULLABLE_ANIMATION_DURATION,
} from '../../constants/mapClusterConstants';
import { ZOOM } from '../../constants/mapConstants';
import data from '../../data/data';
import eventBus from '../../eventBus';
import { findById } from '../../helpers/findBy';
import { ActualMapObj, MapObject, System } from '../../ts/enums/enums';
import { ICamera } from '../../ts/models/camera.model';
import { Detector } from '../../ts/models/mapObject.model';
import { TMeteo } from '../../ts/models/MapObjects/meteo.model';
import { PointUds } from '../../ts/models/pointUds.model';
import { TL } from '../../ts/models/tl.model';
import checkCycleTime from '../helpers/checkCycleTime';
import { getArrayIdsFeatures } from '../helpers/getArrayIdsFeatures';
import setObjectProperty from '../helpers/setObjectProperty';
import RootStore from '../rootStore/rootStore';
import { StoreUtils } from '../storeUtils/storeUtils';

import { DEVICES_AMOUNT, LOADING } from './constants/constants';
import setDataLog from './helpers/setDataLog';
import {
  ICurObjWs,
  IWSItem,
  LonLat,
  MapDataArrays,
  TLRes,
  TObjMap,
  TSystemsInfo,
} from './mapDataStore.model';
import service from './mapDataStore.service';

const {
  LIGHTS,
  DETECTORS,
  CAMERAS,
  SPECIAL_TRANSPORT,
  PUBLIC_TRANSPORT,
  METEO,
} = SYSTEM;

const { RTA, ROAD_WORKS, ACTIVITIES, TRAFFIC_RESTRICTIONS } = EVENTS;
const { INITIAL, CROSSROAD_BORDER } = ZOOM;

const { PERCENT_STEP, DELAY_STEP } = LOADING;

const { tls } = ActualMapObj;
const { Longitude, Latitude } = LonLat;

let centeredDeviceTimerId: N<NodeJS.Timer> = null;

class MapDataStore {
  rootStore;
  tls: TL[] = [];
  cameras: ICamera[] = [];
  detectors: Detector[] = [];
  meteo: TMeteo[] = [];
  pointsUds: PointUds[] = [];
  currentZoom = INITIAL;
  resolution = 1;
  coordinates: Coordinate = [0, 0];
  publicTransport = data.publicTransport;
  specialTransport = data.specialTransport;
  roadWorks = data.roadWorks;
  rta = data.rta;
  tlCurrentTacts: any[] = [];
  wheelZoom = 0;
  errorsTl: IFeaturesArr[] | null = [];
  center: Coordinate = [];
  percentLoad = 0;
  fetchedDevicesCounter = 0;
  selectedCamera: N<ICamera> = null;
  selectedFeature: N<Feature<Point>> = null;
  isMapMoveEnd = true;
  viewResolution = -1;
  isTlsDataFetched = true;
  setMapData;
  setKeysValues;

  constructor(rootStore: typeof RootStore) {
    makeAutoObservable(
      this,
      {
        rootStore: false,
        fetchTrafficLights: flow.bound,
        fetchDetectors: flow.bound,
        fetchCameras: flow.bound,
        fetchPointsUds: flow.bound,
        getCoordinatesById: action.bound,
        systemCoords: computed.struct,
      },
      { deep: true }
    );

    this.rootStore = rootStore;

    const utils = new StoreUtils(this);

    this.setMapData = utils.setKeyValue;
    this.setKeysValues = utils.setKeysValues;
  }

  *fetchTrafficLights(regionId: number) {
    const { setMapData, setPercent } = this;

    const { data, isOk }: TLRes = yield service.getObjects(
      MapObject.tl,
      regionId
    );

    !isOk && setMapData('isTlsDataFetched', false);

    setPercent();

    const correctedData = checkCycleTime(data);

    const trafficLightsData: TL[] = correctedData.map((el) => {
      const { latitude, longitude, caption } = el.deviceInfo;

      const newEl: TL = {
        ...el,
        latitude,
        longitude,
        caption,
        linkedDeviceIds: getFilteredTLLinkedDevices(el.linkedDeviceIds, el.id),
      };

      return newEl;
    });

    setDataLog('traffic lights', trafficLightsData);

    this.tls = trafficLightsData;
  }

  *fetchDetectors(regionId: number) {
    const { data } = yield service.getObjects<Detector>(MapObject.dt, regionId);

    setDataLog('detectors', data);

    this.setPercent();
    this.detectors = data;
  }

  *fetchCameras(regionId: number) {
    const { data } = yield service.getObjects(MapObject.cm, regionId);

    setDataLog('cameras', data);

    this.setPercent();
    this.cameras = data;
  }

  *fetchMeteo(regionId: number) {
    const { data }: { data: TMeteo[] } = yield service.getObjects(
      MapObject.mt,
      regionId
    );

    setDataLog('meteo', data);

    this.setPercent();
    this.meteo = data;
  }

  *fetchPointsUds(regionId: number) {
    const { data } = yield service.getObjects(MapObject.uds, regionId);

    const points = data.map((item: PointUds) => {
      const [longitude, latitude] = item.basicProfileInfo.centerPoint;

      item.longitude = longitude;
      item.latitude = latitude;

      return item;
    });

    setDataLog('points uds', data);

    this.setPercent();
    this.pointsUds = points;
  }

  *fetchVideoStreamsProxyList(regionId: number) {
    const { data }: { data: VideoStreamsProxyList } =
      yield service.getVideoStreamsProxyList(regionId);

    const videoStreamsProxyList = data.map((item) => {
      item.proxyHosts = item.proxyHosts.filter((proxy) => proxy !== '');

      return item;
    });

    this.rootStore.videoWallPanelStore.setKeyValue(
      'videoStreamsProxyList',
      videoStreamsProxyList
    );
  }

  fetchDataByRegion = () => {
    const { regionData } = this.rootStore.uiStore;
    const { loadStartMapSettings, fetchWeather } = this.rootStore.gisDataStore;

    if (!regionData)
      return console.error('Конфигурация региона не установлена');

    const regionId = regionData.id;

    this.rootStore.videoWallPanelStore.clearAllData();
    this.coordinates = regionData.mapCenterCoords;
    this.rootStore.uiStore.infoData = null;
    this.fetchTrafficLights(regionId);
    this.fetchDetectors(regionId);
    this.fetchCameras(regionId);
    this.fetchMeteo(regionId);
    this.fetchPointsUds(regionId);
    this.fetchVideoStreamsProxyList(regionId);
    fetchWeather(regionId);
    loadStartMapSettings(regionId);
  };

  setPercent = () => {
    setTimeout(() => {
      const newValue = this.percentLoad + PERCENT_STEP;

      this.setMapData('percentLoad', newValue);
    }, ++this.fetchedDevicesCounter * DELAY_STEP);
  };

  setCenteredDevice = (id: number, system: System) => {
    const { systemsInfo, isCrossroadBorder, getCoordinatesById, rootStore } =
      this;
    const { map } = rootStore.mapStore;
    const { infoData, setIsMarkers, setInfoData } = rootStore.uiStore;
    const { openClusterByFeatureId } = rootStore.clustersStore;
    const { setPointsValue } = rootStore.pointsUdsStore;

    centeredDeviceTimerId && clearTimeout(centeredDeviceTimerId);
    const curSystem = systemsInfo[system];
    const isTL = infoData?.system === System.Lights;

    if (isCrossroadBorder && map) {
      const coordinate = getCoordinatesById(id);

      if (!coordinate) return;

      return setInfoData({
        id,
        system,
        coordinate: toLonLat(coordinate),
      });
    }

    if (!curSystem) return;

    const isActiveSystem = systemsInfo[system].isMarkers;

    !isActiveSystem && setIsMarkers(system);

    const duration =
      isActiveSystem && !isTL
        ? NULLABLE_ANIMATION_DURATION
        : ADD_LAYER_CORRECTION;

    system !== System.Lights && setPointsValue('isShowPhaseCircle', false);

    centeredDeviceTimerId = setTimeout(() => {
      openClusterByFeatureId(id, system);
    }, duration);
  };

  getErrorsTl = (
    features: Feature<Point>[],
    featuresSelect: IFeaturesArr[]
  ) => {
    const { uiStore, clustersStore, pointsUdsStore, constructorStore } =
      this.rootStore;

    const { infoData, isAlarmAlert } = uiStore;
    const { isClusterOpen, clusters } = clustersStore;
    const { isPhaseCircle } = pointsUdsStore;
    const { isConstructor } = constructorStore;

    if (!clusters.length) return;

    const errorsFeatures = getErrorsObj(clusters);

    if (featuresSelect.length && isClusterOpen) {
      errorsFeatures.push(...featuresSelect);
    }

    const errorsAlarmsFeatures = deleteSelectedClusterAlarm(errorsFeatures);

    const isDeleteAlarm =
      !errorsAlarmsFeatures.length ||
      isConstructor ||
      !isAlarmAlert ||
      (isPhaseCircle && infoData) ||
      !features.length;

    if (isDeleteAlarm) {
      return (this.errorsTl = []);
    }

    const idArrayNew = getArrayIdsFeatures(errorsAlarmsFeatures);
    const idArrayOld = getArrayIdsFeatures(this.errorsTl);

    if (!isEqual(idArrayNew, idArrayOld)) {
      this.errorsTl = errorsAlarmsFeatures;
    }
  };

  get mapProxy() {
    const { map } = this.rootStore.mapStore;

    return map;
  }

  get mapViewProxy() {
    return this.rootStore.mapStore.map?.getView();
  }

  get viewProxyResolution() {
    return this.viewResolution;
  }

  get isCrossroadBorder() {
    return this.currentZoom >= CROSSROAD_BORDER;
  }

  get systemsInfo(): TSystemsInfo {
    const { uiStore } = this.rootStore;

    const { detectors, tls, cameras, meteo } = this;

    const {
      isLights,
      isDetectors,
      isCameras,
      isSpecialTransport,
      isPublicTransport,
      isMeteo,
    } = uiStore.markers;

    return {
      [System.Lights]: {
        title: System.Lights,
        data: tls,
        isMarkers: isLights,
        isHaveDetailed: true,
      },
      [System.Detectors]: {
        title: System.Detectors,
        data: detectors,
        isMarkers: isDetectors,
        isHaveDetailed: true,
      },
      [System.Cameras]: {
        title: System.Cameras,
        data: cameras,
        isMarkers: isCameras,
        isHaveDetailed: true,
      },
      [System.Meteo]: {
        title: System.Meteo,
        data: meteo,
        isMarkers: isMeteo,
        isHaveDetailed: false,
      },
      [System.SpecialTransport]: {
        title: System.SpecialTransport,
        data: [],
        isMarkers: isSpecialTransport,
        isHaveDetailed: false,
      },
      [System.PublicTransport]: {
        title: System.PublicTransport,
        data: [],
        isMarkers: isPublicTransport,
        isHaveDetailed: false,
      },
    };
  }

  get systems() {
    return Object.values(this.systemsInfo);
  }

  get systemsArray() {
    return this.systems.reduce((acc: TObjMap[], { data }) => {
      return [...acc, ...data];
    }, []);
  }

  get systemCoords() {
    return this.systemsArray.map(({ longitude, latitude, id }) => ({
      id,
      location: [longitude, latitude] as XY,
    }));
  }

  get events() {
    const { isRta, isRoadWorks, isActivities, isTrafficRestrictions } =
      this.rootStore.uiStore.markers;

    return [
      {
        title: RTA,
        data: [],
        isMarkers: isRta,
      },
      {
        title: ROAD_WORKS,
        data: [],
        isMarkers: isRoadWorks,
      },
      {
        title: ACTIVITIES,
        data: [],
        isMarkers: isActivities,
      },
      {
        title: TRAFFIC_RESTRICTIONS,
        data: [],
        isMarkers: isTrafficRestrictions,
      },
    ];
  }

  get isDevicesFetched() {
    return this.fetchedDevicesCounter === DEVICES_AMOUNT;
  }

  getCoordinatesById(id: number) {
    const activeItem = this.systemsArray.find((el) => el['id'] === id);

    if (activeItem?.longitude && activeItem?.latitude) {
      return fromLonLat([activeItem?.longitude, activeItem?.latitude]);
    }
  }

  getMapData = (systemTitle: string) => {
    switch (systemTitle) {
      case LIGHTS:
        return this.tls;
      case DETECTORS:
        return this.detectors;
      case CAMERAS:
        return this.cameras;
      case PUBLIC_TRANSPORT:
        return this.publicTransport;
      case SPECIAL_TRANSPORT:
        return this.specialTransport;
      case METEO:
        return this.meteo;
      case ROAD_WORKS:
        return this.roadWorks;
      case RTA:
        return this.rta;
      default:
        return [];
    }
  };

  getById = <K>(
    id: number,
    systemKey: Extract<K, MapDataArrays>
  ): U<this[typeof systemKey][number]> => {
    return findById(this[systemKey], id);
  };

  addItemWS<K, T>(type: Extract<K, MapDataArrays>, id: number, item: T) {
    const currentObject = this[type].find((el: IWSItem) => el.id === id);

    if (!currentObject) {
      this[type] = [...this[type], item] as this[typeof type];
    }
  }

  deleteItemWS<K>(type: Extract<K, MapDataArrays>, id: number) {
    const currentObject = this[type].find((el: IWSItem) => el.id === id);

    if (currentObject) {
      const { infoData, setInfoData } = this.rootStore.uiStore;
      const { closeCluster } = this.rootStore.clustersStore;

      if (infoData?.id === id) {
        setInfoData(null);
        closeCluster(id);
      }

      this[type] = this[type].filter((el: IWSItem) => el.id !== id);
    }
  }

  updateItemWS<K>(
    type: Extract<K, MapDataArrays>,
    id: number,
    path: string[],
    value: any
  ) {
    const { currentObject, curInd } = (this[type] as Array<IWSItem>).reduce(
      (acc: ICurObjWs, el: IWSItem, index) => {
        if (el.id === id) {
          return { currentObject: el, curInd: index };
        }

        return acc;
      },
      { currentObject: null, curInd: null }
    );

    if (!currentObject || curInd === null) return;

    if (path.at(-1) === 'mode') {
      const newTL = [...this[tls]];
      const propsId = id;
      const idx = newTL.findIndex(({ id }) => id === propsId);

      newTL[idx].tlStatus.mode = value;

      return (this[tls] = newTL);
    }

    setObjectProperty(currentObject, path, value);

    this[type][curInd] = cloneDeep(currentObject);

    const lonLatKey = path.filter(
      (item) => item === Longitude || item === Latitude
    )?.[0] as U<LonLat>;

    if (!lonLatKey || !path.includes('deviceInfo')) return;

    this[type][curInd][lonLatKey] = value;

    eventBus.updateFeatures.broadcast();
  }

  setIsCorrectCoordinate = (
    idx: number,
    type: ActualMapObj,
    value: boolean
  ) => {
    this[type][idx]['isCorrectCoordinate'] = value;
  };
}

export default MapDataStore;
