import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';

import { System } from '../../../../ts/enums/enums';

import DevicesBlock from './DevicesBlock/DevicesBlock';
import { ShowBtnsDevices } from './DisplayBtns/constants/constants';
import DisplayBtns from './DisplayBtns/DisplayBtns';
import FiltersPanel from './FiltersPanel/FiltersPanel';
import useSystemMonitoring from './hooks/useSystemMonitoring';

import styles from './SystemMonitoring.module.scss';

interface ISystemMonitoringProps {
  systemsList: System[];
}

const SystemMonitoring: FC<ISystemMonitoringProps> = ({ systemsList }) => {
  const [displayType, setDisplayType] = useState<ShowBtnsDevices>(
    ShowBtnsDevices.List
  );

  const { addressLoading, dataList } = useSystemMonitoring({ systemsList });

  return (
    <div className={styles.container}>
      <div className={styles.titleRow}>
        <h3> Список оборудования: </h3>
        <DisplayBtns
          displayType={displayType}
          setDisplayType={setDisplayType}
        />
      </div>
      <FiltersPanel />
      <DevicesBlock
        data={dataList}
        displayType={displayType}
        addressLoading={addressLoading}
      />
    </div>
  );
};

export default observer(SystemMonitoring);
