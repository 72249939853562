import { observer } from 'mobx-react-lite';

import rootStore from '../../../../../../../stores/rootStore/rootStore';
import WarningNotify from '../../../../../../TrafficLightDetailed/TlManagement/StatusCard/WarningNotify/WarningNotify';
import ControlPanel from '../ControlCard/ConrolPanel/ControlPanel';
import {
  getIsTLOnStartedGroup,
  getTLControlInfo,
} from '../ControlCard/helpers/helpers';

import ModalConfirm from './ModalConfirm/ModalConfirm';

import styles from './GroupControl.module.scss';

const TL_NOT_CONTROL_NOTIFY =
  'Групповое управление недоступно, так как все выбранные СО доступны только для просмотра';

const GroupControl = () => {
  const { selectedIds } = rootStore.scriptsControlStore;

  const isActiveCoordTL = selectedIds.some((id) => getIsTLOnStartedGroup(id));

  const isEveryTLNotControl = selectedIds.every(
    (id) => !getTLControlInfo(id)?.isAccessMode
  );

  return (
    <div className={styles.container}>
      <h4>Панель группового управления</h4>
      <ControlPanel
        tlIds={selectedIds}
        isActiveCoordTL={isActiveCoordTL}
        ModalComponent={ModalConfirm}
        isDisabledControl={isEveryTLNotControl}
      />
      <WarningNotify
        isActiveNotify={isEveryTLNotControl}
        warningTitle={TL_NOT_CONTROL_NOTIFY}
      />
    </div>
  );
};

export default observer(GroupControl);
