import { getObjectEntries } from '../../../../../../helpers/getObjectEntries';
import { CHART_NAMES, ChartBDKeys } from '../constants/constants';

const getChartParamName = (graphTitle: ChartBDKeys) => {
  const graphParam = getObjectEntries(CHART_NAMES).find(
    ([key, value]) => value === graphTitle
  );

  if (!graphParam) return null;

  const [paramKey] = graphParam;

  return paramKey;
};

export default getChartParamName;
