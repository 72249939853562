import { Tag } from 'antd';
import { FC } from 'react';
import { IoIosWarning } from 'react-icons/io';

import styles from './WarningNotify.module.scss';

const WARNING_TEXT = 'СО находится в запущенной группе КУ';

interface WarningNotifyProps {
  isActiveNotify: boolean;
  warningTitle?: string;
}

const WarningNotify: FC<WarningNotifyProps> = ({
  isActiveNotify,
  warningTitle = WARNING_TEXT,
}) => {
  if (!isActiveNotify) return null;

  return (
    <div className={styles.container}>
      <IoIosWarning />
      <Tag color="warning">{warningTitle}</Tag>
    </div>
  );
};

export default WarningNotify;
