import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import ChartClusterLayer from '../../ui-kit/MapLayers/ClusterLayers/ChartClusterLayer';
import { useDataFeatures, useFeatures } from '../hooks';

const ClusterLayers: FC = () => {
  const data = useDataFeatures();
  const features = useFeatures(data);

  if (!data) {
    return null;
  }

  return <ChartClusterLayer features={features} />;
};

export default observer(ClusterLayers);
