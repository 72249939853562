import { FC, useEffect } from 'react';

import { APP_VERSION } from '../../../../../constants/constants';
import { convertDate } from '../../../helpers/helpers';
import { convertDateToTime } from '../../helpers';
import { IServerData } from '../../NJSInfo.model';

interface IMainNJSInfo {
  data: IServerData;
  timer: number;
  setTimer: (
    func: (prev: N<number> | undefined) => N<number> | undefined
  ) => void;
}

const MainNJSInfo: FC<IMainNJSInfo> = ({ data, timer, setTimer }) => {
  useEffect(() => {
    const timerId = setInterval(() => {
      setTimer((prev) => (prev ? prev + 1000 : null));
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <h2>Имя инстанса сервера: {data.appName}</h2>
      <p>Версия сервера: {data.version}</p>
      <p>Версия WEB приложения: {APP_VERSION}</p>
      <p>
        Дата последней перезагрузки сервера: {convertDate(data.uptime / 1000)}
      </p>
      <p>
        Время работы сервера: {timer ? convertDateToTime(timer / 1000) : ''}
      </p>
      <p>ID сервера: {data.serverSystemId}</p>
      <p>WEB ID: {data.webSystemId}</p>
    </div>
  );
};

export default MainNJSInfo;
