import { toLonLat } from 'ol/proj';

import checkIsTLValid from '../../../../helpers/checkIsTLValid';
import { checkDisabledControl } from '../../../../stores/mapDataStore/helpers/checkDisabledControl';
import {
  ISystemsInfo,
  TObjMap,
} from '../../../../stores/mapDataStore/mapDataStore.model';
import rootStore from '../../../../stores/rootStore/rootStore';
import { System } from '../../../../ts/enums/enums';
import { getIsCoordinatesOnExtent } from '../../../Map/helpers/getIsCoordinatesOnExtent';

import { FilterArgs } from './AllSystemsTab.model';
import { DisabledTypes } from './constants/constants';

export const isSearchedDevice = (device: TObjMap, value: N<string>) => {
  if (!value) return true;

  let isIncludes = false;
  const curValue = value.trim();

  if (
    device.id?.toString().trim().toLowerCase().includes(curValue.toLowerCase())
  )
    isIncludes = true;

  if (
    device.caption
      ?.toString()
      .trim()
      .toLowerCase()
      .includes(curValue.toLowerCase())
  )
    isIncludes = true;

  if (
    device.deviceInfo.externalDeviceId
      ?.toString()
      .trim()
      .toLowerCase()
      .includes(curValue.toLowerCase())
  )
    isIncludes = true;

  return isIncludes;
};

export const checkMatches = (elements: TObjMap[], value: string) => {
  return elements.filter((item) => isSearchedDevice(item, value));
};

const getSearchedSystems = ({
  searchedArea,
  systems,
  systemsInfo,
}: Omit<FilterArgs, 'searchedValue' | 'isDetailedForm'>) => {
  if (!searchedArea.length) return systems;

  return searchedArea.reduce((acc, item) => {
    acc.push(systemsInfo[item as System]);

    return acc;
  }, [] as ISystemsInfo[]);
};

interface OptionalTypeProps {
  isDetailedForm?: boolean;
  isGroupDispatch?: boolean;
}

export const getTypeDisabledDevice = (
  device: TObjMap,
  system: System,
  { isDetailedForm = false, isGroupDispatch = false }: OptionalTypeProps = {}
) => {
  const { getCoordinatesById } = rootStore.mapDataStore;

  const coordinates = getCoordinatesById(device.id) ?? [];
  const isIncorrectControlMode = checkDisabledControl(
    device.deviceInfo.deviceStatusId
  );

  if (!coordinates || !getIsCoordinatesOnExtent(toLonLat(coordinates)))
    return DisabledTypes.UnknownCoordinates;

  if (isGroupDispatch && isIncorrectControlMode)
    return DisabledTypes.IncorrectControlStatus;

  if (isDetailedForm && !checkIsTLValid(device, system))
    return DisabledTypes.NoDataDetailedTL;

  return null;
};

export const getFilteredSystems = ({
  searchedArea,
  systems,
  systemsInfo,
  searchedValue,
  isDetailedForm,
}: FilterArgs) => {
  const filteredSystems = getSearchedSystems({
    searchedArea,
    systems,
    systemsInfo,
  });

  return filteredSystems.map((item) => {
    const newItem = {
      ...item,
      data: checkMatches(item.data, searchedValue).map((el) => {
        const disabledType = getTypeDisabledDevice(el, item.title, {
          isDetailedForm,
        });

        return {
          ...el,
          disabledType,
        };
      }),
    };

    newItem.data.sort((a, b) => {
      return a.id - b.id;
    });

    return newItem;
  });
};
