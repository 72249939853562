import { TIME_FORMAT } from '../../../../../../../constants/constants';

const formattedDate = (format: string) => {
  const [date, time] = format.split(',');

  if (!time) return date;

  return `${date}, ${TIME_FORMAT}`;
};

export default formattedDate;
