import { IS_DEV } from '../../../../constants/constants';
import rootStore from '../../../../stores/rootStore/rootStore';
import { Path } from '../../../api.model';
import { getAppPathHeaders } from '../../../helpers/getAppPathHeaders';
import http from '../../../http';
import tryCatchWrapper from '../../../tryCatchWrapper';

import { GeocodeSchema } from './geocode.zod';
import { Geocode, Location } from './model/geocode.model';

const GEOCODE = 'api/geocode/reverse';

interface GeocodeProps {
  locations: Location[];
  regionId: number;
  appPath: Path;
}

export const geocodeApi = {
  geocode: ({ locations, regionId, appPath }: GeocodeProps) =>
    tryCatchWrapper<Geocode[]>(
      async () => {
        const { urlConfigurations } = rootStore.userDataStore;

        if (!urlConfigurations)
          return console.error('Ошибка получения urlConfigurations');

        const { gisApplicationServer } = urlConfigurations;

        const res = await http.post(
          `${gisApplicationServer}/${GEOCODE}`,
          {
            locations,
            options: {
              format: 'json',
              configuration: `EPUTS_R${regionId}`,
            },
          },
          {
            headers: getAppPathHeaders(appPath),
          }
        );

        const validate = GeocodeSchema.array().safeParse(res.data);

        if (!validate.success) {
          IS_DEV &&
            console.error('Ошибка валидации геокодирования', validate.error);

          return [];
        }

        return res.data;
      },
      {
        isBlockedNotify: true,
      }
    ),
};

export default geocodeApi;
