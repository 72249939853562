import { isEqual } from 'lodash';
import { FC } from 'react';
import { TbZoomReset } from 'react-icons/tb';

import ButtonOnlyIcon from '../../../ButtonOnlyIcon/ButtonOnlyIcon';
import { DISABLED_BTN_TEXT } from '../../CandleChart/constants/constants';
import { INITIAL_X_DOMAIN } from '../../constants/constants';
import { IButtonsPanelProps } from '../ButtonsPanel';

type TZoomOutButtonProps = Pick<
  IButtonsPanelProps,
  'domain' | 'handleZoomOut'
> & {
  isShow?: boolean;
};

const ZoomOutButton: FC<TZoomOutButtonProps> = ({
  domain,
  handleZoomOut,
  isShow = true,
}) => {
  const isDisabled = isEqual(domain.x, INITIAL_X_DOMAIN);

  const tip = isShow ? 'К начальному масштабу' : DISABLED_BTN_TEXT;

  return (
    <ButtonOnlyIcon
      isDisabled={isDisabled || !isShow}
      onClick={handleZoomOut}
      tip={tip}
      isSmall
    >
      <TbZoomReset />
    </ButtonOnlyIcon>
  );
};

export default ZoomOutButton;
