import { uniqueId } from 'lodash';
import { observer } from 'mobx-react-lite';
import { Feature } from 'ol';
import { FC } from 'react';

import rootStore from '../../../../stores/rootStore/rootStore';
import { System } from '../../../../ts/enums/enums';
import { getAvatarIcon } from '../../../DetailedStatistics/Monitoring/SystemMonitoring/helpers/helpers';
import { FeaturesMode } from '../../../ui-kit/MapLayers/ClusterLayers/helpers/colors';

import { getClusterDevicesInfo } from './helpers/getClusterDevicesInfo';
import { getClusterStatusDevices } from './helpers/getClusterStatusDevices';

import styles from './ClustersContent.module.scss';

interface IClustersContentProps {
  clustersFeatures: Feature[];
}

const isSystem = (name: string): name is System =>
  Object.values(System).some((system) => system === name);

const ClustersContent: FC<IClustersContentProps> = ({ clustersFeatures }) => {
  const { featureMode } = rootStore.clustersStore;

  const clusters =
    featureMode !== FeaturesMode.Disabled
      ? getClusterDevicesInfo(clustersFeatures)
      : getClusterStatusDevices(clustersFeatures, featureMode);

  return (
    <>
      <div className={styles.container}>
        {clusters.map((item) => (
          <div className={styles.clustersRow} key={uniqueId()}>
            {isSystem(item.name) && (
              <span className={styles.avatar}>{getAvatarIcon(item.name)}</span>
            )}
            <p>
              {item.name}: {item.length}
            </p>
          </div>
        ))}
      </div>
    </>
  );
};

export default observer(ClustersContent);
