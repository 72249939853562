import eventBus from '../../../../eventBus';
import {
  IAdminTableConfig,
  ITableConfigType,
} from '../../../../ts/models/table.model';

const getPaginationOptions = (defaultPageSize: number, max: number) => {
  const pageSizeOptions = [];

  for (let i = 1; i <= 3; i++) {
    const size = defaultPageSize * i;

    if (size >= max) {
      pageSizeOptions.push(max);
      break;
    }

    pageSizeOptions.push(size);
  }
  const lastIndex = pageSizeOptions.length - 1;

  if (pageSizeOptions[lastIndex] !== max) pageSizeOptions.push(max);

  return pageSizeOptions;
};

export const getTableConfig = (
  type: ITableConfigType,
  max: number,
  minPageSize: number,
  pageSize: number
) => {
  const isPagination = max >= minPageSize;

  const config: IAdminTableConfig = {
    bordered: false,
    pagination: isPagination
      ? {
          position: ['bottomRight'],
          defaultPageSize: minPageSize,
          pageSizeOptions: getPaginationOptions(minPageSize, max),
          hideOnSinglePage: true,
          showSizeChanger: true,
          //@ts-ignore
          onShowSizeChange: eventBus.tablePaginationChange.broadcast,
          pageSize,
        }
      : false,
    size: 'small',
  };

  return config;
};
