import { SECOND } from '../../../../../constants/constants';
import { PREDICT_PERIOD_VALUES } from '../../../../TrafficLightDetailed/TlDetectors/constants/constants';
import { isCandleChartPeriod } from '../../../../TrafficLightDetailed/TlDetectors/model/graph.model';
import { DEFAULT_RANGE } from '../constants/constants';

const getPredictKeyToSec = (key: string) => {
  if (!isCandleChartPeriod(key)) return DEFAULT_RANGE;

  return PREDICT_PERIOD_VALUES[key] / SECOND;
};

export default getPredictKeyToSec;
