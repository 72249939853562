import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { ReactComponent as CameraIco } from '../../../../assets/icons/map/ic_camera.svg';
import { COLORS } from '../../../../constants/colorsConstants';
import rootStore from '../../../../stores/rootStore/rootStore';
import { System } from '../../../../ts/enums/enums';
import DeviceIcon from '../../DeviceIcon/DeviceIcon';
import DeviceId from '../../DeviceIcon/DeviceId/DeviceId';
import getDeviceIcoFill from '../../helpers/getDeviceIcoFill';
import getIcoColor from '../../helpers/getIcoColor';
import getIconSize from '../../helpers/getIconSize';
import { CameraIconProps } from '../../models/camera.model';
import { DeviceIconProps } from '../../models/device.model';

import styles from './CameraIcon.module.scss';

const CameraIcon: FC<CameraIconProps> = ({
  camera,
  onClick,
  rate,
  isActive = true,
  isId = false,
}) => {
  const { cameras } = rootStore.mapDataStore;
  const { infoData } = rootStore.uiStore;

  const { linkColor, linkedDetectorId, id } = camera;
  const size = getIconSize(camera.size, rate);
  const sizeProps = { width: size, height: size };
  const isCmActive = isActive && id === infoData?.id;

  const color = linkedDetectorId
    ? getIcoColor(linkColor)
    : getDeviceIcoFill(id, cameras);

  const frameStyle = classNames({
    [styles.icoCursor]: !!onClick,
    [styles.unActive]: !isActive,
  });

  const deviceIconProps: DeviceIconProps = {
    device: camera,
    size,
    rate,
    onClick,
    className: frameStyle,
    fill: isCmActive ? COLORS.PRIMARY : color,
    id,
    system: System.Cameras,
  };

  return (
    <DeviceIcon {...deviceIconProps}>
      {isId ? (
        <DeviceId id={id ?? 0} color={color} size={size} />
      ) : (
        <CameraIco {...sizeProps} className={styles.ico} />
      )}
    </DeviceIcon>
  );
};

export default observer(CameraIcon);
