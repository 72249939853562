import { notification } from 'antd';

import { INewUser } from '../../components/Admin/models/adminTypes.model';
import { VERSION } from '../../constants/versions';
import { IUserData } from '../../stores/userDataStore/userDataStore';
import { TableData } from '../../ts/models/table.model';
import { getAppPathHeaders } from '../helpers/getAppPathHeaders';
import http from '../http';
import tryCatchWrapper from '../tryCatchWrapper';

import { auth } from './auth/auth';
import { settings } from './settings/settings';

const USER = VERSION + '/user';
const INFO = USER + '/info';
const USER_ACCESS = USER + '/access';

export const user = {
  auth,
  settings,
  infoById: (userId: number, appPath: string) =>
    tryCatchWrapper<IUserData>(
      async () => {
        const res = await http.get(`${INFO}/${userId}`, {
          headers: getAppPathHeaders(appPath),
        });

        return res?.data?.user;
      },
      {
        isBlockedNotify: true,
      }
    ),
  infoBySelf: async (appPath: string) => {
    const res = await http.get(INFO, {
      headers: getAppPathHeaders(appPath),
    });

    return res;
  },
  create: (user: INewUser, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.post(`${USER}/registration`, user, {
        headers: getAppPathHeaders(appPath),
      });

      return res;
    }),
  update: async (id: number, user: any, appPath: string) =>
    tryCatchWrapper(
      async () =>
        await http.put(`${USER}/update/${id}`, user, {
          headers: getAppPathHeaders(appPath),
        })
    ),
  delete: async (id: number, appPath: string) => {
    try {
      const res = (await http.delete(`${USER}/delete/${id}`, {
        headers: getAppPathHeaders(appPath),
      })) as any;

      return res;
    } catch (err: any) {
      notification.error({
        message: 'Ошибка',
        description: `${err?.response?.data?.errors[0]?.msg}`,
      });

      throw new Error(err?.response?.data?.errors[0]?.msg);
    }
  },
  fetchAccess: async (id: number | string, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.get(`${USER_ACCESS}/${id}`, {
        headers: getAppPathHeaders(appPath),
      });

      const data: TableData = res.data;

      return data;
    }),
};
