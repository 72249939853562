import { useMemo } from 'react';

import useTLSec from '../../../../../../../../../Panel/ScriptsControl/SelectModule/DispatchGroupControl/DipatchGroupDetailed/ControlCard/TabsDetailed/hooks/useTLSec';
import { getPhases } from '../../../../../../../../../SignalProgramsBand/helpers/getPhases';
import useCurrentProgram from '../../../../../../../../../TrafficLightDetailed/TlManagement/PhaseTimeChangeCurrentPlanMode/hooks/useCurrentProgram';

const usePhaseInfo = (id: number) => {
  const { sgnProgramValue } = useCurrentProgram(id);
  const { sec, activeTL } = useTLSec(id);

  const { curPhase, secToEndPhase } = useMemo(() => {
    const phase = getPhases(sgnProgramValue?.phasesSet ?? []).find(
      ({ stop, start }) => sec <= stop && sec >= start
    );

    const secToEndPhase = phase ? phase.stop - sec : 0;

    return { curPhase: phase, secToEndPhase };
  }, [sec, sgnProgramValue?.phasesSet]);

  return { sec, curPhase, activeTL, secToEndPhase };
};

export default usePhaseInfo;
