import eventBus from '../../../eventBus';
import rootStore from '../../../stores/rootStore/rootStore';
import { MapObjectFull } from '../../../ts/enums/enums';

export enum UpdateEntityEvents {
  ScheduledTlsCommands = 'scheduledTlsCommands',
}

export interface IUpdateEntityData {
  key: string;
  objectId: number;
  objectType: string;
}

export const updatePhaseHold = (data: IUpdateEntityData) => {
  const { infoData } = rootStore.uiStore;
  const { key, objectId, objectType } = data;

  if (key === UpdateEntityEvents.ScheduledTlsCommands) {
    objectType === MapObjectFull.trafficLights &&
      infoData?.id === objectId &&
      eventBus.phaseHoldChange.broadcast();

    return;
  }

  return console.error('WS WARN: Незарегистрированная сущность для обновления');
};
