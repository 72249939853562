import { useEffect, useState } from 'react';

import { COLORS } from '../../../constants/colorsConstants';
import { INITIAL_FORMATTED_DATA } from '../constants/constants';
import {
  FormattedData,
  useFormattedDataArgs,
} from '../PhaseCircleOverlay/PhaseCircleOverlay.model';

const useFormattedData = ({ tlTacts }: useFormattedDataArgs) => {
  const [formattedData, setFormattedData] = useState<FormattedData>([
    {
      ...INITIAL_FORMATTED_DATA,
      color: tlTacts[0].color ?? COLORS.SUCCESS,
    },
  ]);

  useEffect(() => {
    let sum = 0;

    const newFormattedData = tlTacts.map(({ color, time, animation }, i) => {
      sum += time;

      return { color, stop: sum, phase: i, animation };
    });

    setFormattedData(newFormattedData);
  }, [tlTacts]);

  return formattedData;
};

export default useFormattedData;
