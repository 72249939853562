/* eslint-disable camelcase */
import { Card, Table } from 'antd';
import React, { useState, useEffect, useCallback, useRef } from 'react';

import { ReactComponent as MagistralIco } from '../../../assets/icons/map_road.svg';
import { findById } from '../../../helpers/findBy';
import rootStore from '../../../stores/rootStore/rootStore';
import { DetailedItemProps } from '../../DetailedMain/DetailedWrapper/constants/constants';
import PreLoader from '../../ui-kit/PreLoader/PreLoader';

import { columnsChDir } from './helpers/columnsChDir';
import { columnsPhDir } from './helpers/columnsPhDir';
import { columnsSigProg } from './helpers/columnsSigProg';
import { getChDirSource } from './helpers/getChDirSource';
import { getDK } from './helpers/getDK';
import { getPhDirSource } from './helpers/getPhDirSource';
import { getSigProgSource } from './helpers/getSigProgSource';

import styles from './TlObjectPassport.module.scss';

const TlObjectPassport: React.FC<DetailedItemProps> = (props) => {
  const { id } = props;
  const [loading, setLoading] = useState(true);

  const [phDirSet, setPhDirSet] = useState([]);
  const [chDirSet, setChDirSet] = useState([]);
  const [sigProgSet, setSigProgSet] = useState([]);

  const dk = useRef<any>({});
  const phDir = useRef([]);
  const chDir = useRef([]);
  const sigProg = useRef([]);

  const getTLPassportInfo = useCallback(() => {
    const { tls } = rootStore.mapDataStore;

    const activeTrafficLight = findById(tls, id);

    dk.current = getDK(activeTrafficLight);

    // ** JIRA TASK [VMNE-133]
    // ** for phase and directions <Table> "Направления и каналы"
    // ** needs array [phase_dir] in store
    // phDir.current = activeTrafficLight?.cycleData?.phaseDir;

    // ** demo-template-array-[phase_dir]-id-4097:
    phDir.current = [
      { min: 5, max: 60, dir: 'YYNNNYYYNY', ph_num: 1 },
      { min: 15, max: 60, dir: 'NNYYYNYYNY', ph_num: 2 },
      { min: 5, max: 60, dir: 'NYNYYYNNYN', ph_num: 3 },
    ] as any;

    setPhDirSet(getPhDirSource(phDir.current));

    // ** JIRA TASK [VMNE-133]
    // ** for channels in directions <Table> "Каналы в направлениях"
    // ** needs array [directions] in store
    // chDir.current = activeTrafficLight?.cycleData?.directions;

    // ** demo-template-array-[directions]-id-4097:
    chDir.current = [
      {
        dir_num: 1,
        red: 'YNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN',
        end: 'NYNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN',
        green: 'NNYNNNNNNNNNNNNNNNNNNNNNNNNNNNNN',
        begin: 'YYNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN',
        add_gr: 0,
        add_gg: 0,
        end_len: 3,
        begin_len: 1,
        flash_count: 3,
      },
      {
        dir_num: 2,
        red: 'NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN',
        end: 'NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN',
        green: 'NNNYNNNNNNNNNNNNNNNNNNNNNNNNNNNN',
        begin: 'NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN',
        add_gr: 0,
        add_gg: 0,
        end_len: 0,
        begin_len: 0,
        flash_count: 3,
      },
      {
        dir_num: 3,
        red: 'NNNNYNNNNNNNNNNNNNNNNNNNNNNNNNNN',
        end: 'NNNNYNNNNNNNNNNNNNNNNNNNNNNNNNNN',
        green: 'NNNNNYNNNNNNNNNNNNNNNNNNNNNNNNNN',
        begin: 'NNNNYNNNNNNNNNNNNNNNNNNNNNNNNNNN',
        add_gr: 0,
        add_gg: 0,
        end_len: 0,
        begin_len: 0,
        flash_count: 3,
      },
      {
        dir_num: 4,
        red: 'NNNNNNYNNNNNNNNNNNNNNNNNNNNNNNNN',
        end: 'NNNNNNNYNNNNNNNNNNNNNNNNNNNNNNNN',
        green: 'NNNNNNNNYNNNNNNNNNNNNNNNNNNNNNNN',
        begin: 'NNNNNNYYNNNNNNNNNNNNNNNNNNNNNNNN',
        add_gr: 0,
        add_gg: 0,
        end_len: 3,
        begin_len: 1,
        flash_count: 3,
      },
      {
        dir_num: 5,
        red: 'NNNNNNNNNYNNNNNNNNNNNNNNNNNNNNNN',
        end: 'NNNNNNNNNNYNNNNNNNNNNNNNNNNNNNNN',
        green: 'NNNNNNNNNNNYNNNNNNNNNNNNNNNNNNNN',
        begin: 'NNNNNNNNNYYNNNNNNNNNNNNNNNNNNNNN',
        add_gr: 0,
        add_gg: 0,
        end_len: 3,
        begin_len: 1,
        flash_count: 3,
      },
      {
        dir_num: 6,
        red: 'NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN',
        end: 'NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN',
        green: 'NNNNNNNNNNNNYNNNNNNNNNNNNNNNNNNN',
        begin: 'NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN',
        add_gr: 0,
        add_gg: 0,
        end_len: 3,
        begin_len: 1,
        flash_count: 3,
      },
      {
        dir_num: 7,
        red: 'NNNNNNNNNNNNNYNNNNNNNNNNNNNNNNNN',
        end: 'NNNNNNNNNNNNNNYNNNNNNNNNNNNNNNNN',
        green: 'NNNNNNNNNNNNNNNYNNNNNNNNNNNNNNNN',
        begin: 'NNNNNNNNNNNNNYYNNNNNNNNNNNNNNNNN',
        add_gr: 0,
        add_gg: 0,
        end_len: 3,
        begin_len: 1,
        flash_count: 3,
      },
      {
        dir_num: 8,
        red: 'NNNNNNNNNNNNNNNNYNNNNNNNNNNNNNNN',
        end: 'NNNNNNNNNNNNNNNNNYNNNNNNNNNNNNNN',
        green: 'NNNNNNNNNNNNNNNNNNYNNNNNNNNNNNNN',
        begin: 'NNNNNNNNNNNNNNNNYYNNNNNNNNNNNNNN',
        add_gr: 0,
        add_gg: 0,
        end_len: 3,
        begin_len: 1,
        flash_count: 3,
      },
      {
        dir_num: 9,
        red: 'NNNNNNNNNNNNNNNNNNNYNNNNNNNNNNNN',
        end: 'NNNNNNNNNNNNNNNNNNNNYNNNNNNNNNNN',
        green: 'NNNNNNNNNNNNNNNNNNNNNYNNNNNNNNNN',
        begin: 'NNNNNNNNNNNNNNNNNNNYYNNNNNNNNNNN',
        add_gr: 0,
        add_gg: 0,
        end_len: 3,
        begin_len: 1,
        flash_count: 3,
      },
      {
        dir_num: 10,
        red: 'NNNNNNNNNNNNNNNNNNNNNNYYYNNNNNNN',
        end: 'NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN',
        green: 'NNNNNNNNNNNNNNNNNNNNNNNYNYNNNNNN',
        begin: 'NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN',
        add_gr: 0,
        add_gg: 0,
        end_len: 0,
        begin_len: 0,
        flash_count: 0,
      },
    ] as any;

    setChDirSet(getChDirSource(chDir.current));

    // ** JIRA TASK [VMNE-133]
    // ** for channels in directions <Table> "Сигнальная программа №..."
    // ** needs array [csd.ast] in store
    // chDir.current = activeTrafficLight?.cycleData?.ast;

    // ** demo-template-array-[csd.ast]-id-4097:
    sigProg.current = [
      {
        num: 1,
        len: 108,
        len_pt: [8, 8, 8],
        len_ph: [32, 20, 32],
        ph: [1, 2, 3],
        tacts: 3,
      },
      {
        num: 2,
        len: 108,
        len_pt: [8, 8, 8],
        len_ph: [32, 20, 32],
        ph: [1, 3, 2],
        tacts: 3,
      },
      {
        num: 3,
        len: 108,
        len_pt: [8, 8, 8],
        len_ph: [32, 20, 32],
        ph: [3, 1, 2],
        tacts: 3,
      },
    ] as any;

    setSigProgSet(
      // @ts-ignore
      getSigProgSource(sigProg.current, activeTrafficLight?.cycleData?.last)
    );

    // const currentCmd = DECODE_CMD_AST.find(
    //   (cmdObj) => cmdObj?.cmd === activeTrafficLight?.cycleData?.cmd_ast
    // ) || { full: 'программа не определена' };

    // setCmdInfo(currentCmd);

    setLoading(false);
  }, [id]);

  useEffect(() => {
    getTLPassportInfo();
  }, [getTLPassportInfo]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.containerSub}>
          <Card className={styles.passInfo}>
            <h3>Информация</h3>
            <PreLoader loading={loading} loadersNum={1}>
              <div className={styles.infcontent}>
                <div>
                  <MagistralIco />
                  <h4>Дорожный контроллер</h4>
                  <p id={'inf_num_dk'}>
                    {dk.current['short'] || 'контроллер не определен'}
                  </p>
                </div>
              </div>
            </PreLoader>
          </Card>
          <div className={styles.passGraphNProg}>
            <div className={styles.passGraphNProgSubWrap}>
              <Card className={styles.passDailyLogs}>
                <h3>Суточные графики</h3>
                <PreLoader loading={loading} loadersNum={1} />
              </Card>
              <Card className={styles.passSgnProg}>
                <PreLoader loading={loading} loadersNum={1}>
                  {sigProgSet?.length && (
                    <Table
                      pagination={false}
                      dataSource={sigProgSet} // ** template data <<Сигнальная программа №...>>
                      columns={columnsSigProg}
                    />
                  )}
                </PreLoader>
              </Card>
            </div>
          </div>
        </div>
        <div className={styles.containerSub}>
          <Card className={styles.passDirNChnls}>
            <h3>Направления и каналы</h3>
            <PreLoader loading={loading} loadersNum={1}>
              {phDirSet.length && (
                <Table
                  pagination={false}
                  dataSource={phDirSet} // ** template data <<Направления и каналы>>
                  columns={columnsPhDir}
                />
              )}
            </PreLoader>
          </Card>
          <Card className={styles.passChInDrcs}>
            <h3>Каналы в направлениях</h3>
            <PreLoader loading={loading} loadersNum={1}>
              {chDirSet.length && (
                <Table
                  pagination={false}
                  dataSource={chDirSet} // ** template data <<Каналы в направлениях>>
                  columns={columnsChDir}
                />
              )}
            </PreLoader>
          </Card>
        </div>
      </div>
    </>
  );
};

export default TlObjectPassport;
