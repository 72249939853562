import { ReactNode } from 'react';

import rootStore from '../../../../../../stores/rootStore/rootStore';
import { ChartKeys } from '../../../../../InfoPanel/SystemsInfo/PopupDetector/Chart/constants/constants';
import { GraphTypes, IValuesDatesArr } from '../../../constants/constants';

export interface TDtInfo {
  caption: string | JSX.Element;
  valueDt: string;
  dsc: string;
  isDisabled?: boolean;
  specialWarning?: ReactNode;
}

interface IGetTitleDtProps {
  captions: (string | JSX.Element)[];
  valuesArr: [string, IValuesDatesArr][];
}

export const getTitleDetectors = ({
  captions,
  valuesArr,
}: IGetTitleDtProps): TDtInfo[] => {
  const { graphType } = rootStore.detailedStore;

  return captions.map((el) => {
    const titleArr =
      valuesArr.find(
        ([key, value]) => value.min === el || value.value === el
      ) ?? valuesArr[0];

    const [valueDt, info] = titleArr;
    const isDisabled =
      graphType === GraphTypes.CandlesChart && valueDt === ChartKeys.Density;

    const dsc = !isDisabled ? info.value : 'Временно недоступен';

    return { caption: el, valueDt, dsc, isDisabled };
  });
};
