import { Form, InputNumber } from 'antd';
import { Rule } from 'antd/lib/form';
import React, { useEffect, useState } from 'react';

import { IPhasesData } from '../../../PhaseHold/PhaseHold.model';
import { TitleTable } from '../constants/constants';

import { DURATION_CHANGE, rulesTableEdit } from './constants/constants';
import { onChangeTablePhases } from './helpers/onChangeTablePhases';
import { recordDataOnTable } from './helpers/recordDataOnTable';

import styles from './EditableCell.module.scss';

const { SUCCESS, ERROR } = DURATION_CHANGE;

type dataIndexColumnTemporary = keyof typeof TitleTable;

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: dataIndexColumnTemporary;
  record: any;
  setIsValidateTable: SetState<boolean>;
  isPhasesTable: boolean;
  setDataTable: SetState<N<IPhasesData[]>>;
  durationMinArray: number[];
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  setIsValidateTable,
  isPhasesTable,
  setDataTable,
  durationMinArray,
  ...restProps
}) => {
  const [newRows, setNewRows] = useState<IPhasesData>();
  const [errorValidate, setErrorValidate] = useState<boolean>(false);

  useEffect(() => {
    if (!newRows) return;

    const durationUpdateTable = !errorValidate ? SUCCESS : ERROR;

    const idTimeout = recordDataOnTable(
      setDataTable,
      newRows,
      durationUpdateTable,
      setIsValidateTable,
      durationMinArray
    );

    return () => clearTimeout(idTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newRows, errorValidate, durationMinArray]);

  const onChange = (value: N<number>) => {
    const newRowsValue = { ...record, [dataIndex]: value };

    setNewRows(newRowsValue);
  };

  const checkValues = (value: N<number>) => {
    if (!newRows) return;

    const { isError, resultValidate } = onChangeTablePhases(
      value,
      newRows,
      isPhasesTable,
      dataIndex,
      durationMinArray
    );

    setErrorValidate(isError);

    return resultValidate;
  };

  if (!editable) {
    return (
      <td {...restProps}>
        <div className={styles.noEditable}>{children}</div>
      </td>
    );
  }

  return (
    <td {...restProps}>
      <Form.Item
        className={styles.inputNumbers}
        name={dataIndex}
        rules={
          [
            ...rulesTableEdit,
            { validator: (_: any, value: N<number>) => checkValues(value) },
          ] as Rule[]
        }
        initialValue={record[dataIndex]}
      >
        <InputNumber
          variant="borderless"
          controls={false}
          onChange={onChange}
          maxLength={5}
        />
      </Form.Item>
    </td>
  );
};

export default EditableCell;
