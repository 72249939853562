import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';

import styles from './CloseBtn.module.scss';

interface CloseBtnProps {
  onClick: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  color?: string;
  bgColor?: string;
  size?: SN;
  crossWeight?: SN;
  className?: string;
}

const CloseBtn: FC<CloseBtnProps> = ({
  onClick,
  color = 'var(--font-color)',
  bgColor = 'var(--bg)',
  size = 40,
  crossWeight = 1.5,
  className,
}) => {
  const { isDark } = rootStore.uiStore;

  const svgStyle = classNames([styles.svg], {
    [styles.light]: !isDark,
    [styles.dark]: isDark,
  });

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      onClick={onClick}
      className={`${svgStyle} ${className}`}
    >
      <rect width={size} height={size} fill={bgColor} />
      <path d="M25 15L15 25" stroke={color} strokeWidth={crossWeight} />
      <path d="M15 15L25 25" stroke={color} strokeWidth={crossWeight} />
    </svg>
  );
};

export default observer(CloseBtn);
