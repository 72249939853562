import { FormInstance } from 'antd/lib';

const getInitValues = <T>(initData: T, form: FormInstance) => {
  const formKeys = Object.keys(form.getFieldsValue()) as (keyof T)[];

  return formKeys.reduce((acc, key) => {
    acc[key] = initData[key];

    return acc;
  }, {} as T);
};

export default getInitValues;
