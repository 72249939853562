import { useCallback, useEffect, useMemo, useState } from 'react';

import previewApi from '../../../api/mapObjects/detectors/preview/preview';
import { findById } from '../../../helpers/findBy';
import {
  DETECTOR_IMG_PATH,
  PREVIEW_ERROR_DSC,
} from '../../Constructor/DetailedConstructor/constants/constants';
import { getCorrectScene } from '../helpers/sceneHelpers/sceneHelpers';
import { ReturnProps, UseDetectorsPreviewProps } from '../models/DG.model';

const useDetectorsPreview = <T extends U<true> = undefined>({
  detectors,
  id,
  isScene,
}: UseDetectorsPreviewProps<T>): ReturnProps<T> => {
  const [src, setSrc] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);

  const scene = useMemo(() => {
    if (!id) {
      setSrc('');
      setIsLoading(false);

      return;
    }
    const detector = findById(detectors, id);

    const extOptions = detector?.dai_jdt80?.source_info?.ext_options;

    const jsettings =
      extOptions?.camera_jsetting2?.dt_ext_jsetting ??
      extOptions?.camera_jsetting;

    const sceneRes = jsettings?.scene;

    return getCorrectScene(sceneRes);
  }, [detectors, id]);

  const getSrc = useCallback(async (id: number) => {
    const res = await previewApi.getPreviewById(
      DETECTOR_IMG_PATH,
      id,
      PREVIEW_ERROR_DSC
    );

    setSrc(res ? URL.createObjectURL(res) : '');
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (!id || isScene) return;

    setIsLoading(true);
    getSrc(id);
  }, [getSrc, id, isScene]);

  if (isScene) return { scene } as ReturnProps<T>;

  return {
    src,
    isLoading,
    scene,
  } as ReturnProps<T>;
};

export default useDetectorsPreview;
