import { Card } from 'antd';
import { uniqueId } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef } from 'react';

import rootStore from '../../../../stores/rootStore/rootStore';
import {
  ICsdData,
  IProcessedDataInitial,
  ITimePoints,
} from '../../../../ts/models/signalPrograms.model';

import SgnProgramWrapper, {
  SgnProgramWrapperProps,
} from './SgnProgramWrapper/SgnProgramWrapper';

import styles from './SignalProgramCard.module.scss';

interface ISignalProgramCard {
  programs: IProcessedDataInitial[];
  csdData: ICsdData[];
  phasesSet: ITimePoints[][];
  isError: boolean[];
  id: number;
}

const ERROR_TEXT = 'Недостаточно данных для отрисовки программы';

const SignalProgramCard = ({
  programs,
  csdData,
  phasesSet,
  isError,
  id,
}: ISignalProgramCard) => {
  const { selectPlanNumber } = rootStore.detailedStore;
  const blocksRefsArray = useRef<HTMLDivElement[]>([]);

  const addToRefs = useCallback((el: N<HTMLDivElement>, index: number) => {
    if (!el || blocksRefsArray.current.includes(el)) return;
    blocksRefsArray.current.splice(index, 0, el);
  }, []);

  const executeScroll = (index: N<number>) => {
    if (!index) return;

    const activeElement = blocksRefsArray.current.find(
      ({ id }) => id === index.toString()
    );

    const timeoutId = setTimeout(() => {
      activeElement?.scrollIntoView({ behavior: 'smooth' });
    });

    return () => {
      clearTimeout(timeoutId);
    };
  };

  useEffect(() => {
    executeScroll(selectPlanNumber ?? null);
  }, [selectPlanNumber]);

  if (!programs.length) {
    <Card className={styles.wrapper}>
      <h3>Нет данных</h3>
    </Card>;
  }

  return (
    <>
      {programs.map((program, index) => {
        if (program.tlSignalProgram2 && program.tlTacts && !isError[index]) {
          const title = program.execTime;

          const sgnProps: SgnProgramWrapperProps = {
            csdData: csdData[index],
            id,
            phaseGroupNum: program.phaseGroupNum,
            phasesSet: phasesSet[index],
            title,
            addToRefs,
          };

          return (
            <SgnProgramWrapper
              key={program.phaseGroupNum ?? uniqueId('')}
              {...sgnProps}
            />
          );
        }

        return (
          <Card className={styles.wrapper} key={uniqueId()}>
            <h3>{`${program.execTime}: ${ERROR_TEXT}`}</h3>
          </Card>
        );
      })}
    </>
  );
};

export default observer(SignalProgramCard);
