import { useState, useEffect, useCallback } from 'react';

import eventBus from '../../../eventBus';
import rootStore from '../../../stores/rootStore/rootStore';
import { getFeatures } from '../helpers/getFeatures';
import { IDataFeatures } from '../Mapper.model';

const useDataFeatures = () => {
  const { isGroupDispatch } = rootStore.scriptsControlStore;
  const { systems } = rootStore.mapDataStore;
  const { setSelectedClusters } = rootStore.clustersStore;
  const { isPhaseCircle } = rootStore.pointsUdsStore;
  const { isConstructor } = rootStore.constructorStore;

  const [data, setData] = useState<N<IDataFeatures[]>>(null);

  const updateFeatures = useCallback(() => {
    const newFeatures = getFeatures(systems, isGroupDispatch);

    setData(newFeatures);
  }, [systems, isGroupDispatch]);

  useEffect(() => {
    const unsubscribe = eventBus.updateFeatures.subscribe(updateFeatures);

    isPhaseCircle ? setSelectedClusters(null) : updateFeatures();

    return () => unsubscribe();
  }, [updateFeatures, isPhaseCircle, isConstructor, setSelectedClusters]);

  return data;
};

export default useDataFeatures;
