import { DEVICES_INFO } from '../constants/devicesConstants';
import { TObjMap } from '../stores/mapDataStore/mapDataStore.model';
import { System } from '../ts/enums/enums';
import { isTLObject } from '../ts/models/tl.model';

const { Delete, NotAvailable } = DEVICES_INFO.STATE;

const checkIsTLValid = (
  currentObject: TObjMap,
  system: System = System.Lights
) => {
  if (!isTLObject(currentObject, system)) return true;

  const state = currentObject?.tlStatus?.state;

  return !(state === Delete.com || state === NotAvailable.com);
};

export default checkIsTLValid;
