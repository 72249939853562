import { isNumber } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC, useCallback } from 'react';

import rootStore from '../../../../../../../../../stores/rootStore/rootStore';
import { System } from '../../../../../../../../../ts/enums/enums';
import CameraIcon from '../../../../../../../../Overlays/Camera/CameraIcon/CameraIcon';
import VideoDetectorIcon from '../../../../../../../../Overlays/Camera/VideoDetectorIcon/VideoDetectorIcon';
import { LinkedCamera } from '../../../../../../../../Overlays/models/camera.model';
import { HandleClickDevice } from '../../../models/crossroadSchema.model';

const VIDEO_DT_RATE = 1.4;

interface CameraProps {
  id: number;
  daiJdt83: NonNullable<LinkedCamera['dai_jdt83']>;
  activeId: number;
  rate: number;
  handleClickDevice?: HandleClickDevice;
}

const Camera: FC<CameraProps> = ({
  daiJdt83,
  id,
  activeId,
  rate,
  handleClickDevice,
}) => {
  const { detectors } = rootStore.mapDataStore;

  const { linkedDetectorId } = daiJdt83;
  const isDtExist = detectors.some((dt) => dt.id === linkedDetectorId);
  const key = `camIco_${id}`;

  const onClick = useCallback(
    () => handleClickDevice?.(id, System.Cameras),
    [handleClickDevice, id]
  );

  const handleDt = useCallback(
    () =>
      isNumber(linkedDetectorId) &&
      handleClickDevice?.(linkedDetectorId, System.Detectors),
    [handleClickDevice, linkedDetectorId]
  );

  const props = {
    camera: daiJdt83,
    key,
    activeId,
    rate,
    onClick,
  };

  if (isDtExist && linkedDetectorId) {
    return (
      <VideoDetectorIcon
        {...props}
        icoRate={VIDEO_DT_RATE}
        handleDt={handleDt}
      />
    );
  }

  return <CameraIcon {...props} />;
};

export default observer(Camera);
