import { Form, Input, Select } from 'antd';
import { Dayjs } from 'dayjs';
import { isString } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useState } from 'react';

import { getObjectEntries } from '../../../../../helpers/getObjectEntries';
import rootStore from '../../../../../stores/rootStore/rootStore';
import {
  ARRAY_TYPE,
  IQueryParamRequest,
  isDateValues,
  isRangeDateValues,
  QueryParamItem,
  WebElementsTypes,
} from '../../../../../ts/models/table.model';
import Popover, { PopoverProps } from '../../../Popover/Popover';
import { getQueryParamsBody } from '../../helpers/getQueryParamsBody/getQueryParamsBody';
import { ITable } from '../../Table';

import BtnsBlock from './BtnsBlock/BtnsBlock';
import DateFormatPicker from './DateFormatPicker/DateFormatPicker';
import { getFormFieldsetValues, ValuesType } from './helpers';
import checkFormChange from './helpers/checkFormChange';
import { filterFormItemValuesByOptions } from './helpers/filterFormItemValuesByOptions';
import getFormattedDefaultValues from './helpers/getFormattedDefaultValues';
import { getOptionsByAssociativeKey } from './helpers/getOptionsByAssociativeKey';
import getRulesFiltration from './helpers/getRulesFiltration';

export type Options = Exclude<QueryParamItem['webElementOptions'], null>;

interface ISearchForm {
  queryParams: QueryParamItem[];
  refreshData: ITable['refreshData'];
  queryParamsRequest: IQueryParamRequest;
  setQueryParamsRequest: SetState<IQueryParamRequest>;
  onClose: () => void;
}

const SearchForm: FC<ISearchForm> = ({
  queryParams,
  refreshData,
  onClose,
  queryParamsRequest,
  setQueryParamsRequest,
}) => {
  const { interfaceTipsDelay } = rootStore.uiStore;
  const [formValues, setFormValues] = useState<Record<string, any>>({});
  const [isChangeForm, setIsChangeForm] = useState(false);

  const [form] = Form.useForm();

  const setDefaultFromValues = useCallback(
    (type: ValuesType, isNeedCheck = false) => {
      const defaultValues = getFormFieldsetValues(queryParams, type, {
        isNeedCheck,
      });

      const formattedFormValues = getFormattedDefaultValues(defaultValues);

      setFormValues(formattedFormValues);
      setQueryParamsRequest(defaultValues);
      form.resetFields();
      form.setFieldsValue(formattedFormValues);

      form.validateFields();
    },
    [form, queryParams, setQueryParamsRequest]
  );

  useEffect(() => {
    setDefaultFromValues('defaultValue', true);
  }, [setDefaultFromValues]);

  const onChangeDate = (value: [Dayjs, Dayjs] | Dayjs, jkey: string) => {
    form.setFieldValue(jkey, value);
    const values = form.getFieldsValue();
    const newValues = getQueryParamsBody(values, queryParams);

    setIsChangeForm(checkFormChange(newValues, queryParams));

    setQueryParamsRequest(newValues);
  };

  const handleSubmitServerSearch = async () => {
    refreshData(queryParamsRequest);
    onClose();
    form.resetFields();
  };

  const btnsprops = {
    form,
    queryParams,
    isChangeForm,
    handleSubmitServerSearch,
    setDefaultFromValues,
    onClose,
  };

  const handleValuesChange = async (
    changedValues: OWKS<unknown>,
    values: OWKS<unknown>
  ) => {
    const [key, value] = getObjectEntries(changedValues)[0];

    if (isString(value)) {
      const trimValue = value.trimStart() || null;

      form.setFieldValue(key, trimValue);
      values[key] = trimValue;
    }

    setFormValues(values);

    form.setFieldValue(key, value ?? null);

    const newValues = getQueryParamsBody(values, queryParams);

    setQueryParamsRequest(newValues);

    setIsChangeForm(checkFormChange(newValues, queryParams));
  };

  return (
    <Form form={form} layout="vertical" onValuesChange={handleValuesChange}>
      {queryParams.map((param) => {
        const defaultValue = param.defaultValue;
        const isSelect =
          param.webElement === WebElementsTypes.Select ||
          param.webElement === WebElementsTypes.MultiSelect;

        const basicParams = {
          label: param.webElementCapture,
          name: param.jkey,
          rules: getRulesFiltration(param),
        };

        if (param.webElement === WebElementsTypes.Input) {
          const { minValue, maxValue } = param;

          return (
            <Form.Item key={param.jkey} {...basicParams}>
              <Input
                allowClear
                type={param.jvalueType}
                min={minValue ?? 0}
                max={maxValue ?? undefined}
              />
            </Form.Item>
          );
        }

        if (isSelect && param.webElementOptions !== null) {
          const jvalueArr = param.jvalueType.split('.');
          const mode = jvalueArr.at(1) === ARRAY_TYPE ? 'multiple' : undefined;
          const isDisabled = !param.webElementOptions.length;

          const popoverProps: Omit<PopoverProps, 'children'> = {
            tipsDelay: interfaceTipsDelay,
            content: isDisabled ? 'Данные для фильтра отсутствуют' : '',
            placement: 'left',
          };

          return (
            <Popover {...popoverProps} key={param.jkey}>
              <Form.Item {...basicParams}>
                <Select
                  allowClear
                  options={param.webElementOptions}
                  placeholder={param.webElementCapture}
                  mode={mode}
                  disabled={isDisabled}
                  filterOption={(input, option) =>
                    (option?.label ?? '')?.includes(input)
                  }
                />
              </Form.Item>
            </Popover>
          );
        }

        if (
          param.webElement === WebElementsTypes.SelectAssociativeKey &&
          param.webElementAssociativeOptions
        ) {
          const jvalueArr = param.jvalueType.split('.');
          const isMultiple = jvalueArr.at(1) === ARRAY_TYPE;
          const options = getOptionsByAssociativeKey(param, formValues);

          filterFormItemValuesByOptions({
            form,
            isMultiple,
            options,
            param,
            setFormValues,
          });

          const isDisabled = !options.length;

          if (isDisabled) {
            form.resetFields([param.jkey]);
          }

          const popoverProps: Omit<PopoverProps, 'children'> = {
            tipsDelay: interfaceTipsDelay,
            content: isDisabled ? 'Данные для фильтра отсутствуют' : '',
            placement: 'left',
          };

          return (
            <Popover {...popoverProps} key={param.jkey}>
              <Form.Item {...basicParams}>
                <Select
                  allowClear
                  options={options}
                  placeholder={param.webElementCapture}
                  mode={isMultiple ? 'multiple' : undefined}
                  disabled={isDisabled}
                  filterOption={(input, option) =>
                    (option?.label ?? '').includes(input)
                  }
                />
              </Form.Item>
            </Popover>
          );
        }

        if (
          isDateValues(defaultValue, param.jvalueType) ||
          isRangeDateValues(defaultValue, param.jvalueType)
        ) {
          return (
            <DateFormatPicker
              key={basicParams.name}
              onChange={(value) => value && onChangeDate(value, param.jkey)}
              format={defaultValue.dateFormat}
              basicParams={basicParams}
              formProps={{
                formValues,
                jkey: param.jkey,
                jvalueType: param.jvalueType,
              }}
            />
          );
        }

        return null;
      })}
      <BtnsBlock {...btnsprops} />
    </Form>
  );
};

export default observer(SearchForm);
