import { useQuery } from '@tanstack/react-query';
import { isNumber } from 'lodash';
import { toLonLat } from 'ol/proj';

import api from '../api';
import { NO_WEATHER_DATA } from '../components/Sidebar/InfoBlock/constants/constants';
import { QUERY_KEY, UPDATE_WEATHER_TIME } from '../constants/queryConstants';
import rootStore from '../stores/rootStore/rootStore';

const { WEATHER_INFO } = QUERY_KEY;
const { fetchWeather } = api.integration.gis.weather.weatherApi;

const APP_PATH = 'hooks.useWeather';

const useWeather = (isDisableWeather = false) => {
  const { initialMapCenter } = rootStore.uiStore;

  const {
    data: weatherData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [WEATHER_INFO, initialMapCenter, isDisableWeather],
    queryFn: async () => {
      if (!initialMapCenter || isDisableWeather) return null;

      const [long, lat] = toLonLat(initialMapCenter);

      const res = await fetchWeather({
        longitude: long,
        latitude: lat,
        appPath: APP_PATH,
      });

      if (!res) return null;

      const {
        attributes: { conditionText, temp },
      } = res;

      const t = isNumber(temp) ? Math.round(temp) : null;

      const formattedTemp = isNumber(t) ? `${t >= 0 ? '+' : ''}${t}°` : null;

      return { temp: formattedTemp, weather: conditionText };
    },
    refetchInterval: UPDATE_WEATHER_TIME,
    enabled: initialMapCenter && !isDisableWeather,
  });

  return {
    weatherData: weatherData ?? NO_WEATHER_DATA,
    isLoading,
    isError,
  };
};

export default useWeather;
