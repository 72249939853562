import { isNumber, isString } from 'lodash';

import {
  isDateValues,
  isRangeDateValues,
  QueryParamItem,
  VALUE_TYPES,
} from '../../../../ts/models/table.model';

export const getIsValidItem = (
  jvalueType: QueryParamItem['jvalueType'],
  item: unknown
) => {
  if (jvalueType === VALUE_TYPES.datePickerJson) {
    const isValidDate = isDateValues(item, jvalueType) && isString(item.value);

    return isValidDate;
  }

  if (jvalueType === VALUE_TYPES.rangeDatePickerJson) {
    const isValidDate =
      isRangeDateValues(item, jvalueType) &&
      isString(item.from) &&
      isString(item.to);

    return isValidDate;
  }

  if (Array.isArray(item)) {
    return !!item.length;
  }

  if (isNumber(item)) return true;

  return !!item;
};
