import { Map } from 'ol';
import { easeOut } from 'ol/easing';
import type { Extent } from 'ol/extent';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { Cluster } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import AnimatedCluster from 'ol-ext/layer/AnimatedCluster';

import { CLUSTERS_INFO } from '../../../../../constants/mapClusterConstants';

const { ANIMATION_DURATION } = CLUSTERS_INFO;

/**
 * The function checks if the feature is in the map extent.
 * @param {ol.Feature} feature  - feature.
 * @param {[float, float, float, float]} extent - extent.
 * @returns {boolean} - is feature in extent.
 */
const isFeatureInExtent = (feature: Feature<Point>, extent: Extent) => {
  const featureGeometry = feature.getGeometry();
  const featureCoordinates = featureGeometry?.getCoordinates() ?? [0, 0];

  const feaX = featureCoordinates[0];
  const feaY = featureCoordinates[1];
  const leftCorner = extent[0];
  const upCorner = extent[1];
  const rightCorner = extent[2];
  const downCorner = extent[3];

  const isHoriz = feaX >= leftCorner && feaX <= rightCorner;
  const isVert = feaY >= upCorner && feaY <= downCorner;

  return isHoriz && isVert;
};

export const flattenFeaturesInExtent = (
  map: Map,
  features: Feature<Point>[]
) => {
  const currentExtent = map.getView().calculateExtent();

  const featuresExtent = features.reduce((acc: Feature<Point>[], el) => {
    if (isFeatureInExtent(el, currentExtent)) {
      acc.push(el);
    }

    return acc;
  }, []);

  return featuresExtent;
};

export function checkIfTwoDimensional(aggregate: Feature[] | null) {
  // Has not at least one entry that is an array
  // if (aggregate && !aggregate[0]) return false
  // return true
  return aggregate
    ? aggregate.every((entry: Feature) => !Array.isArray(entry))
    : true;
}

export function generateNewClusterSource() {
  return new Cluster({
    source: new VectorSource({}),
  });
}

export function generateAnimatedClusters(clusterSource: Cluster) {
  return new AnimatedCluster({
    source: clusterSource,
    animationDuration: ANIMATION_DURATION,
    easingFunction: () => easeOut(0.5),
  });
}
