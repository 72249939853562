import { notification } from 'antd';

export const getSuccesNoti = (
  queryParamsReq: U<{ [key: string]: unknown }>
) => {
  if (queryParamsReq && Object.keys(queryParamsReq).length)
    notification.success({
      message: 'Выполнено',
      description: 'Данные успешно обновлены',
    });
};
