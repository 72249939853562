import { ChartBDKeys } from '../../../../components/InfoPanel/SystemsInfo/PopupDetector/Chart/constants/constants';
import { getAppPathHeaders } from '../../../helpers/getAppPathHeaders';
import http from '../../../http';
import tryCatchWrapper from '../../../tryCatchWrapper';
import { DT_STATISTIC } from '../constants/constants';
import {
  StatisticDataInit,
  PredictDataProps,
  UseDtStatisticQueryOptions,
} from '../model/dtStatistics.model';

import useDtStatisticDataQuery from './query/useDtStatisticDataQuery';

const PREDICT = DT_STATISTIC + '/predict';

class DtStatisticApi {
  fetchPredictData = ({
    id,
    left,
    right,
    directions,
    appPath,
    signal,
  }: PredictDataProps) =>
    tryCatchWrapper<StatisticDataInit>(
      async () => {
        const res = await http.get(`${PREDICT}/${id}`, {
          headers: getAppPathHeaders(appPath),
          params: {
            left,
            right,
            directions: JSON.stringify(directions),
          },
          signal,
        });

        return res.data;
      },
      {
        errorMessage: 'PREDICT_GRAPH_DATA',
        ignoreCodes: [410, 404],
      }
    );

  useDtStatisticData = <T extends U<ChartBDKeys> = undefined>(
    props: UseDtStatisticQueryOptions<T>
  ) =>
    useDtStatisticDataQuery({
      ...props,
      fetchPredictData: this.fetchPredictData,
    });
}

const dtStatisticApi = new DtStatisticApi();

export default dtStatisticApi;
