import { ClusterStoreValues } from '../../storeUtils/model/store.model';

export const DEFAULT_CLUSTERS_VALUES: ClusterStoreValues = {
  animationTimeoutInfo: null,
  triggerCenteredCoord: null,
  isClusterClicked: false,
  clusters: [],
  selectedClusters: [],
  features: [],
  unlinkedFeatures: [],
};
