import classNames from 'classnames';
import { CSSProperties, FC } from 'react';
import { LuWifi, LuWifiOff } from 'react-icons/lu';

import Popover, { PopoverProps } from '../../../ui-kit/Popover/Popover';

import styles from './DtLanesButton.module.scss';

const LABEL = 'Вкл/Выкл отображения полос';
const EMPTY_DATA = 'Недостаточно данных для отображения полос';

export interface DtLanesProps {
  onDtLanesClick: () => void;
  isDtLanes: boolean;
  isDtLanesValid: boolean;
}

export interface DtLanesButtonProps extends DtLanesProps {
  basicProps?: {
    className: string;
    style: CSSProperties;
  };
  popoverProps: Omit<PopoverProps, 'children' | 'content'>;
}

const DtLanesButton: FC<DtLanesButtonProps> = ({
  isDtLanes,
  basicProps,
  popoverProps,
  isDtLanesValid,
  onDtLanesClick,
}) => {
  const isDisabled = !isDtLanesValid;

  const icoStyle = classNames(basicProps?.className, {
    [styles.active]: isDtLanes,
    [styles.disabled]: isDisabled,
  });

  const icoProps = {
    onClick: !isDisabled ? onDtLanesClick : undefined,
    className: icoStyle,
    style: basicProps?.style,
  };

  const content = isDisabled ? EMPTY_DATA : LABEL;

  return (
    <Popover {...popoverProps} content={content}>
      {!isDtLanes ? (
        <LuWifi {...icoProps} data-testid="on" />
      ) : (
        <LuWifiOff {...icoProps} data-testid="off" />
      )}
    </Popover>
  );
};

export default DtLanesButton;
