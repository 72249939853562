const { REACT_APP_API_INSTANCE_NAME } = process.env;

const INSTANCE = REACT_APP_API_INSTANCE_NAME ?? 'njs';
const API = `/${INSTANCE}/api`;

const location =
  typeof window !== 'undefined'
    ? window.location
    : {
        protocol: 'http',
        origin: '{хост сервера отдачи бандла}',
        host: '{хост сервера отдачи бандла}',
      };
const originUrl = location?.origin;
const isSecure = location?.protocol === 'https:';
const wsProtocol = isSecure ? 'wss' : 'ws';

interface IConfig {
  OSM_SOURCE: Record<string, string> | undefined;
  getCameraName: (...args: any) => string;
  VERSION: number;
  EPUTS_URL: string;
  WEB_SOCKET_URL: string;
  REPORT_SERVICE_URL: string;
}

const BASIC: IConfig = {
  OSM_SOURCE: undefined,
  getCameraName: (camera: any, id: any) => camera?.srcId,
  VERSION: 1,
  EPUTS_URL: originUrl + API,
  WEB_SOCKET_URL: `${wsProtocol}://${location.host}/${INSTANCE}/ws`,
  REPORT_SERVICE_URL: `https://gis.eputs.org/report-server`,
};

const DEV: IConfig = {
  ...BASIC,
  EPUTS_URL: `https://dev.eputs.org${API}`,
  WEB_SOCKET_URL: 'wss://dev.eputs.org/njs/ws',
};

const LOCAL: IConfig = {
  ...BASIC,
  EPUTS_URL: '/api',
  WEB_SOCKET_URL: 'ws://localhost:5000/ws',
};

const BUILD: IConfig = {
  ...BASIC,
};

const PROD: IConfig = {
  ...BASIC,
  OSM_SOURCE: {
    url: `${originUrl + API}/v1/tiles/{z}/{x}/{y}`,
  },
};

const PROD_ONLINE_TILES: IConfig = {
  ...PROD,
  OSM_SOURCE: undefined,
};

const CONFIG_NAMES = {
  DEV,
  LOCAL,
  BUILD,
  PROD,
  PROD_ONLINE_TILES,
} as const;

type TConfigNames = keyof typeof CONFIG_NAMES;

const configName = process.env.REACT_APP_CONFIG_NAME as TConfigNames;

if (!CONFIG_NAMES[configName]) {
  const cfgError = {
    message: `Ошибка обработки конфига, название конфига из .env '${configName}' не соответствует допустимым значениям`,
    REACT_APP_CONFIG_NAME: configName,
    existConfigsNames: Object.keys(CONFIG_NAMES),
  };

  console.error('Ошибка обработки конфига', JSON.stringify(cfgError, null, 2));
}

export const CONFIG: IConfig = CONFIG_NAMES[configName] ?? CONFIG_NAMES.DEV;
