import { Feature } from 'ol';
import { Geometry } from 'ol/geom';

import { WKID_MERCATOR } from '../../../../../apiGIS/constants/srs';
import { IGetEsriFeatures } from '../../../../../apiGIS/models/sources/sourceDefinitions';
import { queryFeatures } from '../../../../../apiGIS/sources/FeatureServerSource';
import { exportFileService } from '../../../../../helpers/export/helpers';
import {
  DEFAULT_OUT_FIELDS,
  DEFAULT_WHERE,
  EXTENT_DR,
  MAX_FEATURES_PER_REQUEST,
} from '../../constants';
import {
  ILayerDataNode,
  ILayerQueryResult,
  ILayerTableHeader,
} from '../../layers.model';

export const queryLayerData = async (
  selectedItem: ILayerDataNode,
  text?: string
): Promise<ILayerQueryResult> => {
  const url = selectedItem.item?.url || '';

  const outFields = selectedItem.item?.outFields || DEFAULT_OUT_FIELDS;

  const where = DEFAULT_WHERE;
  const dR = EXTENT_DR;
  const bbox = `{"xmin":${-dR},"ymin":${-dR},"xmax":${dR},"ymax":${dR},"spatialReference":{"wkid":${WKID_MERCATOR}}}`;

  const params: IGetEsriFeatures = {
    bbox,
    url,
    where,
    outFields,
    num: MAX_FEATURES_PER_REQUEST,
  };

  const data = await queryFeatures(params);
  const { features, objectIdFieldName } = data;

  return { features, objectIdFieldName };
};

export const getRowItems = (
  features: Feature[],
  objectIdFieldName: string,
  definition: ILayerDataNode,
  text?: string
): Record<string, any>[] => {
  const outFields = definition.item?.outFields || [];

  return features
    .map((feature: Feature<Geometry>, index: number) => {
      const attributes = feature.getProperties() || {};

      attributes['key'] = attributes[objectIdFieldName] || String(index + 1);

      delete attributes['geometry'];

      return attributes;
    })
    .filter((item: any) => {
      if (!text || text === '') {
        return true;
      }

      const textView = text.toUpperCase();

      for (let i = 0; i < outFields.length; i++) {
        const fieldName = outFields[i];

        const value = String(item[fieldName]).toUpperCase();

        if (value.includes(textView)) {
          return true;
        }
      }

      return false;
    });
};

export const getHeaders = (definition: ILayerDataNode): ILayerTableHeader[] => {
  const fields = definition.item?.fields || [];
  const outFields = definition.item?.outFields || [];

  return fields
    .map((field) => {
      return {
        title: field.alias || field.name,
        dataIndex: field.name,
        key: field.name,
      };
    })
    .filter((header) => {
      const { key } = header;

      return outFields.includes(key);
    });
};

export const downloadLayerTable = async (
  title: string,
  rows: any[],
  headers: any[]
) => {
  const titleView = title ? `Данные слоя ${title}` : `Данные слоя`;

  exportFileService(rows, '', titleView, headers);
};
