import { observer } from 'mobx-react-lite';
import React from 'react';
import { MdSettings } from 'react-icons/md';

import { ReactComponent as ArrowBack } from '../../../assets/icons/ic_arrow_back.svg';
import { KEY_EVENT_COMPONENT } from '../../../constants/constants';
import useKeyCombination from '../../../keyCombination/useKeyCombination';
import rootStore from '../../../stores/rootStore/rootStore';
import ButtonOnlyIcon from '../../ui-kit/ButtonOnlyIcon/ButtonOnlyIcon';
import Popover from '../../ui-kit/Popover/Popover';
import getOnlyOneModule from '../ScriptsControl/SelectModule/ModulesSwitcher/helpers/getOnlyOneModule';

import styles from './CollapseBtn.module.scss';

export interface CollapseBtnProps {
  actionPopoverContent?: string;
  onAction?: (e?: React.MouseEvent<HTMLElement>) => unknown;
  actionIco?: JSX.Element;
  isActive?: boolean;
}

const CollapseBtn: React.FC<CollapseBtnProps> = ({
  onAction,
  actionIco = <MdSettings />,
  actionPopoverContent,
  isActive,
}) => {
  const { setIsNot, interfaceTipsDelay, moduleManagementType, setKeyValue } =
    rootStore.uiStore;

  const content = moduleManagementType
    ? 'Вернуться к диспетчерскому управлению'
    : 'Свернуть панель';

  const handleClick = () => {
    if (moduleManagementType && !getOnlyOneModule().isOnceModule) {
      return setKeyValue('moduleManagementType', null);
    }

    setIsNot('isPanel', false);
  };

  useKeyCombination(KEY_EVENT_COMPONENT.ESCAPE.panel, handleClick);

  return (
    <div className={styles.container}>
      <div className={styles.rollUp}>
        <Popover
          content={content}
          placement="top"
          tipsDelay={interfaceTipsDelay}
        >
          <button className={styles.backBtn} onClick={handleClick}>
            <ArrowBack />
          </button>
        </Popover>
      </div>
      {onAction && (
        <ButtonOnlyIcon
          onClick={onAction}
          isSmall
          isActive={isActive}
          tip={actionPopoverContent}
        >
          {actionIco}
        </ButtonOnlyIcon>
      )}
    </div>
  );
};

export default observer(CollapseBtn);
