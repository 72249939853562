import { sendError } from '../../helpers/sendError';
import { TLRes } from '../mapDataStore/mapDataStore.model';

type TLData = TLRes['data'];

const checkCycleTime = (data: TLData) =>
  data.map((tl) => {
    const { tlTacts } = tl;

    if (tlTacts) {
      const { cycle } = tl.tlStatus;

      const cycleFromTacts = tlTacts.reduce((res, tact) => {
        // eslint-disable-next-line no-param-reassign
        res += tact.time;

        return res;
      }, 0);

      if (cycle !== cycleFromTacts) {
        tl.tlStatus.cycle = cycleFromTacts;
        const title = 'Не соответствие данных';
        const error = `Продолжительность цикла в СО: id=${tl.id} не соответствует продолжительности тактов. Сумма тактов = ${cycleFromTacts}, Продолжительность цикла: tlStatus.cycle = ${cycle}`;

        const header = { header: tl.header };

        const tlInfo = tl.addinfo ? { ...tl.addinfo, ...header } : header;

        sendError(title, { error: error, ...tlInfo }, 'checkCycleTime');
      }
    }

    return tl;
  });

export default checkCycleTime;
