import { FC } from 'react';

import { ERROR_TEXT_SGN_PROGRAMS } from '../../../../../constants/commands';
import {
  ICsdData,
  ITimePoints,
} from '../../../../../ts/models/signalPrograms.model';
import NewSignalProgram from '../../../../NewSignalProgram/NewSignalProgram';

import styles from './SgnProgramTooltip.module.scss';

interface ISgnProgramTooltipProps {
  execTime: U<string>;
  csdData: N<ICsdData>;
  phasesSet: ITimePoints[];
  isError: boolean;
  phaseGroupNum: N<number>;
  id: number;
}

const SgnProgramTooltip: FC<ISgnProgramTooltipProps> = ({
  execTime,
  csdData,
  phasesSet,
  isError,
  phaseGroupNum,
  id,
}) => {
  if (isError || !csdData) {
    return <h3>{ERROR_TEXT_SGN_PROGRAMS}</h3>;
  }

  return (
    <div className={styles.wrapper}>
      <NewSignalProgram
        id={id}
        isStatic={true}
        phasesSet={phasesSet}
        csdData={csdData}
        title={execTime}
        phaseGroupNum={phaseGroupNum}
      />
    </div>
  );
};

export default SgnProgramTooltip;
