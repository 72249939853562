import { useEffect, useRef } from 'react';

import { Scene } from '../../../ts/models/MapObjects/detectors.model';
import { drawShape, drawTLines } from '../helpers/helpers';

interface IUseDrawGrid {
  width: number;
  height: number;
  scene: Scene;
  isShowAllInfo?: boolean;
}

export const useDrawGrid = ({
  width,
  height,
  scene,
  isShowAllInfo = false,
}: IUseDrawGrid) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const ctx = canvasRef?.current?.getContext('2d');

    if (!ctx || !scene?.lanes || !scene?.trafficLines) return;

    const { lanes, trafficLines } = scene;

    ctx.clearRect(0, 0, width, height);
    lanes.forEach((lane, i) => {
      drawShape(lane, ctx, width, height, i, isShowAllInfo);
    });
    trafficLines.forEach((lane) => {
      drawTLines(lane.points, ctx, width, height);
    });
  }, [height, scene, width, isShowAllInfo]);

  return { canvasRef };
};
