import eventBus from '../eventBusCore';

import { EventBusEvents } from './enums/eventEnums';

const eventName = EventBusEvents.PhaseHoldChange;

const subscribe = (callback: () => void) => {
  return eventBus.subscribe(eventName, callback);
};

const broadcast = () => {
  eventBus.broadcast(eventName);
};

export default {
  subscribe,
  broadcast,
};
