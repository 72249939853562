import { isNumber } from 'lodash';
import { useEffect, useState } from 'react';

import api from '../../../../../api';
import { Geocode } from '../../../../../api/integration/gis/geocode/model/geocode.model';
import rootStore from '../../../../../stores/rootStore/rootStore';

const { geocode } = api.integration.gis.geocode.geocodeApi;

const APP_PATH = 'DetailedStatistics.Monitoring.SystemMonitoring.useGeocode';

const useGeocode = () => {
  const { systemCoords } = rootStore.mapDataStore;
  const { regionData } = rootStore.uiStore;

  const [addressInfo, setAddressInfo] = useState<Geocode[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const regionId = regionData?.id;

    if (!isNumber(regionId)) return;

    (async () => {
      setIsLoading(true);

      const geocodeArray = await geocode({
        locations: systemCoords,
        appPath: APP_PATH,
        regionId,
      });

      setIsLoading(false);
      setAddressInfo(geocodeArray ?? []);
    })();
  }, [systemCoords, regionData?.id]);

  return { addressInfo, isLoading };
};

export default useGeocode;
