import { Map, View } from 'ol';
import { useEffect, useRef } from 'react';

import { MAP_INITIAL_OPTIONS, ZOOM } from '../../../constants/mapConstants';
import rootStore from '../../../stores/rootStore/rootStore';
import { normalizeExtent } from '../helpers/normalizeExtent';

const useMapInit = () => {
  const { setMapData } = rootStore.mapDataStore;
  const { setMapValue } = rootStore.mapStore;
  const { initialMapCenter, regionData } = rootStore.uiStore;

  const map = useRef(new Map(MAP_INITIAL_OPTIONS)).current;
  const mapRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    map.setTarget(mapRef.current ?? undefined);

    return () => {
      setMapValue('map', null);
    };
  }, [map, mapRef, setMapValue]);

  useEffect(() => {
    if (!regionData) return;

    const extent = normalizeExtent(regionData.extent);
    const zoom = regionData.initialZoom;

    map.setView(
      new View({
        zoom,
        minZoom: zoom,
        maxZoom: ZOOM.MAX,
        center: initialMapCenter,
        extent,
      })
    );

    setMapValue('map', map);

    map.getView().on('change', (evt) => {
      const resolution = map.getView().getResolution() || -1;

      setMapData('viewResolution', resolution);
    });
  }, [initialMapCenter, regionData, setMapData, mapRef, map, setMapValue]);

  return mapRef;
};

export default useMapInit;
