import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import Img from '../../../../../../../../../../../ui-kit/Img/Img';

import usePhaseImage from './hooks/usePhaseImage';

import styles from './MainImage.module.scss';

interface MainImageProps {
  id: number;
  isShow: boolean;
}

const MainImage: FC<MainImageProps> = ({ id, isShow }) => {
  const imgUrl = usePhaseImage(id);

  const blockStyle = classNames(styles.container, {
    [styles.invisible]: !isShow,
  });

  return (
    <div className={blockStyle}>
      <Img src={imgUrl} alt="Картинка Фазы" className={styles.img} />
    </div>
  );
};

export default observer(MainImage);
