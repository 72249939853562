import classNames from 'classnames';
import { FC } from 'react';

import ButtonOnlyIcon from '../../../../ui-kit/ButtonOnlyIcon/ButtonOnlyIcon';

import { DISPLAY_ICONS_DEVICES, ShowBtnsDevices } from './constants/constants';

import styles from './DisplayBtns.module.scss';

interface IDisplayBtnsProps {
  displayType: ShowBtnsDevices;
  setDisplayType: SetState<ShowBtnsDevices>;
  isNeedAbsolute?: boolean;
}

const DisplayBtns: FC<IDisplayBtnsProps> = ({
  displayType,
  setDisplayType,
  isNeedAbsolute = true,
}) => {
  const containerStyle = classNames({
    [styles.container]: true,
    [styles.absolute]: isNeedAbsolute,
  });

  return (
    <div className={containerStyle}>
      {DISPLAY_ICONS_DEVICES.map((el) => (
        <ButtonOnlyIcon
          key={el.id}
          isActive={displayType === el.type}
          onClick={() => setDisplayType(el.type)}
          tip={el.tooltip}
        >
          {el.icon}
        </ButtonOnlyIcon>
      ))}
    </div>
  );
};

export default DisplayBtns;
