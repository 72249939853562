import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../stores/rootStore/rootStore';
import { IInfoPanelData } from '../../../../stores/uiStore/uiStore.model';
import { System } from '../../../../ts/enums/enums';
import { TLDetailedCode } from '../../../../ts/enums/userData';
import { DeviceControlAccess } from '../../../../ts/models/mapObject.model';
import { MAIN_KEY } from '../../../DetailedMain/DetailedWrapper/constants/tlItems';
import Popover from '../../../ui-kit/Popover/Popover';

import checkControlAccessTL from './helpers/checkControlAccessTL';

import styles from './InfoBtns.module.scss';

interface IInfoBtns {
  isLights: boolean;
  infoData: IInfoPanelData;
  controlAccess: DeviceControlAccess;
  isDisabled: boolean;
}

export const MANAGEMENT_KEY = '8';

const InfoBtns: FC<IInfoBtns> = ({
  isLights,
  infoData,
  controlAccess,
  isDisabled,
}) => {
  const { interfaceTipsDelay } = rootStore.uiStore;

  const { isTlsDataFetched } = rootStore.mapDataStore;
  const { hasAccess } = rootStore.userDataStore;
  const { openDetailed } = rootStore.detailedStore;

  const { isManagementAccess, isAccessManagementCode } =
    checkControlAccessTL(controlAccess);
  const isMeteo = infoData.system === System.Meteo;
  const isTl = infoData.system === System.Lights;

  const isDetailedAccess = hasAccess(TLDetailedCode.isDetailed) && !isMeteo;

  const isDetailed = isTl
    ? isDetailedAccess && isTlsDataFetched
    : isDetailedAccess;

  const popoverProps = {
    tipsDelay: interfaceTipsDelay,
    content: 'Открыть',
    placement: 'top',
  } as const;

  const handleClick = (key: U<string>) => {
    key && openDetailed({ key });
  };

  if (!isDetailedAccess) return null;

  return (
    <div className={styles.buttonsBlock}>
      {isDetailed && (
        <Popover
          {...popoverProps}
          content={`${popoverProps.content} подробную информацию об объекте`}
        >
          <Button
            type="primary"
            className={styles.detailsBtn}
            onClick={() => handleClick(MAIN_KEY)}
            disabled={isDisabled}
          >
            Подробнее
          </Button>
        </Popover>
      )}
      {isLights && isAccessManagementCode && (
        <Popover
          {...popoverProps}
          content={`${popoverProps.content} управление СО`}
        >
          <Button
            type="primary"
            className={styles.detailsBtn}
            onClick={() => handleClick(MANAGEMENT_KEY)}
            disabled={isDisabled || !MANAGEMENT_KEY || !isManagementAccess}
          >
            Управление СО
          </Button>
        </Popover>
      )}
    </div>
  );
};

export default observer(InfoBtns);
