import { isNumber } from 'lodash';
import { useCallback, useRef } from 'react';

import { findById } from '../helpers/findBy';

export type HandleScrollType = (
  elementId: Nullish<number>,
  getTimeoutId: (timeoutId: NodeJS.Timeout) => void
) => void;

const ANIMATION_SCROLL_DURATION = 100;

const useScrollToElement = (animationDuration = ANIMATION_SCROLL_DURATION) => {
  const blocksRefsArray = useRef<HTMLElement[]>([]);

  const addToRefs = useCallback(
    (el: N<HTMLElement>, id: number) => {
      if (!el || blocksRefsArray.current?.includes(el)) return;

      blocksRefsArray.current?.splice(id, 0, el);
    },
    [blocksRefsArray]
  );

  const handleScroll = useCallback<HandleScrollType>(
    (elementId, getTimeoutId) => {
      if (!isNumber(elementId)) return;

      const activeElement = findById(
        blocksRefsArray.current,
        elementId.toString()
      );

      const timeoutId = setTimeout(() => {
        activeElement?.scrollIntoView({ behavior: 'smooth' });
      }, animationDuration);

      getTimeoutId(timeoutId);
    },
    [animationDuration]
  );

  return { addToRefs, handleScroll, blocksRefsArray };
};

export default useScrollToElement;
