import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import CameraProjection from '../../../../../../../Overlays/Camera/CameraProjection/CameraProjection';
import { HandleClickDevice } from '../../models/crossroadSchema.model';

import Camera from './Camera/Camera';
import useLinkedCameras from './hooks/useLinkedCameras';

interface LinkedCamerasProps {
  linkedCams: number[];
  activeId: number;
  rate: number;
  handleClickDevice?: HandleClickDevice;
}

const LinkedCameras: FC<LinkedCamerasProps> = ({
  linkedCams,
  activeId,
  rate,
  handleClickDevice,
}) => {
  const cameras = useLinkedCameras({ linkedCams });

  const cameraProps = {
    activeId,
    rate,
    handleClickDevice,
  };

  return (
    <>
      {cameras.map(({ id, dai_jdt83 }) => {
        if (!dai_jdt83) return null;

        return (
          <div key={`camera_proj_${id}`}>
            <CameraProjection
              camera={dai_jdt83}
              isExtended={activeId === id}
              rate={rate}
            />
            <Camera {...cameraProps} daiJdt83={dai_jdt83} id={id} />
          </div>
        );
      })}
    </>
  );
};

export default observer(LinkedCameras);
