import tlIcon, {
  ReactComponent as TLIconJSX,
} from '../assets/icons/map/ic_tr_light.svg';
import tlIconAllRed, {
  ReactComponent as TLIconAllRedJSX,
} from '../assets/icons/map/ic_tr_light_all_red.svg';
import tlIconASU, {
  ReactComponent as TLIconASUJSX,
} from '../assets/icons/map/ic_tr_light_asu.svg';
import tlIconAU, {
  ReactComponent as TLIconAUJSX,
} from '../assets/icons/map/ic_tr_light_au.svg';
import tlIconCU, {
  ReactComponent as TLIconCUJSX,
} from '../assets/icons/map/ic_tr_light_cu.svg';
import tlIconLAU, {
  ReactComponent as TLIconLAUJSX,
} from '../assets/icons/map/ic_tr_light_lau.svg';
import tlIconTech, {
  ReactComponent as TLIconTechJSX,
} from '../assets/icons/map/ic_tr_light_tech.svg';
import tlIconVPU, {
  ReactComponent as TLIconVPUJSX,
} from '../assets/icons/map/ic_tr_light_vpu.svg';
import tlIconYellow, {
  ReactComponent as TLIconYellowJSX,
} from '../assets/icons/map/ic_tr_light_yellow.svg';

import { COLORS } from './colorsConstants';
import { DEVICES_INFO } from './devicesConstants';

export const TL_DETAILED = {
  MODE: {
    Off: {
      com: 0,
      dsc: 'Отключен',
      icon: tlIcon,
      iconJSX: <TLIconJSX />,
      borderColor: null,
    },
    Work: {
      com: 1,
      dsc: 'Рабочий штатный режим',
      icon: tlIcon,
      iconJSX: <TLIconJSX />,
      borderColor: null,
    },
    Critical: {
      com: 2,
      dsc: 'Критическая неисправность',
      icon: tlIcon,
      iconJSX: <TLIconJSX />,
      borderColor: COLORS.DANGEROUS,
    },
    ManualControlVPU: {
      com: 3,
      dsc: 'Ручное управление с ВПУ',
      icon: tlIconVPU,
      iconJSX: <TLIconVPUJSX />,
      borderColor: null,
    },
    TechnologicalMode: {
      com: 4,
      dsc: 'Технологический режим',
      icon: tlIconTech,
      iconJSX: <TLIconTechJSX />,
      borderColor: null,
    },
    YellowFlash: {
      com: 5,
      dsc: 'Желтое мигание (ЖМ)',
      icon: tlIconYellow,
      iconJSX: <TLIconYellowJSX />,
      borderColor: null,
    },
    AllRed: {
      com: 6,
      dsc: 'Всем красный',
      icon: tlIconAllRed,
      iconJSX: <TLIconAllRedJSX />,
      borderColor: null,
    },
    RemoteControl: {
      com: 7,
      dsc: 'Удаленное управление из АСУДД',
      icon: tlIconASU,
      iconJSX: <TLIconASUJSX />,
      borderColor: COLORS.PRIMARY,
    },
    AdaptiveControl: {
      com: 9,
      dsc: 'Адаптивное управление с сервера',
      icon: tlIconAU,
      iconJSX: <TLIconAUJSX />,
      borderColor: COLORS.PRIMARY,
    },
    CoordinatedManagement: {
      com: 10,
      dsc: 'Координированное управление',
      icon: tlIconCU,
      iconJSX: <TLIconCUJSX />,
      borderColor: COLORS.PRIMARY,
    },
    LocalAdaptiveControl: {
      com: 11,
      dsc: 'Локальное адаптивное управление',
      icon: tlIconLAU,
      iconJSX: <TLIconLAUJSX />,
      borderColor: null,
    },
    Unknown: {
      com: 255,
      dsc: 'Нет связи с ДК',
      icon: tlIcon,
      iconJSX: <TLIconJSX />,
      borderColor: null,
    },
  },
  SOURCE: {
    FB: {
      com: 0,
      dsc: 'ФБ - Физический Блок/Неисправность',
    },
    RU: {
      com: 1,
      dsc: 'РУ – Ручное Управление/Выносной Пульт Управления',
    },
    TVP: {
      com: 2,
      dsc: 'ТВП – Табло Вызова Пешеходом (кнопка вызова пешеходной фазы)',
    },
    SA: {
      com: 3,
      dsc: 'СА – Сетевой Адаптер/Центр',
    },
    DT: {
      com: 4,
      dsc: 'ДТ – Детектор Транспорта/МГР (Местное Гибкое/Адаптивное Регулирование)',
    },
    IP: {
      com: 5,
      dsc: 'ИП – Инженерный Пульт/Пульт диагностики контроллера',
    },
    APP: {
      com: 6,
      dsc: 'АПП – Автоматический Переключатель Программ (ЛР – Локальный Режим)',
    },
    DU: {
      com: 7,
      dsc: 'ДУ – Диспетчерское Управление',
    },
    PS: {
      com: 254,
      dsc: 'ПС – Потеря Связи/Отсутствие связи',
    },
    UNKNOWN: {
      com: 255,
      dsc: 'неизвестно',
    },
  },
  STATE: { ...DEVICES_INFO.STATE },
  PHASE_ID: {
    SWITCHED_OUT: 'off',
    CMD_SWITCHED_OUT: 0,
    AROUND_RED: 'ar',
    CMD_AROUND_RED: 1,
    YELLOW_FLASHING: 'yf',
    CMD_YELLOW_FLASHING: 2,
    EDITED: 'edt',
    COMMON: 'off',
  },
} as const;
