import StatusLegend from '../StatusLegend/StatusLegend';

import SearchBlock from './SearchBlock/SearchBlock';

import styles from './FilterBlock.module.scss';

const FilterBlock = () => {
  return (
    <div className={styles.container}>
      <StatusLegend />
      <SearchBlock />
    </div>
  );
};

export default FilterBlock;
