import dayjs from 'dayjs';

import { TIME_FORMAT } from '../../../../../constants/constants';

const FORMAT = `D MMM ${TIME_FORMAT}`;

export const getCorrectDateText = (
  selectedDate: number,
  durationTime: number
) => {
  const dateFrom = dayjs.unix(selectedDate).locale('ru');
  const dateTo = dateFrom.add(durationTime, 'second');

  return `c ${dateFrom.format(FORMAT)} до ${dateTo.format(FORMAT)}`;
};
