import { setRange } from '../PhaseHold/helpers/setRange';

export interface IDisabledTimePickerProps {
  h?: [number, number];
  m?: [number, number];
  s?: [number, number];
}

export const disabledTimePicker = (time: N<IDisabledTimePickerProps>) => {
  return {
    disabledHours: () => (time?.h ? setRange(time.h[0], time.h[1]) : []),
    disabledMinutes: () => (time?.m ? setRange(time.m[0], time.m[1]) : []),
    disabledSeconds: (selectedHour: number, selectedMinute: number) => {
      const isMidnight = selectedHour < 1 && selectedMinute < 1;
      const disSec = isMidnight ? [0] : [];

      const sec =
        selectedMinute === 30 && time?.s ? setRange(time.s[0], time.s[1]) : [];

      return [...disSec, ...sec];
    },
  };
};
