import { isNumber } from 'lodash';

import { TYPE_OF_TACTS } from '../../../constants/signalsConstants';
import getCircleSec from '../../../helpers/getCircleSec';
import {
  ICsdData,
  IProcessedDataInitial,
  ITacts,
  ITimePoints,
} from '../../../ts/models/signalPrograms.model';

import {
  getDefaultColorLine,
  getFlashGreenColorLine,
  getFlashYellowColorLine,
  getGreenColorLine,
  getRedColorLine,
  getYellowRedColorLine,
} from './getColorsArray';

const { GREEN, GREEN_FLASH, YELLOW, YELLOW_AND_RED, RED, OTHER } =
  TYPE_OF_TACTS;

const makeDirections = (
  processedArray: Nullish<IProcessedDataInitial['tlSignalProgram2']>,
  cycleTime: U<number>
) => {
  if (!processedArray || !isNumber(cycleTime)) return null;

  const sortDirections = processedArray.sort((a, b) => a.dirNum - b.dirNum);

  const res = sortDirections.map((procDirection, procIndex) => {
    let tacts = procDirection.data.map((prTact) => {
      const { type, stop, start, phase, step, onlygreen } = prTact;

      return {
        type: type,
        length: stop ? stop - start : cycleTime - start,
        phase,
        step,
        onlygreen,
      };
    });

    const viewName = procDirection.viewName;

    const len = procDirection.data.length;

    procDirection.data.forEach((prTact, i) => {
      const { start, phase, step, onlygreen, stop } = prTact;

      const basicTacts = {
        type: RED,
        phase,
        step,
        onlygreen,
      };

      if (i === 0 && start !== 0) {
        return (tacts = [
          {
            ...basicTacts,
            length: start,
          },
          ...tacts,
        ]);
      }

      if (i === len - 1 && stop && stop < cycleTime) {
        return (tacts = [
          ...tacts,
          {
            ...basicTacts,
            length: cycleTime - stop,
          },
        ]);
      }
    });

    return {
      dirNum: procIndex + 1,
      tacts: tacts,
      viewName: viewName,
      dirTransportType: procDirection.dirType,
    };
  });

  return res;
};

export const getColorsSet = (type: any, length = 1) => {
  switch (type) {
    case GREEN:
      return getGreenColorLine(length);
    case GREEN_FLASH:
      return getFlashGreenColorLine(length);
    case YELLOW:
      return getFlashYellowColorLine(length);
    case YELLOW_AND_RED:
      return getYellowRedColorLine(length);
    case RED:
      return getRedColorLine(length);
    case OTHER:
      return getDefaultColorLine(length);
    default:
      return getDefaultColorLine(length);
  }
};

export const getColoredItems = (tacts: ITacts[] = []) => {
  const res = tacts.map(({ type, length }) => getColorsSet(type, length));

  return res.flat();
};

export const getPhasesMarks = (
  phases: NonNullable<IProcessedDataInitial['tlTacts']>
) => {
  let stopCount = 0;
  const timePoints: ITimePoints[] = [];

  phases.forEach((ph) => {
    const endPoint = stopCount + ph.time;

    timePoints.push({
      ...{
        start: stopCount,
        stop: endPoint,
        phase: Math.trunc((ph.tact + 1) / 2),
        length: endPoint - stopCount,
      },
      ...ph,
    });
    stopCount += ph.time;
  });

  return { timePoints, stopCount };
};

const getCircle = (data: IProcessedDataInitial) => {
  return data.cycleTime && data.cycleTime >= 0 && data.cycleTime <= 2000
    ? data.cycleTime
    : 1;
};

const formatTacts = (declaredTacts: ICsdData['tacts']) => {
  if (!declaredTacts) return null;

  return declaredTacts.map((el, i, self) => {
    if (self[0]?.tact === 0) {
      return { ...el, tact: el?.tact + 1 };
    }

    return el;
  });
};

export const createCsdGraphData = (
  data: IProcessedDataInitial,
  isStatic: boolean
): ICsdData => {
  const correctCircle = getCircle(data);

  if (!isStatic) {
    const correctCircleSec = getCircleSec(
      data.lsec,
      data.cycleTime,
      data.timeRcv
    );

    return {
      lsec: data.lsec,
      circle: correctCircle,
      circleSec: correctCircleSec,
      shift: correctCircle - correctCircleSec,
      tacts: formatTacts(data.tlTacts ?? []),
      directions: makeDirections(data.tlSignalProgram2, data.cycleTime),
    };
  }

  return {
    circle: correctCircle,
    tacts: formatTacts(data.tlTacts),
    directions: makeDirections(data.tlSignalProgram2, data.cycleTime),
  };
};
