/* eslint-disable camelcase */
import { z } from 'zod';

import {
  CONDITION_ROADWAY_CODES,
  PRECIPITATION_CODES,
} from '../../../constants/meteo/meteo';
import { MapObjectFull } from '../../../ts/enums/enums';
import {
  MessageType,
  getZodMessageCustom,
} from '../../helpers/getZodMessageCustom';
import { NN, NNInt, longitude, latitude } from '../../zod/utils.zod';
import { BasicMapDataSchema, LinkedDeviceIdsSchema } from '../mapObjects.zod';

export const FinalFromProfilesSchema = z.object({
  angle: NNInt,
  offsetX: z.number(),
  offsetY: z.number(),
  size: NNInt,
  color: z.string(),
  distance: NNInt,
  widthRate: z.number(),
  arcHeight: NNInt,
  preDistanceRate: z.number(),
  opacity: z.number(),
});

export const MuecSchema = z.object({
  GAS_CO: NN.nullable(),
  GAS_NO: NN.nullable(),
  GAS_NO2: NN.nullable(),
  GAS_SO2: NN.nullable(),
});

export const MeteoPropertiesSchema = z.object({
  env_temp: z.number().nullable().optional(),
  humidity: NN.nullable().optional(),
  pressure: NN.nullable().optional(),
  dew_point: z.number().nullable().optional(),
  road_grip: z.number().gte(0).lte(1).nullable().optional(),
  road_temp: z.number().nullable().optional(),
  ice_height: NN.nullable().optional(),
  wind_speed: NN.nullable().optional(),
  aia_percent: NN.nullable().optional(),
  ice_percent: NN.nullable().optional(),
  snow_height: NN.nullable().optional(),
  water_height: NN.nullable().optional(),
  wind_direction: NN.gte(0).lte(360).nullable().optional(),
  aia_freeze_point: z.number().nullable().optional(),
  precipitation_intensity: NN.nullable().optional(),
});

const MeteoMainInfoSchema = z.object({
  timestamp: NN.nullable().optional(),
  timestamp_utc: NN.nullable().optional(),
  device_uid: z.string(),
  road_state: NNInt.refine(
    (val) => CONDITION_ROADWAY_CODES.includes(val),
    getZodMessageCustom({
      nameParent: 'data',
      nameValue: 'road_state',
      rangeArr: CONDITION_ROADWAY_CODES,
      messageType: MessageType.OnArray,
    })
  )
    .nullable()
    .optional(),
  precipitation_type: NNInt.refine(
    (val) => PRECIPITATION_CODES.includes(val),
    getZodMessageCustom({
      nameParent: 'data',
      nameValue: 'precipitation_type',
      rangeArr: PRECIPITATION_CODES,
      messageType: MessageType.OnArray,
    })
  )
    .nullable()
    .optional(),
  muec_add: MuecSchema.optional(),
});

export const MeteoDataSchema = MeteoMainInfoSchema.merge(MeteoPropertiesSchema);

export const MeteoFinalFromProfilesSchema = z.object({
  id: NNInt,
  caption: z.string(),
  type: z.literal(MapObjectFull.meteo),
  offsetX: z.number(),
  offsetY: z.number(),
  size: NNInt,
  longitude,
  latitude,
});

export const MeteoDaiJdt83Schema = MeteoFinalFromProfilesSchema.extend({});

export const MeteoSchema = BasicMapDataSchema.extend({
  data: MeteoDataSchema,
  regionId: z.number(),
  finalFromProfiles: MeteoFinalFromProfilesSchema.nullable(),
  linkedDevices: LinkedDeviceIdsSchema,
  dai_jdt83: MeteoDaiJdt83Schema.nullable(),
});
