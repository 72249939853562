import React, { FC } from 'react';

import styles from './TableWrapper.module.scss';

interface ITableWrapper {
  children: React.ReactNode;
}

const TableWrapper: FC<ITableWrapper> = ({ children }) => {
  return <div className={styles.wrapper}>{children}</div>;
};

export default TableWrapper;
