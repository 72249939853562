import { Button } from 'antd';
import { Dayjs } from 'dayjs';
import { FC, useEffect, useState } from 'react';

import { DATE_FORMAT_TO_SHOW, SECOND } from '../../../constants/constants';

import styles from './NotifyTimeDescription.module.scss';

interface NotifyTimeDescriptionProps {
  time: string;
  serverTime: Dayjs;
}

const NotifyTimeDescription: FC<NotifyTimeDescriptionProps> = ({
  time,
  serverTime,
}) => {
  const [timeServerDJ, setTimeServerDJ] = useState<Dayjs>(serverTime);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeServerDJ((prev) => prev.add(1, 's'));
    }, SECOND);

    return () => {
      clearInterval(intervalId);
    };
  }, [serverTime]);

  const onUpdatePage = () => window.location.reload();

  const timeStr = timeServerDJ.format(DATE_FORMAT_TO_SHOW);

  return (
    <div className={styles.container}>
      <h4 className={styles.title}>
        Некорректно настроено время на устройстве
      </h4>
      <p>
        {time} (<strong>могут быть сбои в работе</strong>)
      </p>
      <p>Серверное время: {timeStr}</p>
      <p>После изменения времени перезагрузите страницу</p>
      <Button onClick={onUpdatePage} type="primary">
        Перезагрузить
      </Button>
    </div>
  );
};

export default NotifyTimeDescription;
