import { observer } from 'mobx-react-lite';
import { fromLonLat } from 'ol/proj';
import { FC } from 'react';
import { FiMapPin } from 'react-icons/fi';

import rootStore from '../../../../stores/rootStore/rootStore';
import handleCenterZoom from '../../../Map/helpers/zoomHandlers/handleCenterZoom';
import ButtonOnlyIcon from '../../../ui-kit/ButtonOnlyIcon/ButtonOnlyIcon';
import { PointUdsInfoProps } from '../../SystemsInfo.model';

const BTN_STYLE = {
  width: '25px',
  height: '15px',
  marginBottom: '-1px',
};

const PointUdsName: FC<PointUdsInfoProps> = ({ deviceInfo }) => {
  const { pointsUds } = rootStore.mapDataStore;

  const { pointUdsName, pointUdsUid } = deviceInfo;

  const point = pointsUds.find(
    ({ basicProfileInfo }) => basicProfileInfo.point_uds_uid === pointUdsUid
  );

  if (!point) {
    return <>{`${pointUdsName} - точка не существует!`}</>;
  }

  const pointCenter = point.basicProfileInfo.centerPoint;

  const toPointCenter = () => handleCenterZoom(fromLonLat(pointCenter));

  return (
    <>
      {pointUdsName}

      <ButtonOnlyIcon
        onClick={toPointCenter}
        popoverProps={{ placement: 'top' }}
        tip={'Перейти к точке на карте'}
        style={BTN_STYLE}
      >
        <FiMapPin />
      </ButtonOnlyIcon>
    </>
  );
};

export default observer(PointUdsName);
