import { uniqueId } from 'lodash';
import { FC } from 'react';

import { FilterData, FilterNestedItem } from '../../../../models/chart.model';
import FilterItem, { FilterItemData } from '../FilterItem/FilterItem';

interface NestedFilterProps {
  nestedValues: U<FilterNestedItem[]>;
  isLastParent: boolean;
  parentValue: FilterData;
  defaultFilterProps: FilterItemData;
}

const NestedFilter: FC<NestedFilterProps> = ({
  nestedValues,
  isLastParent,
  parentValue,
  defaultFilterProps,
}) => {
  if (!nestedValues) return null;

  return (
    <>
      {nestedValues?.map((el, index, arr) => {
        const isLastNested = index === arr.length - 1 && isLastParent;
        const name = `${parentValue.name}: ${el.name}`;

        if (!arr.length) return null;

        return (
          <FilterItem
            key={uniqueId('nested_')}
            {...defaultFilterProps}
            {...el}
            filterType={el.filterType}
            name={name}
            isLast={isLastNested}
            parentType={parentValue.type}
          />
        );
      })}
    </>
  );
};

export default NestedFilter;
