import { Tag } from 'antd';
import { FC, useState } from 'react';

import parseTLMode from '../../../../../../../../../helpers/parseTLMode';
import { getTLModeName } from '../../../../../../../../../helpers/statusHelpers';
import { TlMode } from '../../../../../../../../../ts/models/tl.model';
import { IDataList } from '../../../../../../../../DetailedStatistics/Monitoring/model/monitoring.model';
import { ShowBtnsDevices } from '../../../../../../../../DetailedStatistics/Monitoring/SystemMonitoring/DisplayBtns/constants/constants';
import DisplayBtns from '../../../../../../../../DetailedStatistics/Monitoring/SystemMonitoring/DisplayBtns/DisplayBtns';

import { TLS_DISPLAY_TYPE } from './constants/devicesDisplayTypes';

import styles from './BodyConfirm.module.scss';

interface BodyConfirmProps {
  mode: TlMode;
  addressLoading: boolean;
  dataList: IDataList[];
}

const BodyConfirm: FC<BodyConfirmProps> = ({
  mode,
  addressLoading,
  dataList,
}) => {
  const [displayType, setDisplayType] = useState<ShowBtnsDevices>(
    ShowBtnsDevices.List
  );

  const modeLabel = getTLModeName(mode);
  const color = parseTLMode(mode).color;

  const DisplayItem = TLS_DISPLAY_TYPE[displayType];

  return (
    <div className={styles.container}>
      <DisplayBtns displayType={displayType} setDisplayType={setDisplayType} />
      <div className={styles.row}>
        <h4>Применяемый режим:</h4>
        <Tag color={color}>{modeLabel}</Tag>
      </div>
      <h4>Список СО:</h4>
      <div className={styles.listContainer}>
        <DisplayItem dataList={dataList} addressLoading={addressLoading} />
      </div>
    </div>
  );
};

export default BodyConfirm;
