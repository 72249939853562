import { TCommands } from '../../../../components/TrafficLightDetailed/TlManagement/model/postCommands.model';
import { VERSION } from '../../../../constants/versions';
import { TableData } from '../../../../ts/models/table.model';
import { Path } from '../../../api.model';
import { getAppPathHeaders } from '../../../helpers/getAppPathHeaders';
import http from '../../../http';
import tryCatchWrapper from '../../../tryCatchWrapper';

import { PlannedCommandsSchema } from './plannedCommands.zod';

type PlannedCommands = Omit<TableData, 'dataRows'> & {
  dataRows: TCommands[];
};

const OBJECTS = VERSION + '/objects';
const TL = OBJECTS + '/tl/';

interface PlannedCommandsProps {
  id: number;
  selectedDate: string[];
  appPath: Path;
  isBlockedNotify?: boolean;
  isNeedValidate?: boolean;
}

const plannedCommandsApi = {
  getPlannedCommands: ({
    id,
    selectedDate,
    appPath,
    isBlockedNotify = false,
    isNeedValidate = true,
  }: PlannedCommandsProps) =>
    tryCatchWrapper(
      async () => {
        const plannedCommands = await http.get<PlannedCommands>(
          `${TL + id}/command/planned`,
          {
            headers: getAppPathHeaders(appPath),
            params: {
              dateFrom: selectedDate[0],
              dateTo: selectedDate[1],
            },
          }
        );

        const parserResult = PlannedCommandsSchema.safeParse(
          plannedCommands.data
        );

        if (isNeedValidate && !parserResult.success) {
          console.error('Error of planned commands', parserResult);

          return;
        }

        return plannedCommands.data;
      },
      {
        isBlockedNotify,
        errorMessage: 'PLANNED_CMD_ERROR',
        notifyErrorDsc: '',
      }
    ),
};

export default plannedCommandsApi;
