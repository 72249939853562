import { Card } from 'antd';
import React from 'react';

import { TLPhaseCodes } from '../../../ts/enums/tl';
import translateCode from '../../Constructor/DetailedConstructor/ConstructorPhaseImages/helpers/translateCode';
import ImageGallery from '../../ui-kit/ImageGallery/ImageGallery';
import Img from '../../ui-kit/Img/Img';

import { IPhasesData } from './helpers/getImgPhases';

import styles from './TlPhaseSchemeGrid.module.scss';

interface TlPhaseSchemeGridProps {
  id: number;
  phasesData: IPhasesData[];
  cashedIMGs: Record<string, unknown>;
}

const TlPhaseSchemeGrid: React.FC<TlPhaseSchemeGridProps> = (
  props: TlPhaseSchemeGridProps
) => {
  const { id, phasesData }: TlPhaseSchemeGridProps = props;

  if (!phasesData) {
    return (
      <div className={styles.containerNotData}>
        <Card className={styles.mainGraphCardNotData}>
          <h3>Нет данных</h3>
        </Card>
      </div>
    );
  }

  return (
    <ImageGallery>
      <div className={styles.container}>
        {phasesData &&
          phasesData.map(({ phaseID, phaseIMG, main, code }, i) => {
            const { label } = translateCode(
              code ?? TLPhaseCodes.Undefined,
              Number(phaseID)
            );

            return (
              main !== 0 && (
                <Card className={styles.phaseScheme} key={`key_${i}`}>
                  <h3>{label}</h3>
                  <Img
                    src={phaseIMG}
                    alt={`tl_${id}_${label}`}
                    className={styles.containerImg}
                    classNameImg={styles.img}
                    isShowIcons
                  />
                </Card>
              )
            );
          })}
      </div>
    </ImageGallery>
  );
};

export default TlPhaseSchemeGrid;
