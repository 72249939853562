import { point as turfPoint } from '@turf/helpers';
import { toMercator, toWgs84 } from '@turf/turf';

import { DEFAULT_CENTER } from '../../components/Scene/constants';
import { TMap } from '../../stores/mapStore/mapStore.model';

import { PI_2_DEG, SCENE_DEFAULT_TILE_SIZE } from './constants';

export const lon2tile = (lon: number, zoom: number) =>
  Math.floor(((lon + PI_2_DEG) / (2 * PI_2_DEG)) * Math.pow(2, zoom));

export const lat2tile = (lat: number, zoom: number) => {
  const latHAtn = Math.tan((lat * Math.PI) / PI_2_DEG);
  const latHCos = Math.cos((lat * Math.PI) / PI_2_DEG);
  const zoom2n = Math.pow(2, zoom);

  const tileNumber =
    ((1 - Math.log(latHAtn + 1 / latHCos) / Math.PI) / 2) * zoom2n;

  return Math.floor(tileNumber);
};

export const rad2deg = (rad: number) => (rad * 180) / Math.PI;

export const getLonLat = (x: number, y: number, zoom: number) => {
  const n = Math.pow(2, zoom);

  const lon = (x / n) * 2 * PI_2_DEG - PI_2_DEG;
  const lat = rad2deg(Math.atan(Math.sinh(Math.PI * (1 - (2 * y) / n))));

  return { lon, lat };
};

export const getSceneBBox = (
  lon: number,
  lat: number,
  zoom: number,
  width: number,
  height: number
) => {
  const tile_size = SCENE_DEFAULT_TILE_SIZE;

  const x = lon2tile(lon, zoom);
  const y = lat2tile(lat, zoom);

  const x1 = (x * tile_size - width / 2) / tile_size;
  const y1 = (y * tile_size - height / 2) / tile_size;
  const x2 = (x * tile_size + width / 2) / tile_size;
  const y2 = (y * tile_size + height / 2) / tile_size;

  const { lon: lon_s, lat: lat_s } = getLonLat(x1, y1, zoom);
  const { lon: lon_e, lat: lat_e } = getLonLat(x2, y2, zoom);

  const p1 = toMercator(turfPoint([lon_s, lat_s])).geometry.coordinates;
  const p2 = toMercator(turfPoint([lon_e, lat_e])).geometry.coordinates;

  const [x_1, y_1] = p1;
  const [x_2, y_2] = p2;

  const xMin = Math.min(x_1, x_2);
  const yMin = Math.min(y_1, y_2);
  const xMax = Math.max(x_1, x_2);
  const yMax = Math.max(y_1, y_2);

  const dX = (xMax - xMin) / 2;
  const dY = (yMax - yMin) / 2;

  return [xMin - dX, yMin - dY, xMax + dX, yMax + dY];
};

export const getMapCenter = (map?: TMap) => {
  const defaultCenter = DEFAULT_CENTER;

  if (!map) {
    return toWgs84(turfPoint(defaultCenter)).geometry.coordinates;
  }

  const center = map.getView().getCenter() || defaultCenter;

  return toWgs84(turfPoint(center)).geometry.coordinates;
};
