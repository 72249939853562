import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { findById } from '../../../helpers/findBy';
import rootStore from '../../../stores/rootStore/rootStore';
import { MapActions } from '../../../stores/uiStore/uiStore.model';
import { System } from '../../../ts/enums/enums';
import { LaneDirectionKey } from '../../Constructor/TrafficLaneConstructor/TLaneConstructor.model';
import DtIcon from '../DtIcon/DtIcon';
import { TLanesBlockProps } from '../TrafficLanesBlock/TLanesBlock.model';
import TrafficLanesBlock from '../TrafficLanesBlock/TrafficLanesBlock';

const { ToCamera, FromCamera } = LaneDirectionKey;

const TLanesDirections: FC<TLanesBlockProps> = (props) => {
  const { setInfoData, infoData } = rootStore.uiStore;
  const { detectors } = rootStore.mapDataStore;
  const { isTrafficLanes } = rootStore.pointsUdsStore;

  const {
    id,
    linkedCameraId,
    dtIconOffsetX,
    dtIconOffsetY,
    dtIconSize,
    fromCamera,
  } = props.trafficLane;

  const isDtIconVisible = Boolean(
    !linkedCameraId && dtIconOffsetX && dtIconOffsetY
  );

  const handleDtIcon = () => {
    if (id === infoData?.id) return setInfoData(null);

    const dt = findById(detectors, id);

    if (!dt) return;
    const { longitude, latitude } = dt;

    setInfoData(
      {
        system: System.Detectors,
        coordinate: [longitude, latitude],
        id,
      },
      MapActions.NoActions
    );
  };

  return (
    <>
      {isTrafficLanes ? (
        <>
          <TrafficLanesBlock {...props} directionKey={ToCamera} />

          {fromCamera ? (
            <TrafficLanesBlock {...props} directionKey={FromCamera} />
          ) : null}
        </>
      ) : null}

      <DtIcon
        isVisible={isDtIconVisible}
        offsetX={dtIconOffsetX}
        offsetY={dtIconOffsetY}
        size={dtIconSize}
        id={id}
        onClick={handleDtIcon}
      />
    </>
  );
};

export default observer(TLanesDirections);
