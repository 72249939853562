import dayjs, { Dayjs } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isoWeek from 'dayjs/plugin/isoWeek';
import { isNumber, isString, isUndefined } from 'lodash';

import { WEEK_FORMAT } from '../../../../../constants/constants';
import {
  QueryParamItem,
  VALUE_TYPES,
  ValueTypes,
} from '../../../../../ts/models/table.model';
import checkReversedFormat from '../../TableServerSearch/SearchForm/DateFormatPicker/helpers/checkReversedFormat';
import formattedDate from '../../TableServerSearch/SearchForm/DateFormatPicker/helpers/formattedDate';

dayjs.extend(advancedFormat);
dayjs.extend(isoWeek);

const getValueType = (type: ValueTypes, value: unknown) => {
  switch (type) {
    case VALUE_TYPES.number:
      return Number(value);
    case VALUE_TYPES.string:
      return String(value);
    case VALUE_TYPES.numberArray:
      return Array.isArray(value) ? value.map((item) => Number(item)) : [];
    case VALUE_TYPES.stringArray:
      return Array.isArray(value) ? value.map((item) => String(item)) : [];
  }
};

interface PeriodReturn {
  from: N<string>;
  to: N<string>;
}

const formattedPeriod = (
  value: N<[Dayjs, Dayjs] | Dayjs>,
  format: string,
  jvalueType: 'datePickerJson' | 'rangeDatePickerJson'
): N<PeriodReturn> => {
  const isDatePicker = jvalueType === VALUE_TYPES.datePickerJson;

  if (!value) return { from: null, to: null };

  const correctionFormat = format.trim().toUpperCase();
  const correctFormat = formattedDate(correctionFormat);
  const isRange = Array.isArray(value);

  if (isDatePicker && !isRange) {
    const isWeekFormat = checkReversedFormat(correctionFormat, WEEK_FORMAT);

    return {
      from: value.format(isWeekFormat ? WEEK_FORMAT : correctFormat),
      to: null,
    };
  }

  if (!isRange || (isRange && value.length !== 2)) return null;

  const [from, to] = value;

  return {
    from: from.format(correctFormat),
    to: to.format(correctFormat),
  };
};

export const getQueryParamsBody = (
  values: { [key: string]: unknown },
  queryParams: QueryParamItem[],
  isNeedStructure = false
) => {
  const newBody = queryParams.reduce(
    (acc: { [key: string]: unknown }, param) => {
      const value = values[param.jkey];
      const key = param.jkey;

      const defaultValue = param.defaultValue as N<{ dateFormat: string }>;

      const dateFormat =
        defaultValue &&
        Object.hasOwn(defaultValue, 'dateFormat') &&
        defaultValue.dateFormat;

      if (
        param.jvalueType === VALUE_TYPES.datePickerJson &&
        (dayjs.isDayjs(value) || !value) &&
        isString(dateFormat)
      ) {
        const date = formattedPeriod(
          value as N<Dayjs>,
          dateFormat,
          param.jvalueType
        );

        const newDate = date
          ? {
              value: date.from,
              dateFormat,
            }
          : null;

        if (!newDate && !isNeedStructure) return acc;

        acc[key] = newDate;

        return acc;
      }

      if (
        param.jvalueType === VALUE_TYPES.rangeDatePickerJson &&
        (Array.isArray(value) || !value) &&
        isString(dateFormat)
      ) {
        if (Array.isArray(value) && value.some((el) => !dayjs.isDayjs(el)))
          return acc;

        const date = formattedPeriod(
          value as N<[Dayjs, Dayjs]>,
          dateFormat,
          param.jvalueType
        );

        const newDate =
          date && !isUndefined(date?.to)
            ? {
                from: date.from,
                to: date.to,
                dateFormat,
              }
            : null;

        if (!newDate && !isNeedStructure) return acc;

        acc[key] = newDate;

        return acc;
      }

      const isValue = (value || isNumber(value)) && key !== 'et_dt';

      if (!isNeedStructure && !isValue) return acc;

      acc[key] = isValue ? getValueType(param.jvalueType, value) : null;

      return acc;
    },
    {}
  );

  return (Object.keys(newBody).length && newBody) || undefined;
};
