import type { Map } from 'ol';
import Feature from 'ol/Feature';
import type { Point } from 'ol/geom';

import { MultipleClusters } from '../../../../stores/clustersStore/clustersStore.model';
import { SelectedFeatures } from '../../../../stores/scriptsControlStore/scriptsControlStore.model';
import { FeatureDeviceProps, FeaturesTypes } from '../../../../ts/enums/enums';
import { getFeatureOnClusterCoord } from '../../../Map/helpers/getFeatureOnClusterCoord';
import { IFeaturesArr } from '../../../Map/helpers/getFeaturesCluster';

export const getCrosshairFeatures = (
  map: Map,
  features: Feature<Point>[],
  selectedFeatureSomeArray: SelectedFeatures[],
  selectedClusters: MultipleClusters[]
) =>
  features.reduce((acc: IFeaturesArr[], feature) => {
    const featureId = feature.get('id');

    const chosenFeature = selectedFeatureSomeArray.find(
      ({ id }) => id === featureId
    );

    if (chosenFeature) {
      const coordFeatureCluster = getFeatureOnClusterCoord(map, feature);
      const coord = feature.getGeometry()?.getCoordinates();

      const isFeatureOnCluster = selectedClusters.some((el) =>
        el.cluster
          .get(FeaturesTypes.Features)
          ?.some(
            (item: Feature) => item.get(FeatureDeviceProps.Id) === featureId
          )
      );

      const correctCoordinates = isFeatureOnCluster
        ? coordFeatureCluster
        : coord;

      acc.push({
        feature,
        coord: correctCoordinates,
        isSelectFeature: isFeatureOnCluster && !!coordFeatureCluster,
      });
    }

    return acc;
  }, []);
