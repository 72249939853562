import { InputNumber } from 'antd';
import { uniqueId } from 'lodash';
import { FC } from 'react';

import AccordionSimple from '../../../../../ui-kit/AccordionSimple/AccordionSimple';
import { CollapseProps } from '../../constants/constants';

import styles from './ModelCollapse.module.scss';

const ModelCollapse: FC<CollapseProps> = ({
  title,
  intensity,
  speed,
  vehicles,
  turn,
}) => {
  return (
    <AccordionSimple title={title} isClosed={false}>
      <div className={styles.card}>
        <div className={styles.row}>
          <div className={styles.box}>
            <p>Интенсивность:</p>
            <InputNumber
              min={0}
              max={5000}
              defaultValue={intensity}
              className={styles.item}
              placeholder="Интенсивность, ТС/ч"
            />
          </div>
          <div className={styles.box}>
            <p>Средняя скорость:</p>
            <InputNumber
              min={0}
              max={200}
              defaultValue={speed}
              className={styles.item}
              placeholder="Средняя скорость, км/ч"
            />
          </div>
        </div>
        <div className={styles.boxBlock}>
          <p>Состав транспортного потока, %:</p>
          <div className={styles.rowBlock}>
            {vehicles.map((el) => (
              <div key={el.type}>
                <span>{el.icon}</span>
                <InputNumber
                  min={0}
                  max={100}
                  defaultValue={el.value}
                  className={styles.block}
                  placeholder="%"
                />
              </div>
            ))}
          </div>
        </div>
        {turn.length ? (
          <div className={styles.boxBlock}>
            <p>% ТС на поворот/разворот:</p>
            {turn.map((el) => (
              <div className={styles.rowContainer} key={uniqueId('turn_')}>
                <p>{el.label}:</p>
                <InputNumber min={0} max={100} defaultValue={el.value} />
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </AccordionSimple>
  );
};

export default ModelCollapse;
