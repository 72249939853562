import { FC, useMemo } from 'react';

import Export from '../../../Export/Export';
import { DISABLED_BTN_TEXT } from '../../CandleChart/constants/constants';
import { DataValue } from '../../models/chart.model';

interface IExport {
  data: DataValue[];
  title: string;
  isShow?: boolean;
}

const ExportButton: FC<IExport> = ({ data, title, isShow = true }) => {
  const exportData = useMemo(() => {
    return data.map(({ index, time, ...rest }) => ({
      'Дата и время': time,
      ...rest,
    }));
  }, [data]);

  const isDisabled = !data.length;

  const basicPopoverContent = !isDisabled
    ? 'Экспортировать данные'
    : 'Недостаточно данных для экспорта';

  const popoverContent = isShow ? basicPopoverContent : DISABLED_BTN_TEXT;

  return (
    <Export
      data={exportData}
      title={title}
      tip={popoverContent}
      isDisabled={isDisabled || !isShow}
      popconfirmProps={{
        placement: 'top',
      }}
    />
  );
};

export default ExportButton;
