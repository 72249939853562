import DOMPurify from 'dompurify';

const MAX_FILE_NAME = 30;

export const sanitizeText = (title: string) => {
  return DOMPurify.sanitize(title);
};

export const sanitizeAndCrop = (title: string) => {
  const value = sanitizeText(title);

  if (value && value.length >= MAX_FILE_NAME) {
    return value.substring(0, MAX_FILE_NAME);
  }

  return value;
};
