import { debounce } from 'lodash';

import { ZOOM } from '../../../../constants/mapConstants';
import rootStore from '../../../../stores/rootStore/rootStore';
import { handleZoom as setZoom } from '../../../Map/helpers/zoomHandlers/handleZoom';

const handleBtnZoom = debounce(
  (step: number) => {
    const { currentZoom } = rootStore.mapDataStore;
    const { regionData } = rootStore.uiStore;

    const disabledMinZoom = regionData?.initialZoom ?? ZOOM.MIN;
    const newZoom = currentZoom + step;

    if (newZoom <= ZOOM.MAX && newZoom >= disabledMinZoom) {
      setZoom(newZoom);
    }
  },
  ZOOM.DURATION,
  { leading: true, maxWait: ZOOM.DURATION }
);

export default handleBtnZoom;
