import { observer } from 'mobx-react-lite';
import { FC, useRef, useEffect } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import { ICsdData, ITimePoints } from '../../../ts/models/signalPrograms.model';
import Directions from '../Directions/Directions';
import useResize from '../hooks/useResize';
import Phases from '../Phases/Phases';
import Tacts from '../Tacts/Tacts';
import TimePointer from '../TimePointer/TimePointer';

import styles from './Program.module.scss';

export interface IProgram {
  step: number;
  csdData: ICsdData;
  phasesSet: ITimePoints[];
  isStatic: boolean;
  setWidth: React.Dispatch<React.SetStateAction<number>>;
}

const Program: FC<IProgram> = ({
  step,
  csdData,
  phasesSet,
  isStatic,
  setWidth,
}) => {
  const { isPanel } = rootStore.uiStore;
  const wrapRef = useRef<HTMLDivElement>(null);

  const { width, height } = useResize({ wrapRef, isPanel });

  useEffect(() => {
    setWidth(width);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height]);

  return (
    <div className={styles.programWrapper} ref={wrapRef}>
      {!isStatic && <TimePointer step={step} height={height} />}
      <Tacts
        width={width}
        height={height}
        phasesSet={phasesSet}
        csdData={csdData}
      />
      <Directions width={width} csdData={csdData} />
      <Phases phasesSet={phasesSet} width={width} len={csdData.circle} />
    </div>
  );
};

export default observer(Program);
