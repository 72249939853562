import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';

import { findById } from '../../../helpers/findBy';
import rootStore from '../../../stores/rootStore/rootStore';
import { MapActions } from '../../../stores/uiStore/uiStore.model';
import { System } from '../../../ts/enums/enums';
import { MeteoDaiJdt83 } from '../../../ts/models/MapObjects/meteo.model';
import MeteoIcon from '../MeteoIcon/MeteoIcon';

interface MeteosProps {
  mtIds: N<number[]>;
}

const Meteos: FC<MeteosProps> = ({ mtIds }) => {
  const { markers, setInfoData, infoData } = rootStore.uiStore;
  const { meteo } = rootStore.mapDataStore;

  const meteos = useMemo(
    () =>
      mtIds?.reduce((res: MeteoDaiJdt83[], dtId) => {
        const mt = findById(meteo, dtId);
        const final = mt?.dai_jdt83;

        final && res.push(final);

        return res;
      }, []),
    [mtIds, meteo]
  );

  if (!markers.isMeteo || !mtIds) return null;

  const handleMeteo = (id: number, lon: number, lat: number) =>
    setInfoData(
      infoData?.id !== id
        ? {
            system: System.Meteo,
            coordinate: [lon, lat],
            id,
          }
        : null,
      MapActions.NoActions
    );

  return (
    <>
      {meteos?.map((meteo) => {
        const { id, longitude, latitude } = meteo;

        return (
          <MeteoIcon
            {...meteo}
            key={id}
            onClick={() => handleMeteo(id, longitude, latitude)}
          />
        );
      })}
    </>
  );
};

export default observer(Meteos);
