import { FC, ReactNode } from 'react';

import styles from './CamerasDetailedWrapper.module.scss';

interface CamerasWrapperProps {
  id: number;
  caption: U<string>;
  children: ReactNode;
}

const CamerasDetailedWrapper: FC<CamerasWrapperProps> = ({
  id,
  caption,
  children,
}) => (
  <div key={id} className={styles.container}>
    <h3>{`Камера ${id} ${caption}:`}</h3>
    {children}
  </div>
);

export default CamerasDetailedWrapper;
