/* eslint-disable camelcase */
import { z } from 'zod';

import {
  MessageType,
  getZodMessageCustom,
} from '../../../helpers/getZodMessageCustom';
import { Int, NNInt } from '../../../zod/utils.zod';

enum ItsWorkMode {
  NONE = 'NONE',
  SCHEDULE = 'SCHEDULE',
  REQUEST = 'REQUEST',
  RANDOM = 'RANDOM',
  COORDINATION_LEAD = 'COORDINATION_LEAD',
  COORDINATION_FOLLOW = 'COORDINATION_FOLLOW',
  COORDINATION_LEAD_NO_REQUEST = 'COORDINATION_LEAD_NO_REQUEST',
  COORDINATION_FOLLOW_NO_REQUEST = 'COORDINATION_FOLLOW_NO_REQUEST',
}

enum ItsObjectMode {
  OFF = 'OFF',
  BASIC = 'BASIC',
  CRITICAL_ERROR = 'CRITICAL_ERROR',
  MANUAL_CONTROL = 'MANUAL_CONTROL',
  MANUAL_CONTROL_TECH = 'MANUAL_CONTROL_TECH',
  YELLOW = 'YELLOW',
  ALL_RED = 'ALL_RED',
  REMOTE_CONTROL = 'REMOTE_CONTROL',
  WORK_BY_PROGRAM = 'WORK_BY_PROGRAM',
  COORDINATED = 'COORDINATED',
  LOCAL_ADAPTIVE = 'LOCAL_ADAPTIVE',
}

enum ItsCoordRole {
  LEAD = 'LEAD',
  FOLLOW = 'FOLLOW',
}

const isStringWithNumber = (key: string) =>
  z.string().refine(
    (val) => !isNaN(Number(val)),
    getZodMessageCustom({
      nameValue: key,
      nameParent: 'External resource ITSLab',
      messageType: MessageType.IsNaN,
    })
  );

const CoordinationTLSchema = z.object({
  group: z.string(),
  role: z.nativeEnum(ItsCoordRole),
  offset: Int,
});

const DistancesTLSchema = z.object({
  object_to: isStringWithNumber('object_to'),
  distance: NNInt,
});

export const ItsTlsSchema = z.object({
  object_id: isStringWithNumber('object_id'),
  external_id: isStringWithNumber('external_id'),
  current_program: NNInt.nullable(),
  current_program_last_phase: NNInt.nullable(),
  current_phase: NNInt.nullable(),
  is_active: z.boolean().nullable(),
  is_currently_span: z.boolean().nullable(),
  object_mode: z.nativeEnum(ItsObjectMode).nullable(),
  last_update: NNInt.nullable(),
  work_mode: z.nativeEnum(ItsWorkMode),
  coordination: CoordinationTLSchema.nullable(),
  distances: DistancesTLSchema.array(),
});
