import { isNumber } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  COMMANDS,
  TlManagementKeys,
} from '../../../../../../../constants/commands';
import eventBus from '../../../../../../../eventBus';
import { findById } from '../../../../../../../helpers/findBy';
import rootStore from '../../../../../../../stores/rootStore/rootStore';
import { postCommand } from '../../../../helpers/postCommand';
import { IPostCommandProps } from '../../../../model/postCommands.model';
import { getCorrectDateText } from '../../../../PhaseHold/helpers/getCorrectDateText';
import { secondsToTime } from '../../../../PhaseHold/helpers/secondsToTime';
import BodyContent from '../../../../PhaseHold/ModalConfirm/BodyContent/BodyContent';
import { ModalConfirmProps } from '../../../../PhaseHold/ModalConfirm/ModalConfirm';
import ModalSignalProgram from '../../../../PlanSelect/ModalSignalProgram/ModalSignalProgram';
import { editSignalProgram } from '../../../helpers/editSignalProgram';
import { UseConfirmPlanProps } from '../../../model/temporaryPlan.model';

const { CoordinatedManagement } = TlManagementKeys;
const { SET_OBJECT_SYNC_PLAN } = COMMANDS;

const PATH =
  '.TrafficLightDetailed.TlManagement.TemporaryPlan.Modal.Confirm.useConfirmPlan';
const CONFIRM_TITLE = `Подтверждаете включение режима координированного управления?`;

type ModalProps = Omit<ModalConfirmProps, 'icon'>;

const useConfirmPlan = ({
  id,
  selectedTime,
  selectedPlan,
  programInfo,
  readyDataPhases,
  clearPlanSelect,
}: UseConfirmPlanProps) => {
  const { activeControlKey } = rootStore.detailedStore;
  const { tls } = rootStore.mapDataStore;

  const [openModal, setOpenModal] = useState(false);
  const [isLoadingRequest, setIsLoadingRequest] = useState<boolean>(false);

  const activeTL = useMemo(() => findById(tls, id), [tls, id]);

  useEffect(() => {
    const unsubscribe = eventBus.clickedControlBtn.subscribe(() => {
      activeControlKey === CoordinatedManagement && setOpenModal(true);
    });

    return () => unsubscribe();
  }, [activeControlKey]);

  const onOkConfirm = useCallback(async () => {
    if (!selectedTime.duration) return;

    setIsLoadingRequest(true);

    const selectedDate = selectedTime.date?.unix();

    const cmdInfo = `План № ${selectedPlan} (запущен${
      !selectedDate
        ? ` на ${secondsToTime(selectedTime.duration)}`
        : ` ${getCorrectDateText(selectedDate, selectedTime.duration)}`
    })`;

    const propsPostCommand: IPostCommandProps = {
      id,
      command: selectedPlan,
      cmdTitle: SET_OBJECT_SYNC_PLAN,
      cmdInfo,
      selectedDate: selectedTime.date,
      phasesValue: readyDataPhases,
      durationTime: selectedTime.duration ?? undefined,
      disabledTime: 0,
    };

    await postCommand(propsPostCommand, PATH);

    setIsLoadingRequest(false);
    setOpenModal(false);
    clearPlanSelect();
  }, [id, clearPlanSelect, selectedPlan, selectedTime, readyDataPhases]);

  const modalConfirmProps: ModalProps = {
    modalTitle: activeTL
      ? `Отправка команды управления для светофорного объекта ${activeTL.deviceInfo.externalDeviceId} ${activeTL.caption}`
      : 'Неизвестный СО',
    openModal: openModal,
    setOpenModal,
    onOkConfirm,
    width: '1400px',
    okButtonProps: { loading: isLoadingRequest },
  };

  const content = [
    `План № ${selectedPlan}`,
    selectedTime.date && selectedTime.duration
      ? `Период: ${getCorrectDateText(
          selectedTime.date.unix(),
          selectedTime.duration
        )}`
      : '',
    `Длительность: ${secondsToTime(selectedTime.duration)}`,
  ];

  const { isPlansLoading, programs, sgnProgramValues } = programInfo;

  const activePlanIndex = isNumber(selectedPlan) && selectedPlan - 1;

  const readySgnProgram = useMemo(() => {
    const csdData =
      isNumber(activePlanIndex) &&
      sgnProgramValues.at(activePlanIndex)?.csdData;

    return readyDataPhases.length && csdData
      ? editSignalProgram(readyDataPhases, csdData)
      : null;
  }, [activePlanIndex, readyDataPhases, sgnProgramValues]);

  const modalBodyProps = {
    content: (
      <BodyContent title={<strong>{CONFIRM_TITLE}</strong>} content={content} />
    ),
    image: isNumber(activePlanIndex) ? (
      <ModalSignalProgram
        programExecTime={programs.at(activePlanIndex)?.execTime ?? null}
        csdData={
          !readyDataPhases.length
            ? sgnProgramValues.at(activePlanIndex)?.csdData
            : readySgnProgram?.newCsdDataPhase
        }
        phasesSet={
          !readyDataPhases.length
            ? sgnProgramValues.at(activePlanIndex)?.phasesSet
            : readySgnProgram?.newPhasesSet
        }
        isError={
          !readyDataPhases.length
            ? !!sgnProgramValues.at(activePlanIndex)?.isError
            : Boolean(readySgnProgram?.isError)
        }
        isLoadingProgram={!isPlansLoading}
        id={id}
      />
    ) : null,
  };

  return { modalConfirmProps, modalBodyProps };
};

export default useConfirmPlan;
