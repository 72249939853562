import { DescriptionsProps } from 'antd/lib';

import SkeletonInput from '../../../../../../../../../../../../DetailedStatistics/Monitoring/SkeletonInput/SceletonInput';

const getDeviceMainDesc = (
  dsc: U<string>,
  addressLoading: boolean,
  address: N<string>
): DescriptionsProps['items'] => {
  const addressValue = address || 'Не найден';

  return [
    {
      key: 'dsc',
      label: 'Описание',
      children: dsc || 'Отсутствует',
      span: 3,
    },
    {
      key: 'address',
      label: 'Адрес',
      children: <SkeletonInput loading={addressLoading} value={addressValue} />,
      span: 3,
    },
  ];
};

export default getDeviceMainDesc;
