import { Menu } from 'antd';
import { MenuProps } from 'antd/lib';
import { cloneDeep } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useMemo } from 'react';

import api from '../../../../api';
import rootStore from '../../../../stores/rootStore/rootStore';
import DefaultChecked from '../../../ui-kit/DefaultChecked/DefaultChecked';

import { getFormattedTreeByDevId } from './helpers/helpersFormattedTree';
import { getAllReportsLabels, getBreadCrumbs } from './helpers/helpersTree';

import styles from './ReportTree.module.scss';

export const APP_PATH_REPORT = 'Panel.Reports';

const MAX_LENGTH = 28;

interface SelectItemProps {
  key: string;
  keyPath: string[];
}

const ReportTree: FC = () => {
  const {
    isReportsPanel,
    activeKey,
    tree,
    openedKeys,
    filteredTreeBySearch,
    isLoadingTree,
    setReportsValue,
    setReportValues,
  } = rootStore.reportsPanelStore;

  const fetchReportTree = useCallback(async () => {
    setReportValues({
      isLoadingTree: true,
      tree: [],
    });

    const treeRes = await api.reports.fetchTree(APP_PATH_REPORT);

    if (!treeRes) return setReportsValue('isLoadingTree', false);

    setReportValues({
      reportsLabels: getAllReportsLabels(cloneDeep(treeRes)),
      tree: treeRes,
      isLoadingTree: false,
    });
  }, [setReportsValue, setReportValues]);

  const preparedReportTree = useMemo(() => {
    return getFormattedTreeByDevId(filteredTreeBySearch, MAX_LENGTH);
  }, [filteredTreeBySearch]);

  useEffect(() => {
    isReportsPanel && fetchReportTree();
  }, [isReportsPanel, fetchReportTree]);

  const onSelectItem = ({ key, keyPath }: SelectItemProps) => {
    const reversedKeyPath = keyPath.reverse();

    const currentKey = Number(key) || null;

    const breadCrumbs = currentKey ? getBreadCrumbs(tree, reversedKeyPath) : [];

    setReportValues({
      activeBreadCrumbs: breadCrumbs,
      activeKey: currentKey,
    });
  };

  const onOpenChange = (keys: string[]) => {
    setReportsValue('openedKeys', keys);
  };

  const menuSystemsProps: MenuProps = {
    items: preparedReportTree,
    mode: 'inline',
    selectedKeys: activeKey ? [activeKey.toString()] : [],
    openKeys: openedKeys,
    className: styles.menuContainer,
    onOpenChange,
    onSelect: onSelectItem,
  };

  return (
    <div className={styles.container}>
      <DefaultChecked
        isLoading={isLoadingTree}
        isEmptyData={!preparedReportTree.length}
        noDataProps={{ textError: 'Нет доступных отчетов' }}
      >
        <Menu {...menuSystemsProps} />
      </DefaultChecked>
    </div>
  );
};

export default observer(ReportTree);
