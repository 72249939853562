import { type CheckboxOptionType, Radio } from 'antd';
import { FC, useState } from 'react';

import api from '../../../../../api';
import { IServerData } from '../../NJSInfo.model';
import InfoBox from '../InfoBox/InfoBox';

import styles from './TableValidationStatus.module.scss';

type Status = IServerData['tableValidationStatus'];

interface TableValidationStatusProps {
  status: Status;
}

const TABLE_VALIDATION_STATUS_TITLE = 'Статус валидации таблиц';
const APP_PATH = '.adminPanel.webserver.info.TableValidationStatus';

const OPTIONS: CheckboxOptionType[] = [
  {
    label: 'Включена',
    value: 'enable',
  },
  {
    label: 'Отладка',
    value: 'debug',
  },
  {
    label: 'Отключена',
    value: 'disable',
  },
];

const TableValidationStatus: FC<TableValidationStatusProps> = ({ status }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = async (value: Status) => {
    setIsLoading(true);

    await api.server.tableValidation.setStatus(value, APP_PATH);

    setIsLoading(false);
  };

  return (
    <InfoBox title={TABLE_VALIDATION_STATUS_TITLE} className={styles.infoBox}>
      <Radio.Group
        disabled={isLoading}
        size="small"
        optionType="button"
        buttonStyle="solid"
        options={OPTIONS}
        value={status}
        onChange={(e) => handleChange(e.target.value)}
      />
    </InfoBox>
  );
};

export default TableValidationStatus;
