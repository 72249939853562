import type {
  Layer as DeckLayer,
  ViewStateChangeParameters,
} from '@deck.gl/core';
import { DeckGL as DeckGLScene } from '@deck.gl/react';
import React, { FC, useEffect, useState } from 'react';

import rootStore from '../../stores/rootStore/rootStore';

import { SCENE_CHANGE_STATE_TIMOUT } from './constants';
import {
  getInitialMapState,
  getMapLayers,
  getRegionViewState,
  getTooltip,
  updateMapLayers,
} from './helpers';
import { ISceneProps, ISceneViewState } from './model';

import styles from './scene.module.scss';

const Scene: FC<ISceneProps> = (props: ISceneProps) => {
  const { uiStore, mapDataStore, gisDataStore } = rootStore;
  const { currentZoom, mapProxy } = mapDataStore;
  const { basemapProxy, layersState, renderers } = gisDataStore;
  const { isRightPanel, regionData } = uiStore;

  const [sceneLayers, setSceneLayers] = useState<DeckLayer[]>(
    props.layers || []
  );

  const [sceneViewState, setSceneViewState] = useState<ISceneViewState>(
    getInitialMapState(props, currentZoom)
  );

  useEffect(() => {
    setSceneViewState({ ...sceneViewState, zoom: props.zoom });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.zoom]);

  useEffect(() => {
    const viewState = getRegionViewState(sceneViewState, regionData);

    viewState &&
      setTimeout(() => {
        setSceneViewState(viewState);
      }, SCENE_CHANGE_STATE_TIMOUT);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionData]);

  useEffect(() => {
    const layers = updateMapLayers(
      layersState,
      sceneViewState,
      basemapProxy,
      mapProxy,
      renderers
    );

    props.layers?.forEach((layer) => {
      // @ts-ignore
      layers.push(layer);
    });

    setSceneLayers(layers);

    setSceneLayers(layers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.layers, props.map, basemapProxy]);

  const appLayers = getMapLayers(mapProxy, layersState);

  appLayers.forEach((layer) => {
    layer?.on('change:visible', (evt) => {
      const layers = updateMapLayers(
        layersState,
        sceneViewState,
        basemapProxy,
        mapProxy,
        renderers
      );

      setSceneLayers(layers);
    });
  });

  const onViewStateChange = (evt: ViewStateChangeParameters) => {
    const { viewState, interactionState } = evt;

    setSceneViewState(viewState);

    if (!interactionState.isDragging) {
      const layers = updateMapLayers(
        layersState,
        viewState,
        basemapProxy,
        mapProxy,
        renderers
      );

      props.layers?.forEach((layer) => {
        // @ts-ignore
        layers.push(layer);
      });

      setSceneLayers(layers);
    }
  };

  return (
    <div
      className={
        !isRightPanel ? styles.sceneContainer : styles.sceneContainerRightPanel
      }
    >
      <DeckGLScene
        initialViewState={sceneViewState}
        controller
        layers={sceneLayers}
        height={'calc(100vh)'}
        getTooltip={props.getTooltip || getTooltip}
        onHover={props.onFeatureHover}
        onClick={props.onClick}
        onViewStateChange={onViewStateChange}
      />
    </div>
  );
};

export default Scene;
