import { observer } from 'mobx-react-lite';

import { CLUSTERS } from '../../../constants/mapConstants';
import rootStore from '../../../stores/rootStore/rootStore';

import ClustersContent from './ClustersContent/ClustersContent';
import MapIconContent from './MapIconContent/MapIconContent';

const TooltipContent = () => {
  const { tooltipData } = rootStore.uiStore;

  if (!tooltipData) return null;

  const { id, system, clustersFeatures } = tooltipData;

  if (system === CLUSTERS)
    return <ClustersContent clustersFeatures={clustersFeatures} />;

  return <MapIconContent id={id} system={system} />;
};

export default observer(TooltipContent);
