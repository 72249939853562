import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { TooltipPlacement } from 'antd/lib/tooltip';

interface IGetBtnsProps {
  handleEdit: (collaboration: any) => void;
  handleDelete: (is: number) => Promise<void>;
  collaboration: any;
  isDisabledDelete?: boolean;
}

export const getDivisionsBtns = ({
  handleEdit,
  handleDelete,
  collaboration,
  isDisabledDelete = false,
}: IGetBtnsProps) => {
  const actionButtons = [
    {
      isDisabled: false,
      icon: <EditOutlined />,
      tooltip: 'Редактировать',
      handler: () => {
        handleEdit(collaboration);
      },
    },
    {
      isDisabled: isDisabledDelete,
      icon: <DeleteOutlined />,
      tooltip: 'Удалить',
      placement: 'right' as TooltipPlacement,
      handler: () => {
        return;
      },
      confirmProps: {
        name: collaboration.name,
        entity: 'подразделение',
        confirm: () => handleDelete(collaboration.id),
      },
    },
  ];

  return actionButtons;
};
