import { Map as OLMap } from 'ol';
import type { Coordinate } from 'ol/coordinate';
import Feature from 'ol/Feature';
import { Point } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import { fromLonLat } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { Icon, Style } from 'ol/style';

import { Z_INDEX_VECTOR_LAYER } from '../../../../apiGIS/constants/map';
import locationIcon from '../../../../assets/icons/map/location.svg';
import { ZOOM } from '../../../../constants/mapConstants';
import { ZoomProps } from '../UiMap';

export const addVectorLayer = (mapObject: OLMap) => {
  const source = new VectorSource();
  const layer = new VectorLayer({
    source,
  });

  layer.setZIndex(Z_INDEX_VECTOR_LAYER);

  layer.setStyle(
    () =>
      new Style({
        image: new Icon({
          src: locationIcon,
          scale: 1,
          anchor: [0.5, 0.8],
        }),
      })
  );
  const feature = new Feature();

  source.addFeature(feature);

  mapObject.addLayer(layer);
};

export const setFeatureCoord = (map: OLMap, selectedCoord: Coordinate) => {
  const layer = map.getAllLayers().find((layer) => {
    if (layer instanceof VectorLayer) return layer;

    return null;
  });

  if (!layer) return;

  const source = layer.getSource() as VectorSource;

  if (!source) return;

  source.getFeatures()[0]?.setGeometry(new Point(fromLonLat(selectedCoord)));
};

export const getZoomProps = (initialZoom: number, zoomProps: U<ZoomProps>) => {
  const zoom = zoomProps?.initZoom ?? initialZoom;
  const minZoom = zoomProps?.minZoom ?? initialZoom;
  const maxZoom = zoomProps?.maxZoom ?? ZOOM.MAX;

  const isCorrectZoom = zoom >= minZoom && zoom <= maxZoom;

  const zoomValue = isCorrectZoom ? zoom : minZoom;

  return { zoom: zoomValue, minZoom, maxZoom };
};

export const getViewProps = (map: OLMap) => {
  const view = map.getView();
  const currZoom = view.getZoom();
  const resolution = view.getResolution();

  return { currZoom, resolution };
};
