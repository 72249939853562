import { uniqueId } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';

import styles from './GISContent.module.scss';

interface IGISContentProps {
  fields: { key: string; value: SN }[];
}

const GISContent: FC<IGISContentProps> = ({ fields }) => {
  const items = fields.map((field) => {
    const value = field.value || 'неизвестно';
    const { key } = field;

    return {
      key,
      value,
    };
  });

  return (
    <>
      <div className={styles.container}>
        {items.map((item) => (
          <div className={styles.fieldsRow} key={uniqueId()}>
            {item.key}: {item.value}
          </div>
        ))}
      </div>
    </>
  );
};

export default observer(GISContent);
