import { cloneDeep, isEqual } from 'lodash';
import { makeAutoObservable } from 'mobx';

import { VideoStreamsProxyList } from '../../api/server/web/web.model';
import { VideoPanelTab } from '../../components/Panel/VideoWallPanel/VideoPanelTabs/constants';
import {
  createVideoList,
  getChangeProfileParams,
} from '../../components/VideoWall/helpers/helpers';
import { PanelType } from '../../ts/enums/enums';
import {
  LayoutGrid,
  ProfileItem,
  VideoListItem,
} from '../../ts/models/videoWall.model';
import RootStore from '../rootStore/rootStore';
import { StoreUtils } from '../storeUtils/storeUtils';

interface SelectProfileParams {
  id: number;
  name: string;
  videoList: VideoListItem[];
  isChangeable: boolean;
  layoutGrid: LayoutGrid;
  isShared: boolean;
}
class VideoWallPanelStore {
  rootStore;
  segment: VideoPanelTab = 'profilesList';
  availableCamerasIds: number[] = [];
  private _videoList: VideoListItem[] = [];
  selectedProfileId: N<number> = null;
  selectedProfileName: N<string> = null;
  selectedProfileIsShared = false;
  private _layoutGrid: LayoutGrid = [2, 2];
  isVideoListEdit = false;
  tempProfileName = '';
  isFullScreen = false;
  savedProfilesList: ProfileItem[] = [];
  isSavedProfilesListsLoading = false;
  isAbilityToChange = false;
  isXeoma = false;
  isUpdateXeoma = false;
  isXeomaMjpeg = false;
  setKeyValue;
  setKeysValues;
  videoStreamsProxyList: VideoStreamsProxyList = [];

  constructor(rootStore: typeof RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
    this.rootStore = rootStore;

    const utils = new StoreUtils(this);

    this.setKeyValue = utils.setKeyValue;
    this.setKeysValues = utils.setKeysValues;
  }

  get videoList() {
    return cloneDeep(this._videoList);
  }

  set videoList(value: VideoListItem[]) {
    this._videoList = cloneDeep(value);
  }

  get layoutGrid() {
    return cloneDeep(this._layoutGrid);
  }

  set layoutGrid(value: LayoutGrid) {
    this._layoutGrid = cloneDeep(value);
  }

  get hasSomeEditChanges(): boolean {
    if (!this.selectedProfileId) return true;

    const { name, jprofile, isShared } = getChangeProfileParams(
      this.savedProfilesList,
      this.selectedProfileId
    );

    const isEqualVideoList = isEqual(this.videoList, jprofile.videoList);

    const isEqualLayoutGrid = isEqual(this.layoutGrid, jprofile.layoutGrid);

    const isEqualProfileName = isEqual(this.tempProfileName, name);

    const isEqualProfileSharedStatus = isEqual(
      this.selectedProfileIsShared,
      isShared
    );

    return !(
      isEqualVideoList &&
      isEqualLayoutGrid &&
      isEqualProfileName &&
      isEqualProfileSharedStatus
    );
  }

  get isVideoPanel() {
    const { panelType } = this.rootStore.uiStore;

    return panelType === PanelType.VideoWall;
  }

  get layoutGridLength() {
    const [width, height] = this.layoutGrid;

    return width * height;
  }

  clearAllData = () => {
    const { setKeysValues } = this;

    setKeysValues({
      videoList: [],
      selectedProfileName: null,
      selectedProfileId: null,
      segment: 'profilesList',
    });
  };

  createNewProfile = () => {
    const { layoutGridLength, setKeysValues } = this;

    const newVideoList = createVideoList(layoutGridLength);

    setKeysValues({
      isVideoListEdit: true,
      selectedProfileId: null,
      selectedProfileName: null,
      videoList: newVideoList,
      segment: 'camerasList',
    });
  };

  selectProfile = (params: SelectProfileParams) => {
    const { id, name, videoList, layoutGrid, isChangeable, isShared } = params;
    const { setKeysValues } = this;

    setKeysValues({
      isVideoListEdit: false,
      videoList: videoList,
      selectedProfileId: id,
      selectedProfileName: name,
      selectedProfileIsShared: isShared,
      isAbilityToChange: isChangeable,
      layoutGrid: layoutGrid,
    });
  };
}

export default VideoWallPanelStore;
