import { findById } from '../../../../helpers/findBy';
import rootStore from '../../../../stores/rootStore/rootStore';
import { TLFinalArrayFromProfiles } from '../../../../ts/models/tl.model';

type DtIds = Nullish<number[]>;

export const getLinkedLanes = (dtIds: DtIds) => {
  const { detectors } = rootStore.mapDataStore;

  return (
    dtIds?.reduce((res: NonNullable<TLFinalArrayFromProfiles>, id) => {
      const dt = findById(detectors, id);

      dt?.dai_jdt83 && res.push(dt.dai_jdt83);

      return res;
    }, []) ?? null
  );
};

export const getFilteredLinkedLanes = (dtIds: DtIds) => {
  const tLanes = getLinkedLanes(dtIds);

  if (!tLanes) return null;

  const filteredLanes = tLanes.filter((lane) =>
    dtIds?.some((id) => id === lane.id)
  );

  return filteredLanes.map((lane) => ({
    ...lane,
    isEnabled: false,
  }));
};
