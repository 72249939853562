import rootStore from '../../../../../../../stores/rootStore/rootStore';
import { FilteredTypes } from '../../../../../../TrafficLightDetailed/TlDetectors/constants/constants';
import {
  DISABLED_BTN_TEXT,
  DISABLED_NORMATIVE_TEXT,
} from '../../../../CandleChart/constants/constants';

import { WARNING_TEXT } from './helpers';

interface DisabledInfoProps {
  filterType: FilteredTypes;
  isDisabledItems: boolean;
  isActive: boolean;
  disabled: U<boolean>;
  name: string;
}

const getDisabledInfo = ({
  filterType,
  isActive,
  isDisabledItems,
  disabled = false,
  name,
}: DisabledInfoProps) => {
  const { disabledChartTypes } = rootStore.detailedStore;

  const isDisabledChartType = disabledChartTypes[filterType];

  const isLaneFilter = filterType === FilteredTypes.Lanes;

  const isDisabledDir =
    (isDisabledItems && !isLaneFilter && isActive) ||
    isDisabledChartType ||
    disabled;
  const content = isDisabledDir ? `${name} (${WARNING_TEXT})` : name;

  const tooltipPrev = isDisabledChartType ? DISABLED_BTN_TEXT : content;

  const tooltip = disabled ? DISABLED_NORMATIVE_TEXT : tooltipPrev;

  return { tooltip, isDisabledDir };
};

export default getDisabledInfo;
