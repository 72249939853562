import { Input } from 'antd';
import React, { useState } from 'react';
import { TiLocation } from 'react-icons/ti';

import { geomToLatLon } from '../../../../apiGIS/geometry/helpers';
import { geocoderSearch } from '../../../../apiGIS/helpers/search';
import {
  getMapPoint,
  IDrawResult,
} from '../../../../apiGIS/tasks/interactions';
import { TMap } from '../../../../stores/mapStore/mapStore.model';
import ButtonOnlyIcon from '../../../ui-kit/ButtonOnlyIcon/ButtonOnlyIcon';
import {
  SEARCH_PLACEHOLDER_CONTENT,
  START_POINT_ICON_SIZE,
  START_POINT_STYLE,
} from '../constants';
import { IRoutePoint } from '../route.model';

import styles from '../RouteButton.module.scss';

const { Search } = Input;

export const RoutePoint = (props: {
  map: TMap;
  value: IRoutePoint;
  onChange: (evt: IRoutePoint) => void;
  onInput: (evt: any) => void;
}) => {
  const [selection, setSelection] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const getPoint = async () => {
    setLoading(true);

    setSelection(true);

    const action: IDrawResult = await getMapPoint(props.map);

    setLoading(false);

    const { feature } = action;

    if (!feature) {
      return;
    }

    const lonLat = geomToLatLon(feature.getGeometry());

    if (!lonLat) {
      setSelection(false);

      return;
    }

    const [x, y] = lonLat;

    const searchItems = await geocoderSearch(props.map, `${x},${y}`, undefined);

    const title =
      searchItems && searchItems.length > 0
        ? searchItems[0].title
        : `${x},${y}`;

    const item: IRoutePoint = {
      feature,
      title,
      selected: true,
      x,
      y,
    };

    props.onChange(item);

    setSelection(false);
  };

  const onSearch = async (text: string) => {
    setLoading(true);

    const searchItems = await geocoderSearch(props.map, text, undefined);

    if (searchItems && searchItems.length > 0) {
      const [searchItem] = searchItems;

      const { feature, title } = searchItem;

      // @ts-ignore
      const coordinates = feature
        ? geomToLatLon(feature.getGeometry())
        : undefined;

      if (!coordinates) {
        return;
      }

      const [x, y] = coordinates;

      const item: IRoutePoint = {
        feature,
        title,
        selected: true,
        x,
        y,
      };

      props.onChange(item);

      setSelection(false);
    }

    setLoading(false);
  };

  const onInput = (evt: any) => {
    const text = evt.target.value;

    props.onInput(text);
  };

  return (
    <Search
      value={props.value.title}
      placeholder={SEARCH_PLACEHOLDER_CONTENT}
      className={styles.routeField}
      onSearch={onSearch}
      onChange={onInput}
      allowClear
      loading={loading}
      addonBefore={
        <div className={styles.filler}>
          <ButtonOnlyIcon onClick={getPoint} style={START_POINT_STYLE}>
            <TiLocation
              size={START_POINT_ICON_SIZE}
              className={selection ? styles.selectionActive : ''}
            />
          </ButtonOnlyIcon>
        </div>
      }
    />
  );
};
