import { Card } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { VscLayers } from 'react-icons/vsc';

import { showFeatures } from '../../../apiGIS/layers/utils/flashFeatures';
import { MAP_BUTTONS_NAMES } from '../../../constants/uiComponentConstants';
import rootStore from '../../../stores/rootStore/rootStore';
import { CategoryLayerGIS } from '../../../ts/enums/enums';
import { MapButtonsCode } from '../../../ts/enums/userData';
import ButtonIco from '../../ui-kit/ButtonIco/ButtonIco';
import { PopoverProps } from '../../ui-kit/Popover/Popover';
import { COMPONENT_VISIBILITY_TIMEOUT } from '../constants';

import LayersTree from './components/LayersTree';
import LayerTreeItem from './components/LayerTreeItem';
import LayerSettings from './components/Settings/LayerSettings';
import LayerTable from './components/Table/LayerTable';
import { LOCAL_STYLES, MODES } from './constants';
import { ILayerDataNode } from './layers.model';

import styles from './LayersButton.module.scss';

export const PLACEMENT_MAP_BTNS = 'bottom';

const LayersButton: FC = () => {
  const { uiStore, mapDataStore } = rootStore;
  const { mapProxy, viewProxyResolution } = mapDataStore;

  const { mapButtonsTipsDelay, activeMapButton, setKeyValue, isLayersBtnGis } =
    uiStore;

  const { layersState, hasIntegrationLayersGIS, layersVisibilityProxy } =
    rootStore.gisDataStore;
  const { hasAccess } = rootStore.userDataStore;
  const [closeTimeout, setCloseTimeout] = useState<U<NodeJS.Timeout>>();
  const [treeItems, setTreeItems] = useState([] as ILayerDataNode[]);
  const [rerender, setRerender] = useState(Math.random());

  const [mode, setMode] = useState(MODES.TREE);

  const [selectedNode, setSelectedNode] = useState<ILayerDataNode | undefined>(
    undefined
  );

  const getTreeItems = () => {
    const items: ILayerDataNode[] = [];

    layersState.forEach((layer) => {
      let hasGroup = false;

      const title = LayerTreeItem({
        item: layer,
        key: layer.id,
        title: layer.alias,
        map: mapProxy,
      });

      if (layer.category?.category !== CategoryLayerGIS.Common) {
        return;
      }

      items.forEach((group) => {
        if (layer.group && group.key === layer.group) {
          hasGroup = true;

          if (group.children) {
            group.children.push({
              key: layer.id,
              title,
              item: layer,
              map: mapProxy,
            });
          } else {
            group.children = [
              { key: layer.id, title, item: layer, map: mapProxy },
            ];
          }
        }
      });

      if (!hasGroup) {
        if (layer.group) {
          items.push({
            key: layer.group,
            title: layer.group,
            children: [{ key: layer.id, title, item: layer, map: mapProxy }],
            item: layer,
            isGroup: true,
            map: mapProxy,
          });
        } else {
          items.push({ key: layer.id, title, item: layer, map: mapProxy });
        }
      }
    });

    return items;
  };

  useEffect(() => {
    setTreeItems(getTreeItems());

    // eslint-disable-next-line
  }, [mapProxy, rerender]);

  useEffect(() => {
    setRerender(Math.random());
  }, [viewProxyResolution, layersVisibilityProxy]);

  const [isLayers, setIsLayers] = useState<boolean>(false);

  const handleIsLayers = () => {
    setRerender(Math.random());

    setIsLayers(!isLayers);
  };

  let tooltipText = `${!isLayers ? 'Показать' : 'Скрыть'} список слоев`;
  const layersIsEmpty = layersState.length === 0;

  const tooltipEmptyLayers = `Для выбранного региона ${
    hasIntegrationLayersGIS
      ? 'слои временно недоступны'
      : 'интеграция с ГИС недоступна'
  }`;

  tooltipText = layersIsEmpty ? tooltipEmptyLayers : tooltipText;

  const popOverProps: Omit<PopoverProps, 'children'> = {
    tipsDelay: mapButtonsTipsDelay,
    content: tooltipText,
    placement: PLACEMENT_MAP_BTNS,
  } as const;

  useEffect(() => {
    if (isLayers) {
      setKeyValue('activeMapButton', MAP_BUTTONS_NAMES.LAYERS);
    } else {
      showFeatures([]);
    }
  }, [isLayers, setKeyValue]);

  useEffect(() => {
    if (activeMapButton !== MAP_BUTTONS_NAMES.LAYERS) {
      showFeatures([]);
      setIsLayers(false);
    }
  }, [activeMapButton]);

  const isAccess = hasAccess(MapButtonsCode.LayersButton);

  if (!isAccess || !hasIntegrationLayersGIS || !isLayersBtnGis) return null;

  const startTimer = () => {
    if (closeTimeout) {
      return;
    }

    const timeout = setTimeout(() => {
      setIsLayers(false);
      clearTimeout(closeTimeout);
    }, COMPONENT_VISIBILITY_TIMEOUT);

    setCloseTimeout(timeout);
  };

  const stopTimer = () => {
    clearTimeout(closeTimeout);

    setCloseTimeout(undefined);
  };

  return (
    <>
      <ButtonIco
        isActive={isLayers}
        isHover
        onClick={handleIsLayers}
        disabled={layersIsEmpty}
        popoverProps={popOverProps}
      >
        <VscLayers />
      </ButtonIco>
      {isLayers ? (
        <Card
          onMouseLeave={startTimer}
          onMouseEnter={stopTimer}
          bordered={false}
          className={styles.layersPanel}
          style={LOCAL_STYLES.outerContainer}
          styles={{ body: LOCAL_STYLES.panelBodyStyle }}
        >
          <div
            className={
              !selectedNode ? styles.layersContainer : styles.settingsContainer
            }
          >
            <LayersTree
              treeData={treeItems}
              onSettingsClick={(evt: ILayerDataNode) => {
                setSelectedNode(evt);
                setMode(MODES.SETTINGS);
              }}
              onTableClick={(evt: ILayerDataNode) => {
                setSelectedNode(evt);
                setMode(MODES.TABLE);
              }}
            />
          </div>
          {mode === MODES.SETTINGS && selectedNode ? (
            <LayerSettings
              map={mapProxy}
              selectedNode={selectedNode}
              onBack={() => setSelectedNode(undefined)}
            />
          ) : null}
          {mode === MODES.TABLE && selectedNode ? (
            <LayerTable
              map={mapProxy}
              selectedNode={selectedNode}
              onBack={() => setSelectedNode(undefined)}
            />
          ) : null}
        </Card>
      ) : null}
    </>
  );
};

export default observer(LayersButton);
