import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import { FiInfo } from 'react-icons/fi';

import rootStore from '../../../../../../stores/rootStore/rootStore';
import ButtonIco, {
  TPopoverProps,
} from '../../../../../ui-kit/ButtonIco/ButtonIco';
import { UdsInfo } from '../../models/crossroad.model';

import InfoContent from './InfoContent/InfoContent';

import styles from './InfoBtn.module.scss';

interface InfoBtnProps {
  pointUdsInfo: UdsInfo[];
}

const InfoBtn: FC<InfoBtnProps> = ({ pointUdsInfo }) => {
  const { interfaceTipsDelay } = rootStore.uiStore;

  const [isInfo, setIsInfo] = useState(false);

  const onClick = () => setIsInfo((prev) => !prev);

  const isDisabled = !pointUdsInfo.length;

  const popoverProps: TPopoverProps = {
    content: `${
      isInfo ? 'Скрыть' : 'Открыть'
    } справочную информацию о точке УДС`,
    tipsDelay: interfaceTipsDelay,
    placement: 'left',
  };

  if (isDisabled) return null;

  return (
    <span className={styles.container}>
      <ButtonIco
        isRound
        isHover
        onClick={onClick}
        isSmall
        popoverProps={popoverProps}
      >
        <FiInfo size={16} />
      </ButtonIco>
      {isInfo && <InfoContent pointUdsInfo={pointUdsInfo} />}
    </span>
  );
};

export default observer(InfoBtn);
