import notification from '../components/ui-kit/Notification/Notification';

export interface MessageResponse {
  status: number;
  data: {
    message?: string;
  };
}

const showNotify = (res: U<MessageResponse>, message?: string) => {
  if (!res) return;

  const notify = message ?? res.data?.message;

  switch (res.status) {
    case 200:
      return notification.success('COMPLETE', {
        message: notify,
      });
    case 207:
      return notification.warning('ATTENTION', { message: notify });
    default:
      return;
  }
};

export default showNotify;
