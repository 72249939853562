import { sha256 } from 'js-sha256';

import { MINUTE } from '../../../constants/constants';

type AythProps = {
  keyAuth: string;
  login: string;
  password: string;
};

export const createAuthorizationHash = ({
  keyAuth,
  login,
  password,
}: AythProps) => {
  const kVerticalBar = '|';
  const creationTime = Math.floor(new Date().getTime() / MINUTE);

  const buff =
    login +
    kVerticalBar +
    password +
    kVerticalBar +
    creationTime +
    kVerticalBar +
    keyAuth;

  return sha256(buff).toString().toUpperCase();
};
