import { PanelTypes, RightPanelType } from '../../../ts/enums/enums';

const {
  Cluster,
  Constructor,
  CoordControl,
  GroupDispatch,
  InfoPanel,
  IdentificationGis,
} = RightPanelType;

const TYPES_VALUES: Record<PanelTypes, RightPanelType[]> = {
  [PanelTypes.LeftPanel]: [Constructor, CoordControl, GroupDispatch],
  [PanelTypes.InfoPanel]: [Cluster, InfoPanel, IdentificationGis],
};

export const getIsSpecialType = (
  panelType: N<RightPanelType>,
  type: PanelTypes
) => (panelType ? TYPES_VALUES[type].includes(panelType) : false);
