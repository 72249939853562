import { observer } from 'mobx-react-lite';
import { AiOutlineAim } from 'react-icons/ai';

import rootStore from '../../../../stores/rootStore/rootStore';
import ButtonOnlyIcon from '../../../ui-kit/ButtonOnlyIcon/ButtonOnlyIcon';
import usePanelLogic from '../../hooks/usePanelLogic';

import styles from './ClusterHeader.module.scss';

const ClusterHeader = () => {
  const { clickedCluster: clickedClusterMap, setTriggerCenteredCoord } =
    rootStore.clustersStore;

  const { panelContentContext: clickedCluster } =
    usePanelLogic(clickedClusterMap);

  const onClick = () => {
    clickedCluster?.coordinate &&
      setTriggerCenteredCoord(clickedCluster.coordinate);
  };

  return (
    <div className={styles.infoHead}>
      <ButtonOnlyIcon
        onClick={onClick}
        isSmall
        popoverProps={{ placement: 'left' }}
        tip={'Центрировать группу объектов'}
      >
        <AiOutlineAim />
      </ButtonOnlyIcon>
      <h3 className={styles.infoTitle}>Группа объектов</h3>
    </div>
  );
};

export default observer(ClusterHeader);
