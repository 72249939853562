import { PHASE_IMG, REGION } from '../../constants/constants';
import { VERSION } from '../../constants/versions';
import rootStore from '../../stores/rootStore/rootStore';
import { Path } from '../api.model';
import { getAppPathHeaders } from '../helpers/getAppPathHeaders';
import http from '../http';
import tryCatchWrapper from '../tryCatchWrapper';

const DEV = `${VERSION}/development/`;

interface UploadPhaseParams {
  rewrite?: boolean;
  folderName?: SN;
}

const developmentApi = {
  uploadSchemePhase: (
    body: unknown,
    appPath: Path,
    params?: UploadPhaseParams
  ) =>
    tryCatchWrapper(async () => {
      const { regionData } = rootStore.uiStore;

      if (!regionData) return;
      const folder = PHASE_IMG + REGION + regionData.id;

      const res = await http.post(
        `${DEV}/scheme-phase-by-phase-departure/upload`,
        body,
        {
          headers: getAppPathHeaders(appPath),
          params: {
            rewrite: params?.rewrite ?? true,
            folderName: params?.folderName ?? folder,
          },
        }
      );

      return res;
    }),
};

export default developmentApi;
