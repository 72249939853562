import { useMemo } from 'react';

import { findById } from '../../../../../../../../../helpers/findBy';
import useCircleTimer from '../../../../../../../../../hooks/useCircleTimer';
import rootStore from '../../../../../../../../../stores/rootStore/rootStore';
import { ChanelTypes } from '../../../../../../../../../ts/enums/enums';
import useChannel from '../../../../../../../../Socket/useChannel';
import { WSData } from '../../../../../../../../TLTimeSecWrapper/TLTimeSec/TLTimeSec';
import useCurrentProgram from '../../../../../../../../TrafficLightDetailed/TlManagement/PhaseTimeChangeCurrentPlanMode/hooks/useCurrentProgram';

const useTLSec = (tlId: number) => {
  const { tls } = rootStore.mapDataStore;
  const { categories } = rootStore.channelsDataStore;

  const activeTL = useMemo(() => findById(tls, tlId), [tls, tlId]);

  const { sgnProgramValue } = useCurrentProgram(tlId);

  const wsData: N<WSData> = useChannel(
    categories,
    ChanelTypes.TlCurrentTacts,
    tlId,
    true
  );

  const sec = useCircleTimer(
    activeTL?.tlStatus,
    sgnProgramValue?.phasesSet ?? [],
    wsData
  );

  return { activeTL, sec };
};

export default useTLSec;
