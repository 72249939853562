import { useCallback, useEffect, useState } from 'react';

import { TlManagementKeys } from '../../../../../constants/commands';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { TimeCardBasicProps } from '../../model/timeCard.model';
import {
  INITIAL_SELECTED_PLAN,
  INITIAL_SELECTED_TIME,
} from '../constants/constants';
import { SelectedPlan, SelectedTime } from '../models/plansControl.model';

const usePlanSelect = () => {
  const { activeControlKey, setIsNotDetailed } = rootStore.detailedStore;

  const [selectedPlan, setSelectedPlan] = useState<SelectedPlan>(
    INITIAL_SELECTED_PLAN
  );

  const [selectedTime, setSelectedTime] = useState<SelectedTime>(
    INITIAL_SELECTED_TIME
  );

  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    setIsChecked(false);
  }, [activeControlKey]);

  useEffect(() => {
    if (activeControlKey !== TlManagementKeys.PlanSelection) return;

    const isNecessary = selectedPlan.planId && selectedTime.duration;
    const isSelectPlanNow = isNecessary && !isChecked;
    const isSelectPlanOnTime = isNecessary && isChecked && selectedTime.date;

    setIsNotDetailed(
      'isDisabledControlCmd',
      !isSelectPlanNow && !isSelectPlanOnTime
    );
  }, [
    selectedPlan,
    selectedTime,
    isChecked,
    activeControlKey,
    setIsNotDetailed,
  ]);

  const clearPlanSelect = useCallback(() => {
    setIsChecked(false);
    setSelectedTime(INITIAL_SELECTED_TIME);
    setSelectedPlan(INITIAL_SELECTED_PLAN);
  }, []);

  const timeCardProps: TimeCardBasicProps = {
    isChecked,
    setIsChecked,
    selectedTimeInfo: selectedTime,
    setSelectedTimeInfo: setSelectedTime,
  };

  return {
    clearPlanSelect,
    timeCardProps,
    selectedPlan,
    setSelectedPlan,
    selectedTime,
  };
};

export default usePlanSelect;
