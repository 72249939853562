/* eslint-disable camelcase */
import { CoordStartGroup } from '../../../../components/Panel/ScriptsControl/model/coordControl.model';
import message from '../../../../components/ui-kit/Notification/Notification';
import NOTICE from '../../../../constants/notificationConstants';
import { VERSION } from '../../../../constants/versions';
import showNotify from '../../../../helpers/showNotify';
import { getAppPathHeaders } from '../../../helpers/getAppPathHeaders';
import http from '../../../http';
import tryCatchWrapper from '../../../tryCatchWrapper';

import { GroupSchema, GroupStartSchema } from './coordination.zod';

export interface ICreateInfo {
  deviceId: string;
  followedDevicesIds: UnEmptyArray<string>;
}

interface IStartGroupInfo {
  uid: string;
  time: number;
  requestMode: boolean;
}

export const ITS_LAB = VERSION + '/integration/itslab';

const COORD_CONTROL = ITS_LAB + '/coordination';
const REQUEST_COORD_PARAMS = {
  isExternalError: true,
};

const coordinationControlApi = {
  getGroupsList: (
    appPath: string,
    { full = false, isNeedNotifyError = true } = {}
  ) =>
    tryCatchWrapper(
      async () => {
        const res = await http.get(COORD_CONTROL, {
          headers: getAppPathHeaders(appPath),
          params: {
            full,
          },
        });

        const validate = GroupSchema.array().safeParse(res?.data);

        if (!validate.success) {
          isNeedNotifyError && message.error('INCORRECT_DATA_TYPE');

          return console.error(validate.error);
        }

        return res?.data;
      },
      {
        ...REQUEST_COORD_PARAMS,
        isHideErrorNotify: !isNeedNotifyError,
        errorMessage: 'ERROR_GROUPS_LIST',
        ignoreCodes: [423],
      }
    ),
  createGroup: (createInfo: ICreateInfo, appPath: string) =>
    tryCatchWrapper(
      async () => {
        const res = await http.post(COORD_CONTROL, createInfo, {
          headers: getAppPathHeaders(appPath),
        });

        showNotify(res, NOTICE.SUCCESS_GROUP_CREATE);

        return res;
      },
      {
        ...REQUEST_COORD_PARAMS,
        errorMessage: 'Ошибка создания группы координации',
      }
    ),
  deleteGroup: (uid: string, appPath: string) =>
    tryCatchWrapper(
      async () => {
        const res = await http.delete(COORD_CONTROL + `/${uid}`, {
          headers: getAppPathHeaders(appPath),
        });

        showNotify(res, NOTICE.DELETED_SUCCESSFULLY);

        return res;
      },
      {
        ...REQUEST_COORD_PARAMS,
        errorMessage: `Ошибка удаления группы координации с uid: ${uid}`,
      }
    ),
  startGroup: (
    startGroupInfo: IStartGroupInfo,
    appPath: string
  ): Promise<U<CoordStartGroup>> =>
    tryCatchWrapper(
      async () => {
        const { uid, requestMode, time } = startGroupInfo;

        const res = await http.patch(
          COORD_CONTROL + `/${uid}/start`,
          { time: time, request_mode: requestMode },
          {
            headers: getAppPathHeaders(appPath),
          }
        );

        const validate = GroupStartSchema.safeParse(res?.data);

        if (!validate.success) {
          return console.error(validate.error);
        }

        showNotify(res, NOTICE.SUCCESS_START_GROUP);

        return res?.data;
      },
      {
        ...REQUEST_COORD_PARAMS,
        errorMessage: `Ошибка запуска группы координации с uid: ${startGroupInfo.uid}`,
      }
    ),
  stopGroup: (uid: string, appPath: string) =>
    tryCatchWrapper(
      async () => {
        const res = await http.patch(COORD_CONTROL + `/${uid}/stop`, {
          headers: getAppPathHeaders(appPath),
        });

        showNotify(res, NOTICE.SUCCESS_STOP_GROUP);

        return res;
      },
      {
        ...REQUEST_COORD_PARAMS,
        notifyKey: `STOP_GROUP_${uid}_ERROR`,

        errorMessage: `Ошибка остановки группы координации с uid: ${uid}`,
      }
    ),
};

export { coordinationControlApi };
