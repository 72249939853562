import { FC } from 'react';

import useScrollToElement from '../../../../../../hooks/useScrollToElement';
import NoData from '../../../../../ui-kit/Errors/NoData/NoData';
import { IDataList } from '../../../model/monitoring.model';

import DeviceTile from './DeviceTile/DeviceTile';

import styles from './DevicesTiles.module.scss';

interface IDevicesTilesProps {
  data: IDataList[];
  addressLoading: boolean;
}

const DevicesTiles: FC<IDevicesTilesProps> = ({ data, addressLoading }) => {
  const { addToRefs, handleScroll } = useScrollToElement();

  if (!data.length) return <NoData />;

  return (
    <div className={styles.container}>
      {data.map((el) => (
        <DeviceTile
          key={el.id}
          {...el}
          addressLoading={addressLoading}
          addToRefs={addToRefs}
          handleScroll={handleScroll}
        />
      ))}
    </div>
  );
};

export default DevicesTiles;
