import { FC } from 'react';

import PhasesSlider from '../PhasesSlider/PhasesSlider';

import styles from './PhasesContainer.module.scss';

interface PhasesContainerProps {
  tlId: number;
  sec: number;
}

const PhasesContainer: FC<PhasesContainerProps> = ({ tlId, sec }) => {
  return (
    <div className={styles.container}>
      <PhasesSlider tlId={tlId} sec={sec} />
    </div>
  );
};

export default PhasesContainer;
