import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import MonitoringWidget from '../../../DetailedStatistics/Monitoring/MonitoringWidget/MonitoringWidget';

import useMonitoringCard from './hooks/useMonitoringCard';
import { TWidgetsOptionalData } from './models/monitoring.model';

interface IMonitoringCardProps {
  statusData: U<TWidgetsOptionalData>;
  title: string;
}

const MonitoringCard: FC<IMonitoringCardProps> = ({ statusData, title }) => {
  const { dataPieChart, activeSectors, handleClickSector } =
    useMonitoringCard(statusData);

  return (
    <MonitoringWidget
      activeSectors={activeSectors}
      dataPieChart={dataPieChart}
      isEmptyData={!dataPieChart}
      handleClickSector={handleClickSector}
      title={title}
      footerTitle="Кол-во оборудования"
    />
  );
};

export default observer(MonitoringCard);
