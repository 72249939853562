import { uniqueId } from 'lodash';

import {
  ColumnTypes,
  ITableColumn,
  TableData,
} from '../../../../../ts/models/table.model';
import { ITable } from '../../Table';

import { getColumnProps } from './helpers/getColumnProps';
import { getSortedColumns } from './helpers/getSortedColumns';

export const getMutTableData = (
  tableData: TableData,
  collaborationRender: ITable['collaborationRender'],
  activeStyle: string,
  isOrderNumber?: boolean
): TableData => {
  const visibleColumns = tableData.headerItem.filter(
    (item: ITableColumn) => !!item.visible
  );

  if (collaborationRender) {
    visibleColumns.push({
      title: 'Взаимодействие',
      dataIndex: 'collaboration',
      visible: true,
      type: ColumnTypes.collaboration,
      filterSettings: {
        filters: null,
        searched: false,
        sorted: false,
      },
      readonly: false,
      referens: 'collaboration',
    });
  }

  const newColumns = visibleColumns.map((item, i) => {
    const column = {
      ...item,
      multiple: i,
      dataIndex: item.referens,
    };

    getColumnProps(column, tableData, collaborationRender, activeStyle);

    return column;
  });

  const filteredColumns = getSortedColumns(newColumns, isOrderNumber);

  const dataRows = tableData.dataRows.map((item: any, i) => {
    item.key = uniqueId();

    if (isOrderNumber) {
      item.orderNumber = i + 1;
    }

    if (collaborationRender) {
      item.collaboration = { ...item };
    }

    return item;
  });

  return {
    ...tableData,
    headerItem: filteredColumns,
    dataRows: dataRows,
  };
};
