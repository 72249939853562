/* eslint-disable jsx-a11y/no-static-element-interactions */
import classNames from 'classnames';
import { FC, useState, MouseEvent } from 'react';
import { BsFileEarmark, BsFileEarmarkCheck } from 'react-icons/bs';

import rootStore from '../../../stores/rootStore/rootStore';
import message from '../Notification/Notification';
import Popover from '../Popover/Popover';

import styles from './Copy.module.scss';

interface CopyProps {
  copyString: string;
  className?: string;
}

const Copy: FC<CopyProps> = ({ className = '', copyString }) => {
  const { interfaceTipsDelay } = rootStore.uiStore;
  const [isCopy, setIsCopy] = useState<boolean>(false);

  const handleCopy = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();

    navigator.clipboard.writeText(copyString);
    message.success('TEXT_COPY');
    setIsCopy(true);
    setTimeout(() => {
      setIsCopy(false);
    }, 3000);
  };

  const copyBtnStyle = classNames({
    [styles.copyButton]: true,
    [styles.copied]: isCopy,
    [className]: !!className,
  });

  return (
    <Popover
      content="Скопировать"
      placement="top"
      tipsDelay={interfaceTipsDelay}
    >
      <div className={copyBtnStyle} onClick={handleCopy}>
        {isCopy ? (
          <BsFileEarmarkCheck size={20} />
        ) : (
          <BsFileEarmark size={20} />
        )}
      </div>
    </Popover>
  );
};

export default Copy;
