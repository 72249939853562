import { FC } from 'react';
import { HiChevronLeft } from 'react-icons/hi';
import { CustomArrowProps } from 'react-slick';

import { TIME_SLIDER_CONSTANTS } from '../constants';

export const SliderPrevArrow: FC = (props: CustomArrowProps) => {
  const { className, onClick } = props;

  return (
    <div
      className={className}
      style={{
        ...TIME_SLIDER_CONSTANTS.borderRadiusLeft,
        ...TIME_SLIDER_CONSTANTS.body,
      }}
      onClick={onClick}
      role="presentation"
    >
      <div
        style={{
          ...TIME_SLIDER_CONSTANTS.borderRadiusRight,
          ...TIME_SLIDER_CONSTANTS.arrow,
        }}
      >
        <HiChevronLeft size={'middle'} />
      </div>
    </div>
  );
};
