import { observer } from 'mobx-react-lite';
import React from 'react';

import { ReactComponent as FilledIco } from '../../../../assets/icons/filledGrid.svg';
import { ReactComponent as GridIco } from '../../../../assets/icons/grid.svg';
import { ReactComponent as ListIco } from '../../../../assets/icons/list.svg';
import { PHASES_TEMPLATE } from '../../../../constants/constants';
import rootStore from '../../../../stores/rootStore/rootStore';
import Popover, { PopoverProps } from '../../../ui-kit/Popover/Popover';

import styles from './VsSwitcher.module.scss';

const POPOVER_PHASE_CONTENT = {
  GRID: 'Отображение сеткой',
  LIST: 'Переключаемый список',
  FILLED: 'Редактируемый размер схем',
};

enum PhasesTemplate {
  FILLED,
  LIST,
  GRID,
}

export type PopoverPropsFormatted = Omit<PopoverProps, 'children' | 'content'>;

const { FILLED, LIST, GRID } = PHASES_TEMPLATE;

interface VsSwitcherProps {
  toggler: any;
}

const VsSwitcher = (props: VsSwitcherProps) => {
  const { interfaceTipsDelay } = rootStore.uiStore;

  const { toggler } = props;

  const popoverProps: PopoverPropsFormatted = {
    tipsDelay: interfaceTipsDelay,
    placement: 'bottom',
  };

  return (
    <div className={styles.container}>
      <input
        defaultChecked
        id="gridTemplate"
        name="VsSwitchInput"
        onClick={() => {
          toggler(GRID as unknown as PhasesTemplate);
        }}
        type="radio"
      />

      <Popover {...popoverProps} content={POPOVER_PHASE_CONTENT.GRID}>
        <label htmlFor="gridTemplate">
          <GridIco className={styles.gridIco} />
        </label>
      </Popover>

      <input
        id="listVerInput"
        name="VsSwitchInput"
        onClick={() => {
          toggler(LIST as unknown as PhasesTemplate);
        }}
        type="radio"
      />

      <Popover {...popoverProps} content={POPOVER_PHASE_CONTENT.LIST}>
        <label htmlFor="listVerInput">
          <ListIco className={styles.listIco} />
        </label>
      </Popover>

      <input
        id="filledVerInput"
        name="VsSwitchInput"
        onClick={() => {
          toggler(FILLED as any);
        }}
        type="radio"
      />

      <Popover {...popoverProps} content={POPOVER_PHASE_CONTENT.FILLED}>
        <label htmlFor="filledVerInput">
          <FilledIco className={styles.filledIco} />
        </label>
      </Popover>
    </div>
  );
};

export default observer(VsSwitcher);
