/* eslint-disable camelcase */
import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';

import rootStore from '../../../../../../../stores/rootStore/rootStore';
import useDetectorsPreview from '../../../../../../DetectorGrid/hooks/useDetectorsPreview';
import { getCameraInfo } from '../../../../../../VideoCamera/helpers/getCameraInfo/getCameraInfo';
import VideoCamera from '../../../../../../VideoCamera/VideoCamera';

import { getFilteredScene } from './helpers/helpers';

import styles from './VideoPreview.module.scss';

interface VideoPreviewProps {
  id: N<number>;
  isNoImageDt: boolean;
}

const VideoPreview: FC<VideoPreviewProps> = ({ id, isNoImageDt }) => {
  const { cameras, detectors } = rootStore.mapDataStore;

  const { scene } = useDetectorsPreview({
    detectors,
    id,
    isScene: true,
  });

  const correctScene = useMemo(
    () => getFilteredScene(scene, detectors, id),
    [detectors, id, scene]
  );

  const linkedCamera = cameras.find(
    ({ dai_jdt83 }) => dai_jdt83?.linkedDetectorId === id
  );

  return (
    <div className={styles.container}>
      <VideoCamera
        cameraData={getCameraInfo(linkedCamera)}
        isDetectorLanes={!isNoImageDt || undefined}
        scene={correctScene}
      />
    </div>
  );
};

export default observer(VideoPreview);
