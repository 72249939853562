import { CiImageOff } from 'react-icons/ci';

import { ReactComponent as CameraIco } from '../../../assets/icons/map/ic_camera.svg';
import { ReactComponent as DetectorsIco } from '../../../assets/icons/map/ic_detectors.svg';
import { ReactComponent as MeteoIco } from '../../../assets/icons/map/ic_meteo.svg';
import { ReactComponent as PublickTrIco } from '../../../assets/icons/map/ic_public_transport.svg';
import { ReactComponent as SpecialEquipIco } from '../../../assets/icons/map/ic_special_transport.svg';
import { ReactComponent as TrafficLightIco } from '../../../assets/icons/map/ic_tr_light.svg';
import { SYSTEM } from '../../../constants/constants';
import { System } from '../../../ts/enums/enums';

export const switchIco = (system: System) => {
  switch (system) {
    case SYSTEM.LIGHTS:
      return { ico: <TrafficLightIco />, isBg: false };
    case SYSTEM.DETECTORS:
      return { ico: <DetectorsIco />, isBg: false };
    case SYSTEM.CAMERAS:
      return { ico: <CameraIco />, isBg: false };
    case SYSTEM.SPECIAL_TRANSPORT:
      return { ico: <SpecialEquipIco />, isBg: true };
    case SYSTEM.PUBLIC_TRANSPORT:
      return { ico: <PublickTrIco />, isBg: true };
    case SYSTEM.METEO:
      return { ico: <MeteoIco />, isBg: false };
    default:
      return { ico: <CiImageOff />, isBg: true };
  }
};
