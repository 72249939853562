import { Card } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';

import { IBaseMapItem } from '../../../stores/gisDataStore/gisDataStore.model';
import Popover from '../../ui-kit/Popover/Popover';

import styles from './BasemapsButton.module.scss';

export const BaseMapButton = (props: {
  item: IBaseMapItem;
  popOverItemProps: {
    readonly tipsDelay: Nullish<number>;
    readonly placement: TooltipPlacement | undefined;
  };
  onClick: () => void;
  currentBasemap: string;
  subtitle: string | undefined;
}) => (
  <div className={styles.baseMapItemContainer}>
    <Popover
      content={props.item.description || props.item.text}
      {...props.popOverItemProps}
    >
      <Card
        onClick={props.onClick}
        hoverable
        className={styles.baseMapItemCard || styles.baseMapItemImgSelected}
        cover={
          <img
            alt={props.item.text}
            src={props.item.imageSrc}
            className={
              props.currentBasemap === props.item.id
                ? styles.baseMapItemImgSelected
                : styles.baseMapItemImg
            }
          />
        }
      ></Card>
      <div style={{ textAlign: 'center' }}>{props.subtitle}</div>
    </Popover>
  </div>
);
