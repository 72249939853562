import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { CONFIG } from '../../constants/configConstants';
import OverviewMapControl from '../Controls/OverviewMapControl/OverviewMapControl';
import ScaleLineControl from '../Controls/ScaleLineControl/ScaleLineControl';
import ClusterLayers from '../Mapper/ClusterLayers/ClusterLayers';
import ConstructorOverlays from '../Mapper/ConstructorOverlays/ConstructorOverlays';
import Tooltips from '../Mapper/Tooltips/Tooltips';
import AlarmPopup from '../Overlays/AlarmPopup/AlarmPopup';
import InfoPopup from '../Overlays/InfoPopup/InfoPopup';
import MarkerPopup from '../Overlays/MarkerPopup/MarkerPopup';
import PointUdsOverlays from '../Overlays/PointUdsOverlays/PointUdsOverlays';
import { TileLayer } from '../ui-kit/MapLayers';
import { osm } from '../ui-kit/MapSource';

import useBasemap from './hooks/useBasemap';
import useInfoPanel from './hooks/useInfoPanel';
import useIsTlsDataFetched from './hooks/useIsTlsDataFetched';
import useMapEventHandlers from './hooks/useMapEventHandlers';
import useMapInit from './hooks/useMapInit';
import useMapSettings from './hooks/useMapSettings';
import useMeasuring from './hooks/useMeasuring';
import useVisibleLayers from './hooks/useVisibleLayers';
import useWeather from './hooks/useWeather';

import styles from './Map.module.scss';

const Map: FC = () => {
  const mapRef = useMapInit();

  useInfoPanel();
  useMapEventHandlers();
  useMapSettings();
  useVisibleLayers();
  useBasemap();
  useMeasuring();
  useWeather();
  useIsTlsDataFetched();

  return (
    <div className={styles.olMap} ref={mapRef} role="presentation">
      <Tooltips />
      <TileLayer source={osm(CONFIG.OSM_SOURCE)} isNeedRefresh />
      <ClusterLayers />
      <ConstructorOverlays />
      <PointUdsOverlays />
      <MarkerPopup />
      <AlarmPopup />
      <InfoPopup />
      <OverviewMapControl source={osm(CONFIG.OSM_SOURCE)} />
      <ScaleLineControl />
    </div>
  );
};

export default observer(Map);
