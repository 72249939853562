import { toLonLat } from 'ol/proj';

import { getIsCoordinatesOnExtent } from '../../components/Map/helpers/getIsCoordinatesOnExtent';
import rootStore from '../../stores/rootStore/rootStore';

const checkIncorrectCoordinates = (id: number) => {
  const { getCoordinatesById } = rootStore.mapDataStore;

  const coordinates = getCoordinatesById(id) ?? [];

  return !getIsCoordinatesOnExtent(toLonLat(coordinates)) || !coordinates;
};

export default checkIncorrectCoordinates;
