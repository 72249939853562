import { z } from 'zod';

import { BasicProfileInfoSchema } from '../../pointUds/profiles/profiles.zod';
import { NNInt } from '../../zod/utils.zod';
import { DeviceInfoSchema, LinkedDeviceIdsSchema } from '../mapObjects.zod';

export const PointUdsSchema = z.object({
  basicProfileInfo: BasicProfileInfoSchema,
  id: NNInt,
  deviceInfo: DeviceInfoSchema,
  linkedDeviceIds: LinkedDeviceIdsSchema.nullable().optional(),
});
