import { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';

import rootStore from '../../../../../stores/rootStore/rootStore';
import { IRangePickerProps } from '../../../../ui-kit/Picker/RangePicker/RangePicker';
import { TimePickerBasicProps } from '../../../../ui-kit/Picker/TimePicker/TimePicker';
import {
  INITIAL_RANGE_VALUE,
  INTERVALS_NAMES,
} from '../../constants/constants';
import { IDateInfo, IPickerValue } from '../../model/graph.model';
import { disabledDateTimeRangePicker } from '../helpers/disabledDateTimeRangePicker';
import { getDateInfo } from '../helpers/getDateInfo';
import { getSliceDates } from '../helpers/getSliceDates';
import { onChangeDateRP } from '../helpers/onChangeDateRP';

interface IUseDatePickerProps {
  isModal: boolean;
  setIsModal: (val: boolean) => void;
  setDateInfo: SetState<N<IDateInfo>>;
  standardInterval: U<INTERVALS_NAMES>;
  setIsConfirm?: SetState<boolean>;
  setIsNeedNewData?: SetState<boolean>;
}

const INITIAL_RANGE: IPickerValue = {
  ...INITIAL_RANGE_VALUE,
  rangePicker: [...getSliceDates(1, { isNeedUtcFormatted: true })],
};

const DATE_FORMAT = 'DD.MM.YYYY, HH:mm';

const useDatePicker = ({
  isModal,
  setIsModal,
  setDateInfo,
  standardInterval,
  setIsConfirm,
  setIsNeedNewData,
}: IUseDatePickerProps) => {
  const { setGeneralDtInfo } = rootStore.detailedStore;

  const [isDisabledBtnModal, setIsDisabledBtnModal] = useState<boolean>(false);
  const [pickerValue, setPickerValue] = useState<IPickerValue>(INITIAL_RANGE);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    standardInterval && setIsChecked(false);
  }, [standardInterval]);

  useEffect(() => {
    const isInterval =
      (!isChecked && !!standardInterval) ||
      (isChecked && pickerValue.timePicker);
    const isDisabled = !isModal || !pickerValue.rangePicker || !isInterval;

    setIsDisabledBtnModal(isDisabled);
  }, [isModal, pickerValue, standardInterval, isChecked]);

  const onChangeChecked = (value: boolean) => setIsChecked(value);

  const onOkConfirm = () => {
    setIsModal(false);
    setIsConfirm && setIsConfirm(true);

    const dateInfoForm = getDateInfo({
      isChecked,
      pickerValue,
      standardInterval,
    });

    if (!dateInfoForm) return;

    setGeneralDtInfo(null);
    setIsNeedNewData && setIsNeedNewData(true);
    setDateInfo(dateInfoForm);
  };

  const onChangeTimeDuration = (time: N<Dayjs>) => {
    setPickerValue((prev) => ({ ...prev, timePicker: time }));
  };

  const onChangeDate = (value: N<[Dayjs, Dayjs]>) =>
    setPickerValue((prev) => ({
      ...prev,
      ...onChangeDateRP(value),
    }));

  const modalConfirmProps = {
    modalTitle: `Выберите период`,
    openModal: isModal,
    setOpenModal: setIsModal,
    onOkConfirm,
    width: '700px',
    okButtonProps: { disabled: isDisabledBtnModal },
  };

  const timePickerProps: TimePickerBasicProps = {
    onChange: onChangeTimeDuration,
    value: pickerValue.timePicker,
    disabledTimeProps: null,
  };

  const rangePickerProps: IRangePickerProps = {
    dateFormat: DATE_FORMAT,
    onChangeDate,
    value: pickerValue.rangePicker,
    status: pickerValue.status,
    handleDisableTime: (value: N<Dayjs>) => disabledDateTimeRangePicker(value),
  };

  return {
    modalConfirmProps,
    timePickerProps,
    rangePickerProps,
    onChangeChecked,
    setPickerValue,
  };
};

export default useDatePicker;
