import { observer } from 'mobx-react-lite';
import type { Overlay } from 'ol';
import { FC } from 'react';

import { TMap } from '../../../../../../stores/mapStore/mapStore.model';
import rootStore from '../../../../../../stores/rootStore/rootStore';
import CrossroadSchema, {
  CrossroadSchemaProps,
} from '../CrossroadSchema/CrossroadSchema';
import LinkedDevicesWrapper from '../CrossroadSchema/LinkedDevicesWrapper/LinkedDevicesWrapper';

import useCrossroadTLWrapper from './hooks/useCrossroadTLWrapper';

interface CrossroadTLWrapperProps {
  tlId: number;
  map: TMap;
  crossroadOverlay: N<Overlay>;
}

const CrossroadTLWrapper: FC<CrossroadTLWrapperProps> = ({
  map,
  tlId,
  crossroadOverlay,
}) => {
  const { mapPropsDetailed } = rootStore.detailedStore;

  const { crossroadBasicProps, tl } = useCrossroadTLWrapper({
    tlId,
    mapPropsDetailed,
  });

  if (!crossroadBasicProps || !tl) return null;

  const crossroadSchemaProps: CrossroadSchemaProps = {
    ...crossroadBasicProps,
    crossroadOverlay,
    map,
  };

  return (
    <>
      <CrossroadSchema {...crossroadSchemaProps} />
      <LinkedDevicesWrapper
        selectedTL={tl}
        isShowLanes
        rate={crossroadSchemaProps.rate}
      />
    </>
  );
};

export default observer(CrossroadTLWrapper);
