import { Button } from 'antd';
import { FC } from 'react';
import { RiRestartLine } from 'react-icons/ri';

import { IServerData } from '../../NJSInfo.model';
import InfoBox from '../InfoBox/InfoBox';

import GroupItemInfo from './ui/GroupItemInfo/GroupItemInfo';

import styles from './TimeRefresh.module.scss';

interface ITimeRefresh {
  data: IServerData;
  type: 'fullData' | 'partialData';
  isLoading: boolean;
  handleRefresh: () => void;
}

interface IValues {
  [key: string]: IServerData['refreshInfo'];
}

type Titles = Record<string, string>;

const TimeRefresh: FC<ITimeRefresh> = ({
  data,
  type,
  isLoading,
  handleRefresh,
}) => {
  const VALUES: IValues = {
    fullData: data.refreshInfo,
    partialData: data.partialUpdateInfo,
  };
  const TITLES: Titles = {
    fullData: 'Полное обновление данных:',
    partialData: 'Частичное обновление данных:',
  };

  const InfoBoxStyles =
    type === 'fullData' ? styles.fullData : styles.partialData;

  return (
    <InfoBox className={InfoBoxStyles} title={TITLES[type]}>
      <>
        <ul>
          {Object.keys(VALUES[type]).map((item, i) => {
            const updateInfo = VALUES[type][item];

            return (
              <li key={i} className={styles.listItem}>
                <GroupItemInfo updateInfo={updateInfo} objectType={item} />
              </li>
            );
          })}
        </ul>
        {type === 'fullData' ? (
          <Button
            type="primary"
            size="middle"
            icon={<RiRestartLine />}
            onClick={handleRefresh}
            loading={isLoading}
          >
            Обновить данные
          </Button>
        ) : null}
      </>
    </InfoBox>
  );
};

export default TimeRefresh;
