import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';

import AppLayout from '../AppLayout/AppLayout';

const router = createRoutesFromElements(
  <Route path="/" element={<AppLayout />}>
    <Route path="*" />
  </Route>
);

export default createBrowserRouter(router);
