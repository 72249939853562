import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../stores/rootStore/rootStore';
import Popover from '../../../ui-kit/Popover/Popover';

import styles from './TabLabel.module.scss';

interface TabLabelProps {
  tooltip?: string;
  tabName: string;
  isDisabled?: boolean;
}

const TabLabel: FC<TabLabelProps> = ({
  tooltip,
  tabName,
  isDisabled = false,
}) => {
  const { interfaceTipsDelay } = rootStore.uiStore;

  return (
    <Popover content={tooltip} tipsDelay={tooltip ? interfaceTipsDelay : null}>
      <p
        className={classNames({
          [styles.labelTabs]: true,
          [styles.labelDisabledTabs]: isDisabled,
        })}
      >
        {tabName}
      </p>
    </Popover>
  );
};

export default observer(TabLabel);
