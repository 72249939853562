import { ReactElement } from 'react';
import {
  AiOutlineInsertRowBelow,
  AiOutlineUnorderedList,
} from 'react-icons/ai';

export enum ShowBtnsDevices {
  List = 'List',
  Tiles = 'Tiles',
}

export interface IDisplayIconsDevices {
  id: number;
  icon: ReactElement;
  type: ShowBtnsDevices;
  tooltip: string;
}

export const DISPLAY_ICONS_DEVICES: IDisplayIconsDevices[] = [
  {
    id: 0,
    icon: <AiOutlineUnorderedList />,
    type: ShowBtnsDevices.List,
    tooltip: 'Отображать списком',
  },
  {
    id: 1,
    icon: <AiOutlineInsertRowBelow />,
    type: ShowBtnsDevices.Tiles,
    tooltip: 'Отображать в виде плиточек',
  },
];
