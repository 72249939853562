export const getClientUrl = async () => {
  const regionId = localStorage.getItem('regionId') || '';

  const { location } = window;

  const { host, protocol } = location;

  const trailPath = 'gis?configuration=EPUTS_R' + regionId;

  const hostParts = host.split('.');

  hostParts[0] = 'gis';

  const gisHost = hostParts.join('.');

  return `${protocol}//${gisHost}/${trailPath}`;
};
