import { cloneDeep, isString } from 'lodash';

import { IBreadCrumbsItem } from '../../../../Admin/BreadCrumbs/BreadCrumbs';
import { PanelTree } from '../../../AdminPanel/helpers/getMenuLabelTooltips';
import {
  CurrentReportItem,
  FilteredItems,
  ReportsLabels,
  ReportsTreeItem,
} from '../model/reports.model';

export const SEPARATOR_KEY = '#';

type TreeChildren<T extends PanelTree> = NonNullable<T['children']>;

const treeFilter = <T extends PanelTree>(
  tree: T[] | TreeChildren<T>,
  func: (node: T | TreeChildren<T>[number]) => boolean
): T[] | TreeChildren<T> => {
  return tree
    .map((node) => ({ ...node }))
    .filter((node) => {
      node.children = node.children && treeFilter(node.children, func);

      return func(node) || (node.children && node.children.length);
    });
};

export const getFilteredBySearchedValues = (
  tree: ReportsTreeItem[],
  filteredValues: N<FilteredItems[]>
) => {
  if (filteredValues && !filteredValues.length) return tree;

  const cloneTree = cloneDeep(tree);

  if (!filteredValues)
    return cloneTree.map((el) => ({
      ...el,
      children: [],
    }));

  return treeFilter(cloneTree, (node) =>
    filteredValues.some((el) => el.key === node.key.toString())
  );
};

const isIncludesStr = (label: ReportsLabels, searchValue: string) => {
  if (label.label.trim().toLowerCase().includes(searchValue.toLowerCase()))
    return true;

  return false;
};

const getActiveItemsBySearch = (
  tree: ReportsTreeItem[],
  labels: ReportsLabels[],
  searchValue: string
) => {
  const value = searchValue.trim();

  if (!value) return [];

  const items = labels.reduce((acc: FilteredItems[], el) => {
    if (isIncludesStr(el, value)) {
      const currentItem = getCurrentReportItem(tree, el.key);

      currentItem && acc.push({ ...el, keyPath: currentItem.keyPath });
    }

    return acc;
  }, []);

  return items.length ? items : null;
};

export const getActiveInfo = (
  tree: ReportsTreeItem[],
  labels: ReportsLabels[],
  searchValue: string
) => {
  const activeItems = getActiveItemsBySearch(tree, labels, searchValue);

  const openedKeys =
    activeItems?.reduce((acc: string[], el) => {
      el.keyPath.forEach((item) => {
        !acc.includes(item) && acc.push(item);
      });

      return acc;
    }, []) ?? [];

  return {
    openedKeys,
    activeItems,
  };
};

const setLabels = <T extends PanelTree>(menu: T[], labels: ReportsLabels[]) => {
  menu.forEach((item) => {
    if (isString(item.label) && !item.children) {
      const { label, key } = item;

      labels.push({
        label,
        key: key.toString(),
      });
    }

    if (item.children) {
      return setLabels(item.children, labels);
    }
  });
};

export const getAllReportsLabels = <T extends PanelTree>(menu: T[]) => {
  const labels: ReportsLabels[] = [];

  setLabels(menu, labels);

  return labels;
};

export const getTreeWithNormalKeys = <T extends PanelTree>(menu: T[]) => {
  menu.forEach((item) => {
    if (typeof item.label === 'string') {
      item.key = item.label + SEPARATOR_KEY + item.key;
    }

    if (item.children) {
      return getTreeWithNormalKeys(item.children);
    }
  });

  return menu;
};

export const getCurrentReportItem = (
  tree: ReportsTreeItem[],
  key: SN
): N<CurrentReportItem> => {
  for (const el of tree) {
    if (el.key.toString() === key.toString()) {
      return {
        treeItem: el,
        keyPath: [],
      };
    }

    if (el.children) {
      const recursiveParams = getCurrentReportItem(el.children, key);

      if (recursiveParams) {
        recursiveParams.keyPath.unshift(el.key.toString());

        return recursiveParams;
      }
    }
  }

  return null;
};

export const getBreadCrumbs = (
  tree: ReportsTreeItem[],
  keyPath: string[]
): IBreadCrumbsItem[] => {
  return keyPath.map((key) => {
    const currentReportItem = getCurrentReportItem(tree, Number(key));

    return {
      key,
      label: currentReportItem?.treeItem?.label || 'неизвестно',
    };
  });
};
