const downloadFile = (data: any, fileName: string) => {
  const blob = new Blob([data], { type: 'application/octet-stream' });
  const urlCreator = window.URL || window.webkitURL;
  const url = urlCreator.createObjectURL(blob);
  const link: any = document.createElement('a');

  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const downloadBase64File = (contentBase64: string, fileName: string) => {
  const linkSource = `data:application/pdf;base64,${contentBase64}`;

  const downloadLink = document.createElement('a');

  document.body.appendChild(downloadLink);

  downloadLink.href = linkSource;
  downloadLink.target = '_self';
  downloadLink.download = fileName;
  downloadLink.click();

  document.removeChild(downloadLink);
};

export default downloadFile;
