import type { Payload } from 'recharts/types/component/DefaultLegendContent';

import { CalcData } from '../models/chart.model';

import XTick from './XTick/XTick';

import styles from './CustomedTick.module.scss';

interface CustomedTickProps {
  x: number;
  y: number;
  payload: Payload;
  isX: boolean;
  data?: CalcData[];
  labelKey: string;
  visibleTicksCount: number;
  index: number;
}

const CustomedTick = ({
  x,
  y,
  payload,
  isX,
  data,
  labelKey,
  visibleTicksCount,
  index,
}: CustomedTickProps) => {
  if (isX)
    return (
      <XTick
        labelKey={labelKey}
        payload={payload}
        x={x}
        y={y}
        data={data}
        visibleTicksCount={visibleTicksCount}
      />
    );

  const isLast = visibleTicksCount - 1 === index;

  if (!payload.value || isLast) return null;

  return (
    <g transform={`translate(${x},${y})`} className={styles.textLabel}>
      <text x={-5} y={0} dy={16} textAnchor="end">
        {payload.value}
      </text>
    </g>
  );
};

export default CustomedTick;
