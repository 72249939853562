import { isEqual, isNumber } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import { IHeaderItem, IPhasesData } from '../../../PhaseHold/PhaseHold.model';
import { SgnProgramValues } from '../../../PlanSelect/models/plansControl.model';
import getDataTable from '../helpers/getDataTable';

interface UseEditProgramProps {
  selectedPlanId: N<number>;
  programValues: SgnProgramValues[];
}

const useEditProgram = ({
  selectedPlanId,
  programValues,
}: UseEditProgramProps) => {
  const [dataTable, setDataTable] = useState<IPhasesData[]>([]);
  const [isEditTable, setIsEditTable] = useState<boolean>(false);
  const [isValidateTable, setIsValidateTable] = useState<boolean>(true);
  const [columns, setColumns] = useState<IHeaderItem[]>([]);

  useEffect(() => {
    if (!isNumber(selectedPlanId) || !dataTable.length) return;

    const res = getDataTable(programValues, selectedPlanId);

    if (!res) return;

    const isChanged = !isEqual(res, dataTable);

    setIsEditTable(isChanged && isValidateTable);
  }, [dataTable, isValidateTable, programValues, selectedPlanId]);

  const setDefaultDataTable = useCallback(() => {
    setColumns([]);
    if (!programValues.length || !isNumber(selectedPlanId)) return;

    const res = getDataTable(programValues, selectedPlanId);

    setDataTable(res ?? []);
    setIsValidateTable(true);
  }, [programValues, selectedPlanId]);

  useEffect(() => {
    setDefaultDataTable();
  }, [setDefaultDataTable]);

  const clearDataTable = useCallback(() => {
    setIsEditTable(false);
    setIsValidateTable(true);
    setDataTable([]);
  }, []);

  const editTableProps = {
    selectedPlanId,
    isValidateTable,
    dataTable,
    setDataTable,
    setIsValidateTable,
    columns,
    setColumns,
  };

  return {
    isEditTable,
    dataTable,
    editTableProps,
    clearDataTable,
    setDefaultDataTable,
  };
};

export default useEditProgram;
