import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { lazy, Suspense } from 'react';

import rootStore from '../../stores/rootStore/rootStore';
import MjpegPlayerList from '../MjpegPlayerList/MjpegPlayerList';
import Detailed, { DetailedType } from '../ui-kit/Detailed/Detailed';
import CamerasList from '../WebRTCPlayer/CamerasList/CamerasList';

import useSelectedVideoProfileId from './hooks/useSelectedVideoProfileId';

import styles from './VideoWall.module.scss';

const Actions = lazy(() => import('./Actions/Actions'));
const InitialVideo = lazy(() => import('./InitialVideo/InitialVideo'));
const VideoList = lazy(() => import('./VideoList/VideoList'));

const HEADER = 'Модуль видеонаблюдения';

const VideoWall = () => {
  const { setIsNot, setKeyValue } = rootStore.uiStore;
  const { user } = rootStore.userDataStore;
  const {
    videoList,
    selectedProfileId,
    setKeysValues,
    savedProfilesList,
    layoutGrid,
    isXeoma,
    isXeomaMjpeg,
  } = rootStore.videoWallPanelStore;

  useSelectedVideoProfileId({
    savedProfilesList,
    selectedProfileId,
    user,
  });

  const onVideoClose = () => {
    setIsNot('isPanel', false);
    setKeyValue('panelType', null);
    setKeysValues({ isFullScreen: false, isXeoma: false });
  };

  const title =
    selectedProfileId &&
    `${
      savedProfilesList.find((item) => item.id === selectedProfileId)?.name ??
      'Профиль не найден!'
    }`;

  const detailedProps: DetailedType = {
    header: HEADER,
    onClose: onVideoClose,
  };

  if (isXeoma)
    return (
      <Suspense fallback={<Spin spinning={true} />}>
        <Detailed {...detailedProps}>
          <CamerasList />
        </Detailed>
      </Suspense>
    );

  if (isXeomaMjpeg)
    return (
      <Suspense fallback={<Spin spinning={true} />}>
        <Detailed {...detailedProps}>
          <MjpegPlayerList />
        </Detailed>
      </Suspense>
    );

  return (
    <Suspense fallback={<Spin spinning={true} />}>
      <Detailed {...detailedProps}>
        <>
          <div className={styles.header}>
            {title ? (
              <h3 className={styles.text}>Выбранный профиль: {title}</h3>
            ) : null}
            <h3 className={styles.text}>
              Выбранная разметка: {layoutGrid[0]}X{layoutGrid[1]}
            </h3>
          </div>
          {videoList.length ? <Actions /> : null}
          {videoList.length ? (
            <VideoList key={selectedProfileId} />
          ) : (
            <InitialVideo />
          )}
        </>
      </Detailed>
    </Suspense>
  );
};

export default observer(VideoWall);
