import { useState, useEffect } from 'react';

import http from '../../../../../../api/http';
import { TMethods } from '../../../ts/TestingPanel.model';

interface useRequestProps {
  url: string;
  method: TMethods;
  body?: object;
}

const useRequest = (props: useRequestProps) => {
  const { url, body, method } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [statusCode, setStatusCode] = useState<number | null>(null);
  const [response, setResponse] = useState<any>(undefined);

  useEffect(() => {
    setIsLoading(false);
    setError(null);
    setStatusCode(null);
    setResponse(undefined);
  }, [url, method]);

  const send = async () => {
    try {
      setError(null);
      setIsLoading(true);
      const request = await http.request({
        method: method,
        url: url,
        data: body,
      });

      setStatusCode(request.status);
      setResponse(request.data);
    } catch (e: any) {
      setError(e.message);
      setStatusCode(e.response.status);
      setResponse(e.response.data);
    } finally {
      setIsLoading(false);
    }
  };

  return { send, isLoading, error, statusCode, response };
};

export default useRequest;
