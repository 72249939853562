import { useEffect, useState } from 'react';

import rootStore from '../../../../../stores/rootStore/rootStore';
import useAdapterStatistic from '../../../../Admin/AdminSystemObject/ASOMonitoring/hooks/useAdapterStatistic';
import { IWidgetPieInfo } from '../../../../Panel/StatisticsPanel/MonitoringCard/models/monitoring.model';
import getAdapterData from '../helpers/getAdapterData';

const APP_PATH =
  'DetailedStatistics.Monitoring.AdaptersCard.hooks.useAdaptersCard';

const useAdaptersCard = () => {
  const { adapterIds } = rootStore.monitoringPanelStore;
  const { regionData } = rootStore.uiStore;

  const [dataPieChart, setDataPieChart] = useState<IWidgetPieInfo>();

  const { statistic, isLoading, isError } = useAdapterStatistic({
    appPath: APP_PATH,
    regionIds: regionData?.id,
    isEnabled: !!regionData?.id,
  });

  useEffect(() => {
    if (!adapterIds.length) return;

    setDataPieChart(getAdapterData(statistic, adapterIds));
  }, [adapterIds, statistic]);

  return { dataPieChart, isLoading, isError };
};

export default useAdaptersCard;
