import { ReactNode, FC } from 'react';

import styles from './BlockWrapper.module.scss';

interface WrapperProps {
  children: JSX.Element | ReactNode;
}

const BlockWrapper: FC<WrapperProps> = ({ children }) => (
  <div className={styles.settingsContent}>{children}</div>
);

export default BlockWrapper;
