import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { IoIosArrowForward } from 'react-icons/io';

import rootStore from '../../../stores/rootStore/rootStore';

import styles from './BreadCrumbs.module.scss';

export interface IBreadCrumbsItem {
  label: string;
  key: string;
}

interface IBreadCrumbs {
  breadCrumbsItems: IBreadCrumbsItem[];
}

const BreadCrumbs: FC<IBreadCrumbs> = ({ breadCrumbsItems }) => {
  const { setAdminsData, adminTableType, adminPanelKeys } =
    rootStore.adminPanelStore;

  const handleClick = (key: string) => {
    setAdminsData('adminTableType', key);

    if (adminPanelKeys) {
      const keys = [...adminPanelKeys];
      const newKeys = [];

      for (let i = 0; i < keys.length; i++) {
        newKeys.push(keys[i]);

        if (keys[i] === key) break;
      }

      setAdminsData('adminPanelKeys', newKeys.reverse());
    }
  };

  const getClassNames = (key: string) => {
    return classNames({
      [styles.btn]: true,
      [styles.btnActive]: key === adminTableType,
    });
  };

  return (
    <ul className={styles.list}>
      {breadCrumbsItems.map((item, i) => (
        <li key={item.key}>
          <button
            onClick={() => handleClick(item.key)}
            className={getClassNames(item.key)}
          >
            {item.label}
          </button>
          {i < breadCrumbsItems.length - 1 && <IoIosArrowForward />}
        </li>
      ))}
    </ul>
  );
};

export default observer(BreadCrumbs);
