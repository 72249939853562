import { uniqueId } from 'lodash';
import { FC } from 'react';

import {
  TYPE_COLORS,
  TYPE_OF_TACTS,
} from '../../../../constants/signalsConstants';
import {
  ICsdData,
  IDirections,
} from '../../../../ts/models/signalPrograms.model';
import { getTactWidth } from '../../helpers/getTactWidth';

import styles from './Direction.module.scss';

interface IDirectionComponent {
  direction: IDirections;
  csdData: ICsdData;
  width: number;
}

const Directions: FC<IDirectionComponent> = ({ direction, csdData, width }) => {
  return (
    <div className={styles.direction}>
      <div className={styles.subTacts}>
        {direction.tacts.map((item) => (
          <div
            key={uniqueId('*')}
            className={styles.subTactItem}
            style={{
              width: getTactWidth(width, csdData.circle, item.length) + 'px',
              background: TYPE_COLORS[item.type || TYPE_OF_TACTS.OTHER],
            }}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Directions;
