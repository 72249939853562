import { Button, Form } from 'antd';
import { type FormInstance } from 'antd/lib';
import { isEqual } from 'lodash';
import { FC, useEffect, useState } from 'react';

import { QueryParamItem } from '../../../../../../ts/models/table.model';
import { getQueryParamsBody } from '../../../helpers/getQueryParamsBody/getQueryParamsBody';
import { getFormFieldsetValues, ValuesType } from '../helpers';

import styles from './BtnsBlock.module.scss';

interface IBtnsBlock {
  form: FormInstance;
  isChangeForm: boolean;
  queryParams: QueryParamItem[];
  handleSubmitServerSearch: () => Promise<void>;
  setDefaultFromValues: (type: ValuesType) => void;
  onClose: () => void;
}

const TEXT = {
  sumbit: 'Отправить',
  close: 'Закрыть',
  cancel: 'Сбросить',
};

const BtnsBlock: FC<IBtnsBlock> = ({
  form,
  queryParams,
  isChangeForm,
  handleSubmitServerSearch,
  setDefaultFromValues,
  onClose,
}) => {
  const [isReadySubmit, setIsReadySubmit] = useState(false);
  const [isDisabledReset, setIsDisabledReset] = useState(false);

  const values = Form.useWatch([], form);

  useEffect(() => {
    if (!values) return;

    const isDisabled = !isEqual(
      getFormFieldsetValues(queryParams, 'defaultValue', {
        isReturnDate: false,
      }),
      getQueryParamsBody(values, queryParams, true)
    );

    setIsDisabledReset(!isDisabled);

    form
      .validateFields({ validateOnly: true })
      .then(() => {
        setIsReadySubmit(true);
      })
      .catch(() => {
        setIsReadySubmit(false);
      });
  }, [form, queryParams, values]);

  const handleResetForm = () => {
    setDefaultFromValues('defaultValue');
  };

  const handleSubmit = () => {
    handleSubmitServerSearch();
  };

  const isDisabledSubmit = !isReadySubmit || !isChangeForm;

  return (
    <div className={styles.btnsWrap}>
      <Button type="primary" onClick={handleSubmit} disabled={isDisabledSubmit}>
        {TEXT.sumbit}
      </Button>
      <Button onClick={handleResetForm} disabled={isDisabledReset}>
        {TEXT.cancel}
      </Button>
      <Button type="primary" danger onClick={onClose}>
        {TEXT.close}
      </Button>
    </div>
  );
};

export default BtnsBlock;
