import dayjs from 'dayjs';

import { IRegionData } from '../../../../api/server/web/web.zod';
import { HOUR } from '../../../../constants/constants';
import {
  IWeatherDataset,
  IWeatherTimeModel,
} from '../../../Map/IWeatherTimeModel';

const HOURS_STEP = 1;

const HOURS_MS = HOURS_STEP * HOUR;

const STEPS = 72;

export function getStartDate() {
  const date = new Date();

  date.setMinutes(0);
  date.setSeconds(0);
  date.setHours(0);

  return date;
}

export function getCurrentDate(regionData?: N<IRegionData>) {
  const date = new Date();

  const timeZone = regionData?.timeZone ?? 0;

  date.setMinutes(0);
  date.setSeconds(0);

  date.setHours(timeZone + date.getUTCHours());

  return date;
}

export function formatDate(date: number) {
  return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
}

export function formatVisualDate(date: number) {
  return dayjs(date).locale('ru').format('HH:mm');
}

export function getTimes(
  weatherDatasets: IWeatherDataset[],
  regionData?: N<IRegionData>
) {
  const timeZone = regionData?.timeZone ? regionData.timeZone : 0;

  if (weatherDatasets.length > 0) {
    return weatherDatasets.map((item) => {
      const { time } = item;

      const normalizedDate = new Date(time);
      const hour = normalizedDate.getHours();

      let title =
        hour === 0
          ? dayjs(normalizedDate).locale('ru').format('DD MMMM')
          : dayjs(normalizedDate).locale('ru').format('dd HH:mm');

      if (!title) {
        title = '';
      }

      const subtitle = formatVisualDate(new Date(time).getTime());

      const result: IWeatherTimeModel = {
        time,
        title,
        subtitle,
      };

      return result;
    });
  }

  const items = [];

  const startMs = getStartDate().getTime() + timeZone * HOURS_MS;

  for (let i = 0; i < STEPS; i++) {
    const time = formatDate(startMs + i * HOURS_MS);
    const subtitle = formatVisualDate(startMs + i * HOURS_MS);

    const timeItem: IWeatherTimeModel = { time, subtitle };

    items.push(timeItem);
  }

  return items;
}

export function getCurrentTimeSlide(regionData?: N<IRegionData>) {
  const timeZone = regionData?.timeZone ?? 0;
  const date = new Date();

  return date.getUTCHours() + timeZone;
}
