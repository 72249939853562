import api from '../../../api';
import { Path } from '../../../api/api.model';
import rootStore from '../../../stores/rootStore/rootStore';
import { isLibraryKey } from '../../../stores/uiStore/typeGuards/typeGuards';
import { DictKeys, LibraryValues } from '../../../stores/uiStore/uiStore.model';

const { getLibrariesByKey } = api.libraries;

const APP_PATH = 'hooks.useLibraries.fetchDictValue';

type CallbackType<T extends DictKeys> = (
  key: T,
  appPath: Path
) => Promise<U<LibraryValues[]>>;

const fetchDictValue = async <T extends DictKeys>(
  dictKey: T,
  callback?: CallbackType<T>
) => {
  const { setDictionaryValue } = rootStore.uiStore;

  let dict: U<LibraryValues[]> = [];

  if (isLibraryKey(dictKey)) {
    dict = await getLibrariesByKey(dictKey, APP_PATH);
  }

  if (callback) {
    dict = await callback(dictKey, APP_PATH);
  }

  setDictionaryValue(dictKey, dict ?? []);
};

export default fetchDictValue;
