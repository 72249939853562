import { findById } from '../../../../../helpers/findBy';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { System } from '../../../../../ts/enums/enums';
import { ClusterLinks } from '../../../ClusterInfo/model/cluster.model';

const { Cameras, Detectors } = System;

interface VideoDts {
  cm: number;
  dt: number;
}

type Links = [System, number[]][];

const spliceId = (ids: number[], id: number) => {
  const deviceIdx = ids.findIndex((deviceId) => id === deviceId);

  deviceIdx !== undefined && ids.splice(deviceIdx, 1);
};

const getLinks = (linkedDevices: ClusterLinks, cameraId?: N<number>) => {
  const { cameras, detectors } = rootStore.mapDataStore;

  const cmIds = [...(linkedDevices[Cameras] ?? [])];
  const dtIds = [...(linkedDevices[Detectors] ?? [])];

  cameraId && cmIds.push(cameraId);

  const videoDtIds = [...cmIds].reduce((res: VideoDts[], id: number) => {
    const cm = findById(cameras, id);
    const dtId = cm?.dai_jdt83?.linkedDetectorId;
    const isDtExist = detectors.some((dt) => dt.id === dtId);

    cameraId === id && !isDtExist && spliceId(cmIds, id);

    if (isDtExist && dtId) {
      res.push({ cm: id, dt: dtId });
      spliceId(cmIds, id);
      spliceId(dtIds, id);
    }

    return res;
  }, []);

  const filteredLinkedDevices = { ...linkedDevices };

  filteredLinkedDevices[Cameras] = cmIds;
  filteredLinkedDevices[Detectors] = dtIds;
  const links = Object.entries(filteredLinkedDevices) as Links;

  return { links, videoDtIds };
};

export default getLinks;
