import {
  DEVICES_INFO_STATUS,
  TKeysStatus,
} from '../../constants/devicesConstants';
import { System } from '../../ts/enums/enums';
import { ICamera } from '../../ts/models/camera.model';
import { Detector } from '../../ts/models/mapObject.model';
import { TMeteo } from '../../ts/models/MapObjects/meteo.model';
import { TL } from '../../ts/models/tl.model';
import { TObjMap } from '../mapDataStore/mapDataStore.model';

interface IWidgetsData {
  tls: TL[];
  detectors: Detector[];
  cameras: ICamera[];
  meteo: TMeteo[];
}

interface ITotals {
  total: number;
  ratio: number;
  disabled: number;
}

export type TStatusDeviceInfoTotal = Record<TKeysStatus, number> & ITotals;

const getStatusCount = (mapObj: TObjMap[]): TStatusDeviceInfoTotal => {
  const statusObj: Record<TKeysStatus, number> = {
    Work: 0,
    NotWork: 0,
    Error: 0,
    NotConnection: 0,
    Undefined: 0,
    Delete: 0,
    NotAvailable: 0,
  };

  const statusesTotal = mapObj.reduce((acc, el) => {
    const {
      deviceInfo: { deviceStatusId },
    } = el;

    const correctStatus =
      DEVICES_INFO_STATUS.find((el) => deviceStatusId === el[1]) ??
      DEVICES_INFO_STATUS[0];

    acc[correctStatus[0]] += 1;

    return acc;
  }, statusObj);

  const total = mapObj.length;

  const { NotWork, Delete, NotAvailable, NotConnection, Undefined } =
    statusesTotal;

  return {
    ...statusesTotal,
    total,
    ratio: Math.round((statusesTotal.Work * 100) / total),
    disabled: NotWork + Delete + NotAvailable + NotConnection + Undefined,
  };
};

export type TStatusInfoDvc = TStatusDeviceInfoTotal & { system: System };

export type TWidgetsDataKeys =
  | 'widgetTLs'
  | 'widgetDTs'
  | 'widgetCMRs'
  | 'widgetMTs';

export type TWidgetsData = Record<TWidgetsDataKeys, TStatusInfoDvc>;

export const getWidgetsData = ({
  tls,
  detectors,
  cameras,
  meteo,
}: IWidgetsData): TWidgetsData => ({
  widgetTLs: { ...getStatusCount(tls), system: System.Lights },
  widgetDTs: { ...getStatusCount(detectors), system: System.Detectors },
  widgetCMRs: { ...getStatusCount(cameras), system: System.Cameras },
  widgetMTs: { ...getStatusCount(meteo), system: System.Meteo },
});
