import { Button } from 'antd';
import classNames from 'classnames';
import { uniqueId } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { FiInfo } from 'react-icons/fi';

import rootStore from '../../../../../stores/rootStore/rootStore';
import { TlMode } from '../../../../../ts/models/tl.model';
import Popover from '../../../../ui-kit/Popover/Popover';
import { Tip } from '../../model/tlManagement.model';

import TooltipCommandContent from './TooltipCommandContent/TooltipCommandContent';

import styles from './CommandCard.module.scss';

export interface CommandCardProps {
  title: string;
  colorCommand: string;
  disabled?: boolean;
  selected: boolean;
  infoTooltip: Tip;
  mode: TlMode;
  blocked?: boolean;
  isImgLoading?: boolean;
  onClick?: (value: TlMode) => void;
  isStatus?: boolean;
}

const CommandCard: FC<CommandCardProps> = ({
  title,
  colorCommand,
  disabled = false,
  selected,
  infoTooltip,
  mode,
  blocked = false,
  isImgLoading = true,
  isStatus = false,
  onClick,
}) => {
  const { tipsValues } = rootStore.uiStore;

  const textBtn = selected ? 'Текущий' : 'Включить';
  const isBtnDisabled = disabled || selected || !onClick;
  const tooltipBtn = selected ? 'Данный режим уже включен' : infoTooltip.btn;

  return (
    <div
      className={classNames({
        [styles.container]: true,
        [styles.disabledCard]: disabled,
        [styles.active]: selected,
      })}
    >
      <div className={styles.headerCard}>
        <Popover
          key={uniqueId()}
          placement="right"
          tipsDelay={tipsValues.interfaceTipsDelay}
          content={
            <TooltipCommandContent
              title={title}
              infoTooltip={infoTooltip}
              mode={mode}
              blocked={blocked}
              isCurrentMode={selected}
              isStatus={isStatus}
            />
          }
        >
          <FiInfo className={styles.infoBtn} />
        </Popover>
      </div>
      <div className={styles.titleCard}>
        <div
          className={styles.circleCommand}
          style={{ backgroundColor: colorCommand }}
        />
        <p>{title}</p>
      </div>
      {onClick ? (
        <div className={styles.footerCard}>
          <Popover
            key={uniqueId()}
            placement="right"
            tipsDelay={tipsValues.interfaceTipsDelay}
            content={tooltipBtn}
          >
            <Button
              disabled={isBtnDisabled}
              type="primary"
              size="small"
              loading={!isImgLoading}
              onClick={() => {
                onClick(mode);
              }}
            >
              {textBtn}
            </Button>
          </Popover>
        </div>
      ) : null}
    </div>
  );
};

export default observer(CommandCard);
