import { FC } from 'react';

import { COLORS } from '../../../constants/colorsConstants';
import { ICON_SIZE } from '../../Constructor/constants/constants';
import MapSquareIco from '../../Svg/MapSquareIco/MapSquareIco';
import DeviceWrapper from '../DeviceWrapper/DeviceWrapper';
import { DeviceIconProps } from '../models/device.model';

const DeviceIcon: FC<DeviceIconProps> = (props) => {
  const {
    isVisible = true,
    size = ICON_SIZE.DEFAULT,
    onClick,
    children,
    className,
    stroke = COLORS.WHITE,
    fill = '',
    ...wrapperProps
  } = props;

  if (!isVisible) return null;

  return (
    <DeviceWrapper {...wrapperProps} size={size}>
      <MapSquareIco
        width={size}
        height={size}
        fill={fill ?? COLORS.PRIMARY}
        className={className}
        stroke={stroke}
        onClick={onClick}
      />
      {children}
    </DeviceWrapper>
  );
};

export default DeviceIcon;
