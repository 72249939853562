import { Tag } from 'antd';
import { uniqueId } from 'lodash';
import { FC } from 'react';

import { LibraryKeysZod } from '../../../../../../../api/libraries/libraries.zod';
import { getParsedLibraryValue } from '../getParsedLibraryValue';

import styles from './ArrayRender.module.scss';

interface ArrayRenderProps {
  data: any;
  library?: LibraryKeysZod[];
}

export const ArrayRender: FC<ArrayRenderProps> = ({ data, library }) => {
  return (
    <ul className={styles.list}>
      {data.length
        ? data.map((value: number) => (
            <li key={uniqueId(`Ntr5cn_`)}>
              <Tag>
                {library ? getParsedLibraryValue(value, library) : value}
              </Tag>
            </li>
          ))
        : 'Список пуст'}
    </ul>
  );
};
