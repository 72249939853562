import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { getTLControlInfo } from '../../../../../../../../Panel/ScriptsControl/SelectModule/DispatchGroupControl/DipatchGroupDetailed/ControlCard/helpers/helpers';
import WarningNotify from '../../../../../../../../TrafficLightDetailed/TlManagement/StatusCard/WarningNotify/WarningNotify';

import styles from './Warnings.module.scss';

interface WarningsProps {
  id: number;
}

const Warnings: FC<WarningsProps> = ({ id }) => {
  const tlControlInfo = getTLControlInfo(id);

  if (!tlControlInfo) return null;

  const { isActiveCoordTL, isAccessMode, warningText } = tlControlInfo;

  const warningTitle = `${warningText}. По нему не будет отправлена команда`;

  return (
    <div className={styles.container}>
      <WarningNotify
        isActiveNotify={!isAccessMode}
        warningTitle={warningTitle}
      />
      <WarningNotify isActiveNotify={isActiveCoordTL} />
    </div>
  );
};

export default observer(Warnings);
