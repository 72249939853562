import { TLDetailedCode } from '../../../../ts/enums/userData';
import TlDetectors from '../../../TrafficLightDetailed/TlDetectors/TlDetectors';

import { PanelTabConfig } from './constants';

export const DETAILED_DETECTORS_ITEMS: PanelTabConfig[] = [
  {
    label: 'Основная информация',
    key: '1',
    code: TLDetailedCode.MainInfo,
    Component: TlDetectors,
    isLoaded: false,
  },
];
