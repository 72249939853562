import { List } from 'antd';
import { PaginationConfig } from 'antd/lib/pagination';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';

import rootStore from '../../../../stores/rootStore/rootStore';
import ButtonOnlyIcon from '../../../ui-kit/ButtonOnlyIcon/ButtonOnlyIcon';
import Title, { ITitle } from '../../../ui-kit/Title/Title';

import { getTlsDataList, IDataTlsList } from './helpers/getTlsDataList';

import styles from './TlsList.module.scss';

type IDefaultProps = Pick<ITitle, 'placement' | 'titleStyle'>;

interface TlsListProps {
  tlsIds: number[];
  pageSize?: number;
  isNeedActive?: boolean;
  handleAction?: (id: number) => void;
}

const TITLE_LEN = 30;
const DSC_LEN = 45;

const PAGINATION_PROPS: PaginationConfig = {
  position: 'bottom',
  align: 'end',
  size: 'small',
};

const TlsList: FC<TlsListProps> = ({
  tlsIds,
  pageSize,
  isNeedActive = false,
  handleAction,
}) => {
  const { tls } = rootStore.mapDataStore;
  const { selectedIds, selectedGroup } = rootStore.scriptsControlStore;
  const [dataTlsList, setDataTlsList] = useState<IDataTlsList[]>([]);

  useEffect(() => {
    setDataTlsList(getTlsDataList(tlsIds, tls));
  }, [tlsIds, tls]);

  const defaultTitleProps: IDefaultProps = {
    placement: 'right',
    titleStyle: { textAlign: 'left' },
  };

  const paginationProps =
    pageSize && dataTlsList.length > pageSize
      ? { ...PAGINATION_PROPS, pageSize }
      : undefined;

  return (
    <List
      pagination={paginationProps}
      itemLayout="horizontal"
      dataSource={dataTlsList}
      renderItem={({ avatar, description, title, id }) => {
        const isActiveItem =
          selectedIds.some((tlId) => tlId === id) && !selectedGroup;

        const containerStyle = classNames({
          [styles.container]: true,
          [styles.active]: isActiveItem,
        });

        return (
          <List.Item
            key={title}
            actions={
              handleAction
                ? [
                    <ButtonOnlyIcon
                      onClick={() => handleAction(id)}
                      key="delete"
                      isSmall
                      isDanger
                    >
                      <AiOutlineDelete />
                    </ButtonOnlyIcon>,
                  ]
                : undefined
            }
          >
            <List.Item.Meta
              className={isNeedActive ? containerStyle : undefined}
              avatar={avatar}
              title={
                <Title
                  {...defaultTitleProps}
                  title={title}
                  maxLength={TITLE_LEN}
                />
              }
              description={
                <Title
                  {...defaultTitleProps}
                  title={description}
                  maxLength={DSC_LEN}
                />
              }
            />
          </List.Item>
        );
      }}
    />
  );
};

export default observer(TlsList);
