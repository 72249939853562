import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { BsArrowUp } from 'react-icons/bs';

import rootStore from '../../../../../../../stores/rootStore/rootStore';
import Popover from '../../../../../../ui-kit/Popover/Popover';

import { getDirWind } from './helpers/getDirWind';

import styles from './Compass.module.scss';

interface ICompassProps {
  speed: N<SN>;
  dir: N<number>;
}

const Compass: FC<ICompassProps> = ({ speed, dir }) => {
  const { interfaceTipsDelay } = rootStore.uiStore;

  const isEmptyData = speed === null && dir === null;
  const contentTooltip = !isEmptyData
    ? `Скорость ветра: ${speed ?? 'нет данных'}, направление: ${
        getDirWind(dir) || 'неизвестно'
      }`
    : 'Скорость и направление ветра неизвестны';

  return (
    <Popover tipsDelay={interfaceTipsDelay} content={contentTooltip}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.border}>
            <p className={styles.north}>С</p>
            <p className={styles.south}>Ю</p>
            <p className={styles.west}>З</p>
            <p className={styles.east}>В</p>
            <p className={styles.speed}>{speed ?? ''}</p>
            <div className={styles.arrow}>
              {dir !== null ? (
                <BsArrowUp
                  style={{ transform: `rotate(${dir}deg) scale(1, -1)` }}
                />
              ) : (
                <p>Нет данных</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </Popover>
  );
};

export default observer(Compass);
