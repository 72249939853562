import { FC } from 'react';

import { COMMAND_TITLES } from '../../../Timer/constants/constants';
import Timer from '../../../Timer/Timer';
import { TimerEntries } from '../Timers';

import styles from './TimerItem.module.scss';

interface TimerItemProps {
  item: TimerEntries[number];
}

const TimerItem: FC<TimerItemProps> = ({ item }) => {
  const [commandKey, values] = item;

  const titles = COMMAND_TITLES[commandKey];

  if (!titles) return null;

  return (
    <div className={styles.container}>
      <Timer {...values} titles={titles} />
    </div>
  );
};

export default TimerItem;
