import { cloneDeep } from 'lodash';

import { TYPE_OF_TACTS } from '../../../../../constants/signalsConstants';
import { findBy } from '../../../../../helpers/findBy';
import {
  ICsdData,
  IDirections,
  ITacts,
} from '../../../../../ts/models/signalPrograms.model';
import { getPhasesMarks } from '../../../../NewSignalProgram/helpers/createCsdGraphData';
import { IPhasesData } from '../../PhaseHold/PhaseHold.model';

const { GREEN_FLASH, YELLOW, YELLOW_AND_RED, RED } = TYPE_OF_TACTS;

export const editSignalProgram = (
  data: IPhasesData[],
  csdData: U<ICsdData>
) => {
  if (!csdData) return;

  const { directions, tacts } = csdData;

  if (!directions || !tacts) return;

  const newCsdDirections = directions.reduce(
    (acc: N<IDirections[]>, item: IDirections) => {
      const { tacts } = item;

      const newColors = tacts.reduce((acc: N<ITacts[]>, el) => {
        const phaseEdit = findBy(data, el.phase, 'phase');

        const editedPhaseData = cloneDeep(el);

        if (!phaseEdit || !acc) return null;

        if (
          phaseEdit?.spanRedYellowTime &&
          editedPhaseData.type === RED &&
          editedPhaseData.step !== 1
        ) {
          const redYellowType = {
            ...editedPhaseData,
            type: YELLOW_AND_RED,
            length: phaseEdit.spanRedYellowTime,
          };
          const isSumPrTact =
            phaseEdit.spanGreenBlinkTime +
              phaseEdit.spanYellowTime +
              redYellowType.length ===
            phaseEdit.spanTime;

          if (isSumPrTact) {
            return [...acc, redYellowType];
          }
          const redType: ITacts = {
            ...editedPhaseData,
            type: RED,
            length:
              phaseEdit.spanTime -
              (phaseEdit.spanGreenBlinkTime + phaseEdit.spanYellowTime),
          };

          return [...acc, redYellowType, redType];
        }

        switch (editedPhaseData.type) {
          case GREEN_FLASH:
            editedPhaseData.length =
              phaseEdit.spanGreenBlinkTime ?? editedPhaseData.length;
            break;
          case YELLOW:
            editedPhaseData.length =
              phaseEdit.spanYellowTime ?? editedPhaseData.length;
            break;
          case YELLOW_AND_RED:
            editedPhaseData.length =
              phaseEdit.spanRedYellowTime ?? editedPhaseData.length;
            break;
          default:
            if (!phaseEdit) break;

            if (editedPhaseData.onlygreen) {
              editedPhaseData.length = phaseEdit.time + phaseEdit.spanTime;

              break;
            }

            if (editedPhaseData.step === 1) {
              editedPhaseData.length = phaseEdit.time + phaseEdit.spanTime;

              break;
            }

            if (phaseEdit?.spanRedYellowTime === 0) {
              editedPhaseData.length =
                phaseEdit.spanTime -
                (phaseEdit.spanGreenBlinkTime + phaseEdit.spanYellowTime);
            }
        }

        return [...acc, editedPhaseData];
      }, []);

      if (!newColors || !acc) return null;

      return [...acc, { ...item, tacts: newColors }];
    },
    []
  );

  const newTacts = tacts.map((el) => {
    const phaseEdit = findBy(data, el.phase, 'phase');

    if (!phaseEdit) return el;

    el.time = el.main ? phaseEdit.time : phaseEdit.spanTime;

    return el;
  });

  const { timePoints, stopCount } = getPhasesMarks(newTacts);

  return {
    newCsdDataPhase: {
      circle: stopCount,
      directions: newCsdDirections,
      tacts: newTacts,
    },
    newPhasesSet: timePoints,
    isError: Boolean(!newCsdDirections),
  };
};
