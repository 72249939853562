import { useEffect, useState } from 'react';

import {
  NO_WEATHER_DATA,
  StyleBlock,
} from '../components/Sidebar/InfoBlock/constants/constants';
import { SECOND } from '../constants/constants';
import { getDayInfo } from '../helpers/formatDate';
import rootStore from '../stores/rootStore/rootStore';
import { SidebarCode } from '../ts/enums/userData';

import useWeather from './useWeather';

const useDayInfo = () => {
  const { hasAccess } = rootStore.userDataStore;
  const [{ time, timezone, day, dayName }, setDayInfo] = useState(getDayInfo());

  const isDisableWeather = !hasAccess(SidebarCode.Weather);

  const {
    weatherData,
    isError,
    isLoading: isWeatherLoading,
  } = useWeather(isDisableWeather);

  useEffect(() => {
    const timerIdDayInfo = setInterval(() => setDayInfo(getDayInfo()), SECOND);

    return () => {
      clearInterval(timerIdDayInfo);
    };
  }, []);

  const baseReturn = [
    { firstRaw: time, secondRaw: `GMT ${timezone}`, style: StyleBlock.InfoTop },
    {
      firstRaw: day,
      secondRaw: dayName,
      style: !isDisableWeather ? StyleBlock.InfoCenter : StyleBlock.InfoBottom,
    },
  ];

  if (isDisableWeather) return baseReturn;

  const { temp, weather } = isError ? NO_WEATHER_DATA : weatherData;

  return [
    ...baseReturn,
    {
      firstRaw: temp,
      secondRaw: weather ?? 'без осадков',
      style: StyleBlock.InfoBottom,
      isLoading: isWeatherLoading,
    },
  ];
};

export default useDayInfo;
