import { useCallback, useEffect, useRef, useState } from 'react';

const useHandleMove = () => {
  const container = useRef<HTMLDivElement>(null);
  const [isMainContent, setIsMainContent] = useState(true);

  const handleMouseEnter = useCallback(() => {
    setIsMainContent(false);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsMainContent(true);
  }, []);

  useEffect(() => {
    const element =
      container.current?.getElementsByClassName('ant-card-body')?.[0];

    element?.addEventListener('mouseenter', handleMouseEnter);
    element?.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      element?.removeEventListener('mouseenter', handleMouseEnter);
      element?.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [handleMouseEnter, handleMouseLeave]);

  return { isMainContent, container };
};

export default useHandleMove;
