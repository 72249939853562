import { IPhasesData } from '../../../../PhaseHold/PhaseHold.model';
import { TitleTable } from '../../constants/constants';
import { validateTableSgnProgram } from '../constants/constants';

export const onChangeTablePhases = (
  value: N<number>,
  newRows: IPhasesData,
  isPhasesTable: boolean,
  dataIndex: keyof typeof TitleTable,
  durationMinArray: number[]
) => {
  const { spanGreenBlinkTime, spanYellowTime, spanRedYellowTime, spanTime } =
    newRows;

  const { mainTact, minFlash, colorFlash, maxTact } = validateTableSgnProgram;

  if (value === null) {
    return {
      isError: true,
      resultValidate: Promise.reject(new Error()),
    };
  }

  let errorText = '';

  const durMin = durationMinArray[newRows.phase - 1] ?? mainTact;

  const isEditPromTact = TitleTable[dataIndex] !== TitleTable.time;
  const isMainTact = TitleTable[dataIndex] === TitleTable.time;
  const isPromTact = TitleTable[dataIndex] === TitleTable.spanTime;
  const isFlash = TitleTable[dataIndex] !== TitleTable.spanTime;

  const isNumbers =
    !isPhasesTable &&
    isEditPromTact &&
    spanGreenBlinkTime !== undefined &&
    spanYellowTime !== undefined &&
    spanRedYellowTime !== undefined;

  if (!Number.isInteger(value))
    errorText = `Длина может быть только целым числом.`;

  if (value > maxTact)
    errorText = `Длина такта не может быть больше ${maxTact}с.`;

  if (isMainTact && value < durMin)
    errorText = `Длина основного такта не может быть меньше ${durMin}с.`;

  if (isPromTact && value < durMin)
    errorText = `Длина пром такта не может быть меньше ${durMin}с.`;

  if (isFlash && value < colorFlash && value > minFlash)
    errorText = `Длина "${TitleTable[
      dataIndex
    ].toLowerCase()}" должна быть больше ${minFlash} и меньше ${colorFlash}`;

  if (isNumbers) {
    const isUncorrectSumm =
      spanGreenBlinkTime + spanYellowTime + spanRedYellowTime > spanTime;

    if (isUncorrectSumm)
      errorText = `Сумма полей: мигание зеленым, желтым, желт. красным не может быть больше промежуточного такта. (Пром такт = ${spanTime}c.)!`;
  }

  const basicResult = {
    isError: Boolean(errorText),
  };

  if (errorText)
    return {
      ...basicResult,
      resultValidate: Promise.reject(new Error(errorText)),
    };

  return {
    ...basicResult,
    resultValidate: Promise.resolve(),
  };
};
