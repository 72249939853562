import dayjs from 'dayjs';

import rootStore from '../../../../../../../stores/rootStore/rootStore';

import 'dayjs/locale/ru';
import { MINUTES_IN_HOUR } from './getTimeWithTimeZone';

export const getTimeZones = () => {
  const { regionData } = rootStore.uiStore;
  const timeZoneClient = dayjs().utcOffset() / MINUTES_IN_HOUR;

  if (!regionData) return { timeZoneRegion: 0, timeZoneClient };

  const { timeZone } = regionData;
  const isOurTimeZone = timeZoneClient === timeZone;

  return { timeZoneRegion: timeZone, isOurTimeZone, timeZoneClient };
};
