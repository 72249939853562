import layersApi from '../../api/integration/gis/layers/index';

const layersService = {
  async getList(appPath: string, regionId: number) {
    const data = await layersApi.getList(appPath, regionId);

    return { data: data ?? [], hasIntegrationGIS: !!data?.length };
  },
  async getDefinition(appPath: string, url: string) {
    const data = await layersApi.getDefinition(appPath, url);

    return { data: data ?? null, isOk: Boolean(data?.length) };
  },
};

export default layersService;
