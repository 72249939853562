import { TActiveSystemMonitoring } from '../../../../stores/monitoringPanelStore/monitoringPanelStore.model';
import { System } from '../../../../ts/enums/enums';
import { ALL_SYSTEM_TITLE } from '../../constants/constants';

export const getCorrectNameSystem = (system: TActiveSystemMonitoring) => {
  switch (system) {
    case System.Lights:
      return 'светофорного управления';
    case System.Detectors:
      return 'мониторинга параметров ТП';
    case System.Cameras:
      return 'видеонаблюдения';
    case System.Meteo:
      return 'метеостанций';
    case ALL_SYSTEM_TITLE:
      return 'Все подсистемы';
    default:
      return 'неизвестна';
  }
};
