import { type DatePickerProps } from 'antd/lib';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';

import {
  DATE_DEFAULT_FORMAT,
  MONTH_FORMAT,
  WEEK_FORMAT,
  YEAR_FORMAT,
} from '../../../../../../../constants/constants';
import { VALUE_TYPES } from '../../../../../../../ts/models/table.model';

import checkReversedFormat from './checkReversedFormat';
import formattedDate from './formattedDate';

dayjs.extend(weekOfYear);

const PICKER_VALUES: Record<string, DatePickerProps['picker']> = {
  [YEAR_FORMAT]: 'year',
  [WEEK_FORMAT]: 'week',
  [MONTH_FORMAT]: 'month',
};

const customWeekStartEndFormat =
  (weekFormat: string): DatePickerProps['format'] =>
  (value) => {
    const dateNow = dayjs(value).locale('ru');

    return `${dateNow.startOf('week').format(weekFormat)} ~ ${dateNow
      .endOf('week')
      .format(weekFormat)}`;
  };

interface CorrectFormatReturn {
  dateFormat: DatePickerProps['format'];
  isRange: boolean;
  picker?: DatePickerProps['picker'];
  isTime: boolean;
}

const getCorrectFormat = (
  format: string,
  jvalueType: ValueOf<typeof VALUE_TYPES>
): N<CorrectFormatReturn> => {
  const correctionFormat = format.trim().toUpperCase();

  if (jvalueType === VALUE_TYPES.datePickerJson) {
    const isWeekFormat = checkReversedFormat(correctionFormat, WEEK_FORMAT);

    if (isWeekFormat) {
      return {
        dateFormat: customWeekStartEndFormat(DATE_DEFAULT_FORMAT),
        isRange: false,
        picker: PICKER_VALUES[WEEK_FORMAT],
        isTime: false,
      };
    }
  }

  const reservedFormat = correctionFormat.split('').reverse().join('');
  const isTime = correctionFormat.split(',').length === 2;

  return {
    dateFormat: formattedDate(correctionFormat),
    isRange: jvalueType === VALUE_TYPES.rangeDatePickerJson,
    picker: PICKER_VALUES[correctionFormat] ?? PICKER_VALUES[reservedFormat],
    isTime,
  };
};

export default getCorrectFormat;
