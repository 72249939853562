import classNames from 'classnames';

import styles from './ExtraFooter.module.scss';

interface ExtraFooterProps {
  timeFooter: string[];
  isTime: boolean;
  isTimeFooter: boolean;
}

const ExtraFooter = ({
  timeFooter,
  isTime,
  isTimeFooter,
}: ExtraFooterProps) => {
  const isTimeFooterDisplay = isTime && isTimeFooter;

  const footerStyle = classNames({
    [styles.container]: true,
    [styles.formatOne]: timeFooter.length === 1,
    [styles.formatTwo]: timeFooter.length === 2,
    [styles.formatThree]: timeFooter.length === 3,
  });

  if (!isTimeFooterDisplay) return null;

  return (
    <div className={styles.footer}>
      <div className={footerStyle}>
        {timeFooter.map((el) => (
          <p key={el}>{el}</p>
        ))}
      </div>
    </div>
  );
};

export default ExtraFooter;
