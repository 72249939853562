import { TlJournalType } from '../../../../constants/detailedTabsConstant';
import { VERSION } from '../../../../constants/versions';
import {
  IQueryParamRequest,
  TableData,
} from '../../../../ts/models/table.model';
import http from '../../../http';
import tryCatchWrapper from '../../../tryCatchWrapper';

const OBJECTS = VERSION + '/objects';
const TL = OBJECTS + '/tl/';

export const journal = {
  getJournal: (
    id: number,
    type: TlJournalType,
    queryParamRequest?: IQueryParamRequest
  ) =>
    tryCatchWrapper(async () => {
      const journalData = await http.get(`${TL + id}/journal/${type}`, {
        params:
          queryParamRequest && Object.keys(queryParamRequest).length
            ? {
                queryParamsRequest: queryParamRequest,
              }
            : null,
      });

      const data: TableData = journalData?.data;

      return data;
    }),
};
