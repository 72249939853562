import { type MenuProps } from 'antd/lib';
import { toLonLat } from 'ol/proj';
import { FiMapPin } from 'react-icons/fi';
import { RiRemoteControlLine } from 'react-icons/ri';

import checkIsTLValid from '../../../../../../helpers/checkIsTLValid';
import { TObjMap } from '../../../../../../stores/mapDataStore/mapDataStore.model';
import rootStore from '../../../../../../stores/rootStore/rootStore';
import { System } from '../../../../../../ts/enums/enums';
import { TLDetailedCode } from '../../../../../../ts/enums/userData';
import { UNAVAILABLE } from '../../../../../InfoPanel/constants/constants';
import checkControlAccessTL from '../../../../../InfoPanel/SystemsInfo/InfoBtns/helpers/checkControlAccessTL';
import { getIsCoordinatesOnExtent } from '../../../../../Map/helpers/getIsCoordinatesOnExtent';
import { DISABLED_POPOVER_TEXT } from '../../../../../Panel/MainPanelTabs/AllSystemsTab/constants/constants';
import Popover from '../../../../../ui-kit/Popover/Popover';
import DeviceIcon from '../../../SystemMonitoring/DevicesBlock/DevicesList/DeviceIcon/DeviceIcon';
import { getAvatarIcon } from '../../../SystemMonitoring/helpers/helpers';
import getDeviceBySystem from '../../Mapper/CrossroadSchema/helpers/getDeviceBySystem';
import { DropdownKeys } from '../enums/enums';

const ERROR_TL_CONTROL =
  'Для данного СО недоступен режим просмотра и управления';

const checkIncorrectCoordinates = (device: TObjMap) => {
  const { getCoordinatesById } = rootStore.mapDataStore;

  const coordinates = getCoordinatesById(device.id) ?? [];

  return !getIsCoordinatesOnExtent(toLonLat(coordinates)) || !coordinates;
};

const getDropdownItems = (): MenuProps['items'] => {
  const { interfaceTipsDelay } = rootStore.uiStore;
  const { deviceInfo } = rootStore.monitoringPanelStore;
  const { hasAccess } = rootStore.userDataStore;
  const { isTlsDataFetched } = rootStore.mapDataStore;

  if (!deviceInfo) return [];

  const { id, system } = deviceInfo;

  const device = getDeviceBySystem(id, system);

  if (!device) return [];

  const isMeteo = deviceInfo.system === System.Meteo;
  const isDetailed = !isMeteo && hasAccess(TLDetailedCode.isDetailed);

  const isTL = system === System.Lights;

  const isTLValid = checkIsTLValid(device, system);
  const isDisabledTL = !isTLValid || (isTL && !isTlsDataFetched);

  const { deviceStatusId, controlAccess } = device.deviceInfo;

  const isDisabledMapPin = checkIncorrectCoordinates(device);
  const { isAccessManagementCode, isManagementAccess } =
    checkControlAccessTL(controlAccess);

  const items: MenuProps['items'] = [
    {
      key: DropdownKeys.MapPin,
      label: (
        <Popover
          tipsDelay={isDisabledMapPin ? interfaceTipsDelay : null}
          content={DISABLED_POPOVER_TEXT.UnknownCoordinates}
          placement="left"
        >
          Найти на карте
        </Popover>
      ),
      icon: <FiMapPin />,
      disabled: isDisabledMapPin,
    },
  ];

  if (!isDetailed) return items;

  items.push({
    key: DropdownKeys.Detailed,
    label: (
      <Popover
        tipsDelay={isDisabledTL ? interfaceTipsDelay : null}
        content={UNAVAILABLE}
        placement="left"
      >
        Открыть подробнее
      </Popover>
    ),
    icon: (
      <DeviceIcon
        status={deviceStatusId}
        iconSystem={getAvatarIcon(system)}
        isSmall
      />
    ),
    disabled: isDisabledTL,
  });

  const isDisabledControl = isDisabledTL || !isManagementAccess;

  if (isTL && isAccessManagementCode) {
    items.push({
      key: DropdownKeys.Control,
      label: (
        <Popover
          tipsDelay={isDisabledControl ? interfaceTipsDelay : null}
          content={!isManagementAccess ? ERROR_TL_CONTROL : UNAVAILABLE}
          placement="left"
        >
          Управление СО
        </Popover>
      ),
      icon: <RiRemoteControlLine />,
      disabled: isDisabledControl,
    });
  }

  return items;
};

export default getDropdownItems;
