import { io, Socket } from 'socket.io-client';

const RECONNECTION_DELAY_MAX = 5000;

export interface ILayerSocketDefinition {
  url: string;
  path: string;
}

interface ISocketItem {
  url: string;
  socket: Socket;
}

export class SocketServerPool {
  items: ISocketItem[] = [];

  constructor() {
    this.items = [];
  }

  getSocketServer(props: ILayerSocketDefinition) {
    const { url } = props;

    const poolItem = this.items.find((element) => element.url === url);

    const { socketServerUrl, socketServerPath } =
      this.getSocketServerParams(url);

    const socketItem: ISocketItem = poolItem || {
      url,
      socket: io(socketServerUrl, {
        path: socketServerPath,
        reconnectionDelayMax: RECONNECTION_DELAY_MAX,
      }),
    };

    this.items.push(socketItem);

    return socketItem.socket;
  }

  getSocketServerParams(url: string) {
    const urlParts = this.getUrlParts(url);
    const [partHostname, partUrlParameter] = urlParts;

    const socketServerUrl = `${partHostname}/`;
    const socketServerPath = `/${partUrlParameter}/`;

    return {
      socketServerUrl,
      socketServerPath,
    };
  }

  getUrlParts(url: string) {
    const replaced = url
      .replace(`https://`, ``)
      .replace(`http://`, ``)
      .replace(`wss://`, ``)
      .replace(`ws://`, ``);

    return replaced.split(`/`);
  }
}

export const instance = () => {
  return new SocketServerPool();
};
