import { getDirections } from '../../../apiGIS/helpers/direction';
import { IDirectionPoint } from '../../../apiGIS/models/tasks/tasks.model';

import { IRoutePoint } from './route.model';

const routePointToDirectionPoint = (
  point: IRoutePoint
): IDirectionPoint | undefined => {
  const { x, y } = point;

  if (Number.isFinite(x) && Number.isFinite(y)) {
    return { x: Number(x), y: Number(y) };
  }
};

export const getDirection = async (
  point1: IRoutePoint,
  point2: IRoutePoint
) => {
  if (point1.selected && point2.selected) {
    const directionPoint1 = routePointToDirectionPoint(point1);
    const directionPoint2 = routePointToDirectionPoint(point2);

    if (directionPoint1 && directionPoint2) {
      const results = await getDirections(directionPoint1, directionPoint2);

      if (results && results.length > 0) {
        return results[0];
      }
    }
  }

  return undefined;
};
