import { formatVersion, formatVersions } from '../helpers/formatVersions';

import { CONFIG } from './configConstants';

const VERSIONS_CLEAN = {
  CONSTRUCTOR: 1.2,
};

export const VERSION = formatVersion(CONFIG.VERSION);
export const VERSIONS = formatVersions(VERSIONS_CLEAN);
