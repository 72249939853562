import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { TimeRangePickerProps } from 'antd/lib';
import { RangePickerProps } from 'antd/lib/date-picker';
import { Dayjs } from 'dayjs';
import { FC } from 'react';

import { DATE_FORMAT } from '../../../../constants/constants';
import { getSliceDates } from '../../../TrafficLightDetailed/TlDetectors/IntancyGraph/helpers/getSliceDates';

import ExtraFooter from './ExtraFooter/ExtraFooter';
import {
  DisabledTimePickerProps,
  IOptions,
  onDisabledDate,
  onDisabledTime,
} from './helpers/disabledHelpers';
import { getTimeFooterArray } from './helpers/getTimeFooterArray';

export interface IRangePickerProps {
  onChangeDate: (value: N<[Dayjs, Dayjs]>) => void;
  pickerClassName?: string;
  dateFormat?: string;
  isTimeFooter?: boolean;
  status?: RangePickerProps['status'];
  value?: RangePickerProps['value'];
  isPresets?: boolean;
  allowClear?: boolean;
  presets?: TimeRangePickerProps['presets'];
  disabledDateOptions?: IOptions;
  disabledTimeOptions?: DisabledTimePickerProps;
  handleDisableTime?: RangePickerProps['disabledTime'];
  handleDisableDate?: RangePickerProps['disabledDate'];
}

const rangePresetsDefault: TimeRangePickerProps['presets'] = [
  { label: 'Сутки', value: getSliceDates(1, { isNeedUtcFormatted: true }) },
  { label: '3 дня', value: getSliceDates(3, { isNeedUtcFormatted: true }) },
  { label: 'Неделя', value: getSliceDates(7, { isNeedUtcFormatted: true }) },
  {
    label: 'Месяц',
    value: getSliceDates(1, { timeType: 'M', isNeedUtcFormatted: true }),
  },
];

const RangePicker: FC<IRangePickerProps> = ({
  status,
  value,
  dateFormat = DATE_FORMAT,
  disabledDateOptions,
  disabledTimeOptions,
  allowClear = true,
  isPresets = true,
  presets = rangePresetsDefault,
  isTimeFooter = true,
  pickerClassName = '',
  handleDisableDate = (value: Dayjs) =>
    onDisabledDate(value, disabledDateOptions),
  handleDisableTime = () => onDisabledTime(disabledTimeOptions),
  onChangeDate,
}) => {
  const presetsPicker = isPresets ? presets : [];

  const timeFooter = getTimeFooterArray(dateFormat);

  const isTime = !!timeFooter.length;

  const rangePickerProps: RangePickerProps = {
    presets: presetsPicker,
    format: dateFormat,
    onChange: onChangeDate as U<RangePickerProps['onChange']>,
    showTime: isTime,
    value,
    allowClear,
    status,
    locale,
    disabledDate: handleDisableDate,
    disabledTime: handleDisableTime,
    renderExtraFooter: () => (
      <ExtraFooter
        timeFooter={timeFooter}
        isTime={isTime}
        isTimeFooter={isTimeFooter}
      />
    ),
  };

  return (
    <DatePicker.RangePicker {...rangePickerProps} className={pickerClassName} />
  );
};

export default RangePicker;
