import {
  AdapterTypes,
  GeneralAdapterStatistic,
  isAdapterGo,
  MainAdapter,
  StatisticByType,
} from '../model/adapter.model';

const getGeneralStructure = (
  statisticItem: GeneralAdapterStatistic['data'],
  adapterType: AdapterTypes
): MainAdapter => {
  if (!isAdapterGo(statisticItem, adapterType)) return statisticItem;

  const {
    add_info: { adapter },
  } = statisticItem;

  return {
    ...statisticItem,
    uptime_sec: adapter?.uptime_sec ?? null,
    error: adapter?.error?.jerror ?? null,
    statistic: adapter,
    status: adapter?.status ?? null,
  };
};

const transformedDataStatistic = (statistic: U<GeneralAdapterStatistic[]>) => {
  if (!statistic) return;

  return statistic.reduce((acc: StatisticByType, { data, data_type }) => {
    const formattedData = getGeneralStructure(data, data_type);

    if (!acc[data_type]) {
      acc[data_type] = [formattedData];
    } else {
      acc[data_type]?.push(formattedData);
    }

    return acc;
  }, {} as StatisticByType);
};

export default transformedDataStatistic;
