import { isNumber, isString, uniqueId } from 'lodash';

import { findById } from '../../../helpers/findBy';
import rootStore from '../../../stores/rootStore/rootStore';
import { ProfileItem, VideoListItem } from '../../../ts/models/videoWall.model';

export const getCameraById = (id: number | string) => {
  if (isString(id)) return undefined;

  const cameras = rootStore.mapDataStore.cameras;

  const camera = findById(cameras, id);

  return camera;
};

export const createVideoList = (length: number): VideoListItem[] => {
  return new Array(length).fill(0).map(() => uniqueId('_'));
};

const LAYOUT_LENGTH: { [key: number]: number } = {
  2: 50,
  3: 45,
  4: 30,
  5: 25,
  6: 20,
};

export const getTitleText = (title: string, layoutValue: number) => {
  const length = LAYOUT_LENGTH[layoutValue];
  const newTitle = title;

  if (length)
    return newTitle.length > length
      ? newTitle.slice(0, length) + '...'
      : newTitle;

  return newTitle.length > 15 ? newTitle.slice(0, 15) + '...' : newTitle;
};

interface getNewCurrentPageParams {
  layoutGridLength: number;
  videoList: VideoListItem[];
  offset: number;
  withoutLimit?: true;
}

export const getPageItemList = (params: getNewCurrentPageParams) => {
  const { layoutGridLength, videoList, offset } = params;

  const pageItems: VideoListItem[] = [];

  for (let i = 0; i < layoutGridLength; i++) {
    const currentValue = videoList[i + offset];

    pageItems.push(currentValue || uniqueId('_'));
  }

  return pageItems;
};

export const getPages = (
  layoutGridLength: number,
  videoList: VideoListItem[]
) => {
  const newCurrentPages: VideoListItem[][] = [];

  const pagesCount: number =
    videoList.length <= layoutGridLength
      ? 1
      : Math.ceil(videoList.length / layoutGridLength);

  for (let j = 0; j < pagesCount; j++) {
    const offset = layoutGridLength * j;

    const currentPage = getPageItemList({
      layoutGridLength,
      videoList,
      offset,
    });

    // deleting empty pages
    if (currentPage.every(isString)) continue;

    newCurrentPages.push(currentPage);
  }

  if (newCurrentPages.length === 0) {
    // add first page without cameras when we create empty profile
    newCurrentPages.push(videoList.slice(0, layoutGridLength));
  }

  return newCurrentPages;
};

export const checkIsEmptyVideoLIst = (videoList: VideoListItem[]) => {
  const newList = videoList.filter(isNumber);

  return newList.length === 0;
};

export const getChangeProfileParams = (
  savedVideoLists: ProfileItem[],
  profileId: number
) => {
  const profile = savedVideoLists.filter((item) => item.id === profileId)[0];

  return profile;
};

export const getOptions = (savedVideoLists: ProfileItem[]) => {
  const options = savedVideoLists.map((item) => {
    const option = {
      label: item.name,
      value: item.id,
    };

    return option;
  });

  return options;
};
