import { useEffect } from 'react';

import setDisplayStyle from '../../../../helpers/setDisplayStyle';
import rootStore from '../../../../stores/rootStore/rootStore';
import { POINTS_AMOUNT } from '../../constants/constants';
import handlePointsInExtent from '../../helpers/handlePointsInExtent';
import { useOverlays } from '../../hooks';
import { formatPoints, setPopupsRefsData } from '../helpers/helpers';

const usePointUdsOverlays = () => {
  const { isCrossroadBorder, pointsUds: allPoints } = rootStore.mapDataStore;

  const { map } = rootStore.mapStore;
  const { setPointsValue } = rootStore.pointsUdsStore;

  const { refs, popups } = useOverlays(map, POINTS_AMOUNT);

  useEffect(() => {
    const formattedPoints = formatPoints(allPoints);
    const handler = handlePointsInExtent(formattedPoints, refs);

    const handlePoints = () => {
      const { newPoints } = handler();

      if (!newPoints) return;

      setPopupsRefsData(newPoints, popups, refs);
      setPointsValue('pointsInExtent', newPoints);
    };

    if (isCrossroadBorder && map) {
      handlePoints();
      map.on('moveend', handlePoints);
    } else {
      setDisplayStyle(refs);
      setPointsValue('pointsInExtent', []);
    }

    return () => {
      map && map.un('moveend', handlePoints);
    };
  }, [map, isCrossroadBorder, allPoints, refs, popups, setPointsValue]);

  return { refs };
};

export default usePointUdsOverlays;
