import { Feature } from 'ol';

import { IFeaturesArr } from '../../components/Map/helpers/getFeaturesCluster';

export const getArrayIdsFeatures = (errorsFeatures: N<IFeaturesArr[]>) => {
  if (!errorsFeatures) return [];

  const idArray = errorsFeatures.reduce((acc: number[] | number[][], item) => {
    if (item.feature.get('id') === undefined) {
      acc.push(item.feature.get('features').map((el: Feature) => el.get('id')));

      return acc;
    }
    acc.push(item.feature.get('id'));

    return acc;
  }, []);

  return idArray;
};
