import { TablePaginationConfig } from 'antd/lib';

export const MAX_PAGE_SIZE = 5;

export const EDIT_TABLE_PAGINATION: TablePaginationConfig = {
  pageSize: MAX_PAGE_SIZE,
  position: ['bottomRight'],
};

export enum TitleTable {
  phase = 'Фаза',
  time = 'Основной такт',
  spanTime = 'Промежуточный такт',
  spanGreenBlinkTime = 'Мигание зеленым',
  spanYellowTime = 'Мигание желтым',
  spanRedYellowTime = 'Мигание желт. - красным',
}
