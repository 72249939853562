import { IoIosWarning } from 'react-icons/io';

import {
  GRAPH_TYPES_VALUES,
  GraphTypes,
} from '../../../../../TrafficLightDetailed/TlDetectors/constants/constants';
import { TDtInfo } from '../../../../../TrafficLightDetailed/TlDetectors/IntancyGraph/TitleBtns/helpers/getTitleDetectors';

const getBtnInfo = (isDtReady: boolean, isWork: boolean): TDtInfo[] => {
  return GRAPH_TYPES_VALUES.map((el) => {
    const { valueDt, dsc } = el;
    const isCandleGraph = valueDt === GraphTypes.CandlesChart;

    const isDisabled = isCandleGraph && !isDtReady;
    const isNotify = isCandleGraph && !isWork;
    const caption = !isNotify
      ? dsc
      : `${dsc} недоступен, так как по ДТ недостаточно данных`;

    const specialWarning = isNotify ? <IoIosWarning /> : null;

    return { ...el, dsc: caption, isDisabled, specialWarning };
  });
};

export default getBtnInfo;
