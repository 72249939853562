import { findBy } from '../../../../helpers/findBy';
import {
  COLORS_CHART,
  OPACITY_DEFAULT,
  OPACITY_HOVER,
} from '../constants/constants';
import { IColors } from '../models/chart.model';

export const getAreaProps = (
  colors: U<IColors[]>,
  el: string,
  index: number,
  hoverLine: string
) => {
  const color = colors
    ? findBy(colors, el, 'name')?.value
    : COLORS_CHART.at(index);

  const stroke = color ?? COLORS_CHART.at(index);

  const opacityDefault = hoverLine === el ? OPACITY_DEFAULT : OPACITY_HOVER;

  const opacity = hoverLine ? opacityDefault : OPACITY_DEFAULT;

  return { opacity, stroke };
};
