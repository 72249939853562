import rootStore from '../../stores/rootStore/rootStore';
import Detailed, { DetailedType } from '../ui-kit/Detailed/Detailed';

import Monitoring from './Monitoring/Monitoring';

const DetailedStatistics = () => {
  const { clearMonitoringStore } = rootStore.monitoringPanelStore;

  const detailedProps: DetailedType = {
    header: 'Мониторинг состояния оборудования',
    onClose: () => clearMonitoringStore(),
  };

  return (
    <Detailed {...detailedProps}>
      <Monitoring />
    </Detailed>
  );
};

export default DetailedStatistics;
