import {
  API_PATH,
  MEDIA,
  PHASE_IMG_FOLDER,
} from '../../../../../constants/constants';

export const FOLDER_PATHS = {
  URL: 'https://dev.eputs.org/static',
  DEFAULT: MEDIA,
};

export const IMG_FOLDER_PATH = API_PATH + PHASE_IMG_FOLDER;
