import { z } from 'zod';

const GroupsSchema = z.object({
  id: z.number(),
  dsc: z.string(),
  code: z.string(),
  name: z.string(),
  isChange: z.boolean(),
  isDelete: z.boolean(),
});

const SettingsFieldSchema = z
  .object({
    id: z.number(),
    access: z.boolean(),
    systemName: z.string(),
    // eslint-disable-next-line camelcase
    form_field_code: z.string(),
    api: z.string(),
    method: z.string(),
    endpoint: z.string(),
  })
  .partial();

const FilterSettingsSchema = z
  .object({
    sorted: z.boolean(),
    filters: z.boolean().nullable(),
    searched: z.boolean(),
  })
  .partial();

const WebHeaderItemSchema = z.object({
  type: z.string(),
  title: z.string(),
  visible: z.boolean(),
  readonly: z.boolean(),
  referens: z.string(),
  filterSettings: FilterSettingsSchema,
});

export const WebGroupSettingsSchema = z.object({
  dataRows: SettingsFieldSchema.array().nullable(),
  headerItem: WebHeaderItemSchema.array(),
});

export const AdminGroupsSchema = z.object({
  groups: GroupsSchema.array(),
  systemId: z.number(),
  systemName: z.string(),
});

export type ISystemWithGroups = z.infer<typeof AdminGroupsSchema>;

export type IGroup = z.infer<typeof GroupsSchema>;
