import { uniqueId } from 'lodash';
import { FC } from 'react';

import { UdsInfo } from '../../../models/crossroad.model';

import styles from './InfoContent.module.scss';

interface InfoContentProps {
  pointUdsInfo: UdsInfo[];
}

const InfoContent: FC<InfoContentProps> = ({ pointUdsInfo }) => {
  if (!pointUdsInfo.length) return null;

  return (
    <div className={styles.container}>
      <h4>Информация о точке УДС:</h4>
      <div className={styles.contentContainer}>
        {pointUdsInfo.map(({ label, value }) => {
          if (!value) return null;

          return (
            <span className={styles.row} key={uniqueId()}>
              <p>
                <strong>{label}:</strong> {value}
              </p>
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default InfoContent;
