import { isEqual } from 'lodash';
import { getUid, Map, MapBrowserEvent } from 'ol';
import type { Coordinate } from 'ol/coordinate';
import Feature from 'ol/Feature';
// eslint-disable-next-line no-duplicate-imports
import type { FeatureLike } from 'ol/Feature';
import { Point } from 'ol/geom';
import { Layer } from 'ol/layer';
import RenderFeature from 'ol/render/Feature';

import { WEATHER_LAYER_ITEMS } from '../../../apiGIS/constants/weather';
import { GIS_SYSTEM } from '../../../apiGIS/sources/FeatureServerSource';
import { CLUSTERS, CURSOR_MAP } from '../../../constants/mapConstants';
import { TInfoDataSystem } from '../../../stores/mapDataStore/mapDataStore.model';
import { TMap } from '../../../stores/mapStore/mapStore.model';
import rootStore from '../../../stores/rootStore/rootStore';
import {
  IInfoPanelData,
  Markers,
  TipType,
} from '../../../stores/uiStore/uiStore.model';
import {
  CategoryLayerGIS,
  FeatureDeviceProps,
  FeaturesTypes,
  RightPanelType,
  System,
} from '../../../ts/enums/enums';
import { MapOverlays } from '../../Mapper/Mapper.model';
import { VisibleLayer } from '../Map.model';

import { getFeatureOnClusterCoord } from './getFeatureOnClusterCoord';
import { getSelectedMultipleCluster } from './getSelectedMultipleCluster';
import handleInfoPanel from './handleInfoPanel';
import { setMapCursor } from './handleMeasuring';
import { getWeatherParams } from './handleWeather';
import { setTooltipOnCluster } from './setTooltipOnCluster';

const { POINTER, DISABLED } = CURSOR_MAP;

export type TGetTooltip = (
  id: number,
  system: System | typeof CLUSTERS,
  coordinates: U<Coordinate>,
  clustersFeatures?: Feature[]
) => void;

export type GetLayerTooltip = (
  id: U<number | string>,
  coordinates: U<Coordinate>,
  fields: { key: string; value: SN }[],
  displayField: string,
  isWeather?: boolean,
  title?: string,
  text?: string
) => void;
export type TCloseTooltip = () => void;
export type THandleInfoPanel = (
  id: number,
  coordinates: Coordinate,
  isFromMap: boolean,
  system: TInfoDataSystem,
  coordFeatureOnCluster?: U<Coordinate>
) => U<void>;

export const getHit = (e: MapBrowserEvent<PointerEvent>, map: Map) => {
  const featuresArr: FeatureLike[] = map.getFeaturesAtPixel(e.pixel);

  const clusterFeature = featuresArr.at(0);
  const features = clusterFeature?.get(FeaturesTypes.Features);

  return !!features?.length;
};

export const getHitGIS = (
  e: MapBrowserEvent<PointerEvent>,
  map: Map,
  gisTooltipsDelay: TipType
) => {
  if (!gisTooltipsDelay) {
    return false;
  }

  const featuresArr: FeatureLike[] = map.getFeaturesAtPixel(e.pixel);

  const gisFeature = featuresArr.at(0);
  const features = gisFeature?.get(FeaturesTypes.GISFeature);

  return !!features;
};

export const getHitWeather = (e: MapBrowserEvent<PointerEvent>, map: Map) => {
  const layers = map.getLayers().getArray();

  const layer = layers.find(
    (layer) => layer.get('id') === WEATHER_LAYER_ITEMS.factor.id
  );

  return !!layer;
};

interface IHandlePointerProps {
  event?: MapBrowserEvent<PointerEvent>;
  isPoint?: boolean;
}

export const handlePointer = (
  map: Map,
  { event, isPoint = true }: IHandlePointerProps
) => {
  const { measureAction } = rootStore.gisDataStore;

  const isPointer = isPoint && event && getHit(event, map);

  const cluster = (event && map.getFeaturesAtPixel(event.pixel)) as U<
    [Feature<Point>]
  >;

  const features: U<Feature<Point>[]> = cluster
    ?.at(0)
    ?.get(FeaturesTypes.Features);
  const feature = features?.at(0);

  const isFeature = features?.length === 1;

  const isDisabled: boolean = feature?.get(FeatureDeviceProps.IsDisabled);

  let cursor;

  if (isPointer) {
    cursor = POINTER;
  }

  if (isFeature && isDisabled) {
    cursor = DISABLED;
  }

  measureAction.type && setMapCursor(map, measureAction.type);

  cursor && (map.getViewport().style.cursor = cursor);
};

export const handleTooltip = (
  closeTooltip: TCloseTooltip,
  getTooltip: TGetTooltip,
  getLayerTooltip: GetLayerTooltip,
  closeLayerTooltip: TCloseTooltip,
  gisTooltipsDelay: TipType
) => {
  let isStateChanged = true;
  let isStateFeatureChanged = true;
  let featurePrev: FeatureLike[] = [];
  let lastCoordinates: number[] = [];

  return (
    event: MapBrowserEvent<PointerEvent>,
    map: Map,
    clusterFeatures: Feature<Point>[],
    infoData: N<IInfoPanelData>
  ) => {
    const { layersState } = rootStore.gisDataStore;
    const { setKeyValue } = rootStore.uiStore;
    const hit = getHit(event, map);
    const hitGIS = getHitGIS(event, map, gisTooltipsDelay);
    const hitWeather = getHitWeather(event, map);

    if (!hit) featurePrev = [];

    map.forEachFeatureAtPixel(
      event.pixel,
      (feature: FeatureLike, layer: Layer) => {
        const features = feature.get(FeaturesTypes.Features);
        let layerId = '';

        if (layer) layerId = layer.get('id');

        isStateChanged = !(hit && features && isEqual(featurePrev, features));
        isStateFeatureChanged = !(
          hit &&
          layerId &&
          isEqual(featurePrev, [feature])
        );

        if (hit && features?.length) {
          featurePrev = [...features];
          const isCluster = features.length > 1;

          if (!features[0].get(FeatureDeviceProps.Id))
            return setKeyValue('isTooltip', false);

          const isInfoDataFeature =
            infoData?.id === features[0].get(FeatureDeviceProps.Id);

          !isCluster && setKeyValue('isTooltip', !isInfoDataFeature);

          if (!isStateChanged) return;

          handlePointer(map, { event });

          if (isCluster) {
            const isSameCluster = isEqual(
              clusterFeatures,
              feature.get(FeaturesTypes.Features)
            );

            isStateChanged = setTooltipOnCluster({
              map,
              feature,
              isSameCluster,
              getTooltip,
            });
            closeLayerTooltip();

            return;
          }

          const featureInCluster = clusterFeatures.find(
            (el) =>
              el &&
              features[0].get(FeatureDeviceProps.Id) ===
                el.get(FeatureDeviceProps.Id)
          );

          let coord: U<Coordinate>;

          if (featureInCluster) {
            coord = getFeatureOnClusterCoord(map, featureInCluster);

            if (!coord) return;
          }

          getTooltip(
            features[0].get(FeatureDeviceProps.Id),
            features[0].get(FeatureDeviceProps.System),
            coord
          );
          isStateChanged = false;
          closeLayerTooltip();

          return;
        }

        if (!hit && layerId && hitGIS) {
          featurePrev = [feature];
          setKeyValue('isLayerTooltip', true);

          const geometry = feature.getGeometry() as RenderFeature;
          const geometryType = geometry.getType();

          if (!geometry) {
            return;
          }

          if (lastCoordinates.length === 0) {
            lastCoordinates = [];
          }

          if (
            (geometryType !== 'Point' &&
              lastCoordinates[0] !== event.coordinate[0]) ||
            lastCoordinates[1] !== event.coordinate[1]
          ) {
            isStateFeatureChanged = true;
          }

          if (!isStateFeatureChanged) return;

          map.getViewport().style.cursor = POINTER;

          const isPoint = geometryType === 'Point';

          const coordinates = isPoint
            ? geometry.getFlatCoordinates()
            : lastCoordinates;

          lastCoordinates = isPoint ? coordinates : event.coordinate;

          if (!coordinates) {
            return;
          }

          const layerGIS = layersState.find((layer) => layer.id === layerId);

          const displayField = layerGIS?.displayField
            ? feature.get(layerGIS.displayField) ?? feature.getId()
            : layerGIS?.alias ?? feature.getId();

          const tooltipDefinition = layerGIS?.tooltipDefinition;
          const fields: { key: string; value: SN }[] = [];

          if (tooltipDefinition) {
            tooltipDefinition.fields?.forEach((field) => {
              let prop = feature.get(field);

              if (typeof prop === 'object') {
                prop = '';
              }

              if (typeof prop === 'boolean') {
                prop = prop ? 'Да' : 'Нет';
              }

              const fieldProp = layerGIS?.fields?.find(
                (fieldProp) => fieldProp.name === field
              );

              fields.push({
                key: fieldProp?.alias ? fieldProp.alias : field,
                value: prop,
              });
            });
          }

          getLayerTooltip(
            feature.getId(),
            coordinates,
            fields,
            displayField,
            false,
            layerGIS?.alias
          );
          closeTooltip();
          isStateChanged = true;
        }
      }
    );

    if (hitWeather && !hit && !hitGIS) {
      setKeyValue('isLayerTooltip', true);

      const { title } = getWeatherParams(map, event.coordinate);

      getLayerTooltip('Погода', event.coordinate, [], 'Погода', true, title);

      closeTooltip();

      isStateChanged = true;

      return;
    }

    if (!hit && !hitGIS) {
      closeTooltip();
      handlePointer(map, { isPoint: false });
      isStateChanged = true;
    }

    if (!hitGIS) {
      closeLayerTooltip();
      isStateFeatureChanged = true;
      lastCoordinates = [];
    }
  };
};

export const handleInfoDataByClick = (
  e: MapBrowserEvent<any>,
  map: Map,
  isMultipleSelect: boolean
) => {
  const { setMapData } = rootStore.mapDataStore;
  const { clickedCluster, setSelectedClusters } = rootStore.clustersStore;
  const { setSelectedFeatureSomeArray } = rootStore.scriptsControlStore;
  const { setKeyValue, setInfoData } = rootStore.uiStore;

  setKeyValue('clickedCartographyObj', null);

  const features: FeatureLike[] = [];

  const handleFeature = (feature: FeatureLike) => {
    const features: Feature<Point>[] = feature.get(FeaturesTypes.Features) ?? [
      feature,
    ];
    const isCluster = features.length > 1;

    setMapData('selectedFeature', feature as Feature<Point>);

    if (isCluster) {
      const isSameCluster =
        !!clickedCluster?.cluster &&
        getUid(clickedCluster.cluster) === getUid(feature);

      if (isSameCluster && isMultipleSelect) return null;

      const cluster = getSelectedMultipleCluster(feature);

      setSelectedClusters(cluster);
      setInfoData(null);

      return RightPanelType.Cluster;
    }

    if (isMultipleSelect) {
      !isCluster &&
        setSelectedFeatureSomeArray(
          features[0].get(FeatureDeviceProps.Id),
          getFeatureOnClusterCoord(map, features[0])
        );

      return null;
    }

    const feat = features[0];
    const system: System | typeof GIS_SYSTEM = feat.get(
      FeatureDeviceProps.System
    );

    if (system === GIS_SYSTEM) return null;

    const coordinates = feat.getGeometry()?.getFlatCoordinates();
    const coordFeatureOnCluster = getFeatureOnClusterCoord(map, feat);

    if (feat.get(FeaturesTypes.ClusterLine)) return RightPanelType.Cluster;

    if (!feat.get(FeatureDeviceProps.Id)) {
      return null;
    }

    handleInfoPanel({
      id: feat.get(FeatureDeviceProps.Id),
      coordinates: coordinates ?? [],
      isFromMap: true,
      system,
      coordFeatureOnCluster,
    });

    return RightPanelType.InfoPanel;
  };

  map.forEachFeatureAtPixel(e.pixel, (feature: FeatureLike) =>
    features.push(feature)
  );

  if (features.length === 1) {
    return handleFeature(features[0]);
  }

  return null;
};

export const addOverlays = (map: Map, popups: MapOverlays) => {
  Object.values(popups)
    .flat()
    .forEach((el) => el?.getElement() && map.addOverlay(el));
};

export const handleVisibleLayersState = (
  map: TMap,
  visibleLayers: VisibleLayer[]
) => {
  if (!map) {
    return;
  }

  const layers = map.getLayers().getArray();

  layers.forEach((layer) => {
    const id = layer.getClassName();
    const category = layer.get('category');

    if (category !== CategoryLayerGIS.Common) {
      return;
    }

    const visibleLayer = visibleLayers.find((layer) => layer.id === id);

    visibleLayer && layer.setVisible(visibleLayer.visible);
  });
};

export const handleVisibleMainLayer = (map: TMap, markers: Markers) => {
  if (!map) {
    return;
  }

  const layers = map.getLayers().getArray();

  layers.forEach((layer) => {
    const category = layer.get('category');

    switch (category) {
      case CategoryLayerGIS.Social:
        break;
      case CategoryLayerGIS.Common:
        break;
      case CategoryLayerGIS.Weather:
        break;
      case CategoryLayerGIS.PublicTransport:
        layer.setVisible(markers.isPublicTransport);
        break;
      case CategoryLayerGIS.Raster:
        break;
      case CategoryLayerGIS.Traffic:
        layer.setVisible(markers.isTraffic);
        break;
      default:
        break;
    }
  });
};
