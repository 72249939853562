import { useEffect, useRef, useState } from 'react';

import api from '../../api';
import { CONFIG } from '../../constants/configConstants';
import { WS_RECEIVE_EVENTS, WS_SEND_EVENTS } from '../../constants/constants';
import eventBus from '../../eventBus';
import { neverTypeCheck } from '../../helpers/neverTypeCheck';
import rootStore from '../../stores/rootStore/rootStore';
import { ChanelTypes } from '../../ts/enums/enums';
import { EMessage } from '../../ts/models/logsPanel.model';
import notification from '../ui-kit/Notification/Notification';

import { getTimeDiff } from './helpers/getTimeDiff';
import { updatePhaseHold } from './helpers/updatePhaseHold';
import useSocketUpdate from './useSocketUpdate';

interface ISocket {
  send: (event: WS_SEND_EVENTS, message: any) => void;
  subscribe: (
    channelName: string,
    categoryId: string | number,
    returnLastMessage: boolean
  ) => void;
  unsubscribe: (channelName: string, categoryId: string | number) => void;
  isInit: boolean;
}

export const Socket: ISocket = {
  send: () => {},
  subscribe: () => {},
  unsubscribe: () => {},
  isInit: false,
};

const useSocket = () => {
  const {
    addItemHandler,
    deleteItemHandler,
    fieldChangedHandler,
    refreshMapObjectsData,
  } = useSocketUpdate();
  const { isAppReady, isAuth, user, accessToken, resetUserData } =
    rootStore.userDataStore;
  const { updateCategory, clearCategory, reSubscribeAll, getCategory } =
    rootStore.channelsDataStore;

  const ws = useRef<WebSocket>();
  const [status, setStatus] = useState('Инициализирован');

  useEffect(() => {
    //console.log('WS status: ', status);
  }, [status]);

  useEffect(() => {
    WSConnect();

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [isAppReady, isAuth, accessToken]); // eslint-disable-line

  const WSConnect = () => {
    const isAuth = rootStore.userDataStore.isAuth;

    if (!isAppReady || !isAuth) return;

    setStatus('Попытка подключения');

    const WSConnectString = CONFIG.WEB_SOCKET_URL + '?auth=' + accessToken;

    ws.current = new WebSocket(WSConnectString);

    let pingInterval: NodeJS.Timeout | null = null;

    ws.current.onopen = () => {
      setStatus('Соединение открыто');
      gettingData();
      reSubscribeAll();
      pingInterval = setInterval(sendPingMessage, 50000);
    };

    ws.current.onclose = (event: any) => {
      if (pingInterval) {
        clearTimeout(pingInterval);
      }

      if (event.wasClean) {
        return setStatus('Соединение закрыто');
      }

      setStatus(`Соединение прервано`);

      setTimeout(WSConnect, 3000);
    };

    Socket.send = wsSend;
    Socket.subscribe = wsSubscribe;
    Socket.unsubscribe = wsUnsubscribe;
    Socket.isInit = true;
  };

  const gettingData = () => {
    if (!ws.current || ws.current.readyState !== 1) {
      return;
    }

    ws.current.onmessage = (e: any) => {
      const message = JSON.parse(e.data);

      const event: WS_RECEIVE_EVENTS = message.event;

      switch (event) {
        case WS_RECEIVE_EVENTS.unauthorized:
          if (rootStore.userDataStore.accessToken === null) break;

          api.server.fetchPing('Socket.useSocket');
          break;

        case WS_RECEIVE_EVENTS.changeField:
          fieldChangedHandler(message.data);
          break;

        case WS_RECEIVE_EVENTS.addItem:
          addItemHandler(message.data);
          break;

        case WS_RECEIVE_EVENTS.deleteItem:
          deleteItemHandler(message.data);
          break;

        case WS_RECEIVE_EVENTS.subData: {
          const data = message.data;

          updateCategory(data.channel, data.category, data.message);
          break;
        }

        case WS_RECEIVE_EVENTS.updateEntity: {
          const data = message.data;

          updatePhaseHold(data);

          break;
        }

        case WS_RECEIVE_EVENTS.subRes: {
          const data = message.data;

          const { channel, category, reason } = data;

          const dataKeys = Object.keys(data);

          if (dataKeys.includes('unsubscribe')) {
            clearCategory(channel, category);
            break;
          }

          let messageData = data.message;

          const categoryNow = getCategory(channel, category);

          if (
            channel === ChanelTypes.CommandsLogsNew &&
            !data.message &&
            !categoryNow?.data
          ) {
            messageData = {
              additional: null,
              message: null,
              timestamp: new Date().getTime(),
              type: EMessage.Warning,
              userData: {
                id: user?.id,
                login: user?.login,
                name: user?.name,
              },
            };

            if (data.subscribe) {
              messageData.message = `Подключено к каналу: ${channel} ${category}`;
            } else {
              messageData.message = `Ошибка подключения к каналу: ${channel} ${category} причина: ${reason}`;
              messageData.type = EMessage.Error;
            }
          }

          messageData && updateCategory(channel, category, messageData);
          break;
        }

        case WS_RECEIVE_EVENTS.pong: {
          const { status, serverTime } = message.data;

          if (status !== 'ok') {
            console.error('Websocket Ping/Pong request error', message.data);
          } else {
            eventBus.incorrectTimeChange.broadcast(getTimeDiff(serverTime));
          }

          break;
        }

        case WS_RECEIVE_EVENTS.error:
          console.log('WS ERROR: Ошибка сообщения ws', message);
          break;

        case WS_RECEIVE_EVENTS.sessionQuit:
          notification.warning('SESSION_DESTROY', {
            dsc: `Причина: ${message.data?.reason}`,
          });
          resetUserData();
          break;

        case WS_RECEIVE_EVENTS.userDataUpdate:
          eventBus.userDataUpdate.broadcast();
          break;

        case WS_RECEIVE_EVENTS.refreshMapObjectsData:
          refreshMapObjectsData();
          break;

        default:
          neverTypeCheck(event);
          console.log('WS ERROR: Незарегистрированный event ws', message);
          break;
      }
    };
  };

  const wsSend = (event: WS_SEND_EVENTS, message: any): void => {
    if (!ws.current || ws.current.readyState !== 1) {
      return console.error('WS сообщение не может быть отправленно', event);
    }

    let newMessage: any = { event: event, data: message };

    newMessage = JSON.stringify(newMessage);
    ws.current.send(newMessage);
  };

  const wsSubscribe = (
    channelName: string,
    categoryId: string | number,
    returnLastMessage: boolean
  ): void => {
    Socket.send(WS_SEND_EVENTS.subReq, {
      channel: channelName,
      category: categoryId,
      returnLastMessage: returnLastMessage,
    });
  };

  const wsUnsubscribe = (
    channelName: string,
    categoryId: string | number
  ): void => {
    Socket.send(WS_SEND_EVENTS.subExit, {
      channel: channelName,
      category: categoryId,
    });
  };

  const sendPingMessage = () => {
    wsSend(WS_SEND_EVENTS.ping, undefined);
  };

  return;
};

export default useSocket;
