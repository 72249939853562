import rootStore from '../../stores/rootStore/rootStore';
import { DEFAULT_GIS_APPLICATION_SERVER_URL } from '../constants/servers';

export const getGISApplicationServerUrl = () => {
  const { userDataStore } = rootStore;

  return (
    userDataStore.urlConfigurations?.gisApplicationServer ||
    DEFAULT_GIS_APPLICATION_SERVER_URL
  );
};

export const getGeocodeServiceUrl = () => {
  return `${getGISApplicationServerUrl()}/api/geocode`;
};

export const getDirectionsServiceUrl = () => {
  return `${getGISApplicationServerUrl()}/api/directions`;
};
