import {
  ICsdData,
  IProcessedDataInitial,
  ITimePoints,
} from '../../../ts/models/signalPrograms.model';
import { TL } from '../../../ts/models/tl.model';

import { createCsdGraphData, getPhasesMarks } from './createCsdGraphData';
import { getCircleTime, getTLSignalData } from './getTLSignalData';

export interface CsdDataValues {
  timePoints: ITimePoints[];
  csdData: ICsdData;
}

interface IGetCsdData {
  id?: number;
  data?: IProcessedDataInitial;
  isStatic: boolean;
  tlsData?: TL[];
}

const getCsdData = ({
  id,
  data,
  isStatic,
  tlsData,
}: IGetCsdData): U<CsdDataValues> => {
  const processedDataInitial =
    !isStatic && id ? getTLSignalData(id, tlsData ?? []) : data;

  if (!processedDataInitial) return;

  if (isStatic && processedDataInitial) {
    const cycleTime = getCircleTime(processedDataInitial.tlTacts);

    if (!cycleTime) return;

    processedDataInitial.cycleTime = cycleTime;
  }

  const csdData = createCsdGraphData(processedDataInitial, isStatic);

  const tacts = csdData.tacts;

  if (!tacts) return;

  tacts.sort((a, b) => a.tact - b.tact);

  const { timePoints } = getPhasesMarks(tacts);

  return { timePoints, csdData };
};

export default getCsdData;
