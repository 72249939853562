import { Feature } from 'ol';
import { Point } from 'ol/geom';

import { TDeviceStatus } from '../../../constants/devicesConstants';
import { FeatureDeviceProps, System } from '../../../ts/enums/enums';
import { TlMode } from '../../../ts/models/tl.model';

const {
  Id,
  System: SystemType,
  DeviceStatus,
  IsDisabled,
  Mode,
} = FeatureDeviceProps;

type DeviceType = Record<FeatureDeviceProps, unknown>;

interface DeviceInfo extends DeviceType {
  [Id]: number;
  [SystemType]: System;
  [DeviceStatus]: TDeviceStatus;
  [IsDisabled]: boolean;
  [Mode]: N<TlMode>;
}

export const getDeviceInfo = (feature: Feature<Point>) => {
  const deviceInfo: DeviceInfo = {
    [Id]: feature.get(Id),
    [SystemType]: feature.get(SystemType),
    [Mode]: feature.get(Mode),
    [DeviceStatus]: feature.get(DeviceStatus),
    [IsDisabled]: feature.get(IsDisabled),
  };

  return deviceInfo;
};
