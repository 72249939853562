import { getTimeZones } from '../TitleBtns/TimeLocalCard/helpers/getTimeZone';

import { getUTCStr } from './getUTCStr';

export const getTitlePopover = () => {
  const timeZone = getTimeZones();

  if (!timeZone || timeZone?.isOurTimeZone) return '';

  return `Выбор периода происходит в региональном времени (${getUTCStr(
    timeZone.timeZoneRegion
  )}).`;
};
