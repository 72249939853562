import { CONTROL_ACCESS_DEVICES } from '../../../../../constants/constants';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { TLDetailedCode } from '../../../../../ts/enums/userData';
import { DeviceControlAccess } from '../../../../../ts/models/mapObject.model';

const { OnlyRead, ReadAndControl } = CONTROL_ACCESS_DEVICES;

const checkControlAccessTL = (controlAccess: DeviceControlAccess) => {
  const { hasAccess } = rootStore.userDataStore;

  const isAccessManagementCode = hasAccess(TLDetailedCode.Management);

  const isControlAccessRead =
    controlAccess === OnlyRead || controlAccess === ReadAndControl;

  const isManagementAccess = isAccessManagementCode && isControlAccessRead;

  return { isManagementAccess, isAccessManagementCode };
};

export default checkControlAccessTL;
