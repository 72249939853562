import { isNumber } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC, useCallback } from 'react';

import { COLORS } from '../../../../../../../../constants/colorsConstants';
import { findById } from '../../../../../../../../helpers/findBy';
import rootStore from '../../../../../../../../stores/rootStore/rootStore';
import { System } from '../../../../../../../../ts/enums/enums';
import { getDeviceColor } from '../../../../../../../MapBtns/MapInfoWrapper/InfoBtn/ContentInfo/helpers/getMapInfo';
import { getTLIcon } from '../../../../../../../Mapper/helpers/getStyle';
import TLIcon from '../../../../../../../Overlays/PhaseCircleOverlay/TLIcon/TLIcon';
import { HandleClickDevice } from '../../models/crossroadSchema.model';

const { PRIMARY, WHITE } = COLORS;

interface LinkedTlsProps {
  tlId: N<number>;
  activeId: number;
  rate: number;
  handleClickDevice?: HandleClickDevice;
}

const LinkedTls: FC<LinkedTlsProps> = ({
  tlId,
  activeId,
  rate,
  handleClickDevice,
}) => {
  const { tls } = rootStore.mapDataStore;

  const onClick = useCallback(
    () => isNumber(tlId) && handleClickDevice?.(tlId, System.Lights),
    [handleClickDevice, tlId]
  );

  const tl = findById(tls, tlId);

  if (!tl || !isNumber(tlId)) return null;

  const { tlStatus, dai_jdt83 } = tl;
  const { mode, state } = tlStatus;

  const statusFill = getDeviceColor(state);
  const { ico: Icon, borderColor } = getTLIcon(mode);

  const iconProps = dai_jdt83?.tlMain;

  if (!iconProps) return null;

  const { iconOffsetX, iconOffsetY } = iconProps;

  const fill = tlId === activeId ? PRIMARY : statusFill;

  return (
    <TLIcon
      isVisible
      offsetX={iconOffsetX}
      offsetY={iconOffsetY}
      fill={fill}
      Icon={Icon}
      rate={rate}
      activeId={activeId}
      borderColor={borderColor ?? WHITE}
      isIcoWithId
      id={tlId}
      onClick={onClick}
    />
  );
};

export default observer(LinkedTls);
