const getActiveStatus = (status: N<string>, activeStatus: string[]) => {
  if (!status) return [];

  const isNeedAdd = activeStatus.every((el) => el !== status);

  if (isNeedAdd) return [...activeStatus, status];

  return activeStatus.filter((el) => el !== status);
};

export default getActiveStatus;
