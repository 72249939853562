import React from 'react';
import { MdGpsFixed, MdLayers, MdLocationPin } from 'react-icons/md';

import {
  SEARCH_RESULT_ICON_SIZE,
  SEARCH_TYPE_COORDINATES,
  SEARCH_TYPE_GEOCODER,
  SEARCH_TYPE_LAYER,
} from '../constants';

import styles from '../SearchButton.module.scss';

export const SearchResultIcon = (props: { item: any }) => (
  <>
    <div className={styles.searchItemIconType}>
      {props.item.type === SEARCH_TYPE_LAYER ? (
        <MdLayers size={SEARCH_RESULT_ICON_SIZE} />
      ) : null}
      {props.item.type === SEARCH_TYPE_GEOCODER ? (
        <MdLocationPin size={SEARCH_RESULT_ICON_SIZE} />
      ) : null}
      {props.item.type === SEARCH_TYPE_COORDINATES ? (
        <MdGpsFixed size={SEARCH_RESULT_ICON_SIZE} />
      ) : null}
    </div>
  </>
);
