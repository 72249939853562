import { getAppPathHeaders } from '../helpers/getAppPathHeaders';
import http from '../http';
import tryCatchWrapper from '../tryCatchWrapper';

import { SERVER } from './constants';
import { integrationConfiguration } from './integrationConfiguration/integrationConfiguration';
import { InfoSchema } from './server.zod';
import sessions from './sessions';
import { tableValidation } from './tableValidation/tableValidation';
import { web } from './web/web';

const PING = SERVER + '/ping';
const GROUPS = SERVER + '/groups';
const ERROR = SERVER + '/error';
const REFRESH = SERVER + '/data/refresh';

export const server = {
  web,
  sessions,
  integrationConfiguration,
  tableValidation,
  fetchPing: async (appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.get(PING, {
        headers: getAppPathHeaders(appPath),
      });

      return InfoSchema.parse(res.data);
    }),
  fetchGroups: async (appPath: string) => {
    const res = await http.get(GROUPS, {
      headers: getAppPathHeaders(appPath),
    });

    return res.data;
  },
  fetchRefreshServerData: async (appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.get(REFRESH, {
        headers: getAppPathHeaders(appPath),
      });

      return InfoSchema.parse(res.data);
    }),
  sendError: async (body: unknown, appPath: string) => {
    const res = await http.post(ERROR, body, {
      headers: getAppPathHeaders(appPath),
    });

    return res;
  },
};
