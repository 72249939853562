import { Dayjs } from 'dayjs';
import { useEffect, useMemo } from 'react';

import api from '../../../../../api';
import { Path } from '../../../../../api/api.model';
import { LibraryKeys } from '../../../../../api/libraries/enums/enums';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { getTimeWithTimeZone } from '../../../../TrafficLightDetailed/TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeWithTimeZone';
import { getSystemNames } from '../helpers/getSystemNames';

const { useStatistic } = api.adminObjects.systems.adapter;
const { RegionId, SystemId } = LibraryKeys;

export type SystemType = number[] | number;

interface UseAdapterStatisticProps {
  appPath: Path;
  systemIds?: SystemType;
  systemTypes?: SystemType;
  subSystemTypes?: SystemType;
  regionIds?: SystemType;
  isNeedUpdate?: boolean;
  isEnabled?: boolean;
  onUpdateTime?: (time: Dayjs) => void;
}

const useAdapterStatistic = ({
  appPath,
  systemIds,
  systemTypes,
  regionIds,
  subSystemTypes,
  isNeedUpdate = false,
  isEnabled = true,
  onUpdateTime,
}: UseAdapterStatisticProps) => {
  const regionsDict = rootStore.uiStore.dictionaries[RegionId];
  const systemDict = rootStore.uiStore.dictionaries[SystemId];

  const { adapterStatistics, isError, isFetching, isLoading, refetch } =
    useStatistic({
      appPath,
      regionIds,
      subSystemTypes,
      systemIds,
      systemTypes,
      isEnabled,
    });

  useEffect(() => {
    const updateTime = getTimeWithTimeZone().dateFormattedJS;

    onUpdateTime?.(updateTime);
  }, [adapterStatistics, onUpdateTime]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNeedUpdate]);

  const statistic = useMemo(() => {
    return getSystemNames(adapterStatistics ?? {}, systemDict, regionsDict);
  }, [systemDict, adapterStatistics, regionsDict]);

  return {
    isError: !adapterStatistics || isError,
    statistic,
    isLoading,
    isFetching,
  };
};

export default useAdapterStatistic;
