import { isNumber, isString } from 'lodash';

import { WEEK_FORMAT } from '../../../../../../constants/constants';
import { findBy } from '../../../../../../helpers/findBy';
import { getIsValidItem } from '../../../helpers/getIsValidItem';
import checkReversedFormat from '../../SearchForm/DateFormatPicker/helpers/checkReversedFormat';

import {
  isDateValues,
  isRangeDateValues,
  QueryParamItem,
  VALUE_TYPES,
} from './../../../../../../ts/models/table.model';

const ERROR_TEXT = 'Неверный формат полученных данных';

export const getServerSearchValues = (queryParams: QueryParamItem[]) => {
  const values: { [key: string]: QueryParamItem } = {};

  queryParams.forEach((param) => {
    const isValidDefault = getIsValidItem(param.jvalueType, param.defaultValue);
    const isValidCurrent = getIsValidItem(param.jvalueType, param.currentValue);

    if (isValidDefault || isValidCurrent)
      values[param.webElementCapture] = param;
  });

  return values;
};

const isArray = (value: unknown): value is [] => {
  return Array.isArray(value);
};

const formattedDateValue = (
  jvalueType: 'datePickerJson' | 'rangeDatePickerJson',
  value: unknown
) => {
  if (
    jvalueType === VALUE_TYPES.rangeDatePickerJson &&
    isRangeDateValues(value, jvalueType)
  )
    return `С ${value.from} по ${value.to}`;

  if (
    jvalueType === VALUE_TYPES.datePickerJson &&
    isDateValues(value, jvalueType)
  ) {
    const correctionFormat = value.dateFormat.trim().toUpperCase();
    const isWeekFormat = checkReversedFormat(correctionFormat, WEEK_FORMAT);

    const weekValue = value.value;

    if (isWeekFormat && weekValue) {
      const [week, year] = weekValue.split('.');

      return `${week} неделя ${year}г.`;
    }

    return weekValue;
  }

  return ERROR_TEXT;
};

export const parseFilterValue = (param: QueryParamItem) => {
  const value = getIsValidItem(param.jvalueType, param.currentValue)
    ? param.currentValue
    : param.defaultValue;
  const webElemOpt = param.webElementOptions;

  if (
    param.jvalueType === VALUE_TYPES.datePickerJson ||
    param.jvalueType === VALUE_TYPES.rangeDatePickerJson
  ) {
    return formattedDateValue(param.jvalueType, value);
  }

  if (isArray(value)) {
    if (!webElemOpt?.length) return value.join(' ,');

    return value.reduce((acc: string, el: SN) => {
      const label = findBy(webElemOpt, el, 'value')?.label;

      if (!label) return acc;

      return acc ? `${acc} , ${label}` : label;
    }, '');
  }

  if (isNumber(value) || isString(value)) {
    if (!webElemOpt?.length) return value;

    return findBy(webElemOpt, value, 'value')?.label ?? ERROR_TEXT;
  }

  return ERROR_TEXT;
};
