import { Card } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { HiMap } from 'react-icons/hi';

import { OSM_ITEM } from '../../../apiGIS/constants/basemaps/sources';
import { MAP_BUTTONS_NAMES } from '../../../constants/uiComponentConstants';
import { IBaseMapItem } from '../../../stores/gisDataStore/gisDataStore.model';
import rootStore from '../../../stores/rootStore/rootStore';
import { MapButtonsCode } from '../../../ts/enums/userData';
import ButtonIco from '../../ui-kit/ButtonIco/ButtonIco';
import { PLACEMENT_MAP_BTNS } from '../LayersButton/LayersButton';

import { BaseMapButton } from './BaseMapButton';
import { LoadingBaseMapButton } from './LoadingBaseMapButton';

import styles from './BasemapsButton.module.scss';

const PLACEMENT_ITEM = 'bottom';

export const BASEMAPS_CONTENT = {
  content: 'Картографические подложки',
  contentDisabled:
    'Для выбранного региона картографические подложки временно недоступны',
  title: 'Подложки',
  loading: 'загрузка ...',
  loadingPopover: 'Загрузка подложек по региону',
};

export const LOCAL_STYLES = {
  panelBodyStyle: {
    margin: 0,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
  },
  borderRadius: 6,
  emptyCard: { width: 96, height: 96 },
};

const BasemapsButton: FC = () => {
  const { uiStore } = rootStore;
  const { activeMapButton, mapButtonsTipsDelay, setKeyValue, isBasemapBtnGis } =
    uiStore;

  const { setGisValue, getBasemaps, basemaps } = rootStore.gisDataStore;
  const { hasAccess } = rootStore.userDataStore;
  const [isShowPanel, setShowPanel] = useState(false);
  const [items, setItems] = useState<IBaseMapItem[]>([]);
  const [currentBasemap, setCurrentBasemap] = useState('OSM');

  const togglePanel = () => {
    const serverBasemaps = getBasemaps();

    const items = [OSM_ITEM].concat(serverBasemaps);

    setItems(items);
    setShowPanel(!isShowPanel);
  };

  useEffect(() => {
    if (activeMapButton !== MAP_BUTTONS_NAMES.BASEMAPS) {
      setShowPanel(false);
    }
  }, [activeMapButton]);

  useEffect(() => {
    if (isShowPanel) {
      setKeyValue('activeMapButton', MAP_BUTTONS_NAMES.BASEMAPS);
    }
  }, [isShowPanel, setKeyValue]);

  useEffect(() => {
    setItems(basemaps);
  }, [basemaps]);

  const popOverProps = {
    placement: PLACEMENT_MAP_BTNS,
    tipsDelay: mapButtonsTipsDelay,
  } as const;

  const popOverItemProps = {
    placement: PLACEMENT_ITEM,
    tipsDelay: mapButtonsTipsDelay,
  } as const;

  const selectBasemap = (item: IBaseMapItem) => {
    setGisValue('basemap', item);
    setCurrentBasemap(item.id || 'OSM');
  };

  const getSubtitle = (item: IBaseMapItem) =>
    item.text && item.text.length > 10
      ? item.text.slice(0, 8) + '...'
      : item.text;

  const isAccess = hasAccess(MapButtonsCode.BasemapsButton);

  if (!isAccess || !isBasemapBtnGis) return null;

  return (
    <>
      <ButtonIco
        isActive={isShowPanel}
        onClick={() => {
          togglePanel();
        }}
        popoverProps={{ ...popOverProps, content: BASEMAPS_CONTENT.content }}
      >
        <HiMap />
      </ButtonIco>

      {isShowPanel ? (
        <>
          <Card
            bordered={false}
            className={styles.baseMapsPanel}
            bodyStyle={LOCAL_STYLES.panelBodyStyle}
          >
            <div className={styles.baseMapsPanelTitle}>
              {BASEMAPS_CONTENT.title}
            </div>
            <div className={styles.baseMapsContainer}>
              {!items.length ? (
                <LoadingBaseMapButton popOverItemProps={popOverItemProps} />
              ) : (
                items.map((item) => {
                  return (
                    <BaseMapButton
                      key={item.id}
                      item={item}
                      popOverItemProps={popOverItemProps}
                      onClick={() => {
                        selectBasemap(item);
                      }}
                      currentBasemap={currentBasemap}
                      subtitle={getSubtitle(item)}
                    />
                  );
                })
              )}
            </div>
          </Card>
        </>
      ) : null}
    </>
  );
};

export default observer(BasemapsButton);
