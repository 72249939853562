import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';

import rootStore from '../../stores/rootStore/rootStore';
import DefaultChecked from '../ui-kit/DefaultChecked/DefaultChecked';
import Detailed from '../ui-kit/Detailed/Detailed';

import styles from './ExternalResource.module.scss';

export interface IExternalResource {
  name: string;
  url: string;
}

const ExternalResource: FC = () => {
  const [isLoad, setIsLoad] = useState<boolean>(true);
  const [error, setError] = useState<string>();
  const { setKeyValue, externalResource, isDeviceStatus } = rootStore.uiStore;

  const onClose = () => {
    setKeyValue('externalResource', null);
  };

  useEffect(() => {
    setKeyValue('isModalLoading', !(!isLoad && externalResource));
  }, [isLoad, setKeyValue, externalResource]);

  useEffect(() => {
    setIsLoad(true);
  }, [externalResource]);

  if (!externalResource || !isDeviceStatus) return null;

  const handleLoad = () => {
    setIsLoad(false);
  };

  const handleError = () => {
    handleLoad();
    setError('Ошибка внешнего ресурса');
  };

  return (
    <Detailed header={externalResource.name} onClose={onClose}>
      <DefaultChecked
        isLoading={isLoad}
        isEmptyData={!!error}
        noDataProps={{ textError: error }}
        isBackdropFilter
      >
        <iframe
          className={styles.frame}
          allowFullScreen
          frameBorder="0"
          title={externalResource.name}
          src={externalResource.url}
          onLoad={handleLoad}
          onError={handleError}
        />
      </DefaultChecked>
    </Detailed>
  );
};

export default observer(ExternalResource);
