const getArrayOfColor = (n = 1, color = '#fff') =>
  n < 0 ? color : new Array(n).fill(color);

const SIGNAL_GRAPH_COLORS = {
  GREEN: '#3fd300',
  YELLOW_RED: '#d46a00',
  RED: '#ff0000',
  OTHER: '#bfbaba99',
  FLASH_YELLOW: '#fbff00',
  FLASH_GREEN: '#006600',
};
const { GREEN, YELLOW_RED, RED, OTHER, FLASH_YELLOW, FLASH_GREEN } =
  SIGNAL_GRAPH_COLORS;

export const getGreenColorLine = (num: number) => getArrayOfColor(num, GREEN);
export const getYellowRedColorLine = (num: number) =>
  getArrayOfColor(num, YELLOW_RED);
export const getRedColorLine = (num: number) => getArrayOfColor(num, RED);
export const getFlashYellowColorLine = (num: number) =>
  getArrayOfColor(num, FLASH_YELLOW);
export const getFlashGreenColorLine = (num: number) =>
  getArrayOfColor(num, FLASH_GREEN);
export const getDefaultColorLine = (num: number) => getArrayOfColor(num, OTHER);
