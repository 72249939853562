import { ProfilesList } from '../../../../../ts/models/constructor.model';

interface AccFromProfilesList {
  fullList: number[];
  isFinalExist: boolean;
  isNotFinalExist: boolean;
}

const getDataFromProfilesList = (profilesList: U<ProfilesList[]>) =>
  (profilesList ?? []).reduce(
    (acc: AccFromProfilesList, { profileId, isFinalVersion }: ProfilesList) => {
      profileId && acc.fullList.push(profileId);

      if (isFinalVersion) {
        acc.isFinalExist = true;
      }

      if (!isFinalVersion && !acc.isNotFinalExist) {
        acc.isNotFinalExist = true;
      }

      return acc;
    },
    {
      fullList: [],
      isFinalExist: false,
      isNotFinalExist: false,
    }
  );

export default getDataFromProfilesList;
