import { Card } from 'antd';

import styles from './EmptyContainer.module.scss';

interface IEmptyContainer {
  text: string;
}

const EmptyContainer = ({
  text = 'Недостаточно данных для отрисовки программы.',
}: IEmptyContainer) => (
  <Card className={styles.wrapper}>
    <h2 className={styles.noData}>{text}</h2>
  </Card>
);

export default EmptyContainer;
