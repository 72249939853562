import classNames from 'classnames';

import styles from './ModalBody.module.scss';

interface IModalBody {
  content?: JSX.Element;
  image?: N<JSX.Element>;
  phasesEdit?: boolean;
  isPhasesTable?: boolean;
}

const ModalBody = ({
  content,
  image,
  phasesEdit = false,
  isPhasesTable = false,
}: IModalBody) => {
  const containerStyle = classNames({
    [styles.containerDefault]: true,
    [styles.containerRow]: !phasesEdit,
    [styles.containerColumn]: phasesEdit,
    [styles.containerRowPhase]: isPhasesTable,
  });

  return (
    <div className={containerStyle}>
      {content}
      {image}
    </div>
  );
};

export default ModalBody;
