// stroke
export const MIN_STROKE_WIDTH = 0.1;
export const MAX_STROKE_WIDTH = 30;
export const STEP_STROKE_WIDTH = 0.1;

// regular shape
export const MIN_POINTS_COUNT = 3;
export const STEP_POINTS_COUNT = 1;
export const DEFAULT_SHAPE_RADIUS = 15;
export const MIN_SHAPE_RADIUS = 1;
export const MAX_SHAPE_RADIUS = 30;
export const STEP_SHAPE_RADIUS = 0.1;
