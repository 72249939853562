import { INCREASE_GRAPH_HEIGHT, X_KEY } from '../constants/constants';
import { CalcData } from '../models/chart.model';

export const getPeakHighestNumber = (data: CalcData[]) => {
  const res = data.reduce((acc: number, item) => {
    let max = 0;

    for (const key in item) {
      if (key === X_KEY) continue;

      const value = item[key];

      if (typeof value === 'number' && value > max) {
        max = value;
      }
    }

    return max > acc ? max : acc;
  }, 0);

  return Math.round(res * INCREASE_GRAPH_HEIGHT);
};
