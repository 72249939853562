import { Card, Spin } from 'antd';
import classNames from 'classnames';
import { FC } from 'react';

import NewSignalProgram from '../../../../NewSignalProgram/NewSignalProgram';
import { secondsToTime } from '../../PhaseHold/helpers/secondsToTime';
import { BasicModalSignalProgram } from '../../PhaseTimeChangeCurrentPlanMode/model/currentPlan.model';

import styles from './ModalSignalProgram.module.scss';

interface IModalPhaseImage extends BasicModalSignalProgram {
  isError: boolean;
  signalProgramClassName?: string;
  signalGraphWrapClassName?: string;
  isEditTempPlan?: boolean;
}

type NoDataProps = Pick<
  IModalPhaseImage,
  'programExecTime' | 'signalProgramClassName'
>;

const NoData: FC<NoDataProps> = ({
  programExecTime,
  signalProgramClassName,
}) => (
  <Card className={signalProgramClassName}>
    {programExecTime ? <h2>{programExecTime}</h2> : null}
    <h3>Некорректные данные для отрисовки программы.</h3>
  </Card>
);

const ModalSignalProgram = ({
  programExecTime,
  csdData,
  phasesSet,
  isError,
  isLoadingProgram,
  signalProgramClassName = styles.signalPRg,
  signalGraphWrapClassName = styles.signalGraphWrap,
  id,
  isEditTempPlan = false,
}: IModalPhaseImage) => {
  const isErrorData = !csdData || !phasesSet || isError;

  if (!isLoadingProgram) {
    return (
      <Card className={signalProgramClassName}>
        <div className={signalGraphWrapClassName}>
          <Spin size="large" />
        </div>
      </Card>
    );
  }

  if (isErrorData)
    return (
      <NoData
        programExecTime={programExecTime}
        signalProgramClassName={signalProgramClassName}
      />
    );

  const titleContent = `${
    programExecTime ?? 'Неизвестный план'
  }. Общее время: ${secondsToTime(csdData.circle)} (${csdData.circle}c.)`;

  return (
    <div
      className={classNames({
        [styles.wrapper]: isEditTempPlan,
      })}
    >
      <NewSignalProgram
        id={id}
        isStatic={true}
        phasesSet={phasesSet}
        csdData={csdData}
        title={titleContent}
      />
    </div>
  );
};

export default ModalSignalProgram;
