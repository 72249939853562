import { DEVICE_TYPES_ID } from '../../../../../constants/constants';
import {
  DEVICES_INFO,
  DEVICES_INFO_STATUS,
} from '../../../../../constants/devicesConstants';
import { findById } from '../../../../../helpers/findBy';
import { LibraryValues } from '../../../../../stores/uiStore/uiStore.model';
import { Detector } from '../../../../../ts/models/mapObject.model';

export interface IDtInfo {
  label: string;
  value: N<string>;
}

export const getGeneralDtInfo = (
  dt: Detector,
  systemTypeDict: LibraryValues[]
): IDtInfo[] => {
  const { caption, deviceStatusId, deviceTypeId, dsc, uid } = dt.deviceInfo;
  const { scSystemId } = dt.eputsAdapter;

  const systemTypeId = dt.eputsAdapter.scSystemTypeId;
  const extOptions = dt.dai_jdt80.source_info?.ext_options;
  const uidCamera =
    extOptions?.camera_jsetting2?.dt_ext_jsetting?.camera_uid ??
    extOptions?.camera_jsetting?.uid;
  const correctUid = uidCamera ?? null;

  const statusArr =
    DEVICES_INFO_STATUS.find(([key, value]) => deviceStatusId === value) ?? [];
  const statusName = statusArr[0] ?? 'Undefined';

  const status = DEVICES_INFO.STATE[statusName].dsc;

  const systemType = findById(systemTypeDict, systemTypeId)?.name || null;

  const dtInfoArr = [
    { label: 'Название детектора', value: caption },
    { label: 'Тип детектора', value: DEVICE_TYPES_ID[deviceTypeId] },
    { label: 'Id Системы', value: scSystemId.toString() },
    { label: 'Адаптер', value: systemType },
    { label: 'Статус устройства', value: status },
    { label: 'Источник', value: dsc },
    { label: 'id устройства', value: uid },
    { label: 'id камеры', value: correctUid },
  ];

  return dtInfoArr;
};
