import { FC } from 'react';

import { QueryParamItem } from '../../../../../../ts/models/table.model';

import FiltersList from './FiltersList/FiltersList';
import { getServerSearchValues } from './helpers';

import styles from './PopoverContent.module.scss';

interface IPopoverContent {
  queryParams: QueryParamItem[];
}
const TEXT = 'Настройка серверной фильтрации';
const TITLE = 'Примененные фильтры: ';

const PopoverContent: FC<IPopoverContent> = ({ queryParams }) => {
  const values = Object.entries(getServerSearchValues(queryParams));

  if (!values.length) return <p className={styles.text}>{TEXT}</p>;

  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>{TITLE}</p>
      <FiltersList filters={values} />
    </div>
  );
};

export default PopoverContent;
