export const WEATHER_STYLES = {
  panelBodyStyle: {
    margin: 0,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    width: 510,
  },
  timePanelStyle: {
    margin: 0,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
    paddingBottom: 10,
    display: 'flex',
    alignItems: 'center',
    height: 65,
  },
  borderRadius: 6,
  icon: {
    color: 'rgba(255,255,255,1)',
  },
  timeIcon: {
    marginRight: 5,
    width: 96,
    height: 45,
    background: 'rgb(79, 81, 100)',
  },
  timeIconLight: {
    marginRight: 5,
    width: 96,
    height: 45,
    background: 'var(--bg-tint)',
    color: 'white',
  },
  cardBody: {
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  timeButton: {
    width: 96,
    height: 45,
  },
};

export const TIME_SLIDER_CONSTANTS = {
  body: {
    background: 'var(--bg)',
    color: 'var(--font-color)',
    width: 28,
    height: 66,
    display: 'flex',
    alignItems: 'center',
    zIndex: 10,
  },
  arrow: {
    height: 45,
    width: 28,
    background: 'var(--bg-tint)',
    color: 'var(--font-color)',
  },
  borderRadiusRight: {
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    paddingRight: 5,
  },
  borderRadiusLeft: {
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    paddingRight: 5,
  },
};

export const TIME_INTERVAL_TTL = 10000;

export const WEATHER_CONTENT = {
  content: 'Метеоданные',
  contentDisabled: 'Для выбранного региона метеоданные временно недоступны',
  title: 'Метеоданные',
};

export const PLACEMENT = 'bottom';

export const MENU_WIDTH = 80;
export const MIN_OFFSET = 15;

export const LEFT_PANEL_WIDTH = 312;
export const RIGHT_PANEL_WIDTH = 500;

export const LEFT_OFFSET = 20;
export const RIGHT_OFFSET = 50;

export const MINIMAP_BTN_WIDTH = 60;
export const MINIMAP_WIDTH = 260;
export const SCALE_LINE_WIDTH = 120;
