import { FC } from 'react';
import { RxUpdate } from 'react-icons/rx';

import ButtonOnlyIcon from '../../../ButtonOnlyIcon/ButtonOnlyIcon';
import { IButtonsPanelProps } from '../ButtonsPanel';

type TUpdateButtonProps = Pick<
  IButtonsPanelProps,
  'handleUpdateGraph' | 'isLoading'
>;

const UpdateButton: FC<TUpdateButtonProps> = ({
  isLoading,
  handleUpdateGraph,
}) => {
  const tooltipContent = isLoading
    ? 'Идет обновление'
    : 'Обновить данные по графику';

  return (
    <ButtonOnlyIcon
      isDisabled={isLoading}
      onClick={handleUpdateGraph}
      tip={tooltipContent}
      isSmall
    >
      <RxUpdate />
    </ButtonOnlyIcon>
  );
};

export default UpdateButton;
