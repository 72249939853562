import { z } from 'zod';

import { NOT_WORK_STATES, SPECIAL_MODES } from '../../constants/commands';
import { NNInt } from '../zod/utils.zod';

export const getTlStatusZodValidation = (tlStatus: any, nameKey: string) => {
  const { mode, state } = tlStatus;

  const isSpecialValidation =
    SPECIAL_MODES.includes(mode) || NOT_WORK_STATES.includes(state);
  const value = tlStatus[nameKey];

  if (nameKey === 'last') {
    const isValidateLast = isSpecialValidation
      ? z.number().safeParse(value).success
      : z.number().positive().lte(32).safeParse(value).success;

    return isValidateLast;
  }

  const isValidateValue = isSpecialValidation
    ? NNInt.nullable().safeParse(value).success
    : NNInt.safeParse(value).success;

  return isValidateValue;
};
