import { Feature } from 'ol';

import {
  FeaturesMode,
  getStatusDataCluster,
} from '../../../../ui-kit/MapLayers/ClusterLayers/helpers/colors';

interface ClustersStatus {
  name: string;
  length: number;
}

export const getClusterStatusDevices = (
  clustersFeatures: Feature[],
  featureMode: FeaturesMode
) => {
  const { data, statusArr } = getStatusDataCluster(
    clustersFeatures,
    featureMode
  );

  return data.reduce((acc: ClustersStatus[], value, i) => {
    if (value) {
      acc.push({
        name: statusArr[i],
        length: value,
      });
    }

    return acc;
  }, []);
};
