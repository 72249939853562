import { makeAutoObservable } from 'mobx';

import { IBreadCrumbsItem } from '../../components/Admin/BreadCrumbs/BreadCrumbs';
import {
  getActiveInfo,
  getCurrentReportItem,
  getFilteredBySearchedValues,
} from '../../components/Panel/ReportsPanel/ReportTree/helpers/helpersTree';
import {
  ReportsLabels,
  ReportsTreeItem,
} from '../../components/Panel/ReportsPanel/ReportTree/model/reports.model';
import { PanelType } from '../../ts/enums/enums';
import RootStore from '../rootStore/rootStore';
import { StoreUtils } from '../storeUtils/storeUtils';

class ReportsPanelStore {
  rootStore;
  tree: ReportsTreeItem[] = [];
  activeKey: N<number> = null;
  activeBreadCrumbs: IBreadCrumbsItem[] = [];
  currentParams: N<ReportsTreeItem['params']> = null;
  reportsLabels: ReportsLabels[] = [];
  filteredValue = '';
  isLoadingTree = false;
  openedKeys: string[] = [];
  setReportsValue;
  setReportValues;

  constructor(rootStore: typeof RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
    this.rootStore = rootStore;

    const utils = new StoreUtils(this);

    this.setReportsValue = utils.setKeyValue;
    this.setReportValues = utils.setKeysValues;
  }

  clearStore = () => {
    this.setReportValues({
      activeBreadCrumbs: [],
      activeKey: null,
      openedKeys: [],
      isLoadingTree: false,
      filteredValue: '',
      reportsLabels: [],
      tree: [],
      currentParams: null,
    });
  };

  get activeReportInfo() {
    const key = this.activeKey;

    if (!key) return;

    return getCurrentReportItem(this.tree, key)?.treeItem;
  }

  get searchedItems() {
    const { activeItems, openedKeys } = getActiveInfo(
      this.tree,
      this.reportsLabels,
      this.filteredValue
    );

    this.setReportsValue('openedKeys', openedKeys);

    return activeItems;
  }

  get filteredTreeBySearch() {
    return getFilteredBySearchedValues(this.tree, this.searchedItems);
  }

  get isReportsPanel() {
    const { panelType } = this.rootStore.uiStore;

    return panelType === PanelType.Reports;
  }

  get isReportsDetailed() {
    return Boolean(this.activeReportInfo) && this.isReportsPanel;
  }
}

export default ReportsPanelStore;
