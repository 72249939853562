import { Fill, Icon, RegularShape, Stroke, Style, Text } from 'ol/style';

import { GEOMETRY_TYPE_NAMES } from '../../constants/geometry/types';
import { STYLE_CONSTANTS } from '../../constants/styles';
import {
  FillStyleFunctionProps,
  ImageStyleFunctionProps,
  StrokeStyleFunctionProps,
  StyleFunctionProps,
  TextStyleFunctionProps,
} from '../../models/styles/styleDefinitions';

function getFill(params: FillStyleFunctionProps | undefined): undefined | Fill {
  if (params?.color) {
    return new Fill({
      color: params.color || STYLE_CONSTANTS.COLOR.FILL,
    });
  }

  return undefined;
}

function getStroke(
  params: StrokeStyleFunctionProps | undefined
): Stroke | undefined {
  if (params) {
    const { color, width } = params;

    return new Stroke({
      width: width || STYLE_CONSTANTS.WIDTH.DEFAULT,
      color: color || STYLE_CONSTANTS.COLOR.DEFAULT,
    });
  }

  return undefined;
}

function getImage(
  params: ImageStyleFunctionProps
): RegularShape | Icon | undefined {
  if (params) {
    const { src } = params;
    let { radius, angle } = params;

    angle = angle || STYLE_CONSTANTS.ANGLE.DEFAULT;
    radius = radius || STYLE_CONSTANTS.RADIUS.DEFAULT;

    if (src) {
      return new Icon({
        src,
        rotation: angle,
        anchor: [0.5, 0.5],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: radius / STYLE_CONSTANTS.RADIUS.SCALE,
      });
    }

    const { points, fill, stroke } = params;

    return new RegularShape({
      radius,
      points: points || STYLE_CONSTANTS.POINTS.DEFAULT,
      angle,
      rotation: angle,
      stroke: getStroke(stroke),
      fill: getFill(fill),
    });
  }
}

function getText(props: TextStyleFunctionProps): Text | undefined {
  const { feature, resolution, showLabel } = props;

  let { params } = props;

  params = params || {};

  let { maxResolution, minResolution } = params;

  maxResolution = maxResolution || 0;
  minResolution = minResolution ?? Number.MAX_SAFE_INTEGER;

  if (!showLabel) return undefined;

  if (!(resolution <= minResolution && resolution >= maxResolution)) {
    return undefined;
  }

  const { field, text, offsetX, offsetY, font, stroke, fill, rotateWithView } =
    params;

  const geometry = feature.getGeometry();
  const geometryType = geometry
    ? geometry.getType()
    : GEOMETRY_TYPE_NAMES.Point;

  const placement =
    params.placement ||
    geometryType === GEOMETRY_TYPE_NAMES.LineString ||
    geometryType === GEOMETRY_TYPE_NAMES.MultiLineString
      ? STYLE_CONSTANTS.PLACEMENTS.LINE
      : STYLE_CONSTANTS.PLACEMENTS.POINT;

  const textAlign =
    params.align || placement === STYLE_CONSTANTS.PLACEMENTS.POINT
      ? STYLE_CONSTANTS.TEXT_ALIGN.LEFT
      : STYLE_CONSTANTS.TEXT_ALIGN.CENTER;

  let labelValue = text;

  if (feature && field) {
    labelValue = feature.get(field);
    labelValue = labelValue?.toString() ?? ' ';
  }

  return new Text({
    font,
    offsetX,
    offsetY,
    stroke: getStroke(stroke),
    fill: getFill(fill),
    rotateWithView: Boolean(rotateWithView),
    text: labelValue,
    textAlign,
    placement,
  });
}

/**
 * Возвращает стиль на основе параметров
 * @param props
 * @returns {undefined|Style}
 */
function getStyle(props: StyleFunctionProps): Style | undefined {
  const { params, feature, resolution, showLabel } = props;
  let { minResolution, maxResolution } = params;

  minResolution = minResolution || Number.MAX_SAFE_INTEGER;
  maxResolution = maxResolution ? maxResolution : 0;

  if (!(resolution <= minResolution && resolution >= maxResolution)) {
    return undefined;
  }

  const style = new Style();

  const fill = getFill(params.fill);
  const stroke = getStroke(params.stroke);
  const image = getImage(params.image);
  const text = getText({
    params: params.label,
    feature,
    resolution,
    showLabel,
  });

  image && style.setImage(image);

  stroke && style.setStroke(stroke);

  fill && style.setFill(fill);

  text && style.setText(text);

  return style;
}

export { getStyle };
