import { COLORS, COLOR_NAME } from '../../../constants/colorsConstants';
import { CircleElEnum } from '../../../ts/enums/tl';

const {
  Gathered,
  Top,
  Bottom,
  Direct,
  Right,
  Left,
  DirectRight,
  DirectLeft,
  Crosswalk,
} = CircleElEnum;

export const COLOR_KEYS = ['Зел.', 'Син.', 'Фиол.', 'Кр.', 'Кор.'];

export const ENTER_METHOD_OPTIONS = ['Клик', 'Ввод'];

export const COLORS_LIST = [
  { value: 'green', label: 'Зеленый' },
  { value: 'yellowgreen', label: 'Салатовый' },
  { value: 'blue', label: 'Синий' },
  { value: COLOR_NAME.YELLOW, label: 'Жёлтый' },
  { value: 'orange', label: 'Оранжевый' },
  { value: 'red', label: 'Красный' },
  { value: 'purple', label: 'Фиолетовый' },
  { value: 'brown', label: 'Коричневый' },
  { value: 'black', label: 'Чёрный' },
];

export const CAMERA_COLORS_LIST = [
  ...COLORS_LIST,
  { value: COLORS.PRIMARY, label: 'По умолчанию' },
];

export const WIDTH_RANGE = {
  MIN: 1,
  MAX: 6,
  CROSSWALK_MAX: 40,
};

export const DIRECTION_OPTIONS = [
  {
    value: Gathered,
    label: 'Сборная',
  },
  {
    value: Direct,
    label: 'Прямо',
  },
  {
    value: Right,
    label: 'Направо',
  },
  {
    value: Left,
    label: 'Налево',
  },
  {
    value: DirectRight,
    label: 'Прямо и направо',
  },
  {
    value: DirectLeft,
    label: 'Прямо и налево',
  },
  {
    value: Top,
    label: 'Только верх',
  },
  {
    value: Bottom,
    label: 'Только низ',
  },
  {
    value: Crosswalk,
    label: 'Пешеходный переход',
  },
];

export const TITLE_DIVIDER = ' | ';

export const ACCORDION_TITLE_LENGTH = 32;

export const ICON_SIZE = {
  MAX: 20,
  MIN: 5,
  DEFAULT: 10,
};

export const EXTENDED_LABEL = 'Расширенный режим:';
export const POINT_WITHOUT_NAME = 'Точка без имени';
export const VIEW_BLOCK_DIVIDER_MARGIN = '0 0 10px';
export const DISABLED_BY_OPACITY_ANIMATION =
  'Доступно при выключенной анимации мигания';
