import { useEffect } from 'react';

import api from '../../../api';
import rootStore from '../../../stores/rootStore/rootStore';
import { IUserData } from '../../../stores/userDataStore/userDataStore';
import { ProfileItem } from '../../../ts/models/videoWall.model';
import { USER_LAST_WATCHED_VIDEO_PROFILE_KEY } from '../constants';

interface HookProps {
  savedProfilesList: ProfileItem[];
  selectedProfileId: N<number>;
  user: N<IUserData>;
}

const useSelectedVideoProfileId = (props: HookProps) => {
  const { savedProfilesList, selectedProfileId, user } = props;

  const { selectProfile } = rootStore.videoWallPanelStore;

  const handleSelectVideoProfileId = async (selId: number) => {
    if (!user) return;

    if (!user.jprofile) {
      user.jprofile = {
        [USER_LAST_WATCHED_VIDEO_PROFILE_KEY]: selId,
      };
    } else {
      user.jprofile[USER_LAST_WATCHED_VIDEO_PROFILE_KEY] = selId;
    }

    await api.user.settings.saveItem(
      USER_LAST_WATCHED_VIDEO_PROFILE_KEY,
      selId,
      'videoWall.useSelectedVideoProfileId'
    );
  };

  useEffect(() => {
    if (!selectedProfileId) return;
    handleSelectVideoProfileId(selectedProfileId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProfileId]);

  useEffect(() => {
    const user = rootStore.userDataStore.user;

    if (!user?.jprofile) return;

    const userSavedId: N<number> =
      user.jprofile[USER_LAST_WATCHED_VIDEO_PROFILE_KEY];

    if (!userSavedId) return;

    const savedProfile = savedProfilesList.find((el) => el.id === userSavedId);

    if (!savedProfile) return;

    const { jprofile, name, isChange, isShared } = savedProfile;

    selectProfile({
      id: userSavedId,
      name,
      videoList: jprofile.videoList,
      layoutGrid: jprofile.layoutGrid,
      isChangeable: isChange,
      isShared,
    });
  }, [selectProfile, savedProfilesList]);

  return {};
};

export default useSelectedVideoProfileId;
