import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import usePhaseInfo from './hooks/usePhaseInfo';

import styles from './PhaseInfo.module.scss';

interface PhaseInfoProps {
  id: number;
}

const PhaseInfo: FC<PhaseInfoProps> = ({ id }) => {
  const { secToEndPhase, curPhase } = usePhaseInfo(id);

  if (!curPhase) return null;

  return (
    <div className={styles.container}>
      <span className={styles.phase}>Ф {curPhase.phase}</span>
      {secToEndPhase > 0 && (
        <span className={styles.time}>{secToEndPhase}</span>
      )}
    </div>
  );
};

export default observer(PhaseInfo);
