import { FC } from 'react';

import { ProjectionElProps } from '../../models/camera.model';

import styles from './CameraExtended.module.scss';

const ProjectionEl: FC<ProjectionElProps> = (props) => {
  const projectionWrapperStyle = {
    transform: `translate(-50%,-50%) rotate(${props.angle}deg)`,
  };

  return (
    <div className={styles.projectionWrapper} style={projectionWrapperStyle}>
      <div {...props} />
    </div>
  );
};

export default ProjectionEl;
