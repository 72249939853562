import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { ScaleLine } from 'ol/control';
import Control from 'ol/control/Control';
import { useEffect } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';

import styles from './ScaleLineControl.module.scss';

const ScaleLineControl = () => {
  const { isMinificMapOpen, isPanel, isScaleLine, isMinificMap } =
    rootStore.uiStore;

  const { map } = rootStore.mapStore;

  useEffect(() => {
    if (!map) return;

    map.getControls().forEach((interaction: Control) => {
      if (interaction instanceof ScaleLine) {
        map.removeControl(interaction);
      }
    });

    if (!isScaleLine) return;

    const overviewMapWithoutMinificStyle = classNames({
      [styles.scaleLineContainer]: true,
      [styles.isMinificSettingClose]: !isPanel,
    });

    const overviewMapStyle = classNames({
      [styles.scaleLineContainer]: true,
      [styles.isPanel]: isPanel && isMinificMapOpen,
      [styles.isMinificClose]: !isMinificMapOpen && !isPanel,
      [styles.isMinificCloseIsPanel]: !isMinificMapOpen && isPanel,
    });

    const mapScaleLine = new ScaleLine({
      className: isMinificMap
        ? overviewMapStyle
        : overviewMapWithoutMinificStyle,
    });

    map.addControl(mapScaleLine);
  }, [isMinificMapOpen, isPanel, isScaleLine, isMinificMap, map]);

  return null;
};

export default observer(ScaleLineControl);
