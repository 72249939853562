const getDirsEls = (dirs: number[], id: number) =>
  dirs.reduce((acc: HTMLElement[], dir) => {
    const elem = document.getElementById(`${id}-${dir}-direction`);

    elem && acc.push(elem);

    return acc;
  }, []);

export const getAllDirsElements = (
  dirs: N<number[]>,
  greenDirs: N<number[]>,
  id: number
) => {
  const cleanedDirs = dirs?.filter((dir) => !greenDirs?.includes(dir)) ?? [];

  const dirsEls = getDirsEls(cleanedDirs, id);
  const greenDirsEls = greenDirs ? getDirsEls(greenDirs, id) : [];

  return { dirsEls, greenDirsEls };
};
