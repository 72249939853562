import { findById } from '../../../../../../helpers/findBy';
import { TObjMap } from '../../../../../../stores/mapDataStore/mapDataStore.model';
import rootStore from '../../../../../../stores/rootStore/rootStore';
import { PointUds } from '../../../../../../ts/models/pointUds.model';
import { UdsInfo } from '../../models/crossroad.model';

const getUdsInfo = (pointUds: U<PointUds>, coord: U<XY>) => {
  if (!pointUds) return [];

  const { pointUdsName, pointUdsUid } = pointUds.deviceInfo;

  const coordCaption = coord?.join(', ');

  const udsInfo: UdsInfo[] = [
    { label: 'Название', value: pointUdsName },
    { label: 'Uid', value: pointUdsUid },
    { label: 'Координаты', value: `[${coordCaption}]` },
  ];

  return udsInfo;
};

export const getDeviceUdsInfo = (activeDevice: Nullish<TObjMap>) => {
  const { tls, pointsUds } = rootStore.mapDataStore;

  const linkedId = activeDevice?.linkedDeviceIds?.trafficLights;

  const linkedUdsSchemaUid = activeDevice?.deviceInfo?.pointUdsUid;

  const pointUds = pointsUds.find(
    ({ deviceInfo }) => deviceInfo.pointUdsUid === linkedUdsSchemaUid
  );

  const tlLinked = findById(tls, linkedId);

  const tlId = tlLinked?.id;

  let coord: U<XY>;

  coord = tlLinked ? [tlLinked.longitude, tlLinked.latitude] : undefined;

  if (!coord && pointUds) {
    coord = [pointUds.longitude, pointUds.latitude];
  }

  const udsInfo = getUdsInfo(pointUds, coord);

  return { tlId, coord, udsInfo };
};

export const getTLInfo = (dtId: N<number>) => {
  const { detectors } = rootStore.mapDataStore;

  const activeDt = findById(detectors, dtId);

  return getDeviceUdsInfo(activeDt);
};
