export const COLORS = {
  GREEN_DARK: '#007D06',
  PRIMARY: '#1573FF',
  BROWN_RED: '#E35744',
  TURQUOISE: '#3BB4BC',
  SUCCESS: '#37B571',
  YELLOW: '#F4CA16',
  WARNING: '#FA9E14',
  WARNING_WHITE: '#F1A511',
  ERROR: 'var(--error)',
  DANGER: 'var(--danger)',
  PURPLE: '#A226DD',
  MAGENTA: '#F0F',
  DISABLED: 'var(--disabled)',
  BLUE900: '#345187',
  BLUE500: '#1573FF',
  BLUE300: '#408cff',
  BLUE100: '#91d5ff',
  BLUE0: 'rgba(23, 143, 255, 0.16)',
  GREY10: '#C1C6D9',
  GREY100: 'var(--grey100)',
  GREY300: '#414354',
  GREY500: '#898F9D',
  WHITE600: '#bcbcbc',
  BLACK100: '#2f313e',
  BLACK900: 'var(--black900)',
  WHITY: '#f1f1f1',
  WHITE: '#fff',
  NONE: 'none',
  BG_MASK: 'rgba(0, 0, 0, 0.9)',
  GREY_TRANSPARENT: 'rgba(127,127,127,0.1)',
  GREY_ROAD: '#6D6968',
  BLACK: 'black',
  BG_DARK: '#2f313e',
  BG_TINT: 'var(--bg-tint)',
  BG_LIGHT: '#fff',
  FONT_COLOR: 'var(--font-color)',
  BG_TINT_CONTRAST: 'var(--bg-tint-contrast)',
  DANGEROUS: '#FF0000',
  NOT_ALLOWED: 'var(--dir-not-allowed)',
  ALLOWED: '#00ff40',
  BG: 'var(--bg)',
  DARK_ORANGE: '#FC7406',
  DARK_PINK: '#EB4C42',
};

export enum BarColors {
  Dangerous = '#DD5849',
  Average = '#E7AF2D',
  Normal = '#44B573',
}

export const PHASE_CIRCLE_COLORS = {
  GREEN: COLORS.SUCCESS,
  ORANGE: COLORS.WARNING,
  RED: COLORS.BROWN_RED,
};

export const MAP_SYSTEMS_COLORS = {
  DEFAULT: '#37B571',
  LIGHTS: '#37B571',
  DETECTORS: COLORS.BROWN_RED,
  CAMERAS: '#AB50F6',
};

export const MAP_ICONS_COLORS = {
  LIGHTS_COLOR: '#37B571',
  DETECTORS_COLOR: COLORS.BROWN_RED,
  CAMERAS_COLOR: '#AB50F6',
  METEO_COLOR: '#F8B34B',
  STREAMS: '#FFFFFF',
  STATIC_OBJECT: '#1573FF',
  TRANSPORT: '#3621B8',
  ACIDENT: '#EC7A12',
} as const;

export const DEFAULT_CROSSROAD_COLORS = {
  MAIN: 'yellowgreen',
  MAIN_TRANSITION: 'orange',
  CROSSWALK: 'blue',
  CROSSWALK_TRANSITION: 'purple',
};

export const COLOR_PICKER_PRESET = [
  '#F5222D',
  '#FA8C16',
  '#FADB14',
  '#8BBB11',
  '#52C41A',
  '#13A8A8',
  '#1677FF',
  '#2F54EB',
  '#722ED1',
  '#EB2F96',
];

export const COLOR_NAME = {
  YELLOW: 'yellow',
};
