import { CSSProperties } from 'react';

import { COLORS } from '../../../../constants/colorsConstants';
import rootStore from '../../../../stores/rootStore/rootStore';
import {
  LaneDirection,
  TrafficLane,
} from '../../../../ts/models/constructor.model';
import { LANE_LENGTH_MAX } from '../../constants/constants';
import getIcoColor from '../../helpers/getIcoColor';

const { PRIMARY, WARNING, GREY100 } = COLORS;

interface GetStylesArgs {
  trafficLane: TrafficLane;
  isMain: U<boolean>;
  laneDirection: LaneDirection;
  rate?: number;
  isActive?: boolean;
}

type GetBgStyleArgs = Pick<GetStylesArgs, 'trafficLane' | 'laneDirection'>;

const getGradient = (isGradient: boolean, color: string) =>
  isGradient ? `linear-gradient(0deg, rgba(0,0,0,0) 0%, ${color} 80%)` : color;

const getBgStyle = ({ trafficLane, laneDirection }: GetBgStyleArgs) => {
  const { isConstructor } = rootStore.constructorStore;
  const { infoData } = rootStore.uiStore;
  const { id, isGradient, linkColor } = trafficLane;
  const { color, laneParams } = laneDirection;

  const laneBgColor = isConstructor ? getIcoColor(linkColor, color) : WARNING;

  const isActive = id === infoData?.id;
  const mainBgColor = isActive ? PRIMARY : GREY100;
  const oneLaneColor = isActive ? PRIMARY : laneBgColor;
  const laneColor = laneParams.length > 1 ? laneBgColor : oneLaneColor;

  return {
    laneBg: getGradient(isGradient, laneColor),
    mainBgColor,
    oneLaneBg: getGradient(isGradient, oneLaneColor),
  };
};

const getStyles = ({
  trafficLane,
  isMain,
  rate,
  laneDirection,
  isActive = false,
}: GetStylesArgs) => {
  const { calcCircleVal } = rootStore.pointsUdsStore;
  const { id } = trafficLane;

  const {
    angle,
    offsetX,
    offsetY,
    width,
    mainWidth,
    length,
    opacity,
    mainOffsetX,
    mainOffsetY,
    laneParams,
  } = laneDirection;

  const { laneBg, mainBgColor, oneLaneBg } = getBgStyle({
    trafficLane,
    laneDirection,
  });

  const calcCircle = (value: number) => {
    return calcCircleVal(value, rate);
  };

  const laneStyle: CSSProperties = {
    height: calcCircle(length),
    background: laneBg,
    opacity,
    borderRadius: `${calcCircle(1)}px`,
  };

  const mainLaneStyle: CSSProperties = {
    height: calcCircle(15),
    top: calcCircle(12),
    backgroundColor: isActive ? COLORS.PRIMARY : mainBgColor,
  };

  const blockWrapperStyle: CSSProperties = {
    height: `${calcCircle(LANE_LENGTH_MAX)}px`,
    width: `${calcCircle(isMain ? mainWidth : width)}px`,
    top: calcCircle(offsetY),
    left: calcCircle(offsetX),
    transform: `translate(-50%, 0%) rotate(${angle}deg)`,
  };

  const oneLaneWrapperStyle = {
    ...blockWrapperStyle,
    background: oneLaneBg,
    top: calcCircle(mainOffsetY),
    left: calcCircle(mainOffsetX),
  };

  const oneLaneStyle = [
    {
      id,
      number: null,
      top: 0,
      right: 0,
      height: calcCircle(LANE_LENGTH_MAX),
      width: calcCircle(mainWidth),
    },
  ];

  const barStyles = laneParams.map(({ width, offset, id, number }) => ({
    id,
    number,
    top: 0,
    right: calcCircle(offset),
    height: calcCircle(LANE_LENGTH_MAX),
    width: calcCircle(width),
  }));

  const wrapperStyle = isMain ? oneLaneWrapperStyle : blockWrapperStyle;
  const laneStyles = isMain ? oneLaneStyle : barStyles;

  return {
    laneStyle,
    mainLaneStyle,
    barStyles,
    wrapperStyle,
    laneStyles,
  };
};

export default getStyles;
