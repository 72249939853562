import { Spin } from 'antd';
import { FC } from 'react';

import { SignalProgramsBandProps } from '../NewSignalProgram/model/SignalProgramsGraph.model';

import { IBasicProps } from './model/signalProgramsBand.model';
import PhaseRow, { PhaseRowProps } from './PhaseRow/PhaseRow';
import PhasesTime from './PhasesTime/PhasesTime';
import PhaseTitle from './PhaseTitle/PhaseTitle';

import styles from './SignalProgramsBand.module.scss';

const SignalProgramsBand: FC<SignalProgramsBandProps> = ({
  isStatic = false,
  phasesSet,
  isEmpty,
  isError,
  width,
  isSmall = false,
  sec,
}) => {
  if (!width) return <Spin className={styles.spin} />;

  const len = phasesSet.reduce((acc, { length }) => acc + length, 0);

  const isValid = !phasesSet?.length || !len || isError;

  if (isValid && ((isEmpty && !isStatic) || isStatic)) {
    return null;
  }

  const basicProps: IBasicProps = { len, width, phasesSet };
  const extProps: PhaseRowProps = { ...basicProps, isStatic, sec };

  return (
    <div className={styles.containerMain}>
      <PhaseTitle isStatic={isStatic} sec={sec} />
      <PhasesTime {...basicProps} isSmall={isSmall} />
      <PhaseRow {...extProps} />
    </div>
  );
};

export default SignalProgramsBand;
