import { FC } from 'react';

import { LaneDirectionKey } from '../../../../../../../../Constructor/TrafficLaneConstructor/TLaneConstructor.model';
import { TLanesBlockProps } from '../../../../../../../../Overlays/TrafficLanesBlock/TLanesBlock.model';
import TrafficLanesBlock from '../../../../../../../../Overlays/TrafficLanesBlock/TrafficLanesBlock';

const { ToCamera, FromCamera } = LaneDirectionKey;

const TLanes: FC<TLanesBlockProps> = (props) => {
  const { trafficLane } = props;
  const { fromCamera } = trafficLane;

  return (
    <>
      <TrafficLanesBlock {...props} directionKey={ToCamera} />

      {fromCamera ? (
        <TrafficLanesBlock {...props} directionKey={FromCamera} />
      ) : null}
    </>
  );
};

export default TLanes;
