import { ITimePoints } from '../../../ts/models/signalPrograms.model';

const getPhaseLen = (tacts: ITimePoints[]) => {
  return tacts.reduce((acc, { length }) => acc + length, 0);
};

export const getCurPhaseInfo = (phasesSet: ITimePoints[], sec: number) => {
  const curTact = phasesSet.find(
    ({ stop, start }) => sec <= stop && sec >= start
  );

  const curPhase = phasesSet.filter(({ phase }) => phase === curTact?.phase);
  const lenPhase = getPhaseLen(curPhase);

  const mainTactLen = curPhase.find(({ main }) => main)?.length ?? 0;
  const promTactLen = lenPhase - mainTactLen;

  const lastTact = curPhase?.at(-1)?.stop;
  const secToEnd = lastTact ? lastTact - sec : 0;

  return {
    curPhase: curTact?.phase ?? null,
    isPromTact: !curTact?.main,
    secToEnd,
    lenPhase,
    mainTactLen,
    promTactLen,
  };
};
