import { notification } from 'antd';

import NOTICE from '../../../../constants/notificationConstants';
import { VERSION } from '../../../../constants/versions';
import { SignalPrograms } from '../../../../ts/models/signalPrograms.model';
import http from '../../../http';
import tryCatchWrapper from '../../../tryCatchWrapper';

import { SignalProgramsSchema } from './signalPrograms.zod';

const OBJECTS = VERSION + '/objects';
const TL = OBJECTS + '/tl/';

const signalProgramsApi = {
  getProgramsData: (id: number) =>
    tryCatchWrapper(async () => {
      const res = await http.get<{
        tlSignalPrograms: Nullish<SignalPrograms[]>;
      }>(`${TL + id}/details`);
      const programs = res?.data?.tlSignalPrograms;

      if (programs) {
        const errorIds = programs.reduce((acc: string, el: SignalPrograms) => {
          const validation = SignalProgramsSchema.safeParse(el);

          if (!validation.success) {
            const planNumber = el.phaseGroupNum;

            // eslint-disable-next-line no-param-reassign
            acc += planNumber ? `№ ${planNumber}, ` : `без номера, `;
            console.error(`План:${planNumber}`, validation.error.issues);
          }

          return acc;
        }, '');

        errorIds &&
          notification.error({
            message: `Ошибка в сигнальных программах: ${errorIds.slice(
              0,
              -2
            )}.`,
            description: NOTICE.INCORRECT_TYPES_FLEX.description,
          });

        return programs;
      }

      return null;
    }),
};

export default signalProgramsApi;
