import classNames from 'classnames';
import { uniqueId } from 'lodash';
import { FC } from 'react';

import styles from './TimeLine.module.scss';

interface ITimeLine {
  width: number;
  maxCount: number;
}

const TimeLine: FC<ITimeLine> = ({ maxCount, width }) => {
  const line = new Array(maxCount).fill(0);

  if (!width) return null;

  return (
    <div className={styles.wrapper} style={{ width: `100%` }}>
      {line.map((_item, i) => {
        const isMultipleTen = i % 10 === 0;
        const isMultipleFive = i % 5 === 0 && i % 10 !== 0;

        const pointStyles = classNames({
          [styles.point]: true,
          [styles.isMultipleTen]: isMultipleTen,
          [styles.isMultipleFive]: isMultipleFive,
          [styles.isLast]: i === maxCount - 1,
        });

        return (
          <div key={uniqueId('#')} className={pointStyles}>
            {isMultipleTen && <span className={styles.tenPoint}>{i}</span>}
          </div>
        );
      })}
    </div>
  );
};

export default TimeLine;
