import { COLORS } from '../../../../constants/colorsConstants';

const { FONT_COLOR, WARNING, DANGER } = COLORS;

export enum StatusInfoBtn {
  Warning = 'warning',
  Error = 'error',
  Info = 'info',
}

export const INFO_COLOR: Record<StatusInfoBtn, string> = {
  error: DANGER,
  info: FONT_COLOR,
  warning: WARNING,
};
