import { TLPhaseCodes } from '../../../../../ts/enums/tl';

const { Undefined, Common, Phase, AllRed, Edit, Off, AllYellow } = TLPhaseCodes;

const translateCode = (code: number, phaseNumber: N<number>) => {
  switch (code) {
    case Undefined:
      return {
        label: 'Без фазы',
        postfix: '',
        mode: null,
      };
    case Common:
      return {
        label: 'Общее изображение перекрестка',
        postfix: '',
        mode: [1, 2, 3, 4, 7, 9, 10, 11],
      };
    case Phase:
      return {
        label: `Фаза № ${phaseNumber}`,
        postfix: `_${phaseNumber}`,
        mode: null,
      };
    case AllRed:
      return {
        label: `Кругом красное - AR`,
        postfix: '_AR_31',
        mode: [6],
      };
    case Edit:
      return {
        label: `Редактирование - ED`,
        postfix: '_ED_32',
        mode: null,
      };
    case Off:
      return {
        label: `Выключен - OFF`,
        postfix: '_OFF_33',
        mode: [0],
      };
    case AllYellow:
      return {
        label: `Кругом жёлтое - YF`,
        postfix: '_YF_34',
        mode: [5],
      };
    default:
      return {
        label: 'Неизвестный код фазы',
        postfix: '',
        mode: null,
      };
  }
};

export default translateCode;
