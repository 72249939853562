import { TYPE_OF_TACTS } from '../../../../../../constants/signalsConstants';
import {
  ICsdData,
  ITacts,
  ITimePoints,
} from '../../../../../../ts/models/signalPrograms.model';
import { IPhasesData } from '../../../PhaseHold/PhaseHold.model';

export const setTableData = (csdData: ICsdData, phasesSet: ITimePoints[]) => {
  const phasesLengthArr = phasesSet.reduce(
    (acc: IPhasesData[], item, index, arr) => {
      const { main, length, phase } = item;

      const mainTactItem = arr[index - 1];

      if (!main) {
        const phasesTypes = csdData.directions?.reduce(
          (acc: ITacts[], item) => {
            const promTactTypes: ITacts[] = item.tacts.filter(
              (item) =>
                item.phase === phase &&
                (item.type === TYPE_OF_TACTS.GREEN_FLASH ||
                  item.type === TYPE_OF_TACTS.YELLOW ||
                  item.type === TYPE_OF_TACTS.YELLOW_AND_RED)
            );

            if (promTactTypes.length) {
              return promTactTypes;
            }

            return acc;
          },
          []
        );

        if (!phasesTypes) return acc;

        const spanGreenBlinkTime = phasesTypes.find(
          (item) => item.type === TYPE_OF_TACTS.GREEN_FLASH
        )?.length;
        const spanYellowTime = phasesTypes.find(
          (item) => item.type === TYPE_OF_TACTS.YELLOW
        )?.length;
        const spanRedYellowTime = phasesTypes.find(
          (item) => item.type === TYPE_OF_TACTS.YELLOW_AND_RED
        )?.length;

        return [
          ...acc,
          {
            id: phase,
            key: `phase_${phase}`,
            phase: phase,
            time: mainTactItem.length,
            spanTime: length,
            spanGreenBlinkTime: spanGreenBlinkTime ?? 0,
            spanYellowTime: spanYellowTime ?? 0,
            spanRedYellowTime: spanRedYellowTime ?? 0,
          },
        ];
      }

      return acc;
    },
    []
  );

  return phasesLengthArr;
};
