import { uniqueId } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { ICsdData } from '../../../ts/models/signalPrograms.model';

import Name from './Name/Name';

import styles from './DirectionNames.module.scss';

interface IDirectionNames {
  csdData: ICsdData;
  isStatic: boolean;
}

const DirectionNames: FC<IDirectionNames> = ({ csdData, isStatic }) => {
  return (
    <div className={styles.directionNames}>
      {csdData.directions?.map((direction) => (
        <Name
          direction={direction}
          key={uniqueId('^')}
          len={csdData.circle}
          isStatic={isStatic}
          dirType={direction.dirTransportType}
        />
      ))}
    </div>
  );
};

export default observer(DirectionNames);
