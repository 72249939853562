import { fromLonLat } from 'ol/proj';
import { useEffect } from 'react';

// import { CLUSTERS } from '../../../constants/mapClusterConstants';
// import { ZOOM } from '../../../constants/mapConstants';
import { ZOOM } from '../../../constants/mapConstants';
import rootStore from '../../../stores/rootStore/rootStore';
import { MapActions } from '../../../stores/uiStore/uiStore.model';
import handleInfoPanel from '../helpers/handleInfoPanel';
import handleCenter from '../helpers/zoomHandlers/handleCenter';
import handleCenterZoom from '../helpers/zoomHandlers/handleCenterZoom';

const useInfoPanel = () => {
  const { infoData } = rootStore.uiStore;
  const { map } = rootStore.mapStore;

  useEffect(() => {
    if (!map || !infoData?.id || !infoData?.coordinate) return;
    const { id, system, coordFeatureOnCluster, coordinate, mapAction } =
      infoData;

    const coordinates = fromLonLat(coordinate);
    // const resolution =
    //   currentZoom < ZOOM.CLUSTER_BORDER
    //     ? CLUSTERS.CLUSTER_BORDER_RESOLUTION
    //     : map.getView().getResolution();
    //workaround so that the first time you click on a mapping object, an incorrect center calculation occurs
    // const offsetX = handleCenterOffset({ resolution });
    // const center = [coordinates[0] + offsetX, coordinates[1]];

    switch (mapAction) {
      case MapActions.CenterZoom:
        handleCenterZoom(coordinates, ZOOM.TILES_BORDER);
        break;
      case MapActions.Center:
        handleCenter(coordinates);
        break;
      default:
        break;
    }

    handleInfoPanel({
      id,
      coordinates,
      isFromMap: false,
      system,
      coordFeatureOnCluster,
    });
  }, [infoData, map]);
};

export default useInfoPanel;
