import { useEffect } from 'react';

import rootStore from '../../../../stores/rootStore/rootStore';
import { getFilteredLinkedLanes } from '../helpers/getLinkedLanes';
import { LinkedLanesArgs } from '../PointUdsOverlay.model';

const useLinkedLanes = ({ dtIds, uid }: LinkedLanesArgs) => {
  const { detectors } = rootStore.mapDataStore;
  const { setLinkedLanes, setPointsValue } = rootStore.pointsUdsStore;

  useEffect(() => {
    const newLinkedLanes = getFilteredLinkedLanes(dtIds);

    newLinkedLanes && uid && setLinkedLanes(newLinkedLanes, uid);

    return () => {
      uid && setLinkedLanes([], uid);
    };
  }, [detectors, setPointsValue, setLinkedLanes, uid, dtIds]);
};

export default useLinkedLanes;
