import { isNumber } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { FiMapPin } from 'react-icons/fi';

import { findById } from '../../../../helpers/findBy';
import { getSystemTitle } from '../../../../helpers/getSystemTitle';
import rootStore from '../../../../stores/rootStore/rootStore';
import { MapActions } from '../../../../stores/uiStore/uiStore.model';
import ButtonOnlyIcon from '../../../ui-kit/ButtonOnlyIcon/ButtonOnlyIcon';
import NoData from '../../../ui-kit/Errors/NoData/NoData';
import UnexpectedError from '../../../ui-kit/Errors/UnexpectedError/UnexpectedError';
import usePanelLogic from '../../hooks/usePanelLogic';
import { TEXT_EMPTY } from '../NoCurrentObj/NoCurrentObj';

import Title from './Title/Title';

import styles from './InfoHeader.module.scss';

const InfoHeader: FC = () => {
  const {
    infoData: infoDataMap,
    isDetailedForm,
    setInfoData,
  } = rootStore.uiStore;
  const { systemsInfo } = rootStore.mapDataStore;

  const { panelContentContext: infoData } = usePanelLogic(infoDataMap);

  if (!infoData || isDetailedForm) return null;

  const { id, system } = infoData;

  const currentObject =
    isNumber(id) && systemsInfo[system]
      ? findById(systemsInfo[system].data, id)
      : null;

  const caption = currentObject?.deviceInfo.caption;

  if (!currentObject) return <NoData textError={TEXT_EMPTY} isNeedBackground />;

  return (
    <UnexpectedError resetKeys={[id]}>
      <div className={styles.infoHead}>
        <ButtonOnlyIcon
          onClick={() =>
            setInfoData(
              { ...infoData, coordFeatureOnCluster: null },
              MapActions.CenterZoom
            )
          }
          isSmall
          popoverProps={{ placement: 'left' }}
          tip={'Поиск выбранного объекта на карте'}
        >
          <FiMapPin />
        </ButtonOnlyIcon>
        <div>
          <Title caption={getSystemTitle(system, currentObject)} />
          <Title caption={caption} isSub />
        </div>
      </div>
    </UnexpectedError>
  );
};

export default observer(InfoHeader);
