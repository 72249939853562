import { Button, Form } from 'antd';
import { FC, useEffect } from 'react';

import { useRegions } from '../../../../hooks/useRegions';
import useSubmitForm from '../../hooks/useSubmitForm';
import {
  FORM_SETTINGS,
  FORM_COMPONENTS,
  DEFAULT_ROWS_COUNT,
  MULTIPLE_MODE,
  SUBMIT_TEXT,
  RESET,
} from '../constants/constants';
import { ActionType, IDrawerBlock } from '../models/Organizations.model';

import handleSubmit from './helpers/handleSubmit';

import styles from './OrgRegForm.module.scss';

const PATH = 'Admin.Organizations.OrgRegForm';

const OrgRegForm: FC<IDrawerBlock> = (props) => {
  const { type, collaboration } = props;

  const { options } = useRegions(PATH);

  const { form, isLoading, isSubmit, submit, reset } = useSubmitForm<
    any,
    IDrawerBlock
  >({
    handleSubmit,
    props,
    initData: collaboration,
  });

  useEffect(() => {
    type !== ActionType.Edit || !collaboration
      ? form.resetFields()
      : form.setFieldsValue(collaboration);
  }, [form, type, collaboration]);

  return (
    <Form form={form} layout="vertical">
      {FORM_SETTINGS.map(({ label, type, name, rules }, i) => {
        const FormComponent = FORM_COMPONENTS[type];

        return (
          <Form.Item key={i} label={label} name={name} rules={rules}>
            <FormComponent
              options={options}
              allowClear
              rows={DEFAULT_ROWS_COUNT}
              mode={MULTIPLE_MODE}
            />
          </Form.Item>
        );
      })}

      <div className={styles.btns}>
        <Button
          type="primary"
          loading={isLoading}
          onClick={submit}
          disabled={!isSubmit}
        >
          {SUBMIT_TEXT[type]}
        </Button>

        <Button onClick={reset}>{RESET}</Button>
      </div>
    </Form>
  );
};

export default OrgRegForm;
