import { cloneDeep, isEqual } from 'lodash';
import { makeAutoObservable } from 'mobx';
import { Feature, getUid } from 'ol';
import type { Coordinate } from 'ol/coordinate';
import { Point } from 'ol/geom';
import { fromLonLat, toLonLat } from 'ol/proj';

import { FeaturesMode } from '../../components/ui-kit/MapLayers/ClusterLayers/helpers/colors';
import { getObjectEntries } from '../../helpers/getObjectEntries';
import {
  FeatureDeviceProps,
  FeaturesTypes,
  System,
} from '../../ts/enums/enums';
import RootStore from '../rootStore/rootStore';
import { StoreUtils } from '../storeUtils/storeUtils';
import { MapActions } from '../uiStore/uiStore.model';

import {
  AnimationTimeoutInfo,
  Clusters,
  MultipleClusters,
  OpenClusterOptions,
} from './clustersStore.model';
import { DEFAULT_CLUSTERS_VALUES } from './constants/constants';
import checkMarkerOff from './helpers/checkMarkerOff';

const { Features } = FeaturesTypes;

export type ClusterStoreType = ClustersStore;

class ClustersStore {
  rootStore;
  selectedClusters: MultipleClusters[] =
    DEFAULT_CLUSTERS_VALUES.selectedClusters;
  clusters: Feature<Point>[] = DEFAULT_CLUSTERS_VALUES.clusters;
  isClusterClicked: boolean = DEFAULT_CLUSTERS_VALUES.isClusterClicked;
  triggerCenteredCoord: N<Coordinate> =
    DEFAULT_CLUSTERS_VALUES.triggerCenteredCoord;
  animationTimeoutInfo: N<AnimationTimeoutInfo> =
    DEFAULT_CLUSTERS_VALUES.animationTimeoutInfo;
  features: Feature<Point>[] = DEFAULT_CLUSTERS_VALUES.features;
  unlinkedFeatures: Feature<Point>[] = DEFAULT_CLUSTERS_VALUES.unlinkedFeatures;
  setClustersKeyValue;

  constructor(rootStore: typeof RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
    this.rootStore = rootStore;

    const utils = new StoreUtils(this);

    this.setClustersKeyValue = utils.setKeyValue;
  }

  setAnimationTimeoutInfo = (featureId: number, timeoutId: NodeJS.Timeout) => {
    const isNeedClearTimeout =
      this.animationTimeoutInfo?.featureId !== featureId &&
      this.animationTimeoutInfo?.timeoutId;

    if (isNeedClearTimeout && this.animationTimeoutInfo) {
      clearTimeout(this.animationTimeoutInfo.timeoutId);
    }

    this.animationTimeoutInfo = { featureId, timeoutId };
  };

  setIsClusterClicked = (value: boolean) => {
    this.isClusterClicked = value;
  };

  setTriggerCenteredCoord = (coord: N<Coordinate>) => {
    this.triggerCenteredCoord = coord ? fromLonLat(coord) : null;
  };

  openClusterByFeatureId = (
    featureId: number,
    featureSystem: System,
    options?: OpenClusterOptions
  ) => {
    const { setKeyValue, setInfoData } = this.rootStore.uiStore;

    const isNeedOpenFeature = options?.isNeedOpenFeature ?? false;

    const cluster = this.clusters.find((cl) =>
      (cl.get(Features) as Feature<Point>[]).some(
        (feature) => feature.get('id') === featureId
      )
    );

    const clusterCoord = cluster?.getGeometry()?.getFlatCoordinates();

    const isFeature = cluster?.get(Features)?.length === 1;

    const basicInfoDataProps = {
      id: featureId,
      system: featureSystem,
    };

    if (isNeedOpenFeature && !cluster && options?.featureCoord) {
      return setInfoData(
        {
          ...basicInfoDataProps,
          coordinate: options.featureCoord,
        },
        MapActions.Center
      );
    }

    if (isFeature && clusterCoord && isNeedOpenFeature)
      return setInfoData(
        {
          ...basicInfoDataProps,
          coordinate: toLonLat(clusterCoord),
        },
        MapActions.Center
      );

    if (!clusterCoord) return;

    this.setTriggerCenteredCoord(toLonLat(clusterCoord));

    setKeyValue('clickedCartographyObj', {
      id: featureId,
      system: featureSystem,
    });
  };

  setSelectedClusters = (
    cluster: N<MultipleClusters>,
    isCheckMarkers = false
  ) => {
    const { markers } = this.rootStore.uiStore;
    const { clickedCluster } = this;

    if (!cluster) {
      if (!isCheckMarkers) {
        return (this.selectedClusters = []);
      }

      const isNeedCloseCluster = checkMarkerOff(markers, clickedCluster);

      isNeedCloseCluster && (this.selectedClusters = []);

      return;
    }

    const isClusterAdd = this.selectedClusters.some(
      (info) => getUid(info.cluster) === getUid(cluster.cluster)
    );

    this.selectedClusters.forEach((el) => {
      el.isClusterClicked = getUid(el.cluster) === getUid(cluster.cluster);
    });

    if (isClusterAdd) return;

    this.selectedClusters.push(cluster);
  };

  closeCluster = (featureId: number) => {
    this.selectedClusters = this.selectedClusters.filter(
      ({ cluster }) =>
        !cluster
          .get(FeaturesTypes.Features)
          .some(
            (feat: Feature<Point>) =>
              feat.get(FeatureDeviceProps.Id) === featureId
          )
    );
  };

  setClusters = (features: U<Feature<Point>[]>) => {
    if (!features) return (this.clusters = []);

    this.clusters = cloneDeep(features);
  };

  clearClusterStore = () => {
    getObjectEntries(DEFAULT_CLUSTERS_VALUES).forEach(([key, value]) => {
      //@ts-ignore
      this[key] = value;
    });
  };

  get clusterFeatures() {
    const featuresArr = this.clusters.map(
      (item) => item.get('features') as Feature<Point>[]
    );

    return featuresArr.reduce((acc: Clusters, value) => {
      value.length > 1 && acc.push(value);

      return acc;
    }, []);
  }

  get clickedCluster() {
    return (
      this.selectedClusters.find(({ isClusterClicked }) => isClusterClicked) ??
      null
    );
  }

  get clusterMapObj() {
    return this.clusters.find((feature) => {
      const clusterCoord = toLonLat(
        feature.getGeometry()?.getFlatCoordinates() ?? []
      );

      return isEqual(clusterCoord, this.clickedCluster?.coordinate);
    });
  }

  get isClusterOpen() {
    return !!this.clickedCluster;
  }

  get featuresOnCluster() {
    return this.clickedCluster?.cluster?.get(FeaturesTypes.Features) ?? [];
  }

  get isClusterWithClickedFeatures() {
    const { selectedFeatureSomeArray } = this.rootStore.scriptsControlStore;

    if (!this.isClusterClicked) return false;

    return selectedFeatureSomeArray.some(({ id }) =>
      this.clickedCluster?.cluster
        ?.get(FeaturesTypes.Features)
        ?.some((feat: Feature<Point>) => feat.get(FeatureDeviceProps.Id) === id)
    );
  }

  get isFeatureOnCluster() {
    const { infoData, clickedCartographyObj } = this.rootStore.uiStore;
    const { selectedFeatureSomeArray } = this.rootStore.scriptsControlStore;

    const select = infoData ?? selectedFeatureSomeArray.at(0);

    const featureId = clickedCartographyObj?.id ?? select?.id;

    return Boolean(
      this.clickedCluster?.cluster
        ?.get(FeaturesTypes.Features)
        ?.find(
          (feat: Feature<Point>) =>
            featureId === feat.get(FeatureDeviceProps.Id)
        )
    );
  }

  get featureMode() {
    const { isScriptsControl } = this.rootStore.scriptsControlStore;

    return isScriptsControl ? FeaturesMode.Disabled : FeaturesMode.Status;
  }
}

export default ClustersStore;
