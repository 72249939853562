import { Descriptions } from 'antd';
import { FC } from 'react';

import getDeviceMainDesc from './helpers/getDeviceMainDesc';

import styles from './Content.module.scss';

interface ContentProps {
  description: U<string>;
  address: N<string>;
  addressLoading: boolean;
  isShow: boolean;
}

const Content: FC<ContentProps> = ({
  description,
  address,
  addressLoading,
  isShow,
}) => {
  const items = getDeviceMainDesc(description, addressLoading, address);

  if (!isShow) return null;

  return (
    <div className={styles.container}>
      <Descriptions
        layout="vertical"
        items={items}
        size="small"
        className={styles.desc}
      />
    </div>
  );
};

export default Content;
