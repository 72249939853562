import { SgnProgramValues } from '../../../PlanSelect/models/plansControl.model';
import { setTableData } from '../../EditSgnProgramTable/helpers/setTableData';

const getDataTable = (
  programValues: SgnProgramValues[],
  selectedPlanId: number
) => {
  const program = programValues.at(selectedPlanId - 1);

  if (!program?.csdData) return;

  const { csdData, phasesSet } = program;

  const res = setTableData(csdData, phasesSet);

  return res;
};

export default getDataTable;
