import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';

import Point from './Point/Point';

import styles from './TimePointer.module.scss';

interface ITimePointer {
  height: number;
  step: number;
}

const ADDITIONAL_HEIGHT = 90;

const TimePointer: FC<ITimePointer> = ({ height, step }) => {
  const { secTL } = rootStore.uiStore;

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.pointWrapper}
        style={{
          height: height + ADDITIONAL_HEIGHT + 'px',
          transform: `translateX(${step * secTL}px)`,
        }}
      >
        <Point />
      </div>
    </div>
  );
};

export default observer(TimePointer);
