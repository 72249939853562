import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { filesApi } from '../../../../../api/files/files';
import { SECOND } from '../../../../../constants/constants';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { FilesDataType } from '../../../models/documents.model';
import getFilteredData from '../helpers/getFilteredData';
import handleSortUp from '../helpers/handleSortUp';

const PATH = 'TrafficLightDetailed.Documents.DocumentsList';

type Event = ChangeEvent<HTMLInputElement>;

const useDocumentsData = (id: number) => {
  const { isUpdate, setDocumentsKeysValues } = rootStore.documentsStore;

  const [data, setData] = useState<FilesDataType[]>([]);
  const [filteredData, setFilteredData] = useState<FilesDataType[]>([]);
  const [searchedValue, setSearchedValue] = useState<string>('');

  const handleSearch = useDebouncedCallback(
    (e: Event) => {
      const { value } = e.target;

      const newData = getFilteredData(data, value.trim());

      setFilteredData(newData);
      setDocumentsKeysValues({ isDocListLoading: false });
    },
    SECOND / 2,
    { leading: true }
  );

  const onSearch = useCallback(
    (e: Event) => {
      setSearchedValue(e.target.value);
      setDocumentsKeysValues({ isDocListLoading: true });
      handleSearch(e);
    },
    [handleSearch, setDocumentsKeysValues]
  );

  const getFileList = useCallback(async () => {
    setDocumentsKeysValues({
      isDocListLoading: true,
    });

    const res = await filesApi.fetchFilesList(id, PATH);
    const data: FilesDataType[] = handleSortUp(res?.data ?? []);

    setDocumentsKeysValues({
      isUpdate: false,
      isDocListLoading: false,
    });

    setData(data);
    setFilteredData(getFilteredData(data, searchedValue));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, setDocumentsKeysValues]);

  useEffect(() => {
    getFileList();
  }, [getFileList, setDocumentsKeysValues]);

  useEffect(() => {
    isUpdate && getFileList();
  }, [getFileList, isUpdate, setDocumentsKeysValues]);

  return { data: filteredData, onSearch };
};

export default useDocumentsData;
