import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { System } from '../../../../ts/enums/enums';
import { ClusterLinks } from '../../ClusterInfo/model/cluster.model';

import getLinks from './helpers/getLinks';
import LinkedDeviceItem from './LinkedDeviceItem/LinkedDeviceItem';

import styles from './LinkedDevicesInfo.module.scss';

interface LinkedDevicesProps {
  linkedDevices: N<ClusterLinks>;
  cameraId?: N<number>;
}

const { Cameras, Detectors } = System;

const LinkedDevicesInfo: FC<LinkedDevicesProps> = ({
  linkedDevices,
  cameraId,
}) => {
  const isEmpty =
    !linkedDevices || Object.values(linkedDevices).every((el) => !el.length);

  if (isEmpty) {
    return <p className={styles.noLinkedText}>Нет связанных устройств</p>;
  }

  const { links, videoDtIds } = getLinks(linkedDevices, cameraId);

  return (
    <div className={styles.box}>
      {videoDtIds.map(({ cm, dt }) => (
        <div className={styles.videoDt} key={`${cm} ${dt}`}>
          <span>Видеодетектор:</span>
          <LinkedDeviceItem id={cm} system={Cameras} />
          <LinkedDeviceItem id={dt} system={Detectors} />
        </div>
      ))}

      {links.map(([system, ids]) =>
        ids.length
          ? ids.map((id) => (
              <LinkedDeviceItem
                key={`${system} ${id}`}
                id={id}
                system={system}
              />
            ))
          : null
      )}
    </div>
  );
};

export default observer(LinkedDevicesInfo);
