import { Feature } from 'ol';
import { Point } from 'ol/geom';

import {
  TRAFFIC_LIGHTS_STATUS_SETS,
  TStatusCategory,
} from '../../../constants/constants';
import { DEVICES_STATUS_SETS } from '../../../constants/devicesConstants';
import { System } from '../../../ts/enums/enums';
import { getDeviceInfo } from '../../Map/helpers/getDeviceInfo';

export const getColorStatusOverlay = (features: Feature<Point>[]) => {
  const color = features.reduce((acc: U<string>, item) => {
    const { system, mode, deviceStatus } = getDeviceInfo(item);

    const isTL = system === System.Lights;

    const statusSets = !isTL ? DEVICES_STATUS_SETS : TRAFFIC_LIGHTS_STATUS_SETS;

    const alarmKeys = Object.keys(statusSets) as TStatusCategory[];

    const statusValue = !isTL ? deviceStatus : mode;

    const AlarmOverlayName = alarmKeys.reduce((acc: N<TStatusCategory>, el) => {
      if (!statusSets[el].isAlarm) return acc;

      const isActive = statusSets[el].mode.some(
        (alarmNumber) => alarmNumber === statusValue
      );

      if (isActive) {
        return el;
      }

      return acc;
    }, null);

    if (AlarmOverlayName) {
      return statusSets[AlarmOverlayName].color;
    }

    return acc;
  }, undefined);

  return color;
};
