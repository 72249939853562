import { Button } from 'antd';

import styles from './ImportExportBlock.module.scss';

interface IImportExportBlock {
  refImportBtn: any;
  onApply: () => void;
  onSave: () => void;
}

interface IButtons {
  id: number;
  title: string;
  onClick: () => void;
}

const ImportExportBlock = (props: IImportExportBlock) => {
  const { refImportBtn, onApply, onSave } = props;

  const BUTTONS: IButtons[] = [
    {
      id: 0,
      title: 'Выбрать файл',
      onClick: () => {
        refImportBtn.current.click();
      },
    },
    {
      id: 1,
      title: 'Сохранить',
      onClick: onSave,
    },
    {
      id: 2,
      title: 'Применить',
      onClick: onApply,
    },
  ];

  return (
    <>
      <div className={styles.blockBtn}>
        {BUTTONS.map((item) => (
          <Button key={item.id} className={styles.btn} onClick={item.onClick}>
            {item.title}
          </Button>
        ))}
      </div>
    </>
  );
};

export default ImportExportBlock;
