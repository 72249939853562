import { Dropdown } from 'antd';
import { observer } from 'mobx-react-lite';
import { IoIosMore } from 'react-icons/io';

import rootStore from '../../../../../stores/rootStore/rootStore';
import Popover from '../../../../ui-kit/Popover/Popover';

import useDetailedBtn from './hooks/useDetailedBtn';

import styles from './DetailedBtn.module.scss';

const DetailedBtn = () => {
  const { interfaceTipsDelay } = rootStore.uiStore;

  const { handleClick, items } = useDetailedBtn();

  if (!items?.length) return null;

  return (
    <Dropdown
      menu={{ items, onClick: handleClick }}
      trigger={['click']}
      overlayClassName={styles.dropdown}
    >
      <span className={styles.btn}>
        <Popover content={'Действия'} tipsDelay={interfaceTipsDelay}>
          <IoIosMore />
        </Popover>
      </span>
    </Dropdown>
  );
};

export default observer(DetailedBtn);
