import { LAST_ROW_TITLE } from '../../../../constants/detailedTabsConstant';

const getRatio = (total = 0, main = 0, middle = 0) => {
  const phaseSum = main + middle;

  return `${((phaseSum * 100) / total).toFixed(2)}%`;
};

export const getSigProgSource = (astSrc: any[] = [], cmdNum = '') => {
  const currentAst: any = astSrc.find((ast: any) => ast?.num === cmdNum) || {},
    res = currentAst?.len_ph?.reduce((ac: any, e: any, i: any, self: any) => {
      ac.push({
        key: i,
        phaseNum: i + 1,
        lenMain: e,
        lenMid: currentAst?.len_pt[i],
        ratio: getRatio(currentAst?.len, e, currentAst?.len_pt[i]),
      });

      if (i + 1 === self?.length) {
        ac.push({
          key: i + 1,
          phaseNum: LAST_ROW_TITLE,
          lenMain: currentAst?.len,
        });
      }

      return ac;
    }, []);

  return res;
};
