import { FC } from 'react';

import PhaseCircleCrossroad from '../PhaseCircleOverlay/PhaseCircleCrossroad/PhaseCircleCrossroad';

import { SchemaOddProps } from './PointUdsOverlay.model';

const SchemaODD: FC<SchemaOddProps> = ({ schemaODD }) => {
  if (!schemaODD?.id) return null;

  return (
    <PhaseCircleCrossroad
      id={schemaODD.id}
      phaseIdx={-1}
      tlCrossroad={schemaODD}
    />
  );
};

export default SchemaODD;
