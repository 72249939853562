import { TabsProps } from 'antd/lib';

import { System } from '../../../../ts/enums/enums';
import { ClusterTabKeys } from '../constants/constants';

export type ClusterLinks = Partial<Record<System, number[]>>;

export type Item = NonNullable<TabsProps['items']>[number];

export type ClusterTab = Omit<Item, 'key'> & {
  key: ClusterTabKeys;
};

export const isClusterKey = (key: string): key is ClusterTabKeys =>
  Object.values(ClusterTabKeys).some((el) => el === key);
