import { RefObject } from 'react';

import setDisplayStyle from '../../../helpers/setDisplayStyle';
import { POINTS_AMOUNT } from '../constants/constants';
import { getDevicesInExtent } from '../PointUdsOverlays/helpers/helpers';
import { DeviceInExtent } from '../PointUdsOverlays/PointUdsOverlays.model';

const POINTS_RENDER_DELAY = 300;

const handlePointsInExtent = <T extends DeviceInExtent>(
  allPoints: T[],
  refs: RefObject<HTMLDivElement>[]
) => {
  let points: T[] = [];
  let refsAmount = 0;

  const handleDisplay = (amount: number, newPoints: T[]) => {
    setDisplayStyle(refs.slice(refsAmount, amount), 'block');
    points = newPoints;
    refsAmount = amount;
  };

  return () => {
    const pointsInExtent = getDevicesInExtent(allPoints);

    const added = pointsInExtent.filter(
      ({ id }) => !points.find((point) => point.id === id)
    );
    const isNotChanged = points.length && !added.length;

    if (!pointsInExtent.length && isNotChanged) return {};

    const amount = points.length + added.length;
    const newPoints =
      amount > POINTS_AMOUNT ? pointsInExtent : [...points, ...added];

    !points.length
      ? handleDisplay(amount, newPoints)
      : setTimeout(() => handleDisplay(amount, newPoints), POINTS_RENDER_DELAY);

    return { pointsInExtent, newPoints };
  };
};

export default handlePointsInExtent;
