import { Circle, Fill, Icon, Stroke, Style, Text } from 'ol/style';

import {
  CartographicLineSymbol,
  DIC_CLS_CAP,
  DIC_LINE_CAP,
  PictureFillSymbols,
  PictureMarkerSymbol,
  RendererSymbol,
  SimpleFillSymbol,
  SimpleLineSymbol,
  SimpleMarkerSymbol,
  TextSymbol,
} from './renderer.model';

const DIC_LINE_DASH = {
  esriSLSSolid: null,
  esriSLSDash: [10],
  esriSLSDashDot: [10, 0, 10],
  esriSLSDashDotDot: [0, 10, 10, 0, 10],
  esriSLSDot: [0, 10],
  esriSLSLongDash: [12],
  esriSLSLongDashDot: [12, 0, 12],
  esriSLSNull: null,
  esriSLSShortDash: [3],
  esriSLSShortDashDot: [6, 0, 6],
  esriSLSShortDashDotDot: [0, 6, 6, 0, 6],
  esriSLSShortDot: [0, 6],
};

const FeatureSymbol = (symbol: RendererSymbol): Style | undefined => {
  if (!symbol) {
    return undefined;
  }

  const { type } = symbol;

  let symbolDefinition = undefined;

  switch (type) {
    case 'esriPFS': {
      symbolDefinition = esriPFS(symbol as PictureFillSymbols);
      break;
    }
    case 'esriPMS': {
      symbolDefinition = esriPMS(symbol as PictureMarkerSymbol);
      break;
    }
    case 'esriSFS': {
      symbolDefinition = esriSFS(symbol as SimpleFillSymbol);
      break;
    }
    case 'esriSLS': {
      symbolDefinition = esriSLS(symbol as SimpleLineSymbol);
      break;
    }
    case 'esriSMS': {
      symbolDefinition = esriSMS(symbol as SimpleMarkerSymbol);
      break;
    }
    case 'esriCLS': {
      symbolDefinition = esriCLS(symbol as CartographicLineSymbol);
      break;
    }
    case 'esriTS': {
      symbolDefinition = esriTS(symbol as TextSymbol);
      break;
    }
  }

  return new Style({ ...symbolDefinition });
};

/**
 * {
 *   "type": "esriPFS",
 *   "url": "866880A0",
 *   "imageData": "...",
 *   "contentType": "image/png",
 *   "outline": {
 *     "type": "esriSLS",
 *     "style": "esriSLSSolid",
 *     "color": [
 *       110,
 *       110,
 *       110,
 *       255
 *     ],
 *     "width": 1
 *   },
 *   "width": 63,
 *   "height": 63,
 *   "angle": 0,
 *   "xoffset": 0,
 *   "yoffset": 0,
 *   "xscale": 1,
 *   "yscale": 1
 * }
 * @param symbol
 */
function esriPFS(symbol: PictureFillSymbols) {
  return undefined;
}

/**
 * {
 *   "type": "esriPMS",
 *   "imageData": <Base64>
 *   "color": [
 *     115,
 *     76,
 *     0,
 *     255
 *   ],
 *   "url": "471E7E31",
 *   "width": 19.5,
 *   "height": 19.5,
 *   "angle": 0,
 *   "xoffset": 0,
 *   "yoffset": 0
 * }
 * @param symbol
 */
function esriPMS(symbol: PictureMarkerSymbol) {
  const { angle, height, width, url, imageData, xoffset, yoffset } = symbol;

  let sourceImg = undefined;

  if (url) {
    const isURL = validURL(url);

    sourceImg = isURL ? url : undefined;
  }

  if (!sourceImg && imageData) {
    sourceImg = `data:image/png;base64,${imageData}`;
  }

  const icon = new Icon({
    src: sourceImg,
    rotation: angleToRadians(angle),
    offset: [xoffset ?? 0, yoffset ?? 0],
    height: height ? height * 1.333 : undefined,
    width: width ? width * 1.333 : undefined,
  });

  return { image: icon };
}

/**
 * {
 *   "type": "esriSFS",
 *   "style": "esriSFSSolid", //esriSFSBackwardDiagonal, esriSFSCross, esriSFSDiagonalCross, esriSFSForwardDiagonal, esriSFSHorizontal, esriSFSNull, esriSFSSolid, esriSFSVertical
 *   "color": [
 *     115,
 *     76,
 *     0,
 *     255
 *   ],
 *   "outline": {
 *     "type": "esriSLS",
 *     "style": "esriSLSSolid",
 *     "color": [
 *       110,
 *       110,
 *       110,
 *       255
 *     ],
 *     "width": 1
 *   }
 * }
 * @param symbol
 */
function esriSFS(symbol: SimpleFillSymbol) {
  const { color, outline } = symbol;

  const fill = new Fill({
    color: simplifyColor(color),
  });

  const stroke = outline ? esriSLS(outline, undefined).stroke : undefined;

  return {
    stroke,
    fill,
  };
}

/**
 * {
 *   "type": "esriSLS",
 *   "style": "esriSLSDot", //esriSLSDash, esriSLSDashDot, esriSLSDashDotDot, esriSLSDot, esriSLSLongDash, esriSLSLongDashDot, esriSLSNull, esriSLSShortDash, esriSLSShortDashDot, esriSLSShortDashDotDot, esriSLSShortDot, esriSLSSolid
 *   "color": [
 *     115,
 *     76,
 *     0,
 *     255
 *   ],
 *   "width": 1
 * }
 * @param symbol
 * @param styleLineCap
 */
function esriSLS(
  symbol: SimpleLineSymbol,
  styleLineCap?: keyof typeof DIC_LINE_CAP
) {
  const { style, color, width } = symbol;

  const lineDash = DIC_LINE_DASH[style] || undefined;
  const lineCap = styleLineCap
    ? DIC_LINE_CAP[styleLineCap]
    : DIC_LINE_CAP.esriSMSCircle;

  const stroke = new Stroke({
    color: simplifyColor(color),
    width,
    lineCap,
    lineJoin: 'round',
    lineDash,
    lineDashOffset: 0,
    miterLimit: 10,
  });

  return { stroke };
}

/**
 * {
 *   "type": "esriSMS",
 *   "style": "esriSMSSquare", //esriSMSCircle, esriSMSCross, esriSMSDiamond, esriSMSSquare, esriSMSTriangle, esriSMSX
 *   "color": [
 *     76,
 *     115,
 *     0,
 *     255
 *   ],
 *   "size": 8,
 *   "angle": 0,
 *   "xoffset": 0,
 *   "yoffset": 0,
 *   "outline": {
 *     "color": [
 *       152,
 *       230,
 *       0,
 *       255
 *     ],
 *     "width": 1
 *   }
 * }
 * @param symbol
 */
function esriSMS(symbol: SimpleMarkerSymbol) {
  const { style, color, size, angle, xoffset, yoffset, outline } = symbol;

  let fill = undefined;
  let stroke = undefined;

  if (color) {
    fill = new Fill({
      color: simplifyColor(color),
    });
  }

  if (outline) {
    stroke = esriSLS(outline, style).stroke;
  }

  const rotation = angleToRadians(angle);

  const circle = new Circle({
    fill,
    stroke,
    radius: size,
    displacement: [xoffset, yoffset],
    rotation,
    rotateWithView: false,
  });

  return { image: circle };
}

/**
 * {
 *     "color": [
 *         0,
 *         0,
 *         0,
 *         255
 *     ],
 *     "width": 1,
 *     "type": "esriCLS",
 *     "style": "esriSLSSolid",
 *     "cap": "esriLCSButt",
 *     "join": "esriLJSMiter",
 *     "miterLimit": 10
 * }
 * @param symbol
 */
function esriCLS(symbol: CartographicLineSymbol) {
  const { style, color, width, cap } = symbol;

  const lineDash = DIC_LINE_DASH[style] || undefined;
  const lineCap = cap ? DIC_CLS_CAP[cap] : DIC_CLS_CAP.esriLCSRound;

  const stroke = new Stroke({
    color: simplifyColor(color),
    width,
    lineCap,
    lineJoin: 'round',
    lineDash,
    lineDashOffset: 0,
    miterLimit: 10,
  });

  return { stroke };
}

/**
 * {
 *   "type": "esriTS",
 *   "color": [
 *     78,
 *     78,
 *     78,
 *     255
 *   ],
 *   "backgroundColor": [
 *     0,
 *     0,
 *     0,
 *     0
 *   ],
 *   "borderLineSize": 2,
 *   "borderLineColor": [
 *     255,
 *     0,
 *     255,
 *     255
 *   ],
 *   "haloSize": 2,
 *   "haloColor": [
 *     0,
 *     255,
 *     0,
 *     255
 *   ],
 *   "verticalAlignment": "bottom", // baseline, bottom, middle, top
 *   "horizontalAlignment": "left", // center, justify, left, right
 *   "rightToLeft": false,
 *   "angle": 0,
 *   "xoffset": 0,
 *   "yoffset": 0,
 *   "kerning": true,
 *   "font": {
 *     "family": "Arial",
 *     "size": 12,
 *     "style": "normal",
 *     "weight": "bold",
 *     "decoration": "none"
 *   }
 * }
 * @param symbol
 */
function esriTS(symbol: TextSymbol) {
  const {
    text,
    color,
    backgroundColor,
    borderLineSize,
    borderLineColor,
    haloSize,
    haloColor,
    verticalAlignment,
    horizontalAlignment,
    angle,
    xoffset,
    yoffset,
    font,
  } = symbol;

  let fill;
  let stroke;
  let backgroundFill;
  let backgroundStroke;

  const textFont = font
    ? `${font.style} ${font.weight} ${font.size}pt ${font.family}`
    : '20px Calibri,sans-serif';

  if (color) {
    fill = new Fill({
      color: simplifyColor(color),
    });
  }

  if (haloColor) {
    stroke = new Stroke({
      color: simplifyColor(`rgba(${haloColor.join(',')})`),
      width: haloSize ?? null,
    });
  }

  if (backgroundColor) {
    backgroundFill = new Fill({
      color: simplifyColor(`rgba(${backgroundColor.join(',')})`),
    });
  }

  if (borderLineColor) {
    backgroundStroke = new Stroke({
      color: simplifyColor(`rgba(${borderLineColor.join(',')})`),
      width: borderLineSize ?? null,
    });
  }

  const symbolText = new Text({
    text: text,
    font: textFont,
    fill,
    stroke,
    offsetX: xoffset,
    offsetY: yoffset,
    textAlign: horizontalAlignment,
    textBaseline: verticalAlignment,
    rotation: angleToRadians(angle),
    backgroundFill,
    backgroundStroke,
  });

  return { text: symbolText };
}

function validURL(str: string) {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$',
    'i'
  );

  return !!pattern.test(str);
}

function angleToRadians(angle: string | number): number {
  if (!angle) {
    return 0;
  }

  return (Number(angle) || 0) * (Math.PI / 180);
}

function simplifyColor(color: number[] | string) {
  if (Array.isArray(color)) {
    const [r, g, b, a] = color;

    const alpha = a < 1 || a === 0 ? a : a / 255;

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  return color;
}

export { FeatureSymbol };
