import { MINUTE } from '../../../../constants/constants';
import {
  DEFAULT_FILTERED,
  DEFAULT_PREDICT_FILTERED,
  FilteredTypes,
  GraphTypes,
} from '../../../TrafficLightDetailed/TlDetectors/constants/constants';
import { FilterDataValues, UpdateInfo } from '../models/chart.model';

export const INCREASE_GRAPH_HEIGHT = 1.2;
export const ANIMATION_GRAPH_DURATION = 1000;

type TDomainX = [string | number, string | number];

export interface IDomain {
  x: TDomainX;
  y: [number, number];
}

export interface IAreaX {
  left: number;
  right: number;
}

export const DEFAULT_HEIGHT = 100;

export const INITIAL_X_DOMAIN: TDomainX = ['dataMin', 'dataMax'];

export const INITIAL_DOMAIN: IDomain = {
  x: ['dataMin', 'dataMax'],
  y: [0, DEFAULT_HEIGHT],
};

export const INITIAL_AREA_X: IAreaX = {
  left: 0,
  right: 0,
};

export const COLORS_CHART = [
  '#007d06',
  '#fa9e14',
  '#FF0000',
  '#9400D3',
  '#FF00FF',
  '#00FF00',
  '#CD5C5C',
  '#808000',
  '#FFD700',
  '#00FA9A',
  '#BDB76B',
  '#008080',
];

export const AVG_KEY_VALUE = 'среднее знач.';

export const WIDTH_DEFAULT_GRAPH = 920;
export const HEIGHT_DEFAULT_GRAPH = 520;
export const OPENED_PANEL = 500;

export const AREA_COLOR = 'var(--antd-blue)';

export const UPDATE_CHART_INTERVAL = 5 * MINUTE;

export const NO_DATA_INTERVAL_TEXT = 'Нет данных для выбранного интервала';

export const OPACITY_HOVER = 0.3;
export const OPACITY_DEFAULT = 1;

export const X_KEY = 'index';
export const TICK_COUNT = 10;
export const X_TICK_COUNT = 9;

export const MARGIN_GRAPH = {
  top: 100,
  right: 30,
  left: 60,
  bottom: 100,
};

export const LINES_WIDTH = 3;

export const INITIAL_UPDATE_INFO: UpdateInfo = {
  LineChart: null,
  CandlesChart: null,
};

export const INITIAL_FILTERED: Record<GraphTypes, FilterDataValues> = {
  LineChart: DEFAULT_FILTERED,
  CandlesChart: DEFAULT_PREDICT_FILTERED,
};

type DisabledChartButtonsProps = Record<FilteredTypes, boolean>;

export const DISABLED_CHART_BUTTONS: Record<
  GraphTypes,
  DisabledChartButtonsProps
> = {
  LineChart: {
    directions: false,
    lanes: false,
    vehicleTypes: false,
    normative: false,
  },
  CandlesChart: {
    directions: false,
    lanes: true,
    vehicleTypes: true,
    normative: false,
  },
};
