import { Descriptions } from 'antd';
import classNames from 'classnames';
import { FC } from 'react';

import { System } from '../../../../../../../../../../../../ts/enums/enums';
import getDescriptionInfo from '../../../../../../../../../../../DetailedStatistics/Monitoring/SystemMonitoring/DevicesBlock/DevicesList/DeviceItem/Description/helpers/getDescriptionItems';
import Warnings from '../../../../../../../../../../../DetailedStatistics/Monitoring/SystemMonitoring/DevicesBlock/DevicesList/DeviceItem/Description/Warnings/Warnings';

import useAnimate from './hooks/useAnimate';

import styles from './BackgroundContent.module.scss';

interface BackgroundContentProps {
  isShow: boolean;
  id: number;
  address: N<string>;
  addressLoading: boolean;
}

const BackgroundContent: FC<BackgroundContentProps> = ({
  address,
  addressLoading,
  id,
  isShow,
}) => {
  const isAnimate = useAnimate(isShow);

  const items = getDescriptionInfo({
    id,
    system: System.Lights,
    address,
    addressLoading,
    isAdditional: true,
  }).map((el) => ({ ...el, span: 3 }));

  if (!isShow) return null;

  const containerStyle = classNames(styles.container, {
    [styles.animate]: isAnimate,
  });

  return (
    <div className={containerStyle}>
      <Descriptions
        items={items}
        layout="vertical"
        className={styles.desc}
        size="small"
      />
      <Warnings id={id} />
    </div>
  );
};

export default BackgroundContent;
