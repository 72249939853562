import { FC } from 'react';

import { ReactComponent as ArrowBtnIco } from '../../../assets/icons/ic_summary_arrow_btn.svg';

import styles from './SummaryInfoItem.module.scss';

interface SummaryInfo {
  data: any;
}

const SummaryInfoItem: FC<SummaryInfo> = ({ data }) => {
  const { title, subtitle, ico, amount, subAmount, isPercents, victimsAmount } =
    data;

  return (
    <div className={styles.container}>
      <div className={styles.firstRow}>
        {ico}

        <div className={styles.amountContainer}>
          <div className={styles.amount}>
            {amount}

            {isPercents ? <span>%</span> : null}
          </div>

          <div className={styles.subAmountInfo}>
            {subAmount}

            {victimsAmount ? (
              <span className={styles.victimsAmount}>/{victimsAmount}</span>
            ) : null}
          </div>
        </div>
      </div>

      <div className={styles.secondRow}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>{title}</div>

          <div className={styles.subtitle}>{subtitle}</div>
        </div>

        <div className={styles.btnContainer}>
          <button
            className={styles.arrowBtn}
            onClick={() => console.log('summary')}
          >
            <ArrowBtnIco />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SummaryInfoItem;
