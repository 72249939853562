import { FC } from 'react';

import styles from './DotsCustom.module.scss';

interface DotsCustomProps {
  onClick?: () => void;
}

const DotsCustom: FC<DotsCustomProps> = ({ onClick }) => {
  return (
    <button className={styles.dots} onClick={onClick}>
      1
    </button>
  );
};

export default DotsCustom;
