import { Collapse, Input } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { INPUT_MAX_LEN } from '../../../../constants/constants';
import rootStore from '../../../../stores/rootStore/rootStore';
import DefaultChecked from '../../../ui-kit/DefaultChecked/DefaultChecked';

import FileList from './FileList/FileList';
import useDocumentsData from './hooks/useDocumentsData';
import PanelHeader from './PanelHeader/PanelHeader';

import styles from './DocumentsList.module.scss';

const { Panel } = Collapse;

interface IDocumentList {
  id: number;
}

const DocumentsList: FC<IDocumentList> = ({ id }) => {
  const { isDocListLoading } = rootStore.documentsStore;

  const { data, onSearch } = useDocumentsData(id);

  return (
    <div className={styles.wrapper}>
      <Input
        onChange={onSearch}
        className={styles.search}
        placeholder="Поиск файла"
        allowClear
        maxLength={INPUT_MAX_LEN}
        size="large"
      />
      <div className={styles.collapseWrapper}>
        <DefaultChecked
          isLoading={isDocListLoading}
          isEmptyData={!data.length}
          isBackdropFilter
        >
          <div className={styles.collapse}>
            <Collapse defaultActiveKey={['1']}>
              {data.map((item) => (
                <Panel
                  header={<PanelHeader data={item} id={id} />}
                  key={item.lb_file_category_id}
                >
                  <FileList fileList={item.children} />
                </Panel>
              ))}
            </Collapse>
          </div>
        </DefaultChecked>
      </div>
    </div>
  );
};

export default observer(DocumentsList);
