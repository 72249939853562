import { Descriptions } from 'antd';
import { observer } from 'mobx-react-lite';

import useAdapterStatistic from '../../../../../Admin/AdminSystemObject/ASOMonitoring/hooks/useAdapterStatistic';
import DefaultChecked from '../../../../../ui-kit/DefaultChecked/DefaultChecked';
import getMonitoringDevice from '../helpers/getMonitoringDevice';

import getAdapterItems from './helpers/getAdapterItems';

import styles from '../styles/BasicContent.module.scss';

const APP_PATH =
  '.DetailedStatistic.Monitoring.DeviceCard.MainContent.AdapterInfo';

const AdapterInfo = () => {
  const systemId = getMonitoringDevice()?.eputsAdapter.scSystemId;

  const { statistic, isError, isLoading } = useAdapterStatistic({
    appPath: APP_PATH,
    systemIds: systemId,
    isEnabled: !!systemId,
  });

  const items = getAdapterItems(statistic, systemId);

  return (
    <div className={styles.container}>
      <DefaultChecked
        isLoading={isLoading}
        isEmptyData={!items.length || !systemId || isError}
        noDataProps={{ isNeedBackground: false }}
      >
        <Descriptions layout="vertical" items={items} />
      </DefaultChecked>
    </div>
  );
};

export default observer(AdapterInfo);
