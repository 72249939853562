import { Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

import rootStore from '../../../../stores/rootStore/rootStore';

import SelectItem from './SelectItem/SelectItem';

import styles from './SelectTls.module.scss';

const SelectTls = () => {
  const { selectedIds, coordTls, setSelectedFeatureSomeArray } =
    rootStore.scriptsControlStore;

  const options = useMemo(
    () =>
      coordTls
        .map(({ tl: { id, deviceInfo }, isDisabled }) => ({
          value: id,
          label: (
            <SelectItem
              key={id}
              caption={deviceInfo.caption}
              isDisabled={isDisabled}
              id={id}
            />
          ),
          disabled: isDisabled,
        }))
        .sort((a, b) => Number(a.disabled) - Number(b.disabled)),
    [coordTls]
  );

  const setValue = (value: number) => {
    setSelectedFeatureSomeArray(value);
  };

  return (
    <div className={styles.container}>
      <Select
        mode="multiple"
        placeholder="Выбрать светофор"
        className={styles.loadSelect}
        onSelect={setValue}
        onDeselect={setValue}
        options={options}
        value={selectedIds}
        maxTagCount="responsive"
        showSearch
      />
    </div>
  );
};

export default observer(SelectTls);
