import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import rootStore from '../../stores/rootStore/rootStore';
import Detailed from '../ui-kit/Detailed/Detailed';
import NoData from '../ui-kit/Errors/NoData/NoData';

import { RIGHT_PANEL_CONTENT } from './constants/constants';

const RightPanel = () => {
  const {
    isRightPanel,
    rightPanelType,
    isDetailedForm,
    setRightPanel,
    setKeyValue,
  } = rootStore.uiStore;

  const header = rightPanelType
    ? RIGHT_PANEL_CONTENT[rightPanelType].title
    : 'Справочная информация';

  const detailedProps = {
    header,
    onClose: () => setRightPanel(null),
    isRightPanelStyle: true,
  };

  useEffect(() => {
    !isRightPanel && !isDetailedForm && setKeyValue('infoData', null);
  }, [isRightPanel, setKeyValue, isDetailedForm]);

  return (
    <Detailed {...detailedProps}>
      <>
        {rightPanelType ? (
          RIGHT_PANEL_CONTENT[rightPanelType].content
        ) : (
          <NoData textError="Страница в разработке" />
        )}
      </>
    </Detailed>
  );
};

export default observer(RightPanel);
