import { Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';

import { ADMIN_MODAL_WIDTH } from '../../../constants/adminConstants';
import rootStore from '../../../stores/rootStore/rootStore';
import { IAdminModal, IAdminModalType } from '../models/adminTypes.model';

import styles from './AdminModal.module.scss';

const AdminModal: FC<IAdminModal> = ({ children, title }) => {
  const { adminModalType, isModalOpen, setAdminsData } =
    rootStore.adminPanelStore;

  const handleCancel = () => {
    setAdminsData('isModalOpen', false);
    setAdminsData('adminModalType', 'default');
  };

  return (
    <section>
      <Modal
        title={title}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[]}
        maskClosable={false}
        className={styles.myModal}
        width={ADMIN_MODAL_WIDTH[adminModalType as keyof IAdminModalType]}
        centered
      >
        {children}
      </Modal>
    </section>
  );
};

export default observer(AdminModal);
