import { differenceWith } from 'lodash';
import { Feature } from 'ol';
import Point from 'ol/geom/Point';
import VectorSource from 'ol/source/Vector';
import { FeatureLike } from 'ol/Feature'; // eslint-disable-line

export const addFeaturesCluster = (
  source: VectorSource<FeatureLike>,
  features: Feature<Point>[]
) => {
  const prevFeatures = source
    .getFeatures()
    .filter((feature) => feature instanceof Feature) as Feature[];

  if (!prevFeatures.length) return source.addFeatures(features);

  const isEqualFeatures = (feature: Feature, prevFeature: Feature) =>
    feature.get('id') === prevFeature.get('id');

  const addFeaturesArray = differenceWith(
    features,
    prevFeatures,
    isEqualFeatures
  );

  const deleteFeaturesArray = differenceWith(
    prevFeatures,
    features,
    isEqualFeatures
  );

  if (addFeaturesArray.length) {
    source.addFeatures(addFeaturesArray);
  }

  if (deleteFeaturesArray.length) {
    deleteFeaturesArray.forEach((feature) => {
      source.removeFeature(feature);
    });
  }
};
