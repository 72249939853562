import { type ThemeConfig, theme } from 'antd';

import {
  darkComponentsToken,
  darkToken,
  lightComponentsToken,
  lightToken,
} from '../../constants/themeTokens';

const { defaultAlgorithm, darkAlgorithm } = theme;

export const getAntdTheme = (isDark: boolean): ThemeConfig => {
  return {
    algorithm: isDark ? darkAlgorithm : defaultAlgorithm,
    token: isDark ? darkToken : lightToken,
    components: isDark ? darkComponentsToken : lightComponentsToken,
    cssVar: true,
  };
};
