import { DatePicker as AntdDatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';
import type { DatePickerProps as DatePickerType } from 'antd/lib';
import { Dayjs } from 'dayjs';
import { isString } from 'lodash';
import { FC } from 'react';

import { DATE_FORMAT } from '../../../../constants/constants';
import ExtraFooter from '../RangePicker/ExtraFooter/ExtraFooter';
import {
  DisabledTimePickerProps,
  IOptions,
  onDisabledDate,
  onDisabledTime,
} from '../RangePicker/helpers/disabledHelpers';
import { getTimeFooterArray } from '../RangePicker/helpers/getTimeFooterArray';

export interface DatePickerProps {
  isTime?: boolean;
  picker?: DatePickerType['picker'];
  dateFormat?: DatePickerType['format'];
  status?: DatePickerType['status'];
  isTimeFooter?: boolean;
  pickerClassName?: string;
  allowClear?: boolean;
  value?: DatePickerType['value'];
  showNow?: boolean;
  disabled?: boolean;
  disabledDateOptions?: IOptions;
  disabledTimeOptions?: DisabledTimePickerProps;
  onChangeDate: DatePickerType['onChange'];
  handleDisableTime?: DatePickerType['disabledTime'];
  handleDisableDate?: DatePickerType['disabledDate'];
}

const DatePicker: FC<DatePickerProps> = ({
  isTime = true,
  picker,
  dateFormat = DATE_FORMAT,
  isTimeFooter = true,
  pickerClassName = '',
  value,
  allowClear = true,
  status,
  showNow = true,
  disabledDateOptions,
  disabledTimeOptions,
  disabled = false,
  onChangeDate,
  handleDisableDate = (value: Dayjs) =>
    onDisabledDate(value, disabledDateOptions),
  handleDisableTime = () => onDisabledTime(disabledTimeOptions),
}) => {
  const isNeedTime = isTime && isString(dateFormat);

  const timeFooter = isString(dateFormat) ? getTimeFooterArray(dateFormat) : [];

  const datePickerProps: DatePickerType = {
    format: dateFormat,
    onChange: onChangeDate,
    showTime: isNeedTime,
    value,
    status,
    picker,
    locale,
    showNow,
    allowClear,
    placeholder: 'Выберите дату',
    disabled,
    disabledDate: handleDisableDate,
    disabledTime: handleDisableTime,
    renderExtraFooter: () => (
      <ExtraFooter
        timeFooter={timeFooter}
        isTime={isNeedTime}
        isTimeFooter={isTimeFooter}
      />
    ),
  };

  return <AntdDatePicker {...datePickerProps} className={pickerClassName} />;
};

export default DatePicker;
