import { ChartBDKeys } from '../../../../../components/InfoPanel/SystemsInfo/PopupDetector/Chart/constants/constants';
import { getObjectEntries } from '../../../../../helpers/getObjectEntries';
import isFloat from '../../../../../helpers/isFloat';
import { NormilizeData } from '../model/detectors.model';

type EntriesFormatted = [
  keyof NormilizeData['data'][number],
  ValueOf<NormilizeData['data'][number]>
][];

const { Density } = ChartBDKeys;

const formattedFloatData = (
  graphTitle: ChartBDKeys,
  data: NormilizeData['data']
) => {
  if (graphTitle === Density) return data;

  const formattedData = data.map((el) => {
    const entries = getObjectEntries(el) as EntriesFormatted;

    const values = entries.reduce((acc, [key, value]) => {
      acc[key] = !isFloat(value) ? value : Math.ceil(Number(value));

      return acc;
    }, {} as NormilizeData['data'][number]);

    return values;
  });

  return formattedData;
};

export default formattedFloatData;
