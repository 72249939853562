import { Feature } from 'ol';
import { Geometry } from 'ol/geom';
import BaseLayer from 'ol/layer/Base';
import VectorLayer from 'ol/layer/Vector';
import VectorImageLayer from 'ol/layer/VectorImage';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style, Text } from 'ol/style';
import type { TextPlacement } from 'ol/style/Text';

import { ILabelingSettings } from '../../../components/MapBtns/LayersButton/layers.model';
import { ILayer } from '../../../stores/gisDataStore/gisDataStore.model';
import { TMap } from '../../../stores/mapStore/mapStore.model';
import { GEOMETRY_TYPE_NAMES } from '../../constants/geometry/types';
import { Z_INDEX_TEXT_LAYER } from '../../constants/map';
import { DEFAULT_TEXT_STYLE_PROPS } from '../../constants/styles';

const SHOW_LABELS_PROPERTY = 'showLabels';

export const LABELING_LAYER_SETTINGS_PROPERTY = 'labelingSettings';

const getLayer = (
  map: TMap,
  id: U<string>
): VectorImageLayer<VectorSource> | VectorLayer<VectorSource> | undefined => {
  if (!map) {
    return undefined;
  }

  const layer = map
    .getLayers()
    .getArray()
    .find((item: BaseLayer) => item.get('id') === id);

  if (!layer) {
    return undefined;
  }

  if (layer instanceof VectorLayer || layer instanceof VectorImageLayer) {
    return layer;
  }

  return undefined;
};

const getLayerTextStyle = (
  definition?: ILayer,
  labelSettings?: ILabelingSettings
) => {
  const labelProperty = labelSettings?.labelProperty;
  const fillColor =
    labelSettings?.fillColor || DEFAULT_TEXT_STYLE_PROPS.FILL.COLOR;
  const strokeWidth =
    labelSettings?.strokeWidth || DEFAULT_TEXT_STYLE_PROPS.STROKE.WIDTH;
  const strokeColor =
    labelSettings?.strokeColor || DEFAULT_TEXT_STYLE_PROPS.STROKE.COLOR;
  const fontSize = labelSettings?.fontSize;
  const fontFamily = labelSettings?.fontFamily;

  const font =
    fontSize && fontFamily
      ? `bold ${fontSize} ${fontFamily}`
      : DEFAULT_TEXT_STYLE_PROPS.FONT;

  return (feature: Feature, resolution: number) => {
    if (!definition) {
      return;
    }

    const props = feature.getProperties();

    const tooltips = labelProperty
      ? [labelProperty]
      : definition?.tooltipDefinition?.fields || [];

    const values = [];

    for (let i = 0; i < tooltips.length; i++) {
      values.push(props[tooltips[i]]);
    }

    const text = values.filter((val) => !!val).join('\n\r');

    const geometry = feature.getGeometry();

    const geometryType = geometry?.getType();

    let placement: TextPlacement = 'point';

    if (
      geometryType === GEOMETRY_TYPE_NAMES.LineString ||
      geometryType === GEOMETRY_TYPE_NAMES.MultiLineString
    ) {
      placement = 'line';
    }

    const FeatureTextStyle = new Text({
      font: font || DEFAULT_TEXT_STYLE_PROPS.FONT,
      text,
      fill: new Fill({
        color: fillColor,
      }),
      stroke: new Stroke({
        width: strokeWidth,
        color: strokeColor,
      }),
      placement,
    });

    return new Style({
      text: FeatureTextStyle,
      geometry: feature.getGeometry(),
    });
  };
};

export const setTextStyle = (
  map: TMap,
  definition: ILayer | undefined,
  value?: boolean,
  labelSettings?: ILabelingSettings
) => {
  const baseLayerId = definition?.id || 'map_layer_' + Date.now();
  const labelsLayerId = baseLayerId + '_labels';

  const mapLayer = getLayer(map, baseLayerId);

  if (!mapLayer || !map) {
    return;
  }

  const source = mapLayer.getSource();

  let labelingLayer = map
    .getAllLayers()
    .find((element) => element.get('id') === labelsLayerId);

  if (!labelingLayer) {
    labelingLayer = new VectorLayer({
      source: new VectorSource({
        features: [],
      }),
    });

    if (source && source instanceof VectorSource<Feature<Geometry>>) {
      // @ts-ignore
      labelingLayer.setSource(source);
    }

    labelingLayer.set('id', labelsLayerId);

    map.addLayer(labelingLayer);
  }

  const textStyle = getLayerTextStyle(definition, labelSettings);

  labelSettings &&
    labelingLayer?.set(LABELING_LAYER_SETTINGS_PROPERTY, labelSettings);

  if (value) {
    // @ts-ignore
    textStyle && labelingLayer?.setStyle(textStyle);
    labelingLayer?.set(SHOW_LABELS_PROPERTY, true);
  } else {
    // @ts-ignore
    labelingLayer && labelingLayer.setStyle(null);
    labelingLayer?.set(SHOW_LABELS_PROPERTY, false);
  }

  labelingLayer?.setZIndex(Z_INDEX_TEXT_LAYER);
};

export const hasLabels = (map?: TMap, id?: U<string>) => {
  const baseLayerId = id || 'map_layer_' + Date.now();
  const labelsLayerId = baseLayerId + '_labels';

  if (!map) {
    return false;
  }

  const labelingLayer = map
    .getAllLayers()
    .find((element) => element.get('id') === labelsLayerId);

  if (!labelingLayer) {
    return false;
  }

  return labelingLayer.get(SHOW_LABELS_PROPERTY);
};
