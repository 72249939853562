import { isUndefined } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../../../stores/rootStore/rootStore';
import {
  EMPTY,
  TValueMeteo,
} from '../../../../../Mapper/TooltipContent/MapIconContent/MeteoInfo/helpers/getMeteoInfo';
import Popover from '../../../../../ui-kit/Popover/Popover';

import Compass from './Compass/Compass';

import styles from './MeteoMainInfo.module.scss';

interface IMeteoMainInfoProps {
  temp: TValueMeteo;
  weather: TValueMeteo;
  compassInfo: OWKS<U<SN>>;
}

const MeteoMainInfo: FC<IMeteoMainInfoProps> = ({
  temp,
  weather,
  compassInfo,
}) => {
  const { interfaceTipsDelay } = rootStore.uiStore;

  const dir = Number(compassInfo.windDir) || null;
  const speed = compassInfo.windSpeed !== EMPTY ? compassInfo.windSpeed : null;
  const weatherIcon = weather?.value ? (
    weather.icon
  ) : (
    <p>Нет данных об осадках</p>
  );

  return (
    <div className={styles.container}>
      <Compass speed={speed ?? null} dir={dir} />
      <div className={styles.tempContainer}>
        {!isUndefined(temp) ? (
          <Popover content={temp.tooltip} tipsDelay={interfaceTipsDelay}>
            <div className={styles.title}>
              {temp.icon}
              <p>{temp.value}</p>
            </div>
          </Popover>
        ) : null}
      </div>
      <div className={styles.weather}>
        {!isUndefined(weather) ? (
          <Popover content={weather.tooltip} tipsDelay={interfaceTipsDelay}>
            <div className={styles.weatherContainer}>
              <div className={styles.icon}>{weatherIcon}</div>
              <p>{weather.value}</p>
            </div>
          </Popover>
        ) : null}
      </div>
    </div>
  );
};

export default observer(MeteoMainInfo);
