import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import DocumentsList from './DocumentsList/DocumentsList';
import DocViewWindow from './DocViewWindow/DocViewWindow';
import useCleanViewFile from './hooks/useCleanViewFile';

import styles from './Documents.module.scss';

interface IDocument {
  id: number;
}

const Documents: FC<IDocument> = ({ id }) => {
  useCleanViewFile(id);

  return (
    <div className={styles.wrapper}>
      <DocumentsList id={id} />
      <DocViewWindow />
    </div>
  );
};

export default observer(Documents);
