/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';

import { ReactComponent as NoDataIcon } from '../../../../assets/icons/noData.svg';
import { RadioValue } from '../../../TrafficLightDetailed/TlDetectors/model/graph.model';

import ChartBar from './Chart/ChartBar';
import { ChartBDKeys } from './Chart/constants/constants';
import DefaultComponent from './DefaultComponent/DefaultComponent';
import TitleInfo from './DefaultComponent/TitleInfo/TitleInfo';
import useGraphData from './hooks/useGraphData';
import { PopupDetectorProps } from './PopupDetector.model';
import RadioButtons from './RadioButtons/RadioButtons';

import styles from './PopupDetector.module.scss';

const PopupDetector: FC<PopupDetectorProps> = ({ id }) => {
  const [radioValue, setRadioValue] = useState<RadioValue>(
    ChartBDKeys.PassageCount
  );
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [isLabel, setIsLabel] = useState(false);

  const {
    dataGraph,
    dateInterval,
    isError,
    isLoading,
    isNullableData,
    timeUpdate,
  } = useGraphData({ id, radioValue });

  if (isLoading) {
    return (
      <div className={styles.container}>
        <DefaultComponent
          propsTitleInfo={{ dateInterval, timeUpdate, radioValue }}
        />
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      </div>
    );
  }

  if (isError || !dataGraph) {
    return (
      <div className={styles.container}>
        <DefaultComponent
          propsTitleInfo={{ dateInterval, timeUpdate, radioValue }}
        />
        <div className={styles.error}>
          <NoDataIcon />
          <p>Нет данных</p>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <TitleInfo
        dateInterval={dateInterval}
        timeUpdate={timeUpdate}
        radioValue={radioValue}
      />
      {!isNullableData && (
        <ChartBar
          dataGr={dataGraph}
          activeIndex={activeIndex}
          radioValue={radioValue}
          xKey="date"
          setActiveIndex={setActiveIndex}
          isLabel={isLabel}
        />
      )}
      <RadioButtons
        radioValue={radioValue}
        setRadioValue={setRadioValue}
        labelProps={{ isLabel, setIsLabel }}
      />
    </div>
  );
};

export default observer(PopupDetector);
