import dayjs from 'dayjs';

import 'dayjs/locale/ru';
import { setRange } from '../../../TlManagement/PhaseHold/helpers/setRange';
import { getTimeWithTimeZone } from '../TitleBtns/TimeLocalCard/helpers/getTimeWithTimeZone';
import { getTimeZones } from '../TitleBtns/TimeLocalCard/helpers/getTimeZone';

export const disabledDateTimeRangePicker = (value: N<dayjs.Dayjs>) => {
  const timeZone = getTimeZones()?.timeZoneRegion;

  if (!value || !timeZone) {
    return {
      disabledHours: () => setRange(0, 24),
      disabledMinutes: () => setRange(0, 60),
      disabledSeconds: () => setRange(0, 60),
    };
  }

  const todayUtc = getTimeWithTimeZone({ timeZone }).dateFormattedJS;

  const isToday = todayUtc.format('DD.MM.YYYY') === value.format('DD.MM.YYYY');
  const hourNow = todayUtc.hour();
  const minutesNow = todayUtc.minute();

  const disabledHour = isToday
    ? setRange(0, 24).splice(hourNow + 1, 24 - hourNow)
    : [];

  const disabledMinutes = isToday
    ? setRange(0, 60).splice(minutesNow + 1, 60 - hourNow)
    : [];

  return {
    disabledHours: () => disabledHour,
    disabledMinutes: () => disabledMinutes,
  };
};
