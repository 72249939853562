import rootStore from '../../../../../../stores/rootStore/rootStore';

const handleIsLanes = () => {
  const {
    setLinkedLanes,
    setPointsValue,
    isTrafficLanes,
    linkedLanes,
    pointsInExtent,
  } = rootStore.pointsUdsStore;

  pointsInExtent.forEach(({ deviceInfo: { uid } }) => {
    if (uid && linkedLanes[uid]) {
      const newLinkedLanes = linkedLanes[uid].map((lane) => ({
        ...lane,
        isEnabled: !isTrafficLanes,
      }));

      setLinkedLanes(newLinkedLanes, uid);
    }
  });

  setTimeout(() => setPointsValue('isTrafficLanes', !isTrafficLanes));
};

export default handleIsLanes;
