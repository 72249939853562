import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC, MouseEventHandler } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import Popover, { PopoverProps } from '../Popover/Popover';

import styles from './ButtonStatus.module.scss';

export type TPopoverProps = Omit<PopoverProps, 'children' | 'tipsDelay'>;

interface IButtonStatus {
  isActive: boolean;
  popoverProps: TPopoverProps;
  icon: JSX.Element;
  title: string;
  titleClassName?: string;
  handleClick: MouseEventHandler<HTMLAnchorElement> &
    MouseEventHandler<HTMLButtonElement>;
}

const ButtonStatus: FC<IButtonStatus> = ({
  titleClassName,
  popoverProps,
  icon,
  isActive,
  title,
  handleClick,
}) => {
  const { interfaceTipsDelay } = rootStore.uiStore;

  return (
    <Popover {...popoverProps} tipsDelay={interfaceTipsDelay}>
      <Button
        className={styles.device}
        onClick={handleClick}
        type={isActive ? 'primary' : 'default'}
      >
        <div className={styles.content}>
          {icon}
          <p className={titleClassName}>{title}</p>
        </div>
      </Button>
    </Popover>
  );
};

export default observer(ButtonStatus);
