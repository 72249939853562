import { COLORS } from '../../../../../../../constants/colorsConstants';
import {
  DEVICES_INFO,
  DEVICES_STATE,
  TDeviceStatus,
} from '../../../../../../../constants/devicesConstants';
import { findBy } from '../../../../../../../helpers/findBy';
import parseTLMode from '../../../../../../../helpers/parseTLMode';
import { TlMode } from '../../../../../../../ts/models/tl.model';

interface ColorDeviceProps {
  mode: N<TlMode>;
  isDeviceStatus: boolean;
  statusDevice?: TDeviceStatus;
  isTL: boolean;
}

export const getDeviceStatusColor = (statusDevice: TDeviceStatus) => {
  return (
    findBy(DEVICES_STATE, statusDevice, 'com')?.color ??
    DEVICES_INFO.STATE.Undefined.color
  );
};

const getTlModeColor = (mode: N<TlMode>) => {
  const { color } = parseTLMode(mode);

  return color;
};

export const getColorDevice = ({
  mode,
  isDeviceStatus,
  statusDevice,
  isTL,
}: ColorDeviceProps) => {
  if (isDeviceStatus && statusDevice) return getDeviceStatusColor(statusDevice);

  if (isTL) return getTlModeColor(mode);

  return COLORS.PRIMARY;
};
