import serverApi from '../../api/integration/gis/serverapi/index';
import {
  WEATHER_METHOD_NAME,
  WEATHER_MODULE_ID,
} from '../../apiGIS/constants/weather';
import { IWeatherDataset } from '../../components/Map/IWeatherTimeModel';

const weatherService = {
  async execute(
    geographicRegionId: string | number,
    time?: string
  ): Promise<{ data: IWeatherDataset[]; hasIntegrationGIS: boolean }> {
    const queryStringParams: Record<string, any> = {
      geographicRegionId,
    };

    if (time) {
      queryStringParams.time = time;
    }
    const data = await serverApi.execute(
      'gis',
      WEATHER_MODULE_ID,
      WEATHER_METHOD_NAME,
      queryStringParams
    );

    if (!data || Object.hasOwn(data, 'status')) {
      if (data?.status === 423) {
        return { data: [], hasIntegrationGIS: false };
      }

      return { data: [], hasIntegrationGIS: true };
    }

    return { data: data as IWeatherDataset[], hasIntegrationGIS: true };
  },
};

export default weatherService;
