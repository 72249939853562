export const sqlKeywords = [
  'drop',
  'table',
  'select',
  'insert',
  'truncate',
  'view',
  'update',
];

export const DEFAULT_COORDINATES_RESPONSE = `55.1, 37.2`;
