import { useEffect } from 'react';

import rootStore from '../../../../stores/rootStore/rootStore';
import SelectTls from '../SelectTls/SelectTls';

import CreateCoordGroup from './CreateCoordGroup/CreateCoordGroup';
import CreateGroupBtn from './CreateGroupBtn/CreateGroupBtn';

import styles from './ScriptsControlDetailed.module.scss';

const ScriptsControlDetailed = () => {
  const { closeGroups } = rootStore.scriptsControlStore;

  useEffect(() => {
    closeGroups();
  }, [closeGroups]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <SelectTls />
      </div>
      <div className={styles.content}>
        <h3>Список СО:</h3>
        <CreateCoordGroup />
      </div>
      <div className={styles.btnBlock}>
        <CreateGroupBtn />
      </div>
    </div>
  );
};

export default ScriptsControlDetailed;
