import { TPartialConfirm } from '../ButtonOnlyIcon/model/buttonIcon.model';

import { FileExtensions } from './Export.model';

export const OPTION_BUTTON_OPTIONS = {
  size: 'small',
  type: 'text',
} as const;

export const OK_TEXT = 'Экпортировать';
export const CANCEL_TEXT = 'Отмена';

export const CONFIRM_PLACEMENT = 'topLeft';

export const DEFAULT_OPTIONS = [FileExtensions.xlsx, FileExtensions.csv];

export const POPOVER_EXPORT = 'Экспортировать данные';

export const BASE_CONFIRM_PROPS: TPartialConfirm = {
  okText: OK_TEXT,
  cancelText: CANCEL_TEXT,
  icon: null,
};
