import { Form } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { isEqual } from 'lodash';
import { useState, useEffect, useCallback } from 'react';

import getInitValues from '../helpers/getInitValues';

interface UseSubmitFormArgs<Form, Props> {
  handleSubmit: (values: Form, props?: Props) => Promise<void>;
  props?: Props;
  initData?: Form;
  key?: keyof Form;
}

const useSubmitForm = <Form, Props = undefined>({
  handleSubmit,
  props,
  initData,
  key,
}: UseSubmitFormArgs<Form, Props>) => {
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initValues, setInitValues] = useState<Form>();
  const [isInit, setIsInit] = useState(false);

  const [form] = Form.useForm<Form>();
  const values = useWatch([], form);

  const submit = async () => {
    setIsLoading(true);
    await handleSubmit(form.getFieldsValue(), props);
    setIsLoading(false);
  };

  const validate = useCallback(async () => {
    try {
      await form.validateFields({ validateOnly: true });

      key ? values[key] && setIsValid(true) : setIsValid(true);
    } catch (e) {
      setIsValid(false);
    }
  }, [form, values, key]);

  const reset = () => form.resetFields();
  const resetToInit = () => initValues && form.setFieldsValue(initValues);

  useEffect(() => {
    validate();
  }, [validate]);

  useEffect(() => {
    if (!initData) return;
    form.setFieldsValue(initData);
    const values = getInitValues(initData, form) as Form;

    setInitValues(values);
  }, [initData, form]);

  useEffect(() => {
    if (!initValues) return;
    const isValuesEqual = isEqual(initValues, form.getFieldsValue());

    setIsInit(isValuesEqual);
  }, [form, values, setIsInit, initValues]);

  return {
    form,
    isValid,
    isSubmit: isValid && !isInit,
    isLoading,
    submit,
    reset,
    setIsInit,
    resetToInit,
  };
};

export default useSubmitForm;
