import { Card, Button } from 'antd';
import { useState, FC } from 'react';

import { TLPhaseCodes } from '../../../ts/enums/tl';
import translateCode from '../../Constructor/DetailedConstructor/ConstructorPhaseImages/helpers/translateCode';
import Img from '../../ui-kit/Img/Img';
import PreLoader from '../../ui-kit/PreLoader/PreLoader';

import { IPhasesData } from './helpers/getImgPhases';
import { getPhaseTitle } from './helpers/getPhaseTitle';

import styles from './TlPhaseSchemeList.module.scss';

interface TLPhaseSchemeListProps {
  id: number;
  phasesData?: IPhasesData[];
  cashedIMGs?: Record<string, unknown>;
}

const TlPhaseSchemeList: FC<TLPhaseSchemeListProps> = ({ id, phasesData }) => {
  const [currentPhase, setCurrentPhase] = useState<U<IPhasesData>>(
    phasesData?.[0]
  );

  if (!currentPhase) {
    return (
      <div className={styles.containerNotData}>
        <Card className={styles.mainGraphCardNotData}>
          <h3>Нет данных</h3>
        </Card>
      </div>
    );
  }

  const { phaseID, phaseIMG, code } = currentPhase;

  const { label } = translateCode(
    code ?? TLPhaseCodes.Undefined,
    Number(phaseID)
  );

  return (
    <div className={styles.container}>
      <div className={styles.phaseSchemeAlternative}>
        <h1 className={styles.phaseScTitle}>{label}</h1>
        <Img
          alt={`tl_${id}_${getPhaseTitle(phaseID)}`}
          src={phaseIMG}
          className={styles.containerImg}
          classNameImg={styles.img}
          isShowIcons
        />
      </div>
      <div className={styles.schemeSwitch}>
        <ul>
          {phasesData?.map((phase, i) => {
            const { label: titlePhase } = translateCode(
              phase.code ?? TLPhaseCodes.Undefined,
              Number(phase.phaseID)
            );

            return (
              phase.main !== 0 && (
                <li key={`key_${i}`}>
                  <Button
                    type={
                      currentPhase.phaseID === phase.phaseID
                        ? 'primary'
                        : 'default'
                    }
                    onClick={() => {
                      return setCurrentPhase(phase);
                    }}
                  >
                    {titlePhase}
                  </Button>
                </li>
              )
            );
          }) ?? <PreLoader />}
        </ul>
      </div>
    </div>
  );
};

export default TlPhaseSchemeList;
