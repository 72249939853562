import { Card } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useState, useEffect } from 'react';

import { PHASES_TEMPLATE } from '../../../constants/constants';
import { findById } from '../../../helpers/findBy';
import cacheStore from '../../../stores/cacheStore/cacheStore';
import rootStore from '../../../stores/rootStore/rootStore';
import { TL } from '../../../ts/models/tl.model';
import { DetailedItemProps } from '../../DetailedMain/DetailedWrapper/constants/constants';
import Switcher from '../../ui-kit/Switcher/Switcher';
import VsSwitcher from '../temporary/VsSwitcher/VsSwitcher';

import { getImgPhases, IPhasesData } from './helpers/getImgPhases';
import TlPhaseSchemeFilled from './TlPhaseSchemeFilled';
import TlPhaseSchemeGrid from './TlPhaseSchemeGrid';
import TlPhaseSchemeList from './TlPhaseSchemeList';

import styles from './TlPhaseScheme.module.scss';

const TlPhaseScheme: React.FC<DetailedItemProps> = (props) => {
  const { tls } = rootStore.mapDataStore;

  const [activeTL, setActiveTL] = useState<TL>();

  const { id } = props;
  const { FILLED, LIST, GRID } = PHASES_TEMPLATE;

  const schemeType = {
    [FILLED]: TlPhaseSchemeFilled,
    [LIST]: TlPhaseSchemeList,
    [GRID]: TlPhaseSchemeGrid,
  };

  const { cachedImages } = cacheStore;

  const [templateVer, setTemplateVer] = useState(GRID);
  const [phasesData, setPhasesData] = useState<IPhasesData[]>([]);

  useEffect(() => {
    if (!id) return;

    const activeTrafficLight = findById(tls, id);

    setActiveTL(activeTrafficLight);
  }, [tls, id]);

  useEffect(() => {
    if (!activeTL) return;

    const updatePhases = () => {
      const phasesRes = getImgPhases(activeTL);

      setPhasesData(phasesRes ?? []);
    };

    updatePhases();
  }, [activeTL]);

  if (!phasesData?.length) {
    return (
      <div className={styles.containerNotData}>
        <Card className={styles.mainGraphCardNotData}>
          <h3>Нет данных</h3>
        </Card>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <VsSwitcher toggler={setTemplateVer} />
      <Switcher
        id={id}
        phasesData={phasesData}
        cashedIMGs={cachedImages}
        obj={schemeType}
        segment={templateVer}
      />
    </div>
  );
};

export default observer(TlPhaseScheme);
