import { Space } from 'antd';
import React from 'react';

import styles from '../../InfoPanel/IdentificationInfo/IdentificationInfo.module.scss';

export interface ActionItemProps {
  icon: React.FC;
  text: string;
  onClick?: () => void;
}

export const ActionItem = (props: ActionItemProps) => (
  <Space
    onClick={() => {
      props.onClick && props.onClick();
    }}
  >
    <div className={styles.actionItemContainer}>
      {React.createElement(props.icon)}
      <div className={styles.actionItemText}>{props.text}</div>
    </div>
  </Space>
);
