import { DataType, ITableColumn } from '../../../../ts/models/table.model';
import { Collaboration } from '../../models/adminTypes.model';

export type DivisionBody = {
  name: string;
  dsc: string;
  organizationId: number;
};

export type SubmitDivValues = DivisionBody | Collaboration;

export const isDivisionBody = (
  values: SubmitDivValues
): values is DivisionBody => values.name && values.dsc && values.organizationId;

export interface SubmitDivisionsArgs {
  collaboration: any;
  setIsUpdate: SetState<boolean>;
  setIsModalOpen: SetState<boolean>;
  id: number;
  isEdit: boolean;
}

export interface IDivisions {
  dataRows: DataType[];
  headerItems: ITableColumn[];
}

export interface DivisionsProps {
  id: number;
}
