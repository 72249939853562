import { Tag } from 'antd';
import { DescriptionsProps } from 'antd/lib';
import { isNumber, isString } from 'lodash';

import { FormattedStatistic } from '../../../../../../../api/adminObjects/systems/adapter/model/adapter.model';
import { LibraryKeys } from '../../../../../../../api/libraries/enums/enums';
import { UNKNOWN_STATUS } from '../../../../../../../constants/adminConstants';
import { findById } from '../../../../../../../helpers/findBy';
import { getObjectEntries } from '../../../../../../../helpers/getObjectEntries';
import rootStore from '../../../../../../../stores/rootStore/rootStore';
import { ADAPTER_LABELS } from '../../../../../../Admin/AdminSystemObject/ASOMonitoring/constants/monitoringConstants';
import { getAdapterStatistic } from '../../../../../../Admin/AdminSystemObject/ASOMonitoring/helpers/getAdapterStatistic';

type Items = NonNullable<DescriptionsProps['items']>;

const MIN_DOUBLE_COLUMN = 25;

const { SystemId } = LibraryKeys;

const getAdapterItems = (
  statistic: FormattedStatistic[],
  currentSystemId: U<number>
): Items => {
  const systemDict = rootStore.uiStore.dictionaries[SystemId];

  const adapter = statistic.at(0);

  const basicItems: Items = [
    {
      key: 'systemId',
      label: ADAPTER_LABELS.systemId,
      children: currentSystemId,
    },
    {
      key: 'systemName',
      label: ADAPTER_LABELS.systemName,
      children: findById(systemDict, currentSystemId)?.name,
    },
  ];

  if (!adapter) return basicItems;

  const { passesDtFrom10Min, passesTLFrom10Min, workTime, status, regions } =
    getAdapterStatistic(adapter);

  const items = getObjectEntries({
    status,
    workTime,
    passesDtFrom10Min,
    passesTLFrom10Min,
    regions,
  }).reduce((acc: Items, [key, item]) => {
    if (!item) return acc;

    const { label, value, color } = item;

    const isDoubleColumn = label.length >= MIN_DOUBLE_COLUMN;

    (isNumber(value) || isString(value)) &&
      acc.push({
        key,
        label,
        span: isDoubleColumn ? 2 : undefined,
        children: color ? (
          <Tag color={color}>{value?.toString() ?? UNKNOWN_STATUS}</Tag>
        ) : (
          value
        ),
      });

    return acc;
  }, []);

  return [...basicItems, ...items];
};

export default getAdapterItems;
