import { Interaction, MouseWheelZoom } from 'ol/interaction';
import { fromLonLat } from 'ol/proj';
import { useEffect } from 'react';

import setDisplayStyle from '../../../helpers/setDisplayStyle';
import rootStore from '../../../stores/rootStore/rootStore';
import handleCenterZoom from '../helpers/zoomHandlers/handleCenterZoom';

const useMapSettings = () => {
  const { map, constructorCircleRef } = rootStore.mapStore;
  const { isApplyCenter, circleCenter, isWheelZoom, setConstructorData } =
    rootStore.constructorStore;

  // set center for constructor
  useEffect(() => {
    if (isApplyCenter) {
      setDisplayStyle([constructorCircleRef], 'block');
      handleCenterZoom(fromLonLat(circleCenter));
      setConstructorData('isApplyCenter', false);
    }
  }, [isApplyCenter]); // eslint-disable-line

  useEffect(() => {
    if (!map) return;

    map.getInteractions().forEach((interaction: Interaction) => {
      if (interaction instanceof MouseWheelZoom) {
        interaction.setActive(isWheelZoom);
      }
    });
  }, [isWheelZoom, map]);

  return null;
};

export default useMapSettings;
