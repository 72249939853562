import {
  AdapterInfo,
  AdditionalAdapterKeys,
  MainAdapterKeys,
} from '../../../../../components/Admin/AdminSystemObject/ASOMonitoring/models/monitoring.models';
import { Path } from '../../../../api.model';

export type SystemType = number[] | number;

export interface StatisticProps {
  appPath: Path;
  systemIds?: number[];
  systemTypes?: number[];
  regionIds?: number[];
  subSystemTypes?: number[];
  isBlockedNotify?: boolean;
}

export interface useStatisticQueryOptions {
  appPath: string;
  systemIds?: SystemType;
  systemTypes?: SystemType;
  subSystemTypes?: SystemType;
  regionIds?: SystemType;
  specialKeys?: unknown[];
  isEnabled?: boolean;
}

export enum AdapterTypes {
  Adapter = 'adapter',
  AdapterXeoma = 'adapterXeoma',
  AdapterGis = 'adapterGis',
  AdapterMicroService = 'adapterMicroService',
}

export enum EnabledStatus {
  Off,
  On,
}

interface AdapterGoInfo {
  host_id: string;
  uptime_sec: number;
  session_uuid: string;
  status: string;
  lastTimeTZ: string;
  statistics: N<{
    received_passes_from_start: N<number>;
    received_passes_from_last_10min: N<number>;
    events_from_start: N<number>;
    events_from_last_10min: N<number>;
  }>;
  error: N<{
    jerror: OWKS<any>;
    timeTZ: string;
  }>;
}

interface BaseAdapterData {
  sc_system_id: number;
  sc_system_regions_id: N<number[]>;
  sub_systems_id: N<number[]>;
  sc_system_type_id: number;
}

export interface AdapterData extends BaseAdapterData {
  add_info: {
    adapter: N<AdapterGoInfo>;
  };
  is_enabled: EnabledStatus;
}

interface ExternalAdapter extends BaseAdapterData {
  error: N<Record<string, any>>;
  status: string;
  uptime_sec: number;
  statistic: unknown;
}

export interface MainAdapter extends BaseAdapterData {
  error: N<Record<string, any>>;
  status: N<string>;
  uptime_sec: N<number>;
  statistic: unknown;
  is_enabled?: EnabledStatus;
}

export interface GeneralAdapterStatistic {
  data: AdapterData | ExternalAdapter;
  data_type: AdapterTypes;
}

export type StatisticByType = Partial<Record<AdapterTypes, MainAdapter[]>>;

export interface FormattedStatistic extends MainAdapter {
  systemName: N<string>;
  regions: {
    id: number;
    name: string;
  }[];
  adapterType: AdapterTypes;
}

export type FormattedAdapter = Record<MainAdapterKeys, AdapterInfo> &
  Partial<Record<AdditionalAdapterKeys, AdapterInfo>>;

export const isAdapterGo = (
  value: unknown,
  type: AdapterTypes
): value is AdapterData => type === AdapterTypes.Adapter;
