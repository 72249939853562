import dayjs from 'dayjs';
import { uniqueId } from 'lodash';

import { ProfileHistory } from '../../../../../ts/models/constructor.model';
import { getTimeWithTimeZone } from '../../../../TrafficLightDetailed/TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeWithTimeZone';

interface TableData {
  date: string;
  key: string;
  user: string;
}

const getTableData = (profileHistory: ProfileHistory[]) =>
  profileHistory
    .reduce((res: TableData[], { date, user: { name, login } }) => {
      const formattedDate = getTimeWithTimeZone({
        dateJS: dayjs(date),
      }).dateFormatted;

      res.push({
        key: uniqueId('profileHistory_'),
        date: formattedDate,
        user: `${name} | ${login}`,
      });

      return res;
    }, [])
    .reverse();

export default getTableData;
