import { FC } from 'react';

import ButtonVisibleEye from '../../ButtonVisibleEye/ButtonVisibleEye';

import styles from './VisibleLayerBtn.module.scss';

interface VisibleLayerBtnProps {
  isVisible: boolean;
  isBlocked: boolean;
  onClick: () => void;
}

const VisibleLayerBtn: FC<VisibleLayerBtnProps> = ({
  isVisible,
  isBlocked,
  onClick,
}) => {
  return (
    <div className={styles.btnsBlock}>
      <ButtonVisibleEye
        onChangeVisibility={(e) => {
          e?.stopPropagation();
          onClick();
        }}
        isVisible={isVisible}
        isDisabled={isBlocked}
      />
    </div>
  );
};

export default VisibleLayerBtn;
