export const INFO_BORDER = 18.5;
export const NO_DATA = '-';

export const USE_VEHICLES_AMOUNT_PATH =
  'components.overlays.trafficLanesBlock.helpers.fetchVehiclesAmount';

export const DETECTOR_DIRECTION = {
  CAMERA_IN: 1,
  CAMERA_OUT: 2,
  UNDEFINED: 3,
} as const;

export const EMPTY_FILTER = 'Нельзя применить фильтр к одной полосе';
