import { TMap } from '../../../stores/mapStore/mapStore.model';

import { getLayer } from './getLayer';

export const getOpacity = (map: TMap, id: string | undefined) => {
  if (!map || !id) {
    return 1;
  }

  const layer = getLayer(map, id);

  return layer?.getOpacity() || 1;
};
