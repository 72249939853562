import { isString } from 'lodash';
import { useMemo } from 'react';
import { BarChart, Bar, Cell, ResponsiveContainer, XAxis } from 'recharts';
import type { CategoricalChartFunc } from 'recharts/types/chart/generateCategoricalChart';

import { X_KEY } from '../../../../ui-kit/Chart/constants/constants';
import CountBlock from '../CountBlock/CountBlock';
import { IDataGraph } from '../PopupDetector.model';

import { ChartBDKeys } from './constants/constants';
import CustomShape from './CustomShape/CustomShape';
import InfoBlock from './InfoBlock/InfoBlock';
import Label from './Label/Label';
import Tick from './Tick/Tick';

import styles from './ChartBar.module.scss';

interface IChartBar<T extends IDataGraph> {
  dataGr: T[];
  activeIndex: number;
  radioValue: ChartBDKeys;
  isLabel?: boolean;
  xKey: keyof T;
  setActiveIndex: SetState<number>;
}

const ChartBar = <T extends IDataGraph>({
  dataGr,
  activeIndex,
  xKey,
  radioValue,
  isLabel = false,
  setActiveIndex,
}: IChartBar<T>) => {
  const calcData = useMemo(
    () => dataGr.map((el, index) => ({ ...el, index })),
    [dataGr]
  );

  if (!calcData.length) return null;

  const activeCell = calcData[activeIndex];

  const onMouseMove: CategoricalChartFunc = (data) => {
    const index = Number(data?.activeLabel);

    !isNaN(index) && setActiveIndex(index);
  };

  return (
    <div className={styles.container}>
      <InfoBlock activeDate={activeCell.date} radioValue={radioValue} />
      <CountBlock activeCell={activeCell} radioValue={radioValue} />
      <ResponsiveContainer>
        <BarChart
          className={styles.barChart}
          data={calcData}
          onMouseMove={onMouseMove}
          margin={{
            top: 20,
            left: 20,
          }}
        >
          <XAxis
            dataKey={X_KEY}
            tickCount={6}
            type="number"
            tick={(props) => <Tick {...props} data={calcData} xKey={xKey} />}
            axisLine={false}
            tickLine={false}
          />
          <Bar
            dataKey="comparableValue"
            shape={(props) => (
              <CustomShape {...props} activeIndex={activeIndex} />
            )}
            label={
              isLabel
                ? (props) => (
                    <Label
                      {...props}
                      data={calcData}
                      activeIndex={activeIndex}
                    />
                  )
                : undefined
            }
          >
            {calcData.map((item, index) => {
              const status = item.status;

              if (!isString(status)) return null;

              return (
                <Cell
                  cursor="pointer"
                  stroke={index === activeIndex ? 'red' : 'none'}
                  strokeWidth={2}
                  fill={status}
                  key={`cell-${index}`}
                />
              );
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ChartBar;
