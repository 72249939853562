import { ERROR_TEXT_SGN_PROGRAMS } from '../../../../../constants/commands';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { IProcessedDataInitial } from '../../../../../ts/models/signalPrograms.model';
import Popover from '../../../../ui-kit/Popover/Popover';
import { ISelectLabels } from '../../PhaseHold/PhaseHold.model';
import { SgnProgramValues } from '../../PlanSelect/models/plansControl.model';
import SgnProgramTooltip from '../SgnProgramTooltip/SgnProgramTooltip';

import styles from '../SgnProgramTooltip/SgnProgramTooltip.module.scss';

interface ISgnProgramProps {
  programs: IProcessedDataInitial[];
  sgnProgramValues: SgnProgramValues[];
  id: number;
}

export const getSelectLabels = (sgnProgramProps: ISgnProgramProps) => {
  const { programs, sgnProgramValues, id } = sgnProgramProps;

  if (!programs.length || !sgnProgramValues.length) return;

  const { tipsValues } = rootStore.uiStore;

  const signalProgramShow = {
    content: ERROR_TEXT_SGN_PROGRAMS,
    placement: 'right',
    tipsDelay: tipsValues.profileTipsDelay,
  } as const;

  const selectLabels: ISelectLabels[] = programs.map((item, i) => {
    const { execTime, phaseGroupNum } = item;

    const sgnTooltipProps = {
      execTime,
      key: phaseGroupNum,
      phaseGroupNum: phaseGroupNum,
      id: id,
      ...sgnProgramValues[i],
    };

    return {
      key: phaseGroupNum,
      value: execTime,
      label: (
        <Popover
          {...signalProgramShow}
          content={<SgnProgramTooltip {...sgnTooltipProps} key={id} />}
          destroyTooltipOnHide
        >
          <p className={styles.selectText}>{execTime}</p>
        </Popover>
      ),
    };
  });

  return selectLabels;
};
