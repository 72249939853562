import { isNumber } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { findById } from '../../../../helpers/findBy';
import rootStore from '../../../../stores/rootStore/rootStore';
import { getCameraInfo } from '../../../VideoCamera/helpers/getCameraInfo/getCameraInfo';
import VideoCamera from '../../../VideoCamera/VideoCamera';

interface CameraInfoProps {
  id: U<number>;
}

const CameraInfo: FC<CameraInfoProps> = ({ id }) => {
  const { cameras } = rootStore.mapDataStore;
  const cameraData = isNumber(id)
    ? getCameraInfo(findById(cameras, id))
    : undefined;

  return <VideoCamera cameraData={cameraData} />;
};

export default observer(CameraInfo);
