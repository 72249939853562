import classNames from 'classnames';
import { FC } from 'react';

import { TDeviceStatus } from '../../../../../../../../constants/devicesConstants';
import DeviceIcon from '../../DeviceIcon/DeviceIcon';

import PhaseInfo from './PhaseInfo/PhaseInfo';

import styles from './DeviceAvatar.module.scss';

interface DeviceAvatarProps {
  id: number;
  status: TDeviceStatus;
  iconSystem: JSX.Element;
  isActive?: boolean;
  isPhase?: boolean;
  isShow?: boolean;
}

const DeviceAvatar: FC<DeviceAvatarProps> = ({
  id,
  status,
  iconSystem,
  isActive = false,
  isPhase = false,
  isShow = true,
}) => {
  const containerStyle = classNames(styles.avatar, {
    [styles.invisible]: !isShow,
  });

  return (
    <div className={containerStyle}>
      <DeviceIcon status={status} iconSystem={iconSystem} />
      <p className={classNames(styles.id, { [styles.active]: isActive })}>
        {id}
      </p>
      {isPhase && <PhaseInfo id={id} />}
    </div>
  );
};

export default DeviceAvatar;
