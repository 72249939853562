import type { BaseOptionType, DefaultOptionType } from 'antd/es/select';
import { useEffect, useMemo, useState } from 'react';

import rootStore from '../../../../../../../../stores/rootStore/rootStore';

type SelectOptions = (BaseOptionType | DefaultOptionType)[];

const useLinkedVideoCamera = (cameraIds: number[], currentCamId: N<number>) => {
  const { getById } = rootStore.mapDataStore;

  const [selectedCamId, setSelectedCamId] = useState<number>();

  const cameras = useMemo(
    () =>
      cameraIds.reduce((acc: SelectOptions, cameraId: number) => {
        const camera = getById(cameraId, 'cameras');

        if (!camera) return acc;

        acc.push({
          value: camera.id,
          label: `Камера: ${camera.caption}`,
        });

        return acc;
      }, []),
    [cameraIds, getById]
  );

  useEffect(
    () => setSelectedCamId(currentCamId ?? cameraIds[0]),
    [cameraIds, currentCamId]
  );

  return { cameras, selectedCamId, handleChangeSelect: setSelectedCamId };
};

export default useLinkedVideoCamera;
