import { useQuery } from '@tanstack/react-query';
import { isNumber } from 'lodash';

import api from '../../../api';
import {
  DEFAULT_UPDATE_TIME,
  QUERY_KEY,
} from '../../../constants/queryConstants';

const { USER_INFO } = QUERY_KEY;
const { infoById } = api.user;

const APP_PATH = 'UserCard.hooks.useUserInfo';

const useUserInfo = (userId: U<number>) => {
  const {
    data: user,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [USER_INFO, userId],
    queryFn: async () => {
      if (!isNumber(userId)) return null;

      const res = await infoById(userId, APP_PATH);

      return res ?? null;
    },
    refetchInterval: DEFAULT_UPDATE_TIME,
    enabled: isNumber(userId),
  });

  return { user, isLoading, error: isError || !user };
};

export default useUserInfo;
