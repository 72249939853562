import type { Map } from 'ol';
import type { Interaction } from 'ol/interaction';
import SelectCluster from 'ol-ext/interaction/SelectCluster';

export const getSelectInteraction = (map: Map) => {
  const interactions = map.getInteractions().getArray();

  const select = interactions.find((interaction: Interaction) =>
    interaction instanceof SelectCluster ? interaction : null
  ) as N<SelectCluster>;

  return select;
};
