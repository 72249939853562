import { Button, Spin } from 'antd';
import bytes from 'bytes';
import { uniqueId } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';

import downloadFile from '../../../../helpers/downloadFile';
import rootStore from '../../../../stores/rootStore/rootStore';
import { typesComponents } from '../../constants/documents';
import { validateFileExtension } from '../../helpers/validateFiles';

import styles from './DocViewWindow.module.scss';

const VOCABULARY: Record<string, string> = {
  size: 'Размер файла',
  name: 'Наименование',
  extension: 'Расширение',
};

const WAIT_TEXT = 'Файл загружается подождите...';
const CHANGE_TEXT = 'Выберите файл для отображения';

const DocViewWindow = () => {
  const preREf = useRef<HTMLParagraphElement>(null);
  const { viewFile, isLoading, setDocumentsKeysValues, controller } =
    rootStore.documentsStore;
  const [data, setData] = useState<any>(null);
  const [isSupportedExtension, setIsSupportedExtension] = useState(false);
  const [text, setText] = useState<N<string | ArrayBuffer>>(null);

  const handleDownload = () => {
    if (viewFile)
      downloadFile(
        viewFile?.file,
        `${viewFile?.attributes.name}.${viewFile.attributes.extension}`
      );
  };

  const handleCancel = () => {
    controller?.abort();
    setDocumentsKeysValues({
      isLoading: false,
      viewFile: null,
      controller: new AbortController(),
    });
  };

  useEffect(() => {
    isLoading && setDocumentsKeysValues({ viewFile: null });
  }, [isLoading, setDocumentsKeysValues]);

  useEffect(() => {
    if (viewFile) {
      const blob = new Blob([viewFile.file], {
        type: viewFile.type,
      });

      const fileUrl = URL.createObjectURL(blob);

      setData({
        file: fileUrl,
        type: viewFile.type,
      });

      const reader = new FileReader();

      reader.readAsText(viewFile.file, 'blob');

      reader.onload = () => setText(reader.result);

      setIsSupportedExtension(
        validateFileExtension(viewFile.attributes.extension)
      );
    }
  }, [viewFile]);

  useEffect(() => {
    if (text && preREf.current) {
      preREf.current.innerHTML = `${text}`;
    }
  }, [preREf, text]);

  if (!viewFile) {
    return (
      <div className={styles.wrapper}>
        <Spin spinning={isLoading} />
        <p className={styles.infoText}>{isLoading ? WAIT_TEXT : CHANGE_TEXT}</p>
        {isLoading && <Button onClick={handleCancel}>Отменить загрузку</Button>}
      </div>
    );
  }

  if (!isSupportedExtension) {
    return (
      <div className={styles.wrapper}>
        <p className={styles.infoText}>
          Данный формат не доступен для просмотра,
          <br /> вы можете скачать документ нажав на соответствующую кнопку
        </p>

        <Button
          type="primary"
          onClick={handleDownload}
          className={styles.dwldBtn}
        >
          Скачать
        </Button>
      </div>
    );
  }

  type AttributesKey = keyof typeof viewFile.attributes;

  return (
    <div className={styles.wrapper}>
      <div className={styles.fileInfo}>
        {Object.keys(viewFile.attributes).map((key, i) => (
          <div key={i} className={styles.infoItem}>
            <span>{VOCABULARY[key]}:</span>
            {key === 'size'
              ? bytes(Number(viewFile.attributes[key as AttributesKey]))
              : viewFile.attributes[key as AttributesKey]}
          </div>
        ))}
        <Button
          type="primary"
          onClick={handleDownload}
          className={styles.infodwldBtn}
        >
          Скачать
        </Button>
      </div>
      {typesComponents.map(({ types, Component }) => {
        if (types.includes(data.type)) {
          return <Component key={uniqueId('#')} fileUrl={data.file} />;
        }

        return null;
      })}

      {data.type === 'text/plain' ? (
        <div className={styles.text}>
          <p ref={preREf}></p>
        </div>
      ) : null}
    </div>
  );
};

export default observer(DocViewWindow);
