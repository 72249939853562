import { observer } from 'mobx-react-lite';

import rootStore from '../../../stores/rootStore/rootStore';
import { TLDetailedCode } from '../../../ts/enums/userData';

import InfoBtn from './InfoBtn/InfoBtn';
import MapInfo from './MapInfo/MapInfo';

const MapInfoWrapper = () => {
  const { isConstructor } = rootStore.constructorStore;
  const { isDevMapInfo } = rootStore.uiStore;
  const { hasAccess } = rootStore.userDataStore;

  const isMapInfo =
    (hasAccess(TLDetailedCode.DevelopTools) || isConstructor) && isDevMapInfo;

  return <>{isMapInfo ? <MapInfo /> : <InfoBtn />}</>;
};

export default observer(MapInfoWrapper);
