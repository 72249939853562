import { isNumber } from 'lodash';

import { TlManagementKeys } from '../../../../../constants/commands';
import { getTLModeName } from '../../../../../helpers/statusHelpers';
import { TL } from '../../../../../ts/models/tl.model';
import { REMOTE_CONTROL_NAME } from '../constants/controlConstants';

const getIsNeedSwitchTab = (activeTL: TL, activeKey: TlManagementKeys) => {
  const mode = activeTL.tlStatus.mode;

  const name = isNumber(mode) ? getTLModeName(mode) : null;

  const accessCommands = REMOTE_CONTROL_NAME[activeKey];

  const isDisabled = accessCommands?.every((cmd) => cmd !== name) ?? false;

  return isDisabled;
};

export default getIsNeedSwitchTab;
