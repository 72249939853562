import { point as turfPoint, toWgs84 as turfToWgs84 } from '@turf/turf';
import { Feature } from 'ol';
import { Point } from 'ol/geom';

import { geomToLatLon } from '../../../apiGIS/geometry/helpers';
import {
  coordinatesSearch,
  geocoderSearch,
  layersSearch,
  locationToGeometry,
} from '../../../apiGIS/helpers/search';
import {
  flyTo,
  hideFeatures,
  showFeatures,
} from '../../../apiGIS/layers/utils/flashFeatures';
import { createTemporaryLayer } from '../../../apiGIS/layers/utils/temporary';
import { ISearchItem } from '../../../apiGIS/models/search';
import { styleFunction } from '../../../apiGIS/styles/custom/markerStyle';
import { geocode } from '../../../apiGIS/tasks/geocode';
import { getMapPoint } from '../../../apiGIS/tasks/interactions';
import { ILayer } from '../../../stores/gisDataStore/gisDataStore.model';
import { TMap } from '../../../stores/mapStore/mapStore.model';

import {
  ERROR_GEOCODING_OPERATION,
  HIDE_FEATURE_TTL,
  LIST_DIRECTION_ITEM_MAX_SIZE,
  LIST_SEARCH_ITEM_MAX_SIZE,
  PAGER_HEIGHT,
  INPUT_BLOCK_HEIGHT,
  SEARCH_TEMPORARY_LAYER_ID,
  SEARCH_TYPE_COORDINATES,
  SEARCH_TYPE_GEOCODER,
  SEARCH_TYPE_LAYER,
} from './constants';

function hasProvider(provider: string, providers?: string[]) {
  if (!providers || providers.length === 0) {
    return true;
  }

  return providers.find((element) => element === provider);
}

export const getSearchItems = async (
  map: TMap,
  text: string,
  extent: number[] | undefined,
  layersDefinitions: ILayer[],
  providers?: string[]
): Promise<ISearchItem[]> => {
  const hasGeocoderProvider = hasProvider(SEARCH_TYPE_GEOCODER, providers);
  const hasLayersProvider = hasProvider(SEARCH_TYPE_LAYER, providers);
  const hasCoordinatesProvider = hasProvider(
    SEARCH_TYPE_COORDINATES,
    providers
  );

  const geocodeItems: ISearchItem[] = hasGeocoderProvider
    ? await geocoderSearch(map, text, extent)
    : [];

  const layersItems: ISearchItem[] = hasLayersProvider
    ? await layersSearch(map, text, extent, layersDefinitions)
    : [];

  const coordinateItems: ISearchItem[] = hasCoordinatesProvider
    ? await coordinatesSearch(map, text, extent)
    : [];

  let items: ISearchItem[] = [];

  geocodeItems.forEach((item) => items.push(item));
  layersItems.forEach((item) => items.push(item));
  coordinateItems.forEach((item) => items.push(item));

  items = items.map((item: ISearchItem, index) => {
    const { type } = item;

    if (type !== SEARCH_TYPE_LAYER) {
      item.feature?.set('num', String(index + 1));
    }

    return item;
  });

  return items;
};

export const timeToDuration = (time: number): string => {
  const timeValue = new Date(time);

  const seconds = timeValue.getSeconds().toString().padStart(2, '0');
  const minutes = timeValue.getMinutes().toString().padStart(2, '0');

  return `${minutes}:${seconds}`;
};

export const getLocationFromMap = async (
  map: TMap
): Promise<ISearchItem | undefined> => {
  const action = await getMapPoint(map);

  const { feature } = action;

  if (feature) {
    const geometry = feature.getGeometry();

    if (geometry instanceof Point) {
      const mapPointCoordinates = geometry.getCoordinates();

      const [x, y] = mapPointCoordinates;

      const mapPointWGS84 = turfToWgs84(turfPoint([x, y]));

      const [mapPointLon, mapPointLat] = mapPointWGS84.geometry.coordinates;

      const text = `${mapPointLon},${mapPointLat}`;

      const feature = new Feature({
        geometry: new Point(mapPointCoordinates),
        id: Math.random(),
      });

      const locations = await geocode(text, 1);

      const defaultResponse = {
        title: `${mapPointLat}, ${mapPointLon}`,
        type: SEARCH_TYPE_GEOCODER,
        feature,
      };

      if (locations && locations.length > 0) {
        const [item] = locations;

        const { quality } = item;

        const { code } = quality;

        if (code !== ERROR_GEOCODING_OPERATION) {
          const { address } = item;

          return {
            title: address,
            type: SEARCH_TYPE_GEOCODER,
            feature,
          };
        } else {
          return defaultResponse;
        }
      } else {
        return defaultResponse;
      }
    }
  }

  return undefined;
};

export const clearSearchResults = (map: TMap) => {
  if (!map) {
    return;
  }

  const layer = createTemporaryLayer(map, SEARCH_TEMPORARY_LAYER_ID);

  if (!layer) {
    return;
  }

  layer.getSource()?.clear();
};

export const drawSearchResults = (map: TMap, items: ISearchItem[]) => {
  if (!map) {
    return;
  }

  const layer = createTemporaryLayer(map, SEARCH_TEMPORARY_LAYER_ID);

  if (!layer) {
    return;
  }

  clearSearchResults(map);

  const features = items
    .filter((item) => !!item)
    .map((item: ISearchItem, index: number) => {
      const { feature } = item;

      if (!feature || (feature && !feature.getGeometry())) {
        return undefined;
      }

      const geom = feature?.getGeometry() ? feature.getGeometry() : undefined;

      if (!geom) {
        return undefined;
      }

      const location = geomToLatLon(geom);

      if (!location) {
        return undefined;
      }

      const geometry = locationToGeometry(location[0], location[1]);

      return new Feature({
        geometry,
        num: index + 1,
      });
    })
    .filter((item) => !!item);

  const source = layer.getSource();

  if (source) {
    source.clear(true);

    features.forEach((feature) => {
      feature && source.addFeature(feature);
    });
  }

  const style = styleFunction();

  layer.setStyle(style);
};

export const showFeature = async (item: ISearchItem) => {
  if (!item) {
    return;
  }

  const { feature } = item;

  hideFeatures([]);

  feature && showFeatures([feature]);

  setTimeout(() => {
    feature && hideFeatures([feature]);
  }, HIDE_FEATURE_TTL);
};

export const hideFeature = async (item: ISearchItem) => {
  if (!item) {
    return;
  }

  const { feature } = item;

  feature && hideFeatures([feature]);
};

export const zoomTo = async (item: ISearchItem) => {
  if (!item) {
    return;
  }

  const { feature } = item;

  flyTo(feature);
};

export const getSearchPageSize = (elementId: string) => {
  const defaultSize = Math.round(
    document.body.clientHeight / LIST_SEARCH_ITEM_MAX_SIZE
  );

  const element = document.getElementById(elementId);

  if (!element) {
    return defaultSize;
  }

  const height = element.clientHeight - PAGER_HEIGHT;

  return Math.floor(height / LIST_SEARCH_ITEM_MAX_SIZE);
};

export const getDirectionPageSize = (elementId: string) => {
  const defaultSize = Math.round(
    document.body.clientHeight / LIST_DIRECTION_ITEM_MAX_SIZE
  );

  const element = document.getElementById(elementId);

  if (!element) {
    return defaultSize;
  }

  const height = element.clientHeight - PAGER_HEIGHT - INPUT_BLOCK_HEIGHT;

  return Math.floor(height / LIST_DIRECTION_ITEM_MAX_SIZE);
};
