import { observer } from 'mobx-react-lite';
import {
  FC,
  MouseEventHandler,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { BREAK_POINTS } from '../../../../../constants/constants';
import rootStore from '../../../../../stores/rootStore/rootStore';
import {
  CHART_NAMES,
  ChartKeys,
} from '../../../../InfoPanel/SystemsInfo/PopupDetector/Chart/constants/constants';
import useResize from '../../../../NewSignalProgram/hooks/useResize';
import {
  GRAPHS_KEYS_VALUES,
  GRAPHS_TITLE,
  PERIOD_DICT,
  TDatesMinValues,
  TDatesValues,
} from '../../constants/constants';
import { IGraphValues } from '../../model/graph.model';
import RadioButtonsGraph from '../RadioButtonsGraph/RadioButtonsGraph';

import { getTitleArray } from './helpers/getTitleArray';
import { getTitleDetectors } from './helpers/getTitleDetectors';

import styles from './TitleBtns.module.scss';

interface ITitleBtnsProps {
  graphValues: IGraphValues;
  setGraphValues: SetState<IGraphValues>;
  radioValueDate: string;
  isError: boolean;
  changeDate: (value: string) => void;
}

interface ITitleInfo {
  date: (TDatesValues | TDatesMinValues)[];
  graph: (string | JSX.Element)[];
}

const TitleBtns: FC<ITitleBtnsProps> = ({
  graphValues,
  setGraphValues,
  radioValueDate,
  isError,
  changeDate,
}) => {
  const { graphType } = rootStore.detailedStore;
  const { isPanel } = rootStore.uiStore;

  const [titleArray, setTitleArray] = useState<ITitleInfo>({
    date: [],
    graph: [],
  });
  const wrapRef = useRef<HTMLDivElement>(null);

  const { width, height } = useResize({ wrapRef, isPanel });

  const key = useMemo(
    () =>
      Object.keys(CHART_NAMES).find(
        (el) => CHART_NAMES[el as ChartKeys] === graphValues.graphTitle
      ),
    [graphValues.graphTitle]
  );

  useEffect(() => {
    const isMinBtns = width <= BREAK_POINTS.S;
    const dateArr = Object.values(PERIOD_DICT[graphType]);
    const graphArr = Object.values(GRAPHS_TITLE);
    const dateValues = getTitleArray(dateArr);
    const graphTitleValues = getTitleArray(graphArr);

    setTitleArray({
      date: (isMinBtns ? dateValues.dateMin : dateValues.dateFull) as (
        | TDatesValues
        | TDatesMinValues
      )[],
      graph: isMinBtns ? graphTitleValues.dateMin : graphTitleValues.dateFull,
    });
  }, [width, height, graphType]);

  const onChangeGraphTitle = (btnKey: ChartKeys) => {
    setGraphValues((prev: IGraphValues) => ({
      ...prev,
      graphTitle: CHART_NAMES[btnKey],
    }));
  };

  const handleClickBtnInterval: MouseEventHandler<HTMLInputElement> = (e) =>
    //@ts-ignore
    changeDate(e.target.value);
  const handleChangeInterval = (value: string) => changeDate(value);

  return (
    <div className={styles.container} ref={wrapRef}>
      <RadioButtonsGraph
        radioContainerClassName={styles.radioContainer}
        btnsDscArr={getTitleDetectors({
          captions: titleArray.graph,
          valuesArr: GRAPHS_KEYS_VALUES,
        })}
        value={key ?? ChartKeys.Vehicles}
        isDisabled={isError}
        onChange={onChangeGraphTitle}
      />
      <RadioButtonsGraph
        radioContainerClassName={styles.radioContainer}
        btnsDscArr={getTitleDetectors({
          captions: titleArray.date,
          valuesArr: Object.entries(PERIOD_DICT[graphType]),
        })}
        value={radioValueDate}
        onChange={handleChangeInterval}
        onClick={handleClickBtnInterval}
      />
    </div>
  );
};

export default observer(TitleBtns);
