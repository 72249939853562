import classNames from 'classnames';
import { FC } from 'react';
import { FaUserAlt } from 'react-icons/fa';

import { IUserData } from '../../../../../stores/userDataStore/userDataStore';
import { MessageType } from '../../../../../ts/models/logsPanel.model';
import MessageBlock from '../MessageBlock/MessageBlock';

import PopoverMessage from './PopoverMessage/PopoverMessage';

import styles from './Message.module.scss';

interface IMessage {
  item: MessageType;
  user: N<IUserData>;
}

const Message: FC<IMessage> = ({ item, user }) => {
  const id = item.userId;
  const isMe = user?.id === id;

  const blockRowStyles = classNames({
    [styles.blockRow]: true,
    [styles.isMe]: isMe,
  });

  return (
    <div className={blockRowStyles}>
      <PopoverMessage id={id}>
        <div className={styles.avatar}>
          <FaUserAlt className={styles.icon} size={20} />
        </div>
      </PopoverMessage>
      <MessageBlock isMe={isMe} item={item} />
    </div>
  );
};

export default Message;
