import { Tabs } from 'antd';

import { DETAILED_ITEMS } from './constants/detailedItems';

import styles from './DetailedConstructor.module.scss';

const DetailedConstructor = () => (
  <Tabs
    className={styles.tabsCustom}
    defaultActiveKey="1"
    items={DETAILED_ITEMS}
  />
);

export default DetailedConstructor;
