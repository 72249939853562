import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../../stores/rootStore/rootStore';
import { TrafficLane } from '../../../../../ts/models/constructor.model';
import DetectorGrid from '../../../../DetectorGrid/DetectorGrid';
import useDetectorsPreview from '../../../../DetectorGrid/hooks/useDetectorsPreview';
import {
  DETECTOR_TITLE_STYLE,
  IMG_WRAPPER_STYLE,
  SIZE,
} from '../../constants/constants';

import getStatusColor from './helpers/getStatusColor';

import styles from './DetectorImg.module.scss';

interface DetectorImgProps {
  lane: TrafficLane;
}

const DetectorImg: FC<DetectorImgProps> = ({ lane }) => {
  const { detectors } = rootStore.mapDataStore;
  const { viewStatus } = rootStore.constructorStore;

  const { linkColor, isTransferred, id } = lane;

  const { isLoading, scene, src } = useDetectorsPreview({
    detectors,
    id,
  });

  const statusColor = getStatusColor(viewStatus, linkColor, isTransferred);

  const titleStyle = {
    ...DETECTOR_TITLE_STYLE,
    borderLeft: `5px solid ${statusColor}`,
  };

  if (isLoading) {
    return (
      <div className={styles.spinWrapper} style={IMG_WRAPPER_STYLE}>
        <Spin size="large" />
      </div>
    );
  }

  if (!src) {
    return (
      <h3 style={{ ...IMG_WRAPPER_STYLE, ...titleStyle }}>
        Изображение с детектора № {id} отсутствует.
      </h3>
    );
  }

  return (
    <DetectorGrid
      title={`Изображение с детектора № ${id}:`}
      titleStyle={titleStyle}
      width={SIZE.width}
      src={src}
      scene={scene}
      wrapperStyle={IMG_WRAPPER_STYLE}
    />
  );
};

export default observer(DetectorImg);
