import Map from 'ol/Map';
import Overlay from 'ol/Overlay';
import { useEffect, useState } from 'react';

import useRefs from '../../../hooks/useRefs';
import createOverlays from '../helpers/createOverlays';

const useOverlays = (map: N<Map>, amount: number) => {
  const refs = useRefs(amount);
  const [popups, setPopups] = useState<Overlay[]>([]);

  useEffect(() => {
    setPopups(createOverlays({ elements: refs }));
  }, [refs]);

  useEffect(() => {
    map && popups.forEach((el) => map.addOverlay(el));
  }, [popups, map]);

  return {
    refs,
    popups,
  };
};

export default useOverlays;
