import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';

import { TMap } from '../../../../../stores/mapStore/mapStore.model';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { System } from '../../../../../ts/enums/enums';
import NoData from '../../../../ui-kit/Errors/NoData/NoData';
import CrossroadSchemaWrapper from '../CrossroadSchemaWrapper/CrossroadSchemaWrapper';

import { getTLInfo } from './helpers/getTLInfoByDt';
import InfoBtn from './InfoBtn/InfoBtn';
import MapWrapper from './MapWrapper/MapWrapper';

import styles from './CrossroadWrapper.module.scss';

const ERROR_SCHEMA = 'Изображение перекрестка отсутствует';

const CrossroadWrapper = () => {
  const { setDetailedValue, isMapLoading } = rootStore.detailedStore;
  const { infoData } = rootStore.uiStore;
  const { activeDtId, setMapPropsDetailed } = rootStore.detailedStore;

  const [map, setMap] = useState<TMap>(null);

  const isTL = infoData?.system === System.Lights;
  const dtId = isTL ? activeDtId : infoData?.id;

  const { tlId, coord, udsInfo } = getTLInfo(dtId ?? null);

  const setIsLoading = useCallback(
    (value: boolean) => setDetailedValue('isMapLoading', value),
    [setDetailedValue]
  );

  if (!dtId || !coord)
    return <NoData isNeedBackground={false} textError={ERROR_SCHEMA} />;

  return (
    <div className={styles.container}>
      <MapWrapper
        map={map}
        setMap={setMap}
        setMapProps={setMapPropsDetailed}
        centerCoord={coord}
        isMapLoading={isMapLoading}
        setIsLoading={setIsLoading}
      />
      <CrossroadSchemaWrapper tlId={tlId} map={map} dtId={dtId} />
      <InfoBtn pointUdsInfo={udsInfo} />
    </div>
  );
};

export default observer(CrossroadWrapper);
