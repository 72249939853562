import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import rootStore from '../../../stores/rootStore/rootStore';
import handleDeviceId from '../helpers/handleDeviceId';
import handlePanel from '../helpers/handlePanel';

const useInitSearchParams = () => {
  const { isDevicesFetched, systemsArray } = rootStore.mapDataStore;
  const { regionData } = rootStore.uiStore;

  const [searchParams] = useSearchParams();

  const [isInit, setIsInit] = useState(true);

  useEffect(() => {
    if (!isDevicesFetched) return;
    const paramsRegionId = searchParams.get('regionId');
    const regionId = regionData?.id.toString() ?? '';

    setIsInit(false);

    if (regionId !== paramsRegionId) return;
    handleDeviceId(searchParams, systemsArray);
    handlePanel(searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDevicesFetched]);

  return isInit;
};

export default useInitSearchParams;
