import { Radio } from 'antd';
import { FC } from 'react';

import { getObjectEntries } from '../../../../../helpers/getObjectEntries';
import { GRAPHS_TITLE } from '../../../../TrafficLightDetailed/TlDetectors/constants/constants';
import { CHART_NAMES, ChartBDKeys } from '../Chart/constants/constants';

import GraphButtons, { GraphButtonsProps } from './GraphButtons/GraphButtons';

import styles from './RadioButtons.module.scss';

interface RadioButtonsProps {
  radioValue: ChartBDKeys;
  setRadioValue: SetState<ChartBDKeys>;
  labelProps: GraphButtonsProps;
}

const RadioButtons: FC<RadioButtonsProps> = ({
  radioValue,
  setRadioValue,
  labelProps,
}) => {
  return (
    <div className={styles.container}>
      <Radio.Group
        onChange={(e) => setRadioValue(e.target.value)}
        value={radioValue}
        className={styles.radioGroup}
        buttonStyle="solid"
      >
        {getObjectEntries(GRAPHS_TITLE).map(([key, { value }]) => (
          <Radio.Button value={CHART_NAMES[key]} key={key}>
            {value}
          </Radio.Button>
        ))}
      </Radio.Group>
      <GraphButtons {...labelProps} />
    </div>
  );
};

export default RadioButtons;
