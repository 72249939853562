import { useState, useEffect } from 'react';

import mediaApi from '../../../api/media/mediaApi';
import cacheStore from '../../../stores/cacheStore/cacheStore';
import rootStore from '../../../stores/rootStore/rootStore';
import { CrossroadData, CrossroadSvgArgs } from '../models/crossroad.model';

const useFetchCrossroadSvg = ({
  id,
  isNotify = true,
  appPath,
}: CrossroadSvgArgs) => {
  const { isConstructor, setConstructorData } = rootStore.constructorStore;
  const { setCachedImages, cachedImages } = cacheStore;

  const [data, setData] = useState<CrossroadData>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (!id) return;
      const cache = cachedImages[`crossroad${id}`];

      if (cache) {
        isConstructor && setConstructorData('isCrossroadLoaded', true);

        return setData(cache);
      }
      setIsLoading(true);

      const res = await mediaApi.fetchCrossroad(id, appPath, isNotify);

      setData(res ?? null);
      res && setCachedImages(`crossroad${id}`, res);
      setIsLoading(false);
      isConstructor && setConstructorData('isCrossroadLoaded', Boolean(res));
    })();

    return () => {
      setData(null);
      setConstructorData('isCrossroadLoaded', false);
    };
  }, [id, setConstructorData]); // eslint-disable-line

  return { data, isLoading };
};

export default useFetchCrossroadSvg;
