/* eslint-disable jsx-a11y/no-static-element-interactions */
import classNames from 'classnames';
import { uniqueId } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { ReactComponent as DirFromCamera } from '../../../assets/icons/cameras_dir/dir_from_camera.svg';
import { ReactComponent as DirOnCamera } from '../../../assets/icons/cameras_dir/dir_on_camera.svg';
import rootStore from '../../../stores/rootStore/rootStore';
import { LaneDirectionKey } from '../../Constructor/TrafficLaneConstructor/TLaneConstructor.model';
import {
  ALL,
  FilteredTypes,
  FilterValues,
} from '../../TrafficLightDetailed/TlDetectors/constants/constants';
import { checkIsLaneActive } from '../../ui-kit/Chart/ButtonsPanel/FilterButtons/FilterGroup/helpers/checkIsLaneActive';

import { checkIsActiveDir } from './helpers/checkIsActiveDir';
import getStyles from './helpers/getStyles';
import LaneNumber from './LaneNumber/LaneNumber';
import { TLanesDetailedProps } from './TLanesBlock.model';

import styles from './TLanesBlockDetailed.module.scss';

const { ToCamera } = LaneDirectionKey;
const { Lanes, Directions } = FilteredTypes;

const TLanesBlockDetailed: FC<Required<TLanesDetailedProps>> = ({
  trafficLane,
  activeDirs,
  rate,
  handleClickLane,
  directionKey,
}) => {
  const { disabledChartTypes } = rootStore.detailedStore;
  const { toCamera, fromCamera } = trafficLane;

  const isToCamera = directionKey === ToCamera;

  const laneDirection = (isToCamera ? toCamera : fromCamera) ?? trafficLane;

  const { angle } = laneDirection;

  const { laneStyle, mainLaneStyle, barStyles, wrapperStyle, laneStyles } =
    getStyles({ trafficLane, isMain: false, rate, laneDirection });

  const dataIcon = isToCamera ? <DirOnCamera /> : <DirFromCamera />;
  const isActiveDir = checkIsActiveDir(activeDirs, directionKey);
  const isDisabledLanes = disabledChartTypes.lanes;

  const generalContainerStyle = classNames({
    [styles.generalBlock]: true,
    [styles.activeAll]: isActiveDir,
  });

  const laneContainerStyle = classNames(styles.lane, {
    [styles.disabled]: isDisabledLanes,
  });

  return (
    <div className={styles.wrapper} style={wrapperStyle}>
      {laneStyles.map((barStyle) => {
        const isActiveLane = checkIsLaneActive(
          Lanes,
          barStyle.id,
          Directions,
          FilterValues[directionKey]
        );

        return (
          <div className={styles.bar} style={barStyle} key={uniqueId('bar_')}>
            <div
              className={laneContainerStyle}
              style={laneStyle}
              onClick={
                !isDisabledLanes
                  ? () => handleClickLane(barStyle.id, directionKey)
                  : undefined
              }
            />
            <LaneNumber
              angle={angle}
              data={barStyle.id}
              isActiveLane={isActiveLane}
              rate={rate}
            />
          </div>
        );
      })}

      {barStyles.length > 1 && (
        <div
          className={generalContainerStyle}
          style={mainLaneStyle}
          onClick={() => handleClickLane(ALL, directionKey)}
        >
          <LaneNumber
            angle={angle}
            data={dataIcon}
            isIco
            isGeneral
            rate={rate}
          />
        </div>
      )}
    </div>
  );
};

export default observer(TLanesBlockDetailed);
