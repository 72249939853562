import dayjs, { Dayjs, ManipulateType } from 'dayjs';

import { getTimeWithTimeZone } from '../../../../TrafficLightDetailed/TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeWithTimeZone';
import { setRange } from '../../../../TrafficLightDetailed/TlManagement/PhaseHold/helpers/setRange';

export interface IOptions {
  countPast?: N<number>;
  typePast?: ManipulateType;
  countFeature?: N<number>;
  typeFeature?: ManipulateType;
  isNeedFormatted?: boolean;
  startDisabledDate?: Dayjs;
}

type TDisabledDateFunc = (current: Dayjs, options?: IOptions) => boolean;

/**
 * @param {DayjS}  current - DayjS param of all dates on Picker.
 * @param {IOptions} [options = {}] - OptionalParam
 * @param {number | null} [options.countPast] - how long to block Past. Need be Positive. Default 1. Use null to not disabled Past
 * @param {ManipulateType} [options.typePast] - timeType of block Past. Default year.
 * @param {number | null} [options.countFeature] - how long to block Feature. Need be Positive. Default 0. Use null to not disabled Past
 * @param {ManipulateType} [options.typeFeature] - timeType of block Feature. Default day.
 */

export const onDisabledDate: TDisabledDateFunc = (
  current,
  {
    countPast = 1,
    typePast = 'year',
    countFeature = 0,
    typeFeature = 'day',
    isNeedFormatted = true,
    startDisabledDate = dayjs(),
  } = {}
) => {
  const dateNowTZ = getTimeWithTimeZone({
    dateJS: startDisabledDate,
  }).dateFormattedJS;
  const dateNow = (
    isNeedFormatted ? dateNowTZ : startDisabledDate.locale('ru')
  ).startOf('day');
  const currentDate = current.startOf('day');

  const dateAgo =
    countPast !== null ? dateNow.add(Math.abs(countPast) * -1, typePast) : null;
  const dateFeature =
    countFeature !== null
      ? dateNow.add(Math.abs(countFeature), typeFeature)
      : null;

  const isPastDate = (currentDate.diff(dateAgo) || 0) < 0;
  const isFutureDate = (currentDate.diff(dateFeature) || 0) > 0;

  return isFutureDate || isPastDate;
};

type Hour = IntRange<0, 25>;
type Minute = IntRange<0, 61>;
type Second = Minute;

type TimeArr<Time> = [Time, Time] | [];

export interface DisabledTimePickerProps {
  h?: TimeArr<Hour>;
  m?: TimeArr<Minute>;
  s?: TimeArr<Second>;
}

const INITIAL_TIME: DisabledTimePickerProps = {
  h: [],
  m: [],
  s: [],
};

export const onDisabledTime = ({
  h,
  m,
  s,
}: DisabledTimePickerProps = INITIAL_TIME) => {
  return {
    disabledHours: () => (h?.length ? setRange(h[0], h[1]) : []),
    disabledMinutes: () => (m?.length ? setRange(m[0], m[1]) : []),
    disabledSeconds: () => (s?.length ? setRange(s[0], s[1]) : []),
  };
};
