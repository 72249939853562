import { ExclamationCircleFilled } from '@ant-design/icons';
import { App } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, MouseEventHandler, useCallback, useRef } from 'react';
import { AiOutlineFileUnknown } from 'react-icons/ai';
import {
  BsFiletypeDoc,
  BsFiletypeJpg,
  BsFiletypePdf,
  BsFiletypePng,
  BsFiletypeTxt,
  BsFiletypeXls,
} from 'react-icons/bs';

import { filesApi } from '../../../../../api/files/files';
import downloadFile from '../../../../../helpers/downloadFile';
import rootStore from '../../../../../stores/rootStore/rootStore';
import useResize from '../../../../NewSignalProgram/hooks/useResize';
import Title from '../../../../ui-kit/Title/Title';
import { FileListItem } from '../../../models/documents.model';

import BtnsBlock from './BtnsBlock/BtnsBlock';

import styles from './DocumentsItem.module.scss';

interface IDocumentsItem {
  file: FileListItem;
}

type ButtonEvent = MouseEventHandler<HTMLAnchorElement> &
  MouseEventHandler<HTMLButtonElement>;

const DELETE_DSC =
  'После выполнения операции доступ к файлу будет утерян навсегда!';

const TITLE_LENGTH_DEFAULT = 30;
const LEN_CORRECTION = 20;

const ICONS: Record<string, JSX.Element> = {
  pdf: <BsFiletypePdf />,
  doc: <BsFiletypeDoc />,
  docx: <BsFiletypeDoc />,
  jpg: <BsFiletypeJpg />,
  xlsx: <BsFiletypeXls />,
  xls: <BsFiletypeXls />,
  txt: <BsFiletypeTxt />,
  png: <BsFiletypePng />,
  none: <AiOutlineFileUnknown />,
};

const DocumentsItem: FC<IDocumentsItem> = ({ file }) => {
  const { modal } = App.useApp();
  const { setDocumentsKeysValues, controller, activeId } =
    rootStore.documentsStore;
  const { isPanel } = rootStore.uiStore;

  const wrapRef = useRef<HTMLLIElement>(null);
  const { width } = useResize({ wrapRef, isPanel });

  const { profile_id: id, fileAttributes, name } = file.profile;
  const titleLen = Math.round(width / LEN_CORRECTION) || TITLE_LENGTH_DEFAULT;
  const isActive = id === activeId;

  const getFile = useCallback(
    async (option?: 'return') => {
      controller.abort();
      const newController = new AbortController();

      setDocumentsKeysValues({
        isLoading: true,
        controller: newController,
        viewFile: null,
      });

      const res = await filesApi.fetchFile(
        id,
        'tl.detailed.documents',
        newController
      );

      if (!res) return;

      setDocumentsKeysValues({
        viewFile: {
          file: res.data,
          type: res.headers['content-type'],
          id,
          attributes: fileAttributes,
        },
        isLoading: false,
      });

      if (option) return res;
    },
    [controller, fileAttributes, id, setDocumentsKeysValues]
  );

  const deleteFileById = useCallback(async () => {
    const res = await filesApi.deleteFile(id, 'tl.detailed.documents');

    if (!res) return;

    setDocumentsKeysValues({
      isUpdate: true,
      isLoading: false,
    });
  }, [id, setDocumentsKeysValues]);

  const showConfirm = () => {
    modal.confirm({
      title: `Вы хотите удалить ${name}`,
      icon: <ExclamationCircleFilled />,
      content: DELETE_DSC,
      onOk() {
        setDocumentsKeysValues({
          isLoading: true,
          activeId: null,
          viewFile: null,
        });
        deleteFileById();
      },
      okText: 'Удалить',
      okButtonProps: {
        danger: true,
      },
    });
  };

  const handleDeleteFile: ButtonEvent = (e) => {
    e.stopPropagation();
    showConfirm();
  };

  const handleDownLoad: ButtonEvent = async (e) => {
    e.stopPropagation();
    const { setDocumentsKeysValues, viewFile } = rootStore.documentsStore;

    if (viewFile) {
      return downloadFile(
        viewFile?.file,
        `${viewFile?.attributes.name}.${viewFile.attributes.extension}`
      );
    }

    const res = await getFile('return');

    if (!res) return setDocumentsKeysValues({ isLoading: false });

    downloadFile(
      res.data,
      `${fileAttributes.name}.${fileAttributes.extension}`
    );
  };

  const handleClick = () => {
    setDocumentsKeysValues({ activeId: id });
    getFile();
  };

  const elementStyles = classNames({
    [styles.wrapper]: true,
    [styles.isActive]: isActive,
  });

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <li className={elementStyles} onClick={handleClick} ref={wrapRef}>
      <div className={styles.titleBlock}>
        <div className={styles.icon}>
          {ICONS[fileAttributes.extension] || ICONS['none']}
        </div>
        <Title
          title={name}
          maxLength={titleLen}
          titleAddition={'.' + fileAttributes.extension}
        />
      </div>
      <div className={styles.block}>
        <BtnsBlock
          handleDeleteFile={handleDeleteFile}
          handleDownLoad={handleDownLoad}
          isActive={isActive}
          sizeFile={fileAttributes.size}
        />
      </div>
    </li>
  );
};

export default observer(DocumentsItem);
