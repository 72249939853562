import { uniqueId } from 'lodash';
import { observer } from 'mobx-react-lite';
import { CSSProperties, FC, useMemo } from 'react';

import { DEFAULT_CIRCLE } from '../../../../constants/constructorConstants';
import useSwitchers from '../../../../hooks/useSwitchers';
import rootStore from '../../../../stores/rootStore/rootStore';
import { CircleElEnum } from '../../../../ts/enums/tl';
import { TactsImagesEl, TLTact } from '../../../../ts/models/tl.model';
import getSvg from '../../helpers/getSvg';
import sortByCrosswalk from '../../helpers/sortByCrosswalk';
import PhaseCircleImgWrapper from '../../PhaseCircleImgWrapper/PhaseCircleImgWrapper';

const { STROKE_WIDTH } = DEFAULT_CIRCLE;
const { Crosswalk } = CircleElEnum;

interface PhaseCircleImagesProps {
  data: N<TactsImagesEl[]>;
  isBlinkingAnimation: boolean;
  tact: TLTact;
}

const PhaseCircleImages: FC<PhaseCircleImagesProps> = ({
  data,
  isBlinkingAnimation,
  tact,
}) => {
  const { calcCircleVal } = rootStore.pointsUdsStore;

  const { direction_green_in_phase: greenDirs } = tact;

  const circleData = useMemo(() => data && sortByCrosswalk(data), [data]);

  const opacityAnimations = useMemo(
    () => [
      isBlinkingAnimation,
      ...(circleData?.map((d) => d.animation?.opacity) ?? []),
    ],
    [circleData, isBlinkingAnimation]
  );

  const colorAnimations = useMemo(
    () => circleData?.map((d) => d.animation?.color),
    [circleData]
  );

  const [isBlinking, ...opacitySwitchers] = useSwitchers(opacityAnimations);

  const colorSwitchers = useSwitchers(colorAnimations);
  const isBlinkingStatus = isBlinkingAnimation ? !isBlinking : false;

  if (!circleData || !opacityAnimations) return null;

  return (
    <>
      {circleData.map((item, i) => {
        const {
          type,
          color,
          angle,
          size,
          strokeWidth,
          dash,
          positionXY,
          direction,
        } = item;

        const dashArr = dash?.dashArray;
        const imgId = uniqueId(`${type}${i}_`);
        const isCrosswalk = type === Crosswalk;
        const side = calcCircleVal(size);

        const [offsetX, offsetY] = positionXY.map((offset) =>
          calcCircleVal(offset)
        );

        const isDefaultColor =
          opacityAnimations[i] ||
          colorSwitchers[i] === undefined ||
          colorSwitchers[i];

        const imgWrapperStyle = {
          top: offsetY,
          left: offsetX,
          width: side * (dash?.sizeRate ?? 1),
          height: side * (dash?.sizeRate ?? 1),
          transform: isCrosswalk
            ? `translate(-50%,-50%) rotate(${angle}deg)`
            : '',
        };

        const elementStyle: CSSProperties = {
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: side,
          height: side,
          strokeWidth: strokeWidth ?? STROKE_WIDTH,
          strokeDasharray: dashArr ? `${dashArr[0]} ${dashArr[1]}` : '',
          color: isDefaultColor ? color ?? 'green' : 'red',
          opacity: opacityAnimations[i + 1] ? Number(opacitySwitchers[i]) : 1,
          transform: `translate(-50%,-50%) ${
            !isCrosswalk ? `rotate(${angle}deg)` : ''
          }`,
        };

        const isGreen = Boolean(direction && greenDirs?.includes(direction));

        if (isBlinkingStatus && !isGreen) return null;

        return (
          <PhaseCircleImgWrapper style={imgWrapperStyle} key={imgId}>
            {getSvg(type, elementStyle)}
          </PhaseCircleImgWrapper>
        );
      })}
    </>
  );
};

export default observer(PhaseCircleImages);
