import { cloneDeep } from 'lodash';

import { ALL } from '../../../../components/TrafficLightDetailed/TlDetectors/constants/constants';
import { FilterValue } from '../../detailedStore.model';

export const getFilteredValue = (
  arr: N<number[]>,
  value: Exclude<FilterValue, 'default'>
) => {
  const cloneArr = cloneDeep(arr);

  const isOffAll = value === ALL && cloneArr?.length === 0;

  if (!value || isOffAll) return null;

  if (value === ALL) return [];
  if (!cloneArr) return [value];

  const isNeedOff = cloneArr.some((el) => el === value);

  if (isNeedOff) {
    const filteredValues = cloneArr?.filter((el) => el !== value);

    return filteredValues?.length ? filteredValues : null;
  }

  cloneArr.push(value);

  return cloneArr;
};
