import { notification as antdNotify } from 'antd';
import { isString } from 'lodash';

import NOTICE, {
  NotifyTextKeys,
  isNoticeKey,
} from '../../../constants/notificationConstants';

interface NotifyConfig {
  message: string;
  description: string;
  duration?: number;
}

interface NotificationOptions {
  message?: string;
  dsc?: string;
  key?: string;
}

const getMessageConfig = (
  messageName: NotifyTextKeys,
  options: U<NotificationOptions>
) => {
  const config = NOTICE[messageName];

  if (isString(config)) {
    const message = isNoticeKey(options?.message) ? config : options?.message;

    return {
      message: message ?? config,
      description: options?.dsc,
      key: options?.key ?? messageName,
    };
  }

  return {
    message: options?.message ?? config.message,
    description: options?.dsc ?? config.description,
    duration: (config as NotifyConfig).duration,
    key: options?.key ?? messageName,
  };
};

type NotifyProps = (
  message: NotifyTextKeys,
  options?: NotificationOptions
) => void;
type NotifyKeys = 'info' | 'error' | 'success' | 'warning' | 'destroy';

const notification: Record<NotifyKeys, NotifyProps> = {
  info: (messageName, options) =>
    antdNotify.info(getMessageConfig(messageName, options)),
  error: (messageName, options) =>
    antdNotify.error(getMessageConfig(messageName, options)),
  success: (messageName, options) =>
    antdNotify.success(getMessageConfig(messageName, options)),
  warning: (messageName, options) =>
    antdNotify.warning(getMessageConfig(messageName, options)),
  destroy: (messageKey) => antdNotify.destroy(messageKey),
};

export default notification;
