import { isNumber } from 'lodash';

import { TlManagementKeys } from '../../../../../constants/commands';
import { findById } from '../../../../../helpers/findBy';
import { getTLModeName } from '../../../../../helpers/statusHelpers';
import rootStore from '../../../../../stores/rootStore/rootStore';
import {
  REMOTE_CONTROL_NAME,
  TABS_CHILDREN,
} from '../constants/controlConstants';
import TabLabel from '../TabLabel/TabLabel';

export const getTabpanesManagment = (id: number) => {
  const { tls } = rootStore.mapDataStore;

  const mode = findById(tls, id)?.tlStatus.mode;

  const name = isNumber(mode) ? getTLModeName(mode) : null;

  const items = Object.values(TlManagementKeys);

  return items.map((controlKey) => {
    const Item = TABS_CHILDREN[controlKey];

    const accessCommands = REMOTE_CONTROL_NAME[controlKey];

    const isDisabled = accessCommands?.every((cmd) => cmd !== name) ?? false;

    return {
      label: <TabLabel controlKey={controlKey} />,
      key: controlKey,
      children: <Item id={id} />,
      disabled: isDisabled,
    };
  });
};
