import VectorImageLayer from 'ol/layer/VectorImage';
import VectorSource from 'ol/source/Vector';

import { TMap } from '../../../stores/mapStore/mapStore.model';
import { Z_INDEX_LAYER } from '../../constants/map';

const createTemporaryLayer = (
  map: TMap,
  id: string,
  zIndex?: number
): VectorImageLayer<VectorSource> | undefined => {
  if (!map) {
    return;
  }

  const existingVectorLayer = map
    .getAllLayers()
    .find(
      (element) =>
        element.get('id') === id && element instanceof VectorImageLayer
    );

  if (existingVectorLayer && existingVectorLayer instanceof VectorImageLayer) {
    return existingVectorLayer;
  }

  const newVectorLayer: VectorImageLayer<VectorSource> = new VectorImageLayer({
    source: new VectorSource({ features: [] }),
  });

  newVectorLayer.set('id', id);

  newVectorLayer.setZIndex(zIndex || Z_INDEX_LAYER);

  map.addLayer(newVectorLayer);

  return newVectorLayer;
};

export { createTemporaryLayer };
