import { Feature } from 'ol';
import { Geometry, LineString, Polygon, Point } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';

import {
  formatArea,
  formatLength,
  formatPoint,
} from '../../../components/Map/helpers/handleMeasuring';
import { NAMING } from '../../../constants/measurementsConstants';
import { TMap } from '../../../stores/mapStore/mapStore.model';

export interface IMeasurementResult {
  id: string | number;
  type: string;
  valueView: string;
  geometry: Geometry | undefined;
  feature: Feature;
}

export const getMeasurements = (map: TMap) => {
  if (!map) {
    return [];
  }

  const layer = map
    .getLayers()
    .getArray()
    .find(
      (element) =>
        element.get(NAMING.LAYER_PROPERTY_NAME) ===
        NAMING.DEFAULT_MEASURING_LAYER_NAME
    );

  if (!layer) {
    return [];
  }

  if (!(layer instanceof VectorLayer)) {
    return [];
  }

  const source = layer.getSource();
  const features = source?.getFeatures() || [];

  const items: IMeasurementResult[] = features
    .map((feature: Feature, index: number) => {
      const geometry = feature.getGeometry();

      if (!geometry) {
        return undefined;
      }

      const type = geometry?.getType() || 'Point';

      let valueView = '';

      if (geometry instanceof Point) {
        valueView = formatPoint(geometry);
      }

      if (geometry instanceof LineString) {
        valueView = formatLength(geometry);
      }

      if (geometry instanceof Polygon) {
        valueView = formatArea(geometry);
      }

      const element: IMeasurementResult = {
        id: index + 1,
        type,
        valueView,
        geometry,
        feature,
      };

      return element;
    })
    .filter((item: IMeasurementResult) => !!item);

  return items;
};

export const removeMeasurementFeature = (map: TMap, feature: Feature) => {
  if (!map) {
    return;
  }

  const layer = map
    .getLayers()
    .getArray()
    .find(
      (element) =>
        element.get(NAMING.LAYER_PROPERTY_NAME) ===
        NAMING.DEFAULT_MEASURING_LAYER_NAME
    );

  if (!layer) {
    return;
  }

  if (!(layer instanceof VectorLayer)) {
    return;
  }

  const source = layer.getSource();

  if (!source) {
    return;
  }

  source.removeFeature(feature);
};
