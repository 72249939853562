import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../../stores/rootStore/rootStore';
import Popover from '../../../../ui-kit/Popover/Popover';

import styles from './EditButton.module.scss';

interface EditButtonProps {
  handleClickEdit: () => void;
}

const EditButton: FC<EditButtonProps> = ({ handleClickEdit }) => {
  const { interfaceTipsDelay } = rootStore.uiStore;
  const { isActiveControlBtn } = rootStore.detailedStore;

  const popoverProps = {
    content: 'Обязательное поле для заполнения',
    placement: 'right',
    tipsDelay: interfaceTipsDelay,
  } as const;

  return (
    <Popover
      {...popoverProps}
      content={
        isActiveControlBtn
          ? 'Редактировать текущий план?'
          : 'Необходимо активировать команды управления'
      }
    >
      <Button
        type="primary"
        onClick={handleClickEdit}
        disabled={!isActiveControlBtn}
        className={styles.btn}
      >
        Редактировать
      </Button>
    </Popover>
  );
};

export default observer(EditButton);
