import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { sliceTitleBy } from '../../../../helpers/sliceTitle';
import rootStore from '../../../../stores/rootStore/rootStore';
import { Events, System } from '../../../../ts/enums/enums';
import {
  isDeviceStatus,
  isMapObj,
  isSystem,
  isTL,
} from '../../../../ts/models/mapObject.model';
import IcoDevice from '../../../IcoDevice/IcoDevice';
import Popover, { PopoverProps } from '../../Popover/Popover';
import { DataType } from '../Accordion';

import PopoverContent from './PopoverContent/PopoverContent';

import styles from './AccordionItem.module.scss';

const MAX_LENGTH = 35;

interface AccordionItemProps {
  mapObject: DataType;
  activeId: U<number>;
  system: System | Events;
  addToRefs: (ele: N<HTMLElement>, id: number) => void;
  onClick: (id: number) => void;
}

const AccordionItem: FC<AccordionItemProps> = ({
  mapObject,
  activeId,
  system,
  addToRefs,
  onClick,
}) => {
  const { interfaceTipsDelay } = rootStore.uiStore;
  const { id, deviceInfo, disabledType } = mapObject;

  const isDisabled = !!disabledType;
  const isNeedCrop = deviceInfo.caption.length > MAX_LENGTH;

  const statusDevice = isDeviceStatus(deviceInfo.deviceStatusId)
    ? deviceInfo.deviceStatusId
    : undefined;

  const mode = isTL(mapObject) ? mapObject.tlStatus?.mode : undefined;

  const isStatus = isSystem(system) && isMapObj(mapObject);

  const isShowPopover = isDisabled || isStatus || isNeedCrop;

  const popoverProps: Omit<PopoverProps, 'children'> = {
    tipsDelay: isShowPopover ? interfaceTipsDelay : null,
    placement: 'right',
    content: (
      <PopoverContent
        disabledType={disabledType}
        mapObject={mapObject}
        system={system}
      />
    ),
  };

  const btnStyle = classNames({
    [styles.disabled]: isDisabled,
    [styles.activeRaw]: activeId === id && !isDisabled,
  });

  return (
    <Popover {...popoverProps}>
      <li className={styles.container}>
        <button
          className={classNames(styles.block, btnStyle)}
          id={id.toString()}
          ref={(ele) => {
            addToRefs(ele, id);
          }}
          onClick={() => onClick(id)}
          disabled={isDisabled}
        >
          <span
            id={`idx ${id}`}
            className={classNames(styles.idBlock, btnStyle)}
          >
            {id}
          </span>

          <p>{sliceTitleBy(deviceInfo.caption, MAX_LENGTH)}</p>

          {isStatus && (
            <div className={styles.status}>
              <IcoDevice
                system={system}
                iconStyle={styles.icon}
                statusDevice={statusDevice}
                mode={mode}
              />
            </div>
          )}
        </button>
      </li>
    </Popover>
  );
};

export default observer(AccordionItem);
