import { useEffect, useState } from 'react';

import { PANEL_TRANSITION_TIME } from '../../../stores/uiStore/uiStore';
import { PanelContent } from '../InfoPanelWithContext';

import usePanelData from './usePanelData';

const usePanelLogic = <T extends PanelContent>(panelContent: T) => {
  const [prevPanelContent, setPrevPanelContent] = useState<N<T>>(null);

  const { panelContent: panelContentContext, setPanelContent } =
    usePanelData<T>();

  useEffect(() => {
    if (prevPanelContent && !panelContent) {
      const timeoutId = setTimeout(() => {
        setPanelContent(panelContent);
      }, PANEL_TRANSITION_TIME);

      return () => {
        clearTimeout(timeoutId);
      };
    }

    setPanelContent(panelContent);
    setPrevPanelContent(panelContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelContent]);

  return { panelContentContext };
};

export default usePanelLogic;
