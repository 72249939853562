import { TableConfigSettings } from '../ts/models/table.model';

export const TABLE_CONFIG_SETTINGS: TableConfigSettings = {
  height: {
    large: 1080,
    middle: 703,
  },
  users: {
    large: 10,
    middle: 7,
  },
  groups: {
    large: 4,
    middle: 3,
  },
  settings: {
    large: 5,
    middle: 5,
  },
  fullScreen: {
    large: 13,
    middle: 10,
  },
  statusTable: {
    large: 10,
    middle: 7,
    scrollX: 1350,
    scrollY: 550,
    defaultPageSize: 15,
  },
};

export const CELLS_WIDTH = {
  xs: 150,
  s: 200,
};

export const DEFAULT_SCROLL = {
  X: 1350,
  Y: 500,
};

export const DEFAULT_PAGES = 10;
