import classNames from 'classnames';
import { FC } from 'react';

import styles from './InfoBox.module.scss';

interface InfoBoxProps {
  title: string;
  children: JSX.Element;
  className?: string;
}

const InfoBox: FC<InfoBoxProps> = ({ title, children, className = '' }) => {
  const divStyles = classNames({
    [styles.infoBox]: true,
    [className]: !!className,
  });

  return (
    <div className={divStyles}>
      <h3>{title}</h3>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default InfoBox;
