import { isUndefined } from 'lodash';
import { FC } from 'react';

import { TMeteo } from '../../../../../ts/models/MapObjects/meteo.model';

import { getMeteoInfo, TValueMeteo } from './helpers/getMeteoInfo';
import MeteoItem from './MeteoItem/MeteoItem';

import styles from './MeteoInfo.module.scss';

interface IMeteoInfoProps {
  meteo: TMeteo;
}

const MeteoInfo: FC<IMeteoInfoProps> = ({ meteo }) => {
  const meteoInfo = getMeteoInfo(meteo.data);

  if (!meteoInfo) return null;

  const { envTemp, weather, roadGrip } = meteoInfo;

  const meteoInfoValues = Object.values({
    envTemp,
    weather,
    roadGrip,
  }).filter((el) => !isUndefined(el)) as NonNullable<TValueMeteo>[];

  return (
    <>
      <div className={styles.container}>
        {meteoInfoValues.map(({ id, ...info }) => (
          <MeteoItem key={id} {...info} isVertical />
        ))}
      </div>
    </>
  );
};

export default MeteoInfo;
