import {
  API_PATH,
  MEDIA,
  PHASE_IMG_FOLDER,
  REGION,
  SIGN1,
} from '../../../../../constants/constants';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { IMG_FOLDER_PATH } from '../constants/constants';

const getPhaseImagesFolder = () => {
  const { isNewPhaseImages } = rootStore.constructorStore;
  const { regionData } = rootStore.uiStore;

  if (isNewPhaseImages) {
    const id = regionData?.id + '/';

    return {
      path: PHASE_IMG_FOLDER + REGION + id,
      fullPath: IMG_FOLDER_PATH + REGION + id,
    };
  }

  const path = MEDIA + SIGN1;

  return {
    path,
    fullPath: API_PATH + path,
  };
};

export default getPhaseImagesFolder;
