import { Dayjs } from 'dayjs';
import { z } from 'zod';

import { PlannedCommandsSchema } from '../../../../api/mapObjects/trafficLights/commands/plannedCommands.zod';
import { COMMANDS, TlManagementKeys } from '../../../../constants/commands';
import { IPhasesData } from '../PhaseHold/PhaseHold.model';

export type TParam = string | number;

export interface IPostCommandProps {
  id: number;
  command: N<number>;
  cmdTitle: COMMANDS;
  cmdInfo: string;
  selectedDate?: N<Dayjs>;
  durationTime?: U<number>;
  phasesValue?: U<N<IPhasesData[]>>;
  disabledTime?: number;
}

export interface IChangePhaseTime {
  phase: number;
  time: number;
  spanTime: number;
}

export interface ICoordManagment extends IChangePhaseTime {
  spanGreenBlinkTime: number;
  spanYellowTime: number;
  spanRedYellowTime: number;
}

export interface IParamsSender {
  param1: TParam;
  param2?: TParam;
  param3?: TParam;
  param4?: TParam;
  jparam1?: N<IChangePhaseTime[] | ICoordManagment[]>;
}

type TParams = Required<IParamsSender>;

interface ICommandsInfo extends TParams {
  cmd: COMMANDS;
  comment: string;
}

export interface IRequestSender {
  commandInfo: ICommandsInfo;
}

export type TCommands = z.infer<
  typeof PlannedCommandsSchema
>['dataRows'][number];

export const isTabControlKey = (key: string): key is TlManagementKeys =>
  Object.values(TlManagementKeys).some((el) => el === key);
