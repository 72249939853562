import { Button, Tabs } from 'antd';
import { useState } from 'react';

import { SECOND } from '../../../../constants/constants';

import { CONTROL_MODEL_TABS } from './constants/constants';

import styles from './ControlPanel.module.scss';

const ControlPanel = () => {
  const [isLoadingData, setIsLoadingData] = useState(false);

  const handleSubmitData = () => {
    setIsLoadingData(true);

    setTimeout(() => {
      setIsLoadingData(false);
    }, SECOND);
  };

  return (
    <div className={styles.container}>
      <h3>Управление моделью:</h3>
      <Tabs defaultActiveKey="1" items={CONTROL_MODEL_TABS} />
      <Button
        type="primary"
        htmlType="submit"
        onClick={handleSubmitData}
        loading={isLoadingData}
        className={styles.btn}
      >
        Запустить
      </Button>
    </div>
  );
};

export default ControlPanel;
