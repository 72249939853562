/* eslint-disable camelcase */
import { VERSION } from '../../../../constants/versions';
import { getAppPathHeaders } from '../../../helpers/getAppPathHeaders';
import http from '../../../http';
import tryCatchWrapper from '../../../tryCatchWrapper';

const SERVER_FUNCTIONS_API = VERSION + '/integration/gis/serverless';

const REQUEST_PARAMS = {
  isExternalError: true,
};

const serverApi = {
  execute: (
    appPath: string,
    moduleId: string,
    methodName: string,
    queryStringParams?: Record<any, any>
  ) =>
    tryCatchWrapper(
      async () => {
        const props = queryStringParams || {};

        const res = await http.get(
          `${SERVER_FUNCTIONS_API}/guid/${moduleId}/${methodName}`,
          {
            headers: getAppPathHeaders(appPath),
            params: {
              ...props,
            },
          }
        );

        return res?.data;
      },
      {
        ...REQUEST_PARAMS,
        errorMessage: 'Ошибка при выполнении серверной функции',
        ignoreCodes: [423],
      }
    ),
};

export default serverApi;
