import { TL_CIRCLE_ZOOM } from '../constants/constants';
import { CircleElsDynamic } from '../ts/models/constructor.model';

const { START, END, STEP } = TL_CIRCLE_ZOOM;

const getZoomRanges = () => {
  const zooms = [];

  for (let i = END + STEP; i >= START; i = i - STEP) {
    zooms.push(i);
  }

  return zooms;
};

export const zooms = getZoomRanges();

const findZoomRangeStart = (
  currentZoom: number,
  circleElsDynamic?: CircleElsDynamic
) =>
  zooms.find((zoom) => {
    const isCircleEls = circleElsDynamic ? circleElsDynamic[zoom] : true;

    return isCircleEls && currentZoom >= zoom;
  });

export default findZoomRangeStart;
