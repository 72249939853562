import { TLDetailedCode } from '../../../ts/enums/userData';
import rootStore from '../../rootStore/rootStore';

const setDataLog = (key: string, data: unknown) => {
  const { isStaticDataLog } = rootStore.uiStore;
  const { hasAccess } = rootStore.userDataStore;

  const isAccess = hasAccess(TLDetailedCode.DevelopTools) && isStaticDataLog;

  isAccess && console.log(key, data);
};

export default setDataLog;
