import { KEY_COMBINATIONS } from '../constants/constants';
import { typeGuard } from '../helpers/typeGuard';

interface IUseKeyCombinationOptions {
  preventDefault: boolean;
  stopPropagation: boolean;
}

export type CallBackKeyCombination = (
  event: KeyboardEvent,
  ...args: any
) => void;

export interface KeyCombinationProps {
  keyCombination: KEY_COMBINATIONS;
  index: number;
  stopChainingIteration: boolean;
  options: IUseKeyCombinationOptions;
}

class KeyCombination {
  combinations: Map<
    KEY_COMBINATIONS,
    Map<CallBackKeyCombination, KeyCombinationProps>
  >;
  pressedRef = new Set<string>([]);
  constructor() {
    this.combinations = new Map();
    document.addEventListener('keydown', this.handleCombinations.bind(this));
    document.addEventListener('keyup', this.handleKeyUp.bind(this));
  }

  addCombination(
    addKeyCombination: KeyCombinationProps,
    callback: CallBackKeyCombination
  ) {
    const keyCombinations = this.combinations.get(
      addKeyCombination.keyCombination
    );

    if (keyCombinations) {
      keyCombinations.set(callback, addKeyCombination);
    } else {
      this.combinations.set(
        addKeyCombination.keyCombination,
        new Map([[callback, addKeyCombination]])
      );
    }
  }

  removeCombination(
    deletionKeyCombination: KeyCombinationProps,
    callback: CallBackKeyCombination
  ) {
    const keyCombinations = this.combinations.get(
      deletionKeyCombination.keyCombination
    );

    keyCombinations?.delete(callback);
  }

  private handleCombinations(event: KeyboardEvent) {
    this.pressedRef.add(event.key);

    const values = this.pressedRef.values();
    const keyCombination = Array.from(values).join('+');

    if (!typeGuard<KEY_COMBINATIONS>(keyCombination, KEY_COMBINATIONS)) {
      return;
    }
    const combinations = this.combinations.get(keyCombination);

    if (!combinations) {
      return;
    }

    const sortCombination = Array.from(combinations.keys()).sort((a, b) => {
      const itemAIndex = combinations.get(a)?.index || 0;
      const itemBIndex = combinations.get(b)?.index || 0;

      return itemAIndex - itemBIndex;
    });

    for (let i = 0; i < sortCombination.length; i++) {
      const callback = sortCombination[i];

      const combination = combinations.get(callback);

      combination?.options.preventDefault && event.preventDefault();
      combination?.options.stopPropagation && event.stopPropagation();

      callback(event);

      if (combination?.stopChainingIteration) {
        break;
      }
    }
  }

  private handleKeyUp(event: KeyboardEvent) {
    this.pressedRef.delete(event.key);
  }
}
export default KeyCombination;
