import {
  DEVICES_INFO,
  DEVICES_STATE,
  TDeviceStatus,
} from '../../../../../../constants/devicesConstants';
import { findBy } from '../../../../../../helpers/findBy';
import { checkDisabledControl } from '../../../../../../stores/mapDataStore/helpers/checkDisabledControl';

export const ONLY_READ_MODE = 'Для данного СО доступен только режим просмотра';
const SPECIAL_MODE =
  'Управление данным СО недоступно, так как он находится в статусе';

const { Undefined } = DEVICES_INFO.STATE;

export const getTooltipContent = (
  isActiveBtn: boolean,
  deviceStatusId: TDeviceStatus,
  isAccessControl: boolean
) => {
  const isSpecialMode = checkDisabledControl(deviceStatusId);

  const actionText = `${
    !isActiveBtn ? 'Активировать' : 'Отключить'
  } команды управления?`;

  const status =
    findBy(DEVICES_STATE, deviceStatusId, 'com')?.dsc ?? Undefined.dsc;

  const specialMode = `${SPECIAL_MODE}: "${status}"`;

  const tooltipContent = !isSpecialMode ? actionText : specialMode;

  const tooltipBtnText = isAccessControl ? tooltipContent : ONLY_READ_MODE;

  return tooltipBtnText;
};
