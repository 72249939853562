import GeneralDtInfo from '../GeneralDtInfo/GeneralDtInfo';
import CrossroadWrapper from '../GeneralDtTabs/CrossroadWrapper/CrossroadWrapper';

export const DETAILED_GENERAL_ITEMS = [
  {
    label: 'Схема перекрестка',
    key: 'crossroad',
    children: <CrossroadWrapper />,
  },
  {
    label: 'Общая информация',
    key: 'generalInfo',
    children: <GeneralDtInfo />,
  },
];
