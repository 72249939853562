import { ColorPicker } from 'antd';
import { Color } from 'antd/lib/color-picker';
import { Fill } from 'ol/style';
import { useState } from 'react';

import styles from '../../LayersButton.module.scss';

import { getColor } from './helpers';

const FillSelector = (props: {
  fill: Fill | null | undefined;
  onChange: (fill: Fill) => void;
}) => {
  const [color, setColor] = useState(getColor(props.fill));

  const updateColor = (value: Color, hex: string) => {
    props.onChange(new Fill({ color: hex }));

    setColor(hex);
  };

  return (
    <>
      <div className={styles.inputTitle}>Цвет заливки объекта</div>
      <ColorPicker value={color} onChange={updateColor} />
    </>
  );
};

export default FillSelector;
