import axios from 'axios';
import dayjs from 'dayjs';
import { toJS } from 'mobx';
import { utils, writeFileXLSX } from 'xlsx';

import { CONFIG } from '../../constants/configConstants';
import { DATE_FORMAT_TO_SHOW } from '../../constants/constants';
import rootStore from '../../stores/rootStore/rootStore';
import { BasicTableColumn } from '../../ts/models/table.model';

const MIN_WIDTH = 10;
const REPORT_SERVER_STATUS_TIMEOUT = 1000;

const getRowsLengthArray = <T extends Record<keyof T, ValueOf<T>>>(
  data: T[]
) => {
  const widths = {} as Record<keyof T, number>;

  data.forEach((item) => {
    const keys = Object.keys(item) as Exclude<keyof T, number>[];

    keys.forEach((key) => {
      const valueLength = String(item[key]).length;

      if (!widths[key] || widths[key] < valueLength) {
        widths[key] = valueLength;
      }
    });
  });

  return Object.keys(widths).map((key) => ({
    wch: Math.max(widths[key as keyof T], key.length, MIN_WIDTH),
  }));
};

const deleteEmptyProperty = <T extends Record<keyof T, ValueOf<T>>>(
  value: T
) => {
  const isNotToTable = (value: ValueOf<T>) => {
    return (
      typeof value !== 'boolean' &&
      typeof value !== 'number' &&
      typeof value !== 'string'
    );
  };

  const keys = Object.keys(value) as Exclude<keyof T, number>[];

  keys.forEach((key) => {
    if (isNotToTable(value[key]) || key === 'key' || key === 'collaboration') {
      delete value[key];
    }
  });
};

const getDataForExport = <T, K extends keyof T>(
  data: T[],
  headers: U<{ title: string; referens: K }[]>
) => {
  if (!headers) return;

  return data.map((dataItem) => {
    const newItem: OWKS<ValueOf<T>> = {};

    headers.forEach((headerItem) => {
      newItem[headerItem.title] = dataItem[headerItem.referens];
    });

    deleteEmptyProperty(newItem);

    return newItem;
  });
};

const getExportDataHeaders = <T extends Record<keyof T, ValueOf<T>>>(
  data: T
) => {
  return Object.keys(data).map((key) => key.toLocaleUpperCase());
};

export const exportFile = <T extends Record<K, ValueOf<T>>, K extends keyof T>(
  data: T[],
  type: string,
  title: string,
  headers?: BasicTableColumn<K>[]
) => {
  const currentData = getDataForExport(toJS(data), headers) ?? data;
  const ws = utils.json_to_sheet(currentData);

  ws['!cols'] = getRowsLengthArray(currentData);
  utils.sheet_add_aoa(ws, [getExportDataHeaders(currentData[0])]);
  const wb = utils.book_new();

  const date = dayjs().locale('ru').format(DATE_FORMAT_TO_SHOW);
  const fileName = `${title}_${date}.${type}`;

  utils.book_append_sheet(wb, ws, title);
  writeFileXLSX(wb, fileName);

  return;
};

export const exportFileService = async <
  T extends Record<K, ValueOf<T>>,
  K extends keyof T
>(
  data: T[],
  type: string,
  title: string,
  headers?: BasicTableColumn<K>[]
) => {
  const { userDataStore } = rootStore;

  const cols = (headers || []).map(
    (item: BasicTableColumn<K>, index: number) => {
      const { title, type, dataIndex } = item;

      return {
        text: title,
        name: dataIndex || `col_${index}`,
        type,
      };
    }
  );

  const rows = data.map((item) => {
    const row = [];

    for (let i = 0; i < cols.length; i++) {
      const col = cols[i];
      const name = col.name || `col_${i}`;

      // @ts-ignore
      const val = item[name];

      const value = val || Number.isFinite(val) ? val : '';

      row.push(value);
    }

    return row;
  });

  const params = {
    metadata: {
      type: 'type.model',
      title,
    },
    data: {
      cols,
      rows,
    },
  };

  try {
    const url =
      `${userDataStore.urlConfigurations?.reportServer}` ||
      CONFIG.REPORT_SERVICE_URL;

    const response = await axios.post(`${url}/reports/generate`, params, {
      responseType: 'blob',
    });

    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');

    link.href = href;

    link.setAttribute('download', `${title}.xlsx`);

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);

    URL.revokeObjectURL(href);
  } catch (e) {
    console.log(e);
  }

  return;
};

export const getReportServerStatus = async () => {
  try {
    const { userDataStore } = rootStore;

    const url =
      `${userDataStore.urlConfigurations?.reportServer}` ||
      CONFIG.REPORT_SERVICE_URL;

    const response = await axios.get(`${url}`, {
      timeout: REPORT_SERVER_STATUS_TIMEOUT,
    });

    return response && response.data?.status;
  } catch (e) {
    console.log(e);

    return false;
  }
};
