import { Checkbox, Form } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { DEFAULT_PHASE_IMG_PATH } from '../../../../constants/constants';
import rootStore from '../../../../stores/rootStore/rootStore';
import ImageGallery from '../../../ui-kit/ImageGallery/ImageGallery';
import Img from '../../../ui-kit/Img/Img';

import getPhaseImagesFolder from './helpers/getPhaseImagesFolder';
import translateCode from './helpers/translateCode';
import InputDeleteBtn from './InputDeleteBtn/InputDeleteBtn';
import UploadBtn from './UploadBtn/UploadBtn';

import styles from './ConstructorPhaseImages.module.scss';

const ConstructorPhaseImages: FC = () => {
  const {
    phases,
    setPhaseImagePath,
    isNewPhaseImages,
    setIsNotConstructor,
    tl: { id },
  } = rootStore.constructorStore;

  const { regionData } = rootStore.uiStore;

  const handleApproach = () => setIsNotConstructor('isNewPhaseImages');

  if (!id) {
    return <h3>Необходимо добавить светофорный объект.</h3>;
  }

  return (
    <>
      <Checkbox
        checked={isNewPhaseImages}
        onChange={handleApproach}
        disabled={isNewPhaseImages}
      >
        Использовать новый подход (рекомендуется). Внимание!!! Опцию возможно
        использовать только один раз!
      </Checkbox>
      <hr />

      <ImageGallery>
        {phases.map(({ image, phaseNumber, code, cache }, i) => {
          const { label, postfix } = translateCode(code, phaseNumber);

          if (!regionData) return null;
          const folder = getPhaseImagesFolder().path;

          const src =
            image === DEFAULT_PHASE_IMG_PATH
              ? DEFAULT_PHASE_IMG_PATH
              : `${folder + id + postfix}.png?cache=${cache}`;

          return (
            <Form.Item
              key={`phaseImg${i}`}
              label={label}
              className={styles.formItem}
            >
              <InputDeleteBtn idx={i} />
              <UploadBtn postfix={postfix} idx={i} />

              <Img
                alt="Изображение по данному пути не найдено"
                src={isNewPhaseImages ? src : `${image}?cache=${cache}`}
                className={styles.img}
                handleError={() => setPhaseImagePath(i)}
              />
            </Form.Item>
          );
        })}
      </ImageGallery>
    </>
  );
};

export default observer(ConstructorPhaseImages);
