import { Drawer } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';

import { COLORS } from '../../../../constants/colorsConstants';
import rootStore from '../../../../stores/rootStore/rootStore';
import {
  IQueryParamRequest,
  QueryParamItem,
} from '../../../../ts/models/table.model';
import { ITable } from '../Table';

import OpenBtn from './OpenBtn/OpenBtn';
import SearchForm from './SearchForm/SearchForm';

import styles from './TableServerSearch.module.scss';

const Title = () => (
  <p className={styles.title}>Настройка серверной фильтрации</p>
);

interface ITableServerSearch {
  queryParams: QueryParamItem[];
  refreshData: ITable['refreshData'];
  limitIsExceeded: U<boolean>;
  queryParamsRequest: IQueryParamRequest;
  setQueryParamsRequest: SetState<IQueryParamRequest>;
}

const TableServerSearch: FC<ITableServerSearch> = ({
  queryParams,
  refreshData,
  limitIsExceeded,
  queryParamsRequest,
  setQueryParamsRequest,
}) => {
  const [open, setOpen] = useState(false);
  const { isQueryParamsError } = rootStore.uiStore;

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const formProps = {
    queryParams,
    onClose,
    refreshData,
    queryParamsRequest,
    setQueryParamsRequest,
  };

  const openBtnsProps = {
    showDrawer,
    queryParams,
    limitIsExceeded,
  };

  if (isQueryParamsError) return null;

  return (
    <div className={styles.searchedRow}>
      <OpenBtn {...openBtnsProps} />
      <Drawer
        title={<Title />}
        placement="right"
        closable={false}
        onClose={onClose}
        open={open}
        getContainer={false}
        className={styles.drawer}
        styles={{ header: { color: COLORS.FONT_COLOR } }}
      >
        <SearchForm {...formProps} />
      </Drawer>
    </div>
  );
};

export default observer(TableServerSearch);
