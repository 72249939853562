import { isNumber } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { COMMANDS, COMMANDS_CONTENT } from '../../../../constants/commands';
import { TL_STATUS_HEX } from '../../../../constants/tlStatusConstants';
import rootStore from '../../../../stores/rootStore/rootStore';
import { IPostCommandProps } from '../model/postCommands.model';

import CommandCard, { CommandCardProps } from './CommandCard/CommandCard';
import useManagementCommands from './hooks/useManagementCommands';
import ModalCommand, { IModalCommand } from './ModalCommand/ModalCommand';

import styles from './ManagementCommands.module.scss';

const { SET_OBJECT_MODE } = COMMANDS;

export interface IManagementCommands {
  id: number;
}

const ManagementCommands: FC<IManagementCommands> = ({ id }) => {
  const { isActiveControlBtn } = rootStore.detailedStore;

  const {
    sgnProgramName,
    tlName,
    imgRef,
    selected,
    openModal,
    isImgLoading,
    radioValue,
    setOpenModal,
    onClickPhase,
  } = useManagementCommands(id);

  const regime = isActiveControlBtn ? 'активно' : 'отключено';

  const commandData = COMMANDS_CONTENT.find((el) => el.id === radioValue);
  const commandId = commandData?.id ?? null;
  const cmdInfo =
    isNumber(commandId) && commandData?.title
      ? `${commandId} ${commandData?.title ?? ''}`
      : 'Команда не найдена';

  const propsPostCommand: IPostCommandProps = {
    id,
    command: commandId,
    cmdTitle: SET_OBJECT_MODE,
    cmdInfo,
    disabledTime: 0,
  };

  const modalProps: IModalCommand = {
    radioValue,
    sgnProgramName,
    tlName,
    imgRef,
    propsPostCommand,
    openModal,
    setOpenModal,
  };

  return (
    <>
      <h3>Изменить режим светофорного объекта ({regime})</h3>
      <div className={styles.wrapper}>
        {COMMANDS_CONTENT.map(({ disabled, id, tip, body, canSelected }) => {
          if (!canSelected || disabled) return null;

          const isSelected = id === selected;
          const isDisabled = !isActiveControlBtn || disabled;

          const isImgLoadingOnClickedButton =
            id === radioValue ? isImgLoading : true;

          const CommandCardProps: CommandCardProps = {
            title: body,
            colorCommand: TL_STATUS_HEX[id],
            disabled: isDisabled,
            blocked: disabled,
            selected: isSelected,
            infoTooltip: tip,
            mode: id,
            isImgLoading: isImgLoadingOnClickedButton,
            onClick: onClickPhase,
          };

          return <CommandCard key={id} {...CommandCardProps} />;
        })}
      </div>

      <ModalCommand {...modalProps} />
    </>
  );
};

export default observer(ManagementCommands);
