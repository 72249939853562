import { uniqueId } from 'lodash';
import { FC } from 'react';

import DefaultChecked from '../../../../ui-kit/DefaultChecked/DefaultChecked';
import { CollapseProps } from '../constants/constants';

import ModelCollapse from './ModelCollapse/ModelCollapse';

import styles from './ModelData.module.scss';

interface ModelDataProps {
  data: CollapseProps[];
  isLoading: boolean;
}

const ModelData: FC<ModelDataProps> = ({ data, isLoading }) => {
  if (!data.length)
    return <p>Для построение модели необходимо запросить данные сверху</p>;

  return (
    <div className={styles.container}>
      <DefaultChecked isLoading={isLoading}>
        {data.map((el) => (
          <ModelCollapse key={uniqueId()} {...el} />
        ))}
      </DefaultChecked>
    </div>
  );
};

export default ModelData;
