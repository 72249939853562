import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';

import rootStore from '../../stores/rootStore/rootStore';
import BreadCrumbs from '../ui-kit/BreadCrumbs/BreadCrumbs';
import Detailed, { DetailedType } from '../ui-kit/Detailed/Detailed';

import ReportTable from './ReportTable/ReportTable';

const ReportDetailed = () => {
  const { activeKey, activeReportInfo, activeBreadCrumbs, setReportValues } =
    rootStore.reportsPanelStore;

  const clearDetailed = useCallback(() => {
    setReportValues({
      activeBreadCrumbs: [],
      activeKey: null,
    });
  }, [setReportValues]);

  useEffect(() => {
    return () => {
      clearDetailed();
    };
  }, [clearDetailed]);

  if (!activeReportInfo) return null;

  const detailedProps: DetailedType = {
    header: activeReportInfo.label,
    onClose: () => {
      clearDetailed();
    },
  };

  return (
    <Detailed {...detailedProps}>
      <>
        <BreadCrumbs
          activeKey={activeKey?.toString() || null}
          breadCrumbsItems={activeBreadCrumbs}
        />
        <ReportTable />
      </>
    </Detailed>
  );
};

export default observer(ReportDetailed);
