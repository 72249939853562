import { ITableColumn, TableData } from '../../../../ts/models/table.model';

export const getLibraryItem = (column: ITableColumn, tableData: TableData) => {
  const libraries = tableData?.libraries;

  if (!libraries) return undefined;

  const viewValueLibraryKey = column?.viewValueLibraryKey;

  if (!viewValueLibraryKey) return undefined;

  return libraries[viewValueLibraryKey];
};
