import { IValuesDatesArr } from '../../../constants/constants';

interface IDates {
  dateFull: string[];
  dateMin: (string | JSX.Element)[];
}

export const getTitleArray = (dateArr: IValuesDatesArr[]) =>
  dateArr.reduce(
    (acc: IDates, el) => {
      acc.dateFull.push(el.value);
      acc.dateMin.push(el.min);

      return acc;
    },
    { dateFull: [], dateMin: [] }
  );
