import { findById } from '../../../helpers/findBy';
import {
  IProcessedDataInitial,
  SignalPrograms,
} from '../../../ts/models/signalPrograms.model';
import { TL } from '../../../ts/models/tl.model';

export const getCircleTime = (tacts: N<SignalPrograms['tlTacts']>) => {
  if (tacts) {
    const calculateCircle = tacts.reduce((ac, el) => ac + Math.abs(el.time), 0);

    return calculateCircle;
  }

  return 0;
};

export const getTLSignalData = (id: number, tlsData: TL[]) => {
  const activeTrafficLight = findById(tlsData, id);

  if (!activeTrafficLight) return;

  const { lsec, timeRcv, cycle } = activeTrafficLight.tlStatus;

  const tacts = activeTrafficLight.tlTacts;
  const tlSignalProgram2 = activeTrafficLight.tlSignalProgram2 ?? [];
  const phaseGroupNum = activeTrafficLight.tlGeneral.progNum;
  const cycleTime = getCircleTime(tacts);

  if (cycleTime !== undefined && cycle !== cycleTime) {
    //will be delete when repair error on BD
    console.error(
      `ERROR: TL id: ${id}. Поле TlStatus -> cycle не равно сумме длин всех фаз. Cycle = ${cycle}. Длина фаз = ${cycleTime}.`
    );
  }

  if (!phaseGroupNum) return;

  const tlData: IProcessedDataInitial = {
    tlTacts: tacts,
    cycleTime: getCircleTime(tacts) ?? cycle,
    timeRcv: timeRcv,
    lsec: lsec,
    //@ts-ignore
    tlSignalProgram2: tlSignalProgram2,
    phaseGroupNum,
  };

  return tlData;
};
