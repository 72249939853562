import { VERSION } from '../../../constants/versions';
import { getAppPathHeaders } from '../../helpers/getAppPathHeaders';
import http from '../../http';
import tryCatchWrapper from '../../tryCatchWrapper';

import { divisions } from './divisions/divisions';

const CATALOGS = VERSION + '/catalogs';
const ORGANIZATIONS = CATALOGS + '/organizations';

export type AddBody = {
  name: string;
  dsc: string;
  regionsId: number[];
};

export const organizations = {
  divisions,
  getList: (appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.get(ORGANIZATIONS, {
        headers: getAppPathHeaders(appPath),
      });

      return res;
    }),
  create: (body: AddBody, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = http.post(
        ORGANIZATIONS,
        { ...body },
        {
          headers: getAppPathHeaders(appPath),
        }
      );

      return res;
    }),
  update: (body: AddBody, id: number, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = http.put(
        ORGANIZATIONS + `/${id}`,
        { ...body },
        {
          headers: getAppPathHeaders(appPath),
        }
      );

      return res;
    }),
  delete: (id: number, appPath: string) =>
    tryCatchWrapper(async () => {
      const res = await http.delete(ORGANIZATIONS + `/${id}`, {
        headers: getAppPathHeaders(appPath),
      });

      return res;
    }),
};
