import { COLORS } from '../../../../../../constants/colorsConstants';
import {
  DEVICES_INFO,
  DEVICES_STATE,
  TDeviceStatus,
} from '../../../../../../constants/devicesConstants';
import { findBy } from '../../../../../../helpers/findBy';
import { getTLModeName } from '../../../../../../helpers/statusHelpers';
import { TObjMap } from '../../../../../../stores/mapDataStore/mapDataStore.model';
import rootStore from '../../../../../../stores/rootStore/rootStore';
import { System } from '../../../../../../ts/enums/enums';
import { TL } from '../../../../../../ts/models/tl.model';
import { getDeviceColor } from '../../../../../MapBtns/MapInfoWrapper/InfoBtn/ContentInfo/helpers/getMapInfo';

type TooltipInfo = Record<'name' | 'color' | 'optionalTitle', N<string>>;

const DISABLED_MODE = 'Недоступен';
const DISABLED_TITLE_CREATE_GROUP = 'СО уже находится в группе КУ';
const DISABLED_TITLE = 'СО не входит ни в одну группу КУ';

const DISABLED_TOOLTIP_INFO = {
  name: DISABLED_MODE,
  color: COLORS.GREY100,
};

const isTl = (item: TObjMap, system: System): item is TL =>
  system === System.Lights;

const getTooltipInfo = (
  isDisabled: boolean,
  title: string,
  colorDefault: string,
  modeTL: N<string>
): TooltipInfo => {
  const { isScriptsCreateDetailed } = rootStore.scriptsControlStore;

  if (isDisabled && isScriptsCreateDetailed) {
    return {
      ...DISABLED_TOOLTIP_INFO,
      optionalTitle: DISABLED_TITLE_CREATE_GROUP,
    };
  }

  if (isDisabled) {
    return {
      color: null,
      name: null,
      optionalTitle: DISABLED_TITLE,
    };
  }

  return { name: title, color: colorDefault, optionalTitle: modeTL };
};

export const getStatusDevice = (statusDevice: TDeviceStatus) =>
  findBy(DEVICES_STATE, statusDevice, 'com') ?? DEVICES_INFO.STATE.Undefined;

export const getStatusInfo = (
  activeItem: TObjMap,
  system: System,
  isDisabled: boolean
) => {
  const { deviceInfo } = activeItem;

  const isTL = isTl(activeItem, system);

  const mode = isTL ? activeItem.tlStatus.mode : null;
  const nameTL = getTLModeName(mode);
  const statusDevice = deviceInfo.deviceStatusId;

  const statusTitle = getStatusDevice(statusDevice).dsc;

  const colorDefault = getDeviceColor(statusDevice);

  const modeTL = isTL ? nameTL : null;

  return getTooltipInfo(isDisabled, statusTitle, colorDefault, modeTL);
};
