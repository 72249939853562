import { LegendProps } from '../CustomLegend';

type PayloadItem = NonNullable<LegendProps['payload']>[number];
type Payload = PayloadItem['payload'];

export const getTextLegend = (
  payload: Payload,
  value: PayloadItem['value']
) => {
  const percentValue = (payload?.percent ?? 0) * 100;
  const rounded = Math.round(percentValue);
  const isInt = Math.round(percentValue) === percentValue;

  const percent = isInt ? rounded : percentValue.toFixed(2);
  const mainText = `${value} (${payload?.value} / ${percent}%)`;

  return mainText;
};
