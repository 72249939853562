import { WEATHER_STYLES } from '../constants';

export const getPanelsCount = (left: boolean, right: boolean) => {
  return [left, right].filter((item) => item).length;
};

export const getTimeSliderStyle = (leftOffset: number, rightOffset: number) => {
  const baseValue = { ...WEATHER_STYLES.timePanelStyle };

  return {
    ...baseValue,
    left: leftOffset,
    right: rightOffset,
  };
};
