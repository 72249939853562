import { Map } from 'ol';
import { GeoJSON } from 'ol/format';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style';

import { ILayer } from '../../../stores/gisDataStore/gisDataStore.model';
import { COLOR_DRAPING_LAYER } from '../../constants/colors';
import { DRAPING_LAYER_ID, Z_INDEX_DRAPING_LAYER } from '../../constants/map';
import { http } from '../../requests';

export const createDrapingLayer = (map: Map, definitions: ILayer[]) => {
  if (!map) {
    return;
  }

  const drapingLayerDefinition = definitions.find(
    (item) => item.id === DRAPING_LAYER_ID
  );

  if (!drapingLayerDefinition) {
    return;
  }

  const { url, id } = drapingLayerDefinition;

  const queryUrl = url + '/query?f=geojson';

  http(queryUrl)
    .then((response) => {
      const { data } = response;

      const format = new GeoJSON();

      const features = format.readFeatures(data);

      const source: VectorSource<any> = new VectorSource({
        features,
      });

      const layer = new VectorLayer({
        source,
        style: new Style({
          fill: new Fill({
            color: COLOR_DRAPING_LAYER,
          }),
          stroke: new Stroke({
            width: 1,
            color: COLOR_DRAPING_LAYER,
          }),
        }),
      });

      map.addLayer(layer);

      layer.set('id', id);
      layer.setZIndex(Z_INDEX_DRAPING_LAYER);
    })
    .catch((err) => {
      console.error(err);
    });
};
