import type { Feature } from 'ol';
import type { Point } from 'ol/geom';

import { COMMANDS_MODE_ERRORS } from '../../../../../constants/tlStatusConstants';
import { System } from '../../../../../ts/enums/enums';

const FEATURE_KEY = {
  IS_DISABLED: 'isDisabled',
  DEVICE_STATUS: 'deviceStatus',
  MODE: 'mode',
  ID: 'id',
  SYSTEM: 'system',
};

const { DEVICE_STATUS, ID, IS_DISABLED, MODE, SYSTEM } = FEATURE_KEY;

export const updateFeature = (
  features: Feature<Point>[],
  item: Feature<Point>
) => {
  const idFeature: number = item.get(ID);
  const system: System = item.get(SYSTEM);
  const findFeature = features.find((el) => el.get(ID) === idFeature);

  if (!findFeature) return;

  item.set(IS_DISABLED, !!findFeature.get(IS_DISABLED));
  item.set(DEVICE_STATUS, findFeature.get(DEVICE_STATUS));

  if (system === System.Lights) {
    const mode = findFeature.get(MODE) ?? COMMANDS_MODE_ERRORS.UnknownMode;

    item.set(MODE, mode);
  }
};

export const updateFeatures = (
  featuresOld: Feature<Point>[],
  featuresNew: Feature<Point>[]
) =>
  featuresOld.forEach((el) => {
    updateFeature(featuresNew, el);
  }, []);
