import { FC } from 'react';
import { TbChartInfographic } from 'react-icons/tb';

import ButtonOnlyIcon from '../../../../../ui-kit/ButtonOnlyIcon/ButtonOnlyIcon';

import styles from './GraphButtons.module.scss';

export interface GraphButtonsProps {
  isLabel: boolean;
  setIsLabel: SetState<boolean>;
}

const GraphButtons: FC<GraphButtonsProps> = ({ isLabel, setIsLabel }) => {
  const tip = `${
    isLabel ? 'Выключить' : 'Включить'
  } отображение информации по всем столбцам`;

  const handleClick = () => setIsLabel(!isLabel);

  return (
    <span className={styles.container}>
      <ButtonOnlyIcon
        onClick={handleClick}
        isActive={isLabel}
        isSmall
        tip={tip}
      >
        <TbChartInfographic />
      </ButtonOnlyIcon>
    </span>
  );
};

export default GraphButtons;
