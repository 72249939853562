import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { CSSProperties, MouseEventHandler } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import Popover, { PopoverProps } from '../Popover/Popover';

import styles from './ButtonIco.module.scss';

export type TPopoverProps = Partial<PopoverProps>;

interface ButtonIcoProps {
  children: any;
  onClick: MouseEventHandler<HTMLButtonElement>;
  isActive?: boolean;
  isRoundTop?: boolean;
  isRoundBottom?: boolean;
  isRoundRight?: boolean;
  isRoundLeft?: boolean;
  isRound?: boolean;
  isSmall?: boolean;
  isPrimary?: boolean;
  isHover?: boolean;
  style?: CSSProperties;
  isNotAllowed?: boolean;
  disabled?: boolean;
  popoverProps?: TPopoverProps;
}

const ButtonIco: React.FC<ButtonIcoProps> = (props: ButtonIcoProps) => {
  const { interfaceTipsDelay } = rootStore.uiStore;

  const initialPopoverProps = {
    content: '',
    tipsDelay: interfaceTipsDelay,
  };

  const {
    children,
    onClick,
    isActive,
    isRoundTop,
    isRoundBottom,
    isRoundRight,
    isRoundLeft,
    isRound,
    isSmall,
    isPrimary,
    isHover = true,
    style,
    isNotAllowed = false,
    disabled = false,
    popoverProps = initialPopoverProps,
  } = props;

  const btnStyles = classNames({
    [styles.mainBtn]: true,
    [styles.active]: isActive,
    [styles.round]: isRound,
    [styles.roundTop]: isRoundTop,
    [styles.roundBottom]: isRoundBottom,
    [styles.roundRight]: isRoundRight,
    [styles.roundLeft]: isRoundLeft,
    [styles.small]: isSmall,
    [styles.primary]: isPrimary,
    [styles.hover]: isHover && !disabled,
    [styles.isNotAllowed]: disabled || isNotAllowed,
    [styles.disabled]: disabled,
    [styles.hoverForActiveBtn]: isActive,
  });

  const handleClick = !disabled && !isNotAllowed ? onClick : undefined;

  return (
    <Popover {...initialPopoverProps} {...popoverProps}>
      <button className={btnStyles} onClick={handleClick} style={style}>
        {children}
      </button>
    </Popover>
  );
};

export default observer(ButtonIco);
