import { Input, Select } from 'antd';
import type { Rule } from 'antd/es/form';

import {
  DESC_RULES,
  NAME_RULES,
} from '../../../../constants/formsConstants/formsRules';
import { ActionType } from '../models/Organizations.model';

const { TextArea } = Input;

export const ACTIONS_WIDTH = 150;
export const DEFAULT_ROWS_COUNT = 4;

export const FORM_COMPONENTS = {
  input: Input,
  textarea: TextArea,
  regions: Select,
} as const;

type Setting = {
  name: string;
  label: string;
  type: keyof typeof FORM_COMPONENTS;
  rules: Rule[];
};

export const FORM_SETTINGS: Setting[] = [
  {
    name: 'name',
    label: 'Наименование',
    type: 'input',
    rules: NAME_RULES,
  },
  {
    name: 'dsc',
    label: 'Описание',
    type: 'textarea',
    rules: DESC_RULES,
  },
  {
    name: 'regionsId',
    label: 'Регионы',
    type: 'regions',
    rules: [{ required: true, message: 'Обязательное поле' }],
  },
];

export const DIVISIONS_FORM_SETTINGS = FORM_SETTINGS.slice(0, 2);

export const MULTIPLE_MODE = 'multiple';
export const RESET = 'Очистить форму';
export const DIVISIONS = 'Подразделения';

export const SUBMIT_TEXT: Record<ActionType, string> = {
  add: 'Добавить',
  edit: 'Обновить',
};

export const ORG_TITLES: OWKS<string> = {
  add: 'добавления',
  edit: 'редактирования',
};
