import { useMemo } from 'react';

import ModalSignalProgram from '../../../../PlanSelect/ModalSignalProgram/ModalSignalProgram';
import EditSignalProgramTable from '../../../../TemporaryPlan/EditSgnProgramTable/EditSgnProgramTable';
import { getDurationMinArray } from '../../../../TemporaryPlan/helpers/getDurationMinArray';
import { UseEditModalProps } from '../../../model/currentPlan.model';

const useEditModal = ({
  isValidateTable,
  dataTable,
  setIsValidateTable,
  setDataTable,
  baseProgramProps,
  defaultPhasesSet,
  columns,
  setColumns,
}: UseEditModalProps) => {
  const minDurations = useMemo(() => {
    return getDurationMinArray(defaultPhasesSet);
  }, [defaultPhasesSet]);

  const isErrorSgnProgram = baseProgramProps.isError || !isValidateTable;

  const editBodyProps = {
    content: (
      <EditSignalProgramTable
        setIsValidateTable={setIsValidateTable}
        dataTable={dataTable}
        setDataTable={setDataTable}
        isPhasesTable
        durationMinArray={minDurations}
        columns={columns}
        setColumns={setColumns}
      />
    ),
    image: (
      <ModalSignalProgram {...baseProgramProps} isError={isErrorSgnProgram} />
    ),
    phasesEdit: true,
    isPhasesTable: true,
  };

  return { editBodyProps, isErrorSgnProgram };
};

export default useEditModal;
