import { getSecByDayJS } from '../../../../../helpers/formatDate';
import { INTERVALS, INTERVALS_NAMES } from '../../constants/constants';
import { IDateInfo, IPickerValue } from '../../model/graph.model';

import { getRoundDates } from './getRoundDates';

interface IGetDateInfoProps {
  standardInterval: U<INTERVALS_NAMES>;
  pickerValue: IPickerValue;
  isChecked: boolean;
}

type TGetDateInfoF = (value: IGetDateInfoProps) => U<IDateInfo>;

export const getDateInfo: TGetDateInfoF = ({
  standardInterval,
  pickerValue,
  isChecked,
}) => {
  const standardIntervalValue = INTERVALS.find(
    ({ label }) => label === standardInterval
  );

  const interval = isChecked
    ? getSecByDayJS(pickerValue.timePicker)
    : standardIntervalValue?.duration;

  if (!pickerValue.rangePicker || !interval) return;

  const selectedDate = !isChecked
    ? getRoundDates(pickerValue.rangePicker, standardIntervalValue)
    : pickerValue.rangePicker;

  return {
    isNeedUtcFormatted: false,
    selectedDate,
    interval: interval,
    radioValueDate: 'SELECT_TIME',
  };
};
