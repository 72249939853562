import { isEqual } from 'lodash';

import { QueryParamItem } from '../../../../../../ts/models/table.model';
import { getFormFieldsetValues } from '../helpers';

const checkFormChange = (
  values: U<{ [key: string]: unknown }>,
  queryParams: QueryParamItem[]
) => {
  return !isEqual(
    getFormFieldsetValues(queryParams, 'currentValue', {
      isNeedCheck: true,
      isReturnDate: false,
    }),
    values
  );
};

export default checkFormChange;
