/* eslint-disable jsx-a11y/no-static-element-interactions */
import classNames from 'classnames';
import { FC, useState } from 'react';
import {
  AiOutlineSave,
  AiOutlineEdit,
  AiOutlineCloseCircle,
} from 'react-icons/ai';

import rootStore from '../../../stores/rootStore/rootStore';
import Copy from '../Copy/Copy';
import message from '../Notification/Notification';
import Popover from '../Popover/Popover';

import Chunk from './Chunk/Chunk';
import Quote from './Quote';

import styles from './JsonView.module.scss';

interface JsonViewProps {
  object: object;
  editSetObject?: SetState<object>;
}

const ICONS_SIZE = 20;

const JsonView: FC<JsonViewProps> = ({ object, editSetObject }) => {
  const { interfaceTipsDelay } = rootStore.uiStore;
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [textValue, setTextValue] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(true);

  const pickIsEditHandler = () => {
    setIsEdit(true);

    setTextValue(JSON.stringify(object, null, 2));
  };

  const onTabHandle = (event: any) => {
    if (event.code === 'Tab') event.preventDefault();
  };

  const saveEditHandle = () => {
    try {
      const newObject = JSON.parse(textValue);

      if (!editSetObject) return;
      editSetObject(newObject);
      setIsEdit(false);
    } catch (e) {
      message.error('JSON_INVALID');

      setIsValid(false);

      setTimeout(() => {
        setIsValid(true);
      }, 1000);
    }
  };

  const abortEditHandle = () => {
    setIsEdit(false);
  };

  const getTextArea = (): JSX.Element => {
    return (
      <textarea
        rows={textValue.split('\n').length}
        className={styles.textArea}
        value={textValue}
        onChange={(e) => setTextValue(e.target.value)}
        onKeyDown={onTabHandle}
        spellCheck={false}
      />
    );
  };

  const getPrettyJson = (): JSX.Element => {
    return (
      <>
        <Quote parentObj={object} position="start" />
        {Object.entries(object).map((entry, i, array) => (
          <Chunk
            key={i}
            entry={entry}
            parent={object}
            isLastElement={array.length - 1 !== i}
            indentLevel={1}
          />
        ))}
        <Quote parentObj={object} position="end" />
      </>
    );
  };

  const mainStyle = classNames({
    [styles.main]: true,
    [styles.redBorder]: !isValid,
  });

  const isShowEdit = editSetObject && isEdit;

  return (
    <div className={mainStyle}>
      <div className={styles.editBlock}>
        {isEdit ? (
          <>
            <Popover
              content="Сохранить изменения"
              placement="top"
              tipsDelay={interfaceTipsDelay}
            >
              <div className={styles.editBtn} onClick={saveEditHandle}>
                <AiOutlineSave size={ICONS_SIZE} />
              </div>
            </Popover>

            <Popover
              content="Отменить изменения"
              placement="top"
              tipsDelay={interfaceTipsDelay}
            >
              <div className={styles.editBtn} onClick={abortEditHandle}>
                <AiOutlineCloseCircle size={ICONS_SIZE} />
              </div>
            </Popover>
          </>
        ) : (
          <>
            <Copy
              copyString={JSON.stringify(object, null, 2)}
              className={styles.copyButton}
            />
            {editSetObject && (
              <div className={styles.editBtn} onClick={pickIsEditHandler}>
                <Popover
                  content="Редактировать JSON"
                  placement="top"
                  tipsDelay={interfaceTipsDelay}
                >
                  <AiOutlineEdit size={20} />
                </Popover>
              </div>
            )}
          </>
        )}
      </div>
      <code className={styles.code}>
        {isShowEdit ? getTextArea() : getPrettyJson()}
      </code>
    </div>
  );
};

export default JsonView;
