import { useEffect, useState, useMemo, useCallback } from 'react';

import createArray from '../helpers/createArray';

type TimerId = U<NodeJS.Timer>;

const useSwitchers = (data: Nullish<unknown[]>, interval = 500) => {
  const init = useMemo(
    () => (data ? createArray(data.length, true) : []),
    [data]
  );

  const [switchers, setSwithers] = useState<boolean[]>(init);

  const clear = useCallback(
    (timerId: TimerId) => () => timerId && clearInterval(timerId),
    []
  );

  useEffect(() => {
    let timerId: TimerId = undefined;

    if (!data) return clear(timerId);

    timerId = setInterval(() => {
      setSwithers((prev) =>
        prev.map((bool, idx) => (data[idx] ? !bool : true))
      );
    }, interval);

    return clear(timerId);
  }, [data, clear, interval]);

  useEffect(() => {
    setSwithers(init);
  }, [init]);

  return switchers;
};

export default useSwitchers;
