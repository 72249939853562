import api from '..';
import notification from '../../components/ui-kit/Notification/Notification';
import eventBus from '../../eventBus';
import rootStore from '../../stores/rootStore/rootStore';

let isLoading = false;

const waitTokensUpdate = () =>
  new Promise<null>((resolve) => {
    eventBus.tokensUpdateComplete.subscribe(() => {
      resolve(null);
    });
  });

export const refreshTokensRequest = async (triggerEndpoint: string) => {
  try {
    if (isLoading) {
      return await waitTokensUpdate();
    }
    isLoading = true;

    const { userDataStore } = rootStore;
    const refreshToken = userDataStore.refreshToken;

    if (!refreshToken) {
      throw new Error('Отсутствует refreshToken');
    }

    const request = await api.user.auth.refreshToken(
      refreshToken,
      'refreshTokensRequest',
      triggerEndpoint
    );
    const response = request.data;

    userDataStore.setTokens(response.tokens, userDataStore.isRemember);
  } catch (e: any) {
    if (e?.response?.status === 403) {
      notification.error('BLOCKED_ACCESS', {
        dsc: e?.response?.data?.additional?.error,
      });
    } else {
      notification.warning('AUTH_DEADLINE');
    }

    rootStore.userDataStore.resetUserData();
    throw e;
  } finally {
    isLoading = false;
    eventBus.tokensUpdateComplete.broadcast();
  }
};
