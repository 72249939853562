import { RefObject, useEffect, useState } from 'react';

import useResize from '../../../NewSignalProgram/hooks/useResize';
import { BREAK_POINT, getBreakpoint } from '../helpers/helpers';

interface UseBreakpointProps {
  cameraWrapRef: RefObject<HTMLElement>;
  isPanel: boolean;
}

export type BreakPoints = keyof typeof BREAK_POINT;

const useBreakpoint = ({ cameraWrapRef, isPanel }: UseBreakpointProps) => {
  const [breakPoint, setBreakPoint] = useState<N<BreakPoints>>(null);

  const { width } = useResize({ wrapRef: cameraWrapRef, isPanel });

  useEffect(() => {
    if (!width) return;

    setBreakPoint(getBreakpoint(width));
  }, [width]);

  return { breakPoint };
};

export default useBreakpoint;
