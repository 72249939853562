import { Descriptions } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { DictPredictKeys } from '../../../../../../api/services/dtStatistics/constants/constants';
import rootStore from '../../../../../../stores/rootStore/rootStore';
import { GraphTypes } from '../../../../../TrafficLightDetailed/TlDetectors/constants/constants';
import { InfoButtonProps } from '../InfoButton';

import DictDesc from './DictDesc/DictDesc';
import getMainItems from './helpers/getMainItems';

import styles from './PopoverInfoContent.module.scss';

const { Predict } = DictPredictKeys;

const PopoverInfoContent: FC<InfoButtonProps> = ({ graphInfo, timeUpdate }) => {
  const { graphType } = rootStore.detailedStore;
  const predictDict = rootStore.uiStore.dictionaries[Predict];

  const mainItems = getMainItems(graphInfo, timeUpdate);

  const containerStyle = classNames(styles.container, {
    [styles.candle]: Boolean(
      graphType === GraphTypes.CandlesChart && predictDict.length
    ),
  });

  return (
    <div className={containerStyle}>
      <Descriptions
        items={mainItems}
        layout="vertical"
        title="Основная информация"
        className={styles.description}
      />
      <DictDesc descStyle={styles.description} />
    </div>
  );
};

export default observer(PopoverInfoContent);
