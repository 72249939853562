import { useEffect, useState } from 'react';

import { ICategory } from '../../stores/channelsDataStore/channelsDataStore';
import rootStore from '../../stores/rootStore/rootStore';

import { Socket } from './useSocket';

const useChannel = (
  categories: ICategory[],
  channelName: string,
  categoryId: string | number,
  returnLastMessage: U<boolean> = false
) => {
  const { getCategory } = rootStore.channelsDataStore;
  const [message, setMessage] = useState<any>(
    getCategory(channelName, categoryId).data
  );

  useEffect(() => {
    if (!Socket.isInit) {
      return console.error(
        'Подключение к каналу не может быть созданно до инициализации WS'
      );
    }

    Socket.subscribe(
      channelName,
      categoryId,
      returnLastMessage ? returnLastMessage : false
    );

    return () => {
      Socket.unsubscribe(channelName, categoryId);
    };
  }, [channelName, categoryId, returnLastMessage]);

  useEffect(() => {
    const category = getCategory(channelName, categoryId);

    setMessage(category.data);
  }, [channelName, categoryId, categories, getCategory]);

  return message;
};

export default useChannel;
