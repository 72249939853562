import { Feature } from 'ol';

import { IFeatureSet } from '../../../apiGIS/models/sources/sourceDefinitions';

export enum RoutePointEnum {
  START_POINT = 'START_POINT',
  END_POINT = 'END_POINT',
  MID_POINT = 'MID_POINT',
}

export interface IRoutePoint {
  type?: RoutePointEnum;
  title?: string;
  feature?: Feature;
  selected?: boolean;
  x?: number;
  y?: number;
}

export interface IQueryFeaturesResult {
  featuresSet: IFeatureSet;
  tooltipDefinition: { fields: U<string[]>; template: U<string> };
}
