import type { PickingInfo } from '@deck.gl/core';
import { ArcLayer, ColumnLayer, TextLayer } from '@deck.gl/layers';
import {
  booleanContains,
  centerOfMass as turfCenterMass,
  featureCollection,
  point as turfPoint,
  polygon as turfPolygon,
  toMercator,
  toWgs84,
} from '@turf/turf';
import dayjs from 'dayjs';
import { Feature } from 'ol';
import { LineString, Point, Polygon } from 'ol/geom';

import graphApi from '../../../api/integration/gis/graph';
import { queryFeatures } from '../../../apiGIS/sources/FeatureServerSource';
import { TMap } from '../../../stores/mapStore/mapStore.model';

import {
  DEFAULT_BBOX,
  DEFAULT_WHERE,
  LINK_MAX_WIDTH,
  LINK_MIN_WIDTH,
  MAX_NAME_CHARS_LENGTH,
  MAX_RESULTS_FROM_SERVICE,
  NODE_MERGE_OPTION_ATTRIBUTE,
  NODE_MERGE_OPTION_LAYER,
  NODE_MERGE_OPTION_NONE,
  POLYGONS_TEST_SERVICE_URL,
  START_NODE_COLOR,
  TARGET_NODE_COLOR,
  TEXT_LAYER_FONT_COLOR,
  TEXT_LAYER_LABEL_SIZE,
  TEXT_LAYER_OFFSET_LABEL,
  TEXT_LAYER_PADDING,
  TRAFFIC_LAYER_LINK,
  TRAFFIC_LAYER_VERTEX,
  TRAFFIC_LAYER_VERTEX_TEXT,
  VERTEX_COLOR,
  VERTEX_EDGES,
  VERTEX_RADIUS,
} from './constants';
import {
  IGraphDataSet,
  IGraphSet,
  ILink,
  IPairLink,
  ITransferItem,
  IVertex,
  IVertexStubData,
  LinkSegmentData,
  VertexData,
} from './trafficGraph.model';

export const getPointFromVertex = (item: IVertex): Point | undefined => {
  const { feature } = item;

  if (feature?.getGeometry() instanceof Point) {
    return feature.getGeometry() as Point;
  }

  return undefined;
};

export const getPointFromVertexItem = (
  item: IVertexStubData
): Point | undefined => {
  const { lat, lon } = item;

  if (lat && lon) {
    const coordinates = toMercator(turfPoint([lon, lat])).geometry.coordinates;

    return new Point(coordinates);
  }

  return undefined;
};

export const getVertexesItems = async (
  map: TMap,
  items: IVertexStubData[],
  regionId: number,
  mergeMethod: string
): Promise<IVertex[]> => {
  if (!map) {
    return [];
  }

  const filteredItems = items.filter(
    (item: IVertexStubData) => item.regionId === regionId
  );

  if (mergeMethod === NODE_MERGE_OPTION_NONE) {
    const features = filteredItems
      .map((item) => {
        const geometry = getPointFromVertexItem(item);

        if (!geometry) {
          return undefined;
        }

        return new Feature({
          id: item.deviceId,
          ...item,
          nodes: [],
          geometry,
        });
      })
      .filter((item) => !!item);

    return features
      .filter((feature) => !!feature)
      .map((feature, index: number) => {
        const vertex: IVertex = {
          id: index.toString(),
          name: feature?.get('name') || index.toString(),
          nodes: [],
          feature,
        };

        return vertex;
      });
  }

  if (mergeMethod === NODE_MERGE_OPTION_ATTRIBUTE) {
    const propName = 'profileUid';

    const setIds = new Set();

    for (let i = 0; i < filteredItems.length; i++) {
      setIds.add(items[i][propName]);
    }

    const ids = [...setIds];

    const features = [];

    for (let i = 0; i < ids.length; i++) {
      const id = ids[i];

      const nodes = filteredItems.filter((item) => item[propName] === id);

      const points = nodes
        .map((item) => {
          const { lon, lat } = item;

          if (lon && lat) {
            return toMercator(turfPoint([lon, lat]));
          }

          return undefined;
        })
        .filter((item) => !!item);

      try {
        // @ts-ignore
        const coordinates = turfCenterMass(featureCollection(points)).geometry
          .coordinates;

        const geometry = new Point(coordinates);

        const nodeId = nodes.map((item) => item.deviceId).join(',');

        const feature = new Feature({
          id: nodeId,
          nodes,
          geometry,
        });

        features.push(feature);
      } catch (e) {
        console.log(`> err on coordinates`);
      }
    }

    return features
      .filter((feature) => !!feature)
      .map((feature, index: number) => {
        const nodes = feature.getProperties()['nodes'] || [];

        const vertex: IVertex = {
          id: feature?.getProperties()['id'] || index.toString(),
          name: feature?.getProperties()['name'] || index.toString(),
          nodes,
          feature,
        };

        return vertex;
      });
  }

  if (mergeMethod === NODE_MERGE_OPTION_LAYER) {
    const layer = { url: POLYGONS_TEST_SERVICE_URL };
    const where = DEFAULT_WHERE;
    const num = MAX_RESULTS_FROM_SERVICE;

    const params = {
      bbox: '',
      url: layer.url,
      where,
      num,
    };

    params.bbox = DEFAULT_BBOX;

    const { features: polygons } = await queryFeatures(params);

    const features = polygons
      .filter((item) => !!item)
      .map((feature: Feature) => {
        const geometry = feature.getGeometry();

        if (geometry && geometry instanceof Polygon) {
          const coordinates = geometry.getCoordinates();

          // @ts-ignore
          const center = geometry.getExtent()
            ? geometry.getInteriorPoint()
            : null;

          if (!center) {
            return undefined;
          }

          const polygon = turfPolygon(coordinates);

          const nodes = filteredItems.filter((item) => {
            try {
              const { lat, lon } = item;

              if (lat && lon) {
                const point = toMercator(turfPoint([lon, lat]));

                return booleanContains(polygon, point);
              }
            } catch (e) {
              return false;
            }

            return false;
          });

          const id = nodes.map((item) => item.deviceId).join(',');

          return new Feature({
            id,
            name: feature.get('name'),
            nodes,
            geometry: center,
          });
        }

        return undefined;
      })
      .filter((item) => !!item);

    return features
      .filter((feature) => {
        return feature instanceof Feature;
      })
      .map((feature: Feature | undefined, index: number) => {
        const nodes = feature?.getProperties()['nodes'] || [];

        const vertex: IVertex = {
          id: feature?.getProperties()['id'] || index.toString(),
          name: feature?.getProperties()['name'] || index.toString(),
          nodes,
          feature,
        };

        return vertex;
      });
  }

  return [];
};

const getIntensity = (
  links: IPairLink[],
  nodes1: IVertexStubData[],
  nodes2: IVertexStubData[]
) => {
  let intensity = 0;

  for (let i = 0; i < links.length; i++) {
    const { id1: lId1, id2: lId2, count } = links[i];

    const hasId1 = nodes1.find((element) => element.deviceId === lId1);
    const hasId2 = nodes2.find((element) => element.deviceId === lId2);

    if (hasId1 && hasId2) {
      intensity += count;
    }
  }

  return intensity;
};

export const getLinksItems = async (
  vertexes: IVertex[],
  links: IPairLink[]
): Promise<any[]> => {
  const linksItems = [];

  for (let i = 0; i < vertexes.length; i++) {
    const item1 = vertexes[i];

    const point1 = getPointFromVertex(item1);

    const { id: id1, nodes: nodes1 } = item1;

    if (!id1) {
      continue;
    }

    for (let j = 0; j < vertexes.length; j++) {
      const item2 = vertexes[j];

      const { id: id2, nodes: nodes2 } = item2;

      if (id1 === id2 || !id2) {
        continue;
      }

      const point2 = getPointFromVertex(item2);

      const p1Coordinates = point1?.getCoordinates();
      const p2Coordinates = point2?.getCoordinates();

      if (!p1Coordinates || !p2Coordinates) {
        continue;
      }

      const [x1, y1] = p1Coordinates;
      const [x2, y2] = p2Coordinates;

      const geometry = new LineString([
        [x1, y1],
        [x2, y2],
      ]);

      const intensity = getIntensity(links, nodes1, nodes2);

      const link: ILink = {
        id1,
        id2,
        geometry,
        intensity,
      };

      linksItems.push(link);
    }
  }

  return linksItems;
};

export const getVertexes = async (regionId: number) => {
  try {
    const data = await graphApi.getVertexes('gis');

    return data
      .map((element: any) => {
        const item: IVertexStubData = {
          scSystemId: element.sc_system_id,
          lat: element.db3_latitude,
          lon: element.db3_longitude,
          deviceId: element.mc_vehicle_source_id,
          cameraUid: element.db2_camera_uid,
          crossroadId: element.db3_crossroad_id,
          fwdRetVal: element.fdw_ret_val,
          fwdSchemaName: element.fdw_schema_name,
          fwdTableName: element.mc_vehicle_source_129,
          profileUid: element.db3_point_uds_profile_uid,
          regionId: element.db3_region_id,
        };

        return item;
      })
      .filter((item: IVertexStubData) => item.regionId === regionId)
      .filter((item: IVertexStubData) => item.lat && item.lon);
  } catch (e) {
    return [];
  }
};

export const getLinks = async (
  vertexes: IVertexStubData[],
  dateStart: string,
  dateEnd: string
): Promise<IPairLink[]> => {
  try {
    if (!dateStart || !dateEnd) {
      return [];
    }

    const ids = vertexes
      .filter((item) => item.deviceId)
      .map((item) => Number(item.deviceId));

    const data = await graphApi.getLinks('gis', ids, dateStart, dateEnd);

    const items: ITransferItem[] = data.map((element: any) => {
      const item: ITransferItem = {
        tsId: element.mc_vehicle_grnz_id,
        timestamp: new Date(element.passage_timestamp).getTime(),
        deviceId: element.mc_vehicle_source_id,
      };

      return item;
    });

    items.sort((a: ITransferItem, b: ITransferItem) => {
      const { timestamp: t1 } = a;
      const { timestamp: t2 } = b;

      if (t1 > t2) {
        return -1;
      }

      if (t1 < t2) {
        return 1;
      }

      return 0;
    });

    const tsIds = new Set();

    for (let i = 0; i < items.length; i++) {
      const { tsId } = items[i];

      tsIds.add(tsId);
    }

    const tsIdsArray = [...tsIds];

    const linksDict = new Map();

    for (let i = 0; i < tsIdsArray.length; i++) {
      const tsId = tsIdsArray[i];

      const series = items.filter((item: ITransferItem) => item.tsId === tsId);

      for (let j = 0; j < series.length - 1; j++) {
        const series1 = series[j];
        const series2 = series[j + 1];

        const id1 = series1.deviceId;
        const id2 = series2.deviceId;

        if (id1 && id2 && id1 !== id2) {
          const minId = Math.min(id1, id2);
          const maxId = Math.max(id1, id2);

          const key: string = [minId, maxId].join('-');

          if (!linksDict.has(key)) {
            linksDict.set(key, 1);
          } else {
            const count = linksDict.get(key) + 1;

            linksDict.set(key, count);
          }
        }
      }
    }

    const links: IPairLink[] = [];

    const linksArray = [...linksDict];

    for (let i = 0; i < linksArray.length; i++) {
      const [key, count] = linksArray[i];
      const parts = key.split('-').map((item: string) => Number(item));

      const [id1, id2] = parts;

      const link: IPairLink = {
        id1,
        id2,
        count,
      };

      links.push(link);
    }

    return links;
  } catch (e) {
    return [] as IPairLink[];
  }
};

export const getDataset = async (
  regionId: number,
  dateStart: string,
  dateEnd: string
): Promise<IGraphSet> => {
  const vertexes: IVertexStubData[] = await getVertexes(regionId);

  const dateStartView = dateStart.toString();
  const dateEndView = dateEnd.toString();

  const links: IPairLink[] = await getLinks(
    vertexes,
    dateStartView,
    dateEndView
  );

  const key = [dateStart, dateEnd].join('-');

  return {
    key,
    vertexes,
    links,
  };
};

export const getDate = (date?: unknown) => {
  if (!date) {
    const value = new Date();

    value.setHours(0);
    value.setMinutes(0);
    value.setSeconds(0);

    return dayjs(value);
  }

  return date;
};

const getDayjsValue = (date: Date) => {
  const value = date ? date : new Date();

  value.setSeconds(0);

  return dayjs(date);
};

export interface IDateRange {
  dateStart: string | undefined;
  dateEnd: string | undefined;
}

export const getMinDate = (range: IDateRange) => {
  const { dateEnd } = range;

  const date = dateEnd ? new Date(dateEnd) : new Date();

  date.setMonth(0);
  date.setHours(0);
  date.setMinutes(0);

  return getDayjsValue(date);
};

export const getMaxDate = (range: IDateRange) => {
  const { dateStart } = range;

  const date = dateStart ? new Date(dateStart) : new Date();

  date.setHours(23);
  date.setMinutes(59);

  return getDayjsValue(date);
};

export const getDateStart = (date: string | undefined) => {
  return date ? date + ':00' : '';
};

export const getDateEnd = (date: string | undefined) => {
  return date ? date + ':00' : '';
};

export const linkLayer = (data: LinkSegmentData[]) => {
  return new ArcLayer<LinkSegmentData>({
    id: TRAFFIC_LAYER_LINK,
    data,
    getSourcePosition: (d: LinkSegmentData) => d.from.coordinates,
    getTargetPosition: (d: LinkSegmentData) => d.to.coordinates,
    getSourceColor: START_NODE_COLOR,
    getTargetColor: TARGET_NODE_COLOR,
    getWidth: (d: LinkSegmentData) => d.width,
    widthMinPixels: LINK_MIN_WIDTH,
    widthMaxPixels: LINK_MAX_WIDTH,
    pickable: true,
  });
};

export const vertexLayer = (data: VertexData[]) => {
  return new ColumnLayer({
    id: TRAFFIC_LAYER_VERTEX,
    data,
    getFillColor: VERTEX_COLOR,
    getPosition: (d: VertexData) =>
      d.coordinates ? [d.coordinates[0], d.coordinates[1]] : [0, 0],
    diskResolution: VERTEX_EDGES,
    extruded: true,
    radius: VERTEX_RADIUS,
    getElevation: VERTEX_RADIUS,
    pickable: true,
  });
};

export const vertexTextLayer = (data: VertexData[]) => {
  return new TextLayer({
    id: TRAFFIC_LAYER_VERTEX_TEXT,
    data,
    getColor: TEXT_LAYER_FONT_COLOR,
    background: true,
    backgroundPadding: TEXT_LAYER_PADDING,
    getText: (d: VertexData) => {
      const { id } = d;
      const idView = id.toString().split('_').join(',');
      const len = idView.length;

      return len >= MAX_NAME_CHARS_LENGTH
        ? idView.slice(0, MAX_NAME_CHARS_LENGTH) + '...'
        : idView;
    },
    getPosition: (d: VertexData) =>
      d.coordinates ? [d.coordinates[0], d.coordinates[1]] : [0, 0],
    getAlignmentBaseline: 'bottom',
    getSize: TEXT_LAYER_LABEL_SIZE,
    sizeMaxPixels: TEXT_LAYER_LABEL_SIZE,
    getTextAnchor: 'start',
    getPixelOffset: TEXT_LAYER_OFFSET_LABEL,
    fontFamily: 'arial',
    pickable: false,
    wordBreak: 'break-all',
    maxWidth: MAX_NAME_CHARS_LENGTH,
  });
};

export const getLinkFeatures = (
  dataset: IGraphDataSet | null,
  selectedNode?: N<IVertex>
) => {
  return (dataset?.links || [])
    .filter((link: ILink) => {
      if (!selectedNode) {
        return true;
      }

      const { id } = selectedNode;
      const { id1 } = link;

      return id1 === id;
    })
    .map((link: ILink) => {
      const { id1, id2, geometry } = link;

      const coordinates = geometry.getCoordinates();
      const c1 = coordinates[0];
      const c2 = coordinates[coordinates.length - 1];

      const p1 = toWgs84(turfPoint(c1)).geometry.coordinates;
      const p2 = toWgs84(turfPoint(c2)).geometry.coordinates;

      const count = link.intensity || 0;
      const width = count >= 10 ? 10 : 1;

      const item: LinkSegmentData = {
        id1,
        id2,
        count,
        width,
        to: {
          coordinates: [p1[0], p1[1]],
          name: '',
        },
        from: {
          coordinates: [p2[0], p2[1]],
          name: '',
        },
      };

      return item;
    });
};

export const getVertexesFeatures = (dataset: IGraphDataSet | null) => {
  return (dataset?.vertexes || []).map((vertex: IVertex, index) => {
    const { feature } = vertex;
    const name = `Узел-${(index + 1).toString()}`;

    if (!feature) {
      return {
        id: index,
        coordinates: [0, 0],
        name,
      };
    }

    // @ts-ignore
    const coords = feature.getGeometry().getCoordinates() || [0, 0];

    const point = toWgs84(turfPoint(coords)).geometry.coordinates;

    const item: VertexData = {
      id: vertex.id,
      name,
      coordinates: [point[0], point[1]],
    };

    return item;
  });
};

export const getSelectedNodeId = (selectedNode: IVertex) => {
  const { id } = selectedNode;

  const idView = id.toString().split('_').join(',');
  const len = idView.length;

  return len >= MAX_NAME_CHARS_LENGTH
    ? `{ ${`${idView.slice(0, MAX_NAME_CHARS_LENGTH)}...`} }`
    : `{ ${idView} }`;
};

export const getTooltip = ({ object, layer }: PickingInfo) => {
  if (!object || !layer) {
    return null;
  }

  if (layer.id === TRAFFIC_LAYER_VERTEX) {
    return `Узел {${object.name || object.id}}`;
  }

  if (layer.id === TRAFFIC_LAYER_LINK) {
    return `{${object.id1}} - {${object.id2}} ${object.count} [проездов]`;
  }

  return null;
};

export const getNodeIdView = (item: LinkSegmentData) => {
  const { id2 } = item;

  const len = id2.toString().length;
  const maxLen = 30;

  const view =
    len >= maxLen ? id2.toString().slice(0, maxLen) + '...' : id2.toString();

  return view;
};
