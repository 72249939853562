import { useEffect, useState } from 'react';

import api from '../../../api';
import { IRegionData } from '../../../api/server/web/web.zod';
import NOTICE from '../../../constants/notificationConstants';
import { sendError } from '../../../helpers/sendError';
import { IUserData } from '../../../stores/userDataStore/userDataStore';
import notification from '../../ui-kit/Notification/Notification';

interface useRegionsProps {
  user: N<IUserData>;
  resetUserData: () => void;
  handlePickRegion: (region: IRegionData) => void;
}

const APP_PATH = 'RegionSwither.hooks.useRegions';

const useRegions = (props: useRegionsProps) => {
  const { user, resetUserData, handlePickRegion } = props;

  const [availableRegions, setAvailableRegions] = useState<
    IRegionData[] | null
  >(null);
  const [erroredRegionsId, setErroredRegionsId] = useState<number[]>([]);

  const handleSavedRegionId = async () => {
    try {
      const savedRegionId: number = localStorage?.regionId;

      if (!savedRegionId || !user) return false;

      const avalibleIds = user?.regions_id;

      if (!avalibleIds.includes(Number(savedRegionId))) return false;

      const regionData = await api.server.web.fetchRegionById(
        savedRegionId,
        APP_PATH
      );

      handlePickRegion(regionData);

      return true;
    } catch (e: any) {
      return false;
    }
  };

  const loadAvailableRegionsList = async () => {
    if (!user) return;

    const avalibleIds: number[] = user?.regions_id;

    if (!avalibleIds || !avalibleIds.length) {
      notification.warning('USER_AVALIBLE_REGIONS_UNDEFINED');

      return resetUserData();
    }

    const loadedRegions: IRegionData[] = [];
    const erroredRegions: number[] = [];

    for (const regionId of avalibleIds) {
      try {
        const regionData = await api.server.web.fetchRegionById(
          regionId,
          APP_PATH
        );

        loadedRegions.push(regionData);
      } catch (e: any) {
        notification.destroy('REGIONS_LOADING_ERROR');
        erroredRegions.push(regionId);
        notification.error('REGIONS_LOADING_ERROR');
        console.error(`Ошибка загрузки региона ${regionId}`, e);
        sendError(NOTICE.REGIONS_LOADING_ERROR.message, e, APP_PATH);
      }
    }

    setAvailableRegions(loadedRegions);
    setErroredRegionsId(erroredRegions);
  };

  const checkSaveRegions = async () => {
    const haveSaved = await handleSavedRegionId();

    if (haveSaved) return;
    loadAvailableRegionsList();
  };

  useEffect(() => {
    if (availableRegions?.length !== 1) return;
    if (erroredRegionsId.length) return;

    handlePickRegion(availableRegions[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableRegions, erroredRegionsId]);

  useEffect(() => {
    if (!user) return;

    checkSaveRegions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return { availableRegions, erroredRegionsId };
};

export default useRegions;
