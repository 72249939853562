import { isNumber } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { ZOOM } from '../../../../../constants/mapConstants';
import { handleZoomUI } from '../../helpers/handleZoomUI';
import { getViewProps } from '../../helpers/helpers';
import getDisabledZoom from '../helpers/getDisabledZoom';
import { UiZoomBtnsProps } from '../UiZoomBtns/UiZoomBtns';
import { ZoomBtnsWrapperProps } from '../ZoomBtnsWrapper';

const useZoom = ({ map, zoomProps, handleZoomProps }: ZoomBtnsWrapperProps) => {
  const [disabled, setDisabled] = useState<UiZoomBtnsProps['disabled']>({
    disabledIncrease: false,
    disabledDecrease: true,
    disabledReset: true,
  });

  const handlers = useMemo<N<UiZoomBtnsProps['handlers']>>(
    () =>
      map
        ? {
            increase: () => {
              const { currZoom, resolution } = getViewProps(map);

              if (!isNumber(currZoom)) return;

              const newZoom = currZoom + ZOOM.STEP;

              handleZoomUI(newZoom, map, (isEnd) => {
                isEnd && handleZoomProps?.(newZoom, resolution ?? 1);
              });
            },
            decrease: () => {
              const { currZoom, resolution } = getViewProps(map);

              if (!isNumber(currZoom)) return;

              const newZoom = currZoom - ZOOM.STEP;

              handleZoomUI(newZoom, map, (isEnd) => {
                isEnd && handleZoomProps?.(newZoom, resolution ?? 1);
              });
            },
            reset: () => {
              handleZoomUI(zoomProps.minZoom, map, (isEnd) => {
                isEnd &&
                  handleZoomProps?.(
                    zoomProps.minZoom,
                    getViewProps(map).resolution ?? 1
                  );
              });
            },
          }
        : null,
    [handleZoomProps, map, zoomProps.minZoom]
  );

  useEffect(() => {
    if (!map) return;

    const { currZoom } = getViewProps(map);

    const handleZoomChange = () => {
      const newZoom = map.getView().getZoom();

      setDisabled(getDisabledZoom(map, zoomProps));

      if (currZoom !== newZoom) {
        setDisabled(getDisabledZoom(map, zoomProps));
      }
    };

    map.on('moveend', handleZoomChange);

    return () => {
      map.un('moveend', handleZoomChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  return { disabled, handlers };
};

export default useZoom;
