import { SearchOutlined } from '@ant-design/icons';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import classNames from 'classnames';

import {
  ITableColumn,
  TableData,
} from '../../../../../../ts/models/table.model';
import { ITable } from '../../../Table';
import TableSearch from '../../../TableSearch/TableSearch';

import { addFilters } from './addFilters';
import { getSortedFunc } from './getSortedFunc';
import { prepareColumnDataView } from './prepareColumnDataView/prepareColumnDataView';

type ColumnProps = Omit<ITableColumn, 'multiple'> & {
  multiple: number;
};

export const getColumnProps = (
  column: ColumnProps,
  tableData: TableData,
  collaborationRender: ITable['collaborationRender'],
  searchIconClassName: string
) => {
  if (column.width && column.dataIndex !== 'collaboration') {
    column.width = undefined;
  }

  prepareColumnDataView(column, tableData, collaborationRender);

  column.ellipsis = {
    showTitle: true,
  };

  if (column.dataIndex === 'collaboration') {
    column.fixed = 'right';
  }

  if (!column.filterSettings) return column;

  const { searched, sorted } = column.filterSettings;

  addFilters(column, tableData);

  if (sorted) {
    column.sorter = getSortedFunc(column);
  }

  if (searched) {
    column.filterDropdown = ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: FilterDropdownProps) => (
      <TableSearch
        column={column}
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
        clearFilters={clearFilters}
        confirm={confirm}
        title={column.title}
      />
    );

    column.filterIcon = (filtered: boolean) => {
      const iconStyles = classNames({
        [searchIconClassName]: filtered,
      });

      return <SearchOutlined className={iconStyles} />;
    };
  }
};
