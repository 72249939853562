import { useState } from 'react';

import ButtonsModelBlock from './ButtonsModelBlock/ButtonsModelBlock';
import { ThreadType } from './constants/constants';
import ThreadWrapper from './ThreadWrapper/ThreadWrapper';

import styles from './TrafficModelThread.module.scss';

const TrafficModelThread = () => {
  const [threadType, setThreadType] = useState<ThreadType>(ThreadType.D3);

  return (
    <div className={styles.thread}>
      <h3>Транспортная модель:</h3>
      <div className={styles.model}>
        <ThreadWrapper threadType={threadType} />
      </div>
      <ButtonsModelBlock
        threadType={threadType}
        setThreadType={setThreadType}
      />
    </div>
  );
};

export default TrafficModelThread;
