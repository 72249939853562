import BaseLayer from 'ol/layer/Base';
import Heatmap from 'ol/layer/Heatmap';
import VectorLayer from 'ol/layer/Vector';
import VectorImageLayer from 'ol/layer/VectorImage';

import { TMap } from '../../../stores/mapStore/mapStore.model';
import { WebGLLayer } from '../helpers';

const LAYER_ID_PROPERTY = 'id';

export interface ILayerInScaleRangeProps {
  map?: TMap;
  id: string;
}

export const isLayerInScaleRange = (
  props: ILayerInScaleRangeProps
): boolean => {
  const { map, id } = props;

  if (!map) {
    return false;
  }

  const layer = map
    ?.getLayers()
    .getArray()
    .find((layer: BaseLayer) => layer.get(LAYER_ID_PROPERTY) === id);

  if (
    layer &&
    (layer instanceof VectorLayer ||
      layer instanceof WebGLLayer ||
      layer instanceof VectorImageLayer ||
      layer instanceof Heatmap)
  ) {
    const minResolution = layer.getMinResolution();
    const maxResolution = layer.getMaxResolution();

    const resolution = map.getView().getResolution() ?? -1;

    return resolution <= maxResolution && resolution >= minResolution;
  }

  return false;
};
