import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../../stores/rootStore/rootStore';
import DtList from '../DtList/DtList';

import DtVideoPreview from './DtVideoPreview/DtVideoPreview';

import styles from './DetectorsWrapper.module.scss';

interface IDetectorsWrapperProps {
  isTl: boolean;
  idObj: number;
  isNoImageDt: boolean;
}

const DetectorsWrapper: FC<IDetectorsWrapperProps> = ({
  isTl,
  idObj,
  isNoImageDt,
}) => {
  const { activeDtId } = rootStore.detailedStore;

  const dtId = isTl ? activeDtId : idObj;

  return (
    <div className={styles.content}>
      {<DtVideoPreview id={dtId} isTL={isTl} isNoImageDt={isNoImageDt} />}
      {isTl ? (
        <div className={styles.listDt}>
          <DtList tlId={idObj} />
        </div>
      ) : null}
    </div>
  );
};

export default observer(DetectorsWrapper);
