import { notification } from 'antd';
import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';

import { CONFIG } from '../constants/configConstants';
import onRejectedError from '../helpers/onRejectedError';

const HEADERS: AxiosRequestHeaders = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};

const requestSettings: AxiosRequestConfig = {
  baseURL: CONFIG.EPUTS_URL,
  headers: HEADERS,
};

const http = axios.create(requestSettings);

http.interceptors.response.use((config) => {
  config.status !== 502 &&
    config.status !== 0 &&
    notification.destroy('error-notification');

  return config;
}, onRejectedError);

export default http;
