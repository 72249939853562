import { Form, Slider } from 'antd';
import { observer } from 'mobx-react-lite';

import { COLORS } from '../../../constants/colorsConstants';
import { DEFAULT_CIRCLE } from '../../../constants/constructorConstants';
import rootStore from '../../../stores/rootStore/rootStore';
import CircularSlider from '../../ui-kit/CircularSlider/CircularSlider';
import getSliderProps from '../helpers/getSliderProps';

import styles from './CircleParamsConstructor.module.scss';

const { BORDER_WIDTH } = DEFAULT_CIRCLE;

function CircleCenterConstructor() {
  const {
    setConstructorData,
    // circleDiameterRate,
    circleBorderWidth,
    circleHue,
  } = rootStore.constructorStore;

  // It will turn on again in VMNE-2600!
  // const { isCrossroadBorder } = rootStore.mapDataStore;

  // const handleDiameter = (val: number) =>
  //   setConstructorData('circleDiameterRate', val);
  const handleBorderWidth = (val: number) =>
    setConstructorData('circleBorderWidth', val);
  const handleHue = (val: number) => setConstructorData('circleHue', val);

  // It will turn on again in VMNE-2600!
  // const diameterTitle = !isCrossroadBorder
  //   ? 'Необходимо увеличить масштаб для активации настройки'
  //   : '';

  return (
    <div className={styles.settingsContent}>
      {/* 

      It will turn on again in VMNE-2600!
      
      <Tooltip placement="left" title={diameterTitle}>
        <Form.Item label="Коэффициент диаметра круга:">
          <Slider
            {...getSliderProps(15, 25, DIAMETER_RATE, 1)}
            onChange={handleDiameter}
            value={circleDiameterRate}
            disabled={!isCrossroadBorder}
          />
        </Form.Item>
      </Tooltip> */}

      <Form.Item label="Ширина границы:">
        <Slider
          {...getSliderProps(0, 50, BORDER_WIDTH, 1)}
          onChange={handleBorderWidth}
          value={circleBorderWidth}
        />
      </Form.Item>

      <Form.Item label="Оттенок:">
        <div className={styles.circularSingle}>
          <CircularSlider
            color={COLORS.TURQUOISE}
            label="УГОЛ"
            onChange={handleHue}
            value={circleHue}
          />
        </div>
      </Form.Item>
    </div>
  );
}

export default observer(CircleCenterConstructor);
