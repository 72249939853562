import { FC } from 'react';

import { MapInfoItem } from '../helpers/getMapInfo';

import styles from './ContentCard.module.scss';

const ContentCard: FC<MapInfoItem> = ({ title, data }) => {
  return (
    <div className={styles.typeDesignation}>
      <div className={styles.title}>
        <h3>{title}: </h3>
      </div>
      <div className={styles.content}>
        {data.map(({ value, icon }) => (
          <div key={value} className={styles.titleIco}>
            <div className={styles.ico}>{icon}</div>
            <p>- {value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContentCard;
