import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';

import { findById } from '../../../../helpers/findBy';
import rootStore from '../../../../stores/rootStore/rootStore';
import NoData from '../../../ui-kit/Errors/NoData/NoData';
import SelectPlanNumber from '../components/SelectPlanNumber/SelectPlanNumber';
import { BasicControlTabProps } from '../model/tlManagement.model';

import usePlanSelect from './hooks/usePlanSelect';
import useTLPrograms from './hooks/useTLPrograms';
import ModalPlan from './ModalPlan/ModalPlan';
import TimeCardRequiredDuration from './TimeCardRequiredDuration/TimeCardRequiredDuration';

import styles from './PlanSelect.module.scss';

const NO_PLAN = 'План не найден';

const PlanSelect: FC<BasicControlTabProps> = ({ id }) => {
  const { tls } = rootStore.mapDataStore;

  const programInfo = useTLPrograms(id);

  const {
    timeCardProps,
    selectedPlan,
    setSelectedPlan,
    selectedTime,
    clearPlanSelect,
  } = usePlanSelect();

  const activeTL = useMemo(() => findById(tls, id), [tls, id]);

  if (!activeTL) return <NoData />;

  return (
    <div className={styles.container}>
      <h3>Текущий план: {activeTL.tlGeneral.progViewName ?? NO_PLAN}</h3>
      <SelectPlanNumber
        id={id}
        programInfo={programInfo}
        selectedPlan={selectedPlan}
        setSelectedPlan={setSelectedPlan}
      />
      <TimeCardRequiredDuration {...timeCardProps} />
      <ModalPlan
        activeTL={activeTL}
        selectedPlan={selectedPlan.planId}
        selectedTime={selectedTime}
        clearPlanSelect={clearPlanSelect}
        programInfo={programInfo}
      />
    </div>
  );
};

export default observer(PlanSelect);
