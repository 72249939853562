import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../stores/rootStore/rootStore';
import IntancyGraph from '../IntancyGraph/IntancyGraph';

import styles from './GraphCard.module.scss';

interface GraphCardProps {
  isTl: boolean;
  id: number;
}

const GraphCard: FC<GraphCardProps> = ({ id, isTl }) => {
  const { activeDtId } = rootStore.detailedStore;

  const dtId = isTl ? activeDtId : id;

  return (
    <div className={styles.mainGraphCard}>
      <IntancyGraph dtId={dtId} />
    </div>
  );
};

export default observer(GraphCard);
