import { ConfigProvider, Table } from 'antd';
import { observer } from 'mobx-react-lite';

import rootStore from '../../../../stores/rootStore/rootStore';

import { HISTORY_COLUMNS } from './constants/constants';
import getCustomTheme from './helpers/getCustomTheme';
import getTableData from './helpers/getTableData.ts';

import styles from './HistoryDetailed.module.scss';

const HistoryDetailed = () => {
  const { profileHistory } = rootStore.constructorStore;
  const { isDark } = rootStore.uiStore;

  return (
    <ConfigProvider theme={getCustomTheme(isDark)}>
      <Table
        dataSource={getTableData(profileHistory)}
        columns={HISTORY_COLUMNS}
        pagination={false}
        className={styles.table}
      />
    </ConfigProvider>
  );
};

export default observer(HistoryDetailed);
