import { Button, Tag } from 'antd';
import bytes from 'bytes';
import { observer } from 'mobx-react-lite';
import { FC, MouseEventHandler } from 'react';
import { AiOutlineDelete, AiOutlineDownload } from 'react-icons/ai';

import rootStore from '../../../../../../stores/rootStore/rootStore';
import { TLDocumentsCode } from '../../../../../../ts/enums/userData';
import Popover from '../../../../../ui-kit/Popover/Popover';

import styles from './BtnsBlock.module.scss';

interface IBtnsBlock {
  handleDownLoad: MouseEventHandler<HTMLAnchorElement> &
    MouseEventHandler<HTMLButtonElement>;
  handleDeleteFile: MouseEventHandler<HTMLAnchorElement> &
    MouseEventHandler<HTMLButtonElement>;
  isActive: boolean;
  sizeFile?: number;
}

const BtnsBlock: FC<IBtnsBlock> = ({
  handleDownLoad,
  handleDeleteFile,
  isActive,
  sizeFile,
}) => {
  const { interfaceTipsDelay } = rootStore.uiStore;
  const { hasAccess } = rootStore.userDataStore;
  const isDeleteBtn = hasAccess(TLDocumentsCode.deleteBtn);

  const popoverProps = {
    tipsDelay: interfaceTipsDelay,
    placement: 'top',
  } as const;

  return (
    <div className={styles.btnsRow}>
      {sizeFile && (
        <Tag className={styles.tag} color={isActive ? 'green' : 'default'}>
          {bytes(sizeFile)}
        </Tag>
      )}
      <Popover {...popoverProps} content="Скачать">
        <Button
          icon={
            <AiOutlineDownload color={isActive ? 'var(--whity)' : undefined} />
          }
          className={styles.downloadBtn}
          onClick={handleDownLoad}
        />
      </Popover>
      {isDeleteBtn && (
        <Popover {...popoverProps} content="Удалить">
          <Button
            icon={<AiOutlineDelete />}
            className={styles.deleteBtn}
            danger
            onClick={handleDeleteFile}
          />
        </Popover>
      )}
    </div>
  );
};

export default observer(BtnsBlock);
