import { IDisabledTimePickerProps } from '../helpers/disabledTimePicker';

export const COMMANDS_VALUES = {
  SET_OBJECT_MODE: 'управление СО',
  SET_OBJECT_PHASE_NOW: 'удержание фазы',
  SET_OBJECT_PHASE: 'удержание фазы по времени',
  SET_OBJECT_PLAN: 'выбор плана',
  SET_OBJECT_PLAN_TIME_ON_SCHEDULER: 'выбор плана по времени',
  SET_OBJECT_SYNC_PLAN: 'координированное управление',
  SET_OBJECT_PHASE_FLOW: 'изменение времени фаз текущего плана',
};

export const DEFAULT_DISABLED_TIME_DURATION = 5;
export const MINUTE_SECONDS = 60;

export const INITIAL_TIME: IDisabledTimePickerProps = {
  h: [1, 24],
  m: [31, 60],
  s: [1, 60],
};
