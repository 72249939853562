/* eslint-disable jsx-a11y/no-static-element-interactions */
import classNames from 'classnames';
import { isString, uniqueId } from 'lodash';
import { FC } from 'react';
import { MdTimeline } from 'react-icons/md';
import type { Props } from 'recharts/types/component/Legend';

import { ICircleChartProps } from '../../models/circleChart.model';

import { getTextLegend } from './helpers/getTextLegend';

import styles from './CustomLegend.module.scss';

export enum HandleType {
  Leave = 'leave',
  Enter = 'enter',
}

export type LegendProps = Omit<Props, 'payload'> & {
  payload?: {
    value: unknown;
    color: Nullish<string>;
    payload?: {
      percent: Nullish<number>;
      value?: number;
      name: string;
    };
  }[];
};

interface CustomLegendProps extends LegendProps {
  hoverLine: string;
  activeSectors: U<string[]>;
  isOneRecord: boolean;
  handleClickSector: ICircleChartProps['handleClickSector'];
  setHoverLine: SetState<string>;
}

const CustomLegend: FC<CustomLegendProps> = ({
  payload,
  activeSectors,
  isOneRecord,
  handleClickSector,
  hoverLine,
  setHoverLine,
}) => {
  return (
    <div className={styles.container}>
      {payload?.map(({ value, color, payload }) => {
        const handleHover = (eventType: HandleType) =>
          isString(value) &&
          setHoverLine(eventType === HandleType.Enter ? value : '');

        const handleClick = () =>
          payload?.name &&
          !isOneRecord &&
          handleClickSector?.(payload?.name, payload?.value);

        const isSelectedSector = activeSectors?.some(
          (el) => el === payload?.name
        );

        const isHover = hoverLine === value || !hoverLine;
        const isShow = activeSectors?.length
          ? isSelectedSector || hoverLine === value
          : isHover;

        const spanStyle = classNames({
          [styles.title]: true,
          [styles.pointer]: handleClickSector,
          [styles.hover]: !isShow,
        });

        const mainText = getTextLegend(payload, value);

        const key = isString(value) ? value : uniqueId();
        const correctedColor = color ?? undefined;

        return (
          <span
            key={key}
            className={spanStyle}
            onMouseEnter={() => handleHover(HandleType.Enter)}
            onMouseLeave={() => handleHover(HandleType.Leave)}
            onClick={handleClick}
          >
            <MdTimeline color={correctedColor} />
            <p style={{ color: correctedColor }}>{mainText}</p>
          </span>
        );
      })}
    </div>
  );
};

export default CustomLegend;
