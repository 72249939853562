import { App } from 'antd';
import { isNumber } from 'lodash';
import { RefObject, useMemo, useState } from 'react';

import useFullscreen from '../../../../hooks/useFullscreen';
import { getPopupContainer } from '../../../ui-kit/Popover/helpers/getPopupContainer/getPopupContainer';
import CameraInfo from '../../CameraInfo/CameraInfo';
import { showCameraOnMap } from '../../helpers/showCameraOnMap/showCameraOnMap';
import { ActiveStream, VideoCameraProps } from '../../models/VideoCamera.model';

const MODAL_WIDTH = 500;

export interface UseCameraHandlersProps {
  cameraData: U<VideoCameraProps>;
  cameraWrapperRef: RefObject<HTMLDivElement>;
  timeZone: N<number>;
  activeStream: N<ActiveStream>;
}

const useCameraHandlers = ({
  cameraData,
  cameraWrapperRef,
  timeZone,
  activeStream,
}: UseCameraHandlersProps) => {
  const { modal } = App.useApp();

  const [isMouseEnter, setIsMouseEnter] = useState(false);
  const [isDtLanes, setIsDtLanes] = useState(false);

  const { open, close, status } = useFullscreen(cameraWrapperRef);

  const isDisabledPin = useMemo(
    () =>
      !cameraData?.isMapAction ||
      !cameraData?.deviceId ||
      !isNumber(cameraData?.longitude) ||
      !isNumber(cameraData?.latitude),
    [
      cameraData?.deviceId,
      cameraData?.isMapAction,
      cameraData?.latitude,
      cameraData?.longitude,
    ]
  );

  const handleToggleFullScreen = () => {
    if (status) {
      close();
    } else {
      open();
    }
  };

  const handleEnter = () => {
    setIsMouseEnter(true);
  };

  const handleLeave = () => {
    setIsMouseEnter(false);
  };

  const onDtLanesClick = () => setIsDtLanes((prev) => !prev);

  const onPinClick = () => {
    if (!cameraData?.isMapAction) return;

    showCameraOnMap(cameraData);
    close();
  };

  const handleInfo = () => {
    modal.info({
      title: 'Информация о камере',
      content: (
        <CameraInfo
          camera={cameraData}
          timeZone={timeZone}
          activeStream={activeStream}
        />
      ),
      width: MODAL_WIDTH,
      getContainer: getPopupContainer(cameraWrapperRef, status)?.func,
    });
  };

  return {
    status,
    isDtLanes,
    isMouseEnter,
    isDisabledPin,
    handleInfo,
    handleToggleFullScreen,
    handleEnter,
    handleLeave,
    onDtLanesClick,
    onPinClick,
  };
};

export default useCameraHandlers;
