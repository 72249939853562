import { NOT_WORK_STATES, SPECIAL_MODES } from '../../constants/commands';
import { BasicMapDataZod } from '../../ts/models/mapObject.model';
import { TLGeneral, TLStatus } from '../../ts/models/tl.model';
import { NNInt } from '../zod/utils.zod';

import { MessageType, getZodMessageCustom } from './getZodMessageCustom';
import { ObjType } from './validateData';

interface IShema {
  tlGeneral: TLGeneral;
  tlStatus: TLStatus;
}

export const getValidate = <T extends BasicMapDataZod>(
  Schema: any,
  objType: string,
  el: T
) =>
  objType !== ObjType.tl
    ? Schema.safeParse(el)
    : Schema.refine(
        ({ tlStatus: { mode, state }, tlGeneral: { cycleTime } }: IShema) =>
          SPECIAL_MODES.includes(mode) || NOT_WORK_STATES.includes(state)
            ? NNInt.nullable().safeParse(cycleTime).success
            : NNInt.safeParse(cycleTime).success,
        getZodMessageCustom({
          nameValue: 'cycleTime',
          nameParent: 'TlGeneral',
          messageType: MessageType.NotAccessNull,
        })
      ).safeParse(el);
