import { TableProps } from 'antd/lib';

import { TACTS } from '../../../../constants/signalsConstants';
import {
  IProcessedDataInitial,
  SignalPrograms,
} from '../../../../ts/models/signalPrograms.model';
import { TL } from '../../../../ts/models/tl.model';
import { SelectedTime } from '../PlanSelect/models/plansControl.model';

export interface IPhasesValue {
  valueSelect: N<string>;
  radioPhasesValue: number;
}

export interface ModalPhaseProps {
  activeTL: TL;
  isChecked: boolean;
  selectedTimeInfo: SelectedTime;
  setSelectedTimeInfo: SetState<SelectedTime>;
  setIsChecked: SetState<boolean>;
}

export interface IResPhases {
  id: number;
  title: string;
  body: string;
  code: number;
  tip: string;
  urlImage: string;
  addInfo: string;
}

export interface ITacts {
  color: string;
  main: number;
  tact: number;
  time: number;
}

export interface IPhasesData {
  id: number;
  key: string;
  phase: number;
  time: number;
  spanTime: number;
  spanGreenBlinkTime: number;
  spanYellowTime: number;
  spanRedYellowTime: number;
}

export interface IHeaderItem {
  dataIndex: string;
  key: string;
  title: JSX.Element;
  editable: boolean;
  visible: boolean;
  onCell?: NonNullable<TableProps['columns']>[number]['onCell'];
}

export interface ISelectLabels {
  value: U<string>;
  label: JSX.Element;
}

export const isCorrectProgram = (
  program: SignalPrograms
): program is IProcessedDataInitial =>
  program.tlTacts?.every(
    ({ tactType }) => tactType === null || TACTS.some((el) => el === tactType)
  ) ?? true;
