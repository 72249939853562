/* eslint-disable jsx-a11y/no-autofocus */
import {
  Form,
  Button,
  Checkbox,
  notification,
  Input,
  type FormItemProps,
} from 'antd';
import classNames from 'classnames';
import { FC, useState } from 'react';

import { ReactComponent as Lock } from '../../../assets/icons/ic_lock_2.svg';
import { ReactComponent as Profile } from '../../../assets/icons/ic_profile.svg';
import NOTICE from '../../../constants/notificationConstants';
import useAuth from '../../../hooks/useAuth';

import styles from './LoginForm.module.scss';

const MAX_LENGTH = 50;

const RULES = [
  { required: true, message: 'Обязательное поле' },
  { whitespace: true, message: 'Поле не должно содержать пробелы' },
  { max: MAX_LENGTH, message: 'Поле не должно быть больше 50 символов' },
];

interface LoginFormFields {
  login: string;
  password: string;
  remember: boolean;
}

const LoginForm: FC = () => {
  const [form] = Form.useForm<LoginFormFields>();
  const { loginHandler, checkAuthHandler } = useAuth();
  const [isIncorrect, setIsIncorrect] = useState(false);
  const [authPending, setAuthPending] = useState(false);

  const onChange = () => setIsIncorrect(false);

  const handleLogin = async () => {
    const { login, password, remember } = form.getFieldsValue();

    setAuthPending(true);

    const authSuccessful = await loginHandler(
      login.trim(),
      password.trim(),
      remember
    );

    if (!authSuccessful) {
      setAuthPending(false);

      return setIsIncorrect(true);
    }

    notification.success(NOTICE.LOGIN_SUCCESSFUL);

    await checkAuthHandler();
    setAuthPending(false);
    setIsIncorrect(false);
  };

  const isSubmitButtonDisabled = isIncorrect || authPending;

  const submitButtonStyle = classNames({
    [styles.btn]: true,
    [styles.disabled]: isSubmitButtonDisabled,
  });

  const inputFormItemProps: FormItemProps = {
    rules: RULES,
    help: isIncorrect ? 'Неверное имя пользователя или пароль' : undefined,
    validateStatus: isIncorrect ? 'error' : (undefined as U<'error'>),
    className: styles.inputContainer,
  };

  return (
    <section className={styles.container}>
      <Form
        form={form}
        layout="vertical"
        className={styles.wrapper}
        disabled={authPending}
        onFieldsChange={onChange}
        onFinish={handleLogin}
      >
        <Form.Item label="Логин" name="login" {...inputFormItemProps}>
          <Input
            className={styles.input}
            disabled={authPending}
            prefix={<Profile className={styles.icon} />}
            autoFocus={true}
          />
        </Form.Item>
        <Form.Item label="Пароль" name="password" {...inputFormItemProps}>
          <Input.Password
            prefix={<Lock className={styles.icon} />}
            className={styles.input}
          />
        </Form.Item>
        <Form.Item name="remember" valuePropName="checked" initialValue={true}>
          <Checkbox defaultChecked>Запомнить меня</Checkbox>
        </Form.Item>
        <Button
          className={submitButtonStyle}
          type="primary"
          htmlType="submit"
          loading={authPending}
          disabled={isSubmitButtonDisabled}
        >
          Войти в систему
        </Button>
      </Form>
    </section>
  );
};

export default LoginForm;
