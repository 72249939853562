import {
  ICsdData,
  ITimePoints,
} from '../../../../../ts/models/signalPrograms.model';
import NewSignalProgram from '../../../../NewSignalProgram/NewSignalProgram';

export interface SgnProgramWrapperProps {
  id: number;
  csdData: ICsdData;
  phasesSet: ITimePoints[];
  title: string;
  phaseGroupNum: N<number>;
  addToRefs: (htmlEl: N<HTMLDivElement>, index: number) => void;
}

const SgnProgramWrapper = ({
  id,
  csdData,
  phasesSet,
  title,
  phaseGroupNum,
  addToRefs,
}: SgnProgramWrapperProps) => {
  return (
    <div
      ref={(htmlEl) => {
        addToRefs(htmlEl, id);
      }}
      id={phaseGroupNum?.toString() ?? ''}
    >
      <NewSignalProgram
        id={id}
        isStatic={true}
        csdData={csdData}
        phasesSet={phasesSet}
        title={title}
        phaseGroupNum={phaseGroupNum}
      />
    </div>
  );
};

export default SgnProgramWrapper;
