import { cloneDeep } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';

import {
  ANIMATION_DURATION_CLUSTER,
  NULLABLE_ANIMATION_DURATION,
} from '../../../constants/mapClusterConstants';
import rootStore from '../../../stores/rootStore/rootStore';
import { SelectedFeatures } from '../../../stores/scriptsControlStore/scriptsControlStore.model';
import { getDeviceInfo } from '../../Map/helpers/getDeviceInfo';
import { IFeaturesArr } from '../../Map/helpers/getFeaturesCluster';
import useDynamicOverlays from '../../Map/hooks/useDynamicOverlays';
import { getDeviceColor } from '../../MapBtns/MapInfoWrapper/InfoBtn/ContentInfo/helpers/getMapInfo';
import { getUnlinkedPopup } from '../../Mapper/helpers/getUnlinkedFeatures';

import { getCrosshairFeatures } from './helpers/getCrosshairFeatures';
import MarkerContent from './MarkerContent/MarkerContent';

import styles from './MarkerPopup.module.scss';

export const isInfoDataArrayNotNull = (
  value: (SelectedFeatures | null)[]
): value is SelectedFeatures[] => {
  return value.every((el) => el !== null);
};

const MarkerPopup: FC = () => {
  const { map } = rootStore.mapStore;
  const { infoData } = rootStore.uiStore;
  const { selectedFeatureSomeArray, isMultipleSelect } =
    rootStore.scriptsControlStore;
  const { center, currentZoom, isCrossroadBorder } = rootStore.mapDataStore;
  const { isConstructor } = rootStore.constructorStore;
  const {
    isClusterWithClickedFeatures,
    isClusterOpen,
    selectedClusters,
    features,
  } = rootStore.clustersStore;

  const [crosshairFeatures, setCrosshairFeatures] = useState<IFeaturesArr[]>(
    []
  );

  useEffect(() => {
    const infoDataArray = !isMultipleSelect
      ? [infoData]
      : cloneDeep(selectedFeatureSomeArray);

    if (
      !map ||
      !features.length ||
      !infoDataArray.length ||
      !isInfoDataArrayNotNull(infoDataArray)
    ) {
      setCrosshairFeatures([]);

      return;
    }

    const durationOpenedCluster =
      isClusterWithClickedFeatures && isClusterOpen
        ? ANIMATION_DURATION_CLUSTER
        : NULLABLE_ANIMATION_DURATION;

    const animationId = setTimeout(() => {
      const newCrosshairFeatures = getCrosshairFeatures(
        map,
        features,
        infoDataArray,
        selectedClusters
      );

      const newFeatures =
        !isConstructor && isCrossroadBorder && !isMultipleSelect
          ? getUnlinkedPopup(newCrosshairFeatures)
          : newCrosshairFeatures;

      setCrosshairFeatures(newFeatures);
    }, durationOpenedCluster);

    return () => {
      clearTimeout(animationId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isMultipleSelect,
    infoData,
    features,
    selectedFeatureSomeArray.length,
    currentZoom,
    map,
    isClusterOpen,
    isClusterWithClickedFeatures,
    selectedClusters,
    selectedFeatureSomeArray,
  ]);

  const {
    elementRefs,
    isRemovedOverlays,
    featuresInExtent,
    isReadyElementRefs,
  } = useDynamicOverlays({
    map,
    featuresWithOverlays: crosshairFeatures,
    center,
    isOverlaysForAllFeatures: false,
    isInsertFirstOverlay: true,
  });

  if (
    !(
      isReadyElementRefs &&
      featuresInExtent.length &&
      isRemovedOverlays &&
      crosshairFeatures.length &&
      elementRefs.length
    )
  )
    return null;

  return (
    <div>
      {featuresInExtent.length &&
        featuresInExtent.map(({ feature, isSelectFeature }, i) => {
          const { id, system, mode, deviceStatus } = getDeviceInfo(feature);

          const color = getDeviceColor(deviceStatus);

          return (
            <div
              className={styles.container}
              key={`markersSelected${id}`}
              ref={elementRefs[i]}
            >
              <MarkerContent
                system={system}
                colorWithMode={color}
                mode={mode}
                isSelectFeature={isSelectFeature}
              />
            </div>
          );
        })}
    </div>
  );
};

export default observer(MarkerPopup);
