import { FC, RefObject, useEffect } from 'react';

import styles from './CameraMgpeg.module.scss';

interface LoadPropertiesOptions {
  onLoad: () => void;
  onError: () => void;
}

interface ICameraMgpeg {
  src: string;
  cameraRef: RefObject<HTMLImageElement>;
  loadProperties: LoadPropertiesOptions;
}

const CameraMgpeg: FC<ICameraMgpeg> = ({ src, cameraRef, loadProperties }) => {
  const { onError, onLoad } = loadProperties;

  useEffect(() => {
    let imgRef: N<HTMLImageElement> = null;

    cameraRef.current && (imgRef = cameraRef.current);

    return () => {
      imgRef?.removeAttribute('src');
    };
  }, [cameraRef]);

  return (
    <img
      className={styles.img}
      src={src}
      alt="MGPEG"
      data-testid="mjpeg"
      onLoad={onLoad}
      onError={onError}
      ref={cameraRef}
      loading="lazy"
    />
  );
};

export default CameraMgpeg;
