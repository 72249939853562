import { COLORS } from '../../../../../../constants/colorsConstants';

const { DISABLED, BG_TINT, WHITE } = COLORS;

interface CustomShapeProps {
  fill?: string;
  x?: SN;
  y?: SN;
  width?: SN;
  height?: SN;
  index?: number;
  activeIndex: number;
  background?: {
    x?: SN;
    y?: SN;
    width?: SN;
    height?: SN;
  };
}

const CustomShape = ({
  fill,
  height,
  width,
  x,
  y,
  activeIndex,
  index,
  background = {},
}: CustomShapeProps) => {
  const isActive = index === activeIndex;
  const bgColor = isActive ? DISABLED : BG_TINT;

  return (
    <svg>
      <rect {...background} fill={bgColor} />
      <rect x={x} y={y} width={width} height={height} fill={fill} />
      {isActive && (
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          fill={WHITE}
          fillOpacity="0.5"
        />
      )}
    </svg>
  );
};

export default CustomShape;
