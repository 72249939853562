import { GrInfo } from 'react-icons/gr';

import { isLayerInScaleRange } from '../../../../apiGIS/layers/utils/isLayerInScaleRange';
import { LAYER_SCALE_CONTENT } from '../constants';
import { ILayerDataNode } from '../layers.model';

import LayerGroupNode from './LayerGroupNode';
import LayerNode from './LayerNode';

import styles from '../LayersButton.module.scss';

const LayerNotInRangeTip = () => (
  <div className={styles.nodeTipContainer}>
    <GrInfo className={styles.nodeTipIcon} />
    <div>{LAYER_SCALE_CONTENT.ACTION_TIP}</div>
  </div>
);

const LayerTreeItem = (props: ILayerDataNode) => {
  if (!props) {
    return '';
  }

  const { item, children, map, isGroup } = props;

  if (isGroup) {
    return <LayerGroupNode data={props} />;
  }

  if (!item) {
    return '';
  }

  if (children) {
    return <div className={styles.folderTitle}>{item.alias}</div>;
  }

  const { id, alias, minScale, maxScale } = item;

  const isInRange = isLayerInScaleRange({ map, id });

  const minScaleView = !minScale ? 'М1:1' : `М1:${minScale.toLocaleString()}`;
  const maxScaleView = !maxScale ? 'М1:1' : `М1:${maxScale.toLocaleString()}`;

  return (
    <div
      className={styles.nodeTitleContainer}
      title={
        isInRange
          ? LAYER_SCALE_CONTENT.LAYER_IN_SCALE_RANGE
          : LAYER_SCALE_CONTENT.LAYER_NOT_IN_SCALE_RANGE
      }
    >
      <LayerNode
        inRange={isInRange}
        alias={alias}
        id={id}
        maxScaleView={maxScaleView}
        minScaleView={minScaleView}
        onSettingsClick={(evt) => {
          props.onSettingsClick?.(evt);
        }}
        onTableClick={(evt) => {
          props.onTableClick?.(evt);
        }}
        map={map}
      />
      {isInRange ? null : <LayerNotInRangeTip />}
    </div>
  );
};

export default LayerTreeItem;
