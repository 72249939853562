import { Tag } from 'antd';
import { FC } from 'react';

import styles from './FilterItem.module.scss';

interface IFilterItem {
  filter: string;
  handleClose: (filter: string) => void;
  value: string;
}

const FilterItem: FC<IFilterItem> = ({ filter, handleClose, value }) => {
  return (
    <li>
      <Tag className={styles.item} closable onClose={() => handleClose(filter)}>
        {value}
      </Tag>
    </li>
  );
};

export default FilterItem;
