import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { findById } from '../../../../helpers/findBy';
import rootStore from '../../../../stores/rootStore/rootStore';
import { MapActions } from '../../../../stores/uiStore/uiStore.model';
import { System } from '../../../../ts/enums/enums';

import VideoDetectorIcon, { VideoDtIconProps } from './VideoDetectorIcon';

const VideoDetectorPointUds: FC<Omit<VideoDtIconProps, 'handleDt'>> = (
  props
) => {
  const { detectors } = rootStore.mapDataStore;
  const { setInfoData, infoData } = rootStore.uiStore;
  const { linkedDetectorId } = props.camera;

  const dt = findById(detectors, linkedDetectorId);

  const handleDt = () => {
    if (!dt || !linkedDetectorId) return;
    if (linkedDetectorId === infoData?.id) return setInfoData(null);
    const { longitude, latitude } = dt;

    setInfoData(
      {
        system: System.Detectors,
        coordinate: [longitude, latitude],
        id: linkedDetectorId,
      },
      MapActions.NoActions
    );
  };

  return <VideoDetectorIcon {...props} handleDt={handleDt} />;
};

export default observer(VideoDetectorPointUds);
