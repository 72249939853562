import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../stores/rootStore/rootStore';
import { VIDEO_DETECTOR_ICO_RATE } from '../../constants/constants';
import DeviceWrapper from '../../DeviceWrapper/DeviceWrapper';
import { CameraProjectionProps } from '../../models/camera.model';
import CameraCompact from '../CameraCompact/CameraCompact';
import CameraExtended from '../CameraExtended/CameraExtended';
import { MIN_ICO_SIZE } from '../constants/constants';

const CameraProjection: FC<CameraProjectionProps> = ({
  camera,
  rate,
  isExtended = false,
  isActive = true,
}) => {
  if (!camera) return null;

  const { detectors } = rootStore.mapDataStore;
  const { calcCircleVal } = rootStore.pointsUdsStore;

  const { linkedDetectorId } = camera;

  const curSize = calcCircleVal(camera.size, rate);
  const size = curSize < MIN_ICO_SIZE ? MIN_ICO_SIZE : curSize;
  const isDtLinked =
    Boolean(linkedDetectorId) &&
    detectors.some((dt) => dt.id === linkedDetectorId);

  const props = {
    camera,
    size: isDtLinked ? size * VIDEO_DETECTOR_ICO_RATE : size,
  };

  const deviceWrapperProps = {
    device: props.camera,
    size: props.size,
    rate,
    isActive,
    id: camera.id,
  };

  if (isExtended) {
    return (
      <DeviceWrapper {...deviceWrapperProps}>
        <CameraExtended {...props} rate={rate} />
      </DeviceWrapper>
    );
  }

  return (
    <DeviceWrapper {...deviceWrapperProps}>
      <CameraCompact {...props} />
    </DeviceWrapper>
  );
};

export default observer(CameraProjection);
