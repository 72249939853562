import { Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { getTLControlInfo } from '../../ControlCard/helpers/helpers';
import { ModalConfirmProps } from '../../model/dispatchGroup.model';

import BodyConfirm from './BodyConfirm/BodyConfirm';
import Footer from './Footer/Footer';
import useTLInfo from './hooks/useTLInfo';
import TitleConfirm from './TitleConfirm/TitleConfirm';

const CANCEL_TEXT = 'Отменить';
const CONFIRM_TEXT = 'Отправить';
const WIDTH = '900px';

const ModalConfirm: FC<ModalConfirmProps> = ({
  isOpen,
  setIsOpen,
  okButtonProps,
  mode,
  tlIds,
  onOk,
}) => {
  const { addressLoading, dataList } = useTLInfo(tlIds);

  const isSomeTLCoordControl = tlIds.some(
    (id) => getTLControlInfo(id)?.isActiveCoordTL
  );

  return (
    <Modal
      title={<TitleConfirm />}
      styles={{ body: { maxHeight: 800, overflowY: 'auto' } }}
      okText={CONFIRM_TEXT}
      width={WIDTH}
      open={isOpen}
      cancelText={CANCEL_TEXT}
      centered
      onCancel={() => {
        setIsOpen(false);
      }}
      onOk={onOk}
      okButtonProps={{ ...okButtonProps, danger: isSomeTLCoordControl }}
      footer={(_, { OkBtn, CancelBtn }) => (
        <Footer
          CancelBtn={CancelBtn}
          OkBtn={OkBtn}
          tlIds={tlIds}
          isSomeTLCoordControl={isSomeTLCoordControl}
        />
      )}
    >
      <BodyConfirm
        mode={mode}
        dataList={dataList}
        addressLoading={addressLoading}
      />
    </Modal>
  );
};

export default observer(ModalConfirm);
