import { z } from 'zod';

import { NNInt } from '../zod/utils.zod';

const OptionalString = z.string().nullable().optional();

export const LibrarySchema = z.object({
  id: NNInt,
  name: OptionalString,
  dsc: OptionalString,
  mnemocode: OptionalString,
});

export type LibraryKeysZod = z.infer<typeof LibrarySchema>;
