import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import MainContent from './MainContent/MainContent';
import TabsDetailed from './TabsDetailed/TabsDetailed';

import styles from './ControlCard.module.scss';

interface ControlCardProps {
  tlId: number;
}

const ControlCard: FC<ControlCardProps> = ({ tlId }) => {
  return (
    <div className={styles.container}>
      <TabsDetailed tlId={tlId}>
        <MainContent tlId={tlId} />
      </TabsDetailed>
    </div>
  );
};

export default observer(ControlCard);
