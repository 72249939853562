import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, MouseEventHandler } from 'react';
import { FaEyeLowVision } from 'react-icons/fa6';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';

import rootStore from '../../../stores/rootStore/rootStore';
import Popover, { Content, PopoverWithoutChildren } from '../Popover/Popover';

import styles from './ButtonVisibleEye.module.scss';

interface ButtonVisibleEyeProps {
  onChangeVisibility: MouseEventHandler<HTMLDivElement>;
  isVisible: boolean;
  tip?: Content;
  popoverProps?: Omit<PopoverWithoutChildren, 'content'> & {
    content?: Content;
  };
  isSpecialStatus?: boolean;
  isDisabled?: boolean;
}

const ButtonVisibleEye: FC<ButtonVisibleEyeProps> = ({
  isVisible,
  popoverProps = {},
  tip,
  isSpecialStatus = false,
  isDisabled = false,
  onChangeVisibility,
}) => {
  const { interfaceTipsDelay } = rootStore.uiStore;

  let MainIcon: N<FC> = null;

  if (isSpecialStatus || isDisabled) {
    MainIcon = isDisabled ? IoMdEyeOff : FaEyeLowVision;
  } else {
    MainIcon = isVisible ? IoMdEye : IoMdEyeOff;
  }

  const containerStyle = classNames(
    styles.groupTitleIcon,
    isVisible ? styles.active : styles.passive,
    {
      [styles.special]: isSpecialStatus,
      [styles.disabled]: isDisabled,
    }
  );

  const popoverContent = tip ?? popoverProps.content;

  const basePopoverProps: PopoverWithoutChildren = {
    tipsDelay: interfaceTipsDelay,
    ...popoverProps,
    content: popoverContent || null,
  };

  return (
    <Popover {...basePopoverProps}>
      <div
        className={containerStyle}
        role={'presentation'}
        onClick={!isDisabled ? onChangeVisibility : undefined}
      >
        {MainIcon ? <MainIcon /> : null}
      </div>
    </Popover>
  );
};

export default observer(ButtonVisibleEye);
