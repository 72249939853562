import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { DATE_FORMAT } from '../../../../constants/constants';

dayjs.extend(utc);

export const getDate = (
  n: number,
  date: 'day' | 'hour' | 'minute' | 'second' = 'day'
) => dayjs().locale('ru').utcOffset(0).add(n, date).format(DATE_FORMAT);
